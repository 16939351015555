interface IProps {
    Id: number;
    SegmentName: string;
    AccountNumber: number | string;
    Description: string | null;
    ParentCompanyGLAccountId: number;
    Depth: number;
    Children: any;
}

function formatLabel(data: IProps) {
    if (data.AccountNumber && data.Description) {
        return `${data.AccountNumber} - ${data.Description}`;
    }

    return `${data.AccountNumber}`;
}

const mapCompanyGLAccount = (data: IProps) => {
    const options = {
        id: data.Id,
        name: data.SegmentName,
        value: data.AccountNumber,
        label: formatLabel(data),
        parentId: data.ParentCompanyGLAccountId,
        depth: data.Depth,
        children: [],
    };
    if (data.Children && data.Children.length > 0) {
        options.children = data.Children.map((m: IProps) => mapCompanyGLAccount(m));
    }
    return options;
};

export default mapCompanyGLAccount;
