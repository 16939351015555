const subNavPaths = {
    transactions: [
        'TRANSACTIONS_ASNS',
        'TRANSACTIONS_CONSUMPTION',
        'TRANSACTIONS_INVENTORY',
        'TRANSACTIONS_INVOICES',
        'TRANSACTIONS_ACKLNS',
        'TRANSACTIONS_PURCHASE_ORDERS',
        'TRANSACTIONS_RECEIVING',
        'TRANSACTIONS_SIM_DOCUMENT',
    ],
    'parking-lot': [
        'PARK_LOT_ALL_WORKFLOWS',
        'PARK_LOT_NONSTOCK_INVOICES',
        'PARK_LOT_COUNTER_POS',
        'PARK_LOT_GLCODING',
        'PARK_LOT_GLCODING_RELEASE',
        'PARK_LOT_PARKED_DOCUMENTS',
        'PARK_LOT_PO_LINE_NUMS',
        'PARK_LOT_INVOICE_CODING',
        'PARK_LOT_DOCUMENT_APPROVAL',
    ],
    'dynamic-discounting': [
        'DYNAMIC_DISCOUNT_CREATE_CAMPAIGN',
        'DYNAMIC_DISCOUNT_REPORTING',
        'DYNAMIC_DISCOUNT_MANAGE_USERS',
        'DYNAMIC_DISCOUNT_SETTINGS',
    ],
    documents: [
        'ASNS',
        'CONSUMPTION_ADVICES',
        'INVENTORY_ADVICES',
        'INVOICES',
        'PURCHASE_ORDERS',
        'PURCHASE_ORDERS_ACKLNS',
        'RECEIVING_ADVICES',
        'SIM_DOCUMENTS',
    ],
    dashboard: [
        'DASHBOARD_OVERVIEW',
        'DASHBOARD_STRAIGHT_THROUGH_PROCESSING',
        'DASHBOARD_SUPPLIERS',
        'DASHBOARD_INVOICES',
        'DASHBOARD_SPEND',
    ],
};

export default subNavPaths;
