import { getServiceAgreements } from './serviceAgreement';
import { portalUserService } from 'common';

export const SERVICE_AGREEMENT_POPUP_SHOW = 'SERVICE_AGREEMENT_POPUP_SHOW';

export function serviceAgreementPopupShow(popupState = true) {
    return (dispatch, getState) => {
        const { serviceAgreement } = getState();
        dispatch(serviceAgreementChangeState(popupState));

        if (popupState && !serviceAgreement.serviceAgreements) {
            //get serviceAgreements on open
            dispatch(getServiceAgreements(portalUserService.getCurrentCompanyId()));
        }
    };
}

function serviceAgreementChangeState(state) {
    return {
        type: SERVICE_AGREEMENT_POPUP_SHOW,
        payload: !!state,
    };
}
