import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { format } from 'date-fns';
import {
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    ListItemAvatar,
    Avatar,
    IconButton,
    Typography,
    Theme,
} from '@mui/material';
import { ChevronRight, Description } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { BusinessDocType } from '../../../../types';
import { useRelatedDocumentsState } from '../../RelatedDocumentsContext';
import { ScrollToHere } from '../../../../ui';
import { getDocumentLink } from '../../utils';
import { ProcessedAgainstRevisionBlurb } from './ProcessedAgainstRevisionBlurb';
import { DocumentRevision } from './useDocumentRevisions';

const useStyles = makeStyles((theme: Theme) => ({
    list: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    item: {
        paddingTop: '0',
        paddingBottom: '0',
    },
    bold: {
        fontWeight: 'bold',
    },
    active: {
        color: theme.palette.background.paper,
    },
}));

export interface DocumentRevisionListItemProps {
    document: DocumentRevision;

    relatedDocumentType: BusinessDocType;

    relatedDocumentNumber: string;

    currentDocumentType: BusinessDocType;

    processedAgainstRevisionDocumentId: number;
}

export const DocumentRevisionListItem: FC<DocumentRevisionListItemProps> = ({
    document,
    relatedDocumentType,
    relatedDocumentNumber,
    currentDocumentType,
    processedAgainstRevisionDocumentId,
}) => {
    const { documentRouteTemplates } = useRelatedDocumentsState();
    const classes = useStyles();
    const isProcessedAgainstRevision = processedAgainstRevisionDocumentId === document.id;

    return (
        <NavLink
            key={document.id}
            to={getDocumentLink(document.id, relatedDocumentType, documentRouteTemplates)}
            target="_blank"
            data-testid={`document-revision-${document.id}`}
        >
            <ScrollToHere enabled={isProcessedAgainstRevision}>
                <ListItem
                    selected={isProcessedAgainstRevision}
                    className={classes.item}
                    classes={{ selected: classes.active }}
                >
                    <ListItemAvatar>
                        <Avatar>
                            <Description />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Typography className={clsx(isProcessedAgainstRevision ? classes.bold : '')}>{`Revision #${
                                document.revisionNumber + 1
                            }`}</Typography>
                        }
                        secondary={
                            <div className={clsx(isProcessedAgainstRevision ? classes.bold : '')}>
                                <div>Amount: ${document.amount}</div>
                                <div>Date: {format(new Date(document.date), 'P')}</div>
                                {isProcessedAgainstRevision && (
                                    <ProcessedAgainstRevisionBlurb
                                        relatedDocumentType={relatedDocumentType}
                                        relatedDocumentNumber={relatedDocumentNumber}
                                        currentDocumentType={currentDocumentType}
                                    />
                                )}
                            </div>
                        }
                    />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="select">
                            <ChevronRight />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            </ScrollToHere>
        </NavLink>
    );
};
