import projectConfig from '../project.config.json';

export function getLocalizedProfileType(localization, profileType) {
    switch (profileType) {
        case projectConfig.profileType.BuyerDestination:
            return localization.profileTypeBuyerDestination;
        case projectConfig.profileType.BuyerWarehouse:
            return localization.profileTypeBuyerWarehouse;
        case projectConfig.profileType.Invoice:
            return localization.profileTypeInvoice;
        case projectConfig.profileType.Procurement:
            return localization.profileTypeProcurement;
        case projectConfig.profileType.Sales:
            return localization.profileTypeSales;
        case projectConfig.profileType.SupplierWarehouse:
            return localization.profileTypeSupplierWarehouse;
        default:
            console.error('"' + profileType + '" profile type is not yet handled by profile management');
            return null;
    }
}
