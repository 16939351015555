import { BigNumber } from 'bignumber.js';

const sumValues = (values: (string | number | null)[]) => {
    const valueNumbers = values.filter((x) => x != null && x !== '').map((x) => new BigNumber(x!));

    return valueNumbers.reduce((acc, x) => acc.plus(x), new BigNumber(0));
};

const computeRemainingAmount = (target: string | number, values: (string | number | null)[]) => {
    const targetNumber = new BigNumber(target);
    const remainingAmount = targetNumber.minus(sumValues(values));
    return remainingAmount;
};

const formatAmount = (leftSymbol: string, amount: BigNumber, rightSymbol: string) => {
    const prefix = amount.isNegative() ? '-' : '';

    return `${prefix}${leftSymbol}${amount.abs().toFormat(2)}${rightSymbol}`;
};

function safeDivision(dividend: BigNumber, divisor: BigNumber) {
    // ensure dividing 0 by 0 returns 1
    if (dividend.isEqualTo(divisor)) {
        return new BigNumber(1);
    }

    return dividend.dividedBy(divisor);
}

const calculateRemainderAndRunningTotals = (
    selectedIndex: number,
    splits: any[],
    lineNetTotal: number,
    currencySymbol: string
) => {
    const isLastLine = selectedIndex + 1 === splits.length;
    const splitAmounts = splits.map(({ amount }) => (amount === 'NaN' ? null : amount));

    // in case lineNetTotal is 0
    const unformattedPercentRunningTotal = safeDivision(sumValues(splitAmounts), new BigNumber(lineNetTotal));
    const percentRunningTotal = formatAmount('', unformattedPercentRunningTotal.multipliedBy(100), '%');

    const unformattedAmountRunningTotal = sumValues(splitAmounts);
    const amountRunningTotal = formatAmount(currencySymbol, unformattedAmountRunningTotal, '');

    const unformattedPercentRemaining = new BigNumber(1).minus(unformattedPercentRunningTotal);
    const formattedPercentRemaining = formatAmount('', unformattedPercentRemaining.multipliedBy(100), '%');

    const formattedAmountRemaining = formatAmount(
        currencySymbol,
        computeRemainingAmount(lineNetTotal, splitAmounts),
        ''
    );

    const isValidAllocation = unformattedAmountRunningTotal.isEqualTo(new BigNumber(lineNetTotal));

    return {
        isLastLine,
        percentRunningTotal,
        amountRunningTotal,
        formattedPercentRemaining,
        formattedAmountRemaining,
        isValidAllocation,
    };
};

export default calculateRemainderAndRunningTotals;
