export class EventEmitter<T = void> {
    private subscribers: ((arg: T) => void)[] = [];

    public subscribe(cb: (arg: T) => void) {
        this.subscribers.push(cb);

        return () => {
            const index = this.subscribers.indexOf(cb);
            if (index === -1) {
                return;
            }
            this.subscribers.splice(index, 1);
        };
    }

    public notify(arg: T) {
        this.subscribers.forEach((cb) => cb(arg));
    }
}
