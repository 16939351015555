import f, { ODataFilterBuilder } from 'odata-filter-builder';
import { escape, isEmpty, ODataUtils } from '../../../utils';

interface IODataDocumentType {
    name?: string;
    description?: string;
    active?: boolean;
    businessDocTypeId?: number;
    businessDocSubTypeId?: number;
    companyId?: number;
    orderBy?: string;
}

export const createAllDocumentTypesForDataGridFilter = ({
    name,
    description,
    active,
    businessDocTypeId,
    businessDocSubTypeId,
    companyId,
}: Omit<IODataDocumentType, 'orderBy'>) => {
    const filterRules: ODataFilterBuilder[] = [];

    if (!isEmpty(name)) {
        filterRules.push(f().contains('name', escape(name!)));
    }

    if (!isEmpty(description)) {
        filterRules.push(f().contains('description', escape(description!)));
    }

    if (typeof active === 'boolean') {
        filterRules.push(f().eq('active', active));
    }

    if (typeof businessDocTypeId === 'number') {
        filterRules.push(f().eq('BusinessDocTypeId', businessDocTypeId));
    }

    if (typeof businessDocSubTypeId === 'number') {
        filterRules.push(f().eq('BusinessDocSubTypeId', businessDocSubTypeId));
    }

    if (companyId) {
        filterRules.push(f().eq('companyId', companyId));
    }

    return filterRules.length > 0 ? ODataUtils.andConditions(filterRules).toString() : undefined;
};
