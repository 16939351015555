import { createContext, ReactNode, useContext } from 'react';

type FieldVisibility = 'AlwaysVisible' | 'AlwaysInvisible' | 'VisibleIfHasValue';

interface IFormControlContextValue {
    readonly?: boolean;
    visibility?: FieldVisibility;
}

export const FormControlContext = createContext({} as IFormControlContextValue);

export const FormControlProvider = ({ value, children }: { value: IFormControlContextValue; children: ReactNode }) => {
    const oldValue = useContext(FormControlContext);

    return <FormControlContext.Provider value={{ ...oldValue, ...value }}>{children}</FormControlContext.Provider>;
};

export const useFieldsReadonly = (readonlyOverride: boolean | undefined): boolean => {
    const { readonly } = useContext(FormControlContext);

    return readonlyOverride ?? readonly ?? false;
};

export const useFieldVisibility = (visibilityOverride: FieldVisibility | undefined): FieldVisibility => {
    const { visibility } = useContext(FormControlContext);

    return visibilityOverride ?? visibility ?? 'AlwaysVisible';
};
