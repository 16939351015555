import actions from '../actions';

const { PUSH_ALERT, CLEAR_ALERTS } = actions;

const initialState = [
    /*
    { type: 'success', text: 'Test success message' },
    { type: 'error', text: 'Test error message' },
    { type: 'warning', text: 'Test warning message' },
    */
];

// eslint-disable-next-line import/prefer-default-export
export function alerts(state = initialState, action) {
    switch (action.type) {
        case PUSH_ALERT:
            return [...state, action.payload];
        case '@@router/LOCATION_CHANGE':
            // Here we remove all existing messages on location change
            return [];
        case CLEAR_ALERTS:
            // Here we remove all existing messages on location change
            return [];
        default:
            return state;
    }
}
