import { isEqual } from 'lodash';
import { IDocumentViewModel } from '../../services/Document/Api';
import { IDocumentAmountsViewModel } from './IDocumentAmountsViewModel';
import { factory } from './DocumentAmountsViewModelFactory';
import { getOriginalDocument } from './originalDocumentRepository';

/**
 * Extracts all the data from the document that used in the calculations.
 *
 * This needs to be updated if any new getters for these are added in the future.
 */
function getVMData(vm: IDocumentAmountsViewModel) {
    return {
        lineItemAmounts: vm.getLineItemAmounts(),
        charges: vm.getChargesAndAllowances(),
        amountPaid: vm.getAmountPaid(),
    };
}

/**
 * We need this flag to keep track of whether the fields involved in calculations
 * have been changed at least once. This way the totals will be recalculated even if
 * the current values set by the user are the same found in the original document.
 */
let docFieldsHaveChangedAtLeastOnce = false;

export function resetDocFieldsHaveChangedAtLeastOnce() {
    docFieldsHaveChangedAtLeastOnce = false;
}

export function hasAnyFieldInvolvedInCalculationChanged(newDocument: IDocumentViewModel): boolean {
    const originalVM = factory.createDocumentAmountsViewModel(getOriginalDocument());
    const newVM = factory.createDocumentAmountsViewModel(newDocument);

    const originalVMData = getVMData(originalVM);
    const newVMData = getVMData(newVM);

    const haveDocFieldsChanged = !isEqual(originalVMData, newVMData);

    if (haveDocFieldsChanged) {
        docFieldsHaveChangedAtLeastOnce = true;
    }

    return haveDocFieldsChanged || docFieldsHaveChangedAtLeastOnce;
}
