import {
    parseFilterByVendorNumber,
    parseIsRelatedDocumentField,
    parseRelatedDocumentLinkType,
    parseRelatedDocumentType,
} from './parseControlFormatFields.utils';
import { RawControlFormatFields, ControlFormatFields } from './types';

export function parseControlFormatFields(fields: RawControlFormatFields | undefined): ControlFormatFields {
    return {
        isRelatedDocumentField: parseIsRelatedDocumentField(fields?.isRelatedDocumentField),
        relatedDocumentType: parseRelatedDocumentType(fields?.relatedDocumentType),
        relatedDocumentLinkType: parseRelatedDocumentLinkType(fields?.relatedDocumentLinkType),
        filterByVendorNumber: parseFilterByVendorNumber(fields?.filterByVendorNumber),
    };
}
