import { FieldInfo, IAssociatedFieldService } from '../../proservContractTypes';

export class AssociatedFieldService implements IAssociatedFieldService {
    bodyField(options: { lineNumber: number; fieldName: string }) {
        return `bodyField-${options.lineNumber}-${options.fieldName}`;
    }

    glField(options: { lineNumber: number; splitNumber: number; fieldName: string }) {
        return `glField-${options.lineNumber}-${options.splitNumber}-${options.fieldName}`;
    }

    headerField(fieldName: string) {
        return `headerField-${fieldName}`;
    }

    glHeaderField(options: { fieldName: string; splitNumber: number }) {
        return `glHeaderField-${options.splitNumber}-${options.fieldName}`;
    }

    footerField(fieldName: string) {
        return `footerField-${fieldName}`;
    }

    collapsedHeaderField(options: { sectionName: string; fieldName: string }) {
        return `collapsedHeaderField-${options.sectionName}-${options.fieldName}`;
    }

    bodyFieldAllLines(fieldName: string) {
        return `bodyFieldAllLines-${fieldName}`;
    }

    glFieldAllSplits(fieldName: string) {
        return `glFieldAllSplits-${fieldName}`;
    }

    glFieldAllSplitsOnLine(options: { fieldName: string; lineNumber: number }) {
        return `glFieldAllSplitsOnLine-${options.lineNumber}-${options.fieldName}`;
    }

    glCodingSection() {
        return 'glCodingSection';
    }

    getFieldName(associatedFieldKey: string) {
        return associatedFieldKey.split('-').slice(-1)[0];
    }
}

export function convertFieldKeyToFieldInfo(fieldKey: string): FieldInfo {
    const [fieldType, ...data] = fieldKey.split('-');

    switch (fieldType) {
        case 'bodyField': {
            const [lineNumber, fieldName] = data;
            return {
                type: 'BodyFieldInfo',
                lineNumber: Number(lineNumber),
                fieldName,
            } as const;
        }
        case 'glField': {
            const [lineNumber, splitNumber, fieldName] = data;
            return {
                type: 'GLFieldInfo',
                lineNumber: Number(lineNumber),
                splitNumber: Number(splitNumber),
                fieldName,
            } as const;
        }
        case 'glHeaderField': {
            const [splitNumber, fieldName] = data;
            return {
                type: 'GLHeaderFieldInfo',
                splitNumber: Number(splitNumber),
                fieldName,
            } as const;
        }
        case 'headerField': {
            const [fieldName] = data;
            return {
                type: 'HeaderFieldInfo',
                fieldName,
            } as const;
        }
        case 'footerField': {
            const [fieldName] = data;
            return {
                type: 'FooterFieldInfo',
                fieldName,
            } as const;
        }
        case 'collapsedHeaderField': {
            const [sectionName, fieldName] = data;
            return {
                type: 'CollapsedHeaderFieldInfo',
                sectionName,
                fieldName,
            } as const;
        }
        default:
            throw new Error(`Invalid field key ${fieldKey} - type ${fieldType} is unknown`);
    }
}

export default new AssociatedFieldService();
