import { ChangeEvent, FC, Children, isValidElement, ReactNode } from 'react';
import { FormControl, FormHelperText, FormLabel, RadioGroup as MuiRadioGroup, useTheme } from '@mui/material';
import { ITooltipIconProps, TooltipLabel } from '../Tooltip';
import { ReadOnly } from '../ReadOnly';
import { useFieldsReadonly } from '../../ui/Form/FormControlContext';

export interface IRadioButtonGroupProps {
    id?: string;
    label?: string;
    name?: string;
    value?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    tooltip?: ITooltipIconProps;
    testId?: string;
    required?: boolean;
    readonly?: boolean;
    error?: boolean;
    helperText?: string;
    row?: boolean;
}

function findOptionLabel(children: ReactNode, value: unknown) {
    let label = '';
    Children.forEach(children, (c) => {
        if (isValidElement(c) && (c.props as any).value === value) {
            label = (c.props as any).label;
        }
    });

    return label;
}

export const RadioButtonGroup: FC<IRadioButtonGroupProps> = ({
    id,
    label,
    name,
    value,
    onChange,
    children,
    tooltip,
    testId,
    required,
    readonly,
    error,
    helperText,
    row,
}) => {
    const theme = useTheme();

    const isReadonly = useFieldsReadonly(readonly);
    if (isReadonly) {
        return <ReadOnly testId={testId} label={label} value={findOptionLabel(children, value)} />;
    }

    if (required) {
        label = `${label} *`;
    }

    return (
        <FormControl
            data-testid={testId}
            id={id}
            component="fieldset"
            style={{ borderRadius: 4, border: `1px solid ${theme.palette.divider}`, paddingLeft: 8 }}
            error={error}
        >
            <FormLabel component="legend">
                <TooltipLabel label={label} tooltip={tooltip} />
            </FormLabel>
            <MuiRadioGroup aria-label={name} value={value} name={name} onChange={onChange} row={row}>
                {children}
            </MuiRadioGroup>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};
