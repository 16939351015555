import pathToJSON from './pathToJSON';
import { api, outerAPI, parseMessage } from './api';
import { findByProp } from './document/layout';
import { validateEmailFormat, validatePhoneFormat } from './validation';

function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
}

export { getKeyByValue, pathToJSON, api, outerAPI, parseMessage, findByProp, validateEmailFormat, validatePhoneFormat };
