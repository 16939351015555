export const isIE = () => {
    const isIE11 = navigator.userAgent.indexOf('.NET CLR') > -1;
    return isIE11 || navigator.appVersion.indexOf('MSIE') !== -1;
};

export const isWebkit = () =>
    navigator.vendor &&
    navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1;

export const isIOS = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.platform);
    return isIOS;
};

export const isSamsung = () => {
    const isSamsung = navigator.userAgent.match(
        /SAMSUNG|SGH-[I|N|T]|GT-[I|P|N]|SM-[N|P|T|Z|G]|SHV-E|SCH-[I|J|R|S]|SPH-L/i
    );
    return isSamsung;
};
