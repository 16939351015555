import { AxiosResponse } from 'axios';
import { api } from '../../../services/api';
import { WorkflowBulkActionResult } from '../Services';
import {
    IReassignmentUserGroupsByDocumentViewModel,
    IDocumentApprovalViewModel,
    IReassignmentUsersByDocumentViewModel,
} from './ViewModels';

export class WorkflowInstanceApi {
    route = '/WorkflowInstance';

    public updateWorkflowReject = async (
        documentIds: number[],
        reason: string
    ): Promise<AxiosResponse<WorkflowBulkActionResult[]>> => {
        const params = {
            DocumentIDs: documentIds,
            Reason: reason,
        };
        return api().put(`${this.route}/Reject`, params);
    };

    public updateWorkflowApprove = async (
        documentIds: number[],
        reason: string,
        userId: number
    ): Promise<AxiosResponse<WorkflowBulkActionResult[]>> => {
        const params = {
            DocumentIDs: documentIds,
            Reason: reason,
            UserID: userId,
        };
        return api().post(`${this.route}/BulkApprove`, params);
    };

    public getWorkflowReassignUsers = async (
        documentIds: number[]
    ): Promise<AxiosResponse<IReassignmentUsersByDocumentViewModel[]>> => {
        return api().post(`${this.route}/ReassignToUsers`, documentIds);
    };

    public getWorkflowApproveDocuments = async (
        documentIds: number[]
    ): Promise<AxiosResponse<IDocumentApprovalViewModel>> => {
        return api().post(`${this.route}/ValidateBulkApprove`, documentIds);
    };

    public getWorkflowReassignUserGroups = async (
        documentIds: number[],
        currentUserId: number
    ): Promise<AxiosResponse<IReassignmentUserGroupsByDocumentViewModel[]>> => {
        return api().post(`${this.route}/ReassignToUserGroups?userId=${currentUserId}`, documentIds);
    };

    public getWorkflowSingleReassignUserGroups = async (
        documentId: number,
        workflowId: number,
        currentUserId: number
    ): Promise<AxiosResponse<IReassignmentUserGroupsByDocumentViewModel[]>> => {
        return api().get(
            `/WorkflowTask/ReassignWorkflowUserGroups?userId=${currentUserId}&documentId=${documentId}&workflowTaskId=${workflowId}`
        );
    };

    public updateWorkflowReassign = async (
        documentIds: number[],
        reason: string,
        payload: { ReassignToUserId: number | null; ReassignToUserGroupId: number | null },
        currentUserId: number
    ): Promise<AxiosResponse<WorkflowBulkActionResult[]>> => {
        const params = {
            DocumentIDs: documentIds,
            Reason: reason,
            ...payload,
        };
        return api().put(`${this.route}/Reassign?userId=${currentUserId}`, params);
    };
}
