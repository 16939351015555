import actions from '../../actions/index';
import { EDIT_LAYOUT_FETCHING, EDIT_LAYOUT_FETCHED } from '../../actions/document/documentActions';
import { SET_FIELD_DEFAULTS } from '../../actions/document/documentCreationActions';
import {
    TOGGLE_LAYOUT_CONFIG_POPUP,
    TOGGLE_LAYOUT_FIELD,
    CLEAR_LAYOUT_TOGGLE_INFO,
    APPLY_FIELDS_CONFIG,
    TOGGLE_OVERRIDE_TYPE,
} from '../../actions/invoiceCreation/layout';
import { USER_LOGIN_FAILED, USER_LOGIN_PENDING, USER_LOGIN_SUCCESS, USER_LOGOUT } from '../../actions/userlogin';

const { DOCUMENT_LAYOUT_FETCHING } = actions;

const initialState = {
    isLoading: false,
    fieldsPopupVisible: false,
    editedFields: {},
    appliedFieldEdits: {},
    fieldDefaults: {},
    supplierOverride: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case DOCUMENT_LAYOUT_FETCHING:
        case EDIT_LAYOUT_FETCHING:
            return {
                ...state,
                isLoading: true,
                editedFields: initialState.editedFields,
                appliedFieldEdits: initialState.appliedFieldEdits,
                supplierOverride: initialState.supplierOverride,
            };
        case EDIT_LAYOUT_FETCHED:
            return {
                ...state,
                ...payload,
                isLoading: false,
            };
        case TOGGLE_LAYOUT_CONFIG_POPUP:
            // Toggle the popup visibility and remove any unsaved changes to the checkboxes
            return {
                ...state,
                fieldsPopupVisible: !state.fieldsPopupVisible,
                editedFields: { ...state.appliedFieldEdits },
                supplierOverride: initialState.supplierOverride,
            };
        case TOGGLE_LAYOUT_FIELD: {
            // Toggle a checkbox
            const { field, checked: visible } = payload;
            return {
                ...state,
                editedFields: {
                    ...state.editedFields,
                    [field]: visible,
                },
            };
        }
        case TOGGLE_OVERRIDE_TYPE: {
            // Update the state of the supplier override checkbox
            return {
                ...state,
                supplierOverride: payload.supplierOverride,
            };
        }
        case APPLY_FIELDS_CONFIG: {
            // Save changes to the field checkboxes and reset the supplier override checkbox
            return {
                ...state,
                appliedFieldEdits: { ...state.editedFields },
                supplierOverride: initialState.supplierOverride,
            };
        }

        case SET_FIELD_DEFAULTS:
            // Set the default value to be used for fields when they become visible
            return {
                ...state,
                fieldDefaults: payload,
            };

        case USER_LOGIN_FAILED:
        case USER_LOGIN_PENDING: {
            // if it was timeout return currenState, otherwhise initialState
            const { timeout } = state;
            return timeout && timeout === true ? state : initialState;
        }
        case CLEAR_LAYOUT_TOGGLE_INFO:
        case USER_LOGIN_SUCCESS: {
            // if it was timeout return currenState, otherwhise initialState
            const { timeout, ...currentState } = state;
            return timeout && timeout === true ? currentState : initialState;
        }

        case USER_LOGOUT: {
            const { timeout } = payload;
            return timeout && timeout == true ? { ...state, timeout } : initialState;
            //return initialState;
        }
        default:
            return state;
    }
};
