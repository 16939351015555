import { useState, useEffect } from 'react';
import { useStableReference } from '../../../utils';
import {
    createFailedToLoadState,
    createLoadingState,
    DocumentEditValidationState,
    ValidatorState,
    IInternalRule,
} from '../types/private';
import { createNullConfiguration } from './createNullConfiguration';
import { fetchConfiguration, projectDocumentInfo } from './fetchDocumentEditConfiguration';
import { createInternalRulesConfiguration, findIllegalInternalRules } from './createInternalRulesConfiguration';
import { mixInternalRulesConfigWithFetchedConfig } from './mixConfigurations';

export function useDocumentEditConfiguration(
    documentState: DocumentEditValidationState | undefined,
    setValidatorState: (newState: ValidatorState) => void,
    getInternalRules: () => IInternalRule[] | undefined
) {
    const [configuration, setConfiguration] = useState(createNullConfiguration());
    const [configurationLoaded, setConfigurationLoaded] = useState(false);
    const { buyerCompanyId, businessDocType, type } = documentState ?? {};
    const docInfo = useStableReference(documentState && projectDocumentInfo(documentState));
    const internalRules = useStableReference(getInternalRules() ?? null);

    useEffect(() => {
        let canceled = false;
        const cancel = () => {
            canceled = true;
        };

        setValidatorState(createLoadingState());

        if (!buyerCompanyId || !businessDocType || !docInfo || !internalRules || !type) {
            setConfiguration(createNullConfiguration());
            return cancel;
        }

        const fetchAndStoreConfiguration = async () => {
            try {
                const fetchedConfiguration = await fetchConfiguration(buyerCompanyId, businessDocType, docInfo);
                if (canceled) {
                    return;
                }

                if (internalRules.length > 0) {
                    const illegalInternalRules = findIllegalInternalRules(internalRules, type);

                    if (illegalInternalRules.length > 0) {
                        console.error(
                            `Found InternalRules that are not allowed in this context (${type})`,
                            illegalInternalRules
                        );
                        setValidatorState(createFailedToLoadState('IllegalInternalRulesError'));
                        return;
                    }
                }

                setConfiguration(
                    (() => {
                        if (fetchedConfiguration && internalRules.length > 0) {
                            const internalRulesConfig = createInternalRulesConfiguration(internalRules);

                            return mixInternalRulesConfigWithFetchedConfig(
                                internalRulesConfig,
                                fetchedConfiguration,
                                buyerCompanyId,
                                businessDocType
                            );
                        }

                        if (fetchedConfiguration) {
                            return fetchedConfiguration;
                        }

                        if (internalRules.length > 0) {
                            return createInternalRulesConfiguration(internalRules);
                        }

                        return createNullConfiguration();
                    })()
                );
                setConfigurationLoaded(true);
            } catch (e: any) {
                if (canceled) {
                    return;
                }

                console.error('Failed to load document edit validation configuration', e);

                if (e.isAxiosError) {
                    setValidatorState(createFailedToLoadState('ConfigLoadNetworkError'));
                } else {
                    setValidatorState(createFailedToLoadState('ConfigParseError'));
                }
            }
        };

        fetchAndStoreConfiguration();

        return cancel;
    }, [buyerCompanyId, businessDocType, docInfo, setValidatorState, internalRules, type]);

    return {
        configuration,
        configurationLoaded,
    };
}
