export class SuccessfulAuthRedirectLocation {
    /**
     * Saves the redirect location so it can be accessed later after successful authentication.
     * @param location the redirect location.
     */
    setRedirectLocation(location: string) {
        localStorage.setItem('successfulAuthRedirectLocation', location);
    }

    /**
     * Retrieves and deletes the redirect location after a successful authentication.
     * @returns the redirect location, if any exists
     */
    getRedirectLocation() {
        const location = localStorage.getItem('successfulAuthRedirectLocation');
        localStorage.removeItem('successfulAuthRedirectLocation');
        return location;
    }
}

export const successfulAuthRedirectLocation = new SuccessfulAuthRedirectLocation();
