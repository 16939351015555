export class WorkflowValidationError extends Error {
    constructor(msg: string) {
        super(`Script Error: ${msg}`);

        Object.setPrototypeOf(this, WorkflowValidationError.prototype);
    }
}

export class EarlyAccessAttemptError extends WorkflowValidationError {
    constructor(property: string) {
        super(`Attempted to access \`${property}\` before it was set.`);
        Object.setPrototypeOf(this, EarlyAccessAttemptError.prototype);
    }
}

export class InvalidResultError extends WorkflowValidationError {
    constructor({ property, expected, index }: { property: string; expected?: string; index?: number }) {
        const msg = !!expected
            ? `Expected ${expected} as value for ${property}.`
            : `Invalid value for \`${property}\`.`;

        super(!!index ? msg.concat(` At index: ${index}.`) : msg);

        Object.setPrototypeOf(this, InvalidResultError.prototype);
    }
}
