import { useCallback } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

/**
 * Ensures that a pathname ends with "/"
 * @param pathName the pathname
 * @returns the pathname ending with "/"
 */
function normalizeEnding(pathName: string) {
    if (pathName.endsWith('/')) {
        return pathName;
    }

    return pathName + '/';
}

/**
 * Split a normalized ending path into its non-empty "segments" or "parts".
 * @param pathName the pathname to split
 * @returns the non-empty segments/parts
 */
function createParts(pathName: string) {
    return normalizeEnding(pathName).split('/').filter(Boolean);
}

/**
 * Returns whether the `urlPathName` starts with the `routePathName`. "Starts with" means
 * that the non-empty segments of `routePathName` are the prefix segments of `urlPathName`.
 * @param routePathName the pathname for the route / menu link
 * @param urlPathName the pathname for the current page
 * @returns whether the `urlPathName` starts with the `routePathName`.
 */
function pathStartsWith(routePathName: string, urlPathName: string) {
    const routeParts = createParts(routePathName);
    const urlParts = createParts(urlPathName);

    return routeParts.every((x, i) => x === urlParts[i]);
}

/**
 * Helper to check if a menu link is currently selected.
 * @returns A function that takes a pathname for a menu link and returns whether that link should be displayed as "selected"
 */
export function useIsSelected() {
    const history = useHistory();

    // subscribes to history changes, ensuring that using this hook will make
    // a component re-render any time the history changes.
    useLocation();

    return useCallback(
        (pathname: string, useMatchPath?: boolean) => {
            if (pathname === '/') {
                return history.location.pathname === pathname;
            }

            if (useMatchPath) {
                const match = matchPath(history.location.pathname, {
                    path: pathname,
                    exact: false,
                });

                return match !== null;
            }

            if (pathname.includes('parking-lot') && !history.location.pathname.includes('all-workflows')) {
                return history.location.pathname === pathname;
            }

            return pathStartsWith(pathname, history.location.pathname);
        },
        [history]
    );
}
