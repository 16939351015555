export function buildLookupTable<T, K extends string | number, U>(
    values: T[],
    getKey: (value: T) => K,
    getValue: (value: T) => U
) {
    const lookupTable: { [key in K]?: U } = {};
    values.forEach((value) => {
        lookupTable[getKey(value)] = getValue(value);
    });

    return lookupTable;
}
