import * as reportsActions from '../actions/reportActions';

const initialState = {
    definition: { items: [], isLoading: false },
    confirmation: { items: [], isLoading: false },
};

export const reports = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case reportsActions.REPORTS_DEFINITIONS_FETCHING: {
            return {
                ...state,
                definition: {
                    items: [],
                    isLoading: true,
                },
            };
        }

        case reportsActions.REPORTS_DEFINITIONS_FETCHED: {
            return {
                ...state,
                definition: {
                    items: payload,
                    isLoading: false,
                },
            };
        }

        case reportsActions.REPORTS_CONFIRMATION_FETCHING: {
            return {
                ...state,
                confirmation: {
                    items: [],
                    isLoading: true,
                },
            };
        }

        case reportsActions.REPORTS_CONFIRMATION_FETCHED: {
            return {
                ...state,
                confirmation: {
                    items: payload,
                    isLoading: false,
                },
            };
        }

        default:
            return state;
    }
};
