import { normalizeRequestUrl } from '../../../services/utils/axiosOptimizer';
import { registerAPIInterceptor } from '../../../services/utils/optimizeApi';
import { runMutations } from './setupPluginHost';

registerAPIInterceptor((instance) => {
    instance.interceptors.request.use(async (request) => {
        const url = normalizeRequestUrl(request.url) ?? '';

        if (url.match(/Document\/\d+/) && request.method?.toLowerCase() === 'put') {
            return {
                ...request,
                data: await runMutations('Document', request.data),
            };
        }

        return request;
    });
});
