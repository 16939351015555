import IPersistor, { ICacheEntry } from './IPersistor';
import { defaultParser } from '../JSONParser';

export default class LocalStoragePersistor implements IPersistor {
    /**
     * Constructs a LocalStoragePersistor
     * @param name unique name for this persistor to prevent conflicts with other persistors of this type
     * @param versionKey uniquely identify the version of the cache image. this should be updated with each build.
     */
    constructor(private name: string, private versionKey: string | number) {}

    async persist(entries: ICacheEntry[]) {
        while (entries.length > 0) {
            try {
                localStorage.setItem(
                    this.getEntriesKey(),
                    defaultParser.serialize({
                        versionKey: this.versionKey,
                        entries,
                    })
                );
                return;
            } catch (e) {
                if (!(e instanceof DOMException)) {
                    throw e;
                }
                // the error indicates the entries were too big, so shrink them before
                // trying again. we can shrink all the way to 0 entries to handle the
                // case that there is no storage space left at all
                entries.splice(entries.length - 1, 1);
            }
        }
    }

    async hydrate(): Promise<ICacheEntry[]> {
        const entryData = localStorage.getItem(this.getEntriesKey());
        if (entryData === null) {
            return [];
        }
        const { entries, versionKey } = defaultParser.parse(entryData);
        if (versionKey !== this.versionKey) {
            return [];
        }
        return entries;
    }

    getEntriesKey() {
        return `${this.name}.[cacheEntries]`;
    }
}
