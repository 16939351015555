import { api } from '../../../api';
import { ApiResponse } from '../../Utilities';
import { AuditLogAPIViewModel } from '../../ViewModels';
import { GetAuditLogParams } from './Params';

export class AudiLogApi {
    private route = '/AuditLog';

    public getAuditLog = (params: GetAuditLogParams): ApiResponse<AuditLogAPIViewModel[]> => {
        return api().get(this.route, { params });
    };
}
