import { z } from 'zod';
import { CompanyCustomDatasetColumnType } from '../../../types';
import { MessageType, BatchType } from '../../../types/Enums';

export interface CompanyCustomDatasetStructureViewModel {
    Id: number;
    CompanyId: number | null;
    DatasetName: string;
    MessageType: MessageType;
    MessageType_US: string;
    CanBulkUpload: boolean;
    TemplateCsvURL: string;
    Description: string;
    RowVersion: string;
    LastUpdateDate: string;
    BatchType: BatchType;
    BatchType_US: string;
    CompanyCustomDatasetStructureColumns: CompanyCustomDatasetStructureColumnViewModel[];
}

export interface CompanyCustomDatasetStructureColumnViewModel {
    Id: number | null;
    CompanyCustomDatasetStructureId: number;
    FriendlyColumnName: string;
    InternalColumnName: string;
    ColumnType: CompanyCustomDatasetColumnType;
    ColumnType_US: string;
    EnumColumnValues: string;
    Required: boolean;
    AllowMultiSelect: boolean;
}

export const CompanyCustomDatasetStructureColumnSchema = z
    .object({
        Id: z.number().int(),
        CompanyCustomDatasetStructureId: z.number().int().optional(), // not present for new data structure
        FriendlyColumnName: z.string().trim().min(1, 'Friendly column name cannot be blank'),
        InternalColumnName: z.string().trim().min(1, 'Internal column name cannot be blank'),
        ColumnType: z.nativeEnum(CompanyCustomDatasetColumnType),
        EnumColumnValues: z.string().trim().optional(), // TODO: add refinement to require comma-separated values
        Required: z.boolean(),
        AllowMultiSelect: z.boolean(),
    })
    .superRefine(({ ColumnType, EnumColumnValues }, ctx) => {
        if (ColumnType === CompanyCustomDatasetColumnType.CustomEnum && !EnumColumnValues) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Enum column values are required for enum columns',
            });
        }
    });

export const CompanyCustomDatasetStructureSchema = z.object({
    Id: z.number().int().optional(),
    CompanyId: z.number().int().optional().nullable(),
    DatasetName: z.string().trim().min(1, 'Dataset name cannot be blank'),
    CanBulkUpload: z.boolean(),
    Description: z.string().trim().min(1, 'Description cannot be blank'),
    BatchType: z.nativeEnum(BatchType),
    MessageType: z.nativeEnum(MessageType),
    TemplateCsvURL: z.string().trim().optional(),
    CompanyCustomDatasetStructureColumns: z
        .array(CompanyCustomDatasetStructureColumnSchema)
        .min(1, 'At least one column is required'),
});

export type CompanyCustomDatasetStructureFormViewModel = z.infer<typeof CompanyCustomDatasetStructureSchema>;

export type CompanyCustomDatasetStructureColumnFormViewModel = z.infer<
    typeof CompanyCustomDatasetStructureColumnSchema
>;
