import { useState } from 'react';
import { ICRUDService } from '../types';

/**
 * Returns the current form state and observer function to pass to the `CRUDTemplate`.
 *
 * This is used in conjunction with the `onFormStateChange` prop when you need to access
 * the current form state to load other dependencies (such as dropdown options).
 *
 * @param service the CRUDService instance being used.
 */
export function useCRUDFormObserver<ID extends any, U extends object, T extends ICRUDService<ID, U>>(
    service: T & ICRUDService<ID, U>
) {
    const [formState, onFormStateChange] = useState(service.defaultState);
    return { formState, onFormStateChange };
}
