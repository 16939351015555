import { jsXMLObject } from '../../../utils';
import { DotNotationFieldRendererViewModel } from '../../../reusableFeatures';
import { IVendorSiteSearchGrid, IVendorSite, IVendorSiteData } from './types';
import { VendorSiteApi, IVendorSiteViewModel } from '../Api';
import { portalUserService } from '../../UserService';
import { CompanyApi, ICompanyViewModel } from '../../Company/Api';
import { validateData } from '../../../ui';
import { IVendorViewModel, VendorApi } from '../../Vendor/Api';
import { ProfileApi } from '../../Profile/Api';
import { ICountry } from '../../Common';

function convertToVendorSite(data: IVendorSiteViewModel): IVendorSite {
    return {
        id: data.Id,
        vendorId: data.VendorId,
        siteNumber: data.SiteNumber ?? '',
        siteName: data.SiteName ?? '',
        address1: data.Address1 ?? '',
        address2: data.Address2 ?? '',
        address3: data.Address3 ?? '',
        city: data.City ?? '',
        state: data.State ?? '',
        postalCode: data.PostalCode ?? '',
        country: data.Country ?? '',
        contactName: data.ContactName ?? '',
        phone: data.Phone ?? '',
        fax: data.Fax ?? '',
        email: data.Email ?? '',
        attributes: DotNotationFieldRendererViewModel.fromXMLBasedObject(data.Attributes, {}),
        supplierCompanyId: data.SupplierCompanyId ?? undefined,
        salesProfileId: data.SalesProfileId ?? undefined,
    };
}

function convertToVendorSiteViewModel(data: IVendorSiteData & { vendorId: number }): Partial<IVendorSiteViewModel> {
    return {
        VendorId: data.vendorId,
        SiteNumber: data.siteNumber,
        SiteName: data.siteName,
        Address1: data.address1,
        Address2: data.address2,
        Address3: data.address3,
        City: data.city,
        State: data.state,
        PostalCode: data.postalCode,
        Country: data.country,
        ContactName: data.contactName,
        Phone: data.phone,
        Fax: data.fax,
        Email: data.email,
        Attributes: data.attributes.state,
        SupplierCompanyId: data.supplierCompanyId,
        SalesProfileId: data.salesProfileId,
    };
}

export class VendorSiteService {
    private api = new VendorSiteApi();

    private vendorApi = new VendorApi();

    private companyApi = new CompanyApi();

    private profileApi = new ProfileApi();

    public getVendorSites = async (vendorId: number, supplierId: number | null): Promise<IVendorSiteSearchGrid> => {
        const params = {
            vendorId,
        };

        const response = await this.api.getVendorSites(params);

        const items = response.data.Items;

        return {
            count: items.length,
            items: items.map((item) => ({
                ...convertToVendorSite(item),
                isConnectedToSupplier: item.SupplierCompanyId === supplierId && supplierId ? 'Yes' : 'No',
            })),
        };
    };

    public getVendorSite = async (vendorSiteId: number): Promise<IVendorSite | null> => {
        const response = await this.api.getVendorSite(vendorSiteId);

        if (!response.data.length) {
            return null;
        }

        return convertToVendorSite(response.data[0]);
    };

    private validateVendorSite = (vendorSite: IVendorSiteData, countries: ICountry[]) => {
        validateData((errors) => {
            if (!vendorSite.siteName) {
                errors.push('Vendor Site Name is required');
            }
            if (!vendorSite.siteNumber) {
                errors.push('Vendor Site Number is required');
            }
            if (!vendorSite.country) {
                errors.push('Country is required');
            }
            if (!vendorSite.address1) {
                errors.push('Address Line 1 is required');
            }
            const hasState =
                (countries.find((country) => country.Code === vendorSite.country)?.StateCodes.length ?? 0) > 0;
            if (hasState && !vendorSite.state) {
                errors.push('State is required');
            }
            if (!vendorSite.city) {
                errors.push('City is required');
            }
            if (!vendorSite.postalCode) {
                errors.push('Postal Code is required');
            } else if (
                vendorSite.country === 'USA' &&
                vendorSite.postalCode.length !== 5 &&
                vendorSite.postalCode.length !== 10
            ) {
                errors.push('Postal Code is invalid');
            }
            if (!vendorSite.contactName) {
                errors.push('Contact Name is required');
            }
            if (!vendorSite.email) {
                errors.push('Contact Email is required');
            }
            if (!vendorSite.phone) {
                errors.push('Contact Phone Number is required');
            }
        });
    };

    public updateVendorSite = async (
        vendorId: number,
        vendorSiteId: number,
        vendorSite: IVendorSiteData,
        countries: ICountry[]
    ): Promise<void> => {
        this.validateVendorSite(vendorSite, countries);

        await this.api.updateVendorSite(vendorSiteId, {
            CompanyId: portalUserService.getCurrentCompanyId(),
            ...convertToVendorSiteViewModel({
                vendorId,
                ...vendorSite,
            }),
        });
    };

    private getRootElementOfAttributes = (company: ICompanyViewModel) => {
        if (!company.Attributes) {
            return null;
        }

        return jsXMLObject.getRootElementOf(company.Attributes);
    };

    private createSupplier = async (vendor: IVendorViewModel, vendorSiteId: number, vendorSite: IVendorSiteData) => {
        const company = (await this.companyApi.getCompany(vendor.CompanyId)).data[0];
        const root = this.getRootElementOfAttributes(company);

        const companyProfile = (await this.profileApi.searchProfiles(2, vendor.CompanyId)).data[0];

        const { ProfileExtendedDatas } = companyProfile;

        const getAttribute = (attribute: string) =>
            ProfileExtendedDatas.find((data: any) => data.Name === attribute)?.Value ?? '';

        const profile = {
            CompanyUserProfiles: [
                {
                    VendorsCustomerNumber: vendor.CustomerNumber ?? '',
                    VendorClass: vendor.VendorClass,
                    VendorNumber: vendor.VendorNumber,
                    VendorOrgUnit: vendor.OrganizationCode ?? '',
                    VendorSiteId: vendorSiteId,
                    Company: {
                        IsSupplier: true,
                        CreditStatus: 1,
                        Name: vendor.VendorName,
                        Email: vendorSite.email,
                        Phone: vendorSite.phone,
                        Address1: vendorSite.address1,
                        Address2: vendorSite.address2,
                        Address3: vendorSite.address3,
                        Address4: '',
                        City: vendorSite.city,
                        PostalCode: vendorSite.postalCode,
                        State: vendorSite.state,
                        Country: vendorSite.country,
                        DDOfferAcceptanceMode: 0,
                        OnboardingStatus: 2,
                        DefaultLanguage: 'en-US',
                        BillingMethod: 1,
                        BillingPlan: 1,
                        DocumentReleaseSchedule: 1,
                        DocumentReleaseTime: new Date(1900, 1, 1, 20, 0, 0),
                        InvoiceConfirmationSchedule: 1,
                        RemittanceAdviceSchedule: 1,
                        SupplierDefaultPaymentType: 3,
                        DefaultPostFormat: 1,
                        AllowMarketingMaterialDefault: false,
                        SupplierDefaultEmailGroupingEnabled: true,
                        SupplierDefaultEmailGroupingMethod: 2,
                        SupplierDefaultElectronicGroupingEnabled: false,
                        ReleaseTime1: new Date(1900, 1, 1, 20, 0, 0),
                        ReleaseTime2: null,
                        SupplierDefaultGroupReleaseSchedule: 2,
                        SupplierFaxGroupingEnabled: true,
                        //Only legacy vendors need universal send.  Just about all new vendors will be Buyer Only
                        HasUniversalSend: false,
                        DefaultInvoiceDeliverySetting: 1,
                        SupplierPostGroupingEnabled: true,
                        PostCoverPage: true,
                        PostDuplexPrinting: false,
                        PostEnvelope: 2,
                        SupplierMaxEmailSize: 1024, // Defaults to 1mb during registration
                        RemittanceAdviceType: 2,
                        DefaultInvoiceDeliveryMethod: 2,
                        RegistrationStatus: 2,
                        SupplierIsScrapingEmail: false,
                        SupplierIsScrapingFax: false,
                        SupplierIsScrapingPost: false,
                        BillingPeriodStartDay: 1,
                        BillingStartDate: null,
                        SupplierDocumentBatchConfirmations: true,
                        InvoiceReleaseScheduled: true,
                        Fax: vendorSite.fax,
                        TIN: '',
                        'company.OsnStatus': 1,
                        MiscInfoXML: `<root><RegistrationType>Instant</RegistrationType><RegistrationCampaign>Default</RegistrationCampaign></root>`,
                        InitialActivationTime: new Date(),
                        SupportWatch: 1,
                        NumberOfDaysToArchive: 60,
                        DefaultInvoiceSubmissionMethod: 2,
                        Contacts: [
                            {
                                Name: vendorSite.contactName,
                                Email: vendorSite.email,
                                Fax: vendorSite.fax,
                                Phone: vendorSite.phone,
                                Address1: vendorSite.address1,
                                Address2: vendorSite.address2,
                                Address3: vendorSite.address3,
                                Address4: '',
                                City: vendorSite.city,
                                PostalCode: vendorSite.postalCode,
                                State: vendorSite.state,
                                Country: vendorSite.country,
                                Type: 1,
                            },
                        ],
                    },
                    User: {
                        Email: vendorSite.email,
                        Phone: vendorSite.phone,
                        Name: vendorSite.contactName,
                        ShowInvoiceSubmittedPopupInSupplierPortal: true,
                        SubstituteUserActive: false,
                        UserRoles: [],
                    },
                    Profiles: [
                        {
                            ProfileType: 4,
                            Name: vendor.VendorName,
                            InvoiceConfirmationEmail: vendorSite.email,
                            RemittanceAdviceEmail: vendorSite.email,
                            Currency: 1,
                            Address1: vendorSite.address1,
                            Address2: vendorSite.address2,
                            Address3: vendorSite.address3,
                            City: vendorSite.city,
                            State: vendorSite.state,
                            PostalCode: vendorSite.postalCode,
                            Country: vendorSite.country,
                            PODeliveryMethodStatus: 2,
                            Phone: vendorSite.phone,
                            Fax: vendorSite.fax,
                            Email: vendorSite.email,
                            RuleStatus: 1,
                            ProfileDeliveryMethods: [
                                {
                                    Name: '',
                                    AddressLine1: '',
                                    AddressLine2: '',
                                    AddressLine3: '',
                                    City: '',
                                    PostalCode: '',
                                    Country: '',
                                    State: '',
                                    EmailAddress: vendorSite.email,
                                    FaxNumber: '',
                                    ProcessingMode: 1,
                                    Enabled: true,
                                    DeliveryMethod: 2,
                                    WhenUsed: 1,
                                    GroupBy: 1,
                                    GroupReleaseSchedule: 1,
                                    BusinessDocType: 2,
                                    ProfileDeliveryFileTypes: [
                                        {
                                            GroupingMethod: 2,
                                            IsEnabled: true,
                                            FileType: 1,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            ProfileType: 6,
                            Name: vendor.VendorName,
                            InvoiceConfirmationEmail: vendorSite.email,
                            RemittanceAdviceEmail: vendorSite.email,
                            Currency: 1,
                            Address1: vendorSite.address1,
                            Address2: vendorSite.address2,
                            Address3: vendorSite.address3,
                            City: vendorSite.city,
                            State: vendorSite.state,
                            PostalCode: vendorSite.postalCode,
                            Country: vendorSite.country,
                            PODeliveryMethodStatus: 2,
                            Phone: vendorSite.phone,
                            Fax: vendorSite.fax,
                            Email: vendorSite.email,
                            RuleStatus: 1,
                        },
                        {
                            ProfileType: 1,
                            Name: vendor.VendorName,
                            InvoiceConfirmationEmail: vendorSite.email,
                            RemittanceAdviceEmail: vendorSite.email,
                            Currency: 1,
                            Address1: vendorSite.address1,
                            Address2: vendorSite.address2,
                            Address3: vendorSite.address3,
                            City: vendorSite.city,
                            State: vendorSite.state,
                            PostalCode: vendorSite.postalCode,
                            Country: vendorSite.country,
                            PODeliveryMethodStatus: 2,
                            Phone: vendorSite.phone,
                            Fax: vendorSite.fax,
                            Email: vendorSite.email,
                            RuleStatus: 1,
                        },
                    ],
                },
            ],
            CommaSeparatedDocumentTypes: root?.Salesforce?.CommaSeparatedDocumentTypes ?? '',
            BuyersNameInSalesforce: getAttribute('BuyersNameInSalesforce'),
            CampaignName: getAttribute('CampaignName'),
            CampaignPhase: getAttribute('CampaignPhaseForVendorFeed'),
            SalesforceGroupName: root?.Salesforce?.SalesforceGroupName ?? '',
            BuyerCompanyId: company.ID,
        };
        await this.companyApi.postCompanyUserProfile(profile, root?.Salesforce != null, true);
    };

    private associateVendorSiteWithSupplier = async (
        vendorId: number,
        vendorSiteId: number,
        vendorSite: IVendorSiteData
    ) => {
        const vendorSites = await this.getVendorSites(vendorId, vendorSite.supplierCompanyId ?? null);
        const vendor = await this.vendorApi.getVendor(vendorId);

        if (vendorSites.items.length === 1) {
            if (!vendor.data) {
                throw new Error(`Vendor with vendorId: ${vendorId} not found`);
            }

            await this.createSupplier(vendor.data, vendorSiteId, vendorSite);
        }
    };

    public createVendorSite = async (
        vendorId: number,
        vendorSite: IVendorSiteData,
        countries: ICountry[]
    ): Promise<number> => {
        this.validateVendorSite(vendorSite, countries);

        const response = await this.api.createVendorSite({
            // User's cannot create vendor sites if the vendor is linked to a child company,
            // this means they're only able to create vendor sites for vendors linked to their own company
            CompanyId: portalUserService.getCurrentCompanyId(),
            ...convertToVendorSiteViewModel({
                vendorId,
                ...vendorSite,
            }),
        });

        this.associateVendorSiteWithSupplier(vendorId, response.data.ID, vendorSite);

        return response.data.ID;
    };

    static defaultCRUDFields: IVendorSiteData = {
        siteNumber: '',
        siteName: '',
        address1: '',
        address2: '',
        address3: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        contactName: '',
        email: '',
        phone: '',
        fax: '',
        attributes: DotNotationFieldRendererViewModel.fromXMLBasedObject(null, {}),
    };
}
