import { IFieldType, createFieldTypeFactory } from '../infrastructure';

/**
 * A ICurrencyField field object.
 */
export interface ICurrencyField extends IFieldType<number | null> {
    type: 'ICurrencyField';

    locale: string;

    currencyCode: string;

    maxValue?: number;
}

/**
 * Creates a ICurrencyField field object.
 */
export const createCurrencyField = createFieldTypeFactory<ICurrencyField>('ICurrencyField', null);
