import { FC, useEffect, useMemo, useState } from 'react';
import { QuestionMark } from '@mui/icons-material';
import { Box, Divider, Fab, Stack } from '@mui/material';
import { Button, Dialog } from '../../ui';
import { useHistory } from 'react-router';
import { useRemark } from 'react-remark';
import useButtonStyles from '../../ui/Button/useButtonStyles';
import { useQuery } from '@tanstack/react-query';
import { backendServices, IPortalUser, portalUserService } from '../../services';
import Link from '@mui/material/Link';

interface ContentProps {
    markdown: string | null;
    videos: string[] | null;
}

interface HelpProps {
    content: string | null;
}

const MarkdownHelp: FC<HelpProps> = ({ content }) => {
    const [reactContent, setMarkdownSource] = useRemark();

    useEffect(() => {
        if (!content) {
            return;
        }
        setMarkdownSource(content);
    }, [content, setMarkdownSource]);

    if (!content) {
        return null;
    }

    return (
        <Box
            sx={{
                '& ul': {
                    listStyle: 'disc',
                    ml: '1.5rem',
                },
            }}
            data-testid="need-help-markdown"
        >
            {reactContent}
        </Box>
    );
};

const VideoHelp: FC<HelpProps> = ({ content }) => {
    if (!content) {
        return null;
    }

    return (
        <Box sx={{ width: 560, height: 320 }}>
            <iframe
                width="560"
                height="315"
                src={content}
                frameBorder="0"
                allow="fullscreen"
                allowFullScreen
                data-testid="need-help-video"
            ></iframe>
        </Box>
    );
};

const Help: FC<ContentProps> = ({ markdown, videos }) => {
    const videoCount = useMemo(() => videos?.length ?? 0, [videos]);

    return (
        <>
            <MarkdownHelp content={markdown} />
            {markdown && videoCount ? <Divider sx={{ my: 2 }} /> : null}
            <Stack
                direction="row"
                gap={2}
                sx={{ overflowY: 'auto', width: '100%', justifyContent: 'safe center' }}
                data-testid="need-help-videos-container"
            >
                {videos?.map((video) => (
                    <VideoHelp key={video} content={video} />
                ))}
            </Stack>
            <Divider sx={{ mt: 2 }} />
            <div data-testid="need-help-dialog">
                <h4>Still need help?</h4>
                <p>
                    Don't hesitate to reach out to our support team at{' '}
                    <Link href="https://support.transcepta.com" target="_blank">
                        support.transcepta.com
                    </Link>{' '}
                    if you need further assistance.
                </p>
            </div>
        </>
    );
};

export const HelpButton: FC = () => {
    const [loggedInUser, setLoggedInUser] = useState<IPortalUser | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [help, setHelp] = useState<backendServices.Apis.HelpContentViewModel | undefined>(undefined);
    const history = useHistory();
    const styles = useButtonStyles();
    const api = new backendServices.Apis.HelpApi();

    useEffect(() => {
        portalUserService.subscribe((user) => {
            setLoggedInUser(user);
        });
    }, []);

    const query = useQuery(['HelpContent'], () => api.getAllHelpContent(), {
        staleTime: Infinity,
        enabled: !!loggedInUser,
    });

    useEffect(() => {
        const unlisten = history.listen((location) => {
            setHelp(undefined);
            query.data?.data.forEach((item) => {
                const regex = new RegExp(item.Url.toLowerCase(), 'i');

                if (regex.test(location.pathname.toLowerCase())) {
                    setHelp(item);
                    return;
                }
            });
        });

        return () => {
            unlisten();
        };
    }, [help?.Url, history, query.data]);

    if (!help) {
        return null;
    }

    if (help.Video == null && help.Markdown == null) {
        return null;
    }

    return (
        <>
            <Fab
                variant="extended"
                color="primary"
                sx={{ position: 'fixed', bottom: 24, left: 24, zIndex: 99 }}
                className={styles.containedPrimary}
                onClick={() => setOpen(true)}
                data-testid="need-help-floating-message"
            >
                Help
                <Box
                    sx={{
                        bgcolor: 'primary.700',
                        borderRadius: 50,
                        p: 1.5,
                        ml: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <QuestionMark sx={{ fontSize: 'large', color: 'white' }} />
                </Box>
            </Fab>
            <Dialog
                open={open}
                handleOnClose={() => setOpen(false)}
                title={<Box data-testid="need-help-dialog-title">{help.Name}</Box>}
                content={<Help markdown={help.Markdown} videos={help.Video?.split(',') ?? null} />}
                actions={
                    <Button data-testid="need-help-close-button" onClick={() => setOpen(false)}>
                        Close
                    </Button>
                }
            />
        </>
    );
};
