import { AxiosResponse, Method, AxiosRequestHeaders, AxiosResponseHeaders } from 'axios';
import {
    CypressRequestCollector,
    cypressCollectedRequestMethods,
    CypressCollectedRequestMethod,
    CypressCollectedHeaders,
} from '../mockGenerator/CypressRequestCollector';

function isCollectedMethod(method: Method | undefined): method is CypressCollectedRequestMethod {
    return cypressCollectedRequestMethods.includes(method as any);
}

function transformHeaders(headers: AxiosRequestHeaders | AxiosResponseHeaders, ignoredHeaders: Set<string>) {
    const transformedHeaders: any = {};

    Object.keys(headers).forEach((key) => {
        if (ignoredHeaders.has(key.toLowerCase())) {
            /* ignore */
        } else if (key === 'set-cookie') {
            transformedHeaders[key] = headers[key];
        } else {
            transformedHeaders[key] = headers[key].toString();
        }
    });

    return transformedHeaders as CypressCollectedHeaders;
}

function removeParamsFromUrl(url: string) {
    if (url.indexOf('?') === -1) {
        return url;
    }

    return url.substring(0, url.indexOf('?'));
}

function getParamsFromUrl(url: string): any {
    if (url.indexOf('?') === -1) {
        return {};
    }

    const query = url.substring(url.indexOf('?') + 1);
    const params = query.split('&');
    const obj: any = {};
    params.forEach((param) => {
        const [k, v] = param.split('=');
        obj[decodeURIComponent(k)] = decodeURIComponent(v);
    });

    return obj;
}

function transformParams(params: any) {
    const obj: any = {};

    Object.keys(params).forEach((key) => {
        const value = params[key];

        if (value == null) {
            return;
        }

        obj[key] = value.toString();
    });

    return obj;
}

function addPrefix(prefix: string, url: string) {
    if (url.startsWith('/')) {
        url = url.substring(1);
    }

    if (prefix.endsWith('/')) {
        prefix = prefix.substring(0, prefix.length - 1);
    }

    return `${prefix}/${url}`;
}

export class CypressAxiosInterceptor {
    private ignoredHeaders = new Set<string>();

    constructor(private requestCollector: CypressRequestCollector, private prefix: string) {}

    ignoreHeader(header: string) {
        this.ignoredHeaders.add(header);
    }

    handleResponse(response: AxiosResponse<any, any>) {
        const request = response.config;

        let { method = 'GET', url, params = {} } = request;
        method = method.toUpperCase() as Method;

        const { headers = {}, data = null } = request;

        if (!isCollectedMethod(method)) {
            return;
        }

        if (!url) {
            return;
        }

        params = transformParams({
            ...params,
            ...getParamsFromUrl(url),
        });
        url = removeParamsFromUrl(url);
        url = addPrefix(this.prefix, url);

        this.requestCollector.collectRequest({
            request: {
                method,
                url,
                headers: transformHeaders(headers, this.ignoredHeaders),
                queryParams: params,
                body: data,
            },
            response: {
                statusCode: response.status,
                headers: transformHeaders(response.headers, this.ignoredHeaders),
                body: response.data ?? null,
            },
        });
    }
}
