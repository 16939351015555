import BigNumber from 'bignumber.js';
import jsonpath from 'jsonpath';
import { path } from 'ramda';

import { IsUndefinedNullOrEmpty, GetEnumNameFromValue } from 'common/build/legacy/transcepta-common';
import { CreateAddressBlock } from '../address';
import projectConfig from '../../project.config.json';
import { strings } from '../localization/LocalizationStrings';

export const getOtherCompanyName = (document, userCompanyId) => {
    let otherCompanyName = '';

    if (document && document.CommonFields) {
        const { SenderCompanyId, SenderCompanyName, ReceiverCompanyId, ReceiverCompanyName } = document.CommonFields;

        if (ReceiverCompanyName && ReceiverCompanyId && ReceiverCompanyId !== userCompanyId) {
            otherCompanyName = ReceiverCompanyName;
        } else if (SenderCompanyName && SenderCompanyId && SenderCompanyId !== userCompanyId) {
            otherCompanyName = SenderCompanyName;
        }
    }

    return otherCompanyName;
};

export const getDefaultRecipient = (document, userCompanyId) => {
    let recipientEmail = '';
    if (document && document.CommonFields) {
        const { SenderCompanyId, SenderConfirmationEmail, ReceiverCompanyId, ReceiverConfirmationEmail } =
            document.CommonFields;

        if (ReceiverConfirmationEmail && ReceiverCompanyId && ReceiverCompanyId !== userCompanyId) {
            recipientEmail = ReceiverConfirmationEmail;
        } else if (SenderConfirmationEmail && SenderCompanyId && SenderCompanyId !== userCompanyId) {
            recipientEmail = SenderConfirmationEmail;
        }
    }

    return recipientEmail;
};

// new logic the use proper company id for trading partners call or possible other calls for buyer vs supplier company ids.
// In most cases uses the document sender and receiver profile types to determine if it we should use the
// sender or receiver company id for api calls. Most likely trading partner
export const getCompanyIdByProfile = (senderProfileType, receiverProfileType, senderId, receiverId) => {
    if (
        senderProfileType === projectConfig.profileType.BuyerDestination ||
        senderProfileType === projectConfig.profileType.Procurement ||
        senderProfileType === projectConfig.profileType.BuyerWarehouse
    ) {
        return senderId;
    }
    if (
        receiverProfileType === projectConfig.profileType.BuyerDestination ||
        receiverProfileType === projectConfig.profileType.Procurement ||
        receiverProfileType === projectConfig.profileType.BuyerWarehouse
    ) {
        return receiverId;
    }
    return null;
};

// TODO: There are other places doing this in a better way. We should update places that are using this no longer do so.
export const getLocalizedBusDocTypeLabel = (businessDocTypePathName, businessDocType) => {
    let labelIndex;
    if (businessDocType) {
        labelIndex = businessDocType.toString();
    } else if (businessDocTypePathName === 'po-acknowledgments') {
        labelIndex = projectConfig.businessDocType.PurchaseOrderAcknowledgement.toString();
    } else {
        labelIndex = Object.keys(projectConfig.businessDocTypeStateName).find(
            (key) => projectConfig.businessDocTypeStateName[key] === businessDocTypePathName
        );
    }

    switch (labelIndex) {
        case projectConfig.businessDocType.Invoice.toString():
            return strings.documentNameSingleInvoice;
        case projectConfig.businessDocType.PurchaseOrder.toString():
            return strings.documentNameSinglePurchaseOrder;
        case projectConfig.businessDocType.PurchaseOrderAcknowledgement.toString():
            return strings.documentNameSinglePurchaseOrderAcknowledgement;
        case projectConfig.businessDocType.ASN.toString():
            return strings.documentNameSingleASN;
        case projectConfig.businessDocType.InventoryAdvice.toString():
            return strings.documentNameSingleInventoryAdvice;
        case projectConfig.businessDocType.ReceivingAdvice.toString():
            return strings.documentNameSingleReceivingAdvice;
        case projectConfig.businessDocType.ConsumptionAdvice.toString():
            return strings.documentNameSingleConsumptionAdvice;
        case projectConfig.businessDocType.ASNGeneric.toString():
            return strings.documentNameSingleASN;
        case projectConfig.businessDocType.SIMDocument.toString():
            return strings.documentNameSingleSIMDocument;
        default:
            return '';
    }
};

export const getBusinessDocTypeFromPathName = (businessDocTypePathName) => {
    const businessDocTypeEnumName = GetEnumNameFromValue(
        projectConfig.businessDocTypePathName,
        businessDocTypePathName
    );
    return projectConfig.businessDocType[businessDocTypeEnumName];
};

export const getBusinessDocTypePathNameFromType = (businessDocType) => {
    const businessDocTypeEnumName = GetEnumNameFromValue(projectConfig.businessDocType, businessDocType);
    return projectConfig.businessDocTypePathName[businessDocTypeEnumName];
};

export const determineLineItemSkeleton = (layout, fullSkeleton) => {
    const bodyJsonPath = `$${layout.Large.Body[0].SectionJSONPath}`;
    const lineItemSkeleton = jsonpath.query(fullSkeleton, bodyJsonPath)[0];

    return lineItemSkeleton[0];
};

export const determineFieldValue = (field, documentData, sectionJsonPath, cellJsonPath, celljsonPaths = null) => {
    if (!documentData) return '';
    const { JSONPath, FieldName } = field;
    const businessDocType = documentData.BusinessDocType;
    if (!sectionJsonPath) {
        sectionJsonPath = field.sectionJsonPath;
    }
    if (!cellJsonPath) {
        cellJsonPath = field.cellJsonPath;
    }

    let businessDocFields = documentData.BusinessDocFields;
    let value = [];

    let currSectionPath;
    let currCellPath = '';
    let path;
    currSectionPath = sectionJsonPath;
    try {
        value = jsonpath.query(businessDocFields, `$${currSectionPath}`);
        if (value && value.length > 0 && value[0].length > 1) {
            for (var i in value[0]) {
                currSectionPath =
                    businessDocType > 2
                        ? `${sectionJsonPath}[?(@.@id =='${value[0][i]['@id']}')]`
                        : `${sectionJsonPath}[?(@.Id =='${value[0][i].Id}')]`;
            }
        }
        if (celljsonPaths) {
            for (i in celljsonPaths) {
                if (i === 0) currCellPath = celljsonPaths[i];
                else currCellPath += `.${celljsonPaths[i]}`;

                value = jsonpath.query(businessDocFields, `$${currSectionPath}.${currCellPath}`);
                if (value && value.length > 1) {
                    for (i in value) {
                        currCellPath =
                            businessDocType > 2
                                ? `${currCellPath}[?(@.@id =='${value[i]['@id']}')]`
                                : `${currCellPath}[?(@.Id =='${value[i].Id}')]`;
                    }
                }
            }
        }
        if (cellJsonPath) {
            if (currCellPath && currCellPath.length > 0) currCellPath += `.${cellJsonPath}`;
            else currCellPath = cellJsonPath;
        }

        if (currCellPath) {
            value = jsonpath.query(businessDocFields, `$${currSectionPath}.${currCellPath}`);
            if ((!value || !value.length || value.length === 0) && currCellPath.includes('[0]')) {
                value = jsonpath.query(businessDocFields, `$${currSectionPath}.${currCellPath}`);
            }
            const uniqueCellNodes = value && value.length > 0 && Array.isArray(value[0]) ? value[0] : value;
            if (uniqueCellNodes && uniqueCellNodes.length > 0) {
                if (uniqueCellNodes.length > 1 && (uniqueCellNodes[0]['@id'] || uniqueCellNodes[0].Id)) {
                    currCellPath =
                        businessDocType > 3
                            ? `${cellJsonPath}[?(@.@id =='${uniqueCellNodes[0]['@id']}')]`
                            : `${cellJsonPath}[?(@.Id =='${uniqueCellNodes[0].Id}')]`;
                } else if (uniqueCellNodes && uniqueCellNodes.length > 1 && Array.isArray(uniqueCellNodes)) {
                    currCellPath = `${cellJsonPath}[${0}]`;
                }
            }
        }

        if (currSectionPath) path = currSectionPath;
        if (businessDocFields.BusinessDocument.Document) {
            businessDocFields = businessDocFields.BusinessDocument.Document;
        }
        if (currCellPath) {
            if (path.length > 0) path += `.${currCellPath}`;
            else path = currCellPath;
        }
        if (JSONPath) {
            if (path.length > 0) path += `.${JSONPath}`;
            else path = JSONPath;
        }

        const fieldName = path || FieldName;
        const commonFields = documentData.CommonFields || {};

        if (JSONPath) value = jsonpath.query(businessDocFields, `$${path.trim()}`);

        return value && value.length >= 1
            ? value[0]
            : commonFields[`${fieldName}_US`]
            ? commonFields[`${fieldName}_US`]
            : commonFields[fieldName]
            ? commonFields[fieldName]
            : '';
    } catch (thrownValue) {
        // Error is currently being ignored.
        // console.log('exception');
    }
};

export const removeBlankDocumentLines = (layout, document, isInvoice) => {
    const bodyLayout = layout.Large.Body;
    const bodyJsonPath = `$${bodyLayout[0].SectionJSONPath}`;

    const uniqueSectionNodes = jsonpath.nodes(document, bodyJsonPath)[0].value;
    let remainingLineCount = uniqueSectionNodes.length;

    for (
        let sectionNodeIndex = uniqueSectionNodes.length - 1;
        sectionNodeIndex >= 0 && remainingLineCount > 1;
        sectionNodeIndex--
    ) {
        const sectionNode = uniqueSectionNodes[sectionNodeIndex];
        if (documentLineIsBlank(bodyLayout, sectionNode)) {
            uniqueSectionNodes.splice(sectionNodeIndex, 1);
            // Generic doc types do not use an array for their lines when there is only one line.
            // Therefore if this is not an invoice, we must convert from an array to a single object if there will be only one line remaining.
            if (!isInvoice && uniqueSectionNodes.length === 1) {
                jsonpath.value(document, bodyJsonPath, uniqueSectionNodes[0]);
            }
            remainingLineCount -= 1;
        }
    }
};

const documentLineIsBlank = (bodyLayout, bodySectionNode) => {
    const excludedFieldDisplayHints = ['LineNumber'];
    const numericFields = [
        projectConfig.ControlType.Money,
        projectConfig.ControlType.Quantity,
        projectConfig.ControlType.UnitPrice,
        projectConfig.ControlType.Tax,
        projectConfig.ControlType.UnitAmountAndAmount,
    ];

    const excludedFieldTypes = [
        projectConfig.ControlType.GLCodingDotNotation,
        projectConfig.ControlType.GLCodingInline,
    ];

    for (let cellIndex = 0; cellIndex < bodyLayout.length; cellIndex++) {
        const cellLayout = bodyLayout[cellIndex];
        for (let fieldIndex = 0; fieldIndex < cellLayout.Fields.length; fieldIndex++) {
            const fieldLayout = cellLayout.Fields[fieldIndex];

            if (
                !excludedFieldDisplayHints.includes(fieldLayout.DisplayHints) &&
                !excludedFieldTypes.includes(fieldLayout.ControlType)
            ) {
                const cellAndFieldJSONPath =
                    cellLayout.CellJSONPath && cellLayout.CellJSONPath !== ''
                        ? `$..${cellLayout.CellJSONPath}.${fieldLayout.JSONPath}`
                        : `$..${fieldLayout.JSONPath}`;
                const fieldValue = jsonpath.query(bodySectionNode, cellAndFieldJSONPath)[0];
                if (numericFields.includes(fieldLayout.ControlType)) {
                    if (fieldValue && Number(fieldValue) !== 0) {
                        return false;
                    }
                } else if (fieldValue && fieldValue !== '') {
                    return false;
                }
            }
        }
    }

    return true;
};

export const populateCommonBusinessDocumentFields = (layout, document) => {
    // This will populate the common field on generic documents with the values entered by the user. The common fields should always be set to never be visible because they are
    // really just a second location for values on the document. They are used as a constant between all the generic doc types so we can write queries that will work for any
    // generic doc type. Since they are not visible on the edit form, they must be populated by copying the values entered by the user in corresponding fields that are visible.

    const commonBusinessDocumentFieldNames = [
        'CustomerName',
        'CustomerNumber',
        'DocumentAmount',
        'DocumentDate',
        'DocumentStatus',
        'IdentifyingNumber',
        'ReferenceDocumentIdentifyingNumber',
        'VendorName',
        'VendorNumber',
    ];

    const commonBusinessDocumentDestinationFields = {};
    const commonBusinessDocumentSourceFields = {};
    commonBusinessDocumentFieldNames.forEach((name) => {
        commonBusinessDocumentDestinationFields[name] = null;
        commonBusinessDocumentSourceFields[name] = null;
    });

    // Find the destination and source field layout records
    const largeLayout = layout.Large;
    const sectionNames = ['Header', 'Body', 'Footer'];
    sectionNames.forEach((sectionName) => {
        if (largeLayout[sectionName]) {
            let sectionLayout = largeLayout[sectionName];
            if (!Array.isArray(sectionLayout)) {
                sectionLayout = [sectionLayout];
            }

            sectionLayout.forEach((cellLayout) => {
                // The common fields should always have a section path = '..BusinessDocument' and a cell path that is null or empty
                const couldBeDestination =
                    cellLayout.SectionJSONPath === '..BusinessDocument' &&
                    (!cellLayout.CellJSONPath || cellLayout.CellJSONPath === '');
                cellLayout.Fields.forEach((fieldLayout) => {
                    // If the section and cell paths indicate that this field could be a destination field check to see if the FieldName matches one of the common field names
                    if (couldBeDestination && commonBusinessDocumentFieldNames.includes(fieldLayout.FieldName)) {
                        commonBusinessDocumentDestinationFields[fieldLayout.FieldName] = {
                            field: fieldLayout,
                            sectionJsonPath: cellLayout.SectionJSONPath,
                            cellJsonPath: cellLayout.CellJSONPath,
                        };
                    }

                    // If the field record has a DisplayHint that matches on of the common field names, then it should be used as the source for the common field.
                    if (commonBusinessDocumentFieldNames.includes(fieldLayout.DisplayHints)) {
                        commonBusinessDocumentSourceFields[fieldLayout.DisplayHints] = {
                            field: fieldLayout,
                            sectionJsonPath: cellLayout.SectionJSONPath,
                            cellJsonPath: cellLayout.CellJSONPath,
                        };
                    }
                    // The IdentifyingNumber common field is an exception. The DisplayHints is already used for this field in the old supplier portal as "BusinessDocNumber".
                    // For now, we can't change it. So, we have to have this exception case where we are looking for the IdentifyingNumber source with a display hint of
                    // "BusinessDocNumber".
                    else if (
                        commonBusinessDocumentSourceFields.IdentifyingNumber === null &&
                        fieldLayout.DisplayHints === 'BusinessDocNumber'
                    ) {
                        commonBusinessDocumentSourceFields.IdentifyingNumber = {
                            field: fieldLayout,
                            sectionJsonPath: cellLayout.SectionJSONPath,
                            cellJsonPath: cellLayout.CellJSONPath,
                        };
                    }
                });
            });
        }
    });

    // Copy the source value into the destination if a field layout record exists for both the source and the destination
    commonBusinessDocumentFieldNames.forEach((name) => {
        const source = commonBusinessDocumentSourceFields[name];
        const destination = commonBusinessDocumentDestinationFields[name];
        if (source !== null && destination !== null) {
            const value = determineFieldValue(
                source.field,
                document,
                source.sectionJsonPath,
                source.cellJsonPath,
                null
            );

            const sectionJsonPath = `$${destination.sectionJsonPath}`;

            const uniqueSectionNodes = jsonpath.nodes(document, sectionJsonPath)[0].value;

            let sectionNodes = [];
            if (uniqueSectionNodes) {
                if (uniqueSectionNodes.length > 0) {
                    sectionNodes = [uniqueSectionNodes[0]];
                } else if (!uniqueSectionNodes.length) {
                    sectionNodes = [uniqueSectionNodes];
                } else {
                    sectionNodes = uniqueSectionNodes;
                }
            }

            sectionNodes.forEach((sectionNode) => {
                const uniqueCellNodes = sectionNode;

                let cellNodes = [];
                if (uniqueCellNodes) {
                    if (uniqueCellNodes.length > 0) {
                        cellNodes = [uniqueCellNodes[0]];
                    } else if (!uniqueCellNodes.length) {
                        cellNodes = [uniqueCellNodes];
                    } else {
                        cellNodes = uniqueCellNodes;
                    }
                }

                cellNodes.forEach((cellNode) => {
                    // Update the value in the document
                    jsonpath.value(cellNode, `$..${destination.field.JSONPath}`, value);
                });
            });
        }
    });
};

export const findFieldLayoutRecord = (layout, fieldId) => {
    let field = null;

    // Find the field layout record
    const largeLayout = layout.Large;
    const sectionNames = ['Header', 'Body', 'Footer'];
    // Look for the field in each section
    for (let sectionNameIndex = 0; sectionNameIndex < sectionNames.length && field === null; sectionNameIndex++) {
        const sectionName = sectionNames[sectionNameIndex];
        if (largeLayout[sectionName]) {
            let sectionLayout = largeLayout[sectionName];
            // Some sections will only have one cell so we convert to an array to avoid failure on the next loop
            if (!Array.isArray(sectionLayout)) {
                sectionLayout = [sectionLayout];
            }

            // Look for the field in each cell of the section
            for (let cellLayoutIndex = 0; cellLayoutIndex < sectionLayout.length && field === null; cellLayoutIndex++) {
                const cellLayout = sectionLayout[cellLayoutIndex];
                // Check to see if each field in the cell matches the desired id
                for (
                    let fieldLayoutIndex = 0;
                    fieldLayoutIndex < cellLayout.Fields.length && field === null;
                    fieldLayoutIndex++
                ) {
                    const fieldLayout = cellLayout.Fields[fieldLayoutIndex];
                    // If the field is a match assign it to our output variable. This will trigger each loop to exit since we no longer need to keep searching.
                    if (fieldLayout.FieldDictionaryId === fieldId) {
                        field = fieldLayout;
                    }
                }
            }
        }
    }

    return field;
};

export const populateRequiredDefaults = (businessDocType, document, fieldDefaults, selectedProfile) => {
    if (businessDocType === projectConfig.businessDocType.Invoice) {
        // We only want to save a new customer id to the invoice if there is not one already there. This should prevent the cache from changing the proper customer when editing an invoice that has
        // already been created and saved.
        if (
            !document.BusinessDocFields.Invoice.CustomerID ||
            document.BusinessDocFields.Invoice.CustomerID === fieldDefaults.CustomerID
        ) {
            document.BusinessDocFields.Invoice.CustomerID = fieldDefaults.CustomerID;

            if (!document.BusinessDocFields.Invoice.CustomerNumber) {
                document.BusinessDocFields.Invoice.CustomerNumber = fieldDefaults.CustomerNumber;
            }

            if (!document.BusinessDocFields.Invoice.Name) {
                document.BusinessDocFields.Invoice.Name = fieldDefaults.CustomerName;
            }
        }

        // Populate certain fields with defaults if they are empty
        if (!document.BusinessDocFields.Invoice.VendorNumber) {
            document.BusinessDocFields.Invoice.VendorNumber = fieldDefaults.VendorNumber;
        }

        document.BusinessDocFields.Invoice.InvoiceContacts.forEach((invoiceContact) => {
            // let didNotUseDefaultAddress = true;
            // The Bill to (Buyer) contact uses the selected Customer address as their address block by default
            // if (invoiceContact.ContactType === projectConfig.ContactType.Buyer) {
            //     if (invoiceContact.AddressBlock === fieldDefaults.CustomerAddressBlock) {
            //         //didNotUseDefaultAddress = false;
            //         // The old supplier portal used to parse out the inidividual address fields here, but for the new supplier portal the API will be handling this.
            //     }
            // }
            // The Ship to contact uses the selected Customer ship to address block as their address block by default if it is not null or empty. Otherwise, it uses the selected Customer
            // address as their address block by default.
            // else if (invoiceContact.ContactType === projectConfig.ContactType.ShipTo) {
            //     if (IsUndefinedNullOrEmpty(fieldDefaults.CustomerShipToAddressBlock)) {
            //         if (invoiceContact.AddressBlock === fieldDefaults.CustomerAddressBlock) {
            //             //didNotUseDefaultAddress = false;
            //             // The old supplier portal used to parse out the inidividual address fields here, but for the new supplier portal the API will be handling this.
            //         }
            //     }
            //     else {
            //         if (invoiceContact.AddressBlock === fieldDefaults.CustomerShipToAddressBlock) {
            //             //didNotUseDefaultAddress = false;
            //             // The old supplier portal used to parse out the inidividual address fields here, but for the new supplier portal the API will be handling this.
            //         }
            //     }
            // }
            // The Remit to contact uses the selected Invoice Profile remit to address block for its address block by default if it exists otherwise it uses the selected Invoice Profiles address
            /* else */ if (invoiceContact.ContactType === projectConfig.ContactType.RemitTo) {
                if (selectedProfile !== null) {
                    if (
                        invoiceContact.AddressBlock ===
                        CreateAddressBlock({
                            line1: selectedProfile.Address1,
                            line2: selectedProfile.Address2,
                            line3: selectedProfile.Address3,
                            line4: selectedProfile.Address4,
                            city: selectedProfile.City,
                            state: selectedProfile.State,
                            zip: selectedProfile.PostalCode,
                            country: selectedProfile.Country,
                        })
                    ) {
                        // didNotUseDefaultAddress = false;

                        invoiceContact.Address1 = selectedProfile.Address1;
                        invoiceContact.Address2 = selectedProfile.Address2;
                        invoiceContact.Address3 = selectedProfile.Address3;
                        invoiceContact.Address4 = selectedProfile.Address4;
                        invoiceContact.City = selectedProfile.City;
                        invoiceContact.Country = selectedProfile.Country;
                        invoiceContact.PostalCode = selectedProfile.PostalCode;
                        invoiceContact.State = selectedProfile.State;
                    }
                    // else if (IsNotUndefinedNullOrEmpty(selectedProfile.RemitToAddressBlock) && invoiceContact.AddressBlock === selectedProfile.RemitToAddressBlock) {
                    //    //didNotUseDefaultAddress = false;
                    //    // The old supplier portal used to parse out the inidividual address fields here, but for the new supplier portal the API will be handling this.
                    // }
                }
            }
            // The Vendor contact uses the selected Invoice Profile address as its address block by default
            else if (invoiceContact.ContactType === projectConfig.ContactType.Vendor) {
                if (
                    selectedProfile !== null &&
                    invoiceContact.AddressBlock ===
                        CreateAddressBlock({
                            line1: selectedProfile.Address1,
                            line2: selectedProfile.Address2,
                            line3: selectedProfile.Address3,
                            line4: selectedProfile.Address4,
                            city: selectedProfile.City,
                            state: selectedProfile.State,
                            zip: selectedProfile.PostalCode,
                            country: selectedProfile.Country,
                        })
                ) {
                    // didNotUseDefaultAddress = false;

                    invoiceContact.Address1 = selectedProfile.Address1;
                    invoiceContact.Address2 = selectedProfile.Address2;
                    invoiceContact.Address3 = selectedProfile.Address3;
                    invoiceContact.Address4 = selectedProfile.Address4;
                    invoiceContact.City = selectedProfile.City;
                    invoiceContact.Country = selectedProfile.Country;
                    invoiceContact.PostalCode = selectedProfile.PostalCode;
                    invoiceContact.State = selectedProfile.State;
                }
            }

            // Create the Invoice.BillToAddressBlock from the Customer.Name and bill-to InvoiceContact.AddressBlock
            //
            // NOTE : Becuase we are doing this it would be unadvised to make a field dictionary entry for the Invoice.BillToAddressBlock. It would more than likely cause confusion for the supplier if
            //        they attempted to update one of the two fields. If they updated the Inovice.BillToAddressBlock field, their change would be overwritten. If they updated the bill to
            //        InvoiceContact.AddressBlock field, the Inovice.BillToAddressBlock field would be changed as well.
            if (invoiceContact.ContactType === projectConfig.ContactType.Buyer) {
                if (
                    IsUndefinedNullOrEmpty(fieldDefaults.CustomerName) &&
                    IsUndefinedNullOrEmpty(invoiceContact.AddressBlock)
                ) {
                    document.BusinessDocFields.Invoice.BillToAddressBlock = '';
                } else if (IsUndefinedNullOrEmpty(invoiceContact.AddressBlock)) {
                    document.BusinessDocFields.Invoice.BillToAddressBlock = fieldDefaults.CustomerName;
                } else if (
                    IsUndefinedNullOrEmpty(fieldDefaults.CustomerName) ||
                    invoiceContact.AddressBlock.includes(fieldDefaults.CustomerName)
                ) {
                    document.BusinessDocFields.Invoice.BillToAddressBlock = invoiceContact.AddressBlock;
                } else {
                    document.BusinessDocFields.Invoice.BillToAddressBlock = `${fieldDefaults.CustomerName}\n${invoiceContact.AddressBlock}`;
                }
            }
        });

        // Calculate and populate the discount amount
        //
        // NOTE : Becuase we are doing this it would be unadvised to make a field dictionary entry for the Invoice.DiscountAmount. It would more than likely cause confusion for the supplier if they
        //        attempted to update the field. Also, there is currently no javascript logic to update this field automatically. (The old supplier portal did not display this field.)
        document.BusinessDocFields.Invoice.DiscountAmount = Number(
            (new BigNumber(document.BusinessDocFields.Invoice.DiscountPercent) / new BigNumber(100)) *
                new BigNumber(document.BusinessDocFields.Invoice.DiscountableAmount)
        );
    }
};

export const updateMiscAmountsForSave = (businessDocType, document) => {
    if (businessDocType === projectConfig.businessDocType.Invoice) {
        const invoice = document.BusinessDocFields.Invoice;

        invoice.InvoiceLineItems.forEach((invoiceLineItem) => {
            // Filter out any line item misc amounts that have not been populated to avoid saving blank line item misc amounts.
            const newInvoiceLineItemMiscAmountList = invoiceLineItem.InvoiceLineItemMiscAmounts.filter(
                (invoiceLineItemMiscAmount) => {
                    return (
                        invoiceLineItemMiscAmount.Rate ||
                        invoiceLineItemMiscAmount.UnitPrice ||
                        invoiceLineItemMiscAmount.Amount
                    );
                }
            );
            invoiceLineItem.InvoiceLineItemMiscAmounts = newInvoiceLineItemMiscAmountList;

            // Populate the quantity on line item misc amounts that have a unit price.
            // If we leave the quantity blank standard validation will not be able to calculate properly.
            invoiceLineItem.InvoiceLineItemMiscAmounts.forEach((invoiceLineItemMiscAmount) => {
                if (invoiceLineItemMiscAmount.UnitPrice) {
                    invoiceLineItemMiscAmount.Quantity = invoiceLineItem.Quantity;
                }
            });
        });

        // Filter out any misc amounts that have not been populated to avoid saving blank misc amounts.
        const newInvoiceMiscAmountList = [];
        if (invoice.InvoiceMiscAmounts != null) {
            invoice.InvoiceMiscAmounts.forEach((invoiceMiscAmount) => {
                if (invoiceMiscAmount.Rate || invoiceMiscAmount.Amount) {
                    newInvoiceMiscAmountList.push(invoiceMiscAmount);
                }
            });
        }
        invoice.InvoiceMiscAmounts = newInvoiceMiscAmountList;
    }
};

export const validateLayout = (layout, documentData) => {
    let validationErrors = validateFields(layout.Large.Header, documentData);
    const requiredLineItems = [...new Set(validateFields(layout.Large.Body, documentData))];
    validationErrors = validationErrors.concat(requiredLineItems);
    return validationErrors;
};

export const validateFields = (layout, businessDocFields) => {
    const flattenFields = [];
    layout &&
        layout.length > 0 &&
        layout.map((item) => {
            item.Fields
                ? item.Fields.forEach((field) => {
                      let path = item.SectionJSONPath;
                      if (
                          field.EntityName === projectConfig.EntityName.LineItem ||
                          field.EntityName === projectConfig.EntityName.LineItemMiscAmount ||
                          field.EntityName === projectConfig.EntityName.LineItemShippingDetail
                      ) {
                          path += '[*]';
                      }
                      if (item.CellJSONPath) {
                          path = `${path}.${item.CellJSONPath}`;
                          if (
                              (field.EntityName === projectConfig.EntityName.LineItemShippingDetail ||
                                  field.EntityName === projectConfig.EntityName.ShippingDetail) &&
                              item.CellJSONPath[item.CellJSONPath.length - 1] !== ']'
                          ) {
                              path += '[*]';
                          }
                      }
                      flattenFields.push({
                          Field: field,
                          path,
                          cellTitle: item.CellTitle,
                      });
                  })
                : null;
        });
    const requiredFields = flattenFields.filter((item) => item.Field.Required);
    const validationErrors = [];
    requiredFields.map((field) => {
        const fullFieldPath = `$${field.path}.${field.Field.JSONPath}`;
        const value = jsonpath.query(businessDocFields, fullFieldPath);
        if (value.length === 0 || value.some((x) => x === undefined || x === null || x === '')) {
            validationErrors.push({
                TitleText: `${field.Field.ContactType ? field.cellTitle : ''} ${field.Field.Label.split('&nbsp;').join(
                    ' '
                )} ${strings.textIsRequired}`.trim(),
                DetailText: null,
            });
        }
    });
    return validationErrors;
};

export const cleanContacts = (contactBlock) => {
    const contacts = contactBlock || [];
    contacts.map((contact) => {
        const keys = Object.keys(contact).filter((key) => key !== '@id' && key !== 'ContactType');
        let contactEmpty = true;
        keys.map((key) => {
            contact[key] ? (contactEmpty = false) : delete contact[key];
            contact.isEmpty = contactEmpty;
        });
        return contact;
    });
    return contacts
        .filter((contact) => !contact.isEmpty)
        .map((contact) => {
            delete contact.isEmpty;
            return contact;
        });
};

export const cleanMiscAmounts = (miscAmounts) => {
    miscAmounts &&
        miscAmounts.length > 0 &&
        miscAmounts.map((miscAmount) => {
            let keys = Object.keys(miscAmount);
            if (!miscAmount.Amount) {
                keys.map((key) => {
                    delete miscAmount[key];
                });
                miscAmount.isEmpty = true;
                return miscAmount;
            }
            keys = keys.filter(
                (key) =>
                    key !== '@id' &&
                    key !== 'AmountIndicator' &&
                    key !== 'AmountIndicator_US' &&
                    key !== 'AdjustmentMethodId' &&
                    key !== 'AdjustmentReasonId' &&
                    key !== 'Description'
            );
            keys.map((key) => {
                miscAmount[key] ? null : delete miscAmount[key];
            });
            miscAmount.isEmpty = false;
            return miscAmount;
        });
    return miscAmounts
        .filter((miscAmount) => !miscAmount.isEmpty)
        .map((miscAmount) => {
            delete miscAmount.isEmpty;
            return miscAmount;
        });
};

export const getDocumentIdentifyingNumber = (document, businessDocType) => {
    let identifyingNumber = null;

    switch (businessDocType) {
        case projectConfig.businessDocType.Invoice:
            identifyingNumber = document.BusinessDocFields.Invoice.InvoiceNumber;
            break;
        case projectConfig.businessDocType.ASN:
        case projectConfig.businessDocType.ASNGeneric:
            identifyingNumber = document.BusinessDocFields.BusinessDocument.Document.BusinessDocument.ASN[0].ASNNumber;
            break;
        case projectConfig.businessDocType.PurchaseOrderAcknowledgement:
            identifyingNumber =
                document.BusinessDocFields.BusinessDocument.Document.BusinessDocument.PurchaseOrderAcknowledgement
                    .AcknowledgementDocumentNumber;
            break;
        case projectConfig.businessDocType.SIMDocument:
            identifyingNumber =
                document.BusinessDocFields.BusinessDocument.Document.BusinessDocument.SIMDocument.Description;
            break;
        default:
            break;
    }

    return identifyingNumber;
};

export const getDocumentCurrencyType = (document) => {
    const docFieldsKey =
        document && document.BusinessDocType_US
            ? document.BusinessDocType_US.split(' ').join('')
            : Object.keys(projectConfig.businessDocType).find(
                  (key) => projectConfig.businessDocType[key] === document.BusinessDocType
              );
    let businessDocFields;
    switch (document.BusinessDocType) {
        case projectConfig.businessDocType.PurchaseOrderAcknowledgement:
            businessDocFields =
                document && document.BusinessDocFields && docFieldsKey
                    ? document.BusinessDocFields.BusinessDocument.Document.BusinessDocument[docFieldsKey]
                    : null;
            break;
        case projectConfig.businessDocType.PurchaseOrder:
        case projectConfig.businessDocType.Invoice:
        default:
            businessDocFields =
                document && document.BusinessDocFields && docFieldsKey
                    ? document.BusinessDocFields[docFieldsKey] ||
                      document.BusinessDocFields.BusinessDocument.Document[docFieldsKey]
                    : null;
            break;
    }
    const currencyLookup = parseInt(path(['Currency'], businessDocFields), 10);

    return currencyLookup;
};
