import { ControlFormatFields } from '../parseControlFormatFields';
import { FCNC } from '../../../ui';
import { StaticRelatedDocumentLink } from '../StaticRelatedDocumentLink';
import { useHasValueChanged } from './useHasValueChanged';
import { SearchForRelatedDocumentAndDisplayLink } from '../SearchForRelatedDocumentAndDisplayLink';
import { useSearchState } from '../SearchForRelatedDocumentAndDisplayLink/useSearchState';

export interface DynamicRelatedDocumentLinkProps {
    formatting: ControlFormatFields;

    value: string;
}

export const DynamicRelatedDocumentLink: FCNC<DynamicRelatedDocumentLinkProps> = ({ formatting, value }) => {
    const hasValueChanged = useHasValueChanged(value);

    const searchState = useSearchState(formatting, value);

    if (hasValueChanged || searchState.type !== 'RelatedDocumentFoundState') {
        return (
            <SearchForRelatedDocumentAndDisplayLink formatting={formatting} value={value} searchState={searchState} />
        );
    }

    return <StaticRelatedDocumentLink formatting={formatting} value={value} isInEditMode={true} />;
};
