enum UserRoles {
    CompanyAdminRights = 1,
    SupplierAdminRights = 3,
    InvoiceEditRights = 5,
    SupplierLoginRights = 6,
    BuyerAdminRights = 7,
    PaymentCreationRights = 8,
    PaymentApprovalRights = 9,
    BuyerLoginRights = 10,
    DynamicDiscountingEditRights = 11,
    DynamicDiscountingAdminRights = 12,
    DynamicDiscountingViewRights = 13,
    ParkingLotAdminRights = 14,
}

export default UserRoles;
