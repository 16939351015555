import { FC } from 'react';
import { useQuery, QueryFunction, QueryKey } from '@tanstack/react-query';
import { Autocomplete } from '../AutocompleteAndSortedSelect';
import { Typography } from '@mui/material';

interface IOption {
    id: number;
    label: string;
}

export interface IDynamicOptionAutocompleteProps {
    value: number | null;
    label: string;
    onChange: (newId: number | null) => void;
    helperText?: string;
    required?: boolean;
    testId?: string;
    entityType: string;
    queryKey: QueryKey;
    fetchData: QueryFunction<IOption[]>;
    dependenciesSatisfied: boolean;
    dependencyPromptMessage: string;
}

export const DynamicOptionAutocomplete: FC<IDynamicOptionAutocompleteProps> = ({
    value,
    label,
    onChange,
    required,
    helperText,
    testId,
    entityType,
    queryKey,
    fetchData,
    dependenciesSatisfied,
    dependencyPromptMessage,
}) => {
    const { isLoading, error, data } = useQuery<IOption[]>(queryKey, fetchData, {
        enabled: dependenciesSatisfied,
    });

    if (!dependenciesSatisfied) {
        return (
            <div>
                <Typography>{dependencyPromptMessage}</Typography>
            </div>
        );
    }

    if (isLoading || data === undefined) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const selectedOption = data.find((item) => item.id === value) ?? null;

    return (
        <Autocomplete
            value={selectedOption}
            onChange={(o) => onChange(o?.id ?? null)}
            id={`${entityType}-autocomplete`}
            options={data}
            label={label}
            required={required}
            helperText={helperText}
            testId={testId}
        />
    );
};
