import { useState, useCallback, useEffect } from 'react';

/**
 * useState for a CRUD form.
 *
 * @param defaultFields the values of the form if none are to be loaded
 * @param isLoading indicates whether the entityFields are being loaded
 * @param entityFields the field values for an entity being edited. pass null if not editing
 * @returns current state and function to update state
 */
export function useCRUDState<T>(defaultFields: T, isLoading: boolean, entityFields: T | null) {
    const [fields, setFields] = useState(defaultFields);

    const resetFields = useCallback(() => {
        if (isLoading || entityFields === null) {
            return;
        }

        setFields(entityFields);
    }, [isLoading, entityFields]);

    useEffect(() => {
        resetFields();
    }, [resetFields]);

    const handleFieldChange = useCallback(<K extends keyof T>(name: K, value: T[K]) => {
        setFields((s) => ({
            ...s,
            [name]: value,
        }));
    }, []);

    return { fields, handleFieldChange, setFields, resetFields } as const;
}
