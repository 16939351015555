import { useDropzone } from 'react-dropzone';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { Delete, UploadFile, Visibility } from '@mui/icons-material';
import { Button } from '../Button';
import { downloadLocalFile } from '../../utils';
import { getReadableFileSize } from '../../utils/getReadableFileSize';
import { IUploadedFile } from './types/UploadedFile';
import { Loading } from '../Loading';

export interface IDropzoneProps {
    maxFiles?: number;
    files: IUploadedFile[];
    setFiles: (files: IUploadedFile[]) => void;
    uploadFiles?: (files: File[]) => Promise<IUploadedFile[] | undefined>;
    deleteFile?: (file: File) => void;

    /**
     * pdf for pdf
     * images/* from images
     */
    acceptedFilesTypes?: string[];
    downloadType?: string;

    handleView?: (file: IUploadedFile) => void;
    downloadFile?: (file: File, fileName: string) => void;
    selectedFile?: string;
}

export const Dropzone: FC<IDropzoneProps> = ({
    maxFiles,
    files,
    setFiles,
    uploadFiles,
    deleteFile,
    acceptedFilesTypes,
    downloadType,
    handleView,
    downloadFile,
    selectedFile,
}) => {
    const theme = useTheme();
    const [loading, setLoading] = useState<boolean>(false);
    const upload = async (acceptedFiles: File[]) => {
        if (!uploadFiles) {
            return;
        }
        const newFiles = acceptedFiles.map((file) => ({
            fileName: file.name,
            file: Object.assign(file, {
                preview: URL.createObjectURL(file),
            }),
        }));
        setLoading(true);
        const uploadedFiles = await uploadFiles(newFiles.map((f) => f.file));
        if (uploadedFiles) {
            setFiles([...files, ...uploadedFiles]);
        }
        setLoading(false);
    };

    const { getRootProps, getInputProps } = useDropzone({
        maxFiles,
        accept: acceptedFilesTypes,
        onDrop: async (acceptedFiles) => {
            await upload(acceptedFiles);
        },
    });

    const handleDelete = (file: File) => {
        if (deleteFile) {
            deleteFile(file);
        } else {
            const newFiles = files.filter((f) => f.file.name !== file.name);
            setFiles(newFiles);
        }
    };

    return (
        <Stack direction="column">
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                    px: 1,
                    py: 1,
                }}
            >
                {/* Eslint disabled required by dropzone library */}
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <Stack {...getRootProps()} direction="row" alignItems="center" data-testid="dropzone-input">
                    <UploadFile sx={{ mr: 1 }} />
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <input {...getInputProps()} />
                    <Typography variant="caption" component="span">
                        Drag and drop some files here, or click to select files
                    </Typography>
                </Stack>
            </Box>
            <Box component="aside">
                {loading ? (
                    <Loading />
                ) : (
                    files.map((file, index) => (
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            data-testid={`dropzone-file-${index}`}
                            key={file.file.name}
                            sx={{
                                backgroundColor:
                                    selectedFile === file.fileName ? theme.palette.primary.light : undefined,
                            }}
                        >
                            <Button
                                variant="text"
                                key={file.file.name}
                                onClick={() => {
                                    if (downloadFile) {
                                        downloadFile(file.file, file.fileName);
                                    } else {
                                        downloadLocalFile(file.file.webkitRelativePath, file.file.name, downloadType);
                                    }
                                }}
                                sx={{ color: `${theme.palette.text.secondary} !important` }}
                                data-testid={`dropzone-file-${file.file.name}`}
                            >
                                {file.fileName} - {getReadableFileSize(file.file.size, false)}
                            </Button>
                            <Stack direction="row">
                                {handleView && (
                                    <IconButton
                                        onClick={() => handleView(file)}
                                        aria-label={`view-file-${file.file.name}`}
                                        data-testid={`dropzone-view-btn-${file.file.name}`}
                                    >
                                        <Visibility />
                                    </IconButton>
                                )}
                                <IconButton
                                    onClick={() => handleDelete(file.file)}
                                    aria-label={`delete-file-${file.file.name}`}
                                    data-testid={`dropzone-delete-btn-${file.file.name}`}
                                >
                                    <Delete />
                                </IconButton>
                            </Stack>
                        </Stack>
                    ))
                )}
            </Box>
        </Stack>
    );
};
