import { useContext, createContext, useMemo } from 'react';
import {
    createIdleState,
    createReadyState,
    DocumentEditValidationState,
    DocumentEditValidator,
    StatusMessageTemplates,
    defaultStatusMessageTemplates,
    defaultFieldControlState,
    IInternalRule,
    SetDocumentState,
} from '../types/private';
import useDocumentEditValidatorState from './useDocumentEditValidatorState';
import DropdownOptionsRepository from '../PluginHost/services/dropdownOptionsRepository';

const defaultDocumentEditValidatorState = {
    validatorState: createReadyState(),
    validatorResultState: createIdleState(),
    validateEvent: () => Promise.resolve(true),
    shouldDisableSpecificActionButton: () => false,
    getDropdownOptionsFetcher: () => undefined,
    dropdownOptionsRepository: new DropdownOptionsRepository(),
    context: undefined,
    getFieldControlState: () => defaultFieldControlState,
};

const DocumentEditValidatorContext = createContext<DocumentEditValidator>(defaultDocumentEditValidatorState);

const StatusMessageTemplatesContext = createContext<StatusMessageTemplates>(defaultStatusMessageTemplates);

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface DocumentEditValidatorProviderProps {
    children: React.ReactChildren;

    getDocumentState: () => DocumentEditValidationState | undefined;

    setDocumentState: SetDocumentState;

    getInternalRules: () => IInternalRule[] | undefined;

    statusMessageTemplates?: Partial<StatusMessageTemplates>;

    disableValidator?: boolean;
}

export function DocumentEditValidatorProvider({
    getDocumentState,
    setDocumentState,
    getInternalRules,
    children,
    statusMessageTemplates,
    disableValidator,
}: DocumentEditValidatorProviderProps) {
    const state = useDocumentEditValidatorState(getDocumentState, setDocumentState, getInternalRules);
    const messageTemplates = useMemo(
        () => ({
            ...defaultStatusMessageTemplates,
            ...statusMessageTemplates,
        }),
        [statusMessageTemplates]
    );

    return (
        <StatusMessageTemplatesContext.Provider value={messageTemplates}>
            <DocumentEditValidatorContext.Provider value={disableValidator ? defaultDocumentEditValidatorState : state}>
                {children}
            </DocumentEditValidatorContext.Provider>
        </StatusMessageTemplatesContext.Provider>
    );
}

export function useDocumentEditValidator() {
    const state = useContext(DocumentEditValidatorContext);

    return state;
}

export function useStatusMessageTemplates() {
    const templates = useContext(StatusMessageTemplatesContext);

    return templates;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface DocumentEditValidatorInjectedProps {
    documentEditValidator: DocumentEditValidator;
}

export function withDocumentEditValidator<P>(
    Component: React.ComponentType<P & DocumentEditValidatorInjectedProps>
): React.ComponentType<P> {
    return (props: P) => {
        const documentEditValidator = useDocumentEditValidator();

        return (
            <Component
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                documentEditValidator={documentEditValidator}
            />
        );
    };
}
