interface IErrorMessage {
    [key: string]: string;
}

const ErrorMessages: IErrorMessage = {
    '12': 'File requested is not available, please contact customer support for assistance',
    '13': 'Please enter an email that is registered',
};

export default ErrorMessages;
