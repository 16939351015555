import { useState, useEffect } from 'react';

export default function useOperationTookTooLong(tooLongTimeInSeconds: number, isLoading: boolean) {
    const [tookTooLong, setTookTooLong] = useState(false);

    useEffect(() => {
        if (!isLoading) {
            return undefined;
        }

        const timeout = setTimeout(() => {
            setTookTooLong(true);
        }, tooLongTimeInSeconds * 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, [isLoading, tooLongTimeInSeconds]);

    return tookTooLong;
}
