import { useMemo } from 'react';
import { backendServices } from '../../../../services';
import { BusinessDocType } from '../../../../types';
import { useBodyLineNumberState } from '../../../DocumentEditRules';
import { useRelatedDocumentsState } from '../../RelatedDocumentsContext';
import { transformRelatedDocuments } from './transformRelatedDocuments';

export interface IDocumentRelatedValues {
    relatedDocumentId: number;
    currentDocumentType: BusinessDocType;
}

function findLineIds(document: backendServices.ViewModels.DocumentViewModel) {
    switch (document.BusinessDocType) {
        case BusinessDocType.Invoice:
            return document.BusinessDocFields.Invoice.InvoiceLineItems.map((x) => x.Id);
        default:
            throw new Error(`Unsupported business doc type: ${document.BusinessDocType}`);
    }
}

function findLineId(document: backendServices.ViewModels.DocumentViewModel | undefined, lineNumber: number | null) {
    if (lineNumber === null) {
        return null;
    }
    if (!document) {
        return -1;
    }

    return findLineIds(document)[lineNumber - 1];
}

export function useRelatedDocument(documentType: BusinessDocType): IDocumentRelatedValues | undefined {
    const { documentId, relatedDocuments, document: originalDocument } = useRelatedDocumentsState();
    const lineNumber = useBodyLineNumberState();
    const lineId = findLineId(originalDocument, lineNumber);
    const data = useMemo(() => {
        if (!relatedDocuments) {
            return undefined;
        }

        return transformRelatedDocuments(relatedDocuments);
    }, [relatedDocuments]);

    if (!data) {
        return undefined;
    }

    let relatedDocument: IDocumentRelatedValues | undefined;
    // eslint-disable-next-line no-restricted-syntax
    for (const document of data) {
        if (
            document.documentId1 === documentId &&
            document.lineNumber1 === lineId &&
            document.businessDocType2 === documentType &&
            document.active
        ) {
            relatedDocument = {
                relatedDocumentId: document.documentId2,
                currentDocumentType: document.businessDocType1,
            };
        } else if (
            document.documentId2 === documentId &&
            document.lineNumber2 === lineId &&
            document.businessDocType1 === documentType &&
            document.active
        ) {
            relatedDocument = {
                relatedDocumentId: document.documentId1,
                currentDocumentType: document.businessDocType2,
            };
        }
    }

    return relatedDocument;
}
