import { api } from './api';
import apiPaths from './paths';

class DocumentTypeThunk {
    fetchSIMDocumentTypeList = ({ companyId, ...params }) => {
        return api()
            .get(apiPaths.readSIMDocumentTypeList.replace(':companyId', companyId), { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchSIMDocumentType = ({ id, ...params }) => {
        return api()
            .get(apiPaths.readSIMDocumentType.replace(':id', id), { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    addSIMDocumentType = (params) => {
        return api()
            .post(apiPaths.documentType, params)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    updateSIMDocumentType = (params) => {
        return api()
            .put(apiPaths.updateSIMDocumentType.replace(':id', params.Id), params)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchSupplierSIMRequest = (params) => {
        return api()
            .get(apiPaths.readSupplierSIMRequest.replace(':id', params.id), {})
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchSupplierSIMRequests = ({ id, isBuyer, ...params }) => {
        return api()
            .get(apiPaths.readSupplierSIMRequestList.replace(':companyId', id).replace(':isBuyer', isBuyer), { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };
}

export default DocumentTypeThunk;
