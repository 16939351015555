import { IValue, ISegment } from '../types';
import { CompanyCustomDatasetDropdownField } from './CompanyCustomDatasetDropdownField';

interface IProps {
    companyId: number;
    segment: ISegment;
    segmentKey: string;
    value: IValue | null;
    editMode: boolean;
    className?: string;
    isClearable?: boolean;
    onChange: (value: IValue) => void;
    unfocused?: boolean;
}

const SelectCustomData: React.FC<IProps> = ({ companyId, segment, value, editMode, onChange, unfocused = false }) => {
    return (
        <CompanyCustomDatasetDropdownField
            fieldDefinitionKey={segment.CompanyCustomDatasetFieldDefinitionKey}
            datasetName={segment.CompanyCustomDatasetName}
            datasetPath={segment.CompanyCustomDatasetPath}
            smallRecordLimit={segment.CompanyGLAccountSmallRecordLimit}
            minCharsSearch={segment.CompanyCustomDatasetMinCharsSearch}
            companyId={companyId}
            value={value}
            editMode={editMode}
            onChange={onChange}
            glFieldName={segment.Name}
            unfocused={unfocused}
        />
    );
};

export default SelectCustomData;
