import { IUploadedFile } from '../../../../ui/Dropzone/types/UploadedFile';
import { IFieldType, createFieldTypeFactory } from '../infrastructure';

/**
 * A dropzone field object.
 */
export interface IDropzoneField extends IFieldType<IUploadedFile[]> {
    type: 'IDropzoneField';

    files: IUploadedFile[];

    maxFiles?: number;
}

export const createDropzoneField = createFieldTypeFactory<IDropzoneField>('IDropzoneField', []);
