import { useReducer, useEffect } from 'react';
import { initialSearchFormState, ISearchFormState } from './searchFormState';

/**
 * Updates the search form state based on an action the user has taken.
 * @param state current state
 * @param action action user is taking
 * @returns next search form state
 */
export function searchFormReducer(state: ISearchFormState, action: { type: string; payload: any }): ISearchFormState {
    switch (action.type) {
        case 'SET_ENTERED_CRITERIA':
            return { ...state, enteredCriteria: action.payload };
        case 'SUBMIT':
            return {
                ...state,
                appliedCriteria: state.enteredCriteria,
                sortModel: action.payload?.sortModel || [],
                page: 0,
            };
        case 'CLEAR':
            return initialSearchFormState;
        case 'SET_PAGE':
            return { ...state, page: action.payload };
        case 'SET_SORT':
            return { ...state, sortModel: action.payload, page: 0 };
        case 'SET_PAGE_SIZE':
            return { ...state, pageSize: action.payload, page: 0 };
        case 'OVERWRITE_STATE_FROM_SAVED_CRITERIA':
            return action.payload;
        default:
            return state;
    }
}

/**
 * Manages the current search form state using a reducer that adjusts the state correctly
 * for each type of action the user can take.
 * @param savedCriteria the search criteria saved in the URL
 * @returns the current state and dispatch function
 */
export function useSearchFormReducer(savedCriteria: ISearchFormState) {
    const [state, dispatch] = useReducer(searchFormReducer, savedCriteria);

    // observe the saved criteria and overwrite state when it changes. this is
    // needed to update the search form when the user navigates forward/backwards
    useEffect(() => {
        dispatch({
            type: 'OVERWRITE_STATE_FROM_SAVED_CRITERIA',
            payload: savedCriteria,
        });
    }, [savedCriteria]);

    return [state, dispatch] as const;
}
