import { IDocumentViewModel } from '../../services/Document/Api';
import { factory } from './DocumentAmountsViewModelFactory';

interface TaxFieldProps {
    discountableAmount: number;
    lineItemTotals: number[];
    lineQuantities: number[];
}

export const extractTaxFieldProps = (document: IDocumentViewModel): TaxFieldProps => {
    try {
        const vm = factory.createDocumentAmountsViewModel(document);

        const calculatedFields = vm.getCalculatedFields();

        const lineItemAmounts = vm.getLineItemAmounts();

        return {
            discountableAmount: calculatedFields.subtotal,
            lineItemTotals: calculatedFields.lineItemTotals.map((v) => v.total),
            lineQuantities: lineItemAmounts.map((v) => v.quantity),
        };
    } catch (error) {
        return {
            discountableAmount: 0,
            lineItemTotals: [],
            lineQuantities: [],
        };
    }
};
