import actions from '../../actions/index';

const { MANAGEUSERS_FETCHING, MANAGEUSERS_FETCHED, MANAGEUSERS_LIST_LOADED, MANAGEUSERS_ROLES_LOADED } = actions;

const initialState = {
    users: [],
    roles: [],
    isLoading: false,
};

// eslint-disable-next-line import/prefer-default-export
export function manageUsers(state = initialState, action) {
    switch (action.type) {
        case MANAGEUSERS_FETCHING:
            return { ...state, isLoading: true };
        case MANAGEUSERS_FETCHED:
            return { ...state, isLoading: false };
        case MANAGEUSERS_LIST_LOADED:
            return { ...state, users: action.payload };
        case MANAGEUSERS_ROLES_LOADED:
            return { ...state, roles: action.payload };
        default:
            return state;
    }
}
