import {
    CLEAR_DOCUMENT_CREATION_CACHE,
    DOCUMENT_CREATION_SET_ORIGINAL_STATE_INFO,
} from '../../actions/document/documentCreationActions';
import { USER_LOGIN_FAILED, USER_LOGIN_PENDING, USER_LOGIN_SUCCESS, USER_LOGOUT } from '../../actions/userlogin';

const initialState = {
    identifyingNumber: null,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CLEAR_DOCUMENT_CREATION_CACHE:
        case USER_LOGIN_FAILED:
        case USER_LOGIN_PENDING:
        case USER_LOGIN_SUCCESS: {
            // if it was timeout retrun currenState, otherwhise initialState
            const { timeout, ...currentState } = state;
            return timeout && timeout === true ? currentState : initialState;
            //    return state;
        }
        case USER_LOGOUT: {
            const { timeout } = payload;
            return timeout && timeout == true ? { ...state, timeout } : initialState;

            // return {
            //     ...initialState
            // };
        }
        case DOCUMENT_CREATION_SET_ORIGINAL_STATE_INFO:
            return {
                ...state,
                identifyingNumber: payload.identifyingNumber,
            };

        default:
            return state;
    }
};
