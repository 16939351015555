let dedupForeverOn = false;
let cleanups: (() => void)[] = [];

/**
 * Dedup any repeated requests, even when original has finished, until turned back off.
 */
export function turnDedupForeverOn() {
    dedupForeverOn = true;
}

/**
 * Stop deduping repeated requests that have already finished.
 */
export function turnDedupForeverOff() {
    cleanups.forEach((cleanup) => cleanup());
    cleanups = [];
    dedupForeverOn = false;
}

/**
 * Dedup sequential requests with the same key forever until a task finishes.
 *
 * This is equivalent to calling `turnDedupForeverOn` before running the task
 * and calling `turnDedupForeverOff` after the task finishes.
 */
export async function dedupWhileRunning<T>(fn: () => Promise<T>): Promise<T> {
    try {
        turnDedupForeverOn();
        return await fn();
    } finally {
        turnDedupForeverOff();
    }
}

export function scheduleCleanup(fn: () => void): void {
    cleanups.push(fn);
}

export function isDepdupOnForever() {
    return dedupForeverOn;
}
