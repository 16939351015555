import {
    FETCH_BUYER_COMPANY_EXECUTE,
    FETCH_BUYER_COMPANY_FAILURE,
    FETCH_BUYER_COMPANY_SUCCESS,
    FETCH_BUYER_VAT_ARCHIVE_SERVICE_EXECUTE,
    FETCH_BUYER_VAT_ARCHIVE_SERVICE_FAILURE,
    FETCH_BUYER_VAT_ARCHIVE_SERVICE_SUCCESS,
    FETCH_SUPPLIER_COMPANY_EXECUTE,
    FETCH_SUPPLIER_COMPANY_FAILURE,
    FETCH_SUPPLIER_COMPANY_SUCCESS,
    FETCH_SUPPLIER_VAT_ARCHIVE_SERVICE_EXECUTE,
    FETCH_SUPPLIER_VAT_ARCHIVE_SERVICE_FAILURE,
    FETCH_SUPPLIER_VAT_ARCHIVE_SERVICE_SUCCESS,
    SET_SELECTED_CONTACT,
    CLEAR_SELECTED_CONTACT,
} from '../../actions/company/companyActions';
import {
    FETCH_COMPANY_PROFILES_EXECUTE,
    FETCH_COMPANY_PROFILES_FAILURE,
    FETCH_COMPANY_PROFILES_SUCCESS,
    SELECTED_PROFILE_CLEAR,
    SELECTED_PROFILE_SET,
    SELECTED_PROFILE_LOGO_SET,
    SET_SELECTED_PROFILE_VALIDATION_ERRORS,
    UPDATE_COMPANY_PROFILES_PAGE,
    UPDATE_COMPANY_PROFILES_SORT,
    ACCOUNT_PROFILE_SET,
    SET_ACCOUNT_PROFILE_VALIDATION_ERRORS,
    UPDATE_CONTACT_NAME,
    UPDATE_CONTACT_PHONE,
    UPDATE_CONTACT_EMAIL,
} from '../../actions/profile/ProfilesActions';
import { USER_LOGIN_FAILED, USER_LOGIN_PENDING, USER_LOGIN_SUCCESS, USER_LOGOUT } from '../../actions/userlogin';
import projectConfig from '../../project.config.json';

export const selectedContactInitialState = {
    ID: null,
    Name: '',
    Address1: '',
    Address2: '',
    Address3: '',
    City: '',
    State: '',
    PostalCode: '',
    Country: '',
    Fax: null,
    Email: '',
    Type: null,
    TIN: '',
    ABANumber: null,
    AccountNumber: null,
    SWIFT: null,
    IBAN: null,
    Instructions: null,
};

const initialState = {
    buyerCompany: {
        accountInfo: null,
        vatArchiveServiceRecords: [],
    },
    loading: {
        buyerCompany: {
            accountInfo: false,
            vatArchiveServiceRecords: false,
        },
        supplierCompany: {
            accountInfo: false,
            profiles: {
                [projectConfig.profileType.BuyerDestination]: false,
                [projectConfig.profileType.BuyerWarehouse]: false,
                [projectConfig.profileType.Invoice]: false,
                [projectConfig.profileType.Procurement]: false,
                [projectConfig.profileType.Sales]: false,
                [projectConfig.profileType.SupplierWarehouse]: false,
            },
            vatArchiveServiceRecords: false,
        },
    },
    supplierCompany: {
        accountInfo: null,
        profiles: {
            selectedProfile: null,
            selectedProfileLogo: {
                file: null,
                url: null,
            },
            selectedProfileValidationErrors: [],
            accountProfileValidationErrors: [],
            selectedProfileVatArchiveService: null,
            [projectConfig.profileType.BuyerDestination]: {
                page: 0,
                records: [],
            },
            [projectConfig.profileType.BuyerWarehouse]: {
                page: 0,
                records: [],
            },
            [projectConfig.profileType.Invoice]: {
                page: 0,
                records: [],
            },
            [projectConfig.profileType.Procurement]: {
                page: 0,
                records: [],
            },
            [projectConfig.profileType.Sales]: {
                page: 0,
                records: [],
            },
            [projectConfig.profileType.SupplierWarehouse]: {
                page: 0,
                records: [],
            },
        },
        selectedContact: null,
        vatArchiveServiceRecords: [],
    },
    contactInfo: {
        Name: '',
        Phone: '',
    },
};

// eslint-disable-next-line import/prefer-default-export
export function company(state = initialState, action) {
    switch (action.type) {
        case FETCH_BUYER_VAT_ARCHIVE_SERVICE_EXECUTE:
            return {
                ...state,
                buyerCompany: {
                    ...state.buyerCompany,
                    vatArchiveServiceRecords: initialState.buyerCompany.vatArchiveServiceRecords,
                },
                loading: {
                    ...state.loading,
                    buyerCompany: {
                        ...state.loading.buyerCompany,
                        vatArchiveServiceRecords: true,
                    },
                },
            };

        case FETCH_BUYER_VAT_ARCHIVE_SERVICE_FAILURE:
            return {
                ...state,
                buyerCompany: {
                    ...state.buyerCompany,
                    vatArchiveServiceRecords: initialState.buyerCompany.vatArchiveServiceRecords,
                },
                loading: {
                    ...state.loading,
                    buyerCompany: {
                        ...state.loading.buyerCompany,
                        vatArchiveServiceRecords: false,
                    },
                },
            };

        case FETCH_BUYER_VAT_ARCHIVE_SERVICE_SUCCESS:
            return {
                ...state,
                buyerCompany: {
                    ...state.buyerCompany,
                    vatArchiveServiceRecords: action.payload,
                },
                loading: {
                    ...state.loading,
                    buyerCompany: {
                        ...state.loading.buyerCompany,
                        vatArchiveServiceRecords: false,
                    },
                },
            };

        case FETCH_COMPANY_PROFILES_EXECUTE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    supplierCompany: {
                        ...state.loading.supplierCompany,
                        profiles: {
                            ...state.loading.supplierCompany.profiles,
                            [action.payload.params.profileType]: true,
                        },
                    },
                },
                supplierCompany: {
                    ...state.supplierCompany,
                    profiles: {
                        ...state.supplierCompany.profiles,
                        [action.payload.params.profileType]:
                            initialState.supplierCompany.profiles[action.payload.params.profileType],
                    },
                },
            };

        case FETCH_COMPANY_PROFILES_FAILURE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    supplierCompany: {
                        ...state.loading.supplierCompany,
                        profiles: {
                            ...state.loading.supplierCompany.profiles,
                            [action.payload.profileType]: false,
                        },
                    },
                },
                supplierCompany: {
                    ...state.supplierCompany,
                    profiles: {
                        ...state.supplierCompany.profiles,
                        [action.payload.profileType]: initialState.supplierCompany.profiles[action.payload.profileType],
                    },
                },
            };

        case FETCH_COMPANY_PROFILES_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    supplierCompany: {
                        ...state.loading.supplierCompany,
                        profiles: {
                            ...state.loading.supplierCompany.profiles,
                            [action.payload.profileType]: false,
                        },
                    },
                },
                supplierCompany: {
                    ...state.supplierCompany,
                    profiles: {
                        ...state.supplierCompany.profiles,
                        [action.payload.profileType]: {
                            ...state.supplierCompany.profiles[action.payload.profileType],
                            records: action.payload.data,
                        },
                    },
                },
            };

        case FETCH_SUPPLIER_VAT_ARCHIVE_SERVICE_EXECUTE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    supplierCompany: {
                        ...state.loading.supplierCompany,
                        vatArchiveServiceRecords: true,
                    },
                },
                supplierCompany: {
                    ...state.supplierCompany,
                    vatArchiveServiceRecords: initialState.supplierCompany.vatArchiveServiceRecords,
                },
            };

        case FETCH_SUPPLIER_VAT_ARCHIVE_SERVICE_FAILURE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    supplierCompany: {
                        ...state.loading.supplierCompany,
                        vatArchiveServiceRecords: false,
                    },
                },
                supplierCompany: {
                    ...state.supplierCompany,
                    vatArchiveServiceRecords: initialState.supplierCompany.vatArchiveServiceRecords,
                },
            };

        case FETCH_SUPPLIER_VAT_ARCHIVE_SERVICE_SUCCESS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    supplierCompany: {
                        ...state.loading.supplierCompany,
                        vatArchiveServiceRecords: false,
                    },
                },
                supplierCompany: {
                    ...state.supplierCompany,
                    vatArchiveServiceRecords: action.payload,
                },
            };

        case UPDATE_COMPANY_PROFILES_SORT:
            return {
                ...state,
                supplierCompany: {
                    ...state.supplierCompany,
                    profiles: {
                        ...state.supplierCompany.profiles,
                        [action.payload.profileType]: {
                            ...state.supplierCompany.profiles[action.payload.profileType],
                            page: 0,
                            sortKey: action.payload.sortKey,
                            sortDirection: action.payload.sortDirection,
                        },
                    },
                },
            };

        case UPDATE_COMPANY_PROFILES_PAGE:
            return {
                ...state,
                supplierCompany: {
                    ...state.supplierCompany,
                    profiles: {
                        ...state.supplierCompany.profiles,
                        [action.payload.profileType]: {
                            ...state.supplierCompany.profiles[action.payload.profileType],
                            page: action.payload.page,
                        },
                    },
                },
            };

        case SELECTED_PROFILE_CLEAR:
            return {
                ...state,
                supplierCompany: {
                    ...state.supplierCompany,
                    profiles: {
                        ...state.supplierCompany.profiles,
                        selectedProfile: initialState.supplierCompany.profiles.selectedProfile,
                        selectedProfileLogo: initialState.supplierCompany.profiles.selectedProfileLogo,
                        selectedProfileVatArchiveService:
                            initialState.supplierCompany.profiles.selectedProfileVatArchiveService,
                        selectedProfileValidationErrors:
                            initialState.supplierCompany.profiles.selectedProfileValidationErrors,
                    },
                },
            };

        case SELECTED_PROFILE_SET:
            return {
                ...state,
                supplierCompany: {
                    ...state.supplierCompany,
                    profiles: {
                        ...state.supplierCompany.profiles,
                        selectedProfile: action.payload.profile,
                        selectedProfileVatArchiveService: action.payload.vatArchiveService,
                        selectedProfileValidationErrors: state.supplierCompany.profiles.selectedProfileValidationErrors,
                    },
                },
            };

        case SELECTED_PROFILE_LOGO_SET:
            return {
                ...state,
                supplierCompany: {
                    ...state.supplierCompany,
                    profiles: {
                        ...state.supplierCompany.profiles,
                        selectedProfileLogo: {
                            ...state.supplierCompany.profiles.selectedProfileLogo,
                            file: action.payload.file,
                            url: action.payload.url,
                        },
                    },
                },
            };

        case SET_SELECTED_PROFILE_VALIDATION_ERRORS:
            return {
                ...state,
                supplierCompany: {
                    ...state.supplierCompany,
                    profiles: {
                        ...state.supplierCompany.profiles,
                        selectedProfileValidationErrors: action.payload,
                    },
                },
            };

        case FETCH_BUYER_COMPANY_EXECUTE:
            return {
                ...state,
                buyerCompany: {
                    ...state.buyerCompany,
                    accountInfo: initialState.supplierCompany.accountInfo,
                },
                loading: {
                    ...state.loading,
                    buyerCompany: {
                        ...state.loading.buyerCompany,
                        accountInfo: true,
                    },
                },
            };

        case FETCH_BUYER_COMPANY_FAILURE:
            return {
                ...state,
                buyerCompany: {
                    ...state.buyerCompany,
                    accountInfo: initialState.supplierCompany.accountInfo,
                },
                loading: {
                    ...state.loading,
                    buyerCompany: {
                        ...state.loading.buyerCompany,
                        accountInfo: false,
                    },
                },
            };

        case FETCH_BUYER_COMPANY_SUCCESS:
            return {
                ...state,
                buyerCompany: {
                    ...state.buyerCompany,
                    accountInfo: action.payload,
                },
                loading: {
                    ...state.loading,
                    buyerCompany: {
                        ...state.loading.buyerCompany,
                        accountInfo: false,
                    },
                },
            };

        case FETCH_SUPPLIER_COMPANY_EXECUTE:
            return {
                ...state,
                supplierCompany: {
                    ...state.supplierCompany,
                    accountInfo: initialState.supplierCompany.accountInfo,
                },
                loading: {
                    ...state.loading,
                    supplierCompany: {
                        ...state.loading.supplierCompany,
                        accountInfo: true,
                    },
                },
            };

        case FETCH_SUPPLIER_COMPANY_FAILURE:
            return {
                ...state,
                supplierCompany: {
                    ...state.supplierCompany,
                    accountInfo: initialState.supplierCompany.accountInfo,
                },
                loading: {
                    ...state.loading,
                    supplierCompany: {
                        ...state.loading.supplierCompany,
                        accountInfo: false,
                    },
                },
            };

        case FETCH_SUPPLIER_COMPANY_SUCCESS:
            return {
                ...state,
                supplierCompany: {
                    ...state.supplierCompany,
                    accountInfo: action.payload,
                },
                loading: {
                    ...state.loading,
                    supplierCompany: {
                        ...state.loading.supplierCompany,
                        accountInfo: false,
                    },
                },
            };

        case ACCOUNT_PROFILE_SET:
            return {
                ...state,
                supplierCompany: {
                    ...state.supplierCompany,
                    accountInfo: action.payload,
                    profiles: {
                        ...state.supplierCompany.profiles,
                        accountProfileValidationErrors: [],
                    },
                },
            };

        case SET_ACCOUNT_PROFILE_VALIDATION_ERRORS:
            return {
                ...state,
                supplierCompany: {
                    ...state.supplierCompany,
                    profiles: {
                        ...state.supplierCompany.profiles,
                        accountProfileValidationErrors: action.payload,
                    },
                },
            };
        case SET_SELECTED_CONTACT:
            return {
                ...state,
                supplierCompany: {
                    ...state.supplierCompany,
                    selectedContact: action.payload,
                },
            };
        case CLEAR_SELECTED_CONTACT:
            return {
                ...state,
                supplierCompany: {
                    ...state.supplierCompany,
                    selectedContact: null,
                },
            };

        case USER_LOGIN_FAILED:
        case USER_LOGIN_PENDING: {
            // if it was timeout return currenState, otherwhise initialState
            const { timeout } = state;
            return timeout && timeout === true ? state : initialState;
        }
        case USER_LOGIN_SUCCESS: {
            // if it was timeout return currenState, otherwhise initialState
            // remove timeout flag
            const { timeout, ...currentState } = state;
            return timeout && timeout === true ? currentState : initialState;
        }
        case USER_LOGOUT: {
            const { timeout } = action.payload;
            return timeout && timeout == true ? state : initialState;
        }
        default:
            return state;
    }
}
