export enum FileTypes {
    saved = 'saved',
    accepted = 'accepted',
    rejected = 'rejected',
}

export interface IFile {
    Id: number;

    FileSize?: string;
    name: string;
    size: number;
    onDeleteFile?: (event, id, type: string) => void;
    type?: string;
    errors?: string[];
    preview?: string;
    path?: string;
    webkitRelativePath?: string;
    fileType: FileTypes;
}

export interface IRequirments {
    title: string | JSX.Element;
    body: string | JSX.Element;
}
