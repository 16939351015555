import { ReactNode } from 'react';
import { IField, IFieldRendererViewModel } from './types';
import { FieldRendererOnChange } from './IFieldRendererProps';
import { FieldRenderer } from './FieldRenderer';

export interface IRenderFormColumnsProps<FieldRendererViewModel extends IFieldRendererViewModel> {
    onChange: FieldRendererOnChange<FieldRendererViewModel>;

    viewModel: FieldRendererViewModel;

    columns: IField[][];
}

/**
 * Renders the search form fields provided by the search configuration and binds them to their
 * state.
 */
export function renderFormColumns<T extends IFieldRendererViewModel>({
    columns,
    viewModel,
    onChange,
}: IRenderFormColumnsProps<T>): ReactNode[] {
    return columns.map((column) => (
        <>
            {column.map((field) => (
                <FieldRenderer key={field.id} field={field} viewModel={viewModel} onChange={onChange} />
            ))}
        </>
    ));
}
