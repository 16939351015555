import { BusinessDocType } from '../../../types';
import { DocumentRouteTemplates } from '../RelatedDocumentsContext';

export function getDocumentLink(
    documentId: number,
    documentType: BusinessDocType,
    documentRouteTemplates: DocumentRouteTemplates | undefined
) {
    // this shouldn't actually be possible at time of implementation, but best to throw a useful error in this case
    if (!documentRouteTemplates) {
        throw new Error(
            'Unexpected call to getDocumentLink: No DocumentRouteTemplates were provided, so no link can be generated. Make sure that you use the RelatedDocuments.RelatedDocumentsProvider around the document page.'
        );
    }

    const template = documentRouteTemplates[documentType];

    // this also shouldn't happen, but we can update the code later on to do something different when encountering a business doc type we cannot handle
    if (!template) {
        throw new Error(
            `Invalid call to getDocumentLink: there is no document route template for business doc type ${documentType}`
        );
    }

    return template.replace(':documentId', documentId.toString());
}
