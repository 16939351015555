import { api } from '../utils';
import apiPaths from '../api/paths';

export const CLEAR_NOTICE_PAGE = 'CLEAR_NOTICE_PAGE';
export const GET_NOTICE_PAGE_FAILED = 'GET_NOTICE_PAGE_FAILED';
export const GET_NOTICE_PAGE_SUCCESS = 'GET_NOTICE_PAGE_SUCCESS';

export const getNoticePage = () => (dispatch) =>
    api()
        .get(apiPaths.getNoticePage)
        .then(
            (response) => {
                let notices = [];
                if (response.data.length) {
                    response.data.forEach((data) => {
                        notices.push({
                            description: data.Description,
                        });
                    });
                }
                const noticePage = {
                    notices: notices,
                    showNoticePage: notices.length > 0,
                };

                dispatch({
                    type: GET_NOTICE_PAGE_SUCCESS,
                    payload: noticePage,
                });

                return noticePage;
            },
            (error) => {
                dispatch({
                    type: GET_NOTICE_PAGE_FAILED,
                    payload: error,
                });
            }
        );

/**
 * Clear content notice page
 *
 * @return void
 */
export function clearNoticePage() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_NOTICE_PAGE,
        });
    };
}
