import { Currency } from './types';
import { CurrencyApi } from '../Api/CurrencyApi';
import { CurrencyModel } from '../Api/ViewModels';

export class CurrencyService {
    api = new CurrencyApi();

    private mapToCurrency(currency: CurrencyModel): Currency {
        return {
            id: currency.Id,
            currencyType: currency.CurrencyType,
            currencyType_US: currency.CurrencyType_US,
            displayName: currency.DisplayName,
            symbol: currency.Symbol.replace(/\\u(\w\w\w\w)/g, (a, b) => String.fromCharCode(parseInt(b, 16))),
            expirationDate: currency.ExpirationDate,
            lastUpdateDate: currency.LastUpdateDate,
            createdDate: currency.CreatedDate,
        };
    }

    public getCurrencies = async (): Promise<Currency[]> => {
        const response = await this.api.getCurrency();

        return response?.data?.map(this.mapToCurrency);
    };
}
