import { FC, ReactNode } from 'react';
import { IUserLogin } from '../../../transcepta-types';
import LoginContainer from './LoginContainer';

interface IProps {
    article: ReactNode;
    logo?: ReactNode;
    userLogin: IUserLogin;
    loginAgain: () => void;
}

const LogoutPage: FC<IProps> = ({ article, logo, userLogin, loginAgain }) => {
    const handleLogInAgain = async () => {
        loginAgain();
    };

    return (
        <LoginContainer
            article={article}
            logo={logo}
            formVariant="logout"
            userLogin={userLogin}
            isLoading={false}
            email={''}
            emailError={false}
            password={''}
            passwordError={false}
            companyId={''}
            companyIdError={false}
            errorMessage={''}
            successMessage={''}
            handleEmailChange={() => {}}
            handlePasswordChange={() => {}}
            handleCompanyIdChange={() => {}}
            handleSignIn={() => {}}
            handleForgotPassword={() => {}}
            handleReset={() => {}}
            handleBackToSignIn={() => {}}
            handleSSOSignIn={() => {}}
            handleLogInAgain={handleLogInAgain}
        />
    );
};

export default LogoutPage;
