import { FC, useEffect } from 'react';
import clsx from 'clsx';
import { ChevronLeft, ChevronRight, DoubleArrow, HighlightOff } from '@mui/icons-material';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Button } from '../../Atoms';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        paddingTop: '0.5rem',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '0.2rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
        },
    },
    button: {
        color: theme.palette.primary.main,
        backgroundColor: 'unset',
        margin: '0.2rem',
        minWidth: 'unset',
        padding: '0.2rem',
        zIndex: 100,
        '&:hover': {
            backgroundColor: 'unset',
        },
    },
    buttonClose: {
        position: 'absolute',
        right: 0,
        zIndex: 100,
    },
    flipped: {
        transform: 'scaleX(-1)',
    },
}));

export interface IActionsProps {
    textPage: string;
    textOf: string;
    pageNumber: number;
    numPages: number;
    isSmDown: boolean;
    onSetPageNumber: (pageNumber: number) => void;
    onClose?: () => void;
}

/**
 * @deprecated use new pdf viewer in UI - keep this until after ie11 support end
 * @param textPage
 * @param textOf
 * @param numPages
 * @param pageNumber
 * @param isSmDown
 * @param onSetPageNumber
 * @param onClose
 * @constructor
 */
const PDFActions: FC<IActionsProps> = ({
    textPage,
    textOf,
    numPages,
    pageNumber,
    isSmDown,
    onSetPageNumber,
    onClose,
}) => {
    const classes = useStyles();

    useEffect(() => {
        return () => {
            onSetPageNumber(1);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div className={clsx(classes.root)} title={isSmDown ? '' : 'Click and hold to drag.'}>
            <Button
                id="button-firstpage"
                disabled={pageNumber <= 1}
                onClick={() => onSetPageNumber(1)}
                className={clsx(classes.button, classes.flipped)}
            >
                <DoubleArrow />
            </Button>
            <Button
                id="button-previouspage"
                disabled={pageNumber <= 1}
                onClick={() => onSetPageNumber(pageNumber - 1 < 1 ? 1 : pageNumber - 1)}
                className={classes.button}
            >
                <ChevronLeft />
            </Button>
            <span>
                {textPage} {pageNumber || (numPages ? 1 : '--')} {textOf} {numPages || '--'}
            </span>
            <Button
                id="button-nextpage"
                type="button"
                disabled={pageNumber >= numPages}
                onClick={() => onSetPageNumber(pageNumber + 1)}
                className={classes.button}
            >
                <ChevronRight />
            </Button>
            <Button
                id="button-lastpage"
                type="button"
                disabled={pageNumber >= numPages}
                onClick={() => onSetPageNumber(numPages)}
                className={classes.button}
            >
                <DoubleArrow />
            </Button>
            {onClose && (
                <Button
                    id="button-close"
                    type="button"
                    className={clsx(classes.button, classes.buttonClose)}
                    onClick={onClose}
                >
                    <HighlightOff />
                </Button>
            )}
        </div>
    );
};

export default PDFActions;
