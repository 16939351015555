export enum DocumentBatchStatus {
    Created = 1,
    Extracting = 2,
    ExtractingComplete = 3,
    DocumentsReleased = 4,
    Research = 6,
    Canceled = 7,
    SampleArchived = 8,
    PartiallyCanceled = 9,
    WaitingForIndirect = 10,
    Draft = 11,
    SampleReceived = 12,
    SampleVerified = 13,
    SampleRejected = 14,
}
