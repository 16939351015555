import { ICrashSimulator } from './types';
import { EventEmitter } from '../../utils';

class TransceptaCrashSimulator implements ICrashSimulator {
    private emitters: { [key: string]: EventEmitter<number> | undefined } = {};

    private errorBoundaries = new Set<string>();

    private getEmitter(targetErrorBoundary: string) {
        if (!this.emitters[targetErrorBoundary]) {
            this.emitters[targetErrorBoundary] = new EventEmitter();
        }

        return this.emitters[targetErrorBoundary]!;
    }

    private addErrorBoundary(errorBoundaryName: string) {
        this.errorBoundaries.add(errorBoundaryName);
        return () => {
            this.errorBoundaries.delete(errorBoundaryName);
        };
    }

    /**
     * Register a listener for crashing of an error boundary.
     * @param targetErrorBoundary the error boundary that should crash
     * @param cb callback to call when the error boundary should crash
     * @returns a function to unsubscribe.
     */
    onSimulateCrash(targetErrorBoundary: string, cb: (timeout: number) => void) {
        const cleanupSubscription = this.getEmitter(targetErrorBoundary).subscribe(cb);
        const cleanupName = this.addErrorBoundary(targetErrorBoundary);

        return () => {
            cleanupSubscription();
            cleanupName();
        };
    }

    simulateCrash(targetErrorBoundary: string, timeout: number) {
        this.getEmitter(targetErrorBoundary).notify(timeout);
    }

    printErrorBoundaries() {
        // eslint-disable-next-line no-console
        this.errorBoundaries.forEach((e) => console.log(e));
    }
}

const crashSimulator = new TransceptaCrashSimulator();
window.transceptaCrashSimulator = crashSimulator;

export default crashSimulator;
