import { CHANGE_FIXED_MISC_AMOUNT_COUNT } from '../../actions/invoiceCreation/fixedMiscAmountCount';

const fixedMiscAmountCount = (state = { value: 0 }, { type, payload }) => {
    switch (type) {
        case CHANGE_FIXED_MISC_AMOUNT_COUNT:
            return {
                ...state,
                value: payload,
            };

        default:
            return state;
    }
};

export default fixedMiscAmountCount;
