import { FC } from 'react';
import { Theme, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { HeaderMenu, IAccountMenu, IMenuItem } from '../HeaderMenu';
import { TopBarHeaderMenu } from './TopBarHeaderMenu';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        [theme.breakpoints.down('md')]: {
            marginTop: 64,
            marginLeft: 0,
        },
    },
}));

interface ITransitionalHeaderMenuProps {
    menuItems: IMenuItem[];
    accountMenu: IAccountMenu;
}

export const TransitionalHeaderMenu: FC<ITransitionalHeaderMenuProps> = ({ menuItems, accountMenu, children }) => {
    const drawerWidth = 280;

    const classes = useStyles();
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            {isMdDown && <HeaderMenu menuItems={menuItems} accountMenu={accountMenu} drawerWidth={drawerWidth} />}
            {!isMdDown && <TopBarHeaderMenu menuItems={menuItems} accountMenu={accountMenu} />}
            <div className={classes.root}>{children}</div>
        </>
    );
};
