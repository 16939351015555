import { AxiosResponse } from 'axios';
import { BusinessDocType } from '../../../types';
import { api } from '../../api';
import { IDocumentViewModel, IDocumentValidationFailureViewModel } from './ViewModels';

export class DocumentApi {
    route = '/Document';

    public getDocumentSkeleton = async (): Promise<AxiosResponse<IDocumentViewModel | undefined>> => {
        return api().get(`${this.route}/Skeleton?businessDocType=1&client=1`);
    };

    public getDocumentSkeletonByType = async (
        businessDocType: number
    ): Promise<AxiosResponse<IDocumentViewModel | undefined>> => {
        return api().get(`${this.route}/Skeleton?businessDocType=${businessDocType}&client=1`);
    };

    public getDocument = async (documentId: number): Promise<AxiosResponse<IDocumentViewModel | undefined>> => {
        return api().get(`${this.route}/${documentId}`);
    };

    public getDocumentByDraftId = async (
        draftId: number,
        businessDocType: BusinessDocType
    ): Promise<AxiosResponse<IDocumentViewModel | undefined>> => {
        const params = {
            businessDocDraftId: draftId,
            businessDocType,
        };

        return api().get(`${this.route}/Draft`, { params });
    };

    public postIDEPortalValidations = async (
        documentViewModel: IDocumentViewModel | undefined,
        buyerCompanyId: number
    ): Promise<AxiosResponse<IDocumentValidationFailureViewModel[] | undefined>> => {
        const url = `${this.route}/Validate?buyerCompanyId=${buyerCompanyId}`;
        return api().post(url, documentViewModel);
    };
}
