import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Cache } from '../../../utils/cache';
import { requestToKey, normalizeRequestUrl } from './utils';
import { IEndpointsConfig } from './EndpointsConfig';

interface ICachedResponse<T = unknown> {
    data: T;
    status: number;
    statusText: string;
    headers: any;
    invalidationKey: string | undefined;
}

class CacheGetRequestSignal extends Error {
    constructor(readonly request: AxiosRequestConfig, readonly result: ICachedResponse) {
        super('skip request');

        Object.setPrototypeOf(this, CacheGetRequestSignal.prototype);
    }
}

function isRevalidationRequest(request: AxiosRequestConfig) {
    return request.params?.$isRevalidation$;
}

function createRevalidationRequest(request: AxiosRequestConfig): AxiosRequestConfig {
    return {
        ...request,
        params: {
            ...request.params,
            $isRevalidation$: true,
        },
    };
}

function cleanupRevalidationRequest(request: AxiosRequestConfig): AxiosRequestConfig {
    const requestCopy = {
        ...request,
        params: {
            ...request.params,
        },
    };

    delete requestCopy.params.$isRevalidation$;
    if (Object.keys(requestCopy.params).length === 0) {
        delete requestCopy.params;
    }

    return requestCopy;
}

function findConfiguration(endpointsConfig: IEndpointsConfig, request: AxiosRequestConfig) {
    const url = normalizeRequestUrl(request.url);
    const match = endpointsConfig.endpoints.find(([m]) => {
        return (
            (typeof m === 'string' && m === url) ||
            (typeof m === 'object' && url?.match(m)) ||
            (typeof m === 'function' && m(url || ''))
        );
    });
    const endpointConfiguration = match ? match[1] : {};

    return endpointConfiguration;
}

export function invalidateCacheResponsesWithKey(cache: Cache, invalidationKey: string) {
    cache.getEntries().forEach((entry) => {
        const value = entry.value.value as ICachedResponse;

        if (value.invalidationKey === invalidationKey) {
            cache.remove(entry.value.originalKey);
        }
    });
}

function performDeclarativeInvalidation(cache: Cache, endpointsConfig: IEndpointsConfig, request: AxiosRequestConfig) {
    const url = normalizeRequestUrl(request.url);

    // perform invalidation for every endpoint that has caching configured
    endpointsConfig.endpoints.forEach(([, config]) => {
        const { invalidationKey, invalidationConditions } = config;

        // if the config has no invalidation key, we cannot remove responses for it, so
        // skip over any invalidation conditions it might define
        if (!invalidationKey) {
            return;
        }

        // if any invalidation condition for the endpoint matches the request, invalidate the response cache
        // for the endpoint
        if ((invalidationConditions || []).some((condition) => condition(url || '', request))) {
            invalidateCacheResponsesWithKey(cache, invalidationKey);
        }
    });
}

/**
 * Caches responses for get requests
 * @param instance the axios instance to add caching to
 * @param cache the cache to store response data in
 * @param endpointsConfig configuration for endpoint caching. defaults to a config that caches nothing.
 */
export function cacheGetRequests(
    instance: AxiosInstance,
    cache: Cache,
    endpointsConfig: IEndpointsConfig = {
        mode: 'optIn',
        endpoints: [],
        defaultCacheTTL: undefined,
        defaultStaleTTL: undefined,
    }
) {
    instance.interceptors.request.use((request) => {
        const endpointConfig = findConfiguration(endpointsConfig, request);

        // only cache get requests
        if (request.method !== 'get' && request.method !== 'GET') {
            return request;
        }

        // skip endpoints not configured for caching
        if (endpointsConfig.mode === 'optOut' && endpointConfig.optOut) {
            return request;
        }
        if (endpointsConfig.mode === 'optIn' && !endpointConfig.optIn) {
            return request;
        }

        // allow revalidation requests to pass through
        if (isRevalidationRequest(request)) {
            return cleanupRevalidationRequest(request);
        }

        // skip the request if a result is in the cache
        const result = cache.get<ICachedResponse>(requestToKey(request));
        if (result !== null) {
            if (endpointConfig.revalidateWhileStale && cache.isStale(requestToKey(request))) {
                setTimeout(() => {
                    instance.request(createRevalidationRequest(request));
                });
            }

            throw new CacheGetRequestSignal(request, result);
        }

        return request;
    });

    instance.interceptors.response.use(
        (response) => {
            // perform invalidation after successful response
            performDeclarativeInvalidation(cache, endpointsConfig, response.config);

            // only cache successful responses
            if (response.status >= 200 && response.status < 300) {
                const endpointConfig = findConfiguration(endpointsConfig, response.config);
                // do not waste cache entries on endpoints that are skipped in the request interceptor
                if (endpointsConfig.mode === 'optOut' && endpointConfig.optOut) {
                    return response;
                }
                if (endpointsConfig.mode === 'optIn' && !endpointConfig.optIn) {
                    return response;
                }

                cache.set<ICachedResponse>(
                    requestToKey(response.config),
                    {
                        data: response.data,
                        status: response.status,
                        statusText: response.statusText,
                        headers: response.headers,
                        invalidationKey: endpointConfig.invalidationKey,
                    },
                    endpointConfig.cacheTTL,
                    endpointConfig.staleTTL
                );
            }

            return response;
        },
        (error) => {
            // return cached response when request was skipped due to
            // a response being cached
            if (error instanceof CacheGetRequestSignal) {
                const response: AxiosResponse<any> = {
                    ...error.result,
                    config: error.request,
                };

                return response;
            }

            return Promise.reject(error);
        }
    );
}
