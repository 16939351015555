import { AxiosResponse } from 'axios';

import { api } from '../../api';
import { IBusinessDocTypeViewModel, IBusinessDocTypeViewModelData } from './ViewModels/BusinessDocTypeViewModel';

interface IGetAllBusinessDocTypeParams {
    $filter?: string;
    $orderBy?: string;
}

export class BusinessDocTypeApi {
    route = '/BusinessDocType';

    public getAllBusinessDocTypes = async (
        params?: IGetAllBusinessDocTypeParams
    ): Promise<AxiosResponse<IBusinessDocTypeViewModel[]>> => {
        return api().get(`${this.route}`, { params });
    };

    public updateBusinessDocumentType = async (
        businessDocTypeId: number,
        businessDocSubTypeId: number,
        businessDocType: IBusinessDocTypeViewModelData
    ): Promise<void> => {
        const params = {
            businessDocTypeId,
            businessDocSubTypeId,
        };
        return api().put(this.route, businessDocType, { params });
    };

    public insertBusinessDocumentType = async (
        businessDocType: IBusinessDocTypeViewModelData
    ): Promise<AxiosResponse<{ ID: number; SubID: number }>> => {
        return api().post(this.route, businessDocType);
    };

    public deleteBusinessDocumentType = async (
        businessDocTypeId: number,
        businessDocSubTypeId: number
    ): Promise<AxiosResponse<{ ID: number; SubID: number }>> => {
        const params = {
            businessDocTypeId,
            businessDocSubTypeId,
        };
        return api().delete(this.route, { params });
    };
}
