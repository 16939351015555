import { IFieldType, createFieldTypeFactory } from '../infrastructure';
import { GenericEnum } from '../../../../ui';

/**
 * A dropdown field object.
 */
export interface IEnumDropdownField extends IFieldType<number | null> {
    type: 'IEnumDropdownField';

    /**
     * Prompt the user to select a value. Optional, no prompt
     * is displayed if not defined.
     */
    prompt?: string;

    /**
     * Label that is displayed if the current value is "null". Nothing
     * is displayed if not defined.
     */
    nullOptionLabel?: string;

    /**
     * The options the user can select from.
     */
    options: GenericEnum<any>;
}

/**
 * Creates a dropdown field object.
 */
export const createEnumDropdownField = createFieldTypeFactory<IEnumDropdownField>('IEnumDropdownField', null);
