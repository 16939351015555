import * as ProfilesThunk from '../../thunk/ProfilesThunk';
import * as CommonThunk from '../../thunk/CommonThunk';
import * as contactActions from '../contact';
import { cloneObjectHack } from '../../utils/dataConverter';

//

export const FETCH_COMPANY_PROFILES_EXECUTE = 'FETCH_COMPANY_PROFILES_EXECUTE';
export const FETCH_COMPANY_PROFILES_FAILURE = 'FETCH_COMPANY_PROFILES_FAILURE';
export const FETCH_COMPANY_PROFILES_SUCCESS = 'FETCH_COMPANY_PROFILES_SUCCESS';
export const SAVE_SELECTED_PROFILE_EXECUTE = 'SAVE_SELECTED_PROFILE_EXECUTE';
export const SAVE_SELECTED_PROFILE_FAILURE = 'SAVE_SELECTED_PROFILE_FAILURE';
export const SAVE_SELECTED_PROFILE_SUCCESS = 'SAVE_SELECTED_PROFILE_SUCCESS';
export const SAVE_SELECTED_PROFILE_VAT_ARCHIVE_SERVICE_EXECUTE = 'SAVE_SELECTED_PROFILE_VAT_ARCHIVE_SERVICE_EXECUTE';
export const SAVE_SELECTED_PROFILE_VAT_ARCHIVE_SERVICE_FAILURE = 'SAVE_SELECTED_PROFILE_VAT_ARCHIVE_SERVICE_FAILURE';
export const SAVE_SELECTED_PROFILE_VAT_ARCHIVE_SERVICE_SUCCESS = 'SAVE_SELECTED_PROFILE_VAT_ARCHIVE_SERVICE_SUCCESS';
export const SEARCH_PROFILES_SUCCESS = 'SEARCH_PROFILES_SUCCESS';
export const SEARCH_PROFILES_PENDING = 'SEARCH_PROFILES_PENDING';
export const SEARCH_PROFILES_FAILED = 'SEARCH_PROFILES_FAILED';
export const SELECTED_PROFILE_CLEAR = 'SELECTED_PROFILE_CLEAR';
export const SELECTED_PROFILE_SET = 'SELECTED_PROFILE_SET';
export const SELECTED_PROFILE_LOGO_SET = 'SELECTED_PROFILE_LOGO_SET';
export const SET_SELECTED_PROFILE_VALIDATION_ERRORS = 'SET_SELECTED_PROFILE_VALIDATION_ERRORS';
export const UPDATE_COMPANY_PROFILES_PAGE = 'UPDATE_COMPANY_PROFILES_PAGE';
export const UPDATE_COMPANY_PROFILES_SORT = 'UPDATE_COMPANY_PROFILES_SORT';
export const ACCOUNT_PROFILE_SET = 'ACCOUNT_PROFILE_SET';
export const ACCOUNT_PROFILE_FAILED = 'ACCOUNT_PROFILE_FAILED';
export const SET_ACCOUNT_PROFILE_VALIDATION_ERRORS = 'SET_ACCOUNT_PROFILE_VALIDATION_ERRORS';
/*-------------------------------------------------------------------------------*/

/**
 * Search profiles success action
 */
export const searchProfilesSuccessAction = (data) => ({
    type: SEARCH_PROFILES_SUCCESS,
    payload: data,
});

/**
 * Search profiles error action
 */
export const searchProfilesErrorAction = (error) => ({
    type: SEARCH_PROFILES_FAILED,
    payload: error,
});

/**
 * Search profiles
 */
export const searchProfiles = (params) => ({
    type: SEARCH_PROFILES_PENDING,
    payload: { params: params },
});

/*-------------------------------------------------------------------------------*/

export const fetchCompanyProfilesExecute = (params) => {
    return {
        type: FETCH_COMPANY_PROFILES_EXECUTE,
        payload: {
            params: params,
        },
    };
};

export const fetchCompanyProfilesSuccess = (data, profileType) => {
    return {
        type: FETCH_COMPANY_PROFILES_SUCCESS,
        payload: {
            data: data,
            profileType: profileType,
        },
    };
};

export const updateCompanyProfilesPage = (profileType, page) => {
    return {
        type: UPDATE_COMPANY_PROFILES_PAGE,
        payload: {
            page: page,
            profileType: profileType,
        },
    };
};

export const updateCompanyProfilesSort = (profileType, sortKey, sortDirection) => {
    return {
        type: UPDATE_COMPANY_PROFILES_SORT,
        payload: {
            profileType: profileType,
            sortDirection: sortDirection,
            sortKey: sortKey,
        },
    };
};

/*-------------------------------------------------------------------------------*/

export const selectedProfileSet = (profile, vatArchiveService) => {
    return {
        type: SELECTED_PROFILE_SET,
        payload: {
            profile: profile,
            vatArchiveService: vatArchiveService,
        },
    };
};

export const selectedProfileLogoSet = (file, url) => {
    return {
        type: SELECTED_PROFILE_LOGO_SET,
        payload: {
            file: file,
            url: url,
        },
    };
};

export const selectedProfileClear = () => {
    return {
        type: SELECTED_PROFILE_CLEAR,
    };
};

/*-------------------------------------------------------------------------------*/

export const saveSelectedProfileExecute = (params) => {
    return {
        type: SAVE_SELECTED_PROFILE_EXECUTE,
        payload: {
            params: params,
        },
    };
};

export const saveSelectedProfileSuccess = (data) => {
    return {
        type: SAVE_SELECTED_PROFILE_SUCCESS,
        payload: data,
    };
};

export const saveSelectedProfileVatArchiveServiceExecute = (params) => {
    return {
        type: SAVE_SELECTED_PROFILE_VAT_ARCHIVE_SERVICE_EXECUTE,
        payload: {
            params: params,
        },
    };
};

export const saveSelectedProfileVatArchiveServiceSuccess = (data) => {
    return {
        type: SAVE_SELECTED_PROFILE_VAT_ARCHIVE_SERVICE_SUCCESS,
        payload: data,
    };
};

export const setSelectedProfileValidationErrors = (validationErrors) => {
    return {
        type: SET_SELECTED_PROFILE_VALIDATION_ERRORS,
        payload: validationErrors,
    };
};

export const accountProfileSet = (payload) => {
    return {
        type: ACCOUNT_PROFILE_SET,
        payload,
    };
};

export const accountProfileValidationErrors = (validationErrors) => {
    return {
        type: SET_ACCOUNT_PROFILE_VALIDATION_ERRORS,
        payload: validationErrors,
    };
};

//thunks calls
export const sendProfileSupportRequest = (profileType) => async (dispatch, getState) => {
    let state = getState();
    const contact = cloneObjectHack(state.contact);
    await dispatch(ProfilesThunk.saveSelectedProfile(profileType, false));
    await dispatch(CommonThunk.sendEmail('PODeliveryMethodStatus', contact));
};

export const initializeContact = (name, email, phone) => (dispatch) => {
    dispatch(contactActions.updateContact(name));
    dispatch(contactActions.updateEmail(email));
    dispatch(contactActions.updatePhone(phone));
};
