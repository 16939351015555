import { InternalRule } from '../useInternalRules';
import { handleResultsForStep3 } from './handleResultsForStep3';
import { oneSegmentFilledValidation, addsUpValidation, checkPONumberExists } from './Validations';

export const createGLCodingPODependent = (config: { addsUp: boolean; oneSegmentFilled: boolean }): InternalRule => ({
    name: 'glCodingPODependent',
    logic: (app) => {
        app.registerUIDocumentController(({ documentState }, fieldController) => {
            if (checkPONumberExists(documentState.BusinessDocFields.Invoice)) {
                fieldController.hideField(app.services.associatedField.glCodingSection());
            } else {
                fieldController.hideField(app.services.associatedField.bodyFieldAllLines('POLineNumCalc'));
            }
        });

        app.registerValidationFunction((state, results) => {
            if (state.type !== 'UserWorkflowActivityParams') {
                return;
            }

            const { documentState, glMode } = state;

            if (glMode == null) {
                return;
            }

            results = handleResultsForStep3(state.workflowApprovalType, state.workflowActivity, results);

            if (checkPONumberExists(documentState.BusinessDocFields.Invoice)) {
                return;
            }

            if (config.oneSegmentFilled) {
                oneSegmentFilledValidation({
                    glMode,
                    invoice: documentState.BusinessDocFields.Invoice,
                    onValidationFailure: {
                        line: ({ lineNumber, splitNumber }) =>
                            results.addError({
                                message: `No segment filled on split ${splitNumber}`,
                                associatedField: app.services.associatedField.glField({
                                    lineNumber,
                                    splitNumber,
                                    fieldName: 'glSplit',
                                }),
                            }),
                        header: (splitNumber) =>
                            results.addError({
                                message: `No segment filled on split ${splitNumber}`,
                                associatedField: app.services.associatedField.glHeaderField({
                                    splitNumber,
                                    fieldName: 'glSplit',
                                }),
                            }),
                    },
                });
            }

            if (config.addsUp) {
                addsUpValidation({
                    glMode,
                    invoice: documentState.BusinessDocFields.Invoice,
                    onValidationFailure: {
                        line: (lineNumber) =>
                            results.addError({
                                message: 'Amount allocation does not add up',
                                associatedField: app.services.associatedField.glField({
                                    lineNumber,
                                    splitNumber: 1,
                                    fieldName: 'glSplit',
                                }),
                            }),
                        header: () =>
                            results.addError({
                                message: 'Amount allocation does not add up',
                                associatedField: app.services.associatedField.glHeaderField({
                                    splitNumber: 1,
                                    fieldName: 'glSplit',
                                }),
                            }),
                    },
                });
            }
        });
    },
    fieldNamesToValidateOnFocusLoss: ['glSplit', 'PurchaseOrder', 'PurchaseOrderNum', 'WorkflowApprovalType'],
    eventsToValidateOn: [
        'FocusLossEvent',
        'WorkflowLoadEvent',
        'WorkflowReassignEvent',
        'WorkflowSaveEvent',
        'WorkflowSubmitEvent',
    ],
});
