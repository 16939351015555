import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useGenericPageTemplateStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    body: {
        width: '100%',
        display: 'flex',
    },
    title: {
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `${theme.spacing(1)} 0`,
        flexWrap: 'wrap',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
}));
