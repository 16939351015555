import { FCNC } from '../../ui';
import * as React from 'react';
import { Box, List, ListItem, ListItemButton, ListItemText, Typography, Divider, Stack } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { AuditLogUIViewModel, EntityDataObjectUI } from '../../services/backendServices/ViewModels';
import { format } from 'date-fns';

interface IAuditLogProps {
    logData: Map<string, AuditLogUIViewModel>;
}

interface IAuditListRowProps {
    title: string;
    date: string;
    time: string;
}

interface IAuditListRowsProps {
    listRows: IAuditListRowProps[];
    setSelectedIndex: (index: number) => void;
    selectedIndex: number;
}

interface ILogDetailsProps {
    details: Record<string, EntityDataObjectUI> | undefined;
}

const AuditListRows: FCNC<IAuditListRowsProps> = ({ listRows, setSelectedIndex, selectedIndex }) => {
    const individualRows = listRows.map((list: IAuditListRowProps, id: number) => {
        return (
            <div key={id} data-testid={`audit-log-list-item-${id}`}>
                <ListItem key={id} component="div" disablePadding>
                    <ListItemButton
                        data-testid="audit-log-list-item-button"
                        onClick={() => {
                            setSelectedIndex(id);
                        }}
                        selected={selectedIndex === id}
                    >
                        <ListItemText
                            primary={<Typography data-testid="audit-log-list-item-title">{list.title}</Typography>}
                        />
                        <Stack direction="column" alignItems="end">
                            <ChevronRightIcon data-testid="audit-log-list-item-chevron" />
                            <Typography data-testid="audit-log-list-item-date"> {list.date}</Typography>
                            <Typography data-testid="audit-log-list-item-time"> {list.time}</Typography>
                        </Stack>
                    </ListItemButton>
                </ListItem>
                <Divider component="li" />
            </div>
        );
    });
    return <List sx={{ py: 0 }}>{individualRows}</List>;
};

const LogDetails: FCNC<ILogDetailsProps> = ({ details }) => {
    return (
        <>
            {details && (
                <div data-testid="audit-log-item-details">
                    <Typography
                        data-testid="audit-log-item-details-changed-fields"
                        sx={{ fontWeight: 'bold', gap: 1, mb: 2 }}
                    >
                        Changed Fields:
                    </Typography>
                    <Stack gap={2}>
                        {Object.keys(details).map((key) => (
                            <Box key={key}>
                                {key}:{' '}
                                {!details[key].isList || (details[key].isList && details[key].OldValue.length) ? (
                                    <Typography
                                        data-testid="audit-log-item-details-old-value"
                                        sx={{ color: 'error.500' }}
                                    >
                                        {details[key].isList
                                            ? `Removed: ${details[key].OldValue.join(', ')}`
                                            : `Old value: ${details[key].OldValue.join(', ')}`}
                                    </Typography>
                                ) : null}
                                {!details[key].isList || (details[key].isList && details[key].NewValue.length) ? (
                                    <Typography
                                        data-testid="audit-log-item-details-new-value"
                                        sx={{ color: 'primary.main' }}
                                    >
                                        {details[key].isList
                                            ? `Added: ${details[key].NewValue.join(', ')}`
                                            : `New value: ${details[key].NewValue.join(', ')}`}
                                    </Typography>
                                ) : null}
                            </Box>
                        ))}
                    </Stack>
                </div>
            )}
        </>
    );
};

export const AuditLog: FCNC<IAuditLogProps> = ({ logData }: IAuditLogProps) => {
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const listRows: IAuditListRowProps[] = [];
    const groupIDs: string[] = [];

    logData.forEach((group: AuditLogUIViewModel, groupID: string) => {
        const title = `User '${group.UserName}' made changes:`;
        const date = format(new Date(group.CreatedTime), 'MM/dd/yy');
        const time = format(new Date(group.CreatedTime), 'hh:mm a');
        listRows.push({ title, date, time });
        groupIDs.push(groupID);
    });

    return (
        <div data-testid="audit-log-component">
            {logData && logData.size ? (
                <Box
                    sx={{
                        width: '100%',
                        height: 400,
                        display: 'flex',
                        border: '3px solid',
                        borderColor: 'divider',
                        overflowY: 'auto',
                        overflowX: 'auto',
                        whiteSpace: 'pre-wrap',
                    }}
                >
                    <Box
                        sx={{
                            width: '50%',
                            overflowY: 'auto',
                            overflowX: 'auto',
                            border: '1px solid',
                            borderColor: 'divider',
                        }}
                    >
                        <AuditListRows
                            listRows={listRows}
                            setSelectedIndex={setSelectedIndex}
                            selectedIndex={selectedIndex}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: '50%',
                            overflowY: 'auto',
                            overflowX: 'auto',
                            padding: '20px',
                        }}
                    >
                        <LogDetails details={logData.get(groupIDs[selectedIndex])?.EntityData} />
                    </Box>
                </Box>
            ) : null}
        </div>
    );
};
