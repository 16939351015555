import { EnhancedComponentProps } from './EnhancedComponentProps';

export interface ISafeHTMLString {
    type: 'SafeHTMLString';
    value: string;
}

function checkIsSafe(value: ISafeHTMLString) {
    if (!value || value.type !== 'SafeHTMLString') {
        throw new Error('Refusing to operate on non-safe HTML');
    }
}

export function isSafeHTMLString(value: any): value is ISafeHTMLString {
    return value && value.type === 'SafeHTMLString';
}

/**
 * Creates a SafeHTMLString value from a string.
 *
 * This should only be used when you are certain the value is safe
 * to embed as HTML.
 *
 * @param value the value to declare as a SafeHTMLString
 * @returns the SafeHTMLString
 */
export function createSafeHTMLString(value: string): ISafeHTMLString {
    return {
        type: 'SafeHTMLString',
        value,
    };
}

/**
 * Format a SafeHTMLString that has placeholders with replacements for the placeholders.
 * @param value the format string
 * @param data the replacement values
 * @returns the formatted string as a SafeHTMLString
 */
export function formatSafeHTMLString(
    value: ISafeHTMLString,
    data: { [key: string]: ISafeHTMLString | number | boolean }
) {
    let str = value.value;

    const getReplacement = (key: string) => {
        const val = data[key];
        if (typeof val === 'number' || typeof val === 'boolean') {
            return val.toString();
        }

        checkIsSafe(val);
        return val.value;
    };

    Object.keys(data).forEach((key) => {
        str = str.replace(new RegExp(`\\{\\{${key}\\}\\}`, 'g'), getReplacement(key));
    });

    // This is safe because the value is composed entirely of SafeHTMLString values
    // eslint-disable-next-line rulesdir/no-create-safe-html-without-reason
    return createSafeHTMLString(str);
}

export interface IEmbedHTMLProps {
    safeHTMLString: ISafeHTMLString;
}

/**
 * Renders a SafeHTMLString using another component that supports dangerouslySetInnerHTML.
 *
 * The usage is always safe because this component will never accept an unsafe HTML string.
 *
 * @param props the props which contain the underlying component, the SafeHTMLString, and the underlying component's props
 */
export function EmbedHTML<P>(props: EnhancedComponentProps<P, IEmbedHTMLProps>) {
    const { component: Component, safeHTMLString, ...rest } = props as any;

    checkIsSafe(safeHTMLString);

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Component {...rest} dangerouslySetInnerHTML={{ __html: safeHTMLString.value }} />
    );
}
