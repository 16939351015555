import { FC, useState } from 'react';
import { DumbPdfViewer } from './DumbPdfViewer';

interface IState {
    numPages: number;
    pageNumber: number;
}

export interface IPdfViewerProps {
    file: File;
}

export const PdfViewer: FC<IPdfViewerProps> = ({ file }) => {
    const [state, setState] = useState<IState>({
        numPages: 0,
        pageNumber: 1,
    });

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setState({
            ...state,
            numPages,
        });
    };

    const setPage = (page: number) => {
        setState({
            ...state,
            pageNumber: page + 1,
        });
    };

    return (
        <DumbPdfViewer
            file={file}
            onDocumentLoadSuccess={onDocumentLoadSuccess}
            setPage={setPage}
            numPages={state.numPages}
            pageNumber={state.pageNumber}
        />
    );
};
