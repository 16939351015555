import { IFlashMessage } from './IFlashMessage';

/**
 * Creates history state object containing flash messages
 * @param flashMessages the messages to add to the new history state
 * @returns history state object
 */
export function createFlashMessagesHistoryState(flashMessages: IFlashMessage[]) {
    return {
        flashMessages,
    };
}
