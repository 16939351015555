import { JSONSchemaType } from 'ajv';
import { ISearchCriteria, SearchCriteriaType } from '../types';
import { createStringParser, createValidatorFromParser } from '../../../utils/dataParser';

const searchCriteriaSchema: JSONSchemaType<ISearchCriteria[]> = {
    type: 'array',
    items: {
        type: 'object',
        discriminator: { propertyName: 'Type' },
        required: ['Type'],
        oneOf: [
            {
                type: 'object',
                properties: {
                    Type: { const: SearchCriteriaType.Input },
                    FieldName: { type: 'string' },
                    Column: { type: 'number' },
                    Label: { type: 'string' },
                    Row: { type: 'number' },
                },
                required: ['Column', 'Row', 'FieldName', 'Label'],
                additionalProperties: false,
            },
            {
                type: 'object',
                properties: {
                    Type: { const: SearchCriteriaType.NumericRange },
                    FieldName: { type: 'string' },
                    Column: { type: 'number' },
                    Label: { type: 'string' },
                    Row: { type: 'number' },
                    PlaceHolder1: { type: 'string' },
                    PlaceHolder2: { type: 'string' },
                },
                required: ['Column', 'Row', 'FieldName', 'Label', 'PlaceHolder1', 'PlaceHolder2'],
                additionalProperties: false,
            },
            {
                type: 'object',
                properties: {
                    Type: { const: SearchCriteriaType.DateRange },
                    FieldName: { type: 'string' },
                    Column: { type: 'number' },
                    Label: { type: 'string' },
                    Row: { type: 'number' },
                    PlaceHolder1: { type: 'string' },
                    PlaceHolder2: { type: 'string' },
                },
                required: ['Column', 'Row', 'FieldName', 'Label', 'PlaceHolder1', 'PlaceHolder2'],
                additionalProperties: false,
            },
            {
                type: 'object',
                properties: {
                    Type: { const: SearchCriteriaType.Select },
                    FieldName: { type: 'string' },
                    Column: { type: 'number' },
                    Label: { type: 'string' },
                    Row: { type: 'number' },
                    Multi: { type: 'boolean' },
                    Options: {
                        type: 'array',
                        items: {
                            type: 'object',
                            properties: { Label: { type: 'string' }, Value: { type: ['string', 'number'] } },
                            required: ['Label', 'Value'],
                        },
                    },
                },
                required: ['Type', 'FieldName', 'Column', 'Label', 'Row'],
                additionalProperties: false,
            },
        ],
    },
    minItems: 1,
};

const parseSearchCriteria = createStringParser(searchCriteriaSchema, (error: any) => {
    if (error.keyword === 'discriminator') {
        return `must have a 'Type' of 'DataRange', 'Input', 'Select', or 'NumericRange'`;
    }

    return undefined;
});

export const validateSearchCriteria = createValidatorFromParser(parseSearchCriteria);
