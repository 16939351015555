import { createContext, useContext, FC } from 'react';

const NumericFieldTextRightAlignmentContext = createContext(false);

export const useIsNumericFieldRightAligned = () => {
    return useContext(NumericFieldTextRightAlignmentContext);
};

export const RightAlignNumericFields: FC<{}> = ({ children }) => {
    return (
        <NumericFieldTextRightAlignmentContext.Provider value={true}>
            {children}
        </NumericFieldTextRightAlignmentContext.Provider>
    );
};
