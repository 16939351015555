import { FC, Suspense } from 'react';
import { Loading } from '../Loading';
import { lazyNamed } from '../../utils';
const PdfViewer = lazyNamed(() => import('./PdfViewer'), 'PdfViewer');

interface IProps {
    file: File;
}

export const LazyLoadPdfViewer: FC<IProps> = ({ file }) => {
    return (
        <Suspense fallback={<Loading />}>
            <PdfViewer file={file} />
        </Suspense>
    );
};
