import f from 'odata-filter-builder';
import { DocumentTypeApi } from '../Api';
import { orderBy } from '../../utils';
import { GridSortModel } from '@mui/x-data-grid';
import { DocumentTypeViewModel } from '../Api/types';
import { DocumentTypeView, IExemptSupplierOption, ISelectedSuppliersOption } from './types/DocumentTypeView';
import { RecursivePartial } from '../../../types';
import { portalUserService } from '../../UserService';

export class DocumentTypeService {
    api = new DocumentTypeApi();

    private transformToDocumentTypeView(model: DocumentTypeViewModel): DocumentTypeView {
        return {
            approvalWorkflow: model.ApprovalWorkflow,
            attachmentOption: model.AttachmentOption,
            attachmentOption_US: model.AttachmentOption_US ?? '',
            buyerCompanyId: model.BuyerCompanyId,
            buyerCompanyName: model.BuyerCompanyName ?? '',
            createdTime: model.CreatedTime ?? '',
            dataEntrySchedule: model.DataEntrySchedule,
            dataEntrySchedule_US: model.DataEntrySchedule_US ?? '',
            description: model.Description,
            documentClass: model.DocumentClass,
            documentClass_US: model.DocumentClass_US ?? '',
            id: model?.Id ?? undefined,
            lastUpdateDate: model.LastUpdateDate ?? '',
            procurementProfileId: model.ProcurementProfileId,
            reminderEmailDestination: model.ReminderEmailDestination,
            reminderEmailDestination_US: model.ReminderEmailDestination_US ?? '',
            rowVersion: model.RowVersion ?? '',
            supplierSelection: model.SupplierSelection,
            supplierSelection_US: model.SupplierSelection_US ?? '',
            simDocumentTypeSupplierExclusions: model.SIMDocumentTypeSupplierExclusions?.map((v) => ({
                address: v?.Address ?? '',
                emailAddress: v?.EmailAddress,
                id: v?.Id,
                supplierClass: v?.VendorClass,
                supplierName: v?.Name,
                vendorNumber: v?.VendorNumber,
                vendorClass: v?.VendorClass,
                supplierCompanyId: v?.SupplierCompanyId,
                createdTime: v?.CreatedTime,
                lastUpdateDate: v?.LastUpdateDate,
                rowversion: v?.Rowversion,
                simDocumentTypeId: v?.SIMDocumentTypeId,
            })),
            simDocumentTypeSupplierInclusions: model.SIMDocumentTypeSupplierInclusions?.map((v) => ({
                address: v.Address,
                id: v.Id,
                supplierClass: v.VendorClass,
                supplierName: v.Name,
                vendorNumber: v.Address,
                simDocumentTypeId: v.SIMDocumentTypeId,
                supplierCompanyId: v.SupplierCompanyId,
                emailAddress: v.EmailAddress,
                createdTime: v.CreatedTime,
                rowVersion: v.RowVersion,
                lastUpdateDate: v.LastUpdateDate,
                name: v.Name,
                vendorClass: v.VendorClass,
            })),
            simDocumentTypeVendorClasses: model.SIMDocumentTypeVendorClasses,
            simDocumentTypeReminders: model?.SIMDocumentTypeReminders?.map((v) => ({
                tempId: String(v.Id),
                id: v.Id,
                createdTime: v.CreatedTime,
                lastUpdateDate: v.LastUpdateDate,
                rowVersion: v.RowVersion,
                daysInAdvance: String(v?.DaysInAdvance),
                portalText: v?.PortalText,
                emailSubject: v?.EmailSubjectLine,
                emailBody: v?.EmailBodyTemplate,
            })),
        };
    }

    private transformDataToUpdateDocumentTypeViewModel(val: DocumentTypeView): DocumentTypeViewModel {
        return {
            Id: val.id!,
            BuyerCompanyName: val.buyerCompanyName,
            ApprovalWorkflow: val.approvalWorkflow,
            AttachmentOption: val.attachmentOption,
            AttachmentOption_US: val.attachmentOption_US,
            BuyerCompanyId: val.buyerCompanyId,
            CreatedTime: val.createdTime,
            DataEntrySchedule: val.dataEntrySchedule,
            DataEntrySchedule_US: val.dataEntrySchedule_US,
            Description: val.description,
            DocumentClass: val.documentClass,
            DocumentClass_US: val.documentClass_US,
            LastUpdateDate: val.lastUpdateDate,
            ProcurementProfileId: val.procurementProfileId,
            DoNotDeliver: val.doNotDeliver,
            ReminderEmailDestination: val?.reminderEmailDestination,
            ReminderEmailDestination_US: val.reminderEmailDestination_US,
            RowVersion: val.rowVersion,
            SIMDocumentTypeReminders: val?.simDocumentTypeReminders?.map((v) => ({
                CreatedTime: v.createdTime,
                DaysInAdvance: Number(v?.daysInAdvance),
                EmailBodyTemplate: v?.emailBody,
                EmailSubjectLine: v?.emailSubject,
                LastUpdateDate: v?.lastUpdateDate,
                PortalText: v?.portalText,
                Rowversion: v?.rowVersion,
                SIMDocumentTypeId: v?.simDocumentTypeId,
            })),
            SIMDocumentTypeSupplierExclusions: val?.simDocumentTypeSupplierExclusions?.map(
                (v: IExemptSupplierOption) => ({
                    Address: v.address,
                    EmailAddress: v.emailAddress,
                    Name: v.supplierName,
                    SupplierCompanyId: v.supplierCompanyId,
                    VendorClass: v.supplierClass,
                    VendorNumber: v.vendorNumber,
                    CreatedTime: v.createdTime,
                    LastUpdateDate: v.lastUpdateDate,
                    Rowversion: v.rowversion,
                    SIMDocumentTypeId: v.simDocumentTypeId,
                })
            ),
            SIMDocumentTypeSupplierInclusions: val?.simDocumentTypeSupplierInclusions?.map(
                (v: ISelectedSuppliersOption) => ({
                    SIMDocumentTypeId: v?.simDocumentTypeId,
                    SupplierCompanyId: v?.supplierCompanyId,
                    VendorNumber: v?.vendorNumber,
                    Address: v?.address,
                    EmailAddress: v?.emailAddress ?? null,
                    CreatedTime: v?.createdTime,
                    Rowversion: v?.rowVersion,
                    LastUpdateDate: v?.lastUpdateDate ?? '',
                    Name: v?.supplierName ?? v?.name,
                    VendorClass: v?.supplierClass,
                    RowVersion: v.rowVersion,
                })
            ),
            SIMDocumentTypeVendorClasses: val?.simDocumentTypeVendorClasses?.map((v) => ({
                VendorClassId: v?.id,
            })),
            SupplierSelection: val.supplierSelection,
            SupplierSelection_US: val.supplierSelection_US,
        };
    }

    private transformDataToCreateDocumentTypeViewModel(val: DocumentTypeView): RecursivePartial<DocumentTypeViewModel> {
        return {
            ApprovalWorkflow: val.approvalWorkflow,
            AttachmentOption: val.attachmentOption,
            DataEntrySchedule: val.dataEntrySchedule,
            Description: val.description,
            BuyerCompanyId: portalUserService.getCurrentCompanyId(),
            DocumentClass: val.documentClass,
            ProcurementProfileId: val.procurementProfileId,
            DoNotDeliver: val.doNotDeliver,
            ReminderEmailDestination: val?.reminderEmailDestination,
            SIMDocumentTypeReminders: val?.simDocumentTypeReminders?.map((v) => ({
                CreatedTime: v.createdTime,
                DaysInAdvance: Number(v?.daysInAdvance),
                EmailBodyTemplate: v?.emailBody,
                EmailSubjectLine: v?.emailSubject,
                LastUpdateDate: v?.lastUpdateDate,
                PortalText: v?.portalText,
                Rowversion: v?.rowVersion,
                SIMDocumentTypeId: v?.simDocumentTypeId,
            })),
            SIMDocumentTypeSupplierExclusions: val?.simDocumentTypeSupplierExclusions?.map((v) => ({
                Address: v.address,
                EmailAddress: v.emailAddress,
                Name: v.supplierName,
                SupplierCompanyId: v.supplierCompanyId,
                VendorClass: v.supplierClass,
                VendorNumber: v.vendorNumber,
                CreatedTime: v.createdTime,
                Id: v.id,
                LastUpdateDate: v.lastUpdateDate,
                Rowversion: v.rowversion,
                SIMDocumentTypeId: v.simDocumentTypeId,
            })),
            SIMDocumentTypeSupplierInclusions: val?.simDocumentTypeSupplierInclusions?.map((v) => ({
                Id: v?.id,
                SIMDocumentTypeId: v?.simDocumentTypeId,
                SupplierCompanyId: v?.supplierCompanyId,
                VendorNumber: v?.vendorNumber,
                Address: v?.address,
                EmailAddress: v?.emailAddress,
                CreatedTime: v?.createdTime,
                Rowversion: v?.rowVersion,
                LastUpdateDate: v?.lastUpdateDate,
                Name: v?.supplierName ?? v?.name,
                VendorClass: v?.supplierClass,
                RowVersion: v.rowVersion,
            })),
            SIMDocumentTypeVendorClasses: val?.simDocumentTypeVendorClasses?.map((v) => ({
                VendorClassId: v.id,
            })),
            SupplierSelection: val.supplierSelection,
        };
    }

    async createDocumentType(documentTypeView: DocumentTypeView): Promise<number> {
        const response = await this.api.createDocumentType(
            this.transformDataToCreateDocumentTypeViewModel(documentTypeView)
        );
        return response.data.ID;
    }

    async updateDocumentType(supplierCompanyId: number, documentTypeView: DocumentTypeView): Promise<void> {
        await this.api.updateDocumentType({
            companyId: supplierCompanyId,
            params: this.transformDataToUpdateDocumentTypeViewModel(documentTypeView),
        });
    }

    async searchDocumentType(supplierCompanyId: number, isBuyer: boolean): Promise<DocumentTypeView> {
        const response = await this.api.searchDocumentType({
            companyId: supplierCompanyId,
            isBuyer,
        });

        return this.transformToDocumentTypeView(response.data[0]);
    }

    /**
     * Returns the names of trading partners for a supplier that are set up
     * to receive remittance instructions.
     * @param supplierCompanyId the supplier company id to get names for
     * @returns the trading partner names.
     */
    async getTradingPartnersThatReceiveRemittanceInstructions(supplierCompanyId: number) {
        const response = await this.api.searchDocumentTypes({
            companyId: supplierCompanyId,
            isBuyer: false,
            $filter: f().eq('DocumentClass', 4).toString(),
        });

        const companyNames = response.data.map((x) => x.BuyerCompanyName);

        const uniqueCompanyNames = Array.from(new Set(companyNames));

        return uniqueCompanyNames;
    }

    async getBuyerSIMDocumentsTypeList(buyerCompanyId: number, sortModel: GridSortModel) {
        const $orderBy = orderBy(sortModel) || 'ID desc';

        const response = await this.api.searchDocumentTypes({
            companyId: buyerCompanyId,
            isBuyer: true,
            $orderBy,
        });

        return response.data.map((item) => ({ ...item, id: item.Id }));
    }
}
