import { FCNC } from '../FCNC';
import { DataGridServer } from './DataGridServer';

export const LoadingDataGridServer: FCNC = () => {
    return (
        <DataGridServer
            rowCount={0}
            columns={[]}
            pageSize={10}
            page={0}
            data={undefined}
            sortModel={[]}
            onPageSizeChange={() => {}}
            onPageChange={() => {}}
            onSortModelChange={() => {}}
            error={null}
            isLoading={true}
            rowsPerPageOptions={[10]}
        />
    );
};
