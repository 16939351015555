import { InternalRule } from '../useInternalRules';

export const validateGLCodingDropdownOptionSelections: InternalRule = {
    name: 'validateGLCodingDropdownOptionSelections',
    logic: (app) => {
        app.registerValidationFunction(async (state, results) => {
            if (state.type !== 'UserWorkflowActivityParams') {
                return;
            }

            if (state.glAccountStructure == null) {
                return;
            }

            const dropdownFields = state.glAccountStructure.glFields.filter((f) =>
                ['CompanyCustomData', 'CompanyGLAccount', 'Enum'].includes(f.dataType)
            );

            const pendingValidations = new Array<Promise<void>>();

            state.documentState.BusinessDocFields.Invoice.InvoiceLineItems.forEach((lineItem, lineIndex) => {
                lineItem.InvoiceLineItemCompanyGLAccounts.forEach((split, splitIndex) => {
                    const lineNumber = lineIndex + 1;

                    const splitNumber = splitIndex + 1;

                    pendingValidations.concat(
                        dropdownFields.map(async (dropdownField) => {
                            const isDefaultGLAccount =
                                dropdownField.dataType === 'CompanyGLAccount' &&
                                dropdownField.name &&
                                parseInt(dropdownField.companyGLAccountDepth || '-1', 10) === 1;

                            const specificFieldKey = app.services.associatedField.glField({
                                lineNumber,
                                splitNumber,
                                fieldName: dropdownField.name,
                            });

                            const sharedFieldKey = app.services.associatedField.glFieldAllSplits(dropdownField.name);

                            const fieldKey = isDefaultGLAccount ? sharedFieldKey : specificFieldKey;

                            const options = (
                                await app.services.dropdownOptionsRepository.getLatestDropdownOptions(fieldKey)
                            ).map((x) => x.value);

                            const field: any = Object.values(split.Coding.Coding).filter(
                                (f: any) => f.label === dropdownField.label
                            )[0];

                            if (field && field.value !== '' && !options.includes(field.value)) {
                                results.addError({
                                    message: `${
                                        field.value
                                    } is not in the set of allowed values for ${dropdownField.label.replace(
                                        /\:+/,
                                        ''
                                    )}. Please enter a different value to proceed.`,
                                    associatedField: app.services.associatedField.glField({
                                        lineNumber,
                                        splitNumber,
                                        fieldName: 'glSplit',
                                    }),
                                });
                            }
                        })
                    );
                });
            });

            state.documentState.BusinessDocFields.Invoice.InvoiceCompanyGLAccounts.forEach((lineItem) => {
                const { Coding } = lineItem.Coding;

                const fields = Object.values(Coding);

                pendingValidations.concat(
                    fields.map(async (segment: any) => {
                        const fieldKey = app.services.associatedField.glHeaderField({
                            splitNumber: 1,
                            fieldName: segment.label,
                        });

                        const options = (
                            await app.services.dropdownOptionsRepository.getLatestDropdownOptions(fieldKey)
                        ).map((x) => x.value);

                        if (segment && segment.value !== '' && !options.includes(segment.value)) {
                            results.addError({
                                message: `${
                                    segment.value
                                } is not in the set of allowed values for ${segment.label.replace(
                                    /\:+/,
                                    ''
                                )}. Please enter a different value to proceed.`,
                                associatedField: app.services.associatedField.glHeaderField({
                                    splitNumber: 1,
                                    fieldName: 'glSplit',
                                }),
                            });
                        }
                    })
                );
            });

            await Promise.all(pendingValidations);
        });
    },
    fieldNamesToValidateOnFocusLoss: [
        'GLNumber',
        'GLCodingUploadASplitOKButton',
        'glSplit',
        'GLCodingDotNotationInput',
    ],
    eventsToValidateOn: ['FocusLossEvent', 'WorkflowReassignEvent', 'WorkflowSaveEvent', 'WorkflowSubmitEvent'],
};
