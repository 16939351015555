import { api } from './api';
import apiPaths from './paths';

class CompanyThunk {
    constructor() {}

    fetchCompanyDepartments = (params) => {
        return api()
            .get(apiPaths.companyDepartmentList, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchCompanyGLAccounts = (params) => {
        return api()
            .get(apiPaths.glAccountList, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchCompany = (params) => {
        return api()
            .get(apiPaths.readCompany.replace(':companyId', params.companyId))
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    updateCompany = (params) => {
        return api()
            .put(apiPaths.updateCompany.replace(':companyId', params.companyId), params)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    // "customer locations" in the supplier portal UI
    fetchCustomers = (params) => {
        return api()
            .get(apiPaths.customerLocation, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchBuyerPortalSettings = (params) => {
        return api()
            .get(apiPaths.companyBuyerPortalSettings.replace(':id', params.companyID))
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    // "customer location" in the supplier portal UI
    addCustomer = (params) => {
        return api()
            .post(apiPaths.customerLocation, params)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    // "customers" in the supplier portal UI
    fetchTradingPartners = (params) => {
        return api()
            .get(apiPaths.tradingPartners, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchTradingPartnersSearch = (params) => {
        return api()
            .get('/Company/TradingPartners/Search', { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchVATArchiveService = (params) => {
        return api()
            .get(apiPaths.vatArchiveService, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    updateVATArchiveService = (params) => {
        return api()
            .put(apiPaths.updateVatArchiveService.replace(':id', params.vatArchiveServiceId), params.vatArchiveService)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    createVATArchiveService = (params) => {
        return api()
            .post(apiPaths.vatArchiveService, params.vatArchiveService)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchDynamicDiscounting = (params) => {
        return api()
            .get(apiPaths.companyDynamicDiscountingDocuments, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    updateDynamicDiscounting = (params) => {
        return api()
            .put(apiPaths.updateTradingPartners.replace(':supplierCompanyId', params.supplierCompanyId), { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    updateCustomerLocation = (params) => {
        return api()
            .put(apiPaths.updateCustomerLocation.replace(':ID', params.ID), params)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchTechnicalDocuments = (params) => {
        return api()
            .get(apiPaths.technicalDocuments, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchTechnicalDocument = (params) => {
        return api()
            .get(apiPaths.readTechnicalDocument.replace(':documentId', params.documentId), { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchVendorClasses = (params) => {
        return api()
            .get(apiPaths.readVendorClassList.replace(':companyId', params.companyId))
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchSSOSettings = (params) => {
        return api()
            .get(apiPaths.readSSOSettings, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchCompanyGLAccountStructure = (params) => {
        return api()
            .get(apiPaths.glAccountStructure, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchCompanyCustomDataSet = (params) => {
        return api()
            .get(apiPaths.companyCustomDataSet, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };
}

export default CompanyThunk;
