export enum ExternalStatus {
    Held = 1,
    Incomplete = 2,
    Processing = 3,
    PendingApproval = 11,
    Canceled = 12,
    Paid = 13,
    Rejected = 14,
    Scheduled = 15,
    Sent = 16,
    WillNotPay = 17,
    NotDelivered = 18,
    Fixed = 19, // Only used for rejected documents that were fixed and resent using a web portal. Do not show in buyer or supplier portal.
    NotSent = 20,
    Error = 21,
    Parked = 22,
    Accepted = 23, // POA Status
    AcknowledgedWithChanges = 24, // POA Status
    NoAcknowledgementNeeded = 25, // POA Status
    PORejected = 26, // POA Status
    Open = 27, // PO Status
    Closed = 28, // PO Status
    CanceledForPartialReprocess = 29, // Only used for documents in a batch that were not selected to be reprocessed when the batch was reprocessed. Do not show in buyer or supplier portal.
    Draft = 30,
}
