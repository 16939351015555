import { createSelector } from 'reselect';
import * as DocumentListActions from '../../actions/documentsListActions';
import projectConfig from '../../project.config.json';
import { cloneObject } from '../../utils/dataConverter';
import { strings } from '../../utils/localization/LocalizationStrings';
import { DocumentSearchUtil } from 'common/build/legacy/transcepta-common';

const businessDocType = (state) => state.businessDocType;
const currencyList = (state) => cloneObject(state.state.userlogin.currencyList);
const currentSize = (state) => state.state.browser.mediaType;

const layoutConfigs = (state) => state.state.documentSearch.layoutConfigs;

export const searchModelSelector = createSelector(
    businessDocType,
    currencyList,
    currentSize,
    layoutConfigs,

    (businessDocType, currencyList, currentSize, layoutConfigs) => {
        let searchModel = null;
        const layoutConfig = layoutConfigs[currentSize.charAt(0).toUpperCase() + currentSize.slice(1)];
        const searchLable = layoutConfig && layoutConfig.SearchResult.Label ? layoutConfig.SearchResult.Label : '';

        switch (businessDocType) {
            case projectConfig.businessDocType.ASN:
            case projectConfig.businessDocType.ASNGeneric:
                searchModel = new ASN(searchLable);
                break;
            case projectConfig.businessDocType.ConsumptionAdvice:
                searchModel = new ConsumptionAdvices(searchLable);
                break;
            case projectConfig.businessDocType.InventoryAdvice:
                searchModel = new InventoryAdvices(searchLable);
                break;
            case projectConfig.businessDocType.Invoice:
                searchModel = new Invoice(searchLable);
                break;
            case projectConfig.businessDocType.PurchaseOrder:
                searchModel = new PurchaseOrder(searchLable);
                break;
            case projectConfig.businessDocType.PurchaseOrderAcknowledgement:
                searchModel = new PurchaseOrderAcknowledgement(searchLable);
                break;
            case projectConfig.businessDocType.ReceivingAdvice:
                searchModel = new ReceivingAdvices(searchLable);
                break;
            case projectConfig.businessDocType.SIMDocument:
                searchModel = new SIMDocument(searchLable);
                break;
            default:
                break;
        }

        // If a layout config can be found
        if (layoutConfig) {
            searchModel.tableConfig = DocumentSearchUtil.getSearchResultConfig(
                layoutConfig.SearchResult.Fields,
                currencyList
            );
            searchModel.advancedSearchConfig = DocumentSearchUtil.getSearchConfig(layoutConfig.SearchCriteria);
            searchModel.noticeText =
                layoutConfig && layoutConfig.SearchResult.Text ? layoutConfig.SearchResult.Text : '';
        }

        return searchModel;
    }
);

function ASN(searchLable) {
    this.toggleSearch = false;
    this.noDefaultFetching = true;
    this.noDefaultFetchingSimple = null;
    this.onlyAdvancedSearch = true;
    this.pageSize = projectConfig.docPageSize;
    this.resultText = searchLable;
    this.itemFoundText = strings.documentNameSingleASN;
    this.itemsFoundText = strings.documentNamePluralASN;
    this.advancedSearchTitle = strings.h3AdvancedSearchTitleASN;
    this.searchBoxWide = null;
    this.stateName = 'asns';
    this.defaultSortColumn = 'CreatedTime';
    this.fetching = DocumentListActions.ASNS_FETCHING;
    this.fetched = DocumentListActions.ASNS_FETCHED;
    this.search = DocumentListActions.ASNS_SEARCH_ADVANCED;
    this.sorting = DocumentListActions.ASNS_APPLY_SORTING;
    this.clearSearch = DocumentListActions.ASNS_CLEAR_SEARCH;
}

function SIMDocument(searchLable) {
    this.toggleSearch = false;
    this.noDefaultFetching = true;
    this.noDefaultFetchingSimple = null;
    this.onlyAdvancedSearch = true;
    this.pageSize = projectConfig.docPageSize;
    this.resultText = searchLable;
    this.itemFoundText = strings.documentNameSingleSIMDocument;
    this.itemsFoundText = strings.documentNamePluralSIMDocument;
    this.advancedSearchTitle = strings.h3AdvancedSearchTitleSIM;
    this.searchBoxWide = null;
    this.stateName = 'sims';
    this.defaultSortColumn = 'CreatedTime';
    this.fetching = DocumentListActions.SIMS_FETCHING;
    this.fetched = DocumentListActions.SIMS_FETCHED;
    this.search = DocumentListActions.SIMS_SEARCH_ADVANCED;
    this.sorting = DocumentListActions.SIMS_APPLY_SORTING;
    this.clearSearch = DocumentListActions.SIMS_CLEAR_SEARCH;
}

function ConsumptionAdvices(searchLable) {
    this.toggleSearch = false;
    this.noDefaultFetching = true;
    this.noDefaultFetchingSimple = null;
    this.onlyAdvancedSearch = true;
    this.pageSize = projectConfig.docPageSize;
    this.resultText = searchLable;
    this.itemFoundText = strings.documentNameSingleConsumptionAdvice;
    this.itemsFoundText = strings.documentNamePluralConsumptionAdvice;
    this.advancedSearchTitle = strings.h3AdvancedSearchTitleConsumptionAdvice;
    this.searchBoxWide = null;
    this.stateName = 'consumptionAdvices';
    this.defaultSortColumn = 'CreatedTime';
    this.fetching = DocumentListActions.CONSUMPTION_ADVICES_FETCHING;
    this.fetched = DocumentListActions.CONSUMPTION_ADVICES_FETCHED;
    this.search = DocumentListActions.CONSUMPTION_ADVICES_SEARCH_ADVANCED;
    this.sorting = DocumentListActions.CONSUMPTION_ADVICES_APPLY_SORTING;
    this.clearSearch = DocumentListActions.CONSUMPTION_ADVICES_CLEAR_SEARCH;
}

function InventoryAdvices(searchLable) {
    this.toggleSearch = false;
    this.noDefaultFetching = true;
    this.noDefaultFetchingSimple = null;
    this.onlyAdvancedSearch = true;
    this.pageSize = projectConfig.docPageSize;
    this.resultText = searchLable;
    this.itemFoundText = strings.documentNameSingleInventoryAdvice;
    this.itemsFoundText = strings.documentNamePluralInventoryAdvice;
    this.advancedSearchTitle = strings.h3AdvancedSearchTitleInventoryAdvice;
    this.searchBoxWide = null;
    this.stateName = 'inventoryAdvices';
    this.defaultSortColumn = 'CreatedTime';
    this.fetching = DocumentListActions.INVENTORY_ADVICES_FETCHING;
    this.fetched = DocumentListActions.INVENTORY_ADVICES_FETCHED;
    this.search = DocumentListActions.INVENTORY_ADVICES_SEARCH_ADVANCED;
    this.sorting = DocumentListActions.INVENTORY_ADVICES_APPLY_SORTING;
    this.clearSearch = DocumentListActions.INVENTORY_ADVICES_CLEAR_SEARCH;
}

function Invoice(searchLable) {
    this.toggleSearch = false;
    this.noDefaultFetching = true;
    this.noDefaultFetchingSimple = null;
    this.onlyAdvancedSearch = true;
    this.pageSize = projectConfig.docPageSize;
    this.resultText = searchLable;
    this.itemFoundText = strings.documentNameSingleInvoice;
    this.itemsFoundText = strings.documentNamePluralInvoice;
    this.advancedSearchTitle = strings.h3AdvancedSearchTitleInvoice;
    this.searchBoxWide = null;
    this.stateName = 'invoices';
    this.defaultSortColumn = 'CreatedTime';
    this.fetching = DocumentListActions.INVOICES_FETCHING;
    this.fetched = DocumentListActions.INVOICES_FETCHED;
    this.search = DocumentListActions.INVOICES_SEARCH_ADVANCED;
    this.sorting = DocumentListActions.INVOICES_APPLY_SORTING;
    this.clearSearch = DocumentListActions.INVOICES_CLEAR_SEARCH;
}

function PurchaseOrder(searchLable) {
    this.toggleSearch = false;
    this.noDefaultFetching = true;
    this.noDefaultFetchingSimple = null;
    this.onlyAdvancedSearch = true;
    this.pageSize = projectConfig.docPageSize;
    this.resultText = searchLable;
    this.itemFoundText = strings.documentNameSinglePurchaseOrder;
    this.itemsFoundText = strings.documentNamePluralPurchaseOrder;
    this.advancedSearchTitle = strings.h3AdvancedSearchTitlePurchaseOrder;
    this.searchBoxWide = true;
    this.stateName = 'purchaseOrders';
    this.defaultSortColumn = 'CreatedTime';
    this.fetching = DocumentListActions.PURCHASE_ORDERS_FETCHING;
    this.fetched = DocumentListActions.PURCHASE_ORDERS_FETCHED;
    this.search = DocumentListActions.PURCHASE_ORDERS_SEARCH_ADVANCED;
    this.sorting = DocumentListActions.PURCHASE_ORDERS_APPLY_SORTING;
    this.clearSearch = DocumentListActions.PURCHASE_ORDERS_CLEAR_SEARCH;
}

function PurchaseOrderAcknowledgement(searchLable) {
    this.toggleSearch = false;
    this.noDefaultFetching = true;
    this.noDefaultFetchingSimple = null;
    this.onlyAdvancedSearch = true;
    this.pageSize = projectConfig.docPageSize;
    this.resultText = searchLable;
    this.itemFoundText = strings.documentNameSinglePurchaseOrderAcknowledgement;
    this.itemsFoundText = strings.documentNamePluralPurchaseOrderAcknowledgement;
    this.advancedSearchTitle = strings.h3AdvancedSearchTitlePurchaseOrderAcknowledgement;
    this.searchBoxWide = null;
    this.stateName = 'purchaseOrderAcknowledgements';
    this.defaultSortColumn = 'CreatedTime';
    this.fetching = DocumentListActions.PURCHASE_ORDER_ACKN_FETCHING;
    this.fetched = DocumentListActions.PURCHASE_ORDER_ACKN_FETCHED;
    this.search = DocumentListActions.PURCHASE_ORDER_ACKN_SEARCH_ADVANCED;
    this.sorting = DocumentListActions.PURCHASE_ORDER_ACKN_APPLY_SORTING;
    this.clearSearch = DocumentListActions.PURCHASE_ORDER_ACKN_CLEAR_SEARCH;
}

function ReceivingAdvices(searchLable) {
    this.toggleSearch = false;
    this.noDefaultFetching = true;
    this.noDefaultFetchingSimple = null;
    this.onlyAdvancedSearch = true;
    this.pageSize = projectConfig.docPageSize;
    this.resultText = searchLable;
    this.itemFoundText = strings.documentNameSingleReceivingAdvice;
    this.itemsFoundText = strings.documentNamePluralReceivingAdvice;
    this.advancedSearchTitle = strings.h3AdvancedSearchTitleReceivingAdvice;
    this.searchBoxWide = null;
    this.stateName = 'receivingAdvices';
    this.defaultSortColumn = 'CreatedTime';
    this.fetching = DocumentListActions.RECEIVING_ADVICES_FETCHING;
    this.fetched = DocumentListActions.RECEIVING_ADVICES_FETCHED;
    this.search = DocumentListActions.RECEIVING_ADVICES_SEARCH_ADVANCED;
    this.sorting = DocumentListActions.RECEIVING_ADVICES_APPLY_SORTING;
    this.clearSearch = DocumentListActions.RECEIVING_ADVICES_CLEAR_SEARCH;
}
