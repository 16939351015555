export enum DocumentStatus {
    'Processing' = 1,
    'Sent' = 2,
    'Transcepta is Reviewing' = 3,
    'Canceled' = 4,
    'Holding for Scheduled Release' = 5,
    'Determining Customer' = 6,
    'Canceled (Unknown recipient)' = 7,
    'Failed' = 8,
    'Holding for Delivery Address' = 9,
    'Parked' = 10,
    'VAT Processing' = 11,
    'Draft' = 34,
    'Workflow' = 13,
    'Workflow Rejected' = 14,
    'Supplier Hold' = 15,
    'Buyer Hold' = 16,
    'Fixed' = 17,
}
