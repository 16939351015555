import { Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, Fragment } from 'react';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
    item: {
        marginBottom: 16,
    },
    list: {
        marginLeft: 16,
    },
    listItem: {
        listStyle: 'disc',
    },
}));

const LoginSupplierArticle: FC = () => {
    const classes = useStyles();

    return (
        <Fragment>
            <Typography className={classes.item} component="h1" variant="subtitle1">
                Send invoices electronically!
            </Typography>
            <ul className={clsx(classes.item, classes.list)}>
                <Typography component="li" variant="body1" className={classes.listItem}>
                    No upfront software or hardware costs
                </Typography>
                <Typography component="li" variant="body1" className={classes.listItem}>
                    Cheaper than the cost of a stamp
                </Typography>
                <Typography component="li" variant="body1" className={classes.listItem}>
                    Getting started is easy and takes less than a day
                </Typography>
                <Typography component="li" variant="body1" className={classes.listItem}>
                    A complete solution
                </Typography>
            </ul>
            <Link href="https://www.transcepta.com/">Learn more about Transcepta...</Link>
        </Fragment>
    );
};

export default LoginSupplierArticle;
