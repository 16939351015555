import { useMemo } from 'react';

import { ClientValidationController } from './ClientValidationController';
import { DocumentValidationConfiguration } from './DocumentValidationConfiguration';

export function useClientValidationController({
    validationConfiguration,
}: {
    validationConfiguration: Omit<DocumentValidationConfiguration, 'inputParameters'> | null;
}) {
    const clientValidationController = useMemo(() => {
        if (validationConfiguration == null) {
            return null;
        }

        return new ClientValidationController(validationConfiguration);
    }, [validationConfiguration]);

    return clientValidationController;
}
