import {
    ICustomDropdownTouchpoint,
    IRegisterValidationFunctionOptions,
} from '../../ScriptingEngineEnvironment/Touchpoint/CustomDropdown';
import DropdownOptionsRepository from '../../../DocumentEditRules/PluginHost/services/dropdownOptionsRepository';
import { AxiosInstance } from 'axios';

export class CustomDropdownTouchpoint implements ICustomDropdownTouchpoint {
    api: () => AxiosInstance;

    buyerCompanyId: number;

    dropdownOptionsRepository?: DropdownOptionsRepository;

    parentCompanyId?: number;

    constructor(
        api: () => AxiosInstance,
        buyerCompanyId: number,
        dropdownOptionsRepository?: DropdownOptionsRepository,
        parentCompanyId?: number // for multi-company scenarios
    ) {
        this.api = api;
        this.buyerCompanyId = buyerCompanyId;
        this.dropdownOptionsRepository = dropdownOptionsRepository;
        this.parentCompanyId = parentCompanyId;
    }

    register(options: IRegisterValidationFunctionOptions[]): void {
        options.forEach(async (option) => {
            if (option.fieldDefinitionKey && this.dropdownOptionsRepository) {
                const dropdownOptionsPromise = this.dropdownOptionsRepository.getLatestDropdownOptions(
                    option.fieldDefinitionKey
                );

                let isFulfilled = false;

                dropdownOptionsPromise.then(() => {
                    isFulfilled = true;
                });

                // this is necessary because `dropdownOptionsPromise.then` is executed asynchronously
                await Promise.resolve();

                if (isFulfilled) {
                    return;
                }
                const dropdownOptions = await option.fetchOptions(this.api, this.buyerCompanyId, this.parentCompanyId);

                this.dropdownOptionsRepository.setDropdownOptions(option.fieldDefinitionKey, dropdownOptions);
            }
        });
    }
}
