export interface ITradingPartnerSearchViewModel {
    VendorClass: string;
    CompanyID: number | null;
    BuyerCompanyID: number;
    Name: string | null;
    ContactName: string;
    ContactEmail: string;
    VendorNumber: string;
    ProcessingMode?: number;
    VendorId: number | null;
    VendorSiteNumber: string | null;
    InvoiceProfileType: InvoiceProfileType | null;
    OrgUnit: string | null;
}

export enum InvoiceProfileType {
    Normal = 0,
    ScanKey = 1,
}
