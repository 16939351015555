import jsonpath from 'jsonpath';
import { BusinessDocType, ControlType } from '../../legacy/transcepta-types';
import DocumentViewContainer from '../../legacy/transcepta-types/types/DocumentViewContainer';
import { InvoiceLineItem } from '../../services/backendServices/ViewModels/DocumentViewContainer';

export { removeEmptyEntitiesFromDocument } from './removeEmptyEntitiesFromDocument';

const documentLineHasGLCodingInfo = (lineItem: InvoiceLineItem) =>
    lineItem.InvoiceLineItemCompanyGLAccounts.some((glAccount) => {
        const coding = glAccount.Coding.Coding;

        const values = Object.values(coding).map(({ value }: any) => value);

        return !!values.join('');
    });

const documentLineIsBlank = (bodyLayout: any, bodySectionNode: any) => {
    const excludedFieldDisplayHints = ['LineNumber'];
    const excludedFieldNames = ['LineNum'];
    const numericFields = [
        ControlType.Money,
        ControlType.Quantity,
        ControlType.UnitPrice,
        ControlType.Tax,
        ControlType.UnitAmountAndAmount,
    ];

    const excludedFieldTypes = [ControlType.GLCodingDotNotation, ControlType.GLCodingInline];

    for (let cellIndex = 0; cellIndex < bodyLayout.length; cellIndex++) {
        const cellLayout = bodyLayout[cellIndex];
        for (let fieldIndex = 0; fieldIndex < cellLayout.Fields.length; fieldIndex++) {
            const fieldLayout = cellLayout.Fields[fieldIndex];

            if (
                !excludedFieldDisplayHints.includes(fieldLayout.DisplayHints) &&
                !excludedFieldTypes.includes(fieldLayout.ControlType) &&
                !excludedFieldNames.includes(fieldLayout.FieldName)
            ) {
                const cellAndFieldJSONPath =
                    cellLayout.CellJSONPath && cellLayout.CellJSONPath !== ''
                        ? `$..${cellLayout.CellJSONPath}.${fieldLayout.JSONPath}`
                        : `$..${fieldLayout.JSONPath}`;
                let fieldValue = null;

                try {
                    fieldValue = jsonpath.query(bodySectionNode, cellAndFieldJSONPath)[0];
                } catch (e) {
                    console.error('Invalid jsonpath: ', cellAndFieldJSONPath);
                    break;
                }

                if (numericFields.includes(fieldLayout.ControlType)) {
                    if (fieldValue && Number(fieldValue) !== 0) {
                        return false;
                    }
                } else if (fieldValue && fieldValue !== '') {
                    return false;
                }
            }
        }
    }

    if (documentLineHasGLCodingInfo(bodySectionNode)) {
        return false;
    }

    return true;
};

export const removeBlankDocumentLines = (
    layout: any,
    document: DocumentViewContainer,
    businessDocType: BusinessDocType
) => {
    if (businessDocType === BusinessDocType.SIMDocument) {
        return;
    }

    const isInvoice = businessDocType === BusinessDocType.Invoice;

    const bodyLayout = layout.Large.Body;
    const bodyJsonPath = `$${bodyLayout[0].SectionJSONPath}`;

    const uniqueSectionNodes = jsonpath.nodes(document, bodyJsonPath)[0].value;
    let remainingLineCount = uniqueSectionNodes.length;

    for (
        let sectionNodeIndex = uniqueSectionNodes.length - 1;
        sectionNodeIndex >= 0 && remainingLineCount > 1;
        sectionNodeIndex--
    ) {
        const sectionNode = uniqueSectionNodes[sectionNodeIndex];
        if (documentLineIsBlank(bodyLayout, sectionNode)) {
            uniqueSectionNodes.splice(sectionNodeIndex, 1);
            // Generic doc types do not use an array for their lines when there is only one line.
            // Therefore if this is not an invoice, we must convert from an array to a single object if there will be only one line remaining.
            if (!isInvoice && uniqueSectionNodes.length === 1) {
                jsonpath.value(document, bodyJsonPath, uniqueSectionNodes[0]);
            }
            remainingLineCount -= 1;
        }
    }
};
