import { api } from '../../../services/api';
import { IBusinessDocFlipViewModel, IBusinessDocFlipViewModelData } from './ViewModels/BusinessDocFlipViewModel';
import { AxiosResponse } from 'axios';

export class BusinessDocFlipApi {
    route = '/BusinessDocFlip';

    public getBusinessDocFlips = async (params: {
        $filter?: string;
        $orderBy?: string;
    }): Promise<AxiosResponse<IBusinessDocFlipViewModel[]>> => {
        return api().get(`${this.route}`, { params });
    };

    public getBusinessDocFlipsByCompany = async (
        companyId: number
    ): Promise<AxiosResponse<IBusinessDocFlipViewModel[]>> => {
        return api().get(`${this.route}?companyId=${companyId}`);
    };

    public getBusinessDocumentFlip = async (
        businessDocumentFlipId: number
    ): Promise<AxiosResponse<IBusinessDocFlipViewModel[]>> => {
        return api().get(`${this.route}/${businessDocumentFlipId}`);
    };

    public updateBusinessDocumentFlip = async (
        businessDocFlipId: number,
        businessDocFlip: IBusinessDocFlipViewModelData
    ): Promise<AxiosResponse<{ ID: number }>> => {
        return api().put(`${this.route}/${businessDocFlipId}`, businessDocFlip);
    };

    public insertBusinessDocumentFlip = async (
        businessDocFlip: IBusinessDocFlipViewModelData
    ): Promise<AxiosResponse<{ ID: number }>> => {
        return api().post(this.route, businessDocFlip);
    };

    public deleteBusinessDocumentFlip = async (businessDocFlipId: number): Promise<AxiosResponse<{ ID: number }>> => {
        return api().delete(`${this.route}/${businessDocFlipId}`);
    };
}
