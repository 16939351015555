/**
 * @deprecated The method should not be used
 */
export type UncapitalizeKeys<T extends object> = Uncapitalize<keyof T & string>;

/**
 * @deprecated The method should not be used
 */
export type ToCamelCase<T> = T extends (infer R)[] ? ToCamelCase<R>[] : T extends object ? ToCamelCaseObject<T> : T;

/**
 * @deprecated The method should not be used
 */
export type ToCamelCaseObject<T extends object> = {
    [key in UncapitalizeKeys<T>]: Capitalize<key> extends keyof T
        ? ToCamelCase<T[Capitalize<key>]>
        : key extends keyof T
        ? ToCamelCase<T[key]>
        : never;
} & {};

/**
 * @deprecated The method should not be used
 */
export function toSingleCamelCase(k: string): string {
    return `${k.substr(0, 1).toLowerCase()}${k.substr(1)}`;
}

/**
 * @deprecated The method should not be used
 */
export function toCamelCase<T>(obj: T): ToCamelCase<T> {
    if (Array.isArray(obj)) {
        return obj.map(toCamelCase) as any;
    }

    if (typeof obj !== 'object') {
        return obj as any;
    }

    if (obj === null) {
        return obj as any;
    }

    const entries = Object.entries(obj);
    const newEntries = entries.map(([k, v]) => [`${toSingleCamelCase(k)}`, toCamelCase(v)]);

    return Object.fromEntries(newEntries) as any;
}

/**
 * @deprecated The method should not be used
 */
export type CapitalizeKeys<T extends object> = Capitalize<keyof T & string>;

/**
 * @deprecated The method should not be used
 */
export type ToPascalCase<T> = T extends (infer R)[] ? ToPascalCase<R>[] : T extends object ? ToPascalCaseObject<T> : T;

/**
 * @deprecated The method should not be used
 */
export type ToPascalCaseObject<T extends object> = {
    [key in CapitalizeKeys<T>]: Uncapitalize<key> extends keyof T
        ? ToPascalCase<T[Uncapitalize<key>]>
        : key extends keyof T
        ? ToPascalCase<T[key]>
        : never;
} & {};

/**
 * @deprecated The method should not be used
 */
export function toSinglePascalCase(k: string): string {
    return `${k.substr(0, 1).toUpperCase()}${k.substr(1)}`;
}

/**
 * @deprecated The method should not be used
 */
export function toPascalCase<T extends object>(obj: T): ToPascalCase<T> {
    if (Array.isArray(obj)) {
        return obj.map(toPascalCase) as any;
    }

    if (typeof obj !== 'object') {
        return obj as any;
    }

    if (obj === null) {
        return obj as any;
    }

    const entries = Object.entries(obj);
    const newEntries = entries.map(([k, v]) => [`${toSinglePascalCase(k)}`, toPascalCase(v)]);

    return Object.fromEntries(newEntries) as any;
}
