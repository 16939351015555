import { useCallback, useMemo } from 'react';
import { ascendingOrder } from '../../utils';
import { ITooltipIconProps } from '../Tooltip';

export type GenericEnum<T> = {
    [id: string]: T | string;
    [nu: number]: string;
};

export type OptionsType<T> = T extends readonly string[]
    ? T
    : T extends readonly { label: string }[]
    ? T
    : T extends GenericEnum<any>
    ? T
    : never;

export type ExtractString<T extends readonly string[]> = T extends readonly (infer U)[] ? U : string;

export type ExtractObject<T extends readonly { label: string }[]> = T extends readonly (infer U)[]
    ? U
    : { label: string };

export type OptionType<T> = T extends readonly string[]
    ? ExtractString<T>
    : T extends readonly { label: string }[]
    ? ExtractObject<T>
    : T extends GenericEnum<infer U>
    ? U
    : never;

export interface IBaseSelectProps<T, V = OptionType<T> | null> {
    /**
     * The selected value. Must have the same type as the elements of options.
     */
    value: V;

    /**
     * Options for the select-like component. Must be one of these:
     *  - string array
     *  - object with label property array
     *  - enum-like object or enum
     *
     * Internally, the options are normalized to the "object with label property" case:
     *  - strings are converted to an object whose label property is the provided string
     *  - enums are converted so that each "string" key of the enum becomes an option.
     */
    options: OptionsType<T>;

    /**
     * Called when the user selects a value or "clears" the selected value.
     */
    onChange: (selectedValue: V) => void;

    /**
     * By default, options are sorted. Passing true here will disable sorting.
     */
    disableSorting?: boolean;

    /**
     * By default, options are sorted in "ascending order" based on their normalized labels.
     *
     * You can override the comparison function used for sorting by passing one here.
     */
    sortComparisonFn?: (a: OptionType<T>, b: OptionType<T>) => 1 | -1 | 0;

    /**
     * If Autocomplete is readonly then this will render the readonly component instead
     */
    readonly?: boolean;

    /**
     * Extra description for the component
     */
    helperText?: string;

    /**
     * Require field
     */
    required?: boolean;

    /**
     * Test id
     */
    testId?: string;

    /**
     * Tooltip props
     */
    tooltip?: ITooltipIconProps;

    /**
     * Error state
     *
     */
    error?: boolean;
    stacked?: boolean;
}

function enumToOptions<T extends number>(Enum: GenericEnum<T>) {
    return Object.keys(Enum)
        .filter((value) => Number.isNaN(Number(value)))
        .map((value: string) => ({ label: value }));
}

function normalizeValue(value: any, options: any, normalizedOptions: { label: string }[]): { label: string } | null {
    if (Array.isArray(options)) {
        // this is the "array of objects with labels" case, so the value is already normalized
        if (typeof options[0] === 'object') {
            return value;
        }

        // this is the "array of strings" case, so normalize to the object case
        return normalizedOptions.find((x) => x.label === value) ?? null;
    }

    // this is the enum case, so normalize to the object case
    return normalizedOptions.find((x) => options[x.label] === value) ?? null;
}

function normalizeOptions(options: any): { label: string }[] {
    if (Array.isArray(options)) {
        // this is the "array of objects with labels" case, so it is already normalized
        if (typeof options[0] === 'object') {
            return options;
        }

        // this is the "array of strings" case, so normalize it:
        return options.map((label) => ({ label }));
    }

    // this is the enum case
    return enumToOptions(options);
}

function findOriginalOptionBySelectedNormalizedOption(originalOptions: any, selectedLabel: string) {
    if (Array.isArray(originalOptions)) {
        // this is the "array of objects with labels" case, so the selected object is the value to pass back
        if (typeof originalOptions[0] === 'object') {
            return originalOptions.find((x) => x.label === selectedLabel);
        }

        // this is the "array of strings" case, so we need to pass back a string value
        return selectedLabel;
    }

    // this is the enum case, so we need to pass back the "value" side of the enum, so index the enum with the label,
    // which is one of the string keys of the label
    return originalOptions[selectedLabel];
}

export function defaultSortedSelectComparisonFn(a: { label: string }, b: { label: string }) {
    const alabel = a.label.toUpperCase();
    const blabel = b.label.toUpperCase();

    return ascendingOrder(alabel, blabel);
}

/**
 * Handles all the normalization of select-like component props so we don't need to duplicate
 * the logic.
 *
 * @param value the selected value
 * @param options the options
 * @param onChange the function that handles changes to selected values
 * @param disableSorting whether sorting should be disabled
 * @param sortComparisonFn override default sort comparison
 * @returns normalized options, normalized value, and function to handle changes
 */
export function useSelectOptionHelpers(
    value: any,
    options: any,
    onChange: (value: any) => void,
    disableSorting: IBaseSelectProps<any>['disableSorting'],
    sortComparisonFn: ((a: any, b: any) => number) | undefined
) {
    const normalizedOptions = useMemo(() => normalizeOptions(options), [options]);

    const normalizedValue = useMemo(
        () => normalizeValue(value, options, normalizedOptions),
        [value, options, normalizedOptions]
    );

    const sortedOptions = useMemo(() => {
        if (disableSorting) {
            return normalizedOptions;
        }
        return [...normalizedOptions].sort(sortComparisonFn ?? defaultSortedSelectComparisonFn);
    }, [normalizedOptions, disableSorting, sortComparisonFn]);

    const handleChange = useCallback(
        (selectedLabel: string | null) => {
            const selectedObject = selectedLabel
                ? findOriginalOptionBySelectedNormalizedOption(options, selectedLabel)
                : null;
            onChange(selectedObject);
        },
        [options, onChange]
    );

    return { normalizedValue, normalizedOptions: sortedOptions, handleChange };
}

/**
 * Handles all the normalization of multiselect-like component props so we don't need to duplicate
 * the logic.
 *
 * @param values the selected value
 * @param options the options
 * @param onChange the function that handles changes to selected values
 * @param disableSorting whether sorting should be disabled
 * @param sortComparisonFn override default sort comparison
 * @returns normalized options, normalized value, and function to handle changes
 */
export function useMultiSelectOptionHelpers(
    values: any,
    options: any,
    onChange: (value: any) => void,
    disableSorting: IBaseSelectProps<any>['disableSorting'],
    sortComparisonFn: ((a: any, b: any) => number) | undefined
) {
    const normalizedOptions = useMemo(() => normalizeOptions(options), [options]);

    const normalizedValues: { label: string }[] = useMemo(
        () =>
            (values || [])
                .map((value: any) => normalizeValue(value, options, normalizedOptions))
                .filter((value: any) => !!value),
        [values, options, normalizedOptions]
    );

    const sortedOptions = useMemo(() => {
        if (disableSorting) {
            return normalizedOptions;
        }
        return [...normalizedOptions].sort(sortComparisonFn ?? defaultSortedSelectComparisonFn);
    }, [normalizedOptions, disableSorting, sortComparisonFn]);

    const handleChange = useCallback(
        (selectedLabels: string[]) => {
            const selectedObject = (selectedLabels || [])?.map((selectedLabel) =>
                findOriginalOptionBySelectedNormalizedOption(options, selectedLabel)
            );
            onChange(selectedObject);
        },
        [options, onChange]
    );

    return { normalizedValues, normalizedOptions: sortedOptions, handleChange };
}
