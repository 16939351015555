import { IProfile, ProfileService } from '../../Profile';
import {
    EntryConditionLogicType,
    IDocumentApprovalViewModel,
    IReassignmentUserGroupsByDocumentViewModel,
    IReassignmentUsersByDocumentViewModel,
    LogicType,
    WorkflowApi,
    WorkflowInstanceApi,
} from '../Api';
import {
    IWorkflow,
    IWorkflowActivity,
    IWorkflowActivityExitFlow,
    IWorkflowDocumentSearchResult,
    IWorkflowForm,
    IWorkflowGrid,
    IWorkflowSearchItems,
    WorkflowBulkActionResult,
} from './types';
import { createWorkflowGridFilter } from './Workflow.filter';
import {
    transformWorkflowActivityExitFlowFromViewModel,
    transformWorkflowActivityFromViewModel,
    transformWorkflowFromViewModel,
    transformWorkflowToViewModel,
} from './Workflow.utils';

class WorkflowService {
    api = new WorkflowApi();

    profileService = new ProfileService();

    // TODO: remove once a better way to retrieve the companyId is available
    profileMap = new Map<string, IProfile>();

    workflowInstanceApi = new WorkflowInstanceApi();

    public getWorkflowByCompanyId = async (companyId: number): Promise<IWorkflow[]> => {
        const response = await this.api.getWorkflowByCompanyId(companyId);

        return response?.data?.map(transformWorkflowFromViewModel);
    };

    public async getWorkflowGrid(searchInfo: IWorkflowSearchItems): Promise<IWorkflowGrid[]> {
        const { companyId: searchCompanyId, ...searchInfoData } = searchInfo;
        const filter = createWorkflowGridFilter(searchInfoData);

        const viewModel = await this.api.getWorkflowByCompanyId(searchCompanyId ?? undefined, filter);

        return Promise.all(
            viewModel.data.map(
                async ({ Id, Name, ProfileId, Active, WorkflowTemplateUI, ProfileType, BusinessDocType }) => {
                    const profileKey = `${ProfileType}/${ProfileId}`;
                    const profile =
                        this.profileMap.get(profileKey) ??
                        (await this.profileService.getProfile(ProfileId, ProfileType));

                    if (!this.profileMap.has(profileKey) && profile) {
                        this.profileMap.set(profileKey, profile);
                    }

                    return {
                        id: Id,
                        name: Name,
                        companyId: profile?.companyId ?? null,
                        active: Active,
                        workflowTemplateUI: WorkflowTemplateUI,
                        businessDocType: BusinessDocType,
                    } as IWorkflowGrid;
                }
            )
        );
    }

    public getWorkflow = async (workflowId: number): Promise<IWorkflow> => {
        const response = await this.api.getWorkflow(workflowId);

        return transformWorkflowFromViewModel(response.data[0]);
    };

    public getWorkflowActivityById = async (workflowActivityId: number): Promise<IWorkflowActivity> => {
        const response = await this.api.getWorkflowActivityById(workflowActivityId);

        return transformWorkflowActivityFromViewModel(response.data[0]);
    };

    public getWorkflowActivityExitFlow = async (
        workflowActivityExitFlowId: number
    ): Promise<IWorkflowActivityExitFlow> => {
        const response = await this.api.getWorkflowActivityExitFlow(workflowActivityExitFlowId);

        return transformWorkflowActivityExitFlowFromViewModel(response.data[0]);
    };

    public getWorkflowNewFiles(data: IWorkflowForm) {
        const files: File[] = [];
        if (
            data.entryConditionLogicType === EntryConditionLogicType.JavascriptCode &&
            data.entryConditionLogicNewFile
        ) {
            files.push(data.entryConditionLogicNewFile);
        }

        for (const workflowActivity of data.workflowActivities) {
            if (workflowActivity.setupLogicType === LogicType.JavascriptCode && workflowActivity.setupLogicNewFile) {
                files.push(workflowActivity.setupLogicNewFile);
            }

            if (
                workflowActivity.activityLogicType === LogicType.JavascriptCode &&
                workflowActivity.activityLogicNewFile
            ) {
                files.push(workflowActivity.activityLogicNewFile);
            }
        }
        return files;
    }

    public async createWorkflow(data: IWorkflowForm): Promise<number> {
        const model = transformWorkflowToViewModel(data);
        const files: File[] = this.getWorkflowNewFiles(data);
        if (files.length) {
            const response = await this.api.createWorkflowWithFiles(model, files);
            return response.data.ID;
        }
        const response = await this.api.createWorkflowWithoutFiles(model);
        return response.data.ID;
    }

    public async updateWorkflow(id: number, data: IWorkflowForm): Promise<number> {
        const model = transformWorkflowToViewModel(data);
        const files: File[] = this.getWorkflowNewFiles(data);

        if (files.length) {
            const response = await this.api.updateWorkflowWithFiles(id, model, files);
            return response.data.ID;
        }
        const response = await this.api.updateWorkflowWithoutFiles(id, model);
        return response.data.ID;
    }

    public async deleteWorkflow(id: number): Promise<number> {
        const res = await this.api.deleteWorkflow(id);
        return res.data.ID;
    }

    public getWorkflowActivity = async (workflowId: number): Promise<IWorkflowActivity[]> => {
        const response = await this.api.getWorkflowActivity(workflowId);

        return response?.data?.map(transformWorkflowActivityFromViewModel);
    };

    public updateWorkflowReject = async (
        workFlowDocuments: IWorkflowDocumentSearchResult[],
        reason: string
    ): Promise<WorkflowBulkActionResult[]> => {
        const documentIds = workFlowDocuments.map((doc) => doc.DocumentID);
        const response = await this.workflowInstanceApi.updateWorkflowReject(documentIds, reason);

        return response.data;
    };

    public updateWorkflowApprove = async (
        workFlowDocuments: IWorkflowDocumentSearchResult[],
        reason: string,
        userId: number
    ): Promise<WorkflowBulkActionResult[]> => {
        const documentIds = workFlowDocuments.map((doc) => doc.DocumentID);
        const response = await this.workflowInstanceApi.updateWorkflowApprove(documentIds, reason, userId);

        return response.data;
    };

    public getWorkflowReassignUsers = async (
        workFlowDocuments: IWorkflowDocumentSearchResult[]
    ): Promise<IReassignmentUsersByDocumentViewModel[]> => {
        const documentIds = workFlowDocuments.map((doc) => doc.DocumentID);
        const response = await this.workflowInstanceApi.getWorkflowReassignUsers(documentIds);

        return response.data;
    };

    public getWorkflowApproveDocuments = async (
        workFlowDocuments: IWorkflowDocumentSearchResult[]
    ): Promise<IDocumentApprovalViewModel> => {
        const documentIds = workFlowDocuments.map((doc) => doc.DocumentID);
        const response = await this.workflowInstanceApi.getWorkflowApproveDocuments(documentIds);
        return response.data;
    };

    public getWorkflowReassignUserGroups = async (
        workFlowDocuments: IWorkflowDocumentSearchResult[],
        currentUserId: number
    ): Promise<IReassignmentUserGroupsByDocumentViewModel[]> => {
        const documentIds = workFlowDocuments.map((doc) => doc.DocumentID);
        const response = await this.workflowInstanceApi.getWorkflowReassignUserGroups(documentIds, currentUserId);

        return response.data;
    };

    public getWorkflowSingleReassignUserGroups = async (
        workFlowDocuments: IWorkflowDocumentSearchResult[],
        currentUserId: number,
        workflowTaskId: number
    ): Promise<IReassignmentUserGroupsByDocumentViewModel[]> => {
        const documentId = workFlowDocuments.map((doc) => doc.DocumentID)[0];
        const response = await this.workflowInstanceApi.getWorkflowSingleReassignUserGroups(
            documentId,
            workflowTaskId,
            currentUserId
        );

        return response.data;
    };

    public updateWorkflowReassign = async (
        documentIds: number[],
        reason: string,
        userOrUserGroupId: number,
        reassignMode: 'User' | 'UserGroup',
        currentUserId: number
    ): Promise<WorkflowBulkActionResult[]> => {
        const payload =
            reassignMode === 'User'
                ? { ReassignToUserId: userOrUserGroupId, ReassignToUserGroupId: null }
                : { ReassignToUserId: null, ReassignToUserGroupId: userOrUserGroupId };
        const response = await this.workflowInstanceApi.updateWorkflowReassign(
            documentIds,
            reason,
            payload,
            currentUserId
        );

        return response.data;
    };
}

export default WorkflowService;
