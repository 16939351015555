import { Grid, ListItem, ListItemButton, Typography } from '@mui/material';
import clsx from 'clsx';
import {
    GridColumns,
    GridRowId,
    GridRowModel,
    GridRowParams,
    GridRowsProp,
    GridRowTreeNodeConfig,
    GridStateColDef,
} from '@mui/x-data-grid';
import { Fragment } from 'react';
import { useDataGridMobileListItemStyles } from './useDataGridMobileListItemStyles';
import { FCNC } from '../FCNC';

interface IProps {
    index: number;
    row: GridRowModel;
    rows: GridRowsProp;
    columns: GridColumns;
    onRowClick?: (param: GridRowParams<{ [key: string]: any }>) => void;
}

export const DataGridMobileListItem: FCNC<IProps> = ({ index, row, rows, columns, onRowClick }) => {
    const classes = useDataGridMobileListItemStyles();

    const gridRowParams: GridRowParams = {
        id: row.id,
        row,
        columns,
        getValue: (id: GridRowId, field: string) => rows.find((r: GridRowModel) => r.id === id)?.[field],
    };

    const rowNodeParams: GridRowTreeNodeConfig = {
        id: row.id,
        parent: null,
        depth: 0,
        groupingKey: null,
        groupingField: null,
    };

    const colDefParams: GridStateColDef = {
        computedWidth: 100,
        field: '',
    };

    return (
        <ListItem
            sx={{ my: 1, borderRadius: 1 }}
            disablePadding
            className={clsx(index % 2 === 0 ? classes.gridEven : classes.gridOdd)}
        >
            <ListItemButton
                className={classes.grid}
                onClick={() => (onRowClick ? onRowClick(gridRowParams) : undefined)}
            >
                <Grid container columnSpacing={{ xs: 1 }}>
                    {columns
                        .filter((column) => !column.hide)
                        .map((column) => (
                            <Fragment key={column.field}>
                                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                    <Typography component="span" sx={{ fontWeight: 600 }}>
                                        {column.headerName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        component="span"
                                        sx={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                        data-field={column.field}
                                    >
                                        {column.renderCell
                                            ? column.renderCell({
                                                  id: row.id,
                                                  field: column.field,
                                                  value: row[column.field],
                                                  formattedValue: row[column.field],
                                                  row: row,
                                                  cellMode: 'view',
                                                  hasFocus: false,
                                                  tabIndex: 0,
                                                  getValue: (id: GridRowId, field: string) =>
                                                      rows.find((r: GridRowModel) => r.id === id)?.[field],
                                                  api: () => null,
                                                  rowNode: rowNodeParams,
                                                  colDef: colDefParams,
                                              })
                                            : row[column.field]}
                                    </Typography>
                                </Grid>
                            </Fragment>
                        ))}
                </Grid>
            </ListItemButton>
        </ListItem>
    );
};
