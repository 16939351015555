import { ChangeEvent, FC, FormEvent, ReactNode, useEffect, useState } from 'react';
import { IUserLogin } from '../../../transcepta-types';
import { validateEmailFormat } from '../../../transcepta-common';
import LoginContainer from './LoginContainer';
import { FormVariant } from './types';
import { useAutoLogin } from './useAutoLogin';
import { setLoginType, LoginType } from '../../../../utils';

interface ILoginPageProps {
    article: ReactNode;
    logo?: ReactNode;
    userLogin: IUserLogin;
    signIn?: (email: string, password: string) => void;
    reset: (email: string) => void;
    resetRecoverStatus: () => void;
    autoLogin?: () => Promise<void>;
    defaultFormVariant?: FormVariant;
    companySignIn?: (email: string, password: string, companyId: string) => void;
    isBuyer?: boolean;
}

export const LoginPage: FC<ILoginPageProps> = ({
    article,
    logo,
    userLogin,
    signIn,
    reset,
    resetRecoverStatus,
    autoLogin,
    defaultFormVariant,
    companySignIn,
    isBuyer = false,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [companyId, setCompanyId] = useState<string>('');
    const [emailError, setEmailError] = useState<boolean>(false);
    const [passwordError, setPasswordError] = useState<boolean>(false);
    const [companyIdError, setCompanyIdError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>('');
    const [formVariant, setFormVariant] = useState<FormVariant>(defaultFormVariant || 'login');
    const [successMessage, setSuccessMessage] = useState<string | undefined>('');

    useEffect(() => {
        if (userLogin.isLoggedIn) {
            return;
        }

        if (userLogin.lastLoggedInError) {
            setIsLoading(false);
            setSuccessMessage(undefined);
            setErrorMessage(userLogin.lastLoggedInError);
        } else if (userLogin.passwordRecoverError) {
            setIsLoading(false);
            setSuccessMessage(undefined);
            setErrorMessage(userLogin.passwordRecoverError);
        } else if (userLogin.passwordRecoverSuccess) {
            setIsLoading(false);
            setErrorMessage(undefined);
            setSuccessMessage('Password reset confirmation has been sent. Please check your email.');
        } else {
            setErrorMessage(undefined);
        }
    }, [userLogin]);

    const handleEmailChange = (event: ChangeEvent) => {
        const element = event.target as HTMLInputElement;
        const newEmail = element.value as string;

        setEmail(newEmail);
    };

    const handlePasswordChange = (event: ChangeEvent) => {
        const element = event.target as HTMLInputElement;
        const newPassword = element.value as string;

        setPassword(newPassword);
    };

    const handleCompanyIdChange = (event: ChangeEvent) => {
        const element = event.target as HTMLInputElement;
        const newCompanyId = element.value as string;

        setCompanyId(newCompanyId);
    };

    useAutoLogin(autoLogin, setIsLoading);

    const handleSignIn = async (event: FormEvent) => {
        event.preventDefault();

        let newErrorMessage = '';
        let newEmailError = false;
        let newPasswordError = false;
        let newCompanyIdError = false;

        const trimmedEmail = email.trim();

        if (trimmedEmail === '' || !validateEmailFormat(trimmedEmail)) {
            newErrorMessage = `${newErrorMessage} \n Please enter a valid email`;
            newEmailError = true;
        }

        if (password === '') {
            newErrorMessage = `${newErrorMessage} \n Please enter a valid password`;
            newPasswordError = true;
        }

        if (companyId === '' && defaultFormVariant === 'internal') {
            newErrorMessage = `${newErrorMessage} \n Please enter a valid companyId`;
            newCompanyIdError = true;
        }

        if (signIn && !newEmailError && !newPasswordError) {
            setIsLoading(true);
            setErrorMessage(undefined);
            setSuccessMessage(undefined);
            setEmailError(false);
            setPasswordError(false);
            setLoginType(LoginType.normal);
            await signIn(trimmedEmail, password);
        } else if (companySignIn && !newEmailError && !newPasswordError && !newCompanyIdError) {
            setIsLoading(true);
            setErrorMessage(undefined);
            setSuccessMessage(undefined);
            setEmailError(false);
            setPasswordError(false);
            setCompanyIdError(false);
            setLoginType(LoginType.internal);
            await companySignIn(trimmedEmail, password, companyId);
        } else {
            setEmailError(true);
            setPasswordError(true);
            setErrorMessage(newErrorMessage);
        }
    };

    const handleReset = async (event: FormEvent) => {
        event.preventDefault();

        let newErrorMessage = '';
        let newEmailError = false;

        if (email === '' || !validateEmailFormat(email)) {
            newErrorMessage = `${newErrorMessage} \n Please enter a valid email`;
            newEmailError = true;
        }

        if (!newEmailError) {
            setIsLoading(true);
            setErrorMessage('');
            setEmailError(false);
            await reset(email);
            setIsLoading(false);
        } else {
            setEmailError(true);
            setErrorMessage(newErrorMessage);
        }
    };

    const handleForgotPassword = () => {
        setFormVariant('forgotPassword');
    };

    const handleBackToSignIn = () => {
        setFormVariant('login');
        setSuccessMessage(undefined);
        resetRecoverStatus();
    };

    return (
        <LoginContainer
            isBuyer={isBuyer}
            article={article}
            logo={logo}
            formVariant={formVariant}
            userLogin={userLogin}
            isLoading={isLoading}
            email={email}
            emailError={emailError}
            password={password}
            passwordError={passwordError}
            companyId={companyId}
            companyIdError={companyIdError}
            errorMessage={errorMessage}
            successMessage={successMessage}
            handleEmailChange={handleEmailChange}
            handlePasswordChange={handlePasswordChange}
            handleCompanyIdChange={handleCompanyIdChange}
            handleSignIn={handleSignIn}
            handleForgotPassword={handleForgotPassword}
            handleReset={handleReset}
            handleBackToSignIn={handleBackToSignIn}
            handleSSOSignIn={() => {}}
            handleLogInAgain={() => {}}
        />
    );
};
