import { useQueryClient, useMutation } from '@tanstack/react-query';
import { ICRUDService, ICRUDSession } from '../types';
import { stagedCRUDRepository } from '../utils';
import { validEntityId } from './useEntity';

export interface ISaveEntityOptions<ID extends any, U extends object> {
    id: ID | null;
    entityFields: U;
}

/**
 * Returns a react-query mutation to save an entity
 * @param service the ICRUDService to use for saving
 * @returns the mutation
 */
export function useSaveEntity<ID extends any, U extends object, T extends ICRUDService<ID, U>>(
    service: T,
    session: ICRUDSession<ID, U> | undefined
) {
    const queryClient = useQueryClient();
    const mutation = useMutation(
        async ({ id, entityFields }: ISaveEntityOptions<ID, U>) => {
            if (session && session.mode === 'stage' && session.getNewEntityId) {
                if (typeof session.isValidEntity !== 'undefined' && !session.isValidEntity(entityFields)) {
                    throw new Error('Invalid and/or Missing Fields');
                }

                stagedCRUDRepository.setEntity(session.sessionID, entityFields);
                return session.getNewEntityId(entityFields);
            }

            if (id != null && validEntityId(id)) {
                await service.updateEntity(id, entityFields);
                return id;
            } else {
                const newEntityId = await service.createEntity(entityFields);
                return newEntityId;
            }
        },
        {
            onSuccess: () => {
                if ((session?.mode ?? 'commit') === 'commit') {
                    queryClient.invalidateQueries([service.key]);
                    service.invalidateKeys.forEach((key) => {
                        queryClient.invalidateQueries([key]);
                    });

                    if (session) {
                        stagedCRUDRepository.deleteSession(session.sessionID);
                    }
                }
            },
        }
    );

    return mutation;
}
