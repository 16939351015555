import { BusinessDocTypeApi } from '../Api';
import { IBusinessDocTypeViewModel, IBusinessDocTypeViewModelData } from '../Api/ViewModels/BusinessDocTypeViewModel';
import { arrayUtils, smallDateFormat, buildLookupTable } from '../../../utils';
import { ISelectBusinessDoc, IDocumentTypeSearchGrid, IBusinessDocTypeData, IBusinessDocTypeIds } from './types';
import { IDocumentTypeSearchGridItem } from './types/IDocumentTypeSearchGridItem';
import { BusinessDocType } from '../../../types';
import { toPascalCase } from '../../utils/casing';
import { validateData } from '../../../ui';
import { createAllDocumentTypesForDataGridFilter } from './BusinessDocType.filter';

export class BusinessDocTypeService {
    api = new BusinessDocTypeApi();

    public getAllDocumentTypesForDataGrid = async (
        name?: string,
        description?: string,
        active?: boolean,
        baseBusinessDocType?: number,
        companyId?: number,
        orderBy?: string
    ): Promise<IDocumentTypeSearchGrid> => {
        const filter = createAllDocumentTypesForDataGridFilter({
            name,
            description,
            active,
            businessDocTypeId: baseBusinessDocType,
            companyId,
        });

        const response = await this.api.getAllBusinessDocTypes({
            $orderBy: orderBy,
            $filter: filter,
        });

        return {
            count: response.data.length,
            items: response.data.map(
                (item: IBusinessDocTypeViewModel): IDocumentTypeSearchGridItem => ({
                    id: `${item.BusinessDocTypeId.toString()}-${item.BusinessDocSubTypeId!.toString()}`,
                    businessDocType: BusinessDocType[item.BusinessDocTypeId],
                    name: item.Name,
                    description: item.Description,
                    active: item.Active,
                    lastUpdateDate: smallDateFormat(new Date(item.LastUpdateDate)),
                })
            ),
        };
    };

    public getDocumentTypeByIds = async (
        businessDocTypeId: number,
        businessDocSubTypeId: number
    ): Promise<IBusinessDocTypeData> => {
        const filter = createAllDocumentTypesForDataGridFilter({
            businessDocTypeId,
            businessDocSubTypeId,
        });

        const response = await this.api.getAllBusinessDocTypes({
            $filter: filter,
        });

        const { CompanyId, BusinessDocTypeId, BusinessDocSubTypeId, Name, Description, Skeleton, Active } =
            response.data[0];

        return {
            companyId: CompanyId,
            businessDocTypeId: BusinessDocTypeId,
            businessDocSubTypeId: BusinessDocSubTypeId!.toString(),
            name: Name,
            description: Description,
            skeleton: Skeleton,
            active: Active,
        };
    };

    public getGetDocumentTypeName = async () => {
        const response = await this.api.getAllBusinessDocTypes();

        const lookupTable = buildLookupTable(
            response.data,
            (data) => `${data.BusinessDocTypeId}-${data.BusinessDocSubTypeId}`,
            (data) => data.Name
        );

        const getDocumentTypeName = (docTypeId: BusinessDocType, subDocTypeId: number) =>
            lookupTable[`${docTypeId}-${subDocTypeId}`] ?? '';

        return getDocumentTypeName;
    };

    public getBusinessDocTypes = async (): Promise<ISelectBusinessDoc[]> => {
        const response = await this.api.getAllBusinessDocTypes();
        const filteresDocTypes = arrayUtils.removeDuplicates(response.data, (item) => item.BusinessDocTypeId);

        return filteresDocTypes.map((item: IBusinessDocTypeViewModel) => ({
            id: item.BusinessDocTypeId as number,
            label: item.Name,
        }));
    };

    public getAllBusinessDocTypes = async (): Promise<IBusinessDocTypeViewModel[]> => {
        const response = await this.api.getAllBusinessDocTypes();
        return response?.data;
    };

    public getBusinessSubDocTypesByDocTypeNotAsync = (
        allDocTypes: IBusinessDocTypeViewModel[],
        selectedBusinessDocTypeId: number
    ) => {
        return allDocTypes
            .filter(
                (item: IBusinessDocTypeViewModel) =>
                    item.BusinessDocTypeId === selectedBusinessDocTypeId && item.BusinessDocSubTypeId !== 0
            )
            .map((item: IBusinessDocTypeViewModel) => ({
                id: item.BusinessDocSubTypeId as number,
                label: item.Name,
            }));
    };

    public getBusinessSubDocTypesByDocType = async (docTypeId: number): Promise<ISelectBusinessDoc[]> => {
        const response = await this.api.getAllBusinessDocTypes();

        return this.getBusinessSubDocTypesByDocTypeNotAsync(response.data, docTypeId);
    };

    private validateBusinessDocType(businessDocType: IBusinessDocTypeData) {
        validateData((errors) => {
            if (!businessDocType.businessDocTypeId) {
                errors.push('Business Document Type is required');
            }

            if (!businessDocType.name) {
                errors.push('Document Name is required');
            }

            if (!businessDocType.description) {
                errors.push('Description is required');
            }
        });
    }

    private async toViewModel(businessDocTypeFields: IBusinessDocTypeData) {
        const businessDocTypeViewModel: Omit<IBusinessDocTypeViewModelData, 'BusinessDocSubTypeId'> = {
            ...toPascalCase(businessDocTypeFields),
            BusinessDocTypeId: businessDocTypeFields.businessDocTypeId!,
            CompanyId: businessDocTypeFields.companyId || null,
            Skeleton: businessDocTypeFields.skeleton || null,
        };

        return businessDocTypeViewModel;
    }

    public updateBusinessDocumentType = async (
        businessDocTypeId: number,
        businessDocSubTypeId: number,
        businessDocType: IBusinessDocTypeData
    ): Promise<void> => {
        this.validateBusinessDocType(businessDocType);

        await this.api.updateBusinessDocumentType(
            businessDocTypeId,
            businessDocSubTypeId,
            await this.toViewModel(businessDocType)
        );
    };

    public insertBusinessDocumentType = async (
        businessDocType: Omit<IBusinessDocTypeData, 'businessDocSubTypeId'>
    ): Promise<any> => {
        this.validateBusinessDocType(businessDocType);
        const response = await this.api.insertBusinessDocumentType(await this.toViewModel(businessDocType));

        return {
            businessDocTypeId: response.data.ID,
            businessDocSubTypeId: response.data.SubID,
        } as IBusinessDocTypeIds;
    };

    public deleteBusinessDocumentType = async (businessDocTypeId: number, businessDocSubTypeId: number) => {
        await this.api.deleteBusinessDocumentType(businessDocTypeId, businessDocSubTypeId);
    };

    static defaultCRUDFields: IBusinessDocTypeData = {
        companyId: null,
        businessDocTypeId: null,
        name: '',
        description: '',
        active: true,
        skeleton: '',
    };
}
