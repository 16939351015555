import { FileUtil } from '../../../../transcepta-common';
import { FileTypes } from './types';

export const determineCurrentTotalFileSize = (files) => {
    let totalSize = 0;

    files.forEach((file) => {
        if (file.fileType === FileTypes.accepted || file.fileType === FileTypes.saved) {
            totalSize += file.size;
        }
    });

    return totalSize;
};

export const rejectedFileErrorParser = (code, maxFileSize) => {
    switch (code) {
        case 'file-too-large':
            return `File is larger than ${FileUtil.getReadableFileSize(maxFileSize)} `;
        default:
            return null;
    }
};

export const dropHandler = (fileList, acceptedFiles, maxTotalFileSize, maxLengthFileName) => {
    let localCurrentTotalFileSize = determineCurrentTotalFileSize(fileList);

    const files: any = [];

    acceptedFiles.forEach((file) => {
        const errors: string[] = [];
        if (file.size + localCurrentTotalFileSize > maxTotalFileSize) {
            errors.push(
                `Combined size of all supporting document may not exceed ${FileUtil.getReadableFileSize(
                    maxTotalFileSize
                )}`
            );
        }

        if (file.name.split('.')[0].length > maxLengthFileName) {
            errors.push(`File name must not exceed ${maxLengthFileName} characters`);
        }

        localCurrentTotalFileSize += file.size;
        files.push(
            Object.assign(file, {
                Id: Math.floor(Math.random() * 100),
                preview: URL.createObjectURL(file),
                fileType: errors.length > 0 ? FileTypes.rejected : FileTypes.accepted,
                errors,
            })
        );
    });

    return { files, localCurrentTotalFileSize };
};

export const deleteHandler = (fileList, id, maxTotalFileSize, maxLengthFileName) => {
    fileList = fileList.filter((f) => f.Id !== id);
    let currentTotalFileSize = determineCurrentTotalFileSize(fileList);

    const update = fileList.map((file) => {
        const errors: string[] = [];

        if (file.fileType === FileTypes.rejected && file.size + currentTotalFileSize > maxTotalFileSize) {
            errors.push(
                `Combined size of all supporting document may not exceed ${FileUtil.getReadableFileSize(
                    maxTotalFileSize
                )}`
            );
        }
        if (file.fileType === FileTypes.rejected && file.name.split('.')[0].length > maxLengthFileName) {
            errors.push(`File name must not exceed ${maxLengthFileName} characters`);
        }
        if (file.fileType === FileTypes.rejected && errors.length === 0) {
            currentTotalFileSize += file.size;
        }
        return Object.assign(file, {
            fileType: errors.length === 0 ? FileTypes.accepted : FileTypes.rejected,
            errors,
        });
    });

    return update;
};
