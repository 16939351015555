export const TOGGLE_LAYOUT_CONFIG_POPUP = 'TOGGLE_LAYOUT_CONFIG_POPUP';
export const TOGGLE_LAYOUT_FIELD = 'TOGGLE_LAYOUT_FIELD';
export const TOGGLE_OVERRIDE_TYPE = 'TOGGLE_OVERRIDE_TYPE';
export const CLEAR_LAYOUT_TOGGLE_INFO = 'CLEAR_LAYOUT_TOGGLE_INFO';
export const APPLY_FIELDS_CONFIG = 'APPLY_FIELDS_CONFIG';

export const toggleConfigPopup = () => ({ type: TOGGLE_LAYOUT_CONFIG_POPUP });

export const popupFieldChange = (field, checked) => ({
    type: TOGGLE_LAYOUT_FIELD,
    payload: { field, checked },
});

export const overrideTypeChange = (supplierOverride) => ({
    type: TOGGLE_OVERRIDE_TYPE,
    payload: {
        supplierOverride: supplierOverride,
    },
});

export const applyFieldsConfig = () => ({ type: APPLY_FIELDS_CONFIG });

export function clearLayoutToggleInfo() {
    return { type: CLEAR_LAYOUT_TOGGLE_INFO };
}
