import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useDataGridMobileListItemStyles = makeStyles((theme: Theme) => ({
    grid: {
        padding: 12,
    },
    gridEven: {
        backgroundColor: theme.palette.background.paper,
        '&:hover': {
            backgroundColor: theme.palette.grey['200'],
        },
    },
    gridOdd: {
        backgroundColor: theme.palette.background.paper,
        '&:hover': {
            backgroundColor: theme.palette.grey['200'],
        },
    },
}));
