export interface WorkflowTask {
    Id: number;
    WorkflowInstanceId: number;
    WorkflowActivityId: number;
    ThreadId: string;
    RoleTypeId: number | null;
    PreviousTaskId: number | null;
    Status: WorkflowTaskStatus;
    Status_US: string;
    TimeoutTime: null | string;
    CompletedTime: null | string;
    Result: null | string;
    CustomExplanation: string | null;
    LastUpdateDate: string;
    TaskCustomData: string;
    ResultCustomData: string | null;
    CreatedTime: string;
    CompletedByUserId: number | null;
    ReassignToUserGroupId: number | null;
    ReassignToUserId: number | null;
    RetryTime: string | null;
    RetryAttempt: string | null;
    Rowversion: string;
    ReplaceTaskCustomData: boolean;
    WorkflowTaskUsers: {
        WorkflowTaskId: number;
        UserId: number;
        LastUpdateDate: string;
    }[];
    WorkflowTaskUserGroups: {
        WorkflowTaskId: number;
        UserGroupId: number;
    }[];
}

export enum WorkflowTaskStatus {
    Undefined = 0,
    Active = 1,
    Complete = 2,
    Timeout = 3,
    Waiting = 4,
}
