import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { successfulAuthRedirectLocation } from './SuccessfulAuthRedirectLocation';

/**
 * Stores the location the user landed on while unauthenticated so we can redirect to it after
 * the user authenticates.
 */
export function useStoreSuccessfulAuthRedirectLocation() {
    const location = useLocation();
    useEffect(() => {
        // auth pages are not paths we would want to redirect to after a successful authentication
        if (location.pathname.startsWith('/auth')) {
            return;
        }

        // legacy auth pages are not paths we would want to redirect to after a successful authentication
        if (['login', 'logout'].some((x) => location.pathname.toLowerCase().includes(x))) {
            return;
        }

        // any other path that the user landed on while unauthenticated should be redirected to after a successful authentication
        successfulAuthRedirectLocation.setRedirectLocation(location.pathname + location.search + location.hash);
    }, [location]);
}
