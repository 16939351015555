import { createSafeHTMLString, ISafeHTMLString } from '../SafeHTML';
import { getAppConfig } from '../../config';

export interface IVendorSearchModeText {
    label: string;
    searchVendorsText: string;
    searchSuppliersText: string;
    tooltip: ISafeHTMLString | undefined;
}

function getTooltip(tooltip: string) {
    if (!tooltip) {
        return undefined;
    }

    // eslint-disable-next-line rulesdir/no-create-safe-html-without-reason
    return createSafeHTMLString(tooltip);
}

export function getVendorSearchModeText(): IVendorSearchModeText {
    const text = getAppConfig().configurableMessages.vendorSearchModeText;

    return {
        ...text,
        tooltip: getTooltip(text.tooltip),
    };
}
