import { BusinessDocType } from '../../../types';
import { useRelatedDocumentsState } from '../RelatedDocumentsContext';
import { getDocumentLink } from '../utils';
import { Link } from '../../../ui/Link';

export interface GoToActiveRevisionLinkProps {
    relatedDocumentId: number;
    relatedDocumentType: BusinessDocType;
    relatedDocumentNumber: string;
}

export const GoToActiveRevisionLink: React.FC<GoToActiveRevisionLinkProps> = ({
    relatedDocumentId,
    relatedDocumentType,
    relatedDocumentNumber,
}) => {
    const { documentRouteTemplates } = useRelatedDocumentsState();

    return (
        <Link to={getDocumentLink(relatedDocumentId, relatedDocumentType, documentRouteTemplates)} target="_blank">
            {relatedDocumentNumber}
        </Link>
    );
};
