import { IRole, IRoleViewModel, RoleApi } from '../Api';

export class RolesService {
    api = new RoleApi();

    private toRole = (viewModel: IRoleViewModel) => {
        const userGroup: IRole = {
            id: viewModel.ID,
            name: viewModel.Name,
        };
        return userGroup;
    };

    public getRoles = async (): Promise<IRole[]> => {
        const response = await this.api.getRoles();
        const data = response.data;
        return data.map((value) => this.toRole(value));
    };
}
