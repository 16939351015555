export const FETCHED_NOTIFICATIONS = 'FETCHED_NOTIFICATIONS';
export const FETCHED_LOGIN_NOTIFICATIONS = 'FETCHED_LOGIN_NOTIFICATIONS';

export const fetchedNotifications = (portalMsgTypeNone, portalMsgTypeYesBlocking, portalMsgTypeYesNoBlocking) => ({
    type: FETCHED_NOTIFICATIONS,
    payload: {
        portalMsgTypeNone,
        portalMsgTypeYesBlocking,
        portalMsgTypeYesNoBlocking,
    },
});

export const fetchedLoginNotifications = (portalMsgTypeNone) => ({
    type: FETCHED_LOGIN_NOTIFICATIONS,
    payload: {
        portalMsgTypeNone,
    },
});
