import type { PortalUserService } from './PortalUserService';
import type { Permission, ExtraFeature } from './types';
import type { UseCurrentUser } from './hooks';

interface IPortalUserServiceInstances {
    portalUserService: PortalUserService;
    useCurrentUser: UseCurrentUser;
    useCurrentUserCan: (permission: Permission) => boolean;
    useIsExtraFeatureEnabled: (feature: ExtraFeature) => boolean;
    useCurrentCompanyId: () => number;
}

let instances: any = null;

/**
 * Returns the PortalUserService instances for use in `reusableFeatures`.
 *
 * This indirection layer is necessary to prevent circular import issues, as
 * `services` can import from `reusableFeatures`. Typically we don't have imports
 * from `reusableFeatures` to `services`, but it is necessary in this case so that
 * we can encapsulate user/permission handling logic.
 *
 * @returns the instances
 */
export function getInstances(): IPortalUserServiceInstances {
    return instances;
}

export function setInstances(theInstances: IPortalUserServiceInstances) {
    instances = theInstances;
}

export * from './types';
