import { IField, IFieldRendererViewModel } from './types';

export function isFieldVisible(
    field: IField,
    fieldVisibility: 'AlwaysInvisible' | 'AlwaysVisible' | 'VisibleIfHasValue',
    viewModel: IFieldRendererViewModel
) {
    if (fieldVisibility === 'AlwaysInvisible') {
        return false;
    }

    if (fieldVisibility === 'AlwaysVisible') {
        return true;
    }

    return viewModel.getValue(field) !== field.emptyValue;
}
