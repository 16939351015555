enum ErrorCodes {
    PlaceHolder = 0,
    UnAuthorized = 1,
    RequiredField = 2,
    InValidEmailAddressFormat = 3,
    BadRequest = 4,
    ODataException = 5,
    FailedToSendEmail = 6,
    InsufficientPasswordComplexity = 7,
    BadPassword = 8,
    DatabaseError = 9,
    EntityNotFound = 10,
    EntityExists = 11,
    ResourceNotFound = 12,
    PasswordResetFail = 13,
    InvalidEnumValue = 14,
    InvalidValue = 15,
    DocumentValidation = 16,
    ResourceInUse = 17,
    UnsupportedMediaType = 18,
    DocumentCancelError = 19,
    DocumentResendError = 20,
    ActionNotAllowed = 21,
    RequestTimeout = 22,
    EntityCreation = 23,
    EntityUpdateFail = 24,
    FileCreationFailed = 25,
    WorkflowEngineProcessingTimeout = 26,
}

export default ErrorCodes;
