/* eslint-disable */
import { createSelector } from 'reselect';
import { compareHelper } from '../utils/advancedSearch';
import { sortFields, applyLayoutOverrides, reduceFields } from '../utils/document/layout';

const layoutSelector = (state) => state.documentCreation.editLayout;
const browserLessThanSelector = (state) => state.browser.lessThan;
const appliedFieldEditsSelector = (state) => state.documentCreation.editLayout.appliedFieldEdits;

export const convertLayoutBlock = (layoutBlock, sectionJSONPath) => {
    const blockBase = {
        columns: [],
        columnNames: [],
    };
    const parsedBlock = layoutBlock
        ? layoutBlock.reduce((acc, item) => {
              const {
                  Column,
                  CellTitle: Title,
                  Row,
                  Fields,
                  CellJSONPath,
                  SectionJSONPath,
                  Section,
                  Cells,
                  AutoBalanceGroupName,
              } = item;
              const potentialyVisibleFields = applyLayoutOverrides(Fields).filter(
                  (field) => field.NeverVisible !== 'true' && field.NeverVisible !== true
              );

              if (!acc.columnNames.includes(Column)) {
                  acc.columnNames.push(Column);
                  acc.columns.push({});
              }

              let columnIndex = acc.columnNames.indexOf(Column);
              let rowName = Row.toString();
              if (Title !== undefined && Title !== null && Title !== '') rowName = rowName + '_' + Title;

              if (!acc.columns[columnIndex][rowName]) {
                  acc.columns[columnIndex][rowName] = {
                      section: Section,
                      column: Column,
                      row: Row,
                      title: Title,
                      autoBalanceGroupName: AutoBalanceGroupName,
                      fields: [],
                      cells: [],
                  };
              }

              if (potentialyVisibleFields.length) {
                  for (let i = 0; i < potentialyVisibleFields.length; i++) {
                      potentialyVisibleFields[i].cellJsonPath = CellJSONPath;
                      potentialyVisibleFields[i].cellColumn = Column;
                      potentialyVisibleFields[i].cellRow = Row;
                      potentialyVisibleFields[i].section = Section;
                      potentialyVisibleFields[i].sectionJsonPath = SectionJSONPath ? SectionJSONPath : sectionJSONPath;
                      potentialyVisibleFields[i].cellTitle = Title;

                      Object.keys(potentialyVisibleFields[i].ControlFormat).forEach((key) => {
                          let config = potentialyVisibleFields[i].ControlFormat[key];
                          if (!Array.isArray(config)) {
                              config = config.replace(/\{0\}/g, ',').replace(/\{1\}/g, '=');

                              if (key === 'options') {
                                  config = config.split('|');
                                  config = config.map((option) => {
                                      let keyValuePair = option.split('_');

                                      let newOption = {
                                          value: keyValuePair[0],
                                          label: '',
                                      };
                                      if (keyValuePair.length > 1) {
                                          newOption.label = keyValuePair[1]
                                              .replace(/\{2\}/g, '|')
                                              .replace(/\{3\}/g, '_');
                                      } else {
                                          newOption.label = keyValuePair[0]
                                              .replace(/\{2\}/g, '|')
                                              .replace(/\{3\}/g, '_');
                                      }

                                      return newOption;
                                  });

                                  if (
                                      potentialyVisibleFields[i].ControlFormat.clearable &&
                                      (potentialyVisibleFields[i].ControlFormat.clearable === true ||
                                          potentialyVisibleFields[i].ControlFormat.clearable === 'true')
                                  ) {
                                      config.unshift({
                                          value: '',
                                          label: '',
                                      });
                                  }
                              }

                              potentialyVisibleFields[i].ControlFormat[key] = config;
                          }
                      });
                  }

                  acc.columns[columnIndex][rowName].fields = sortFields(
                      acc.columns[columnIndex][rowName].fields.concat(potentialyVisibleFields),
                      'DisplayOrder'
                  );
                  acc.columns[columnIndex][rowName].cells = acc.columns[columnIndex][rowName].cells.concat(
                      convertLayoutBlock(Cells, SectionJSONPath ? SectionJSONPath : sectionJSONPath)
                  );
              }

              return acc;
          }, blockBase)
        : blockBase;

    return parsedBlock.columns;
};

export const convertLayoutBlockBody = (layoutBlock) => {
    var layoutBlockBody = [];

    layoutBlock.forEach((column) => {
        Object.keys(column).forEach((rowName) => {
            let row = column[rowName];
            layoutBlockBody = layoutBlockBody.concat(row.fields);
        });
    });

    return layoutBlockBody;
};

export default createSelector(
    layoutSelector,
    browserLessThanSelector,
    appliedFieldEditsSelector,
    (layouts, screenSizeLessThan, appliedFieldEdits) => {
        const layoutBlocks = { header: [], body: [], footer: [] };

        var layout = getLayoutForScreenSize(layouts, screenSizeLessThan);
        if (layout !== null) {
            if (layout.Header) layoutBlocks.header = convertLayoutBlock(layout.Header);
            if (layout.Body) {
                layoutBlocks.body = convertLayoutBlock(layout.Body);
                layoutBlocks.bodyFields = convertLayoutBlock(layout.Body).reduce(reduceFields, { fields: [] });
            }
            if (layout.Footer) layoutBlocks.footer = convertLayoutBlock(layout.Footer);
        }

        updateLayoutBlocksFieldVisibility(layoutBlocks, appliedFieldEdits);

        return layoutBlocks;
    }
);

export const fieldsSelector = createSelector(
    layoutSelector,
    browserLessThanSelector,
    appliedFieldEditsSelector,
    (layouts, screenSizeLessThan, appliedFieldEdits) => {
        const layoutBlocks = { header: [], body: [], footer: [] };

        var layout = getLayoutForScreenSize(layouts, screenSizeLessThan);
        if (layout !== null) {
            if (layout.Header) layoutBlocks.header = convertLayoutBlock(layout.Header);
            if (layout.Body) layoutBlocks.body = convertLayoutBlockBody(convertLayoutBlock(layout.Body));
            if (layout.Footer) layoutBlocks.footer = convertLayoutBlock(layout.Footer);
        }

        updateLayoutBlocksFieldVisibility(layoutBlocks, appliedFieldEdits);

        return layoutBlocks;
    }
);

function getLayoutForScreenSize(layouts, screenSizeLessThan) {
    if (screenSizeLessThan.medium === true && layouts.Small) return layouts.Small;
    else if (screenSizeLessThan.large === true && layouts.Medium) return layouts.Medium;
    else if (screenSizeLessThan.infinity === true && layouts.Large) return layouts.Large;
    else return null;
}
function updateLayoutBlocksFieldVisibility(layoutBlocks, appliedFieldEdits) {
    if (appliedFieldEdits !== undefined) {
        updateLayoutBlockFieldVisibility(layoutBlocks.header, appliedFieldEdits, false);
        updateLayoutBlockFieldVisibility(layoutBlocks.body, appliedFieldEdits, true);
        updateLayoutBlockFieldVisibility(layoutBlocks.footer, appliedFieldEdits, false);
    }
}
function updateLayoutBlockFieldVisibility(layoutBlock, appliedFieldEdits, blockIsFieldsOnly) {
    if (blockIsFieldsOnly) {
        layoutBlock.forEach((field) => {
            let fieldKey = field.FieldDictionaryId + ';' + field.FieldName;
            if (appliedFieldEdits[fieldKey] !== undefined) {
                field.Visible = appliedFieldEdits[fieldKey];
            }
        });
    } else {
        layoutBlock.forEach((column) => {
            Object.keys(column).forEach((rowName) => {
                let row = column[rowName];
                row.fields.forEach((field) => {
                    let fieldKey = field.FieldDictionaryId + ';' + field.FieldName;
                    if (appliedFieldEdits[fieldKey] !== undefined) {
                        field.Visible = appliedFieldEdits[fieldKey];
                    }
                });
            });
        });
    }
}
