import { ICacheEntry } from './IPersistor';
import { EventEmitter } from '../EventEmitter';
import { IRevalidateOptions } from './types';

interface ICacheEntryWithMetadata<T = unknown> {
    originalKey: any;
    value: T;
    accessTime: number;
    modifiedTime: number;
    cacheTTL: number;
    staleTTL: number;
}

export function keyToString(key: any) {
    return JSON.stringify(key);
}

export default class Cache {
    private data: { [key: string]: ICacheEntryWithMetadata<unknown> | undefined } = {};

    private subscribers = new EventEmitter();

    private keySubscribers: { [key: string]: EventEmitter | undefined } = {};

    private onSetEntrySubscribers = new EventEmitter();

    private computations = new Map<string, Promise<any>>();

    /**
     * Constructs a new in-memory cache
     * @param cacheSize max entries allowed in cache. entries will be evicted with the LRU eviction policy after this size is reached. use -1 to disable eviction.
     * @param cacheTTL default ttl for cache entries that can be overridden when setting an entry. use -1 if you wish for entries to never expire.
     * @param staleTTL default time after which a cache entry is considered stale for the purpose of revalidationWhileStale. Use -1 if you wish for entries to never be considered stale.
     */
    constructor(private cacheSize: number, private cacheTTL: number, private staleTTL: number) {}

    /**
     * The current number of entries in the cache.
     */
    get size() {
        return Object.keys(this.data).length;
    }

    /**
     * Gets all entries in the cache. The value type on entries is opaque and should
     * not be relied on.
     */
    getEntries() {
        const entries = Object.keys(this.data).map((key) => ({
            key,
            value: this.data[key]!,
        }));

        return this.removeExpiredEntries(entries);
    }

    /**
     * Set entries in the cache. The cache will ignore an entry if there is already
     * an entry for the same key in the cache. The entries should only consist of
     * entries previously returned by the `getEntries` method on a cache of the same
     * type.
     * @param entries entries to set
     */
    setEntries(cacheEntries: ICacheEntry[]) {
        let typedCacheEntries = cacheEntries as any as ICacheEntry<ICacheEntryWithMetadata<unknown>>[];

        typedCacheEntries = this.removeExpiredEntries(typedCacheEntries);

        typedCacheEntries.forEach((entry) => {
            if (this.get(entry.value.originalKey) === null) {
                this.data[entry.key] = entry.value as ICacheEntryWithMetadata;
            }
        });

        this.onSetEntrySubscribers.notify();
    }

    /**
     * Get a value from the cache by its key.
     * @param key the key for the entry
     * @returns the value or null if there is no entry for the key
     */
    get<T>(key: any) {
        const stringKey = keyToString(key);
        const item = this.data[stringKey];
        if (item === undefined) {
            return null;
        }
        if (this.isEntryExpired(item)) {
            this.remove(key);
            return null;
        }
        item.accessTime = Date.now();
        return item.value as T;
    }

    /**
     * Create an entry in the cache.
     * @param key key of the entry
     * @param value value of the entry
     * @param ttl optional override for the default TTL that applies only to this entry.
     */
    set<T>(key: any, value: T, cacheTTL?: number, staleTTL?: number) {
        const stringKey = keyToString(key);
        if (this.isEvictionEnabled && this.size >= this.cacheSize && this.data[stringKey] === undefined) {
            this.evictOldestEntry();
        }

        this.data[stringKey] = {
            originalKey: key,
            value,
            cacheTTL: cacheTTL ?? this.cacheTTL,
            staleTTL: staleTTL ?? this.staleTTL,
            accessTime: Date.now(),
            modifiedTime: Date.now(),
        };
        this.notifySubscribers(key);
    }

    /**
     * Remove an entry by its key.
     * @param key key for the entry
     */
    remove(key: any) {
        const stringKey = keyToString(key);
        delete this.data[stringKey];
        this.notifySubscribers(key);
    }

    /**
     * Register a callback to be called whenever the cache is mutated.
     * @param cb called when cache is mutated
     * @returns a function to unregister the subscription
     */
    subscribe(cb: () => void) {
        return this.subscribers.subscribe(cb);
    }

    /**
     * Register a callback to be called whenever a specific entry is mutated.
     * @param key the key for the entry
     * @param cb called when the entry is mutated
     * @returns a function to unregister the subscription
     */
    subscribeKey(key: any, cb: () => void) {
        return this.getKeySubscribers(key).subscribe(cb);
    }

    /**
     * Register a callback to be called when cache entries are set using
     * `setEntries`.
     * @param cb called when `setEntries` finishes executing
     * @returns a function to unregister the subscription
     */
    onSetEntries(cb: () => void) {
        return this.onSetEntrySubscribers.subscribe(cb);
    }

    /**
     * Get an entry or recompute it if needed.
     * @param key the key for the entry
     * @param compute thunk to compute the updated value when stale
     * @param options additional options to control caching for this key.
     * @returns a promise for the updated value
     */
    async getOrRecompute<T>(key: any, compute: () => T | Promise<T>, options?: IRevalidateOptions) {
        const value = this.get<T>(key);
        const isStale = this.isStale(key);

        if (options?.revalidate && isStale && value !== null) {
            this.recomputeEntry(key, compute, options);
            return value as T;
        }

        if (value === null) {
            await this.recomputeEntry(key, compute, options);
            return this.get<T>(key) as T;
        }

        return value;
    }

    /**
     * Checks if the cache entry for a key is stale.
     *
     * Returns false if the key does not exist or entry is expired.
     *
     * @param key the key for the cache entry
     */
    isStale(key: any) {
        const stringKey = keyToString(key);
        const item = this.data[stringKey];
        if (item === undefined) {
            return false;
        }
        if (item.staleTTL === -1) {
            return false;
        }
        return item.modifiedTime + item.staleTTL < Date.now();
    }

    private async recomputeEntry<T>(key: any, compute: () => T | Promise<T>, options?: IRevalidateOptions) {
        if (!this.computations.has(key)) {
            const computation = Promise.resolve(compute());
            this.computations.set(key, computation);
            computation.finally(() => {
                this.computations.delete(key);
            });
        }

        this.set(key, await this.computations.get(key), options?.cacheTTl, options?.staleTTL);
    }

    private evictOldestEntry() {
        const entries = this.getEntries();
        entries.sort((a, b) => {
            const aValue = a.value;
            const bValue = b.value;

            return aValue.accessTime - bValue.accessTime;
        });
        const oldestEntry = entries[0];

        if (oldestEntry) {
            this.remove(oldestEntry.key);
        }
    }

    private removeExpiredEntries(entries: ICacheEntry<ICacheEntryWithMetadata>[]) {
        return entries.filter((entry) => !this.isEntryExpired(entry.value));
    }

    // eslint-disable-next-line class-methods-use-this
    private isEntryExpired(value: ICacheEntryWithMetadata) {
        if (value.cacheTTL === -1) {
            return false;
        }
        return value.modifiedTime + value.cacheTTL < Date.now();
    }

    private notifySubscribers(key: any) {
        this.subscribers.notify();
        this.getKeySubscribers(key).notify();
    }

    private getKeySubscribers(key: any) {
        const stringKey = keyToString(key);

        if (!this.keySubscribers[stringKey]) {
            this.keySubscribers[stringKey] = new EventEmitter();
        }

        return this.keySubscribers[stringKey]!;
    }

    private get isEvictionEnabled() {
        return this.cacheSize > -1;
    }
}
