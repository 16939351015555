import { ReactNode, SyntheticEvent, useState } from 'react';
import {
    Autocomplete as MuiAutocomplete,
    Box,
    TextField,
    FilterOptionsState,
    Stack,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    BoxProps,
} from '@mui/material';
import { IBaseSelectProps, useSelectOptionHelpers } from './selectHelpers';
import { ReadOnly } from '../ReadOnly';
import { useFieldsReadonly } from '../Form';
import { renderTooltipLabel, hideTooltipIconWhenLabelNotFloating } from '../Tooltip';
import ClearIcon from '@mui/icons-material/Clear';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';
import { AutocompleteCloseReason } from '@mui/base/useAutocomplete/useAutocomplete';

export interface IAutocompleteProps<T> extends IBaseSelectProps<T> {
    id: string;
    label: string;
    filterOptions?: (options: T[], state: FilterOptionsState<T>) => T[];
    clearOnBlur?: boolean;
    removeOption?: (option: string) => void;
    autoFocus?: boolean;
    disableClearable?: boolean;
    stacked?: boolean;
    labelBoxSx?: BoxProps['sx'];
    renderInput?: (params: AutocompleteRenderInputParams) => ReactNode;
    disabled?: boolean;
    loading?: boolean;
    loadingText?: string;
    open?: boolean;
    onOpen?: (event: SyntheticEvent) => void;
    onClose?: (event: SyntheticEvent, reason: AutocompleteCloseReason) => void;
    noOptionsText?: string;
}

/**
 * Autocomplete is the full featured Autocomplete, accepts value as IOption, and will return
 * both label and id of the option.
 */
export function Autocomplete<T>({
    id,
    value,
    onChange,
    label,
    options,
    disableSorting,
    sortComparisonFn,
    readonly,
    helperText,
    required,
    filterOptions,
    testId,
    tooltip,
    error,
    clearOnBlur,
    removeOption,
    autoFocus,
    disableClearable,
    stacked,
    labelBoxSx,
    disabled,
    renderInput,
    loading,
    open,
    onOpen,
    onClose,
    loadingText,
    noOptionsText,
}: IAutocompleteProps<T>) {
    const [inputValue, setInputValue] = useState('');
    const { normalizedOptions, normalizedValue, handleChange } = useSelectOptionHelpers(
        value,
        options,
        onChange,
        disableSorting,
        sortComparisonFn
    );
    const isReadonly = useFieldsReadonly(readonly);

    const onInputChange = (_: any, newInputValue: string) => {
        setInputValue(newInputValue);
    };

    if (isReadonly) {
        return <ReadOnly testId={testId} label={label} value={normalizedValue?.label} />;
    }

    if (required && label) {
        label = `${label} *`;
    }

    return (
        <Box
            sx={
                stacked
                    ? { display: 'flex', flexDirection: 'column', width: '100%' }
                    : { display: 'flex', alignItems: 'center', width: '100%' }
            }
        >
            {label ? (
                <Box component="label" htmlFor={id}>
                    <Box sx={{ width: 160, ...labelBoxSx }}>{renderTooltipLabel({ tooltip, label })}</Box>
                </Box>
            ) : null}
            <Stack data-testid={testId} sx={{ width: '100%' }}>
                <MuiAutocomplete
                    disablePortal
                    id={id}
                    value={normalizedValue}
                    onChange={(_, b) => handleChange(b?.label ?? null)}
                    open={open}
                    onOpen={onOpen}
                    onClose={onClose}
                    inputValue={inputValue}
                    onInputChange={onInputChange}
                    options={normalizedOptions}
                    noOptionsText={noOptionsText}
                    sx={{
                        width: '100%',
                        ...hideTooltipIconWhenLabelNotFloating,
                        mb: !normalizedValue ? '3px !important' : '',
                    }}
                    disabled={disabled}
                    renderInput={
                        renderInput
                            ? renderInput
                            : (params) => (
                                  <TextField
                                      // eslint-disable-next-line react/jsx-props-no-spreading
                                      {...params}
                                      required={required}
                                      inputProps={{
                                          ...params.inputProps,
                                          name: 'stopitautocomplete',
                                          id: 'stopitautocomplete',
                                          autoComplete: 'stopitautocomplete',
                                      }}
                                      sx={{ width: '100%' }}
                                      error={error}
                                      autoFocus={autoFocus}
                                      helperText={helperText}
                                  />
                              )
                    }
                    filterOptions={filterOptions as any}
                    clearOnBlur={clearOnBlur}
                    renderOption={
                        removeOption
                            ? (props, option) => (
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  <ListItem {...props}>
                                      <ListItemText primary={option.label} />
                                      <ListItemSecondaryAction>
                                          <IconButton
                                              edge="end"
                                              aria-label="delete"
                                              onClick={() => removeOption(option.label)}
                                          >
                                              <ClearIcon />
                                          </IconButton>
                                      </ListItemSecondaryAction>
                                  </ListItem>
                              )
                            : undefined
                    }
                    openOnFocus={autoFocus}
                    disableClearable={disableClearable}
                    loading={loading}
                    loadingText={loadingText}
                />
            </Stack>
        </Box>
    );
}
