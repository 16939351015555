import { BusinessDocType } from '../../../types';
import { DynamicErrorRenderer } from '../ErrorRenderer';
import { DocumentValidationEvent } from '../proservContractTypes';
import { DocumentEditValidationConfiguration } from '../types/DocumentEditValidationConfiguration';
import { IInternalRulesConfiguration } from './createInternalRulesConfiguration';

export function mixInternalRulesConfigWithFetchedConfig(
    internalRulesConfig: IInternalRulesConfiguration,
    fetchedConfig: DocumentEditValidationConfiguration,
    buyerCompanyId: number,
    businessDocType: BusinessDocType
): DocumentEditValidationConfiguration {
    const focusLossFieldNames = new Set<string>([
        ...internalRulesConfig.fieldNamesToValidateOnFocusLoss,
        ...fetchedConfig.fieldNamesToValidateOnFocusLoss,
    ]);

    const eventsToValidateOn = new Set<DocumentValidationEvent['type']>([
        ...internalRulesConfig.eventsToValidateOn,
        ...fetchedConfig.eventsToValidateOn,
    ]);

    const validationFunctions = [
        ...(internalRulesConfig.validationFunctions ?? []),
        ...(fetchedConfig.validationFunctions ?? []),
    ];

    return {
        fieldNamesToValidateOnFocusLoss: Array.from(focusLossFieldNames.values()),
        eventsToValidateOn: Array.from(eventsToValidateOn.values()),
        validationFunctions: validationFunctions,
        errorRenderer: new DynamicErrorRenderer(
            buyerCompanyId,
            businessDocType,
            internalRulesConfig.validationFailureDictionary
        ),
    };
}
