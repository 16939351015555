import { FCNC } from '../FCNC';
import { FormButtons } from './FormButtons';
import { Button } from '../Button';
import { IActionButton } from './IActionButton';
import { ReactNode } from 'react';
import { Box } from '@mui/material';

export interface ITwoActionButtonsProps {
    submitButton: IActionButton; // default = Save
    cancelButton: IActionButton; // default = Cancel
    testId?: string;
    customRightFooter?: ReactNode;
    isLoading?: boolean;
}

export const TwoActionButtons: FCNC<ITwoActionButtonsProps> = ({
    submitButton,
    cancelButton,
    customRightFooter,
    isLoading,
    testId,
}) => {
    return (
        <FormButtons
            rightButtons={
                <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                    {customRightFooter}
                    <Box display="flex" gap={1}>
                        <Button variant="secondary" onClick={cancelButton.onClick} testid={`${testId}-cancel-btn`}>
                            {cancelButton.text}
                        </Button>
                        <Button
                            type="submit"
                            testid={`${testId}-submit-btn`}
                            disabled={isLoading || submitButton.isDisabled}
                        >
                            {submitButton.text}
                        </Button>
                    </Box>
                </Box>
            }
        />
    );
};
