import { BuyerCustomJSRuleMappingXrefViewModel } from './BuyerCustomJSRuleMappingXrefViewModel';

export interface BuyerCustomJSRuleMappingViewModel {
    Id: number;
    Name: string;
    Label: string;
    Description: string;
    DPSRuleName: string | null;
    RunAfterDPSRuleExecuteMethod: boolean | null;
    BusinessDocType: number | null;
    BuyerCompanyId: number | null;
    VendorClasses: string | null;
    ProcessingMode: number | null;
    RunOnWebEntry: boolean;
    WorkflowId: number | null;
    WorkflowActivityId: number | null;
    RunOnWorkflowSave: boolean;
    RunOnWorkflowSubmit: boolean;
    RunOnWorkflowReassign: boolean;
    RunOnWorkflowFocusLoss: boolean;
    RunOnWorkflowLoad: boolean;
    FocusLossFieldNames: string | null;
    BuyerCompanyName: string | null;
    WorkflowShortName: string | null;
    BuyerCustomJSRuleMappingXrefs: BuyerCustomJSRuleMappingXrefViewModel[];
    AllowedExecutionContexts: AllowedExecutionContexts | null;
}

export enum EngineVersion {
    OldEngine = 1,
    NewEngine = 2,
}

export enum ExecutionContext {
    WebEntry = 1,
    UserWorkflowOrWorkflowActivity = 2,
    DPSRule = 3,
}

export interface AllowedExecutionContexts {
    ExecutionContext: {
        Context: Context[];
    };
}

type Context = WebEntryContext | DPSRuleContext | InWorkflowOrWorkflowActivityContext;

export const ExecutionContextToContextKeyMapping: Record<ExecutionContext, Context['Type']> = {
    [ExecutionContext.WebEntry]: 'WebEntry',
    [ExecutionContext.UserWorkflowOrWorkflowActivity]: 'InWorkflowOrWorkflowActivity',
    [ExecutionContext.DPSRule]: 'DPSRule',
};

interface WebEntryContext {
    Type: 'WebEntry';
    RunOnFocusLoss?: RunOnFocusLoss;
}

interface DPSRuleContext {
    Type: 'DPSRule';
}

interface InWorkflowOrWorkflowActivityContext {
    Type: 'InWorkflowOrWorkflowActivity';
    RunOnFocusLoss?: RunOnFocusLoss;
    RunOnPageLoad?: '1' | '0';
    RunOnReassign?: '1' | '0';
    RunOnSave?: '1' | '0';
    RunOnSubmit?: '1' | '0';
    HideValidationWarningsOnButtonActions?: '1' | '0';
    RunInWorkflowEngine?: '1' | '0';
}

interface RunOnFocusLoss {
    Fields: string | null;
    DisableSubmitWhenFocusLossJShasValidationErrors?: '1' | '0';
}
