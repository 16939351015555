import { createUseCRUDService, ICRUDService } from '../../../reusableFeatures';
import { IUser, IUserFormData, UserService } from '../../../services';

export class UserCRUDService implements ICRUDService<number, IUserFormData> {
    private service = new UserService();

    get defaultState(): IUserFormData {
        return {
            id: null,
            companyID: 0,
            password: '',
            lastLoginDate: '',
            creationDate: '',
            lastLockOutDate: '',
            signinFailureCount: 0,
            lastUpdateDate: '',
            name: '',
            email: '',
            rEmail: '',
            phone: '',
            userName: '',
            invoiceProfileID: 0,
            procurementProfileID: 0,
            showInvoiceSubmittedPopupInSupplierPortal: false,
            active: false,
            title: '',
            jobClassification: '',
            department: '',
            departmentCode: '',
            region: '',
            employeeNumber: '',
            userAttributes: '',
            managersUserId: null,
            substituteUserId: null,
            substituteUserActive: false,
            approvalLimit: 0,
            rowversion: [],
            workflowTaskCount: 0,
            userRoles: [],
            workflowGroupUsers: [],
            autogenUnconfirmed: false,
            type: 0,
            type_US: '',
            menuSettings: null,
            userGroupMemberships: [],
            countryList: [],
        };
    }

    get key(): string {
        return 'User';
    }

    get invalidateKeys(): string[] {
        return ['UserSearch'];
    }

    transformFromIUserToIUserFormData(user: IUser): IUserFormData {
        return {
            ...user,
            userGroupMemberships: user?.userGroupMemberships.map((userGroupMembership) => ({
                ...userGroupMembership,
                checked: true,
            })),
            workflowGroupUsers: user?.workflowGroupUsers.map((workflowGroupUser) => ({
                ...workflowGroupUser,
                checked: true,
            })),
        };
    }

    async fetchEntity(id: number): Promise<IUserFormData> {
        return this.transformFromIUserToIUserFormData(await this.service.getUserById(id));
    }

    createEntity(state: IUserFormData): Promise<number> {
        return this.service.createUser(state);
    }

    async updateEntity(id: number, state: IUserFormData): Promise<void> {
        await this.service.updateUser(id, state);
    }

    async deleteEntity(id: number): Promise<void> {
        await this.service.deleteUser(id);
    }
}

export const useUserCRUDService = createUseCRUDService(UserCRUDService);
