import jp from 'jsonpath';
import './DocumentTitle.scss';
import { debugLogging } from '../../utils';

function getTitleLayoutFieldPath(titleLayout: { Fields: any }[], fieldName: string) {
    return titleLayout[0].Fields.find((x: any) => x.FieldName === fieldName && x.Visible).JSONPath;
}

function getConfigurableText(titleLayout: { Fields: any }[], document: any, fieldName: string) {
    try {
        const fieldPath = getTitleLayoutFieldPath(titleLayout, fieldName);
        return jp.value(document, fieldPath);
    } catch (e) {
        debugLogging.warn('---- ProServ ----');
        debugLogging.warn(`Could not retrieve DocumentTitle field "${fieldName}":`, e);
        debugLogging.warn('-----------------');
        return undefined;
    }
}

function getDefaultCompanyNameText(document: any, userCompanyId: number) {
    let otherCompanyName = '';
    if (document.CommonFields) {
        const { SenderCompanyId, SenderCompanyName, ReceiverCompanyId, ReceiverCompanyName } = document.CommonFields;

        if (ReceiverCompanyName && ReceiverCompanyId && ReceiverCompanyId !== userCompanyId) {
            otherCompanyName = ReceiverCompanyName;
        } else if (SenderCompanyName && SenderCompanyId && SenderCompanyId !== userCompanyId) {
            otherCompanyName = SenderCompanyName;
        }
    }
    return otherCompanyName;
}

interface ICompanyTitleProps {
    userCompanyId: number;
    document: any;
    titleLayout: { Fields: any }[];
    defaultDocumentTypeText: string;
    className?: string;
}

export const DocumentTitle = (props: ICompanyTitleProps) => {
    const { document, userCompanyId, titleLayout, defaultDocumentTypeText, className } = props;

    const businessDocTypeTitle = getConfigurableText(titleLayout, document, 'DocumentType') ?? defaultDocumentTypeText;
    const otherCompanyName =
        getConfigurableText(titleLayout, document, 'CompanyName') ?? getDefaultCompanyNameText(document, userCompanyId);

    return (
        <div className={`document-title-section ${className}`}>
            <span className="document-business-doc-type-title">{businessDocTypeTitle}</span>
            <span className="document-company-name-title">
                {/*<a> This will eventually be a link to the view supplier page, but until the supplier tab is implemented this can't be a link.*/}
                {otherCompanyName}
                {/*</a>*/}
            </span>
        </div>
    );
};
