import { TooltipIcon, ITooltipIconProps } from './TooltipIcon';
import { FCNC } from '../FCNC';
import { Box } from '@mui/material';
import { ReactNode } from 'react';

export interface ITooltipLabelProps {
    label?: ReactNode;
    tooltip?: ITooltipIconProps;
    pl?: number;
}

export const TooltipLabel: FCNC<ITooltipLabelProps> = ({ label, tooltip, pl }) => {
    return (
        <Box component="legend" sx={{ display: 'flex', alignItems: 'center' }}>
            <Box component="span">{label}</Box>
            {tooltip && (
                <Box sx={{ pl: pl ?? 1 }} className="TooltipLabel-icon">
                    <TooltipIcon message={tooltip.message} icon={tooltip.icon} />
                </Box>
            )}
        </Box>
    );
};

export function renderTooltipLabel({ label, tooltip }: ITooltipLabelProps): ReactNode {
    if (!label && !tooltip) {
        return undefined;
    }

    return <TooltipLabel label={label} tooltip={tooltip} />;
}
