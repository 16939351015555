import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles<Theme, { color?: string }>(() => ({
    fieldErrors: {
        listStyleType: 'initial',
    },
    error: {
        fontWeight: 500,
        overflowWrap: 'break-word',
    },
    errorItem: {
        listStyle: 'initial',
    },
    root: ({ color }) => ({
        color: color ?? 'red',
        margin: '5px',
        padding: '5px',
    }),
}));

export default useStyles;
