import associatedFieldService from '../../DocumentEditRules/PluginHost/services/associatedFieldService';
import { IScriptingEngineEnvironment, Touchpoint } from '../ScriptingEngineEnvironment';
import { DocumentValidation, Config, CustomDropdown, UIDocumentController } from './Touchpoint';

export class MetadataExtractorEnvironment implements IScriptingEngineEnvironment {
    config = new Config.ConfigTouchpoint();

    documentValidation = new DocumentValidation.DocumentValidationTouchpoint();

    customDropdown = new CustomDropdown.CustomDropdownTouchpoint();

    UIDocumentController = new UIDocumentController.UIDocumentControllerTouchPoint();

    services = {
        caching: Touchpoint.Caching.createFakeCachingService(),
        associatedFieldService: associatedFieldService,
    };
}
