export enum FieldType {
    Unknown = 0,
    RelativePositionInSection = 1,
    AbsolutePositionInSection = 2,
    RelativePositionToLabel = 3,
    RowStart = 4,
    RowEnd = 5,
    Exclude = 6,
    AbsoluteMatch = 7,
    DefaultValue = 8,
    LabelPosition = 9,
    RelativeForLines = 10,
}
