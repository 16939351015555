//

import projectConfig from '../project.config.json';

/**
 * Models for CreateInvoice container.
 */

export const defaultInvoiceLineItemsState = {
    get state() {
        return [
            {
                InvoiceLineItemMiscAmounts: [],
                InvoiceLineItemShippingDetails: [],
                LineNum: 1,
                LineType: 'LineItem',
                PurchaseOrderNum: null,
                PurchaseOrderLineNum: null,
                SupplierPartNum: null,
                BuyerPartNum: null,
                OtherPartNum: null,
                ItemDescription: null,
                UnitOfMeasure: null,
                Quantity: 0,
                UnitPrice: 0,
                DiscountPercent: 0,
                DiscountAmount: 0,
                LineItemNetTotal: 0,
                TaxPercent: 0,
                TaxAmount: 0,
                TaxCode: null,
                LineItemTotal: 0,
                ShipToContact: null,
                ShipToName: null,
                ShipToAddress1: null,
                ShipToAddress2: null,
                ShipToAddress3: null,
                ShipToCity: null,
                ShipToState: null,
                ShipToPostalCode: null,
                ShipToCountry: null,
                QuantityOrdered: 0,
                QuantityPerPack: 0,
                PieceSize: null,
                ListPrice: 0,
                PackPrice: 0,
                PiecePrice: null,
                WeightPerPack: null,
                WeightPerPiece: null,
                TotalWeight: null,
                PricingUnits: null,
                UnitDiscountAmount: null,
                MiscAmount: null,
                Comments: null,
                JobNumber: null,
                ServiceDate: null,
                TaskNumber: null,
                UnitMiscAmount: null,
                FreightAmount: null,
                ChargeNumber: null,
                BillOfLadingNumber: null,
                BuyerGLCode: null,
                BuyerMiscCode: null,
                StartDate: null,
                EndDate: null,
                BusinessUnit: null,
                OperatingUnit: null,
                Department: null,
                Location: null,
                InstallLocation: null,
                ProductClass: null,
                ProductCategory: null,
                TagId: null,
                IntrastatCode: null,
                MiscInfo: null,
                PricingUnitDivisor: null,
                POLineNumCalc: null,
                POLineNumCalcAudit: null,
                QuantityBackordered: null,
                PODistributionNumber: null,
                POShipmentNumber: null,
                VATMessage: null,
            },
        ];
    },
};

export const defaultInvoiceMiscAmountsState = {
    get state() {
        return [
            {
                AmountIndicator: null,
                AdjustmentReasonId: null,
                Description: null,
                AdjustmentMethodId: null,
                Quantity: null,
                UnitPrice: null,
                Amount: null,
                ReferenceNumber: null,
                CompanyCustomEntryFieldId: null,
                Rate: null,
            },
        ];
    },
};

export const defaultInvoiceShippingDetailsInvoiceState = {
    get state() {
        return [
            {
                BillOfLadingNumber: null,
                Carrier: null,
                CarrierPRO: null,
                PackingSlipNumber: null,
                Reference: null,
                ShippingDate: null,
                ShippingMethod: null,
                TrackingNumber: null,
            },
        ];
    },
};

const defaultCommonFieldsInvoiceState = {
    get state() {
        return {
            SenderProfileId: null,
            ReceiverProfileId: null,
            CustomerId: null,
            CSREmail: null,
            ExternalStatus: null,
            ExternalStatus_US: null,
        };
    },
};

export const defaultInvoiceContactsState = {
    get state() {
        return [
            {
                ContactType: projectConfig.ContactType.Buyer, // bill to
                Name: null,
                Contact: null,
                PartyId: null,
                Address1: null,
                Address2: null,
                Address3: null,
                City: null,
                State: null,
                PostalCode: null,
                Country: null,
                AddressBlock: null,
                PhoneNumber: null,
                Email: null,
                Address4: null,
                FaxNumber: null,
            },
            {
                ContactType: projectConfig.ContactType.ShipTo,
                Name: null,
                Contact: null,
                PartyId: null,
                Address1: null,
                Address2: null,
                Address3: null,
                City: null,
                State: null,
                PostalCode: null,
                Country: null,
                AddressBlock: null,
                PhoneNumber: null,
                Email: null,
                Address4: null,
                FaxNumber: null,
            },
            {
                ContactType: projectConfig.ContactType.RemitTo,
                Name: null,
                Contact: null,
                PartyId: null,
                Address1: null,
                Address2: null,
                Address3: null,
                City: null,
                State: null,
                PostalCode: null,
                Country: null,
                AddressBlock: null,
                PhoneNumber: null,
                Email: null,
                Address4: null,
                FaxNumber: null,
            },
            {
                ContactType: projectConfig.ContactType.Vendor,
                Name: null,
                Contact: null,
                PartyId: null,
                Address1: null,
                Address2: null,
                Address3: null,
                City: null,
                State: null,
                PostalCode: null,
                Country: null,
                AddressBlock: null,
                PhoneNumber: null,
                Email: null,
                Address4: null,
                FaxNumber: null,
            },
        ];
    },
};

const defaultInvoiceState = {
    get state() {
        return {
            InvoiceProfileID: null,
            CustomerID: null,
            CustomerNumber: null,
            Name: null,
            InvoiceNumber: null,
            InvoiceDate: null,
            InvoiceType: 'Invoice',
            InvoiceAmount: 0,
            Currency: null,
            DiscountableAmount: 0,
            AmountDue: 0,
            PurchaseOrder: null,
            SalesOrderNumber: null,
            SalesOrderDate: null,
            PaymentTerms: null,
            DueDate: null,
            DiscountDate: null,
            DiscountPercent: 0,
            DiscountAmount: 0,
            BillToAddressBlock: null,
            BillToFax: null,
            BillToEmail: null,
            TestInvoice: false,
            SalesTaxAmount: null,
            EPayDiscountDate: null,
            EPayDiscountPercent: 0,
            EPayDiscountAmount: 0,
            ShipToAddressBlock: null,
            SalesRep: null,
            ShipVia: null,
            Comments: null,
            SalesTaxPercent: 0,
            ShippingCharges: 0,
            MiscCharges: 0,
            AmountPaid: 0,
            PurchaseDate: null,
            DeliveryDate: null,
            TaxDate: null,
            DiscountDays: 10,
            VendorNumber: null,
            VendorSiteID: null,
            VendorEntityCode: null,
            Description: null,
            VisibleToBuyer: true,
            StatementHidingOption: null,
            VendorTaxId: null,
            OriginalInvoiceNumber: null,
            RemittanceInstructions: null,
            BuyerJobNumber: null,
            BuyerJobName: null,
            BuyerProjectNumber: null,
            BuyerProjectName: null,
            SupplierJobNumber: null,
            SupplierJobName: null,
            SupplierProjectNumber: null,
            SupplierProjectName: null,
            ContractNumber: null,
            ProjectManager: null,
            WorkorderNumber: null,
            TotalWeight: null,
            BuyerStatus: null,
            PurchaseOrderReleaseNumber: null,
            Currency_US: null,
            BillOfLadingNumber: null,
            CostCenter: null,
            MiscInfo: null,
            DeliveryMethod_US: null,
            BuyerCompanyCode: null,
            VendorClass: null,
            OrgUnit: null,
            CompanyInfo: null,
            PaymentStatus: null,
            FreightTerms: null,
            InvoiceNumberExternalFormat: null,
            VendorNumberExternalFormat: null,
            BankAccountNumber: null,
            BankRoutingNumber: null,
            Protocol: null,
            VATMessage: null,
            ProductDiscountAmount: 0,
            SupplierVATNumber: null,
            BuyerVATNumber: null,
            TotalVAT: null,
            InvoiceContacts: defaultInvoiceContactsState.state,
            InvoiceLineItems: defaultInvoiceLineItemsState.state,
            InvoiceMiscAmounts: [],
            InvoiceShippingDetails: [],
        };
    },
};

const defaultBusinessDocumentState = {
    get state() {
        return {
            Document: {
                Contacts: defaultInvoiceContactsState.state,
            },
        };
    },
};

const defaultBusinessDocFieldsState = {
    get state() {
        return {
            Invoice: defaultInvoiceState.state,
            ///    BusinessDocument: defaultBusinessDocumentState.state
        };
    },
};

export const defaultSelectedCurrency = {
    get state() {
        return {
            Code: 'USD',
            Symbol: '$',
        };
    },
};

const defaultState = {
    get state() {
        return {
            BusinessDocType: 1, // required field
            BusinessDocType_US: 'Invoice',
            SelectedCurrency: defaultSelectedCurrency.state,
            CommonFields: defaultCommonFieldsInvoiceState.state,
            BusinessDocFields: defaultBusinessDocFieldsState.state,
        };
    },
};

export const initialState = {
    get state() {
        return defaultState.state;
    },
};
