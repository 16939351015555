export function ParseByteFileSize(readableFileSize) {
    readableFileSize = readableFileSize.replace(',', '');

    const abbreviations = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    for (let index = 0; index < abbreviations.length; index++) {
        if (readableFileSize.includes(abbreviations[index])) {
            let abbreviationIndex = readableFileSize.indexOf(abbreviations[index]);
            readableFileSize = readableFileSize.substring(0, abbreviationIndex).trim();

            let decimalCount = 0;
            if (readableFileSize.includes('.')) {
                let decimalIndex = readableFileSize.indexOf('.');
                decimalCount = readableFileSize.substring(decimalIndex).length - 1;
                readableFileSize = readableFileSize.replace('.', '');
            }

            let desiredPaddingLength = index * 3 - decimalCount;
            return Number(readableFileSize.padEnd(readableFileSize.length + desiredPaddingLength, '0'));
        }
    }

    return Number(readableFileSize.trim());
}

export function determineContentType(fileName) {
    const extensionStartIndex = fileName.lastIndexOf('.');
    if (extensionStartIndex >= 0) {
        const extension = fileName.substring(extensionStartIndex).toLowerCase();
        switch (extension) {
            case '.bmp':
                return 'image/bmp';

            case '.gif':
                return 'image/gif';

            case '.jfif':
                return 'image/pipeg';

            case '.jpe':
            case '.jpeg':
            case '.jpg':
                return 'image/jpeg';

            case '.png':
                return 'image/png';

            default:
                return null;
        }
    }

    return null;
}
