import { UserNotificationThunk } from 'common/build/legacy/transcepta-thunks';
import { parseMessage } from '../utils';
import { pushAlert } from '../actions/alerts';
import { path } from 'ramda';
import * as NotificationActions from '../actions/userNotifications';
import projectConfig from '../project.config.json';
import { portalUserService, UserNotificationTypes } from 'common';

export const fetchNotifications = (loginNotification) => async (dispatch, getState) => {
    const ID = getState().userlogin.ID;

    const params = {
        userId: loginNotification ? null : ID,
        companyId: loginNotification ? null : portalUserService.getCurrentCompanyId(),
        $filter: loginNotification
            ? `(Type eq ${UserNotificationTypes.SupplierPortalLogin} or Type eq ${UserNotificationTypes.BothLogin})`
            : `(Type eq ${UserNotificationTypes.SupplierPortal} or Type eq ${UserNotificationTypes.BothPortals})`,
    };

    const userNotificationThunk = new UserNotificationThunk();
    const response = await userNotificationThunk.fetchNotifications(params);
    if (response.type && response.type === 'error') {
        dispatch(
            pushAlert({
                type: 'error',
                text: parseMessage(path(['response', 'data', 'errors'], response.text)),
            })
        );
    } else {
        const portalMsgTypeNone = response.data.Items.filter(
            (n) => n.PortalAcknowledgement === projectConfig.portalAcknowledgementType.None
        );

        const portalMsgTypeYesBlocking = loginNotification
            ? []
            : response.data.Items.filter(
                  (n) => n.PortalAcknowledgement === projectConfig.portalAcknowledgementType.YesBlocking
              );

        const portalMsgTypeYesNoBlocking = loginNotification
            ? []
            : response.data.Items.filter(
                  (n) => n.PortalAcknowledgement === projectConfig.portalAcknowledgementType.YesNoBlocking
              );

        dispatch(
            loginNotification
                ? NotificationActions.fetchedLoginNotifications(portalMsgTypeNone)
                : NotificationActions.fetchedNotifications(
                      portalMsgTypeNone,
                      portalMsgTypeYesBlocking,
                      portalMsgTypeYesNoBlocking
                  )
        );
    }
};

export const cleanupLoginNotifications = () => async (dispatch) => {
    dispatch(NotificationActions.fetchedLoginNotifications([]));
};

export const acknowledgeNotification = (userNotificationId) => async (dispatch, getState) => {
    const userId = getState().userlogin.ID;
    const userNotificationThunk = new UserNotificationThunk();

    await userNotificationThunk.acknowledgeUserNotification({ userId, userNotificationId });
    dispatch(fetchNotifications());
};
