import { IAccountMenu, IMenuItem } from './types';
import { FC, memo, useMemo, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { TransceptaLogoWhite } from '../Logos';
import { isExternal } from '../../utils';
import { Link } from 'react-router-dom';
import { OpenInNew } from '@mui/icons-material';
import { AccountDropdown } from './AccountDropdown';

export interface ITopBarHeaderMenuProps {
    menuItems: IMenuItem[];
    accountMenu: IAccountMenu;
}

export const TopBarHeaderMenu: FC<ITopBarHeaderMenuProps> = memo(({ menuItems, accountMenu }) => {
    const [isMenuVisible] = useState(true);
    const subdomain = window.location.href.split('.')[0].toLowerCase().replace('https://', '');

    const environmentMessage = useMemo(() => {
        switch (true) {
            case subdomain.indexOf('localhost') > -1:
                return 'localhost';
            case subdomain.indexOf('tst01') > -1:
                return 'QA-01';
            case subdomain.indexOf('tst02') > -1:
                return 'QA-02';
            case subdomain.indexOf('tst03') > -1:
                return 'QA-03';
            case subdomain.indexOf('stg') > -1:
                return 'Staging';
            case subdomain.indexOf('ps') > -1:
                return 'ProServ';
            case subdomain.indexOf('rd') > -1:
                return 'RuleDev';
            default:
                return undefined;
        }
    }, [subdomain]);

    return (
        <header data-testid="top-bar-header-menu">
            <Stack>
                {environmentMessage ? (
                    <Box
                        sx={{
                            bgcolor: 'primary.900',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography sx={{ color: 'error.300', fontWeight: 'bold' }}>{environmentMessage}</Typography>
                    </Box>
                ) : null}
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ height: 72, bgcolor: '#0A4977' }}
                >
                    <Box sx={{ px: 4 }}>
                        <a href="https://transcepta.com/">
                            <TransceptaLogoWhite />
                        </a>
                    </Box>
                    {isMenuVisible ? (
                        <Stack direction="row" sx={{ height: '100%' }}>
                            {menuItems.map((item) => {
                                const external = isExternal(item.path);
                                const selected = window.location.pathname.includes(item.path);

                                return (
                                    <Link
                                        to={item.path}
                                        key={item.name}
                                        style={{ height: '100%' }}
                                        data-testid={`menu-option-${item.testId}`}
                                    >
                                        <Box
                                            sx={
                                                selected
                                                    ? {
                                                          boxSizing: 'border-box',
                                                          mx: 2,
                                                          height: '100%',
                                                          borderBottom: 6,
                                                          borderColor: 'primary.400',
                                                          pt: 3.5,
                                                      }
                                                    : {
                                                          mx: 2,
                                                          height: '100%',
                                                          pt: 3.5,
                                                      }
                                            }
                                        >
                                            <Typography
                                                variant="button"
                                                color="white"
                                                noWrap
                                                sx={{ textTransform: 'capitalize' }}
                                            >
                                                {item.name}
                                            </Typography>
                                            {external ? <OpenInNew sx={{ fontSize: 14 }} /> : undefined}
                                        </Box>
                                    </Link>
                                );
                            })}
                        </Stack>
                    ) : null}
                    <Box>
                        <AccountDropdown accountMenu={accountMenu} />
                    </Box>
                </Stack>
            </Stack>
        </header>
    );
});
