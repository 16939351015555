import { IDashboardRejectionTypesViewModel } from './ViewModels';
import { api } from '../../../services/api';
import ICreatedUpdateDeleteViewModel from '../../Common/Api/ViewModels/CreatedUpdateDeleteViewModel';
import { AxiosResponse } from 'axios';

class DashboardRejectionTypesApi {
    route = '/DashboardRejectionType';

    public getAllDashboardRejectionTypes = async (
        filter?: string
    ): Promise<AxiosResponse<IDashboardRejectionTypesViewModel[] | undefined>> => {
        const params = {
            $filter: filter,
        };

        return api().get(`${this.route}`, { params });
    };

    public getDashboardRejectionType = async (
        id: number,
        filter?: string
    ): Promise<AxiosResponse<IDashboardRejectionTypesViewModel[] | undefined>> => {
        const params = {
            $filter: filter,
        };

        return api().get(`${this.route}/${id}`, { params });
    };

    public updateDashboardRejectionType = async (
        id: number,
        dashboardRejectionType: IDashboardRejectionTypesViewModel
    ): Promise<AxiosResponse<ICreatedUpdateDeleteViewModel | undefined>> => {
        return api().put(`${this.route}/${id}`, dashboardRejectionType);
    };

    public createDashboardRejectionType = async (
        dashboardRejectionType: IDashboardRejectionTypesViewModel
    ): Promise<AxiosResponse<ICreatedUpdateDeleteViewModel | undefined>> => {
        return api().post(`${this.route}`, dashboardRejectionType);
    };

    public deleteDashboardRejectionType = async (
        id: number
    ): Promise<AxiosResponse<ICreatedUpdateDeleteViewModel | undefined>> => {
        return api().delete(`${this.route}/${id}`);
    };
}

export default DashboardRejectionTypesApi;
