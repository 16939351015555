import paths from './paths.config.json';

/**
 * This module allows overriding the paths configuration with environment variables,
 * which is useful for development.
 */

if (process.env.REACT_APP_PORTAL_ROOT_URL !== undefined) {
    paths.ROOT_URL = process.env.REACT_APP_PORTAL_ROOT_URL;
}

if (process.env.REACT_APP_PORTAL_IFRAME_DISABLED === 'true') {
    paths.iframe.disableIframe = true;
}
