import { DateRange } from '@mui/x-date-pickers-pro';
import { endOfDay, startOfDay, subDays, subMonths, subWeeks } from 'date-fns';

export enum DateRangePickerShortcut {
    TODAY = 1,
    YESTERDAY,
    PAST_WEEK,
    PAST_MONTH,
    PAST_3_MONTHS,
    PAST_6_MONTHS,
}

// return start and end dates by providing a date range label
export function getDatesFromShortcut(shortcut: DateRangePickerShortcut): DateRange<Date> {
    const today = new Date();
    let ret: DateRange<Date> = [today, today];
    if (shortcut) {
        switch (shortcut) {
            case DateRangePickerShortcut.TODAY:
                ret = [startOfDay(today), endOfDay(today)];
                break;
            case DateRangePickerShortcut.YESTERDAY:
                ret = [startOfDay(subDays(today, 1)), endOfDay(today)];
                break;
            case DateRangePickerShortcut.PAST_WEEK:
                ret = [startOfDay(subWeeks(today, 1)), endOfDay(today)];
                break;
            case DateRangePickerShortcut.PAST_MONTH:
                ret = [startOfDay(subMonths(today, 1)), endOfDay(today)];
                break;
            case DateRangePickerShortcut.PAST_3_MONTHS:
                ret = [startOfDay(subMonths(today, 3)), endOfDay(today)];
                break;
            case DateRangePickerShortcut.PAST_6_MONTHS:
                ret = [startOfDay(subMonths(today, 6)), endOfDay(today)];
                break;
            default:
                console.error('ERROR: could not match saved date picker shortcut value');
        }
    }
    return ret;
}
