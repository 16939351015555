export const getReadableFileSize = (
    bytes: number,
    inDecimal: boolean = true,
    separator: string = ' ',
    fixed: number = 1
) => {
    const i = Math.floor(Math.log(bytes) / Math.log(inDecimal ? 1000 : 1024)),
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    return `${parseFloat((bytes / Math.pow(inDecimal ? 1000 : 1024, i)).toFixed(fixed))}${separator}${sizes[i]}`;
};
