import * as ErrorCode from '../../../reusableFeatures/ErrorCode';

export interface BaseState {
    timestamp: number;
}

export interface LoadingState extends BaseState {
    type: 'LoadingState';
}

export function createLoadingState(): LoadingState {
    return {
        type: 'LoadingState',
        timestamp: Date.now(),
    };
}

export interface ReadyState extends BaseState {
    type: 'ReadyState';
}

export function createReadyState(): ReadyState {
    return {
        type: 'ReadyState',
        timestamp: Date.now(),
    };
}

export interface FailedToLoadState extends BaseState {
    type: 'FailedToLoadState';

    reason:
        | 'ConfigLoadNetworkError'
        | 'ConfigParseError'
        | 'ScriptLoadNetworkError'
        | 'ScriptParseError'
        | 'ConfigNotAllowedDueToInternalRulesError'
        | 'IllegalInternalRulesError'
        | 'InternalRuleFailedToLoadError';

    errorInfo: ErrorCode.IExternalErrorInfo;
}

export function createFailedToLoadState(reason: FailedToLoadState['reason']): FailedToLoadState {
    const timestamp = Date.now();
    return {
        type: 'FailedToLoadState',
        reason,
        timestamp,
        errorInfo: ErrorCode.createExternalErrorInfo(`DocumentEditValidation.${reason}`, timestamp),
    };
}

export type ValidatorState = LoadingState | ReadyState | FailedToLoadState;

export interface UninitializedState extends BaseState {
    type: 'UninitializedState';
}

export function createUninitializedState(): UninitializedState {
    return {
        type: 'UninitializedState',
        timestamp: Date.now(),
    };
}

export interface IdleState extends BaseState {
    type: 'IdleState';
}

export function createIdleState(): IdleState {
    return {
        type: 'IdleState',
        timestamp: Date.now(),
    };
}

export interface ValidatingState extends BaseState {
    type: 'ValidatingState';
}

export function createValidatingState(): ValidatingState {
    return {
        type: 'ValidatingState',
        timestamp: Date.now(),
    };
}

export interface ValidationSucceededState extends BaseState {
    type: 'ValidationSucceededState';

    messageClasses: MessageClasses;
}

export function createValidationSucceededState(messageClasses: MessageClasses): ValidationSucceededState {
    return {
        type: 'ValidationSucceededState',
        timestamp: Date.now(),
        messageClasses,
    };
}

export interface FieldErrorMessages {
    [fieldKey: string]: string[] | undefined;
}

export interface MessageClass {
    messages: string[];

    fieldMessages: FieldErrorMessages;
}

export interface MessageClasses {
    errorMessages: MessageClass;

    warningMessages: MessageClass;
}

export interface ValidationFailedState extends BaseState {
    type: 'ValidationFailedState';

    messageClasses: MessageClasses;
}

export function createValidationFailedState(messageClasses: MessageClasses): ValidationFailedState {
    return {
        type: 'ValidationFailedState',
        timestamp: Date.now(),
        messageClasses,
    };
}

export interface ValidationFunctionInvalidResponseState extends BaseState {
    type: 'ValidationFunctionInvalidResponseState';

    errorInfo: ErrorCode.IExternalErrorInfo;
}

export function createValidationFunctionInvalidResponseState(): ValidationFunctionInvalidResponseState {
    const timestamp = Date.now();
    return {
        type: 'ValidationFunctionInvalidResponseState',
        timestamp,
        errorInfo: ErrorCode.createExternalErrorInfo('DocumentEditValidation.FunctionInvalidResponse'),
    };
}

export interface ValidationImplementationErrorState extends BaseState {
    type: 'ValidationImplementationErrorState';

    errorInfo: ErrorCode.IExternalErrorInfo;
}

export function createValidationImplementationErrorState(): ValidationImplementationErrorState {
    const timestamp = Date.now();
    return {
        type: 'ValidationImplementationErrorState',
        timestamp,
        errorInfo: ErrorCode.createExternalErrorInfo('DocumentEditValidation.ImplementationError'),
    };
}

export interface ExecutingDocumentControllersState extends BaseState {
    type: 'ExecutingDocumentControllersState';
}

export function createExecutingDocumentControllersState(): ExecutingDocumentControllersState {
    return {
        type: 'ExecutingDocumentControllersState',
        timestamp: Date.now(),
    };
}

export type ValidatorResultState =
    | UninitializedState
    | IdleState
    | ValidatingState
    | ValidationSucceededState
    | ValidationFailedState
    | ValidationFunctionInvalidResponseState
    | ValidationImplementationErrorState
    | ExecutingDocumentControllersState;
