import { FC, useState, ReactNode, FormEvent } from 'react';
import { IconButton, InputAdornment, Stack } from '@mui/material';
import { Button, LoadingMessage, TextField } from '../../../ui';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useHistory } from 'react-router-dom';
import { FormTemplate } from './FormTemplate';
import { useAuthService } from '../Auth';
import { useMutation } from '@tanstack/react-query';

export interface ILoginPageProps {
    forgotPasswordRoute: string;
    marketingContent?: ReactNode;
}

export const LoginPage: FC<ILoginPageProps> = ({ forgotPasswordRoute, marketingContent }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const authService = useAuthService();
    const logInMutation = useMutation(authService.logIn);

    const history = useHistory();

    const handleSignIn = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        logInMutation.mutate({ email, password });
    };
    return (
        <FormTemplate
            mainContent={
                logInMutation.isLoading ? (
                    <LoadingMessage>Authenticating</LoadingMessage>
                ) : (
                    <form onSubmit={(e) => handleSignIn(e)}>
                        <Stack>
                            <TextField
                                stacked={true}
                                required
                                id="transcepta-email"
                                testId="transcepta-email"
                                label="Email"
                                type="email"
                                autoComplete="email"
                                value={email}
                                onChange={(event) => {
                                    const element = event.target as HTMLInputElement;
                                    const newEmail = element.value as string;

                                    setEmail(newEmail);
                                }}
                                sx={{ m: 1, width: 'auto' }}
                            />
                            <TextField
                                stacked={true}
                                required
                                id="transcepta-password"
                                testId="transcepta-password"
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="current-password"
                                value={password}
                                onChange={(event) => {
                                    const element = event.target as HTMLInputElement;
                                    const newPassword = element.value as string;
                                    setPassword(newPassword);
                                }}
                                sx={{ m: 1, width: 'auto' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button testid="sign-in-button" variant="default" type="submit" sx={{ m: 1 }}>
                                Sign In
                            </Button>
                            <Button
                                variant="secondary"
                                sx={{ m: 1 }}
                                onClick={() => {
                                    history.push(forgotPasswordRoute);
                                }}
                            >
                                Forgot Password
                            </Button>
                        </Stack>
                    </form>
                )
            }
            marketingContent={marketingContent}
        />
    );
};
