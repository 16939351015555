import { useQuery } from '@tanstack/react-query';
import { CompanyService } from '../CompanyService';
import { GetTradingPartnersParams } from '../types';

export const useTradingPartners = (params: GetTradingPartnersParams) => {
    const { data, isFetching } = useQuery(
        ['TradingPartners', params.companyId, params.businessDocType, params.$filter],
        async () => {
            const companyService = new CompanyService();
            return companyService.getTradingPartners(params);
        }
    );

    return { tradingPartners: data, tradingPartnersIsFetching: isFetching };
};
