import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import errorTypes from './errorTypes';
import { createExternalErrorInfo, IExternalErrorInfo } from './IExternalErrorInfo';
import { FriendlyErrorMessage } from './FriendlyErrorMessage';
import { EventEmitter, useSubscription } from '../../utils';

interface ILegacyErrorHandler {
    /**
     * Shows the user an error message on the current page.
     * @param errorKey the type of error that occurred.
     */
    showError(errorKey: keyof typeof errorTypes): void;

    /**
     * Until the next page load, ensures only one error per type
     * will be displayed to the user. Only the first one will be reported
     * to Transcepta as well. This is to handle cases where legacy code may
     * have multiple simultaneous(ish) failures, but they all lead to the same
     * observable effect for the user (such as a page not finishing loading).
     */
    onlyShowOneErrorPerTypeOnThisPage(): void;
}

class LegacyErrorHandler implements ILegacyErrorHandler {
    private oneErrorPerType: boolean = false;

    private seenErrorTypes = new Set<string>();

    private errorInfos: IExternalErrorInfo[] = [];

    private emitter = new EventEmitter<IExternalErrorInfo[]>();

    public subscribe = this.emitter.subscribe.bind(this.emitter);

    onlyShowOneErrorPerTypeOnThisPage() {
        this.oneErrorPerType = true;
    }

    reset() {
        this.errorInfos = [];
        this.notify();
        this.oneErrorPerType = false;
        this.seenErrorTypes = new Set<string>();
    }

    showError(errorKey: keyof typeof errorTypes) {
        if (this.oneErrorPerType && this.seenErrorTypes.has(errorKey)) {
            return;
        }
        this.seenErrorTypes.add(errorKey);
        const errorInfo = createExternalErrorInfo(errorKey);
        this.errorInfos.push(errorInfo);
        this.notify();
    }

    private notify() {
        this.emitter.notify(this.errors);
    }

    get errors() {
        return this.errorInfos;
    }
}

const errorHandler = new LegacyErrorHandler();

function useErrors() {
    useSubscription(errorHandler.subscribe.bind(errorHandler));

    const history = useHistory();
    useEffect(() => {
        history.listen(() => {
            errorHandler.reset();
        });
    }, [history]);

    return errorHandler.errors;
}

/**
 * Renders friendly messages for errors we cannot handle in our legacy portals.
 *
 * This is designed to make it easy to push errors from any point in our code. We should
 * not use it for new features. Instead, new features should handle their own state management
 * of errors and use FriendlyErrorMessage to render their messages.
 */
export function LegacyPortalErrors() {
    const errors = useErrors();

    return (
        <>
            {errors.map((error, index) => (
                <FriendlyErrorMessage
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    externalErrorInfo={error}
                    testId={`LegacyPortalErrors--${index}`}
                />
            ))}
        </>
    );
}

/**
 * Use this object to show users friendly messages when a legacy portal
 * fails to load a page. Do not use for new features, only for enhancing
 * existing features with error handling support.
 */
export const legacyErrorHandler: ILegacyErrorHandler = errorHandler;
