import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStableReference } from '../../utils';
import { Collapse, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { IMenuItem, ISubMenu } from './types';
import { HeaderMenuItem } from './HeaderMenuItem';
import { useIsSelected } from './useIsSelected';

interface INavigationSubMenuItemProps {
    parentItem: IMenuItem;
    subMenu: ISubMenu;
}

export const NavigationSubMenuItem: FC<INavigationSubMenuItemProps> = ({ parentItem, subMenu }) => {
    const [open, setOpen] = useState<boolean>(false);
    const history = useHistory();
    const subMenuItems = useStableReference(subMenu.items);
    const isSelected = useIsSelected();

    useEffect(() => {
        const openSubMenuIfNeeded = () => {
            const someSubMenuItemIsSelected = subMenuItems.some((item: any) => isSelected(item.path));

            setOpen(someSubMenuItemIsSelected);
        };

        openSubMenuIfNeeded();

        return history.listen(openSubMenuIfNeeded);
    }, [history, isSelected, subMenuItems]);

    const handleOpen = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItem sx={{ px: 0, py: 0 }} data-testid={`menu-option-${parentItem.testId ?? parentItem.name}`}>
                <ListItemButton onClick={handleOpen}>
                    <ListItemText primary={parentItem.name} />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {subMenu.items.map((item, j) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <HeaderMenuItem menuItem={item} key={j} paddingLeft={4} isSelected={isSelected(item.path)} />
                    ))}
                </List>
            </Collapse>
        </>
    );
};
