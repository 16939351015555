export const CLEAR_DOCUMENT_CREATION_CACHE = 'CLEAR_DOCUMENT_CREATION_CACHE';
export const CLEAR_FLIP_CACHE = 'CLEAR_FLIP_CACHE';
export const CLEAR_SUPPORTING_DOCUMENT_CACHE = 'CLEAR_SUPPORTING_DOCUMENT_CACHE';
export const DOCUMENT_CREATION_ADD_LINE = 'DOCUMENT_CREATION_ADD_LINE';
export const DOCUMENT_CREATION_IDENTIFYING_NUMBER_EDITED = 'DOCUMENT_CREATION_IDENTIFYING_NUMBER_EDITED';
export const DOCUMENT_CREATION_INITIALIZATION_BEGIN = 'DOCUMENT_CREATION_INITIALIZATION_BEGIN';
export const DOCUMENT_CREATION_INITIALIZATION_COMPLETE = 'DOCUMENT_CREATION_INITIALIZATION_COMPLETE';
export const DOCUMENT_CREATION_LOAD_DOCUMENT = 'DOCUMENT_CREATION_LOAD_DOCUMENT';
export const DOCUMENT_CREATION_REMOVE_LINE = 'DOCUMENT_CREATION_REMOVE_LINE';
export const DOCUMENT_CREATION_SAVING_BEGIN = 'DOCUMENT_CREATION_SAVING_BEGIN';
export const DOCUMENT_CREATION_SAVING_COMPLETE = 'DOCUMENT_CREATION_SAVING_COMPLETE';
export const DOCUMENT_CREATION_SET_DEFAULT_COMMON_FIELDS = 'DOCUMENT_CREATION_SET_DEFAULT_COMMON_FIELDS';
export const DOCUMENT_CREATION_SET_ORIGINAL_STATE_INFO = 'DOCUMENT_CREATION_SET_ORIGINAL_STATE_INFO';
export const DOCUMENT_CREATION_UPDATE_FIELD_VALUE = 'DOCUMENT_CREATION_UPDATE_FIELD_VALUE';
export const DOCUMENT_CREATION_UPDATING_FIELDS_BEGIN = 'DOCUMENT_CREATION_UPDATING_FIELDS_BEGIN';
export const DOCUMENT_CREATION_UPDATING_FIELDS_COMPLETE = 'DOCUMENT_CREATION_UPDATING_FIELDS_COMPLETE';
export const DOCUMENT_LINE_ITEM_SKELETON_INITALIZE_HEADER_FIELD_VALUES =
    'DOCUMENT_LINE_ITEM_SKELETON_INITALIZE_HEADER_FIELD_VALUES';
export const DOCUMENT_LINE_ITEM_SKELETON_UPDATE_FIELD_VALUE = 'DOCUMENT_LINE_ITEM_SKELETON_UPDATE_FIELD_VALUE';
export const FETCH_DOCUMENT_FOR_EDIT_EXECUTE = 'FETCH_DOCUMENT_FOR_EDIT_EXECUTE';
export const FETCH_DOCUMENT_FOR_EDIT_FAILURE = 'FETCH_DOCUMENT_FOR_EDIT_FAILURE';
export const FETCH_DOCUMENT_FOR_EDIT_NOT_FOUND = 'FETCH_DOCUMENT_FOR_EDIT_NOT_FOUND';
export const FETCH_DOCUMENT_FOR_EDIT_SUCCESS = 'FETCH_DOCUMENT_FOR_EDIT_SUCCESS';
export const FETCH_DOCUMENT_SKELETON_EXECUTE = 'FETCH_DOCUMENT_SKELETON_EXECUTE';
export const FETCH_DOCUMENT_SKELETON_FAILURE = 'FETCH_DOCUMENT_SKELETON_FAILURE';
export const FETCH_DOCUMENT_SKELETON_SUCCESS = 'FETCH_DOCUMENT_SKELETON_SUCCESS';
export const FETCH_INVOICE_DOCUMENT_FOR_EDIT_SUCCESS = 'FETCH_INVOICE_DOCUMENT_FOR_EDIT_SUCCESS';
export const FETCH_SUPPORTING_DOCUMENTS_FOR_EDIT_EXECUTE = 'FETCH_SUPPORTING_DOCUMENTS_FOR_EDIT_EXECUTE';
export const FETCH_SUPPORTING_DOCUMENTS_FOR_EDIT_FAILURE = 'FETCH_SUPPORTING_DOCUMENTS_FOR_EDIT_FAILURE';
export const FETCH_SUPPORTING_DOCUMENTS_FOR_EDIT_SUCCESS = 'FETCH_SUPPORTING_DOCUMENTS_FOR_EDIT_SUCCESS';
export const RESET_TO_INITIAL_STATE = 'RESET_TO_INTIAL_STATE';
export const SAVE_DOCUMENT_DRAFT_EXECUTE = 'SAVE_DOCUMENT_DRAFT_EXECUTE';
export const SAVE_DOCUMENT_DRAFT_FAILURE = 'SAVE_DOCUMENT_DRAFT_FAILURE';
export const SAVE_DOCUMENT_DRAFT_SUCCESS = 'SAVE_DOCUMENT_DRAFT_SUCCESS';
export const SAVE_DOCUMENT_DRAFT_ATTACHMENTS_EXECUTE = 'SAVE_DOCUMENT_DRAFT_ATTACHMENTS_EXECUTE';
export const SAVE_DOCUMENT_DRAFT_ATTACHMENTS_FAILURE = 'SAVE_DOCUMENT_DRAFT_ATTACHMENTS_FAILURE';
export const SAVE_DOCUMENT_DRAFT_ATTACHMENTS_SUCCESS = 'SAVE_DOCUMENT_DRAFT_ATTACHMENTS_SUCCESS';
export const SAVE_DOCUMENT_FLIP_LINE_ITEMS = 'SAVE_DOCUMENT_FLIP_LINE_ITEMS';
export const SAVE_FLIP_SOURCE = 'SAVE_FLIP_SOURCE_ID';
export const SAVE_LAYOUT_OVERRIDES_EXECUTE = 'SAVE_LAYOUT_OVERRIDES_EXECUTE';
export const SET_FIELD_DEFAULTS = 'SET_FIELD_DEFAULTS';
export const SET_FLIP = 'SET_FLIP';
export const SET_SENDER_PROFILE = 'SET_SENDER_PROFILE';
export const SUBMIT_DOCUMENT_EXECUTE = 'SUBMIT_DOCUMENT_EXECUTE';
export const SUBMIT_DOCUMENT_FAILURE = 'SUBMIT_DOCUMENT_FAILURE';
export const SUBMIT_DOCUMENT_SUCCESS = 'SUBMIT_DOCUMENT_SUCCESS';
export const SUPPORTING_DOCUMENT_REMOVED = 'SUPPORTING_DOCUMENT_REMOVED';
export const SUPPORTING_DOCUMENTS_ADDED = 'SUPPORTING_DOCUMENTS_ADDED';
export const UPDATE_DOCUMENT_EXECUTE = 'UPDATE_DOCUMENT_EXECUTE';
export const UPDATE_DOCUMENT_FAILURE = 'UPDATE_DOCUMENT_FAILURE';
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS';
export const SIM_SELECTED_DOCUMENT_CLASS = 'SIM_SELECTED_DOCUMENT_CLASS';
export const SIM_REQUEST_FIELDS = 'SIM_REQUEST_FIELDS';
export const SIM_DOCUMENT_CREATION_SET_DEFAULT_BUS_DOC_FIELDS = 'SIM_DOCUMENT_CREATION_SET_DEFAULT_BUS_DOC_FIELDS';
export const UPDATE_LAYOUT_FIELD_VISIBLITY = 'UPDATE_LAYOUT_FIELD_VISIBLITY';
export const UPDATE_LAYOUT_FIELD = 'UPDATE_LAYOUT_FIELD';

/* -------------------------------------------------------------------------- */
export const clearDocumentCreationCache = () => ({
    type: CLEAR_DOCUMENT_CREATION_CACHE,
});

export const resetDocumentCreationToInitalState = () => ({
    type: RESET_TO_INITIAL_STATE,
});

export const clearSupportingDocumentCache = () => ({
    type: CLEAR_SUPPORTING_DOCUMENT_CACHE,
});

/* -------------------------------------------------------------------------- */
export const fetchDocumentForEditExecute = (documentId, draftId, params) => ({
    type: FETCH_DOCUMENT_FOR_EDIT_EXECUTE,
    payload: {
        documentId: documentId,
        draftId: draftId,
        params: params,
    },
});

export const fetchDocumentForEditFailure = (err) => ({
    type: FETCH_DOCUMENT_FOR_EDIT_FAILURE,
    payload: err.response.data,
});

export const fetchDocumentForEditNotFound = (documentId, draftId) => ({
    type: FETCH_DOCUMENT_FOR_EDIT_NOT_FOUND,
    payload: {
        documentId: documentId,
        draftId: draftId,
    },
});

export const fetchDocumentForEditSuccess = (data) => ({
    type: FETCH_DOCUMENT_FOR_EDIT_SUCCESS,
    payload: data,
});

export const fetchInvoiceDocumentForEditSuccess = (data) => ({
    type: FETCH_INVOICE_DOCUMENT_FOR_EDIT_SUCCESS,
    payload: data,
});

/* -------------------------------------------------------------------------- */
export const fetchDocumentSkeletonExecute = (params) => ({
    type: FETCH_DOCUMENT_SKELETON_EXECUTE,
    payload: {
        params: params,
    },
});

export const fetchDocumentSkeletonFailure = (err) => ({
    type: FETCH_DOCUMENT_SKELETON_FAILURE,
    payload: err,
});

export const fetchDocumentSkeletonSuccess = (data) => ({
    type: FETCH_DOCUMENT_SKELETON_SUCCESS,
    payload: data,
});

/* -------------------------------------------------------------------------- */
export const fetchSupportingDocumentsForEditExecute = (params) => ({
    type: FETCH_SUPPORTING_DOCUMENTS_FOR_EDIT_EXECUTE,
    payload: { params: params },
});

export const fetchSupportingDocumentsForEditFailure = (err) => ({
    type: FETCH_SUPPORTING_DOCUMENTS_FOR_EDIT_FAILURE,
    payload: err.response.data,
});

export const fetchSupportingDocumentsForEditSuccess = (data) => ({
    type: FETCH_SUPPORTING_DOCUMENTS_FOR_EDIT_SUCCESS,
    payload: {
        files: data,
    },
});

/* -------------------------------------------------------------------------- */
export const saveDraftExecute = (params, businessDocType) => ({
    type: SAVE_DOCUMENT_DRAFT_EXECUTE,
    payload: {
        businessDocType: businessDocType,
        params: params,
    },
});

export const saveDraftFailure = (err) => ({
    type: SAVE_DOCUMENT_DRAFT_FAILURE,
    payload: err.response.data,
});

export const saveDraftSuccess = (data) => ({
    type: SAVE_DOCUMENT_DRAFT_SUCCESS,
    payload: data,
});

/* -------------------------------------------------------------------------- */
export const saveDraftAttachmentsExecute = (params) => ({
    type: SAVE_DOCUMENT_DRAFT_ATTACHMENTS_EXECUTE,
    payload: { params: params },
});

export const saveDraftAttachmentsFailure = (err) => ({
    type: SAVE_DOCUMENT_DRAFT_ATTACHMENTS_FAILURE,
    payload: err.response.data,
});

export const saveDraftAttachmentsSuccess = (data) => ({
    type: SAVE_DOCUMENT_DRAFT_ATTACHMENTS_SUCCESS,
    payload: data,
});

/* -------------------------------------------------------------------------- */
export const submitDocumentExecute = (params) => ({
    type: SUBMIT_DOCUMENT_EXECUTE,
    payload: { params: params },
});

export const submitDocumentFailure = (err) => ({
    type: SUBMIT_DOCUMENT_FAILURE,
    payload: err.response.data,
});

export const submitDocumentSuccess = (data) => ({
    type: SUBMIT_DOCUMENT_SUCCESS,
    payload: data,
});

/* -------------------------------------------------------------------------- */
export const updateDocumentExecute = (params) => ({
    type: UPDATE_DOCUMENT_EXECUTE,
    payload: { params: params },
});

export const updateDocumentFailure = (err) => ({
    type: UPDATE_DOCUMENT_FAILURE,
    payload: err.response.data,
});

export const updateDocumentSuccess = (data) => ({
    type: UPDATE_DOCUMENT_SUCCESS,
    payload: data,
});

/* -------------------------------------------------------------------------- */
export const supportingDocumentRemoved = (acceptedFiles, rejectedFiles, savedFiles, deletedFiles) => ({
    type: SUPPORTING_DOCUMENT_REMOVED,
    payload: {
        acceptedFiles: acceptedFiles,
        rejectedFiles: rejectedFiles,
        savedFiles: savedFiles,
        deletedFiles: deletedFiles,
    },
});

export const supportingDocumentsAdded = (acceptedFiles) => ({
    type: SUPPORTING_DOCUMENTS_ADDED,
    payload: acceptedFiles,
});

/* -------------------------------------------------------------------------- */
export const performDocumentLoad = (document) => ({
    type: DOCUMENT_CREATION_LOAD_DOCUMENT,
    payload: document,
});

export const performDocumentSetDefaultCommonFields = (defaultCommonFields) => ({
    type: DOCUMENT_CREATION_SET_DEFAULT_COMMON_FIELDS,
    payload: defaultCommonFields,
});

export const performDocumentSetDefaultBusinessDocFields = (defaultBusinessDocFields) => ({
    type: SIM_DOCUMENT_CREATION_SET_DEFAULT_BUS_DOC_FIELDS,
    payload: defaultBusinessDocFields,
});

export const performDocumentSetOriginalStateInfo = (identifyingNumber) => ({
    type: DOCUMENT_CREATION_SET_ORIGINAL_STATE_INFO,
    payload: {
        identifyingNumber: identifyingNumber,
    },
});

export const performDocumentFieldValueUpdate = (businessDocument) => ({
    type: DOCUMENT_CREATION_UPDATE_FIELD_VALUE,
    payload: businessDocument,
});

export const addDocumentLine = (businessDocument) => ({
    type: DOCUMENT_CREATION_ADD_LINE,
    payload: businessDocument,
});

export const removeDocumentLine = (businessDocument) => ({
    type: DOCUMENT_CREATION_REMOVE_LINE,
    payload: businessDocument,
});

export const performDocumentLineItemSkeletonFieldValueUpdate = (lineItemSkeleton) => ({
    type: DOCUMENT_LINE_ITEM_SKELETON_UPDATE_FIELD_VALUE,
    payload: lineItemSkeleton,
});

export const initializeDocumentLineItemSkeletonWithHeaderFieldValues = (lineItemSkeleton) => ({
    type: DOCUMENT_LINE_ITEM_SKELETON_INITALIZE_HEADER_FIELD_VALUES,
    payload: lineItemSkeleton,
});

export const documentCreationIdentifyingNumberEdited = (edited) => ({
    type: DOCUMENT_CREATION_IDENTIFYING_NUMBER_EDITED,
    payload: edited,
});

export const documentCreationInitializationBegin = () => ({
    type: DOCUMENT_CREATION_INITIALIZATION_BEGIN,
});

export const documentCreationInitializationComplete = () => ({
    type: DOCUMENT_CREATION_INITIALIZATION_COMPLETE,
});

export const documentCreationSavingBegin = () => ({
    type: DOCUMENT_CREATION_SAVING_BEGIN,
});

export const documentCreationSavingComplete = () => ({
    type: DOCUMENT_CREATION_SAVING_COMPLETE,
});

export const documentCreationUpdatingFieldsBegin = () => ({
    type: DOCUMENT_CREATION_UPDATING_FIELDS_BEGIN,
});

export const documentCreationUpdatingFieldsComplete = () => ({
    type: DOCUMENT_CREATION_UPDATING_FIELDS_COMPLETE,
});

/* -------------------------------------------------------------------------- */
export const clearFlipCache = () => ({
    type: CLEAR_FLIP_CACHE,
});

export const saveFlipLineItems = (lineItems) => ({
    type: SAVE_DOCUMENT_FLIP_LINE_ITEMS,
    payload: lineItems,
});

export const saveFlipSource = (documentId, businessDocType) => ({
    type: SAVE_FLIP_SOURCE,
    payload: {
        documentId: documentId,
        businessDocType: businessDocType,
    },
});

export const setFlip = (flip) => ({
    type: SET_FLIP,
    payload: flip,
});

/* -------------------------------------------------------------------------- */
export const setFieldDefaults = (fieldDefaults) => ({
    type: SET_FIELD_DEFAULTS,
    payload: fieldDefaults,
});

/* -------------------------------------------------------------------------- */
export const saveLayoutOverridesExecute = (params) => ({
    type: SAVE_LAYOUT_OVERRIDES_EXECUTE,
    payload: {
        params: params,
    },
});

/* -------------------------------------------------------------------------- */
export const setSenderProfile = (profileId) => ({
    type: SET_SENDER_PROFILE,
    payload: profileId,
});

export const updateSIMDocumentClass = (payload) => (dispatch) =>
    dispatch({
        type: SIM_SELECTED_DOCUMENT_CLASS,
        payload,
    });

export const updateSIMDocumentCreationFields = (payload) => (dispatch) => {
    dispatch({
        type: SIM_REQUEST_FIELDS,
        payload,
    });
};

export const updateLayoutVisibility = (fieldName) => (dispatch) => {
    dispatch({
        type: UPDATE_LAYOUT_FIELD_VISIBLITY,
        payload: fieldName,
    });
};

export const updateLayoutFieldRequiredAndControlFormat = (payload) => (dispatch) => {
    dispatch({
        type: UPDATE_LAYOUT_FIELD,
        payload,
    });
};
