import { StrictMode, FC, useEffect } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter, useHistory } from 'react-router-dom';
import { SubRoutePreservationProvider, HelpButton } from '../../features';
import createQueryClient from './createQueryClient';
import { ResetScrollState } from './ResetScrollState';
import { analyticsHooks, Zendesk } from '../../infrastructure';
import { CrashTestFlag, ToastShower, ErrorBoundary, TransceptaThemeProvider } from '../../ui';
import { IRoutes } from '../../types';
import { handlePageChange, init } from '../../utils';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_PRO_KEY!);

const WithStrictMode: FC<{ useStrictMode?: boolean }> = ({ useStrictMode, children }) => {
    if (useStrictMode) {
        return <StrictMode>{children}</StrictMode>;
    }

    return <>{children}</>;
};

const WithBrowserRouter: FC<{ useBrowserRouter?: boolean; rootUrl: string }> = ({
    useBrowserRouter,
    rootUrl,
    children,
}) => {
    if (useBrowserRouter) {
        return <BrowserRouter basename={rootUrl}>{children}</BrowserRouter>;
    }

    return <>{children}</>;
};

/**
 * This component should be rendered inside the router so it can detect
 * page changes and allow the LogRocketUtil to handle them.
 */
const SavePageVisitTimeToLogRocketUtil: FC = () => {
    const history = useHistory();

    useEffect(() => {
        history.listen(() => {
            handlePageChange();
        });
    }, [history]);

    return null;
};

/**
 * Bootstraps the analytics hooks system
 */
const AnalyticsHooksBootstrap: FC = () => {
    const history = useHistory();

    useEffect(() => {
        analyticsHooks.bootstrap();

        // trigger an initial page view event
        analyticsHooks.triggerPageView();

        history.listen(() => {
            // trigger page view event whenever history changes
            analyticsHooks.triggerPageView();
        });
    }, [history]);

    return null;
};

export interface IPortalProvidersProps {
    /**
     * Enables React strict mode for the subtree if true.
     *
     * This option exists to support legacy portals and will be removed
     * when those portals no longer need to disable this functionality.
     *
     * Defaults to true.
     */
    useStrictMode?: boolean;

    /**
     * Enable using BrowserRouter from 'react-router-dom'.
     *
     * This option exists to support legacy portals and will be removed
     * when those portals no longer need to disable this functionality.
     *
     * Defaults to true.
     */
    useBrowserRouter?: boolean;

    /**
     * Root url for the application. Used for the browser router and redirect location
     * after root error boundary state reset.
     */
    rootUrl: string;

    /**
     * list of all routes in each portal, this is used for sub route preservation
     * to check if a route should be preserved
     */
    routes: IRoutes;

    enableDarkMode?: boolean;
}

export const PortalProviders: FC<IPortalProvidersProps> = ({
    children,
    rootUrl,
    useBrowserRouter = true,
    useStrictMode = true,
    routes,
    enableDarkMode,
}) => {
    const queryClient = createQueryClient();

    useEffect(() => {
        init();
    }, []);

    return (
        <WithStrictMode useStrictMode={useStrictMode}>
            <QueryClientProvider client={queryClient}>
                <WithBrowserRouter useBrowserRouter={useBrowserRouter} rootUrl={rootUrl}>
                    <TransceptaThemeProvider enableDarkMode={enableDarkMode}>
                        <ErrorBoundary route={rootUrl}>
                            <SubRoutePreservationProvider routes={routes}>
                                <ResetScrollState />
                                <SavePageVisitTimeToLogRocketUtil />
                                <AnalyticsHooksBootstrap />
                                <CrashTestFlag targetErrorBoundary="EntireApp" />
                                <Zendesk />
                                <ToastShower testId="ToastShower" />
                                <HelpButton />
                                {children}
                            </SubRoutePreservationProvider>
                        </ErrorBoundary>
                    </TransceptaThemeProvider>
                </WithBrowserRouter>
                {!(window as any).Cypress && <ReactQueryDevtools initialIsOpen={false} />}
            </QueryClientProvider>
        </WithStrictMode>
    );
};
