import { useCallback, useState } from 'react';
import { IErrors, IOnSubmit } from './types';

/**
 * Creates a function that will handle submitting the form.
 *
 * @param values the current form values
 * @param errors the current form errors
 * @param onSubmit the submission handler to wrap
 * @returns the new function to handle form submission.
 */
export default function useSubmit<T>(values: T, errors: IErrors<T>, onSubmit: IOnSubmit<T>) {
    const [hadErrorsOnSubmit, setHadErrorsOnSubmit] = useState(false);

    const handleSubmit = useCallback(() => {
        const hasErrors = Object.values(errors).some((x) => x !== null && x !== '');
        setHadErrorsOnSubmit(hasErrors);
        onSubmit(values, errors, hasErrors);
    }, [values, errors, onSubmit]);

    return { handleSubmit, hadErrorsOnSubmit };
}
