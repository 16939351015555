import { UserThunk } from 'common/build/legacy/transcepta-thunks';
import { push } from 'connected-react-router';
import { clearAlerts, pushAlert } from '../actions/alerts';
import { outerAPI } from '../utils/index';
import * as UserActions from '../actions/user';
import { clearCachedLogInInfo, userAuthErrorAction, userLogout } from '../actions/userlogin';
import projectConfig from '../project.config.json';
import { apiErrorAction } from '../actions/error';
import R from '../routes';
import { strings } from '../utils/localization/LocalizationStrings';
import * as UserConfig from '../utils/UserTableConfig';
import pathsConfig from '../paths.config.json';
import { filterStorageUtils, portalUserService } from 'common';

export const refreshUserToken = () => async (dispatch) => {
    const userThunk = new UserThunk();
    const response = await userThunk.refreshLoginToken();
    if (response.type && response.type === 'error') {
        dispatch(clearAlerts());
        dispatch(
            pushAlert({
                type: 'error',
                text: strings.textErrorResettingSession,
            })
        );
    } else {
        window.localStorage.access_token = response.data.access_token;
        window.localStorage.refresh_token = response.data.refresh_token;
        window.localStorage.token_expires = response.data['.expires'];
    }
};

export const fetchAllUsers =
    (companyId, queryType, skip, top, sort, filter = null) =>
    async (dispatch, getState) => {
        if (getState().user.allUsers.length > 0 && queryType === projectConfig.userQueryType.WorkflowUsers) {
            return;
        }
        let select = '';
        let expand = null;
        let count = null;
        switch (queryType) {
            case projectConfig.userQueryType.WorkflowUsers:
                select =
                    'ID,Name,EmployeeNumber,SubstituteUserActive,SubstituteUserId,Department,DepartmentCode,UserName,ManagersUserId';
                expand = null;
                count = null;
                break;
            case projectConfig.userQueryType.UserManagement:
                select = 'ID, Name, Email, UserName, LastLoginDate, UserRoles';
                expand = 'UserRoles';
                count = true;
                break;
            case projectConfig.userQueryType.companyUsers:
                select = null;
                expand = null;
                count = null;
                skip = null;
            default:
                select =
                    'ID,Name,EmployeeNumber,SubstituteUserActive,SubstituteUserId,Department,DepartmentCode,UserName,ManagersUserId';
                expand = null;
                count = null;
                break;
        }
        const params = {
            companyID: companyId,
            includeAll: true,
            $select: select,
            $expand: expand,
            $count: count,
            $top: projectConfig.manageUsers.pageSize,
            $orderby: sort || 'Name asc',
            $skip: skip,
            $filter: filter,
        };

        dispatch(UserActions.fetchAllUsersExecute(params));

        const userThunk = new UserThunk();
        const res = await userThunk.fetchAllUsers(params);
        if (res.type && res.type === 'error') {
            dispatch(apiErrorAction(res.text, UserActions.FETCH_ROLE_LIST));
            dispatch(
                pushAlert({
                    type: res.type,
                    text:
                        res.text.response && res.text.response.data.errors[0]
                            ? res.text.response.data.errors[0].Message
                            : res.text.message,
                })
            );
        } else {
            const data = await res.data;
            dispatch(UserActions.fetchAllUsers(data));
        }
    };

export const fetchUser = (userId) => async (dispatch, getState) => {
    const state = getState();
    const userThunk = new UserThunk();
    const params = {
        id: userId,
    };
    const res = await userThunk.fetchAllUsers(params);
    if (res.type && res.type === 'error') {
        dispatch(apiErrorAction(res.text, UserActions.FETCH_ROLE_LIST));
    } else {
        const data = await res.data;
        dispatch(UserActions.fetchUser(data.map((u) => ({ ...u, REmail: u.Email }))));
    }
};

export const fetchRolesList = () => async (dispatch, getState) => {
    const { roles } = getState().user.roles;

    const userThunk = new UserThunk();

    const res = await userThunk.fetchAllRoles();
    if (res.type && res.type === 'error') {
        dispatch(apiErrorAction(res.text, UserActions.FETCH_ROLE_LIST));
        dispatch(
            pushAlert({
                type: res.type,
                text:
                    res.text.response && res.text.response.data.errors[0]
                        ? res.text.response.data.errors[0].Message
                        : res.text.message,
            })
        );
    } else {
        const data = await res.data;
        dispatch(UserActions.fetchRoleList(data));
    }
};

export const goToPage = (skip, companyId, top, filter) => (dispatch, getState) => {
    const state = getState();
    dispatch(UserActions.upateUserSkip(skip));
    const sort = `${state.user.sorting.column} ${state.user.sorting.direction}`;
    dispatch(fetchAllUsers(companyId, projectConfig.userQueryType.UserManagement, skip, top, sort, filter));
};

export const saveUser = (newUser) => async (dispatch, getState) => {
    const state = getState();
    dispatch(clearAlerts());
    const user = state.user.selectedUser[0];

    if (!user.Name || !user.REmail || !user.Email) {
        dispatch(pushAlert({ type: 'error', text: strings.textRequiredFieldMissing }));
        return;
    }

    if (user.REmail && user.REmail !== user.Email) {
        dispatch(pushAlert({ type: 'error', text: strings.textMatchEmailError }));
        return;
    }
    const params = user;
    const userThunk = new UserThunk();
    const res = newUser ? await userThunk.saveNewUser(params) : await userThunk.saveUser(params);
    if (res.type && res.type === 'error') {
        dispatch(apiErrorAction(res.text, UserActions.SAVE_USER_FAILED));
    } else {
        dispatch(push(R.USER_SETTINGS_PAGE.path));
    }
};

export const deleteUser = (id) => async (dispatch, getState) => {
    const state = getState();
    const params = {
        Id: id,
    };
    const userThunk = new UserThunk();
    const res = await userThunk.deleteUser(params);
    if (res.type && res.type === 'error') {
        dispatch(apiErrorAction(res.text, UserActions.SAVE_USER_FAILED));
        dispatch(
            pushAlert({
                type: res.type,
                text:
                    res.text.response && res.text.response.data.errors[0]
                        ? res.text.response.data.errors[0].Message
                        : res.text.message,
            })
        );

        return {
            failed: true,
        };
    } else {
        dispatch(push(R.USER_SETTINGS_PAGE.path));
    }
};

export const changePassword = (oldPassword, newPassword) => async (dispatch, getState) => {
    const params = {
        NewPassword: newPassword,
        OldPassword: oldPassword,
    };
    clearAlerts();
    const userThunk = new UserThunk();
    const response = await userThunk.changePassword(params);
    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text.response.data.errors[0], UserActions.CHANGE_PASSWORD_FAILED));
    } else {
        dispatch(push(R.SETTINGS_ADMINISTRATION.path));
        dispatch(UserActions.togglePasswordChanged());
    }
};

export const applySorting = (column, filter) => async (dispatch, getState) => {
    const config = UserConfig.tableConfig();
    await dispatch(UserActions.applySorting(column, config, UserActions.USERS_SORT));
    const direction = getState().user.sorting.direction;
    const companyId = portalUserService.getCurrentCompanyId();
    const skip = getState().user.skip;
    column = column === 'LastSignIn' ? 'LastLoginDate' : column;
    dispatch(
        fetchAllUsers(
            companyId,
            projectConfig.userQueryType.UserManagement,
            skip,
            null,
            `${column} ${direction}`,
            filter
        )
    );
};

export const fetchSettingsUsers =
    (companyId, queryType, skip, top, sort, searchString = null) =>
    async (dispatch) => {
        dispatch(clearAlerts());
        dispatch(UserActions.fetchUserApplySearch(searchString));
        const filter = userSettingsFilter(searchString);
        dispatch(fetchAllUsers(companyId, queryType, skip, top, sort, filter));
    };

export const userSettingsApplySorting = (column) => async (dispatch, getState) => {
    const searchString = getState().user.searchString;
    const filter = userSettingsFilter(searchString);
    dispatch(applySorting(column, filter));
};

export const goToUserSettingsPage = (skip, companyId, top) => (dispatch, getState) => {
    const searchString = getState().user.searchString;
    const filter = userSettingsFilter(searchString);
    dispatch(goToPage(skip, companyId, top, filter));
};

export const logout =
    (errorMessage = null) =>
    async (dispatch, getState) => {
        portalUserService.clearCurrentUser();
        dispatch(push(R.LOGOUT_PAGE.path));
        const userThunk = new UserThunk();
        const response = await userThunk.logout();
        if (response.type && response.type === 'error') {
            // user will be on signing out page or logged out at this point, so this is probably useless
            // as it isn't displayed on those pages.
            dispatch(userAuthErrorAction(errorMessage));
        } else {
            const { userlogin } = getState();
            const { signOutScript, disableIframe } = pathsConfig.iframe;
            const timeout = userlogin.timeout && userlogin.timeout === true;

            if (!disableIframe) {
                outerAPI().get(signOutScript);
            }

            clearCachedLogInInfo();
            filterStorageUtils.deleteAllFiltersFromSessionStorage();

            dispatch(userLogout({ timeout }));
        }
    };

const userSettingsFilter = (searchString) =>
    searchString
        ? `contains(tolower(Email), tolower('${searchString}')) 
            or contains(tolower(Name), tolower('${searchString}'))
        `
        : null;
