import { api } from './api';
import apiPaths from './paths';

class ProfileThunk {
    constructor() {}

    fetchProfile = (params) => {
        return api()
            .get(apiPaths.profiles, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchProfiles = (params) => {
        return api()
            .get(
                apiPaths.profileSearch
                    .replace(':companyId', params.companyId)
                    .replace(':profileType', params.profileType)
            )
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchProfilesByType = (params) => {
        return api()
            .get(
                apiPaths.profileTypes
                    .replace(':profileId', params.profileId)
                    .replace(':profileType', params.profileType)
            )
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    updateProfile = (params) => {
        let path = apiPaths.profile;
        path = path.replace(':profileId', params.profileId);
        path = params.contact ? path.replace(':contact', params.contact) : path.replace('contact=:contact', '');
        path = params.email ? path.replace(':email', params.email) : path.replace('&email=:email', '');
        path = params.phone ? path.replace(':phone', params.phone) : path.replace('&phone=:phone', '');
        return api()
            .put(path, params.profileFormData)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    createProfile = (params) => {
        let path = apiPaths.newProfile;
        path = params.contact ? path.replace(':contact', params.contact) : path.replace('contact=:contact', '');
        path = params.email ? path.replace(':email', params.email) : path.replace('&email=:email', '');
        path = params.phone ? path.replace(':phone', params.phone) : path.replace('&phone=:phone', '');
        return api()
            .post(path, params.profileFormData)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };
}

export default ProfileThunk;
