import { BaseTextFieldProps } from '@mui/material';
import { BigNumber } from 'bignumber.js';
import { FCNC } from '../FCNC';
import { getLocalePercentPlacements } from './LocaleOptions';
import { NumericMaskedTextField } from './NumericMaskedTextField';
import { ITooltipIconProps } from '../Tooltip';

export interface IPercentFieldProps {
    id?: string;

    locale: string;

    value: number | null;

    onChange: (value: number | null) => void;

    maxValue: number;

    label?: string;

    required?: boolean;

    sx?: BaseTextFieldProps['sx'];

    readonly?: boolean;

    onDoubleClick?: BaseTextFieldProps['onDoubleClick'];

    testId?: string;

    tooltip?: ITooltipIconProps;
}

const multiplyHelper = (num: number | null, m: number) => {
    if (num === null) {
        return num;
    }
    return new BigNumber(num).multipliedBy(m).toNumber();
};

export const PercentField: FCNC<IPercentFieldProps> = ({
    id,
    locale,
    value,
    onChange,
    maxValue,
    required,
    sx,
    readonly,
    label,
    onDoubleClick,
    testId,
    tooltip,
}) => {
    const localeOptions = { symbol: '%', ...getLocalePercentPlacements(locale) };
    const handleChange = (newValue: number | null) => {
        onChange(multiplyHelper(newValue, 0.01));
    };
    const percentThreshold = new BigNumber(maxValue).multipliedBy(100).toNumber();
    return (
        <NumericMaskedTextField
            id={id}
            value={multiplyHelper(value, 100)}
            onChange={handleChange}
            localeOptions={localeOptions}
            maxValue={percentThreshold}
            required={required}
            sx={sx}
            readonly={readonly}
            label={label}
            onDoubleClick={onDoubleClick}
            scale={2}
            testId={testId}
            tooltip={tooltip}
        />
    );
};
