import { backendServices } from '../../../../services';
import { RelatedDocument } from './RelatedDocument';

export function transformRelatedDocuments(
    documents: backendServices.ViewModels.DocumentRelationshipXRefViewModel[]
): RelatedDocument[] {
    return documents.map((value) => ({
        documentId1: value.RelatedDocumentId1 ?? 0,
        documentId2: value.RelatedDocumentId2 ?? 0,
        lineNumber1: value.RelatedDocumentLineNumber1,
        lineNumber2: value.RelatedDocumentLineNumber2,
        businessDocType1: value.BusinessDocType1 ?? 0,
        businessDocType2: value.BusinessDocType2 ?? 0,
        active: value.Active ?? false,
    }));
}
