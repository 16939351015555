import { useCallback, useState, useEffect } from 'react';

export function useAutoLogin(autoLogin: (() => Promise<void>) | undefined, setIsLoading: (isLoading: boolean) => void) {
    const [attemptSignIn, setAttemptSignIn] = useState(false);

    const handleAutoLogin = useCallback(async () => {
        if (autoLogin) {
            setIsLoading(true);
            await autoLogin();
            setIsLoading(false);
        }
    }, [autoLogin, setIsLoading]);

    useEffect(() => {
        if (!attemptSignIn) {
            handleAutoLogin();
            setAttemptSignIn(true);
        }
    }, [attemptSignIn, handleAutoLogin]);
}
