export default {
    /* auth hive */
    login: '/auth/login',
    logout: '/auth/logout',
    passwordRecover: '/Auth/ResetPassword',
    /* users hive */
    readUser: '/User/',
    getAllRoles: '/User/Roles',
    /* companies hive */
    readCompany: '/Company/',
    searchCompany: '/Companies/Search',
    /* documents */
    searchDocuments: '/Document',
    readDocument: '/Document/:id',
    readDocumentDraft: '/Document/Draft',
    documentLayout: '/Document/Layout',
    validateDocument: '/Document/Validations',
    validateDraftDocument: '/Document/Validate',
    documentFiles: '/Document/attachments',
    addDocumentFiles: '/Document/Attachments?documentId=:documentId',
    documentHistory: '/Document/history',
    dynamicDiscounting: '/Document/dynamicdiscounting',
    currencyList: '/common/currency',
    parkingLotDocumentsCount: '/Document/ParkingLotDocumentCounts',
    documentDraft: '/Document/Draft',
    documentDelivery: '/Document/delivery',
    tradingPartnersList: 'Company/TradingPartners',
    customersLocationList: 'Company/TradingPartners/Customer',
    addCustomerLocation: 'Company/TradingPartners/Customer',
    createInvoice: '/Document',
    companyServiceAgreement: '/ServiceAgreement/CompanyServiceAgreement?companyId=:id',
    companyServiceAgreementContent:
        '/ServiceAgreement/ServiceAgreementContent?serviceAgreementId=:id&includeAllVersions=false',
    createServiceAgreementSignature: '/ServiceAgreement/CompanyServiceAgreementSignature',
    readProfile: '/Profile?id=:id&profileType=:profileType',
    searchInvoiceProfile: 'Profile/Search?companyid=:companyId&profiletype=1',
    searchBuyerProfiles: 'Profile/Search?companyid=:buyerCompanyId&profiletype=2',
    getNoticePage:
        '/helpresource?$filter=Enabled eq true and PortalResourceContents/any(c: c/Portal eq 1 and c/Page eq 17 and c/Context eq 0)',
    documentWorkflow: '/WorkflowInstance',
    updateWorkflow: '/WorkflowTask',
    menuSettings: '/Company/SupplierPortalSettings',
    downloadPDF: '/Document/GeneratePDF',
    generatePDF: '/Document/Draft/GeneratePDF',
};
