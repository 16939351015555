import getFullConfig from './getFullConfig';
import { getCookie, setPersistentCookie } from '../cookie';
import { doNotClearCookieOnReset } from '../resetState';

doNotClearCookieOnReset('logRocketRecordingEnabled');

/**
 * Enables recording on the current device for 1 year (as stored
 * in a persistent cookie).
 */
export function enableRecordingOnDevice() {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);

    setPersistentCookie('logRocketRecordingEnabled', 'true', expirationDate);
}

/**
 * Disables recording on the current device for 1 year (as stored
 * in a persistent cookie).
 */
export function disableRecordingOnDevice() {
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 1);

    setPersistentCookie('logRocketRecordingEnabled', 'false', expirationDate);
}

/**
 * Checks if recording is enabled on this device.
 */
export function isRecordingEnabledOnDevice() {
    const config = getFullConfig();
    if (!config) {
        return false;
    }
    if (!config.config.enableConditionalRecording) {
        return true;
    }

    const cookie = getCookie('logRocketRecordingEnabled');
    return cookie === 'true';
}

/**
 * Check if conditional recording is enabled.
 */
export function isConditionalRecordingEnabled() {
    const config = getFullConfig();
    if (!config) {
        return false;
    }
    return config.config.enableConditionalRecording;
}
