import { useSubscription } from '../../../../utils';
import { portalUserService } from '../PortalUserService';

/**
 * Returns the current user
 * @param mode pass 'mustBeLoggedIn' to throw an error if no user is logged in
 * @returns the current user (if one is logged in)
 */
export function useCurrentUser<T extends 'mustBeLoggedIn' | undefined>(mode?: T) {
    useSubscription((cb) => portalUserService.subscribe(cb), [portalUserService]);

    return portalUserService.getCurrentUser(mode);
}

export type UseCurrentUser = typeof useCurrentUser;
