import { AxiosResponse } from 'axios';
import { RecursivePartial } from '../../../types';
import { api } from '../../api';
import { DocumentTypeViewModel, SearchDocumentTypesParams } from './types';

export class DocumentTypeApi {
    private route = '/DocumentType';

    public searchDocumentType({
        companyId,
        isBuyer,
    }: SearchDocumentTypesParams): Promise<AxiosResponse<DocumentTypeViewModel[]>> {
        return api().get(`${this.route}/${companyId}`, {
            params: {
                isBuyer,
            },
        });
    }

    public createDocumentType(params: RecursivePartial<DocumentTypeViewModel>): Promise<AxiosResponse<{ ID: number }>> {
        return api().post(`${this.route}`, { ...params });
    }

    public updateDocumentType({
        companyId,
        params,
    }: {
        companyId: number;
        params: DocumentTypeViewModel;
    }): Promise<AxiosResponse<DocumentTypeViewModel[]>> {
        return api().put(`${this.route}/${companyId}`, { ...params });
    }

    public searchDocumentTypes(params: SearchDocumentTypesParams): Promise<AxiosResponse<DocumentTypeViewModel[]>> {
        return api().get(this.route, { params });
    }
}
