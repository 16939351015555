import { createSafeHTMLString } from '../../utils';

const errorMessages = {
    transient: createSafeHTMLString(
        '<p>We encountered an issue while displaying this page. Please try refreshing this page.</p><p>If the issue persists, please <a href="https://support.transcepta.com" target="_blank">click here to create a support case</a> and reference the error code: {{errorCode}}</p>'
    ),
    persistent: createSafeHTMLString(
        '<p>We encountered an issue while processing your request. Please <a href="https://support.transcepta.com" target="_blank">click here to create a support case</a> and reference the error code: {{errorCode}}</p>'
    ),
} as const;

export default errorMessages;
