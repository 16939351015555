// NON-LEGACY: This module is non-legacy and should be immediately moved to the new ui core library after #14226
import { FC } from 'react';
import { Typography, Theme, Stack } from '@mui/material';
import { Button } from '../Button';

import { makeStyles } from '@mui/styles';
import { Dialog } from '../Dialog';

export const useDialogTitleStyles = makeStyles((theme: Theme) => ({
    warning: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText,
    },
}));

interface IProps {
    title: string;
    prompt: React.ReactNode;
    open: boolean;
    color?: 'primary' | 'warn';
    confirm: {
        onClick: () => void;
        buttonTitle: string;
        autoFocus?: boolean;
        disabled?: boolean;
    };
    reject: {
        onClick: () => void;
        buttonTitle: string;
        autoFocus?: boolean;
        disabled?: boolean;
    };
    disableClickOutside?: boolean;
}

export const ConfirmationDialog: FC<IProps> = ({
    title,
    prompt,
    confirm,
    reject,
    open,
    disableClickOutside = false,
}) => {
    return (
        <Dialog
            title={title}
            open={open}
            handleOnClose={disableClickOutside ? () => {} : reject.onClick}
            content={
                <Stack spacing={2}>
                    {typeof prompt === 'string' ? (
                        <Typography variant="body2" gutterBottom>
                            {prompt}
                        </Typography>
                    ) : (
                        prompt
                    )}
                </Stack>
            }
            actions={
                <Stack direction="row" justifyContent="flex-end" gap={1} sx={{ width: 1 }}>
                    <Button variant="secondary" onClick={reject.onClick} disabled={reject.disabled}>
                        {reject.buttonTitle}
                    </Button>
                    <Button onClick={confirm.onClick} disabled={confirm.disabled}>
                        {confirm.buttonTitle}
                    </Button>
                </Stack>
            }
        />
    );
};
