import { getCurrentDebugLogMode, IDebuggingLogMode } from './logMode';

function getLogModeValue(level: IDebuggingLogMode) {
    return {
        off: 5,
        debug: 1,
        info: 2,
        warn: 3,
        error: 4,
    }[level];
}

/**
 * Log data to console at a specific level.
 *
 * Data is only logged if the current log mode is at or below that level
 *
 * @param level level to log at
 * @param data data to log
 */
export function log(level: Exclude<IDebuggingLogMode, 'off'>, ...data: any[]) {
    if (getLogModeValue(level) >= getLogModeValue(getCurrentDebugLogMode())) {
        // eslint-disable-next-line no-console
        const method = console[level].bind(console);

        method(...data);
    }
}

/**
 * Log data at the info level
 */
export function info(...data: any[]) {
    log('info', ...data);
}

/**
 * Log data at the debug level
 */
export function debug(...data: any[]) {
    log('debug', ...data);
}

/**
 * Log data at the warn level
 */
export function warn(...data: any[]) {
    log('warn', ...data);
}

/**
 * Log data at the error level
 */
export function error(...data: any[]) {
    log('error', ...data);
}
