import projectConfig from '../project.config.json';

export function DetermineMaxDigits(controlType) {
    switch (controlType) {
        case projectConfig.ControlType.Money:
        case projectConfig.ControlType.MoneyLabel:
        case projectConfig.ControlType.Tax:
            return 15; //(19,4)

        case projectConfig.ControlType.Quantity:
        case projectConfig.ControlType.UnitPrice:
            return 12; //(18,6)

        default:
            //tax percentage (currently has no corresponding control type value)
            return 3; //(5,2)
    }
}

export function DetermineMaxDecimalPlaces(controlType, controlFormat, currencyCode) {
    const absoluteMoneyMax = 4; //These four absolute values reflect the storage limits of the fields in the database and should not be changed
    const absoluteQuantityMax = 6;
    const absoluteUnitPriceMax = 6;
    const absolutePercentageMax = 6;

    const defaultMoneyMax = 2;
    const defaultQuantityMax = 6;
    const defaultUnitPriceMax = 6;
    const defaultPercentageMax = 2;

    var absoluteMax;
    var defaultMax;
    var formatMax;
    switch (controlType) {
        case projectConfig.ControlType.Money:
        case projectConfig.ControlType.MoneyLabel:
        case projectConfig.ControlType.Tax:
            if (currencyCode.toLowerCase() === 'usd') return 2;

            absoluteMax = absoluteMoneyMax;
            defaultMax = defaultMoneyMax;
            formatMax =
                controlFormat && controlFormat.amountmaxdecimal
                    ? controlFormat.amountmaxdecimal
                    : controlFormat && controlFormat.maxdecimal
                    ? controlFormat.maxdecimal
                    : null;
            break;

        case projectConfig.ControlType.Quantity:
            absoluteMax = absoluteQuantityMax;
            defaultMax = defaultQuantityMax;
            formatMax = controlFormat && controlFormat.maxdecimal ? controlFormat.maxdecimal : null;
            break;

        case projectConfig.ControlType.UnitPrice:
            absoluteMax = absoluteUnitPriceMax;
            defaultMax = defaultUnitPriceMax;
            formatMax =
                controlFormat && controlFormat.unitamountmaxdecimal
                    ? controlFormat.unitamountmaxdecimal
                    : controlFormat && controlFormat.maxdecimal
                    ? controlFormat.maxdecimal
                    : null;
            break;

        case projectConfig.ControlType.Percentage:
        default:
            //tax percentage (previously had no corresponding control type value)
            absoluteMax = absolutePercentageMax;
            defaultMax = defaultPercentageMax;
            formatMax = controlFormat && controlFormat.percentagemaxdecimal ? controlFormat.percentagemaxdecimal : null;
            break;
    }

    var max = defaultMax;
    if (formatMax !== null || !isNaN(parseInt(formatMax, 10))) max = parseInt(formatMax, 10);

    if (max < 0) max = 0;

    if (max > absoluteMax) max = absoluteMax;

    return max;
}

export function DetermineMinDecimalPalces(controlType, controlFormat, maxDecimal, currencyCode) {
    const defaultMoneyMin = 2;
    const defaultQuantityMin = 0;
    const defaultUnitPriceMin = 2;
    const defaultPercentageMin = 0;

    var defaultMin;
    var formatMin;
    switch (controlType) {
        case projectConfig.ControlType.Money:
        case projectConfig.ControlType.MoneyLabel:
        case projectConfig.ControlType.Tax:
            if (currencyCode.toLowerCase() === 'usd') return 2;

            defaultMin = defaultMoneyMin;
            formatMin =
                controlFormat && controlFormat.amountmindecimal
                    ? controlFormat.amountmindecimal
                    : controlFormat && controlFormat.mindecimal
                    ? controlFormat.mindecimal
                    : null;
            break;

        case projectConfig.ControlType.Quantity:
            defaultMin = defaultQuantityMin;
            formatMin = controlFormat && controlFormat.mindecimal ? controlFormat.mindecimal : null;
            break;

        case projectConfig.ControlType.UnitPrice:
            defaultMin = defaultUnitPriceMin;
            formatMin =
                controlFormat && controlFormat.unitamountmindecimal
                    ? controlFormat.unitamountmindecimal
                    : controlFormat && controlFormat.mindecimal
                    ? controlFormat.mindecimal
                    : null;
            break;

        default:
            //tax percentage (currently has no corresponding control type value)
            defaultMin = defaultPercentageMin;
            formatMin = controlFormat && controlFormat.percentagemindecimal ? controlFormat.percentagemindecimal : null;
            break;
    }

    var min = defaultMin;
    if (formatMin !== null || !isNaN(parseInt(formatMin, 10))) min = parseInt(formatMin, 10);

    if (min < 0) min = 0;

    if (min > maxDecimal) min = maxDecimal;

    return min;
}

export function CleanNumericValue(value, maxDigits, maxDecimal, minDecimal) {
    if (value.length > 0) {
        var numberOfDashesInCurrencySymbol = 0;
        var numberOfDecimalPointsInCurrencySymbol = 0;
        var numberOfNumericCharactersInCurrencySymbol = 0;

        var tempText = '';

        var nonZeroDigitAfterDecimal = false;
        var nonZeroDigitBeforeDecimal = false;
        var numberOfDashesFound = 0;
        var numberOfDecimalPointsFound = 0;
        var numberOfNumericCharactersFound = 0;
        var numberOfZerosAfterDecimalOnEnd = 0;
        for (var c = 0; c < value.length; c++) {
            switch (value.charAt(c)) {
                case '0':
                    numberOfNumericCharactersFound++;
                    if (numberOfNumericCharactersFound > numberOfNumericCharactersInCurrencySymbol) {
                        if (numberOfDecimalPointsFound > numberOfDecimalPointsInCurrencySymbol)
                            numberOfZerosAfterDecimalOnEnd++;
                        else if (nonZeroDigitBeforeDecimal) tempText = tempText + value.charAt(c);
                    }
                    break;
                case '1':
                case '2':
                case '3':
                case '4':
                case '5':
                case '6':
                case '7':
                case '8':
                case '9':
                    numberOfNumericCharactersFound++;
                    if (numberOfNumericCharactersFound > numberOfNumericCharactersInCurrencySymbol) {
                        if (numberOfDecimalPointsFound > numberOfDecimalPointsInCurrencySymbol) {
                            if (numberOfZerosAfterDecimalOnEnd > 0) {
                                for (var z = 0; z < numberOfZerosAfterDecimalOnEnd; z++) {
                                    tempText = tempText + '0';
                                }
                                numberOfZerosAfterDecimalOnEnd = 0;
                            }
                            tempText = tempText + value.charAt(c);
                            nonZeroDigitAfterDecimal = true;
                        } else {
                            tempText = tempText + value.charAt(c);
                            nonZeroDigitBeforeDecimal = true;
                        }
                    }
                    break;
                case '.':
                    if (numberOfDecimalPointsFound === numberOfDecimalPointsInCurrencySymbol)
                        tempText = tempText + value.charAt(c);
                    numberOfDecimalPointsFound++;
                    break;
                case '-':
                    if (numberOfDashesFound === numberOfDashesInCurrencySymbol) tempText = tempText + value.charAt(c);
                    numberOfDashesFound++;
                    break;
                default:
                    break;
            }
        }

        var newText = '';

        for (c = 0; c < tempText.length; c++) {
            switch (tempText.charAt(c)) {
                case '0':
                case '1':
                case '2':
                case '3':
                case '4':
                case '5':
                case '6':
                case '7':
                case '8':
                case '9':
                    newText = newText + tempText.charAt(c);
                    break;
                case '.':
                    if (nonZeroDigitAfterDecimal) newText = newText + tempText.charAt(c);
                    break;
                case '-':
                    newText = newText + tempText.charAt(c);
                    break;
                default:
                    break;
            }
        }

        if (newText.length === 0) newText = '0';

        return RoundToMaxDecimal(newText, maxDigits, maxDecimal, minDecimal);
    }

    return value;
}

export function GenerateDisplayValue(value, maxDigits, maxDecimal, minDecimal, controlType, currencySymbol) {
    var isPercentage = false;
    var isMoney = false;
    switch (controlType) {
        case projectConfig.ControlType.Money:
        case projectConfig.ControlType.MoneyLabel:
        case projectConfig.ControlType.Tax:
            isMoney = true;
            break;

        case projectConfig.ControlType.Quantity:
        case projectConfig.ControlType.UnitPrice:
            break;

        default:
            //tax percentage (currently has no corresponding control type value)
            isPercentage = true;
            break;
    }

    if (value && value !== null) value = value.toString();
    if (value.length === 0) return value;

    var tempText = '';

    var decimalPointExists = false;
    for (var c = 0; c < value.length; c++) {
        switch (value.charAt(c)) {
            case '0':
            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
                tempText = tempText + value.charAt(c);
                break;
            case '.':
                if (!decimalPointExists) {
                    tempText = tempText + value.charAt(c);
                    decimalPointExists = true;
                }
                break;
            case '-':
                if (c === 0) tempText = tempText + value.charAt(c);
                break;
            case ',':
            default:
                break;
        }
    }

    tempText = RoundToMaxDecimal(tempText, maxDigits, maxDecimal, minDecimal);

    decimalPointExists = false;
    var numberOfDigitsAfterDecimal = 0;
    var numberOfDigitsBeforeDecimal = 0;
    for (c = 0; c < tempText.length; c++) {
        switch (tempText.charAt(c)) {
            case '0':
            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
                if (decimalPointExists) numberOfDigitsAfterDecimal++;
                else numberOfDigitsBeforeDecimal++;
                break;
            case '.':
                decimalPointExists = true;
                break;
            default:
                break;
        }
    }

    var newText = '';
    var digitBeforeDecimal = 0;
    var addCommaAfter = numberOfDigitsBeforeDecimal % 3;

    for (c = 0; c < tempText.length; c++) {
        switch (tempText.charAt(c)) {
            case '0':
            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
                if (isMoney && newText.length === 0) newText = newText + currencySymbol;
                newText = newText + tempText.charAt(c);
                digitBeforeDecimal++;
                if (digitBeforeDecimal % 3 === addCommaAfter && digitBeforeDecimal < numberOfDigitsBeforeDecimal)
                    newText = newText + ',';
                break;
            case '.':
                if (newText.length === 0 && numberOfDigitsBeforeDecimal === 0) {
                    if (isMoney) newText = newText + currencySymbol;
                    newText = newText + '0';
                }
                if (numberOfDigitsAfterDecimal > 0 || minDecimal > 0) newText = newText + tempText.charAt(c);
                break;
            case '-':
                if (numberOfDigitsBeforeDecimal > 0 || numberOfDigitsAfterDecimal > 0) newText = tempText.charAt(c);
                if (isMoney) newText = newText + currencySymbol;
                if (numberOfDigitsBeforeDecimal === 0) newText = newText + '0';
                break;
            default:
                break;
        }
    }

    if (newText.length === 0) {
        if (isMoney) newText = currencySymbol + '0';
        else newText = '0';
    }

    if (minDecimal > 0) {
        if (!decimalPointExists) newText = newText + '.';
        while (numberOfDigitsAfterDecimal < minDecimal) {
            newText = newText + '0';
            numberOfDigitsAfterDecimal++;
        }
    }

    if (!isMoney && isPercentage) newText = newText + '%';

    return newText;
}

export function RoundToMaxDecimal(valueText, maxDigits, maxDecimal, minDecimal) {
    var isNegative = false;
    var decimalFound = false;
    var numberOfDecimals = 0;
    var numberOfDigits = 0;
    for (var c = 0; c < valueText.length; c++) {
        switch (valueText.charAt(c)) {
            case '-':
                isNegative = true;
                break;
            case '.':
                decimalFound = true;
                break;
            case '0':
            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
                if (decimalFound) numberOfDecimals++;
                else numberOfDigits++;
                break;
            default:
                break;
        }
    }

    if (numberOfDecimals > maxDecimal) {
        var decimalAfterNewLastDecimal = +valueText.charAt(valueText.length - numberOfDecimals + maxDecimal);
        if (maxDecimal > 0) valueText = valueText.substring(0, valueText.length - numberOfDecimals + maxDecimal);
        else valueText = valueText.substring(0, valueText.length - numberOfDecimals + maxDecimal - 1);
        var newLastDecimal = +valueText.charAt(valueText.length - 1);

        var increaseNextDigit = false;
        if (isNegative) {
            if (decimalAfterNewLastDecimal > 5) newLastDecimal++;
            if (newLastDecimal === 10) {
                increaseNextDigit = true;
                newLastDecimal = 0;
            }

            valueText = valueText.substring(0, valueText.length - 1) + newLastDecimal.toString();

            for (var d = 2; increaseNextDigit && valueText.length - d > 0; d++) {
                var nextChar = valueText.charAt(valueText.length - d);
                if (nextChar !== '.') {
                    var nextDigit = +nextChar;
                    nextDigit++;
                    if (nextDigit < 10) increaseNextDigit = false;
                    else nextDigit = 0;

                    valueText =
                        valueText.substring(0, valueText.length - d) +
                        nextDigit.toString() +
                        valueText.substring(valueText.length - d + 1);
                }
            }

            if (increaseNextDigit) {
                if (numberOfDigits >= maxDigits) {
                    valueText = '-';
                    for (d = 0; d < maxDigits; d++) valueText = valueText + '9';
                    if (maxDecimal > 0) {
                        valueText = valueText + '.';
                        for (d = 0; d < maxDecimal; d++) valueText = valueText + '9';
                    }
                } else valueText = '-1' + valueText.substring(1);
            }
        } else {
            if (decimalAfterNewLastDecimal > 4) newLastDecimal++;
            if (newLastDecimal === 10) {
                increaseNextDigit = true;
                newLastDecimal = 0;
            }

            valueText = valueText.substring(0, valueText.length - 1) + newLastDecimal.toString();

            for (d = 2; increaseNextDigit && valueText.length - d > -1; d++) {
                nextChar = valueText.charAt(valueText.length - d);
                if (nextChar !== '.') {
                    nextDigit = +nextChar;
                    nextDigit++;
                    if (nextDigit < 10) increaseNextDigit = false;
                    else nextDigit = 0;

                    if (valueText.length - d === 0) valueText = nextDigit.toString() + valueText.substring(1);
                    else
                        valueText =
                            valueText.substring(0, valueText.length - d) +
                            nextDigit.toString() +
                            valueText.substring(valueText.length - d + 1);
                }
            }

            if (increaseNextDigit) {
                if (numberOfDigits >= maxDigits) {
                    valueText = '';
                    for (d = 0; d < maxDigits; d++) {
                        valueText = valueText + '9';
                    }
                    if (maxDecimal > 0) {
                        valueText = valueText + '.';
                        for (d = 0; d < maxDecimal; d++) valueText = valueText + '9';
                    }
                } else valueText = '1' + valueText;
            }
        }

        numberOfDecimals = maxDecimal;
    }

    while (numberOfDecimals > minDecimal) {
        if (valueText.charAt(valueText.length - 1) === '0') {
            valueText = valueText.substring(0, valueText.length - 1);
            numberOfDecimals--;
        } else break;
    }

    return valueText;
}
