enum DocumentScrapingStatus {
    Success = 0,
    Error = 1,
    Undeliverable = 2,
    DoNotSend = 3,
    InternalError = 4,
    DuplicateInvoice = 5,
}

export default DocumentScrapingStatus;
