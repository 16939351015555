import { IWorkflowActivity } from '../../proservContractTypes';
import { ValidationFunctionResultsCollector } from '../useInternalRules/RuleEnvironment';

export function handleResultsForStep3(
    workflowApprovalType: number,
    activity: IWorkflowActivity,
    results: ValidationFunctionResultsCollector
) {
    if (activity.Step === 3 && workflowApprovalType !== 1) {
        return {
            addError: results.addWarning.bind(results),
            addWarning: results.addWarning.bind(results),
        } as ValidationFunctionResultsCollector;
    }

    return results;
}
