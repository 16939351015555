import { FCNC } from '../../../ui';
import { DynamicRelatedDocumentLink } from '../DynamicRelatedDocumentLink';
import { parseControlFormatFields, RawControlFormatFields } from '../parseControlFormatFields';
import { StaticRelatedDocumentLink } from '../StaticRelatedDocumentLink';

export interface RelatedDocumentLinkProps {
    formatting: RawControlFormatFields;

    value: string;

    editMode: boolean;
}

export const RelatedDocumentLink: FCNC<RelatedDocumentLinkProps> = ({ formatting, value, editMode }) => {
    const formattingFields = parseControlFormatFields(formatting);

    if (formattingFields.isRelatedDocumentField && editMode) {
        return <DynamicRelatedDocumentLink formatting={formattingFields} value={value} />;
    }

    if (formattingFields.isRelatedDocumentField) {
        return <StaticRelatedDocumentLink formatting={formattingFields} value={value} isInEditMode={editMode} />;
    }

    return <>{value}</>;
};
