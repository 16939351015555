import ErrorList from './ErrorList';
import SingleError from './SingleError';

export interface FieldErrorsProps {
    errors: string[] | undefined;
    testIdPrefix: string;
    color?: string;
}

export default function FieldErrors({ errors, testIdPrefix, color }: FieldErrorsProps) {
    if (!errors) {
        return null;
    }

    if (errors.length === 1) {
        return <SingleError color={color} error={errors[0]} testId={`${testIdPrefix}--ErrorItem`} />;
    }

    return <ErrorList color={color} errors={errors} testIdPrefix={testIdPrefix} />;
}
