import JSONParser from './JSONParser';
import InstanceConverter from './InstanceConverter';
import { IInstanceTypeConverter } from './types';

/**
 * Parses / serializes JSON / objects.
 *
 * Allows converting complex objects to plain objects and vice versa
 * to enable serialization of non-plain objects.
 */
export default class JSONParserFacade {
    private parser: JSONParser;

    constructor(converters: IInstanceTypeConverter<unknown>[]) {
        const converter = new InstanceConverter(converters);
        this.parser = new JSONParser(converter);
    }

    /**
     * Parse a JSON encoded string, that potentially contains serialized
     * complex objects.
     * @param value to parse
     * @returns the parsed value
     */
    parse(value: string) {
        return this.parser.parse(value);
    }

    /**
     * Serialize a value, potentially containing a complex object, to a JSON
     * string.
     * @param value to serialize
     * @returns the serialized string
     */
    serialize(value: any) {
        return this.parser.serialize(value);
    }
}
