import { useQuery } from '@tanstack/react-query';
import { backendServices } from '../../services';
import { RuntimeRuleFetcher } from '../CustomJSRuleEngineV2/CoreEngine/Utility/RuntimeRuleFetcher';
import {
    DocumentValidationConfiguration,
    extractValidationConfiguration,
} from './ClientValidationController/DocumentValidationConfiguration';

/**
 * Retrieves the runtime rules and validation configuration based on the provided parameters.
 *
 * @param options - The options for retrieving the runtime rules.
 * @param options.context - The execution context.
 * @param options.validationsEnabled - A boolean indicating whether validations are enabled.
 * @param options.buyerCompanyId - The buyer company ID.
 * @param options.workflowId - The workflow ID.
 * @param options.workflowActivityId - The workflow activity ID.
 * @param options.vendorClass - The vendor class.
 * @param options.childCompanyId - The child company ID if applicable.
 * @returns An object containing the runtime rules, validation configuration, and loading state.
 */
export function useRuntimeRules({
    context,
    validationsEnabled,
    buyerCompanyId,
    workflowId,
    workflowActivityId,
    vendorClass,
    childCompanyId,
}: {
    context: backendServices.ViewModels.ExecutionContext;
    validationsEnabled: boolean;
    buyerCompanyId?: number | null;
    workflowId?: number;
    workflowActivityId?: number;
    vendorClass?: string;
    childCompanyId?: number | null;
}) {
    const shouldFetch = buyerCompanyId != null && validationsEnabled;

    const { data, isLoading } = useQuery(
        [
            'ValidationRuntimeRules',
            context,
            buyerCompanyId,
            workflowId,
            workflowActivityId,
            vendorClass,
            childCompanyId,
        ],
        async () => {
            let validationConfiguration: DocumentValidationConfiguration | null = null;

            const ruleFetcher = new RuntimeRuleFetcher();
            const runtimeRules = await ruleFetcher.fetchRules(
                {
                    engineVersion: 2,
                    buyerCompanyId: buyerCompanyId!,
                    executionContext: context,
                    workflowId,
                    workflowActivityId,
                    vendorClass,
                    childCompanyId: childCompanyId ?? undefined,
                },
                (mapping) => {
                    const configuration = extractValidationConfiguration(mapping, context, vendorClass);

                    validationConfiguration = configuration;
                }
            );

            return {
                runtimeRules,
                validationConfiguration: validationConfiguration as DocumentValidationConfiguration | null,
            };
        },
        {
            enabled: shouldFetch,
            staleTime: Infinity,
        }
    );

    return {
        runtimeRules: data?.runtimeRules,
        isLoading,
        validationConfiguration: data?.validationConfiguration ?? null,
    };
}
