import LogRocket from 'logrocket';
import getFullConfig from './getFullConfig';

let lastPageLoadTime = 0;

/**
 * Record the time a page in the app loads at
 */
export function handlePageChange() {
    lastPageLoadTime = Date.now();
}

/**
 * Gets the LogRocket session URL, with a start timestamp of the last page load time.
 *
 * This replaces the timestamp that LogRocket generates, which was found to be inadequate.
 *
 * Starting the session playback at the last page load will help us quickly figure out what
 * the user did on the page that leads to a crash. If we need more context, we can always replay
 * the entire session.
 */
export function getSessionURL() {
    if (!getFullConfig()) {
        return Promise.resolve('');
    }

    return new Promise<string>((res) => {
        LogRocket.getSessionURL((sessionURL) => {
            res(sessionURL.replace(/\?t=(\d|\.)+/, `?t=${lastPageLoadTime}`));
        });
    });
}
