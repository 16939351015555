import { AxiosResponse } from 'axios';
import { api } from '../../api';
import { IRoleViewModel } from './ViewModels';

export class RoleApi {
    route = '/User/Roles';

    public getRoles = (): Promise<AxiosResponse<IRoleViewModel[]>> => {
        return api().get(`${this.route}/`);
    };
}
