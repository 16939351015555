import { AxiosResponse } from 'axios';
import { api } from '../../api';
import { CurrencyModel } from './ViewModels';

export class CurrencyApi {
    route = '/common/currency';

    public getCurrency = (): Promise<AxiosResponse<CurrencyModel[]>> => {
        return api().get(this.route);
    };
}
