import { isPlainObject } from 'lodash';

export function removeDuplicates<T>(obj: T[], key: (value: T) => any) {
    const seen = new Set();
    const result: T[] = [];

    obj.forEach((v) => {
        const valueKey = key(v);
        if (!seen.has(valueKey)) {
            result.push(v);
            seen.add(valueKey);
        }
    });

    return result;
}

export function removeDuplicatesPreserveOrder<T>(obj: T[], key: (value: T) => any) {
    return obj.filter((v, i, a) => a.findIndex((v2) => key(v2) === key(v)) === i);
}

export const isPlainObjectArrayOrNestedArray = (candidateArr: any) =>
    Array.isArray(candidateArr) && candidateArr.some((value) => isPlainObject(value) || Array.isArray(value));

/**
 * This function is meant to be used in conjunction with lodash/mergeWith
 *
 * Replaces arrays not containing at least one object or array, instead of doing a recursive merge.
 *
 */
export function replacePrimitiveArrayCustomizer(objValue: any, srcValue: any) {
    if (
        (!isPlainObject(objValue) || !isPlainObject(srcValue)) &&
        !isPlainObjectArrayOrNestedArray(objValue) &&
        !isPlainObjectArrayOrNestedArray(Object.values(srcValue ?? {})) // srcValue can be an 'array-like' object without a 'length' property
    ) {
        return srcValue;
    }
    return undefined;
}
