import { ZendeskBootstrap } from './ZendeskBootstrap';
import { getAppConfig } from '../../config';
import { FCNC } from '../../ui';

export const Zendesk: FCNC = () => {
    const { zenDeskKey } = getAppConfig();
    const setting = {
        color: {
            theme: '#1f73b7',
        },
    };

    // eslint-disable-next-line react/jsx-props-no-spreading
    return zenDeskKey ? <ZendeskBootstrap defer zendeskKey={zenDeskKey} {...setting} /> : null;
};
