import { useEffect, useMemo } from 'react';
import { set, lensPath } from 'ramda';
import { useFlashMessage, createFlashMessagesHistoryState } from '../../FlashMessages';
import { DotNotationFieldRendererViewModel } from '../../FieldRenderer';
import { useCRUDState, useMutationSuccess, useStableReference } from '../../../utils';
import { FieldChangeHandler, ICRUDService, ICRUDSession } from '../types';
import { useEntity, useSaveEntity, useDeleteEntity, useFileStoreState } from '../hooks';
import { stagedCRUDRepository } from './stagedCRUDRepository';

/**
 * Manages the state of the CRUD page.
 * @param service the ICRUDService to use for CRUD tasks
 * @param entityId the id of the entity being edited, or null if creating a new entity
 * @param editable whether the user can edit the entity
 * @param successFlashMessageKey used to display success message when create/edit page use different components
 * @param onCancel handles cancellation button click
 * @param onFormStateChange observes form state changes so parent can load dependencies based on form state
 * @param onUpdateSuccess handles successful entity update
 * @param onCreateSuccess handles successful entity creation
 * @param onDeleteSuccess handles successful entity deletion
 * @param filePaths the file paths to persist out of localstorage
 * @returns the current state and functions to update the current state.
 */
export function useCRUDTemplateState<ID extends any, U extends object, T extends ICRUDService<ID, U>>(
    service: T,
    entityId: ID | null,
    session: ICRUDSession<ID, U> | undefined,
    editable: boolean,
    successFlashMessageKey: string | undefined,
    onCancel: (() => void) | undefined,
    onFormStateChange: ((data: U) => void) | undefined,
    onUpdateSuccess: (() => void) | undefined,
    onCreateSuccess:
        | ((id: ID, flashMessageHistoryState: ReturnType<typeof createFlashMessagesHistoryState>) => void)
        | undefined,
    onDeleteSuccess: (() => void) | undefined,
    filePaths: string[] | ((fields: U) => string[])
) {
    const sessionEntityState = useStableReference(
        (session && stagedCRUDRepository.getEntity<U>(session.sessionID)) ?? null
    );

    const { getFiles, addFile, clearFiles } = useFileStoreState();
    stagedCRUDRepository.fileStoreGetFiles = getFiles;
    stagedCRUDRepository.fileStoreAddFile = addFile;
    stagedCRUDRepository.fileStoreClearFiles = clearFiles;
    stagedCRUDRepository.filePaths = filePaths;

    // this fetches the current entity state (when editing)
    const {
        isLoading: isLoadingEntity,
        data: entityState,
        error: entityDataLoadError,
    } = useEntity<ID, U, T>(service, entityId, editable, sessionEntityState);

    const initialEntityState = sessionEntityState ?? entityState;

    // this manages the form field state
    const {
        fields,
        handleFieldChange: handleBasicFieldChange,
        setFields,
        resetFields,
    } = useCRUDState(service.defaultState, isLoadingEntity, initialEntityState);

    // this propagates changes to the form state to the parent's observer.
    useEffect(() => {
        if (onFormStateChange) {
            onFormStateChange(fields);
        }
    }, [fields, onFormStateChange]);

    // this wraps the form state in a view model that is used when rendering data-driven forms
    const [vm, setVM] = useMemo(
        () => [
            new DotNotationFieldRendererViewModel(fields, {}),
            (updater: (vm: DotNotationFieldRendererViewModel<U>) => DotNotationFieldRendererViewModel<U>) => {
                setFields((newFields) => updater(new DotNotationFieldRendererViewModel(newFields, {})).state);
            },
        ],
        [fields, setFields]
    );

    // create mutation for saving and call correct callback after successful save
    const saveMutation = useSaveEntity<ID, U, T>(service, session);
    const handleCreateSuccess = (id: ID) => {
        if (onCreateSuccess) {
            onCreateSuccess(
                id,
                createFlashMessagesHistoryState([
                    {
                        messageKey: successFlashMessageKey ?? '',
                        text: 'true',
                    },
                ])
            );
        }
    };
    useMutationSuccess(saveMutation, entityId ? onUpdateSuccess : handleCreateSuccess);

    // create mutation for deletion and call correct callback after successful deletion
    const deleteMutation = useDeleteEntity<ID, U, T>(service, session, onDeleteSuccess);

    // manage the success flash message state for the page.
    const [successFlashMessage, clearSuccessFlashMessage] = useFlashMessage(successFlashMessageKey ?? '');

    // handler for save button click / form submission
    const handleSave = () => {
        clearSuccessFlashMessage();
        saveMutation.mutate({
            id: entityId,
            entityFields: fields,
        });
    };

    // handler for cancel button click
    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        }

        clearSuccessFlashMessage();
        resetFields();
    };

    // handler for delete button click
    const handleDelete = () => {
        if (!entityId) {
            return;
        }

        deleteMutation.mutate({
            id: entityId,
            fields,
        });
    };

    const stageSession = (update?: (fields: U) => U) => {
        if (session) {
            stagedCRUDRepository.setEntity(session.sessionID, update ? update(fields) : fields);
        }
    };

    const handleFieldChange: FieldChangeHandler<U> = (key: any, value: any) => {
        if (Array.isArray(key)) {
            setFields((v) => set(lensPath(key), value, v));
        } else {
            handleBasicFieldChange(key, value);
        }
    };

    useEffect(() => {
        if (initialEntityState !== null && initialEntityState === fields && session?.handleInit && !entityId) {
            setFields(session.handleInit);
        }
        // we purposely do not want to execute when `session` changes, but we do need to execute `session.handleInit` inside.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialEntityState, fields, entityId]);

    return {
        handleSave,
        handleCancel,
        handleDelete,
        saveMutation,
        deleteMutation,
        successFlashMessage,
        fields,
        handleFieldChange,
        entityDataLoadError,
        vm,
        setVM,
        isLoadingEntity,
        stageSession,
    };
}
