import traverseObject from './traverseObject';
import InstanceConverter from './InstanceConverter';

export default class JSONParser {
    constructor(private instanceConverter: InstanceConverter) {}

    private convertToJSONSerializable(object: any) {
        return traverseObject(object, (value) => {
            return this.instanceConverter.convertToPlainObject(value);
        });
    }

    private convertToComplexObjects(object: any) {
        return traverseObject(object, (value) => {
            return this.instanceConverter.convertToInstance(value);
        });
    }

    parse(value: string) {
        return this.convertToComplexObjects(JSON.parse(value));
    }

    serialize(value: any) {
        return JSON.stringify(this.convertToJSONSerializable(value));
    }
}
