import ReactDOM from 'react-dom';
import FeatureApp from './FeatureApp';

class FeatureManager {
    private isLaunched = false;

    /**
     * Opens the feature manager if it isn't currently opened.
     */
    launchFeatureManager() {
        if (this.isLaunched) {
            return;
        }

        this.isLaunched = true;

        const el = document.createElement('div');
        document.body.appendChild(el);

        const handleClose = () => {
            ReactDOM.unmountComponentAtNode(el);
            document.body.removeChild(el);
            window.location.reload();
        };

        ReactDOM.render(<FeatureApp onClose={handleClose} />, el);
    }
}

declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Window {
        transceptaFeatureManager: FeatureManager;
    }
}

export const featureManager = new FeatureManager();
window.transceptaFeatureManager = featureManager;
