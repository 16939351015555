import { escape } from '../../../utils';
import { formatAmount } from './currency';
import { getInvalidCharacters } from './validation';
import { insertIf } from './array';
import { getUtcDate, defaultMinDate, defaultMaxDate, getUtcDateByHoursDifference } from './date';
import { ICurrency, IConfig, IFormat, ISearchConfig, ISearchResult, IColumnDisplay } from '../../transcepta-types';
import { formatDistanceToNow } from 'date-fns';
import { SearchCriteriaType } from '../../../services/backendServices/ViewModels';

export const getSearchConfig = (searchConfigs: ISearchConfig[]) => {
    const config: IConfig<ISearchConfig> = {
        left: [],
        right: [],
    };

    searchConfigs?.forEach((searchConfig: ISearchConfig) => {
        const options =
            searchConfig.Options &&
            searchConfig.Options.sort((option1: any, option2: any) => {
                if (option1.Label > option2.Label) {
                    return 1;
                }
                if (option1.Label < option2.Label) {
                    return -1;
                }
                return 0;
            }).map((o: any) => ({
                value: o.Value,
                label: o.Label,
            }));

        const searchInput = {
            name: searchConfig.Label,
            key: searchConfig.FieldName,
            type: searchConfig.Type,
            ...(searchConfig.AutoFocus && { autoFocus: true }),
            ...(searchConfig.Multi && { multi: true }),
            ...(searchConfig.Options && { options }),
            validationRules: [
                ...insertIf(searchConfig.Type === 'DateRange', {
                    test: (range: string[]) => {
                        const startDate = new Date(Date.parse(range && range[0] ? range[0] : defaultMinDate));
                        const endDate = new Date(Date.parse(range && range[1] ? range[1] : defaultMaxDate));
                        const { utcStartDate, utcEndDate } = getUtcDate(startDate, endDate);
                        return utcStartDate > utcEndDate;
                    },
                    message: 'The Start date must be on or before the End date.',
                }),
                ...insertIf(searchConfig.Type === 'Input' || searchConfig.Type === 'NumericRange', {
                    test: (value: string) => getInvalidCharacters(value, ['<', '>']),
                    message: 'Invalid characters used',
                }),
                ...insertIf(
                    searchConfig.Type === 'NumericRange',
                    {
                        test: (range: string) => {
                            const values = range && range.split('/');
                            if (searchConfig.FieldName === 'TimeParked') {
                                return (
                                    (values && values[0] && !/^[0-9]+$/.test(values[0].trim())) ||
                                    (values && values[1] && !/^[0-9]+$/.test(values[1].trim()))
                                );
                            }
                            return (
                                (values && values[0] && !/^[0-9.-]+$/.test(values[0].trim())) ||
                                (values && values[1] && !/^[0-9.-]+$/.test(values[1].trim()))
                            );
                        },
                        message:
                            searchConfig.FieldName === 'TimeParked'
                                ? 'Please enter a valid range that contains no letters'
                                : 'Please enter a valid range that contains no letters ("-" sign is allowed)',
                    },
                    {
                        test: (range) => {
                            const values = range && range.split('/');
                            return values && parseFloat(values[0]) > parseFloat(values[1]);
                        },
                        message: 'The Min value must be less than or equal to the Max value.',
                    }
                ),
                ...insertIf(searchConfig.FieldName === 'TimeParked', {
                    test: (range: string) => {
                        const values = range && range.split('/');
                        let minHour = values && values[0] && values[0].length > 0 ? values[0] : 0;
                        let maxHour = values && values[1] && values[1].length > 0 ? values[1] : 0;

                        minHour = Number(minHour);
                        maxHour = Number(maxHour);
                        const utcStartDate = getUtcDateByHoursDifference(minHour);
                        const utcEndDate = getUtcDateByHoursDifference(maxHour);
                        return values && values[0].length > 0 && values[1].length > 0
                            ? utcStartDate < utcEndDate
                            : false;
                    },
                    message: 'The Min time parked should be less than the Max time parked.',
                }),
                ...insertIf(searchConfig.FieldName === 'DocumentID', {
                    test: (value: string) => value && !/^\d+$/.test(value.trim()),
                    message: 'Please enter a valid Id that contains no letters',
                }),
            ],
        };

        if (searchConfig.Column === 1) {
            config.left.push(searchInput);
        } else {
            config.right.push(searchInput);
        }
    });

    return config;
};

const statusRenderer = (row: any, col: any, displayDictionary: any, cssDictionary: any) => {
    let className = 'td';
    let displayStatus = '';

    // Find the right status display from the search result display configuration
    for (const key in displayDictionary) {
        if (key === (row.Status && row.Status.toString())) {
            displayStatus = displayDictionary[key];
            break;
        }
    }

    // Find css class
    for (const key in cssDictionary) {
        if (key === (row.Status && row.Status.toString())) {
            className += ` ${cssDictionary[key]} `;
        }
    }

    return (
        <div className={className} key={`${col.idx}`}>
            {displayStatus}
        </div>
    );
};

const amountRenderer = (row: any, currencyList: ICurrency[], fieldName: string, format: IFormat) => {
    const sign =
        currencyList && row.Currency
            ? // @ts-ignore
              currencyList.find((currency) => currency.CurrencyType === row.Currency).Symbol
            : '';

    return (
        <div key="Amount" className="td alignRight">
            {`${sign}  ${formatAmount(row[fieldName], format)}`}
        </div>
    );
};

const validationMessagesRenderer = (row: any, fieldName: string) => {
    return (
        <div className="td" key="`{fieldName}`">
            {row[fieldName] && row[fieldName].length > 1 ? (
                <span className="tooltip">
                    <ul style={{ paddingLeft: 15, paddingRight: 15 }}>
                        {row[fieldName].map((item: any, index: number) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <li key={index}>{item.TitleText}</li>
                        ))}
                    </ul>
                </span>
            ) : null}
            {Array.isArray(row[fieldName]) && row[fieldName].length ? row[fieldName][0].TitleText : null}
            {row[fieldName] && row[fieldName].length > 1 ? <strong> ...</strong> : null}
        </div>
    );
};

const arrayRenderer = (row: any, fieldName: string) => {
    return (
        <div className="td" key="`{fieldName}`">
            {row[fieldName] ? (
                <span className="tooltip">
                    <ul style={{ paddingLeft: 15, paddingRight: 15 }}>
                        {row[fieldName].map((item: any, index: number) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </span>
            ) : null}
            {row[fieldName] ? row[fieldName][0] : null}
            {row[fieldName] && row[fieldName].length > 1 ? <strong> ...</strong> : null}
        </div>
    );
};

const createdTimeRenderer = (row: any, fieldName: string, format: IFormat) => {
    return format.Function && format.Function === 'MomentFromNow' ? (
        <div key="CreatedTime" className="td alignRight">
            {formatDistanceToNow(new Date(row[fieldName]), { addSuffix: false })}
        </div>
    ) : (
        ''
    );
};

export const getSearchResultConfig = (searchResult: ISearchResult[], currencyList: ICurrency[]) => {
    const config: IColumnDisplay[] = [];

    searchResult?.forEach((c) => {
        const columnDisplay: IColumnDisplay = {
            name: c.Label,
            key: c.FieldName,
            type: c.Type,
            ...(c.Sort === false && { noSort: true }),
            ...(c.Display && {
                desktopRenderer: (row: any, col: any) => statusRenderer(row, col, c.Display, c.Css),
            }),
            ...(c.Type === 'Currency' && {
                align: 'right',
                desktopRenderer: (row: any) => amountRenderer(row, currencyList, c.FieldName, c.Format),
            }),
            ...(c.Type === 'ValidationFailureMessages' && {
                desktopRenderer: (row: any) => validationMessagesRenderer(row, c.FieldName),
            }),
            ...(c.Type === 'Array' && {
                desktopRenderer: (row: any) => arrayRenderer(row, c.FieldName),
            }),
            ...(c.Type === 'DateTimeFromNow' && {
                desktopRenderer: (row: any) => createdTimeRenderer(row, c.FieldName, c.Format),
            }),
        };
        config.push(columnDisplay);
    });
    return config;
};

interface IContainsContainsModeSearchInfo {
    mode: 'Contains';

    criteriaFilled: boolean;
}

interface IContainsStartsWithModeModeSearchInfo {
    mode: 'StartsWith';
}

export type ContainsSearchInfo = IContainsContainsModeSearchInfo | IContainsStartsWithModeModeSearchInfo;

function replaceContains(value: string) {
    return value?.replace(/contains\(([^)]*)\)/g, 'startswith($1)');
}

function processSearchFilterConfig(searchFilterConfig: any, containsSearchInfo: ContainsSearchInfo) {
    if (containsSearchInfo.mode === 'StartsWith') {
        searchFilterConfig = JSON.parse(JSON.stringify(searchFilterConfig));

        // make these starts with if they exist and contain "contains"
        searchFilterConfig.$filter.Prefix = replaceContains(searchFilterConfig.$filter.Prefix);
        searchFilterConfig.$filter.Default = replaceContains(searchFilterConfig.$filter.Default);

        const fields = searchFilterConfig.$filter.Fields;

        Object.keys(fields).forEach((key) => {
            // need special handling for status dictionary
            if (key === 'Status') {
                Object.keys(fields.Status).forEach((statusNum) => {
                    fields.Status[statusNum] = replaceContains(fields.Status[statusNum]);
                });

                return;
            }

            fields[key] = replaceContains(fields[key]);
        });
    }

    return searchFilterConfig;
}

function getHasContainsCriteria(searchFilterConfig: any, searchParams: any) {
    const fields = searchFilterConfig.$filter.Fields;
    return Object.keys(fields).some((key) => {
        if (key === 'Status') {
            // a selected status will perform a contains search
            return (
                searchParams.Status &&
                searchParams.Status.value &&
                searchParams.Status.value.some(
                    (x: any) =>
                        x.value &&
                        !Number.isNaN(Number(x.value)) &&
                        fields.Status[Number(x.value)] &&
                        fields.Status[Number(x.value)].includes('contains(')
                )
            );
        }

        // regular field has contains and search param is filled
        return fields[key].includes('contains(') && searchParams && searchParams[key] && searchParams[key].value;
    });
}

export function hasContainsInFilters(searchFilterConfig: any) {
    const fields = searchFilterConfig.$filter.Fields;
    return Object.keys(fields).some((key) => {
        if (key === 'Status') {
            // some status has 'contains()' in search filter string
            return Object.keys(fields.Status).some((x) => fields.Status[x].includes('contains('));
        }

        // any other field has 'contains()' in search filter string
        return fields[key].includes('contains(');
    });
}

export function shouldRenderPerformContainsSearchButton(searchFilterConfig: any) {
    try {
        return hasContainsInFilters(searchFilterConfig);
    } catch (e) {
        return false;
    }
}

export function getContainsSearchInfo(
    performContainsSearch: boolean,
    searchFilterConfig: any,
    searchParams: any
): ContainsSearchInfo {
    if (!performContainsSearch) {
        return { mode: 'StartsWith' };
    }

    const hasContainsCriteria = getHasContainsCriteria(searchFilterConfig, searchParams);

    return {
        mode: 'Contains',
        criteriaFilled: hasContainsCriteria,
    };
}

const requestParametersParser = (requestParameters: any, paramKeys: any, searchParams: any) => {
    let additionalParameters: Record<string, any> = {};
    for (const key in requestParameters) {
        if (paramKeys.includes(key)) {
            const paramType = searchParams[key].type;
            if (paramType === 'NumericRange') {
                const numericRanges = searchParams[key].value.split('/');
                numericRanges[0] =
                    numericRanges[0] && numericRanges[0].trim().length !== 0
                        ? numericRanges[0]
                        : Number.MIN_SAFE_INTEGER.toString();
                numericRanges[1] =
                    numericRanges[1] && numericRanges[1].trim().length !== 0
                        ? numericRanges[1]
                        : Number.MAX_SAFE_INTEGER.toString();
                const params = requestParameters[key].split(' & ');
                // eslint-disable-next-line @typescript-eslint/no-loop-func
                params.forEach((value: any, i: number) => {
                    const param = value.replace(`$${key}${i + 1}$`, numericRanges[i]).split('=');
                    additionalParameters = {
                        ...additionalParameters,
                        [param[0]]: param[1],
                    };
                });
            } else {
                let param = requestParameters[key];
                if (paramType === 'Select') {
                    param = param.replace(`$${key}$`, searchParams[key].value.value).split('=');
                } else {
                    param = param.replace(`$${key}$`, searchParams[key].value).split('=');
                }
                additionalParameters = {
                    ...additionalParameters,
                    [param[0]]: param[1],
                };
            }
        }
    }
    return additionalParameters;
};

export const getSearchFilter = (
    docType: any,
    searchParams: any,
    searchFilterConfig: any,
    performContainsSearch: boolean
) => {
    searchFilterConfig = processSearchFilterConfig(
        searchFilterConfig,
        getContainsSearchInfo(performContainsSearch, searchFilterConfig, searchParams)
    );
    const { $filter: oDataFilter, ...requestParameters } = searchFilterConfig;
    let $filter = `BusinessDocType eq ${docType}`;
    const oDataSearchParamKeys: string[] = [];
    const requestSearchParamKeys: string[] = [];

    for (const key in searchParams) {
        if (
            searchParams[key] &&
            searchParams[key].value &&
            ((searchParams[key].type === 'Select' && searchParams[key].value.value) ||
                (!/^\s+$/.test(searchParams[key].value) && searchParams[key].value.length) ||
                searchParams[key].type === 'Checkbox')
        ) {
            if (oDataFilter.Fields.hasOwnProperty(key)) {
                oDataSearchParamKeys.push(key);
            }
            if (requestParameters.hasOwnProperty(key)) {
                requestSearchParamKeys.push(key);
            }
        }
    }

    const filterString = oDataSearchParamKeys.reduce((acc, fieldKey) => {
        const paramValue = searchParams[fieldKey].value;
        const paramType: SearchCriteriaType = searchParams[fieldKey].type;
        const filterTemplate = searchFilterConfig.$filter.Fields[fieldKey];

        if (acc) {
            acc += ` ${searchFilterConfig.$filter.ConcatenationString} `;
        }

        if (paramType === 'DateRange') {
            if (Date.parse(paramValue[0]) || Date.parse(paramValue[1])) {
                const startDate = new Date(Date.parse(paramValue[0] ? paramValue[0] : defaultMinDate));
                const endDate = new Date(Date.parse(paramValue[1] ? paramValue[1] : defaultMaxDate));

                //set date start to start of the day 00:00:00, and date end to the end of the date 23:59:59
                startDate.setHours(0, 0, 0, 0);
                endDate.setHours(23, 59, 59, 999);

                const utcStartDate = new Date(
                    startDate.getTime() - startDate.getTimezoneOffset() * 60000
                ).toISOString();
                const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString();

                acc += `${filterTemplate
                    .replace(`$${fieldKey}1$`, utcStartDate)
                    .replace(`$${fieldKey}2$`, utcEndDate)}`;
            }
        } else if (fieldKey === 'TimeParked') {
            const ranges = searchParams[fieldKey].value.split('/');
            ranges[0] = ranges[0] && ranges[0].trim().length !== 0 ? ranges[0] : 0;
            ranges[1] = ranges[1] && ranges[1].trim().length !== 0 ? ranges[1] : 0;

            const startDate = ranges[1] === 0 ? new Date(0).toISOString() : getUtcDateByHoursDifference(ranges[1]);
            const endDate = getUtcDateByHoursDifference(ranges[0]);

            acc += `${filterTemplate.replace(`$${fieldKey}1$`, startDate).replace(`$${fieldKey}2$`, endDate)} `;
        } else if (paramType === 'NumericRange') {
            const ranges = searchParams[fieldKey].value.split('/');
            ranges[0] = ranges[0] && ranges[0].trim().length !== 0 ? ranges[0] : Number.MIN_SAFE_INTEGER.toString();
            ranges[1] = ranges[1] && ranges[1].trim().length !== 0 ? ranges[1] : Number.MAX_SAFE_INTEGER.toString();

            acc += `${filterTemplate.replace(`$${fieldKey}1$`, ranges[0]).replace(`$${fieldKey}2$`, ranges[1])} `;
        } else if (fieldKey === 'Status') {
            const selectResult = paramValue.map?.((a: any) => a.value) || [];
            const filterValueResults: any[] = [];

            for (const k in filterTemplate) {
                if (selectResult.includes(parseFloat(k))) {
                    filterValueResults.push(filterTemplate[k]);
                }
            }

            if (filterValueResults.length) {
                acc += `(${filterValueResults.reduce((accResult, value) => {
                    if (accResult) {
                        accResult += ` or `;
                    }
                    accResult += `(${value})`;
                    return accResult;
                }, '')})`;
            }
        } else if (paramType === 'Checkbox') {
            if (paramValue) {
                acc += filterTemplate;
            }
        } else {
            acc += filterTemplate.replace(
                `$${fieldKey}$`,
                paramType === 'Select' ? paramValue.value : escape(paramValue)
            );
        }
        return acc;
    }, '');

    if (filterString) {
        $filter += ` ${searchFilterConfig.$filter.ConcatenationString} ${filterString}`;
    }

    if (searchFilterConfig.$filter.Prefix) {
        $filter += ` ${searchFilterConfig.$filter.ConcatenationString} ${searchFilterConfig.$filter.Prefix}`;
    }
    const additionalParameters = requestParametersParser(requestParameters, requestSearchParamKeys, searchParams);

    return { $filter, additionalParameters };
};
