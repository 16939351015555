import f, { ODataFilterBuilder } from 'odata-filter-builder';
import { escape, isEmpty, ODataUtils } from '../../../utils';

import { IUserSearchItems } from './types';

export const createUserFilter = ({ name, email }: Partial<IUserSearchItems> = {}) => {
    const filterRules: ODataFilterBuilder[] = [];

    if (!isEmpty(name)) {
        filterRules.push(f().contains('name', escape(name!)));
    }

    if (!isEmpty(email)) {
        filterRules.push(f().contains('email', escape(email!)));
    }

    return filterRules.length ? ODataUtils.orConditions(filterRules).toString() : undefined;
};
