import { useCurrentUser } from './useCurrentUser';
import { ExtraFeature } from '../types';

export function useIsExtraFeatureEnabled(extraFeature: ExtraFeature) {
    const currentUser = useCurrentUser();
    if (!currentUser) {
        return false;
    }

    return currentUser.extraFeatures.has(extraFeature);
}
