import React, { useRef, useState, useLayoutEffect } from 'react';

/**
 * Subscribes to an event emitter by calling the provided `subscribe` function
 * on mount. Triggers a re-render whenever the event emitter emits the event
 * it is subscribed to.
 * @param subscribe a function that takes a callback to call when the event occurs and returns a function to dispose the subscription.
 */
export function useSubscription(subscribe: (cb: () => void) => () => void, deps?: React.DependencyList) {
    const mutationCountRef = useRef(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [, setMutationCount] = useState(0);

    /* eslint-disable react-hooks/exhaustive-deps */
    // this is intended to only subscribe on component mount
    // and trigger re-renders whenever the subcriber callback
    // is called
    useLayoutEffect(() => {
        return subscribe(() => {
            mutationCountRef.current += 1;
            setMutationCount(mutationCountRef.current);
        });
    }, deps);
    /* eslint-enable */
}
