import { ScriptCompiler } from '../../Utility';
import { RuntimeWorkflowValidation } from './RuntimeWorkflowValidation';
import { api } from '../../../../services/api';

async function fetchScriptSource(fileName: string): Promise<string> {
    const response = await api().get(`../TransceptaAPICode/WorkflowJSFiles/${fileName}`);

    return response.data;
}

async function fetchAndCompileScript(scriptName: string) {
    const scriptSrc = await fetchScriptSource(scriptName);

    return ScriptCompiler.compileWorkflowScript(scriptSrc);
}

async function createWorkflowValidation(scriptName: string): Promise<RuntimeWorkflowValidation> {
    const script = await fetchAndCompileScript(scriptName);

    return {
        name: scriptName,
        script,
    };
}

class RuntimeWorkflowValidationFetcher {
    async fetchWorkflowValidation(scriptName: string) {
        const workflowValidation = await createWorkflowValidation(scriptName);
        return workflowValidation;
    }
}

export const runtimeWorkflowValidationFetcher = new RuntimeWorkflowValidationFetcher();
