import { DateRange } from '@mui/lab';
import { createFieldTypeFactory, IFieldType } from '../infrastructure';

/**
 * A Date Range field object.
 */
export interface IDateRangePickerField extends IFieldType<DateRange<Date>> {
    type: 'IDateRangePickerField';

    placeholders?: [string, string];
}

export const createDateRangePickerField = createFieldTypeFactory<IDateRangePickerField>('IDateRangePickerField', [
    null,
    null,
]);
