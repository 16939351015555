//
export const GET_CUSTOMER_LOCATION_EXECUTE = 'GET_CUSTOMER_LOCATION_EXECUTE';
export const GET_CUSTOMER_LOCATION_SUCCESS = 'GET_CUSTOMER_LOCATION_SUCCESS';
export const GET_CUSTOMER_LOCATION_FAILED = 'GET_CUSTOMER_LOCATION_FAILED';

export const CREATE_CUSTOMER_LOCATION_EXECUTE = 'CREATE_CUSTOMER_LOCATION_EXECUTE';
export const CREATE_CUSTOMER_LOCATION_SUCCESS = 'CREATE_CUSTOMER_LOCATION_SUCCESS';
export const CREATE_CUSTOMER_LOCATION_FAILED = 'CREATE_CUSTOMER_LOCATION_FAILED';

export const FETCH_CUSTOMER_LOCATION_EXECUTE = 'FETCH_CUSTOMER_LOCATION_EXECUTE';
export const FETCH_CUSTOMER_LOCATION_FAILURE = 'FETCH_CUSTOMER_LOCATION_FAILURE';
export const FETCH_CUSTOMER_LOCATION_SUCCESS = 'FETCH_CUSTOMER_LOCATION_SUCCESS';

/**
 * Get customer location list success action
 */
export const getCustomerLocationSuccessAction = (response, page, sortColumnKey, sortDirection) => ({
    type: GET_CUSTOMER_LOCATION_SUCCESS,
    payload: {
        ...response.data,
        currentPage: page,
        sorting: {
            column: sortColumnKey,
            type: sortDirection,
        },
    },
});

/**
 * Get customer location list error action
 */
export const getCustomerLocationErrorAction = (error) => ({
    type: GET_CUSTOMER_LOCATION_FAILED,
});

/**
 * Get customer location list
 *
 */
export const getCustomerLocation = (params) => ({
    type: GET_CUSTOMER_LOCATION_EXECUTE,
    payload: {
        params: params,
    },
});

/**
 * Add new customer location request
 */
export const createCustomerLocationRequest = (params) => ({
    type: CREATE_CUSTOMER_LOCATION_EXECUTE,
    payload: {
        params: params,
    },
});

/**
 * Add new customer location request success
 */
export const createCustomerLocationRequestSuccess = (response) => ({
    type: CREATE_CUSTOMER_LOCATION_SUCCESS,
    payload: response,
});

/**
 * Add new customer location request failed
 */
export const createCustomerLocationRequestFailed = (err) => ({
    type: CREATE_CUSTOMER_LOCATION_FAILED,
    playload: err,
});

export const fetchCustomerLocationExecute = (params) => ({
    type: FETCH_CUSTOMER_LOCATION_EXECUTE,
    payload: { params: params },
});

export const fetchCustomerLocationFailure = (err) => ({
    type: FETCH_CUSTOMER_LOCATION_FAILURE,
    payload: err,
});

export const fetchCustomerLocationSuccess = (data) => ({
    type: FETCH_CUSTOMER_LOCATION_SUCCESS,
    payload: data,
});
