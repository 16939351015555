/**
 * Implements the same functionality as React.useMemo, but for
 * use in class components. Use to refactor state computed from props
 * in a class component that is too complex to convert to a function
 * component all at once.
 */
export default class ClassComponentMemoizer<T> {
    private oldKey: any[] | undefined;

    private oldValue: T | undefined;

    // eslint-disable-next-line class-methods-use-this
    private keysDiffer(oldKey: any[], newKey: any[]) {
        if (oldKey.length !== newKey.length) {
            return true;
        }
        return oldKey.some((x, i) => x !== newKey[i]);
    }

    getOrCompute(computeValue: () => T, dependencies: any[]) {
        if (!this.oldKey || this.keysDiffer(this.oldKey, dependencies)) {
            this.oldKey = dependencies;
            this.oldValue = computeValue();
        }

        return this.oldValue as T;
    }
}
