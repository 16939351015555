import { default as ValidationFailureTypesApi } from '../../../../services/ValidationFailureTypes/Api/ValidationFailureTypesApi';
import { BusinessDocType } from '../../../../types';
import { ValidationFailureDictionary } from '../../../DocumentEditRules/types/ValidationFailureType';

/**
 * Fetches the validation failure types based on the provided parameters.
 *
 * @param buyerCompanyId - The ID of the buyer company.
 * @param businessDocType - The business document type.
 * @param childCompanyId - The ID of the child company (optional).
 * @returns A dictionary of validation failure types.
 */
export async function fetchValidationFailureTypes(
    buyerCompanyId: number,
    businessDocType: BusinessDocType,
    childCompanyId?: number | null
) {
    const vftApi = new ValidationFailureTypesApi();

    const res = await vftApi.getValidationFailureTypesByCompanyId(
        buyerCompanyId,
        `BusinessDocType eq ${businessDocType}`,
        childCompanyId
    );

    const failureTypeDictionary: ValidationFailureDictionary = {};

    res.data.forEach((v) => {
        failureTypeDictionary[v.ErrorCode] = {
            errorCode: v.ErrorCode,
            titleText: v.TitleText,
            detailText: v.DetailText,
            disposition: v.Disposition,
            id: v.Id,
            validationType: v.ValidationType,
        };
    });

    return failureTypeDictionary;
}
