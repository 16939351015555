import { PropsWithChildren } from 'react';

const thStyle = {
    textAlign: 'left',
} as const;

export default function FeatureList({ children }: PropsWithChildren<{}>) {
    return (
        <table style={{ width: '100%' }}>
            <thead>
                <tr>
                    <th style={thStyle}>Feature Key</th>
                    <th style={thStyle}>Your Setting</th>
                    <th style={thStyle}>Feature State</th>
                </tr>
            </thead>
            <tbody>{children}</tbody>
        </table>
    );
}
