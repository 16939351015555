import { BusinessDocType } from '../../../types';

// TODO: Should these be provided by the portal?
const BusinessDocTypeNames: { [key in BusinessDocType]?: string } = {
    [BusinessDocType.Invoice]: 'Invoice',
    [BusinessDocType.PurchaseOrder]: 'PO',
    [BusinessDocType.PurchaseOrderAcknowledgement]: 'PO ACK',
    [BusinessDocType.ASN]: 'ASN',
    [BusinessDocType.SIMDocument]: 'SIM Doc',
    [BusinessDocType.InventoryAdvice]: 'Inventory Advice',
    [BusinessDocType.ReceivingAdvice]: 'Receiving Advice',
    [BusinessDocType.ConsumptionAdvice]: 'Consumption Advice',
};

export function getDocumentTypeName(documentType: BusinessDocType) {
    return BusinessDocTypeNames[documentType];
}
