import actions from '../../actions/index';

const {
    DOCUMENT_SEARCH_LAYOUT_CONFIG_FETCHING,
    DOCUMENT_SEARCH_LAYOUT_CONFIG_FETCHED,
    DOCUMENT_SEARCH_CSV_DATA_FETCHED,
    DOCUMENT_SEARCH_CLEAR_CSV_DATA,
} = actions;

const initialState = {
    layoutConfigs: {},
    isLoading: false,
    csvData: '',
};

export function documentSearch(state = initialState, action) {
    switch (action.type) {
        case DOCUMENT_SEARCH_LAYOUT_CONFIG_FETCHING:
            return { ...state, isLoading: true };
        case DOCUMENT_SEARCH_LAYOUT_CONFIG_FETCHED:
            return {
                ...state,
                layoutConfigs: action.payload,
                isLoading: false,
            };
        case DOCUMENT_SEARCH_CSV_DATA_FETCHED:
            return {
                ...state,
                csvData: action.payload,
            };
        case DOCUMENT_SEARCH_CLEAR_CSV_DATA:
            return {
                ...state,
                csvData: '',
            };
        default:
            return state;
    }
}
