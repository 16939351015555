import { FC, ReactNode } from 'react';
import { FormControlLabel, Radio } from '@mui/material';

export interface IRadioButtonProps {
    value: string;
    label?: string;
    labelAsNode?: ReactNode;
}

export const RadioButton: FC<IRadioButtonProps> = ({ value, label, labelAsNode }) => {
    return <FormControlLabel key={value} value={value} control={<Radio />} label={label || labelAsNode} />;
};
