import { useMutation, useQuery } from '@tanstack/react-query';
import { Box } from '@mui/material';

import { Alert, Loading } from '../../ui';
import { IDocumentViewModel } from '../../services/Document/Api';
import { DocumentEditRules } from '..';
import { ExecutionService } from '../CustomJSRuleEngineV2';
import { backendServices, Disposition } from '../../services';

import { formatValidationResultAndTransformToMessageClasses } from './formatValidationResults';
import { useVendorForCustomJSRuleEngineV2 } from './useVendorForCustomJSRuleEngineV2';
import { useValidationFailureDictionary } from './useValidationFailureDictionary';
import { useRuntimeRules } from './useRuntimeRules';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useClientValidationController } from './ClientValidationController';
import { defaultMessageClassesState, DocumentValidatorProvider } from './DocumentValidationContext';
import { defaultFieldControlState, IFieldControlStates, useDropdownOptionsRepository } from '../DocumentEditRules';
import { ValidationFailureType } from '../DocumentEditRules/types/ValidationFailureType';
import { DocumentValidatorV2 } from '../CJSRuleEngineV2ClientWrapper';
import { ValidationEvent } from '../CustomJSRuleEngineV2/ExecutionService/DPSValidations';
import { ContextInformationCollector } from '../CustomJSRuleEngineV2/ExecutionService/DPSValidations/ContextInformationCollector';
import { ClientValidationController } from './ClientValidationController/ClientValidationController';
import { createCachingService } from './createCachingService';
import { BusinessDocType } from '../../types';

function nextEventLoop() {
    return new Promise((r) => setTimeout(r));
}

type DocumentState =
    | (IDocumentViewModel & {
          attachments: {
              accepted: object[];
              saved: object[];
          };
      })
    | null;

function validationResultToValidationFailureDetailsVM({
    error,
    validationFailureType,
    documentId,
}: {
    error: ExecutionService.DPSValidations.DPSValidationResult;
    validationFailureType: ValidationFailureType;
    documentId: number;
}) {
    const { titleArguments, detailArguments = [], defaultVFT, computedDisposition } = error;

    const { titleText, detailText } = defaultVFT ?? {};

    return {
        TitleText: titleText,
        DetailText: detailText,
        DocumentId: documentId,
        ValidationFailureTypeId: validationFailureType.id,
        // VFTDisposition is a subset of Disposition, so this should be fine.
        Disposition: computedDisposition as unknown as Disposition,
        TitleSub1: titleArguments[0] ?? null,
        TitleSub2: titleArguments[1] ?? null,
        TitleSub3: titleArguments[2] ?? null,
        TitleSub4: titleArguments[3] ?? null,
        TitleSub5: titleArguments[4] ?? null,
        TitleSub6: titleArguments[5] ?? null,
        DetailSub1: detailArguments[0] ?? null,
        DetailSub2: detailArguments[1] ?? null,
        DetailSub3: detailArguments[2] ?? null,
        DetailSub4: detailArguments[3] ?? null,
        DetailSub5: detailArguments[4] ?? null,
        DetailSub6: detailArguments[5] ?? null,
    };
}

function shouldEnableValidations({
    context,
    buyerCompanyId,
    document,
    workflowId,
    workflowActivityId,
    senderCompanyId,
    vfDictionaryIsLoading,
    vendorIsLoading,
    ignoreSenderCompanyId,
}: Partial<WorkflowValidationsV2WrapperProps> & {
    vfDictionaryIsLoading: boolean;
    vendorIsLoading: boolean;
}) {
    const { attachments, BusinessDocType: businessDocType, ...docState } = document ?? {};

    const documentHasLoaded = !!Object.keys(docState).length;

    if (context === backendServices.ViewModels.ExecutionContext.UserWorkflowOrWorkflowActivity) {
        return (
            buyerCompanyId != null &&
            documentHasLoaded &&
            workflowId != null &&
            workflowActivityId != null &&
            (senderCompanyId != null || !!ignoreSenderCompanyId) &&
            !vfDictionaryIsLoading &&
            // we cannot load the vendor (trading partner) record without the senderCompanyId
            (!vendorIsLoading || !!ignoreSenderCompanyId) &&
            businessDocType === BusinessDocType.Invoice
        );
    } else if (context === backendServices.ViewModels.ExecutionContext.WebEntry) {
        return (
            buyerCompanyId != null &&
            documentHasLoaded &&
            senderCompanyId != null &&
            !vfDictionaryIsLoading &&
            !vendorIsLoading &&
            businessDocType === BusinessDocType.Invoice
        );
    }

    throw new Error('Unexpected Execution Context');
}

function usePreviousDocumentState(controller: ClientValidationController | null, document: DocumentState) {
    const [previousDocumentState, setPreviousDocumentState] = useState<DocumentState | null>(null);

    const shouldCaptureDocumentState = !!controller?.shouldCaptureDocumentState();

    useEffect(() => {
        const captureDocumentState = async () => {
            await new Promise((r) => setTimeout(r));
            setPreviousDocumentState(document);
        };

        if (shouldCaptureDocumentState) {
            captureDocumentState();
        }
        // This is intentional, we don't want to capture the document state on each document update
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldCaptureDocumentState]);

    return previousDocumentState;
}

type ValidationRenderProp =
    | {
          validationResultAsStrings: true;
          render: (results: { errors: string[]; warnings: string[]; validating: boolean }) => ReactNode;
      }
    | {
          validationResultAsStrings: false | undefined;
          render: (results: ReactNode) => ReactNode;
      };

type WorkflowValidationsV2WrapperProps = {
    context: backendServices.ViewModels.ExecutionContext;
    render(errors: ReactNode): ReactNode;
    buyerCompanyId?: number | null;
    workflowId?: number;
    workflowActivityId?: number;
    senderCompanyId?: number;
    document?: DocumentState;
    disableValidations?: boolean;
    fetchValidationFailureDetails?: (documentId: number) => void;
    ignoreSenderCompanyId?: boolean;
    documentStateUpdater: (oldState: IDocumentViewModel, newState: IDocumentViewModel) => void;
    childCompanyId?: number | null;
} & ValidationRenderProp;

export function CJSRuleEngineV2ClientWrapper({
    context,
    render,
    buyerCompanyId,
    workflowId,
    workflowActivityId,
    senderCompanyId,
    document,
    disableValidations = false,
    validationResultAsStrings,
    fetchValidationFailureDetails,
    ignoreSenderCompanyId = false,
    documentStateUpdater,
    childCompanyId = null,
}: WorkflowValidationsV2WrapperProps) {
    // includes optional childCompanyId
    const { vendorClass, vendorIsLoading } = useVendorForCustomJSRuleEngineV2({
        buyerCompanyId,
        context,
        senderCompanyId,
        childCompanyId,
    });
    const { validationFailureDictionary, isLoading: vfDictionaryIsLoading } = useValidationFailureDictionary({
        buyerCompanyId,
        businessDocType: document?.BusinessDocType,
        childCompanyId,
    });
    const dropdownOptionsRepository = useDropdownOptionsRepository();

    const validationsEnabled =
        !disableValidations &&
        shouldEnableValidations({
            context,
            buyerCompanyId,
            workflowId,
            workflowActivityId,
            senderCompanyId,
            document,
            vendorIsLoading,
            vfDictionaryIsLoading,
            ignoreSenderCompanyId,
        });

    const {
        runtimeRules,
        isLoading: rulesAreLoading,
        validationConfiguration,
    } = useRuntimeRules({
        context,
        validationsEnabled,
        buyerCompanyId,
        vendorClass,
        workflowActivityId,
        workflowId,
        childCompanyId,
    });

    const validationController = useClientValidationController({ validationConfiguration });

    const previousDocumentState = usePreviousDocumentState(validationController, document ?? null);
    const [previousEvent, setPreviousEvent] = useState<ValidationEvent | null>(null);

    const readyToRunValidations = validationsEnabled && !rulesAreLoading;

    const { data: cjsEngine, isLoading: isLoadingCJSEngine } = useQuery(
        ['CJSEngine'],
        async () => {
            const engine = await ExecutionService.DPSValidations.executeClientValidations({
                context,
                buyerCompanyId: buyerCompanyId!,
                workflowId,
                workflowActivityId,
                senderCompanyId: senderCompanyId!,
                vendorClass,
                rulesFromClient: runtimeRules!,
                dropdownRepository: dropdownOptionsRepository,
                createCachingService,
                childCompanyId: childCompanyId ?? undefined,
            });

            return engine;
        },
        {
            enabled: readyToRunValidations,
            cacheTime: 0,
            staleTime: Infinity,
        }
    );

    const [fieldControlStates, setFieldControlStates] = useState<IFieldControlStates>({});

    const { mutateAsync: executeControllers } = useMutation({
        mutationFn: async ({
            contextInformationCollector,
        }: {
            contextInformationCollector: ContextInformationCollector;
        }) => {
            await cjsEngine!.controlDocumentUI({
                document: document!,
                documentStateUpdater,
                contextInformationCollector,
                setFieldControlStates,
            });
        },
    });

    const { data: validationResult, mutateAsync: executeValidations } = useMutation({
        mutationFn: async ({
            contextInformationCollector,
        }: {
            contextInformationCollector: ContextInformationCollector;
        }) => {
            const rawValidationResults = await cjsEngine!.validateDocument({
                document: document!,
                contextInformationCollector,
            });

            return cjsEngine!.transformToErrorAndWarningLists(
                rawValidationResults,
                validationFailureDictionary!,
                validationConfiguration?.inputParameters ?? []
            );
        },
        cacheTime: Infinity,
    });

    const isValidating = !!validationController?.isValidating();

    const { mutateAsync: postValidationResults } = useMutation(async ({ documentId }: { documentId: number }) => {
        if (
            validationResult == null ||
            !readyToRunValidations ||
            /**
             * If this is nullish, it means there's no mapping configured for this context,
             * so we shouldn't update the Validation Failure Details
             */
            validationConfiguration == null
        ) {
            return;
        }

        const { errors } = validationResult;

        const validationFailureDetails = errors.map((error) => {
            const validationFailureType = validationFailureDictionary?.[error.errorCode];

            return validationResultToValidationFailureDetailsVM({
                error,
                // By the time we have validation results, this data has already loaded.
                validationFailureType: validationFailureType!,
                documentId,
            });
        });

        const documentApi = new backendServices.Apis.DocumentApi();

        await documentApi.postDocumentValidationResult({ documentId }, validationFailureDetails);

        fetchValidationFailureDetails?.(documentId);
    });

    const validateEvent = useCallback(
        async (event: ValidationEvent) => {
            if (disableValidations) {
                return true;
            }

            if (!readyToRunValidations || isLoadingCJSEngine) {
                return false;
            }

            if (!validationController?.isValidationNeeded(event)) {
                return true;
            }

            if (!validationController?.canRunValidations()) {
                return false;
            }

            validationController.setValidatingState();

            /**
             * * There are some events (e.g., "SupportingDocumentChangeEvent") that take too long to
             * * update the redux store, this ensures validations will run after the state is up to date.
             */
            await nextEventLoop();

            const contextInformationCollector = new ContextInformationCollector({
                type: 'client',
                event,
                previousExecution:
                    previousDocumentState && previousEvent
                        ? {
                              event: previousEvent,
                              document: previousDocumentState,
                          }
                        : null,
            });

            await executeControllers({ contextInformationCollector });

            // We need to wait for redux to apply the document edits performed in the previous step
            await nextEventLoop();

            const result = await executeValidations({ contextInformationCollector });

            const hasErrors = !!result.errors.length;

            if (hasErrors) {
                validationController.setValidationFailedState();
            } else {
                validationController.setValidationSucceededState();
            }
            setPreviousEvent(event);

            if (
                event.type === 'WorkflowSubmitEvent' &&
                validationController.workflowSubmitEventIsAllowedInValidationFailedState
            ) {
                return true;
            }

            return !hasErrors;
        },
        [
            validationController,
            executeValidations,
            readyToRunValidations,
            isLoadingCJSEngine,
            disableValidations,
            executeControllers,
            previousDocumentState,
            previousEvent,
        ]
    );

    const [hasTriggeredLoadEvent, setHasTriggeredLoadEvent] = useState(false);

    useEffect(() => {
        if (readyToRunValidations && !isLoadingCJSEngine && !hasTriggeredLoadEvent) {
            validateEvent({
                type: 'WorkflowLoadEvent',
            });
            setHasTriggeredLoadEvent(true);
        }
    }, [validateEvent, readyToRunValidations, isLoadingCJSEngine, hasTriggeredLoadEvent]);

    const messageClasses = useMemo(() => {
        if (!validationResult) {
            return defaultMessageClassesState;
        }

        return formatValidationResultAndTransformToMessageClasses(validationResult);
    }, [validationResult]);

    const {
        errorMessages: { messages: headerErrorMessages },
        warningMessages: { messages: headerWarningMessages },
    } = messageClasses;

    const [fullErrorList, fullWarningList] = useMemo(() => {
        const associatedErrors = Object.values(messageClasses.errorMessages.fieldMessages)
            .flat()
            .filter((list): list is NonNullable<typeof list> => list != null);

        const associatedWarnings = Object.values(messageClasses.warningMessages.fieldMessages)
            .flat()
            .filter((list): list is NonNullable<typeof list> => list != null);

        const errorList = headerErrorMessages.concat(associatedErrors);
        const warningList = headerWarningMessages.concat(associatedWarnings);

        return [errorList, warningList];
    }, [messageClasses, headerErrorMessages, headerWarningMessages]);

    const renderValidations = () =>
        isValidating ? (
            <Alert testId="dbp-validation-alert-loading" severity="info">
                <div>
                    <Loading /> Running Validations
                </div>
            </Alert>
        ) : (
            <>
                {headerErrorMessages.length > 0 && (
                    <Alert testId="dps-validation-alert-error" severity="error" sx={{ my: 1 }}>
                        <Box sx={{ p: 1 }}>
                            <DocumentEditRules.ErrorList
                                testIdPrefix="jsValidationError"
                                errors={headerErrorMessages}
                                color="inherit"
                            />
                        </Box>
                    </Alert>
                )}
                {headerWarningMessages.length > 0 && (
                    <Alert testId="dps-validation-alert-warning" severity="warning" action={<></>} sx={{ my: 1 }}>
                        <Box sx={{ p: 1 }}>
                            <DocumentEditRules.ErrorList
                                testIdPrefix="jsValidationWarning"
                                errors={headerWarningMessages}
                                color="inherit"
                            />
                        </Box>
                    </Alert>
                )}
            </>
        );

    const getFieldControlState = useCallback(
        (fieldKey: string) => fieldControlStates[fieldKey] ?? defaultFieldControlState,
        [fieldControlStates]
    );

    return (
        <DocumentValidatorV2.CJSEngineVersionProvider value={2}>
            <DocumentValidatorProvider
                value={{
                    validateEvent,
                    shouldDisableActionButton: (event) =>
                        validationController?.shouldDisableActionButton(event) ?? false,
                    postValidationResults,
                    isValidating,
                    hasRanValidations: validationController?.hasRanValidations ?? false,
                    messageClasses,
                    // This is used by the dialogs we display on submit,
                    // so we need to show the full list of errors and warnings
                    validationExceptions: {
                        errors: fullErrorList,
                        warnings: fullWarningList,
                    },
                    validationExceptionsDialogOptions: {
                        hideValidationWarningsOnButtonActions:
                            validationConfiguration?.HideValidationWarningsOnButtonActions ?? false,
                    },
                    getFieldControlState,
                }}
            >
                {render(
                    validationResultAsStrings
                        ? { errors: headerErrorMessages, warnings: headerWarningMessages, validating: isValidating }
                        : renderValidations()
                )}
            </DocumentValidatorProvider>
        </DocumentValidatorV2.CJSEngineVersionProvider>
    );
}
