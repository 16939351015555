import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { successfulAuthRedirectLocation } from './SuccessfulAuthRedirectLocation';

/**
 * Redirects the user to the stored location they originally landed on after they successfully authenticate.
 * @param isSuccess whether authentication and profile fetching has been successful.
 */
export function useRedirectAfterSuccessfulAuth(isSuccess: boolean) {
    const history = useHistory();
    const [isRedirected, setIsRedirected] = useState(false);

    useEffect(() => {
        if (isSuccess) {
            const url = successfulAuthRedirectLocation.getRedirectLocation();
            if (url) {
                history.push(url);
            }

            setIsRedirected(true);
        }
    }, [isSuccess, history]);

    return isRedirected;
}
