import { FC, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { AppBar, Drawer, IconButton, Toolbar, useMediaQuery, useTheme, Theme, Box } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { NavigationDrawer } from './HeaderMenuDrawer';
import { TransceptaLogoWhite } from '../Logos';
import { IMenuItem, IAccountMenu } from './types';
import { AccountDropdown } from './AccountDropdown';

const useStyles = makeStyles((theme: Theme) => ({
    accountButton: {
        '&:focus': {
            border: `1px solid ${theme.palette.primary.contrastText}`,
        },
    },
    appBar: ({ drawerWidth }: { drawerWidth: number }) => ({
        maxHeight: 64,
        [theme.breakpoints.up('lg')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    }),
    menuButton: {
        color: theme.palette.primary.contrastText,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '15px',
    },
    appBarHeader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    drawer: ({ drawerWidth }: { drawerWidth: number }) => ({
        [theme.breakpoints.up('lg')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    }),
}));

export interface IHeaderMenuProps {
    menuItems: IMenuItem[];
    accountMenu: IAccountMenu;
    drawerWidth: number;
}

export const HeaderMenu: FC<IHeaderMenuProps> = ({ menuItems, accountMenu, drawerWidth }) => {
    const classes = useStyles({ drawerWidth });
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const location = useLocation();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        setMobileOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="fixed" sx={{ bgcolor: 'primary.700', p: 0 }}>
                <Toolbar sx={{ padding: '0 !important' }}>
                    {isMdDown && (
                        <>
                            <div className={classes.menuButton}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={handleDrawerToggle}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </div>
                            <div className={classes.appBarHeader}>
                                <TransceptaLogoWhite />
                            </div>
                        </>
                    )}
                    <Box sx={{ flexGrow: 1 }} />
                    {!isMdDown && <AccountDropdown accountMenu={accountMenu} />}
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="site navigation">
                {isMdDown ? (
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor="left"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        sx={{
                            '& .MuiDrawer-paper': {
                                width: drawerWidth,
                                height: '100%',
                                backgroundColor: 'primary.700',
                                color: 'primary.contrastText',
                            },
                        }}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        <NavigationDrawer
                            drawerWidth={drawerWidth}
                            handleDrawerToggle={handleDrawerToggle}
                            accountMenu={accountMenu}
                            menuItems={menuItems}
                        />
                    </Drawer>
                ) : (
                    <Drawer
                        sx={{
                            '& .MuiDrawer-paper': {
                                width: drawerWidth,
                                height: '100%',
                                backgroundColor: 'primary.700',
                                color: 'primary.contrastText',
                            },
                        }}
                        variant="permanent"
                        open
                    >
                        <NavigationDrawer
                            drawerWidth={drawerWidth}
                            handleDrawerToggle={handleDrawerToggle}
                            accountMenu={accountMenu}
                            menuItems={menuItems}
                        />
                    </Drawer>
                )}
            </nav>
        </Box>
    );
};
