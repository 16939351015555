import { BaseTextFieldProps, InputLabelProps } from '@mui/material';
import { FCNC } from '../FCNC';
import { getLocalePercentPlacements } from './LocaleOptions';
import { NumericMaskedTextField } from './NumericMaskedTextField';
import { ITooltipIconProps } from '../Tooltip';
import { FocusEventHandler } from 'react';

export interface INumericTextFieldProps {
    id?: string;

    locale: string;

    value: number | null;

    onChange: (value: number | null) => void;

    maxValue?: number;

    label?: string;

    required?: boolean;

    sx?: BaseTextFieldProps['sx'];

    readonly?: boolean;

    onDoubleClick?: BaseTextFieldProps['onDoubleClick'];

    scale?: number;

    padFractionalZeros?: boolean;

    testId?: string;

    tooltip?: ITooltipIconProps;

    inputLabelProps?: InputLabelProps;

    disabled?: boolean;

    signed?: boolean;

    onFocus?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;

    placeholder?: string;

    stacked?: boolean;

    omitCommas?: boolean;

    textFieldLabel?: string;
}

export const NumericTextField: FCNC<INumericTextFieldProps> = ({
    locale,
    value,
    onChange,
    maxValue,
    required,
    sx,
    readonly,
    label,
    id,
    onDoubleClick,
    testId,
    tooltip,
    scale = 2,
    padFractionalZeros = false,
    inputLabelProps,
    disabled,
    signed,
    onFocus,
    placeholder,
    stacked,
    omitCommas = false,
    textFieldLabel,
}) => {
    const localeOptions = { ...getLocalePercentPlacements(locale) };
    return (
        <NumericMaskedTextField
            stacked={stacked}
            id={id}
            value={value}
            onChange={onChange}
            localeOptions={localeOptions}
            maxValue={maxValue}
            required={required}
            sx={sx}
            readonly={readonly}
            label={label}
            onDoubleClick={onDoubleClick}
            scale={scale}
            padFractionalZeros={padFractionalZeros}
            testId={testId}
            tooltip={tooltip}
            inputLabelProps={inputLabelProps}
            disabled={disabled}
            signed={signed}
            onFocus={onFocus}
            placeholder={placeholder}
            omitCommas={omitCommas}
            textFieldLabel={textFieldLabel}
        />
    );
};
