import { FC } from 'react';
import { Grid } from '../Grid';

/**
 * Adds baseline styling around pages in our legacy portals so that they can be built using mui without
 * conflicting with legacy styles.
 */
export const LegacyPortalPageWrapper: FC = ({ children }) => {
    return (
        <Grid
            className="baseline"
            container
            alignItems="flex-start"
            justifyContent="center"
            sx={{
                width: 1,
                px: {
                    md: 4,
                    lg: 6,
                },
                py: 4,
                fontSize: 16,
                backgroundColor: 'background.default',
            }}
        >
            <Grid item sx={{ width: 1 }}>
                {children}
            </Grid>
        </Grid>
    );
};
