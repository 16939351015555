import { FC, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Pagination } from '../Pagination';
import { Box, Stack } from '@mui/material';

export interface IDumbPdfViewerProps {
    file: File;
    onDocumentLoadSuccess: ({ numPages }: { numPages: number }) => void;
    pageNumber: number;
    numPages?: number;
    setPage?: (page: number) => void;
    scale?: number;
}

export const DumbPdfViewer: FC<IDumbPdfViewerProps> = ({
    file,
    onDocumentLoadSuccess,
    numPages,
    pageNumber,
    setPage,
    scale,
}) => {
    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
    }, []);

    return (
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            <Stack direction="column" justifyContent="center" alignItems="center">
                <Page pageNumber={pageNumber} scale={scale} />
                {numPages && setPage && numPages > 1 && (
                    <Box sx={{ my: 2 }}>
                        <Pagination count={numPages} page={pageNumber - 1} setPage={setPage} size="small" />
                    </Box>
                )}
            </Stack>
        </Document>
    );
};
