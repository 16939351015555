export type IDebuggingLogMode = 'off' | 'debug' | 'info' | 'warn' | 'error';

export function setCurrentDebugLogMode(logMode: IDebuggingLogMode) {
    localStorage.setItem('transceptaDebugLogMode', logMode);
}

export function getCurrentDebugLogMode() {
    return (localStorage.getItem('transceptaDebugLogMode') as IDebuggingLogMode | null) ?? 'off';
}

declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Window {
        transceptaSetCurrentDebugLogMode: typeof setCurrentDebugLogMode;
    }
}

window.transceptaSetCurrentDebugLogMode = setCurrentDebugLogMode;
