import { Box, List, ListItem, ListItemText } from '@mui/material';
import { GridColDef, GridColumns } from '@mui/x-data-grid';
import { ComponentProps } from 'react';
import { formatDistanceToNow, isValid } from 'date-fns';

import { Currency, DocumentStatus, backendServices } from '../../services';
import { TOOLTIP_CELL_MODE } from '../../ui';

import { formatAmount } from '../../legacy/transcepta-common/utils/currency';

type FieldType<T extends backendServices.ViewModels.SearchResultFieldType> = Extract<
    backendServices.ViewModels.SearchResultField,
    { Type: T }
>;

const textOverflowSx: ComponentProps<typeof Box>['sx'] = {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
};

function getArrayColumn(
    base: GridColDef,
    field: FieldType<'Array'>,
    usersMap: Map<number, string>,
    userGroupsMap: Map<number, string>
) {
    const column: GridColDef = {
        ...base,
        sortable: field.Sort ?? base.sortable,
        renderCell: ({ row, cellMode }) => {
            const isUserName = field.FieldName === 'UserName';
            const fieldName = isUserName ? 'User' : field.FieldName;

            let values = row[fieldName] as unknown;

            if (isUserName && Array.isArray(values)) {
                values = values.map((userId) => usersMap.get(userId) ?? null).filter(Boolean);

                // Adding User group names
                values = (values as unknown[]).concat(
                    ((row.UserGroup as number[]) ?? [])
                        .map((userGroupId) => userGroupsMap.get(userGroupId) ?? null)
                        .filter(Boolean)
                );
            }

            if (values == null || !Array.isArray(values) || !values.length) {
                return null;
            }

            if (cellMode === TOOLTIP_CELL_MODE) {
                return (
                    <List
                        dense
                        sx={{
                            maxHeight: '300px',
                            overflow: 'auto',
                        }}
                    >
                        {values.map((value) => (
                            <ListItem key={value}>
                                <ListItemText primary={value} />
                            </ListItem>
                        ))}
                    </List>
                );
            }

            return <Box sx={textOverflowSx}>{values.join(', ')}</Box>;
        },
    };

    return column;
}

function getCurrencyColumn(base: GridColDef, field: FieldType<'Currency'>, currencyList: Map<number, string>) {
    const column: GridColDef = {
        ...base,
        renderCell: ({ row }) => {
            const value = row[field.FieldName] as unknown;

            if (value == null || (typeof value !== 'string' && typeof value !== 'number')) {
                return null;
            }

            const symbol = currencyList.get(row.Currency) ?? '';

            return <Box>{`${symbol} ${formatAmount(value.toString(), field.Format)}`}</Box>;
        },
    };

    return column;
}

function getDateTimeColumn(base: GridColDef, field: FieldType<'DateTime'>) {
    const column: GridColDef = {
        ...base,
        renderCell: ({ row }) => {
            const value = row[field.FieldName] as unknown;

            if (value == null || typeof value !== 'string') {
                return null;
            }

            const parsedValue = new Date(value);

            if (!isValid(parsedValue)) {
                return value;
            }

            return parsedValue.toLocaleDateString();
        },
    };

    return column;
}

function getDateTimeFromNowColumn(base: GridColDef, field: FieldType<'DateTimeFromNow'>) {
    const column: GridColDef = {
        ...base,
        renderCell: ({ row }) => {
            const value = row[field.FieldName] as unknown;

            if (value == null || typeof value !== 'string') {
                return null;
            }

            const parsedValue = new Date(value);

            if (!isValid(parsedValue)) {
                return null;
            }

            const formatFn = field.Format.Function;

            if (formatFn === 'MomentFromNow') {
                return (
                    <Box>
                        {formatDistanceToNow(parsedValue, {
                            addSuffix: false,
                        })}
                    </Box>
                );
            }

            return null;
        },
    };

    return column;
}

function getValidationFailureMessagesColumn(base: GridColDef, field: FieldType<'ValidationFailureMessages'>) {
    const column: GridColDef = {
        ...base,
        renderCell: ({ row, cellMode }) => {
            const values = row[field.FieldName] as unknown;

            if (values == null || !Array.isArray(values) || !values.length) {
                return null;
            }

            if (cellMode === TOOLTIP_CELL_MODE) {
                return (
                    <List
                        dense
                        sx={{
                            maxHeight: '300px',
                            overflow: 'auto',
                        }}
                    >
                        {values.map((value) => (
                            <ListItem key={value.TitleText}>
                                <ListItemText primary={value.TitleText} />
                            </ListItem>
                        ))}
                    </List>
                );
            }

            return <Box sx={textOverflowSx}>{values.map((v) => v.TitleText).join(', ')}</Box>;
        },
    };

    return column;
}

function getStringColumn(base: GridColDef, field: FieldType<'String'>) {
    const column: GridColDef = {
        ...base,
        sortable: field.Sort ?? base.sortable,
        renderCell: ({ row }) => {
            const value = row[field.FieldName] as unknown;

            if (value == null) {
                return null;
            }

            if (field.FieldName === 'DocumentID' && value === 0 && row.Status === DocumentStatus.Draft) {
                return null;
            }

            const isStatusField = field.FieldName === 'Status';

            if (!isStatusField || typeof value !== 'number') {
                // Not sure if this would ever happen
                return value as string;
            }

            const { Css: cssRecord = {}, Display: displayRecord = {} } = field;

            const displayedString = displayRecord[value];
            const appliedCss = cssRecord[value];

            return (
                <Box
                    sx={{
                        ...textOverflowSx,
                        color: appliedCss === 'rejected' ? 'error.main' : undefined,
                    }}
                >
                    {displayedString}
                </Box>
            );
        },
    };

    return column;
}

export const SUFFIX_FOR_DUPES = '-dupe';

/**
 * Using a Record to get an exhaustive check of all keys
 */
const lowercaseFieldTypes: Record<
    Lowercase<backendServices.ViewModels.SearchResultFieldType>,
    backendServices.ViewModels.SearchResultFieldType
> = {
    array: 'Array',
    currency: 'Currency',
    datetime: 'DateTime',
    datetimefromnow: 'DateTimeFromNow',
    string: 'String',
    validationfailuremessages: 'ValidationFailureMessages',
};

const getSearchResultFieldTypeOrDefault = (
    fieldTypeVM: string,
    field: backendServices.ViewModels.SearchResultField
): backendServices.ViewModels.SearchResultFieldType => {
    const targetFieldType = fieldTypeVM.toLowerCase() as keyof typeof lowercaseFieldTypes;
    const properFieldType = lowercaseFieldTypes[targetFieldType];

    if (properFieldType != null) {
        return properFieldType;
    }

    console.error(`Unexpected field type: ${JSON.stringify(field)}`);
    return 'String';
};

export function getGridColumns({
    configuration,
    currencyList,
    users,
    userGroups,
}: {
    configuration: backendServices.ViewModels.DocumentSearchConfigurationViewModel;
    currencyList: Currency[];
    users: Pick<backendServices.ViewModels.UserViewModel, 'ID' | 'Name'>[];
    userGroups: Pick<backendServices.ViewModels.UserGroupViewModel, 'Id' | 'Name'>[];
}): GridColumns {
    const {
        SearchConfiguration: { SearchResult },
    } = configuration;

    const userGroupsMap = new Map(userGroups.map((x) => [x.Id, x.Name]));
    const userMap = new Map(users.map((u) => [u.ID, u.Name]));
    const currencyMap = new Map(currencyList.map((c) => [c.currencyType, c.symbol]));

    const columns: GridColumns = SearchResult.Fields.map((field) => {
        const commonConfig = {
            field: field.FieldName,
            headerName: field.Label,
            sortable: true,
        };

        field.Type = getSearchResultFieldTypeOrDefault(field.Type, field);

        switch (field.Type) {
            case 'Array': {
                return getArrayColumn(commonConfig, field, userMap, userGroupsMap);
            }
            case 'Currency': {
                return getCurrencyColumn(commonConfig, field, currencyMap);
            }
            case 'DateTime': {
                return getDateTimeColumn(commonConfig, field);
            }
            case 'DateTimeFromNow': {
                return getDateTimeFromNowColumn(commonConfig, field);
            }
            case 'ValidationFailureMessages': {
                return getValidationFailureMessagesColumn(commonConfig, field);
            }
            case 'String': {
                return getStringColumn(commonConfig, field);
            }
            default: {
                // This would never be reached, but we need it to silence an eslint error
                return getStringColumn(commonConfig, field);
            }
        }
    });

    const fieldNameInstances = new Map<string, number>();
    return columns.map((col) => {
        const instanceCount = fieldNameInstances.get(col.field);

        if (typeof instanceCount === 'undefined') {
            fieldNameInstances.set(col.field, 1);
            return col;
        }

        const suffixString = new Array(instanceCount).fill(SUFFIX_FOR_DUPES).join('');

        fieldNameInstances.set(col.field, instanceCount + 1);

        col.field += suffixString;

        return col;
    });
}
