import { SimpleFieldRendererViewModel } from '../../FieldRenderer';
import { getSearchFormFieldColumns, getTablePageSizeKey } from '../configUtils';
import { initialSearchFormState, ISearchFormState } from './searchFormState';
import { useSearchFormReducer } from './useSearchFormReducer';
import { useSaveSearchCriteria, useSavedSearchCriteria } from './urlParams';
import { ISearchConfiguration, ISearchRequest, ISearchService } from '../types';

/**
 * Manages the search form state, including storing current state in the URL
 * @param searchConfiguration the configuration for the search page
 * @returns the search form state, dispatch function, and current search request
 */
export function useSearchFormState(
    searchConfiguration: ISearchConfiguration<any> | undefined,
    searchService: ISearchService<any>,
    dontUpdateUrl: boolean
) {
    const savedCriteria = useSavedSearchCriteria();
    const initialValuesSearchFormState: ISearchFormState = { ...initialSearchFormState };
    // overwriting saved criteria with initial values of search form field columns
    if (searchConfiguration) {
        const searchFormFieldColumns =
            getSearchFormFieldColumns(searchConfiguration, new SimpleFieldRendererViewModel({}))?.flat() || [];
        for (const searchFormFieldColumn of searchFormFieldColumns) {
            if (
                searchFormFieldColumn.initialValue !== undefined &&
                JSON.stringify(searchFormFieldColumn.initialValue) !==
                    JSON.stringify(searchFormFieldColumn.defaultValue)
            ) {
                if (
                    JSON.stringify(savedCriteria.enteredCriteria.getValue(searchFormFieldColumn)) ===
                    JSON.stringify(searchFormFieldColumn.defaultValue)
                ) {
                    savedCriteria.enteredCriteria = savedCriteria.enteredCriteria.setValue(
                        searchFormFieldColumn,
                        searchFormFieldColumn.initialValue
                    );
                    savedCriteria.appliedCriteria = savedCriteria.appliedCriteria.setValue(
                        searchFormFieldColumn,
                        searchFormFieldColumn.initialValue
                    );
                }
                initialValuesSearchFormState.enteredCriteria = initialValuesSearchFormState.enteredCriteria.setValue(
                    searchFormFieldColumn,
                    searchFormFieldColumn.initialValue
                );
                initialValuesSearchFormState.appliedCriteria = initialValuesSearchFormState.appliedCriteria.setValue(
                    searchFormFieldColumn,
                    searchFormFieldColumn.initialValue
                );
            }
        }
    }

    // overwriting saved criteria with initial sort value of search configuration
    if (searchConfiguration?.initialSort?.length && !savedCriteria.sortModel.length) {
        savedCriteria.sortModel = searchConfiguration.initialSort;
    }

    const pageSize = Number(localStorage.getItem(getTablePageSizeKey(searchService.key)));

    if (
        savedCriteria.pageSize === undefined &&
        pageSize !== 0 &&
        searchConfiguration?.pageSizeOptions.includes(pageSize)
    ) {
        savedCriteria.pageSize = pageSize;
    }

    const [state, dispatch] = useSearchFormReducer(savedCriteria);
    const defaultPageSize = searchConfiguration?.defaultPageSize ?? 0;
    const searchRequest: ISearchRequest<any> = {
        searchQuery: state.appliedCriteria,
        pageSize: state.pageSize ?? defaultPageSize,
        pageNumber: state.page,
        sort: state.sortModel,
    };

    useSaveSearchCriteria(searchRequest, dontUpdateUrl, defaultPageSize);

    return {
        state,
        dispatch,
        searchRequest,
        initialValuesSearchFormState,
    };
}
