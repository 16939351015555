import { createSelector } from 'reselect';

import { cloneObjectHack } from '../../utils/dataConverter';
import { determineDefaultVATNumber } from '../../utils/defaultVATNumber';

const buyerVATArchiveServiceRecords = (state, invoiceProfileId, buyerDestinationProfileId) =>
    cloneObjectHack(state.company.buyerCompany.vatArchiveServiceRecords);
const supplierVATArchiveServiceRecords = (state, invoiceProfileId, buyerDestinationProfileId) =>
    cloneObjectHack(state.company.supplierCompany.vatArchiveServiceRecords);
const invoiceProfileId = (state, invoiceProfileId, buyerDestinationProfileId) => invoiceProfileId;
const buyerDestinationProfileId = (state, invoiceProfileId, buyerDestinationProfileId) => buyerDestinationProfileId;

export default createSelector(
    buyerVATArchiveServiceRecords,
    supplierVATArchiveServiceRecords,
    invoiceProfileId,
    buyerDestinationProfileId,
    (buyerVATArchiveServiceRecords, supplierVATArchiveServiceRecords, invoiceProfileId, buyerDestinationProfileId) => {
        const defaultVATNumbers = {
            buyerVATNumber: null,
            supplierVATNumber: null,
        };

        let buyerProfileVATArchiveService = buyerVATArchiveServiceRecords.filter(
            (vatArchiveService) => vatArchiveService.BuyerDestinationProfileId && !vatArchiveService.InvoiceProfileId
        );
        if (buyerProfileVATArchiveService.length > 0) {
            if (buyerDestinationProfileId) {
                buyerProfileVATArchiveService = buyerProfileVATArchiveService.filter(
                    (vatArchiveService) => vatArchiveService.BuyerDestinationProfileId === buyerDestinationProfileId
                );
                if (buyerProfileVATArchiveService.length > 0) {
                    buyerProfileVATArchiveService = buyerProfileVATArchiveService[0];
                } else {
                    buyerProfileVATArchiveService = { VATComplianceRequired: false, VATNumber: null };
                }
            } else {
                buyerProfileVATArchiveService = buyerProfileVATArchiveService[0];
            }
        } else {
            buyerProfileVATArchiveService = { VATComplianceRequired: false, VATNumber: null };
        }

        let buyerCompanyVATArchiveService = buyerVATArchiveServiceRecords.filter(
            (vatArchiveService) => !vatArchiveService.BuyerDestinationProfileId && !vatArchiveService.InvoiceProfileId
        );
        if (buyerCompanyVATArchiveService.length > 0) {
            buyerCompanyVATArchiveService = buyerCompanyVATArchiveService[0];
        } else {
            buyerCompanyVATArchiveService = { VATComplianceRequired: false, VATNumber: null };
        }

        defaultVATNumbers.buyerVATNumber = determineDefaultVATNumber(
            buyerProfileVATArchiveService,
            buyerCompanyVATArchiveService
        );

        let supplierProfileVATArchiveService = supplierVATArchiveServiceRecords.filter(
            (vatArchiveService) => vatArchiveService.InvoiceProfileId && !vatArchiveService.BuyerDestinationProfileId
        );
        if (supplierProfileVATArchiveService.length > 0) {
            if (invoiceProfileId) {
                supplierProfileVATArchiveService = supplierProfileVATArchiveService.filter(
                    (vatArchiveService) => vatArchiveService.InvoiceProfileId === invoiceProfileId
                );
                if (supplierProfileVATArchiveService.length > 0) {
                    supplierProfileVATArchiveService = supplierProfileVATArchiveService[0];
                } else {
                    supplierProfileVATArchiveService = { VATComplianceRequired: false, VATNumber: null };
                }
            } else {
                supplierProfileVATArchiveService = supplierProfileVATArchiveService[0];
            }
        } else {
            supplierProfileVATArchiveService = { VATComplianceRequired: false, VATNumber: null };
        }

        let supplierCompanyVATArchiveService = supplierVATArchiveServiceRecords.filter(
            (vatArchiveService) => !vatArchiveService.InvoiceProfileId && !vatArchiveService.BuyerDestinationProfileId
        );
        if (supplierCompanyVATArchiveService.length > 0) {
            supplierCompanyVATArchiveService = supplierCompanyVATArchiveService[0];
        } else {
            supplierCompanyVATArchiveService = { VATComplianceRequired: false, VATNumber: null };
        }

        defaultVATNumbers.supplierVATNumber = determineDefaultVATNumber(
            supplierProfileVATArchiveService,
            supplierCompanyVATArchiveService
        );

        return defaultVATNumbers;
    }
);
