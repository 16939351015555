import axios from 'axios';
import getFullConfig from './getFullConfig';
import { getSessionURL } from './sessionURL';

const registeredSlackEventNames = new Set<string>();

/**
 * Sends a `message` to a slack channel if one is configured to receive notifications
 * for `eventName`
 *
 * Note: The `eventName` must have been registered using `registerSlackEventName` or this
 * function will throw.
 *
 * @param eventName the type of event that occurred. used to control the destination channel.
 * @param message details to send in the message to help understand the context of the message.
 */
export async function sendSlackNotification(eventName: string, message: string) {
    const config = getFullConfig();

    if (!registeredSlackEventNames.has(eventName)) {
        throw new Error(
            `Received unknown eventName ${eventName} in \`sendSlackNotification\`. You must register event names so they can be enumerated for configuration purposes.`
        );
    }

    if (!config) {
        return;
    }

    const channelNames = config.config.slackNotificationEventConfig[eventName];

    if (!channelNames) {
        return;
    }

    const sessionURL = await getSessionURL();

    channelNames.forEach((channelName: string) => {
        const channelWebhookURL = config.config.slackChannelWebhookURLs[channelName];

        if (!channelWebhookURL) {
            return;
        }

        axios.post(
            channelWebhookURL,
            {
                text: `-----------------------------\nEvent: ${eventName}\n\nDetails: ${message}\n\nURL: ${window.location.href}\n\nView Session: ${sessionURL}\n----------------------------------`,
            },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        );
    });
}

/**
 * Register an event name that will be used to send Slack notifications.
 *
 * Event names must be registered so that they can be enumerated by someone
 * maintaining the mapping between event names and Slack channels.
 *
 * The `eventName` should be registered in module scope in the same module
 * that sends the notifications. Doing so makes it easy to maintain event
 * names that are still being used by the application.
 *
 * @param eventName the event name to register
 */
export function registerSlackEventName(eventName: string) {
    registeredSlackEventNames.add(eventName);
}

function showEventNamesIfRequested() {
    if (!window.location.search.includes('showSlackEventNames')) {
        return;
    }

    setTimeout(() => {
        const names = Array.from(registeredSlackEventNames).join(', ');
        // eslint-disable-next-line no-alert
        alert(`Slack event names: ${names}`);
    });
}

showEventNamesIfRequested();
