// NON-LEGACY: This module is non-legacy and should be immediately moved to the new ui core library after #14226
// REFACTOR: Remove all dependencies on legacy components from this component.
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { rejectedFileErrorParser, dropHandler, deleteHandler } from './modules/helpers';
import { FileTypes, IFile, IRequirments } from './modules/types';
import { Modal } from '../../Molecules';
import './Dropzone.scss';
import { AcceptedFile } from './component/AcceptedFile';
import { Button } from '../../Atoms';
import { CloudUpload, Help } from '@mui/icons-material';

export interface IProps {
    acceptedFileTypes: string[];
    primaryInstructions: string;
    dragInstructions: string;
    maxIndividualFileSize: number;
    maxTotalFileSize: number;
    maxLengthFileName: number;
    initialFiles: IFile[];
    fileRequirements?: IRequirments;
    onDropFiles: (acceptedFiles: IFile[]) => void;
    onDeleteFile: (id: number, type, accepted) => void;
    downloadAttachment: (id: number) => void;
}

export const Dropzone: React.FC<IProps> = ({
    acceptedFileTypes,
    primaryInstructions,
    dragInstructions,
    maxIndividualFileSize,
    maxTotalFileSize,
    maxLengthFileName,
    initialFiles = [],
    fileRequirements,
    onDropFiles,
    onDeleteFile,
    downloadAttachment,
}) => {
    const [openFileHelper, setOpenFileHelper] = useState(false);

    const [fileList, setFileList] = useState<IFile[]>(
        initialFiles.map((file) => ({ ...file, fileType: FileTypes.saved }))
    );

    /**
     * It's possible that the component was mounted before the initialFiles
     * were loaded, we need to update the list in that case.
     */
    const [allowInitialFilesLateConcat, setAllowInitialFilesLateConcat] = useState(!initialFiles.length);
    useEffect(() => {
        if (initialFiles.length && allowInitialFilesLateConcat) {
            setAllowInitialFilesLateConcat(false);
            setFileList((prev) => [...prev, ...initialFiles]);
        }
        /**
         * Not adding {initialFiles} to the dependency array
         * to avoid triggering the effect on ref changes.
         */
        // eslint-disable-next-line
    }, [initialFiles.length, allowInitialFilesLateConcat]);

    const onDropAccepted = useCallback(
        (acceptedFiles) => {
            const { files } = dropHandler(fileList, acceptedFiles, maxTotalFileSize, maxLengthFileName);

            setFileList((prevState) => [...prevState, ...files]);

            const accepted = files.filter((f) => f.fileType === FileTypes.accepted);
            onDropFiles(accepted);
        },
        [onDropFiles, fileList, maxLengthFileName, maxTotalFileSize]
    );

    const onDropRejected = useCallback(
        (rejectedFiles) => {
            const rejected: IFile[] = [];

            rejectedFiles.forEach((file) => {
                const errorMsg: string[] = [];
                file.errors.forEach((error) => {
                    const message = rejectedFileErrorParser(
                        error.code,

                        maxIndividualFileSize
                    );
                    errorMsg.push(message || error.message);
                });
                rejected.push({
                    Id: Math.floor(Math.random() * 100),
                    name: file.file.name,
                    size: file.file.size,
                    fileType: FileTypes.rejected,
                    errors: errorMsg,
                });
            });
            setFileList((prevState) => [...prevState, ...rejected]);
        },
        [maxIndividualFileSize]
    );

    function handleDelete(event, id, type) {
        event.preventDefault();

        const newFileList = deleteHandler(fileList, id, maxTotalFileSize, maxLengthFileName);
        const accepted = newFileList.filter((f) => f.fileType === FileTypes.accepted);

        onDeleteFile(id, type, accepted);
        setFileList(newFileList);
    }

    const {
        getRootProps,
        getInputProps,
        open: openDropZone,
        isDragActive,
    } = useDropzone({
        accept: acceptedFileTypes,
        onDropAccepted,
        onDropRejected,
        multiple: true,
        maxSize: maxIndividualFileSize,
        noClick: true,
        noKeyboard: true,
    });

    const open = () => {
        // Only used for testing Backlog Item #17091
        if ((window as any).transceptaTestDropzone17091 === 'enabled') {
            // eslint-disable-next-line
            alert('test dropzone click');
            return;
        }

        openDropZone();
    };

    return (
        // This is the proper use of the react-dropzone library
        /* eslint-disable react/jsx-props-no-spreading */
        <section className="core-dropzone">
            <div {...getRootProps({ className: 'dropzone' })}>
                <input id="input-dropzone" {...getInputProps()} />
                <div
                    className={`primary-instructions ${isDragActive ? 'drag-active' : ''}`}
                    onClick={(e) => {
                        if (e.target === e.currentTarget) {
                            open();
                        }
                    }}
                >
                    <CloudUpload onClick={open} />
                    {isDragActive ? (
                        <p>{dragInstructions}</p>
                    ) : (
                        <>
                            <h3 className="primary-message">
                                {primaryInstructions}
                                {fileRequirements && (
                                    <>
                                        {' '}
                                        <Modal
                                            testId="instructions-modal"
                                            isOpen={openFileHelper}
                                            closeIcon
                                            type={'warning'}
                                            isWide={false}
                                            scrollTop={false}
                                            renderTitle={fileRequirements.title}
                                            renderBody={fileRequirements.body}
                                            handleClose={() => setOpenFileHelper(false)}
                                        />
                                        <span
                                            data-testid="question-icon"
                                            className="quesiton-icon"
                                            onClick={() => setOpenFileHelper(!openFileHelper)}
                                            role="button"
                                            tabIndex={0}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    setOpenFileHelper(!openFileHelper);
                                                }
                                            }}
                                        >
                                            <Help />
                                        </span>
                                    </>
                                )}
                            </h3>

                            <Button id="button-browse-files" onClick={() => open()}>
                                Or Browse Files
                            </Button>
                        </>
                    )}
                </div>
            </div>
            {fileList.length > 0 && (
                <aside className="files">
                    {fileList.map((savedFile) => {
                        return (
                            <AcceptedFile
                                key={savedFile.Id}
                                Id={savedFile.Id}
                                name={savedFile.name}
                                FileSize={savedFile.FileSize}
                                size={savedFile.size}
                                fileType={savedFile.fileType}
                                errors={savedFile.errors}
                                onDeleteFile={handleDelete}
                                preview={savedFile.preview}
                                downloadAttachment={downloadAttachment}
                            />
                        );
                    })}
                </aside>
            )}
        </section>
    );
};
