import { Typography } from '@mui/material';
import { IExternalErrorInfo } from './IExternalErrorInfo';
import errorMessages from './errorMessages';
import { EmbedHTML, formatSafeHTMLString } from '../../utils';
import { Alert } from '../../ui';

export interface IFriendlyErrorMessageProps {
    externalErrorInfo: IExternalErrorInfo;
    testId: string;
}

export function FriendlyErrorMessage({ externalErrorInfo, testId }: IFriendlyErrorMessageProps) {
    // Note: we format the message in the compoonent we use to display it because this will let us integrate
    // with our i18n library later easily. see ticket #14092, which requires coming up with a solution
    // to integrate SafeHTML and our future i18n library.
    const errorMessage = formatSafeHTMLString(errorMessages[externalErrorInfo.errorClass], {
        errorCode: externalErrorInfo.errorCode,
    });

    return (
        <Alert severity="error" testId={testId}>
            <EmbedHTML
                component={Typography}
                variant="body2"
                data-testid={`${testId}--StatusText`}
                safeHTMLString={errorMessage}
            />
        </Alert>
    );
}
