/* eslint-disable import/prefer-default-export */
import { push } from 'connected-react-router';
import * as UserActions from './user';
import * as UserLoginActions from './userlogin';
import R from '../routes';
import { pushAlert } from './alerts';
import { parseMessage } from '../utils';
import { path } from 'ramda';
import * as DocumentActions from './document/documentActions';
import * as documentCreationActions from '../actions/document/documentCreationActions';
import { strings } from '../utils/localization/LocalizationStrings';
import pathsConfig from '../paths.config.json';

export const API_ERROR = 'API_ERROR';

/**
 * This function checks API server errors
 * Use it to dispatch all errors, except auth errors
 *
 * @param {Object} err Error, axios answer from server
 * @param {String} eventType Event type to dispatch
 * @param {extraPayload} object payload, that will be added to the result object
 */
export function apiErrorAction(err, eventType, extraPayload = {}, dispatchAlert = true) {
    return (dispatch, getState) => {
        console.warn(`${eventType} error: ${JSON.stringify(err, false, 4)}`);
        // default message body
        const state = getState();
        let result = [];
        let isNetworkError = false;

        if (err.message === 'Network Error' || err.message === 'timeout of 125000ms exceeded') {
            dispatch(
                pushAlert({
                    type: 'error',
                    text: "Network error. Can't connect to Transcepta server. Please, check your connection.",
                })
            );

            isNetworkError = true;
            dispatchAlert = false;
        } else if (err.Message === 'Incorrect password.') {
            result.push({ Code: err.Code, Message: strings.textBadOldPassword });
        } else if (err.response && err.response.data && err.response.data.errors) {
            result = err.response.data.errors;
        } else if (err.response && err.response.status && err.response.statusText) {
            result.push({ Code: err.response.status, Message: err.response.statusText });
        } else if (err === strings.textAPIVaidationFailureMessage) {
            result.push({ Code: 0, Message: err });
            dispatchAlert = false;
            dispatch(
                pushAlert({
                    type: 'error',
                    text: strings.textAPIVaidationFailureMessage,
                })
            );
        } else if (err.Code === 22) {
            err.Message = strings.textTimeoutError;
        }

        if (result.length === 0) {
            result.push({
                Code: 'internal_error',
                text: `Error: "${err.message}"`,
            });
        }
        //400 is all non login unauthorized responses from the API
        if (result.find((v) => +v.Code === 1) && err.response.status === 400) {
            let location = window.location.href;
            let newLocation = location.substring(0, location.lastIndexOf('/'));
            window.location.href = newLocation;
        }
        //403 is all login/bad token unauthorized responses from the API
        if (result.find((v) => +v.Code === 1) && err.response.status === 403) {
            const {
                router: {
                    location: { pathname },
                },
            } = state;
            if (pathname !== `${pathsConfig.ROOT_URL}/logout`) {
                dispatch(UserLoginActions.userTimeout(true, pathname));
            }
            dispatch(UserLoginActions.userLogout({ timeout: true }));
            dispatch(push(R.LOGOUT_PAGE.path));
        }
        let resultCopy = { ...result };
        if (eventType) {
            switch (eventType) {
                case UserActions.CHANGE_PASSWORD_FAILED:
                    dispatchAlert = false;
                    dispatch(
                        pushAlert({
                            type: 'error',
                            text: result[0].Message,
                        })
                    );
                    break;
                case DocumentActions.DOCUMENT_VALIDATION_FETCH_ERROR:
                case documentCreationActions.SAVE_DOCUMENT_DRAFT_FAILURE:
                    if (resultCopy[0].Code === 16) {
                        // Lloyd has requested that we add "(validation error)" after the validation error message to help guide our support team.
                        // Since this is for our support team it shouldn't need to be localized.
                        resultCopy[0].text = strings.textValidationError16 + ' (validation error)';
                    }
                    dispatch({
                        type: eventType,
                        payload: {
                            errors: resultCopy,
                            isNetworkError,
                            ...extraPayload,
                        },
                    });
                    break;
                case DocumentActions.CANCEL_DOCUMENT_FAILURE:
                    if (resultCopy[0].Code === 19) {
                        resultCopy[0].text = strings.textError19;
                    }
                    dispatch({
                        type: eventType,
                        payload: {
                            errors: resultCopy,
                            isNetworkError,
                            ...extraPayload,
                        },
                    });
                    break;
                case UserActions.SAVE_USER_FAILED: {
                    if (resultCopy[0].Code === 11) {
                        dispatchAlert = false;
                        dispatch(
                            pushAlert({
                                type: 'error',
                                text: strings.textUserNameOrEmailAlreadyExists,
                            })
                        );
                    }
                    break;
                }
                default:
                    dispatch({
                        type: eventType,
                        payload: {
                            errors: result,
                            isNetworkError,
                            ...extraPayload,
                        },
                    });
                    break;
            }
        }

        if (dispatchAlert) {
            dispatch(
                pushAlert({
                    type: 'error',
                    text: parseMessage(path(['response', 'data', 'errors'], err)),
                })
            );
        }
    };
}
