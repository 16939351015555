import { IDropdownOption } from '../../proservContractTypes';

class DropdownOptionsRepository {
    private options = new Map<string, Promise<IDropdownOption[]>>();

    private deferredResolvers = new Map<string, (options: IDropdownOption[] | Promise<IDropdownOption[]>) => void>();

    setDropdownOptions(fieldKey: string, options: Promise<IDropdownOption[]> | IDropdownOption[]): void {
        this.resolveDeferredIfNeeded(fieldKey, options);

        this.options.set(fieldKey, Promise.resolve(options));
    }

    getLatestDropdownOptions(fieldKey: string): Promise<IDropdownOption[]> {
        this.createDeferredIfNeeded(fieldKey);

        return this.options.get(fieldKey)!;
    }

    /**
     * The options may not have started being fetched when the validation function executes.
     *
     * In this case, we store a promise for the options as well as a function to resolve the promise later.
     *
     * When the options are set later on, we will resolve this promise if we find that we have stored a function
     * to resolve it.
     *
     * Doing this will allow "suspending" the validation function until the options become available.
     */
    private createDeferredIfNeeded(fieldKey: string) {
        if (!this.options.get(fieldKey)) {
            this.options.set(
                fieldKey,
                new Promise((res) => {
                    this.deferredResolvers.set(fieldKey, res);
                })
            );
        }
    }

    /**
     * See `createDeferredIfNeeded`. Here is where we resolve the promises it creates.
     */
    private resolveDeferredIfNeeded(fieldKey: string, options: Promise<IDropdownOption[]> | IDropdownOption[]) {
        const res = this.deferredResolvers.get(fieldKey);
        if (!res) {
            return;
        }

        // no need to do this again
        this.deferredResolvers.delete(fieldKey);

        res(options);
    }
}

export default DropdownOptionsRepository;
