import { ReactNode } from 'react';
import { CreateAuthService, useAuthToken } from './Auth';
import { FetchUserProfileThenShowPortal } from './FetchUserProfileThenShowPortal';
import { LoginPagesRouter } from './LoginPagesRouter';
import { FetchUserProfile } from './FetchUserProfile';

export interface ILoginPagesProps {
    marketingContent?: ReactNode;
    fetchUserProfile: FetchUserProfile;
    portal: ReactNode;
    isDefaultSSO: boolean;
}

export const LoginPages = CreateAuthService.inside<ILoginPagesProps>(
    ({ marketingContent, fetchUserProfile, portal, isDefaultSSO }: ILoginPagesProps) => {
        const authToken = useAuthToken();
        if (!authToken) {
            return <LoginPagesRouter marketingContent={marketingContent} isDefaultSSO={isDefaultSSO} />;
        }

        return (
            <FetchUserProfileThenShowPortal
                marketingContent={marketingContent}
                fetchUserProfile={fetchUserProfile}
                portal={portal}
                userViewModel={authToken}
            />
        );
    }
);
