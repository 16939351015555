import { FCNC } from '../FCNC';
import { FormButtons } from './FormButtons';
import { Button } from '../Button';
import { IActionButton } from './IActionButton';

export interface ICRUDButtonsProps {
    submitButton: IActionButton;
    cancelButton: IActionButton;
    deleteButton?: IActionButton | null;
    testId?: string;
}

export const CRUDButtons: FCNC<ICRUDButtonsProps> = ({ submitButton, cancelButton, deleteButton, testId }) => {
    return (
        <FormButtons
            leftButtons={
                deleteButton && (
                    <Button color="error" onClick={deleteButton.onClick} testid={`${testId}-delete-btn`}>
                        {deleteButton.text}
                    </Button>
                )
            }
            rightButtons={
                <>
                    <Button variant="secondary" testid={`${testId}-cancel-btn`} onClick={cancelButton.onClick}>
                        {cancelButton.text}
                    </Button>
                    <Button type="submit" disabled={submitButton.isDisabled} testid={`${testId}-submit-btn`}>
                        {submitButton.text}
                    </Button>
                </>
            }
        />
    );
};
