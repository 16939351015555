import { RuntimeRuleFetcher } from '../../CoreEngine/Utility/RuntimeRuleFetcher';
import { DPSValidationEnvironment } from './DPSValidationEnvironment';
import { ExecutionContext } from '../../../../services/backendServices/ViewModels';
import { api } from '../../../../services/api';
import { RuntimeRule } from '../../CoreEngine/Utility/RuntimeRule';
import { ViewModels } from '../../../../services/backendServices';
import DropdownOptionsRepository from '../../../DocumentEditRules/PluginHost/services/dropdownOptionsRepository';
import { DocumentValidationTouchpoint } from './DocumentValidationTouchpoint';
import { UIDocumentControllerTouchPoint } from './UIDocumentControllerTouchPoint';
import { CustomJSEngine } from './CustomJSEngine';
import { Touchpoint } from '../../ScriptingEngineEnvironment';
import associatedFieldService from '../../../DocumentEditRules/PluginHost/services/associatedFieldService';
import { MetadataExtractorEnvironment } from '../../MetadataExtractor';

const filterMappingByRunInWorkflowEngine = (mapping: ViewModels.BuyerCustomJSRuleMappingViewModel) => {
    const workflowCtx = mapping.AllowedExecutionContexts?.ExecutionContext.Context.find(
        (ctx) => ctx.Type === 'InWorkflowOrWorkflowActivity'
    );

    // Checking the context type twice because `.find()` does not narrow down the type
    if (workflowCtx == null || workflowCtx.Type !== 'InWorkflowOrWorkflowActivity') {
        return false;
    }

    if (workflowCtx.RunInWorkflowEngine !== '1') {
        return false;
    }

    return true;
};

interface CreateEngineConfig {
    context: ExecutionContext;
    buyerCompanyId: number;
    runAfterDPSRule?: boolean;
    dpsRuleName?: string;
    workflowId?: number;
    workflowActivityId?: number;
    senderCompanyId?: number;
    vendorClass?: string;
    rulesFromClient?: RuntimeRule[];
    inWorkflowEngine?: boolean;
    dropdownRepository?: DropdownOptionsRepository;
    createCachingService: (scriptName: string) => Touchpoint.Caching.CachingService;
    parentCompanyId?: number;
    writeToLog?: (message: string) => void;
}

export async function createEngine({
    context,
    buyerCompanyId,
    runAfterDPSRule,
    dpsRuleName,
    workflowId,
    workflowActivityId,
    senderCompanyId,
    vendorClass,
    rulesFromClient,
    inWorkflowEngine = false,
    dropdownRepository,
    createCachingService,
    parentCompanyId,
    writeToLog,
}: CreateEngineConfig): Promise<CustomJSEngine> {
    const rules = await (async () => {
        if (
            (context === ExecutionContext.UserWorkflowOrWorkflowActivity && !inWorkflowEngine) ||
            context === ExecutionContext.WebEntry
        ) {
            if (!rulesFromClient) {
                throw new Error('Tried to create JS engine before rules were available');
            }

            return rulesFromClient;
        }

        const ruleFetcher = new RuntimeRuleFetcher();
        const fetchedRules = await ruleFetcher.fetchRules(
            {
                engineVersion: 2,
                buyerCompanyId,
                executionContext: context,
                runAfterDPSExecuteMethod: runAfterDPSRule,
                dpsRuleName,
                workflowId,
                workflowActivityId,
                vendorClass,
            },
            undefined,
            inWorkflowEngine ? filterMappingByRunInWorkflowEngine : undefined
        );

        return fetchedRules;
    })();

    const documentValidation = new DocumentValidationTouchpoint(
        context,
        api,
        buyerCompanyId,
        senderCompanyId,
        parentCompanyId,
        writeToLog
    );

    const uiDocumentController = new UIDocumentControllerTouchPoint(
        context,
        api,
        buyerCompanyId,
        senderCompanyId,
        parentCompanyId
    );

    // find all libraries, compile them with just the MetadataExtractorEnvironment just to extract the functions
    // and then concatenate those functions into one object. This object will be passed to all other rules
    const libraryEnv = new MetadataExtractorEnvironment();
    let concatenatedLibraryFunctions = {};
    const libraryRules = rules.filter((rule) => rule.isLibrary);
    for (const libraryRule of libraryRules) {
        try {
            const libraryFunctions = libraryRule.script(libraryEnv);
            concatenatedLibraryFunctions = { ...concatenatedLibraryFunctions, ...libraryFunctions };
        } catch (e) {
            throw new Error(`Error compiling library rule ${libraryRule.name}: ${e}`);
        }
    }

    for (const rule of rules.filter((r) => !r.isLibrary)) {
        try {
            documentValidation.setCurrentScript(rule);
            uiDocumentController.setCurrentScript(rule);

            const cachingService = createCachingService(rule.name);

            const environment = new DPSValidationEnvironment(
                context,
                api,
                buyerCompanyId, // if we are in a multi-company scenario, this will be the child company
                dropdownRepository,
                documentValidation,
                uiDocumentController,
                { caching: cachingService, associatedFieldService },
                concatenatedLibraryFunctions,
                parentCompanyId
            );

            rule.script(environment);
        } catch (e) {
            throw new Error(`Error compiling rule ${rule.name}: ${e}`);
        }
    }

    documentValidation.completeRegistration();

    uiDocumentController.completeRegistration();

    return new CustomJSEngine(documentValidation, uiDocumentController, context);
}
