import { createSelector } from 'reselect';

import { cloneObjectHack } from '../../utils/dataConverter';

const stateList = (state) => (state.userlogin ? cloneObjectHack(state.userlogin.stateList) : []);

export default createSelector(stateList, (stateList) => {
    return stateList.map((state) => {
        return {
            label: state.Name,
            value: state.Code,
            countryCode: state.Country,
        };
    });
});
