import { combineReducers } from 'redux';
import { createDocumentReducerThatReCalculatesTotalsCorrectly } from 'common';

import attachments from './attachments';
import customerLocation from './customerLocation';
import documentCreationFields from './documentCreationFields';
import documentSkeleton from './documentSkeleton';
import editLayout from './editLayout';
import fixedMiscAmountCount from './fixedMiscAmountCount';
import flip from './flip';
import invoiceCreationFields from './invoiceCreationFields';
import originalStateInfo from './originalStateInfo';
import status from './status';
import simRequestInfo from './simRequestInfo';

export default combineReducers({
    attachments: attachments,
    customerLocation: customerLocation,
    documentCreationFields: createDocumentReducerThatReCalculatesTotalsCorrectly(documentCreationFields),
    documentSkeleton: documentSkeleton,
    editLayout: editLayout,
    fixedMiscAmountCount: fixedMiscAmountCount,
    flip: flip,
    invoiceCreationFields: createDocumentReducerThatReCalculatesTotalsCorrectly(invoiceCreationFields),
    originalStateInfo: originalStateInfo,
    status: status,
    simRequestInfo: simRequestInfo,
});
