import { throttle } from 'lodash';
import Cache from './Cache';
import IPersistor from './IPersistor';

/**
 * Persists the cache to persistent storage. This allows cached
 * data to survive a page refresh.
 *
 * The cache is hydrated once immediately when this is called. Since hydration is asynchronous,
 * entries that come back from the persistor are only stored in the cache if there is not already
 * an entry for that key (eg, this could happen if a web response populated a cache key before
 * hydration finished; we would want to store the response, not hydrated entry, which is what
 * we do).
 *
 * The cache will be persisted whenever it is mutated (entries are updated / removed). To prevent
 * persisting too often, the persistence is throttled by `persistThrottleInMS`.
 *
 * @param cache the cache to persist
 * @param persistor the object that persists the cache to persistent storage
 * @param persistThrottleInMS throttle on persistence frequency
 */
export default function persistCacheInBackground(cache: Cache, persistor: IPersistor, persistThrottleInMS: number) {
    async function hydrate() {
        const entries = await persistor.hydrate();
        cache.setEntries(entries);
    }

    async function persist() {
        const entries = cache.getEntries();
        await persistor.persist(entries);
    }

    cache.subscribe(throttle(persist, persistThrottleInMS));

    // hydrate once at startup
    hydrate();
}
