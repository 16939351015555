export function amountFormatter(num: number, isMoneyAmount = false) {
    let ret: string;
    if (Math.abs(num) >= 1e12) {
        ret = (Math.round((num / 1e12) * 100) / 100).toString() + 'T';
    } else if (Math.abs(num) >= 1e9) {
        ret = (Math.round((num / 1e9) * 100) / 100).toString() + 'B';
    } else if (Math.abs(num) >= 1e6) {
        ret = (Math.round((num / 1e6) * 100) / 100).toString() + 'M';
    } else if (Math.abs(num) >= 1e3) {
        ret = (Math.round((num / 1e3) * 100) / 100).toString() + 'K';
    } else {
        ret = (Math.round(num * 100) / 100).toString();
    }
    return `${isMoneyAmount ? '$' : ''}` + ret;
}
