import * as simDocumentTypesActions from '../actions/simDocumentTypesActions';
import { applySortingHelper } from './helpers';
import projectConfig from '../project.config.json';
import { USER_LOGIN_FAILED, USER_LOGIN_PENDING, USER_LOGIN_SUCCESS, USER_LOGOUT } from '../actions/userlogin';

const initialState = {
    all: {
        isLoading: false,
        items: [],
        skip: 0,
        sorting: { column: 'DocumentClass_US', direction: 'desc', type: 'String' },
    },
    simDocumentType: {
        isLoading: false,
        id: 0,
        Description: '',
        DocumentClass: null,
        SupplierSelection: null,
        DataEntrySchedule: null,
        AttachmentOption: '',
        ApprovalWorkflow: false,
        DoNotDeliver: false,
        //ReminderEmailDestination: projectConfig.emailDestinations['Sales Department'], // Default
        SIMDocumentTypeVendorClasses: [],
        SIMDocumentTypeSupplierInclusions: [],
        SIMDocumentTypeSupplierExclusions: [],
        SIMDocumentTypeReminders: [],
    },
    suppliers: {
        isLoading: false,
        items: [],
    },
    vendorClasses: {
        isLoading: false,
        items: [],
    },
    SIMRequests: {
        isLoading: false,
        items: [],
    },
};

export const simDocumentTypes = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case simDocumentTypesActions.SIM_DOCUMENT_TYPE_REMOVE_REMINDER: {
            return {
                ...state,
                simDocumentType: {
                    ...state.simDocumentType,
                    SIMDocumentTypeReminders: state.simDocumentType.SIMDocumentTypeReminders.filter(
                        (s) => s.Id !== payload
                    ),
                },
            };
        }

        case simDocumentTypesActions.SIM_DOCUMENT_TYPE_FETCHED: {
            return {
                ...state,
                simDocumentType: {
                    ...payload,
                    isLoading: false,
                },
            };
        }

        case simDocumentTypesActions.SIM_DOCUMENT_TYPE_CLEAR_SELECTION: {
            return {
                ...state,
                simDocumentType: {
                    ...state.simDocumentType,
                    SIMDocumentTypeSupplierInclusions: [],
                    SIMDocumentTypeVendorClasses: [],
                },
            };
        }

        case simDocumentTypesActions.SIM_DOCUMENT_TYPE_FETCHING_FAILED: {
            return {
                ...state,
                simDocumentType: initialState.simDocumentType,
            };
        }
        case simDocumentTypesActions.SIM_DOCUMENT_TYPE_FETCHING: {
            return {
                ...state,
                simDocumentType: {
                    ...state.simDocumentType,
                    isLoading: true,
                },
            };
        }

        case simDocumentTypesActions.SIM_DOCUMENT_TYPE_UPDATE: {
            const { fieldName, value } = payload;
            return {
                ...state,
                simDocumentType: {
                    ...state.simDocumentType,
                    [fieldName]: value,
                },
            };
        }

        case simDocumentTypesActions.SIM_DOCUMENT_TYPE_SUPPLIER_REMOVE: {
            return {
                ...state,
                simDocumentType: {
                    ...state.simDocumentType,
                    SIMDocumentTypeSupplierInclusions: state.simDocumentType.simDocumentTypeSupplierInclusions.filter(
                        (s) => s.CustomerVendorNumber !== payload
                    ),
                },
            };
        }

        case simDocumentTypesActions.SIM_DOCUMENT_TYPE_INITIALIZE: {
            return {
                ...state,
                simDocumentType: initialState.simDocumentType,
            };
        }

        case simDocumentTypesActions.SIM_DOCUMENT_TYPE_SUPPLIER_ADD: {
            return {
                ...state,
                simDocumentType: {
                    ...state.simDocumentType,
                    SIMDocumentTypeSupplierInclusions: payload,
                },
            };
        }

        case simDocumentTypesActions.SIM_DOCUMENT_TYPE_EXEMPT_SUPPLIER_REMOVE: {
            return {
                ...state,
                simDocumentType: {
                    ...state.simDocumentType,
                    SIMDocumentTypeSupplierExclusions: state.simDocumentType.simDocumentTypeSupplierExclusions.filter(
                        (s) => s.CustomerVendorNumber !== payload
                    ),
                },
            };
        }

        case simDocumentTypesActions.SIM_DOCUMENT_TYPE_EXEMPT_SUPPLIER_ADD: {
            return {
                ...state,
                simDocumentType: {
                    ...state.simDocumentType,
                    SIMDocumentTypeSupplierExclusions: payload,
                },
            };
        }

        case simDocumentTypesActions.FAILED_SIM_DOCUMENT_TYPES: {
            return {
                ...state,
                all: { ...initialState.all },
            };
        }

        case simDocumentTypesActions.FETCHING_SIM_DOCUMENT_TYPES: {
            return {
                ...state,
                all: {
                    ...state.all,
                    isLoading: true,
                },
            };
        }
        case simDocumentTypesActions.FETCHED_SIM_DOCUMENT_TYPES: {
            return {
                ...state,
                all: {
                    ...state.all,
                    isLoading: false,
                    items: payload,
                },
            };
        }

        case simDocumentTypesActions.SIM_DOCUMENT_TYPES_APPLY_SORTING: {
            return {
                ...state,
                all: {
                    ...applySortingHelper(state.all, payload),
                },
            };
        }

        case simDocumentTypesActions.SIM_DOCUMENT_TYPE_SUPPLIER_SEARCH_FETCHED: {
            return {
                ...state,
                suppliers: {
                    ...state.suppliers,
                    isLoading: false,
                    items: payload,
                },
            };
        }

        case simDocumentTypesActions.SIM_DOCUMENT_TYPE_SUPPLIER_SEARCH_FAILED: {
            return {
                ...initialState.suppliers,
            };
        }

        case simDocumentTypesActions.SIM_DOCUMENT_TYPE_SUPPLIER_SEARCH_FETCHING: {
            return {
                ...state,
                suppliers: {
                    ...state.suppliers,
                    isLoading: true,
                    items: [],
                },
            };
        }

        case simDocumentTypesActions.SIM_DOCUMENT_TYPE_SUPPLIER_FETCHING_FAILED: {
            return {
                ...state,
                suppliers: { ...initialState.suppliers },
            };
        }

        case simDocumentTypesActions.SIM_DOCUMENT_TYPE_VENDOR_CLASSES_FETCHED: {
            return {
                ...state,
                vendorClasses: {
                    ...state.vendorClasses,
                    isLoading: false,
                    items: payload,
                },
            };
        }

        case simDocumentTypesActions.SIM_DOCUMENT_TYPE_VENDOR_CLASSES_FAILED: {
            return {
                ...state,
                vendorClasses: initialState.vendlorClasses,
            };
        }

        case simDocumentTypesActions.SIM_DOCUMENT_TYPE_VENDOR_CLASSES_FETCHING: {
            return {
                ...state,
                vendorClasses: {
                    ...state.vendorClasses,
                    isLoading: true,
                    items: [],
                },
            };
        }

        case simDocumentTypesActions.SIM_DOCUMENT_TYPE_VENDOR_CLASS_ADD: {
            return {
                ...state,
                simDocumentType: {
                    ...state.simDocumentType,
                    SIMDocumentTypeVendorClasses: payload,
                },
            };
        }

        case simDocumentTypesActions.SIM_REQUEST_FETCHING: {
            const { timeout, ...currentState } = state;
            return timeout && timeout === true
                ? currentState
                : {
                      ...state,
                      SIMRequests: {
                          items: [],
                          isLoading: true,
                      },
                  };
        }

        case simDocumentTypesActions.SIM_REQUEST_FETCHING_FAILED: {
            return {
                ...state,
                SIMRequests: initialState.SIMRequests,
            };
        }

        case simDocumentTypesActions.SIM_REQUEST_FETCHED: {
            return {
                ...state,
                SIMRequests: {
                    isLoading: false,
                    items: payload,
                },
            };
        }
        case USER_LOGIN_FAILED:
        case USER_LOGIN_PENDING: {
            // if it was timeout return currenState, otherwhise initialState
            const { timeout } = state;
            return timeout && timeout === true ? state : initialState;
        }
        case USER_LOGIN_SUCCESS: {
            // if it was timeout return currenState, otherwhise initialState
            // remove timeout flag
            const { timeout, ...currentState } = state;
            return timeout && timeout === true ? currentState : initialState;
        }
        case USER_LOGOUT: {
            const { timeout } = payload;
            return timeout && timeout === true ? { ...state, timeout } : initialState;
        }
        default:
            return state;
    }
};
