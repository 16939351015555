import axios from 'axios';
import projectConfig from './project.config.json';
import { optimizeApi } from '../../services/utils/optimizeApi';

const apiConfig = {
    baseURL: '/v1.0/api',
    timeout: 125000,
};

function createApi() {
    return axios.create({
        ...apiConfig,
        headers: {
            Authorization: `Bearer ${window.localStorage.access_token ? window.localStorage.access_token : ''}`,
        },
    });
}

export function api() {
    return optimizeApi(createApi());
}

export const parseMessage = (errArr) =>
    errArr &&
    errArr.map((e) => [
        <span key="1">
            {projectConfig.errorMessages[e.Code]
                ? `${projectConfig.errorMessages[e.Code]} (${e.Code})`
                : `${e.Message} (${e.Code})`}
        </span>,
    ]);

export default api;
