import { IWorkflowActivityExitFlowViewModel } from './WorkflowActivityExitFlowViewModel';

import { RoleType } from '../../../UserService/Api';
import { WorkflowApprovalType } from './WorkflowViewModel';

export interface IWorkflowActivityViewModel {
    Id: number;
    WorkflowId: number;
    Name: string;
    Step: number;
    ActivityType: WorkflowActivityType;
    ActivityType_US?: string;
    ActivityJavaScriptFilePath: string | null;
    Service: string;
    SetupLogicType: LogicType | null; // Supported values: Store Procedure and Store Procedure Instance Params
    SetupLogicType_US?: string;
    SetupLogic: string;
    SetupJavaScriptFilePath: string | null;
    ActivityLogicType: LogicType | null; // Supported values: Store Procedure and Store Procedure Instance Params
    ActivityLogicType_US?: string;
    ActivityLogic: string;
    EndEventResult: WorkflowResult | null;
    EndEventResult_US?: string;
    ReassignmentRule: WorkflowReassignmentRule | null;
    ReassignmentRule_US?: string;
    TimeoutCount: number | null;
    TimeoutUnits: WorkflowActivityTimeoutUnit | null;
    TimeoutUnits_US?: string;
    RoleTypeId: RoleType | null;
    WorkflowFieldDictionaryTemplateId: number | null;
    Reprocess: string | null; // XML Data Structure
    CustomData: string | null; // XML Data Structure - WorkflowActivityConfiguration
    GlCodingEditability: boolean;
    DocumentEditActive: boolean;
    DocumentLineAddDeleteActive: boolean;
    ApprovalType: WorkflowApprovalType | null;
    ApprovalType_US?: string;
    DefaultApproverUserId: number;
    DefaultApproverUserGroupId: number;
    DefaultApproverUserEmail: string;
    WorkflowAdminUserGroupName: string;
    WorkflowActivityExitFlows: IWorkflowActivityExitFlowViewModel[];
    LastUpdateDate?: string;
    Rowversion?: string;
}

export enum WorkflowActivityType {
    Undefined,
    'Start Event',
    Script,
    User,
    Manual,
    Send,
    Receive,
    'Business Rule',
    'Exclusive Gateway',
    'Event Based Gateway',
    'Parallel Gateway',
    'Parallel Event Based Gateway',
    'Inclusive Gateway',
    'Complex Gateway',
    'End Event',
    Service,
}

export enum LogicType {
    Undefined,
    SQLScript,
    SQLStatement,
    'Stored Procedure',
    VBScript,
    'Stored Procedure Instance Params',
    JavascriptCode,
}

export enum WorkflowResult {
    'Undefined' = 0,
    'Continue' = 1,
    'Cancel' = 2,
    'Research' = 3,
    'Next Workflow' = 4,
    'Fail' = 5,
}

export enum WorkflowReassignmentRule {
    Undefined,
    'No Reassignment Allowed',
    'Reassign to Manager',
    'Reassign to Direct Report',
    'Reassign to Manager or Direct Report',
    'Reassign to User Group',
    'Reassign to Group and Clear',
    'Reassign to All and Clear',
    'Reassign to All Users',
}

export enum WorkflowActivityTimeoutUnit {
    Undefined,
    Hours,
    Days,
    'Business Days',
}
