// NON-LEGACY: This module is non-legacy
// REFACTOR: Move this to the buyer portal since it is not common code
import { FC, useEffect, useState } from 'react';
import { BusinessDocType, IDocument, ITradingPartners, IUser } from '../../../transcepta-types';
import { WorkflowUtil } from '../../../transcepta-common';
import { Button } from '../../Atoms/Buttons';
import { CircularProgress } from '@mui/material';

interface ILinkText {
    view: string;
    viewInNewWindow: string;
    download: string;
    copy: string;
    delete: string;
}

interface IProps {
    isBuyerPortal: boolean;
    document: IDocument;
    showModalPDF: () => Promise<void>;
    showPDFInNewWindow: () => Promise<void>;
    downloadAction: (documentId: number, businessDocType: BusinessDocType, isDraft?: boolean) => Promise<void>;
    copyAction: (documentId: number, businessDocType: BusinessDocType) => void;
    deleteAction: (documentId: number, businessDocType: BusinessDocType, isDraft?: boolean) => void;
    isDraft: boolean;
    tradingPartner: ITradingPartners;
    user: IUser;
    linkText: ILinkText;
}

const OtherActions: FC<IProps> = ({
    isBuyerPortal,
    document,
    downloadAction,
    linkText,
    copyAction,
    deleteAction,
    isDraft = false,
    showModalPDF,
    showPDFInNewWindow,
    tradingPartner,
    user,
}) => {
    const [isCopyLinkVisible, setIsCopyLinkVisible] = useState(false);
    const [isDownloadLinkVisible, setIsDownloadLinkVisible] = useState(false);
    const [isDeleteLinkVisible, setIsDeleteLinkVisible] = useState(false);
    const [isOtherActionsVisible, setIsOtherActionsVisible] = useState(false);
    const [draftBusinessDocId, setDraftBusinessDocId] = useState(WorkflowUtil.getDraftBusinessDocId(document, isDraft));
    const [downloadId, setDownloadId] = useState(isDraft ? draftBusinessDocId : document.Id);
    const [isLoadingDownloadDocument, setIsLoadingDownloadDocument] = useState(false);
    const [isLoadingViewDocument, setIsLoadingViewDocument] = useState(false);
    const [isLoadingViewNewWindowDocument, setIsLoadingViewNewWindowDocument] = useState(false);
    // always run this effect is the desired behavior
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setIsDownloadLinkVisible(
            isBuyerPortal
                ? WorkflowUtil.isDocumentAvailableInArchive(document)
                : WorkflowUtil.isDocumentAvailableToDownload(document, isDraft)
        );
        setIsCopyLinkVisible(
            isBuyerPortal ? false : WorkflowUtil.isDocumentAvailableToCopy(document, isDraft, tradingPartner, user)
        );
        setIsDeleteLinkVisible(isBuyerPortal ? false : WorkflowUtil.isDocumentAvailableToDelete(isDraft, user));
        setIsOtherActionsVisible(isDownloadLinkVisible || isCopyLinkVisible || isDeleteLinkVisible);
        setDraftBusinessDocId(WorkflowUtil.getDraftBusinessDocId(document, isDraft));
        setDownloadId(isDraft ? draftBusinessDocId : document.Id);
    });

    const handleDownloadLink = async () => {
        try {
            setIsLoadingDownloadDocument(true);
            if (isBuyerPortal) {
                await downloadAction(document.Id, document.BusinessDocType);
            } else {
                await downloadAction(downloadId ?? -1, document.BusinessDocType, isDraft);
            }
            setIsLoadingDownloadDocument(false);
        } catch (error) {
            setIsLoadingDownloadDocument(false);
        }
    };

    const handleViewLink = async () => {
        try {
            setIsLoadingViewDocument(true);
            await showModalPDF();
            setIsLoadingViewDocument(false);
        } catch (error) {
            setIsLoadingViewDocument(false);
        }
    };

    const handleViewNewWindowLink = async () => {
        try {
            setIsLoadingViewNewWindowDocument(true);
            await showPDFInNewWindow();
            setIsLoadingViewNewWindowDocument(false);
        } catch (error) {
            setIsLoadingViewNewWindowDocument(false);
        }
    };

    const renderDownloadLink = () => {
        if (!isDownloadLinkVisible) {
            return null;
        }

        return isBuyerPortal ? (
            <div className="document-footer-bordered-row">
                <Button variant="text" onClick={handleViewLink} id={'view-btn'} disabled={isLoadingViewDocument}>
                    {linkText.view}
                    {isLoadingViewDocument && (
                        <CircularProgress
                            sx={{
                                position: 'absolute',
                                alignSelf: 'center',
                            }}
                        />
                    )}
                </Button>
                <br />
                <Button
                    variant="text"
                    onClick={handleViewNewWindowLink}
                    id={'view-new-window-btn'}
                    disabled={isLoadingViewNewWindowDocument}
                >
                    {linkText.viewInNewWindow}
                    {isLoadingViewNewWindowDocument && (
                        <CircularProgress
                            sx={{
                                position: 'absolute',
                                alignSelf: 'center',
                            }}
                        />
                    )}
                </Button>
                <br />
                <Button
                    disabled={isLoadingDownloadDocument}
                    variant="text"
                    onClick={handleDownloadLink}
                    id={'download-btn'}
                >
                    {linkText.download}
                    {isLoadingDownloadDocument && (
                        <CircularProgress
                            sx={{
                                position: 'absolute',
                                alignSelf: 'center',
                            }}
                        />
                    )}
                </Button>
            </div>
        ) : (
            <div className="other-actions-row">
                <Button
                    variant="text"
                    onClick={handleDownloadLink}
                    id={'download-btn'}
                    disabled={isLoadingDownloadDocument}
                >
                    {linkText.download}
                    {isLoadingDownloadDocument && (
                        <CircularProgress
                            sx={{
                                position: 'absolute',
                                alignSelf: 'center',
                            }}
                        />
                    )}
                </Button>
            </div>
        );
    };

    const renderCopyLink = () => {
        if (!isCopyLinkVisible) {
            return null;
        }
        return isBuyerPortal ? (
            <div className="document-footer-bordered-row">
                {
                    <Button
                        variant="text"
                        onClick={() => {
                            copyAction(document.Id, document.BusinessDocType);
                        }}
                        id={'copy-btn'}
                    >
                        {linkText.copy}
                    </Button>
                }
            </div>
        ) : (
            <div className="other-actions-row">
                <Button
                    variant="text"
                    onClick={() => {
                        copyAction(document.Id, document.BusinessDocType);
                    }}
                    id={'copy-btn'}
                >
                    {linkText.copy}
                </Button>
            </div>
        );
    };

    const renderDeleteLink = () => {
        if (!isDeleteLinkVisible) {
            return null;
        }

        return isBuyerPortal ? (
            <div className="document-footer-bordered-row">
                {
                    <Button
                        variant="text"
                        onClick={() => {
                            deleteAction(document.Id, document.BusinessDocType);
                        }}
                        className="delete-link"
                        id={'delete-btn'}
                    >
                        {linkText.delete}
                    </Button>
                }
            </div>
        ) : (
            <div className="other-actions-row">
                <Button
                    variant="text"
                    onClick={() => {
                        deleteAction(draftBusinessDocId ?? -1, document.BusinessDocType, isDraft);
                    }}
                    className="delete-link"
                    id={'delete-btn'}
                >
                    {linkText.delete}
                </Button>
            </div>
        );
    };

    if (!isOtherActionsVisible) {
        return null;
    }

    return (
        <div className={isBuyerPortal ? 'actions-column' : ''}>
            <div className={isBuyerPortal ? 'document-footer-row' : 'other-actions-header-row'}>
                <h3>Other Actions</h3>
            </div>
            {renderDownloadLink()}
            {renderCopyLink()}
            {renderDeleteLink()}
        </div>
    );
};

export default OtherActions;
