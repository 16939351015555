import { useQuery } from '@tanstack/react-query';
import { RolesService } from './RoleService';

export const useRoles = () => {
    const { data, isFetching, error } = useQuery(['Roles'], async () => {
        const userService = new RolesService();
        return userService.getRoles();
    });

    return {
        roles: data ?? [],
        isFetching,
        error,
    };
};
