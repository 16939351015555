import { backendServices } from '../../../services';
import { ascendingOrderByKey } from '../../../utils/sorting';
import { ValidationEvent } from '../../CustomJSRuleEngineV2/ExecutionService/DPSValidations';
import { ParsedInputParameter, ParsedInputParameterSetting, processXrefParameters } from '../../CustomJSRulesV2/Utils';

export type DocumentValidationConfiguration = {
    fieldNamesToValidateOnFocusLoss: string[];

    eventsToValidateOn: ValidationEvent['type'][];

    inputParameters: (ParsedInputParameterSetting & { override: ParsedInputParameter | null })[];

    /**
     * @description
     * If this is set to true and there are only
     * validation failures of type "Warning",
     * the "warnings popup" will not be displayed.
     *
     * @default false
     *
     ** Only available for "InWorkflowOrWorkflowActivity"
     */
    HideValidationWarningsOnButtonActions?: boolean;

    /**
     * @description
     * If this is set to true, it will disable the button linked to "WorkflowSubmitEvent"
     * when there are validation failures of type "Error".
     *
     * @default false
     *
     ** Only available for "InWorkflowOrWorkflowActivity"
     ** Only available if eventsToValidateOn includes "FocusLossEvent"
     */
    DisableSubmitWhenFocusLossJShasValidationErrors?: boolean;
};

function extractEvent(isEnabled: boolean, name: ValidationEvent['type']) {
    if (isEnabled) {
        return [name];
    }
    return [];
}

export function extractValidationConfiguration(
    mapping: backendServices.ViewModels.BuyerCustomJSRuleMappingViewModel,
    context: backendServices.ViewModels.ExecutionContext,
    vendorClass: string | undefined
): DocumentValidationConfiguration {
    const currentContext =
        mapping.AllowedExecutionContexts?.ExecutionContext.Context.find(
            (ctx) => ctx.Type === backendServices.ViewModels.ExecutionContextToContextKeyMapping[context]
        ) ?? null;

    const mappingResult = mapping.BuyerCustomJSRuleMappingXrefs.sort(ascendingOrderByKey('Ordinal')).map(
        processXrefParameters
    );

    const { ruleParameters, overrideParameterValues } = mappingResult.reduce(
        (result, parsedXrefParameters) => {
            result.ruleParameters.push(...parsedXrefParameters.ruleParameters);

            const defaultOverride = parsedXrefParameters.parameterOverrides.find((o) => !o.vendorClasses.length);

            /**
             * When the document has no vendorClass,
             * we'll use the default override if it's available.
             */
            const vendorClassOverride =
                vendorClass === undefined
                    ? defaultOverride
                    : parsedXrefParameters.parameterOverrides.find((override) =>
                          override.vendorClasses.includes(vendorClass)
                      ) ?? defaultOverride;

            if (vendorClassOverride) {
                result.overrideParameterValues.push(...vendorClassOverride.parameterValues);
            }

            return result;
        },
        {
            ruleParameters: new Array<ParsedInputParameterSetting>(),
            overrideParameterValues: new Array<ParsedInputParameter>(),
        }
    );

    const inputParameters = ruleParameters.map((ruleParameter) => ({
        ...ruleParameter,
        override:
            overrideParameterValues.find(
                (overrideParameterValue) => overrideParameterValue.Name === ruleParameter.Name
            ) || null,
    }));

    if (currentContext?.Type === 'InWorkflowOrWorkflowActivity') {
        return {
            fieldNamesToValidateOnFocusLoss: currentContext.RunOnFocusLoss?.Fields?.split(',') ?? [],
            eventsToValidateOn: [
                ...extractEvent(!!currentContext.RunOnFocusLoss, 'FocusLossEvent'),
                ...extractEvent(currentContext.RunOnReassign === '1', 'WorkflowReassignEvent'),
                ...extractEvent(currentContext.RunOnSave === '1', 'WorkflowSaveEvent'),
                ...extractEvent(currentContext.RunOnSubmit === '1', 'WorkflowSubmitEvent'),
                ...extractEvent(currentContext.RunOnPageLoad === '1', 'WorkflowLoadEvent'),
            ],
            inputParameters,
            DisableSubmitWhenFocusLossJShasValidationErrors:
                currentContext.RunOnFocusLoss?.DisableSubmitWhenFocusLossJShasValidationErrors === '1' ?? false,
            HideValidationWarningsOnButtonActions:
                currentContext.HideValidationWarningsOnButtonActions === '1' ?? false,
        };
    }

    if (currentContext?.Type === 'WebEntry') {
        return {
            fieldNamesToValidateOnFocusLoss: currentContext.RunOnFocusLoss?.Fields?.split(',') ?? [],
            eventsToValidateOn: [
                'WorkflowLoadEvent',
                'WebEntrySaveAndPreviewEvent',
                ...extractEvent(!!currentContext.RunOnFocusLoss, 'FocusLossEvent'),
            ],
            inputParameters,
        };
    }

    throw new Error('Unexpected execution context');
}
