import { ReactNode } from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { TransceptaThemeProvider } from './themes';

const client = new QueryClient({
    defaultOptions: {
        queries: {
            // we need this because every time you click into the story, you click an iframe
            // which will cause refetching. that behavior is weird and doesn't match what will
            // happen when the component is mounted in the parent window.
            refetchOnWindowFocus: true,
        },
    },
});

/**
 * Wrap a component for storybook / cypress component test to apply theme and other default
 * providers used by our components.
 *
 * @param children the rendered component to decorate
 * @returns decorated rendered component
 */
export function componentTestDecorator(children: ReactNode): ReactNode {
    return (
        <BrowserRouter>
            <QueryClientProvider client={client}>
                <TransceptaThemeProvider>{children}</TransceptaThemeProvider>
            </QueryClientProvider>
        </BrowserRouter>
    );
}

export function clearQueryClient() {
    client.clear();
}
