// REFACTOR: Need to get the static text here from the configurable messages
import { FC, ReactNode, useCallback } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import resetState from '../../utils/resetState';

interface IProps {
    /**
     * If true, the dialog is open.
     */
    open: boolean;

    /**
     * Location to redirect to (actual browser navigation) after the user
     * resets state.
     */
    redirectLocation: string;

    /**
     * Title to show in the dialog.
     */
    title: string;

    /**
     * Reason to give the user for the failure. Make sure to inform
     * the user to click the Reset button in your message. This is not
     * included by default because the verbiage may need to differ based
     * on how you word your reason.
     */
    reason: ReactNode | string;
}

/**
 * Dialog that allows the user to reset the application state when an unrecoverable error
 * is encountered.
 */
export const ResetApplicationStateDialog: FC<IProps> = ({ open, redirectLocation, title, reason }) => {
    const handleOnClose = useCallback(() => {
        resetState(redirectLocation);
    }, [redirectLocation]);

    return (
        <Dialog onClose={handleOnClose} aria-labelledby="error-dialog-title" open={open}>
            <DialogTitle id="error-dialog-title">{title}</DialogTitle>
            <DialogContent dividers>
                <Typography variant="body2" gutterBottom>
                    {reason}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleOnClose} variant="contained" color="primary">
                    Reset
                </Button>
            </DialogActions>
        </Dialog>
    );
};
