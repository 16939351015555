import { FilterOptionsState } from '@mui/material';

/**
 * Create a `filterOptions` function to pass to Autocomplete component.
 *
 * @param normalize function to normalize query and option labels before comparing them
 * @param predicate to determine if normalized query matches normalized option.
 * @returns the `filterOptions` function
 */
export function createAutocompleteFilterOptions(
    normalize: (value: string) => string,
    predicate: (option: string, query: string) => boolean
) {
    function filterOptions<T>(options: T[], state: FilterOptionsState<T>) {
        const query = normalize(state.inputValue.toLowerCase());
        return options.filter((o) => predicate(normalize(state.getOptionLabel(o)), query));
    }

    return filterOptions;
}

/**
 * Filter autocomplete options using `String.prototype.startsWith` instead of default `String.prototype.includes`
 */
export const startsWithAutocompleteFilterOptions = createAutocompleteFilterOptions(
    (v) => v.toLowerCase(),
    (option, query) => option.startsWith(query)
);

/**
 * Filter autocomplete options using `String.prototype.endsWith` instead of default `String.prototype.includes`
 */
export const endsWithAutocompleteFilterOptions = createAutocompleteFilterOptions(
    (v) => v.toLowerCase(),
    (option, query) => option.startsWith(query)
);
