import f, { ODataFilterBuilder } from 'odata-filter-builder';
import { ODataUtils, escape, isEmpty } from '../../../utils';

import { IWorkflowSearchItems } from './types';

export const createWorkflowGridFilter = ({
    name,
    workflowTemplateUI,
    active,
    businessDocType,
}: Partial<IWorkflowSearchItems> = {}) => {
    const filterRules: ODataFilterBuilder[] = [];

    if (!isEmpty(name)) {
        filterRules.push(f().contains('name', escape(name!)));
    }

    if (typeof active === 'boolean') {
        filterRules.push(f().eq('active', active));
    }

    if (businessDocType) {
        filterRules.push(f().eq('businessDocType', businessDocType));
    }

    if (workflowTemplateUI) {
        filterRules.push(f().eq('workflowTemplateUI', workflowTemplateUI));
    }

    return filterRules.length ? ODataUtils.andConditions(filterRules).toString() : undefined;
};
