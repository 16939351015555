export type HasProperty<K extends string> = { [P in K]: number | string };
export type Comparator<K extends string> = <T extends HasProperty<K>>(a: T, b: T) => number;

export const ascendingOrder = (a: number | string, b: number | string) => {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
};

export const descendingOrder = (a: number | string, b: number | string) => {
    return -1 * ascendingOrder(a, b);
};

/**
 * Returns a comparator that causes an "ascending" sort on the provided key property
 * @param key the key property to sort on
 * @returns a comparator that can be passed to `Array.prototype.sort`
 */
export function ascendingOrderByKey<K extends string>(key: K): Comparator<K> {
    return (a, b) => ascendingOrder(a[key], b[key]);
}

/**
 * Returns a comparator that causes an "descending" sort on the provided key property
 * @param key the key property to sort on
 * @returns a comparator that can be passed to `Array.prototype.sort`
 */
export function descendingOrderByKey<K extends string>(key: K): Comparator<K> {
    return (a, b) => descendingOrder(a[key], b[key]);
}
