import { api, parseMessage } from '../../utils';
import apiPaths from '../../api/paths';

import { getServiceAgreementContent } from './serviceAgreementContent';
import { path } from 'ramda';
import { portalUserService } from 'common';

export const SERVICE_AGREEMENTS_SUCCESS = 'SERVICE_AGREEMENTS_SUCCESS';
export const SERVICE_AGREEMENTS_PENDING = 'SERVICE_AGREEMENTS_PENDING';
export const SERVICE_AGREEMENT_FAILED = 'SERVICE_AGREEMENT_FAILED';

/**
 * Service agreement success action
 * @param {*} response - response to api
 *
 * @return { Function }
 */
export function serviceAgreementSuccessAction(response) {
    return (dispatch) => {
        try {
            if (Array.isArray(response.data) && response.data.length > 0) {
                const serviceAgreementsData = response.data.reduce(
                    ({ serviceAgreementsIds, serviceAgreements }, serviceAgreement) => ({
                        serviceAgreementsIds: [...serviceAgreementsIds, serviceAgreement.ServiceAgreementId],
                        serviceAgreements: {
                            ...serviceAgreements,
                            [serviceAgreement.ServiceAgreementId]: serviceAgreement,
                        },
                    }),
                    { serviceAgreementsIds: [], serviceAgreements: {} }
                );
                dispatch({
                    type: SERVICE_AGREEMENTS_SUCCESS,
                    payload: { ...serviceAgreementsData },
                });

                dispatch(getServiceAgreementContent(serviceAgreementsData.serviceAgreementsIds));
            } else {
                throw new SyntaxError('Данные не корректны');
            }
        } catch (error) {
            console.warn(error);
        }
    };
}

/**
 * Service agreement error action
 * @param {*} error
 *
 * @return { Function }
 */
export function serviceAgreementErrorAction(error) {
    return (dispatch) => {
        dispatch({
            type: SERVICE_AGREEMENT_FAILED,
            payload: parseMessage(path(['response', 'data', 'errors'], error)),
        });
    };
}

/**
 * Get service agreement
 * @param { number } companyId
 *
 * @return { Function }
 */
export function getServiceAgreements(companyId) {
    return (dispatch, getState) => {
        companyId = companyId || portalUserService.getCurrentCompanyId();
        dispatch({ type: SERVICE_AGREEMENTS_PENDING, payload: { state: true } });
        api()
            .get(apiPaths.companyServiceAgreement.replace(':id', companyId))
            .then(
                (response) => dispatch(serviceAgreementSuccessAction(response)),
                (error) => dispatch(serviceAgreementErrorAction(error))
            )
            .then(() => dispatch({ type: SERVICE_AGREEMENTS_PENDING, payload: { state: false } }));
    };
}
