import { useMediaQuery, useTheme } from '@mui/material';
import { backendServices } from '../../services';

export function useSearchLayoutSize(): backendServices.ViewModels.FieldDictionaryScreenSize {
    const FieldDictionaryScreenSize = backendServices.ViewModels.FieldDictionaryScreenSize;

    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    const layoutSize = isSmDown
        ? FieldDictionaryScreenSize.Small
        : isMdDown
        ? FieldDictionaryScreenSize.Medium
        : FieldDictionaryScreenSize.Large;

    return layoutSize;
}
