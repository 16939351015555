import { DocumentValidationTouchpoint } from './DocumentValidationTouchpoint';
import { UIDocumentControllerTouchPoint } from './UIDocumentControllerTouchPoint';
import { Touchpoint } from '../../ScriptingEngineEnvironment';
import { VFTDisposition } from '../../../../services/ValidationFailureTypes/Api/ViewModels';
import { DocumentValidationConfiguration } from '../../../CJSRuleEngineV2ClientWrapper/ClientValidationController/DocumentValidationConfiguration';
import { ValidationFailureDictionary } from '../../../DocumentEditRules/types/ValidationFailureType';
import {
    transformToDPSValidationResultErrors,
    transformToDPSValidationResultWarnings,
} from './transformToDPSValidationResults';
import { ExecutionContext } from '../../../../services/backendServices/ViewModels';

export class CustomJSEngine {
    constructor(
        private readonly documentValidation: DocumentValidationTouchpoint,
        private readonly uiDocumentController: UIDocumentControllerTouchPoint,
        private readonly context: ExecutionContext
    ) {}

    public async validateDocument(...args: Parameters<DocumentValidationTouchpoint['executeValidationFunctions']>) {
        const validationResults = await this.documentValidation.executeValidationFunctions(...args);

        return validationResults;
    }

    public async controlDocumentUI(...args: Parameters<UIDocumentControllerTouchPoint['executeControllerFunctions']>) {
        await this.uiDocumentController.executeControllerFunctions(...args);
    }

    /**
     * Mutates the validation results returned by the Buyer Custom JS Rules
     */
    private applyOverridesToValidationResults(
        results: Touchpoint.DocumentValidation.ValidationClassResult[],
        validationFailureDictionary: ValidationFailureDictionary,
        inputParameters: DocumentValidationConfiguration['inputParameters']
    ): void {
        results.forEach((item) => {
            const inputParameterConfig = inputParameters.find(
                (inputParameter) => inputParameter.ErrorCode === item.result.errorCode
            );
            const override = inputParameterConfig?.override;

            const validationFailureType = validationFailureDictionary?.[item.result.errorCode];

            const newClass = override?.ValidationClass ?? null;
            if (newClass !== null) {
                item.class = newClass;
            }

            const computedDisposition = override?.Disposition ?? validationFailureType?.disposition;

            if (
                computedDisposition === VFTDisposition.Park ||
                computedDisposition === VFTDisposition.ReturnToSupplier
            ) {
                item.result.computedDisposition = computedDisposition;
            }
        });
    }

    public transformToErrorAndWarningLists(
        results: Touchpoint.DocumentValidation.ValidationClassResult[],
        validationFailureDictionary: ValidationFailureDictionary,
        // TODO: figure out how to get the input parameters from inside the engine and set them in the constructor,
        // instead of getting them from the client code
        inputParameters: DocumentValidationConfiguration['inputParameters']
    ) {
        this.applyOverridesToValidationResults(results, validationFailureDictionary, inputParameters);

        return {
            warnings: transformToDPSValidationResultWarnings(results, validationFailureDictionary),
            errors: transformToDPSValidationResultErrors(results, validationFailureDictionary, this.context),
        };
    }
}
