import { useQuery } from '@tanstack/react-query';
import { BusinessDocFlipService } from '../BusinessDocFlip';
import { isFeatureEnabled } from '../../../../config';

/**
 * Fetches and returns document flip dropdown options
 *
 * @param buyerCompanyId the unique id of the Buyer Company
 * @returns current state of the query
 */
export function useBusinessDocFlipDropdownOptions(buyerCompanyId: number | null) {
    const { data, isFetching, error } = useQuery(
        ['BusinessDocFlipDropdownOptions', buyerCompanyId],
        async () => {
            const businessDocFlip = new BusinessDocFlipService();
            return businessDocFlip.getBusinessDocumentFlipsForDropdown(buyerCompanyId!);
        },
        {
            enabled: !!buyerCompanyId && isFeatureEnabled('APIBasedInvoiceFlips'),
            staleTime: 0,
            cacheTime: 0,
            retry: false,
        }
    );

    return {
        data: data ?? null,
        isLoading: isFetching,
        error: error,
    };
}
