import { useFieldErrors } from './DocumentLocationsContexts';
import { FieldErrors as BaseFieldErrors } from '../Components';

export interface FieldErrorsProps {
    fieldName: string;
}

export default function FieldErrors({ fieldName }: FieldErrorsProps) {
    const errors = useFieldErrors(fieldName);

    if (!errors) {
        return null;
    }

    return (
        <>
            <BaseFieldErrors
                color="red"
                errors={errors.errorMessages}
                testIdPrefix={`DocumentEditValidator--InlineErrors--${fieldName}`}
            />
            <BaseFieldErrors
                color="orange"
                errors={errors.warningMessages}
                testIdPrefix={`DocumentEditValidator--InlineWarnings--${fieldName}`}
            />
        </>
    );
}
