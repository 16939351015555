import axios from 'axios';
import { optimizeApi } from './utils/optimizeApi';
import { provideApi } from './api';

const apiConfig = {
    baseURL: '/v1.0/api',
    timeout: 125000,
};

function createApi() {
    return axios.create({
        ...apiConfig,
        headers: {
            Authorization: `Bearer ${window.localStorage.access_token ? window.localStorage.access_token : ''}`,
        },
    });
}

function clientSideApi() {
    return optimizeApi(createApi());
}

provideApi(clientSideApi);
