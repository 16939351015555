export function pathMatch(routePath: string, locationPath: string) {
    if (routePath === locationPath) {
        return true;
    }

    const routeParts = routePath.split('/');
    const locationParts = locationPath.split('/');

    if (routeParts.length === locationParts.length) {
        for (let p = 0; p < routeParts.length; p++) {
            // skip checking segments of the path that are parameters
            if (routeParts[p].length === 0 || routeParts[p][0] !== ':') {
                if (routeParts[p] !== locationParts[p]) {
                    return false;
                }
            }
        }

        return true;
    }

    return false;
}
