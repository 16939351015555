import { IBreadCrumb } from './IBreadCrumb';

/**
 * Creates a history state with breadcrumb list in it
 * @param breadCrumbList bread crumb list to store in history state
 * @returns the history state object
 */
export function createBreadCrumbHistoryState(breadCrumbList: IBreadCrumb[]) {
    return { breadCrumbList };
}
