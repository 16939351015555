// Parking lot
export const PARKING_LOT_FETCHING = 'PARKING_LOT_FETCHING';
export const PARKING_LOT_FETCHED = 'PARKING_LOT_FETCHED';
export const PARKING_LOT_SEARCH = 'PARKING_LOT_SEARCH';
export const PARKING_LOT_SEARCH_ADVANCED = 'PARKING_LOT_SEARCH_ADVANCED';
export const PARKING_LOT_APPLY_SORTING = 'PARKING_LOT_APPLY_SORTING';

// ASNs
export const ASNS_FETCHING = 'ASNS_FETCHING';
export const ASNS_FETCHED = 'ASNS_FETCHED';
export const ASNS_REJECTED_COUNT_FETCHED = 'ASNS_REJECTED_COUNT_FETCHED';
export const ASNS_SEARCH = 'ASNS_SEARCH';
export const ASNS_SEARCH_ADVANCED = 'ASNS_SEARCH_ADVANCED';
export const ASNS_APPLY_SORTING = 'ASNS_APPLY_SORTING';
export const ASNS_SET_SEARCH_MODEL = 'ASNS_SET_SEARCH_MODEL';
export const ASNS_CLEAR_SEARCH = 'ASNS_CLEAR_SEARCH';

//SIM
export const SIMS_FETCHING = 'SIMS_FETCHING';
export const SIMS_FETCHED = 'SIMS_FETCHED';
export const SIMS_SEARCH_ADVANCED = 'SIMS_SEARCH_ADVANCED';
export const SIMS_APPLY_SORTING = 'SIMS_APPLY_SORTING';
export const SIMS_CLEAR_SEARCH = 'SIMS_CLEAR_SEARCH';

// Consumption Advices
export const CONSUMPTION_ADVICES_REJECTED_COUNT_FETCHED = 'CONSUMPTION_ADVICES_REJECTED_COUNT_FETCHED';
export const CONSUMPTION_ADVICES_APPLY_SORTING = 'CONSUMPTION_ADVICES_APPLY_SORTING';
export const CONSUMPTION_ADVICES_FETCHED = 'CONSUMPTION_ADVICES_FETCHED';
export const CONSUMPTION_ADVICES_FETCHING = 'CONSUMPTION_ADVICES_FETCHING';
export const CONSUMPTION_ADVICES_SEARCH = 'CONSUMPTION_ADVICES_SEARCH';
export const CONSUMPTION_ADVICES_SEARCH_ADVANCED = 'CONSUMPTION_ADVICES_SEARCH_ADVANCED';
export const CONSUMPTION_ADVICES_SET_SEARCH_MODEL = 'CONSUMPTION_ADVICES_SET_SEARCH_MODEL';
export const CONSUMPTION_ADVICES_CLEAR_SEARCH = 'CONSUMPTION_ADVICES_CLEAR_SEARCH';

// Inventory Advices
export const INVENTORY_ADVICES_FETCHING = 'INVENTORY_ADVICES_FETCHING';
export const INVENTORY_ADVICES_FETCHED = 'INVENTORY_ADVICES_FETCHED';
export const INVENTORY_ADVICES_REJECTED_COUNT_FETCHED = 'INVENTORY_ADVICES_REJECTED_COUNT_FETCHED';
export const INVENTORY_ADVICES_SEARCH = 'INVENTORY_ADVICES_SEARCH';
export const INVENTORY_ADVICES_SEARCH_ADVANCED = 'INVENTORY_ADVICES_SEARCH_ADVANCED';
export const INVENTORY_ADVICES_APPLY_SORTING = 'INVENTORY_ADVICES_APPLY_SORTING';
export const INVENTORY_ADVICES_SET_SEARCH_MODEL = 'INVENTORY_ADVICES_SET_SEARCH_MODEL';
export const INVENTORY_ADVICES_CLEAR_SEARCH = 'INVENTORY_ADVICES_CLEAR_SEARCH';

// Invoices
export const INVOICES_FETCHING = 'INVOICES_FETCHING';
export const INVOICES_FETCHED = 'INVOICES_FETCHED';
export const INVOICES_REJECTED_COUNT_FETCHED = 'INVOICES_REJECTED_COUNT_FETCHED';
export const INVOICES_SEARCH = 'INVOICES_SEARCH';
export const INVOICES_SEARCH_ADVANCED = 'INVOICES_SEARCH_ADVANCED';
export const INVOICES_APPLY_SORTING = 'INVOICES_APPLY_SORTING';
export const INVOICES_SHOW_ALL = 'INVOICES_SHOW_ALL';
export const INVOICES_SET_SEARCH_MODEL = 'INVOICES_SET_SEARCH_MODEL';
export const INVOICES_CLEAR_SEARCH = 'INVOICES_CLEAR_SEARCH';

// Purchase Orders
export const PURCHASE_ORDERS_FETCHING = 'PURCHASE_ORDERS_FETCHING';
export const PURCHASE_ORDERS_FETCHED = 'PURCHASE_ORDERS_FETCHED';
export const PURCHASE_ORDERS_SEARCH = 'PURCHASE_ORDERS_SEARCH';
export const PURCHASE_ORDERS_SEARCH_ADVANCED = 'PURCHASE_ORDERS_SEARCH_ADVANCED';
export const PURCHASE_ORDERS_APPLY_SORTING = 'PURCHASE_ORDERS_APPLY_SORTING';
export const PURCHASE_ORDERS_SHOW_ALL = 'PURCHASE_ORDERS_SHOW_ALL';
export const PURCHASE_ORDERS_SET_SEARCH_MODEL = 'PURCHASE_ORDERS_SET_SEARCH_MODEL';
export const PURCHASE_ORDERS_CLEAR_SEARCH = 'PURCHASE_ORDERS_CLEAR_SEARCH';

// Purchase Order Acknowledgements
export const PURCHASE_ORDER_ACKN_FETCHING = 'PURCHASE_ORDER_ACKN_FETCHING';
export const PURCHASE_ORDER_ACKN_FETCHED = 'PURCHASE_ORDER_ACKN_FETCHED';
export const PURCHASE_ORDER_ACKN_REJECTED_COUNT_FETCHED = 'PURCHASE_ORDER_ACKN_REJECTED_COUNT_FETCHED';
export const PURCHASE_ORDER_ACKN_SEARCH = 'PURCHASE_ORDER_ACKN_SEARCH';
export const PURCHASE_ORDER_ACKN_SEARCH_ADVANCED = 'PURCHASE_ORDER_ACKN_SEARCH_ADVANCED';
export const PURCHASE_ORDER_ACKN_APPLY_SORTING = 'PURCHASE_ORDER_ACKN_APPLY_SORTING';
export const PURCHASE_ORDER_ACKN_SET_SEARCH_MODEL = 'PURCHASE_ORDER_ACKN_SET_SEARCH_MODEL';
export const PURCHASE_ORDER_ACKN_CLEAR_SEARCH = 'PURCHASE_ORDER_ACKN_CLEAR_SEARCH';

// Receiving advices
export const RECEIVING_ADVICES_REJECTED_COUNT_FETCHED = 'RECEIVING_ADVICES_REJECTED_COUNT_FETCHED';
export const RECEIVING_ADVICES_APPLY_SORTING = 'RECEIVING_ADVICES_APPLY_SORTING';
export const RECEIVING_ADVICES_FETCHED = 'RECEIVING_ADVICES_FETCHED';
export const RECEIVING_ADVICES_FETCHING = 'RECEIVING_ADVICES_FETCHING';
export const RECEIVING_ADVICES_SEARCH = 'RECEIVING_ADVICES_SEARCH';
export const RECEIVING_ADVICES_SEARCH_ADVANCED = 'RECEIVING_ADVICES_SEARCH_ADVANCED';
export const RECEIVING_ADVICES_SET_SEARCH_MODEL = 'RECEIVING_ADVICES_SET_SEARCH_MODEL';
export const RECEIVING_ADVICES_CLEAR_SEARCH = 'RECEIVING_ADVICES_CLEAR_SEARCH';

const modeToListName = {
    invoices: 'INVOICES',
    purchaseOrders: 'PURCHASE_ORDERS',
    purchaseOrderAcknowledgements: 'PURCHASE_ORDER_ACKN',
    asns: 'ASNS',
    inventoryAdvices: 'INVENTORY_ADVICES',
    receivingAdvices: 'RECEIVING_ADVICES',
    consumptionAdvices: 'CONSUMPTION_ADVICES',
    sims: 'SIMS',
};

export const setPerformContainsSearch = (mode, enabled) => ({
    type: `${modeToListName[mode]}_SET_PERFORM_CONTAINS_SEARCH`,
    payload: enabled,
});
