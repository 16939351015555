import { merge } from 'lodash';
import { IAppConfig } from './types';
import { getPortalDefaultConfig } from './portalDefaultConfig';
import { RecursivePartial } from '../../types';

let appConfig: IAppConfig | null = null;

export function getAppConfig(): IAppConfig {
    if (!appConfig) {
        // default config values that can be overridden by a portal or specific environment
        const defaultConfig: IAppConfig = {
            apiCacheEndpointConfig: {
                mode: 'optIn',
                endpoints: [],
            },
            featureFlags: {
                DocumentTotalsCalculationsV2: 'enabled',
            },
            logRocket: {
                projectId: '',
                slackChannelWebhookURLs: {},
                slackNotificationEventConfig: {},
                resetSessionOnLogOut: false,
                enableConditionalRecording: true,
                initOptions: {
                    network: {
                        requestSanitizer: (r) => {
                            if (r.url.indexOf('/auth/login') > -1 || r.url.indexOf('SignInAPI.aspx') > -1) {
                                r.body = r.body?.replace(/password=(.*?)($|&)/, 'password=**redacted**$2');
                            }
                            return r;
                        },
                    },
                },
                ignoredCompanyIds: [],
                ignoredUserEmails: ['buyer.demo@gmail.com'],
            },
            logInStallTimeout: 30,
            idleTimeout: 10,
            configurableMessages: {
                rootErrorBoundary: {
                    dialogTitle: 'Something went wrong while displaying this page',
                    paragraphs: [
                        'We have encountered an error while trying to display this page. Please click the Reset button below to exit the current page.',
                        'The error that caused this message to display has been reported to the Transcepta team and we are looking into it.',
                        '<a href="https://help.transcepta.com/hc/en-us/articles/4406231242132" target="_blank">Click here</a> for a list of supported browsers and troubleshooting steps.',
                        'If you keep encountering this issue, please <a href="https://support.transcepta.com/" target="_blank">click here to create a support case.</a> and reference the error code: {{errorCode}}',
                    ],
                },
                vendorSearchModeText: {
                    label: 'Search Mode',
                    searchVendorsText: 'Search Your Vendor Feed',
                    searchSuppliersText: 'Search Connected Suppliers',
                    tooltip:
                        '<p style="padding-bottom: 8px"><i>Search Your Vendor Feed</i>: This mode searches for vendors using your provided vendor feed. Search results will contain information for all vendors in your feed including those who are not registered to transact with Transcepta.</p><p><i>Search Connected Suppliers</i>: This mode searches only for suppliers who are registered to transact with Transcepta.</p>',
                },
            },
            addCustomerInstructionsUrl: 'https://support.transcepta.com/',
        };

        // portal overrides
        const portalDefaultConfig = getPortalDefaultConfig();

        // environment overrides (deployment overrides)
        const environmentOverrides: RecursivePartial<IAppConfig> = window.transceptaPortalConfig ?? {};

        // override defaults with portal defaults and portal defaults with environment overrides
        appConfig = merge({}, defaultConfig, portalDefaultConfig, environmentOverrides);

        // store the applied config on the window so that support can see what config is applied to running application
        window.transceptaPortalAppliedConfig = appConfig;
    }

    return appConfig;
}
