export const SHOW_MOBILE_MENU = 'SHOW_MOBILE_MENU';
export const HIDE_MOBILE_MENU = 'HIDE_MOBILE_MENU';
export const NAVIGATE_TO_PATH = 'NAVIGATE_TO_PATH';

export const showMobileMenu = () => ({
    type: SHOW_MOBILE_MENU,
});

export const hideMobileMenu = () => ({
    type: HIDE_MOBILE_MENU,
});

export const navigateToPath = () => ({
    type: NAVIGATE_TO_PATH,
});
