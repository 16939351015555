import { JSONSchemaType } from 'ajv';
import { ISearchResult } from '../types';
import { createStringParser, createValidatorFromParser } from '../../../utils/dataParser';

const searchResultSchema: JSONSchemaType<ISearchResult> = {
    type: 'object',
    properties: {
        Label: { type: 'string' },
        Text: { type: 'string', nullable: true },
        Fields: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    Type: { type: 'string' },
                    Label: { type: 'string' },
                    FieldName: { type: 'string' },
                },
                required: ['Type', 'Label', 'FieldName'],
                additionalProperties: true,
            },
            minItems: 1,
        },
    },
    required: ['Label', 'Fields'],
};

const parseSearchResult = createStringParser(searchResultSchema);

export const validateSearchResult = createValidatorFromParser(parseSearchResult);
