export interface IErrorType {
    /**
     * What department should handle the issue if reported.
     */
    department: 'Systems' | 'Pro Serv' | 'Backend Dev' | 'Frontend Dev';

    /**
     * Ordinal number uniquely identifying the error type within this department.
     */
    departmentOrdinal: number;

    /**
     * Description of the error type.
     */
    description: string;

    /**
     * Severity of the error (if already known)
     */
    severity: 'Low' | 'Medium' | 'High' | 'Triage';

    /**
     * If this is 'transient', the error may spontaneously resolve.
     *
     * If this is 'persistent', the error requires a change to be made to code or configuration to resolve.
     */
    errorClass: 'transient' | 'persistent';
}

function createErrorType(value: IErrorType): IErrorType {
    return value;
}

const errorTypes = {
    'DocumentEditValidation.ConfigLoadNetworkError': createErrorType({
        department: 'Systems',
        departmentOrdinal: 1,
        description: 'Occurs when the BuyerCustomJSRuleMapping endpoint fails due to a network error.',
        severity: 'Triage',
        errorClass: 'transient',
    }),
    'DocumentEditValidation.ScriptLoadNetworkError': createErrorType({
        department: 'Systems',
        departmentOrdinal: 2,
        description:
            'Occurs when one of the scripts specified in the BuyerCustomJSRuleMapping cannot be loaded due to a network error.',
        severity: 'Triage',
        errorClass: 'transient',
    }),
    'DocumentEditValidation.ConfigParseError': createErrorType({
        department: 'Backend Dev',
        departmentOrdinal: 1,
        description: 'Occurs when an invalid response is returned by the BuyerCustomJSRuleMapping endpoint.',
        severity: 'High',
        errorClass: 'persistent',
    }),
    'DocumentEditValidation.ScriptParseError': createErrorType({
        department: 'Pro Serv',
        departmentOrdinal: 1,
        description:
            'Occurs when one of the scripts specified in the BuyerCustomJSRuleMapping cannot be parsed (is invalid JS code).',
        severity: 'Triage',
        errorClass: 'persistent',
    }),
    'DocumentEditValidation.ConfigNotAllowedDueToInternalRulesError': createErrorType({
        department: 'Pro Serv',
        departmentOrdinal: 4,
        description:
            'Occurs when Pro Serv configured a BuyerCustomJSRuleMapping and also InternalRules for the same document edit context. This is not allowed because custom buyer rules and internal rules combined can lead to strange logic conflicts.',
        severity: 'High',
        errorClass: 'persistent',
    }),
    'DocumentEditValidation.IllegalInternalRulesError': createErrorType({
        department: 'Frontend Dev',
        departmentOrdinal: 2,
        description: 'Occurs when internal rules were selected in the wrong document edit context.',
        severity: 'High',
        errorClass: 'persistent',
    }),
    'DocumentEditValidation.InternalRuleFailedToLoadError': createErrorType({
        department: 'Frontend Dev',
        departmentOrdinal: 3,
        description: 'Occurs when an internal rule throws an error during loading.',
        severity: 'High',
        errorClass: 'persistent',
    }),
    'DocumentEditValidation.FunctionInvalidResponse': createErrorType({
        department: 'Pro Serv',
        departmentOrdinal: 2,
        description:
            'Occurs when one of the scripts specified in the BuyerCustomJSRuleMapping returns an invalid response while validating a document.',
        severity: 'Triage',
        errorClass: 'persistent',
    }),
    'DocumentEditValidation.ImplementationError': createErrorType({
        department: 'Frontend Dev',
        departmentOrdinal: 1,
        description:
            'Occurs when validation fails due to an unhandled exception, indicating a problem in the front-end code.',
        severity: 'High',
        errorClass: 'persistent',
    }),
    'GLCrossValidation.ScriptLoadNetworkError': createErrorType({
        department: 'Systems',
        departmentOrdinal: 3,
        description: 'Occurs when the GL cross validation script fails to load due to a network issue.',
        severity: 'Triage',
        errorClass: 'transient',
    }),
    'GLCrossValidation.ScriptLoadDependenciesError': createErrorType({
        department: 'Pro Serv',
        departmentOrdinal: 3,
        description: 'Occurs when the GL cross validation script fails to load its dependencies (any error).',
        severity: 'Triage',
        errorClass: 'persistent',
    }),
    'SupplierSearch.CannotLoadVendorClasses': createErrorType({
        department: 'Systems',
        departmentOrdinal: 4,
        description: 'We failed to load the vendor classes on the supplier search page due to an API or network error.',
        severity: 'Triage',
        errorClass: 'transient',
    }),
    'DocumentDisplay.DependencyFailedToLoad': createErrorType({
        department: 'Systems',
        departmentOrdinal: 5,
        description:
            'One or more API endpoints required to load and display a document failed, and therefore the document could not be displayed to the user.',
        severity: 'Triage',
        errorClass: 'transient',
    }),
    ReactAppCrash: createErrorType({
        department: 'Systems',
        departmentOrdinal: 6,
        description:
            'The React Application crashed and an error dialog was displayed to the user allowing them to reset their application state. This may have resolved the issue for the user, but they might experience it again if the root cause is a configuration issue or bug in the application code.',
        severity: 'Triage',
        errorClass: 'transient',
    }),
} as const;

export default errorTypes;
