import { FeatureAppStateProvider } from './FeatureAppState';
import FeatureDialog from './FeatureDialog';
import FeatureManager from './FeatureManager';

export interface IFeatureAppProps {
    onClose(): void;
}

export default function FeatureApp({ onClose }: IFeatureAppProps) {
    return (
        <FeatureAppStateProvider>
            <FeatureDialog onClose={onClose}>
                <FeatureManager />
            </FeatureDialog>
        </FeatureAppStateProvider>
    );
}
