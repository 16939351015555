import {
    AmountIndicator,
    IDocumentViewModel,
    IInvoiceLineItem,
    IInvoiceLineItemMiscAmount,
    IInvoiceMiscAmount,
} from '../../services/Document/Api';

import {
    ICalculatedFields,
    IAmountChargeOrAllowance,
    IDocumentAmountsViewModel,
    ILineItemAmount,
} from './IDocumentAmountsViewModel';

function getAppliedToField(amountIndicator: AmountIndicator): IAmountChargeOrAllowance['appliedToField'] {
    switch (amountIndicator) {
        case AmountIndicator.TotalAllowance:
        case AmountIndicator.TotalCharge:
            return 'total';
        case AmountIndicator.AmountDueAllowance:
        case AmountIndicator.AmountDueCharge:
            return 'amountDue';
        default:
            return 'total';
    }
}

function convertMiscAmountToChargeOrAllowance(
    miscAmount: IInvoiceLineItemMiscAmount | IInvoiceMiscAmount
): IAmountChargeOrAllowance {
    return {
        name: miscAmount.AdjustmentReasonId === 27 ? 'VAT' : miscAmount.Description,
        amount: Number(miscAmount.Amount!),
        appliedToField: getAppliedToField(miscAmount.AmountIndicator),
    };
}

function getInvoiceLineItemChargesAndAllowances(lineItem: IInvoiceLineItem): IAmountChargeOrAllowance[] {
    return [
        ...lineItem.InvoiceLineItemMiscAmounts.map(convertMiscAmountToChargeOrAllowance),
        {
            name: 'DiscountAmount',
            amount: Number(lineItem.DiscountAmount ?? 0) * -1,
            appliedToField: 'total',
        },
        {
            name: 'FreightAmount',
            amount: Number(lineItem.FreightAmount ?? 0),
            appliedToField: 'total',
        },
        {
            name: 'TaxAmount',
            amount: Number(lineItem.TaxAmount ?? 0),
            appliedToField: 'total',
        },
    ];
}

export class InvoiceDocumentAmountsViewModel implements IDocumentAmountsViewModel {
    constructor(private document: IDocumentViewModel) {}

    getLineItemAmounts(): ILineItemAmount[] {
        return this.document.BusinessDocFields.Invoice.InvoiceLineItems.map((x) => ({
            unitPrice: Number(x.UnitPrice),
            quantity: Number(x.Quantity),
            pricingUnitDivisor: Number(x.PricingUnitDivisor || 1),
            chargesAndAllowances: getInvoiceLineItemChargesAndAllowances(x),
        }));
    }

    getChargesAndAllowances(): IAmountChargeOrAllowance[] {
        const invoice = this.document.BusinessDocFields.Invoice;

        return [
            ...invoice.InvoiceMiscAmounts.map(convertMiscAmountToChargeOrAllowance),
            {
                name: 'SalesTaxAmount',
                amount: Number(invoice.SalesTaxAmount ?? 0),
                appliedToField: 'total',
            },
            {
                name: 'ShippingCharges',
                amount: Number(invoice.ShippingCharges ?? 0),
                appliedToField: 'total',
            },
            {
                name: 'MiscCharges',
                amount: Number(invoice.MiscCharges ?? 0),
                appliedToField: 'total',
            },
            {
                name: 'ProductDiscountAmount',
                amount: Number(invoice.ProductDiscountAmount ?? 0) * -1,
                appliedToField: 'total',
            },
        ];
    }

    getAmountPaid(): number {
        return Number(this.document.BusinessDocFields.Invoice.AmountPaid ?? 0);
    }

    setCalculatedFields(calculatedFields: ICalculatedFields): IDocumentViewModel {
        return {
            ...this.document,
            BusinessDocFields: {
                ...this.document.BusinessDocFields,
                Invoice: {
                    ...this.document.BusinessDocFields.Invoice,
                    InvoiceAmount: calculatedFields.total,
                    DiscountableAmount: calculatedFields.subtotal,
                    AmountDue: calculatedFields.amountDue,
                    TotalVAT: calculatedFields.totalVAT,
                    InvoiceLineItems: this.document.BusinessDocFields.Invoice.InvoiceLineItems.map((x, i) => ({
                        ...x,
                        LineItemNetTotal: calculatedFields.lineItemTotals[i].netTotal,
                        LineItemTotal: calculatedFields.lineItemTotals[i].total,
                    })),
                },
            },
        };
    }

    getCalculatedFields(): ICalculatedFields {
        const invoice = this.document.BusinessDocFields.Invoice;

        return {
            total: invoice.InvoiceAmount,
            subtotal: invoice.DiscountableAmount,
            amountDue: invoice.AmountDue,
            totalVAT: invoice.TotalVAT,
            lineItemTotals: invoice.InvoiceLineItems.map((x) => ({
                netTotal: x.LineItemNetTotal,
                total: x.LineItemTotal,
            })),
        };
    }
}
