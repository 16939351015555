import { IFeatureFlagConfigurationSource, IFeatureFlagStates } from './types';

function getFullKey(featureKey: string) {
    return `transcepta.feature-flags.${featureKey}`;
}

export function getFeatureState(featureKey: string): IFeatureFlagStates {
    const state = localStorage.getItem(getFullKey(featureKey));
    if (state === 'enabled' || state === 'disabled') {
        return state;
    }
    return 'unset';
}

export function setFeatureState(featureKey: string, state: 'enabled' | 'disabled' | 'unset') {
    localStorage.setItem(getFullKey(featureKey), state);
}

/**
 * Checks if a feature flag is enabled by the user in their browser's local storage.
 */
const localStorageSource: IFeatureFlagConfigurationSource = {
    getFeatureFlagState(featureKey: string) {
        return getFeatureState(featureKey);
    },
};

export default localStorageSource;
