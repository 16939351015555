import { IFieldType, createFieldTypeFactory } from '../infrastructure';

/**
 * A ICurrencyField field object.
 */
export interface INumericTextField extends IFieldType<number | null> {
    type: 'INumericTextField';

    locale: string;

    scale?: number;

    padFractionalZeros?: boolean;

    maxValue?: number;

    omitCommas?: boolean;
}

/**
 * Creates a INumericTextField field object.
 */
export const createNumericTextField = createFieldTypeFactory<INumericTextField>('INumericTextField', null);
