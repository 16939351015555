import { FC } from 'react';
import { LogoutPage } from 'common/build/legacy/transcepta-ui-core';
import { ScopedCssBaseline, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import LoginSupplierArticle from './LoginSupplierArticle';
import { logoutAction } from '../../../actions/userlogin';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        height: '95vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const Logout: FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const selector = useSelector((state) => state as any);

    const loginAgain = () => {
        dispatch(logoutAction());
    };

    return (
        <ScopedCssBaseline>
            <div className={classes.root}>
                <LogoutPage article={<LoginSupplierArticle />} userLogin={selector.userlogin} loginAgain={loginAgain} />
            </div>
        </ScopedCssBaseline>
    );
};

export default Logout;
