import { pushAlert } from '../alerts';

export const CHANGEPASSWORD_FIELD_CHANGE = 'CHANGEPASSWORD_FIELD_CHANGE';
export const CHANGEPASSWORD_CHANGE_REQUEST = 'CHANGEPASSWORD_CHANGE_REQUEST';
export const CHANGEPASSWORD_CHANGE_SUCCESS = 'CHANGEPASSWORD_CHANGE_SUCCESS';
export const CHANGEPASSWORD_CANCEL = 'CHANGEPASSWORD_CANCEL';
export const CHANGEPASSWORD_CONFIRMATION_FAIL = 'CHANGEPASSWORD_CONFIRMATION_FAIL';

export function handleFieldChange(e) {
    const { name, value } = e.target;
    return {
        type: CHANGEPASSWORD_FIELD_CHANGE,
        payload: { name, value },
    };
}

export function changePasswordCancel() {
    return {
        type: CHANGEPASSWORD_CANCEL,
    };
}

export function handleSaveChange() {
    return (dispatch, getState) => {
        const { oldPassword, newPassword, newPasswordConfirmation } = getState().changePassword;
        if (!oldPassword) dispatch(pushAlert({ type: 'error', text: 'Please type old password' }));
        else if (newPassword !== newPasswordConfirmation)
            dispatch(pushAlert({ type: 'error', text: 'Wrong password confirmation' }));
        else if (!newPassword) dispatch(pushAlert({ type: 'error', text: 'New password is blank' }));
        // TODO: Send api request here
        else dispatch(pushAlert({ type: 'success', text: 'Password was changed successfully' }));
    };
}
