import { format } from 'date-fns';
import { passwordLocalizedStrings } from '../../../features';
import { RecursivePartial } from '../../../types';
import { calculatePageOffset } from '../../../utils';
import { IUserViewModel, RoleType, UserApi } from '../Api';
import { portalUserService } from '../PortalUserService';
import {
    IGetUsersParams,
    IUser,
    IUserFormData,
    IUserGrid,
    IUserGridItem,
    IUserOption,
    IUserSearchItems,
} from './types';
import { createUserFilter } from './User.filter';

interface IPasswordChangeRequest {
    oldPassword: string;
    newPassword: string;
    newPasswordConfirmation: string;
}

interface IPasswordValidationErrorStrings {
    textPasswordCategoryError: string;
    textMissingOldPassword: string;
    textBadPasswordConfirm: string;
    textOldPasswordMatches: string;
    textMissingNewPassword: string;
    textBadPasswordLength: string;
}

export class UserService {
    private api = new UserApi();

    private transformFromViewModel(model: IUserViewModel): IUser {
        return {
            id: model.ID,
            companyID: model.CompanyID,
            password: model.Password,
            lastLoginDate: model.LastLoginDate,
            creationDate: model.CreationDate,
            lastLockOutDate: model.LastLockOutDate,
            signinFailureCount: model.SigninFailureCount,
            lastUpdateDate: model.LastUpdateDate,
            name: model.Name,
            email: model.Email,
            rEmail: model.Email,
            phone: model.Phone,
            userName: model.UserName,
            invoiceProfileID: model.InvoiceProfileID,
            procurementProfileID: model.ProcurementProfileID,
            showInvoiceSubmittedPopupInSupplierPortal: model.ShowInvoiceSubmittedPopupInSupplierPortal,
            active: model.Active,
            title: model.Title,
            jobClassification: model.JobClassification,
            department: model.Department,
            departmentCode: model.DepartmentCode,
            region: model.Region,
            employeeNumber: model.EmployeeNumber,
            userAttributes: model.UserAttributes,
            managersUserId: model.ManagersUserId,
            substituteUserId: model.SubstituteUserId,
            substituteUserActive: model.SubstituteUserActive,
            approvalLimit: model.ApprovalLimit,
            rowversion: model.Rowversion,
            workflowTaskCount: model.WorkflowTaskCount,
            userRoles: model.UserRoles,
            workflowGroupUsers: model.WorkflowGroupUsers,
            autogenUnconfirmed: model.AutogenUnconfirmed,
            type: model.Type,
            type_US: model.Type_US,
            menuSettings: model.menuSettings,
            userGroupMemberships: model.UserGroupMemberships,
            countryList: model.countryList,
        };
    }

    private toUserCreateUpdateViewModel(user: IUserFormData): RecursivePartial<IUserViewModel> {
        const companyId = portalUserService.getCurrentCompanyId();
        return {
            ID: user.id ?? undefined,
            UserName: user.userName,
            AutogenUnconfirmed: user.autogenUnconfirmed ?? null,
            CompanyID: companyId,
            Name: user.name,
            Email: user.email,
            Phone: user.phone,
            EmployeeNumber: user.employeeNumber,
            Title: user.title,
            Department: user.department,
            Region: user.region,
            JobClassification: user.jobClassification,
            ManagersUserId: user.managersUserId ?? null,
            UserRoles: user.userRoles.map((userRole) => ({ RoleID: userRole.RoleID })),
            UserGroupMemberships: user.userGroupMemberships
                .filter((userGroupMembership) => userGroupMembership.checked)
                .map((userGroupMembership) => ({
                    UserGroupId: userGroupMembership.UserGroupId,
                    UserId: user.id!,
                    Id: userGroupMembership.Id ?? undefined,
                })),
            SubstituteUserActive: user.substituteUserActive || false,
            SubstituteUserId: user.substituteUserId ?? null,
            WorkflowGroupUsers: user.workflowGroupUsers
                .filter((workflowGroupUser) => workflowGroupUser.checked)
                .map((workflowGroupUser) => ({
                    WorkflowId: workflowGroupUser.WorkflowId,
                    UserId: user.id!,
                    Id: workflowGroupUser.Id ?? undefined,
                })),
            ShowInvoiceSubmittedPopupInSupplierPortal: user.showInvoiceSubmittedPopupInSupplierPortal || false,
        };
    }

    public getUserById = async (userId: number): Promise<IUser> => {
        const res = await this.api.getUser(userId);

        return this.transformFromViewModel(res.data[0]);
    };

    public getUsersByCompanyId = async (companyId: number): Promise<IUser[]> => {
        const response = await this.api.getUsers({ companyId });

        return response.data.Items.map((value) => this.transformFromViewModel(value));
    };

    private toUserSearchGrid = (user: IUserViewModel): IUserGridItem => {
        return {
            id: user.ID,
            adminAccount: user.UserRoles.some((role) => role.RoleID === RoleType.CompanyAdminRights),
            emailAddress: user.Email,
            lastSignIn: user.LastLoginDate ? format(new Date(user.LastLoginDate), 'P') : '',
            phoneNumber: user.Phone,
            name: user.Name,
            roles: user.UserRoles.map(({ RoleID }) => ({ id: RoleID })),
            substituteUserName: user.SubstituteUserName || '',
        };
    };

    public getUserSearchGrid = async (
        companyId: number,
        count: boolean,
        pageSize: number,
        page: number
    ): Promise<IUserGrid> => {
        const params: IGetUsersParams = {
            companyId,
            includeAll: true,
            $count: count,
            $top: pageSize,
            $skip: calculatePageOffset(pageSize, page),
        };
        const response = await this.api.getUsers(params);

        return {
            count: response.data.Count || 0,
            items: response.data.Items.map((value) => this.toUserSearchGrid(value)),
        };
    };

    public getUsersSearchGrid = async (
        companyId: number,
        count: boolean,
        pageSize: number,
        page: number,
        sort?: string,
        searchItems?: IUserSearchItems
    ): Promise<IUserGrid> => {
        const filter = createUserFilter(searchItems);
        const params: IGetUsersParams = {
            companyId,
            $count: count,
            $top: pageSize,
            $skip: calculatePageOffset(pageSize, page),
            $select: 'ID, Name, Email, UserName, LastLoginDate, UserRoles, Type, SubstituteUserName',
            $expand: 'UserRoles',
            includeSubstituteUser: 'true',
        };
        if (sort) {
            params.$orderby = sort;
        }
        if (filter) {
            params.$filter = filter;
        }
        const response = await this.api.getUsers(params);

        return {
            count: response.data.Count || 0,
            items: response.data.Items.map((value) => this.toUserSearchGrid(value)),
        };
    };

    public validatePassword({
        password,
        errorStrings = passwordLocalizedStrings,
    }: {
        password: string;
        errorStrings?: IPasswordValidationErrorStrings;
    }) {
        let categoryCounterCheck = 0;
        const errors = new Array<string>();

        if (password.length < 7) {
            errors.push(errorStrings.textBadPasswordLength);
        }

        //contains lowercase
        if (/[a-z]/.test(password)) {
            categoryCounterCheck++;
        }
        //contains uppercase
        if (/[A-Z]/.test(password)) {
            categoryCounterCheck++;
        }
        //contains a numbers
        if (/[0-9]/.test(password)) {
            categoryCounterCheck++;
        }
        //match a special character
        if (/[\!\@\#\$\%\^\&\*\(\)\_\+\|\-\=\{\}\[\]\:\"\;\'\<\>\/]/.test(password)) {
            categoryCounterCheck++;
        }

        if (categoryCounterCheck < 3) {
            errors.push(errorStrings.textPasswordCategoryError);
        }

        return errors;
    }

    public validatePasswordChangeRequest = (
        info: IPasswordChangeRequest,
        errorStrings: IPasswordValidationErrorStrings
    ): {
        isValid: boolean;
        message: string;
    } => {
        const { newPassword, oldPassword, newPasswordConfirmation } = info;

        const errorText = this.validatePassword({ password: newPassword, errorStrings });

        if (!oldPassword) {
            errorText.push(errorStrings.textMissingOldPassword);
        }
        if (newPassword !== newPasswordConfirmation) {
            errorText.push(errorStrings.textBadPasswordConfirm);
        }
        if (oldPassword === newPassword) {
            errorText.push(errorStrings.textOldPasswordMatches);
        }
        if (!newPassword) {
            errorText.push(errorStrings.textMissingNewPassword);
        }

        const message = errorText.join('\n');

        return {
            isValid: errorText.length === 0,
            message,
        };
    };

    public changePassword = async (
        info: IPasswordChangeRequest,
        errorStrings: IPasswordValidationErrorStrings
    ): Promise<void> => {
        const validationResult = this.validatePasswordChangeRequest(info, errorStrings);

        if (!validationResult.isValid) {
            throw validationResult.message;
        }

        await this.api.changePassword({
            NewPassword: info.newPassword,
            OldPassword: info.oldPassword,
        });
    };

    public getUsers = async (companyId: number) => {
        const response = await this.api.getUsers({ companyId, $select: 'ID,Name', includeAll: true });
        return response.data.Items.map((value) => this.toUserOption(value));
    };

    private toUserOption(userViewModel: IUserViewModel): IUserOption {
        return { id: userViewModel.ID, name: userViewModel.Name };
    }

    public createUser = async (user: IUserFormData): Promise<number> => {
        if (user.email !== user.rEmail) {
            throw new Error('Email addresses must match');
        }
        const response = await this.api.createUser(this.toUserCreateUpdateViewModel(user));
        return response.data.ID;
    };

    public updateUser = async (id: number, user: IUserFormData): Promise<number> => {
        if (user.email !== user.rEmail) {
            throw new Error('Email addresses must match');
        }
        const response = await this.api.updateUser(id, this.toUserCreateUpdateViewModel(user));
        return response.data.ID;
    };

    public deleteUser = (id: number) => {
        return this.api.deleteUser(id);
    };
}
