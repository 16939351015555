import { ChangeEvent, FC, FormEvent } from 'react';
import { TextField, Button } from '../../../../ui';
import { Link, Stack, Typography } from '@mui/material';

interface IProps {
    email: string;
    emailError: boolean;
    handleEmailChange: (event: ChangeEvent) => void;
    password: string;
    passwordError: boolean;
    handlePasswordChange: (event: ChangeEvent) => void;
    handleSignIn: (event: FormEvent) => void;
    handleForgotPassword: () => void;
    isBuyer?: boolean;
}

const LoginForm: FC<IProps> = ({
    email,
    emailError,
    handleEmailChange,
    password,
    passwordError,
    handlePasswordChange,
    handleSignIn,
    handleForgotPassword,
    isBuyer = false,
}) => {
    return (
        <form onSubmit={handleSignIn} noValidate>
            <TextField
                stacked
                id="transcepta-login-email"
                testId="login-page-email-input"
                label="Email"
                type="email"
                color="secondary"
                value={email}
                error={emailError}
                onChange={handleEmailChange}
                sx={{
                    pb: 1,
                    '.MuiInputLabel-shrink': {
                        top: -8,
                    },
                }}
            />
            <TextField
                stacked
                id="transcepta-login-password"
                testId="login-page-password-input"
                label="Password"
                type="password"
                color="secondary"
                value={password}
                error={passwordError}
                onChange={handlePasswordChange}
                sx={{
                    pb: 1,
                    '.MuiInputLabel-shrink': {
                        top: -8,
                    },
                }}
            />
            <Stack direction="row" justifyContent="space-between">
                <Button variant="secondary" onClick={handleForgotPassword} testid="login-page-forgot-password-button">
                    I forgot my password
                </Button>
                <Button type="submit" testid="login-page-sign-in-button">
                    Sign In
                </Button>
            </Stack>
            {isBuyer && (
                <Typography component="p" align="right" fontSize={12}>
                    Are you a Supplier/Vendor? Log in{' '}
                    <Link href="https://supplier1.transcepta.com/SupplierPortal/login">here</Link>.
                </Typography>
            )}
        </form>
    );
};

export default LoginForm;
