import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { BusinessDocType } from '../../../types';
import { DocumentRevisionsPopup } from './DocumentRevisionsPopup';
import { Button } from '../../../ui';

const useStyles = makeStyles(() => ({
    root: {
        padding: '0',
        fontWeight: 'normal',
        minWidth: '0',
    },
}));

export interface ShowRevisionsButtonProps {
    relatedDocumentId: number;
    relatedDocumentNumber: string;
    relatedDocumentType: BusinessDocType;
    currentDocumentType: BusinessDocType;
}

export const ShowRevisionsButton: React.FC<ShowRevisionsButtonProps> = ({
    relatedDocumentId,
    relatedDocumentNumber,
    relatedDocumentType,
    currentDocumentType,
}) => {
    const classes = useStyles();
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    return (
        <>
            {isPopupOpen && (
                <DocumentRevisionsPopup
                    isPopupOpen={isPopupOpen}
                    handleOnClosePopup={() => setIsPopupOpen(false)}
                    relatedDocumentId={relatedDocumentId}
                    relatedDocumentType={relatedDocumentType}
                    relatedDocumentNumber={relatedDocumentNumber}
                    currentDocumentType={currentDocumentType}
                />
            )}
            <Button variant="text" className={classes.root} onClick={() => setIsPopupOpen(true)}>
                {relatedDocumentNumber}
            </Button>
        </>
    );
};
