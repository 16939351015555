// DEPRECATED: Do not use
// REFACTOR: Move inside Organisms/Grid since that is the only place that uses it.
import { FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import { Card } from '../Card/Card';
import { DataRowField } from './DataRowField';
import './DataRow.scss';
import { CheckboxCell } from './CheckboxCell';

export type ConfigProps = {
    name: string;
    key: string;
    type: string;
    desktopRenderer?: any;
};

export interface IDataRowProps {
    /**
     * And configuratoin object array
     * {name:string, key:string, type:string, desktopRenderer?: any}[]
     */
    config: any;
    record: any;
    isDesktop: boolean;
    onRowClick?: (record: any) => void;
    isLastClicked?: boolean;
}

/**
 * @deprecated
 */
export const DataRow: FC<IDataRowProps> = ({ config, record, isDesktop, onRowClick, isLastClicked }) => {
    const [configState, setConfigState] = useState(config);

    useEffect(() => {
        setConfigState(config);
    }, [config]);

    const onClickHandler = () => (isDesktop && onRowClick ? onRowClick(record) : '');

    return (
        <div
            data-testid="DataRow"
            className={clsx('core-data-row', {
                'row-is-link': isDesktop && onRowClick,
                'row-is-last-clicked': isLastClicked,
                'is-mobile': !isDesktop,
            })}
            role="button"
            onClick={onClickHandler}
            tabIndex={0}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    onClickHandler();
                }
            }}
        >
            {isDesktop ? (
                <>
                    <CheckboxCell record={record} />
                    {config.map((col) => (
                        <div className="data-column">
                            <DataRowField record={record} col={col} />
                        </div>
                    ))}
                </>
            ) : (
                <Card
                    header={
                        <>
                            <CheckboxCell record={record} />
                            {configState.slice(0, 2).map((col) => (
                                <div className="data-column">
                                    <p className="column-name">{col.name}:</p>
                                    <DataRowField record={record} col={col} />
                                </div>
                            ))}
                        </>
                    }
                    isExpanded={false}
                    renderBodyContent={configState.slice(2).map((col) => (
                        <div className="content data-column">
                            <p className="column-name">{col.name}:</p>
                            <DataRowField record={record} col={col} />
                        </div>
                    ))}
                    handleAction={() => onRowClick && onRowClick(record)}
                    classNameContent={isLastClicked ? 'row-is-last-clicked' : ''}
                />
            )}
        </div>
    );
};
