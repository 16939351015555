import { Typography, ScopedCssBaseline } from '@mui/material';
import { hasAssociatedErrors, hasUnassociatedErrors } from './utils';
import { useDocumentEditValidator, useStatusMessageTemplates } from '../../DocumentEditValidator';
import { DocumentEditValidator, MessageClass, MessageClasses } from '../../types/private';
import useStyles from './useStyles';
import { ErrorList } from '../../Components';
import { Alert, AlertSeverity } from '../../../../ui';
import * as ErrorCode from '../../../../reusableFeatures/ErrorCode';
import { EmbedHTML, ISafeHTMLString } from '../../../../utils';
import { useMessageClassesContext } from '../../MessageClassesProvider';

interface IStatusMessageProps {
    message: ISafeHTMLString;
    severity: AlertSeverity;
    loading?: boolean;
}

function StatusMessage({ message, severity, loading }: IStatusMessageProps) {
    return (
        <Alert severity={severity} loading={loading} testId="DocumentEditValidatorStatus">
            <EmbedHTML
                component={Typography}
                variant="body2"
                data-testid="DocumentEditValidatorStatus--StatusText"
                safeHTMLString={message}
            />
        </Alert>
    );
}

interface IMessageClassAlertProps {
    messageClass: MessageClass;

    messageClassType: keyof MessageClasses;
}

function MessageClassAlert({ messageClass, messageClassType }: IMessageClassAlertProps) {
    const messageTemplates = useStatusMessageTemplates();

    const severity = (
        {
            errorMessages: 'error',
            warningMessages: 'warning',
        } as const
    )[messageClassType];

    const type = (
        {
            errorMessages: 'Error',
            warningMessages: 'Warning',
        } as const
    )[messageClassType];

    const testId = `DocumentEditValidatorStatus-${type}`;

    if (!hasAssociatedErrors(messageClass) && !hasUnassociatedErrors(messageClass)) {
        return null;
    }

    if (!hasUnassociatedErrors(messageClass)) {
        return (
            <Alert severity={severity} testId={testId} sx={{ mb: 2 }}>
                <EmbedHTML
                    component={Typography}
                    variant="body2"
                    data-testid={`${testId}--StatusText`}
                    safeHTMLString={messageTemplates[`validationOnlyInline${type}sNoticeMessage`]}
                />
            </Alert>
        );
    }

    return (
        <Alert severity={severity} testId={testId} sx={{ mb: 2 }}>
            {hasUnassociatedErrors(messageClass) && (
                <EmbedHTML
                    component={Typography}
                    variant="body2"
                    data-testid={`${testId}--StatusText`}
                    safeHTMLString={messageTemplates[`validation${type}ListPrefixMessage`]}
                />
            )}
            <ErrorList errors={messageClass.messages} color="inherit" testIdPrefix={`${testId}--List`} />
            {hasAssociatedErrors(messageClass) && (
                <EmbedHTML
                    component={Typography}
                    variant="body2"
                    data-testid={`${testId}--StatusText`}
                    safeHTMLString={messageTemplates[`validationAdditionalInline${type}sNoticeMessage`]}
                />
            )}
        </Alert>
    );
}

interface IMessageClassAlertsProps {
    messageClasses: MessageClasses;
}

function MessageClassAlerts({ messageClasses }: IMessageClassAlertsProps) {
    return (
        <>
            <MessageClassAlert messageClass={messageClasses.errorMessages} messageClassType="errorMessages" />
            <MessageClassAlert messageClass={messageClasses.warningMessages} messageClassType="warningMessages" />
        </>
    );
}

export interface IDocumentEditValidatorStatusProps {
    validator: DocumentEditValidator;
}

export function DocumentEditValidatorStatus({ validator }: IDocumentEditValidatorStatusProps) {
    const messageTemplates = useStatusMessageTemplates();

    const messageClasses = useMessageClassesContext();
    const { validatorState, validatorResultState } = validator;

    if (validatorState.type === 'LoadingState') {
        return <StatusMessage severity="info" message={messageTemplates.loadingMessage} loading />;
    }

    if (validatorState.type === 'FailedToLoadState') {
        const errorInfo = validatorState.errorInfo;

        return <ErrorCode.FriendlyErrorMessage externalErrorInfo={errorInfo} testId="DocumentEditValidatorStatus" />;
    }

    if (validatorResultState.type === 'UninitializedState') {
        return <StatusMessage severity="info" message={messageTemplates.loadingMessage} loading />;
    }

    if (validatorResultState.type === 'IdleState') {
        return null;
    }

    if (validatorResultState.type === 'ValidatingState') {
        return <StatusMessage severity="info" message={messageTemplates.validatingMessage} loading />;
    }

    if (validatorResultState.type === 'ExecutingDocumentControllersState') {
        return <StatusMessage severity="info" message={messageTemplates.executingDocumentControllersMessage} loading />;
    }

    if (
        validatorResultState.type === 'ValidationFunctionInvalidResponseState' ||
        validatorResultState.type === 'ValidationImplementationErrorState'
    ) {
        const errorInfo = validatorResultState.errorInfo;

        return <ErrorCode.FriendlyErrorMessage externalErrorInfo={errorInfo} testId="DocumentEditValidatorStatus" />;
    }

    return <MessageClassAlerts messageClasses={messageClasses} />;
}

const DocumentEditValidatorStatusWithBaseline = () => {
    const classes = useStyles();
    const validator = useDocumentEditValidator();

    return (
        <div className={classes.root}>
            <ScopedCssBaseline>
                <DocumentEditValidatorStatus validator={validator} />
            </ScopedCssBaseline>
        </div>
    );
};

export default DocumentEditValidatorStatusWithBaseline;
