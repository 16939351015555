import clsx from 'clsx';
import useStyles from './useStyles';

export interface ErrorListProps {
    errors: string[];
    testIdPrefix: string;
    color?: string;
}

export default function ErrorList({ errors, color, testIdPrefix }: ErrorListProps) {
    const classes = useStyles({ color });

    if (errors.length === 0) {
        return null;
    }

    return (
        <ul className={clsx(classes.root, classes.fieldErrors)} data-testid={`${testIdPrefix}--ErrorList`}>
            {errors.map((e, i) => (
                /* eslint-disable react/no-array-index-key */
                <li
                    className={clsx(classes.error, classes.errorItem)}
                    key={i}
                    data-testid={`${testIdPrefix}--ErrorList--ErrorItem`}
                >
                    {e}
                </li>
                /* eslint-enable */
            ))}
        </ul>
    );
}
