import { useEffect, useMemo, useState } from 'react';
import ValidationService from './ValidationService';
import { setupPluginHost, cleanupPluginHost } from '../PluginHost';
import {
    DocumentEditValidationState,
    ValidatorState,
    createLoadingState,
    createReadyState,
    createFailedToLoadState,
    DocumentEditValidationConfiguration,
    IInternalRule,
} from '../types/private';
import { useDocumentEditConfiguration } from '../Configuration';
import { useStableReference } from '../../../utils';
import DropdownOptionsRepository from '../PluginHost/services/dropdownOptionsRepository';

/**
 * The return type of `useValidationService`.
 */
export interface IValidationServiceState {
    validatorState: ValidatorState;

    validationService: ValidationService;

    configuration: DocumentEditValidationConfiguration;
}

/**
 * Low level hook that implements the core document validation lifecycle. Not suitable
 * for direct use in application components.
 *
 * Handles clean-up and re-initialization as needed.
 *
 * @param documentState current document state
 * @returns an object to run validations.
 */
export default function useValidationService(
    documentState: DocumentEditValidationState | undefined,
    getInternalRules: () => IInternalRule[] | undefined,
    dropdownOptionsRepository: DropdownOptionsRepository
): IValidationServiceState {
    const [validatorState, setValidatorState] = useState<ValidatorState>(createLoadingState());
    const { configuration, configurationLoaded } = useDocumentEditConfiguration(
        documentState,
        setValidatorState,
        getInternalRules
    );
    const docState = useStableReference(documentState);

    // we need to construct a new validation service, with a stable reference,
    // whenever the configuration changes.
    const validationService = useMemo(() => {
        const service = new ValidationService(configuration, dropdownOptionsRepository);
        return service;
    }, [configuration, dropdownOptionsRepository]);

    useEffect(() => {
        // prevents race-conditions when configuration hasn't loaded yet which would otherwise
        // potentially trigger this effect to run while loading configuration.
        if (!configurationLoaded) {
            return undefined;
        }

        // set the loading state first
        setValidatorState(createLoadingState());

        // register handlers for different events and collect their dispose functions (functions that cancel subscriptions)
        const subscriptionDisposers = [
            validationService.onAllFunctionsRegistered(() => {
                setValidatorState(createReadyState());
            }),
            validationService.onScriptFailedToLoad((reason) => {
                setValidatorState(createFailedToLoadState(reason));
            }),
        ];

        // hook up the validation service so scripts can access it and so that it can intercept
        // API calls
        setupPluginHost(validationService);

        // load the scripts
        validationService.loadScripts();

        return () => {
            // dispose the subscriptions to the various event handlers on the service
            subscriptionDisposers.forEach((dispose) => dispose());
            // unhook the API call interceptor
            cleanupPluginHost();
        };
    }, [validationService, configurationLoaded]);

    useEffect(() => {
        validationService.setCurrentDocumentState(docState);
    }, [validationService, docState]);

    return {
        validatorState,
        validationService,
        configuration,
    };
}
