import { useCurrentUser } from './useCurrentUser';
import { Permission } from '../types';

export function useCurrentUserCan(permission: Permission) {
    const currentUser = useCurrentUser();
    if (!currentUser) {
        return false;
    }

    return currentUser.permissions.has(permission);
}
