import { QueryClient } from '@tanstack/react-query';
import { CompanyThunk } from '../../../../transcepta-thunks';
import mapCompanyCustomData from './mapCompanyCustomData';
import mapCompanyGLAccount from './mapCompanyGLAccount';
import { ISegment } from '../types';
import { portalUserService } from '../../../../../services';

const queryOptions = {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    cacheTime: 24 * 60 * 60 * 1000, // 24 hours
};

/**
 * Fetches company custom data.
 *
 * @param {Object} params - The parameters for fetching company custom data.
 * @param {number} params.companyId - The ID of the company.
 * @param {string} params.datasetName - The name of the dataset.
 * @param {string} params.datasetValuePath - The path to the dataset value.
 * @param {number} [params.childCompanyId] - The optional ID of the child company.
 * @returns {Promise<Array>} The list of company custom data.
 * @throws {Error} If the fetch operation fails.
 */
export async function fetchCompanyCustomData(params: {
    companyId: number;
    datasetName: string | undefined;
    datasetValuePath: string | undefined;
    childCompanyId?: number;
}) {
    const companyThunk = new CompanyThunk();
    const response = await companyThunk.fetchCompanyCustomDataSet(params);
    if ('type' in response) {
        throw new Error(response.text);
    }
    const options = mapCompanyCustomData(response.data, params.datasetValuePath ?? '');
    return options;
}

/**
 * Fetches GL accounts for a company.
 *
 * @param params - The parameters for fetching GL accounts.
 * @param params.companyId - The ID of the company.
 * @param params.childCompanyId - The ID of the child company (optional).
 * @returns An array of GL account options.
 */
export async function fetchCompanyGLAccounts(params: { companyId: number; childCompanyId?: number }) {
    const companyThunk = new CompanyThunk();
    const response = await companyThunk.fetchCompanyGLAccounts(params);
    if ('type' in response) {
        throw new Error(response.text);
    }
    // eslint-disable-line @typescript-eslint/no-explicit-any
    const options = response.data.map((d: any) => mapCompanyGLAccount(d)); // TODO: define type
    return options;
}

/**
 * Creates arguments for fetching company custom data. Uses child company ID if available (to avoid prop drilling for GL coding components).
 *
 * @param datasetName - The name of the dataset.
 * @param datasetPath - The path of the dataset.
 * @param companyId - The ID of the company.
 * @returns An array of arguments for fetching company custom data.
 */
export function createFetchCompanyCustomDataArgs(
    datasetName: string | undefined,
    datasetPath: string | undefined,
    companyId: number
) {
    // for multi-company support
    const childCompanyId = portalUserService.getCompanyChildrenSelection()?.selectedChild?.value ?? null;
    const params = {
        companyId,
        datasetName,
        datasetValuePath: datasetPath,
        ...(childCompanyId !== null && { childCompanyId }),
    };

    return [
        ['GLCompanyCustomDataInput', datasetName, datasetPath, companyId, childCompanyId],
        () => fetchCompanyCustomData(params),
        queryOptions,
    ] as const;
}

/**
 * Creates the arguments required to fetch company GL accounts data. Uses child company ID if available (to avoid prop drilling for GL coding components).
 *
 * @param {ISegment} seg - The segment object containing data type information.
 * @param {number} companyId - The ID of the company.
 *
 * @returns {readonly [string[], () => Promise<any>, object]} The arguments required for the query client prefetch.
 * @returns {string[]} return.0 - The query key array.
 * @returns {Function} return.1 - The function to fetch company GL accounts data.
 * @returns {object} return.2 - The query options.
 */
export function createFetchCompanyGLAccountsDataArgs(seg: ISegment, companyId: number) {
    // for multi-company support
    const childCompanyId = portalUserService.getCompanyChildrenSelection()?.selectedChild?.value ?? null;
    const params = {
        companyId,
        ...(childCompanyId !== null && { childCompanyId }),
    };

    return [
        ['GLCompanyGLAccountsDataInput', seg.DataType, companyId, childCompanyId],
        () => fetchCompanyGLAccounts(params),
        queryOptions,
    ] as const;
}

export interface IGLCodeDataType {
    CompanyGLAccount: string;
    CompanyCustomData: string;
}

/**
 * Prefetches GL segment data. Internally handles child company ID for multi-company support.
 *
 * @param queryClient - The query client.
 * @param glCodeDataType - The GL code data type.
 * @param glSegments - The GL segments.
 * @param companyId - The company ID.
 */
export function prefetchGLSegmentData(
    queryClient: QueryClient,
    glCodeDataType: IGLCodeDataType,
    glSegments: ISegment[],
    companyId: number
) {
    glSegments.forEach((seg) => {
        if (seg.DataType === glCodeDataType.CompanyCustomData && !seg.CompanyCustomDatasetFieldDefinitionKey) {
            queryClient.prefetchQuery(
                ...createFetchCompanyCustomDataArgs(
                    seg.CompanyCustomDatasetName,
                    seg.CompanyCustomDatasetPath,
                    companyId
                )
            );
        }

        if (seg.DataType === glCodeDataType.CompanyGLAccount) {
            queryClient.prefetchQuery(...createFetchCompanyGLAccountsDataArgs(seg, companyId));
        }
    });
}
