import axios from 'axios';
import { EventEmitter } from '../../../utils';
import {
    DocumentEditValidationFunction,
    DocumentEditValidationFunctionParams,
    ValidationResults,
    IDocumentEditValidationFunctionResults,
    DocumentValidationEvent,
    ValidationError,
    EntityMutationFunction,
    Entities,
    EntityType,
    entityTypes,
    IDropdownOptionsFetcher,
    UIDocumentController,
    DocumentState,
    IDropdownOptionsRepository,
} from '../proservContractTypes';
import { ValidationFunctionProgrammingError } from '../DocumentEditValidator/errors';
import {
    DocumentEditValidationConfiguration,
    FailedToLoadState,
    DocumentEditValidationState,
    IDropdownOptionsFetcherRegistration,
    IUIDocumentControllerRegistration,
    IFieldControlStates,
    IInternalRule,
    ValidationFunctionScript,
    SetDocumentState,
} from '../types/private';
import { getPluginHostObject } from '../PluginHost';
import associatedFieldService from '../PluginHost/services/associatedFieldService';
import { DocumentFieldController } from './DocumentFieldController';

function isDefined<T>(x: T | undefined): x is T {
    return !!x;
}

class ValidationResultFormatError extends Error {
    constructor(message: string) {
        super(message);
        Object.setPrototypeOf(this, ValidationResultFormatError.prototype);
    }
}

class ValidationResultSemanticsError extends Error {
    constructor(message: string) {
        super(message);
        Object.setPrototypeOf(this, ValidationResultSemanticsError.prototype);
    }
}

class DocumentEditValidationFunctionResults implements IDocumentEditValidationFunctionResults {
    validationResults: ValidationResults = [];

    constructor(private documentEditValidationConfiguration: DocumentEditValidationConfiguration) {}

    addError(error: ValidationError): void {
        this.validateError(error);
        this.validationResults.push({
            type: 'ErrorResult',
            error,
        });
    }

    addWarning(error: ValidationError): void {
        this.validateError(error);
        this.validationResults.push({
            type: 'WarningResult',
            error,
        });
    }

    private validateError(error: any) {
        this.validateErrorSemantics(this.validateErrorFormat(error));
    }

    private validateErrorFormat(error: any) {
        if (typeof error !== 'object') {
            throw new ValidationResultFormatError(`value is not a ValidationError (not an object).`);
        }

        if (error === null) {
            throw new ValidationResultFormatError(`value is not a ValidationError (is null).`);
        }

        if (typeof error.associatedField !== 'undefined' && typeof error.associatedField !== 'string') {
            throw new ValidationResultFormatError(
                `value is not a ValidationError (its 'associatedField' property must either be undefined or a string)`
            );
        }

        if (typeof error.errorCode !== 'string') {
            throw new ValidationResultFormatError(
                `value is not a ValidationError (its 'errorCode' property must be a string)`
            );
        }

        if (!Array.isArray(error.detailArguments)) {
            throw new ValidationResultFormatError(
                `value is not a ValidationError (its 'detailArguments' property must be an array)`
            );
        }

        if (!Array.isArray(error.titleArguments)) {
            throw new ValidationResultFormatError(
                `value is not a ValidationError (its 'titleArguments' property must be an array)`
            );
        }

        return error as ValidationError;
    }

    private validateErrorSemantics(error: ValidationError) {
        const { fieldNamesToValidateOnFocusLoss } = this.documentEditValidationConfiguration;

        if (error.associatedField) {
            const fieldName = associatedFieldService.getFieldName(error.associatedField);
            if (!fieldNamesToValidateOnFocusLoss.includes(fieldName)) {
                throw new ValidationResultSemanticsError(
                    `the field "${fieldName}" is not validated on focus loss, so errors cannot be associated with it`
                );
            }
        }
    }
}

function getScriptSrc(src: string) {
    // if we are on dev server, we will have an issue retrieving the script due to CORS errors generated by the browser.
    // Cypress intercepts were working because they ignore CORS, but the scripts were not working directly in the portal
    // on the dev server. this code will extract the path to the script and then append that to the current origin. the
    // proxy configured by the dev server will then make the request to the actual API server being connected to, and return
    // the correct file contents. since the application makes the request on the current origin, the browser won't generate any
    // CORS errors and prevent reading the response.
    if (window.location.href.startsWith('http://localhost')) {
        const matches = src.match(/(\/v1\.0.*)$/);
        if (!matches) {
            throw new Error('Could not extract pathname from script url');
        }
        return `${window.location.origin}${matches[1]}`;
    }

    return src;
}

/**
 * Contains the logic to load all scripts for a BuyerCustomJSRuleMapping and execute them to register
 * with the application.
 */
class ScriptLoader {
    private scriptFailedToLoad = new EventEmitter<FailedToLoadState['reason']>();

    /**
     * Loads all configured scripts.
     */
    public loadScripts(
        documentEditValidationConfiguration: DocumentEditValidationConfiguration,
        service: ValidationService,
        dropdownOptionsRepository: IDropdownOptionsRepository
    ) {
        return new Promise((res) => {
            const { validationFunctions } = documentEditValidationConfiguration;
            if (!validationFunctions) {
                return;
            }

            Promise.all(
                validationFunctions.map(async (rule) => {
                    if ('src' in rule) {
                        return this.loadScript(rule.name, rule.src, service, dropdownOptionsRepository);
                    } else {
                        return this.loadInternalRule(rule, service, dropdownOptionsRepository);
                    }
                })
            ).then(() => {
                setTimeout(res);
            });
        });
    }

    private loadInternalRule(
        rule: IInternalRule,
        service: ValidationService,
        dropdownOptionsRepository: IDropdownOptionsRepository
    ) {
        try {
            rule.logic(getPluginHostObject(service, rule.name, dropdownOptionsRepository));

            setTimeout(() => {
                if (!service.isScriptFullyRegistered(rule.name)) {
                    this.errorOccurred('InternalRuleFailedToLoadError');
                    console.error(
                        'An InternalRule failed to register before event loop finished.',
                        'Rule Info: ',
                        rule
                    );
                }
            });
        } catch (e) {
            this.errorOccurred('InternalRuleFailedToLoadError');
            console.error('An InternalRule failed to execute during loading', e, 'Rule info: ', rule);
        }
    }

    private loadScript(
        name: string,
        src: string,
        service: ValidationService,
        dropdownOptionsRepository: IDropdownOptionsRepository
    ) {
        const api = axios.create();
        return api.get(getScriptSrc(src)).then(
            (x) => {
                const scriptData = this.wrapScript(x.data);
                try {
                    eval(scriptData)(getPluginHostObject(service, name, dropdownOptionsRepository));

                    setTimeout(() => {
                        if (!service.isScriptFullyRegistered(name)) {
                            this.errorOccurred('ScriptParseError');
                            console.error(
                                'Document edit validation script failed to register before event loop finished.',
                                'Script Info: ',
                                { name, src }
                            );
                        }
                    });
                } catch (e) {
                    this.errorOccurred('ScriptParseError');
                    console.error('A Document Edit Rules script failed to execute during loading', e, 'Script Info: ', {
                        name,
                        src,
                    });
                }
            },
            (err) => {
                this.errorOccurred('ScriptLoadNetworkError');
                console.error('Document edit validation script could not be loaded.', err, 'Script Info: ', {
                    name,
                    src,
                });
            }
        );
    }

    private wrapScript(data: string) {
        return `(function (app) {
            ${data}
        })`;
    }

    /**
     * Listen for script load failure.
     * @param cb function to call if any script fails to load
     * @returns a function to call to dispose the subscription.
     */
    public onScriptFailedToLoad(cb: (reason: FailedToLoadState['reason']) => void) {
        return this.scriptFailedToLoad.subscribe(cb);
    }

    public errorOccurred(reason: FailedToLoadState['reason']) {
        this.scriptFailedToLoad.notify(reason);
    }
}

/**
 * Implements the core business logic for the document edit validator functionality.
 */
export default class ValidationService {
    private scriptLoader = new ScriptLoader();

    private functionOrder: string[] = [];

    private functions: { [name: string]: DocumentEditValidationFunction | undefined } = {};

    private mutationFunctions: { [type in EntityType]?: { [name: string]: EntityMutationFunction<type> | undefined } } =
        {};

    private allFunctionsRegistered = new EventEmitter();

    private mutationFailed = new EventEmitter();

    private implementationErrorOccurred = new EventEmitter();

    private hasRanValidations = false;

    private currentDocumentState: DocumentEditValidationState | undefined;

    private dropdownFetchers: {
        [fieldDefinitionKey: string]: IDropdownOptionsFetcherRegistration | undefined;
    } = {};

    private uiControllers: {
        [name: string]: IUIDocumentControllerRegistration[] | undefined;
    } = {};

    constructor(
        private documentEditValidationConfiguration: DocumentEditValidationConfiguration,
        private dropdownOptionsRepository: IDropdownOptionsRepository
    ) {}

    /**
     * Indicates whether or not any validation functions were registered.
     */
    public get hasValidations() {
        return this.validators.length > 0;
    }

    public get hasUIDocumentControllers() {
        return Object.keys(this.uiControllers).length > 0;
    }

    /**
     * Loads all configured scripts.
     */
    public loadScripts() {
        this.functionOrder = (this.documentEditValidationConfiguration.validationFunctions ?? []).map((x) => x.name);
        this.scriptLoader
            .loadScripts(this.documentEditValidationConfiguration, this, this.dropdownOptionsRepository)
            .then(() => this.notifyObserversWhenAllFunctionsRegistered());
    }

    public isScriptFullyRegistered(scriptName: string) {
        return (
            !!this.functions[scriptName] ||
            this.hasMutationFunction(scriptName) ||
            this.hasDropdownFetcher(scriptName) ||
            this.hasUIController(scriptName)
        );
    }

    public setCurrentDocumentState(documentState: DocumentEditValidationState | undefined) {
        this.currentDocumentState = documentState;
    }

    public registerDropdownOptionsFetcher(name: string, fetcher: IDropdownOptionsFetcher) {
        const existingRegistration = this.dropdownFetchers[fetcher.fieldDefinitionKey];
        if (existingRegistration) {
            console.error(
                'ValidationService - Multiple scripts (or the same script multiple times) attempted to register a dropdown fetcher for the same `fieldDefinitionKey`.',
                {
                    alreadyRegisteredBy: existingRegistration.ruleName,
                    attemptedToRegisterBy: name,
                }
            );
            this.scriptLoader.errorOccurred('ScriptParseError');
            return;
        }

        this.dropdownFetchers[fetcher.fieldDefinitionKey] = {
            ruleName: name,
            fetcher,
        };
    }

    public getDropdownOptionsFetcher(fieldDefinitionKey: string) {
        return this.dropdownFetchers[fieldDefinitionKey];
    }

    public getDropdownOptionsRepository() {
        return this.dropdownOptionsRepository;
    }

    public registerUIDocumentController(name: string, controller: UIDocumentController) {
        let controllerRegistrations = this.uiControllers[name];

        if (!controllerRegistrations) {
            controllerRegistrations = [];
            this.uiControllers[name] = controllerRegistrations;
        }

        controllerRegistrations.push({
            ruleName: name,
            runController: controller,
        });
    }

    public async executeDocumentControllers(
        context: DocumentEditValidationState,
        setFieldControlStates: (fieldControlStates: IFieldControlStates) => void,
        setDocumentState: SetDocumentState
    ) {
        const { documentState } = context;

        const initialDocumentState = JSON.parse(JSON.stringify(documentState)) as DocumentState;
        const mutableDocumentState = JSON.parse(JSON.stringify(documentState)) as DocumentState;
        const documentFieldController = new DocumentFieldController();

        const controllerRegistrations = this.getUIDocumentControllerRegistrations();

        // eslint-disable-next-line no-restricted-syntax
        for (const registration of controllerRegistrations) {
            try {
                // eslint-disable-next-line no-await-in-loop
                await registration.runController(context, mutableDocumentState, documentFieldController);
            } catch (e) {
                const { validationFunctions } = this.documentEditValidationConfiguration;
                console.error(
                    "ValidationService - A programming error occurred in a rule script's UIDocumentController function and has been logged here for the proserv developer",
                    e,
                    (validationFunctions! as (IInternalRule | ValidationFunctionScript)[]).find(
                        (x) => x.name === registration.ruleName
                    )
                );

                throw e;
            }
        }

        setDocumentState(initialDocumentState, mutableDocumentState);
        setFieldControlStates(documentFieldController.getFieldControlStates());
    }

    /**
     * Should be called by a validation script to register its validation function after
     * it loads.
     * @param name name of the validation script
     * @param fn validation function
     */
    public registerValidatorFunction(name: string, fn: DocumentEditValidationFunction) {
        this.functions[name] = fn;
        setTimeout(() => this.notifyObserversWhenAllFunctionsRegistered());
    }

    /**
     * Should be called by a validation script to register its entity mutation function after
     * it loads.
     * @param name name of the validation script
     * @param entityType the type of entity to mutate
     * @param fn entity mutation function
     */
    public registerEntityMutation<K extends EntityType>(name: string, entityType: K, fn: EntityMutationFunction<K>) {
        if (!entityTypes.includes(entityType)) {
            this.scriptLoader.errorOccurred('ScriptParseError');
            console.error(
                'Document edit validation script attempted to register mutation for unknown entity type:',
                entityType,
                'Script Info: ',
                { name }
            );
            return;
        }

        const functions = this.getMutationFunctions(entityType);
        functions[name] = fn;
        setTimeout(() => this.notifyObserversWhenAllFunctionsRegistered());
    }

    /**
     * Listen for all validation functions to be registered with the service.
     * @param cb function to call after all validation functions have been registered.
     * @returns a function to call to dispose the subscription.
     */
    public onAllFunctionsRegistered(cb: () => void) {
        return this.allFunctionsRegistered.subscribe(cb);
    }

    /**
     * Listen for script load failure.
     * @param cb function to call if any script fails to load
     * @returns a function to call to dispose the subscription.
     */
    public onScriptFailedToLoad(cb: (reason: FailedToLoadState['reason']) => void) {
        return this.scriptLoader.onScriptFailedToLoad(cb);
    }

    /**
     * Listen for a failing mutation
     * @param cb function to call if a mutation fails.
     * @returns a function to call to dispose the subscription.
     */
    public onMutationFailed(cb: () => void) {
        return this.mutationFailed.subscribe(cb);
    }

    /**
     * Listen for an implementation error occurring.
     * @param cb function to call if an implementation error occurs.
     * @returns a function to call to dispose the subscription.
     */
    public onImplementationErrorOccurred(cb: () => void) {
        return this.implementationErrorOccurred.subscribe(cb);
    }

    /**
     * Mutates a copy of the provided entities and returns them.
     * @param entityType the type of entity being mutated
     * @param entity the entity to mutate
     * @returns the mutated entity
     */
    public async mutate<K extends EntityType>(entityType: K, entity: Entities[K]) {
        const newEntity = JSON.parse(JSON.stringify(entity)) as Entities[K];

        if (!this.currentDocumentState) {
            console.error('ValidationService - Current context was not available when mutations were executed');
            this.implementationErrorOccurred.notify();
            throw new Error('Current context was not available when mutations were executed');
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const [index, mutation] of this.getMutationFunctionsInOrder(entityType).entries()) {
            try {
                // eslint-disable-next-line no-await-in-loop
                await mutation(newEntity, this.currentDocumentState);
            } catch (e) {
                const { validationFunctions } = this.documentEditValidationConfiguration;
                console.error(
                    "ValidationService - A programming error occurred in a validation script's mutation function and has been logged here for the proserv developer",
                    e,
                    validationFunctions![index]
                );

                this.mutationFailed.notify();
                throw e;
            }
        }

        return newEntity;
    }

    /**
     * Validates the document edit on some event.
     *
     * May reject with one of these exceptions:
     *   - ValidationFunctionProgrammingError: The proserv developer's validation script threw an error
     *
     * @param params the document edit state and event.
     * @returns a promise for the validation response
     */
    public async validate(params: DocumentEditValidationFunctionParams) {
        this.hasRanValidations = true;

        let results: ValidationResults = [];

        // This is by far the cleanest way to run the validators in sequence (which is a business requirement).
        // eslint-disable-next-line no-restricted-syntax
        for (const [i, validator] of this.validators.entries()) {
            const resultsCollector = new DocumentEditValidationFunctionResults(
                this.documentEditValidationConfiguration
            );
            // eslint-disable-next-line no-await-in-loop
            await this.callValidator(validator, i, params, resultsCollector);
            results = results.concat(resultsCollector.validationResults);
        }

        return results;
    }

    private async callValidator(
        validator: DocumentEditValidationFunction,
        index: number,
        params: DocumentEditValidationFunctionParams,
        resultsCollector: IDocumentEditValidationFunctionResults
    ): Promise<void> {
        try {
            await validator(params, resultsCollector);
        } catch (e) {
            const { validationFunctions } = this.documentEditValidationConfiguration;
            console.error(
                'ValidationService - A programming error occurred in a validation script and has been logged here for the proserv developer',
                e,
                validationFunctions![index]
            );
            throw new ValidationFunctionProgrammingError(e, validationFunctions![index]);
        }
    }

    /**
     * Check if validation is required for an event.
     * @param event to check
     * @returns boolean indicating if validation is required
     */
    public isValidationNeeded(event: DocumentValidationEvent) {
        const { eventsToValidateOn, fieldNamesToValidateOnFocusLoss } = this.documentEditValidationConfiguration;

        if (!this.hasValidations) {
            return false;
        }

        if (!eventsToValidateOn.includes(event.type)) {
            return false;
        }

        if (event.type === 'FocusLossEvent' && !fieldNamesToValidateOnFocusLoss.includes(event.fieldName)) {
            return false;
        }

        if (event.type === 'FocusLossEvent' && !this.hasRanValidations) {
            return false;
        }

        return true;
    }

    private get validators() {
        const validators = this.functionOrder.map((x) => this.functions[x]).filter(isDefined);

        return validators;
    }

    private getMutationFunctionsInOrder<K extends EntityType>(entityType: K) {
        const functions = this.getMutationFunctions(entityType);
        const mutations = this.functionOrder.map((x) => functions[x]);

        return mutations.filter(isDefined);
    }

    private getMutationFunctions<K extends EntityType>(entityType: K) {
        if (!this.mutationFunctions[entityType]) {
            this.mutationFunctions[entityType] = {};
        }

        return this.mutationFunctions[entityType] as { [name: string]: EntityMutationFunction<K> | undefined };
    }

    private hasMutationFunction(scriptName: string) {
        return Object.keys(this.mutationFunctions).some((key) => {
            const functions = this.mutationFunctions[key as EntityType] || {};
            return !!functions[scriptName];
        });
    }

    private notifyObserversWhenAllFunctionsRegistered() {
        const allScriptsLoaded = this.functionOrder.every((scriptName) => {
            return this.isScriptFullyRegistered(scriptName);
        });

        if (allScriptsLoaded) {
            this.allFunctionsRegistered.notify();
        }
    }

    private hasDropdownFetcher(scriptName: string) {
        return Object.keys(this.dropdownFetchers).some((key) => this.dropdownFetchers[key]?.ruleName === scriptName);
    }

    private hasUIController(scriptName: string) {
        return !!this.uiControllers[scriptName];
    }

    private getUIDocumentControllerRegistrations() {
        return this.functionOrder.flatMap((x) => this.uiControllers[x] ?? []);
    }
}
