import { IDocumentViewModel } from '../../services/Document/Api';
import { BusinessDocType } from '../../types';
import { IDocumentAmountsViewModel } from './IDocumentAmountsViewModel';
import { InvoiceDocumentAmountsViewModel } from './InvoiceDocumentAmountsViewModel';

export class DocumentAmountsViewModelFactory {
    createDocumentAmountsViewModel(document: IDocumentViewModel): IDocumentAmountsViewModel {
        switch (document.BusinessDocType) {
            case BusinessDocType.Invoice:
                return new InvoiceDocumentAmountsViewModel(document);
            default:
                throw new Error(`Unsupported doc type ${document.BusinessDocType}`);
        }
    }
}

export const factory = new DocumentAmountsViewModelFactory();
