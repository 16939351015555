import { FC } from 'react';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles<Theme, { withBorder: boolean }>((theme) => ({
    root: {
        border: (props) => (props.withBorder ? `1px solid ${theme.palette.divider}` : 'none'),
    },
    paperBackgroundColor: {
        backgroundColor: theme.palette.background.paper,
    },
}));

interface IProps {
    index: number;
    value: number;
    whiteBg?: boolean;
    withBorder?: boolean;
    condensed?: boolean;
}

export const TabPanel: FC<IProps> = ({ index, value, children, whiteBg = false, withBorder = true, condensed }) => {
    const classes = useStyles({ withBorder });

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            className={clsx(classes.root, whiteBg ? classes.paperBackgroundColor : null)}
        >
            {value === index && <Box p={condensed ? 0 : 3}>{children}</Box>}
        </div>
    );
};
