import { useEffect, useState } from 'react';

export function useHasValueChanged(value: string) {
    const [originalValue] = useState(value);
    const [hasValueChanged, setHasValueChanged] = useState(false);

    useEffect(() => {
        setHasValueChanged(originalValue !== value);
    }, [originalValue, value]);

    return hasValueChanged;
}
