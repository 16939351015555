import { Box, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactNode } from 'react';
import { TransceptaLogoWhite } from '../../../ui/Logos/TransceptaLogoWhite';
import { TransceptaLogoDarkBlue } from '../../../ui/Logos/TransceptaLogoDarkBlue';

interface IFormTemplateProps {
    mainContent: ReactNode;
    marketingContent?: ReactNode;
    testId?: string;
}

export const FormTemplate = ({ mainContent, marketingContent, testId }: IFormTemplateProps) => {
    const theme = useTheme();
    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                flexDirection: 'column',
            }}
        >
            {theme.palette.mode === 'dark' ? <TransceptaLogoWhite /> : <TransceptaLogoDarkBlue />}
            <Paper sx={{ width: marketingContent ? 660 : 330, p: 2, display: 'flex' }}>
                {marketingContent ? <Box sx={{ width: 300, pr: 5 }}>{marketingContent}</Box> : null}
                <Box sx={{ width: 360, px: 2 }} data-testid={testId}>
                    {mainContent}
                </Box>
            </Paper>
        </Box>
    );
};
