//
import { push } from 'connected-react-router';
import * as ApplicationActions from '../actions/ApplicationActions';

/**
 * Toggling mobile menu
 */
export const toggleMobileMenu = () => (dispatch, getState) => {
    const state = getState();
    const { mobileMenuVisible } = state.appearance;
    if (mobileMenuVisible) dispatch(ApplicationActions.hideMobileMenu());
    else dispatch(ApplicationActions.showMobileMenu());
};

export const hideMobileMenu = () => (dispatch, getState) => {
    dispatch(ApplicationActions.hideMobileMenu());
};

export const navigateToPath = (pathToRedirect) => (dispatch, getState) => {
    dispatch(ApplicationActions.navigateToPath());
    dispatch(push(pathToRedirect));
};
