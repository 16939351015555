import { FETCHED_NOTIFICATIONS, FETCHED_LOGIN_NOTIFICATIONS } from '../actions/userNotifications';

const initialUserNotificationState = {
    portalMsgTypeNone: [],
    portalMsgTypeYesBlocking: [],
    portalMsgTypeYesNoBlocking: [],
};

const initialLoginNotificationState = {
    portalMsgTypeNone: [],
};

export function userNotifications(state = initialUserNotificationState, action) {
    switch (action.type) {
        case FETCHED_NOTIFICATIONS:
            return {
                ...state,
                portalMsgTypeNone: action.payload.portalMsgTypeNone,
                portalMsgTypeYesBlocking: action.payload.portalMsgTypeYesBlocking,
                portalMsgTypeYesNoBlocking: action.payload.portalMsgTypeYesNoBlocking,
            };
        default:
            return state;
    }
}

export function loginNotifications(state = initialLoginNotificationState, action) {
    switch (action.type) {
        case FETCHED_LOGIN_NOTIFICATIONS:
            return {
                ...state,
                portalMsgTypeNone: action.payload.portalMsgTypeNone,
            };
        default:
            return state;
    }
}
