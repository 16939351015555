import { SelectItem, Select, ISelectProps } from '../Select';
import { IBaseSelectProps, OptionType, useSelectOptionHelpers } from './selectHelpers';
import { ReadOnly } from '../ReadOnly';
import { useFieldsReadonly } from '../Form';
import { Box, BoxProps, SxProps, Theme } from '@mui/material';
import { renderTooltipLabel } from '../Tooltip';
import { ReactNode } from 'react';

export interface ISortedSelectProps<T, V> extends IBaseSelectProps<T, V>, Omit<ISelectProps, 'onChange' | 'value'> {
    nullOptionLabel?: string;
    prompt?: string;
    renderLabel?: (label: string) => ReactNode;
    inputLabelSx?: SxProps<Theme>;
    stacked?: boolean;
    labelBoxSx?: BoxProps['sx'];
    selectContainerSx?: BoxProps['sx'];
    error?: boolean;
    helperText?: string;
}

export function SortedSelect<T>({
    nullOptionLabel,
    options,
    prompt,
    label,
    labelId,
    id,
    value,
    name,
    onChange,
    disableSorting,
    sortComparisonFn,
    readonly,
    required,
    testId,
    tooltip,
    disabled,
    renderValue,
    renderLabel,
    inputLabelSx,
    stacked,
    labelBoxSx,
    selectContainerSx,
    error,
    helperText,
}: ISortedSelectProps<T, OptionType<T> | null>) {
    const { normalizedOptions, normalizedValue, handleChange } = useSelectOptionHelpers(
        value,
        options,
        onChange,
        disableSorting,
        sortComparisonFn
    );
    const isReadonly = useFieldsReadonly(readonly);

    const nullOption =
        typeof nullOptionLabel === 'string' ? (
            <SelectItem
                value={nullOptionLabel}
                sx={{
                    '& > .blank-label': {
                        height: '1em',
                    },
                }}
            >
                {nullOptionLabel.length ? nullOptionLabel : <div className="blank-label" />}
            </SelectItem>
        ) : null;
    const promptItem = prompt ? (
        <SelectItem value={prompt} disabled>
            {prompt}
        </SelectItem>
    ) : null;

    function handleValueChange(newValue: string) {
        if (newValue === prompt || newValue === nullOptionLabel) {
            handleChange(null);
        } else {
            handleChange(newValue);
        }
    }

    if (isReadonly) {
        return <ReadOnly testId={testId} label={label} value={normalizedValue?.label} />;
    }

    if (required) {
        label = `${label} *`;
    }

    return (
        <Box
            sx={
                stacked
                    ? { display: 'flex', flexDirection: 'column', width: '100%' }
                    : { display: 'flex', alignItems: 'center', width: '100%' }
            }
        >
            {label ? (
                <Box component="label" htmlFor={id}>
                    <Box sx={{ width: 160, ...labelBoxSx }}>{renderTooltipLabel({ tooltip, label })}</Box>
                </Box>
            ) : null}
            <Select
                testId={testId}
                disabled={disabled}
                id={id}
                labelId={labelId}
                value={normalizedValue?.label ?? nullOptionLabel ?? prompt ?? ''}
                name={name}
                onChange={(ev) => handleValueChange(ev.target.value as string)}
                required={required}
                renderValue={renderValue}
                inputLabelSx={inputLabelSx}
                selectContainerSx={selectContainerSx}
                error={error}
                helperText={helperText}
            >
                {promptItem}
                {nullOption}
                {normalizedOptions.map((option) => (
                    <SelectItem data-testid={`${testId}-${option.label}`} value={option.label} key={option.label}>
                        {renderLabel ? renderLabel(option.label) : option.label}
                    </SelectItem>
                ))}
            </Select>
        </Box>
    );
}
