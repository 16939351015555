import { Typography } from '@mui/material';
import { ReactNode } from 'react';
import { Button, Dialog } from '../../ui';
import { EmbedHTML } from '../../utils';

interface UserInfoModalProps {
    visible: boolean;
    hidePopup: () => void;
    title: ReactNode;
    innerText: string;
    maxWidth?: string;
}

export const UserInfoModal = ({ visible, hidePopup, title, innerText, maxWidth }: UserInfoModalProps) => (
    <Dialog
        open={visible}
        handleOnClose={hidePopup}
        title={title}
        content={
            <EmbedHTML
                component={Typography}
                safeHTMLString={{
                    type: 'SafeHTMLString',
                    value: innerText,
                }}
                sx={{
                    '& ul': {
                        paddingLeft: '30px',
                        ' > li': {
                            listStyle: 'initial',
                        },
                    },
                }}
            />
        }
        actions={<Button onClick={hidePopup}>Close</Button>}
        maxWidth={maxWidth}
    />
);
