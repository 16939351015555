import { createContext, useState, ReactNode, FC } from 'react';

export const ShortcutsStateContext = createContext({});

export const ShortcutsStateProvider: FC<{}> = ({ children }: { children?: ReactNode }) => {
    const [shouldUseCustomShortcuts] = useState(true);

    return (
        <ShortcutsStateContext.Provider value={{ shouldUseCustomShortcuts }}>{children}</ShortcutsStateContext.Provider>
    );
};
