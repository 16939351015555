export function IsUndefinedNullOrEmpty(str: string) {
    return str === undefined || str === null || str.length === 0;
}

export function IsNotUndefinedNullOrEmpty(str: string) {
    return str !== undefined && str !== null && str.length > 0;
}

export function IsUpperCase(str: string) {
    if (IsUndefinedNullOrEmpty(str)) {
        return false;
    }
    return str === str.toUpperCase();
}

export function IsLowerCase(str: string) {
    if (IsUndefinedNullOrEmpty(str)) {
        return false;
    }
    return str === str.toLowerCase();
}

export function AddNewLine(textBlock: string, newLine: string) {
    if (IsUndefinedNullOrEmpty(textBlock)) {
        return newLine;
    }
    return `${textBlock}\n${newLine}`;
}

export function EscapeXmlCharacters(str: string) {
    if (IsUndefinedNullOrEmpty(str)) {
        return str;
    }
    return str.replace('<', '&lt;').replace('>', '&gt;');
}

export function NewLineToHtml(str: string) {
    if (IsUndefinedNullOrEmpty(str)) {
        return str;
    }
    return str.replace(/(?:\r\n|\r|\n)/g, '<br/>');
}

export function ConvertLeadingSpacesToNonBreaking(str: string) {
    if (IsUndefinedNullOrEmpty(str)) {
        return str;
    }
    return str.replace(/ /g, '\u00a0');
}

export function getStringArraysPermutations(stringArrays: string[][]): string[] {
    const mixStringArrays = (accPermutations: string[] = [''], currentStringArrayIdx: number = 0): string[] => {
        const permutations: string[] = [];

        if (typeof stringArrays[currentStringArrayIdx] === 'undefined') {
            return accPermutations;
        }

        accPermutations.forEach((aString) =>
            stringArrays[currentStringArrayIdx].forEach((bString) => permutations.push(aString + bString))
        );

        return mixStringArrays(permutations, currentStringArrayIdx + 1);
    };

    return mixStringArrays();
}
