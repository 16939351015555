import { useEffect } from 'react';
import { UseMutationResult } from '@tanstack/react-query';

/**
 * Call a callback after a react-query mutation completes
 * @param mutation the mutation to observe
 * @param cb the callback to call after successful mutation
 */
export function useMutationSuccess<T = any>(
    mutation: UseMutationResult<T, any, any, any>,
    cb: ((data: T) => void) | undefined
) {
    useEffect(() => {
        if (mutation.isSuccess && cb) {
            cb(mutation.data);
        }
        // we want to ignore changes to `cb`
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mutation.data, mutation.isSuccess]);
}
