import { ComponentProps, useMemo } from 'react';
import {
    DataGrid as MuiDataGrid,
    GridCellMode,
    GridCellParams,
    GridColumns,
    GridRenderCellParams,
    GridRowModel,
    GridRowParams,
    GridSelectionModel,
    GridSortModel,
} from '@mui/x-data-grid';
import { useDataGridStyles } from './useDataGridStyles';
import { DataGridPagination } from '../DataGridPagination';
import { FCNC } from '../FCNC';
import { Box, Paper, useMediaQuery, useTheme } from '@mui/material';
import { DataGridMobileServer } from '../DataGridMobileServer';
import { range } from '../../utils';
import { Tooltip } from '../Tooltip';

type MuiDataGridProps = ComponentProps<typeof MuiDataGrid>;

interface BaseDataGridServerProps {
    /**
     * Number of rows for entire data grid
     */
    rowCount: number;

    /**
     * interface for what columns to show and what options for the row are avalible
     */
    columns: GridColumns;

    /**
     * Function for what row was clicked and what should happen
     *
     * @param param
     */
    onRowClick?: (param: GridRowParams) => void;

    onCellClick?: (param: GridCellParams) => void;

    pageSize: number;

    data: GridRowModel[] | undefined;

    error: unknown;

    isLoading: boolean;

    checkbox?: boolean;

    isRowSelectable?: (params: GridRowParams) => boolean;

    onSelectionModelChange?: any;

    selectionModel?: GridSelectionModel;

    testId?: string;

    getRowId?: MuiDataGridProps['getRowId'];
}

interface PaginatedDataGridServerProps {
    onPageSizeChange: (newPageSize: number) => void;

    page: number;

    onPageChange: (newPage: number) => void;

    rowsPerPageOptions: number[];

    noPagination?: false;
}

interface NonPaginatedDataGridServerProps {
    onPageSizeChange?: undefined;

    page?: undefined;

    onPageChange?: undefined;

    rowsPerPageOptions?: undefined;

    noPagination: true;
}

interface SortableDataGridServerProps {
    sortModel: GridSortModel;

    onSortModelChange: (newSortModel: GridSortModel) => void;
}

interface NonSortableDataGridServerProps {
    sortModel?: undefined;

    onSortModelChange?: undefined;
}

export type IDataGridServerProps = BaseDataGridServerProps &
    (PaginatedDataGridServerProps | NonPaginatedDataGridServerProps) &
    (SortableDataGridServerProps | NonSortableDataGridServerProps);

export const TOOLTIP_CELL_MODE = 'tooltip' as GridCellMode;

function getTextMaxWordWidth(text: string) {
    try {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const words = text.split(' ');
        if (context && words.length) {
            let font = '500 12px / 56px "Open Sans"';
            let letterSpacing = '0.4px';
            const columnHeaderTitle = document.getElementsByClassName('MuiDataGrid-columnHeaderTitle')[0];
            if (columnHeaderTitle) {
                const columnHeaderTitleStyles = getComputedStyle(columnHeaderTitle);
                font = columnHeaderTitleStyles.font;
                letterSpacing = columnHeaderTitleStyles.letterSpacing;
            }
            context.font = font;
            // @ts-ignore
            context.letterSpacing = letterSpacing;
            // calculate width of each word in title, and set maxWidth to the maximum word width found
            let maxWidth = 0;
            words.forEach((word) => {
                const measuredWordWidth = Math.ceil(context.measureText(word).width);
                if (maxWidth < measuredWordWidth) {
                    maxWidth = measuredWordWidth;
                }
            });
            return maxWidth;
        }
    } catch (error) {
        console.error(error);
    }
    return 0;
}

/**
 * Server Side Sorted and Paginated implementation of the MuiDataGrid
 */
export const DataGridServer: FCNC<IDataGridServerProps> = ({
    rowCount,
    columns,
    onRowClick,
    pageSize,
    page,
    sortModel,
    onPageSizeChange,
    onPageChange,
    onSortModelChange,
    data,
    error,
    isLoading,
    rowsPerPageOptions,
    checkbox,
    isRowSelectable,
    onSelectionModelChange,
    selectionModel,
    testId,
    getRowId,
    onCellClick,
    noPagination = false,
}) => {
    const classes = useDataGridStyles();
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const isAlreadyUsingFlex = columns.reduce(
        (isUsingFlex, column) => isUsingFlex || Number.isInteger(column.flex),
        false
    );

    columns = columns.map((x) => ({
        ...(typeof x.headerName === 'string'
            ? {
                  // minWidth = maximum word in title width + 30px if column is sortable to allow for sorting arrow + 20px (default column mui padding is 10px on each side)
                  minWidth: getTextMaxWordWidth(x.headerName) + (x.sortable ?? true ? 30 : 0) + 20,
              }
            : {}),
        ...(!isAlreadyUsingFlex ? { flex: 1 } : {}),
        ...x,
        renderCell: (params: GridRenderCellParams<any>) => {
            let renderedValue = params.value;
            let renderedValueAsTooltip = params.value;
            if (x.renderCell) {
                renderedValue = x.renderCell(params);
                renderedValueAsTooltip = x.renderCell({ ...params, cellMode: TOOLTIP_CELL_MODE });
            }

            // in responsive mode, we do not want to render the tooltip component
            if (isSmDown) {
                return renderedValue;
            }

            return (
                <Tooltip
                    title={renderedValueAsTooltip}
                    onClick={() => {
                        /* having this empty click handler causes clicking the text to still
                    trigger "onRowClick". removing the click handler will cause clicking the
                    text to do nothing. so leave it be */
                    }}
                >
                    <Box sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                        {renderedValue}
                    </Box>
                </Tooltip>
            );
        },
    }));

    const rowCountForSize = useMemo(() => {
        if (rowCount < pageSize) {
            return rowCount;
        }

        return pageSize;
    }, [pageSize, rowCount]);

    return (
        <Paper
            sx={{
                width: '100%',
                minHeight: 162,
                bgcolor: theme.palette.mode === 'dark' ? 'grey.900' : 'grey.100',
                backgroundImage: 'none',
                p: 1,
            }}
        >
            {!isSmDown ? (
                <div data-testid={testId} style={{ height: 36 * rowCountForSize + 122 }}>
                    <MuiDataGrid
                        sx={{
                            // allow table column titles to line-wrap
                            '.MuiDataGrid-columnHeaderTitle': {
                                whiteSpace: 'normal',
                                lineHeight: 'normal',
                            },
                        }}
                        hideFooter={noPagination}
                        getRowId={getRowId}
                        onCellClick={onCellClick}
                        checkboxSelection={checkbox}
                        keepNonExistentRowsSelected={checkbox}
                        isRowSelectable={isRowSelectable}
                        disableSelectionOnClick
                        rows={data ?? range(pageSize).map((_, id) => ({ id }))}
                        rowHeight={32}
                        columns={columns}
                        rowCount={rowCount}
                        onRowClick={onRowClick}
                        pageSize={pageSize}
                        page={page}
                        onPageSizeChange={onPageSizeChange}
                        rowsPerPageOptions={rowsPerPageOptions}
                        classes={{ root: classes.root }}
                        getRowClassName={() => 'grid'}
                        onPageChange={onPageChange}
                        paginationMode="server"
                        sortingMode="server"
                        sortingOrder={['desc', 'asc']}
                        sortModel={sortModel}
                        onSortModelChange={onSortModelChange}
                        loading={isLoading}
                        error={error}
                        onSelectionModelChange={onSelectionModelChange}
                        selectionModel={selectionModel}
                        components={{
                            Pagination: DataGridPagination,
                        }}
                        componentsProps={{
                            pagination: {
                                pageSize,
                                rowsPerPageOptions,
                                jumpToPage: true,
                            },
                        }}
                    />
                </div>
            ) : (
                <DataGridMobileServer
                    testId={testId}
                    isLoading={isLoading}
                    rowCount={rowCount}
                    data={data}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={onPageSizeChange}
                    page={page}
                    onPageChange={onPageChange}
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    error={error}
                    onRowClick={onRowClick}
                    jumpPage
                />
            )}
        </Paper>
    );
};
