import { FC, FormEvent, ReactNode } from 'react';
import { Stack } from '@mui/material';
import { Button } from '../../../ui';
import { useHistory } from 'react-router-dom';
import { FormTemplate } from './FormTemplate';

export interface ILogoutPageProps {
    loginRoute: string;
    marketingContent?: ReactNode;
}

export const LogoutPage: FC<ILogoutPageProps> = ({ loginRoute, marketingContent }) => {
    const history = useHistory();

    const handleLogout = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        history.push(loginRoute);
    };

    return (
        <FormTemplate
            mainContent={
                <form onSubmit={(e) => handleLogout(e)}>
                    <Stack sx={{ alignItems: 'center' }}>
                        <Button testid="sign-in-again-button" variant="default" type="submit" sx={{ m: 1, mt: 3 }}>
                            Sign In Again
                        </Button>
                    </Stack>
                </form>
            }
            marketingContent={marketingContent}
        />
    );
};
