import { api } from '../../../api';
import { ApiResponse } from '../../Utilities';
import { ProfileType, ProfileViewModel } from '../../ViewModels';

export class ProfileApi {
    private route = '/Profile';

    getProfileSearch(params: { profileType: ProfileType; companyId?: number }): ApiResponse<ProfileViewModel[]> {
        return api().get(`${this.route}/Search`, { params });
    }
}
