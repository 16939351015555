import { useState, useRef, useLayoutEffect } from 'react';

/**
 * Use the actual rendered width of a parent element. This can be used to perform
 * layout calculations.
 *
 * The width will be repeatedly calculated until it stabilizes on mount and whenever the window resizes.
 *
 * @param isReferenceElement function that determines if a parent element is the reference element.
 * @returns the width of the reference element.
 */
export function useReferenceWidth(isReferenceElement: (element: HTMLElement) => boolean) {
    const [referenceWidth, setReferenceWidth] = useState<number>(0);

    const ref = useRef<any>();

    useLayoutEffect(() => {
        let lastWidth = 0;

        const calcWidth = () => {
            let el = ref.current;
            while (el) {
                if (isReferenceElement(el)) {
                    break;
                }
                el = el.parentElement;
            }

            if (el) {
                if (lastWidth === el.clientWidth) {
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    clearInterval(interval);
                }

                lastWidth = el.clientWidth;

                setReferenceWidth(el.clientWidth);
            }
        };

        const interval = setInterval(calcWidth, 100);
        window.addEventListener('resize', calcWidth);
        return () => {
            window.removeEventListener('resize', calcWidth);
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [referenceWidth, ref] as const;
}
