export enum DocumentBatchAction {
    Created = 1,
    ClientSubmitted,
    ClientCommitted,
    ClientRequested,
    ClientDownloaded,
    Sent,
    Closed,
    NeedsResearch,
    Reprocessed,
    Canceled,
    RuleExecuted,
    Canceling,
    ArchivePurged,
    DuplicateAndReprocess,
    Holding,
    DPSInstanceAssigned,
    ReleasedFromHold,
}
