import { ControlType, IControlFormat } from '../../transcepta-types';

export function RoundToMaxDecimal(valueText: string, maxDigits: number, maxDecimal: number, minDecimal: number) {
    let isNegative = false;
    let decimalFound = false;
    let numberOfDecimals = 0;
    let numberOfDigits = 0;

    for (let c = 0; c < valueText?.length; c++) {
        switch (valueText.charAt(c)) {
            case '-':
                isNegative = true;
                break;
            case '.':
                decimalFound = true;
                break;
            case '0':
            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
                if (decimalFound) {
                    numberOfDecimals++;
                } else {
                    numberOfDigits++;
                }
                break;
            default:
                break;
        }
    }

    if (numberOfDecimals > maxDecimal) {
        const decimalAfterNewLastDecimal = +valueText.charAt(valueText?.length - numberOfDecimals + maxDecimal);
        if (maxDecimal > 0) {
            valueText = valueText.substring(0, valueText?.length - numberOfDecimals + maxDecimal);
        } else {
            valueText = valueText.substring(0, valueText?.length - numberOfDecimals + maxDecimal - 1);
        }
        let newLastDecimal = +valueText.charAt(valueText?.length - 1);

        let increaseNextDigit = false;
        if (isNegative) {
            if (decimalAfterNewLastDecimal > 5) {
                newLastDecimal++;
            }
            if (newLastDecimal === 10) {
                increaseNextDigit = true;
                newLastDecimal = 0;
            }

            valueText = valueText.substring(0, valueText?.length - 1) + newLastDecimal.toString();

            for (let d = 2; increaseNextDigit && valueText?.length - d > 0; d++) {
                const nextChar = valueText.charAt(valueText?.length - d);
                if (nextChar !== '.') {
                    let nextDigit = +nextChar;
                    nextDigit++;
                    if (nextDigit < 10) {
                        increaseNextDigit = false;
                    } else {
                        nextDigit = 0;
                    }

                    valueText =
                        valueText.substring(0, valueText?.length - d) +
                        nextDigit.toString() +
                        valueText.substring(valueText?.length - d + 1);
                }
            }

            if (increaseNextDigit) {
                if (numberOfDigits >= maxDigits) {
                    valueText = '-';
                    for (let d = 0; d < maxDigits; d++) {
                        valueText += '9';
                    }
                    if (maxDecimal > 0) {
                        valueText += '.';
                        for (let d = 0; d < maxDecimal; d++) {
                            valueText += '9';
                        }
                    }
                } else {
                    valueText = `-1${valueText.substring(1)}`;
                }
            }
        } else {
            if (decimalAfterNewLastDecimal > 4) {
                newLastDecimal++;
            }
            if (newLastDecimal === 10) {
                increaseNextDigit = true;
                newLastDecimal = 0;
            }

            valueText = valueText.substring(0, valueText?.length - 1) + newLastDecimal.toString();

            for (let d = 2; increaseNextDigit && valueText?.length - d > -1; d++) {
                const nextChar = valueText.charAt(valueText?.length - d);
                if (nextChar !== '.') {
                    let nextDigit = +nextChar;
                    nextDigit++;
                    if (nextDigit < 10) {
                        increaseNextDigit = false;
                    } else {
                        nextDigit = 0;
                    }

                    if (valueText?.length - d === 0) {
                        valueText = nextDigit.toString() + valueText.substring(1);
                    } else {
                        valueText =
                            valueText.substring(0, valueText?.length - d) +
                            nextDigit.toString() +
                            valueText.substring(valueText?.length - d + 1);
                    }
                }
            }

            if (increaseNextDigit) {
                if (numberOfDigits >= maxDigits) {
                    valueText = '';
                    for (let d = 0; d < maxDigits; d++) {
                        valueText += '9';
                    }
                    if (maxDecimal > 0) {
                        valueText += '.';
                        for (let d = 0; d < maxDecimal; d++) {
                            valueText += '9';
                        }
                    }
                } else {
                    valueText = `1${valueText}`;
                }
            }
        }

        numberOfDecimals = maxDecimal;
    }

    while (numberOfDecimals > minDecimal) {
        if (valueText.charAt(valueText?.length - 1) === '0') {
            valueText = valueText.substring(0, valueText?.length - 1);
            numberOfDecimals--;
        } else {
            break;
        }
    }

    return valueText;
}

export const determineNeedsFormatting = (controlType: ControlType) => {
    let needsFormatting = false;
    switch (controlType) {
        case ControlType.Money:
        case ControlType.MoneyLabel:
        case ControlType.Tax:
        case ControlType.Quantity:
        case ControlType.UnitPrice:
        case ControlType.Percentage:
            needsFormatting = true;
            break;
        default:
            needsFormatting = false;
            break;
    }
    return needsFormatting;
};

export function DetermineMaxDigits(controlType: ControlType) {
    switch (controlType) {
        case ControlType.Money:
        case ControlType.MoneyLabel:
        case ControlType.Tax:
            return 15; // (19,4)

        case ControlType.Quantity:
        case ControlType.UnitPrice:
            return 12; // (18,6)

        case ControlType.Percentage:
        default:
            // tax percentage (currently has no corresponding control type value)
            return 3; // (5,2)
    }
}

export function DetermineMaxDecimalPlaces(
    controlType: ControlType,
    controlFormat: IControlFormat | null,
    currencyCode: string | null
) {
    const absoluteMoneyMax = 4; // These four absolute values reflect the storage limits of the fields in the database and should not be changed
    const absoluteQuantityMax = 6;
    const absoluteUnitPriceMax = 6;
    const absolutePercentageMax = 6;

    const defaultMoneyMax = 4;
    const defaultQuantityMax = 6;
    const defaultUnitPriceMax = 6;
    const defaultPercentageMax = 2;

    let absoluteMax;
    let defaultMax;
    let formatMax: string | null;

    switch (controlType) {
        case ControlType.Money:
        case ControlType.MoneyLabel:
        case ControlType.Tax:
            if (!currencyCode || currencyCode.toLowerCase() === 'usd') {
                return 2;
            }

            absoluteMax = absoluteMoneyMax;
            defaultMax = defaultMoneyMax;
            formatMax =
                controlFormat && controlFormat.amountmaxdecimal
                    ? controlFormat.amountmaxdecimal
                    : controlFormat && controlFormat.maxdecimal
                    ? controlFormat.maxdecimal
                    : null;
            break;

        case ControlType.Quantity:
            absoluteMax = absoluteQuantityMax;
            defaultMax = defaultQuantityMax;
            formatMax = controlFormat && controlFormat.maxdecimal ? controlFormat.maxdecimal : null;
            break;

        case ControlType.UnitPrice:
            absoluteMax = absoluteUnitPriceMax;
            defaultMax = defaultUnitPriceMax;
            formatMax =
                controlFormat && controlFormat.unitamountmaxdecimal
                    ? controlFormat.unitamountmaxdecimal
                    : controlFormat && controlFormat.maxdecimal
                    ? controlFormat.maxdecimal
                    : null;
            break;

        case ControlType.Percentage:
        default:
            // tax percentage (currently has no corresponding control type value)
            absoluteMax = absolutePercentageMax;
            defaultMax = defaultPercentageMax;
            formatMax = controlFormat && controlFormat.percentagemaxdecimal ? controlFormat.percentagemaxdecimal : null;
            break;
    }

    let max = defaultMax;
    if (formatMax !== null || !isNaN(parseInt(formatMax ?? '', 10))) {
        max = parseInt(formatMax ?? '', 10);
    }

    if (max < 0) {
        max = 0;
    }

    if (max > absoluteMax) {
        max = absoluteMax;
    }

    return max;
}

export function DetermineMinDecimalPalces(
    controlType: ControlType,
    controlFormat: IControlFormat | null,
    maxDecimal: number,
    currencyCode: string | null
) {
    const defaultMoneyMin = 2;
    const defaultQuantityMin = 0;
    const defaultUnitPriceMin = 2;
    const defaultPercentageMin = 0;

    let defaultMin;
    let formatMin: string | null;

    switch (controlType) {
        case ControlType.Money:
        case ControlType.MoneyLabel:
        case ControlType.Tax:
            if (!currencyCode || currencyCode.toLowerCase() === 'usd') {
                return 2;
            }

            defaultMin = defaultMoneyMin;
            formatMin =
                controlFormat && controlFormat.amountmindecimal
                    ? controlFormat.amountmindecimal
                    : controlFormat && controlFormat.mindecimal
                    ? controlFormat.mindecimal
                    : null;
            break;

        case ControlType.Quantity:
            defaultMin = defaultQuantityMin;
            formatMin = controlFormat && controlFormat.mindecimal ? controlFormat.mindecimal : null;
            break;

        case ControlType.UnitPrice:
            defaultMin = defaultUnitPriceMin;
            formatMin =
                controlFormat && controlFormat.unitamountmindecimal
                    ? controlFormat.unitamountmindecimal
                    : controlFormat && controlFormat.mindecimal
                    ? controlFormat.mindecimal
                    : null;
            break;

        case ControlType.Percentage:
        default:
            // tax percentage (currently has no corresponding control type value)
            defaultMin = defaultPercentageMin;
            formatMin = controlFormat && controlFormat.percentagemindecimal ? controlFormat.percentagemindecimal : null;
            break;
    }

    let min = defaultMin;
    if (formatMin !== null || !isNaN(parseInt(formatMin ?? '', 10))) {
        min = parseInt(formatMin ?? '', 10);
    }

    if (min < 0) {
        min = 0;
    }

    if (min > maxDecimal) {
        min = maxDecimal;
    }

    return min;
}

export function CleanNumericValue(value: string, maxDigits: number, maxDecimal: number, minDecimal: number) {
    if (value?.length > 0) {
        const numberOfDashesInCurrencySymbol = 0;
        const numberOfDecimalPointsInCurrencySymbol = 0;
        const numberOfNumericCharactersInCurrencySymbol = 0;

        let tempText = '';

        let nonZeroDigitAfterDecimal = false;
        let nonZeroDigitBeforeDecimal = false;
        let numberOfDashesFound = 0;
        let numberOfDecimalPointsFound = 0;
        let numberOfNumericCharactersFound = 0;
        let numberOfZerosAfterDecimalOnEnd = 0;

        for (let c = 0; c < value?.length; c++) {
            switch (value.charAt(c)) {
                case '0':
                    numberOfNumericCharactersFound++;
                    if (numberOfNumericCharactersFound > numberOfNumericCharactersInCurrencySymbol) {
                        if (numberOfDecimalPointsFound > numberOfDecimalPointsInCurrencySymbol) {
                            numberOfZerosAfterDecimalOnEnd++;
                        } else if (nonZeroDigitBeforeDecimal) {
                            tempText += value.charAt(c);
                        }
                    }
                    break;
                case '1':
                case '2':
                case '3':
                case '4':
                case '5':
                case '6':
                case '7':
                case '8':
                case '9':
                    numberOfNumericCharactersFound++;
                    if (numberOfNumericCharactersFound > numberOfNumericCharactersInCurrencySymbol) {
                        if (numberOfDecimalPointsFound > numberOfDecimalPointsInCurrencySymbol) {
                            if (numberOfZerosAfterDecimalOnEnd > 0) {
                                for (let z = 0; z < numberOfZerosAfterDecimalOnEnd; z++) {
                                    tempText += '0';
                                }
                                numberOfZerosAfterDecimalOnEnd = 0;
                            }
                            tempText += value.charAt(c);
                            nonZeroDigitAfterDecimal = true;
                        } else {
                            tempText += value.charAt(c);
                            nonZeroDigitBeforeDecimal = true;
                        }
                    }
                    break;
                case '.':
                    if (numberOfDecimalPointsFound === numberOfDecimalPointsInCurrencySymbol) {
                        tempText += value.charAt(c);
                    }
                    numberOfDecimalPointsFound++;
                    break;
                case '-':
                    if (numberOfDashesFound === numberOfDashesInCurrencySymbol) {
                        tempText += value.charAt(c);
                    }
                    numberOfDashesFound++;
                    break;
                default:
                    break;
            }
        }

        let newText = '';

        for (let c = 0; c < tempText?.length; c++) {
            switch (tempText.charAt(c)) {
                case '0':
                case '1':
                case '2':
                case '3':
                case '4':
                case '5':
                case '6':
                case '7':
                case '8':
                case '9':
                    newText += tempText.charAt(c);
                    break;
                case '.':
                    if (nonZeroDigitAfterDecimal) {
                        newText += tempText.charAt(c);
                    }
                    break;
                case '-':
                    newText += tempText.charAt(c);
                    break;
                default:
                    break;
            }
        }

        if (newText?.length === 0) {
            newText = '0';
        }

        return RoundToMaxDecimal(newText, maxDigits, maxDecimal, minDecimal);
    }

    return value;
}

export function GenerateDisplayValue(
    value: string,
    maxDigits: number,
    maxDecimal: number,
    minDecimal: number,
    controlType: ControlType,
    currencySymbol: string
) {
    let isPercentage = false;
    let isMoney = false;
    switch (controlType) {
        case ControlType.Money:
        case ControlType.MoneyLabel:
        case ControlType.Tax:
            isMoney = true;
            break;

        case ControlType.Quantity:
        case ControlType.UnitPrice:
            break;

        case ControlType.Percentage:
        default:
            // tax percentage (currently has no corresponding control type value)
            isPercentage = true;
            break;
    }

    if (value) {
        value = value.toString();
    }
    if (value?.length === 0) {
        return value;
    }

    let tempText = '';

    let decimalPointExists = false;
    for (let c = 0; c < value?.length; c++) {
        switch (value.charAt(c)) {
            case '0':
            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
                tempText += value.charAt(c);
                break;
            case '.':
                if (!decimalPointExists) {
                    tempText += value.charAt(c);
                    decimalPointExists = true;
                }
                break;
            case '-':
                if (c === 0) {
                    tempText += value.charAt(c);
                }
                break;
            case ',':
            default:
                break;
        }
    }

    tempText = RoundToMaxDecimal(tempText, maxDigits, maxDecimal, minDecimal);

    decimalPointExists = false;
    let numberOfDigitsAfterDecimal = 0;
    let numberOfDigitsBeforeDecimal = 0;
    for (let c = 0; c < tempText?.length; c++) {
        switch (tempText.charAt(c)) {
            case '0':
            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
                if (decimalPointExists) {
                    numberOfDigitsAfterDecimal++;
                } else {
                    numberOfDigitsBeforeDecimal++;
                }
                break;
            case '.':
                decimalPointExists = true;
                break;
            default:
                break;
        }
    }

    let newText = '';
    let digitBeforeDecimal = 0;
    const addCommaAfter = numberOfDigitsBeforeDecimal % 3;

    for (let c = 0; c < tempText?.length; c++) {
        switch (tempText.charAt(c)) {
            case '0':
            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
                if (isMoney && newText?.length === 0) {
                    newText += currencySymbol;
                }
                newText += tempText.charAt(c);
                digitBeforeDecimal++;
                if (digitBeforeDecimal % 3 === addCommaAfter && digitBeforeDecimal < numberOfDigitsBeforeDecimal) {
                    newText = `${newText},`;
                }
                break;
            case '.':
                if (newText?.length === 0 && numberOfDigitsBeforeDecimal === 0) {
                    if (isMoney) {
                        newText += currencySymbol;
                    }
                    newText += '0';
                }
                if (numberOfDigitsAfterDecimal > 0 || minDecimal > 0) {
                    newText += tempText.charAt(c);
                }
                break;
            case '-':
                if (numberOfDigitsBeforeDecimal > 0 || numberOfDigitsAfterDecimal > 0) {
                    newText = tempText.charAt(c);
                }
                if (isMoney) {
                    newText += currencySymbol;
                }
                if (numberOfDigitsBeforeDecimal === 0) {
                    newText += '0';
                }
                break;
            default:
                break;
        }
    }

    if (newText?.length === 0) {
        if (isMoney) {
            newText = `${currencySymbol}0`;
        } else {
            newText = '0';
        }
    }

    if (minDecimal > 0) {
        if (!decimalPointExists) {
            newText += '.';
        }
        while (numberOfDigitsAfterDecimal < minDecimal) {
            newText += '0';
            numberOfDigitsAfterDecimal++;
        }
    }

    if (!isMoney && isPercentage) {
        newText += '%';
    }

    return newText;
}

export const parseNumericValue = (value: any): number | undefined => {
    if (typeof value === 'number') {
        return value;
    }

    if (typeof value === 'string') {
        const parsedValue = Number.parseInt(value, 10);

        if (Number.isNaN(parsedValue)) {
            return undefined;
        }

        return parsedValue;
    }

    return undefined;
};
