import { escape, portalUserService } from 'common';
import { pushAlert, clearAlerts } from '../actions/alerts';
import * as documentActions from '../actions/document/documentActions';
import * as documentSearchActions from '../actions/documentSearchActions';
import projectConfig from '../project.config.json';
import { searchModelSelector } from '../selectors/documentSearch/searchModelSelector';
import { DocumentThunk } from 'common/build/legacy/transcepta-thunks';
import { strings } from '../utils/localization/LocalizationStrings';
import { apiErrorAction } from '../actions/error';
import apiPaths from '../api/paths';
import { api, parseMessage } from '../utils';
import { path } from 'ramda';
import { DocumentSearchUtil, ValidationUtil } from 'common/build/legacy/transcepta-common';
import { convertSearchLayouts as convertLayouts } from 'common/build/legacy/transcepta-common';
import { sub } from 'date-fns';
import { shouldShowDateRangeError } from 'common/build/legacy/transcepta-search-page/old-implementation/components/DateInput';
import { getContainsSearchInfo } from 'common/build/legacy/transcepta-common/utils/documentSearch';

export const fetchDocumentSearchConfig =
    (businessDocType, page = 1) =>
    async (dispatch, getState) => {
        dispatch({
            type: documentActions.DOCUMENT_SEARCH_LAYOUT_CONFIG_FETCHING,
            payload: { businessDocType },
        });
        let params = { businessDocType, page: page };
        let docThunk = new DocumentThunk();
        let response = await docThunk.fetchSearchConfig(params);

        if (response.type && response.type === 'error') {
            dispatch(documentActions.documentSearchLayoutConfigFetched([]));
            dispatch(apiErrorAction(response.text));
        } else {
            const layoutConfigs = response.data;
            dispatch(documentActions.documentSearchLayoutConfigFetched(convertLayouts(layoutConfigs)));
        }
    };

export const fetchDocuments = (businessDocType, skip, sort) => (dispatch, getState) => {
    const state = getState();
    const businessDocTypeStateName = projectConfig.businessDocTypeStateName[businessDocType.toString()];
    const searchParams = state[businessDocTypeStateName] ? state[businessDocTypeStateName].searchParams : {};

    dispatch(applyDocumentSearch(searchParams, businessDocType, skip, sort, true));
};

export const documentExists =
    (
        companyId,
        businessDocType,
        businessDocId,
        senderProfileId,
        identifyingNumber,
        testBusinessDoc,
        businessDocStatus,
        documentId
    ) =>
    async (dispatch, getState) => {
        let filter = 'Draft eq 0';
        filter = filter + ' and BusinessDocType eq ' + businessDocType;
        filter = filter + ' and SenderProfileId eq ' + senderProfileId;

        if (businessDocType === projectConfig.businessDocType.Invoice) {
            filter = filter + ' and Id ne ' + businessDocId;
            filter = filter + " and InvoiceNumber eq '" + escape(identifyingNumber) + "'";
            filter = filter + ' and TestBusinessDoc eq ' + testBusinessDoc;
            filter = filter + ' and BusinessDocStatus eq ' + businessDocStatus;
            filter = filter + ' and (ExternalStatus eq ' + projectConfig.externalStatus.Held;
            filter = filter + ' or ExternalStatus eq ' + projectConfig.externalStatus.Processing;
            filter = filter + ' or ExternalStatus eq ' + projectConfig.externalStatus.Parked + ')';
        } else {
            // Generic document filters
            filter = filter + ' and DocumentID ne ' + documentId;
            filter = filter + ' and Status ne ' + projectConfig.documentStatus.Draft;
            filter = filter + ' and ExternalStatus ne ' + projectConfig.externalStatus.Draft;
        }

        const params = {
            companyID: companyId,
            $inlinecount: 'allPages',
            $top: 1,
            $filter: filter,
            $skip: 0,
            identifyingNumber: identifyingNumber,
            useTransaction: true,
        };

        dispatch(documentActions.documentDuplicateCheckExecute(params));

        const documentThunk = new DocumentThunk();
        const response = await documentThunk.fetchDocumentList(params);
        if (response.type && response.type === 'error') {
            dispatch(documentActions.documentDuplicateCheckFailure(response.text));
            dispatch(apiErrorAction(response.text));
        } else {
            const data = await response.data;
            dispatch(documentActions.documentDuplicateCheckSuccess(data));
        }
    };

export const documentDraftExists = (businessDocType, idNumber, companyId, documentId) => async (dispatch, getState) => {
    if (idNumber) {
        idNumber = idNumber.trim();
    }
    let filter =
        businessDocType === projectConfig.businessDocType.Invoice
            ? 'Draft eq 1 and BusinessDocType eq ' +
              businessDocType +
              " and InvoiceNumber eq '" +
              escape(idNumber) +
              "'"
            : 'ExternalStatus eq ' + projectConfig.externalStatus.Draft + ' and BusinessDocType eq ' + businessDocType;
    if (documentId && businessDocType !== projectConfig.businessDocType.Invoice)
        filter += ' and DocumentID ne ' + documentId + 'L';

    const params = {
        companyID: companyId,
        $count: true,
        $top: null,
        $filter: filter,
        $skip: 0,
        useTransaction: true,
        identifyingNumber:
            businessDocType !== projectConfig.businessDocType.Invoice
                ? idNumber == null
                    ? 'null'
                    : idNumber.trim()
                : null,
    };
    let documentThunk = new DocumentThunk();
    let response = await documentThunk.fetchDocumentList(params);
    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, 'fetching', params));
    } else {
        let data = await response.data;
        if (data.Count > 0) {
            return data;
        }
        return null;
    }
};

export const getSearchFilter = (state) => {
    try {
        const currentSize = state.browser.mediaType;
        const layoutConfigs = state.documentSearch.layoutConfigs;

        const { SearchFilter } = layoutConfigs[currentSize.charAt(0).toUpperCase() + currentSize.slice(1)];

        return SearchFilter;
    } catch (e) {
        return null;
    }
};

export const applyDocumentSearch =
    (searchParams, docType, skip, sort, getUpdatedTotalEntries = true, isCsv = false) =>
    async (dispatch, getState) => {
        if (searchParams) {
            const state = getState();
            const companyId = portalUserService.getCurrentCompanyId();
            const includeValidations = false;
            const userId = state.userlogin.ID;
            if (
                searchParams.CreatedTime &&
                searchParams.CreatedTime.value[1] &&
                searchParams.CreatedTime.value[1].toString() === new Date('1969-12-31T16:00:00').toString()
            ) {
                searchParams.CreatedTime.value[1] = new Date();
            }

            const currentSize = state.browser.mediaType;
            const { SearchFilter } =
                state.documentSearch.layoutConfigs[currentSize.charAt(0).toUpperCase() + currentSize.slice(1)];

            let startDate;
            let endDate;
            const isDateRangeSet = searchParams.CreatedTime.value.length !== 0;
            const performContainsSearch = state[projectConfig.businessDocTypeStateName[docType]].performContainsSearch;
            const filterParams = isDateRangeSet
                ? searchParams
                : {
                      ...searchParams,
                      CreatedTime: { name: 'Date Sent Range', type: 'DateRange', value: [startDate, endDate] },
                  };
            const containsSearchInfo = getContainsSearchInfo(performContainsSearch, SearchFilter, filterParams);
            if (isDateRangeSet) {
                startDate = searchParams.CreatedTime.value[0];
                endDate = searchParams.CreatedTime.value[1];

                if (shouldShowDateRangeError(startDate, endDate, containsSearchInfo)) {
                    dispatch(
                        pushAlert({
                            type: 'error',
                            text: 'Searches are limited to 6 months to ensure optimal performance',
                        })
                    );
                    return;
                }
            } else if (containsSearchInfo.mode === 'Contains' && containsSearchInfo.criteriaFilled) {
                endDate = new Date();
                startDate = sub(endDate, { days: 90 });
            }

            let { $filter, additionalParameters } = DocumentSearchUtil.getSearchFilter(
                docType,
                filterParams,
                SearchFilter,
                performContainsSearch
            );

            let simDocTypes = state.simDocumentTypes.all.items;
            let searchModel = searchModelSelector({ state: state, businessDocType: docType, simDocTypes });
            let newSkip = skip ? skip : 0;
            let validateFields = [];
            let noSearchParams = hasNoSearchParameters(
                searchModel.advancedSearchConfig,
                isDateRangeSet
                    ? searchParams
                    : {
                          ...searchParams,
                          CreatedTime: { name: 'Date Sent Range', type: 'DateRange', value: [startDate, endDate] },
                      },
                validateFields
            );

            const params = {
                companyID: companyId,
                $count: getUpdatedTotalEntries === true ? true : null,
                ...(!isCsv && { $top: projectConfig.docPageSize.toString() }),
                $filter,
                $skip: newSkip,
                $orderby: sort ? sort : null,
                user: userId && userId.toString().length > 0 ? userId.toString() : null,
                validationMessages: includeValidations,
                isBuyer: false,
                ...additionalParameters,
            };

            const headers = {
                ...(isCsv && { Accept: 'text/csv' }),
            };

            dispatch(clearAlerts());
            if (searchParams && !noSearchParams) {
                dispatch(documentSearchActions.documentsFetching(searchParams, newSkip, searchModel.fetching));
                if (validateFields.length > 0) {
                    dispatch(
                        pushAlert({
                            type: 'error',
                            text: validateFields.join(' \n '),
                        })
                    );
                    return;
                }
                let documentThunk = new DocumentThunk();
                let response = await documentThunk.fetchDocumentList(params, headers);
                let data = await response.data;
                if (response.type && response.type === 'error') {
                    dispatch(
                        documentSearchActions.documentsFetched([], 0, searchParams, newSkip, sort, searchModel.fetched)
                    );

                    if (
                        response.text.response &&
                        response.text.response.data.errors[0] &&
                        response.text.response.data.errors[0].Code === 22
                    ) {
                        dispatch(
                            pushAlert({
                                type: response.type,
                                text: strings.textDocumentQueryTimeoutError,
                            })
                        );
                    } else {
                        dispatch(apiErrorAction(response.text, searchModel.fetching, params));
                    }
                } else {
                    if (isCsv) {
                        dispatch({
                            type: documentActions.DOCUMENT_SEARCH_CSV_DATA_FETCHED,
                            payload: data,
                        });
                    } else {
                        const itemsFiltered = data.Items;
                        const totalEntries =
                            data.Count !== null && data.Count !== undefined
                                ? data.Count
                                : state[searchModel.stateName].totalEntries;
                        dispatch(
                            documentSearchActions.documentsFetched(
                                itemsFiltered,
                                totalEntries,
                                searchParams,
                                newSkip,
                                sort,
                                searchModel.fetched
                            )
                        );
                    }
                }
            } else {
                dispatch(
                    pushAlert({
                        type: 'error',
                        text: strings.textPleaseProvideSearchCriteria,
                    })
                );
            }
        }
    };

function hasNoSearchParameters(advancedSearchConfig, searchParams, validationMessages) {
    let noSearchParams = true;

    if (advancedSearchConfig.left || advancedSearchConfig.right) {
        if (advancedSearchConfig.left) {
            advancedSearchConfig.left.forEach((field) => {
                noSearchParams = noSearchParams && isNotASearchParameter(field, searchParams, validationMessages);
            });
        }
        if (advancedSearchConfig.right) {
            advancedSearchConfig.right.forEach((field) => {
                noSearchParams = noSearchParams && isNotASearchParameter(field, searchParams, validationMessages);
            });
        }
    } else {
        advancedSearchConfig.forEach((field) => {
            noSearchParams = noSearchParams && isNotASearchParameter(field, searchParams, validationMessages);
        });
    }

    return noSearchParams;
}

function isNotASearchParameter(field, searchParams, validationMessages) {
    let notASearchParameter = true;

    let value =
        searchParams[field.key] && searchParams[field.key].type === 'Input'
            ? searchParams[field.key].value.trim()
            : searchParams[field.key] && searchParams[field.key].value;

    if (searchParams[field.key] && value != '') {
        notASearchParameter = false;

        if (
            (searchParams[field.key].type === 'Long' ||
                searchParams[field.key].type === 'Number' ||
                searchParams[field.key].type === 'Integer') &&
            isNaN(searchParams[field.key].value)
        ) {
            validationMessages.push(strings.textInvalid.replace(':field', field.name));
        }

        if (searchParams[field.key].type === 'NumericRange') {
            let values = searchParams[field.key].value.split('/');
            if (
                (/^\s+$/.test(values[0]) && values[0].length >= 2) ||
                (/^\s+$/.test(values[1]) && values[1].length >= 2) ||
                isNaN(values[0]) ||
                isNaN(values[1])
            ) {
                validationMessages.push(strings.textInvalid.replace(':field', field.name));
            }
        }

        if (searchParams[field.key].type === 'DateRange') {
            let dateValues = searchParams[field.key].value;
            if (dateValues && dateValues.length !== 2) {
                validationMessages.push(strings.textInvalid.replace(':field', field.name));
            }
        }
    }

    return notASearchParameter;
}

export const applySorting = (column, docType) => async (dispatch, getState) => {
    const state = getState();
    const searchModel = searchModelSelector({ state: state, businessDocType: docType });
    await dispatch(documentSearchActions.applySorting(column, searchModel.tableConfig, searchModel.sorting));
    const direction = getState()[searchModel.stateName].sorting.direction;
    const searchParams = state[searchModel.stateName].searchParams;
    const skip = getState()[searchModel.stateName].skip;
    dispatch(applyDocumentSearch(searchParams, docType, skip, column + ' ' + direction, false));
};

export const goToPage = (skip, docType) => (dispatch, getState) => {
    const state = getState();
    let searchModel = searchModelSelector({ state: state, businessDocType: docType });
    const searchParams = state[searchModel.stateName].searchParams;
    const sort = state[searchModel.stateName].sorting.column + ' ' + state[searchModel.stateName].sorting.direction;
    dispatch(applyDocumentSearch(searchParams, docType, skip, sort, false));
};

export const getCsvSearchData = (docType) => async (dispatch, getState) => {
    const state = getState();
    const searchModel = searchModelSelector({ state: state, businessDocType: docType });
    const searchParams = state[searchModel.stateName].searchParams;
    const skip = getState()[searchModel.stateName].skip;
    const sort = state[searchModel.stateName].sorting.column + ' ' + state[searchModel.stateName].sorting.direction;
    dispatch(applyDocumentSearch(searchParams, docType, null, sort, false, true));
};

export const clearCsvData = () => ({
    type: documentActions.DOCUMENT_SEARCH_CLEAR_CSV_DATA,
});

export const clearSearch = (docType) => (dispatch, getState) => {
    const searchModel = searchModelSelector({ state: getState(), businessDocType: docType });
    dispatch(documentSearchActions.clearSearch(searchModel.clearSearch));
};
