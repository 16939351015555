// DEPRECATED: This component is legacy and will no longer be used in new features.
// REFACTOR: Remove this component after the Grid has been replaced with DataGrid
import { FC } from 'react';
import './Label.scss';

export interface ILabelProps {
    id: string;
    text: string;
    className?: string;
    type: 'none' | 'gnav' | 'gnav-alt';
    active?: boolean;
    onClick?: Function;
}

/**
 * @deprecated
 */
export const Label: FC<ILabelProps> = ({ text, className, type, active, id, onClick }) => (
    <div
        id={id}
        className={`core-label ${className || ''} ${type} ${active ? 'active' : null}`}
        onClick={() => (onClick ? onClick() : {})}
        role="button"
        tabIndex={0}
        onKeyPress={(e) => {
            if (e.key === 'Enter') {
                if (onClick) {
                    onClick();
                }
            }
        }}
    >
        {text}
    </div>
);
