import { parseString, ParserOptions, Builder, BuilderOptions } from 'xml2js';

export class OldXMLParser {
    constructor(private options: ParserOptions) {}

    parse(xmlString: string) {
        let res: any;
        parseString(xmlString, this.options, (err, result) => {
            if (err) {
                throw err;
            }
            res = result;
        });

        return res;
    }
}

export class OldXMLBuilder {
    private builder: Builder;

    constructor(options?: BuilderOptions) {
        this.builder = new Builder(options);
    }

    build(obj: any) {
        return this.builder.buildObject(obj);
    }
}
