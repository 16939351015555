import * as TransceptaThunks from 'common/build/legacy/transcepta-thunks';

import * as CommonActions from '../actions/CommonActions';
import { apiErrorAction } from '../actions/error';
import { portalUserService } from 'common';

export const fetchCountries = () => async (dispatch, getState) => {
    const params = {
        $orderby: 'SortIndex ASC',
    };
    dispatch(CommonActions.fetchCountriesExecute(params));

    let commonThunk = new TransceptaThunks.CommonThunk();
    let response = await commonThunk.fetchCountries(params);
    let data = await response.data;
    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, CommonActions.FETCH_COUNTRIES_FAILURE, params));
    } else {
        dispatch(CommonActions.fetchCountriesSuccess(data));
    }
};

export const fetchStates = () => async (dispatch, getState) => {
    const params = {
        $orderby: 'SortIndex ASC, Name ASC',
    };
    dispatch(CommonActions.fetchStatesExecute(params));

    let commonThunk = new TransceptaThunks.CommonThunk();
    let response = await commonThunk.fetchStates(params);
    let data = await response.data;
    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, CommonActions.FETCH_STATES_FAILURE, params));
    } else {
        dispatch(CommonActions.fetchStatesSuccess(data));
    }
};

export const fetchStandardStyleSheets = () => async (dispatch, getState) => {
    const params = null;
    dispatch(CommonActions.fetchStandardStyleSheetsExecute(params));

    const commonThunk = new TransceptaThunks.CommonThunk();
    const response = await commonThunk.fetchStandardStyleSheets(params);
    const data = await response.data;
    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, CommonActions.FETCH_STANDARD_STYLE_SHEETS_FAILURE, params));
    } else {
        dispatch(CommonActions.fetchStandardStyleSheetsSuccess(data));
    }
};

export const sendEmail = (type, contact) => async (dispatch, getState) => {
    const companyId = portalUserService.getCurrentCompanyId();
    const contactInfo = {
        type: type,
        email: contact.Email,
        name: contact.Name,
        phone: contact.Phone,
        companyId: companyId,
    };
    const commonThunk = new TransceptaThunks.CommonThunk();
    const response = await commonThunk.postEmail(contactInfo);
    const data = await response.data;
    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, CommonActions.FETCH_STANDARD_STYLE_SHEETS_FAILURE, contactInfo));
    }
};
