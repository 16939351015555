import ValidationService from '../ValidationService/ValidationService';
import { createServices } from './services';
import {
    Entities,
    EntityType,
    ITransceptaDocumentEditRulesPluginHost,
    IDropdownOptionsRepository,
} from '../proservContractTypes';

let currentValidationService: ValidationService | null = null;

/**
 * Stores a reference to the current validation service instance so that mutation
 * functions can be executed.
 *
 * @param service validation service that the plugins will register with
 */
export default function setupPluginHost(service: ValidationService) {
    currentValidationService = service;
}

/**
 * Run mutations on an entity using the globally registered validation service.
 * @param entity the entity to mutate
 * @returns the mutated entity
 */
export async function runMutations<K extends EntityType>(entityType: K, entity: Entities[K]) {
    if (!currentValidationService) {
        return entity;
    }
    return currentValidationService.mutate(entityType, entity);
}

export function cleanupPluginHost() {
    currentValidationService = null;
}

export function getPluginHostObject(
    service: ValidationService,
    scriptName: string,
    dropdownOptionsRepository: IDropdownOptionsRepository
): ITransceptaDocumentEditRulesPluginHost {
    const services = createServices(scriptName, dropdownOptionsRepository);
    return {
        registerValidationFunction: service.registerValidatorFunction.bind(service, scriptName),
        registerEntityMutationFunction: service.registerEntityMutation.bind(service, scriptName),
        registerDropdownOptionsFetcher: service.registerDropdownOptionsFetcher.bind(service, scriptName),
        registerUIDocumentController: service.registerUIDocumentController.bind(service, scriptName),
        services,
    };
}
