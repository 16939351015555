/* eslint-disable no-prototype-builtins,array-callback-return,no-param-reassign */
import React from 'react';
import cloneDeep from 'lodash';

import { compareHelper } from './advancedSearch';

/**
 * Extract only props with values !== null and return new object
 * @param f - input Object
 */
const getValuableProps = (f) =>
    Object.keys(f)
        .filter((k) => f[k] !== null)
        .reduce((o, k) => {
            o[k] = f[k];
            return o;
        }, {});

/**
 * Convert Document Layout and Document Data received from API to handy object
 * @param config - Document Layout config (from API)
 * @param data - Document Data (from API)
 * @return {{type: string, data: {
 *      column1: {
 *          row1: {
 *              Fields: [
 *                  {
 *                  BusinessDocType: 1,
 *                  EntityName: "InvoiceContact",
 *                  FieldName: "PartyId",
 *                  ContactType: 2,
 *                  Label: "Site Code",
 *                  …}
 *              ]
 *          }
 *          ...
 *      }
 *      ...
 * }}}
 */

const dataConverter = (config, data) => {
    const docType = data.BusinessDocType_US;
    const res = {};
    if (config)
        config.Cells.map((c) => {
            if (!res.hasOwnProperty(`column${c.Column}`)) res[`column${c.Column}`] = {};
            const { Title, Fields } = c;
            const docFieldsData = data.BusinessDocFields[docType.replace(' ', '')];
            const fieldData = Fields.map((f) => {
                const field = getValuableProps(f);
                if (field.EntityName === 'InvoiceContact') {
                    const contact = docFieldsData.InvoiceContacts.find((v) => +v.ContactType === +field.ContactType);
                    field.Value = contact && contact.hasOwnProperty(field.FieldName) ? contact[field.FieldName] : null;
                    return field;
                }

                if (field.EntityName === 'InvoiceLineItem') {
                    field.Value = [];
                    docFieldsData.InvoiceLineItems.map((td) => {
                        field.Value.push(td[field.FieldName]);
                    });
                    return field;
                }

                field.Value =
                    docFieldsData && docFieldsData.hasOwnProperty(field.FieldName)
                        ? docFieldsData[field.FieldName]
                        : data.CommonFields[field.FieldName];

                return field;
            });
            res[`column${c.Column}`][`row${c.Row}`] = { Title, Fields: fieldData };
        });

    return { type: docType, data: res, isError: false };
};

export const cellsExtracter = ({ data }) => {
    const res = { Fields: [] };
    if (data && Object.keys(data).length)
        Object.keys(data).map((rowKey) => {
            // rows iterator
            if (Object.keys(data[rowKey]))
                Object.keys(data[rowKey]).map((colKey) => {
                    // cols iterator
                    const { Fields } = data[rowKey][colKey];
                    const visibleFields = Fields.filter((f) => f.Visible || f.Required);
                    res.Fields = res.Fields.concat(visibleFields);
                });
        });
    return res;
};

/**
 * split value by thousands
 * @example
 * // returns 1,234,567.05
 *  thousands(1234567.05, ',');
 * @param {number} value - number
 * @param {string} [delimiter=','] - thousands delimiter
 * @return {string}
 */
export const thousands = (value, { Round, Split }, delimiter = ',') => {
    let regex = new RegExp(`(\\d)(?=(\\d{${Split}})+(?!\\d))`, 'g');
    return !value
        ? '0.00'
        : value >= 0
        ? `${String(value).split('.')[0].replace(regex, `$1${delimiter}`)}.${
              (parseFloat(value) || 0).toFixed(Round).split('.')[1]
          }`
        : String(value || 0);
};

/**
 * format string, changes /n signs with <br /> and wrap to <span>
 * @param {string} stringToFormat
 */
export const stringNewlineFormatting = (stringToFormat) => {
    if (stringToFormat && typeof stringToFormat === 'string') {
        const lineBreaks = stringToFormat.split('\n');

        if (lineBreaks.length === 1) {
            const text = lineBreaks[0];
            const max = 100;
            if (text && text.length > max) {
                stringToFormat = text.substr(0, max - 1) + (text.length > max ? '...' : '');
            }
        }

        return stringToFormat.split('\n').map((item, key) => (
            <span key={key}>
                {item}
                <br />
            </span>
        ));
    }

    return stringToFormat;
};

export const base64ToBlob = (base64, contentType) => {
    // decode base64 string, remove space for IE compatibility
    var binary = atob(base64.replace(/\s/g, ''));
    var len = binary.length;
    var buffer = new ArrayBuffer(len);
    var view = new Uint8Array(buffer);
    for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
    }

    // create the blob object with passed contentType
    var blob = new Blob([view], { type: contentType });
    return blob;
};

export const cloneObject = (sourceObject) => {
    return cloneDeep(sourceObject);
};

export const cloneObjectHack = (sourceObject) => {
    if (sourceObject) return JSON.parse(JSON.stringify(sourceObject));
};

export function clearAllObjectValues(object) {
    Object.keys(object).forEach((key) => {
        if (object[key] !== null) {
            if (typeof object[key] === 'object') {
                clearAllObjectValues(object[key]);
            } else {
                object[key] = null;
            }
        }
    });
}

export const arraySort = (array, sortKey, sortDirection) => {
    if (sortDirection.toUpperCase() === 'DESC') {
        return array.sort((a, b) => compareHelper.String(b[sortKey], a[sortKey]));
    } else {
        return array.sort((a, b) => compareHelper.String(a[sortKey], b[sortKey]));
    }
};

export default dataConverter;
