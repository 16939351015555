/* eslint-disable no-underscore-dangle */
declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Window {
        __TRANSCEPTA_BUILD_KEY__: string | number | undefined;
    }
}

function getVersionKeyFromWindow() {
    const keySetInEntryPointNotRequired =
        process.env.NODE_ENV === 'test' || process.env.STORYBOOK === 'true' || (window as any).Cypress;

    if (!('__TRANSCEPTA_BUILD_KEY__' in window) && !keySetInEntryPointNotRequired) {
        console.error(
            'Attempted to retrieve the version key before it was set. The version key must be set in the entry point before any other imports to ensure one consistent version is used everywhere. It should be assigned to window.__TRANSCEPTA_BUILD_KEY__'
        );
    }

    if (
        window.__TRANSCEPTA_BUILD_KEY__ === undefined &&
        process.env.NODE_ENV === 'production' &&
        !keySetInEntryPointNotRequired
    ) {
        throw new Error('Refusing to run production bundle without version key being set.');
    }

    return window.__TRANSCEPTA_BUILD_KEY__;
}

let versionKeyForTest: string | number | undefined;

/**
 * Use a version key for a synchronous unit test
 * @param versionKey version key to use during test
 * @param testCode test to execute that depends on a version being set
 */
export function useVersionKeyForTest(versionKey: string | number, testCode: () => void) {
    try {
        versionKeyForTest = versionKey;
        return testCode();
    } finally {
        versionKeyForTest = undefined;
    }
}

/**
 * Use a version key for an asynchronous unit test
 * @param versionKey version key to use during test
 * @param testCode test to execute that depends on a version being set
 */
export async function useVersionKeyForAsyncTest(versionKey: string | number, testCode: () => Promise<void>) {
    try {
        versionKeyForTest = versionKey;
        return await testCode();
    } finally {
        versionKeyForTest = undefined;
    }
}

/**
 * Get the version key
 *
 * @returns the version key
 */
export function getVersionKey() {
    return getVersionKeyFromWindow() || versionKeyForTest || localStorage.getItem('debugVersionKey');
}
