import { path } from 'ramda';
import * as TransceptaThunks from 'common/build/legacy/transcepta-thunks';

import { pushAlert } from '../actions/alerts';
import * as companyActions from '../actions/company/companyActions';
import { parseMessage } from '../utils';
import { apiErrorAction } from '../actions/error';
import { push } from 'connected-react-router';
import R from '../routes';
import { clearAlerts } from '../actions/alerts';
import { portalUserService } from 'common';

export const fetchVatArchiveServiceRecords = (companyId) => async (dispatch, getState) => {
    const isBuyer = portalUserService.getCurrentCompanyId() !== companyId;
    const params = {
        companyId: companyId,
    };

    if (isBuyer) {
        dispatch(companyActions.fetchBuyerVATArchiveServiceExecute(params));
    } else {
        dispatch(companyActions.fetchSupplierVATArchiveServiceExecute(params));
    }

    let companyThunk = new TransceptaThunks.CompanyThunk();
    let response = await companyThunk.fetchVATArchiveService(params);
    let data = await response.data;
    if (response.type && response.type === 'error') {
        if (isBuyer) {
            dispatch(companyActions.fetchBuyerVATArchiveServiceFailure(response.text));
        } else {
            dispatch(companyActions.fetchSupplierVATArchiveServiceFailure(response.text));
        }
        dispatch(
            pushAlert({
                type: 'error',
                text: parseMessage(path(['response', 'data', 'errors'], response.text)),
            })
        );
    } else {
        if (isBuyer) {
            dispatch(companyActions.fetchBuyerVATArchiveServiceSuccess(data));
        } else {
            dispatch(companyActions.fetchSupplierVATArchiveServiceSuccess(data));
        }
    }
};

export const fetchCompany = (companyId) => async (dispatch, getState) => {
    const isBuyer = portalUserService.getCurrentCompanyId() !== companyId;
    const params = {
        companyId: companyId,
    };

    if (isBuyer) {
        dispatch(companyActions.fetchBuyerCompanyExecute(params));
    } else {
        dispatch(companyActions.fetchSupplierCompanyExecute(params));
    }

    let companyThunk = new TransceptaThunks.CompanyThunk();
    let response = await companyThunk.fetchCompany(params);

    let data = await response.data;
    if (response.type && response.type === 'error') {
        if (isBuyer) {
            dispatch(companyActions.fetchBuyerCompanyFailure(response.text));
        } else {
            dispatch(companyActions.fetchSupplierCompanyFailure(response.text));
        }
        dispatch(
            pushAlert({
                type: 'error',
                text: parseMessage(path(['response', 'data', 'errors'], response.text)),
            })
        );
    } else {
        if (isBuyer) {
            dispatch(companyActions.fetchBuyerCompanySuccess(data[0]));
        } else {
            dispatch(companyActions.fetchSupplierCompanySuccess(data[0]));
        }
    }
};

export const setSelectedContact = (selectedContact) => (dispatch) => {
    dispatch(companyActions.setSelectedContact(selectedContact));
};

export const clearSelectedContact = () => (dispatch) => {
    dispatch(companyActions.clearSelectedContact());
};

export const saveSelectedContact = (payload) => async (dispatch, getState) => {
    const CompanyID = portalUserService.getCurrentCompanyId();

    const params = {
        companyId: CompanyID,
        Contacts: [
            {
                CompanyID,
                ...payload,
            },
        ],
    };

    let companyThunk = new TransceptaThunks.CompanyThunk();
    let response = await companyThunk.updateCompany(params);
    let data = await response.data;
    if (response.type && response.type === 'error') {
        dispatch(clearAlerts());
        dispatch(apiErrorAction(response.text, companyActions.SAVE_SELECTED_CONTACT_FAILED, params));
    } else {
        dispatch(companyActions.saveSelectedContactSuccess(data[0]));
        dispatch(navigateToManageRemittanceInstruction());
    }
};

export const navigateToManageRemittanceInstruction = () => (dispatch) => {
    dispatch(push(R.MANAGE_REMITTANCE_INSTRUCTIONS.path));
};

export const navigateToSettings = () => (dispatch) => {
    dispatch(push(R.SETTINGS_ADMINISTRATION.path));
};

export const updateSupplierEnableRealTimeFileConfirmationEmails = (payload) => async (dispatch) => {
    const companyThunk = new TransceptaThunks.CompanyThunk();
    const response = await companyThunk.updateCompany(payload);

    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, profilesActions.ACCOUNT_PROFILE_FAILED, params));
        return;
    }

    dispatch(navigateToSettings());
};
