import { useQuery } from '@tanstack/react-query';
import { CompanyService, backendServices, portalUserService } from '../../services';

const determineSenderCompanyId = (context: backendServices.ViewModels.ExecutionContext, senderCompanyId: number) => {
    if (context === backendServices.ViewModels.ExecutionContext.UserWorkflowOrWorkflowActivity) {
        return senderCompanyId;
    }

    if (context === backendServices.ViewModels.ExecutionContext.WebEntry) {
        return portalUserService.getCurrentCompanyId();
    }

    throw new Error('Unexpected execution context.');
};

/**
 * Retrieves the vendor class and loading status for the custom JS rule engine v2.
 *
 * @param options - The options for retrieving the vendor class.
 * @param options.buyerCompanyId - The buyer company ID.
 * @param options.context - The execution context.
 * @param options.senderCompanyId - The sender company ID.
 * @param options.childCompanyId - The child company ID if applicable.
 * @returns An object containing the vendor class and loading status.
 */
export function useVendorForCustomJSRuleEngineV2({
    buyerCompanyId,
    context,
    senderCompanyId: senderCompanyIdFromDoc,
    childCompanyId,
}: {
    buyerCompanyId?: number | null;
    context: backendServices.ViewModels.ExecutionContext;
    senderCompanyId?: number;
    childCompanyId?: number | null;
}) {
    const senderCompanyId = senderCompanyIdFromDoc
        ? determineSenderCompanyId(context, senderCompanyIdFromDoc)
        : undefined;

    const { data: vendorClass, isLoading: vendorIsLoading } = useQuery(
        ['VendorForCustomJSRuleEngineV2', buyerCompanyId, senderCompanyId, childCompanyId],
        async () => {
            const companyService = new CompanyService();

            const res = await companyService.getTradingPartnersByCompanyId(
                senderCompanyId!,
                `BuyerCompanyId eq ${buyerCompanyId}`,
                childCompanyId
            );

            return res.Items?.[0]?.VendorClass;
        },
        {
            enabled: buyerCompanyId != null && senderCompanyId != null,
        }
    );

    return {
        vendorClass: vendorClass ?? undefined,
        vendorIsLoading,
    };
}
