import globalPropertySource from './globalPropertySource';
import localStorageSource from './localStorageSource';
/**
 * All registered sources for feature flag configurations.
 *
 * Later sources that explicitly set a flag state override the
 * state set by earlier sources.
 */
const sources = [globalPropertySource, localStorageSource];

export default sources;
