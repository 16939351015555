import { makeStyles } from '@mui/styles';
import { ICustomTheme } from '../themes';

export const getClasses = (theme: ICustomTheme) => ({
    root: {
        padding: '8px 16px !important',
    },
    containedPrimary: {
        border: 0,
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
            border: 0,
            boxShadow: 'none',
            backgroundColor: theme.palette.primary['600'],
            color: theme.palette.primary.contrastText,
        },
        '&:active': {
            border: 0,
            boxShadow: 'none',
            backgroundColor: theme.palette.primary['700'],
            color: theme.palette.primary.contrastText,
        },
        '&:focus': {
            outline: '2px solid',
            outlineColor: theme.palette.primary['700'],
            outlineOffset: 0,
            boxShadow: 'none',
        },
    },
    containedError: {
        color: theme.palette.error.contrastText,
        backgroundColor: theme.palette.error.main,
        border: 0,
        boxShadow: 'none',
        '&:hover': {
            border: 0,
            boxShadow: 'none',
            backgroundColor: theme.palette.error['400'],
        },
        '&:active': {
            border: 0,
            boxShadow: 'none',
            backgroundColor: theme.palette.error['300'],
        },
        '&:focus': {
            outline: '2px solid',
            outlineColor: theme.palette.error['700'],
            outlineOffset: 0,
            boxShadow: 'none',
        },
    },
    containedWarn: {
        color: theme.palette.warning.contrastText,
        backgroundColor: theme.palette.warning.main,
        border: 0,
        boxShadow: 'none',
        '&:hover': {
            border: 0,
            boxShadow: 'none',
            backgroundColor: theme.palette.warning['400'],
        },
        '&:active': {
            border: 0,
            boxShadow: 'none',
            backgroundColor: theme.palette.warning['300'],
        },
        '&:focus': {
            outline: '2px solid',
            outlineColor: theme.palette.warning['700'],
            outlineOffset: 0,
            boxShadow: 'none',
        },
    },
    containedSuccess: {
        color: theme.palette.success.contrastText,
        backgroundColor: theme.palette.success.main,
        border: 0,
        boxShadow: 'none',
        '&:hover': {
            border: 0,
            boxShadow: 'none',
            backgroundColor: theme.palette.success['400'],
        },
        '&:active': {
            border: 0,
            boxShadow: 'none',
            backgroundColor: theme.palette.success['300'],
        },
        '&:focus': {
            outline: '2px solid',
            outlineColor: theme.palette.success['700'],
            outlineOffset: 0,
            boxShadow: 'none',
        },
    },
    textPrimary: {
        color: theme.palette.primary.main,
        border: 0,
        '&:active': {
            border: 0,
            boxShadow: 'none',
            backgroundColor: theme.palette.primary['200'],
        },
        '&:hover': {
            border: 0,
            boxShadow: 'none',
            backgroundColor: theme.palette.primary['100'],
        },
        '&:focus': {
            border: 0,
            outline: '2px solid',
            outlineColor: theme.palette.primary['500'],
            outlineOffset: 0,
            boxShadow: 'none',
        },
    },
    textError: {
        color: theme.palette.error.main,
        border: 0,
        '&:active': {
            border: 0,
            boxShadow: 'none',
            backgroundColor: theme.palette.error['200'],
        },
        '&:hover': {
            border: 0,
            boxShadow: 'none',
            backgroundColor: theme.palette.error['100'],
        },
        '&:focus': {
            border: 0,
            outline: '2px solid',
            outlineColor: theme.palette.error['500'],
            outlineOffset: 0,
            boxShadow: 'none',
        },
    },
    textWarn: {
        color: theme.palette.warning.main,
        border: 0,
        '&:active': {
            border: 0,
            boxShadow: 'none',
            backgroundColor: theme.palette.warning['200'],
        },
        '&:hover': {
            border: 0,
            boxShadow: 'none',
            backgroundColor: theme.palette.warning['100'],
        },
        '&:focus': {
            border: 0,
            outline: '2px solid',
            outlineColor: theme.palette.warning['500'],
            outlineOffset: 0,
            boxShadow: 'none',
        },
    },
    textSuccess: {
        color: theme.palette.success.main,
        border: 0,
        '&:active': {
            border: 0,
            boxShadow: 'none',
            backgroundColor: theme.palette.success['200'],
        },
        '&:hover': {
            border: 0,
            boxShadow: 'none',
            backgroundColor: theme.palette.success['100'],
        },
        '&:focus': {
            border: 0,
            outline: '2px solid',
            outlineColor: theme.palette.success['500'],
            outlineOffset: 0,
            boxShadow: 'none',
        },
    },
    outlinedPrimary: {
        color: theme.palette.primary.main,
        backgroundColor: 'transparent',
        border: `1px solid ${theme.palette.primary.main}`,
        '&:active': {
            border: `1px solid ${theme.palette.primary.main}`,
            boxShadow: 'none',
            backgroundColor: theme.palette.primary['200'],
        },
        '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`,
            boxShadow: 'none',
            backgroundColor: theme.palette.primary['100'],
        },
        '&:focus': {
            border: `1px solid ${theme.palette.primary.main}`,
            outline: '2px solid',
            outlineColor: theme.palette.primary['500'],
            outlineOffset: 0,
            boxShadow: 'none',
        },
    },
    outlinedError: {
        color: theme.palette.error.main,
        border: `1px solid ${theme.palette.error.main}`,
        '&:active': {
            border: `1px solid ${theme.palette.error.main}`,
            boxShadow: 'none',
            backgroundColor: theme.palette.error['200'],
        },
        '&:hover': {
            border: `1px solid ${theme.palette.error.main}`,
            boxShadow: 'none',
            backgroundColor: theme.palette.error['100'],
        },
        '&:focus': {
            border: `1px solid ${theme.palette.error.main}`,
            outline: '2px solid',
            outlineColor: theme.palette.error['500'],
            outlineOffset: 0,
            boxShadow: 'none',
        },
    },
    outlinedWarning: {
        color: theme.palette.warning.main,
        border: `1px solid ${theme.palette.warning.main}`,
        '&:active': {
            border: `1px solid ${theme.palette.warning.main}`,
            boxShadow: 'none',
            backgroundColor: theme.palette.warning['200'],
        },
        '&:hover': {
            border: `1px solid ${theme.palette.warning.main}`,
            boxShadow: 'none',
            backgroundColor: theme.palette.warning['100'],
        },
        '&:focus': {
            border: `1px solid ${theme.palette.warning.main}`,
            outline: '2px solid',
            outlineColor: theme.palette.warning['500'],
            outlineOffset: 0,
            boxShadow: 'none',
        },
    },
    outlinedSuccess: {
        color: theme.palette.success.main,
        border: `1px solid ${theme.palette.success.main}`,
        '&:active': {
            border: `1px solid ${theme.palette.success.main}`,
            boxShadow: 'none',
            backgroundColor: theme.palette.success['200'],
        },
        '&:hover': {
            border: `1px solid ${theme.palette.success.main}`,
            boxShadow: 'none',
            backgroundColor: theme.palette.success['100'],
        },
        '&:focus': {
            border: `1px solid ${theme.palette.success.main}`,
            outline: '2px solid',
            outlineColor: theme.palette.success['500'],
            outlineOffset: 0,
            boxShadow: 'none',
        },
    },
});

const useButtonStyles = makeStyles(getClasses);

export default useButtonStyles;
