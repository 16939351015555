// DEPRECATED: Do not use
// REFACTOR: Move under Molecules/DataRow (itself to be moved under Organisms/Grid) as that is the only place it is used.
import { FC } from 'react';
import { Label } from '../../Atoms';
import './GridCell.scss';
import { Info } from '@mui/icons-material';

export interface IGridCellProps {
    cellType: 'label' | 'icon';
    data: any;
}

const Icon = () => {
    return <Info />;
};

const componentMap = {
    label: Label,
    icon: Icon,
};

/**
 * @deprecated
 */
export const GridCell: FC<IGridCellProps> = ({ cellType, data }) => {
    const CellComponent = componentMap[cellType];

    return (
        <div className="core-grid-cell">
            {/* Ignore prop spreading here. Component is already deprecated. */}
            {/* eslint-disable-next-line */}
            <CellComponent {...data} />
        </div>
    );
};
