enum ValidationType {
    Unknown = 0,
    Standard = 1,
    Configurable = 2,
    RequiredField = 3,
    BuyerCustom = 4,
    BuyerCustomNonValidation = 5,
}

export default ValidationType;
