import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './envVariablePathConfigOverride';
import './setBuildKey';
import './defaultConfig';
import 'common/build/services/useClientSideApi';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import './styles/index.scss';
import store, { history } from './reducers';
import App from './components/App';
import pathsConfig from './paths.config.json';
import routes from './routes';
import { PortalProviders } from 'common';

const appRoot = document.getElementById('root')!;
ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <PortalProviders
                rootUrl={pathsConfig.ROOT_URL}
                useStrictMode={false}
                useBrowserRouter={false}
                routes={routes}
                enableDarkMode={false}
            >
                <App />
            </PortalProviders>
        </ConnectedRouter>
    </Provider>,
    appRoot
);
ReactModal.setAppElement(appRoot);
