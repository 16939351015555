import { useLocation } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import { IFlashMessage } from './IFlashMessage';

const seenLocationKeys = new Set<string | undefined>();

/**
 * Get flash message by key for current history entry state.
 *
 * @param messageKey the key of the message
 * @returns the message if it is set in history state, otherwise undefined
 */
export function useFlashMessage(messageKey: string) {
    const location = useLocation();
    const [message, setMessage] = useState<string | null>(null);
    const [alertSeverity, setAlertSeverity] = useState<Required<IFlashMessage>['alertSeverity'] | null>(null);

    useEffect(() => {
        // do not display flash message if we have on a previous view of this history entry
        if (seenLocationKeys.has(location.key)) {
            return;
        }
        let theMessage: string | null = null;
        let theAlertSeverity: typeof alertSeverity = null;
        try {
            const flashMessages = (location.state as any).flashMessages as IFlashMessage[];
            flashMessages.forEach((flashMessage) => {
                if (flashMessage.messageKey === messageKey) {
                    theMessage = flashMessage.text;
                    theAlertSeverity = flashMessage.alertSeverity ?? null;
                }
            });

            // keep track that we displayed the flash message on this view of this history entry
            seenLocationKeys.add(location.key);
        } catch (e) {
            /* ignore error */
        }

        setMessage(theMessage);
        setAlertSeverity(theAlertSeverity);
    }, [location, messageKey]);

    const clearMessage = useCallback(() => {
        setMessage(null);
        setAlertSeverity(null);
    }, []);

    return [message, clearMessage, alertSeverity] as const;
}
