import { Box } from '@mui/material';
import { useReferenceWidth } from '../../../utils';
import { FCNC } from '../../../ui';

export interface IDotNotationErrorProps {
    validationContext: string;
    error: Error;
}

export const DotNotationError: FCNC<IDotNotationErrorProps> = ({ validationContext, error }) => {
    const [message, ...errorContext] = error.message.split('\n');

    const [referenceWidth, ref] = useReferenceWidth((el) => el.classList.contains('MuiAlert-root'));

    return (
        <Box sx={{ display: 'inline-block', verticalAlign: 'top' }}>
            <span>
                {validationContext}: {message}:
            </span>
            <Box ref={ref} component="pre" sx={{ maxWidth: referenceWidth - 130, overflow: 'auto' }}>
                {errorContext.join('\n')}
            </Box>
        </Box>
    );
};
