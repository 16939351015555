//

import { handleActions } from 'redux-actions';

import * as CustomerActions from '../actions/CustomerActions';
import * as CustomerLocationActions from '../actions/invoiceCreation/customerLocation';
import * as CustomerModels from '../models/CustomerModels';
import { Action } from '../models/Actions';
import { applySortingHelper } from './helpers';
import * as UserLoginActions from '../actions/userlogin';

const initialState = {
    customers: [],
    isLoading: false,
    currentCustomer: null,
    currentCustomerLocation: null,
    buyerProfiles: [],
    availableDocTypes: [],
    parkingEnabled: false,
    showParkingFailuresInSupplierPortal: false,
    customersFilter: {
        items: [],
        isLoading: false,
    },
    search: {
        isLoading: false,
        totalEntries: null,
        filteredCustomers: [],
        searchParams: {},
        searchString: '',
        onlyAdvancedSearch: null,
        searchBoxWide: true,
        skip: null,
        sorting: { column: 'CustomerNumber', direction: 'ASC', type: 'String' },
        advancedSearch: false,
        fetchAll: true,
    },
    currentProfile: null,
};

const customerReducer = handleActions(
    {
        [UserLoginActions.USER_LOGOUT]: (state, action) => {
            const { timeout } = action.payload;
            return timeout && timeout == true ? state : initialState;
            //return { ...initialState };
        },

        [CustomerActions.NAVIGATE_TO_SELECT_CUSTOMER_PAGE]: (state) => {
            return {
                ...state,
            };
        },

        [CustomerActions.SELECT_CUSTOMER_LIST_FETCH_EXECUTE]: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        [CustomerActions.SELECT_CUSTOMER_LIST_FETCH_SUCCESS]: (state, action) => {
            return {
                ...state,
                isLoading: false,
                customers: action.payload,
            };
        },
        [CustomerActions.SELECT_CUSTOMER_LIST_FETCH_FAILED]: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },

        [CustomerActions.SELECT_CUSTOMER]: (state, action) => {
            return {
                ...state,
                currentCustomer: action.payload,
            };
        },

        [CustomerActions.SELECT_CUSTOMER_EXECUTE]: (state, action) => {
            return {
                ...state,
                currentCustomer: initialState.currentCustomer,
            };
        },

        [CustomerActions.SELECT_CUSTOMER_LOCATION]: (state, action) => {
            return {
                ...state,
                currentCustomerLocation: action.payload,
            };
        },

        [CustomerActions.SEARCH_BUYER_PROFILES_EXECUTE]: (state) => {
            return {
                ...state,
            };
        },
        [CustomerActions.SEARCH_BUYER_PROFILES_SUCCESS]: (state, action) => {
            return {
                ...state,
                buyerProfiles: action.payload,
            };
        },
        [CustomerActions.SEARCH_BUYER_PROFILES_FAILED]: (state) => {
            return {
                ...state,
            };
        },
        [CustomerActions.SELECT_CUSTOMER_SETTINGS]: (state, action) => {
            return {
                ...state,
                availableDocTypes: action.payload.BusinessDocEnables,
                parkingEnabled: action.payload.EnableParkingLot,
                showParkingFailuresInSupplierPortal: action.payload.ShowParkingFailuresInSupplierPortal,
            };
        },

        [CustomerLocationActions.FETCH_CUSTOMER_LOCATION_EXECUTE]: (state, action) => {
            return {
                ...state,
                currentCustomerLocation: initialState.currentCustomerLocation,
            };
        },
        [CustomerLocationActions.FETCH_CUSTOMER_LOCATION_FAILURE]: (state, action) => {
            return {
                ...state,
                currentCustomerLocation: initialState.currentCustomerLocation,
            };
        },
        [CustomerLocationActions.FETCH_CUSTOMER_LOCATION_SUCCESS]: (state, action) => {
            return {
                ...state,
                currentCustomerLocation: action.payload.Count > 0 ? action.payload.Items[0] : null,
            };
        },
        [CustomerActions.CUSTOMERS_FOR_ADVANCE_SEARCH_FILTER_FETCHING]: (state, action) => {
            return {
                ...state,
                customersFilter: { items: [], isLoading: true },
            };
        },
        [CustomerActions.CUSTOMERS_FOR_ADVANCE_SEARCH_FILTER_FETCHED]: (state, action) => {
            return {
                ...state,
                customersFilter: { items: action.payload, isLoading: false },
            };
        },
        [CustomerActions.CUSTOMERS_TOOGLE_SEARCH]: (state, action) => {
            return {
                ...state,
                search: { ...state.search, advancedSearch: action.payload },
            };
        },
        [CustomerActions.CUSTOMERS_FETCHING]: (state, action) => {
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: true,
                    searchParams: action.payload && action.payload.searchParams ? action.payload.searchParams : null,
                    searchString: action.payload.searchString,
                    fetchAll: action.payload && action.payload.fetchAll ? action.payload.fetchAll : null,
                },
            };
        },
        [CustomerActions.CUSTOMERS_FETCHED]: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                search: {
                    ...state.search,
                    isLoading: false,
                    filteredCustomers: payload.filteredCustomers,
                    searchString: payload.searchString,
                    searchParams: payload.searchParams,
                    totalEntries:
                        payload.totalEntries !== null && payload.totalEntries !== undefined
                            ? payload.totalEntries
                            : state.totalEntries,
                    skip: payload.skip,
                    sort: payload.sort,
                    fetchAll: payload.fetchAll ? payload.fetchAll : false,
                },
            };
        },
        [CustomerActions.CUSTOMERS_CLEAR_SEARCH]: (state, action) => {
            return {
                ...state,
                search: {
                    ...initialState.search,
                    advancedSearch: state.search.advancedSearch,
                },
            };
        },
        [CustomerActions.CUSTOMERS_CLEAR_SEARCH_STRING]: (state, action) => {
            return {
                ...state,
                search: {
                    ...state.search,
                    searchString: '',
                },
            };
        },
        [CustomerActions.CUSTOMERS_APPLY_SORTING]: (state, action) => {
            return {
                ...state,
                search: {
                    ...applySortingHelper(state.search, action.payload),
                },
            };
        },
        [CustomerActions.CUSTOMER_PROFILE_FETCHING]: (state, action) => {
            return {
                ...state,
                currentProfile: {
                    isLoading: true,
                },
            };
        },
        [CustomerActions.CUSTOMER_PROFILE_FETCHED]: (state, action) => {
            return {
                ...state,
                currentProfile: {
                    ...action.payload,
                    isLoading: false,
                },
            };
        },

        [CustomerActions.CURRENT_CUSTOMER_CLEAR]: (state, action) => {
            return {
                ...state,
                currentProfile: null,
                currentCustomer: null,
                currentCustomerLocation: null,
            };
        },
    },
    initialState
);

export default customerReducer;
