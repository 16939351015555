import { calculateDocumentTotals } from './calculateDocumentTotals';
import { path, Path } from 'ramda';
import { isFeatureEnabled } from '../../config';

/**
 * Why the hell does this exist?
 *
 * We can't use `calculateDocumentTotals` directly as a selector because of crappy old code that uses mutation
 * to calculate information during renders and relies on "resetting" these calculations when a change is made to the
 * document. It detects these changes when the document object changes. Using the selector method means the document object
 * changes when anything in redux changes. We need the approach below to make the document object with re-calculated totals
 * change only when the document in redux changes.
 *
 * Yes this is horrible and yet another reason why we need to re-write document display.
 */

type DocumentStateUpdateFunction = <T extends object>(
    state: T,
    docFields: ReturnType<typeof calculateDocumentTotals>
) => T;

/**
 * Wraps a reducer for the "document" slice so that totals are re-calculated whenever an action causes a new
 * "document" to be returned.
 *
 * @param reducer the base reducer
 * @param documentPath the path to the document fields in the state object
 * @param setDocument the function used to set the document fields in the state object
 * @returns the new reducer
 */
export function createDocumentReducerThatReCalculatesTotalsCorrectly(
    reducer: (state: any, action: any) => any,
    documentPath?: Path,
    setDocument?: DocumentStateUpdateFunction
) {
    if (!isFeatureEnabled('DocumentTotalsCalculationsV2')) {
        return reducer;
    }

    const docPath = documentPath ?? [];
    return (state: any, action: any) => {
        const newState = reducer(state, action);

        if (state !== newState && path(docPath, state) !== path(docPath, newState)) {
            const updatedDocument = calculateDocumentTotals(path(docPath, newState)!);
            return setDocument?.(newState, updatedDocument) ?? updatedDocument;
        }
        return newState;
    };
}
