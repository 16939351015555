import { CircularProgress } from '@mui/material';
import { Grid } from '../Grid';

export function Loading() {
    return (
        <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <Grid item>
                <CircularProgress />
            </Grid>
        </Grid>
    );
}
