export type LocaleOptions = {
    symbolPlacement: 'left' | 'right';
    thousandsSeparator: string;
    decimalSeparator: string;
};

const localePlacements = new Map<string, LocaleOptions>([
    [
        'en_US',
        {
            symbolPlacement: 'left',
            thousandsSeparator: ',',
            decimalSeparator: '.',
        },
    ],
    [
        'en_GB',
        {
            symbolPlacement: 'left',
            thousandsSeparator: ',',
            decimalSeparator: '.',
        },
    ],
    [
        'en_CA',
        {
            symbolPlacement: 'left',
            thousandsSeparator: ',',
            decimalSeparator: '.',
        },
    ],
    [
        'fr_CA',
        {
            symbolPlacement: 'right',
            thousandsSeparator: ' ',
            decimalSeparator: ',',
        },
    ],
    [
        'de',
        {
            decimalSeparator: ',',
            thousandsSeparator: ' ',
            symbolPlacement: 'right',
        },
    ],
]);

const localePercentPlacements = new Map<string, LocaleOptions>([
    [
        'en_US',
        {
            symbolPlacement: 'right',
            thousandsSeparator: ',',
            decimalSeparator: '.',
        },
    ],
    [
        'en_GB',
        {
            symbolPlacement: 'right',
            thousandsSeparator: ',',
            decimalSeparator: '.',
        },
    ],
    [
        'en_CA',
        {
            symbolPlacement: 'right',
            thousandsSeparator: ',',
            decimalSeparator: '.',
        },
    ],
    [
        'fr_CA',
        {
            symbolPlacement: 'right',
            thousandsSeparator: ' ',
            decimalSeparator: ',',
        },
    ],
    [
        'de',
        {
            symbolPlacement: 'left',
            thousandsSeparator: ' ',
            decimalSeparator: ',',
        },
    ],
]);

export const getLocalePlacements = (locale: string) => {
    return localePlacements.get(locale) ?? localePlacements.get('en_US')!;
};

export const getLocalePercentPlacements = (locale: string) => {
    return localePercentPlacements.get(locale) ?? localePercentPlacements.get('en_US')!;
};
