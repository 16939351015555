import update from 'immutability-helper';
import actions from '../../actions/index';

const { CHANGEPASSWORD_FIELD_CHANGE, CHANGEPASSWORD_CANCEL } = actions;

const initialState = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
};

// eslint-disable-next-line import/prefer-default-export
export function changePassword(state = initialState, action) {
    switch (action.type) {
        case CHANGEPASSWORD_FIELD_CHANGE:
            return update(state, { [action.payload.name]: { $set: action.payload.value } });
        case CHANGEPASSWORD_CANCEL:
            return { ...initialState };
        default:
            return state;
    }
}
