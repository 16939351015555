import { GridColumns, GridRowModel, GridRowParams, GridSortModel } from '@mui/x-data-grid';
import { Box, List, Stack, TextField, Typography } from '@mui/material';
import { Loading } from '../Loading';
import { Alert } from '../Alert';
import { DataGridMobileListItem } from './DataGridMobileListItem';
import { Pagination } from '../Pagination';
import { SortedSelect } from '../AutocompleteAndSortedSelect';
import { FCNC } from '../FCNC';
import { FocusEvent, KeyboardEvent, useEffect, useState } from 'react';

export interface IDataGridMobileServerProps {
    /**
     * Number of rows for entire data grid
     */
    rowCount: number;

    /**
     * interface for what columns to show and what options for the row are avalible
     */
    columns: GridColumns;

    /**
     * Function for what row was clicked and what should happen
     *
     * @param param
     */
    onRowClick?: (param: GridRowParams) => void;

    pageSize: number;

    onPageSizeChange?: (newPageSize: number) => void;

    page?: number;

    onPageChange?: (newPage: number) => void;

    sortModel?: GridSortModel;

    onSortModelChange?: (newSortModel: GridSortModel) => void;

    isLoading: boolean;

    error: unknown;

    data: GridRowModel[] | undefined;

    testId?: string;

    jumpPage?: boolean;
}

export const DataGridMobileServer: FCNC<IDataGridMobileServerProps> = ({
    rowCount,
    columns,
    onRowClick,
    pageSize,
    page,
    sortModel,
    onPageChange,
    // onPageSizeChange, TODO: Implement page size change for mobile view
    onSortModelChange,
    isLoading,
    error,
    data,
    testId,
    jumpPage = false,
}) => {
    const noSorting = !sortModel || !onSortModelChange;
    const [_page, setPage] = useState(String(page ? page + 1 : 1));
    const [_error, setError] = useState<boolean>(false);

    const noPagination = onPageChange === undefined || page === undefined;

    useEffect(() => {
        if (page !== undefined) {
            setPage(String(page + 1));
        }
    }, [page]);

    const sortOptions = noSorting
        ? []
        : columns
              .filter((column) => column.sortable ?? true)
              .filter((column) => column.headerName && column.field)
              .flatMap(
                  (column) =>
                      [
                          {
                              id: column.field,
                              label: `${column.headerName ?? ''} - Ascending`,
                              field: column.field,
                              sort: 'asc',
                          },
                          {
                              id: column.field,
                              label: `${column.headerName ?? ''} - Descending`,
                              field: column.field,
                              sort: 'desc',
                          },
                      ] as const
              );

    const sortBy = noSorting
        ? null
        : sortOptions.find((x) => x.field === sortModel[0]?.field && x.sort === sortModel[0]?.sort) ?? null;

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return <Alert severity="error" testId="data-grid-error">{`Error: ${error}`}</Alert>;
    }

    const submitJump = (target: HTMLInputElement) => {
        if (onPageChange) {
            const pageCount = Math.ceil(rowCount / pageSize);
            const newPage = Number(target.value);
            if (!Number.isNaN(newPage) && newPage >= 1 && newPage <= pageCount) {
                setPage(String(newPage));
                onPageChange(newPage - 1);
                setError(false);
            } else {
                setPage(String(page! + 1));
                setError(true);
            }
        }
    };

    const handleJumpToPageFocus = (event: FocusEvent<HTMLInputElement>) => {
        event.target.setSelectionRange(0, event.target.value.length);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            submitJump(event.target as HTMLInputElement);
        }
    };

    return (
        <div style={{ width: '100%', minHeight: 162 }} data-testid={testId}>
            {!noSorting && sortOptions.length > 0 && (
                <Stack direction="row" alignItems="flex-end" sx={{ my: 1 }}>
                    <SortedSelect
                        label="Sort By"
                        stacked
                        onChange={(value) => onSortModelChange(value ? [value] : [])}
                        options={sortOptions}
                        value={sortBy}
                    />
                </Stack>
            )}
            <List sx={{ py: 0 }}>
                {data?.map((row, index) => (
                    <DataGridMobileListItem
                        key={row.id}
                        index={index}
                        row={row}
                        rows={data}
                        columns={columns}
                        onRowClick={onRowClick}
                    />
                ))}
            </List>
            {!noPagination && (
                <Stack alignItems="center" direction="row" justifyContent={jumpPage ? 'space-between' : 'center'}>
                    <Pagination
                        count={Math.ceil(rowCount / pageSize)}
                        page={page}
                        setPage={onPageChange}
                        size="small"
                    />

                    {jumpPage && (
                        <Stack direction="row" alignItems="center">
                            <Typography variant="caption" component="span" sx={{ mr: 1 }}>
                                Jump to
                            </Typography>
                            <Box sx={{ width: 48 }}>
                                <TextField
                                    value={_page}
                                    onFocus={handleJumpToPageFocus}
                                    onChange={({ target }) => setPage(target.value)}
                                    onBlur={({ target }) => submitJump(target as HTMLInputElement)}
                                    onKeyDown={handleKeyDown}
                                    error={_error}
                                />
                            </Box>
                        </Stack>
                    )}
                </Stack>
            )}
        </div>
    );
};
