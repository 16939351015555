import { AxiosRequestConfig } from 'axios';

/**
 * Ensure that urls for the same resource are exact string matches.
 * @param url the url to normalize
 * @returns the normalized url
 */
export function normalizeRequestUrl(url: string | undefined) {
    if (!url) {
        return null;
    }

    // remove query string from url since sometimes query string hasn't been processed.
    url = url.replace(/\?.*/g, '');

    if (url[0] !== '/') {
        return '/' + url;
    }

    return url;
}

function headersToKey(headers: any) {
    if (!headers) {
        return null;
    }

    const headersCopy = { ...headers };
    ['delete', 'post', 'put', 'patch', 'get', 'common', 'head'].forEach((k) => {
        delete headersCopy[k];
    });

    return {
        ...headers.common,
        ...headers.get,
        ...headersCopy,
    };
}

function extractParams(url: string | undefined) {
    if (!url) {
        return undefined;
    }

    const params: any = {};
    url.substring(url.indexOf('?') + 1)
        .split('&')
        .forEach((param) => {
            const [k, v] = param.split('=');
            params[decodeURIComponent(k)] = decodeURIComponent(v);
        });

    return params;
}

function stringifyParamValues(params: any) {
    if (!params) {
        return params;
    }

    const newParams: any = {};
    Object.keys(params).forEach((key) => {
        newParams[key] = params[key] + '';
    });

    return newParams;
}

function deepSortByKey(obj: any): any {
    if (Array.isArray(obj)) {
        return obj.map(deepSortByKey);
    }

    if (typeof obj === 'object' && obj !== null) {
        const sortedKeys = Object.keys(obj).sort();
        const newObj: any = {};
        sortedKeys.forEach((k) => {
            newObj[k] = deepSortByKey(obj[k]);
        });
        return newObj;
    }

    return obj;
}

/**
 * Return a string cache key for the request object
 * @param request to retrieve key for
 * @returns the request's key
 */
export function requestToKey(request: AxiosRequestConfig) {
    return JSON.stringify(
        deepSortByKey({
            url: normalizeRequestUrl(request.url),
            params: stringifyParamValues(request.params ?? extractParams(request.url)) ?? null,
            headers: headersToKey(request.headers),
        })
    );
}
