import { init, runIfEnabled } from './init';
import {
    isConditionalRecordingEnabled,
    enableRecordingOnDevice,
    disableRecordingOnDevice,
} from './conditionalRecording';
import { conditionalRecordingStopgap } from './conditionalRecordingStopgap';

export interface ITransceptaLogRocketUserTraits {
    companyID: string;
    name: string;
    isSSO: string;
    email: string;
    username: string;
    isRecordingEnabled: boolean;
}

/**
 * Identify the user to LogRocket and enable session recording if not already
 * enabled.
 *
 * @param userId unique identifier for the user
 * @param traits additional user traits about the user
 */
export function identify(userId: string, traits: ITransceptaLogRocketUserTraits) {
    const { isRecordingEnabled, ...logRocketTraits } = conditionalRecordingStopgap(traits);

    if (isConditionalRecordingEnabled() && !isRecordingEnabled) {
        // this will disable recording in future page loads. recording
        // might still be on in this page load if the "isRecordingEnabled" flag was not already
        // false.
        disableRecordingOnDevice();
        return;
    }

    if (isRecordingEnabled) {
        enableRecordingOnDevice();
    }

    init();

    runIfEnabled((logRocket) => {
        logRocket.identify(userId, logRocketTraits as any);
    });
}
