import { getInstances, Permission } from '../../../services/UserService/PortalUserService/indirection';

/**
 * Determines how the CRUD page should be rendered based on user permissions.
 *
 * @param entityId the id of the entity being edited (or null if creating)
 * @param readPermission the permission required to read/view an existing entity
 * @param createPermission the permission required to create a new entity
 * @param editPermission the permission required to edit an existing entity
 * @param deletePermission the permission required to delete an existing entity
 * @returns information to control how to render the page
 */
export function useCRUDPermissions(
    entityId: any,
    readPermission: Permission | undefined,
    createPermission: Permission | undefined,
    editPermission: Permission | undefined,
    deletePermission: Permission | undefined,
    readonly: boolean | undefined
) {
    const user = getInstances().useCurrentUser('mustBeLoggedIn');
    const canCreate = !createPermission || user.permissions.has(createPermission);
    const canRead = !readPermission || user.permissions.has(readPermission);
    const canEdit = !editPermission || user.permissions.has(editPermission);
    const canDelete = !deletePermission || user.permissions.has(deletePermission);

    const mode = (() => {
        if (entityId === null) {
            if (canCreate) {
                return 'Create';
            } else {
                return 'CreateAccessDenied';
            }
        }

        if (readonly && canRead) {
            return 'View';
        }

        // it doesn't make sense to be able to edit without being able to read
        if (canEdit && canRead) {
            return 'Edit';
        }

        if (canRead) {
            return 'View';
        }

        return 'EditAccessDenied';
    })();

    const editable = mode === 'Create' || mode === 'Edit';

    return { canEdit, canDelete, editable, mode } as const;
}
