/* eslint-disable react/jsx-props-no-spreading */
import { createContext, useContext, useMemo, ReactNode, ComponentType } from 'react';

export type ServiceConstructor = new (...args: any[]) => any;

export type CompatibleInstance<C extends ServiceConstructor> = Pick<InstanceType<C>, keyof InstanceType<C>>;

export type CompatibleConstructor<C extends ServiceConstructor> = new (
    ...args: ConstructorParameters<C>
) => CompatibleInstance<C>;

export interface ICreateServiceProps<C extends ServiceConstructor> {
    args: ConstructorParameters<C>;
    children: ReactNode;
}

export interface ICreateServiceMockProps<C extends ServiceConstructor, M extends CompatibleConstructor<C>> {
    cls: M;
    children: ReactNode;
}

export type CreateServiceInsideArgs<
    Props,
    Component extends ComponentType<Props>,
    C extends ServiceConstructor
> = ConstructorParameters<C> extends [] ? [Component] : [Component, (props: Props) => ConstructorParameters<C>];

/**
 * Component and subcomponents/helpers to inject a service in a subtree.
 */
export interface ICreateServiceComponent<C extends ServiceConstructor> {
    /**
     * Main component to construct and inject a service in its subtree.
     */
    ({ args, children }: ICreateServiceProps<C>): JSX.Element;

    /**
     * Creates a component by taking 1 or 2 arguments (1 if the service class has no constructor arguments, 2 otherwise).
     *
     * The first argument is the component implementation. The props of the created component will be passed into it.
     *
     * The second argument is a function that is provided the props of the created component, which returns the arguments
     * to the service class's constructor. The second argument cannot be provided if the service class's constructor has
     * no parameters.
     *
     * This is a useful shorthand when you need access to the service directly in the top-level component you want it
     * provided in. Without this, the top-level component would not be able to access the service.
     */
    inside: <Props>(...args: CreateServiceInsideArgs<Props, ComponentType<Props>, C>) => (props: Props) => JSX.Element;

    /**
     * Provide a different implementation of a service class to use in a subtree. This implementation
     * will be used instead of the default implementation in the subtree.
     */
    OverrideClassInSubtree: <M extends CompatibleConstructor<C>>({
        cls,
        children,
    }: ICreateServiceMockProps<C, M>) => JSX.Element;

    /**
     * Returns the current instance for use in an automated test *only*.
     *
     * Never use this in a component implementation. It will not work if the application
     * provides multiple instances of the service in different parts of the component tree.
     * @returns the *only* instance in a rendered tree.
     */
    getInstanceInTest: <M extends CompatibleInstance<InstanceType<C>> = InstanceType<C>>() => M;
}

/**
 * Utility to provide a very simple form of dependency injection that allows us to replace our
 * "service layer" classes with "fake" implementations in our automated testing code.
 *
 * There is no concept of "wiring dependencies". Instead, the injector constructs the "root" service that
 * the components interact with. The constructor of the service class that is constructed is responsible for
 * constructing its own dependencies. Since we design our components to only interact with a single "root" service,
 * this allows us to mock out just the "root service" interface for our tests by defining a class with the same
 * public interface as the original "root service".
 */
export function createServiceInjector<C extends ServiceConstructor>(constructor: C) {
    type ServiceInstanceType = InstanceType<C>;

    const ConstructorContext = createContext<C>(constructor);
    const InstanceContext = createContext<ServiceInstanceType | null>(null);

    // this is being used to access the last created instance in test code. it cannot
    // be relied on in real code where multiple instances of a service may exist within
    // different parts of the application's component tree. but in tests, the tree will
    // only contain a single instance.
    let currentInstance: ServiceInstanceType | null = null;

    /**
     * Constructs a new instance of the service class when any of the arguments passed to the hook
     * change.
     *
     * Use this instead of `CreateService` when you only need to access the instance of a service
     * at one level in the component tree. Generally you don't want to use this though.
     *
     * @param args arguments to provide to the service class's constructor
     * @returns an instance of the service class.
     */
    const useNewService = (...args: ConstructorParameters<C>) => {
        const Constructor = useContext(ConstructorContext);
        const instance = useMemo(() => {
            currentInstance = new Constructor(...args) as ServiceInstanceType;
            return currentInstance!;
            // we want to treat each arg as a separate dependency, that way the args array instance
            // can change, even if none of the actual args change. this makes it easier to use the
            // `CreateService` component.
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [Constructor, ...args]);
        return instance;
    };

    /**
     * why
     * @param param0
     * @returns
     */
    const CreateService: ICreateServiceComponent<C> = ({ args, children }: ICreateServiceProps<C>) => {
        const instance = useNewService(...args);

        return <InstanceContext.Provider value={instance}>{children}</InstanceContext.Provider>;
    };

    CreateService.inside = <Props,>(...args: CreateServiceInsideArgs<Props, ComponentType<Props>, C>) => {
        const [C, getArgs] = args[1]
            ? [args[0], args[1]]
            : [args[0], (() => []) as any as () => ConstructorParameters<C>];

        return (props: Props) => {
            return (
                <CreateService args={getArgs(props)}>
                    <C {...props} />
                </CreateService>
            );
        };
    };

    CreateService.OverrideClassInSubtree = <M extends CompatibleConstructor<C>>({
        cls,
        children,
    }: ICreateServiceMockProps<C, M>) => {
        return <ConstructorContext.Provider value={cls as any}>{children}</ConstructorContext.Provider>;
    };

    CreateService.getInstanceInTest = <M extends CompatibleInstance<ServiceInstanceType> = ServiceInstanceType>() => {
        return currentInstance! as M;
    };

    /**
     * Returns the current instance of a service class that was provided by `CreateService`.
     * @returns an instance of `C`, which is of type `T`
     */
    const useCurrentService = () => {
        const instance = useContext(InstanceContext);

        if (instance === null) {
            throw new Error(
                'Attempted to call `useService` in a component that is not an ancestor of `CreateService`. `CreateService` creates and provides an instance of a service to its subtree.'
            );
        }

        return instance;
    };

    return [CreateService, useCurrentService, useNewService] as const;
}
