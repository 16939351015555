import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface SplitScreenDocumentViewContextType {
    splitScreenDocumentView: boolean;
    splitScreenDocumentViewSupported: boolean;
    setSplitScreenDocumentView: (splitScreenDocumentView: boolean) => void;
    clearSplitScreenDocumentView: () => void;
}

const defaultSplitScreenDocumentViewContext: SplitScreenDocumentViewContextType = {
    splitScreenDocumentView: false,
    splitScreenDocumentViewSupported: false,
    setSplitScreenDocumentView: () => {},
    clearSplitScreenDocumentView: () => {},
};

const SplitScreenDocumentViewContext = createContext<SplitScreenDocumentViewContextType>(
    defaultSplitScreenDocumentViewContext
);

interface SplitScreenDocumentViewProviderProps {
    children: ReactNode;
}

const SPLIT_SCREEN_LOCAL_STORAGE_KEY = 'splitScreenDocumentView';

export const SplitScreenDocumentViewProvider: React.FC<SplitScreenDocumentViewProviderProps> = ({ children }) => {
    const storedSplitScreenDocumentViewSetting = localStorage.getItem(SPLIT_SCREEN_LOCAL_STORAGE_KEY);

    const [splitScreenDocumentView, setSplitScreenDocumentView] = useState<boolean>(
        storedSplitScreenDocumentViewSetting === 'true' ? true : false
    );

    useEffect(() => {
        localStorage.setItem(SPLIT_SCREEN_LOCAL_STORAGE_KEY, splitScreenDocumentView ? 'true' : 'false');
    }, [splitScreenDocumentView]);

    const clearSplitScreenDocumentView = () => {
        localStorage.removeItem(SPLIT_SCREEN_LOCAL_STORAGE_KEY);
        setSplitScreenDocumentView(false);
    };

    return (
        <SplitScreenDocumentViewContext.Provider
            value={{
                splitScreenDocumentView,
                splitScreenDocumentViewSupported: true,
                setSplitScreenDocumentView,
                clearSplitScreenDocumentView,
            }}
        >
            {children}
        </SplitScreenDocumentViewContext.Provider>
    );
};

export const useSplitScreenDocumentView = () => {
    return useContext(SplitScreenDocumentViewContext);
};

export function withSplitScreenDocumentView<P>(
    Component: React.ComponentType<P & { splitScreenDocumentViewContext: SplitScreenDocumentViewContextType }>
): React.ComponentType<P> {
    return (props: P) => {
        const splitScreenDocumentViewContext = useSplitScreenDocumentView();

        return (
            <Component
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                splitScreenDocumentViewContext={splitScreenDocumentViewContext}
            />
        );
    };
}
