import {
    Box,
    List,
    ListItem,
    ListItemButton,
    Typography,
    useMediaQuery,
    useTheme,
    CircularProgress,
    IconButton,
    Stack,
} from '@mui/material';
import { Sync } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import { disableLeftNavLink, menuVisibilityMapper } from './portalLeftNav';
import { useIsSelected } from './useIsSelected';
import { useCurrentUserCan, useCurrentCompanyId } from '../../services';
import { useHistory } from 'react-router';
import { useCompany } from '../../hooks';

const SyncButton = ({
    isSyncing,
    isHighlighted,
    onClick,
}: {
    isSyncing: boolean;
    isHighlighted: boolean;
    onClick: () => void;
}) => {
    return (
        <IconButton
            disabled={isSyncing}
            onClick={(event) => {
                event.preventDefault();

                onClick();
            }}
            edge="end"
        >
            {isSyncing ? (
                <CircularProgress
                    size="15px"
                    sx={{
                        color: 'white',
                    }}
                />
            ) : (
                <Sync
                    sx={{
                        pointerEvents: 'auto',
                        color: isHighlighted ? 'white' : 'black',
                    }}
                />
            )}
        </IconButton>
    );
};

type NavigationDrawerProps = {
    menuSettings: any;

    syncAllWorkflows?: {
        onClick: () => void;
        isSyncing: boolean;
    };
};

export function LeftNav({ menuSettings, syncAllWorkflows }: NavigationDrawerProps) {
    const isDashboardEnabled = useCurrentUserCan('Dashboard.DisplayNewDashboard');
    const currentCompanyID = useCurrentCompanyId();
    const currentCompany = useCompany({ companyId: currentCompanyID })?.data;

    // This is what generates the left nav links
    const items = currentCompany
        ? menuVisibilityMapper(menuSettings, isDashboardEnabled, undefined, currentCompany, true)
        : [];

    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const isSelected = useIsSelected();
    const history = useHistory();

    if (isMdDown) {
        return null;
    }

    if (!items) {
        return null;
    }

    return (
        <Box sx={{ minWidth: 245, bgcolor: 'background.paper' }}>
            <List sx={{ height: '100%', p: 0 }}>
                {items.map((menuItem, index) => {
                    // TODO: fix types used in menuVisibilityMapper (inference is not working properly)
                    // @ts-ignore
                    if ('isSectionHeader' in menuItem && menuItem.isSectionHeader) {
                        return (
                            <ListItem
                                key={`section-header-${menuItem.name}`}
                                sx={{ p: 0 }}
                                data-testid={`left-nav-item-${menuItem.name}`}
                            >
                                <ListItemButton
                                    sx={{
                                        backgroundColor: 'primary.700',
                                        color: 'white',
                                        pointerEvents: 'none',
                                    }}
                                >
                                    <Stack direction="row" justifyContent="space-between" width="100%">
                                        <Typography variant="button" sx={{ py: 1, textTransform: 'capitalize' }}>
                                            {menuItem.name}
                                        </Typography>
                                    </Stack>
                                </ListItemButton>
                            </ListItem>
                        );
                    }

                    const withSyncButton = menuItem.path.includes('/BuyerPortal/parking-lot') && index === 0;
                    const itemIsSelected = isSelected(menuItem.path);
                    let leftNavTabText = menuItem.name;

                    // TODO: fix types used in menuVisibilityMapper (inference is not working properly)
                    // @ts-ignore
                    if ('count' in menuItem && menuItem.count) {
                        // @ts-ignore
                        leftNavTabText = leftNavTabText.concat(` (${menuItem.count})`);
                    }

                    return (
                        <ListItem
                            key={`${menuItem.name}-${menuItem.path}`}
                            sx={{ p: 0 }}
                            data-testid={`left-nav-item-${menuItem.name}`}
                        >
                            <ListItemButton
                                component={NavLink}
                                to={menuItem.path}
                                sx={
                                    itemIsSelected
                                        ? {
                                              backgroundColor: 'primary.400',
                                              color: 'white',
                                              '&:hover': {
                                                  backgroundColor: '#06314F',
                                                  color: 'white',
                                              },
                                              // @ts-ignore
                                              boxShadow: (t) => `inset -6px 0 ${t.palette.primary[300]}`,
                                              pointerEvents:
                                                  disableLeftNavLink(history.location.pathname) ||
                                                  items.some((i) => i.path === history.location.pathname)
                                                      ? 'none'
                                                      : undefined,
                                          }
                                        : {
                                              '&:hover': {
                                                  backgroundColor: 'primary.100',
                                              },
                                          }
                                }
                            >
                                <Stack direction="row" justifyContent="space-between" width="100%">
                                    <Typography variant="button" sx={{ py: 1, textTransform: 'capitalize' }}>
                                        {leftNavTabText}
                                    </Typography>
                                    {syncAllWorkflows && withSyncButton && (
                                        <SyncButton
                                            isSyncing={syncAllWorkflows.isSyncing}
                                            isHighlighted={itemIsSelected}
                                            onClick={() => {
                                                syncAllWorkflows.onClick();
                                            }}
                                        />
                                    )}
                                </Stack>
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
}
