export interface IDynamicPropertyAccessExpr {
    type: 'IDynamicPropertyAccessExpr';
    object: DotNotationExpr;
    property: DotNotationExpr | INumberExpr | IVariableExpr;
}

export function createDynamicPropertyAccessExpr(
    value: Omit<IDynamicPropertyAccessExpr, 'type'>
): IDynamicPropertyAccessExpr {
    return {
        type: 'IDynamicPropertyAccessExpr',
        ...value,
    };
}

export interface IPropertyAccessExpr {
    type: 'IPropertyAccessExpr';
    object: DotNotationExpr;
    property: string;
}

export function createPropertyAccessExpr(value: Omit<IPropertyAccessExpr, 'type'>): IPropertyAccessExpr {
    return {
        type: 'IPropertyAccessExpr',
        ...value,
    };
}

export interface IPropertyNameExpr {
    type: 'IPropertyNameExpr';
    property: string;
}

export function createPropertyNameExpr(value: Omit<IPropertyNameExpr, 'type'>): IPropertyNameExpr {
    return {
        type: 'IPropertyNameExpr',
        ...value,
    };
}

export interface IVariableExpr {
    type: 'IVariableExpr';
    name: string;
}

export function createVariableExpr(value: Omit<IVariableExpr, 'type'>): IVariableExpr {
    return {
        type: 'IVariableExpr',
        ...value,
    };
}

export interface INumberExpr {
    type: 'INumberExpr';
    value: number;
}

export function createNumberExpr(value: Omit<INumberExpr, 'type'>): INumberExpr {
    return {
        type: 'INumberExpr',
        ...value,
    };
}

export type DotNotationExpr = IDynamicPropertyAccessExpr | IPropertyAccessExpr | IPropertyNameExpr;
