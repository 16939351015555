//
import { Action } from '../models/Actions';

export const PERFORM_CHANGE_COMMON_DOCUMENT_FIELD = 'PERFORM_CHANGE_COMMON_DOCUMENT_FIELD';

export const performChangeDocumentField = (fieldName, value) => ({
    type: PERFORM_CHANGE_COMMON_DOCUMENT_FIELD,
    payload: {
        fieldName,
        value,
    },
});
