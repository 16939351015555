import { createContext, useContext } from 'react';
import { UserWorkflowActivityState } from '../types/DocumentEditValidationState';
import { InternalRule } from './useInternalRules/InternalRule';
import { useInternalRules } from './useInternalRules';
import { useDropdownOptionsRepository } from '../DropdownOptionsRepositoryContext';
import { IFieldControlState } from '../types/IFieldControlStates';

import { MessageClasses } from '../types/ValidatorStates';
import { defaultMessageClassesState } from './useInternalRules/useInternalRules';
import { RecursivePartial } from '../../../types';
import { ValidationEvent } from '../../CustomJSRuleEngineV2/ExecutionService/DPSValidations';

type WorkflowActivityConfiguration = RecursivePartial<{
    GLCoding: {
        Mode: 'AlwaysVisible' | 'PODependent' | 'ReadOnly' | 'NeverVisible' | 'CustomLogic';
    };
}> | null;

interface InternalRulesValidator {
    validateEvent(event: ValidationEvent): Promise<boolean> | undefined;

    shouldDisableActionButton(
        eventType: Exclude<ValidationEvent['type'], 'FocusLossEvent' | 'WorkflowLoadEvent'>
    ): boolean;

    messageClasses: MessageClasses;

    getFieldControlState: (field: string) => IFieldControlState;

    workflowActivityConfiguration: WorkflowActivityConfiguration;
}

const InternalRulesValidatorContext = createContext<InternalRulesValidator>({
    validateEvent: () => Promise.resolve(true),
    shouldDisableActionButton: () => false,
    getFieldControlState: () => ({}),
    messageClasses: defaultMessageClassesState,
    workflowActivityConfiguration: null,
});

interface InternalRulesValidatorProviderProps {
    children: React.ReactChildren;

    getDocumentState: () => UserWorkflowActivityState | undefined;

    getInternalRules: () => InternalRule[] | undefined;

    workflowActivityConfiguration: WorkflowActivityConfiguration;
}

export function InternalRulesValidatorProvider({
    children,
    getDocumentState,
    getInternalRules,
    workflowActivityConfiguration,
}: InternalRulesValidatorProviderProps) {
    const dropdownOptionsRepository = useDropdownOptionsRepository();

    const state = useInternalRules({
        getDocumentState,
        getInternalRules,
        dropdownOptionsRepository,
    });

    return (
        <InternalRulesValidatorContext.Provider value={{ ...state, workflowActivityConfiguration }}>
            {children}
        </InternalRulesValidatorContext.Provider>
    );
}

export function useInternalRulesValidatorContext() {
    const state = useContext(InternalRulesValidatorContext);

    return state;
}

export function withInternalRulesValidator<P>(
    Component: React.ComponentType<P & { internalRulesValidator: InternalRulesValidator }>
): React.ComponentType<P> {
    return (props: P) => {
        const validator = useInternalRulesValidatorContext();

        return (
            <Component
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                internalRulesValidator={validator}
            />
        );
    };
}
