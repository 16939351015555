import { FC } from 'react';
import { FileUtil } from '../../../../transcepta-common';
import { Delete, InsertDriveFile } from '@mui/icons-material';
import { FileTypes, IFile } from '../modules/types';
import { IconButton } from '../../../Atoms/IconButton';

interface IAcceptedFileProps extends IFile {
    downloadAttachment: (id: number) => void;
}

export const AcceptedFile: FC<IAcceptedFileProps> = ({
    Id,
    name,
    FileSize,
    size,
    fileType,
    errors,
    onDeleteFile,
    preview,
    downloadAttachment,
}) => {
    const handleDownload = async () => {
        if (!preview) {
            downloadAttachment(Id);
        }
    };

    const fileTypeResult = () => {
        switch (fileType) {
            case FileTypes.accepted:
                return 'ready to upload';
            case FileTypes.rejected:
                return 'File is not allowed and will not be uploaded';
            default:
                return '';
        }
    };

    return (
        <div data-testid={`file-${Id}`} className="file">
            <div className="info">
                <InsertDriveFile />
                <div>
                    <a
                        id="a-download-attachment"
                        data-testid={`fileDownloadBtn-${Id}`}
                        className="dropzone-btn download-btn"
                        href={preview}
                        onClick={handleDownload}
                        download
                    >
                        {name}
                    </a>

                    <p className="file-size">{FileSize || `(${FileUtil.getReadableFileSize(size)})`}</p>
                    <p className={fileType === FileTypes.accepted ? 'file-allowed' : 'not-allowed'}>
                        {fileTypeResult()}
                    </p>
                    {errors && errors.length > 0 && (
                        <div className="errors">
                            {errors.map((error) => (
                                <p>{error}</p>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            {onDeleteFile && (
                <IconButton
                    id="a-delete-attachment"
                    data-testid={`fileDeleteBtn-${Id}`}
                    className="dropzone-btn remove-btn"
                    onClick={(event) => onDeleteFile(event, Id, fileType)}
                    type="button"
                >
                    <Delete />
                </IconButton>
            )}
        </div>
    );
};
