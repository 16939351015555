import { Box, SelectChangeEvent, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { gridPaginationSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import { FCNC } from '../FCNC';
import { Pagination } from '../Pagination';
import { Select, SelectItem } from '../Select';
import { useDataGridStyles } from '../DataGridServer';
import { TextField } from '../TextField';
import { KeyboardEvent, FocusEvent, useState } from 'react';

export interface IDataGridPaginationProps {
    /**
     * Number of rows shown on a grid page
     */
    pageSize: number;

    /**
     * list of numbers available to update page size to
     *
     * Note: Cannot exceed 100 rows on Free Mui Data Grid
     */
    rowsPerPageOptions: number[];

    jumpToPage?: boolean;
}

/**
 * Pagination for DataGrid
 *
 * This component will not work without being attached to either DataGrid or DataGridServer
 *
 * @param pageSize
 * @param rowsPerPageOptions
 * @param jumpToPage
 * @constructor
 */
export const DataGridPagination: FCNC<IDataGridPaginationProps> = ({ pageSize, rowsPerPageOptions, jumpToPage }) => {
    const classes = useDataGridStyles();
    const apiRef = useGridApiContext();
    const paginationState = useGridSelector(apiRef, gridPaginationSelector);
    const [page, setPage] = useState(String(paginationState.page + 1));
    const [error, setError] = useState<boolean>(false);
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

    const handlePageSizeChange = (event: SelectChangeEvent<string | number | (string | number)[]>) => {
        apiRef.current.setPage(0);
        apiRef.current.setPageSize(Number(event.target.value));
    };

    const handleJumpToPageFocus = (event: FocusEvent<HTMLInputElement>) => {
        event.target.setSelectionRange(0, event.target.value.length);
    };

    const submitJump = (target: HTMLInputElement) => {
        const newPage = Number(target.value);
        if (!Number.isNaN(newPage) && newPage >= 1 && newPage <= paginationState.pageCount) {
            setPage(String(newPage));
            apiRef.current.setPage(newPage - 1);
            setError(false);
        } else {
            setPage(String(paginationState.page + 1));
            setError(true);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            submitJump(event.target as HTMLInputElement);
        }
    };

    return (
        <Stack
            direction={isMdDown ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems="center"
            sx={{ px: 2, width: '100%' }}
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: 150 }}>
                <Box sx={{ width: 64 }}>
                    <Select
                        value={pageSize.toString()}
                        onChange={handlePageSizeChange}
                        testId="data-grid-pagination-page-size-selector"
                    >
                        {rowsPerPageOptions.map((value) => (
                            <SelectItem value={value} key={value} data-testid={`page-size-item-${value}`}>
                                {value}
                            </SelectItem>
                        ))}
                    </Select>
                </Box>
                <Typography
                    variant="caption"
                    component="span"
                    sx={{ ml: 1 }}
                    data-testid="data-grid-pagination-row-count"
                >
                    {paginationState.rowCount} row{paginationState.rowCount === 1 ? '' : 's'}
                </Typography>
            </Stack>
            <Pagination
                count={paginationState.pageCount}
                page={paginationState.page}
                setPage={apiRef.current.setPage}
                size={isMdDown ? 'small' : 'large'}
                classes={{ root: classes.paginationRoot }}
            />
            {jumpToPage && paginationState.pageCount > 5 ? (
                <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ width: 175 }}>
                    <Typography variant="caption" component="span" sx={{ mr: 1 }}>
                        Jump to page
                    </Typography>
                    <Box sx={{ width: 64 }}>
                        <TextField
                            value={page.toString()}
                            onFocus={handleJumpToPageFocus}
                            onChange={({ target }) => setPage(target.value)}
                            error={error}
                            onBlur={({ target }) => submitJump(target as HTMLInputElement)}
                            onKeyDown={handleKeyDown}
                        />
                    </Box>
                </Stack>
            ) : (
                <Box sx={{ width: 150 }} />
            )}
        </Stack>
    );
};
