import { z } from 'zod';

export interface SubmissionMethodViewModel {
    Id: number;
    CompanyId: number;
    Name: string;
    Description: string;
    SalesRepRegistrationSummaryMessage: string;
    SelfRegistrationSummaryMessage: string;
    SubmissionMethod: SubmissionMethod;
    Logo: string | null;
    /**
     * This a stringified JSON payload.
     *
     * The parsed object should be validated with `SubmissionGuideLayoutSchema.parse()`
     * after doing a GET and then again before doing a PUT or POST.
     */
    SubmissionGuideLayout?: string | null;
    SubmissionMethod_US?: string | null;
}

export enum SubmissionMethod {
    WebEntry = 1,
    Email = 2,
    VirtualPrinter = 3,
    EDI_XML = 4,
    NonTranscepta = 5,
}

export const SubmissionMethodName = {
    [SubmissionMethod.WebEntry]: 'Via Web Portal',
    [SubmissionMethod.Email]: 'Via Email',
    [SubmissionMethod.VirtualPrinter]: 'Via Virtual Printer',
    [SubmissionMethod.EDI_XML]: 'Via EDI Process',
    [SubmissionMethod.NonTranscepta]: 'Non Transcepta',
};

export enum SubmissionGuideLogo {
    PDF,
    CSV,
    TXT,
    PRINTER,
    INVOICE,
    SEND,
    RECEIVE,
    COMPLETE,
    DATA_CONNECTION,
}

const SubmissionGuideInstructionSchema = z.object({
    logos: z.array(z.nativeEnum(SubmissionGuideLogo)),
    subtitle: z.string().trim().min(1, 'Subtitle cannot be blank').nullable(),
    text: z.string().trim().min(1, 'Instruction text cannot be blank').nullable(),
    urls: z.array(
        z.union([
            z.object({
                url: z.string({ required_error: 'Button URL is required' }).trim().min(1, 'Button URL cannot be blank'),
                buttonTitle: z
                    .string({ required_error: 'Button title is required' })
                    .trim()
                    .min(1, 'Button title cannot be blank'),
            }),
            z.object({
                url: z
                    .string({ required_error: 'Email address is required' })
                    .trim()
                    .min(1, 'Email address cannot be blank')
                    .email(),
                buttonTitle: z.null(),
            }),
        ])
    ),
});
export type SubmissionGuideInstructionViewModel = z.infer<typeof SubmissionGuideInstructionSchema>;

export const SubmissionGuideLayoutSchema = z.object({
    steps: z
        .array(
            z.object({
                id: z.number().int().min(0),
                title: z
                    .string({ required_error: 'Step title is required' })
                    .trim()
                    .min(1, 'Step title cannot be blank'),
                instructions: z.array(SubmissionGuideInstructionSchema).min(1).max(2),
            })
        )
        .min(1),
});
export type SubmissionGuideLayoutViewModel = z.infer<typeof SubmissionGuideLayoutSchema>;
