import { BuyerCustomJSRuleMappingXrefViewModel } from './BuyerCustomJSRuleMappingXrefViewModel';
import { EngineVersion } from './BuyerCustomJSRuleMappingViewModel';

export interface BuyerCustomJSRuleViewModel {
    Id: number | null;
    Name: string;
    Description: string;
    BuyerCompanyId: number | null;
    BuyerCompanyName: string | null;
    JavaScriptFileName: string;
    JavaScriptFilePath: string;
    OriginalFileName?: string;
    BuyerCustomJSRuleMappingXrefs: BuyerCustomJSRuleMappingXrefViewModel[];
    RowVersion: string;
    LastUpdatedDate: string;
    EngineVersion: EngineVersion | null;
    Label: string;
    InputParameterSettings: InputParameterSettings | null;
    AllowedExecutionContexts: string;
    Dependencies: BuyerCustomJSRuleViewModel[] | null;
    RuleType: RuleType;
}

export interface InputParameterSettings {
    Parameters: {
        Parameter?: InputParameterSetting[] | InputParameterSetting;
    };
}

export enum RuleType {
    Rule = 1,
    ReusableRule = 2,
    Library = 3,
}

export interface InputParameterSetting {
    Name: string;
    Label: string;
    Description: string;
    Type: InputParameterSettingType;
    ConfigurableByCompany: string;
    DefaultValue?: string | string[];
    ErrorCode: string;
}

export type PrimitiveInputParameterSettingType = 'string' | 'number' | 'boolean';

export type ArrayInputParameterSettingType = 'Array<string>' | 'Array<number>';

export type InputParameterSettingType = ArrayInputParameterSettingType | PrimitiveInputParameterSettingType;
