import { Chip, Stack } from '@mui/material';
import {
    Autocomplete,
    Button,
    Checkbox,
    CurrencyField,
    DateRangePickerField,
    DateRangePickerShortcut,
    Dropzone,
    ITooltipIconProps,
    MultiSortedSelect,
    NumericTextField,
    RadioButton,
    RadioButtonGroup,
    RangeNumericTextField,
    SortedSelect,
    TaxField,
    TextField,
    useFieldVisibility,
} from '../../ui';
import { sleep, UnexpectedCase } from '../../utils';
import { IFieldRendererProps } from './IFieldRendererProps';
import { isFieldVisible } from './isFieldVisible';
import { IFieldRendererViewModel } from './types';

/**
 * Renders the correct field control (component) provided a field object.
 *
 * The field's state will be bound to the provided view model.
 */
export function FieldRenderer<T extends IFieldRendererViewModel>({
    field,
    viewModel,
    onChange,
}: IFieldRendererProps<T>) {
    const fieldVisibility = useFieldVisibility(field.visibility);

    if (!isFieldVisible(field, fieldVisibility, viewModel)) {
        return null;
    }

    const handleClearValue = () => {
        onChange((vm) => vm.setValue(field, undefined), field.dataBindingKey);
    };

    const tooltip: ITooltipIconProps | undefined = (() => {
        if (viewModel.isUsingComputedValue(field)) {
            return {
                icon: 'Info',
                message:
                    'The displayed value was automatically computed. You can override this value by entering a different value here.',
            };
        }

        if (viewModel.isOverridingComputedValue(field)) {
            return {
                icon: 'Info',
                message: (
                    <>
                        You have entered a value that is overriding the automatically computed value.{' '}
                        <Button onClick={handleClearValue}>Use Computed Value</Button>
                    </>
                ),
            };
        }

        return field.tooltip;
    })();

    switch (field.type) {
        case 'ICheckboxField':
            return (
                <Checkbox
                    id={`dynamic-field-${field.id}`}
                    testId={`dynamic-field-${field.id}`}
                    label={field.label}
                    checked={viewModel.getValue(field)}
                    onChange={(ev) => onChange((vm) => vm.setValue(field, ev.target.checked), field.dataBindingKey)}
                    readonly={field.readOnly}
                    tooltip={tooltip}
                    height="initial"
                />
            );
        case 'ITextField':
            return (
                <TextField
                    id={`dynamic-field-${field.id}`}
                    testId={`dynamic-field-${field.id}`}
                    label={field.label}
                    value={viewModel.getValue(field)}
                    onChange={(ev) => onChange((vm) => vm.setValue(field, ev.target.value), field.dataBindingKey)}
                    readonly={field.readOnly}
                    required={field.required}
                    tooltip={tooltip}
                />
            );
        case 'IDropdownField': {
            if (field.multiple) {
                const optionValues = Array.isArray(viewModel.getValue(field)) ? (viewModel.getValue(field) as []) : [];
                const selectedOptions = field.options.filter((x: any) => optionValues.some((v) => v === x.value));
                return (
                    <MultiSortedSelect
                        id={`dynamic-field-${field.id}`}
                        testId={`dynamic-field-${field.id}`}
                        label={field.label}
                        value={selectedOptions}
                        options={field.options}
                        nullOptionLabel={field.nullOptionLabel}
                        prompt={field.prompt}
                        onChange={(newValues) =>
                            onChange(
                                (vm) =>
                                    vm.setValue(
                                        field,
                                        (newValues || [])?.map((newValue) => newValue?.value)
                                    ),
                                field.dataBindingKey
                            )
                        }
                        readonly={field.readOnly}
                        required={field.required}
                        multiple={field.multiple}
                        tooltip={tooltip}
                        renderValue={(values) => (
                            <Stack direction="row" gap={1} flexWrap="wrap" px={0.5}>
                                {(values as string[])?.map((v) => (
                                    <Chip
                                        onDelete={() =>
                                            onChange((vm) => {
                                                const newSelectedOptions = selectedOptions
                                                    .filter((opt) => opt.label !== v)
                                                    .map((opt) => opt.value);
                                                return vm.setValue(field, newSelectedOptions);
                                            }, field.dataBindingKey)
                                        }
                                        onMouseDown={(e) => {
                                            e.stopPropagation();
                                        }}
                                        label={v}
                                        key={v}
                                        sx={{
                                            fontSize: (theme) => theme.typography.body2,
                                        }}
                                    />
                                ))}
                            </Stack>
                        )}
                    />
                );
            }
            const optionValue = viewModel.getValue(field);
            const selectedOption = field.options.find((x: any) => x.value === optionValue) ?? null;
            return (
                <SortedSelect
                    id={`dynamic-field-${field.id}`}
                    testId={`dynamic-field-${field.id}`}
                    label={field.label}
                    value={selectedOption}
                    options={field.options}
                    nullOptionLabel={field.nullOptionLabel}
                    prompt={field.prompt}
                    onChange={(newValue) =>
                        onChange((vm) => vm.setValue(field, newValue?.value ?? null), field.dataBindingKey)
                    }
                    readonly={field.readOnly}
                    required={field.required}
                    multiple={field.multiple}
                    tooltip={tooltip}
                />
            );
        }
        case 'IEnumDropdownField': {
            return (
                <SortedSelect
                    id={`dynamic-field-${field.id}`}
                    testId={`dynamic-field-${field.id}`}
                    label={field.label}
                    value={viewModel.getValue(field)}
                    options={field.options}
                    nullOptionLabel={field.nullOptionLabel}
                    prompt={field.prompt}
                    onChange={(newValue) =>
                        onChange((vm) => vm.setValue(field, newValue ?? null), field.dataBindingKey)
                    }
                    readonly={field.readOnly}
                    required={field.required}
                    tooltip={tooltip}
                />
            );
        }
        case 'IAutocompleteField': {
            const optionValue = viewModel.getValue(field);
            const selectedOption = field.options.find((x) => x.value === optionValue) ?? null;
            return (
                <Autocomplete
                    id={`dynamic-field-${field.id}`}
                    testId={`dynamic-field-${field.id}`}
                    label={field.label}
                    value={selectedOption}
                    options={field.options}
                    onChange={(newValue) =>
                        onChange((vm) => vm.setValue(field, newValue?.value ?? null), field.dataBindingKey)
                    }
                    readonly={field.readOnly}
                    required={field.required}
                    tooltip={tooltip}
                />
            );
        }
        case 'IRenderPropField': {
            const value = viewModel.getValue(field);
            return (
                <>
                    {field.render(value, (newValue) =>
                        onChange((vm) => vm.setValue(field, newValue), field.dataBindingKey)
                    )}
                </>
            );
        }
        case 'IRadioButtonGroup': {
            const optionValue = viewModel.getValue(field);
            const selectedOption = field.options.find((x) => x.value === optionValue) ?? null;
            return (
                <>
                    <RadioButtonGroup
                        id={`dynamic-field-${field.id}`}
                        testId={`dynamic-field-${field.id}`}
                        label={field.label}
                        onChange={(ev) =>
                            onChange(
                                (vm) =>
                                    vm.setValue(
                                        field,
                                        field.options.find((o) => o.label === ev.target.value)?.value ?? null
                                    ),
                                field.dataBindingKey
                            )
                        }
                        value={selectedOption?.label ?? ''}
                        tooltip={tooltip}
                        required={field.required}
                        readonly={field.readOnly}
                    >
                        {field.options.map((x) => (
                            <RadioButton key={x.label} label={x.label} value={x.label} />
                        ))}
                    </RadioButtonGroup>
                </>
            );
        }
        case 'ICurrencyField':
            return (
                <CurrencyField
                    label={field.label}
                    value={viewModel.getValue(field)}
                    locale={field.locale}
                    maxValue={field.maxValue}
                    currencyCode={field.currencyCode}
                    onChange={(newValue) => onChange((vm) => vm.setValue(field, newValue), field.dataBindingKey)}
                    id={`dynamic-field-${field.id}`}
                    testId={`dynamic-field-${field.id}`}
                    readonly={field.readOnly}
                    required={field.required}
                    tooltip={tooltip}
                />
            );
        case 'ITaxField':
            const taxableAmount =
                field.taxableAmountDataBindingKeys.reduce(
                    (acc: number | null | undefined, x) => acc ?? viewModel.getValueWithKey<number | null>(x),
                    undefined
                ) ?? 0;

            return (
                <TaxField
                    label={field.label}
                    taxableAmount={taxableAmount}
                    value={viewModel.getValue(field)}
                    locale={field.locale}
                    maxPercentageValue={field.maxPercentageValue}
                    maxAmountValue={field.maxAmountValue}
                    currencyCode={field.currencyCode}
                    onChange={(newValue) => onChange((vm) => vm.setValue(field, newValue), field.dataBindingKey)}
                    id={`dynamic-field-${field.id}`}
                    testId={`dynamic-field-${field.id}`}
                    readonly={field.readOnly}
                    required={field.required}
                    tooltip={tooltip}
                />
            );
        case 'INumericTextField':
            return (
                <NumericTextField
                    label={field.label}
                    value={viewModel.getValue(field)}
                    locale={field.locale}
                    maxValue={field.maxValue}
                    scale={field.scale}
                    padFractionalZeros={field.padFractionalZeros}
                    onChange={(newValue) => onChange((vm) => vm.setValue(field, newValue), field.dataBindingKey)}
                    id={`dynamic-field-${field.id}`}
                    testId={`dynamic-field-${field.id}`}
                    readonly={field.readOnly}
                    required={field.required}
                    tooltip={tooltip}
                    omitCommas={field.omitCommas ?? false}
                />
            );
        case 'IDropzoneField':
            return (
                <Dropzone
                    files={viewModel.getValue(field)}
                    setFiles={(newValue) => {
                        onChange((vm) => vm.setValue(field, newValue), field.dataBindingKey);
                    }}
                    uploadFiles={async (newValues) => {
                        await sleep(50);
                        return newValues?.map((x) => ({ fileName: x.name, file: x }));
                    }}
                    maxFiles={field.maxFiles}
                />
            );
        case 'IDateRangePickerField':
            return (
                <DateRangePickerField
                    label={field.label}
                    placeholders={field.placeholders}
                    id={`dynamic-field-${field.id}`}
                    testId={`dynamic-field-${field.id}`}
                    required={field.required}
                    readonly={field.readOnly}
                    value={viewModel.getValue(field)}
                    onChange={(newValue) => {
                        if (newValue[0] !== null || newValue[1] !== null) {
                            onChange((vm) => vm.setValue(field, newValue), field.dataBindingKey);
                        } else {
                            onChange((vm) => vm.setValue(field, undefined), field.dataBindingKey);
                        }
                    }}
                    shortcuts={[
                        DateRangePickerShortcut.TODAY,
                        DateRangePickerShortcut.YESTERDAY,
                        DateRangePickerShortcut.PAST_WEEK,
                        DateRangePickerShortcut.PAST_MONTH,
                        DateRangePickerShortcut.PAST_3_MONTHS,
                        DateRangePickerShortcut.PAST_6_MONTHS,
                    ]}
                />
            );
        case 'IRangeNumericTextField':
            return (
                <RangeNumericTextField
                    label={field.label}
                    value={viewModel.getValue(field)}
                    locale={field.locale}
                    maxValue={field.maxValue}
                    scale={field.scale}
                    padFractionalZeros={field.padFractionalZeros}
                    onChange={(newValue) => {
                        if (typeof newValue[0] === 'number' || typeof newValue[1] === 'number') {
                            onChange((vm) => vm.setValue(field, newValue), field.dataBindingKey);
                        } else {
                            onChange((vm) => vm.setValue(field, undefined), field.dataBindingKey);
                        }
                    }}
                    id={`dynamic-field-${field.id}`}
                    testId={`dynamic-field-${field.id}`}
                    readonly={field.readOnly}
                    required={field.required}
                    tooltip={tooltip}
                />
            );
        default:
            throw new UnexpectedCase(field);
    }
}
