export type ILayouts<K extends string, R> = {
    [k in K]: R;
};

export interface IHasScreenSize<K extends string> {
    ScreenSize_US: K;
}

/**
 * Convert an array of layouts to a dictionary of layouts.
 * @param layouts the array of layouts
 * @param transformLayout function to transform layout objects
 * @returns the dictionary of transformed layouts
 */
function convertLayouts<T extends IHasScreenSize<string>, R>(
    layouts: T[],
    transformLayout: (layout: T) => R
): ILayouts<T extends IHasScreenSize<infer K> ? K : string, R> {
    return layouts.reduce(
        (acc, layout) => ({
            ...acc,
            [layout.ScreenSize_US]: transformLayout(layout),
        }),
        {} as any
    );
}

export interface IDocumentLayout {
    ScreenSize_US: 'Small' | 'Medium' | 'Large';
    Cells: {
        Section_US: string;
        [key: string]: any;
    }[];
}

/**
 * Convert API document layout representation to internal document layout representation
 * @param layouts the API layouts
 * @returns the internal layouts
 */
export function convertDocumentLayouts(layouts: IDocumentLayout[]) {
    const o = convertLayouts(layouts, (layout) =>
        layout.Cells.reduce(
            (acc, cell) => ({
                ...acc,
                [cell.Section_US]: [...(acc[cell.Section_US] ?? []), cell],
            }),
            {} as { [key: string]: IDocumentLayout['Cells'] }
        )
    );

    return o;
}

export interface ISearchLayout {
    ScreenSize_US: 'Small' | 'Medium' | 'Large';
    SearchConfiguration: any;
}

/**
 * Convert API search configuration layout representation to internal search configuration layout representation
 * @param layouts the API layouts
 * @returns the internal layouts
 */
export function convertSearchLayouts(layouts: ISearchLayout[] | ILayouts<'Small' | 'Medium' | 'Large', any>) {
    return Array.isArray(layouts) ? convertLayouts(layouts, (layout) => layout.SearchConfiguration) : layouts;
}
