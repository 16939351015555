import { RoleType } from '../../../../legacy/transcepta-types';
import { api } from '../../../../services/api';
import { IDropdownOptionsRepository, ITransceptaDocumentEditValidationServices } from '../../proservContractTypes';
import { createCachingService } from './cachingService';
import associatedFieldService from './associatedFieldService';
import threadingService from './threadingService';

/**
 * creates services that can be provided without reference to the current application context. (Like libraries
 * or static data).
 * @param ruleName the name of the rule to be used to compose the unique key for the caching service
 */
export function createServices(
    ruleName: string,
    dropdownOptionsRepository: IDropdownOptionsRepository
): ITransceptaDocumentEditValidationServices {
    return {
        userRoles: RoleType,
        caching: createCachingService(ruleName),
        associatedField: associatedFieldService,
        api,
        threading: threadingService,
        dropdownOptionsRepository,
    };
}
