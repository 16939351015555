import { makeStyles } from '@mui/styles';
import { ICustomTheme } from '../themes';

export const useDataGridStyles = makeStyles((theme: ICustomTheme) => ({
    root: {
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey['900'] : theme.palette.grey['100'],
        border: 0,
        '& .MuiDataGrid-columnHeaders': {
            border: 0,
        },
        '& .MuiDataGrid-columnHeader--moving': {
            backgroundColor: 'rgba(0, 0, 0, 0)',
        },
        '& .MuiDataGrid-iconSeparator': {
            display: 'none',
        },
        '& .MuiDataGrid-menuIcon': {
            display: 'none !important',
        },
        '& .MuiDataGrid-overlay': {
            position: 'absolute',
            height: 'unset!important',
            zIndex: '1',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
        },
        '& .MuiIconButton-label': {
            color: theme.palette.primary.contrastText,
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
            padding: 0,
        },
        '& .MuiDataGrid-selectedRowCount': {
            display: 'none !important',
        },
        '& .MuiSvgIcon-root': {
            height: 20,
            width: 20,
        },
        '&. MuiDataGrid-footerContainer': {
            justifyContent: 'center',
        },
        '& .grid': {
            marginTop: 2,
            marginBottom: 2,
            borderRadius: 4,
            backgroundColor: theme.palette.background.paper,
        },
        '& .grid:nth-child(even)':
            theme.palette.mode === 'light'
                ? {
                      backgroundColor: '#F4F5FB',
                      '&:hover': {
                          backgroundColor: theme.palette.grey['300'],
                      },
                      '& .MuiSvgIcon-root': {
                          color: theme.palette.text.secondary,
                      },
                  }
                : undefined,
        '& .grid:nth-child(odd)':
            theme.palette.mode === 'light'
                ? {
                      backgroundColor: theme.palette.background.paper,
                      '&:hover': {
                          backgroundColor: theme.palette.grey['300'],
                      },
                      '& .MuiSvgIcon-root': {
                          color: theme.palette.text.secondary,
                      },
                  }
                : undefined,
    },
    paginationRoot: {
        '& .MuiSvgIcon-root': {
            color: theme.palette.text.primary,
        },
    },
}));
