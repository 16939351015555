import { normalizeDateTimeString, toISO8601String, convertToPTDateTimeString } from './tzNormalization';

/**
 * Returns a date object from an input "date value".
 *
 * Uses our `tzNormalization` util to handle date strings
 * with no timezone information.
 *
 * The value that is returned is suitable to pass to a non-timezone
 * aware component. This utility function should be used to create
 * a wrapper component around a non-timezone aware component.
 *
 * @param date the date object or string
 * @returns a date object
 */
export function convertIncomingDate(date: string | Date) {
    // if string, handle potential for it to lack TZ data. in this case,
    // we need to convert to use "PT" if there is no timezone, because this
    // string source is probably from the API.
    if (typeof date === 'string') {
        date = new Date(normalizeDateTimeString(date));
    }

    return date;
}

/**
 * Converts a date coming from user input (wrapped non-timezone aware component) so it can be
 * passed back to the API.
 *
 * If the API does not support storing TZ information, pass `"PT"` to the second
 * parameter `mode`.
 *
 * @param date the date object or string
 * @returns a date string.
 */
export function convertOutgoingDate(date: string | Date, mode: 'PT' | 'UTC' = 'UTC') {
    // if string, handle potential to lack TZ data. in this case,
    // we need to use the user's TZ, so use Date constructor. we know this
    // string would be coming from user input, that is why we assume it is in the user's
    // timezone.
    if (typeof date === 'string') {
        date = new Date(date);
    }

    // convert the date object to ISO UTC string
    const isoStr = toISO8601String(date);

    // return PT string with no TZ information if requested
    if (mode === 'PT') {
        return convertToPTDateTimeString(isoStr);
    }

    // return ISO UTC string.
    return isoStr;
}
