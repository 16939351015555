export const HELP_RESOURCE_CATEGORIES_FETCHING = 'HELP_RESOURCE_CATEGORIES_FETCHING';
export const HELP_RESOURCE_CATEGORIES_FETCHED = 'HELP_RESOURCE_CATEGORIES_FETCHED';
export const HELP_RESOURCE_ARTICLES_FETCHING = 'HELP_RESOURCE_ARTICLES_FETCHING';
export const HELP_RESOURCE_ARTICLES_FETCHED = 'HELP_RESOURCE_ARTICLES_FETCHED';
export const HELP_RESOURCE_ARTICLE_FETCHING = 'HELP_RESOURCE_ARTICLE_FETCHING';
export const HELP_RESOURCE_ARTICLE_FETCHED = 'HELP_RESOURCE_ARTICLE_FETCHED';
export const HELP_RESOURCE_CATEGORIES_LOAD = 'HELP_RESOURCE_CATEGORIES_LOAD';
export const HELP_RESOURCE_SEARCH_FETCHING = 'HELP_RESOURCE_SEARCH_FETCHING';
export const HELP_RESOURCE_SEARCH_FETCHED = 'HELP_RESOURCE_SEARCH_FETCHED';

export const resourceCategoriesFetching = () => {
    return (dispatch) => {
        dispatch({
            type: HELP_RESOURCE_CATEGORIES_FETCHING,
        });
    };
};

export const resourceCategoriesFetched = (payload) => {
    return (dispatch) => {
        dispatch({
            type: HELP_RESOURCE_CATEGORIES_FETCHED,
            payload,
        });
    };
};

export const articlesFetching = () => {
    return (dispatch) => {
        dispatch({
            type: HELP_RESOURCE_ARTICLES_FETCHING,
        });
    };
};

export const articlesFetched = (payload) => {
    return (dispatch) => {
        dispatch({
            type: HELP_RESOURCE_ARTICLES_FETCHED,
            payload,
        });
    };
};

export const articleFetching = () => {
    return (dispatch) => {
        dispatch({
            type: HELP_RESOURCE_ARTICLE_FETCHING,
        });
    };
};

export const articleFetched = (payload) => {
    return (dispatch) => {
        dispatch({
            type: HELP_RESOURCE_ARTICLE_FETCHED,
            payload,
        });
    };
};

export const categoriesLoad = (payload) => {
    return (dispatch) => {
        dispatch({
            type: HELP_RESOURCE_CATEGORIES_LOAD,
            payload,
        });
    };
};

export const searchFetching = () => {
    return (dispatch) => {
        dispatch({
            type: HELP_RESOURCE_SEARCH_FETCHING,
        });
    };
};

export const searchFetched = (payload) => {
    return (dispatch) => {
        dispatch({
            type: HELP_RESOURCE_SEARCH_FETCHED,
            payload,
        });
    };
};
