export const WorkflowTemplateUI = [
    'Document Approval',
    'Invoice Approval',
    'Invoice Coding',
    'Invoice Reconcile',
    'Non-Inventory',
    'Non-Inventory Rel.',
    'Parked Documents',
    'Counter POs',
] as const;

export type WorkflowTemplateUIType = typeof WorkflowTemplateUI[number];
