import { ReactNode } from 'react';
import { Grid } from '../Grid';
import { GridSize, Backdrop } from '@mui/material';
import { FCNC } from '../FCNC';
import { Loading } from '../Loading';

export interface IFormItemsProps {
    /**
     * Form Input components as a list of components, each list item is a column
     */
    columns: ReactNode[];

    /**
     * Boolean as to whether or not to show backdrop
     */
    showBackdrop: boolean;
}

/**
 * Internal component for Form, puts items in list into columns
 *
 * @param columns
 * @param showBackdrop
 * @constructor
 */
export const FormItems: FCNC<IFormItemsProps> = ({ columns, showBackdrop }) => {
    const xs = (12 / columns.length) as GridSize;

    return (
        <Grid sx={{ p: 3, position: 'relative' }} container columns={{ xs: 4, md: 12 }}>
            <Backdrop open={showBackdrop} sx={{ position: 'absolute', zIndex: 1 }}>
                <Loading />
            </Backdrop>
            {columns.map((item, index) => (
                <Grid
                    item
                    xs={xs}
                    md={xs}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    sx={{
                        '& > *': {
                            padding: '8px !important',
                        },
                    }}
                >
                    {item}
                </Grid>
            ))}
        </Grid>
    );
};
