import { api } from '../../api';
import { IContactInfo } from './ViewModels';

export class ContactApi {
    route = '/Contact';

    public updateContact = async (contactViewModel: IContactInfo): Promise<void> => {
        return api().post(`${this.route}`, contactViewModel);
    };
}
