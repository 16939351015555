import mergeWith from 'lodash/mergeWith';
import { arrayUtils } from 'common';

import { handleActions } from 'redux-actions';

import * as CreateInvoiceActions from '../../actions/CreateInvoiceActions';
import * as CreateDocumentActions from '../../actions/createDocument';
import * as DocumentCreationActions from '../../actions/document/documentCreationActions';
import * as UserLoginActions from '../../actions/userlogin';
import * as InvoiceModels from '../../models/InvoiceModels';
import projectConfig from '../../project.config.json';
import { cloneObjectHack } from '../../utils/dataConverter';
import { isAfter, isBefore, format, add } from 'date-fns';

const сreateInvoiceReducer = handleActions(
    {
        // clear invoice creation fields upon user logout
        [UserLoginActions.USER_LOGOUT]: (state, action) => {
            let initialState = InvoiceModels.initialState.state;
            const { timeout } = action.payload;
            return timeout && timeout == true ? { ...state, timeout } : initialState;
            //return { ...initialState };
        },

        // clear all invoice creation fields
        [CreateInvoiceActions.PERFORM_CLEAR_INVOICE_CREATION_FIELDS]: (state, action) => {
            let initialState = InvoiceModels.initialState.state;
            return { ...initialState };
        },

        [DocumentCreationActions.FETCH_DOCUMENT_FOR_EDIT_EXECUTE]: (state, action) => {
            let initialState = InvoiceModels.initialState.state;
            return { ...initialState };
        },

        [DocumentCreationActions.FETCH_DOCUMENT_FOR_EDIT_SUCCESS]: (state, action) => {
            let initialState = InvoiceModels.initialState.state;
            return { ...initialState };
        },

        // Load draft or document for edit
        [DocumentCreationActions.FETCH_INVOICE_DOCUMENT_FOR_EDIT_SUCCESS]: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },

        // Reducer change invoice line items fields
        [CreateInvoiceActions.PERFORM_SET_DEFAULT_DATA_INVOICE_FIELD]: (state, action) => {
            return {
                ...state,
                isCopy: action.payload.defaultFields.isCopy,
                CommonFields: {
                    ...state.CommonFields,
                    ...action.payload.defaultFields.CommonFields,
                },
                BusinessDocFields: {
                    ...state.BusinessDocFields,
                    Invoice: {
                        ...state.BusinessDocFields.Invoice,
                        ...action.payload.defaultFields.BusinessDocFields.Invoice,
                    },
                },
            };
        },

        [CreateInvoiceActions.PERFORM_INVOICE_FIELD_VALUE_UPDATE]: (state, action) => {
            switch (action.payload.fieldLayout.EntityName) {
                case projectConfig.EntityName.Main:
                    let newInvoiceState = cloneObjectHack(state.BusinessDocFields.Invoice);
                    newInvoiceState[action.payload.fieldName] = action.payload.newValue;

                    return {
                        ...state,
                        BusinessDocFields: {
                            ...state.BusinessDocFields,
                            Invoice: newInvoiceState,
                        },
                    };

                case projectConfig.EntityName.Contact:
                    let newInvoiceContactsState = cloneObjectHack(state.BusinessDocFields.Invoice.InvoiceContacts);
                    let invoiceContactNotFound = true;
                    state.BusinessDocFields.Invoice.InvoiceContacts.forEach((invoiceContact, contactIndex) => {
                        if (invoiceContact.ContactType === action.payload.fieldLayout.ContactType) {
                            invoiceContactNotFound = false;
                            newInvoiceContactsState[contactIndex][action.payload.fieldName] = action.payload.newValue;
                        }
                    });

                    if (invoiceContactNotFound === true) {
                        let newInvoiceContact = InvoiceModels.defaultInvoiceContactsState.state[0];
                        newInvoiceContact.ContactType = action.payload.fieldLayout.ContactType;
                        newInvoiceContact[action.payload.fieldName] = action.payload.newValue;

                        newInvoiceContactsState = [...newInvoiceContactsState, newInvoiceContact];
                    }

                    return {
                        ...state,
                        BusinessDocFields: {
                            ...state.BusinessDocFields,
                            Invoice: {
                                ...state.BusinessDocFields.Invoice,
                                InvoiceContacts: newInvoiceContactsState,
                            },
                        },
                    };

                case projectConfig.EntityName.LineItem:
                    let newInvoiceLineItemsState = cloneObjectHack(state.BusinessDocFields.Invoice.InvoiceLineItems);

                    let startingLineIndex = action.payload.index;
                    let endingLineIndex = action.payload.index + 1;
                    if (action.payload.updateAllInstances) {
                        startingLineIndex = 0;
                        endingLineIndex = newInvoiceLineItemsState.length;
                    }

                    for (let lineIndex = startingLineIndex; lineIndex < endingLineIndex; lineIndex++) {
                        newInvoiceLineItemsState[lineIndex][action.payload.fieldName] = action.payload.newValue;
                    }

                    return {
                        ...state,
                        BusinessDocFields: {
                            ...state.BusinessDocFields,
                            Invoice: {
                                ...state.BusinessDocFields.Invoice,
                                InvoiceLineItems: newInvoiceLineItemsState,
                            },
                        },
                    };

                case projectConfig.EntityName.LineItemMiscAmount:
                    newInvoiceLineItemsState = cloneObjectHack(state.BusinessDocFields.Invoice.InvoiceLineItems);

                    startingLineIndex = action.payload.index;
                    endingLineIndex = action.payload.index + 1;
                    if (action.payload.updateAllInstances) {
                        startingLineIndex = 0;
                        endingLineIndex = newInvoiceLineItemsState.length;
                    }

                    // If we do not pass a field name and the update all instances flag is set to true we are removing the display of the misc amount field.
                    // Therefore, we need to remove the misc amount records in order for them to stop displaying.
                    if (action.payload.fieldName === null && action.payload.updateAllInstances) {
                        for (let lineIndex = startingLineIndex; lineIndex < endingLineIndex; lineIndex++) {
                            newInvoiceLineItemsState[lineIndex].InvoiceLineItemMiscAmounts = newInvoiceLineItemsState[
                                lineIndex
                            ].InvoiceLineItemMiscAmounts.filter((miscAmount) => {
                                return (
                                    miscAmount.AdjustmentReasonId !== action.payload.fieldLayout.AdjustmentReasonId ||
                                    miscAmount.AdjustmentMethodId !== action.payload.fieldLayout.AdjustmentMethodId ||
                                    miscAmount.AmountIndicator !== action.payload.fieldLayout.AmountIndicator ||
                                    miscAmount.Description !== action.payload.fieldLayout.Label
                                );
                            });
                        }
                    } else {
                        for (let lineIndex = startingLineIndex; lineIndex < endingLineIndex; lineIndex++) {
                            let lineItemMiscAmountDoesNotExist = true;

                            for (
                                let miscAmountIndex = 0;
                                miscAmountIndex <
                                    newInvoiceLineItemsState[lineIndex].InvoiceLineItemMiscAmounts.length &&
                                lineItemMiscAmountDoesNotExist;
                                miscAmountIndex++
                            ) {
                                let invoiceLineItemMiscAmount =
                                    newInvoiceLineItemsState[lineIndex].InvoiceLineItemMiscAmounts[miscAmountIndex];
                                if (
                                    invoiceLineItemMiscAmount.AdjustmentReasonId ===
                                        action.payload.fieldLayout.AdjustmentReasonId &&
                                    invoiceLineItemMiscAmount.AdjustmentMethodId ===
                                        action.payload.fieldLayout.AdjustmentMethodId &&
                                    invoiceLineItemMiscAmount.AmountIndicator ===
                                        action.payload.fieldLayout.AmountIndicator &&
                                    invoiceLineItemMiscAmount.Description === action.payload.fieldLayout.Label
                                ) {
                                    lineItemMiscAmountDoesNotExist = false;
                                    newInvoiceLineItemsState[lineIndex]['InvoiceLineItemMiscAmounts'][miscAmountIndex][
                                        action.payload.fieldName
                                    ] = action.payload.newValue;
                                }
                            }

                            if (lineItemMiscAmountDoesNotExist === true) {
                                let newLineItemMiscAmount = InvoiceModels.defaultInvoiceMiscAmountsState.state[0];
                                newLineItemMiscAmount.AdjustmentMethodId =
                                    action.payload.fieldLayout.AdjustmentMethodId;
                                newLineItemMiscAmount.AdjustmentReasonId =
                                    action.payload.fieldLayout.AdjustmentReasonId;
                                newLineItemMiscAmount.AmountIndicator = action.payload.fieldLayout.AmountIndicator;
                                newLineItemMiscAmount.Description = action.payload.fieldLayout.Label;
                                newLineItemMiscAmount[action.payload.fieldName] = action.payload.newValue;

                                newInvoiceLineItemsState[lineIndex]['InvoiceLineItemMiscAmounts'] = [
                                    ...newInvoiceLineItemsState[lineIndex].InvoiceLineItemMiscAmounts,
                                    newLineItemMiscAmount,
                                ];
                            }
                        }
                    }

                    return {
                        ...state,
                        BusinessDocFields: {
                            ...state.BusinessDocFields,
                            Invoice: {
                                ...state.BusinessDocFields.Invoice,
                                InvoiceLineItems: newInvoiceLineItemsState,
                            },
                        },
                    };

                case projectConfig.EntityName.LineItemShippingDetail:
                    newInvoiceLineItemsState = cloneObjectHack(state.BusinessDocFields.Invoice.InvoiceLineItems);

                    startingLineIndex = action.payload.index;
                    endingLineIndex = action.payload.index + 1;
                    if (action.payload.updateAllInstances) {
                        startingLineIndex = 0;
                        endingLineIndex = newInvoiceLineItemsState.length;
                    }

                    for (let lineIndex = startingLineIndex; lineIndex < endingLineIndex; lineIndex++) {
                        if (newInvoiceLineItemsState[lineIndex].InvoiceLineItemShippingDetails.length === 0) {
                            let newLineItemShippingDetail =
                                InvoiceModels.defaultInvoiceShippingDetailsInvoiceState.state[0];
                            newLineItemShippingDetail[action.payload.fieldName] = action.payload.newValue;

                            newInvoiceLineItemsState[lineIndex].InvoiceLineItemShippingDetails = [
                                ...newInvoiceLineItemsState[lineIndex].InvoiceLineItemShippingDetails,
                                newLineItemShippingDetail,
                            ];
                        } else {
                            newInvoiceLineItemsState[lineIndex]['InvoiceLineItemShippingDetails'][0][
                                action.payload.fieldName
                            ] = action.payload.newValue;
                        }
                    }

                    return {
                        ...state,
                        BusinessDocFields: {
                            ...state.BusinessDocFields,
                            Invoice: {
                                ...state.BusinessDocFields.Invoice,
                                InvoiceLineItems: newInvoiceLineItemsState,
                            },
                        },
                    };

                case projectConfig.EntityName.MiscAmount:
                    let newInvoiceMiscAmountsState = cloneObjectHack(
                        state.BusinessDocFields.Invoice.InvoiceMiscAmounts
                    );
                    if (action.payload.index !== null) {
                        newInvoiceMiscAmountsState[action.payload.index][action.payload.fieldName] =
                            action.payload.newValue;
                    } else {
                        let miscAmountDoesNotExist = true;

                        for (
                            let miscAmountIndex = 0;
                            miscAmountIndex < newInvoiceMiscAmountsState.length && miscAmountDoesNotExist;
                            miscAmountIndex++
                        ) {
                            let invoiceMiscAmount = newInvoiceMiscAmountsState[miscAmountIndex];
                            if (
                                invoiceMiscAmount.AdjustmentReasonId ===
                                    action.payload.fieldLayout.AdjustmentReasonId &&
                                invoiceMiscAmount.AdjustmentMethodId ===
                                    action.payload.fieldLayout.AdjustmentMethodId &&
                                invoiceMiscAmount.AmountIndicator === action.payload.fieldLayout.AmountIndicator &&
                                invoiceMiscAmount.Description === action.payload.fieldLayout.Label
                            ) {
                                miscAmountDoesNotExist = false;
                                newInvoiceMiscAmountsState[miscAmountIndex][action.payload.fieldName] =
                                    action.payload.newValue;
                            }
                        }

                        if (miscAmountDoesNotExist === true) {
                            let newMiscAmount = InvoiceModels.defaultInvoiceMiscAmountsState.state[0];
                            newMiscAmount.AdjustmentMethodId = action.payload.fieldLayout.AdjustmentMethodId;
                            newMiscAmount.AdjustmentReasonId = action.payload.fieldLayout.AdjustmentReasonId;
                            newMiscAmount.AmountIndicator = action.payload.fieldLayout.AmountIndicator;
                            newMiscAmount.Description = action.payload.fieldLayout.Label;
                            newMiscAmount[action.payload.fieldName] = action.payload.newValue;

                            newInvoiceMiscAmountsState = [...newInvoiceMiscAmountsState, newMiscAmount];
                        }
                    }

                    return {
                        ...state,
                        BusinessDocFields: {
                            ...state.BusinessDocFields,
                            Invoice: {
                                ...state.BusinessDocFields.Invoice,
                                InvoiceMiscAmounts: newInvoiceMiscAmountsState,
                            },
                        },
                    };

                case projectConfig.EntityName.ShippingDetail:
                    let newInvoiceShippingDetailsState = cloneObjectHack(
                        state.BusinessDocFields.Invoice.InvoiceShippingDetails
                    );
                    if (newInvoiceShippingDetailsState.length === 0) {
                        let newShippingDetail = InvoiceModels.defaultInvoiceShippingDetailsInvoiceState.state[0];
                        newShippingDetail[action.payload.fieldName] = action.payload.newValue;

                        newInvoiceShippingDetailsState = [...newInvoiceShippingDetailsState, newShippingDetail];
                    } else {
                        newInvoiceShippingDetailsState[0][action.payload.fieldName] = action.payload.newValue;
                    }

                    return {
                        ...state,
                        BusinessDocFields: {
                            ...state.BusinessDocFields,
                            Invoice: {
                                ...state.BusinessDocFields.Invoice,
                                InvoiceShippingDetails: newInvoiceShippingDetailsState,
                            },
                        },
                    };

                default:
                    return { ...state };
            }
        },

        [CreateInvoiceActions.PERFORM_APPLY_DOCUMENT_STATE_DIFF]: (state, action) => {
            const stateDiff = action.payload;
            const newState = mergeWith(state, stateDiff, arrayUtils.replacePrimitiveArrayCustomizer);

            return { ...newState };
        },

        // Reducer change invoice fields
        [CreateInvoiceActions.PERFORM_CHANGE_INVOICE_FIELD]: (state, action) => {
            const newInvoiceState = { ...state.BusinessDocFields.Invoice };
            //var foundField = false;
            for (let invoiceField in state.BusinessDocFields.Invoice) {
                if (invoiceField === action.payload.fieldName) {
                    newInvoiceState[invoiceField] = action.payload.value;
                }
            }

            return {
                ...state,
                BusinessDocFields: {
                    ...state.BusinessDocFields,
                    Invoice: {
                        ...state.BusinessDocFields.Invoice,
                        ...newInvoiceState,
                    },
                },
            };
        },

        // Reducer change invoice line items fields
        [CreateInvoiceActions.PERFORM_CHANGE_INVOICE_LINE_ITEMS_FIELD]: (state, action) => {
            let newStateInvoiceLineItems = state.BusinessDocFields.Invoice.InvoiceLineItems.slice();
            for (let valueArray in state.BusinessDocFields.Invoice.InvoiceLineItems[action.payload.arrayIndeхMap]) {
                if (valueArray === action.payload.fieldName) {
                    newStateInvoiceLineItems[action.payload.arrayIndeхMap] = {
                        ...state.BusinessDocFields.Invoice.InvoiceLineItems[action.payload.arrayIndeхMap],
                        [valueArray]: action.payload.value,
                    };
                }
            }
            return {
                ...state,
                BusinessDocFields: {
                    ...state.BusinessDocFields,
                    Invoice: {
                        ...state.BusinessDocFields.Invoice,
                        InvoiceLineItems: newStateInvoiceLineItems,
                    },
                },
            };
        },

        // Reducer add invoice misc amount
        [CreateInvoiceActions.PERFORM_ADD_INVOICE_MISC_AMOUNT]: (state, action) => {
            return {
                ...state,
                BusinessDocFields: {
                    ...state.BusinessDocFields,
                    Invoice: {
                        ...state.BusinessDocFields.Invoice,
                        InvoiceMiscAmounts: [
                            ...state.BusinessDocFields.Invoice.InvoiceMiscAmounts,
                            action.payload.miscAmount,
                        ],
                    },
                },
            };
        },

        // Reducer add invoice misc amount
        [CreateInvoiceActions.PERFORM_ADD_INVOICE_CONTACT]: (state, action) => {
            return {
                ...state,
                BusinessDocFields: {
                    ...state.BusinessDocFields,
                    Invoice: {
                        ...state.BusinessDocFields.Invoice,
                        InvoiceContacts: [...state.BusinessDocFields.Invoice.InvoiceContacts, action.payload.contact],
                    },
                },
            };
        },

        // Reducer update multiple fields on invoice misc amount at one time
        [CreateInvoiceActions.PERFORM_UPDATE_INVOICE_MISC_AMOUNT]: (state, action) => {
            return {
                ...state,
                BusinessDocFields: {
                    ...state.BusinessDocFields,
                    Invoice: {
                        ...state.BusinessDocFields.Invoice,
                        InvoiceMiscAmounts: state.BusinessDocFields.Invoice.InvoiceMiscAmounts.slice(
                            0,
                            action.payload.index
                        )
                            .concat([action.payload.miscAmount])
                            .concat(state.BusinessDocFields.Invoice.InvoiceMiscAmounts.slice(action.payload.index + 1)),
                    },
                },
            };
        },

        // Reducer delete invoice misc amount
        [CreateInvoiceActions.PERFORM_DELETE_INVOICE_MISC_AMOUNT]: (state, action) => {
            return {
                ...state,
                BusinessDocFields: {
                    ...state.BusinessDocFields,
                    Invoice: {
                        ...state.BusinessDocFields.Invoice,
                        InvoiceMiscAmounts: state.BusinessDocFields.Invoice.InvoiceMiscAmounts.slice(
                            0,
                            action.payload.index
                        ).concat(state.BusinessDocFields.Invoice.InvoiceMiscAmounts.slice(action.payload.index + 1)),
                    },
                },
            };
        },

        // Reducer change invoice misc amount fields
        [CreateInvoiceActions.PERFORM_CHANGE_INVOICE_MISC_AMOUNTS_FIELD]: (state, action) => {
            let newStateInvoiceMiscAmount = state.BusinessDocFields.Invoice.InvoiceMiscAmounts.slice();
            for (let InvoiceMiscAmountFieldName in state.BusinessDocFields.Invoice.InvoiceMiscAmounts[
                action.payload.arrayIndeхMap
            ]) {
                if (InvoiceMiscAmountFieldName === action.payload.fieldName) {
                    newStateInvoiceMiscAmount[action.payload.arrayIndeхMap] = {
                        ...state.BusinessDocFields.Invoice.InvoiceMiscAmounts[action.payload.arrayIndeхMap],
                        [InvoiceMiscAmountFieldName]: action.payload.value,
                    };
                }
            }
            return {
                ...state,
                BusinessDocFields: {
                    ...state.BusinessDocFields,
                    Invoice: {
                        ...state.BusinessDocFields.Invoice,
                        InvoiceMiscAmounts: newStateInvoiceMiscAmount,
                    },
                },
            };
        },

        // Reducer change invoice contact fields
        [CreateInvoiceActions.PERFORM_CHANGE_CONTACT_INVOICE_FIELD]: (state, action) => {
            let newStateInvoiceContacts = state.BusinessDocFields.Invoice.InvoiceContacts.slice();
            state.BusinessDocFields.Invoice.InvoiceContacts.forEach((invoiceContact, indexInvoiceContact) => {
                for (let invoiceContactField in invoiceContact) {
                    if (
                        invoiceContact[Object.keys(action.payload.objectFieldNameMap)[0]] ===
                            action.payload.objectFieldNameMap[Object.keys(action.payload.objectFieldNameMap)[0]] &&
                        invoiceContactField === action.payload.fieldName
                    ) {
                        newStateInvoiceContacts[indexInvoiceContact] = {
                            ...invoiceContact,
                            [invoiceContactField]: action.payload.value,
                        };
                    }
                }
            });

            return {
                ...state,
                BusinessDocFields: {
                    ...state.BusinessDocFields,
                    Invoice: {
                        ...state.BusinessDocFields.Invoice,
                        InvoiceContacts: newStateInvoiceContacts,
                    },
                },
            };
        },

        // Reducer update common fields
        [CreateDocumentActions.PERFORM_CHANGE_COMMON_DOCUMENT_FIELD]: (state, action) => {
            const newInvoiceState = { ...state };
            //var foundField = false;
            for (let invoiceField in state.CommonFields) {
                if (invoiceField === action.payload.fieldName) {
                    newInvoiceState.CommonFields[invoiceField] = action.payload.value;
                }
            }

            return {
                ...state,
                CommonFields: {
                    ...state.CommonFields,
                    ...newInvoiceState.CommonFields,
                },
            };
        },

        [CreateInvoiceActions.PERFORM_CHANGE_INVOICE]: (state, action) => {
            const newInvoiceState = state;

            const businessDocType = action.payload.value.BusinessDocType;
            let document = action.payload.value;

            let businessDocument = {};
            if (businessDocType === projectConfig.businessDocType.PurchaseOrder) {
                businessDocument = action.payload.value.BusinessDocFields.PurchaseOrder;
            } else if (businessDocType === projectConfig.businessDocType.Invoice) {
                businessDocument = action.payload.value.BusinessDocFields.Invoice;
            }

            if (businessDocType === projectConfig.businessDocType.PurchaseOrder) {
                newInvoiceState.BusinessDocFields.Invoice['InvoiceLineItems'] = []; //document["PurchaseOrderLineItems"];
                let invoiceLineItems = [];
                let currInvoiceLineItem = InvoiceModels.defaultInvoiceLineItemsState.state[0];
                let invoiceMiscAmount = 0;
                let invoiceSubTotal = 0;

                // This invoice is being created from a PO flip. Therefore we need to set the flipped document id.
                newInvoiceState.BusinessDocFields.Invoice.FlippedDocumentId = document.Id;

                for (var i = 0; i < businessDocument['PurchaseOrderLineItems'].length; i++) {
                    currInvoiceLineItem = InvoiceModels.defaultInvoiceLineItemsState.state[0];
                    let invoiceLineMiscAmount = businessDocument['PurchaseOrderLineItems'][i][
                        'PurchaseOrderLineItemMiscAmounts'
                    ].reduce((miscAmount, item) => {
                        return +miscAmount + +item.Amount;
                    }, 0);

                    //copy each field from PO Line to Invoice Line
                    for (let field in businessDocument['PurchaseOrderLineItems'][i]) {
                        if (field === 'PurchaseOrderLineItemMiscAmounts')
                            currInvoiceLineItem['InvoiceLineItemMiscAmounts'] =
                                businessDocument['PurchaseOrderLineItems'][i][field];
                        if (field === 'PurchaseOrderLineItemCompanyGLAccounts')
                            currInvoiceLineItem['InvoiceLineItemCompanyGLAccounts'] =
                                businessDocument['PurchaseOrderLineItems'][i][field];
                        else if (field === 'LineNum')
                            currInvoiceLineItem['PurchaseOrderLineNum'] =
                                businessDocument['PurchaseOrderLineItems'][i][field];
                        else if (field === 'LineItemNetTotal')
                            invoiceSubTotal += businessDocument['PurchaseOrderLineItems'][i][field];
                        else if (field === 'QuantityOrdered')
                            currInvoiceLineItem['QuantityOrdered'] = Number(
                                businessDocument['PurchaseOrderLineItems'][i][field]
                            );
                        else currInvoiceLineItem[field] = businessDocument['PurchaseOrderLineItems'][i][field];
                    }
                    currInvoiceLineItem.LineItemTotal = currInvoiceLineItem.Quantity * currInvoiceLineItem.UnitPrice;
                    currInvoiceLineItem.LineNum = i + 1;
                    currInvoiceLineItem.LineItemNetTotal =
                        currInvoiceLineItem.LineItemTotal + Number(invoiceLineMiscAmount);
                    invoiceLineItems.push(currInvoiceLineItem);
                }
                newInvoiceState.BusinessDocFields.Invoice['InvoiceLineItems'] = invoiceLineItems;
                delete businessDocument['PurchaseOrderLineItems'];

                newInvoiceState.BusinessDocFields.Invoice['InvoiceContacts'] =
                    businessDocument['PurchaseOrderContacts'];
                delete businessDocument['PurchaseOrderContacts'];

                newInvoiceState.BusinessDocFields.Invoice['InvoiceMiscAmounts'] =
                    businessDocument['PurchaseOrderMiscAmounts'];
                for (i = 0; i < businessDocument['PurchaseOrderMiscAmounts'].length; i++) {
                    invoiceMiscAmount += Number(businessDocument['PurchaseOrderMiscAmounts'][i].Amount);
                }

                delete businessDocument['PurchaseOrderMiscAmounts'];

                newInvoiceState.BusinessDocFields.Invoice['DiscountableAmount'] = invoiceSubTotal;
                newInvoiceState.BusinessDocFields.Invoice['InvoiceAmount'] =
                    invoiceMiscAmount + newInvoiceState.BusinessDocFields.Invoice['DiscountableAmount'];
                newInvoiceState.BusinessDocFields.Invoice['AmountDue'] =
                    newInvoiceState.BusinessDocFields.Invoice['InvoiceAmount'];
                newInvoiceState.BusinessDocFields.Invoice['InvoiceShippingDetails'] = [];

                let companyIdsToNotFlipComments = projectConfig.POInvoiceFlipCommentsCompanyIds.split(',').map(
                    (x) => +x
                );
                for (let field in businessDocument) {
                    if (field === 'PurchaseOrderNumber')
                        newInvoiceState.BusinessDocFields.Invoice['PurchaseOrder'] =
                            businessDocument['PurchaseOrderNumber'];
                    else if (field === 'ReleaseNumber')
                        newInvoiceState.BusinessDocFields.Invoice['PurchaseOrderReleaseNumber'] =
                            businessDocument['ReleaseNumber'];
                    else if (field === 'PaymentTerms') {
                        newInvoiceState.BusinessDocFields.Invoice[field] = businessDocument[field];
                        if (businessDocument[field]) {
                            var x = businessDocument[field].split(' ');
                            let i = x.indexOf('Net');

                            newInvoiceState.BusinessDocFields.Invoice['DueDate'] = format(
                                add(new Date(), { days: x[i + 1] }),
                                'MM/dd/yyyy'
                            );
                            if (
                                isAfter(
                                    new Date('01/01/1900'),
                                    new Date(newInvoiceState.BusinessDocFields.Invoice['DueDate'])
                                ) ||
                                isBefore(
                                    new Date('06/07/2079'),
                                    new Date(newInvoiceState.BusinessDocFields.Invoice['DueDate'])
                                )
                            ) {
                                newInvoiceState.BusinessDocFields.Invoice['DueDate'] = format(new Date(), 'P');
                            }
                        } else newInvoiceState.BusinessDocFields.Invoice['DueDate'] = format(new Date(), 'P');
                    } else if (newInvoiceState.BusinessDocFields.Invoice.hasOwnProperty(field)) {
                        if (
                            field === 'Comments' &&
                            document.CommonFields &&
                            !companyIdsToNotFlipComments.includes(document.CommonFields.SenderCompanyId)
                        ) {
                            if (businessDocument[field]) {
                                newInvoiceState.BusinessDocFields.Invoice[field] = businessDocument[field].replace(
                                    /[\&\#\<\>\[\]\+]/g,
                                    ''
                                );
                            }
                        } else if (field !== 'Comments') {
                            newInvoiceState.BusinessDocFields.Invoice[field] = businessDocument[field];
                        }
                    }
                }
            } else {
                for (let invoiceField in businessDocument) {
                    newInvoiceState.BusinessDocFields.Invoice[invoiceField] = businessDocument[invoiceField];
                }
                newInvoiceState.Id = action.payload.value.Id;
            }
            if (action.payload.value.CommonFields) {
                newInvoiceState.CommonFields.SenderProfileId = action.payload.value.CommonFields.SenderProfileId;
                newInvoiceState.CommonFields.CustomerId = action.payload.value.CommonFields.CustomerId;
                newInvoiceState.CommonFields.ReceiverProfileId = action.payload.value.CommonFields.ReceiverProfileId;
                newInvoiceState.CommonFields.CSREmail = action.payload.value.CommonFields.CSREmail;
                newInvoiceState.CommonFields.ExternalStatus = action.payload.value.CommonFields.ExternalStatus;
                newInvoiceState.CommonFields.ExternalStatus_US = action.payload.value.CommonFields.ExternalStatus_US;
            }

            return {
                ...state,
                ...newInvoiceState,
            };
        },

        [CreateInvoiceActions.UPDATE_INVOICE_CREATION_SELECTED_CURRENCY]: (state, action) => {
            return {
                ...state,
                SelectedCurrency: action.payload,
            };
        },

        [CreateInvoiceActions.UPDATE_INVOICE_CREATION_SELECTED_PROFILE]: (state, action) => {
            let newStateWithDefaultData = { ...state };
            newStateWithDefaultData.BusinessDocFields.Invoice.InvoiceProfileID = action.payload.value;
            return {
                ...state,
                newStateWithDefaultData,
            };
        },
        [DocumentCreationActions.RESET_TO_INITIAL_STATE]: (state, action) => {
            let initialState = InvoiceModels.initialState.state;
            return { ...initialState };
        },
    },
    InvoiceModels.initialState.state
);

export default сreateInvoiceReducer;
