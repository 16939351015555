import { lensPath, path, set } from 'ramda';
import { v1 } from 'uuid';
import { getVersionKey } from '../../../utils';
import { StoredFiles } from '../types';

const getFilePaths = (filePath: string) => {
    const paths: (string | number)[] = filePath.split('.');
    for (let i = 0; i < paths.length; i++) {
        if (/^\d+$/.test(paths[i] + '')) {
            paths[i] = +paths[i];
        }
    }
    return paths;
};

export class StagedCRUDRepository {
    private _filePaths: string[] | ((fields: any) => string[]) = [];

    private _fileStoreGetFiles: (sessionID: string) => StoredFiles = () => ({});

    private _fileStoreAddFile: (sessionID: string, path: string, file: File) => void = () => null;

    private _fileStoreClearFiles: (sessionID: string) => void = () => null;

    set filePaths(filePaths: string[] | ((fields: any) => string[])) {
        this._filePaths = filePaths;
    }

    set fileStoreGetFiles(fileStoreGetFiles: (sessionID: string) => StoredFiles) {
        this._fileStoreGetFiles = fileStoreGetFiles;
    }

    set fileStoreAddFile(fileStoreAddFile: (sessionID: string, path: string, file: File) => void) {
        this._fileStoreAddFile = fileStoreAddFile;
    }

    set fileStoreClearFiles(fileStoreClearFiles: (sessionID: string) => void) {
        this._fileStoreClearFiles = fileStoreClearFiles;
    }

    createSession() {
        const sessionID = v1();
        return sessionID;
    }

    deleteSession(sessionID: string) {
        localStorage.removeItem(this.createKey(sessionID));
        this._fileStoreClearFiles(sessionID);
    }

    handleFiles<T extends object>(
        sessionID: string,
        entity: T,
        callback: (filePath: string, paths: (string | number)[], files: StoredFiles) => void
    ) {
        const files = this._fileStoreGetFiles(sessionID);
        const filePaths = Array.isArray(this._filePaths) ? this._filePaths : this._filePaths(entity);
        for (const filePath of filePaths) {
            const paths = getFilePaths(filePath);
            callback(filePath, paths, files);
        }
    }

    setEntity<T extends object>(sessionID: string, entity: T) {
        this.handleFiles(sessionID, entity, (filePath, paths, files) => {
            const file = path(paths, entity);
            if (file && file instanceof File && file !== files[filePath]) {
                this._fileStoreAddFile(sessionID, filePath, file);
            }
        });
        localStorage.setItem(this.createKey(sessionID), JSON.stringify(entity));
    }

    getEntity<T extends object>(sessionID: string): T | null {
        const item = localStorage.getItem(this.createKey(sessionID));

        if (!item) {
            return null;
        }

        let entity = JSON.parse(item);
        this.handleFiles(sessionID, entity, (filePath, paths, files) => {
            const file = files[filePath];
            if (file && file instanceof File) {
                entity = set(lensPath(paths), file, entity);
            }
        });

        return entity;
    }

    private createKey(sessionID: string) {
        return `stagedCRUD-${getVersionKey()}-${sessionID}`;
    }
}

export const stagedCRUDRepository = new StagedCRUDRepository();
