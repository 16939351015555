import { createSelector } from 'reselect';

import projectConfig from '../../project.config.json';
import { cloneObjectHack } from '../../utils/dataConverter';

const businessDocType = (state, businessDocType, editMode) => businessDocType;
const businessDocFields = (state, businessDocType, editMode) =>
    editMode === true
        ? businessDocType === projectConfig.businessDocType.Invoice
            ? state.documentCreation.invoiceCreationFields.BusinessDocFields
            : state.documentCreation.documentCreationFields.BusinessDocFields
        : state.document.document.BusinessDocFields;
const fixedMiscAmountCount = (state, businessDocType, editMode) =>
    editMode === true ? state.documentCreation.fixedMiscAmountCount.value : null;

export default createSelector(
    businessDocType,
    businessDocFields,
    fixedMiscAmountCount,
    (businessDocType, businessDocFields, fixedMiscAmountCount) => {
        let miscChargesInfo = {
            fixedMiscAmountCount: fixedMiscAmountCount,
            miscCharges: [],
        };

        switch (businessDocType) {
            case projectConfig.businessDocType.Invoice:
                if (businessDocFields && businessDocFields.Invoice) {
                    const invoice = cloneObjectHack(businessDocFields.Invoice);
                    if (invoice.InvoiceMiscAmounts) {
                        miscChargesInfo.miscCharges = invoice.InvoiceMiscAmounts;
                    }
                }
                break;

            case projectConfig.businessDocType.PurchaseOrder:
                if (businessDocFields && businessDocFields.PurchaseOrder) {
                    const purchaseOrder = cloneObjectHack(businessDocFields.PurchaseOrder);
                    if (purchaseOrder.PurchaseOrderMiscAmounts) {
                        miscChargesInfo.miscCharges = purchaseOrder.PurchaseOrderMiscAmounts;
                    }
                }
                break;

            case projectConfig.businessDocType.PurchaseOrderAcknowledgement:
                if (
                    businessDocFields &&
                    businessDocFields.BusinessDocument &&
                    businessDocFields.BusinessDocument.Document &&
                    businessDocFields.BusinessDocument.Document.BusinessDocument
                ) {
                    const businessDocument = cloneObjectHack(
                        businessDocFields.BusinessDocument.Document.BusinessDocument
                    );
                    if (
                        businessDocument.PurchaseOrderAcknowledgement &&
                        businessDocument.PurchaseOrderAcknowledgement.MiscAmounts &&
                        businessDocument.PurchaseOrderAcknowledgement.MiscAmounts.MiscAmount
                    ) {
                        miscChargesInfo.miscCharges =
                            businessDocument.PurchaseOrderAcknowledgement.MiscAmounts.MiscAmount;
                    }
                }
                break;

            default:
                break;
        }

        return miscChargesInfo;
    }
);
