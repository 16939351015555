import { AxiosInstance, AxiosError } from 'axios';
import { CypressAxiosInterceptor } from './CypressAxiosInterceptor';

function isAxiosError(error: any): error is AxiosError {
    return error.isAxiosError;
}

export function installCypressAxiosInterceptor(instance: AxiosInstance, interceptor: CypressAxiosInterceptor) {
    instance.interceptors.response.use(
        (response) => {
            interceptor.handleResponse(response);

            return response;
        },
        async (error) => {
            if (isAxiosError(error)) {
                const { response } = error;
                if (response) {
                    interceptor.handleResponse(response);
                }
            }

            throw error;
        }
    );
}
