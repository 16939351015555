import {
    DOCUMENT_LINE_ITEM_SKELETON_INITALIZE_HEADER_FIELD_VALUES,
    DOCUMENT_LINE_ITEM_SKELETON_UPDATE_FIELD_VALUE,
    FETCH_DOCUMENT_SKELETON_EXECUTE,
    FETCH_DOCUMENT_SKELETON_FAILURE,
    FETCH_DOCUMENT_SKELETON_SUCCESS,
} from '../../actions/document/documentCreationActions';
import { USER_LOGIN_FAILED, USER_LOGIN_PENDING, USER_LOGIN_SUCCESS, USER_LOGOUT } from '../../actions/userlogin';

const initialState = {
    fullSkeleton: null,
    lineItemSkeleton: null,
    loading: {
        fullSkeleton: false,
        lineItemSkeleton: false,
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case DOCUMENT_LINE_ITEM_SKELETON_INITALIZE_HEADER_FIELD_VALUES:
        case DOCUMENT_LINE_ITEM_SKELETON_UPDATE_FIELD_VALUE:
            return {
                ...state,
                lineItemSkeleton: action.payload,
            };

        case FETCH_DOCUMENT_SKELETON_EXECUTE:
            return {
                ...state,
                fullSkeleton: initialState.fullSkeleton,
                lineItemSkeleton: initialState.lineItemSkeleton,
                loading: {
                    fullSkeleton: true,
                    lineItemSkeleton: true,
                },
            };

        case FETCH_DOCUMENT_SKELETON_FAILURE:
            return {
                ...state,
                loading: {
                    fullSkeleton: false,
                    lineItemSkeleton: false,
                },
            };

        case FETCH_DOCUMENT_SKELETON_SUCCESS:
            return {
                ...state,
                fullSkeleton: action.payload.fullSkeleton,
                lineItemSkeleton: action.payload.lineItemSkeleton,
                loading: {
                    fullSkeleton: false,
                    lineItemSkeleton: false,
                },
            };

        case USER_LOGIN_FAILED:
        case USER_LOGIN_PENDING: {
            // if it was timeout return currenState, otherwhise initialState
            const { timeout } = state;
            return timeout && timeout === true ? state : initialState;
        }
        case USER_LOGIN_SUCCESS: {
            // if it was timeout return currenState, otherwhise initialState
            const { timeout, ...currentState } = state;
            return timeout && timeout === true ? currentState : initialState;
        }
        case USER_LOGOUT: {
            const { timeout } = action.payload;
            return timeout && timeout == true ? { ...state, timeout } : initialState;
            //return initialState;
        }
        default:
            return state;
    }
};
