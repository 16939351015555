import { IFieldType, createFieldTypeFactory } from '../infrastructure';

/**
 * A checkbox field object.
 */
export interface ICheckboxField extends IFieldType<boolean> {
    type: 'ICheckboxField';
}

/**
 * Creates a checkbox field object
 */
export const createCheckboxField = createFieldTypeFactory<ICheckboxField>('ICheckboxField', false);
