/* eslint-disable no-console */
import { authTokenStore } from './AuthTokenStore';
import { createServiceInjector } from '../../../services/utils/dependencyInjection/index';
import { ILoginResponseViewModel } from '../../../services/UserService/Api';
import { sleep } from '../../../utils';

export interface UserLogInOptions {
    email: string;
    password: string;
}

export class AuthService {
    logIn = async ({ email, password }: UserLogInOptions) => {
        // call the API to get the token
        // but haha, we don't have one yet
        await sleep(500);
        console.log('logging in with: ', email, password);
        const mockToken: ILoginResponseViewModel = {
            '.expires': 'string',
            '.issued': 'string',
            expires_in: 100000,
            refresh_token: 'string',
            token_type: 'bearer',
            access_token: 'string',
            userId: 'string',
            companyId: 'string',
        };
        authTokenStore.setAuthToken(mockToken);
    };

    logOut = async () => {
        // call logout API
        console.log('calling logout api');
    };

    initSSO = async () => {
        // redirect to the SSO service
        await sleep(500);
        window.location.href = window.location.href + '?token=faketoken';
    };

    exchangeSSOToken = async (token: string) => {
        // call API
        await sleep(500);
        console.log('exchanged token: ', token);
        const mockToken: ILoginResponseViewModel = {
            '.expires': 'string',
            '.issued': 'string',
            expires_in: 100000,
            refresh_token: token,
            token_type: 'bearer',
            access_token: token,
            userId: 'string',
            companyId: 'string',
        };

        authTokenStore.setAuthToken(mockToken);
    };

    getSSOSettings = async () => {
        await sleep(500);
        return null;
    };

    resetPassword = async (email: string) => {
        await sleep(500);
        console.log('reseting password for: ', email);
    };
}

export const [CreateAuthService, useAuthService] = createServiceInjector(AuthService);
