import { FC, useMemo } from 'react';
import clsx from 'clsx';
import {
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    Theme,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChevronLeft } from '@mui/icons-material';
import { NavigationSubMenuItem } from './SubMenuItem';
import { TransceptaLogoWhite } from '../Logos';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { IAccountMenu, IMenuItem } from './types';
import { useIsSelected } from './useIsSelected';
import { HeaderMenuItem } from './HeaderMenuItem';

const useStyles = makeStyles((theme: Theme) => ({
    drawerRoot: {
        height: '100%',
        color: theme.palette.primary.contrastText,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    menuButton: {
        color: theme.palette.primary.contrastText,
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    toolbarFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 16,
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-end',
        },
    },
}));

interface INavigationDrawerProps {
    drawerWidth: number;
    handleDrawerToggle: () => void;
    menuItems: IMenuItem[];
    accountMenu: IAccountMenu;
}

function useMenuItems(menuItems: IMenuItem[]): { menuItems: IMenuItem[]; isRootLevel: boolean } {
    const { pathname } = useLocation();

    const alwaysVisibleItems = menuItems.filter((item) => !!item.alwaysVisible);
    const menuItemsWithReplace = useMemo(
        () => menuItems.filter((item) => item.subMenuStyle === 'replace'),
        [menuItems]
    );
    const filteredMenuItems = menuItems.filter((item) => item.subMenuStyle !== 'replace');

    const nestedMenuItems = useMemo(
        () =>
            menuItemsWithReplace
                .map((item) => {
                    const { path } = item;

                    const match = matchPath(pathname, {
                        path,
                        exact: false,
                    });

                    if (match == null) {
                        return [];
                    }

                    const { params } = match;

                    const items = item.subMenu?.items ?? [];

                    return items.map((subItem) => {
                        const fullPath = Object.entries(params).reduce((result, [key, value]) => {
                            return result.replace(`:${key}`, value as string);
                        }, subItem.path);

                        return {
                            ...subItem,
                            path: fullPath,
                        };
                    });
                })
                .flat(),
        [menuItemsWithReplace, pathname]
    );

    if (!nestedMenuItems.length) {
        return { menuItems: filteredMenuItems, isRootLevel: true };
    }

    return { menuItems: alwaysVisibleItems.concat(nestedMenuItems), isRootLevel: false };
}

export const NavigationDrawer: FC<INavigationDrawerProps> = ({
    drawerWidth,
    handleDrawerToggle,
    menuItems: allMenuItems,
    accountMenu,
}) => {
    const classes = useStyles({ drawerWidth });
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
    const history = useHistory();

    const { menuItems, isRootLevel } = useMenuItems(allMenuItems);

    const isSelected = useIsSelected();

    return (
        <div className={classes.drawerRoot}>
            <div>
                <div className={clsx(classes.toolbar, classes.toolbarFlex)}>
                    {!isMdDown ? (
                        <TransceptaLogoWhite />
                    ) : (
                        <IconButton
                            color="inherit"
                            aria-label="close drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <ChevronLeft />
                        </IconButton>
                    )}
                </div>
                <Divider />
                <List>
                    {menuItems.map((menuItem, index) => {
                        const subMenu = menuItem.subMenu;

                        if (subMenu) {
                            return (
                                <NavigationSubMenuItem
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    subMenu={subMenu}
                                    parentItem={menuItem}
                                />
                            );
                        }
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <HeaderMenuItem
                                key={index}
                                menuItem={menuItem}
                                isSelected={isSelected(menuItem.path, !isRootLevel)}
                            />
                        );
                    })}
                </List>
            </div>
            {isMdDown && (
                <div>
                    <List>
                        {accountMenu.items.map((accountItem: any, index: number) => (
                            <ListItem
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                sx={
                                    !accountItem.action && isSelected(accountItem.path)
                                        ? { borderRight: 6, borderColor: 'primary.400', p: 0 }
                                        : { p: 0 }
                                }
                            >
                                <ListItemButton
                                    onClick={() => {
                                        if (accountItem.action) {
                                            accountItem.action();
                                        } else {
                                            history.push(accountItem.path);
                                        }
                                        handleDrawerToggle();
                                    }}
                                >
                                    <ListItemText primary={accountItem.name} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </div>
            )}
        </div>
    );
};
