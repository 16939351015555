import { useIMask, IMask } from 'react-imask';
import { useEffect, useState, useCallback, FocusEventHandler } from 'react';
import { TextField } from '../TextField';
import { FCNC } from '../FCNC';
import { ReadOnly } from '../ReadOnly';
import { BaseTextFieldProps, InputLabelProps } from '@mui/material';
import { useFieldsReadonly } from '../Form/FormControlContext';
import { ITooltipIconProps } from '../Tooltip';

interface IBaseMaskedTextFieldProps {
    id?: string;

    maskOptions: IMask.AnyMaskedOptions;

    initialValue: string;

    onChange: (value: string) => void;

    label?: string;

    required?: boolean;

    sx?: BaseTextFieldProps['sx'];

    readonly?: boolean;

    onDoubleClick?: BaseTextFieldProps['onDoubleClick'];

    testId?: string;

    tooltip?: ITooltipIconProps;

    inputLabelProps?: InputLabelProps;

    disabled?: boolean;

    onFocus?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    placeholder?: string;
    stacked?: boolean;
    textFieldLabel?: string;
}

const BaseMaskedTextField: FCNC<IBaseMaskedTextFieldProps> = ({
    id,
    initialValue,
    onChange,
    required,
    sx,
    readonly,
    label,
    maskOptions,
    onDoubleClick,
    testId,
    tooltip,
    inputLabelProps,
    disabled,
    onFocus,
    placeholder,
    stacked,
    textFieldLabel,
}) => {
    const [readOnlyValue, setReadOnlyValue] = useState('');

    const { ref, maskRef } = useIMask(maskOptions);

    // to update the input element with latest value from parent state
    useEffect(() => {
        maskRef.current.unmaskedValue = initialValue;
        setReadOnlyValue(maskRef.current.value);
        // we only want to run this on the first render to set the initial value
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = useCallback(() => {
        if (maskRef.current.value !== readOnlyValue) {
            onChange(maskRef.current.unmaskedValue);
        }
        // ignoring `maskRef`, it should be stable
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onChange, readOnlyValue]);

    // attach a listener to parent form "submit" event
    useEffect(() => {
        let el = ref.current.parentElement;
        while (el && el.tagName.toLowerCase() !== 'form') {
            el = el.parentElement;
        }

        if (el) {
            el.addEventListener('submit', handleChange);
            return () => {
                el?.removeEventListener('submit', handleChange);
            };
        }

        return undefined;
        // we only want to re-create the listener when the `handleChange` function changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleChange]);

    const isReadonly = useFieldsReadonly(readonly);

    if (isReadonly) {
        return (
            <>
                <ReadOnly sx={sx} label={label} value={readOnlyValue} testId={testId} />
                <input ref={ref as any} style={{ display: 'none' }} />
            </>
        );
    }

    return (
        <TextField
            stacked={stacked}
            testId={testId}
            label={label}
            id={id}
            inputRef={ref}
            onBlur={handleChange}
            required={required}
            sx={sx}
            onDoubleClick={onDoubleClick}
            tooltip={tooltip}
            inputLabelProps={inputLabelProps}
            disabled={disabled}
            onFocus={onFocus}
            placeholder={placeholder}
            textFieldLabel={textFieldLabel}
        />
    );
};

export interface IAnyMaskedTextFieldProps {
    id?: string;

    maskOptions: IMask.AnyMaskedOptions;

    value: string;

    onChange: (value: string) => void;

    label?: string;

    required?: boolean;

    sx?: BaseTextFieldProps['sx'];

    readonly?: boolean;

    onDoubleClick?: BaseTextFieldProps['onDoubleClick'];

    testId?: string;

    tooltip?: ITooltipIconProps;

    inputLabelProps?: InputLabelProps;

    disabled?: boolean;

    onFocus?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    placeholder?: string;
    stacked?: boolean;
    textFieldLabel?: string;
}

export const AnyMaskedTextField: FCNC<IAnyMaskedTextFieldProps> = ({
    stacked,
    id,
    onChange,
    value,
    required,
    sx,
    readonly,
    label,
    maskOptions,
    onDoubleClick,
    testId,
    tooltip,
    inputLabelProps,
    disabled,
    onFocus,
    placeholder,
    textFieldLabel,
}) => {
    const [key, setKey] = useState(0);

    return (
        <BaseMaskedTextField
            stacked={stacked}
            id={id}
            maskOptions={maskOptions}
            key={`${key}-${value}`}
            initialValue={value}
            onChange={(v) => {
                onChange(v);
                setKey((k) => k + 1);
            }}
            required={required}
            sx={sx}
            readonly={readonly}
            label={label}
            onDoubleClick={onDoubleClick}
            testId={testId}
            tooltip={tooltip}
            inputLabelProps={inputLabelProps}
            disabled={disabled}
            onFocus={onFocus}
            placeholder={placeholder}
            textFieldLabel={textFieldLabel}
        />
    );
};
