import { useEffect, useCallback } from 'react';
import { FCNC } from '../../ui';
import { IZendeskProps } from './types';

const canUseDOM = () => !(typeof window === 'undefined' || !window.document || !window.document.createElement);

export const ZendeskAPI = (...args: any) => {
    if (!canUseDOM() || !window.zE) {
        console.error('Zendesk is not initialized yet');

        return;
    }

    window.zE.apply(null, args);
};

export const ZendeskBootstrap: FCNC<IZendeskProps> = ({ defer, zendeskKey, ...settings }) => {
    const insertScript = useCallback(() => {
        const script = document.createElement('script');
        script[defer ? 'defer' : 'async'] = true;

        script.id = 'ze-snippet';
        script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;

        document.body.appendChild(script);
    }, [defer, zendeskKey]);

    useEffect(() => {
        if (canUseDOM() && !window.zE) {
            insertScript();

            window.zESettings = settings;
        }

        return () => {
            if (!canUseDOM() || !window.zE) {
                return;
            }

            delete window.zE;
            delete window.zESettings;
        };
    }, [insertScript, settings]);

    return null;
};
