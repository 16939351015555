import {
    ISearchConfiguration,
    ISearchRequest,
    ISearchResponse,
    ISearchService,
    createSimpleDropdownOptions,
    createUseSearchService,
    createAutocompleteField,
    createTextField,
} from '../../../reusableFeatures';
import { GridColumns } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { IValidationFailureTypeSearchItem, ValidationFailureTypesService } from '../../ValidationFailureTypes';
import { IValidationFailureTypesGrid } from './types';
import { paginate } from '../../../utils';
import { CompanyService } from '../../Company';

export class ValidationFailureTypeSearchService implements ISearchService<IValidationFailureTypeSearchItem> {
    private validationFailureTypesService = new ValidationFailureTypesService();

    async fetchSearchConfiguration(): Promise<ISearchConfiguration<IValidationFailureTypeSearchItem>> {
        const company = new CompanyService();
        const allCompanies = (await company.getAllBuyerCompanyOptions()).map(({ label }) => label);

        const gridColumns: GridColumns = [
            { field: 'errorCode', headerName: 'Error Code', flex: 1, sortable: false },
            {
                field: 'buyerCompany',
                headerName: 'Buyer Company',
                flex: 1,
                minWidth: 200,
                sortable: false,
                renderCell: (params) => {
                    const compLabel = allCompanies.find((value) => value.split(' [')[0] === params.value);
                    const companyName = compLabel ? compLabel : params.value;

                    return params.value ? <Typography>{companyName}</Typography> : null;
                },
            },
            { field: 'validationType', headerName: 'Validation Type', flex: 1, sortable: false },
            { field: 'titleText', headerName: 'Title Text', flex: 2, sortable: false },
            {
                field: 'dashboardRejectionTypeLabel',
                headerName: 'Dashboard Rejection Type Label',
                flex: 1,
                sortable: false,
            },
            {
                field: 'dashboardRejectionTypeDescription',
                headerName: 'Dashboard Rejection Type Description',
                flex: 1,
                sortable: false,
            },
        ];

        const data = await this.validationFailureTypesService.getValidationFailureTypesGrid();

        const allErrorCodes: Set<string> = new Set<string>();
        const allTitleText: Set<string> = new Set<string>();

        data?.forEach((item) => {
            if (item.errorCode !== '') {
                allErrorCodes.add(item.errorCode);
            }
            if (item.titleText !== '') {
                allTitleText.add(item.titleText);
            }
        });

        const searchFormFieldColumns = [
            [
                createAutocompleteField({
                    label: 'Buyer Name',
                    defaultValue: null,
                    dataBindingKey: 'buyerName',
                    options: createSimpleDropdownOptions(allCompanies),
                }),
                createAutocompleteField({
                    label: 'Error Code',
                    defaultValue: null,
                    dataBindingKey: 'errorCode',
                    options: createSimpleDropdownOptions([...allErrorCodes]),
                }),
                createAutocompleteField({
                    label: 'Title Text',
                    defaultValue: null,
                    dataBindingKey: 'titleText',
                    options: createSimpleDropdownOptions([...allTitleText]),
                }),
                createTextField({
                    dataBindingKey: 'dashboardRejectionTypeLabel',
                    defaultValue: '',
                    label: 'Dashboard Rejection Type Label',
                }),
                createTextField({
                    dataBindingKey: 'dashboardRejectionTypeDescription',
                    defaultValue: '',
                    label: 'Dashboard Rejection Type Description',
                }),
            ],
        ];

        return {
            gridColumns,
            enabledExportingMethods: [],
            searchFormFieldColumns,
            pageSizeOptions: [10, 25, 50],
            defaultPageSize: 10,
            searchWithNoCriteria: true,
        };
    }

    private filterValidationFailureTypesGrid = (
        grid: IValidationFailureTypesGrid[],
        buyerName: string | null,
        errorCode: string | null,
        titleText: string | null,
        dashboardRejectionTypeLabel: string,
        dashboardRejectionTypeDescription: string
    ) => {
        let filteredGrid = [...grid];

        if (buyerName) {
            const candidateBuyerName = buyerName.split(' [')[0];
            filteredGrid = filteredGrid.filter((item) => item.buyerCompany === candidateBuyerName);
        }

        if (errorCode) {
            filteredGrid = filteredGrid.filter((item) => item.errorCode.toString() === errorCode);
        }

        if (titleText) {
            filteredGrid = filteredGrid.filter((item) => item.titleText === titleText);
        }

        if (dashboardRejectionTypeLabel) {
            filteredGrid = filteredGrid.filter((item) =>
                item.dashboardRejectionTypeLabel
                    .toLocaleLowerCase()
                    .includes(dashboardRejectionTypeLabel.toLocaleLowerCase())
            );
        }

        if (dashboardRejectionTypeDescription) {
            filteredGrid = filteredGrid.filter((item) =>
                item.dashboardRejectionTypeDescription
                    .toLocaleLowerCase()
                    .includes(dashboardRejectionTypeDescription.toLocaleLowerCase())
            );
        }

        return filteredGrid;
    };

    async fetchResults(
        _searchConfiguration: ISearchConfiguration<IValidationFailureTypeSearchItem>,
        searchRequest: ISearchRequest<IValidationFailureTypeSearchItem>
    ): Promise<ISearchResponse> {
        const response = await this.validationFailureTypesService.getValidationFailureTypesGrid();

        if (!response) {
            return {
                pageResults: [],
                totalResultCount: 0,
            };
        }

        const filtered = this.filterValidationFailureTypesGrid(
            response,
            searchRequest.searchQuery.state.buyerName,
            searchRequest.searchQuery.state.errorCode,
            searchRequest.searchQuery.state.titleText,
            searchRequest.searchQuery.state.dashboardRejectionTypeLabel,
            searchRequest.searchQuery.state.dashboardRejectionTypeDescription
        );

        return {
            pageResults: paginate(filtered, searchRequest.pageSize, searchRequest.pageNumber),
            totalResultCount: filtered.length,
        };
    }

    exportData(): Promise<void> {
        throw new Error('Exporting not implemented for dashboard rejection type search');
    }

    get key(): string {
        return 'ValidationFailureTypeSearchService';
    }
}

export const useValidationFailureTypeSearchService = createUseSearchService(ValidationFailureTypeSearchService);
