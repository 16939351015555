// DEPRECATED: This component is deprecated
// REFACTOR: Create a replacement component (eg using material-ui toasts or notifications)
import { FC } from 'react';
import './notification.scss';

export interface INotificationProps {
    type: string;
    text: string | JSX.Element;
    renderActions?: () => JSX.Element;
    testid?: string;
}

/**
 * @deprecated
 */
export const Notification: FC<INotificationProps> = ({ type, text, renderActions, testid }) => {
    return (
        <div data-testid={testid} className={`notification ${type}`}>
            <div className="info">
                <p className="message">{text}</p>
            </div>
            {renderActions?.()}
        </div>
    );
};
