import { api } from './api';
import apiPaths from './paths';

class NotificationThunk {
    constructor() {}

    fetchNotifications = (params) => {
        return api()
            .get(apiPaths.userNotification, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    acknowledgeUserNotification = (params) => {
        return api()
            .post(apiPaths.acknowledgeUserNotification, params)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };
}

export default NotificationThunk;
