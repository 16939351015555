import { RecursivePartial } from '../../../../types';
import { api } from '../../../api';
import { ApiResponse, PageResult } from '../../Utilities';
import {
    BuyerCompanyUserProfileViewModel,
    BuyerPortalSettingsViewModel,
    CompanyViewModel,
    SubmissionMethodViewModel,
    TradingPartnerViewModel,
    VatArchiveServiceViewModel,
    VendorClassViewModel,
    CompanyUserProfileListViewModel,
    CompanySearchViewModel,
} from '../../ViewModels';
import { GetBuyerSubmissionMethod, GetTradingPartnersParams, GetBuyerCompanyUserProfile } from './Params';

export class CompanyApi {
    private route = '/Company';

    public getCompanyBySearchString = (searchString: string): ApiResponse<CompanyViewModel[]> => {
        return api().get(`${this.route}/search/${searchString}`);
    };

    public getCompany = (id: number): ApiResponse<CompanyViewModel[]> => {
        return api().get(`${this.route}/${id}`);
    };

    public updateCompany = (id: number, companyViewModel: Partial<CompanyViewModel>): ApiResponse<void> => {
        return api().put(`${this.route}/${id}`, companyViewModel);
    };

    public updateCompanyWithFiles(
        id: number,
        companyViewModel: Partial<CompanyViewModel>,
        selectedLogo?: File,
        selectedFile?: File
    ): ApiResponse<void> {
        const formData = new FormData();

        const jsonData = JSON.stringify(companyViewModel);
        const blobData = new Blob([jsonData], {
            type: 'application/json',
        });

        formData.append('Data', blobData);

        if (selectedLogo && selectedLogo.name) {
            formData.append('Logo', selectedLogo, selectedLogo.name);
        }

        if (selectedFile && selectedFile.name) {
            formData.append('PDF', selectedFile, selectedFile.name);
        }

        const headers = {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
        };

        return api().put(`${this.route}/${id}`, formData, { headers });
    }

    public getSubmissionMethod = (params: GetBuyerSubmissionMethod): ApiResponse<SubmissionMethodViewModel[]> => {
        return api().get(`${this.route}/BuyerSubmissionMethod`, {
            params,
        });
    };

    public postSubmissionMethod = (
        id: number,
        updatedBuyerSubmissionMethodList: RecursivePartial<SubmissionMethodViewModel>[]
    ): ApiResponse<void> => {
        const formData = new FormData();
        formData.append('Data', JSON.stringify(updatedBuyerSubmissionMethodList));

        return api().post(`${this.route}/BuyerSubmissionMethod?companyId=${id}`, formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        });
    };

    /**
     * Retrieves a submission method by its ID.
     * @param id - The ID of the submission method.
     * @returns An ApiResponse containing the submission method view model.
     */
    public getSubmissionMethodById = (id: number): ApiResponse<SubmissionMethodViewModel> => {
        return api().get(`${this.route}/BuyerSubmissionMethod/${id}`);
    };

    /**
     * Updates the submission method by ID.
     * @param id - The ID of the submission method.
     * @param submissionMethod - The update of the submission method view model.
     * @param files - Optional array of files to be uploaded.
     * @returns An ApiResponse object representing the response from the API.
     */
    public updateSubmissionMethodById = (
        id: number,
        submissionMethod: RecursivePartial<SubmissionMethodViewModel>,
        files?: File[]
    ): ApiResponse<void> => {
        const formData = new FormData();
        const jsonData = JSON.stringify(submissionMethod);

        formData.append('data', jsonData);

        if (files) {
            files.forEach((file) => {
                formData.append(file.name.split('.')[0], file, file.name);
            });
        }

        return api().put(`${this.route}/BuyerSubmissionMethod/${id}`, formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        });
    };

    public getCompanies = (params: {
        isBuyer: boolean;
        $filter?: string;
        $orderBy?: string;
    }): ApiResponse<CompanyViewModel[]> => {
        return api().get(`${this.route}`, { params });
    };

    /**
     * This endpoint is meant to be used on search pages.
     * It does not return the full Company ViewModel.
     */
    public getCompanySearch(params: {
        isBuyer: boolean;
        $count: boolean;
        $skip?: number;
        $top: number;
        $filter?: string;
        $orderBy?: string;
    }): ApiResponse<PageResult<CompanySearchViewModel>> {
        return api().get(`${this.route}/CompanySearch`, { params });
    }

    public getTradingPartners = (
        params: GetTradingPartnersParams
    ): ApiResponse<{ Count: null; Items: TradingPartnerViewModel[] }> => {
        return api().get(`${this.route}/TradingPartners`, { params });
    };

    public getTradingPartner = (id: number): ApiResponse<TradingPartnerViewModel> => {
        return api().get(`${this.route}/TradingPartners/${id}`);
    };

    public putTradingPartners = (
        tradingPartnerId: number,
        tradingPartner: RecursivePartial<TradingPartnerViewModel>
    ): ApiResponse<{ Id: number }> => {
        return api().put(`${this.route}/TradingPartners/${tradingPartnerId}`, tradingPartner);
    };

    public postCompanyUserProfile(
        params: {
            isSupplierRegistration?: boolean;
            internalSystemUser?: string;
            isScanKey?: boolean;
        },
        body: RecursivePartial<CompanyUserProfileListViewModel>
    ): ApiResponse<{ results: { CompanyId: number; CompanyName: string; Message: string }[] }> {
        return api().post(`${this.route}/CompanyUserProfile`, body, { params });
    }

    public getBuyerPortalSettings(params: { companyId: number }): ApiResponse<BuyerPortalSettingsViewModel> {
        return api().get(`${this.route}/BuyerPortalSettings`, { params });
    }

    public getVendorClass(params: { companyId: number; childCompanyId?: string }): ApiResponse<VendorClassViewModel[]> {
        return api().get(`${this.route}/VendorClass`, { params });
    }

    /**
     * Creates a new buyer
     * @param buyerCompanyViewModel a view model representing the buyer company
     */
    public postBuyerCompanyUserProfile = (
        buyerCompanyViewModel: RecursivePartial<BuyerCompanyUserProfileViewModel>
    ): ApiResponse<undefined> => {
        return api().post(`${this.route}/BuyerCompanyUserProfile`, buyerCompanyViewModel);
    };

    /**
     * Creates a new buyer for a customer who's already a vendor
     * @param buyerCompanyViewModel a view model representing the buyer company
     */
    public putBuyerCompanyUserProfile = (
        buyerCompanyViewModel: RecursivePartial<BuyerCompanyUserProfileViewModel>
    ): ApiResponse<undefined> => {
        return api().put(`${this.route}/BuyerCompanyUserProfile`, buyerCompanyViewModel);
    };

    /**
     * Fetches a buyer company profile
     * @returns a view model representing the buyer company
     */
    public getBuyerCompanyUserProfile = (
        params?: GetBuyerCompanyUserProfile
    ): ApiResponse<RecursivePartial<BuyerCompanyUserProfileViewModel>> => {
        return api().get(`${this.route}/BuyerCompanyUserProfile`, {
            params,
        });
    };

    public getVatArchiveService(params: { companyId: number }): ApiResponse<VatArchiveServiceViewModel[]> {
        return api().get(`${this.route}/VatArchiveService`, { params });
    }

    public getCompanyCustomDataset<T = any>(params: {
        companyId: number;
        datasetName?: string;
        datasetValuePath?: string;
        childCompanyId?: string;
    }): ApiResponse<{
        CompanyId: number;
        DatasetName: string;
        KeyValues: {
            Key: string;
            Value: T;
        }[];
    }> {
        return api().get(`${this.route}/CompanyCustomDataset`, { params });
    }
}
