import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { portalUserService } from '../../services';
import { DASHBOARD_MULTI_COMPANY_LOCAL_STORAGE_KEY } from './Dashboard.utils';

interface DashboardMultiCompanyContextType {
    selectedCompanyIds: number[];
    setSelectedCompanyIds: (ids: number[]) => void;
}

const defaultContext: DashboardMultiCompanyContextType = {
    selectedCompanyIds: [],
    setSelectedCompanyIds: () => {},
};

const DashboardMultiCompanyContext = createContext<DashboardMultiCompanyContextType>(defaultContext);

interface DashboardMultiCompanyProviderProps {
    children: ReactNode;
}

export const DashboardMultiCompanyProvider: React.FC<DashboardMultiCompanyProviderProps> = ({ children }) => {
    const allCompanyIds = portalUserService.getParentAndChildCompanyIds();
    const storedCompanyIdsString = localStorage.getItem(DASHBOARD_MULTI_COMPANY_LOCAL_STORAGE_KEY);
    // convert local storage string to array of numbers safely
    const storedCompanyIds = storedCompanyIdsString
        ? storedCompanyIdsString
              .split(',')
              .map((id) => {
                  const num = Number(id.trim());
                  return isNaN(num) ? null : num;
              })
              .filter((num): num is number => num !== null)
        : [];

    const [selectedCompanyIds, setSelectedCompanyIds] = useState<number[]>(
        storedCompanyIds.length > 0 ? storedCompanyIds : allCompanyIds
    );

    // Sync with local storage
    useEffect(() => {
        const selectedCompanyIdsString = selectedCompanyIds.join(',');
        localStorage.setItem(DASHBOARD_MULTI_COMPANY_LOCAL_STORAGE_KEY, selectedCompanyIdsString);
    }, [selectedCompanyIds]);

    // Sync with portalUserService
    useEffect(() => {
        portalUserService.setMultipleCompanyChildrenSelection(selectedCompanyIds);
    }, [selectedCompanyIds]);

    return (
        <DashboardMultiCompanyContext.Provider value={{ selectedCompanyIds, setSelectedCompanyIds }}>
            {children}
        </DashboardMultiCompanyContext.Provider>
    );
};

export const useDashboardMultiCompany = (): DashboardMultiCompanyContextType => {
    return useContext(DashboardMultiCompanyContext);
};
