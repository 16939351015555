import f, { ODataFilterBuilder } from 'odata-filter-builder';
import { ODataUtils, escape, isEmpty } from '../../../utils';

import { ICompanyGLAccountStructureSearchItems } from './types';

export const createCompanyGLAccountStructureGridFilter = ({
    name,
    active,
    companyId,
}: Partial<ICompanyGLAccountStructureSearchItems> = {}) => {
    const filterRules: ODataFilterBuilder[] = [];

    if (!isEmpty(name)) {
        filterRules.push(f().contains('name', escape(name!)));
    }

    if (typeof active === 'boolean') {
        filterRules.push(f().eq('active', active));
    }

    if (companyId) {
        filterRules.push(f().eq('companyId', companyId));
    }

    return filterRules.length ? ODataUtils.andConditions(filterRules).toString() : undefined;
};
