export enum FieldCategorization {
    Unknown,
    Required,
    Reach,
    BuyerRequired,
    VendorClassSpecific,
    BuyerClassSpecific,
    ExludeFromPortalSkeleton,
    Grading,
}
