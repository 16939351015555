/**
 * * The utils directory includes frontend only code that would break the node service
 * * if imported, so we need to be specific when importing from it.
 */
import { andConditions as _andConditions, orConditions as _orConditions } from '../../../../utils/odata';
import { escape as _escape } from '../../../../utils/escape';
import { isEmpty as _isEmpty } from '../../../../utils/isEmpty';
import { executeValidationsForWorkflowEngine } from '../DPSValidations';

function createWrappedFunction<T extends (...args: Parameters<T>) => ReturnType<T>>(fn: T) {
    return (...args: Parameters<T>) => fn(...args);
}

export function getWorkflowValidationUtils() {
    const OData = {
        andConditions: createWrappedFunction(_andConditions),
        orConditions: createWrappedFunction(_orConditions),
    };

    return {
        OData,
        escape: createWrappedFunction(_escape),
        isEmpty: createWrappedFunction(_isEmpty),
        executeDocumentValidationScript: createWrappedFunction(executeValidationsForWorkflowEngine),
    };
}
