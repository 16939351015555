import { ReactNode } from 'react';

export class ServiceError extends Error {
    constructor(public readonly validationErrors: ReactNode[]) {
        super('A Validation Error Occurred');

        Object.setPrototypeOf(this, ServiceError.prototype);
    }
}

export function validateData(cb: (errors: ReactNode[]) => void) {
    const errors: ReactNode[] = [];
    cb(errors);
    if (errors.length > 0) {
        throw new ServiceError(errors);
    }
}
