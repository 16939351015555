// DEPRECATED: Do not use
// REFACTOR: Move to Organisms/Grid as that is the only place it is used.
import { FC, useState, useRef, useEffect, useCallback } from 'react';
import ReactPaginate from 'react-paginate';
import './Paginator.scss';
import { Input as InputField } from '../InputField';

export interface IPaginator {
    pageCount: number;
    pageSize: number;
    initialPage?: number;
    handlePageClick: (skip: number, top: number) => void;
    afterPageChangeRef?: React.MutableRefObject<(() => void) | undefined>;
    pageSizes?: number[];
    onPageSizeChange?: (top: number) => void;
}

interface IPaginatorInputProps {
    pageCount: number;
    onPageChange: (page: number) => void;
}

interface IPaginatorSizeProps {
    pageSizes: number[];
    onPageSizeChange?: (size: number) => void;
}

const PaginatorSize: React.FC<IPaginatorSizeProps> = ({ pageSizes, onPageSizeChange }) => {
    return (
        <div className="core-paginator-page-size">
            Rows per page:&nbsp;
            <select
                name="pageSizes"
                className="core-input"
                onChange={(ev) => (onPageSizeChange ? onPageSizeChange(parseInt(ev.target.value, 10)) : null)}
            >
                {pageSizes.map((size) => {
                    return <option value={size}>{size}</option>;
                })}
            </select>
        </div>
    );
};

const PaginatorInput: React.FC<IPaginatorInputProps> = ({ pageCount, onPageChange }) => {
    const [page, setPage] = useState('');
    const [error, setError] = useState('');

    const handlePageJump = useCallback(() => {
        const pageAsNumber = Number.parseInt(page, 10);

        if (Number.isNaN(pageAsNumber)) {
            setError('The page number must be a number.');
            return;
        }

        if (pageAsNumber >= 1 && pageAsNumber <= pageCount) {
            onPageChange(pageAsNumber - 1);
        } else {
            setError(`The page number must be between 1 and ${pageCount}, inclusive.`);
        }
    }, [onPageChange, page, pageCount]);

    return (
        <div className="core-paginator-input">
            <InputField
                id="jumpToPage"
                fieldName="jumpToPage"
                placeholder="Jump to Page #"
                value={page}
                onChange={(e) => {
                    if (!e.target.value.match(/^\d*$/)) {
                        return;
                    }

                    setPage(e.target.value);
                }}
                onEnterPress={handlePageJump}
                editable
            />
            <span className="core-paginator-error">{error}</span>
        </div>
    );
};

/**
 * @deprecated
 */
export const Paginator: FC<IPaginator> = ({
    pageCount,
    pageSize,
    initialPage = 0,
    handlePageClick,
    afterPageChangeRef,
    pageSizes,
    onPageSizeChange,
}) => {
    // some defensive coding because we had NaN passed in, which broke a prop diff in
    // ReactPaginate below, which led to an infinite setState loop.
    if (Number.isNaN(initialPage)) {
        initialPage = 0;
    }

    // neither the Input nor ReactPaginate are controlled components, so using this quick hack
    // to re-mount both whenever either one mutates the page number (even if the number does
    // not change during the mutation).
    const [mutationNumber, setMutationNumber] = useState(0);
    // re-mounting the ReactPaginate loses the page number state. due to the `initialPage` prop
    // only being set initially by this component's parent, we need to keep state for the selected
    // page to restore that when we remount ReactPaginate
    const [selectedPage, setSelectedPage] = useState(initialPage);
    const focusHackRef = useRef<HTMLElement | null>(null);

    // needed for new search page implementation which controls the state of the current page
    // TODO: make this component fully controlled after we eliminate old search page
    useEffect(() => {
        setSelectedPage(initialPage);
    }, [initialPage]);

    const scrollToPaginatorAfterPageChanges = useCallback(() => {
        if (afterPageChangeRef) {
            afterPageChangeRef.current = () => {
                focusHackRef.current?.scrollIntoView();
            };
        }
    }, [afterPageChangeRef]);

    const handlePageNumberClick = useCallback(
        (pageNumber: number) => {
            scrollToPaginatorAfterPageChanges();
            setSelectedPage(pageNumber);
            setMutationNumber(mutationNumber + 1);
            const skip = Math.ceil(pageNumber * pageSize);

            handlePageClick(skip, pageSize);
        },
        [handlePageClick, mutationNumber, pageSize, scrollToPaginatorAfterPageChanges]
    );

    if (pageCount < 1 && !pageSizes) {
        return null;
    }

    return (
        <div className="core-paginator">
            {pageSizes && <PaginatorSize pageSizes={pageSizes} onPageSizeChange={onPageSizeChange} />}
            <ReactPaginate
                key={`paginate-${mutationNumber}`}
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={<li>...</li>}
                breakClassName={'break-me'}
                pageCount={Math.ceil(pageCount)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                forcePage={selectedPage}
                onPageChange={(curPage) => {
                    handlePageNumberClick(curPage.selected);
                }}
                containerClassName={'core-pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
            />
            <PaginatorInput
                key={`input-${mutationNumber}`}
                pageCount={Math.ceil(pageCount)}
                onPageChange={handlePageNumberClick}
            />
            <span ref={focusHackRef} />
        </div>
    );
};
