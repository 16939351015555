//

import { handleActions } from 'redux-actions';

import * as ProfilesActions from '../../actions/profile/ProfilesActions';
import * as UserLoginActions from '../../actions/userlogin';

const initialState = {
    data: [],
    isLoading: false,
};

const profilesReducer = handleActions(
    {
        [UserLoginActions.USER_LOGOUT]: (state, action) => {
            return { ...initialState };
        },

        [ProfilesActions.SEARCH_PROFILES_SUCCESS]: (state, action) => {
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            };
        },

        [ProfilesActions.SEARCH_PROFILES_PENDING]: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },

        [ProfilesActions.SEARCH_PROFILES_FAILED]: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
    },
    initialState
);

export default profilesReducer;
