import { createSafeHTMLString, ISafeHTMLString } from '../../../utils';

export interface StatusMessageTemplates {
    validationErrorListPrefixMessage: ISafeHTMLString;

    validationAdditionalInlineErrorsNoticeMessage: ISafeHTMLString;

    validationOnlyInlineErrorsNoticeMessage: ISafeHTMLString;

    validationWarningListPrefixMessage: ISafeHTMLString;

    validationAdditionalInlineWarningsNoticeMessage: ISafeHTMLString;

    validationOnlyInlineWarningsNoticeMessage: ISafeHTMLString;

    validationSucceededMessage: ISafeHTMLString;

    validatingMessage: ISafeHTMLString;

    loadingMessage: ISafeHTMLString;

    executingDocumentControllersMessage: ISafeHTMLString;
}

export const defaultStatusMessageTemplates: StatusMessageTemplates = {
    validationErrorListPrefixMessage: createSafeHTMLString(
        'Please correct the validation errors in the list below to continue:'
    ),
    validationAdditionalInlineErrorsNoticeMessage: createSafeHTMLString(
        'Please note there are additional inline errors that must also be corrected to continue. Scroll to see those.'
    ),
    validationOnlyInlineErrorsNoticeMessage: createSafeHTMLString(
        'There are inline errors that must be corrected to continue. Scroll to see those.'
    ),
    validationWarningListPrefixMessage: createSafeHTMLString(
        'The validation warnings below may affect document processing:'
    ),
    validationAdditionalInlineWarningsNoticeMessage: createSafeHTMLString(
        'Please note there are additional inline warnings that may affect document processing. Scroll to see those.'
    ),
    validationOnlyInlineWarningsNoticeMessage: createSafeHTMLString(
        'There are inline warnings that may affect document processing. Scroll to see those.'
    ),
    validationSucceededMessage: createSafeHTMLString('Document validation succeeded.'),
    validatingMessage: createSafeHTMLString('Validating Document'),
    loadingMessage: createSafeHTMLString('Loading Document Validators'),
    executingDocumentControllersMessage: createSafeHTMLString('Updating Document'),
};
