import { useQuery } from '@tanstack/react-query';
import { ExecutionService } from '../CustomJSRuleEngineV2';
import { BusinessDocType } from '../../types';

/**
 * Custom hook that fetches the validation failure dictionary based on the provided parameters.
 *
 * @param options - The options object containing the parameters for fetching the validation failure dictionary.
 * @param options.buyerCompanyId - The ID of the buyer company.
 * @param options.businessDocType - The type of the business document.
 * @param options.childCompanyId - The ID of the child company.
 * @returns An object containing the validation failure dictionary and a boolean indicating if the data is still loading.
 */
export function useValidationFailureDictionary({
    buyerCompanyId,
    businessDocType,
    childCompanyId,
}: {
    buyerCompanyId?: number | null;
    businessDocType?: BusinessDocType | null;
    childCompanyId?: number | null;
}) {
    const shouldFetch = buyerCompanyId != null && businessDocType != null;

    const { data: validationFailureDictionary, isLoading } = useQuery(
        ['ValidationFailureDictionary', buyerCompanyId, businessDocType, childCompanyId],
        async () => {
            const vfDictionary = await ExecutionService.DPSValidations.fetchValidationFailureTypes(
                buyerCompanyId!,
                businessDocType!,
                childCompanyId
            );

            return vfDictionary;
        },
        {
            enabled: shouldFetch,
            staleTime: Infinity,
        }
    );

    return {
        validationFailureDictionary,
        isLoading,
    };
}
