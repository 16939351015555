import { Pagination as MuiPagination, PaginationItem, Typography } from '@mui/material';
import { FCNC } from '../FCNC';
import { PaginationClasses } from '@mui/material/Pagination/paginationClasses';

export interface IPaginationProps {
    count: number;
    page: number;
    setPage: (page: number) => void;
    size: 'small' | 'large';
    classes?: Partial<PaginationClasses>;
}

export const Pagination: FCNC<IPaginationProps> = ({ count, page, setPage, size, classes }) => {
    return (
        <MuiPagination
            siblingCount={size === 'small' ? 0 : undefined}
            count={count}
            page={page + 1}
            onChange={(event, value) => setPage(value - 1)}
            color="primary"
            size={size}
            classes={classes}
            data-testid="pagination"
            sx={
                size === 'small'
                    ? { '& .MuiPaginationItem-root': { px: 1 } }
                    : {
                          '& .MuiPaginationItem-root': {
                              px: 3,
                          },
                      }
            }
            renderItem={(item) => (
                <PaginationItem
                    slots={{
                        previous: () => (
                            <Typography variant="button" sx={{ color: 'primary.500' }}>
                                {size === 'small' ? 'Prev' : 'Previous'}
                            </Typography>
                        ),
                        next: () => (
                            <Typography variant="button" sx={{ color: 'primary.500' }}>
                                Next
                            </Typography>
                        ),
                    }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...item}
                />
            )}
        />
    );
};
