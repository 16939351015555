import { api } from '../../utils';
import apiPaths from '../../api/paths';

export const SERVICE_AGREEMENT_CONTENT_SUCCESS = 'SERVICE_AGREEMENT_CONTENT_SUCCESS';
export const SERVICE_AGREEMENT_CONTENT_PENDING = 'SERVICE_AGREEMENT_CONTENT_PENDING';
export const SERVICE_AGREEMENT_CONTENT_FAILED = 'SERVICE_AGREEMENT_CONTENT_FAILED';

/**
 * Service agreement success action
 * @param {Object[]} serviceAgreementContents - response to api
 *
 * @return { Function }
 */
export function serviceAgreementContentSuccessAction(serviceAgreementContents) {
    return (dispatch) => {
        const serviceAgreementContentsData = serviceAgreementContents
            .filter((content) => content !== null)
            .reduce(
                ({ serviceAgreementContentsIds, serviceAgreementContents }, serviceAgreementContent) => ({
                    serviceAgreementContentsIds: [...serviceAgreementContentsIds, serviceAgreementContent.Id],
                    serviceAgreementContents: {
                        ...serviceAgreementContents,
                        [serviceAgreementContent.Id]: serviceAgreementContent,
                    },
                }),
                { serviceAgreementContentsIds: [], serviceAgreementContents: {} }
            );
        dispatch({
            type: SERVICE_AGREEMENT_CONTENT_SUCCESS,
            payload: { ...serviceAgreementContentsData },
        });
    };
}

/**
 * Service agreement error action
 * @param {*} error
 *
 * @return { Function }
 */
export function serviceAgreementContentErrorAction(error) {
    return (dispatch) => {
        dispatch({
            type: SERVICE_AGREEMENT_CONTENT_FAILED,
            payload: error,
        });
        return null;
    };
}

/**
 * Get service agreement
 * @param { number|number[] } serviceAgreementIds
 *
 * @return { Function }
 */
export function getServiceAgreementContent(serviceAgreementIds = []) {
    serviceAgreementIds = [].concat(serviceAgreementIds);

    return (dispatch) => {
        dispatch({ type: SERVICE_AGREEMENT_CONTENT_PENDING, payload: { state: true } });
        Promise.all(
            serviceAgreementIds.map((serviceAgreementId) =>
                api()
                    .get(apiPaths.companyServiceAgreementContent.replace(':id', serviceAgreementId))
                    .then(parseContentResponse, (error) => dispatch(serviceAgreementContentErrorAction(error)))
            )
        )
            .then((agreementsContents) => dispatch(serviceAgreementContentSuccessAction(agreementsContents)))
            .then(() => dispatch({ type: SERVICE_AGREEMENT_CONTENT_PENDING, payload: { state: false } }));
    };
}

function parseContentResponse(response) {
    const { headers, data } = response;
    let boundary = headers['content-type'].match(/boundary="([a-z0-9-]+)"/);
    boundary = boundary && `--${boundary[1]}`;
    const parts = data.split(boundary);
    const jsonPart = parts[1];
    const json = jsonPart.substr(jsonPart.indexOf('\r\n\r\n'));

    try {
        return JSON.parse(json)[0];
    } catch (e) {
        console.warn(e);
        return response;
    }
}
