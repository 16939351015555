import {
    ISearchConfiguration,
    ISearchRequest,
    ISearchResponse,
    ISearchService,
    createUseSearchService,
    createAutocompleteField,
    createDropdownField,
    createTextField,
    createSimpleDropdownOptions,
    createEnumDropdownField,
} from '../../../reusableFeatures';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import { IWorkflowSearchItems } from './types';
import { WorkflowService } from './index';
import { paginate } from '../../../utils';

import { CompanyService } from '../../Company';
import { Typography } from '@mui/material';
import { WorkflowTemplateUI } from '../../BusinessDocTypeSearch';
import { BusinessDocType } from '../../../types';

export class WorkflowSearchService implements ISearchService<IWorkflowSearchItems> {
    private workflowService = new WorkflowService();

    async fetchSearchConfiguration(): Promise<ISearchConfiguration<IWorkflowSearchItems>> {
        const companyService = new CompanyService();
        const allCompanyOptions = await companyService.getAllBuyerCompanyOptions();

        const gridColumns: GridColumns = [
            { field: 'name', headerName: 'Workflow Name', flex: 1, sortable: false },
            {
                field: 'businessDocType',
                headerName: 'Doc Type',
                flex: 1,
                renderCell: (params) => <Typography>{BusinessDocType[params.value]}</Typography>,
                sortable: false,
            },
            {
                field: 'companyId',
                headerName: 'Company',
                minWidth: 200,
                flex: 1,
                renderCell: (params: GridRenderCellParams<number>) => {
                    const comp = allCompanyOptions.find(({ value }) => value === params.value);
                    const companyName = comp?.label ? comp.label : `[${params.value}]`;

                    return params.value ? <Typography>{companyName}</Typography> : null;
                },
                sortable: false,
            },
            {
                field: 'active',
                headerName: 'Active',
                flex: 1,
                renderCell: (params: GridRenderCellParams<boolean>) => (
                    <>{params.value ? <Typography>Yes</Typography> : <Typography>No</Typography>}</>
                ),
                sortable: false,
            },
            {
                field: 'workflowTemplateUI',
                headerName: 'Workflow UI Template',
                flex: 1,
                renderCell: (params) => <Typography>{params.value}</Typography>,
                sortable: false,
            },
        ];

        const searchFormFieldColumns = [
            [
                createTextField({
                    id: 'workflow-name',
                    label: 'Workflow Name',
                    defaultValue: '',
                    dataBindingKey: 'name',
                }),
                createAutocompleteField({
                    id: 'workflow-companyId',
                    label: 'Company',
                    defaultValue: null,
                    dataBindingKey: 'companyId',
                    options: allCompanyOptions,
                }),
                createEnumDropdownField({
                    id: 'workflow-docType',
                    label: 'Doc Type',
                    defaultValue: null,
                    dataBindingKey: 'businessDocType',
                    options: BusinessDocType,
                }),
            ],
            [
                createDropdownField({
                    id: 'workflow-active',
                    label: 'Active',
                    defaultValue: null,
                    dataBindingKey: 'active',
                    options: [
                        { label: 'Yes', value: true },
                        { label: 'No', value: false },
                    ],
                }),
                createDropdownField({
                    id: 'workflow-workflowTemplateUI',
                    label: 'Workflow UI Template',
                    defaultValue: null,
                    dataBindingKey: 'workflowTemplateUI',
                    options: createSimpleDropdownOptions([...WorkflowTemplateUI]),
                }),
            ],
        ];

        return {
            gridColumns,
            enabledExportingMethods: [],
            searchFormFieldColumns,
            pageSizeOptions: [10, 25, 50],
            defaultPageSize: 10,
            searchWithNoCriteria: true,
        };
    }

    async fetchResults(
        _searchConfiguration: ISearchConfiguration<IWorkflowSearchItems>,
        searchRequest: ISearchRequest<IWorkflowSearchItems>
    ): Promise<ISearchResponse> {
        const { searchQuery } = searchRequest;

        const response = await this.workflowService.getWorkflowGrid({
            name: searchQuery.state.name,
            companyId: searchQuery.state.companyId,
            businessDocType: searchQuery.state.businessDocType,
            active: searchQuery.state.active,
            workflowTemplateUI: searchQuery.state.workflowTemplateUI,
        });

        if (!response) {
            return {
                pageResults: [],
                totalResultCount: 0,
            };
        }

        const filtered = [...response];

        return {
            pageResults: paginate(filtered, searchRequest.pageSize, searchRequest.pageNumber),
            totalResultCount: filtered.length,
        };
    }

    exportData(): Promise<void> {
        throw new Error('Exporting not implemented for workflow search');
    }

    get key(): string {
        return 'WorkflowSearch';
    }
}

export const useWorkflowSearchService = createUseSearchService(WorkflowSearchService);
