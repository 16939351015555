import { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { sleep } from '../../../utils/sleep';
import { normalizeRequestUrl } from '../axiosOptimizer/utils';
import { IRequestMatcher, IAxiosMocker } from './types';

export class AxiosMocker implements IAxiosMocker {
    private errorMatchers: IRequestMatcher[] = [];

    public addHandler(errorMatcher: IRequestMatcher) {
        this.errorMatchers.push(errorMatcher);
    }

    public resetHandlers() {
        this.errorMatchers = [];
    }

    public findMatcher(request: AxiosRequestConfig) {
        const url = normalizeRequestUrl(request.url);
        const match = this.errorMatchers.find(({ urlMatcher: m }) => {
            return (
                (typeof m === 'string' && m === url) ||
                (typeof m === 'object' && url?.match(m)) ||
                (typeof m === 'function' && m(url || ''))
            );
        });

        if (!match) {
            return undefined;
        }

        const { method } = match;

        if (method.toLowerCase() !== request.method?.toLowerCase()) {
            return undefined;
        }

        return match;
    }
}

class RespondWithResponse extends Error {
    constructor(readonly request: AxiosRequestConfig, readonly matcher: IRequestMatcher) {
        super('skip request');

        Object.setPrototypeOf(this, RespondWithResponse.prototype);
    }
}

class FakeAxiosError extends Error implements AxiosError {
    public isAxiosError = true;

    constructor(readonly config: AxiosRequestConfig, readonly response: AxiosResponse) {
        super('fake axios error');

        Object.setPrototypeOf(this, FakeAxiosError.prototype);
    }

    // eslint-disable-next-line class-methods-use-this
    toJSON() {
        return {};
    }
}

function extractDynamicResponder(matcher: IRequestMatcher) {
    if ('intercept' in matcher) {
        return matcher.intercept;
    }

    return async () => {
        if (matcher.delay) {
            await sleep(matcher.delay);
        }

        return matcher.fakeResponse;
    };
}

function logMessage(...args: any[]) {
    if (process.env.NODE_ENV === 'test') {
        return;
    }
    // eslint-disable-next-line no-console
    console.log(...args);
}

function logError(...args: any[]) {
    if (process.env.NODE_ENV === 'test') {
        return;
    }

    console.error(...args);
}

export function installAxiosMocker(instance: AxiosInstance, axiosMocker: AxiosMocker) {
    instance.interceptors.request.use((request) => {
        const matcher = axiosMocker.findMatcher(request);

        if (!matcher) {
            return request;
        }

        throw new RespondWithResponse(request, matcher);
    });

    instance.interceptors.response.use(
        (response) => response,
        async (error) => {
            // return cached response when request was skipped due to
            // a response being cached
            if (error instanceof RespondWithResponse) {
                // eslint-disable-next-line no-console
                logMessage('Intercepted API request for testing purposes. The request is', error.request);

                const responder = extractDynamicResponder(error.matcher);

                try {
                    const fakeResponse = await responder(error.request);

                    // eslint-disable-next-line no-console
                    logMessage('The mocked response is', fakeResponse);

                    const axiosResponse = {
                        ...fakeResponse,
                        config: error.request,
                    };

                    if (fakeResponse.status >= 399) {
                        // this won't be caught by the catch statement
                        return Promise.reject(new FakeAxiosError(error.request, axiosResponse));
                    }

                    return axiosResponse;
                } catch (e) {
                    logError('Error ocurred in axiosMocker handler', e);
                    throw new FakeAxiosError(error.request, {
                        data: null,
                        headers: {},
                        status: 500,
                        statusText: 'Internal Server Error',
                        config: error.request,
                    });
                }
            }

            throw error;
        }
    );
}
