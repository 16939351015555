import { Color, Variant } from './types';

export const mapToMuiVariant = (variant: Variant) => {
    if (variant === 'text') {
        return 'text';
    }
    if (variant === 'secondary') {
        return 'outlined';
    }

    return 'contained';
};

export const toMuiColor = (color: Color) => {
    switch (color) {
        case 'primary':
            return 'primary';
        case 'error':
            return 'error';
        case 'warn':
            return 'warning';
        case 'success':
            return 'success';
        default:
            return 'primary';
    }
};
