// DEPRECATED: Do not use
// REFACTOR: This component needs to be rebuilt as part of the document rendering engine rewrite. It should be rebuilt to use the new ui core library.
import { FC, useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { IFormConfig, IAccount, SegmentDataTypes, IAccountValue, IValue } from './types';
import Select from './components/SelectVirtualized';
import { FormNavigator } from '../../Molecules/FormNavigator';
import { Input } from '../../Molecules/InputField';
import { loadConstantDropdown } from './helpers';
import SelectGLAccounts from './components/SelectCompanyGLAccounts';
import SelectCustomData from './components/SelectCustomData';
import './styles.scss';
import { Button } from '../../Atoms';
import { DocumentEditRules } from '../../../../features';

import SplitAmountAndPercent from './components/SplitAmountAndPercents';
import RunningTotals from './components/RunningTotals';

import { Box } from '@mui/material';
import { useDocumentValidatorContext } from '../../../../features/CJSRuleEngineV2ClientWrapper/DocumentValidationContext';

export type Props = {
    companyId: number;
    selectedIndex: number;
    type: 'inline' | 'dot-notation';
    lineNumber: string;
    formConfig: IFormConfig;
    splits: [];
    currencyCode: string;
    currencySymbol: string;
    onAddSplitClick: () => void;
    onPreviousSplitClick: () => void;
    onNextSplitClick: () => void;
    onSegmentChange: (key: string, value: string) => void;
    getAccountList: (id: string | null, depth: string | number) => IAccount;
    onResetAction: () => void;
    formErrors: { type: string; message: string }[];
    lineNetTotal: number;
    remainingAmountMode?: 'allSplits' | 'lastSplitOnly';
    unfocused: boolean;
};

/**
 * @deprecated
 */
export const GLCodingForm: FC<Props> = ({
    selectedIndex,
    type,
    lineNumber,
    formConfig,
    onPreviousSplitClick,
    onNextSplitClick,
    onAddSplitClick,
    onSegmentChange: baseOnSegmentChange,
    splits,
    currencyCode,
    currencySymbol,
    onResetAction,
    formErrors,
    lineNetTotal,
    companyId,
    remainingAmountMode = 'lastSplitOnly',
    unfocused = false,
}) => {
    const [errors, setErrors] = useState(formErrors);
    const [selectedAccount, setSelectedAccount] = useState<IAccountValue>();
    const initialAccountRef = useRef<IAccountValue>();

    const validator = DocumentEditRules.useDocumentEditValidator();
    const documentValidatorV2 = useDocumentValidatorContext();
    const internalRulesValidator = DocumentEditRules.internalRules.useInternalRulesValidatorContext();

    const getFieldHasErrors = DocumentEditRules.useGetFieldHasErrors();
    const getFieldKey = DocumentEditRules.useCreateFieldKey();
    const getAllSplitsFieldKey = DocumentEditRules.useCreateGetGlCodingAllSplitsFieldKey();
    const getAllSplitsOnLineFieldKey = DocumentEditRules.useCreateGetGlCodingAllSplitsOnLineFieldKey();
    const glCodingSectionKey = DocumentEditRules.useGLCodingSectionKey();
    const getGlKeys = (fieldName: string) => [
        glCodingSectionKey,
        getAllSplitsFieldKey(fieldName),
        getAllSplitsOnLineFieldKey(fieldName),
        getFieldKey(fieldName),
    ];
    const getFieldControlState = DocumentEditRules.useCreateGetFieldControlState();

    useEffect(() => {
        setErrors(formErrors);
    }, [formErrors]);

    const validateFieldChange = (segmentKey, newValue) => {
        const segment = formConfig.segments.find((x) => x.key === segmentKey);
        if (!segment) {
            return;
        }
        if (segment.value === newValue) {
            return;
        }

        validator.validateEvent({
            type: 'FocusLossEvent',
            fieldName: segment.Name,
        });

        documentValidatorV2.validateEvent({
            type: 'FocusLossEvent',
            fieldName: segment.Name,
        });

        internalRulesValidator.validateEvent({
            type: 'FocusLossEvent',
            fieldName: segment.Name,
        });
    };

    const onSegmentChange = (segmentKey, value) => {
        baseOnSegmentChange(segmentKey, value);
        validateFieldChange(segmentKey, value);
        validator.validateEvent({
            type: 'FocusLossEvent',
            fieldName: 'glSplit',
        });
        documentValidatorV2.validateEvent({
            type: 'FocusLossEvent',
            fieldName: 'glSplit',
        });

        internalRulesValidator.validateEvent({
            type: 'FocusLossEvent',
            fieldName: 'glSplit',
        });
    };

    const handleOnAccountChange = (account: IAccountValue, segmentKey?: string) => {
        setSelectedAccount(account);
        if (segmentKey) {
            onSegmentChange(segmentKey, account.value);
        }
    };

    const getIsEditable = (fieldName: string) => {
        const { editable } = getFieldControlState(getGlKeys(fieldName));
        return editable;
    };

    const percentIsEditable = getIsEditable('percent');
    const amountIsEditable = getIsEditable('amount');

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                flexGrow: '1',
            }}
        >
            <div className={`core-gl-coding-form ${type}`}>
                {errors && errors.length > 0 && (
                    <ul className="header-errors">
                        {errors
                            .filter((e) => e.type === 'header')
                            .map((errorType) => (
                                <li>{errorType.message}</li>
                            ))}
                    </ul>
                )}
                {type === 'dot-notation' && lineNumber && (
                    <label className="form-segment line-number">
                        Line #{' '}
                        <Input id={'lineNumber'} initialValue={lineNumber} fieldName={'lineNumber'} editable={false} />
                    </label>
                )}

                {type === 'dot-notation' && (
                    <FormNavigator
                        currentForm={selectedIndex + 1}
                        label="Dist. Line#"
                        onPreviousClick={onPreviousSplitClick}
                        onNextClick={onNextSplitClick}
                        onAddClick={onAddSplitClick}
                        showAdd={selectedIndex === splits?.length - 1}
                        previousActionDisabled={selectedIndex === 0}
                    />
                )}
                <div className={`segment-body`}>
                    {formConfig &&
                        formConfig.segments &&
                        formConfig.segments.map((segment, index) => {
                            const { visible, editable } = getFieldControlState(getGlKeys(segment.Name));

                            if (!visible) {
                                return null;
                            }

                            const isDefaultAccount: boolean = (segment.DataType === SegmentDataTypes.CompanyGLAccount &&
                                segment.CompanyGLAccountSegmentName &&
                                segment.CompanyGLAccountDepth &&
                                parseInt(segment.CompanyGLAccountDepth, 10) === 1) as boolean;

                            return (
                                // eslint-disable-next-line
                                <div className="form-segment-wrap" key={`${index}-${segment.Name}`}>
                                    <div
                                        className={clsx('form-segment', {
                                            'form-segment--has-error': getFieldHasErrors(segment.Name),
                                        })}
                                    >
                                        <label>{segment.Label}</label>
                                        {segment.DataType === SegmentDataTypes.Text && (
                                            <>
                                                <Input
                                                    className="gl-input-text"
                                                    id={`input-segment-${segment.key}`}
                                                    testid={`input-segment-${segment.key}`}
                                                    initialValue={segment.value}
                                                    fieldName={segment.key}
                                                    editMode
                                                    editable={editable}
                                                    onBlur={onSegmentChange}
                                                />
                                                {errors &&
                                                    errors.length > 0 &&
                                                    errors
                                                        .filter((e) => e.type === segment.key)
                                                        .map((errorType) => (
                                                            <li className="form-error">{errorType.message}</li>
                                                        ))}
                                            </>
                                        )}
                                        {segment.DataType === SegmentDataTypes.CompanyGLAccount && (
                                            <SelectGLAccounts
                                                companyId={companyId}
                                                segment={segment}
                                                value={segment.value ? { value: segment.value } : null}
                                                segmentKey={segment.key}
                                                segmentName={segment.CompanyGLAccountSegmentName}
                                                depth={segment.CompanyGLAccountDepth}
                                                editMode={editable}
                                                isDefaultAccount={isDefaultAccount}
                                                selectedAccount={selectedAccount}
                                                initialAccountRef={initialAccountRef}
                                                onAccountChange={handleOnAccountChange}
                                                unfocused={unfocused}
                                            />
                                        )}
                                        {segment.DataType === SegmentDataTypes.CompanyCustomData && (
                                            <SelectCustomData
                                                companyId={companyId}
                                                segment={segment}
                                                value={segment.value ? { value: segment.value } : null}
                                                segmentKey={segment.key}
                                                onChange={(value: IValue) => {
                                                    onSegmentChange(segment.key, value.value);
                                                }}
                                                editMode={editable}
                                                unfocused={unfocused}
                                            />
                                        )}
                                        {segment.DataType === SegmentDataTypes.Enum && (
                                            <Select
                                                isClearable={false}
                                                data={loadConstantDropdown(segment.Enums)}
                                                value={segment.value ? { value: segment.value } : null}
                                                onChange={(option: unknown) => {
                                                    const data = option as IValue;
                                                    onSegmentChange(segment.key, data.value);
                                                }}
                                                editMode={editable}
                                            />
                                        )}
                                    </div>
                                    <DocumentEditRules.FieldErrors fieldName={segment.Name} />
                                </div>
                            );
                        })}
                    {onResetAction && (
                        <Button onClick={onResetAction} id={'reset-gl-code-btn'}>
                            Reset GL Codes
                        </Button>
                    )}
                </div>
                {splits?.length > 1 && (
                    <div className="split-info">
                        <SplitAmountAndPercent
                            splitIndex={selectedIndex}
                            splits={splits}
                            total={lineNetTotal}
                            currencySymbol={currencySymbol}
                            remainingAmountMode={remainingAmountMode}
                            currencyCode={currencyCode}
                            onSegmentChange={onSegmentChange}
                            config={{ percentIsEditable, amountIsEditable }}
                            initialValues={{ percent: formConfig.percent, amount: formConfig.amount }}
                            lineNumber={lineNumber}
                        />
                        {(remainingAmountMode === 'allSplits' || selectedIndex + 1 === splits?.length) && (
                            <RunningTotals
                                splits={splits}
                                total={lineNetTotal}
                                currencySymbol={currencySymbol}
                                shortHelperText={remainingAmountMode !== 'allSplits'}
                            />
                        )}
                    </div>
                )}
            </div>
            <DocumentEditRules.FieldErrors fieldName="glSplit" />
        </Box>
    );
};

export default GLCodingForm;

export { prefetchGLSegmentData } from './components/fetchData';
