import { useEffect } from 'react';

export interface IFeatureDialogProps {
    onClose(): void;
}

const featureDialogOverlay = {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
} as const;

const featureDialog = {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    margin: 'auto',
    width: '600px',
    height: '524px',
    background: '#fff',
} as const;

const featureDialogTitle = {
    backgroundColor: '#ec9213',
    color: '#fff',
    fontSize: '16px',
    padding: '2px',
} as const;

const featureDialogClose = {
    position: 'absolute',
    top: '0',
    right: '5px',
    background: 'transparent',
    border: 'none',
    fontSize: '16px',
    color: '#fff',
    cursor: 'pointer',
} as const;

const featureDialogChildren = {
    backgroundColor: '#fff',
    padding: '10px',
    height: '500px',
    overflow: 'auto',
};

export default function FeatureDialog({ children, onClose }: React.PropsWithChildren<IFeatureDialogProps>) {
    useEffect(() => {
        const initial = document.body.style.overflow;

        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = initial;
        };
    }, []);

    return (
        <div style={featureDialogOverlay}>
            <div style={featureDialog}>
                <div style={featureDialogTitle}>Manage Features</div>
                <button style={featureDialogClose} onClick={onClose}>
                    X
                </button>
                <div style={featureDialogChildren}>{children}</div>
            </div>
        </div>
    );
}
