import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { FCNC } from '../FCNC';

export interface IFormButtonsProps {
    leftButtons?: ReactNode;
    rightButtons?: ReactNode;
}

export const FormButtons: FCNC<IFormButtonsProps> = ({ leftButtons, rightButtons }) => {
    return (
        <Box
            sx={{
                px: 4,
                pb: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <Box>{leftButtons}</Box>
            <Box display="flex" gap={1}>
                {rightButtons}
            </Box>
        </Box>
    );
};
