import f, { ODataFilterBuilder } from 'odata-filter-builder';
import { isEmpty, ODataUtils } from '../../../utils';

export function createDashboardRejectionTypeFilter(validationFailureTypeId: number) {
    const filterRules: ODataFilterBuilder[] = [];

    if (!isEmpty(validationFailureTypeId)) {
        filterRules.push(f().eq('ValidationFailureErrorTypeId', validationFailureTypeId));
    }

    return filterRules.length > 0 ? ODataUtils.andConditions(filterRules).toString() : undefined;
}
