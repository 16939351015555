import errorTypes, { IErrorType } from './errorTypes';
import {
    createSafeHTMLString,
    formatSafeHTMLString,
    registerSlackEventName,
    ISafeHTMLString,
    runIfEnabled,
    sendSlackNotification,
} from '../../utils';

export interface IExternalErrorInfo {
    errorCode: ISafeHTMLString;

    errorClass: IErrorType['errorClass'];
}

const errorCodeTemplate = createSafeHTMLString('{{key}}_{{timestamp}}');

const errorTypeExternalKeys = {
    Systems: createSafeHTMLString('S{{ordinal}}'),
    'Frontend Dev': createSafeHTMLString('FD{{ordinal}}'),
    'Backend Dev': createSafeHTMLString('BD{{ordinal}}'),
    'Pro Serv': createSafeHTMLString('PS{{ordinal}}'),
};

Object.keys(errorTypeExternalKeys).forEach((key) => {
    registerSlackEventName(`${key} Error`);
});

export function getExternalErrorKey(errorType: IErrorType) {
    return formatSafeHTMLString(errorTypeExternalKeys[errorType.department], {
        ordinal: errorType.departmentOrdinal,
    });
}

/**
 * Returns ExternalErrorInfo for an internal error key.
 *
 * Calling this will report the error via LogRocket and Slack. Therefore, ensure
 * you only call this once when the error first occurs.
 *
 * @param errorKey the internal error key
 * @param timestamp optional timestamp value. will use current time if not provided.
 * @returns the ExternalErrorInfo object
 */
export function createExternalErrorInfo(errorKey: keyof typeof errorTypes, timestamp?: number): IExternalErrorInfo {
    const usedTimestamp = Math.round((timestamp ?? Date.now()) / 1000);
    const errorType = errorTypes[errorKey];
    const externalErrorKey = getExternalErrorKey(errorType);
    const errorCode = formatSafeHTMLString(errorCodeTemplate, {
        key: externalErrorKey,
        timestamp: usedTimestamp,
    });

    if (errorType.severity === 'High' || errorType.severity === 'Triage') {
        sendSlackNotification(
            `${errorType.department} Error`,
            `An error has occurred on one of our portals that may be impeding a user's ability to use the application.
Error Type: ${errorKey}
Error Code Displayed To User: ${errorCode.value}
Error Details: \`\`\`${JSON.stringify(errorType)}\`\`\``
        );
    }

    runIfEnabled((logRocket) => {
        logRocket.track(errorKey, {
            timestamp: usedTimestamp,
            url: window.location.href,
            pathname: window.location.pathname,
            errorType: JSON.stringify(errorType),
            errorCode: errorCode.value,
        });
    });

    return {
        errorCode,
        errorClass: errorType.errorClass,
    };
}
