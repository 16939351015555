import { RecursivePartial } from '../../../../types';
import { api } from '../../../api';
import { ApiResponse } from '../../Utilities';
import {
    FileViewModel,
    MessageFileUploadResultViewModel,
    MessageOnlyViewModel,
    MessageWebUploadResultViewModel,
} from '../../ViewModels';
import { GetMessageByMessageTypeParams, WebUploadParams, FileUploadParams, IDataUploadBody } from './Params';

export class MessageApi {
    private route = '/Message';

    /**
     * Retrieves messages (data upload history) by message type.
     *
     * @param params.messageType The message type.
     * @param params.companyId The company
     * @param params.$select The fields to select.
     * @param params.$filter The filter to apply.
     * @returns An ApiResponse containing an array of partial MessageOnlyViewModel objects.
     */
    public getMessageByMessageType(
        params: GetMessageByMessageTypeParams
    ): ApiResponse<RecursivePartial<MessageOnlyViewModel>[]> {
        return api().get(`${this.route}`, { params });
    }

    /**
     * Retrieves associated source file for a message (data upload history item).
     *
     * @param id The ID of the message (data upload history item).
     * @returns An ApiResponse containing the file view model.
     */
    public getMessageSourceFile = (id: number): ApiResponse<FileViewModel> => {
        return api().get(`${this.route}/DownloadSourceFile/${id}`);
    };

    /**
     * Posts a file upload for a dataset structure.
     *
     * @param params.companyCustomDatasetStructureId The dataset structure ID.
     * @param file The file to upload.
     * @returns An ApiResponse containing the result of the file upload.
     */
    public postMessageFileUpload = (
        params: FileUploadParams,
        file: File
    ): ApiResponse<MessageFileUploadResultViewModel> => {
        const formData = new FormData();
        formData.append('file', file);

        return api().post(`${this.route}/FileUpload`, formData, {
            params,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    };

    /**
     * Posts a web upload for a dataset structure.
     *
     * @param params.companyCustomDatasetStructureId The ataset structure ID.
     * @param data The table of data to upload.
     * @returns An ApiResponse containing the result of the web upload.
     */
    public postMessageWebUpload = (
        params: WebUploadParams,
        data: IDataUploadBody
    ): ApiResponse<MessageWebUploadResultViewModel> => {
        return api().post(`${this.route}/WebUpload`, data, { params });
    };
}
