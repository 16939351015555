import { validateData } from '../../../ui';
import { IBaseEmailTemplate } from './types/IBaseEmailTemplate';

export type PrimitiveRecord = Record<string, string | boolean | number | null | undefined>;

export interface IEmailTemplate extends IBaseEmailTemplate {
    /** category of email template */
    category: string;

    /** unique name of the email template under category */
    name: string;

    /** metadata for tagging template for a specific feature */
    metadata?: PrimitiveRecord;

    /** returns the initial body for a new email, given parameters to fill in the template */
    body: (params: Record<string, string | boolean | number | null | undefined>) => string;
}

const templates: IEmailTemplate[] = [
    {
        subject: 'DO NOT REPLY: Invoice Validation',
        category: 'ValidateSamples',
        name: 'Default',
        from: '',
        to: 'test@transcepta.com',
        body: () => {
            return 'Example 1';
        },
        metadata: { tradingPartner: 'Buyer 5' },
    },
    {
        subject: 'DO NOT REPLY: Invoice Admin',
        category: 'ValidateSamples',
        name: 'Admin',
        from: 'support@transcepta.com',
        to: 'test@transcepta.com',
        body: () => {
            return 'Example 2';
        },
        metadata: { tradingPartner: 'Buyer 3' },
    },
    {
        subject: 'DO NOT REPLY: Invoice Validation Standard',
        category: 'ValidateSamples',
        name: 'Standard',
        from: 'support@transcepta.com',
        to: 'test@transcepta.com',
        body: () => {
            return 'Example 2';
        },
    },
];

export class EmailTemplateService {
    /**
     * Finds all email templates matching the `category` and whose `metadata.{x}`
     * matches `params.{x}` for each `{x}` in `params`
     *
     * @param category
     * @param params
     * @returns Promise with the email templates
     */
    async getEmailTemplatesForCategory(category: string, params: PrimitiveRecord): Promise<IEmailTemplate[]> {
        const templatesFound = templates.filter(
            (t) => t.category === category && JSON.stringify(t.metadata) === JSON.stringify(params)
        );

        validateData((errors) => {
            if (!templatesFound || templatesFound.length === 0) {
                errors.push(
                    'This buyer does not have any sample requirements configured. Please configure sample requirements before reporting issues with samples for this trading partner'
                );
            }
        });

        return templatesFound;
    }
}
