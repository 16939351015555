import { IField } from '../FieldRenderer/types';
import { dotNotation } from '../FieldRenderer/dotNotation';
import { parseField } from '../FieldRenderer/parseField';
import { isNonNull } from '../../utils';
import { ReactNode } from 'react';

/**
 * Parses attributes that were previously serialized to an array of IField[]
 * @param value attributes that were previously serialized
 * @returns the fields
 */
export function parseAttributes(value: any): IField[] {
    // if value is null, return an empty array without logging an error
    if (!value) {
        return [];
    }

    try {
        // ensure that the data property is an array before trying to do anything else with it
        if (!Array.isArray(value.root.attributes)) {
            throw new Error('Expected "data" property of attributes to be an array');
        }

        const data: any[] = value.root.attributes;

        return data.map(parseField).filter(isNonNull);
    } catch (e) {
        console.error('Error parsing attribute array, returning empty array', e);
        return [];
    }
}

/**
 * Serializes attributes so they can be stored in an "XML" field.
 * @param attributes the attributes to serialize
 * @returns the serialized attributes
 */
export function serializeAttributes(attributes: IField[]) {
    if (attributes.length === 0) {
        return null;
    }

    return { root: { attributes } };
}

export function validateAttributesDotNotation(context: string[], attributes: IField[], errors: ReactNode[]) {
    attributes.forEach((field, i) => {
        if (!field.label) {
            errors.push(`${[...context, `Field ${i + 1}`].join(' / ')} is missing label`);
        } else {
            dotNotation.validateDotNotation(
                [...context, field.label, 'Field Data Binding Key'].join(' / '),
                field.dataBindingKey,
                errors
            );
        }
    });
}
