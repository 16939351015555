import { IScriptingEngineEnvironment } from '../../ScriptingEngineEnvironment';
import { ConfigTouchpoint } from '../../MetadataExtractor/Touchpoint/Config';
import { DocumentValidationTouchpoint } from './DocumentValidationTouchpoint';
import { ExecutionContext } from '../../../../services/backendServices/ViewModels';
import { AxiosInstance } from 'axios';
import { CustomDropdownTouchpoint } from '../CustomDropdown/CustomDropdownTouchpoint';
import DropdownOptionsRepository from '../../../DocumentEditRules/PluginHost/services/dropdownOptionsRepository';
import { UIDocumentControllerTouchPoint } from './UIDocumentControllerTouchPoint';

export class DPSValidationEnvironment implements IScriptingEngineEnvironment {
    config: ConfigTouchpoint;

    customDropdown: CustomDropdownTouchpoint;

    constructor(
        public readonly context: ExecutionContext,
        api: () => AxiosInstance,
        buyerCompanyId: number,
        dropdownRepository: DropdownOptionsRepository | undefined,
        public readonly documentValidation: DocumentValidationTouchpoint,
        public readonly UIDocumentController: UIDocumentControllerTouchPoint,
        public readonly services: IScriptingEngineEnvironment['services'],
        public readonly libraryJS: Record<string, unknown>,
        public readonly parentCompanyId?: number // for multi-company scenarios
    ) {
        this.config = new ConfigTouchpoint();

        this.customDropdown = new CustomDropdownTouchpoint(api, buyerCompanyId, dropdownRepository, parentCompanyId);
    }
}
