import f, { ODataFilterBuilder } from 'odata-filter-builder';
import { isEmpty, ODataUtils } from '../../../utils';
import { BusinessDocType } from '../../../types';

export function createValidationFailureTypesFilter(businessDocType: BusinessDocType) {
    const filterRules: ODataFilterBuilder[] = [];

    if (!isEmpty(businessDocType)) {
        filterRules.push(f().eq('BusinessDocType', businessDocType));
    }

    return filterRules.length > 0 ? ODataUtils.orConditions(filterRules).toString() : undefined;
}
