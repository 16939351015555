import { Cache, LocalStoragePersistor, persistCacheInBackground } from '../../../../utils/cache';
import { FetchOptions } from '../../proservContractTypes';
import { getVersionKey } from '../../../../utils/versionKey';

const fiveMinutes = 5 * 60 * 1000;
const cache = new Cache(-1, fiveMinutes, fiveMinutes);

const versionKey = getVersionKey();
if (versionKey) {
    const persistor = new LocalStoragePersistor('documentEditValidationCache', versionKey);
    persistCacheInBackground(cache, persistor, 1000);
}

export function createCachingService(ruleName: string) {
    return {
        fetch<T>(key: any, fn: () => T | Promise<T>, options?: FetchOptions) {
            const composedKey = { ruleName, key };
            return cache.getOrRecompute(composedKey, fn, options);
        },
    };
}
