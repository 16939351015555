import { pushAlert, clearAlerts } from '../actions/alerts';
import { push } from 'connected-react-router';
import { strings } from '../utils/localization/LocalizationStrings';
import R from '../routes';

export const FETCHING_SIM_DOCUMENT_TYPES = 'FETCHING_SIM_DOCUMENT_TYPES';
export const FETCHED_SIM_DOCUMENT_TYPES = 'FETCHED_SIM_DOCUMENT_TYPES';
export const FAILED_SIM_DOCUMENT_TYPES = 'FAILED_SIM_DOCUMENT_TYPES';
export const SIM_DOCUMENT_TYPES_APPLY_SORTING = 'SIM_DOCUMENT_TYPES_APPLY_SORTING';
export const SIM_DOCUMENT_TYPE_SUPPLIER_REMOVE = 'SIM_DOCUMENT_TYPE_SUPPLIER_REMOVE';
export const SIM_DOCUMENT_TYPE_SUPPLIER_SEARCH_FAILED = 'SIM_DOCUMENT_TYPE_SUPPLIER_SEARCH_FAILED';
export const SIM_DOCUMENT_TYPE_SUPPLIER_ADD = 'SIM_DOCUMENT_TYPE_SUPPLIER_ADD';
export const SIM_DOCUMENT_TYPE_SUPPLIER_SEARCH_FETCHING = 'SIM_DOCUMENT_TYPE_SUPPLIER_SEARCH_FETCHING';
export const SIM_DOCUMENT_TYPE_SUPPLIER_SEARCH_FETCHED = 'SIM_DOCUMENT_TYPE_SUPPLIER_SEARCH_FETCHED';
export const SIM_DOCUMENT_TYPE_SUPPLIER_FETCHING_FAILED = 'SIM_DOCUMENT_TYPE_SUPPLIER_FETCHING_FAILED';
export const SIM_DOCUMENT_TYPE_EXEMPT_SUPPLIER_REMOVE = 'SIM_DOCUMENT_TYPE_EXEMPT_SUPPLIER_REMOVE';
export const SIM_DOCUMENT_TYPE_EXEMPT_SUPPLIER_ADD = 'SIM_DOCUMENT_TYPE_EXEMPT_SUPPLIER_ADD';

export const SIM_DOCUMENT_TYPE_VENDOR_CLASSES_FETCHING = 'SIM_DOCUMENT_TYPE_VENDOR_CLASSES_FETCHING';
export const SIM_DOCUMENT_TYPE_VENDOR_CLASSES_FETCHED = 'SIM_DOCUMENT_TYPE_VENDOR_CLASSES_FETCHED';
export const SIM_DOCUMENT_TYPE_VENDOR_CLASSES_FAILED = 'SIM_DOCUMENT_TYPE_VENDOR_CLASSES_FAILED';

export const SIM_DOCUMENT_TYPE_VENDOR_CLASS_ADD = 'SIM_DOCUMENT_TYPE_VENDOR_CLASS_ADD';
export const SIM_DOCUMENT_TYPE_INITIALIZE = 'SIM_DOCUMENT_TYPE_INITIALIZE';
export const SIM_DOCUMENT_TYPE_UPDATE = 'SIM_DOCUMENT_TYPE_UPDATE';
export const SIM_DOCUMENT_TYPE_FETCHING = 'SIM_DOCUMENT_TYPE_FETCHING';
export const SIM_DOCUMENT_TYPE_FETCHING_FAILED = 'SIM_DOCUMENT_TYPE_FETCHING_FAILED';
export const SIM_DOCUMENT_TYPE_FETCHED = 'SIM_DOCUMENT_TYPE_FETCHED';

export const SIM_DOCUMENT_TYPE_SAVING = 'SIM_DOCUMENT_TYPE_SAVING';
export const SIM_DOCUMENT_TYPE_CLEAR_SELECTION = 'SIM_DOCUMENT_TYPE_CLEAR_SELECTION';
export const SIM_DOCUMENT_TYPE_REMOVE_REMINDER = 'SIM_DOCUMENT_TYPE_REMOVE_REMINDER';

export const SIM_REQUEST_FETCHING = 'SIM_REQUEST_FETCHING';
export const SIM_REQUEST_FETCHING_FAILED = 'SIM_REQUEST_FETCHING_FAILED';
export const SIM_REQUEST_FETCHED = 'SIM_REQUEST_FETCHED';
export const SIM_DOCUMENT_TYPE_REQUESTS_FETCHING = 'SIM_DOCUMENT_TYPE_REQUESTS_FETCHING';
export const SIM_DOCUMENT_TYPE_REQUESTS_FETCHING_FAILED = 'SIM_DOCUMENT_TYPE_REQUESTS_FETCHING_FAILED';
export const SIM_DOCUMENT_TYPE_REQUESTS_FETCHED = 'SIM_DOCUMENT_TYPE_REQUESTS_FETCHED';

export const removeReminderSIMDocumentType = (payload) => (dispatch) =>
    dispatch({ type: SIM_DOCUMENT_TYPE_REMOVE_REMINDER, payload });

export const fetchingSIMDocumentType = () => (dispatch) => dispatch({ type: SIM_DOCUMENT_TYPE_FETCHING });

export const simDocumentTypeFetched = (payload) => (dispatch) => dispatch({ type: SIM_DOCUMENT_TYPE_FETCHED, payload });

export const fetchingSIMDocumentTypeFailed = () => (dispatch) => dispatch({ type: SIM_DOCUMENT_TYPE_FETCHING_FAILED });

export const initializeSIMDocumentType = () => {
    return (dispatch) => {
        dispatch({
            type: SIM_DOCUMENT_TYPE_INITIALIZE,
        });
    };
};

export const fetchingSIMDocumentTypes = () => {
    return (dispatch) => {
        dispatch({
            type: FETCHING_SIM_DOCUMENT_TYPES,
        });
    };
};

export const failedSIMDocumentTypes = () => {
    return (dispatch) => {
        dispatch({
            type: FAILED_SIM_DOCUMENT_TYPES,
        });
    };
};

export const fetchedSIMDocumentTypes = (payload) => {
    return (dispatch) => {
        dispatch({
            type: FETCHED_SIM_DOCUMENT_TYPES,
            payload,
        });
    };
};

export const simDocumentTypesApplySorting = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SIM_DOCUMENT_TYPES_APPLY_SORTING,
            payload,
        });
    };
};

export const removeSelectedSupplier = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SIM_DOCUMENT_TYPE_SUPPLIER_REMOVE,
            payload,
        });
    };
};

export const addSelectedSupplier = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SIM_DOCUMENT_TYPE_SUPPLIER_ADD,
            payload,
        });
    };
};

export const fetchingSuppliers = () => {
    return (dispatch) => {
        dispatch({
            type: SIM_DOCUMENT_TYPE_SUPPLIER_SEARCH_FETCHING,
        });
    };
};

export const fetchedSuppliers = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SIM_DOCUMENT_TYPE_SUPPLIER_SEARCH_FETCHED,
            payload,
        });
    };
};

export const fetchingSuppliersFailed = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SIM_DOCUMENT_TYPE_SUPPLIER_FETCHING_FAILED,
        });
    };
};

export const displayEmailRequiredMessage = (payload) => (dispatch) => {
    dispatch(clearAlerts());
    if (payload) {
        dispatch(
            pushAlert({
                type: 'error',
                text: strings.textSIMDocumentTypeRequiredEmail,
            })
        );
    }
};

export const removeExemptSelectedSupplier = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SIM_DOCUMENT_TYPE_EXEMPT_SUPPLIER_REMOVE,
            payload,
        });
    };
};

export const addExemptSelectedSupplier = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SIM_DOCUMENT_TYPE_EXEMPT_SUPPLIER_ADD,
            payload,
        });
    };
};

export const fetchingVendorClasses = () => {
    return (dispatch) => {
        dispatch({
            type: SIM_DOCUMENT_TYPE_VENDOR_CLASSES_FETCHING,
        });
    };
};

export const fetchedVendorClasses = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SIM_DOCUMENT_TYPE_VENDOR_CLASSES_FETCHED,
            payload,
        });
    };
};

export const failedVendorClasses = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SIM_DOCUMENT_TYPE_VENDOR_CLASSES_FAILED,
            payload,
        });
    };
};

export const addVendorClasses = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SIM_DOCUMENT_TYPE_VENDOR_CLASS_ADD,
            payload,
        });
    };
};

export const fetchingSIMDocumentRequests = () => {
    return (dispatch) => {
        dispatch({
            type: SIM_DOCUMENT_TYPE_REQUESTS_FETCHING,
        });
    };
};

export const fetchedSIMDocumentRequests = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SIM_DOCUMENT_TYPE_REQUESTS_FETCHED,
            payload,
        });
    };
};

export const fetchingSIMDocumentRequestsFailed = () => {
    return (dispatch) => {
        dispatch({
            type: SIM_DOCUMENT_TYPE_REQUESTS_FETCHING_FAILED,
        });
    };
};

export const navigateToSIMDocumentSelection = () => {
    return (dispatch) => {
        //console.log(R.DOCUMENT_CREATION_SIM_DOCUMENT_REQUEST_SELECTION.path);
        dispatch(push(R.DOCUMENT_CREATION_SIM_DOCUMENT_REQUEST_SELECTION.path));
    };
};

export const updateSIMDocumentType = (fieldName, value) => (dispatch) =>
    dispatch({ type: SIM_DOCUMENT_TYPE_UPDATE, payload: { fieldName, value } });

export const clearSelectionSIMDocumentType = () => (dispatch) => dispatch({ type: SIM_DOCUMENT_TYPE_CLEAR_SELECTION });

export const fetchingSIMRequest = (payload) => (dispatch) =>
    dispatch({
        type: SIM_REQUEST_FETCHING,
        payload,
    });

export const fetchingSIMRequestFailed = () => (dispatch) =>
    dispatch({
        type: SIM_REQUEST_FETCHING_FAILED,
    });

export const SIMRequestFetched = (payload) => (dispatch) =>
    dispatch({
        type: SIM_REQUEST_FETCHED,
        payload,
    });
