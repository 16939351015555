import { BusinessDocType } from '../../types';
import { backendServices } from '../../services';

export const subNavPaths = {
    dashboard: [
        { path: '/BuyerPortal/dashboard/overview', name: 'Overview' },
        { path: '/BuyerPortal/dashboard/straight-through-processing', name: 'Straight-Through Processing' },
        { path: '/BuyerPortal/dashboard/suppliers', name: 'Suppliers' },
        { path: '/BuyerPortal/dashboard/invoices', name: 'Invoices' },
        { path: '/BuyerPortal/dashboard/spend', name: 'Spend' },
    ],
    transactions: [
        { path: '/BuyerPortal/transactions/asns', name: 'ASNs', docType: BusinessDocType.ASNGeneric },
        {
            path: '/BuyerPortal/transactions/inventory',
            name: 'Inventory Advices',
            docType: BusinessDocType.InventoryAdvice,
        },
        { path: '/BuyerPortal/transactions/invoices', name: 'Invoices', docType: BusinessDocType.Invoice },
        {
            path: '/BuyerPortal/transactions/acklns',
            name: 'PO Acknowledgments',
            docType: BusinessDocType.PurchaseOrderAcknowledgement,
        },
        { path: '/BuyerPortal/transactions/pos', name: 'Purchase Orders', docType: BusinessDocType.PurchaseOrder },
        {
            path: '/BuyerPortal/transactions/consumption',
            name: 'Consumption Advices',
            docType: BusinessDocType.ConsumptionAdvice,
        },
        {
            path: '/BuyerPortal/transactions/receiving',
            name: 'Receiving Advices',
            docType: BusinessDocType.ReceivingAdvice,
        },
        {
            path: '/BuyerPortal/transactions/sim-documents',
            name: 'SIM Documents',
            docType: BusinessDocType.SIMDocument,
        },
    ],
    'parking-lot': [
        { path: 'all-workflows', name: 'All Workflows', workflowTemplateUI: 'All Workflows' },
        { path: 'invoice-reconcile', name: 'Nonstock Invoices', workflowTemplateUI: 'Invoice Reconcile' },
        { path: 'counter-pos', name: 'Counter POS', workflowTemplateUI: 'Counter POs' },
        { path: 'invoice-coding', name: 'Invoice Coding', workflowTemplateUI: 'Invoice Coding' },
        { path: 'document-approval', name: 'Document Approval', workflowTemplateUI: 'Document Approval' },
        { path: 'non-inventory', name: 'GL Coding', workflowTemplateUI: 'Non-Inventory' },
        { path: 'non-inventory-release', name: 'GL Coding Release', workflowTemplateUI: 'Non-Inventory Rel.' },
        { path: 'parked-documents', name: 'Parked Documents', workflowTemplateUI: 'Parked Documents' },
        { path: 'invoice-approval', name: 'PO Line Nums', workflowTemplateUI: 'Invoice Approval' },
        {
            path: 'create',
            name: 'New Document',
            workflowTemplateUI: 'Document Approval',
            showLeftNavInParkingLot: false,
        },
    ],
    'dynamic-discounting': [
        { path: '/BuyerPortal/dynamic-discounting/new', name: 'Create Campaign' },
        { path: '/BuyerPortal/dynamic-discounting/reports', name: 'Reporting' },
        { path: '/BuyerPortal/dynamic-discounting/users', name: 'Manage Users' },
        { path: '/BuyerPortal/dynamic-discounting/settings', name: 'Settings' },
    ],
    documents: [
        { path: '/SupplierPortal/documents/asns', name: 'ASNs', docType: BusinessDocType.ASNGeneric },
        {
            path: '/SupplierPortal/documents/consumption-advices',
            name: 'Consumption Advices',
            docType: BusinessDocType.ConsumptionAdvice,
        },
        {
            path: '/SupplierPortal/documents/inventory-advices',
            name: 'Inventory Advices',
            docType: BusinessDocType.InventoryAdvice,
        },
        { path: '/SupplierPortal/documents/invoices', name: 'Invoices', docType: BusinessDocType.Invoice },
        {
            path: '/SupplierPortal/documents/purchase-orders',
            name: 'Purchase Orders',
            docType: BusinessDocType.PurchaseOrder,
        },
        {
            path: '/SupplierPortal/documents/po-acknowledgments',
            name: 'PO Acknowledgments',
            docType: BusinessDocType.PurchaseOrderAcknowledgement,
        },
        {
            path: '/SupplierPortal/documents/receiving-advices',
            name: 'Receiving Advices',
            docType: BusinessDocType.ReceivingAdvice,
        },
        {
            path: '/SupplierPortal/documents/sim-documents',
            name: 'SIM Documents',
            docType: BusinessDocType.SIMDocument,
        },
    ],
};

export const parseBusinessDocumentVisibility = (menuSettings: any, docType: BusinessDocType) =>
    !!menuSettings?.BusinessDocEnables?.find((item: any) => item.BusinessDocType === docType);

function getParkingLotMenuItems({
    currentCompany,
    menuSettings: { ChildCompanies, WorkflowsEnabled },
    includeSectionHeaders,
}: {
    currentCompany?: backendServices.ViewModels.CompanyViewModel;
    menuSettings: Pick<backendServices.ViewModels.BuyerPortalSettingsViewModel, 'WorkflowsEnabled' | 'ChildCompanies'>;
    includeSectionHeaders: boolean;
}) {
    const allCompanies: { name: string; id: number }[] =
        ChildCompanies?.map((c) => ({
            name: c.Name,
            id: c.Id,
        })) ?? [];

    if (currentCompany) {
        allCompanies.unshift({
            name: currentCompany.Name,
            id: currentCompany.ID,
        });
    }

    allCompanies.sort((a, b) => a.name.localeCompare(b.name));

    const multiCompanyIsAvailable = (ChildCompanies?.length ?? 0) > 0;

    const sortedWorkflows = WorkflowsEnabled.sort((a, b) => a.ShortName.localeCompare(b.ShortName));

    let workflowsSortedByCompany: Array<
        | typeof sortedWorkflows[number]
        | {
              isSectionHeader: true;
              label: string;
              companyId: number;
          }
    >[] = [];

    if (multiCompanyIsAvailable) {
        const workflowMap = new Map<number, typeof sortedWorkflows>();

        sortedWorkflows.forEach((workflow) => {
            const key = workflow.CompanyId;

            const registeredWorkflows = workflowMap.get(key);

            if (!registeredWorkflows) {
                workflowMap.set(key, [workflow]);
                return;
            }

            registeredWorkflows.push(workflow);
            workflowMap.set(key, registeredWorkflows);
        });

        workflowsSortedByCompany = allCompanies.map((c) => {
            const companyWorkflows = workflowMap.get(c.id);

            if (!companyWorkflows) {
                return [];
            }

            if (includeSectionHeaders) {
                return [
                    {
                        isSectionHeader: true,
                        label: c.name,
                        companyId: c.id,
                    },
                    ...companyWorkflows,
                ];
            }

            return companyWorkflows;
        });
    }

    let totalAcrossCompanies = 0;

    const itemGroups = (
        includeSectionHeaders && allCompanies.length > 1 ? workflowsSortedByCompany : [sortedWorkflows]
    ).map((workflowGroup) =>
        workflowGroup
            .map((item) => {
                if ('isSectionHeader' in item && item.isSectionHeader) {
                    return {
                        name: item.label,
                        count: 0,
                        isSectionHeader: true,
                        companyId: item.companyId,
                        path: '',
                    };
                }

                if (!('WorkflowTemplateUI' in item) || !item.WorkflowTemplateUI) {
                    return undefined;
                }

                const path = subNavPaths['parking-lot'].find(
                    (subItem) => subItem.workflowTemplateUI === item.WorkflowTemplateUI
                );

                totalAcrossCompanies += item.DocumentCount;

                let companyName: string | undefined = undefined;
                if (multiCompanyIsAvailable && item.CompanyId) {
                    companyName = allCompanies.find((c) => c.id === item.CompanyId)?.name;
                }

                return {
                    name: item.ShortName,
                    path: `/BuyerPortal/parking-lot/${item.Id}/${path?.path}`,
                    count: item.DocumentCount,
                    companyName: companyName,
                };
            })
            .filter((item): item is NonNullable<typeof item> => item !== undefined)
    );

    const groupsWithAllWorkflows = itemGroups.map((items) => {
        if (itemGroups.length < 2) {
            return items;
        }

        const companyTotal = items.reduce((acc, i) => acc + (i.count || 0), 0);

        if (includeSectionHeaders && items.length > 2) {
            // sectionHeader should always be the first item at this point
            const [sectionHeader, ...workflows] = items;

            return [
                sectionHeader,
                {
                    name: 'All Workflows',
                    path: `/BuyerPortal/parking-lot/erp/${sectionHeader.companyId}/all-workflows`,
                    count: companyTotal,
                    companyName: undefined,
                },
                ...workflows,
            ];
        }

        return items;
    });

    const allItems = groupsWithAllWorkflows.flat();

    if (allItems.length > 1 || !allItems.length) {
        allItems.unshift({
            name: 'All Workflows',
            path: '/BuyerPortal/parking-lot/all-workflows',
            count: totalAcrossCompanies,
            companyName: undefined,
        });
    }

    return allItems;
}

export const menuVisibilityMapper = (
    menuSettings: any,
    isDashboardEnabled?: boolean,
    forcedPathName?: string,
    currentCompany?: backendServices.ViewModels.CompanyViewModel,
    includeSectionHeaders: boolean = false
) => {
    const pathname = forcedPathName ? forcedPathName : window.location.pathname;

    // ! This is a temporary workaround to avoid the horizontal overflow when viewing a document while the left nav is displayed
    const lastSegment = pathname.split('/').at(-1);
    const lastSegmentIsDocId = typeof Number(lastSegment) === 'number' && !isNaN(Number(lastSegment));
    const isNewParkingLotDocumentCreator = !!subNavPaths['parking-lot'].find(
        (subItem) => lastSegment === subItem.path && subItem.showLeftNavInParkingLot === false
    );

    if (pathname.includes('dashboard') && isDashboardEnabled) {
        return subNavPaths.dashboard;
    }

    if (pathname.includes('transactions') && !lastSegmentIsDocId) {
        return subNavPaths.transactions.filter((item) => parseBusinessDocumentVisibility(menuSettings, item.docType));
    }

    if (
        pathname.includes('parking-lot') &&
        !pathname.includes('settings') &&
        !lastSegmentIsDocId &&
        !isNewParkingLotDocumentCreator
    ) {
        const items = getParkingLotMenuItems({ currentCompany, menuSettings, includeSectionHeaders });
        return items;
    }

    if (pathname.includes('dynamic-discounting')) {
        return subNavPaths['dynamic-discounting'];
    }

    // We need to check for supplier portal because otherwise these items would be added when visiting `/BuyerPortal/parking-lot/${workflowId}/parked-documents/${documentId}`.
    // This will only happen on the parking lot workflow.
    if (pathname.includes('documents') && pathname.includes('SupplierPortal')) {
        return subNavPaths.documents.filter((item) => parseBusinessDocumentVisibility(menuSettings, item.docType));
    }

    return null;
};

export const disableLeftNavLink = (pathname: string) => {
    return [
        '/BuyerPortal/transactions/invoices/DeliveredInvoices',
        '/BuyerPortal/transactions/invoices/RejectedInvoices',
    ].includes(pathname);
};
