import { FC, useMemo } from 'react';
import { CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import { darkTheme, lightTheme } from './themes';

interface ITransceptaThemeProviderProps {
    enableDarkMode?: boolean;
}

export const TransceptaThemeProvider: FC<ITransceptaThemeProviderProps> = ({ enableDarkMode, children }) => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = useMemo(
        () => (enableDarkMode ? (prefersDarkMode ? darkTheme : lightTheme) : lightTheme),
        [enableDarkMode, prefersDarkMode]
    );

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};
