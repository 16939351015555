import * as contactActions from '../actions/contact';

export const initialState = {
    Name: null,
    Email: null,
    Phone: null,
};

export function contact(state = initialState, action) {
    switch (action.type) {
        case contactActions.UPDATE_CONTACT:
            return {
                ...state,
                Name: action.payload,
            };
        case contactActions.UPDATE_EMAIL:
            return {
                ...state,
                Email: action.payload,
            };
        case contactActions.UPDATE_PHONE:
            return {
                ...state,
                Phone: action.payload,
            };
        case contactActions.CLEAR_EMAIL_STATE:
            return {
                ...initialState,
            };
        default:
            return state;
    }
}
