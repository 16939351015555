export type ProfileViewModel = {
    Id: number;
    CompanyID: number;
    ProfileType: number;
    Name: string;
    Address1: string;
    Address2: string;
    Address3: string;
    City: string;
    Currency: number;
    State: string;
    PostalCode: string;
    Country: string;
    Phone: string;
    Fax: string;
    Email: string;
    InvoiceConfirmationEmail: string;
    RemittanceAdviceEmail: string;
    RemitToAddressBlock: string | null;
    Signature: string;
    Currency_US: string;
    VatRegistrationNumber: string;
    CompanyVatRegistrationNumber: string;
    APEMail: string;
    Rowversion: number[];
    RuleStatus: number;
    RuleStatus_US: string;
    LogoPath: string;
    PODeliveryMethodStatus_US: string;
    InvoiceProfileType: number;
    InvoiceProfileType_US: string;
    VATComplianceRequired: boolean;
    SignVATInvoices: boolean;
    VATNumber: string;
    CountryOfEstablishment: string;
    ProfileDeliveryMethods: ProfileDeliveryMethodViewModel[];
    ProfileExtendedDatas: ProfileExtendedDataViewModel[];
    ScanKeyProfileId: number | null;
    RuleConfigurationName?: string | null;
    PODeliveryMethodStatus?: number | null;
    DeliveryMethodIsEditable?: boolean;
};

export interface ProfileDeliveryMethodViewModel {
    ID: number;
    ProfileId: number;
    DeliveryMethod: number;
    Rowversion: number[];
    LastUpdateDate: string;
    IsEnabled: boolean;
    TestDelivery: boolean;
    ProcessingMode: number;
    ProcessingMode_US: string;
    EmailAddress: string;
    FaxNumber: string;
    PostRecipient: string;
    AddressLine1: string;
    AddressLine2: string;
    AddressLine3: string;
    City: string;
    PostalCode: string;
    Country: string;
    State: string;
    Uri: string;
    WhenUsed: number;
    Name: string;
    Settings: string;
    GroupBy: number;
    GroupReleaseSchedule: number;
    GroupBy_US: string;
    NoVATDelivery: boolean;
    WhenUsed_US: string;
    CombinedCustomZipFileNamePattern: string;
    SeparateCustomZipFileNamePattern: string;
    ZipFileCounter: number;
    ZipFileMaxDigits: number;
    BusinessDocType: number;
    BusinessDocType_US: string;
    DeliveryMethod_US: string;
    ProfileDeliveryFileTypes: ProfileDeliveryFileTypeViewModel[];
}

export type ProfileDeliveryFileTypeViewModel = {
    ID: number;
    DeliveryMethodId: number;
    FileType: number;
    GroupingMethod: number;
    Rowversion: number[];
    LastUpdateDate: string;
    Counter: number;
    MaxDigits: number;
    CombinedCustomFileNamePattern: string;
    SeparateCustomFileNamePattern: string;
    GroupingMethod_US: string;
    IsEnabled: boolean;
    Xslt: string;
    Settings: string;
    FileSubType: string;
    FileType_US: string;
};

export type ProfileExtendedDataViewModel = {
    ID?: number;
    ProfileId?: number;
    Name: string;
    Value: string;
    Description: string;
    Label: string;
    FieldType?: number;
    VisibleToSupplier?: boolean;
    Rowversion?: string;
    LastUpdateDate?: string;
};

export enum ProfileType {
    Undefined,
    Invoice,
    BuyerDestination,
    Procurement,
    Sales,
    Unknown,
    SupplierWarehouse,
    BuyerWarehouse,
}
