import { FC } from 'react';
import { Typography, Link as MuiLink, Stack } from '@mui/material';
import { Link } from '../Link';

interface IProps {
    goToText: string;
    goToRoute: string;
    testId?: string;
}

export const PageNotFound: FC<IProps> = ({ goToText, goToRoute, testId }) => {
    return (
        <Stack data-testid={testId} justifyContent="center" alignItems="center" sx={{ width: '100%', height: '90vh' }}>
            <Typography align="center" component="h2" variant="h1">
                404
            </Typography>
            <Typography align="center" component="h3" variant="h2" gutterBottom>
                Ooops!! Page Not Found
            </Typography>
            <Typography component="p" variant="body1">
                We couldn&apos;t find the page you were looking for. Please try finding the page via the links on the
                menu above.
            </Typography>
            <Typography component="p" variant="body1" gutterBottom>
                If you believe you should not be seeing this message, &nbsp;
                <MuiLink href="https://support.transcepta.com" target="_blank">
                    please click here to create a support case.
                </MuiLink>
            </Typography>
            <Typography align="center" component="p" variant="body1">
                <Link to={goToRoute}>{goToText}</Link>
            </Typography>
        </Stack>
    );
};
