import { AxiosResponse } from 'axios';
import { ErrorMessages } from '../../../transcepta-types';

export interface ICustomResponse {
    isSuccess: boolean;
    errorMessage?: string;
}

export interface IAxiosResponse {
    type: string;
    text: any;
}

export default class ResponseBuilder {
    customResponse: ICustomResponse = {
        isSuccess: true,
    };

    axiosResponse: AxiosResponse<any> | IAxiosResponse;

    dispatch: any;

    dispatchErrorType?: string;

    dispatchSuccessType?: string;

    constructor(axiosResponse: AxiosResponse | IAxiosResponse, dispatch: any) {
        this.axiosResponse = axiosResponse;
        this.dispatch = dispatch;
    }

    withDispatchWhenError(type: string): ResponseBuilder {
        this.dispatchErrorType = type;
        return this;
    }

    withDispatchWhenSuccess(type: string): ResponseBuilder {
        this.dispatchSuccessType = type;
        return this;
    }

    buildAsync = async (): Promise<ICustomResponse> => {
        const { type } = this.axiosResponse as IAxiosResponse;

        if ((this.dispatchErrorType || this.dispatchSuccessType) && !this.dispatch) {
            throw 'Missing dispatch';
        }

        if (type === 'error') {
            const {
                text: {
                    response: { data },
                },
            } = this.axiosResponse as IAxiosResponse;
            const { errors } = data;
            const message = ErrorMessages[errors[0]?.Code] ?? errors[0]?.Message;

            this.customResponse = {
                isSuccess: false,
                errorMessage: message,
            };

            if (this.dispatchErrorType) {
                await this.dispatch({ type: this.dispatchErrorType, payload: message });
            }

            return this.customResponse;
        }

        if (this.dispatchSuccessType) {
            await this.dispatch({ type: this.dispatchSuccessType });
        }

        return this.customResponse;
    };
}
