const persistedCookies = new Set<string>();

/**
 * Prevents a cookie with a particular name from being deleted
 * when resetting application state.
 * @param name name of the cookie to not reset
 */
export function doNotClearCookieOnReset(name: string) {
    persistedCookies.add(name);
}

function deleteAllCookies() {
    const cookies = document.cookie.split(';');

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

        // eslint-disable-next-line no-continue
        if (persistedCookies.has(name.trim())) {
            continue;
        }

        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
}

/**
 * Resets all application state in a last-ditch effort to
 * restore functionality for the user.
 */
export default function resetState(redirectLocation = '/') {
    window.localStorage.clear();
    window.sessionStorage.clear();
    deleteAllCookies();
    window.location.href = redirectLocation;
}
