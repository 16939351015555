import { IDocumentViewModel } from '../../services/Document/Api';
import { factory } from './DocumentAmountsViewModelFactory';

export function isBusinessDocTypeSupported(document: IDocumentViewModel) {
    try {
        factory.createDocumentAmountsViewModel(document);
        return true;
    } catch (e) {
        return false;
    }
}
