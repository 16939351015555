import { IField } from './fields';
import { IFieldRendererViewModel } from './IFieldRendererViewModel';

/**
 * Implements the IFieldRendererViewModel for a simple key-value mapping object.
 */
export class SimpleFieldRendererViewModel<T extends object> implements IFieldRendererViewModel {
    constructor(public readonly state: T) {}

    getValue<Field extends IField>(field: Field): Field['defaultValue'] {
        return this.getValueWithKey<Field['defaultValue']>(field.dataBindingKey) ?? field.defaultValue;
    }

    getValueWithKey<U>(dataBindingKey: string): U | undefined {
        return (this.state as any)[dataBindingKey];
    }

    setValue<Field extends IField>(field: Field, value: Field['defaultValue'] | undefined) {
        return this.setValueWithKey(field.dataBindingKey, value);
    }

    setValueWithKey<U>(dataBindingKey: string, value: U): this {
        return new SimpleFieldRendererViewModel<T>({
            ...this.state,
            [dataBindingKey]: value,
        }) as this;
    }

    isOverridingComputedValue(): boolean {
        return false;
    }

    isUsingComputedValue(): boolean {
        return false;
    }
}
