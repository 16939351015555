enum DocumentStatus {
    Inserted = 1,
    ReadyToProcess = 2,
    ReadyToDispatch = 3,
    ProcessingComplete = 4,
    Research = 5,
    Canceled = 6,
    HoldingForScheduledRelease = 8,
    HoldingForDeliveryInformation = 9,
    PartiallyCanceled = 10,
    DoNotDeliver = 11,
    Failed = 12,
    CustomerStatusVerified = 13,
    HoldingForDuplicateVATInvoice = 14,
    VATComplianceVerified = 15,
    Released = 16,
    HoldingForDeliveryAddress = 17,
    HoldingForAssignmentScriptExecution = 18,
    AssignmentScriptExecutionComplete = 19,
    HoldingForRoutingScriptExecution = 20,
    RoutingScriptExecutionComplete = 21,
    HoldingForValidationScriptExecution = 22,
    ValidationScriptExecutionComplete = 23,
    HoldingForHoldScriptExecution = 24,
    HoldScriptExecutionComplete = 25,
    BuyerHold = 26,
    Parked = 27,
    HoldingForVATClearance = 28,
    HoldingForVATBuyerResponse = 29,
    HoldingForGoogleMaps = 30,
    CanceledForPartialReprocess = 31,
    HoldingForWorkflow = 32,
    WorkflowExecutionComplete = 33,
}

export default DocumentStatus;
