// DEPRECATED: Do not use
// REFACTOR: Replace all uses with uses of DataGrid
import { FC, useRef, useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { GridHeader } from '../../Molecules/GridHeader/GridHeader';
import { DataRow } from '../../Molecules/DataRow/DataRow';
import { useIsDesktop } from '../../CommonResources/hooks/useWindowSize';
import './Grid.scss';
import { Paginator } from '../../Molecules/Paginator';

export interface IGridProps {
    entityName: string;
    gridConfig: any;
    records: any;
    recordsCount: number;

    emptyGridMessage: string;
    pageCount: number;
    handlePageClick: () => void;
    pageSize: number;
    sortColumn?: string;
    sortDirection?: string;
    initialPage?: number;
    lastClickedRowId?: any;
    rowKey?: string;
    columnsLimit?: number;

    handleRowClick?: (id?: any) => void;
    handleOnSort?: (id?: any) => void;
    handleOnPageSize?: (size: number) => void;
    pageSizes?: number[];
}

/**
 * @deprecated
 */
export const Grid: FC<IGridProps> = (props) => {
    const { gridConfig = [] } = props;
    const theme = useTheme();
    const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));
    const isDesktop = useIsDesktop();
    const desktopColumnLimitReached = gridConfig.length > (props.columnsLimit ?? 10) ? isLgUp : true;
    const afterPageChange = useRef<(() => void) | undefined>();

    useEffect(() => {
        if (afterPageChange.current) {
            afterPageChange.current();
            // don't want to call this if next record change is due to something
            // other than page change (like different search, different sort, etc).
            afterPageChange.current = undefined;
        }
    }, [props.records]);

    return (
        <div className="core-grid">
            <GridHeader
                columns={gridConfig}
                handleSort={props.handleOnSort}
                isDesktop={isDesktop ? desktopColumnLimitReached : false}
                sortColumn={props.sortColumn}
                sortDirection={props.sortDirection}
                records={props.records}
            />
            <div className="body">
                {props.records?.map((record: any, index: number) => (
                    <DataRow
                        // TODO:
                        // key should be the id of the record, not all records have id's yet though
                        // This should be improved when moving the MuiDataGrid where id's required
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        record={record}
                        config={gridConfig}
                        isDesktop={isDesktop ? desktopColumnLimitReached : false}
                        onRowClick={props.handleRowClick}
                        isLastClicked={record[props.rowKey ?? ''] === (props.lastClickedRowId ?? -1)}
                    />
                ))}
                {props.records?.length === 0 && <div className="empty-state">{props.emptyGridMessage}</div>}
            </div>
            {(props.pageCount > 1 || !!props.pageSizes) && (
                <div className="paginator">
                    <Paginator
                        initialPage={props.initialPage}
                        pageSize={props.pageSize}
                        pageCount={props.pageCount}
                        handlePageClick={props.handlePageClick}
                        afterPageChangeRef={afterPageChange}
                        pageSizes={props.pageSizes}
                        onPageSizeChange={props.handleOnPageSize}
                    />
                </div>
            )}
        </div>
    );
};
