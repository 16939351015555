const FILTER_PREFIX = 'SearchPageTemplate/Filter/';

const getKey = (pathname: string) => `${FILTER_PREFIX}${pathname}`;

function setFiltersByPathname(pathname: string, filter: string) {
    const key = getKey(pathname);

    sessionStorage.setItem(key, filter);
}

function getFiltersByPathname(pathname: string) {
    const key = getKey(pathname);

    return sessionStorage.getItem(key);
}

function deleteAllFiltersFromSessionStorage() {
    const filteredKeys = Object.keys(sessionStorage).filter((key) => key.startsWith(FILTER_PREFIX));

    filteredKeys.forEach((key) => sessionStorage.removeItem(key));
}

export const filterStorageUtils = {
    setFiltersByPathname,
    getFiltersByPathname,
    deleteAllFiltersFromSessionStorage,
};
