import { push } from 'connected-react-router';
import qs from 'qs';
import { ResponseBuilder } from 'common/build/legacy/transcepta-common';
import { getPreservedRoute, isInternalLogin, logSessionInformation, portalUserService, isUserLoggedIn } from 'common';
import { UserThunk } from 'common/build/legacy/transcepta-thunks';
import { api, outerAPI } from '../utils';
import apiPaths from '../api/paths';
import R from '../routes';
import { apiErrorAction } from './error';
import { getNoticePage } from './noticePage';
import pathsConfig from '../paths.config.json';
import * as ApplicationThunk from '../thunk/ApplicationThunk';
import * as CommonThunk from '../thunk/CommonThunk';
import { strings } from '../utils/localization/LocalizationStrings';
import * as userThunk from '../thunk/UserThunk';
import { cleanupLoginNotifications } from '../thunk/UserNotificationThunk';

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_PENDING = 'USER_LOGIN_PENDING';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_PROFILE_UPDATED = 'USER_PROFILE_UPDATED';
export const USER_PROFILE_UPDATE_FAILED = 'USER_PROFILE_UPDATE_FAILED';

export const USER_PASSWORD_RECOVER_PENDING = 'USER_PASSWORD_RECOVER_PENDING';
export const USER_PASSWORD_RECOVER_FAILED = 'USER_PASSWORD_RECOVER_FAILED';
export const USER_PASSWORD_RECOVER_SUCCESS = 'USER_PASSWORD_RECOVER_SUCCESS';
export const USER_PASSWORD_RECOVER_RESET = 'USER_PASSWORD_RECOVER_RESET';

export const USER_CURRENCY_FETCHED = 'USER_CURRENCY_FETCHED';
export const USER_MENUSETTINGS_FETCHED = 'USER_MENUSETTINGS_FETCHED';
export const USER_LOGIN_TIMEOUT = 'USER_LOGIN_TIMEOUT';

function requestCurrentUserProfileErrorAction(response) {
    return {
        type: USER_PROFILE_UPDATE_FAILED,
    };
}

function requestCurrentUserProfileSuccessAction(response) {
    if (response.data && response.data[0]) {
        const profileData = portalUserService.assignAllRolesToInternalUser(response.data[0]);

        return {
            type: USER_PROFILE_UPDATED,
            payload: profileData,
        };
    }

    return {
        type: USER_PROFILE_UPDATE_FAILED,
    };
}

export function requestCurrentUserProfile() {
    return (dispatch, getState) => {
        const { ID } = getState().userlogin;
        return api()
            .get(`${apiPaths.readUser}${ID}`)
            .then(
                (res) => {
                    dispatch(requestCurrentUserProfileSuccessAction(res));
                    return portalUserService.assignAllRolesToInternalUser(res.data[0]);
                },
                (err) => {
                    dispatch(requestCurrentUserProfileErrorAction(err));
                    return null;
                }
            );
    };
}

export function fetchUserCurrencies() {
    return (dispatch) => {
        api()
            .get(apiPaths.currencyList)
            .then(
                ({ data: payload }) => {
                    dispatch({ type: USER_CURRENCY_FETCHED, payload });
                },
                (err) => dispatch(apiErrorAction(err, USER_PASSWORD_RECOVER_FAILED))
            );
    };
}

export const fetchMenuSettings = (companyId) => (dispatch, getState) => {
    const companyid = companyId || getState().userlogin.companyID;
    return new Promise((resolve, reject) => {
        api()
            .get(`${apiPaths.menuSettings}`, { params: { companyid } })
            .then(
                ({ data }) => {
                    dispatch({ type: USER_MENUSETTINGS_FETCHED, payload: data });
                    resolve();
                },
                (err) => {
                    dispatch(apiErrorAction(err, USER_PASSWORD_RECOVER_FAILED));
                    reject();
                }
            );
    });
};

export function validateUserRolesAndPreventLogin() {
    return async (dispatch) => {
        const user = portalUserService.getCurrentUser();
        if (!user || !user.permissions.has('SupplierPortal.SignIn')) {
            dispatch({
                type: USER_LOGIN_FAILED,
                payload: {
                    error: strings.textErrorInvalidPermissionsSignIn,
                },
            });
            return false;
        }
        return true;
    };
}

export function userLoginFailedMessage(message) {
    return {
        type: USER_LOGIN_FAILED,
        payload: {
            error: message,
        },
    };
}

export const userTimeout = (timeout, pathname = null) => ({
    type: USER_LOGIN_TIMEOUT,
    payload: { timeout, pathname },
});

export function userAuthSuccessAction(response) {
    return async (dispatch, getState) => {
        const state = getState();
        const auth = {
            isLoggedIn: true,
            access_token: response.data.access_token,
            expires_in: response.data.expires_in,
            Name: response.data.userName,
            companyID: parseInt(response.data.companyId, 10),
            ID: response.data.userId,
        };

        window.localStorage.is_logged_in = true;
        window.localStorage.access_token = response.data.access_token;
        window.localStorage.refresh_token = response.data.refresh_token;
        window.localStorage.token_expires = response.data['.expires'];
        window.localStorage.expires_in = response.data.expires_in;
        window.localStorage.name = response.data.userName;
        window.localStorage.company_id = parseInt(response.data.companyId, 10);
        window.localStorage.id = response.data.userId;

        await portalUserService.loadSupplierPortalUser(response.data);

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: auth,
        });

        dispatch(cleanupLoginNotifications());

        await Promise.all([
            dispatch(fetchUserCurrencies()),
            dispatch(CommonThunk.fetchCountries()),
            dispatch(CommonThunk.fetchStates()),
            dispatch(CommonThunk.fetchStandardStyleSheets()),
            dispatch(fetchMenuSettings(response.data.companyId)),
            dispatch(getNoticePage()),
            dispatch(requestCurrentUserProfile()),
        ]);

        // if login success - start action show notice page
        const referrer = state.router.location.referrer;
        const prevRoute = getPreservedRoute();

        // check if user has the required roles to access the application
        const shouldContinue = await dispatch(validateUserRolesAndPreventLogin());

        if (!shouldContinue) {
            return;
        }

        if (state.userlogin.timeout === true && state.userlogin.pathname) {
            dispatch(ApplicationThunk.navigateToPath(state.userlogin.pathname));
            dispatch(userTimeout(false));
        } else if (getState().noticePage.showNoticePage === true) {
            dispatch(push(R.NOTICE_PAGE.path));
        } else if (referrer) {
            dispatch(ApplicationThunk.navigateToPath(referrer));
        } else if (prevRoute) {
            dispatch(push(prevRoute));
        } else if (
            state.router.location.pathname &&
            state.router.location.pathname.toLowerCase() !== `${pathsConfig.ROOT_URL}/login`.toLowerCase() &&
            state.router.location.pathname.toLowerCase() !== `${pathsConfig.ROOT_URL}/internallogin`.toLowerCase()
        ) {
            dispatch(ApplicationThunk.navigateToPath(state.router.location.pathname));
        } else {
            dispatch(push(R.HOME.path));
        }
    };
}

export function autoLoginAction() {
    return async (dispatch) => {
        if (isUserLoggedIn()) {
            const response = {
                data: {
                    isLoggedIn: localStorage.getItem('is_logged_in'),
                    access_token: localStorage.getItem('access_token'),
                    refresh_token: localStorage.getItem('refresh_token'),
                    expires_in: localStorage.getItem('expires_in'),
                    '.expires': localStorage.getItem('token_expires'),
                    userName: localStorage.getItem('name'),
                    companyId: localStorage.getItem('company_id'),
                    userId: localStorage.getItem('id'),
                },
            };

            await dispatch(userAuthSuccessAction(response));
        }
    };
}

export function clearCachedLogInInfo() {
    localStorage.removeItem('is_logged_in');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('expires_in');
    localStorage.removeItem('token_expires');
    localStorage.removeItem('name');
    localStorage.removeItem('company_id');
    localStorage.removeItem('id');
}

export function logoutAction() {
    return (dispatch) => {
        const loginPath = isInternalLogin() ? R.INTERNAL_LOGIN_PAGE.path : R.LOGIN_PAGE.path;
        dispatch(push(loginPath));
    };
}

export function userAuthErrorAction(err) {
    console.warn(`Auth error: ${JSON.stringify(err, false, 4)}`);

    return userLoginFailedMessage(strings.textErrorInvalidSignIn);
}

export function authUserByLoginPassword(username, password, companyId) {
    const { signInScript, disableIframe } = pathsConfig.iframe;
    return async (dispatch) => {
        dispatch({ type: USER_LOGIN_PENDING });

        if (disableIframe) {
            await api()
                .post(
                    apiPaths.login,
                    qs.stringify({
                        grant_type: 'password',
                        username,
                        password,
                        companyId,
                    })
                )
                .then(
                    async (res) => {
                        await dispatch(userAuthSuccessAction(res));
                        logSessionInformation({ event: 'Login' });
                    },
                    async (err) => await dispatch(userAuthErrorAction(err))
                );
        } else {
            await outerAPI()
                .post(
                    signInScript,
                    qs.stringify({
                        username,
                        password,
                        companyId,
                    })
                )
                .then(
                    async () => {
                        await api()
                            .post(
                                apiPaths.login,
                                qs.stringify({
                                    grant_type: 'password',
                                    username,
                                    password,
                                    companyId,
                                })
                            )
                            .then(
                                async (res) => {
                                    await dispatch(userAuthSuccessAction(res));
                                    logSessionInformation({ event: 'Login' });
                                },
                                async (err) => await dispatch(userAuthErrorAction(err))
                            );
                    },
                    async (err) => await dispatch(userAuthErrorAction(err))
                );
        }
    };
}

export function redirectToLogout() {
    return (dispatch) => {
        dispatch(userThunk.logout());
    };
}

export function resetPassword(email) {
    return async (dispatch) => {
        dispatch({ type: USER_PASSWORD_RECOVER_PENDING });

        const userThunk = new UserThunk();
        const response = await userThunk.resetPassword(email);

        const responseBuilder = new ResponseBuilder(response, dispatch);
        responseBuilder.withDispatchWhenError(USER_PASSWORD_RECOVER_FAILED);
        responseBuilder.withDispatchWhenSuccess(USER_PASSWORD_RECOVER_SUCCESS);

        await responseBuilder.buildAsync();
    };
}

export const userLogout = (payload) => ({
    type: USER_LOGOUT,
    payload,
});

export function resetPasswordRecoverStatus() {
    return (dispatch) => {
        dispatch({ type: USER_PASSWORD_RECOVER_RESET });
    };
}
