import { ShowRevisionsButton } from '../ShowRevisionsButton';
import { GoToActiveRevisionLink } from '../GoToActiveRevisionLink';
import { BusinessDocType } from '../../../types';

export interface RelatedDocumentLinkRendererProps {
    relatedDocumentId: number;
    relatedDocumentNumber: string;
    relatedDocumentType: BusinessDocType;
    currentDocumentType: BusinessDocType;
    linkType: 'GoToActive' | 'ShowRevisions';
}

export const RelatedDocumentLinkRenderer: React.FC<RelatedDocumentLinkRendererProps> = ({
    relatedDocumentId,
    relatedDocumentNumber,
    relatedDocumentType,
    currentDocumentType,
    linkType,
}) => {
    if (linkType === 'ShowRevisions') {
        return (
            <ShowRevisionsButton
                relatedDocumentNumber={relatedDocumentNumber}
                relatedDocumentId={relatedDocumentId}
                relatedDocumentType={relatedDocumentType}
                currentDocumentType={currentDocumentType}
            />
        );
    }

    return (
        <GoToActiveRevisionLink
            relatedDocumentId={relatedDocumentId}
            relatedDocumentType={relatedDocumentType}
            relatedDocumentNumber={relatedDocumentNumber}
        />
    );
};
