/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */
import { DotNotationExpr } from './DotNotation';
import { resolveDynamicProperty } from './getValue';

/**
 * Ensures that an array has the specified length, adding `undefined` elements
 * after all existing elements until it has the specified length.
 * @param obj the array
 * @param length the length the array should have
 */
function ensureLength(obj: any[], length: number) {
    const currentLength = obj.length;

    if (currentLength >= length) {
        return;
    }

    obj[length - 1] = undefined;

    obj.fill(undefined, currentLength, length);
}

/**
 * Updates an object, returning an array if it is already an array, or creating an array if a numeric
 * property is specified and the object does not exist.
 * @param obj the object to update
 * @param property the property to update
 * @param updater function that is applied to the current property value and returns the new property value
 * @returns the updated object / array (shallow copy)
 */
function updateDynamicPropertyRespectingArrays(obj: any, property: any, updater: (value: any) => any): any {
    // do not allow updating non-numeric array properties (treating arrays like a plain object)
    if (typeof property !== 'number' && Array.isArray(obj)) {
        throw new Error('Unsupported operation - Attempted to update a non-numeric property of an array');
    }

    // when the property is a number, and the object is an array or does not yet exist, we want to return an array
    if (typeof property === 'number' && (Array.isArray(obj) || obj === undefined)) {
        const arrCopy = obj ? [...obj] : [];
        ensureLength(arrCopy, property + 1);
        arrCopy[property] = updater(arrCopy[property]);
        return arrCopy;
    } else {
        return {
            ...obj,
            [property]: updater(obj?.[property]),
        };
    }
}

/**
 * Update a nested object using an updater function.
 * @param expr the expression to access the nested property to update.
 * @param currentObject the object to access the property in.
 * @param env the environment containing variable values
 * @param updater a function that is passed the current value of the property and returns the new value of the property
 * @returns a shallow-copy of `currentObject` with the nested property update applied.
 */
export function updateIn(expr: DotNotationExpr, currentObject: any, env: any, updater: (value: any) => any): any {
    switch (expr.type) {
        case 'IDynamicPropertyAccessExpr': {
            const property = resolveDynamicProperty(expr.property, currentObject, env);
            return updateIn(expr.object, currentObject, env, (subObj: any) =>
                updateDynamicPropertyRespectingArrays(subObj, property, updater)
            );
        }
        case 'IPropertyAccessExpr': {
            return updateIn(expr.object, currentObject, env, (subObj: any) => ({
                ...subObj,
                [expr.property]: updater(subObj?.[expr.property]),
            }));
        }
        case 'IPropertyNameExpr':
            return {
                ...currentObject,
                [expr.property]: updater(currentObject?.[expr.property]),
            };
    }
}

/**
 * Sets the value of a nested property in an object
 * @param expr the expression to access the nested property
 * @param currentObject the object to access the property in
 * @param env the environment containing variable values
 * @param value the new value for the property
 * @returns a shallow-copy of `currentObject` with the nested property upated to `value`.
 */
export function setValue(expr: DotNotationExpr, currentObject: any, env: any, value: any): any {
    return updateIn(expr, currentObject, env, () => value);
}
