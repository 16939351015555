import { ReactNode } from 'react';
import { FCNC } from '../FCNC';
import { Dialog as MuiDialog, DialogActions, DialogTitle, DialogContent, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface IDialogProps {
    open: boolean;
    title?: ReactNode;
    content: ReactNode;
    unmountContentIfClosed?: boolean;
    actions?: ReactNode;
    handleOnClose: () => void;
    testId?: string;
    stopPropagation?: boolean;
    maxWidth?: string;
    maxHeight?: string;
    fullWidth?: boolean;
    staticWidth?: boolean;
}

export const Dialog: FCNC<IDialogProps> = ({
    open,
    title,
    actions,
    handleOnClose,
    content,
    unmountContentIfClosed = false,
    testId,
    stopPropagation,
    maxWidth,
    maxHeight,
    fullWidth,
    staticWidth = false,
}) => {
    return (
        <MuiDialog
            onClick={(e) => {
                if (stopPropagation) {
                    e.stopPropagation();
                }
            }}
            onClose={handleOnClose}
            aria-labelledby="dialog-title"
            open={open}
            data-testid={testId}
            fullWidth={fullWidth}
            sx={(theme) => ({
                '.MuiDialog-paper': {
                    overflowY: 'visible',
                    maxWidth: maxWidth,
                    maxHeight: maxHeight,
                    width: staticWidth ? '50vw' : undefined,
                    [theme.breakpoints.down('md')]: {
                        width: staticWidth ? '100vw' : undefined,
                    },
                },
            })}
        >
            {title && (
                <DialogTitle id="dialog-title" data-testid={`${testId}--title`}>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>{title}</Box>
                        <Box ml={1}>
                            <IconButton onClick={handleOnClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
            )}
            <DialogContent dividers data-testid={`${testId}--content`}>
                {unmountContentIfClosed && !open ? null : content}
            </DialogContent>
            {actions && <DialogActions data-testid={`${testId}--actions`}>{actions}</DialogActions>}
        </MuiDialog>
    );
};
