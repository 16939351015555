const idTemplate = `[a-zA-Z_][a-zA-Z$_0-9]*`;

const tokenTypes = [
    {
        type: 'DOT',
        pattern: /^\./,
    },
    {
        type: 'OPEN_SQUARE',
        pattern: /^\[/,
    },
    {
        type: 'CLOSE_SQUARE',
        pattern: /^\]/,
    },
    {
        type: 'NUMBER',
        pattern: /^([0-9]+)/,
    },
    {
        type: 'NAME',
        pattern: /^"(((\\")|[^"])+)"/,
        transform: (value: string) => value.replace(/\\"/g, '"'),
    },
    {
        type: 'NAME',
        pattern: new RegExp(`^\\\\(\\$${idTemplate})`),
    },
    {
        type: 'VARIABLE',
        pattern: new RegExp(`^\\$(${idTemplate})`),
    },
    {
        type: 'NAME',
        pattern: new RegExp(`^(${idTemplate})`),
    },
] as const;

export type TokenType = typeof tokenTypes[number]['type'];

export interface IToken {
    type: TokenType;
    value: string;
    inputPosition: number;
}

/**
 * Turns input string into tokens that are easier to deal with in parser.
 * @param value input string
 * @returns tokens for parser
 */
export function lexDotNotation(value: string): IToken[] {
    const originalValue = value;
    const tokens: IToken[] = [];
    let position = 0;
    while (value.length > 0) {
        let foundMatch = false;
        for (const tokenType of tokenTypes) {
            const matches = value.match(tokenType.pattern);
            if (matches) {
                foundMatch = true;
                tokens.push({
                    type: tokenType.type,
                    value: 'transform' in tokenType ? tokenType.transform(matches[1]) : matches[1],
                    inputPosition: position,
                });
                value = value.substring(matches[0].length);
                position += matches[0].length;
                break;
            }
        }

        if (!foundMatch) {
            throw new Error(`Invalid character(s) in input\n${originalValue}\n${' '.repeat(position)}^`);
        }
    }
    return tokens;
}
