export function parseXMLBoolean(value: string) {
    if (value.toLowerCase() === 'true') {
        return true;
    }

    if (value.toLowerCase() === 'false') {
        return false;
    }

    throw new Error(`Invalid XML boolean: ${value}`);
}

export function parseXMLNumber(value: string) {
    if (Number.isNaN(Number(value))) {
        throw new Error(`Invalid XML number: ${value}`);
    }

    return Number(value);
}

export function generateXMLBoolean(value: boolean): string {
    if (value) {
        return 'True';
    }

    return 'False';
}

export function generateXMLNumber(value: number) {
    return value.toString();
}
