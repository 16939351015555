import { Route, Switch, Redirect } from 'react-router-dom';
import R from '../routes';
import Login from './page/login/Login';
import Logout from './page/login/Logout';
import InternalLogin from './page/login/InternalLogin';
import { lazy, Suspense } from 'react';
import { Loading } from 'common';

const InnerPage = lazy(() => import('../containers/InnerPageLayout'));

const App = () => (
    <Switch>
        <Route path={R.LOGIN_PAGE.path}>
            <Login />
        </Route>
        <Route path={R.INTERNAL_LOGIN_PAGE.path}>
            <InternalLogin />
        </Route>
        <Route path={R.LOGOUT_PAGE.path}>
            <Logout />
        </Route>
        <Route path={R.ROOT.path}>
            <Suspense fallback={<Loading />}>
                <InnerPage />
            </Suspense>
        </Route>
        <Redirect from="*" to={R.ROOT.path} />
    </Switch>
);

export default App;
