export const FETCH_COUNTRIES_EXECUTE = 'FETCH_COUNTRIES_EXECUTE';
export const FETCH_COUNTRIES_FAILURE = 'FETCH_COUNTRIES_FAILURE';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_STANDARD_STYLE_SHEETS_EXECUTE = 'FETCH_STANDARD_STYLE_SHEETS_EXECUTE';
export const FETCH_STANDARD_STYLE_SHEETS_FAILURE = 'FETCH_STANDARD_STYLE_SHEETS_FAILURE';
export const FETCH_STANDARD_STYLE_SHEETS_SUCCESS = 'FETCH_STANDARD_STYLE_SHEETS_SUCCESS';
export const FETCH_STATES_EXECUTE = 'FETCH_STATES_EXECUTE';
export const FETCH_STATES_FAILURE = 'FETCH_STATES_FAILURE';
export const FETCH_STATES_SUCCESS = 'FETCH_STATES_SUCCESS';

export const fetchCountriesExecute = (params) => ({
    type: FETCH_COUNTRIES_EXECUTE,
    payload: {
        params: params,
    },
});

export const fetchCountriesSuccess = (data) => ({
    type: FETCH_COUNTRIES_SUCCESS,
    payload: data,
});

export const fetchStatesExecute = (params) => ({
    type: FETCH_STATES_EXECUTE,
    payload: {
        params: params,
    },
});

export const fetchStatesSuccess = (data) => ({
    type: FETCH_STATES_SUCCESS,
    payload: data,
});

export const fetchStandardStyleSheetsExecute = (params) => ({
    type: FETCH_STANDARD_STYLE_SHEETS_EXECUTE,
    payload: {
        params: params,
    },
});

export const fetchStandardStyleSheetsSuccess = (data) => ({
    type: FETCH_STANDARD_STYLE_SHEETS_SUCCESS,
    payload: data,
});
