/**
 * This module provides functions for normalizing dates that do not include timezone information so that we can
 * internationalize/localize the display of dates in our application for all users.
 */
import { getTimezoneOffset, formatInTimeZone } from 'date-fns-tz';

function hasTimezoneInformation(iso8601DateString: string) {
    const timestring = iso8601DateString.substring(iso8601DateString.indexOf('T'));
    const hasOffest = iso8601DateString.includes('T') && (timestring.includes('+') || timestring.includes('-'));
    return hasOffest || iso8601DateString.endsWith('Z');
}

function getDateString(iso8601DateString: string) {
    // extract just date information from the date string
    const matches = iso8601DateString.match(/(\d{4})-(\d{2})-(\d{2})/);
    if (!matches) {
        throw new Error('expected ISO 8601 datetime string');
    }
    const [, y, m, d] = matches;

    return `${y}-${m}-${d}`;
}

function getTimeString(iso8601DateString: string) {
    // extract just date information from the date string
    const matches = iso8601DateString.match(/T(\d{2}):(\d{2}):(\d{2})/);
    if (!matches) {
        return 'T00:00:00';
    }
    const [, h, m, s] = matches;

    return `T${h}:${m}:${s}`;
}

function getPTOffest(iso8601DateString: string) {
    // create a UTC date object on same date (this is what the library needs to get correct offset)
    // keep in mind this will be wrong if the iso8601DateString is for a time on a day that DST switches
    // happen.
    const dateOnlyString = `${getDateString(iso8601DateString)}T00:00:00Z`;
    const date = new Date(dateOnlyString);

    // tells us the offset taking DST into account as best as possible
    // but the offset is in ms and we need hours
    // This may not be exact for times around a DST switch, as we only keep the "date" part.
    // This is a tradeoff to simplify the implementation and it is known that it is actually impossible
    // to recover the exact offset because some times happen "twice" on days that DST switches
    // (eg, during "fall back", 1 AM happens twice. DST is on the first time and off the second time)
    const offset = getTimezoneOffset('America/Los_Angeles', date);
    const hoursOffset = offset / (60 * 60 * 1000);

    // the hours offset is either -7 or -8 for this timezone
    if (hoursOffset === -7) {
        return '-07:00';
    }

    return '-08:00';
}

function addPTOffset(iso8601DateString: string) {
    return `${getDateString(iso8601DateString)}${getTimeString(iso8601DateString)}${getPTOffest(iso8601DateString)}`;
}

/**
 * Returns an ISO 8601 UTC string for any date object.
 *
 * In general, use this to convert date objects before passing them to the API.
 *
 * Also see `convertToPTDateTimeString` in case an API is "losing" timezone information.
 */
export function toISO8601String(date: Date) {
    return date.toISOString().replace(/\.\d{3}/, '');
}

/**
 * Convert datetime string that may not contain timezone information to
 * an ISO 8601 UTC string. We assume PT when no timezone information is present in
 * the string.
 *
 * *Warning: Do not pass datetime strings coming from user input to this function. Pass those to the
 * `Date` constructor. That will ensure that they are parsed using the user's timezone if there is no
 * timezone information in the string. This function assumes PT timezone if there is no information, which
 * makes it useful for dealing with our API that historically has stored datetimes without timezone information
 * under the assumption that they are PT.*
 */
export function normalizeDateTimeString(iso8601DateString: string) {
    if (!hasTimezoneInformation(iso8601DateString)) {
        iso8601DateString = addPTOffset(iso8601DateString);
    }

    const date = new Date(iso8601DateString);
    return toISO8601String(date);
}

/**
 * Convert UTC datetime string back to PT datetime string that the API accepts
 *
 * *Warning: Only use this if the API you are passing the datetime string to "loses" timezone
 * information. In such a case, this conversion is necessary to ensure that `normalizeDateTimeString`
 * will recover the correct datetime when the value is read later on.*
 */
export function convertToPTDateTimeString(utcDateTimeString: string) {
    const date = formatInTimeZone(utcDateTimeString, 'America/Los_Angeles', 'yyyy-MM-dd');
    const time = formatInTimeZone(utcDateTimeString, 'America/Los_Angeles', 'HH:mm:ss');
    return `${date}T${time}`;
}
