import { FCNC } from '../FCNC';
import { ErrorToErrorMessage } from './ErrorToErrorMessage';
import { Alert } from '../Alert';
import { AlertProps } from '@mui/material';

export interface IErrorAlertProps {
    error: unknown;
    prompt?: string;
    testId: string;
    sx?: AlertProps['sx'];
    onClose?: AlertProps['onClose'];
}
/**
 * Renders an error to an error message
 */
export const ErrorAlert: FCNC<IErrorAlertProps> = ({ error, prompt, testId, sx, onClose }) => {
    if (!error) {
        return null;
    }

    return (
        <Alert testId={testId} severity="error" sx={sx} onClose={onClose}>
            <ErrorToErrorMessage error={error} prompt={prompt} />
        </Alert>
    );
};
