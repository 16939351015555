import { IConfig, IFormConfig, IValidationRule } from '../../transcepta-types';

export const getInvalidCharacters = (value: string, invalidCharactersList: string[]) => {
    const chars = value ? [...value] : [];
    return chars.reduce((acc, k) => {
        if (acc && invalidCharactersList.includes(k) && !acc.includes(k)) {
            acc += `, `;
        }
        if (invalidCharactersList.includes(k) && !acc.includes(k)) {
            acc += k;
        }
        return acc;
    }, '');
};

export const formValidationSchemaGenerator = (formConfig: IConfig<IFormConfig>) => {
    let validationSchema = {};

    formConfig &&
        Object.values(formConfig).forEach((columns: IFormConfig[]) => {
            columns.forEach((field) => {
                let rules: IValidationRule[] = [];
                if (field.required) {
                    rules = [...rules, { isRequired: true, message: field.requiredMessage }];
                }

                if (field.validationRules) {
                    field.validationRules.forEach((rule: IValidationRule) => {
                        rules = [...rules, rule];
                    });
                }
                validationSchema = {
                    ...validationSchema,
                    [field.key]: {
                        rules,
                    },
                };
            });
        });

    return validationSchema;
};

export const isPostalCodeValid = (country: string, postalCode: string) => {
    if (country === 'USA') {
        return !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(postalCode);
    }
    return postalCode.length > 10;
};

export const validateEmailFormat = (email: string) => {
    const pattern = new RegExp(
        "^[\\w\\.!#$%&'*+/=?^{|}~-]{1,63}@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([\\w\\-]{1,61}\\.)+[a-zA-Z]{2,24}))$"
    );
    return pattern.test(email) || email.length === 0;
};
