import { FC } from 'react';
import { Divider, Grid, Link as MuiLink, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { ILinkGroup } from './types';

export interface ISettingsProps {
    linkGroups: ILinkGroup[];
}

const useStyles = makeStyles((theme: Theme) => ({
    link: {
        listStyle: 'none',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

export const Settings: FC<ISettingsProps> = ({ linkGroups }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const linkGroupsWithoutLogout = linkGroups.filter((linkGroup) => linkGroup.title !== '' && linkGroup.visible);

    return (
        <Grid
            sx={
                isSmDown
                    ? {
                          px: 3,
                      }
                    : {}
            }
            container
            spacing={4}
            direction={isSmDown ? 'column' : 'row'}
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            {linkGroupsWithoutLogout.map((linkGroup) => {
                return (
                    <Grid item key={`group-${linkGroup.title}`} xs={true}>
                        <Typography>{linkGroup.title}</Typography>
                        {linkGroup.title && <Divider />}
                        <ul>
                            {linkGroup.links.map(
                                (link) =>
                                    link.visible && (
                                        <Grid item component="li" key={`link-${link.text}`} className={classes.link}>
                                            <MuiLink
                                                component={Link}
                                                to={link.path ? link.path : '#'}
                                                onClick={link.onClick}
                                                data-testid={link.text.replace(/\s/g, '-')}
                                            >
                                                {link.text}
                                            </MuiLink>
                                        </Grid>
                                    )
                            )}
                        </ul>
                    </Grid>
                );
            })}
        </Grid>
    );
};
