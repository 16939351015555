import { makeStyles } from '@mui/styles';

export const useDataGraphTableStyles = makeStyles(() => ({
    root: {
        '& .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: 'initial',
            textOverflow: 'initial',
            textAlign: 'center',
            lineHeight: 1.28581,
        },
        '& .MuiDataGrid-cell': {
            borderBottom: 'none',
        },
    },
}));

export const useProgressBarStyles = makeStyles(() => ({
    root: {
        width: 40,
        marginLeft: 4,
        height: 12,
    },
}));

export const footerStyles = {
    container: {
        height: 27,
        display: 'flex',
        width: '100%',
        paddingRight: '28px',
        paddingLeft: '8px',
        paddingTop: '4px',
    },
    cell: {
        fontWeight: 'bold',
        textAlign: 'right',
    },
};
