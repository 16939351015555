import { AxiosResponse } from 'axios';
import { IDocumentBatchSamplesViewModel, IDocumentBatchViewModel } from './ViewModels';
import { api } from '../../api';
import { IFileViewModel } from '../../../utils';

export interface IPutDocumentBatchParams {
    historyDescription?: string;
}

export interface ISearchDocumentBatchSamplesParams {
    ruleConfigurationName?: string;
    senderProfileId?: string | number;
    status?: string;
    $top?: number;
    $skip?: number;
    $count?: boolean;
    $orderby?: string;
    buyerCompanyName?: string;
}

export class DocumentBatchApi {
    route = '/DocumentBatch';

    public searchDocumentBatchSamples = async (
        params: ISearchDocumentBatchSamplesParams
    ): Promise<AxiosResponse<{ Count: number; Items: IDocumentBatchSamplesViewModel[] }>> => {
        return api().get(`${this.route}/samples/search`, { params });
    };

    public putDocumentBatch = (
        id: number,
        data: Partial<IDocumentBatchViewModel>,
        params: IPutDocumentBatchParams
    ): Promise<AxiosResponse<{ ID: number }>> => {
        return api().put(`${this.route}/${id}`, data, { params });
    };

    public getDocumentBatchFile = (id: number): Promise<AxiosResponse<IFileViewModel>> => {
        return api().get(`${this.route}/samples/search/${id}`);
    };
}
