import { BigNumber } from 'bignumber.js';
import { path } from 'ramda';
import { DetermineMaxDigits, DetermineMaxDecimalPlaces, DetermineMinDecimalPalces, CleanNumericValue } from './numeric';
import {
    ControlType,
    BusinessDocType,
    ArchiveStatus as DocumentArchiveStatus,
    IDocument,
    ExternalStatus,
    UserRoles as Roles,
    IUser,
    DocumentStatus,
    DocumentScrapingStatus,
    ITradingPartners,
    ProcessingMode,
} from '../../transcepta-types';
import { format } from 'date-fns';

export const glCodingAmountMaxDigits = DetermineMaxDigits(ControlType.Money);
export const glCodingAmountMaxDecimal = (currencyCode: string) =>
    DetermineMaxDecimalPlaces(ControlType.Money, null, currencyCode);
export const glCodingAmountMinDecimal = (currencyCode: string) =>
    DetermineMinDecimalPalces(ControlType.Money, null, glCodingAmountMaxDecimal(currencyCode), currencyCode);

export const glCodingPercentMaxDigits = DetermineMaxDigits(ControlType.Percentage);
export const glCodingPercentMaxDecimal = DetermineMaxDecimalPlaces(ControlType.Percentage, null, null);
export const glCodingPercentMinDecimal = DetermineMinDecimalPalces(
    ControlType.Percentage,
    null,
    glCodingPercentMaxDecimal,
    null
);

export const getGlStructureInitialState = (item: any, glStructure: any, glcodingSeperator: string) => {
    const codingValues = item && item.coding ? item.coding.split(glcodingSeperator) : [];

    const mappedRelationShips =
        glStructure &&
        glStructure.GLSegments &&
        glStructure.GLSegments.GLSegment.reduce((acc: any, segment: any, index: number) => {
            return {
                ...acc,
                [`${segment.Name.toLowerCase().replace(/\s+/g, '')}`]: codingValues[index] ? codingValues[index] : '',
            };
        }, {});

    return {
        ...mappedRelationShips,
        ...(item && item.percent && { percent: item.percent }),
        ...(item && item.amount && { amount: item.amount }),
    };
};

export const generateFormConfig = (glStructure: any, values: any) => {
    const segments =
        glStructure &&
        glStructure.GLSegments &&
        glStructure.GLSegments.GLSegment.map((s: any) => {
            const { Name } = s;
            const key = Name.toLowerCase().replace(/\s+/g, '');
            const value = values[`${Name.toLowerCase().replace(/\s+/g, '')}`]
                ? values[`${Name.toLowerCase().replace(/\s+/g, '')}`]
                : '';

            return {
                ...s,
                key,
                value,
            };
        });
    return { segments, percent: values.percent, amount: values.amount };
};

export const getGLCodingLineIndex = (glCodingInfo: any, lineId: any) => {
    if (
        glCodingInfo !== undefined &&
        glCodingInfo !== null &&
        glCodingInfo.lines !== undefined &&
        glCodingInfo.lines !== null
    ) {
        for (let i = 0; i < glCodingInfo.lines.length; i++) {
            if (glCodingInfo.lines[i].lineId === lineId) {
                return i;
            }
        }
    }

    return null;
};

export function glCodingBalancePercent(
    values: any,
    lastChangedIndex: number,
    lineTotal: number,
    currencyCode: string,
    previousValues: any
) {
    let newAmount;
    let amount;
    let percent;
    const amountMaxDecimal = glCodingAmountMaxDecimal(currencyCode);
    const amountMinDecimal = glCodingAmountMinDecimal(currencyCode);

    const maxPercent = new BigNumber(100);

    let totalPercent = new BigNumber(0);
    for (let i = 0; i < values.length; i++) {
        if (values[i].percent !== undefined && values[i].percent !== null && values[i].percent !== '') {
            percent = new BigNumber(values[i].percent);
            totalPercent = totalPercent.plus(percent);
        } else {
            values[i].percent = null;
        }
    }

    const total = new BigNumber(lineTotal);

    let totalAmount = new BigNumber(0);
    for (let i = 0; i < values.length; i++) {
        if (values[i].percent === null) {
            values[i].amount = null;
        } else {
            let previousPercent = null;
            if (previousValues && previousValues[i] && previousValues[i].percent) {
                //@ts-ignore
                previousPercent = new BigNumber(previousValues[i].percent);
            }
            percent = new BigNumber(values[i].percent);
            //@ts-ignore
            if (percent.comparedTo(previousPercent) !== 0) {
                amount = total.times(percent).dividedBy(maxPercent);
                values[i].amount = Number(
                    CleanNumericValue(
                        Number(amount).toString(),
                        glCodingAmountMaxDigits,
                        amountMaxDecimal,
                        amountMinDecimal
                    )
                );
            }
            newAmount = new BigNumber(values[i].amount);
            totalAmount = totalAmount.plus(newAmount);
        }
    }

    // if the total of the percentages = 100, then the total of the calculated amounts should = line total
    if (totalPercent.isEqualTo(maxPercent) && !totalAmount.isEqualTo(total)) {
        // determine the smallest possible increment based upon the max decimal for the amount field
        let smallestStep = new BigNumber(1);
        for (let i = 0; i < amountMaxDecimal; i++) {
            smallestStep = smallestStep.dividedBy(10);
        }

        // set a limit on the number of loops we do below as a precaution to prevent an infinite loop
        let maxIterations;
        if (totalAmount.isGreaterThan(total)) {
            maxIterations = Number(totalAmount.minus(total).dividedBy(smallestStep));
        } else {
            maxIterations = Number(total.minus(totalAmount).dividedBy(smallestStep));
        }

        for (let iteration = 0; iteration < maxIterations && !totalAmount.isEqualTo(total); iteration++) {
            for (let i = values.length - 1; i >= 0 && !totalAmount.isEqualTo(total); i--) {
                if (values[i].amount !== null) {
                    percent = new BigNumber(values[i].percent);
                    amount = new BigNumber(values[i].amount);
                    // only update values that were rounded (value != calculated value)
                    if (
                        totalAmount.isGreaterThan(total) &&
                        amount.isGreaterThan(total.times(percent).dividedBy(maxPercent))
                    ) {
                        newAmount = amount.minus(smallestStep);
                        values[i].amount = Number(newAmount);
                        totalAmount = totalAmount.minus(smallestStep);
                    } else if (
                        totalAmount.isLessThan(total) &&
                        amount.isLessThan(total.times(percent).dividedBy(maxPercent))
                    ) {
                        newAmount = amount.plus(smallestStep);
                        values[i].amount = Number(newAmount);
                        totalAmount = totalAmount.plus(smallestStep);
                    }
                }
            }
        }
    }

    return values;
}

export function glCodingBalanceAmount(values: any[], lastChangedIndex: any, lineTotal: number, previousValues: any[]) {
    let percent;
    let amount;
    let i;
    let newPercent;
    const total = new BigNumber(lineTotal);

    let totalAmount = new BigNumber(0);
    for (i = 0; i < values.length; i++) {
        if (values[i].amount !== undefined && values[i].amount !== null && values[i].amount !== '') {
            amount = new BigNumber(values[i].amount);
            totalAmount = totalAmount.plus(amount);
        } else {
            values[i].amount = null;
        }
    }

    const maxPercent = new BigNumber(100);
    let totalPercent = new BigNumber(0);
    for (i = 0; i < values.length; i++) {
        if (values[i].amount === null) {
            values[i].percent = null;
        } else {
            let previousAmount = null;
            if (previousValues && previousValues[i] && previousValues[i].amount) {
                //@ts-ignore
                previousAmount = new BigNumber(previousValues[i].amount);
            }
            amount = new BigNumber(values[i].amount);
            //@ts-ignore
            if (amount.comparedTo(previousAmount) !== 0) {
                percent = maxPercent.times(amount).dividedBy(total);
                values[i].percent = Number(
                    CleanNumericValue(
                        Number(percent).toString(),
                        glCodingPercentMaxDigits,
                        glCodingPercentMaxDecimal,
                        glCodingPercentMinDecimal
                    )
                );
            }
            newPercent = new BigNumber(values[i].percent);
            totalPercent = totalPercent.plus(newPercent);
        }
    }

    // if the total of the amounts = line total, then the total of the calculated percentages should = 100
    if (totalAmount.isEqualTo(total) && !totalPercent.isEqualTo(maxPercent)) {
        // determine the smallest possible increment based upon the max decimal for the percent field
        let smallestStep = new BigNumber(1);
        for (i = 0; i < glCodingPercentMaxDecimal; i++) {
            smallestStep = smallestStep.dividedBy(10);
        }
        // set a limit on the number of loops we do below as a precaution to prevent an infinite loop
        let maxIterations;
        if (totalPercent.isGreaterThan(maxPercent)) {
            maxIterations = Number(totalPercent.minus(maxPercent).dividedBy(smallestStep));
        } else {
            maxIterations = Number(maxPercent.minus(totalPercent).dividedBy(smallestStep));
        }
        for (let iteration = 0; iteration < maxIterations && !totalPercent.isEqualTo(maxPercent); iteration++) {
            for (i = values.length - 1; i >= 0 && !totalPercent.isEqualTo(maxPercent); i--) {
                if (values[i].percent !== null) {
                    amount = new BigNumber(values[i].amount);
                    percent = new BigNumber(values[i].percent);
                    // only update values that were rounded (value != calculated value)
                    if (
                        totalPercent.isGreaterThan(maxPercent) &&
                        percent.isGreaterThan(maxPercent.times(amount).dividedBy(total))
                    ) {
                        newPercent = percent.minus(smallestStep);
                        values[i].percent = Number(newPercent);
                        totalPercent = totalPercent.minus(smallestStep);
                    } else if (
                        totalPercent.isLessThan(maxPercent) &&
                        percent.isLessThan(maxPercent.times(amount).dividedBy(total))
                    ) {
                        newPercent = percent.plus(smallestStep);
                        values[i].percent = Number(newPercent);
                        totalPercent = totalPercent.plus(smallestStep);
                    }
                }
            }
        }
    }
    return values;
}

export const fieldRenderer = (field: any, value: any, currencySymbol: string) => {
    const moneyFields = [ControlType.Money, ControlType.MoneyLabel, ControlType.Tax, ControlType.UnitAmountAndAmount];
    return field.controlType === ControlType.Date && value
        ? format(new Date(value), 'P')
        : moneyFields.includes(field.controlType) && !isNaN(parseFloat(value))
        ? `${currencySymbol} ${parseFloat(value).toFixed(2)}`
        : field.controlType === ControlType.Quantity && value
        ? parseInt(value, 10)
        : value;
};

export const getCustomerName = (doc: any, documentType: any) => {
    const namePaths = {
        [BusinessDocType.Invoice]: ['CommonFields', 'SenderCompanyName'],
        [BusinessDocType.PurchaseOrder]: ['CommonFields', 'SenderCompanyName'],
        [BusinessDocType.PurchaseOrderAcknowledgement]: ['CommonFields', 'SenderCompanyName'],
        [BusinessDocType.ASN]: ['CommonFields', 'SenderCompanyName'],
        [BusinessDocType.InventoryAdvice]: ['CommonFields', 'SenderCompanyName'],
    };

    return documentType ? path(namePaths[documentType], doc) : 'Unknown';
};

export const isDocumentAvailableInArchive = (document: IDocument): boolean => {
    if (!document) {
        return false;
    }

    const { ArchiveStatus, ArchivePath } =
        document.CommonFields !== null ? document.CommonFields : { ArchiveStatus: null, ArchivePath: null };

    return (
        ArchiveStatus === DocumentArchiveStatus.Archived &&
        ArchivePath !== undefined &&
        ArchivePath !== null &&
        ArchivePath !== ''
    );
};

export const isDocumentEditable = (document: IDocument): boolean => {
    if (!document) {
        return false;
    }

    switch (document.BusinessDocType) {
        case BusinessDocType.ASN:
        case BusinessDocType.ASNGeneric:
        case BusinessDocType.PurchaseOrderAcknowledgement:
            return true;

        case BusinessDocType.Invoice:
            return document.BusinessDocFields.Invoice.InvoiceType !== 'Auto Credit Memo';

        default:
            return false;
    }
};

export const isDocumentNotRejectedParkedOrWorkInProgress = (document: IDocument, isDraft: boolean): boolean => {
    if (!document) {
        return false;
    }

    const documentExternalStatus = document.CommonFields !== null ? document.CommonFields.ExternalStatus : null;

    if (documentExternalStatus === null) {
        return !isDraft;
    }

    const rejectedParkedAndWorkInProgressExternalStatuses = [
        ExternalStatus.Draft,
        ExternalStatus.Error,
        ExternalStatus.Incomplete,
        ExternalStatus.NotSent,
        ExternalStatus.Parked,
        ExternalStatus.Rejected,
    ];

    return !rejectedParkedAndWorkInProgressExternalStatuses.includes(documentExternalStatus) && !isDraft;
};

export const canUserEditDocuments = (user: IUser) => {
    const { HasCompletedRegistration } =
        user.menuSettings !== null ? user.menuSettings : { HasCompletedRegistration: null };
    const UserRoles =
        user.UserRoles !== null
            ? user.UserRoles.map((userRole) => {
                  return userRole.RoleID;
              })
            : [];

    return (
        HasCompletedRegistration !== undefined &&
        HasCompletedRegistration !== null &&
        HasCompletedRegistration === true &&
        UserRoles.includes(Roles.InvoiceEditRights)
    );
};

export const getDraftBusinessDocId = (document: IDocument, isDraft: boolean) => {
    if (!document || !isDraft) {
        return null;
    }

    switch (document.BusinessDocType) {
        case BusinessDocType.Invoice:
            return document.BusinessDocFields.Invoice.ID;
        default:
            return document &&
                document.BusinessDocFields &&
                document.BusinessDocFields.BusinessDocument &&
                document.BusinessDocFields.BusinessDocument.Id
                ? document.BusinessDocFields.BusinessDocument.Id
                : null;
    }
};

export const isDocumentWorkInProgress = (document: IDocument, isDraft: boolean): boolean => {
    if (!document) {
        return false;
    }

    const documentExternalStatus = document.CommonFields !== null ? document.CommonFields.ExternalStatus : null;

    if (documentExternalStatus === null) {
        return isDraft;
    }

    const workInProgressExternalStatuses = [
        ExternalStatus.Draft,
        ExternalStatus.Error,
        ExternalStatus.Incomplete,
        ExternalStatus.NotSent,
    ];

    return isDraft || workInProgressExternalStatuses.includes(documentExternalStatus);
};

export const isDocumentProcessed = (document: IDocument): boolean => {
    if (!document) {
        return false;
    }

    const { Status, ScrapingStatus } =
        document.CommonFields !== null ? document.CommonFields : { Status: null, ScrapingStatus: null };

    // Check to see if the status is either ProcessingComplete or Cancelled and does not have a scraping status of Error or DuplicateInvoice
    return (
        (Status === DocumentStatus.ProcessingComplete || Status === DocumentStatus.Canceled) &&
        ScrapingStatus !== DocumentScrapingStatus.Error &&
        ScrapingStatus !== DocumentScrapingStatus.DuplicateInvoice
    );
};

export const isDocumentAvailableToCopy = (
    document: IDocument,
    isDraft: boolean,
    tradingPartner: ITradingPartners,
    user: IUser
): boolean => {
    if (!document || !tradingPartner || tradingPartner.ProcessingMode === ProcessingMode.Disabled) {
        return false;
    }

    switch (document.BusinessDocType) {
        // web entry doc types
        case BusinessDocType.ASN:
        case BusinessDocType.ASNGeneric:
        case BusinessDocType.PurchaseOrderAcknowledgement:
            // Even though ASN and POA are a web entry doc types, create a copy should not currently be available for them.
            // We will be implementing this for ASNs and POAs in the future.
            return false;

        case BusinessDocType.Invoice:
            // Copy should only be displayed when web entry is available for the business doc type, the document is not rejected, parked, or incomplete,
            // the supplier has completed registration, and the user has edit rights.
            return (
                document !== undefined &&
                document !== null &&
                user !== undefined &&
                user !== null &&
                isDocumentEditable(document) &&
                isDocumentNotRejectedParkedOrWorkInProgress(document, isDraft) &&
                canUserEditDocuments(user)
            );

        // non-web entry doc types
        default:
            // Copy should only be displayed when web entry is available for the business doc type
            return false;
    }
};

export const isDocumentAvailableToDownload = (document: IDocument, isDraft: boolean): boolean => {
    if (document !== undefined && document !== null) {
        switch (document.BusinessDocType) {
            // web entry doc types
            case BusinessDocType.ASN:
            case BusinessDocType.ASNGeneric:
            case BusinessDocType.Invoice:
            case BusinessDocType.PurchaseOrderAcknowledgement:
                // If the invoice is not a work in progress (file is generated upon download click),
                // and the file that represents this document is no longer archived,
                // then do not display the download link
                return isDocumentWorkInProgress(document, isDraft) || isDocumentAvailableInArchive(document);

            // non-web entry doc types
            default:
                // If the file that represents this document is no longer archived,
                // then do not display the download link
                return isDocumentAvailableInArchive(document);
        }
    }

    return false;
};

export const isDocumentAvailableToDelete = (isDraft: boolean, user: IUser) => {
    // Delete is only available to users with edit rights and only if the document is a draft
    return isDraft === true && user !== undefined && user !== null && canUserEditDocuments(user);
};

export const generateReadableBusinessDocType = (document: IDocument, strings: { [x: string]: string }): string => {
    switch (document.BusinessDocType) {
        case BusinessDocType.ASN:
        case BusinessDocType.ASNGeneric:
            return strings.h3DocumentFoundTextASN;

        case BusinessDocType.ConsumptionAdvice:
            return strings.h3DocumentFoundTextConsumptionAdvice;

        case BusinessDocType.FunctionalACK:
            return strings.h3DocumentFoundTextFunctionalAck;

        case BusinessDocType.InventoryAdvice:
            return strings.h3DocumentFoundTextInventoryAdvice;

        case BusinessDocType.Invoice:
            return strings.h3DocumentFoundTextInvoice;

        case BusinessDocType.PurchaseOrder:
            return strings.h3DocumentFoundTextPurchaseOrder;

        case BusinessDocType.PurchaseOrderAcknowledgement:
            return strings.h3DocumentFoundTextPurchaseOrderAcknowledgement;

        case BusinessDocType.ReceivingAdvice:
            return strings.h3DocumentFoundTextReceivingAdvice;

        case BusinessDocType.SIMDocument:
            return document.BusinessDocFields.BusinessDocument.Document.BusinessDocument.SIMDocument.Description;

        default:
            return 'Unknown';
    }
};
