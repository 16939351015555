import { useResetScrollState } from './useResetScrollState';

/**
 * Renders a component that scrolls to the top of the page on new page
 * views.
 */
export function ResetScrollState() {
    useResetScrollState();
    return null;
}
