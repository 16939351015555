import { AxiosResponse } from 'axios';
import { api } from '../../../services/api';
import ICreatedUpdateDeleteViewModel from '../../Common/Api/ViewModels/CreatedUpdateDeleteViewModel';
import { ICompanyGLAccountStructureViewModel } from './ViewModels';

export class CompanyGLAccountStructureApi {
    route = '/Company/GLAccountStructure';

    public getCompanyGLAccountStructure = (
        id: number
    ): Promise<AxiosResponse<ICompanyGLAccountStructureViewModel[]>> => {
        return api().get(`${this.route}/${id}`);
    };

    public getCompanyGLAccountStructureByCompanyId = (
        companyId?: number,
        filter?: string,
        orderBy?: string
    ): Promise<AxiosResponse<ICompanyGLAccountStructureViewModel[]>> => {
        const params = {
            companyId,
            $filter: filter,
            $orderBy: orderBy,
        };

        return api().get(`${this.route}`, { params });
    };

    public createCompanyGLAccountStructure = (
        companyGLAccountStructureViewModel: Omit<ICompanyGLAccountStructureViewModel, 'Id'>
    ): Promise<AxiosResponse<{ IDs: number[]; Exceptions: any[] }>> => {
        return api().post(this.route, [companyGLAccountStructureViewModel]);
    };

    public updateCompanyGLAccountStructure = (
        id: number,
        companyGLAccountStructureViewModel: Omit<ICompanyGLAccountStructureViewModel, 'Id'>
    ): Promise<AxiosResponse<ICreatedUpdateDeleteViewModel>> => {
        return api().put(`${this.route}/${id}`, companyGLAccountStructureViewModel);
    };

    public deleteCompanyGLAccountStructure = (id: number): Promise<AxiosResponse<ICreatedUpdateDeleteViewModel>> => {
        return api().delete(`${this.route}/${id}`);
    };
}
