enum DDOfferStatus {
    PotentialOffer = 0,
    Active = 1,
    Inactive = 2,
    AutoAccepted = 3,
    ManuallyAccepted = 4,
    Declined = 5,
    Expired = 6,
}

export default DDOfferStatus;
