import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';

export type { TooltipProps };

export const Tooltip = (props: TooltipProps) => {
    return (
        <MuiTooltip
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            onClick={
                props.onClick ??
                ((ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                })
            }
            enterTouchDelay={10}
            slotProps={{ tooltip: { sx: { fontSize: 'inherit' } } }}
        />
    );
};
