import { StaticErrorRenderer } from '../ErrorRenderer';
import { DocumentEditValidationFunctionParams, DocumentValidationEvent } from '../proservContractTypes';
import { DocumentEditValidationConfiguration, IInternalRule, ValidationFailureDictionary } from '../types/private';

export type IInternalRulesConfiguration = DocumentEditValidationConfiguration & {
    validationFailureDictionary: ValidationFailureDictionary;
};

export function findIllegalInternalRules(
    internalRules: IInternalRule[],
    type: DocumentEditValidationFunctionParams['type']
) {
    return internalRules.filter((x) => !x.enabledContexts.includes(type));
}

export function createInternalRulesConfiguration(internalRules: IInternalRule[]): IInternalRulesConfiguration {
    const focusLossFieldNames = new Set<string>();
    const eventsToValidateOn = new Set<DocumentValidationEvent['type']>();
    const validationFailureDictionary: ValidationFailureDictionary = {};

    internalRules.forEach((rule) => {
        rule.fieldNamesToValidateOnFocusLoss.forEach((fieldName) => {
            focusLossFieldNames.add(fieldName);
        });

        rule.eventsToValidateOn.forEach((eventName) => {
            eventsToValidateOn.add(eventName);
        });

        rule.validationFailureTypes.forEach((vft) => {
            validationFailureDictionary[vft.errorCode] = vft;
        });
    });

    const errorRenderer = new StaticErrorRenderer(validationFailureDictionary);

    return {
        fieldNamesToValidateOnFocusLoss: Array.from(focusLossFieldNames.values()),
        eventsToValidateOn: Array.from(eventsToValidateOn.values()),
        errorRenderer,
        validationFunctions: internalRules,
        validationFailureDictionary,
    };
}
