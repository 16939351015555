import { ChangeEvent, FC, FormEvent } from 'react';
import { Button } from '../../Atoms';
import { Stack } from '@mui/material';
import { TextField } from '../../../../ui';

interface IProps {
    email: string;
    emailError: boolean;
    handleEmailChange: (event: ChangeEvent) => void;
    handleReset: (event: FormEvent) => void;
    handleBackToSignIn: () => void;
}

const ForgotPasswordForm: FC<IProps> = ({ email, emailError, handleEmailChange, handleReset, handleBackToSignIn }) => {
    return (
        <form onSubmit={handleReset}>
            <TextField
                stacked
                id="transcepta-change-password-email"
                label="Email"
                type="email"
                color="secondary"
                value={email}
                error={emailError}
                onChange={handleEmailChange}
                sx={{
                    pb: 1,
                    '.MuiInputLabel-shrink': {
                        top: -8,
                    },
                }}
            />
            <Stack direction="row" justifyContent="flex-end" gap={1}>
                <Button variant="secondary" onClick={handleBackToSignIn}>
                    Back to Sign In
                </Button>
                <Button type="submit">Reset</Button>
            </Stack>
        </form>
    );
};

export default ForgotPasswordForm;
