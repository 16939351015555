import { useState, useLayoutEffect, useRef } from 'react';

/**
 * Same thing as `useState` but `initialValues` have been replaced with
 * `inputValues` that are observed and overwrite the current state when
 * they change.
 *
 * The `inputValues` are considered to change when their JSON representation
 * changes.
 *
 * @param inputValues the input values to reset to whenever they change
 * @returns the current values and a function to set the current values
 */
export function useResettableState<T>(inputValues: T) {
    const oldKeyRef = useRef('');
    const newKey = JSON.stringify(inputValues);
    const [values, setValues] = useState(inputValues);

    // Below correctly checks dependencies assuming
    // the JSON.stringify expression only changes when
    // inputValues changes
    /* eslint-disable react-hooks/exhaustive-deps */
    useLayoutEffect(() => {
        oldKeyRef.current = newKey;
        setValues(inputValues);
    }, [newKey]);
    /* eslint-enable */

    // we need to handle the diff on render (before effect runs and mutates
    // the state) to ensure we return changes to inputValues BEFORE the effect
    // runs. not doing so can lead to unexpected behavior.
    return [oldKeyRef.current === newKey ? values : inputValues, setValues] as const;
}
