import { AxiosResponse } from 'axios';
import { ITechnicalDocument } from '../../../types';
import { api } from '../../api';
import {
    ICompanyViewModel,
    IVendorClassViewModel,
    IBuyerPortalViewModel,
    ITradingPartnerSearchViewModel,
    ITradingPartnerViewModel,
} from './ViewModels';

/**
 * @deprecated This class is deprecated. Use the CompanyApi class from
 * packages\common\src\services\backendServices\Apis\CompanyApi\CompanyApi.ts instead.
 */
export class CompanyApi {
    route = '/Company';

    public getAllBuyerCompanies = async (filter?: string): Promise<AxiosResponse<ICompanyViewModel[] | undefined>> => {
        const params = {
            isBuyer: true,
            $filter: filter,
        };

        return api().get(`${this.route}`, { params });
    };

    public getCompany = async (id: number): Promise<AxiosResponse<ICompanyViewModel[]>> => {
        return api().get(`${this.route}/${id}`);
    };

    public getVendorClasses = async (
        companyId: number
    ): Promise<AxiosResponse<IVendorClassViewModel[] | undefined>> => {
        const params = {
            companyId,
        };

        return api().get(`${this.route}/VendorClass`, { params });
    };

    public updateCompany = async (id: number, companyViewModel: Partial<ICompanyViewModel>): Promise<void> => {
        return api().put(`${this.route}/${id}`, companyViewModel);
    };

    public getBuyerPortalSettings = async (companyId: number): Promise<AxiosResponse<IBuyerPortalViewModel>> => {
        const params = {
            companyId,
        };

        return api().get(`${this.route}/BuyerPortalSettings`, { params });
    };

    public getTradingPartners = async (
        params: Record<string, string | number | boolean | null | undefined>
    ): Promise<AxiosResponse<{ Count: null; Items: ITradingPartnerViewModel[] }>> => {
        return api().get(`${this.route}/TradingPartners`, { params });
    };

    public async downloadTechnicalDoc(params: {
        documentId: string;
    }): Promise<AxiosResponse<ITechnicalDocument[] | undefined>> {
        const headers = {
            Accept: 'application/json, text/plain, */*',
        };

        const response = await api().get(`${this.route}/TechnicalDocuments/${params.documentId}`, {
            params,
            headers,
        });

        return response;
    }

    public async getTechnicalDocuments(
        companyId: number,
        $orderBy?: string,
        $filter = 'Visible eq true',
        $select = 'Id,FileName,Version,Visible,CompanyId,FileByteCount,LastUpdateDate,UserName'
    ): Promise<AxiosResponse<ITechnicalDocument[] | undefined>> {
        const params = {
            companyId,
            $filter,
            $select,
            $orderBy,
        };
        return api().get(`${this.route}/TechnicalDocuments`, { params });
    }

    public getTradingPartnersByCompanyId = async (
        companyId: number,
        count: boolean,
        top: number,
        skip: number | undefined,
        orderBy: string | undefined,
        companyName: string,
        contactName: string,
        contactEmail: string,
        vendorNumber: string,
        vendorClass: string | null,
        includeDisabled: boolean,
        includeVendorTable: boolean,
        vendorSiteNumber: string | null,
        childCompanyId?: string,
        orgUnit?: string
    ): Promise<AxiosResponse<{ Count: number; Items: ITradingPartnerSearchViewModel[] }>> => {
        const params = {
            companyId,
            $count: count,
            $top: top,
            $skip: skip,
            $orderBy: orderBy,
            companyName,
            contactName,
            contactEmail,
            vendorNumber,
            vendorClass,
            includeVendors: includeVendorTable,
            vendorSiteNumber,
            includeDisabled,
            childCompanyId,
            orgUnit,
        };

        return api().get(`${this.route}/TradingPartners/Search`, { params });
    };

    public getBuyerCompaniesForDataGrid = async (name?: string, orderBy?: string) => {
        const params = {
            $filter: name ? `contains(name,'${name}')` : undefined,
            $orderBy: orderBy,
        };

        return api().get(`${this.route}`, { params });
    };

    public postCompanyUserProfile = async (
        profiles: any,
        isSupplierRegistration?: boolean,
        fromVendorFeed?: boolean,
        internalSystemUser?: string,
        isScanKey?: boolean
    ) => {
        const params = {
            isSupplierRegistration,
            internalSystemUser,
            isScanKey,
            fromVendorFeed,
        };

        return api().post(`${this.route}/CompanyUserProfile`, profiles, { params });
    };
}
