/* eslint-disable no-param-reassign,no-return-assign */
import { api } from '../utils';
import apiPaths from '../api/paths';

export const MENU_BAGES_FETCHED = 'MENU_BAGES_FETCHED';
export const MENU_BAGES_FETCHING = 'MENU_BAGES_FETCHING';

export function fetchBage() {
    return (dispatch, getState) => {
        if (!getState().menuBages.total && !getState().menuBages.isLoading) {
            dispatch({ type: MENU_BAGES_FETCHING });
            api()
                .get(apiPaths.parkingLotDocumentsCount)
                .then(({ data: { ParkingLotDocumentCounts } }) => {
                    const payload = ParkingLotDocumentCounts.reduce((acc, item) => {
                        acc[item.BusinessDocType] = item.Count;
                        return acc;
                    }, {});
                    payload.total = Object.keys(payload).reduce((acc, key) => (acc += payload[key]), 0);
                    dispatch({ type: MENU_BAGES_FETCHED, payload });
                });
        }
    };
}
