import { createSelector } from 'reselect';

import { convertLayoutBlock } from '../invoiceCreation';
import projectConfig from '../../project.config.json';
import { cloneObjectHack } from '../../utils/dataConverter';
import {
    reduceFields,
    miscAmountLayoutDoesNotExist,
    addTempMiscAmountLayout,
    modifyLayoutToIncludeBuyerWebEntryTestFields,
} from '../../utils/document/layout';

const businessDocType = (state, editMode, businessDocType) => businessDocType;
const document = (state, editMode, businessDocType) =>
    editMode
        ? businessDocType === projectConfig.businessDocType.Invoice
            ? cloneObjectHack(state.documentCreation.invoiceCreationFields)
            : cloneObjectHack(state.documentCreation.documentCreationFields)
        : cloneObjectHack(state.document.document);
const layouts = (state, editMode, businessDocType) => cloneObjectHack(state.documentLayout.layout);
const processingMode = (state, editMode, businessDocType) =>
    state.selectCustomer && state.selectCustomer.currentCustomer
        ? state.selectCustomer.currentCustomer.ProcessingMode
        : null;
const size = (state, editMode, businessDocType) => state.browser.mediaType;

export default createSelector(
    businessDocType,
    document,
    layouts,
    processingMode,
    size,
    (businessDocType, document, layouts, processingMode, size) => {
        const sizeName = size.charAt(0).toUpperCase() + size.slice(1);
        const layout = layouts && layouts[sizeName] ? layouts[sizeName] : null;
        const layoutBlocks = {
            body: [],
            bodyLayout: [],
            header: [],
            info: {
                hasLineItemFields: false,
                hasToggleableFields: false,
            },
            footer: [],
            footerLayout: [],
            reducedHeader: [],
            rawLayout: layout,
        };

        if (layout) {
            let layoutHeader = layout.Header;
            let layoutBody = layout.Body;
            let layoutFooter = layout.Footer;

            if (document.BusinessDocFields) {
                switch (businessDocType) {
                    case projectConfig.businessDocType.Invoice:
                        const invoice = document.BusinessDocFields.Invoice;
                        if (invoice) {
                            if (
                                processingMode === projectConfig.ProcessingMode.BuyerWebEntryTest ||
                                processingMode === projectConfig.ProcessingMode.BuyerEntry
                            ) {
                                modifyLayoutToIncludeBuyerWebEntryTestFields(layoutHeader);
                            }

                            if (invoice.InvoiceLineItems && invoice.InvoiceLineItems.length) {
                                invoice.InvoiceLineItems.forEach((invoiceLineItem) => {
                                    if (
                                        invoiceLineItem.InvoiceLineItemMiscAmounts &&
                                        invoiceLineItem.InvoiceLineItemMiscAmounts.length
                                    ) {
                                        invoiceLineItem.InvoiceLineItemMiscAmounts.forEach(
                                            (invoiceLineItemMiscAmount) => {
                                                if (
                                                    miscAmountLayoutDoesNotExist(
                                                        layoutBody,
                                                        projectConfig.EntityName.LineItemMiscAmount,
                                                        invoiceLineItemMiscAmount
                                                    )
                                                ) {
                                                    addTempMiscAmountLayout(
                                                        layoutBody,
                                                        projectConfig.EntityName.LineItemMiscAmount,
                                                        invoiceLineItemMiscAmount
                                                    );
                                                }
                                            }
                                        );
                                    }
                                });
                            }

                            if (invoice.InvoiceMiscAmounts && invoice.InvoiceMiscAmounts.length) {
                                invoice.InvoiceMiscAmounts.forEach((invoiceMiscAmount) => {
                                    if (
                                        miscAmountLayoutDoesNotExist(
                                            layoutFooter,
                                            projectConfig.EntityName.MiscAmount,
                                            invoiceMiscAmount
                                        )
                                    ) {
                                        addTempMiscAmountLayout(
                                            layoutFooter,
                                            projectConfig.EntityName.MiscAmount,
                                            invoiceMiscAmount
                                        );
                                    }
                                });
                            }
                        }
                        break;
                    case projectConfig.businessDocType.PurchaseOrder:
                        const purchaseOrder = document.BusinessDocFields.PurchaseOrder;
                        if (purchaseOrder) {
                            if (purchaseOrder.PurchaseOrderLineItems && purchaseOrder.PurchaseOrderLineItems.length) {
                                purchaseOrder.PurchaseOrderLineItems.forEach((purchaseOrderLineItem) => {
                                    if (
                                        purchaseOrderLineItem.PurchaseOrderLineItemMiscAmounts &&
                                        purchaseOrderLineItem.PurchaseOrderLineItemMiscAmounts.length
                                    ) {
                                        purchaseOrderLineItem.PurchaseOrderLineItemMiscAmounts.forEach(
                                            (purchaseOrderLineItemMiscAmount) => {
                                                if (
                                                    miscAmountLayoutDoesNotExist(
                                                        layoutBody,
                                                        projectConfig.EntityName.LineItemMiscAmount,
                                                        purchaseOrderLineItemMiscAmount
                                                    )
                                                ) {
                                                    addTempMiscAmountLayout(
                                                        layoutBody,
                                                        projectConfig.EntityName.LineItemMiscAmount,
                                                        purchaseOrderLineItemMiscAmount
                                                    );
                                                }
                                            }
                                        );
                                    }
                                });
                            }

                            if (
                                purchaseOrder.PurchaseOrderMiscAmounts &&
                                purchaseOrder.PurchaseOrderMiscAmounts.length
                            ) {
                                purchaseOrder.PurchaseOrderMiscAmounts.forEach((purchaseOrderMiscAmount) => {
                                    if (
                                        miscAmountLayoutDoesNotExist(
                                            layoutFooter,
                                            projectConfig.EntityName.MiscAmount,
                                            purchaseOrderMiscAmount
                                        )
                                    ) {
                                        addTempMiscAmountLayout(
                                            layoutFooter,
                                            projectConfig.EntityName.MiscAmount,
                                            purchaseOrderMiscAmount
                                        );
                                    }
                                });
                            }
                        }
                        break;
                    case projectConfig.businessDocType.PurchaseOrderAcknowledgement:
                        const poa =
                            document &&
                            document.BusinessDocFields &&
                            document.BusinessDocFields.BusinessDocument &&
                            document.BusinessDocFields.BusinessDocument.Document &&
                            document.BusinessDocFields.BusinessDocument.Document.BusinessDocument
                                ? document.BusinessDocFields.BusinessDocument.Document.BusinessDocument
                                      .PurchaseOrderAcknowledgement
                                : null;
                        if (poa) {
                            if (poa.LineItems && poa.LineItems.LineItem && poa.LineItems.LineItem.length) {
                                poa.LineItems.LineItem.forEach((poaLineItem) => {
                                    if (
                                        poaLineItem.MiscAmounts &&
                                        poaLineItem.MiscAmounts.MiscAmount &&
                                        poaLineItem.MiscAmounts.MiscAmount.length
                                    ) {
                                        poaLineItem.MiscAmounts.MiscAmount.forEach((poaLineItemMiscAmount) => {
                                            if (
                                                miscAmountLayoutDoesNotExist(
                                                    layoutBody,
                                                    projectConfig.EntityName.LineItemMiscAmount,
                                                    poaLineItemMiscAmount
                                                )
                                            ) {
                                                addTempMiscAmountLayout(
                                                    layoutBody,
                                                    projectConfig.EntityName.LineItemMiscAmount,
                                                    poaLineItemMiscAmount
                                                );
                                            }
                                        });
                                    }
                                });
                            }

                            if (poa.MiscAmounts && poa.MiscAmounts.MiscAmount && poa.MiscAmounts.MiscAmount.length) {
                                poa.MiscAmounts.MiscAmount.forEach((poaMiscAmount) => {
                                    if (
                                        miscAmountLayoutDoesNotExist(
                                            layoutFooter,
                                            projectConfig.EntityName.MiscAmount,
                                            poaMiscAmount
                                        )
                                    ) {
                                        addTempMiscAmountLayout(
                                            layoutFooter,
                                            projectConfig.EntityName.MiscAmount,
                                            poaMiscAmount
                                        );
                                    }
                                });
                            }
                        }
                        break;
                    default:
                        break;
                }
            }

            layoutBlocks.header = convertLayoutBlock(layoutHeader);
            layoutBlocks.reducedHeader = layoutBlocks.header.reduce(reduceFields, { fields: [] });
            layoutBlocks.bodyLayout = convertLayoutBlock(layoutBody);
            layoutBlocks.body = layoutBlocks.bodyLayout.reduce(reduceFields, { fields: [] });
            layoutBlocks.footerLayout = convertLayoutBlock(layoutFooter);
            layoutBlocks.footer = layoutBlocks.footerLayout.reduce(reduceFields, { fields: [] });

            layoutBlocks.info.hasLineItemFields = layoutBlocks.body.fields && layoutBlocks.body.fields.length > 0;
            layoutBlocks.info.hasToggleableFields =
                layoutBlocks.reducedHeader.fields.some((field) => field.ShowVisibilityToggleCheckbox === true) ||
                layoutBlocks.body.fields.some((field) => field.ShowVisibilityToggleCheckbox === true) ||
                layoutBlocks.footer.fields.some((field) => field.ShowVisibilityToggleCheckbox === true);
        }

        return layoutBlocks;
    }
);
