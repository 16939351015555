import { Loading } from './Loading';
import { FC } from 'react';
import { Stack } from '@mui/material';

export interface ILoadingMessage {
    testId?: string;
}

export const LoadingMessage: FC<ILoadingMessage> = ({ testId, children }) => {
    return (
        <Stack data-testid={testId} sx={{ alignItems: 'center' }}>
            <Loading /> {children}
        </Stack>
    );
};
