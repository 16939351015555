import { UnexpectedCase } from '../../../../../utils/UnexpectedCase';
import { Touchpoint } from '../../../ScriptingEngineEnvironment';

export function defaultValueByConfigParamType(
    definition: Touchpoint.Config.ConfigParamDefinition<Touchpoint.Config.ConfigParamType>
) {
    if (definition.type === 'string') {
        return '';
    }

    if (definition.type === 'boolean') {
        return false;
    }

    if (definition.type === 'number') {
        return 0;
    }

    if (definition.type === 'Array<number>' || definition.type === 'Array<string>') {
        return [];
    }

    throw new UnexpectedCase(definition.type);
}
