import { IInternalRule } from '../types/public';

export const testInternalRule: IInternalRule = {
    name: 'testInternalRule',
    logic: (app) => {
        app.registerValidationFunction((state, results) => {
            if (state.documentState.BusinessDocFields.Invoice.InvoiceType === 'foo') {
                results.addError({
                    errorCode: 'foo',
                    titleArguments: [],
                    detailArguments: [],
                    associatedField: app.services.associatedField.headerField('InvoiceType'),
                });
            }
        });
    },
    fieldNamesToValidateOnFocusLoss: ['InvoiceType'],
    eventsToValidateOn: [
        'FocusLossEvent',
        'WebEntrySaveAndPreviewEvent',
        'WorkflowLoadEvent',
        'WorkflowReassignEvent',
        'WorkflowSaveEvent',
        'WorkflowSubmitEvent',
    ],
    validationFailureTypes: [
        {
            errorCode: 'foo',
            titleText: 'Foo',
            detailText: null,
        },
    ],
    enabledContexts: ['UserWorkflowActivityParams', 'WebEntryParams'],
};
