import { useContext } from 'react';
import { GridRowCheckboxContext } from '../../Organisms/Grid/GridRowCheckboxContext';
import { Checkbox } from '../../../../ui';

export const HeaderCheckboxCell = ({ records }) => {
    const contextState = useContext(GridRowCheckboxContext);
    if (!contextState.areCheckboxesEnabled) {
        return null;
    }

    function getHeaderCheckboxState(contextState, records) {
        if (contextState.checkedBoxes.length === records.length) {
            return 'checked';
        }

        if (contextState.checkedBoxes.length > 0) {
            return 'indeterminate';
        }

        return 'unchecked';
    }

    const state = getHeaderCheckboxState(contextState, records);

    function updateHeaderCheckbox() {
        if (state === 'unchecked') {
            contextState.updateCheckedBoxes(
                records.map((record) => {
                    return record.Id;
                })
            );
        } else {
            contextState.updateCheckedBoxes([]);
        }
    }
    return (
        <span>
            <Checkbox
                checked={state === 'checked'}
                indeterminate={state === 'indeterminate'}
                onChange={() => updateHeaderCheckbox()}
            />
        </span>
    );
};
