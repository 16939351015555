export function documentsFetching(searchParams, skip, FETCHING_ACTION_TYPE) {
    return {
        type: FETCHING_ACTION_TYPE,
        payload: {
            searchParams: searchParams,
            newSkip: skip,
        },
    };
}

export function documentsFetched(filteredDocuments, totalEntries, searchParams, skip, orderBy, FETCHED_ACTION_TYPE) {
    return {
        type: FETCHED_ACTION_TYPE,
        payload: {
            filteredDocuments: filteredDocuments || [],
            totalEntries: totalEntries,
            searchParams: searchParams,
            skip: skip,
            orderBy: orderBy,
        },
    };
}

export function applySorting(column, config, SORTING_ACTION_TYPE) {
    return {
        type: SORTING_ACTION_TYPE,
        payload: {
            column,
            type: config.find((col) => col.key === column).type.name,
        },
    };
}

export const clearSearch = (CLEAR_SEARCH) => {
    return {
        type: CLEAR_SEARCH,
    };
};
