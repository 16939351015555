import { FC } from 'react';
import { IRoutes } from '../../../transcepta-types';
import { IMenuSettings } from '../../../transcepta-common';
import DashboardIcon from './DashboardIcon';

import IconInvoices from './icons/icon-invoices.png';
import IconPO from './icons/icon-po.png';
import IconSIM from './icons/icon-sim.png';

import './DashboardIcons.scss';

export interface IDashboardIconsProps {
    projectConfig: any;
    url: string;
    strings: any;
    R: IRoutes;
    menuSettings?: IMenuSettings;

    parseVisibility: (type: number) => boolean;
}

/**
 * @deprecated
 */
const DashboardIcons: FC<IDashboardIconsProps> = (props) => {
    return (
        <div className="dashboard-icons-container flexBlock fillWidth">
            <div className={`dashboard-icons dash-nav-8`}>
                {props.parseVisibility(props.projectConfig.businessDocType.Invoice) ? (
                    <DashboardIcon
                        to={props.R.DOCUMENT_SEARCH.path.replace(
                            ':businessDocType',
                            props.projectConfig.businessDocTypePathName.Invoice
                        )}
                    >
                        <div className="img-holder">
                            <img src={IconInvoices} className="img-inv" alt="Invoices" />
                        </div>
                        <span>{props.strings.textInvoices}</span>
                    </DashboardIcon>
                ) : null}
                {props.parseVisibility(props.projectConfig.businessDocType.PurchaseOrder) ? (
                    <DashboardIcon
                        to={props.R.DOCUMENT_SEARCH.path.replace(
                            ':businessDocType',
                            props.projectConfig.businessDocTypePathName.PurchaseOrder
                        )}
                    >
                        <div className="img-holder">
                            <img src={IconPO} className="img-po" alt="Purchase Orders" />
                        </div>
                        <span>{props.strings.textsPOs}</span>
                    </DashboardIcon>
                ) : null}
                {props.parseVisibility(props.projectConfig.businessDocType.PurchaseOrderAcknowledgement) ? (
                    <DashboardIcon
                        to={props.R.DOCUMENT_SEARCH.path.replace(
                            ':businessDocType',
                            props.projectConfig.businessDocTypePathName.PurchaseOrderAcknowledgement
                        )}
                    >
                        <div className="img-holder">
                            <div className={`poa-animation-8`} />
                        </div>
                        <span>{props.strings.textPOAs}</span>
                    </DashboardIcon>
                ) : null}
                {props.parseVisibility(props.projectConfig.businessDocType.ASNGeneric) ? (
                    <DashboardIcon
                        to={props.R.DOCUMENT_SEARCH.path.replace(
                            ':businessDocType',
                            props.projectConfig.businessDocTypePathName.ASNGeneric
                        )}
                    >
                        <div className="img-holder">
                            <div className={`asn-animation-a-8`} />
                            <div className={`asn-animation-b-8`} />
                            <div className={`asn-animation-c-8`} />
                        </div>
                        <span>{props.strings.textASNs}</span>
                    </DashboardIcon>
                ) : null}
                {props.parseVisibility(props.projectConfig.businessDocType.InventoryAdvice) ? (
                    <DashboardIcon
                        to={props.R.DOCUMENT_SEARCH.path.replace(
                            ':businessDocType',
                            props.projectConfig.businessDocTypePathName.InventoryAdvice
                        )}
                    >
                        <div className="img-holder">
                            <div className={`inventory-advice-animation-8`} />
                        </div>
                        <span>{props.strings.textInventoryAdvices}</span>
                    </DashboardIcon>
                ) : null}
                {props.parseVisibility(props.projectConfig.businessDocType.SIMDocument) ? (
                    <DashboardIcon
                        to={props.R.DOCUMENT_SEARCH.path.replace(
                            ':businessDocType',
                            props.projectConfig.businessDocTypePathName.SIMDocument
                        )}
                    >
                        <div className="img-holder">
                            <img src={IconSIM} className="img-po" alt="SIM Documents" />
                        </div>
                        <span>{props.strings.textSIMDocuments}</span>
                    </DashboardIcon>
                ) : null}
                {props.menuSettings && props.menuSettings.EnableDynamicDiscounting ? (
                    <DashboardIcon to={props.R.EARLY_PAYMENTS.path}>
                        <div className="img-holder">
                            <div className="stopwatch0001" />
                        </div>
                        <span>{props.strings.textEarlyPayments}</span>
                    </DashboardIcon>
                ) : null}
                {props.menuSettings && !props.menuSettings.IsParkingLotOnly ? (
                    <DashboardIcon to={props.R.REPORTING.path}>
                        <div className="img-holder">
                            <div className="img-doc-wrapper">
                                <div className="bar-chart">
                                    <span className={`blue-bar-8`} />
                                    <span className={`grey-bar-8`} />
                                    <span className={`orange-bar-8`} />
                                </div>
                            </div>
                        </div>
                        <span>{props.strings.textReporting}</span>
                    </DashboardIcon>
                ) : null}
                <DashboardIcon to={props.R.HELP_CENTER.path}>
                    <div className="img-holder">
                        <div className={`help-animation-8`} />
                    </div>
                    <span>{props.strings.textHelpCenter}</span>
                </DashboardIcon>
            </div>
        </div>
    );
};

export default DashboardIcons;
