import { v1 as uuidv1 } from 'uuid';
import { IFieldType } from './IFieldType';
import { WithOptional } from './WithOptional';

/**
 * Create a factory/constructor function for a field type.
 *
 * The constructor will provide defaults for the "id" and "visibility" fields.
 */
export function createFieldTypeFactory<T extends IFieldType<any>>(type: T['type'], emptyValue: T['emptyValue']) {
    return (value: Omit<WithOptional<T, 'visibility' | 'id' | 'readOnly'>, 'type' | 'emptyValue'>) => {
        return {
            ...value,
            type,
            id: value.id ?? uuidv1(),
            visibility: value.visibility,
            readOnly: value.readOnly,
            emptyValue,
        };
    };
}
