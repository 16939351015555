import { useQuery } from '@tanstack/react-query';
import { ControlFormatFields } from '../../parseControlFormatFields';
import { useRelatedDocumentsState } from '../../RelatedDocumentsContext';
import { extractVendorNumber, searchForRelatedDocument } from './searchForRelatedDocument';
import { SearchState } from './SearchState';

export function useSearchState(formatting: ControlFormatFields, value: string): SearchState {
    const { document: currentDocument, isBuyer } = useRelatedDocumentsState();
    const { relatedDocumentType, filterByVendorNumber } = formatting;

    const vendorNumber = !!currentDocument ? extractVendorNumber(currentDocument) : null;

    const isMissingRequiredVendorNumber = filterByVendorNumber && !vendorNumber;

    // When filterByVendorNumber is false, vendor number changes shouldn't trigger new requests.
    const vendorNumberParam = filterByVendorNumber ? vendorNumber : null;

    const { data } = useQuery(
        ['RelatedDocumentSearch', relatedDocumentType, value, vendorNumberParam],
        async () => {
            const res = await searchForRelatedDocument({
                relatedDocumentType,
                relatedDocumentNumber: value,
                isBuyer,
                vendorNumber: vendorNumberParam,
            });

            return res;
        },
        {
            enabled: !!value && !isMissingRequiredVendorNumber,
            cacheTime: 0,
            staleTime: 0,
        }
    );

    if (!value || isMissingRequiredVendorNumber) {
        return {
            type: 'NoSearchPerformedState',
        };
    }

    if (data === undefined) {
        return {
            type: 'PerformingSearchState',
        };
    }

    if (data === null) {
        return {
            type: 'NoRelatedDocumentFoundState',
        };
    }

    if (!filterByVendorNumber && data.vendorNumber?.toString().trim() !== vendorNumber?.toString().trim()) {
        return {
            type: 'RelatedDocumentFoundWithDifferentVendorState',
            relatedDocumentId: data.id,
        };
    }

    return {
        type: 'RelatedDocumentFoundState',
        relatedDocumentId: data.id,
    };
}
