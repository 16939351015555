import { AxiosInstance } from 'axios';

let providedApiGetter: (() => AxiosInstance) | null = null;

export function api() {
    if (!providedApiGetter) {
        throw new Error('Attempted to access api() before it was provided.');
    }

    return providedApiGetter();
}

export function provideApi(apiGetter: () => AxiosInstance) {
    providedApiGetter = apiGetter;
}
