// DEPRECATED: The code in this module should not be used in any new places
// REFACTOR: Move this inside of Molecules/DataRow since that is the only place that uses it

import { format } from 'date-fns';

enum CellTypes {
    label = 'label',
    icon = 'icon',
}

const getFormattedData = (data, config) => {
    if (config.name.includes('Date')) {
        return format(new Date(data), 'P');
    }
    if (config.name.includes('Time')) {
        return format(new Date(data), 'Pp');
    }
    return String(data);
};

/**
 * @deprecated
 */
export const cellAdapter = (config, data) => {
    if (!data) {
        return { cellType: CellTypes.label, data: { text: '' } };
    }
    if (config.type === Boolean && data) {
        return { cellType: CellTypes.icon, data: { type: 'success', style: null } };
    }
    if (config.name.includes('Date') || config.name.includes('Time')) {
        const formattedData = getFormattedData(data, config);
        return { cellType: CellTypes.label, data: { text: formattedData } };
    }

    return { cellType: CellTypes.label, data: { text: data } };
};
