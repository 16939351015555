import { isAfter } from 'date-fns';

export const isUserLoggedIn = () => {
    const storedisLoggedIn = !!localStorage.is_logged_in;
    const storedTokenExpires =
        localStorage.getItem('token_expires') && typeof localStorage.getItem('token_expires') === 'string'
            ? new Date(localStorage.getItem('token_expires')!)
            : null;
    return storedisLoggedIn && storedTokenExpires && isAfter(storedTokenExpires!, new Date());
};
