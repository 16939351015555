export function setSessionCookie(name: string, value: string) {
    document.cookie = `${name}=${value}; path=/`;
}

export function setPersistentCookie(name: string, value: string, expirationDate: Date) {
    document.cookie = `${name}=${value}; path=/; expires=${expirationDate.toUTCString()}`;
}

export function getCookie(name: string): string | null {
    name += '=';
    const decodedCookies = decodeURIComponent(document.cookie);
    const cookies = decodedCookies.split('; ');
    let result: string | null = null;
    cookies.forEach((val) => {
        if (val.indexOf(name) === -1) {
            return;
        }
        result = val.substring(name.length);
    });
    return result;
}
