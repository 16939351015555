import { XMLParser } from 'fast-xml-parser';
import { IDocumentFlipSearchGridItem, IDocumentFlipSearchGrid, IBusinessDocFlipDropdownOption } from './types';
import { BusinessDocFlipApi } from '../Api';
import { isFeatureEnabled } from '../../../config';
import { smallDateFormat, OldXMLParser, ascendingOrderByKey, arrayUtils } from '../../../utils';
import { IBusinessDocFlipData, IBusinessDocFlip } from './types/IBusinessDocFlip';
import { IBusinessDocFlipViewModel, IBusinessDocFlipViewModelData } from '../Api/ViewModels/BusinessDocFlipViewModel';
import { validateData, EntityNotFoundError } from '../../../ui';
import { BusinessDocTypeService } from '../../BusinessDocType';
import { CompanyService } from '../../Company';
import { createBusinessDocFlipGridFilter } from './BusinessDocFlipApi.filter';
import { ReactNode } from 'react';

export class BusinessDocFlipService {
    private api = new BusinessDocFlipApi();

    private businessDocTypeService = new BusinessDocTypeService();

    private companyService = new CompanyService();

    public getBusinessDocumentFlipsForDropdown = async (buyerCompanyId: number) => {
        // create function to create dropdown options from the view models returned by API
        const getDocumentTypeName = await this.businessDocTypeService.getGetDocumentTypeName();
        const createDropdownOption = (value: IBusinessDocFlipViewModel): IBusinessDocFlipDropdownOption => ({
            label: getDocumentTypeName(value.DestinationBusinessDocTypeId, value.DestinationBusinessDocSubTypeId),
            // TODO: in future we will need to pass back both the top level and sub id for the value
            value: value.DestinationBusinessDocTypeId,
        });

        // get base and company-specific options from API
        // API returns the base list and company specific list in one call
        const dropdownOptions = await (async () => {
            const response = await this.api.getBusinessDocFlipsByCompany(buyerCompanyId);
            return response.data.map(createDropdownOption);
        })();

        const sortedDropdownOptions = dropdownOptions.sort(ascendingOrderByKey('label'));
        return sortedDropdownOptions;
    };

    public getAllDocumentFlipsForDataGrid = async (
        id: string | null,
        buyerCompanyId: number | null,
        name: string | null,
        sourceDocType: number | null,
        destinationDocType: number | null
    ): Promise<IDocumentFlipSearchGrid> => {
        const getDocumentTypeName = await this.businessDocTypeService.getGetDocumentTypeName();
        const getBuyerCompanyName = await this.companyService.getBuyerCompanyName();

        const filters = createBusinessDocFlipGridFilter(id, buyerCompanyId, name, sourceDocType, destinationDocType);
        const response = await this.api.getBusinessDocFlips({
            $filter: filters || undefined,
        });

        return {
            count: response.data.length,
            items: response.data.map(
                (item): IDocumentFlipSearchGridItem => ({
                    id: item.Id,
                    buyerCompanyName: getBuyerCompanyName(item.CompanyId),
                    name: item.Name,
                    description: item.Description,
                    sourceDocType: getDocumentTypeName(item.SourceBusinessDocTypeId, item.SourceBusinessDocSubTypeId),
                    destinationDocType: getDocumentTypeName(
                        item.DestinationBusinessDocTypeId,
                        item.DestinationBusinessDocSubTypeId
                    ),
                    lastUpdateDate: smallDateFormat(new Date(item.LastUpdateDate)),
                })
            ),
        };
    };

    public getBusinessDocumentFlip = async (businesDocFlipId: number): Promise<IBusinessDocFlip> => {
        const response = await this.api.getBusinessDocumentFlip(businesDocFlipId);

        if (response.data.length === 0) {
            throw new EntityNotFoundError(businesDocFlipId);
        }

        const docFlipArray = response.data.map(
            (item: IBusinessDocFlipViewModel): IBusinessDocFlip => ({
                id: item.Id,
                buyerCompanyId: item.CompanyId,
                name: item.Name,
                description: item.Description,
                sourceBusinessDocTypeId: item.SourceBusinessDocTypeId,
                sourceBusinessDocSubTypeId: item.SourceBusinessDocSubTypeId || null,
                destinationBusinessDocTypeId: item.DestinationBusinessDocTypeId,
                destinationBusinessDocSubTypeId: item.DestinationBusinessDocSubTypeId || null,
                flipMapping: item.FlipMapping,
            })
        );

        return docFlipArray[0];
    };

    private validateBusinessDocFlip(businessDocFlip: IBusinessDocFlipData) {
        validateData((errors) => {
            if (!businessDocFlip.name) {
                errors.push('Document Name is required');
            }

            if (!businessDocFlip.description) {
                errors.push('Description is required');
            }

            if (!businessDocFlip.sourceBusinessDocTypeId) {
                errors.push('Source Business Document Type is required');
            }

            if (!businessDocFlip.destinationBusinessDocTypeId) {
                errors.push('Destination Business Document Type is required');
            }

            if (!businessDocFlip.flipMapping) {
                errors.push('Flip Mapping is required');
            } else {
                this.validateFlipMapping(businessDocFlip.flipMapping, errors);
            }
        });
    }

    private validateFlipMapping(flipMapping: string, errors: ReactNode[]) {
        try {
            const parser = isFeatureEnabled('XMLParserSwitch') ? new XMLParser() : new OldXMLParser({});
            parser.parse(flipMapping);
        } catch (e: any) {
            errors.push(`Invalid Flip Mapping XML: ${e.message}`);
        }
    }

    private toViewModel(businessDocFlipFields: IBusinessDocFlipData) {
        const businessDocFlipViewModel: IBusinessDocFlipViewModelData = {
            CompanyId: businessDocFlipFields.buyerCompanyId,
            Name: businessDocFlipFields.name,
            Description: businessDocFlipFields.description,
            FlipMapping: businessDocFlipFields.flipMapping,
            SourceBusinessDocTypeId: businessDocFlipFields.sourceBusinessDocTypeId ?? 0,
            SourceBusinessDocSubTypeId: businessDocFlipFields.sourceBusinessDocSubTypeId ?? 0,
            DestinationBusinessDocTypeId: businessDocFlipFields.destinationBusinessDocTypeId ?? 0,
            DestinationBusinessDocSubTypeId: businessDocFlipFields.destinationBusinessDocSubTypeId ?? 0,
        };

        return businessDocFlipViewModel;
    }

    public updateBusinessDocumentFlip = async (
        businessDocFlipId: number,
        businessDocFlip: IBusinessDocFlipData
    ): Promise<void> => {
        this.validateBusinessDocFlip(businessDocFlip);
        await this.api.updateBusinessDocumentFlip(businessDocFlipId, this.toViewModel(businessDocFlip));
    };

    public insertBusinessDocumentFlip = async (businessDocFlip: IBusinessDocFlipData): Promise<number> => {
        this.validateBusinessDocFlip(businessDocFlip);
        const response = await this.api.insertBusinessDocumentFlip(this.toViewModel(businessDocFlip));
        return response.data.ID;
    };

    public deleteBusinessDocFlip = async (businessDocFlipId: number) => {
        await this.api.deleteBusinessDocumentFlip(businessDocFlipId);
    };

    static defaultCRUDFields: IBusinessDocFlipData = {
        name: '',
        buyerCompanyId: null,
        description: '',
        sourceBusinessDocTypeId: null,
        sourceBusinessDocSubTypeId: null,
        destinationBusinessDocTypeId: null,
        destinationBusinessDocSubTypeId: null,
        flipMapping: '',
    };
}
