import { IFieldType, createFieldTypeFactory } from '../infrastructure';

/**
 * A TextField field object.
 */
export interface ITextField extends IFieldType<string> {
    type: 'ITextField';
}

/**
 * Creates a TextField field object.
 */
export const createTextField = createFieldTypeFactory<ITextField>('ITextField', '');
