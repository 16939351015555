import { BusinessDocType } from '../../../../../types';
import { isAllowedEnumKey } from '../../../../../utils/enum';
import { Touchpoint, Utility } from '../../../ScriptingEngineEnvironment';
import { ConfigTouchpointExtractedMetadata } from './ConfigTouchpointExtractedMetadata';
import { ConfigTouchpointStoredMetadata } from './ConfigTouchpointStoredMetadata';
import { defaultValueByConfigParamType } from './defaultValueByConfigParamType';

export class ConfigTouchpoint implements Touchpoint.Config.IConfigTouchpoint {
    private data: ConfigTouchpointStoredMetadata = {
        ruleType: null,
        ruleName: null,
        businessDocType: null,
        executionContexts: [],
        configParams: [],
    };

    ruleType(ruleType: string): void {
        if (!isAllowedEnumKey(Utility.RuleType, ruleType)) {
            throw new Error(`Rule called \`app.config.ruleType()\` with an invalid type: ${ruleType}`);
        }

        this.data.ruleType = Utility.RuleType[ruleType];
    }

    ruleName(ruleName: string): void {
        this.data.ruleName = ruleName;
    }

    businessDocType(businessDocType: string): void {
        if (!isAllowedEnumKey(BusinessDocType, businessDocType)) {
            throw new Error(`Rule called \`app.config.businessDocType()\` with an invalid type: ${businessDocType}`);
        }

        this.data.businessDocType = BusinessDocType[businessDocType];
    }

    allowExecutionContext(executionContext: string): void {
        if (!isAllowedEnumKey(Utility.ExecutionContext.ExecutionContextType, executionContext)) {
            throw new Error(
                `Rule called \`app.config.allowExecutionContext()\` with an invalid type: ${executionContext}`
            );
        }

        this.data.executionContexts.push(Utility.ExecutionContext.ExecutionContextType[executionContext]);
    }

    param<T extends Touchpoint.Config.ConfigParamType>(
        definition: Touchpoint.Config.ConfigParamDefinition<T>
    ): Touchpoint.Config.ConfigParamValueType<T> {
        // ensure we have an actual object for the param definition
        if (typeof definition !== 'object' || !definition) {
            throw new Error(
                `Rule called \`app.config.param()\` with invalid value for param = ${JSON.stringify(definition)}`
            );
        }

        // check that the non-defaultValue properties are valid

        if (typeof definition.configurableByCompany !== 'boolean') {
            throw new Error(
                `Rule called \`app.config.param()\` with invalid value for param.configurableByCompany = ${JSON.stringify(
                    definition.configurableByCompany
                )}`
            );
        }

        if (typeof definition.description !== 'string') {
            throw new Error(
                `Rule called \`app.config.param()\` with invalid value for param.description = ${JSON.stringify(
                    definition.description
                )}`
            );
        }

        if (typeof definition.label !== 'string') {
            throw new Error(
                `Rule called \`app.config.param()\` with invalid value for param.label = ${JSON.stringify(
                    definition.label
                )}`
            );
        }

        if (typeof definition.name !== 'string') {
            throw new Error(
                `Rule called \`app.config.param()\` with invalid value for param.name = ${JSON.stringify(
                    definition.name
                )}`
            );
        }

        if (typeof definition.errorCode !== 'string' || definition.errorCode.length === 0) {
            throw new Error(
                `Rule called \`app.config.param()\` with invalid value for param.errorCode = ${JSON.stringify(
                    definition.errorCode
                )}`
            );
        }

        if (
            definition.type !== 'string' &&
            definition.type !== 'boolean' &&
            definition.type !== 'number' &&
            definition.type !== 'Array<number>' &&
            definition.type !== 'Array<string>'
        ) {
            throw new Error(
                `Rule called \`app.config.param()\` with invalid value for param.type = ${JSON.stringify(
                    definition.type
                )}`
            );
        }

        // perform cross-checking of the data type and default value (ensure the default value, if provided, matches the type)

        const crossCheckPrimitiveType = (type: 'string' | 'boolean' | 'number') => {
            if (
                definition.type === type &&
                typeof definition.defaultValue !== type &&
                typeof definition.defaultValue !== 'undefined'
            ) {
                throw new Error(
                    `Rule called \`app.config.param()\` with param.type = ${JSON.stringify(
                        definition.type
                    )} and invalid param.defaultValue = ${JSON.stringify(definition.defaultValue)}`
                );
            }
        };

        const crossCheckArrayType = (type: 'string' | 'number') => {
            // pass check if definition is not of an array type
            if (definition.type !== `Array<${type}>`) {
                return;
            }

            // pass check if default value is undefined
            if (definition.defaultValue === undefined) {
                return;
            }

            if (!Array.isArray(definition.defaultValue)) {
                throw new Error(
                    `Rule called \`app.config.param()\` with param.type = ${JSON.stringify(
                        definition.type
                    )} and invalid param.defaultValue = ${JSON.stringify(definition.defaultValue)}`
                );
            }

            const checkArrayValue = (value: unknown) => {
                if (typeof value !== type) {
                    throw new Error(
                        `Rule called \`app.config.param()\` with param.type = ${JSON.stringify(
                            definition.type
                        )} and invalid param.defaultValue = ${JSON.stringify(definition.defaultValue)}`
                    );
                }
            };

            definition.defaultValue.forEach(checkArrayValue);
        };

        crossCheckPrimitiveType('string');
        crossCheckPrimitiveType('number');
        crossCheckPrimitiveType('boolean');
        crossCheckArrayType('string');
        crossCheckArrayType('number');

        // store the config param
        this.data.configParams.push(definition);

        // be careful to keep the return type of this correct if new datatypes are supported in future.
        return defaultValueByConfigParamType(definition) as any;
    }

    getExtractedData(): ConfigTouchpointExtractedMetadata {
        if (!this.data.ruleType) {
            throw new Error('Rule did not call `app.config.ruleType()` to set its type, as required.');
        }

        if (!this.data.ruleName) {
            throw new Error('Rule did not call `app.config.ruleName()` to set its name, as required.');
        }

        if (!this.data.businessDocType) {
            throw new Error(
                'Rule did not call `app.config.businessDocType()` to set the BusinessDocType it works with, as required.'
            );
        }

        if (this.data.executionContexts.length === 0) {
            throw new Error(
                'Rule did not call `app.config.allowExecutionContext()` to set at least one allowed execution context, as required.'
            );
        }

        return {
            ruleType: this.data.ruleType,
            ruleName: this.data.ruleName,
            businessDocType: this.data.businessDocType,
            executionContexts: this.data.executionContexts,
            configParams: this.data.configParams,
        };
    }
}
