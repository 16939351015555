import { FC, ReactNode } from 'react';
import {
    Accordion as MuiAccordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails as MuiAccordionDetails,
    Typography,
    SxProps,
    TypographyProps,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';

export interface IAccordionProps {
    title: string;
    variant?: 'default' | 'transparent';
    defaultExpanded?: boolean;
    extraIcon?: ReactNode;
    sx?: SxProps<Theme>;
    typographySx?: SxProps<Theme>;
    typographyProps?: TypographyProps;
    accordionSummarySx?: SxProps<Theme>;
    accordionDetailsSx?: SxProps<Theme>;
    disableGutters?: boolean;
    expanded?: boolean;
    testId?: string;
}

export const Accordion: FC<IAccordionProps> = ({
    title,
    defaultExpanded,
    children,
    extraIcon,
    sx,
    typographySx,
    typographyProps,
    accordionSummarySx,
    disableGutters,
    expanded,
    variant = 'default',
    accordionDetailsSx,
    testId,
}) => {
    return (
        <MuiAccordion
            data-testid={testId}
            expanded={expanded}
            defaultExpanded={defaultExpanded}
            sx={[
                variant === 'transparent' && {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                    '&::before': {
                        display: 'none',
                    },
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            disableGutters={disableGutters}
        >
            <MuiAccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                expandIcon={<ExpandMore />}
                sx={{ ...accordionSummarySx }}
            >
                {/* eslint-disable react/jsx-props-no-spreading */}
                <Typography
                    component="h3"
                    variant="h6"
                    sx={{ alignSelf: 'center', ...typographySx }}
                    {...typographyProps}
                >
                    {title}
                </Typography>
                {extraIcon}
            </MuiAccordionSummary>
            <MuiAccordionDetails sx={accordionDetailsSx}>{children}</MuiAccordionDetails>
        </MuiAccordion>
    );
};
