import { createContext, useContext, useState } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import ErrorCodes from './ErrorCodes';
import ErrorMessages from './ErrorMessages';
import { AlertV2 } from '../Atoms';

interface IApiErrorContext {
    errorCodes: string[];
    setErrorCodes: (errorCodes: string[]) => void;
    withDefaultErrorHandling: <T extends Function>(fn: T) => T;
    withDefaultErrorHandlingAndBackendMessaging: <T extends Function>(fn: T) => T;
}

export const ApiErrorContext = createContext({} as IApiErrorContext);

const useStyles = makeStyles(() => ({
    alert: {
        width: '100%',
        marginBottom: 16,
    },
}));

export const useApiErrors = () => useContext(ApiErrorContext);

export const ApiErrorProvider = ({ children }) => {
    const classes = useStyles();
    const [errorCodes, setErrorCodes] = useState<string[]>([]);

    const withDefaultErrorHandling = <T extends Function>(fn: T) => {
        return (async (...args: any[]) => {
            try {
                return await fn(...args);
            } catch (err: any) {
                if (axios.isAxiosError(err)) {
                    setErrorCodes(err?.response?.data?.errors?.map((e) => ErrorMessages[ErrorCodes[e.Code]]));
                }
                throw err;
            }
        }) as Function as T;
    };

    const withDefaultErrorHandlingAndBackendMessaging = <T extends Function>(fn: T) => {
        return (async (...args: any[]) => {
            try {
                return await fn(...args);
            } catch (err: any) {
                if (axios.isAxiosError(err)) {
                    setErrorCodes(err?.response?.data?.errors?.map((e) => e.Message));
                }
                throw err;
            }
        }) as Function as T;
    };

    const apiErrorContext: IApiErrorContext = {
        errorCodes,
        setErrorCodes,
        withDefaultErrorHandling,
        withDefaultErrorHandlingAndBackendMessaging,
    };

    return (
        <ApiErrorContext.Provider value={apiErrorContext}>
            {errorCodes?.map((code) => (
                <AlertV2 key={code} severity="error" testId={`${code}-error-alert`} classes={{ root: classes.alert }}>
                    <Typography component="span" variant="subtitle2">
                        {code}
                    </Typography>
                </AlertV2>
            ))}
            {children}
        </ApiErrorContext.Provider>
    );
};
