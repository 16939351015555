export const cypressCollectedRequestMethods = ['GET', 'POST', 'PUT', 'DELETE'] as const;

export type CypressCollectedRequestMethod = typeof cypressCollectedRequestMethods[number];

export type CypressCollectedHeaders = Record<string, string | string[] | undefined>;

export type CypressCollectedParams = Record<string, string | undefined>;

export interface ICypressCollectedRequest {
    method: CypressCollectedRequestMethod;

    url: string;

    queryParams: CypressCollectedParams;

    headers: CypressCollectedHeaders;

    body: unknown;
}

export interface ICypressCollectedResponse {
    statusCode: number;

    headers: CypressCollectedHeaders;

    body: unknown;
}

export interface ICypressCollectedRequestInfo {
    request: ICypressCollectedRequest;

    response: ICypressCollectedResponse;
}

export class CypressRequestCollector {
    private collectedRequests: ICypressCollectedRequestInfo[] = [];

    reset() {
        this.collectedRequests = [];
    }

    collectRequest(requestInfo: ICypressCollectedRequestInfo) {
        this.collectedRequests.push(requestInfo);
    }

    copyRequests() {
        const collectedRequests = JSON.stringify(this.collectedRequests);

        // @ts-ignore
        copy(collectedRequests);
    }

    get requests() {
        return this.collectedRequests;
    }
}
