import {
    SERVICE_AGREEMENTS_SUCCESS,
    SERVICE_AGREEMENTS_PENDING,
    SERVICE_AGREEMENT_FAILED,
} from '../actions/serviceAgreement/serviceAgreement';
import {
    SERVICE_AGREEMENT_CONTENT_PENDING,
    SERVICE_AGREEMENT_CONTENT_SUCCESS,
} from '../actions/serviceAgreement/serviceAgreementContent';
import { SERVICE_AGREEMENT_POPUP_SHOW } from '../actions/serviceAgreement/serviceAgreementPopup';
import {
    CREATE_SERVICE_AGREEMENT_SIGNATURE_SUCCESS,
    CREATE_SERVICE_AGREEMENT_SIGNATURE_PENDING,
} from '../actions/serviceAgreement/createServiceAgreementSignature';

const initialState = {
    serviceAgreementsIds: [],
    serviceAgreements: null,
    serviceAgreementsErrors: null,

    serviceAgreementContentsIds: [],
    serviceAgreementContents: null,

    serviceAgreementContentsPending: false,
    serviceAgreementsPending: false,
    serviceAgreementContent: null,
    serviceAgreementPopupShow: false,

    signaturePending: false,
};

export function serviceAgreement(state = initialState, { type, payload }) {
    switch (type) {
        case SERVICE_AGREEMENTS_PENDING:
            return {
                ...state,
                serviceAgreementsPending: payload.state,
            };

        case SERVICE_AGREEMENTS_SUCCESS:
            return {
                ...state,
                ...payload,
            };

        case SERVICE_AGREEMENT_FAILED:
            return {
                ...state,
                serviceAgreementsErrors: payload,
            };

        case SERVICE_AGREEMENT_CONTENT_PENDING:
            return {
                ...state,
                serviceAgreementContentsPending: payload.state,
            };

        case SERVICE_AGREEMENT_CONTENT_SUCCESS:
            return {
                ...state,
                ...payload,
            };

        case SERVICE_AGREEMENT_POPUP_SHOW:
            return {
                ...state,
                serviceAgreementPopupShow: payload,
                serviceAgreementsErrors: null,
            };

        case CREATE_SERVICE_AGREEMENT_SIGNATURE_PENDING:
            return {
                ...state,
                signaturePending: true,
            };

        case CREATE_SERVICE_AGREEMENT_SIGNATURE_SUCCESS:
            return {
                ...state,
                signaturePending: false,
            };

        default:
            return state;
    }
}
