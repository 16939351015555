import { FC } from 'react';
import { IUserLogin } from '../../../transcepta-types';
import { Button } from '../../../../ui';

interface IProps {
    userLogin: IUserLogin;
    handleSSOSignIn: () => void;
    handleLogInAgain: () => void;
}

const SSOForm: FC<IProps> = ({ userLogin, handleSSOSignIn, handleLogInAgain }) => {
    return (
        <>
            <Button color="primary" onClick={handleSSOSignIn}>
                {userLogin?.accountProfile?.SSOAttributes?.root?.signinLabel ?? 'Sign In using your Login'}
            </Button>
            {userLogin.accountProfile?.SSOAttributes?.root?.showGoBackLink && (
                <Button variant="text" onClick={handleLogInAgain} sx={{ mt: 2 }}>
                    Go Back to Transcepta Manager
                </Button>
            )}
        </>
    );
};

export default SSOForm;
