import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { DocumentEditRules } from '../../../../../../features';
import { convertFieldKeyToFieldInfo } from '../../../../../../features/DocumentEditRules/PluginHost/services/associatedFieldService';
import { createFetchCompanyCustomDataArgs } from '../fetchData';
import DropdownOptionsRepository from '../../../../../../features/DocumentEditRules/PluginHost/services/dropdownOptionsRepository';
import { IDropdownFieldOption } from '../../../../Atoms';

function convertDropdownData(options: DocumentEditRules.FetchedDropdownOptions) {
    return options.reduce<DocumentEditRules.IFetchedDropdownOption[]>((acc, option) => {
        if (typeof option === 'string') {
            acc.push({ label: option, value: option });
        } else if (option !== null) {
            acc.push(option);
        }
        return acc;
    }, []);
}

function usePrevious<T>(value: T) {
    const ref = useRef<T | undefined>(undefined);

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}

function getFieldFetcherKey(fetcher: DocumentEditRules.IDropdownOptionsFetcherRegistration, fieldKey: string) {
    if (fetcher.fetcher.doNotDependOnFieldInstance) {
        return fetcher.fetcher.fieldDefinitionKey;
    }

    return `${fetcher.fetcher.fieldDefinitionKey}-${fieldKey}`;
}

function fetcherShouldUpdate(
    fetcher: DocumentEditRules.IDropdownOptionsFetcherRegistration,
    oldContext: DocumentEditRules.DocumentEditValidationState,
    newContext: DocumentEditRules.DocumentEditValidationState,
    datasetName: string,
    datasetPath: string
) {
    try {
        return fetcher.fetcher.shouldUpdate(oldContext, newContext, { datasetName, datasetPath });
    } catch (e) {
        console.error(
            `Pro Serv - An error ocurred in your fetcher's "shouldUpdate" function. Your fetcher will not be called to fetch options.`,
            e,
            fetcher
        );
        return false;
    }
}

const skipFetching = [
    ['UnknownFetcher'],
    () => [],
    {
        enabled: false,
    },
] as const;

function useFetcherQueryArgs(
    fetcher: DocumentEditRules.IDropdownOptionsFetcherRegistration | undefined,
    context: DocumentEditRules.DocumentEditValidationState | undefined,
    fieldKey: string,
    datasetName: string,
    datasetPath: string
) {
    const change = useRef(0);
    const oldContext = usePrevious(context);

    return useMemo(() => {
        if (!fetcher) {
            return skipFetching;
        }

        if (!context) {
            return skipFetching;
        }

        if (oldContext && fetcherShouldUpdate(fetcher, oldContext, context, datasetName, datasetPath)) {
            change.current += 1;
        }

        const queryKey = `DropdownOptionsFetcher-${getFieldFetcherKey(fetcher, fieldKey)}-${change.current}`;

        return [
            [queryKey],
            async (): Promise<DocumentEditRules.IFetchedDropdownOption[]> => {
                try {
                    const options = await fetcher.fetcher.fetchOptions(context, convertFieldKeyToFieldInfo(fieldKey), {
                        datasetName,
                        datasetPath,
                    });
                    return convertDropdownData(options);
                } catch (e) {
                    console.error(
                        `Pro Serv - An error ocurred in your fetcher's "fetchOptions" function. An error will be displayed to the user and the field unusable.`,
                        e,
                        fetcher
                    );
                    throw new Error('Failed to fetch options');
                }
            },
            {
                refetchOnWindowFocus: false,
                refetchOnMount: false,
                refetchOnReconnect: false,
                retry: false,
            },
        ] as const;
    }, [fetcher, context, oldContext, datasetName, datasetPath, fieldKey]);
}

function useUseQueryArgs(
    fieldDefinitionKey: string | undefined,
    fetcher: DocumentEditRules.IDropdownOptionsFetcherRegistration | undefined,
    datasetName: string | undefined,
    datasetPath: string | undefined,
    companyId: number,
    context: DocumentEditRules.DocumentEditValidationState | undefined,
    fieldKey: string
) {
    const fetcherQueryArgs = useFetcherQueryArgs(fetcher, context, fieldKey, datasetName ?? '', datasetPath ?? '');

    if (!fieldDefinitionKey) {
        return createFetchCompanyCustomDataArgs(datasetName, datasetPath, companyId);
    }

    return fetcherQueryArgs;
}

export function useDropdownOptionsV1(
    fieldDefinitionKey: string | undefined,
    datasetName: string | undefined,
    datasetPath: string | undefined,
    companyId: number
) {
    const validator = DocumentEditRules.useDocumentEditValidator();
    const fieldName = DocumentEditRules.useContextualFieldName();
    const fieldKey = DocumentEditRules.useFieldKey(fieldName);

    const fetcher = fieldDefinitionKey ? validator.getDropdownOptionsFetcher(fieldDefinitionKey) : undefined;

    const queryArgs = useUseQueryArgs(
        fieldDefinitionKey,
        fetcher,
        datasetName,
        datasetPath,
        companyId,
        validator.context,
        fieldKey
    );

    return useQuery<DocumentEditRules.IFetchedDropdownOption[]>(...(queryArgs as [any, any, any]));
}

// V2 engine does not currently support undefined fieldDefinitionKey, and doesn't have a mechanism to call the fetcher
// with special params like datasetName and datasetPath, and other caching capabilities.
export function useDropdownOptionsV2(
    fieldDefinitionKey: string | undefined,
    dropdownOptionsRepository: DropdownOptionsRepository | undefined
) {
    const [isError, setIsError] = useState<boolean>(false);
    const [data, setData] = useState<IDropdownFieldOption[] | undefined>(undefined);

    useEffect(() => {
        if (dropdownOptionsRepository && fieldDefinitionKey) {
            const fetchDropdownData = async () => {
                let dropdownData = await dropdownOptionsRepository.getLatestDropdownOptions(fieldDefinitionKey);
                if (dropdownData && dropdownData.length) {
                    dropdownData = convertDropdownData(dropdownData);
                    setData(dropdownData);
                } else {
                    setIsError(true);
                }
            };

            fetchDropdownData();
        }
    }, [fieldDefinitionKey, dropdownOptionsRepository]);

    return { data, isError };
}
