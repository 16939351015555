import { IDropdownOptionsRepository } from '../../proservContractTypes';
import { InternalRule } from './InternalRule';
import {
    DocumentLayoutController,
    IRValidationResults,
    InternalRuleEnvironment,
    ValidationFunction,
    ValidationFunctionResultsCollector,
} from './RuleEnvironment';

import associatedFieldService from '../../PluginHost/services/associatedFieldService';
import { DocumentFieldController } from '../../ValidationService/DocumentFieldController';
import { UserWorkflowActivityState } from '../../types/DocumentEditValidationState';
import { InternalRuleConfiguration } from './useInternalRulesConfiguration';

export class InternalRuleService {
    private environment: InternalRuleEnvironment;

    private documentControllers = new Array<DocumentLayoutController>();

    private validationFunctions = new Array<ValidationFunction>();

    constructor(
        private readonly rules: InternalRule[],
        // @ts-ignore this is ok, dropdownOptionsRepository is being used on `loadRules`
        private readonly dropdownOptionsRepository: IDropdownOptionsRepository,
        private readonly configuration: InternalRuleConfiguration
    ) {
        this.environment = {
            registerUIDocumentController: (...args) => this.registerUIDocumentController(...args),
            registerValidationFunction: (...args) => this.registerValidationFunction(...args),
            services: {
                associatedField: associatedFieldService,
                dropdownOptionsRepository,
            },
        };
    }

    loadRules() {
        this.rules.map((rule) => {
            rule.logic(this.environment);
        });
    }

    public async executeValidations(documentState: UserWorkflowActivityState) {
        const validationResults: IRValidationResults = [];

        for (const validationFunction of this.validationFunctions) {
            const resultsCollector = new ValidationFunctionResultsCollector(this.configuration);
            await validationFunction(documentState, resultsCollector);

            validationResults.push(...resultsCollector.validationResults);
        }

        return validationResults;
    }

    public async executeUIDocumentControllers(
        documentState: UserWorkflowActivityState,
        setDocumentFieldsControlState: (
            fieldControlStates: ReturnType<DocumentFieldController['getFieldControlStates']>
        ) => void
    ) {
        const documentController = new DocumentFieldController();

        for (const controller of this.documentControllers) {
            await controller(documentState, documentController);
        }

        setDocumentFieldsControlState(documentController.getFieldControlStates());
    }

    private registerUIDocumentController(controller: DocumentLayoutController) {
        this.documentControllers.push(controller);
    }

    private registerValidationFunction(validationFunction: ValidationFunction) {
        this.validationFunctions.push(validationFunction);
    }
}
