import { Location } from 'history';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

const raf = requestAnimationFrame || setTimeout;

function scrollElementToTop(el: HTMLElement | null) {
    if (!el) {
        return;
    }
    el.scrollTop = 0;
}

export function useResetScrollState() {
    const history = useHistory();

    useEffect(() => {
        const visitedLocations = new Set<string | undefined>();

        let lastLocation: Location | null = null;

        return history.listen(() => {
            // if only the 'hash' or 'query string' have changed, do not scroll to top.
            if (lastLocation && lastLocation.pathname === history.location.pathname) {
                return;
            }
            lastLocation = history.location;

            // ensure we only run on new locations, not previously visited locations,
            // that way scroll state is kept for previous locations
            const { key } = history.location;
            if (visitedLocations.has(key)) {
                return;
            }
            visitedLocations.add(key);

            // the nested request animation frame is required here. the first one will be
            // guaranteed to run *after* the next React render, which currently will include
            // the child page render from the route change. the second is then scheduled after
            // the updates from that render are painted to the screen, which is when we would like to scroll.
            raf(() =>
                raf(() => {
                    scrollElementToTop(document.querySelector('html'));
                    scrollElementToTop(document.querySelector('body'));
                })
            );
        });
    }, [history]);
}
