import { useLocation } from 'react-router-dom';
import { IBreadCrumb } from './IBreadCrumb';

/**
 * Returns the current page's full bread crumb list
 * @param defaultHistoryCrumbs default breadcrumbs if none can be found in history state
 * @param currentCrumb the current page's bread crumb
 * @returns the breadcrumbs in history plus current page breadcrumb
 */
export function useBreadcrumbs(defaultHistoryCrumbs: IBreadCrumb[], currentCrumb: IBreadCrumb) {
    const location = useLocation();

    const historyCrumbs = (() => {
        try {
            return (location.state as any).breadCrumbList as IBreadCrumb[];
        } catch (e) {
            return defaultHistoryCrumbs;
        }
    })();

    return {
        breadcrumbs: [...historyCrumbs, currentCrumb],
        historyCrumbs,
    };
}
