import { Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { Button, TooltipIcon } from '../../ui';
import { ISearchConfiguration, ISearchRequest, ISearchService } from './types';
import { useState } from 'react';

interface ExportDataProps<T extends object> {
    searchConfiguration: ISearchConfiguration<any>;
    searchService: ISearchService<any>;
    searchRequest: ISearchRequest<T>;
    singularEntityType: string;
    exportInfoMessage?: string;
}

export const ExportData = <T extends object>({
    searchConfiguration,
    searchRequest,
    searchService,
    singularEntityType,
    exportInfoMessage,
}: ExportDataProps<T>) => {
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
    const [isExporting, setIsExporting] = useState(false);

    const handleClick = async () => {
        try {
            setIsExporting(true);
            await searchService.exportData(searchConfiguration, searchRequest, '', `${singularEntityType} list.csv`);
            setIsExporting(false);
        } catch (error) {
            setIsExporting(false);
        }
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
            <Button variant="text" onClick={handleClick} disabled={isExporting ? isExporting : false}>
                Export
                {isExporting && (
                    <CircularProgress
                        sx={{
                            position: 'absolute',
                        }}
                        size={isSmDown ? 14 : 20}
                    />
                )}
            </Button>
            {exportInfoMessage && <TooltipIcon message={exportInfoMessage} icon="Info" />}
        </Box>
    );
};
