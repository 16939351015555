import { FC } from 'react';
import { GridCell } from '../GridCell/GridCell';
import { cellAdapter } from '../../CommonResources/helpers/grid';

export interface IDataRowFieldProps {
    col: any;

    record: any;
}

export const DataRowField: FC<IDataRowFieldProps> = ({ col, record }) => {
    if (col.desktopRenderer) {
        return <div className="core-grid-cell">{col.desktopRenderer(record, col)}</div>;
    }

    return <GridCell {...cellAdapter(col, record[col.key])} />;
};
