import { backendServices } from '../../../../../services';
import { BusinessDocType } from '../../../../../types';
import { DocumentRevision } from './DocumentRevision';

function getAmount(document: backendServices.ViewModels.DocumentViewModel, businessDocType: BusinessDocType) {
    switch (businessDocType) {
        case BusinessDocType.PurchaseOrder:
            return document.BusinessDocFields.PurchaseOrder.PurchaseOrderAmount;
        default:
            throw new Error('Document type not supported');
    }
}

function getRevisionNumber(document: backendServices.ViewModels.DocumentViewModel, businessDocType: BusinessDocType) {
    switch (businessDocType) {
        case BusinessDocType.PurchaseOrder:
            return document.BusinessDocFields.PurchaseOrder.Revision;
        default:
            throw new Error('Document type not supported');
    }
}

function toDocumentRevision(value: backendServices.ViewModels.DocumentViewModel): DocumentRevision {
    return {
        id: value.Id,
        date: new Date(value.CreationDate), // Note: the type from transcepta-types is wrong (is a string)
        revisionNumber: getRevisionNumber(value, value.BusinessDocType),
        documentType: value.BusinessDocType_US,
        amount: getAmount(value, value.BusinessDocType),
    };
}

export async function fetchDocumentRevisions(documentId: number) {
    const api = new backendServices.Apis.DocumentApi();

    const response = await api.getDocumentRevisions(documentId);

    return response.data.map(toDocumentRevision);
}
