import { useCookies as reactCookies } from 'react-cookie';
import isEmpty from '../isEmpty';

function useCookies(cookieName: string) {
    const [cookies, setCookies, removeCookies] = reactCookies([cookieName]);
    const options = { defaultPath: '/' };

    const getExpired = () => {
        return new Date(Number.MAX_VALUE * 1000);
    };

    const setCookie = (value: object, key: string = '') => {
        const expires = getExpired();

        const all = value as any;

        value = all.searchString ?? all;

        value = Object.entries(value)
            .filter((f: any) => !!f[1].value)
            .map((m) => ({
                [m[0]]: m[1],
            }))
            .reduce((acc, value) => ({ ...acc, ...value }), {});

        all.searchString = value;

        if (key) {
            value = { [key]: all };
        }

        if (cookies[cookieName]) {
            value = { ...cookies[cookieName], ...value };
        }

        setCookies(cookieName, value, { path: options.defaultPath, expires });
    };

    const removeCookie = (key: string = '') => {
        const expires = getExpired();

        if (cookies[cookieName]) {
            if (key) {
                const value = {};
                Object.keys(cookies[cookieName]).forEach((innerKey: string) => {
                    if (innerKey !== key) {
                        value[innerKey] = cookies[cookieName][innerKey];
                    }
                });

                if (isEmpty(value)) {
                    removeCookies(cookieName, { path: options.defaultPath });
                } else {
                    setCookies(cookieName, value, {
                        path: options.defaultPath,
                        expires,
                    });
                }
            } else {
                removeCookies(cookieName, { path: options.defaultPath });
            }
        }
    };

    return [cookies[cookieName], setCookie, removeCookie];
}

export default useCookies;
