import { createContext, FC, useContext, useMemo } from 'react';
import DropdownOptionsRepository from './PluginHost/services/dropdownOptionsRepository';

const DropdownOptionsRepositoryContext = createContext(new DropdownOptionsRepository());

export const DropdownOptionsRepositoryProvider: FC = ({ children }) => {
    const dropdownOptionsRepository = useMemo(() => new DropdownOptionsRepository(), []);

    return (
        <DropdownOptionsRepositoryContext.Provider value={dropdownOptionsRepository}>
            {children}
        </DropdownOptionsRepositoryContext.Provider>
    );
};

export function useDropdownOptionsRepository() {
    return useContext(DropdownOptionsRepositoryContext);
}
