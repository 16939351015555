// DEPRECATED: Do not use
// REFACTOR: Move this component under Organisms/GLCodingForm
import { FC } from 'react';
import { Input } from '../InputField';
import './styles.scss';
import { Add, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

export type Props = {
    label: string;
    currentForm: number;
    onPreviousClick: () => void;
    onNextClick: () => void;
    onAddClick?: () => void;
    previousActionDisabled?: boolean;
    showAdd?: boolean;
};

/**
 * @deprecated
 */
export const FormNavigator: FC<Props> = ({
    label,
    currentForm,
    onPreviousClick,
    onNextClick,
    onAddClick,
    previousActionDisabled = false,
    showAdd = false,
}) => {
    return (
        <div className="core-form-navigator">
            <label>{label}</label>
            <div className="form-navigator">
                <span
                    className={`action-btn ${previousActionDisabled ? 'disabled' : ''}`}
                    onClick={onPreviousClick}
                    role="button"
                    tabIndex={0}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            onPreviousClick();
                        }
                    }}
                >
                    <KeyboardArrowLeft />
                </span>
                <Input
                    id={'current-form-display'}
                    className="input-display"
                    fieldName="current-form"
                    initialValue={currentForm.toString()}
                    editMode="edit"
                    editable={false}
                />
                {showAdd ? (
                    <span
                        className="action-btn"
                        onClick={onAddClick}
                        role="button"
                        tabIndex={0}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                if (onAddClick) {
                                    onAddClick();
                                }
                            }
                        }}
                    >
                        <Add />
                    </span>
                ) : (
                    <span
                        className="action-btn"
                        onClick={onNextClick}
                        role="button"
                        tabIndex={0}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                onNextClick();
                            }
                        }}
                    >
                        <KeyboardArrowRight />
                    </span>
                )}
            </div>
        </div>
    );
};

export default FormNavigator;
