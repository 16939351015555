/**
 * Returns all elements that are in `a` that are not also in `b`
 *
 * @alias setDifference
 * @param a the set that acts like the "universe" in the relative complement
 * @param b the set of items to exclude from the universe.
 * @returns the relative complement of `b` with resepect to `a`
 */
export function setRelativeComplement<T>(a: readonly T[], b: readonly T[]) {
    const output: T[] = [];
    const setOfB = new Set();
    b.forEach((item) => setOfB.add(item));
    a.forEach((item) => {
        if (!setOfB.has(item)) {
            output.push(item);
        }
    });

    return output;
}

/**
 * Alias of `setRelativeComplement`
 * @alias setRelativeComplement
 */
export function setDifference<T>(a: readonly T[], b: readonly T[]) {
    return setRelativeComplement(a, b);
}

/**
 * Returns all elements that are in both `a` and `b`.
 * @param a first set
 * @param b second set
 * @returns the intersection of the sets.
 */
export function setIntersection<T>(a: readonly T[], b: readonly T[]) {
    const output: T[] = [];
    const setOfB = new Set();
    b.forEach((item) => setOfB.add(item));
    a.forEach((item) => {
        if (setOfB.has(item)) {
            output.push(item);
        }
    });

    return output;
}
