import { ReactNode, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { ErrorAlert, LoadingMessage } from '../../ui';
import { FormTemplate } from './Forms/FormTemplate';
import { useRedirectAfterSuccessfulAuth } from './Auth';
import { FetchUserProfile } from './FetchUserProfile';
import { ILoginResponseViewModel } from '../../services/UserService/Api';

export interface IFetchUserProfileThenShowPortal {
    marketingContent?: ReactNode;
    portal: ReactNode;
    fetchUserProfile: FetchUserProfile;
    userViewModel: ILoginResponseViewModel;
}

export const FetchUserProfileThenShowPortal = ({
    marketingContent,
    portal,
    fetchUserProfile,
    userViewModel,
}: IFetchUserProfileThenShowPortal) => {
    const mutation = useMutation(async () => fetchUserProfile(userViewModel));

    useEffect(() => {
        mutation.mutate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isRedirected = useRedirectAfterSuccessfulAuth(mutation.isSuccess);

    if (mutation.isError) {
        return <ErrorAlert error={mutation.error} testId="fetch-user-profile-error" />;
    }

    if (mutation.isSuccess && isRedirected) {
        return <>{portal}</>;
    }

    return (
        <FormTemplate
            marketingContent={marketingContent}
            mainContent={<LoadingMessage>Fetching User Profile</LoadingMessage>}
        />
    );
};
