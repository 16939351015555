// DEPRECATED: Do not use
// REFACTOR: Replace all uses with Dialog
import { FC, useEffect } from 'react';
import ReactModal from 'react-modal';
import './modal.scss';
import { Close } from '@mui/icons-material';

export interface IModalProps {
    type?: 'standard' | 'info' | 'warning' | 'form';
    closeIcon?: boolean;
    isOpen: boolean;
    isWide?: boolean;
    scrollTop?: boolean;
    className?: string;
    renderTitle: string | JSX.Element;
    renderBody: string | JSX.Element;
    renderAction?: string | JSX.Element;
    handleClose?: () => void;
    testId?: string;
}

/**
 * @deprecated
 */
export const Modal: FC<IModalProps> = ({
    isOpen,
    isWide,
    scrollTop = true,
    type = 'standard',
    className,
    renderTitle,
    renderBody,
    renderAction,
    handleClose,
    testId = 'core-modal',
}) => {
    useEffect(() => {
        if (isOpen && scrollTop) {
            window.scrollTo(0, 0);
        }
    }, [isOpen, scrollTop]);

    return (
        <ReactModal
            isOpen={isOpen}
            portalClassName="core-modal-portal"
            className={{
                base: `core-modal ${type} ${isWide ? 'wide' : ''} ${className || ''}`,
                afterOpen: 'open',
                beforeClose: 'close',
            }}
            shouldCloseOnOverlayClick
            overlayClassName={{
                base: 'core-modal-overlay',
                afterOpen: 'core-modal-overlay_after-open',
                beforeClose: 'core-modal-overlay_before-close',
            }}
            onRequestClose={handleClose}
        >
            <div data-testid={testId}>
                <h3 className="header">
                    {renderTitle}
                    {handleClose && (
                        <div
                            id="a-modal-close"
                            className="close-icon"
                            onClick={handleClose}
                            role="button"
                            tabIndex={0}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleClose();
                                }
                            }}
                        >
                            <Close />
                        </div>
                    )}
                </h3>
                <div className="body">{renderBody}</div>
                {renderAction && <div className="actions">{renderAction}</div>}
            </div>
        </ReactModal>
    );
};
