import * as ApplicationActions from './ApplicationActions';
import * as userlogin from './userlogin';
import * as manageUsers from './profile/manageUsers';
import * as changePassword from './profile/changePassword';
import * as alerts from './alerts';
import * as documentSearchActions from './documentSearchActions';
import * as documentListActionTypes from './documentsListActions';
import * as apiError from './error';
import * as doc from './document/documentActions';
import * as menuBage from './menuBageActions';
import * as CustomerActions from './CustomerActions';
import * as serviceAgreement from './serviceAgreement/serviceAgreement';
import * as ProfilesActions from './profile/ProfilesActions';
import * as documentCreationActions from './document/documentCreationActions';

// eslint-disable-next-line import/prefer-default-export
export default {
    ...apiError,
    ...ApplicationActions,
    ...userlogin,
    ...manageUsers,
    ...alerts,
    ...changePassword,
    ...documentSearchActions,
    ...documentListActionTypes,
    ...doc,
    ...menuBage,
    ...CustomerActions,
    ...serviceAgreement,
    ...ProfilesActions,
    ...documentCreationActions,
};
