import { IsUpperCase, IsLowerCase } from './string';

export function GetEnumNameFromValue(enumJson, enumValue) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in enumJson) {
        if (enumJson[key] === enumValue) {
            return key;
        }
    }

    return false;
}

export function GetReadableEnumString(enumName, spacesInAcronyms = false) {
    let readableString = '';
    for (let i = 0; i < enumName.length; i += 1) {
        if (IsUpperCase(enumName[i]) && readableString.length > 0) {
            if (
                spacesInAcronyms === true ||
                IsLowerCase(enumName[i - 1]) ||
                (i + 1 < enumName.Length && IsLowerCase(enumName[i + 1]))
            ) {
                readableString = `${readableString} `;
            }
        }

        readableString += enumName[i];
    }

    return readableString;
}
