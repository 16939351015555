import { DocumentValidationConfiguration } from '../../../CJSRuleEngineV2ClientWrapper/ClientValidationController/DocumentValidationConfiguration';
import { InternalRule } from './InternalRule';

import { useMemo } from 'react';
import { ValidationEvent } from '../../../CustomJSRuleEngineV2/ExecutionService/DPSValidations';

export type InternalRuleConfiguration = Omit<DocumentValidationConfiguration, 'inputParameters'>;

export function useInternalRulesConfiguration(internalRules: InternalRule[] | null): InternalRuleConfiguration | null {
    const config = useMemo(() => {
        if (internalRules == null) {
            return null;
        }

        const focusLossFieldNames = new Set<string>();
        const eventsToValidateOn = new Set<ValidationEvent['type']>();

        internalRules.forEach((rule) => {
            rule.fieldNamesToValidateOnFocusLoss.forEach((fieldName) => {
                focusLossFieldNames.add(fieldName);
            });

            rule.eventsToValidateOn.forEach((eventName) => {
                eventsToValidateOn.add(eventName);
            });
        });

        return {
            fieldNamesToValidateOnFocusLoss: Array.from(focusLossFieldNames.values()),
            eventsToValidateOn: Array.from(eventsToValidateOn.values()),
            DisableSubmitWhenFocusLossJShasValidationErrors: true,
        };
    }, [internalRules]);

    return config;
}
