/* eslint-disable no-unused-vars,no-param-reassign */
export const DOCUMENT_FETCHING = 'DOCUMENT_FETCHING';
export const DOCUMENT_FETCHED = 'DOCUMENT_FETCHED';
export const DOCUMENT_NOT_FOUND = 'DOCUMENT_NOT_FOUND';
export const DOCUMENT_FETCH_ERROR = 'DOCUMENT_FETCH_ERROR';
export const DOCUMENT_SHOULD_SHOW_404_ERROR = 'DOCUMENT_SHOULD_SHOW_404_ERROR';

export const DOCUMENT_LAYOUT_FETCHING = 'DOCUMENT_LAYOUT_FETCHING';
export const DOCUMENT_LAYOUT_FETCHED = 'DOCUMENT_LAYOUT_FETCHED';
export const DOCUMENT_LAYOUT_FETCH_ERROR = 'DOCUMENT_LAYOUT_FETCH_ERROR';

export const DOCUMENT_SENDERCOMPANYID_FETCHED = 'DOCUMENT_SENDERCOMPANYID_FETCHED';
export const DOCUMENT_RECEIVERCOMPANYID_FETCHED = 'DOCUMENT_RECEIVERCOMPANYID_FETCHED';

export const DOCUMENT_ATTACHMENTS_EXECUTE = 'DOCUMENT_ATTACHMENTS_EXECUTE';
export const DOCUMENT_ATTACHMENTS_FETCHED = 'DOCUMENT_ATTACHMENTS_FETCHED';
export const DOCUMENT_ATTACHMENTS_FETCH_ERROR = 'DOCUMENT_ATTACHMENTS_FETCH_ERROR';
export const DOCUMENT_ATTACHMENT_DOWNLOAD_EXECUTE = 'DOCUMENT_ATTACHMENT_DOWNLOAD_EXECUTE';
export const DOCUMENT_ATTACHMENT_DOWNLOAD_FAILURE = 'DOCUMENT_ATTACHMENT_DOWNLOAD_FAILURE';
export const DOCUMENT_ATTACHMENT_DOWNLOAD_SUCCESS = 'DOCUMENT_ATTACHMENT_DOWNLOAD_SUCCESS';
export const DOCUMENT_ATTACHMENTS_ADDED = 'DOCUMENT_ATTACHMENTS_ADDED';
export const DOCUMENT_ATTACHMENT_REMOVED = 'DOCUMENT_ATTACHMENT_REMOVED';
export const SAVE_DOCUMENT_ATTACHMENTS_EXECUTE = 'SAVE_DOCUMENT_ATTACHMENTS_EXECUTE';
export const SAVE_DOCUMENT_ATTACHMENTS_FAILURE = 'SAVE_DOCUMENT_ATTACHMENTS_FAILURE';
export const SAVE_DOCUMENT_ATTACHMENTS_SUCCESS = 'SAVE_DOCUMENT_ATTACHMENTS_SUCCESS';

export const DOCUMENT_VALIDATION_EXECUTE = 'DOCUMENT_VALIDATION_EXECUTE';
export const DOCUMENT_VALIDATION_FETCHED = 'DOCUMENT_VALIDATION_FETCHED';
export const DOCUMENT_VALIDATION_FETCH_ERROR = 'DOCUMENT_VALIDATION_FETCH_ERROR';

export const DOCUMENT_SET_ATTACHMENTS_ALLOWED = 'DOCUMENT_TOGGLE_ATTACHMENTS';

export const DOCUMENT_FIELD_VALIDATION = 'DOCUMENT_FIELD_VALIDATION';
export const DOCUMENT_CLEAR_VALIDATION = 'DOCUMENT_CLEAR_VALIDATION';

export const DOCUMENT_HISTORY_EXECUTE = 'DOCUMENT_HISTORY_EXECUTE';
export const DOCUMENT_HISTORY_FETCHED = 'DOCUMENT_HISTORY_FETCHED';
export const DOCUMENT_HISTORY_FETCH_ERROR = 'DOCUMENT_HISTORY_FETCH_ERROR';

export const DOCUMENT_DYNAMIC_DISCOUNTING_EXECUTE = 'DOCUMENT_DYNAMIC_DISCOUNTING_EXECUTE';
export const DOCUMENT_DYNAMIC_DISCOUNTING_FETCHED = 'DOCUMENT_DYNAMIC_DISCOUNTING_FETCHED';
export const DOCUMENT_DYNAMIC_DISCOUNTING_ERROR = 'DOCUMENT_DYNAMIC_DISCOUNTING_ERROR';

export const DOCUMENT_DELIVERY_EXECUTE = 'DOCUMENT_DELIVERY_EXECUTE';
export const DOCUMENT_DELIVERY_FETCHED = 'DOCUMENT_DELIVERY_FETCHED';
export const DOCUMENT_DELIVERY_ERROR = 'DOCUMENT_DELIVERY_ERROR';

export const DOCUMENT_WORKFLOW_EXECUTE = 'DOCUMENT_WORKFLOW_EXECUTE';
export const DOCUMENT_WORKFLOW_FETCHED = 'DOCUMENT_WORKFLOW_FETCHED';
export const DOCUMENT_WORKFLOW_ERROR = 'DOCUMENT_WORKFLOW_ERROR';

export const DOCUMENT_TOGGLE_ACTION_POPUP = 'DOCUMENT_TOGGLE_ACTION_POPUP';

export const DOCUMENT_APPROVE_ERROR = 'DOCUMENT_APPROVE_ERROR';
export const DOCUMENT_REJECT_ERROR = 'DOCUMENT_REJECT_ERROR';

export const GET_MESSAGE_SUCCESS = 'GET_MESSAGE_SUCCESS';
export const GET_MESSAGE_FAILED = 'GET_MESSAGE_FAILED';
export const GET_MESSAGE_EXECUTE = 'GET_MESSAGE_EXECUTE';
export const POST_MESSAGE_SUCCESS = 'POST_MESSAGE_SUCCESS';
export const POST_MESSAGE_FAILED = 'POST_MESSAGE_FAILED';
export const POST_MESSAGE_EXECUTE = 'POST_MESSAGE_EXECUTE';
export const PUT_MESSAGE_SUCCESS = 'PUT_MESSAGE_SUCCESS';
export const PUT_MESSAGE_FAILED = 'PUT_MESSAGE_FAILED';
export const PUT_MESSAGE_EXECUTE = 'PUT_MESSAGE_EXECUTE';

export const DRAFT_DOCUMENT_DELETE_EXECUTE = 'DRAFT_DOCUMENT_DELETE_EXECUTE';
export const DRAFT_DOCUMENT_DELETE_SUCCESS = 'DRAFT_DOCUMENT_DELETE_SUCCESS';
export const DRAFT_DOCUMENT_DELETE_ERROR = 'DRAFT_DOCUMENT_DELETE_ERROR';

export const DOCUMENT_NAVIGATE_TO_ASNS = 'DOCUMENT_NAVIGATE_TO_ASNS';
export const DOCUMENT_NAVIGATE_TO_CONSUMPTION_ADVICES = 'DOCUMENT_NAVIGATE_TO_CONSUMPTION_ADVICES';
export const DOCUMENT_NAVIGATE_TO_CREATE_DOCUMENT_DRAFT = 'DOCUMENT_NAVIGATE_TO_CREATE_DOCUMENT_DRAFT';
export const DOCUMENT_NAVIGATE_TO_INVENTORY_ADVICES = 'DOCUMENT_NAVIGATE_TO_INVENTORY_ADVICES';
export const DOCUMENT_NAVIGATE_TO_INVOICES = 'DOCUMENT_NAVIGATE_TO_INVOICES';
export const DOCUMENT_NAVIGATE_TO_PURCHASE_ORDER_ACKNOWLEDGEMENTS =
    'DOCUMENT_NAVIGATE_TO_PURCHASE_ORDER_ACKNOWLEDGEMENTS';
export const DOCUMENT_NAVIGATE_TO_PURCHASE_ORDERS = 'DOCUMENT_NAVIGATE_TO_PURCHASE_ORDERS';
export const DOCUMENT_NAVIGATE_TO_RECEIVING_ADVICES = 'DOCUMENT_NAVIGATE_TO_RECEIVING_ADVICES';
export const DOCUMENT_SET_SEARCH_MODEL = 'DOCUMENT_SET_SEARCH_MODEL';

export const FETCH_DOCUMENT_PDF_EXECTUE = 'FETCH_DOCUMENT_PDF_EXECTUE';
export const FETCH_DOCUMENT_PDF_FAILED = 'FETCH_DOCUMENT_PDF_FAILED';
export const FETCH_DOCUMENT_PDF_SUCCESS = 'FETCH_DOCUMENT_PDF_SUCCESS';
export const GENERATE_DOCUMENT_PDF_EXECTUE = 'GENERATE_DOCUMENT_PDF_EXECTUE';
export const GENERATE_DOCUMENT_PDF_FAILED = 'GENERATE_DOCUMENT_PDF_FAILED';
export const GENERATE_DOCUMENT_PDF_SUCCESS = 'GENERATE_DOCUMENT_PDF_SUCCESS';

export const DOCUMENT_IS_A_COPY = 'DOCUMENT_IS_A_COPY';

export const RELEASE_DOCUMENT_EXECUTE = 'RELEASE_DOCUMENT_EXECUTE';
export const RELEASE_DOCUMENT_FAILURE = 'RELEASE_DOCUMENT_FAILURE';
export const RELEASE_DOCUMENT_SUCCESS = 'RELEASE_DOCUMENT_SUCCESS';

export const DOCUMENT_DUPLICATE_CHECK_EXECUTE = 'DOCUMENT_DUPLICATE_CHECK_EXECUTE';
export const DOCUMENT_DUPLICATE_CHECK_FAILURE = 'DOCUMENT_DUPLICATE_CHECK_FAILURE';
export const DOCUMENT_DUPLICATE_CHECK_SUCCESS = 'DOCUMENT_DUPLICATE_CHECK_SUCCESS';

export const CANCEL_DOCUMENT_EXECUTE = 'CANCEL_DOCUMENT_EXECUTE';
export const CANCEL_DOCUMENT_FAILURE = 'CANCEL_DOCUMENT_FAILURE';
export const CANCEL_DOCUMENT_SUCCESS = 'CANCEL_DOCUMENT_SUCCESS';

export const DOCUMENT_CLEAR_HISTORY_DELIVERY = 'DOCUMENT_CLEAR_HISTORY_DELIVERY';

// Document Search
export const DOCUMENT_SEARCH_LAYOUT_CONFIG_FETCHING = 'DOCUMENT_SEARCH_LAYOUT_CONFIG_FETCHING';
export const DOCUMENT_SEARCH_LAYOUT_CONFIG_FETCHED = 'DOCUMENT_SEARCH_LAYOUT_CONFIG_FETCHED';
export const DOCUMENT_SEARCH_CSV_DATA_FETCHED = 'DOCUMENT_SEARCH_CSV_DATA_FETCHED';
export const DOCUMENT_SEARCH_CLEAR_CSV_DATA = 'DOCUMENT_SEARCH_CLEAR_CSV_DATA';

// Document Buyer Message
export const DOCUMENT_GET_BUYER_MESSAGE_START = 'DOCUMENT_GET_BUYER_MESSAGE_START';
export const DOCUMENT_GET_BUYER_MESSAGE_END = 'DOCUMENT_GET_BUYER_MESSAGE_END';
export const DOCUMENT_GET_BUYER_MESSAGE_SUCCESS = 'DOCUMENT_GET_BUYER_MESSAGE_SUCCESS';
export const DOCUMENT_GET_BUYER_MESSAGE_FAILURE = 'DOCUMENT_GET_BUYER_MESSAGE_FAILURE';
export const DOCUMENT_CLEAR_MESSAGES = 'DOCUMENT_CLEAR_MESSAGES';

// TODO: there is no difference between edit and view layout. These should eventually be removed and both view and edit pages should use the same action and thunk.
export const EDIT_LAYOUT_FETCHED = 'EDIT_LAYOUT_FETCHED';
export const EDIT_LAYOUT_FETCHING = 'EDIT_LAYOUT_FETCHING';
export const editLayoutFetching = () => ({
    type: EDIT_LAYOUT_FETCHING,
});
export const editLayoutFetched = (layouts) => ({
    type: EDIT_LAYOUT_FETCHED,
    payload: layouts,
});

export const documentLayoutFetching = (params) => ({
    type: DOCUMENT_LAYOUT_FETCHING,
    payload: params,
});

export const documentLayoutFetched = (params, convertedLayout) => ({
    type: DOCUMENT_LAYOUT_FETCHED,
    payload: {
        data: convertedLayout,
        req: params,
    },
});

export const documentFetched = (documentData) => ({
    type: DOCUMENT_FETCHED,
    payload: documentData,
});

export const fetchDocumentLayoutFailed = (err) => ({
    type: DOCUMENT_LAYOUT_FETCH_ERROR,
    payload: err,
});

/* -------------------------------------------------------------------------- */
export const fetchDocumentExecute = (documentId, draft, params) => ({
    type: DOCUMENT_FETCHING,
    payload: {
        documentId: documentId,
        draft: draft,
        params: params,
    },
});

export const fetchDocumentNotFound = (documentId) => ({
    type: DOCUMENT_NOT_FOUND,
    payload: { documentId },
});

export const fetchDocumentFailed = (err) => ({
    type: DOCUMENT_FETCH_ERROR,
    payload: err,
});

export const clearDocumentHistoryDelivery = () => ({
    type: DOCUMENT_CLEAR_HISTORY_DELIVERY,
});

export const shouldShow404Error = (params) => ({
    type: DOCUMENT_SHOULD_SHOW_404_ERROR,
    payload: params,
});

/* -------------------------------------------------------------------------- */
export const fetchDocumentStatusExecute = (params) => ({
    type: DOCUMENT_VALIDATION_EXECUTE,
    payload: { params: params },
});

export const fetchDocumentStatusSuccess = (data) => ({
    type: DOCUMENT_VALIDATION_FETCHED,
    payload: data,
});

export const fetchDocumentStatusFailed = (err) => ({
    type: DOCUMENT_VALIDATION_FETCH_ERROR,
    payload: err,
});

export const setDocumentFieldValidations = (data) => ({
    type: DOCUMENT_FIELD_VALIDATION,
    payload: data,
});

export const clearDocumentValidations = () => ({
    type: DOCUMENT_CLEAR_VALIDATION,
});
/* -------------------------------------------------------------------------- */
export const fetchDocumentHistoryExecute = (params) => ({
    type: DOCUMENT_HISTORY_EXECUTE,
    payload: { params: params },
});

export const fetchDocumentHistorySuccess = (data) => ({
    type: DOCUMENT_HISTORY_FETCHED,
    payload: data,
});

export const fetchDocumentHistoryFailed = (err, documentId) => ({
    type: DOCUMENT_HISTORY_FETCH_ERROR,
    payload: err.response.data,
});

/* -------------------------------------------------------------------------- */
export const fetchDocumentFilesExecute = (params) => ({
    type: DOCUMENT_ATTACHMENTS_EXECUTE,
    payload: { params: params },
});

export const fetchDocumentFilesSuccess = (data) => ({
    type: DOCUMENT_ATTACHMENTS_FETCHED,
    payload: data,
});

export const fetchDocumentFilesFailed = (err) => ({
    type: DOCUMENT_ATTACHMENTS_FETCH_ERROR,
    payload: err.response.data,
});

export const downloadDocumentAttachmentExecute = (params) => ({
    type: DOCUMENT_ATTACHMENT_DOWNLOAD_EXECUTE,
    payload: { params: params },
});

export const downloadDocumentAttachmentFailure = (err) => ({
    type: DOCUMENT_ATTACHMENT_DOWNLOAD_FAILURE,
    payload: err.response.data,
});

export const downloadDocumentAttachmentSuccess = (data) => ({
    type: DOCUMENT_ATTACHMENT_DOWNLOAD_SUCCESS,
    payload: data,
});

export const documentAttachmentRemoved = (acceptedFiles, rejectedFiles, savedFiles, deletedFiles) => ({
    type: DOCUMENT_ATTACHMENT_REMOVED,
    payload: {
        acceptedFiles: acceptedFiles,
        rejectedFiles: rejectedFiles,
        savedFiles: savedFiles,
        deletedFiles: deletedFiles,
    },
});

export const documentAttachmentsAdded = (acceptedFiles) => ({
    type: DOCUMENT_ATTACHMENTS_ADDED,
    payload: acceptedFiles,
});

export const saveDocumentAttachmentsExecute = (params) => ({
    type: SAVE_DOCUMENT_ATTACHMENTS_EXECUTE,
    payload: { params: params },
});

export const saveDocumentAttachmentsFailure = (err) => ({
    type: SAVE_DOCUMENT_ATTACHMENTS_FAILURE,
    payload: err.response.data,
});

export const saveDocumentAttachmentsSuccess = (data) => ({
    type: SAVE_DOCUMENT_ATTACHMENTS_SUCCESS,
    payload: data,
});

export const setAttachmentsAllowed = (data) => ({
    type: DOCUMENT_SET_ATTACHMENTS_ALLOWED,
    payload: data,
});

/* -------------------------------------------------------------------------- */
export const fetchDynamicDiscountingExecute = (params) => ({
    type: DOCUMENT_DYNAMIC_DISCOUNTING_EXECUTE,
    payload: { params: params },
});

export const fetchDynamicDiscountingSuccess = (data) => ({
    type: DOCUMENT_DYNAMIC_DISCOUNTING_FETCHED,
    payload: data,
});

export const fetchDynamicDiscountingFailed = (err, documentId) => ({
    type: DOCUMENT_DYNAMIC_DISCOUNTING_ERROR,
    payload: err.response.data,
});

/* -------------------------------------------------------------------------- */
export const fetchMessagesExecute = (params) => ({
    type: GET_MESSAGE_EXECUTE,
    payload: { params: params },
});

export const fetchMessagesSuccess = (data) => ({
    type: GET_MESSAGE_SUCCESS,
    payload: data,
});

export const fetchMessagesFailure = (err) => ({
    type: GET_MESSAGE_FAILED,
    payload: err,
});

export const postMessageExecute = (params) => ({
    type: POST_MESSAGE_EXECUTE,
    payload: { params: params },
});

export const postMessageSuccess = (data) => ({
    type: POST_MESSAGE_SUCCESS,
});

export const postMessageFailure = (err) => ({
    type: POST_MESSAGE_FAILED,
    payload: { error: err },
});

export const putMessageExecute = (params) => ({
    type: PUT_MESSAGE_EXECUTE,
    payload: { params: params },
});

export const putMessageSuccess = (data) => ({
    type: PUT_MESSAGE_SUCCESS,
});

export const putMessageFailure = (err) => ({
    type: PUT_MESSAGE_FAILED,
    payload: { error: err },
});

/* -------------------------------------------------------------------------- */
export const setSearchModel = (searchModel) => ({
    type: DOCUMENT_SET_SEARCH_MODEL,
    payload: { searchModel: searchModel },
});

export const documentSearchLayoutConfigFetched = (searchConfigs) => ({
    type: DOCUMENT_SEARCH_LAYOUT_CONFIG_FETCHED,
    payload: searchConfigs,
});

/* -------------------------------------------------------------------------- */
export const fetchDeliveryDetailsExecute = (params) => ({
    type: DOCUMENT_DELIVERY_EXECUTE,
    payload: { params: params },
});

export const fetchDeliveryDetailsSuccess = (data) => ({
    type: DOCUMENT_DELIVERY_FETCHED,
    payload: data,
});

export const fetchDeliveryDetailsFailed = (err, documentId) => ({
    type: DOCUMENT_DELIVERY_ERROR,
    payload: err.response.data,
});

/* -----------------------------Document Workflow--------------------------------------- */
export const fetchWorkflowExecute = () => ({
    type: DOCUMENT_WORKFLOW_EXECUTE,
});

export const fetchWorkflowSuccess = (uiActions, linesToHighlight) => ({
    type: DOCUMENT_WORKFLOW_FETCHED,
    payload: {
        linesToHighlight: {
            Line: linesToHighlight,
        },
    },
    uiActions,
});

export const fetchWorkflowFailed = (err) => ({
    type: DOCUMENT_WORKFLOW_ERROR,
    payload: err.response.data,
});

export const showActionPopup = (status) => ({ type: DOCUMENT_TOGGLE_ACTION_POPUP, payload: status[0] });

/* -----------------------------approveDocument--------------------------------------- */
export const approveDocumentExecute = () => ({
    type: DOCUMENT_WORKFLOW_EXECUTE,
});

export const approveDocumentSuccess = (response) => ({
    type: DOCUMENT_WORKFLOW_FETCHED,
    payload: response.data,
});

export const approveDocumentFailed = (err) => ({
    type: DOCUMENT_APPROVE_ERROR,
    payload: err.response.data,
});

/* -----------------------------rejectDocument--------------------------------------- */
export const rejectDocumentExecute = () => ({
    type: DOCUMENT_WORKFLOW_EXECUTE,
});

export const rejectDocumentSuccess = (response) => ({
    type: DOCUMENT_WORKFLOW_FETCHED,
    payload: response.data,
});

export const rejectDocumentFailed = (err) => ({
    type: DOCUMENT_REJECT_ERROR,
    payload: err.response.data,
});

export const fetchDocumentSenderCompanyId = (params) => ({
    type: DOCUMENT_SENDERCOMPANYID_FETCHED,
    payload: params,
});

export const fetchDocumentReceiverCompanyId = (params) => ({
    type: DOCUMENT_RECEIVERCOMPANYID_FETCHED,
    payload: params,
});
/* -----------------------------draftDocument---------------------------------------- */
export const deleteDraftDocumentExecute = (params) => ({
    type: DRAFT_DOCUMENT_DELETE_EXECUTE,
    payload: {
        params: params,
    },
});

export const deleteDraftDocumentSuccess = () => ({
    type: DRAFT_DOCUMENT_DELETE_SUCCESS,
});

export const deleteDraftDocumentFailed = (err) => ({
    type: DRAFT_DOCUMENT_DELETE_ERROR,
    payload: err.response.data,
});

/* -----------------------------pdf-------------------------------------------------- */
export const fetchDocumentPdfExecute = (params) => ({
    type: FETCH_DOCUMENT_PDF_EXECTUE,
    payload: { params: params },
});

export const fetchDocumentPdfFailed = (err) => ({
    type: FETCH_DOCUMENT_PDF_FAILED,
    payload: err.response.data,
});

export const fetchDocumentPdfSuccess = (data) => ({
    type: FETCH_DOCUMENT_PDF_SUCCESS,
    payload: data,
});

export const generateDocumentPdfExecute = (params) => ({
    type: GENERATE_DOCUMENT_PDF_EXECTUE,
    payload: { params: params },
});

export const generateDocumentPdfFailed = (err) => ({
    type: GENERATE_DOCUMENT_PDF_FAILED,
    payload: err.response.data,
});

export const generateDocumentPdfSuccess = (data) => ({
    type: GENERATE_DOCUMENT_PDF_SUCCESS,
    payload: data,
});

/* -----------------------------navigation------------------------------------------- */
export const navigateToASNs = () => ({
    type: DOCUMENT_NAVIGATE_TO_ASNS,
});

export const navigateToConsumptionAdvices = () => ({
    type: DOCUMENT_NAVIGATE_TO_CONSUMPTION_ADVICES,
});

export const navigateToCreateDocumentDraft = () => ({
    type: DOCUMENT_NAVIGATE_TO_CREATE_DOCUMENT_DRAFT,
});

export const navigateToInventoryAdvices = () => ({
    type: DOCUMENT_NAVIGATE_TO_INVENTORY_ADVICES,
});

export const navigateToInvoices = () => ({
    type: DOCUMENT_NAVIGATE_TO_INVOICES,
});

export const navigateToPurchaseOrderAcknowledgements = () => ({
    type: DOCUMENT_NAVIGATE_TO_PURCHASE_ORDER_ACKNOWLEDGEMENTS,
});

export const navigateToPurchaseOrders = () => ({
    type: DOCUMENT_NAVIGATE_TO_PURCHASE_ORDERS,
});

export const navigateToReceivingAdvices = () => ({
    type: DOCUMENT_NAVIGATE_TO_RECEIVING_ADVICES,
});

/*-----------------------Document Copy Actions ---------------------------*/
export const markAsCopy = (data) => ({
    type: DOCUMENT_IS_A_COPY,
    payload: data,
});

/*-----------------------Document Release From Hold ---------------------------*/
export const releaseDocumentExecute = (params) => ({
    type: RELEASE_DOCUMENT_EXECUTE,
    payload: { params: params },
});

export const releaseDocumentFailure = (err) => ({
    type: RELEASE_DOCUMENT_FAILURE,
    payload: err.response.data,
});

export const releaseDocumentSuccess = (data) => ({
    type: RELEASE_DOCUMENT_SUCCESS,
    payload: data,
});

export const cancelDocumentExecute = (params) => ({
    type: CANCEL_DOCUMENT_EXECUTE,
    payload: { params: params },
});

export const cancelDocumentFailure = (err) => ({
    type: CANCEL_DOCUMENT_FAILURE,
    payload: err.response.data,
});

export const cancelDocumentSuccess = (data) => ({
    type: CANCEL_DOCUMENT_SUCCESS,
    payload: data,
});

/*-----------------------Document Duplicate Check ---------------------------*/
export const documentDuplicateCheckExecute = (params) => ({
    type: DOCUMENT_DUPLICATE_CHECK_EXECUTE,
    payload: { params: params },
});

export const documentDuplicateCheckFailure = (err) => ({
    type: DOCUMENT_DUPLICATE_CHECK_FAILURE,
    payload: err.response.data,
});

export const documentDuplicateCheckSuccess = (data) => ({
    type: DOCUMENT_DUPLICATE_CHECK_SUCCESS,
    payload: data,
});

/*-------------------------Document Buyer Message--------------------------*/

export const documentGetBuyerMessageStart = () => ({
    type: DOCUMENT_GET_BUYER_MESSAGE_START,
});
export const documentGetBuyerMessageEnd = () => ({
    type: DOCUMENT_GET_BUYER_MESSAGE_END,
});
export const documentGetBuyerMessageSuccess = (messages) => {
    return {
        type: DOCUMENT_GET_BUYER_MESSAGE_SUCCESS,
        payload: { messages },
    };
};
export const documentGetBuyerMessageFailure = (error) => ({
    type: DOCUMENT_GET_BUYER_MESSAGE_FAILURE,
    payload: { error },
});
export const clearDocumentMessages = () => ({
    type: DOCUMENT_CLEAR_MESSAGES,
});
