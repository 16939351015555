import projectConfig from '../../project.config.json';
import { compareHelper } from '../advancedSearch';
import { fromPairs, map, toPairs, type, chain } from 'ramda';
import { cloneObjectHack } from '../dataConverter.js';

export function sortFields(fields, orderBy) {
    if (fields && Array.isArray(fields)) {
        switch (orderBy) {
            case 'DisplayOrder':
                // When trying to find the actual order in which to display the fields we sort by DisplayOrder then by LinePriority then by Label
                return fields.sort(function (a, b) {
                    var result = compareHelper.Number(a.DisplayOrder, b.DisplayOrder);
                    if (result === 0) result = compareHelper.Number(a.LinePriority, b.LinePriority);
                    if (result === 0) result = compareHelper.String(a.Label, b.Label);

                    return result;
                });

            case 'Label':
                // This sort is only used when determining the display order for the checkboxes in the field toggle popup
                return fields.sort((a, b) => compareHelper.String(a.Label, b.Label));

            case 'LinePriority':
                // This sort is only used when determining which fields should be in the main part of a line and which should be in the additional fields
                return fields.sort((a, b) => compareHelper.Number(a.LinePriority, b.LinePriority));

            default:
                return fields;
        }
    }

    return [];
}

export const applyLayoutOverrides = (fields) => {
    fields.forEach((field) => {
        Object.keys(field.LayoutOverrides).forEach((key) => {
            switch (key) {
                case 'DefaultControlWidth':
                case 'DisplayOrder':
                case 'LinePriority':
                case 'MinimumControlWidth':
                    field[key] = Number(field.LayoutOverrides[key]);
                    break;

                case 'Editable':
                case 'NeverVisible':
                case 'Required':
                case 'ShowVisibilityToggleCheckbox':
                case 'Visible':
                    field[key] = Boolean(field.LayoutOverrides[key]);
                    break;

                case 'Label':
                case 'LabelCssClass':
                case 'ValueCssClass':
                    field[key] = field.LayoutOverrides[key];
                    break;

                default:
                    break;
            }
        });
    });

    return fields;
};

const WINDOW_PADDING = (windowWidth) => {
    if (windowWidth > 768) return 60;
    else return 10;
};
export function determineHeaderFieldTableWidth(windowWidth) {
    return windowWidth - WINDOW_PADDING(windowWidth);
}

const DEFAULT_REMOVE_LINE_HEADER_PIXEL_WIDTH = 30;
const FIXED_LAST_LINE_SPACER_PIXEL_WIDTH = 17; //Warning: Altering this value will throw off alignment of controls on page
const FIXED_LINE_TOTAL_PIXEL_WIDTH = 137; //This value is for calculating the width of the additional line level field table and does not control the width of the line total field. That width is set in the dictionary table and this value should be updated accordingly whenever that value is changed.
export const DEFAULT_SPACER_PIXEL_WIDTH = 11;
const MAX_LINE_PIXEL_WIDTH = (windowWidth) =>
    windowWidth -
    DEFAULT_REMOVE_LINE_HEADER_PIXEL_WIDTH -
    DEFAULT_SPACER_PIXEL_WIDTH -
    FIXED_LAST_LINE_SPACER_PIXEL_WIDTH -
    WINDOW_PADDING(windowWidth);
export function determineMainAndAdditionalLineItemFields(layoutBodyFields, invoice, windowWidth) {
    var allBodyFields = [];

    for (var c = 0; c < layoutBodyFields.length; c++) {
        for (var r = 0; r < layoutBodyFields[c].length; r++) {
            for (var f = 0; f < layoutBodyFields[c][r].fields.length; f++) {
                if (fieldShouldBeDisplayed(layoutBodyFields[c][r].fields[f], invoice))
                    allBodyFields.push(layoutBodyFields[c][r].fields[f]);
            }
        }
    }
    allBodyFields = sortFields(allBodyFields, 'LinePriority');

    const mainLineFieldIds = [];

    var totalInMainLineFields = false;
    var totalMinWidth = 0;
    var totalMaxWidth = 0;
    for (
        f = 0;
        f < allBodyFields.length &&
        totalMinWidth + allBodyFields[f].MinimumControlWidth + DEFAULT_SPACER_PIXEL_WIDTH <=
            MAX_LINE_PIXEL_WIDTH(windowWidth);
        f++
    ) {
        totalMinWidth = totalMinWidth + allBodyFields[f].MinimumControlWidth + DEFAULT_SPACER_PIXEL_WIDTH;
        totalMaxWidth = totalMaxWidth + allBodyFields[f].DefaultControlWidth;
        mainLineFieldIds.push(allBodyFields[f].FieldDictionaryId);
        if (allBodyFields[f].DisplayHints === 'LineNetTotal' && windowWidth >= 600) totalInMainLineFields = true;
    }

    const mainLineFields = [];
    const additionalFields = [];

    for (c = 0; c < layoutBodyFields.length; c++) {
        var additionalFieldsColumn = [];
        for (r = 0; r < layoutBodyFields[c].length; r++) {
            var additionalFieldsRow = {
                fields: [],
                row: layoutBodyFields[c][r].row,
                title: layoutBodyFields[c][r].title,
            };

            for (f = 0; f < layoutBodyFields[c][r].fields.length; f++) {
                if (fieldShouldBeDisplayed(layoutBodyFields[c][r].fields[f], invoice)) {
                    if (mainLineFieldIds.includes(layoutBodyFields[c][r].fields[f].FieldDictionaryId))
                        mainLineFields.push(layoutBodyFields[c][r].fields[f]);
                    else additionalFieldsRow.fields.push(layoutBodyFields[c][r].fields[f]);
                }
            }

            additionalFieldsRow.fields = sortFields(additionalFieldsRow.fields, 'DisplayOrder');

            additionalFieldsColumn.push(additionalFieldsRow);
        }

        additionalFields.push(additionalFieldsColumn);
    }

    var { mainLineFieldContainers, additionalLineLevelFieldTableWidth, removeLineHeaderWidth } =
        calculateMainLineFieldWidths(mainLineFields, totalMaxWidth, totalMinWidth, windowWidth);

    return {
        lineItemMainFields: mainLineFieldContainers,
        lineItemAdditionalFields: additionalFields,
        additionalLineLevelFieldTableWidth: additionalLineLevelFieldTableWidth,
        removeLineHeaderWidth: removeLineHeaderWidth,
        totalInMainLineFields: totalInMainLineFields,
    };
}
function calculateMainLineFieldWidths(mainLineFields, totalMaxWidth, totalMinWidth, windowWidth) {
    const mainLineFieldContainers = [];
    const numberOfFieldsToBeInLine = mainLineFields.length;
    const maxLinePixelWidth = MAX_LINE_PIXEL_WIDTH(windowWidth);

    var additionalLineLevelFieldTableWidth = 0;
    var removeLineHeaderWidth = DEFAULT_REMOVE_LINE_HEADER_PIXEL_WIDTH;

    //Calculate the widths of the fields in the line table and the spacers
    mainLineFields = sortFields(mainLineFields, 'DisplayOrder');

    mainLineFields.forEach(function (field) {
        var mainLineFieldContainer = {
            field: field,
            pixelsToTrimOnField: 0,
            calculatedWidth: field.DefaultControlWidth,
            maxMinDifference: field.DefaultControlWidth - field.MinimumControlWidth,
            spacerWidth: DEFAULT_SPACER_PIXEL_WIDTH,
        };

        mainLineFieldContainers.push(mainLineFieldContainer);
    });

    var spacerWidth = DEFAULT_SPACER_PIXEL_WIDTH;
    var extraSpace = 0;
    if (totalMaxWidth + DEFAULT_SPACER_PIXEL_WIDTH * (numberOfFieldsToBeInLine - 1) < maxLinePixelWidth) {
        if (numberOfFieldsToBeInLine > 1) {
            spacerWidth = (maxLinePixelWidth - totalMaxWidth) / (numberOfFieldsToBeInLine - 1);
            extraSpace = (maxLinePixelWidth - totalMaxWidth) % (numberOfFieldsToBeInLine - 1);
            additionalLineLevelFieldTableWidth =
                windowWidth -
                FIXED_LAST_LINE_SPACER_PIXEL_WIDTH -
                FIXED_LINE_TOTAL_PIXEL_WIDTH -
                extraSpace -
                spacerWidth -
                DEFAULT_REMOVE_LINE_HEADER_PIXEL_WIDTH -
                WINDOW_PADDING(windowWidth);
        } else {
            spacerWidth = maxLinePixelWidth - FIXED_LAST_LINE_SPACER_PIXEL_WIDTH - totalMaxWidth;
            removeLineHeaderWidth = DEFAULT_REMOVE_LINE_HEADER_PIXEL_WIDTH + spacerWidth;
        }
    } else {
        var pixelsToTrim = 0;
        pixelsToTrim = totalMaxWidth + DEFAULT_SPACER_PIXEL_WIDTH * (numberOfFieldsToBeInLine - 1) - maxLinePixelWidth;

        var averagePixelsThatCanBeTrimmed = 0;
        mainLineFieldContainers.forEach(function (container) {
            averagePixelsThatCanBeTrimmed = averagePixelsThatCanBeTrimmed + container.maxMinDifference;
        });
        if (numberOfFieldsToBeInLine > 0)
            averagePixelsThatCanBeTrimmed = averagePixelsThatCanBeTrimmed / numberOfFieldsToBeInLine;
        else averagePixelsThatCanBeTrimmed = 1;

        var relativeSize;
        while (pixelsToTrim > 0) {
            // eslint-disable-next-line
            mainLineFieldContainers.forEach(function (container) {
                if (container.maxMinDifference - container.pixelsToTrimOnField > 0) {
                    relativeSize = Math.round(container.maxMinDifference / averagePixelsThatCanBeTrimmed);
                    if (relativeSize === 0) {
                        // Field is smaller than the average size. To have gotten this far the field must still have pixels to trim. Therefore we must update the relative size
                        // to 1 so that a pixel will still be trimmed from it.
                        relativeSize = 1;
                    }

                    if (relativeSize === 1) {
                        // Field is average size or smaller. Trim one pixel from the field.
                        container.pixelsToTrimOnField++;
                        pixelsToTrim--;
                    } else if (
                        relativeSize <= pixelsToTrim &&
                        relativeSize <= container.maxMinDifference - container.pixelsToTrimOnField
                    ) {
                        // Field is at least double the average size. Its relative size as a number of pixels is small enough to be trimmed from it. Trim by its relative
                        // size.
                        container.pixelsToTrimOnField = container.pixelsToTrimOnField + relativeSize;
                        pixelsToTrim = pixelsToTrim - relativeSize;
                    } else if (
                        pixelsToTrim <= relativeSize &&
                        pixelsToTrim <= container.maxMinDifference - container.pixelsToTrimOnField
                    ) {
                        // Field is at least double the average size. Its relative size is greater than the number of pixels remaining to be trimmed for the line. Also, the
                        // remaining pixels to be trimmed from the line is less than the number remaining that can be trimmed from the field. Trim the remaining number pixels
                        // that need to be trimmed from the line.
                        container.pixelsToTrimOnField = container.pixelsToTrimOnField + pixelsToTrim;
                        pixelsToTrim = 0;
                    } else {
                        // Field is at least double the average size. Its relative size is greater than the remaining pixels it has left to trim. Trim the remaining number of
                        // pixels that the field has.
                        pixelsToTrim = pixelsToTrim - (container.maxMinDifference - container.pixelsToTrimOnField);
                        container.pixelsToTrimOnField = container.maxMinDifference;
                    }
                }
            });
        }

        if (numberOfFieldsToBeInLine > 1)
            additionalLineLevelFieldTableWidth =
                windowWidth -
                FIXED_LAST_LINE_SPACER_PIXEL_WIDTH -
                FIXED_LINE_TOTAL_PIXEL_WIDTH -
                extraSpace -
                spacerWidth -
                DEFAULT_REMOVE_LINE_HEADER_PIXEL_WIDTH -
                WINDOW_PADDING(windowWidth);
        else {
            spacerWidth = DEFAULT_SPACER_PIXEL_WIDTH;
            removeLineHeaderWidth = DEFAULT_REMOVE_LINE_HEADER_PIXEL_WIDTH + spacerWidth;
        }
    }

    mainLineFieldContainers.forEach(function (container) {
        container.calculatedWidth = container.calculatedWidth - container.pixelsToTrimOnField;
        container.spacerWidth = spacerWidth;
    });

    if (numberOfFieldsToBeInLine > 0) {
        mainLineFieldContainers[mainLineFieldContainers.length - 1].spacerWidth = FIXED_LAST_LINE_SPACER_PIXEL_WIDTH;
        if (numberOfFieldsToBeInLine > 1)
            mainLineFieldContainers[mainLineFieldContainers.length - 2].spacerWidth = spacerWidth + extraSpace;
    }

    return {
        mainLineFieldContainers: mainLineFieldContainers,
        additionalLineLevelFieldTableWidth: additionalLineLevelFieldTableWidth,
        removeLineHeaderWidth: removeLineHeaderWidth,
    };
}

export function fieldShouldBeDisplayed(layoutField, invoice) {
    return layoutField.Required || layoutField.Visible || fieldIsPopulated(layoutField, invoice);
}
function fieldIsPopulated(layoutField, invoice) {
    switch (layoutField.EntityName) {
        case 1: // main
        case 2: // contact
        case 6: // misc amount
        case 7: // shipping detail
            var fieldValue = determineFieldValue(layoutField, invoice);
            return fieldValue && fieldValue !== null && fieldValue !== '' ? true : false;

        case 3: // line item
        case 4: // line item misc amount
        case 5: // line item shipping detail
            for (var index = 0; index < invoice.BusinessDocFields.Invoice.InvoiceLineItems.length; index++) {
                fieldValue = determineFieldValue(layoutField, invoice, index);
                if (fieldValue && fieldValue !== null && fieldValue !== '') return true;
            }
            return false;

        default:
            return false;
    }
}

export function determineFieldValue(field, invoiceFields, lineIndex = null) {
    switch (field.EntityName) {
        case projectConfig.EntityName.Main:
            return invoiceFields.BusinessDocFields.Invoice[field.FieldName];

        case projectConfig.EntityName.Contact:
            var contact = invoiceFields.BusinessDocFields.Invoice.InvoiceContacts.find(function (contact) {
                return contact.ContactType === field.ContactType;
            });

            if (contact) return contact[field.FieldName];
            break;

        case projectConfig.EntityName.LineItem:
            if (lineIndex !== null)
                return invoiceFields.BusinessDocFields.Invoice.InvoiceLineItems[lineIndex][field.FieldName];
            break;

        case projectConfig.EntityName.LineItemMiscAmount:
            if (
                lineIndex !== null &&
                invoiceFields.BusinessDocFields.Invoice.InvoiceLineItems[
                    lineIndex.InvoiceLineItemMiscAmounts !== undefined
                ]
            ) {
                var miscAmount = invoiceFields.BusinessDocFields.Invoice.InvoiceLineItems[
                    lineIndex
                ].InvoiceLineItemMiscAmounts.find(function (miscAmount) {
                    return (
                        miscAmount.AdjustmentReasonId === field.AdjustmentReasonId &&
                        miscAmount.AdjustmentMethodId === field.AdjustmentMethodId &&
                        miscAmount.AmountIndicator === field.AmountIndicator &&
                        miscAmount.Description === field.Label
                    );
                });

                if (miscAmount) return miscAmount.Amount;
            }
            break;

        case projectConfig.EntityName.LineItemShippingDetail:
            if (
                lineIndex !== null &&
                invoiceFields.BusinessDocFields.Invoice.InvoiceLineItems[lineIndex].InvoiceLineItemShippingDetails !==
                    undefined &&
                invoiceFields.BusinessDocFields.Invoice.InvoiceLineItems[lineIndex].InvoiceLineItemShippingDetails
                    .length > 0
            )
                return invoiceFields.BusinessDocFields.Invoice.InvoiceLineItems[lineIndex]
                    .InvoiceLineItemShippingDetails[0][field.FieldName];
            break;

        case projectConfig.EntityName.MiscAmount:
            miscAmount = invoiceFields.BusinessDocFields.Invoice.InvoiceMiscAmounts.find(function (miscAmount) {
                return (
                    miscAmount.AdjustmentReasonId === field.AdjustmentReasonId &&
                    miscAmount.AdjustmentMethodId === field.AdjustmentMethodId &&
                    miscAmount.AmountIndicator === field.AmountIndicator &&
                    miscAmount.Description === field.Label
                );
            });

            if (miscAmount) return miscAmount.Amount;
            break;

        case projectConfig.EntityName.ShippingDetail:
            if (invoiceFields.BusinessDocFields.Invoice.InvoiceShippingDetails.length > 0)
                return invoiceFields.BusinessDocFields.Invoice.InvoiceShippingDetails[0][field.FieldName];
            break;

        default:
            break;
    }

    return null;
}
export function determineSecondaryFieldName(fieldName) {
    // TODO: update field dictionary to handle this instead of hard-coding this lookup
    switch (fieldName) {
        case 'SalesTaxAmount':
            return 'SalesTaxPercent';
        default:
            return null;
    }
}
export function determineSecondaryFieldValue(field, invoiceFields, lineIndex = null) {
    if (
        field.ControlType !== projectConfig.ControlType.Tax &&
        field.ControlType !== projectConfig.ControlType.UnitAmountAndAmount
    )
        return null;

    if (field.EntityName === projectConfig.EntityName.LineItemMiscAmount && lineIndex !== null) {
        var miscAmount = invoiceFields.BusinessDocFields.Invoice.InvoiceLineItems[
            lineIndex
        ].InvoiceLineItemMiscAmounts.find(function (miscAmount) {
            return (
                miscAmount.AdjustmentReasonId === field.AdjustmentReasonId &&
                miscAmount.AdjustmentMethodId === field.AdjustmentMethodId &&
                miscAmount.AmountIndicator === field.AmountIndicator &&
                miscAmount.Description === field.Label
            );
        });

        if (miscAmount) {
            if (field.ControlType === projectConfig.ControlType.Tax) return miscAmount.Rate;
            else return miscAmount.UnitPrice;
        }
    } else if (field.EntityName === projectConfig.EntityName.MiscAmount) {
        miscAmount = invoiceFields.BusinessDocFields.Invoice.InvoiceMiscAmounts.find(function (miscAmount) {
            return (
                miscAmount.AdjustmentReasonId === field.AdjustmentReasonId &&
                miscAmount.AdjustmentMethodId === field.AdjustmentMethodId &&
                miscAmount.AmountIndicator === field.AmountIndicator &&
                miscAmount.Description === field.Label
            );
        });

        if (miscAmount) {
            if (field.ControlType === projectConfig.ControlType.Tax) return miscAmount.Rate;
            else return miscAmount.UnitPrice;
        }
    } else {
        var secondaryFieldName = determineSecondaryFieldName(field.FieldName);
        if (secondaryFieldName !== null) return invoiceFields.BusinessDocFields.Invoice[secondaryFieldName];
    }

    return null;
}

const flattenObj = (obj) => {
    const go = (obj_) =>
        chain(([k, v]) => {
            if (type(v) === 'Object' || type(v) === 'Array') {
                return map(([k_, v_]) => [`${k}.${k_}`, v_], go(v));
            } else {
                return [[k, v]];
            }
        }, toPairs(obj_));

    return fromPairs(go(obj));
};
export const findByProp = (obj, propName) => {
    const flatten = flattenObj(obj);
    const k = Object.keys(flatten).find((key) => key.includes(propName));
    return flatten[k];
};

export const reduceFields = (acc, items) => {
    Object.keys(items).forEach((key) => {
        const item = items[key];
        acc.row = item.row;
        for (let i = 0; i < item.fields.length; i++) {
            item.fields[i].row = item.row;
            item.fields[i].column = item.column;
            item.fields[i].title = item.title;
            item.fields[i].cells = item.Cells;
        }
        acc.fields = acc.fields.concat(item.fields);
    });

    return acc;
};

export const getFieldLayoutRecord = (layout, entityName, fieldName, contactType = null) => {
    const sectionNames = ['Header', 'Body', 'Footer'];

    let fieldLayout = null;

    for (let sectionNameIndex = 0; sectionNameIndex < sectionNames.length && fieldLayout === null; sectionNameIndex++) {
        const sectionName = sectionNames[sectionNameIndex];
        const section = layout.Large[sectionName] ? layout.Large[sectionName] : [];

        for (let cellIndex = 0; cellIndex < section.length && fieldLayout === null; cellIndex++) {
            const cell = section[cellIndex];

            for (let fieldIndex = 0; fieldIndex < cell.Fields.length && fieldLayout === null; fieldIndex++) {
                const field = cell.Fields[fieldIndex];

                if (
                    field.EntityName === entityName &&
                    field.FieldName === fieldName &&
                    (contactType === null || field.ContactType === contactType)
                ) {
                    fieldLayout = field;
                }
            }
        }
    }

    return fieldLayout;
};

export const getFieldLayoutRecordByFieldPurpose = (layoutSection, purpose) => {
    let fieldLayout = layoutSection.fields.find(function (fieldLayout) {
        return fieldLayout.DisplayHints === purpose;
    });

    if (!fieldLayout) {
        fieldLayout = layoutSection.fields.find(function (fieldLayout) {
            return fieldLayout.FieldName === purpose;
        });
    }

    if (fieldLayout) {
        return cloneObjectHack(fieldLayout);
    }

    return null;
};

export const miscAmountLayoutDoesNotExist = (sectionLayout, entityName, miscAmount) => {
    // Look through every field of every cell for an exact match of a field dictionary record
    for (let cellIndex = 0; cellIndex < sectionLayout.length; cellIndex++) {
        const cellLayout = sectionLayout[cellIndex];

        for (let fieldIndex = 0; fieldIndex < cellLayout.Fields.length; fieldIndex++) {
            const fieldLayout = cellLayout.Fields[fieldIndex];

            // If we find an exact match return false
            if (
                fieldLayout.EntityName === entityName &&
                Number(fieldLayout.AdjustmentMethodId) === Number(miscAmount.AdjustmentMethodId) &&
                Number(fieldLayout.AdjustmentReasonId) === Number(miscAmount.AdjustmentReasonId) &&
                Number(fieldLayout.AmountIndicator) === Number(miscAmount.AmountIndicator) &&
                fieldLayout.Label === miscAmount.Description
            ) {
                return false;
            }
        }
    }

    // If no match was found return true
    return true;
};

export const addTempMiscAmountLayout = (sectionLayout, entityName, miscAmount) => {
    // Look through every field of every cell for a match of a field dictionary record on the adjustment reason id and entity name only
    for (let cellIndex = 0; cellIndex < sectionLayout.length; cellIndex++) {
        const cellLayout = sectionLayout[cellIndex];

        for (let fieldIndex = 0; fieldIndex < cellLayout.Fields.length; fieldIndex++) {
            const fieldLayout = cellLayout.Fields[fieldIndex];

            if (
                fieldLayout.EntityName === entityName &&
                Number(fieldLayout.AdjustmentReasonId) === Number(miscAmount.AdjustmentReasonId)
            ) {
                // Create a copy of the partially matched layout
                let tempMiscAmountLayout = cloneObjectHack(fieldLayout);

                // Give the new temp layout a fake id so it doesn't interfere with the record from which it was created
                tempMiscAmountLayout.FieldDictionaryId =
                    'temp_' + tempMiscAmountLayout.FieldDictionaryId + '_' + miscAmount.Description;

                // Update the new temp layout to match the misc amount
                tempMiscAmountLayout.AdjustmentMethodId = Number(miscAmount.AdjustmentMethodId);
                tempMiscAmountLayout.AdjustmentReasonId = Number(miscAmount.AdjustmentReasonId);
                tempMiscAmountLayout.AmountIndicator = Number(miscAmount.AmountIndicator);
                tempMiscAmountLayout.Label = miscAmount.Description;

                // Update the JSON path on the new temp layout to match the misc amount exactly (adjustment reason, adjustment method, amount indicator, and description)
                const jsonPathRegex = /(.*)(\[\?\(.*\)\])(\.[a-zA-Z0-9]*)/;
                const jsonPathReplacementTempLayout =
                    '$1[?(@.AdjustmentReasonId == ' +
                    miscAmount.AdjustmentReasonId +
                    ' && @.AdjustmentMethodId == ' +
                    miscAmount.AdjustmentMethodId +
                    ' && @.AmountIndicator == ' +
                    miscAmount.AmountIndicator +
                    ' && @.Description == "' +
                    miscAmount.Description +
                    '")]$3';
                tempMiscAmountLayout.JSONPath = tempMiscAmountLayout.JSONPath.replace(
                    jsonPathRegex,
                    jsonPathReplacementTempLayout
                );

                // Update the JSON path on the original layout to include the label in order to differentiate it from the temp record
                // If we don't do this, the temp record's value could potentially show up twice if it matches on adjustment reason,
                // adjustment method, and amount indicator but not the description (label).
                const jsonPathReplacementOriginalLayout =
                    '$1[?(@.AdjustmentReasonId == ' +
                    fieldLayout.AdjustmentReasonId +
                    ' && @.AdjustmentMethodId == ' +
                    fieldLayout.AdjustmentMethodId +
                    ' && @.AmountIndicator == ' +
                    fieldLayout.AmountIndicator +
                    ' && @.Description == "' +
                    fieldLayout.Label +
                    '")]$3';
                fieldLayout.JSONPath = fieldLayout.JSONPath.replace(jsonPathRegex, jsonPathReplacementOriginalLayout);

                // Update the new temp layout to be visible and not toggleable
                tempMiscAmountLayout.Visible = true;
                tempMiscAmountLayout.ShowVisibilityToggleCheckbox = false;
                tempMiscAmountLayout.Required = false;

                // Add the new temp layout to the cell
                cellLayout.Fields.push(tempMiscAmountLayout);

                return true;
            }
        }
    }

    return false;
};

export const modifyLayoutToIncludeBuyerWebEntryTestFields = (sectionLayout) => {
    let vendorNumberLayout = null;
    let vendorSiteIdLayout = null;

    // Look through every field of every cell for an exact match of each field dictionary record that needs to be modified
    for (
        let cellIndex = 0;
        cellIndex < sectionLayout.length && (!vendorNumberLayout || !vendorSiteIdLayout);
        cellIndex++
    ) {
        const cellLayout = sectionLayout[cellIndex];

        for (
            let fieldIndex = 0;
            fieldIndex < cellLayout.Fields.length && (!vendorNumberLayout || !vendorSiteIdLayout);
            fieldIndex++
        ) {
            const fieldLayout = cellLayout.Fields[fieldIndex];

            if (fieldLayout.EntityName === projectConfig.EntityName.Main) {
                if (!vendorNumberLayout && fieldLayout.FieldName === 'VendorNumber') {
                    vendorNumberLayout = fieldLayout;
                } else if (!vendorSiteIdLayout && fieldLayout.FieldName === 'VendorSiteID') {
                    vendorSiteIdLayout = fieldLayout;
                }
            }
        }
    }

    if (vendorNumberLayout) {
        modifyTempBuyerWebEntryTestFieldLayout(vendorNumberLayout, 1, 'true');
    }

    if (vendorSiteIdLayout) {
        modifyTempBuyerWebEntryTestFieldLayout(vendorSiteIdLayout, 2, null);
    }
};
const modifyTempBuyerWebEntryTestFieldLayout = (fieldLayout, displayOrder, labelBold) => {
    fieldLayout.Editable = true;
    fieldLayout.Editability = projectConfig.Editability.Editable;
    fieldLayout.Visible = true;
    fieldLayout.VisibleOnManageInvoicePage = false;

    if (displayOrder !== null) {
        fieldLayout.DisplayOrder = displayOrder;

        if (fieldLayout.LayoutOverrides && fieldLayout.LayoutOverrides.DisplayOrder) {
            fieldLayout.LayoutOverrides.DisplayOrder = displayOrder;
        }
    }

    if (labelBold !== null) {
        if (!fieldLayout.ControlFormat) {
            fieldLayout.ControlFormat = {};
        }

        fieldLayout.ControlFormat.label_bold = labelBold;
    }
};
