import { SIM_REQUEST_FIELDS, RESET_TO_INITIAL_STATE } from '../../actions/document/documentCreationActions';
import { USER_LOGIN_FAILED, USER_LOGIN_PENDING, USER_LOGIN_SUCCESS, USER_LOGOUT } from '../../actions/userlogin';

const initialState = {};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SIM_REQUEST_FIELDS:
            return {
                ...state,
                CustomerId: payload.CustomerId,
                CustomerName: payload.CustomerName,
                Description: payload.Description,
                SIMDocumentTypeId: payload.SIMDocumentTypeId,
                DocumentClass: payload.DocumentClass,
                DueDate: payload.DueDate,
                ProcurementId: payload.ProcurementProfileId,
                DocumentType: payload.DocumentType,
                DataEntrySchedule: payload.DataEntrySchedule,
                SIMRequestId: payload.SIMRequestId,
            };
        case USER_LOGIN_FAILED:
        case USER_LOGIN_PENDING: {
            // if it was timeout retrun currenState, otherwhise initialState
            const { timeout } = state;
            return timeout && timeout === true ? state : initialState;
        }
        case RESET_TO_INITIAL_STATE:
        case USER_LOGIN_SUCCESS: {
            // if it was timeout retrun currenState, otherwhise initialState
            // remove timeout flag
            const { timeout, ...currentState } = state;
            return timeout && timeout === true ? currentState : initialState;
        }
        case USER_LOGOUT: {
            const { timeout } = payload;
            return timeout && timeout === true ? { ...state, timeout } : initialState;
        }
        default:
            return state;
    }
};
