import { DPSRuleExecutionContext } from './DPSRuleExecutionContext';
import { UserWorkflowExecutionContext } from './UserWorkflowExecutionContext';
import { WebEntryExecutionContext } from './WebEntryExecutionContext';

export type ExecutionContext = DPSRuleExecutionContext | UserWorkflowExecutionContext | WebEntryExecutionContext;

export enum ExecutionContextType {
    WebEntry = 1,
    UserWorkflowOrWorkflowActivity = 2,
    DPSRule = 3,
}
