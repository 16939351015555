import { FC, FormEvent, ReactNode, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { Button, LoadingMessage, ErrorAlert } from '../../../ui';
import { FormTemplate } from './FormTemplate';
import { useAuthService } from '../Auth';
import { useQueryParams } from '../../../utils';
import { useMutation, useQuery } from '@tanstack/react-query';

export interface ISSOPageProps {
    marketingContent?: ReactNode;
}

export const SSOPage: FC<ISSOPageProps> = ({ marketingContent }) => {
    const authService = useAuthService();
    const { token } = useQueryParams();
    const getSSOSettingsResult = useQuery(['SSOSettings'], authService.getSSOSettings, { retry: 1 });
    const [isInitingSSO, setIsInitingSSO] = useState(false);
    const initSSOMutation = useMutation(authService.initSSO, {
        onError: () => setIsInitingSSO(false),
    });
    const exchangeSSOTokenMutation = useMutation(authService.exchangeSSOToken, {
        onError: () => setIsInitingSSO(false),
    });

    useEffect(() => {
        if (token) {
            exchangeSSOTokenMutation.mutate(token);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const handleSSOSignIn = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setIsInitingSSO(true);
        initSSOMutation.mutate();
    };

    return (
        <FormTemplate
            mainContent={(() => {
                if (token && !exchangeSSOTokenMutation.error) {
                    return <LoadingMessage>Logging You In</LoadingMessage>;
                }

                if (getSSOSettingsResult.isLoading && !getSSOSettingsResult.isError) {
                    return <LoadingMessage>Fetching SSO Settings</LoadingMessage>;
                }

                if (isInitingSSO) {
                    return <LoadingMessage>Loading Next Step</LoadingMessage>;
                }

                return (
                    <form onSubmit={(e) => handleSSOSignIn(e)}>
                        <Stack sx={{ alignItems: 'center' }}>
                            <ErrorAlert
                                error={
                                    exchangeSSOTokenMutation.error ||
                                    getSSOSettingsResult.error ||
                                    initSSOMutation.error
                                }
                                testId="sso-page-error"
                            />
                            <Button
                                variant="default"
                                type="submit"
                                sx={{ m: 1, mt: 3 }}
                                testid="sso-sign-in-button"
                                disabled={getSSOSettingsResult.error ? true : false}
                            >
                                Sign in with Transcepta
                            </Button>
                        </Stack>
                    </form>
                );
            })()}
            marketingContent={marketingContent}
            testId="sso-form"
        />
    );
};
