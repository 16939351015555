import { toCamelCase, toPascalCase } from '../../utils/casing';
import { IDashboardRejectionTypesViewModel } from '../Api/ViewModels';
import { DashboardRejectionTypesApi } from '../Api';
import { IDashboardRejectionType, IDashboardRejectionTypesGrid } from './types';
import { createDashboardRejectionTypeFilter } from './DashboardRejectionTypeService.filter';

class DashboardRejectionTypesService {
    api = new DashboardRejectionTypesApi();

    public getDashboardRejectionTypesGrid = async (): Promise<IDashboardRejectionTypesGrid[] | undefined> => {
        const viewModel = await this.api.getAllDashboardRejectionTypes();

        const rejectionType = viewModel?.data?.map((model) => toCamelCase<IDashboardRejectionTypesViewModel>(model));

        const grid: IDashboardRejectionTypesGrid[] = [];

        rejectionType?.forEach((value) => {
            grid.push({
                id: value.id,
                validationFailureType: value.validationFailureErrorTypeId.toString(),
                label: value.label,
                description: value.shortDescription ?? '',
                validationFailureErrorTypeId: value.validationFailureErrorTypeId,
            });
        });

        return grid;
    };

    public getDashboardRejectionType = async (id: number): Promise<IDashboardRejectionType | undefined> => {
        const viewModel = await this.api.getDashboardRejectionType(id);

        if (!viewModel.data) {
            throw Error(viewModel.status.toString());
        }

        return {
            id: viewModel.data[0].Id,
            validationFailureErrorTypeId: viewModel.data[0].ValidationFailureErrorTypeId,
            shortDescription: viewModel.data[0].ShortDescription,
            label: viewModel.data[0].Label,
            lastUpdateDate: viewModel.data[0].LastUpdateDate,
            rowversion: viewModel.data[0].Rowversion,
        };
    };

    public getDashboardRejectionTypeByValidationFailureTypeId = async (
        validationFailureTypeId: number
    ): Promise<IDashboardRejectionType | undefined> => {
        const filter = createDashboardRejectionTypeFilter(validationFailureTypeId);
        const viewModel = await this.api.getAllDashboardRejectionTypes(filter);

        if (viewModel.data === undefined || viewModel.data == null || viewModel.data?.length === 0) {
            return undefined;
        }

        return {
            id: viewModel.data[0].Id,
            validationFailureErrorTypeId: viewModel.data[0].ValidationFailureErrorTypeId,
            shortDescription: viewModel.data[0].ShortDescription,
            label: viewModel.data[0].Label,
            lastUpdateDate: viewModel.data[0].LastUpdateDate,
            rowversion: viewModel.data[0].Rowversion,
        };
    };

    public upsertDashboardRejectionType = async (
        validationFailureErrorTypeId: number,
        label: string,
        shortDescription?: string,
        id?: number,
        lastUpdateDate?: string,
        rowversion?: number[]
    ) => {
        const model: IDashboardRejectionType = {
            validationFailureErrorTypeId,
            shortDescription,
            label,
            lastUpdateDate,
            rowversion,
        };

        const viewModel = toPascalCase(model) as IDashboardRejectionTypesViewModel;

        if (id) {
            return this.api.updateDashboardRejectionType(id, viewModel);
        }
        return this.api.createDashboardRejectionType(viewModel);
    };

    public deleteDashboardRejectionType = async (id: number) => {
        await this.api.deleteDashboardRejectionType(id);
    };
}

export default DashboardRejectionTypesService;
