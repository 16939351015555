import { ReactNode, FC } from 'react';
import { Alert as MuiAlert, AlertProps, CircularProgress } from '@mui/material';
import { AlertSeverity } from './types';

export interface IAlertProps {
    severity: AlertSeverity;
    testId: string;
    loading?: boolean;
    action?: ReactNode;
    closeText?: string;
    icon?: ReactNode;
    onClose?: AlertProps['onClose'];
    classes?: AlertProps['classes'];
    sx?: AlertProps['sx'];
}

export const Alert: FC<IAlertProps> = ({
    severity,
    action,
    closeText,
    icon,
    onClose,
    classes,
    children,
    loading,
    testId,
    sx,
}) => {
    return (
        <MuiAlert
            severity={severity}
            action={action}
            closeText={closeText}
            icon={loading ? <CircularProgress size="22px" /> : icon}
            onClose={onClose}
            classes={classes}
            data-testid={testId}
            sx={{
                display: 'flex',
                alignItems: 'center',
                whiteSpace: 'pre-line',
                ...sx,
            }}
        >
            {children}
        </MuiAlert>
    );
};
