import { IDocumentViewModel } from '../../../../services/Document/Api';
import { ValidationEvent } from './types';

type PreviousExecution = {
    event: ValidationEvent;

    document: IDocumentViewModel;
};

export type ContextInformation = {
    /**
     * front-end only
     */
    event: ValidationEvent | null;

    /**
     * front-end only
     *
     * null if it's the first time the script is running
     */
    previousExecution: PreviousExecution | null;
};

type ClientOptions = {
    type: 'client';

    event: ValidationEvent;
    previousExecution: PreviousExecution | null;
};

type ServerOptions = {
    type: 'server';
};

type EnvironmentOptions = ClientOptions | ServerOptions;

export class ContextInformationCollector {
    constructor(private envOptions: EnvironmentOptions) {}

    getContextInformation(): ContextInformation {
        if (this.envOptions.type === 'server') {
            return {
                event: null,
                previousExecution: null,
            };
        }

        const { event, previousExecution } = this.envOptions;

        return {
            event,
            previousExecution,
        };
    }
}
