import { IAppConfig } from './types';
import { RecursivePartial } from '../../types';

let defaultConfig: RecursivePartial<IAppConfig> = {};

export function setPortalDefaultConfig(config: RecursivePartial<IAppConfig>) {
    defaultConfig = config;
}

export function getPortalDefaultConfig() {
    return defaultConfig;
}
