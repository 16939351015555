import { DashboardApi, TopSupplierRejections } from '../../../Api';
import { DashboardReportService } from '../../DashboardReportService';
import { createDashboardReportService } from '../../createDashboardReportService';
import { DashboardDataPayload } from '../../../Api/DashboardDataPayload';
import { GridRowModelUpdate } from '@mui/x-data-grid';
import { DataGraphColumn } from '../../../Charts/DataGraphTable';

export class TopSupplierRejectionsService implements DashboardReportService<TopSupplierRejections, GridRowModelUpdate> {
    private api = new DashboardApi();

    get storedProcedure() {
        return 'DashboardReportTopSupplierRejections' as const;
    }

    async fetchReport(settings: DashboardDataPayload) {
        const res = await this.api.getDashboardReportData<TopSupplierRejections>(settings, this.storedProcedure);
        return res.data;
    }

    get nullifyDataIfEmptyObj() {
        return {
            optIn: false,
            xAxisPropertyKey: undefined,
        };
    }

    transformVMToChartData(vm: TopSupplierRejections['viewModel']) {
        return vm.map((p) => ({
            id: p.SupplierCompanyId,
            supplierName: p.SupplierName,
            supplierCompanyId: p.SupplierCompanyId,
            reasons: p.Reasons,
            numberOfRejections: p.NumRejections?.toLocaleString('en-US'),
        }));
    }

    async exportToCSV(settings: DashboardDataPayload) {
        return this.api.exportDashboardReport(settings, 'DashboardExportReportTopSupplierRejections');
    }

    get tableColumns(): DataGraphColumn[] {
        return [
            {
                field: 'supplierName',
                headerName: 'Supplier Name',
                flex: 2,
            },
            {
                field: 'numberOfRejections',
                headerName: 'Number of rejections',
                flex: 2,
            },
            {
                field: 'reasons',
                headerName: 'Rejection Reasons',
                flex: 3,
                align: 'left',
            },
        ];
    }
}

export const useTopSupplierRejectionsService = createDashboardReportService(TopSupplierRejectionsService);
