interface IProps {
    KeyValues: [
        {
            Key: string;
            Value: any;
        }
    ];
}

const mapCompanyCustomData = (data: IProps, datasetValuePath: string) => {
    const valuePath: string = datasetValuePath.substring(datasetValuePath.lastIndexOf('/') + 1);
    if (data.KeyValues === null || !data.KeyValues.length) {
        return [];
    }

    // wrap it in an array if it isn't one
    const values = ((val) => (Array.isArray(val) ? val : [val]))(data.KeyValues[0].Value);

    // filter null values out
    const options = values.reduce<{ value: any; label: any }[]>((acc, value: any) => {
        if (value[valuePath] !== null) {
            acc.push({
                value: value[valuePath],
                label: value[valuePath],
            });
        }

        return acc;
    }, []);

    return options;
};

export default mapCompanyCustomData;
