import { Box, CircularProgress, Typography } from '@mui/material';
import { ControlFormatFields } from '../parseControlFormatFields';
import { FCNC } from '../../../ui';
import { SearchState } from './useSearchState';
import { getDocumentTypeName } from '../utils';
import { GoToActiveRevisionLink } from '../GoToActiveRevisionLink';

export interface SearchForRelatedDocumentAndDisplayLinkProps {
    formatting: ControlFormatFields;

    value: string;

    searchState: SearchState;
}

export const SearchForRelatedDocumentAndDisplayLink: FCNC<SearchForRelatedDocumentAndDisplayLinkProps> = ({
    formatting,
    value,
    searchState,
}) => {
    if (searchState.type === 'NoSearchPerformedState') {
        return null;
    }

    if (searchState.type === 'PerformingSearchState') {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CircularProgress size="14px" sx={{ mr: 1 }} />
                <Typography>Searching for PO in the Transcepta system.</Typography>
            </Box>
        );
    }

    if (searchState.type === 'NoRelatedDocumentFoundState') {
        const { relatedDocumentType } = formatting;
        const documentTypeName = getDocumentTypeName(relatedDocumentType);
        return (
            <Typography sx={{ color: 'orange' }}>
                The {documentTypeName} was not found in the Transcepta system.
            </Typography>
        );
    }

    if (searchState.type === 'RelatedDocumentFoundWithDifferentVendorState') {
        const { relatedDocumentType } = formatting;
        const documentTypeName = getDocumentTypeName(relatedDocumentType);
        return (
            <>
                <Typography sx={{ color: 'orange' }}>
                    The {documentTypeName} was found in the Transcepta system, but linked to a different vendor.
                </Typography>
                <GoToActiveRevisionLink
                    relatedDocumentId={searchState.relatedDocumentId}
                    relatedDocumentType={formatting.relatedDocumentType}
                    relatedDocumentNumber={value}
                />
            </>
        );
    }

    return (
        <GoToActiveRevisionLink
            relatedDocumentId={searchState.relatedDocumentId}
            relatedDocumentType={formatting.relatedDocumentType}
            relatedDocumentNumber={value}
        />
    );
};
