import { BusinessDocType } from '../../../types';

export interface RawControlFormatFields {
    isRelatedDocumentField?: unknown;
    relatedDocumentType?: unknown;
    relatedDocumentLinkType?: unknown;
    filterByVendorNumber?: 'true' | 'false';
}

export const relatedDocumentLinkTypes = ['GoToActive', 'ShowRevisions'] as const;

export type RelatedDocumentLinkType = typeof relatedDocumentLinkTypes[number];

export interface ControlFormatFields {
    isRelatedDocumentField: boolean;
    relatedDocumentType: BusinessDocType;
    relatedDocumentLinkType: RelatedDocumentLinkType;
    filterByVendorNumber: boolean;
}
