import { useQuery } from '@tanstack/react-query';
import { fetchDocumentRevisions } from './fetchDocumentRevisions';

export function useDocumentRevisions(relatedDocumentId: number | null) {
    const { data, error } = useQuery(
        ['documentRevision', relatedDocumentId],
        () => fetchDocumentRevisions(relatedDocumentId!),
        {
            retry: false,
            enabled: relatedDocumentId !== null,
            staleTime: Infinity,
            cacheTime: Infinity,
        }
    );

    return { data, error };
}
