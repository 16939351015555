import { setInstances } from './indirection';
import { portalUserService } from './PortalUserService';
import { useCurrentUser, useCurrentUserCan, useIsExtraFeatureEnabled, useCurrentCompanyId } from './hooks';

setInstances({
    portalUserService,
    useCurrentUser,
    useCurrentUserCan,
    useIsExtraFeatureEnabled,
    useCurrentCompanyId,
});
