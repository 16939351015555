import { useState } from 'react';
import {
    renderErrorToErrorMessage,
    Form,
    TwoActionButtons,
    CRUDButtons,
    GenericPageTemplate,
    EntityNotFoundError,
    PageNotFound,
    ConfirmationDialog,
} from '../../ui';
import { ICRUDService, ICRUDTemplateProps } from './types';
import { renderLayoutConfig, useFormLayoutConfig, useCRUDTemplateState } from './utils';
import { useCRUDPermissions } from './hooks';

/**
 * Renders a Create, Edit, or View page for an entity.
 *
 * Contains all the state management to keep our CRUD page experience consistent.
 */
export function CRUDTemplate<ID extends any, U extends object, T extends ICRUDService<ID, U>>({
    testId,
    service,
    entityType,
    entityId,
    readPermission,
    createPermission,
    editPermission,
    deletePermission,
    onCancel,
    onUpdateSuccess,
    onCreateSuccess,
    onDeleteSuccess,
    renderFormLayout,
    successFlashMessageKey,
    loadingMessage,
    searchPagePathName,
    onFormStateChange,
    session,
    confirmDeletion,
    customFooterMessage,
    instructions,
    headerContent,
    hideDeleteButton = false,
    readonly = false,
    filePaths = [],
}: ICRUDTemplateProps<ID, U, T>) {
    const [shouldDisplayDialog, setShouldDisplayDialog] = useState(false);

    // tells us how to render the CRUD page based on the permissions the user has.
    const { canDelete, editable, mode } = useCRUDPermissions(
        entityId,
        readPermission,
        createPermission,
        editPermission,
        deletePermission,
        readonly
    );

    // the form layout can be data-driven if the service provides a method to fetch a data-driven
    // config. this hook fetches that configuration.
    const {
        isLoading: isLoadingLayoutConfig,
        data: layoutConfig,
        error: layoutConfigLoadError,
    } = useFormLayoutConfig<ID, U, T>(service);

    // manages the state of the CRUD page.
    const crudState = useCRUDTemplateState(
        service,
        entityId,
        session,
        editable,
        successFlashMessageKey,
        onCancel,
        onFormStateChange,
        onUpdateSuccess,
        onCreateSuccess,
        onDeleteSuccess,
        filePaths
    );

    // we determine the loading message, if any, to display here.
    const formLoadingMessage = (() => {
        if (crudState.isLoadingEntity) {
            return `Loading ${entityType}`;
        }

        if (isLoadingLayoutConfig) {
            return `Loading ${entityType} form layout`;
        }

        if (loadingMessage) {
            return loadingMessage;
        }

        return null;
    })();

    // determine the "saving message" here. this is what is displayed when an action is taking
    // place.
    const formSavingMessage = (() => {
        if (crudState.saveMutation.isLoading) {
            return `Saving ${entityType}`;
        }

        if (crudState.deleteMutation.isLoading) {
            return `Deleting ${entityType}`;
        }

        return null;
    })();

    // determine the "success message" here. this is displayed after an action is successfully
    // completed.
    const formSuccessMessage = (() => {
        if (crudState.saveMutation.isSuccess || crudState.successFlashMessage === 'true') {
            return `${entityType} Saved`;
        }

        if (crudState.deleteMutation.isSuccess) {
            return `${entityType} Deleted`;
        }

        return null;
    })();

    // determines what form buttons to display
    const formButtons = (() => {
        // note that this implies that the user needs "edit" permissions to "delete".
        // this is a good idea, because it would be strange to display a "delete" button
        // to a user who cannot edit the entity.
        if (!editable || readonly) {
            return null;
        }

        const cancelButton = {
            text: onCancel ? 'Cancel' : 'Reset',
            onClick: crudState.handleCancel,
        };

        const performDelete = crudState.handleDelete;

        const handleDelete = () => {
            setShouldDisplayDialog(true);
        };

        const deleteButton = {
            text: 'Delete',
            onClick: confirmDeletion ? handleDelete : performDelete,
        };

        if (!service.deleteEntity || !canDelete || mode === 'Create' || hideDeleteButton) {
            return (
                <TwoActionButtons
                    testId={`${testId}-body-form-buttons`}
                    submitButton={{
                        text: 'Save',
                    }}
                    cancelButton={cancelButton}
                />
            );
        }

        return (
            <>
                <CRUDButtons
                    testId={`${testId}-body-form-buttons`}
                    submitButton={{
                        text: 'Save',
                    }}
                    cancelButton={cancelButton}
                    deleteButton={deleteButton}
                />
                {shouldDisplayDialog && (
                    <ConfirmationDialog
                        title={`Delete ${entityType}`}
                        prompt={`Are you sure you want to delete this ${entityType}? This operation cannot be undone.`}
                        confirm={{
                            onClick: () => {
                                setShouldDisplayDialog((previous) => !previous);
                                performDelete();
                            },
                            buttonTitle: 'Yes',
                            autoFocus: true,
                        }}
                        reject={{
                            onClick: () => {
                                setShouldDisplayDialog((previous) => !previous);
                            },
                            buttonTitle: 'No',
                        }}
                        open={shouldDisplayDialog}
                    />
                )}
            </>
        );
    })();

    // renders the form columns
    const formColumns = (() => {
        // preferentially rendering from a data-driven layout config. note that the type of our props
        // prevents passing a `renderFormLayout` function if this is ever true.
        if (layoutConfig) {
            return renderLayoutConfig(layoutConfig, crudState.vm, crudState.setVM);
        }

        // this should never be true while fetching the data-driven layout because the types prevent
        // passing it.
        if (renderFormLayout) {
            return renderFormLayout(crudState.fields, crudState.handleFieldChange, crudState.stageSession);
        }

        // this is what would render while loading the data-driven layout
        return [];
    })();

    if (mode === 'CreateAccessDenied') {
        return (
            <GenericPageTemplate
                testId={testId}
                title="Access Denied"
                body={<>You do not have sufficient permissions to create a new {entityType}</>}
            />
        );
    }

    if (mode === 'EditAccessDenied') {
        return (
            <GenericPageTemplate
                testId={testId}
                title="Access Denied"
                body={<>You do not have sufficient permissions to view or edit this {entityType}</>}
            />
        );
    }

    if (
        (mode === 'Edit' || mode === 'View') &&
        crudState.entityDataLoadError &&
        crudState.entityDataLoadError instanceof EntityNotFoundError
    ) {
        return <PageNotFound testId={testId} goToText={`Go to ${entityType} search`} goToRoute={searchPagePathName} />;
    }

    return (
        <GenericPageTemplate
            testId={testId}
            title={`${mode} ${entityType}`}
            headerContent={headerContent}
            body={
                <>
                    {instructions}
                    <Form
                        testId={`${testId}-body-form`}
                        loadingMessage={formLoadingMessage}
                        savingMessage={formSavingMessage}
                        successMessage={formSuccessMessage}
                        errorMessage={renderErrorToErrorMessage(
                            crudState.entityDataLoadError ||
                                layoutConfigLoadError ||
                                crudState.saveMutation.error ||
                                crudState.deleteMutation.error
                        )}
                        requiredMessage={editable && !readonly}
                        readonly={!editable || readonly}
                        formButtons={formButtons}
                        onSubmit={crudState.handleSave}
                        columns={formColumns}
                        customFooterMessage={customFooterMessage}
                    />
                </>
            }
        />
    );
}
