//
import { push } from 'connected-react-router';
import R from '../routes';
import { Action } from '../models/Actions';
import * as CustomerModels from '../models/CustomerModels';

export const SELECT_CUSTOMER_LIST_FETCH_EXECUTE = 'SELECT_CUSTOMER_LIST_FETCH_EXECUTE';
export const SELECT_CUSTOMER_LIST_FETCH_FAILED = 'SELECT_CUSTOMER_LIST_FETCH_FAILED';
export const SELECT_CUSTOMER_LIST_FETCH_SUCCESS = 'SELECT_CUSTOMER_LIST_FETCH_SUCCESS';
export const SELECT_CUSTOMER_SETTINGS = 'SELECT_CUSTOMER_SETTINGS';

export const NAVIGATE_TO_SELECT_CUSTOMER_PAGE = 'NAVIGATE_TO_SELECT_CUSTOMER_PAGE';

export const SELECT_CUSTOMER = 'SELECT_CUSTOMER';
export const SELECT_CUSTOMER_EXECUTE = 'SELECT_CUSTOMER_EXECUTE';
export const SELECT_CUSTOMER_LOCATION = 'SELECT_CUSTOMER_LOCATION';

export const SEARCH_BUYER_PROFILES_EXECUTE = 'SEARCH_BUYER_PROFILES_EXECUTE';
export const SEARCH_BUYER_PROFILES_SUCCESS = 'SEARCH_BUYER_PROFILES_SUCCESS';
export const SEARCH_BUYER_PROFILES_FAILED = 'SEARCH_BUYER_PROFILES_FAILED';

export const CUSTOMERS_FOR_ADVANCE_SEARCH_FILTER_FETCHING = 'CUSTOMERS_FOR_ADVANCE_SEARCH_FILTER_FETCHING';
export const CUSTOMERS_FOR_ADVANCE_SEARCH_FILTER_FETCHED = 'CUSTOMERS_FOR_ADVANCE_SEARCH_FILTER_FETCHED';
export const CUSTOMERS_FOR_ADVANCE_SEARCH_FILTER_FAILED = 'CUSTOMERS_FOR_ADVANCE_SEARCH_FILTER_FAILED';
export const CUSTOMERS_FETCHING = 'CUSTOMERS_FETCHING';
export const CUSTOMERS_FETCHED = 'CUSTOMERS_FETCHED';
export const CUSTOMERS_SEARCH_ADVANCED = 'CUSTOMERS_SEARCH_ADVANCED';
export const CUSTOMERS_APPLY_SORTING = 'CUSTOMERS_APPLY_SORTING';
export const CUSTOMERS_CLEAR_SEARCH = 'CUSTOMERS_CLEAR_SEARCH';
export const CUSTOMERS_CLEAR_SEARCH_STRING = 'CUSTOMERS_CLEAR_SEARCH_STRING';
export const CUSTOMERS_TOOGLE_SEARCH = 'CUSTOMERS_TOOGLE_SEARCH';
export const CUSTOMER_PROFILE_FETCHING = 'CUSTOMER_PROFILE_FETCHING';
export const CUSTOMER_PROFILE_FETCHED = 'CUSTOMER_PROFILE_FETCHED';
export const CURRENT_CUSTOMER_FETCHING = 'CURRENT_CUSTOMER_FETCHING';
export const CURRENT_CUSTOMER_FETCHED = 'CURRENT_CUSTOMER_FETCHED';
export const CURRENT_CUSTOMER_CLEAR = 'CURRENT_CUSTOMER_CLEAR';

export const fetchCustomerList = (params) => ({
    type: SELECT_CUSTOMER_LIST_FETCH_EXECUTE,
    payload: {
        params: params,
    },
});

export const fetchCustomerListSuccess = (response) => ({
    type: SELECT_CUSTOMER_LIST_FETCH_SUCCESS,
    payload: response,
});

export const fetchCustomerListFailed = (err) => ({
    type: SELECT_CUSTOMER_LIST_FETCH_FAILED,
    playload: err,
});

export const navigateToSelectCustomerPage = () => ({
    type: NAVIGATE_TO_SELECT_CUSTOMER_PAGE,
});

export const selectCustomerAction = (customer) => ({
    type: SELECT_CUSTOMER,
    payload: customer,
});

export const selectCustomerExecute = (params) => ({
    type: SELECT_CUSTOMER_EXECUTE,
    payload: {
        params: params,
    },
});

export const selectCustomerLocationAction = (customerLocation) => ({
    type: SELECT_CUSTOMER_LOCATION,
    payload: customerLocation,
});

export const searchBuyerProfiles = (params) => ({
    type: SEARCH_BUYER_PROFILES_EXECUTE,
    payload: {
        params: params,
    },
});

export const searchBuyerProfilesSuccess = (response) => ({
    type: SEARCH_BUYER_PROFILES_SUCCESS,
    payload: response.data,
});

export const searchBuyerProfilesFailed = (err) => ({
    type: SEARCH_BUYER_PROFILES_FAILED,
    playload: err,
});

export const setCustomerSettings = (response) => (dispatch) => {
    dispatch({
        type: SELECT_CUSTOMER_SETTINGS,
        payload: response,
    });
};

export const customersForAdvanceSearchFiltersFetching = () => (dispatch) => {
    dispatch({
        type: CUSTOMERS_FOR_ADVANCE_SEARCH_FILTER_FETCHING,
    });
};

export const customersForAdvanceSearchFiltersFetched = (response) => (dispatch) => {
    dispatch({
        type: CUSTOMERS_FOR_ADVANCE_SEARCH_FILTER_FETCHED,
        payload: response,
    });
};

export const customersFetching = (payload) => (dispatch) => {
    dispatch({
        type: CUSTOMERS_FETCHING,
        payload,
    });
};

export const customersFetched = (response) => (dispatch) => {
    dispatch({
        type: CUSTOMERS_FETCHED,
        payload: response,
    });
};

export const toogleAdvancedSearch = (response) => (dispatch) => {
    dispatch({
        type: CUSTOMERS_TOOGLE_SEARCH,
        payload: response,
    });
};

export const customerClearSearch = () => (dispatch) => {
    dispatch({
        type: CUSTOMERS_CLEAR_SEARCH,
    });
};

export const customerClearSearchString = () => (dispatch) => {
    dispatch({
        type: CUSTOMERS_CLEAR_SEARCH_STRING,
    });
};

export const customerSortSearch = (payload) => (dispatch) => {
    dispatch({
        type: CUSTOMERS_APPLY_SORTING,
        payload,
    });
};

export const fetchingCustomerProfile = () => ({
    type: CUSTOMER_PROFILE_FETCHING,
});

export const fetchedCustomerProfile = (payload) => ({
    type: CUSTOMER_PROFILE_FETCHED,
    payload,
});

export const fetchingCurrentCustomer = () => ({
    type: CURRENT_CUSTOMER_FETCHING,
});

export const fetchedCurrentCustomer = (payload) => ({
    type: CURRENT_CUSTOMER_FETCHING,
    payload,
});

export const clearCurrentCustomer = () => ({
    type: CURRENT_CUSTOMER_CLEAR,
});

export const setCustomerProfile = (payload) => ({
    type: CUSTOMER_PROFILE_FETCHED,
    payload,
});

export const navigateToManageCreateCustomer = () => (dispatch) => {
    dispatch(
        push({
            pathname: R.MANAGE_CUSTOMERS_CREATE.path,
            state: {
                path: R.MANAGE_CUSTOMERS.path,
            },
        })
    );
};

export const navigateToBackManageSelectCustomer = () => (dispatch) => {
    dispatch(clearCurrentCustomer());
    dispatch(push(R.MANAGE_CUSTOMERS.path));
};
