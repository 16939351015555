import { useMemo, useState, useCallback, useEffect } from 'react';

import DropdownOptionsRepository from '../../PluginHost/services/dropdownOptionsRepository';
import { UserWorkflowActivityState } from '../../types/DocumentEditValidationState';

import { useInternalRulesConfiguration } from './useInternalRulesConfiguration';
import { useClientValidationController } from '../../../CJSRuleEngineV2ClientWrapper/ClientValidationController';
import { InternalRuleService } from './InternalRuleService';
import { InternalRule } from './InternalRule';
import { IFieldControlStates } from '../../types/IFieldControlStates';
import { IRValidationResults } from './RuleEnvironment';
import { MessageClass, MessageClasses } from '../../types/ValidatorStates';
import { useStableReference } from '../../../../utils';
import _ from 'lodash';
import { ValidationEvent } from '../../../CustomJSRuleEngineV2/ExecutionService/DPSValidations';

export const defaultMessageClassesState: MessageClasses = {
    errorMessages: {
        messages: [],
        fieldMessages: {},
    },
    warningMessages: {
        messages: [],
        fieldMessages: {},
    },
};

function transformIRValidationResultsToMessageClasses(results: IRValidationResults): MessageClasses {
    const reduceResultToMsgClass = (msgClass: MessageClass, result: IRValidationResults[number]) => {
        const { error } = result;

        if (typeof error.associatedField === 'string') {
            msgClass.fieldMessages[error.associatedField] ??= [];
            msgClass.fieldMessages[error.associatedField]?.push(error.message);
        } else {
            msgClass.messages.push(error.message);
        }

        return msgClass;
    };

    const errorMessages = results
        .filter((result) => result.type === 'ErrorResult')
        .reduce<MessageClass>(reduceResultToMsgClass, {
            messages: [],
            fieldMessages: {} as Record<string, string[]>,
        });

    const warningMessages = results
        .filter((result) => result.type === 'WarningResult')
        .reduce<MessageClass>(reduceResultToMsgClass, {
            messages: [],
            fieldMessages: {} as Record<string, string[]>,
        });

    return {
        errorMessages,
        warningMessages,
    };
}

function shouldEnableValidations({
    document,
    internalRules,
}: {
    document: ReturnType<InternalRulesConfig['getDocumentState']>;
    internalRules: ReturnType<InternalRulesConfig['getInternalRules']> | null;
}) {
    const documentHasLoaded = document != null;
    const internalRulesHaveLoaded = internalRules != null;

    return documentHasLoaded && internalRulesHaveLoaded;
}

type InternalRulesConfig = {
    dropdownOptionsRepository: DropdownOptionsRepository;

    getDocumentState: () => UserWorkflowActivityState | undefined;
    getInternalRules: () => InternalRule[] | undefined;
};

export function useInternalRules({
    getDocumentState,
    getInternalRules,
    dropdownOptionsRepository,
}: InternalRulesConfig) {
    const VALIDATION_DEBOUNCE_TIME = 100;
    const documentState = useStableReference(getDocumentState());
    const internalRules = useStableReference(getInternalRules() ?? null);

    const [messageClasses, setMessageClasses] = useState<MessageClasses>(defaultMessageClassesState);

    const resetMsgClasses = () => setMessageClasses(defaultMessageClassesState);

    const [fieldControlStates, setFieldControlStates] = useState<IFieldControlStates>({});

    const validationsEnabled = shouldEnableValidations({
        document: documentState,
        internalRules,
    });

    const validationConfiguration = useInternalRulesConfiguration(internalRules);

    const validationController = useClientValidationController({ validationConfiguration });

    const ruleService = useMemo(() => {
        if (internalRules == null || validationConfiguration == null) {
            return null;
        }

        const service = new InternalRuleService(internalRules, dropdownOptionsRepository, validationConfiguration);

        service.loadRules();

        return service;
    }, [internalRules, dropdownOptionsRepository, validationConfiguration]);

    const readyToRunValidations = validationsEnabled && ruleService != null;

    const validateEvent = useMemo(
        () =>
            _.debounce(async (event: ValidationEvent) => {
                if (!readyToRunValidations) {
                    return false;
                }

                if (!validationController?.isValidationNeeded(event)) {
                    return true;
                }

                if (!validationController?.canRunValidations()) {
                    return false;
                }

                validationController.setValidatingState();

                resetMsgClasses();

                const document = getDocumentState();

                try {
                    await ruleService.executeUIDocumentControllers(document!, setFieldControlStates);
                } catch {
                    validationController.setValidationFailedState();
                    return false;
                }

                await new Promise((r) => setTimeout(r));

                const result = await ruleService.executeValidations(document!);

                const hasErrors = !!result.filter((res) => res.type === 'ErrorResult').length;

                if (hasErrors) {
                    validationController.setValidationFailedState();
                } else {
                    validationController.setValidationSucceededState();
                }

                const messages = transformIRValidationResultsToMessageClasses(result);

                setMessageClasses(messages);

                return !hasErrors;
            }, VALIDATION_DEBOUNCE_TIME),
        [validationController, ruleService, readyToRunValidations, getDocumentState]
    );

    useEffect(() => {
        if (readyToRunValidations) {
            validateEvent({
                type: 'WorkflowLoadEvent',
            });
        }
    }, [validateEvent, readyToRunValidations]);

    const getFieldControlState = useCallback(
        (fieldKey: string) => fieldControlStates[fieldKey] ?? {},
        [fieldControlStates]
    );

    return {
        messageClasses,
        getFieldControlState,
        validateEvent,
        shouldDisableActionButton: (event: any) => validationController?.shouldDisableActionButton(event) ?? false,
    };
}
