import { DashboardApi, InvoicesRejectedDashboardReport } from '../../../Api';
import { SeriesColumnOptions } from 'highcharts';
import { DashboardReportService } from '../../DashboardReportService';
import { createDashboardReportService } from '../../createDashboardReportService';
import { DashboardDataPayload } from '../../../Api/DashboardDataPayload';
import { dashboardFormatDate } from '../../../Charts/Chart';

export class InvoicesRejectedReportService
    implements DashboardReportService<InvoicesRejectedDashboardReport, SeriesColumnOptions['data']>
{
    private api = new DashboardApi();

    get storedProcedure() {
        return 'DashboardReportInvoicesRejected' as const;
    }

    async fetchReport(settings: DashboardDataPayload) {
        const res = await this.api.getDashboardReportData<InvoicesRejectedDashboardReport>(
            settings,
            this.storedProcedure
        );
        return res.data;
    }

    get nullifyDataIfEmptyObj() {
        return {
            optIn: true,
            xAxisPropertyKey: 'Date' as const,
        };
    }

    transformVMToChartData(vm: InvoicesRejectedDashboardReport['viewModel']) {
        return vm.map((p) => ({
            y: p.TotalRejections,
            name: dashboardFormatDate(p.Date),
            label: dashboardFormatDate(p.Date),
            originalDate: p.Date,
        }));
    }
}

export const useInvoicesRejectedReportService = createDashboardReportService(InvoicesRejectedReportService);
