import { ReactNode } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { LoginPage } from './Forms/LoginPage';
import { ForgotPasswordPage } from './Forms/ForgotPasswordPage';
import { LogoutPage } from './Forms/LogoutPage';
import { SSOPage } from './Forms/SSOPage';
import { useStoreSuccessfulAuthRedirectLocation } from './Auth';

export interface ILoginPageRouterProps {
    marketingContent?: ReactNode;
    isDefaultSSO: boolean;
}

export const LoginPagesRouter = ({ marketingContent, isDefaultSSO }: ILoginPageRouterProps) => {
    const defaultLoginRoute = isDefaultSSO ? '/auth/ssologin' : '/auth/login';

    useStoreSuccessfulAuthRedirectLocation();

    return (
        <Switch>
            <Route path="/auth/login">
                <LoginPage forgotPasswordRoute="/auth/forgotpassword" marketingContent={marketingContent} />
            </Route>
            <Route path="/auth/forgotpassword">
                <ForgotPasswordPage loginRoute="/auth/login" marketingContent={marketingContent} />
            </Route>
            <Route path="/auth/logout">
                <LogoutPage loginRoute={defaultLoginRoute} marketingContent={marketingContent} />
            </Route>
            <Route path="/auth/ssologin">
                <SSOPage marketingContent={marketingContent} />
            </Route>
            <Redirect to={defaultLoginRoute} />
        </Switch>
    );
};
