import * as helpResourceActions from '../actions/helpResourceActions';

const initialState = {
    categories: {
        items: [],
        isLoading: false,
    },
    articles: {
        items: [],
        isLoading: false,
    },
    article: {
        item: {},
        isLoading: false,
        validated: false,
    },
    searchResults: {
        items: [],
        isLoading: false,
        validated: false,
    },
};

export const helpResource = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case helpResourceActions.HELP_RESOURCE_CATEGORIES_FETCHING: {
            return {
                ...state,
                categories: {
                    items: [],
                    initialLoad: [],
                    isLoading: true,
                    validated: false,
                },
            };
        }

        case helpResourceActions.HELP_RESOURCE_CATEGORIES_FETCHED: {
            return {
                ...state,
                categories: {
                    items: [],
                    initialLoad: payload,
                    isLoading: false,
                    validated: false,
                },
            };
        }

        case helpResourceActions.HELP_RESOURCE_CATEGORIES_LOAD: {
            return {
                ...state,
                categories: {
                    items: payload,
                    initialLoad: [],
                    isLoading: false,
                    validated: true,
                },
            };
        }

        case helpResourceActions.HELP_RESOURCE_ARTICLES_FETCHING: {
            return {
                ...state,
                articles: {
                    items: [],
                    isLoading: true,
                },
            };
        }
        case helpResourceActions.HELP_RESOURCE_ARTICLES_FETCHED: {
            return {
                ...state,
                articles: {
                    items: payload.articles,
                    isLoading: false,
                },
                selectedCategory: payload.category,
            };
        }

        case helpResourceActions.HELP_RESOURCE_ARTICLE_FETCHING: {
            return {
                ...state,
                article: {
                    item: {},
                    isLoading: true,
                },
            };
        }
        case helpResourceActions.HELP_RESOURCE_ARTICLE_FETCHED: {
            return {
                ...state,
                article: {
                    item: payload.article,
                    isLoading: false,
                },
                selectedCategory: payload.category,
            };
        }

        case helpResourceActions.HELP_RESOURCE_SEARCH_FETCHING: {
            return {
                ...state,
                searchResults: {
                    items: [],
                    isLoading: true,
                    validated: false,
                },
            };
        }

        case helpResourceActions.HELP_RESOURCE_SEARCH_FETCHED: {
            return {
                ...state,
                searchResults: {
                    items: payload,
                    isLoading: false,
                    validated: false,
                },
            };
        }

        default:
            return state;
    }
};
