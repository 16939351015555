import { FC, useRef, useEffect } from 'react';
import { Box } from '@mui/system';

export interface IScrollToHereProps {
    marginTop?: string;
    enabled?: boolean;
}

export const ScrollToHere: FC<IScrollToHereProps> = ({ marginTop = '90x', enabled = true, children }) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        ref.current.scrollIntoView({
            behavior: 'smooth',
        });
    }, [enabled]);

    if (!enabled) {
        return <>{children}</>;
    }

    return (
        <Box sx={{ scrollMarginTop: marginTop }} ref={ref}>
            {children}
        </Box>
    );
};
