export enum OsnStatus {
    NotRequired = 1,
    Pending = 2,
    NeedVendorNumber = 3,
    Failed = 4,
    Registered = 5,
}

export const OsnStatusName: { [key in OsnStatus]: string } = {
    [OsnStatus.NotRequired]: 'Not Required',
    [OsnStatus.Pending]: 'Pending',
    [OsnStatus.NeedVendorNumber]: 'Need Vendor Number',
    [OsnStatus.Failed]: 'Failed',
    [OsnStatus.Registered]: 'Registered',
};
