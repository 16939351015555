import { AxiosInstance } from 'axios';
import { apiErrorNormalizer } from './axiosOptimizer/apiErrorNormalizer';
import { installCypressAxiosInterceptor, CypressAxiosInterceptor } from '../../infrastructure';
import { cypressRequestCollector } from './cypressRequestCollector';

const cypressAxiosInterceptor = new CypressAxiosInterceptor(
    cypressRequestCollector,
    '/TransceptaDataExtractionAPI/api'
);
cypressAxiosInterceptor.ignoreHeader('server');
cypressAxiosInterceptor.ignoreHeader('x-powered-by');
cypressAxiosInterceptor.ignoreHeader('x-aspnet-version');
cypressAxiosInterceptor.ignoreHeader('authorization');

export function optimizeDataExtractorApi(instance: AxiosInstance) {
    installCypressAxiosInterceptor(instance, cypressAxiosInterceptor);
    apiErrorNormalizer(instance);
    return instance;
}
