import { toCamelCase } from '../../utils/casing';
import ValidationFailureTypesApi from '../Api/ValidationFailureTypesApi';
import { IValidationFailureType, IValidationFailureTypesGrid } from './types';
import { IValidationFailureTypeViewModel } from '../Api/ViewModels';
import { DashboardRejectionTypesService } from '../../../services/DashboardRejectionTypes/Service';

class ValidationFailureTypesService {
    api = new ValidationFailureTypesApi();

    dashboardRejectionTypeService = new DashboardRejectionTypesService();

    public getValidationFailureTypesGrid = async (): Promise<IValidationFailureTypesGrid[] | undefined> => {
        const viewModel = await this.api.getAllValidationFailureTypes();
        const rejectionTypeGrid = await this.dashboardRejectionTypeService.getDashboardRejectionTypesGrid();

        const validationType = viewModel?.data?.map((model) => toCamelCase<IValidationFailureTypeViewModel>(model));

        const rejectionTypeLookupTable = new Map(rejectionTypeGrid?.map((i) => [i.validationFailureErrorTypeId, i]));

        const grid: IValidationFailureTypesGrid[] = [];

        if (rejectionTypeLookupTable) {
            validationType?.forEach((value) => {
                grid.push({
                    id: value.id ?? 0,
                    errorCode: value.errorCode,
                    buyerCompany: value.buyerCompanyName ?? '',
                    validationType: value.validationType_US ?? '',
                    titleText: value.titleText,
                    dashboardRejectionTypeLabel: rejectionTypeLookupTable.get(value.id ?? -1)?.label ?? '',
                    dashboardRejectionTypeDescription: rejectionTypeLookupTable.get(value.id ?? -1)?.description ?? '',
                });
            });
        } else {
            validationType?.forEach((value) => {
                grid.push({
                    id: value.id ?? 0,
                    errorCode: value.errorCode,
                    buyerCompany: value.buyerCompanyName ?? '',
                    validationType: value.validationType_US ?? '',
                    titleText: value.titleText,
                    dashboardRejectionTypeLabel: '',
                    dashboardRejectionTypeDescription: '',
                });
            });
        }

        return grid;
    };

    public getValidationFailureType = async (id: number): Promise<IValidationFailureType | undefined> => {
        const viewModel = await this.api.getValidationFailureTypes(id);

        if (!viewModel.data) {
            throw Error(viewModel.status.toString());
        }

        return toCamelCase<IValidationFailureTypeViewModel>(viewModel.data[0]);
    };

    public getValidationFailureTypeWithDashboardRejectionType = async (
        id: number
    ): Promise<IValidationFailureType | undefined> => {
        const viewModel = await this.api.getValidationFailureTypes(id);

        if (!viewModel.data) {
            throw Error(viewModel.status.toString());
        }
        const rejectionViewModel =
            await this.dashboardRejectionTypeService.getDashboardRejectionTypeByValidationFailureTypeId(
                viewModel.data[0].Id ?? 0
            );

        return {
            businessDocType: viewModel.data[0].BusinessDocType,
            buyerCompanyId: viewModel.data[0].BuyerCompanyId,
            buyerCompanyName: viewModel.data[0].BuyerCompanyName,
            buyerDetailText: viewModel.data[0].BuyerDetailText,
            buyerTitleText: viewModel.data[0].BuyerTitleText,
            dashboardRejectionTypeId: rejectionViewModel?.id,
            dashboardRejectionTypeDescription: rejectionViewModel?.shortDescription,
            dashboardRejectionTypeLabel: rejectionViewModel?.label,
            detailText: viewModel.data[0].DetailText,
            disposition: viewModel.data[0].Disposition,
            errorCode: viewModel.data[0].ErrorCode,
            fieldDictionaryId: viewModel.data[0].FieldDictionaryId,
            id: viewModel.data[0].Id,
            internalOnly: viewModel.data[0].InternalOnly,
            label: viewModel.data[0].Label,
            lastUpdateDate: viewModel.data[0].LastUpdateDate,
            rowversion: viewModel.data[0].Rowversion,
            shortText: viewModel.data[0].ShortText,
            titleText: viewModel.data[0].TitleText,
            validationFailureDetails: viewModel.data[0].ValidationFailureDetails,
            validationType: viewModel.data[0].ValidationType,
        };
    };

    public async insertValidationFailureTypes(state: IValidationFailureType): Promise<number> {
        const viewModel = this.transformToViewModel(state);
        const res = await this.api.createValidationFailureTypes(viewModel);
        return res.data.ID;
    }

    public async updateValidationFailureTypes(id: number, state: IValidationFailureType): Promise<number> {
        const viewModel = this.transformToViewModel(state);
        const res = await this.api.updateValidationFailureTypes(id, viewModel);
        return res.data.ID;
    }

    private transformToViewModel(data: IValidationFailureType): IValidationFailureTypeViewModel {
        return {
            ErrorCode: data.errorCode,
            BusinessDocType: data.businessDocType,
            ValidationType: data.validationType,
            Disposition: data.disposition,
            Label: data.label,
            InternalOnly: data.internalOnly,
            TitleText: data.titleText,
            ShortText: data.shortText,
            DetailText: data.detailText,
            BuyerTitleText: data.buyerTitleText,
            BuyerDetailText: data.buyerDetailText,
            Id: data.id,
            BuyerCompanyId: data.buyerCompanyId,
        } as IValidationFailureTypeViewModel;
    }

    public deleteValidationFailureTypes = async (id: number) => {
        await this.api.deleteValidationFailureTypes(id);
    };
}

export default ValidationFailureTypesService;
