import { CLEAR_NOTICE_PAGE, GET_NOTICE_PAGE_FAILED, GET_NOTICE_PAGE_SUCCESS } from '../actions/noticePage';
import { USER_LOGOUT } from '../actions/userlogin';

const initialState = {
    notices: [],
    showNoticePage: false,
};

export function noticePage(state = initialState, { type, payload }) {
    switch (type) {
        case CLEAR_NOTICE_PAGE:
        case GET_NOTICE_PAGE_FAILED:
        case USER_LOGOUT:
            const { timeout } = payload;
            return timeout && timeout == true ? state : initialState;
        //return initialState;

        case GET_NOTICE_PAGE_SUCCESS:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
}
