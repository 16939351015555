import { toPascalCase } from '../../../services';
import { BusinessDocType } from '../../../types';
import { validateData } from '../../../ui';
import { smallDateFormat } from '../../../utils';
import { BusinessDocTypeSearchApi } from '../Api';
import {
    IBusinessDocTypeSearchViewModel,
    IBusinessDocTypeSearchViewModelData,
} from '../Api/ViewModels/BusinessDocTypeSearchViewModel';
import { validateSearchCriteria, validateSearchResult, validateSearchFilter } from './parsers';
import {
    IBusinessDocTypeSearch,
    IBusinessDocTypeSearchData,
    IDocumentTypeSearchSearchGrid,
    IDocumentTypeSearchSearchGridItem,
    Page,
    ScreenSize,
} from './types';

type FieldControls = {
    required?: boolean;
    visible?: boolean;
};

export function getBusinessDocTypeSearchDataFieldControls(
    fields: IBusinessDocTypeSearchData,
    key: keyof IBusinessDocTypeSearchData
): FieldControls {
    const isWorkflowPage = fields.page === Page['Workflow Page'];

    const showSupplierInputFields = !isWorkflowPage;

    if (['supplierSearchCriteria', 'supplierSearchFilter', 'supplierSearchResult'].includes(key)) {
        return {
            visible: showSupplierInputFields,
            required: showSupplierInputFields,
        };
    }

    if (['buyerSearchCriteria', 'buyerSearchFilter', 'buyerSearchResult'].includes(key)) {
        return {
            visible: true,
            required: true,
        };
    }

    if (key === 'workflowTemplateUI') {
        return {
            visible: isWorkflowPage,
            required: isWorkflowPage,
        };
    }

    return {};
}

export class BusinessDocTypeSearchService {
    api = new BusinessDocTypeSearchApi();

    public getAllDocumentTypesForDataGrid = async (
        companyId?: number,
        businessDocTypeId?: number,
        workflowTemplateUI?: string,
        page?: number,
        screenSize?: number,
        orderBy?: string
    ): Promise<IDocumentTypeSearchSearchGrid> => {
        const response = await this.api.getBusinessDocTypeSearch({
            companyId,
            businessDocTypeId,
            workflowTemplateUI,
            page,
            screenSize,
            orderBy,
        });

        return {
            count: response.data.length,
            items: response.data.map(
                (item: IBusinessDocTypeSearchViewModel): IDocumentTypeSearchSearchGridItem => ({
                    id: item.Id,
                    businessDocTypeId: BusinessDocType[item.BusinessDocTypeId],
                    workflowTemplateUI: item.WorkflowTemplateUI,
                    page: Page[item.Page],
                    screenSize: ScreenSize[item.ScreenSize],
                    lastUpdateDate: item.LastUpdateDate ? smallDateFormat(new Date(item.LastUpdateDate)) : '',
                })
            ),
        };
    };

    public getDocumentTypeSearchById = async (id: number): Promise<IBusinessDocTypeSearch> => {
        const response = await this.api.getBusinessDocTypeSearch({
            id,
        });

        const docTypeSearch = response.data[0];

        return {
            id: docTypeSearch.Id,
            companyId: docTypeSearch.CompanyId,
            businessDocTypeId: docTypeSearch.BusinessDocTypeId,
            workflowTemplateUI: docTypeSearch.WorkflowTemplateUI,
            page: docTypeSearch.Page,
            screenSize: docTypeSearch.ScreenSize,
            senderProfileType: docTypeSearch.SenderProfileType,
            receiverProfileType: docTypeSearch.ReceiverProfileType,
            buyerSearchCriteria: docTypeSearch.BuyerSearchCriteria
                ? JSON.stringify(docTypeSearch.BuyerSearchCriteria)
                : '',
            buyerSearchResult: docTypeSearch.BuyerSearchResult ? JSON.stringify(docTypeSearch.BuyerSearchResult) : '',
            buyerSearchFilter: docTypeSearch.BuyerSearchFilter ? JSON.stringify(docTypeSearch.BuyerSearchFilter) : '',
            supplierSearchCriteria: docTypeSearch.SupplierSearchCriteria
                ? JSON.stringify(docTypeSearch.SupplierSearchCriteria)
                : '',
            supplierSearchResult: docTypeSearch.SupplierSearchResult
                ? JSON.stringify(docTypeSearch.SupplierSearchResult)
                : '',
            supplierSearchFilter: docTypeSearch.SupplierSearchFilter
                ? JSON.stringify(docTypeSearch.SupplierSearchFilter)
                : '',
        };
    };

    private validateBusinessDocTypeSearch(businessDocTypeSearch: IBusinessDocTypeSearchData) {
        validateData((errors) => {
            if (!businessDocTypeSearch.companyId && businessDocTypeSearch.companyId !== 0) {
                errors.push('Company ID is required');
            }
            if (!businessDocTypeSearch.businessDocTypeId && businessDocTypeSearch.businessDocTypeId !== 0) {
                errors.push('Business Document Type is required');
            }
            if (!businessDocTypeSearch.page && businessDocTypeSearch.page !== 0) {
                errors.push('Page is required');
            }
            if (businessDocTypeSearch.page === Page['Workflow Page'] && !businessDocTypeSearch.workflowTemplateUI) {
                errors.push('Workflow Template UI is required');
            }
            if (!businessDocTypeSearch.screenSize && businessDocTypeSearch.screenSize !== 0) {
                errors.push('Screen Size is required');
            }
            if (!businessDocTypeSearch.senderProfileType && businessDocTypeSearch.senderProfileType !== 0) {
                errors.push('Sender Profile Type is required');
            }
            if (!businessDocTypeSearch.receiverProfileType && businessDocTypeSearch.receiverProfileType !== 0) {
                errors.push('Receiver Profile Type is required');
            }
            if (
                getBusinessDocTypeSearchDataFieldControls(businessDocTypeSearch, 'buyerSearchCriteria').required &&
                !businessDocTypeSearch.buyerSearchCriteria
            ) {
                errors.push('Buyer Search Criteria is required');
            }
            if (
                getBusinessDocTypeSearchDataFieldControls(businessDocTypeSearch, 'buyerSearchResult').required &&
                !businessDocTypeSearch.buyerSearchResult
            ) {
                errors.push('Buyer Search Result is required');
            }
            if (
                getBusinessDocTypeSearchDataFieldControls(businessDocTypeSearch, 'buyerSearchFilter').required &&
                !businessDocTypeSearch.buyerSearchFilter
            ) {
                errors.push('Buyer Search Filter is required');
            }
            if (
                getBusinessDocTypeSearchDataFieldControls(businessDocTypeSearch, 'supplierSearchCriteria').required &&
                !businessDocTypeSearch.supplierSearchCriteria
            ) {
                errors.push('Supplier Search Criteria is required');
            }
            if (
                getBusinessDocTypeSearchDataFieldControls(businessDocTypeSearch, 'supplierSearchResult').required &&
                !businessDocTypeSearch.supplierSearchResult
            ) {
                errors.push('Supplier Search Result is required');
            }
            if (
                getBusinessDocTypeSearchDataFieldControls(businessDocTypeSearch, 'supplierSearchFilter').required &&
                !businessDocTypeSearch.supplierSearchFilter
            ) {
                errors.push('Supplier Search Filter is required');
            }
        });
    }

    private validateBusinessDocTypeSearchWithSchemas = (businessDocTypeSearchFields: IBusinessDocTypeSearchData) => {
        validateData((errors) => {
            if (businessDocTypeSearchFields.buyerSearchCriteria) {
                validateSearchCriteria(businessDocTypeSearchFields.buyerSearchCriteria, 'BuyerSearchCriteria', errors);
            }
            if (businessDocTypeSearchFields.buyerSearchResult) {
                validateSearchResult(businessDocTypeSearchFields.buyerSearchResult, 'BuyerSearchResult', errors);
            }
            if (businessDocTypeSearchFields.buyerSearchFilter) {
                validateSearchFilter(businessDocTypeSearchFields.buyerSearchFilter, 'BuyerSearchFilter', errors);
            }

            if (businessDocTypeSearchFields.supplierSearchCriteria) {
                validateSearchCriteria(
                    businessDocTypeSearchFields.supplierSearchCriteria,
                    'SupplierSearchCriteria',
                    errors
                );
            }
            if (businessDocTypeSearchFields.supplierSearchResult) {
                validateSearchResult(businessDocTypeSearchFields.supplierSearchResult, 'SupplierSearchResult', errors);
            }
            if (businessDocTypeSearchFields.supplierSearchFilter) {
                validateSearchFilter(businessDocTypeSearchFields.supplierSearchFilter, 'SupplierSearchFilter', errors);
            }
        });
    };

    private toViewModel(businessDocTypeSearchFields: IBusinessDocTypeSearchData) {
        const businessDocTypeViewModel: IBusinessDocTypeSearchViewModelData = {
            ...toPascalCase(businessDocTypeSearchFields),
            BusinessDocTypeId: businessDocTypeSearchFields.businessDocTypeId!,
            WorkflowTemplateUI: businessDocTypeSearchFields.workflowTemplateUI!,
            Page: businessDocTypeSearchFields.page!,
            ScreenSize: businessDocTypeSearchFields.screenSize!,
            SenderProfileType: businessDocTypeSearchFields.senderProfileType!,
            ReceiverProfileType: businessDocTypeSearchFields.receiverProfileType!,
            BuyerSearchCriteria: businessDocTypeSearchFields.buyerSearchCriteria
                ? JSON.parse(businessDocTypeSearchFields.buyerSearchCriteria)
                : null,
            BuyerSearchResult: businessDocTypeSearchFields.buyerSearchResult
                ? JSON.parse(businessDocTypeSearchFields.buyerSearchResult)
                : null,
            BuyerSearchFilter: businessDocTypeSearchFields.buyerSearchFilter
                ? JSON.parse(businessDocTypeSearchFields.buyerSearchFilter)
                : null,
            SupplierSearchCriteria: businessDocTypeSearchFields.supplierSearchCriteria
                ? JSON.parse(businessDocTypeSearchFields.supplierSearchCriteria)
                : null,
            SupplierSearchResult: businessDocTypeSearchFields.supplierSearchResult
                ? JSON.parse(businessDocTypeSearchFields.supplierSearchResult)
                : null,
            SupplierSearchFilter: businessDocTypeSearchFields.supplierSearchFilter
                ? JSON.parse(businessDocTypeSearchFields.supplierSearchFilter)
                : null,
        };
        return businessDocTypeViewModel;
    }

    public updateBusinessDocumentTypeSearch = async (
        id: number,
        businessDocTypeSearch: IBusinessDocTypeSearchData
    ): Promise<void> => {
        this.validateBusinessDocTypeSearch(businessDocTypeSearch);
        this.validateBusinessDocTypeSearchWithSchemas(businessDocTypeSearch);

        await this.api.updateBusinessDocumentTypeSearch(id, await this.toViewModel(businessDocTypeSearch));
    };

    public insertBusinessDocumentTypeSearch = async (
        businessDocTypeSearch: IBusinessDocTypeSearchData
    ): Promise<number> => {
        this.validateBusinessDocTypeSearch(businessDocTypeSearch);
        this.validateBusinessDocTypeSearchWithSchemas(businessDocTypeSearch);

        const res = await this.api.insertBusinessDocumentTypeSearch(this.toViewModel(businessDocTypeSearch));

        return res.data.ID;
    };

    public deleteBusinessDocumentTypeSearch = async (id: number): Promise<void> => {
        await this.api.deleteBusinessDocumentTypeSearch(id);
    };

    static defaultCRUDFields: IBusinessDocTypeSearchData = {
        companyId: null,
        businessDocTypeId: null,
        workflowTemplateUI: null,
        page: null,
        screenSize: null,
        senderProfileType: null,
        receiverProfileType: null,
        buyerSearchCriteria: '',
        buyerSearchResult: '',
        buyerSearchFilter: '',
        supplierSearchCriteria: '',
        supplierSearchResult: '',
        supplierSearchFilter: '',
    };
}
