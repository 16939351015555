import { FCNC } from '../../../../../../ui';

import './RunningTotals.scss';

import calculateRemainderAndRunningTotals from '../calculateRemainderAndRunningTotals';

type RunningTotalsProps = {
    splits: any[];
    total: number;
    currencySymbol: string;
    shortHelperText?: boolean;
};

const RunningTotals: FCNC<RunningTotalsProps> = ({ splits, total, currencySymbol, shortHelperText = true }) => {
    const runningTotalsHelperText = `Total allocation${!shortHelperText ? ' (% and Amount)' : ''}:`;

    const { percentRunningTotal, amountRunningTotal, isValidAllocation } = calculateRemainderAndRunningTotals(
        0, // index is irrelevant in this context
        splits,
        total,
        currencySymbol
    );

    return (
        <div className={`running-totals-container${isValidAllocation ? ' valid-allocation' : ''}`}>
            <div className="running-totals-label">{runningTotalsHelperText}</div>
            <div className="core-gl-coding-percent-totals">{percentRunningTotal}</div>
            <div className="core-gl-coding-amount-totals">{amountRunningTotal}</div>
        </div>
    );
};

export default RunningTotals;
