import { ViewModels } from '../../../../services/backendServices';
import { VFTDisposition } from '../../../../services/ValidationFailureTypes/Api/ViewModels';
import { UnexpectedCase } from '../../../../utils/UnexpectedCase';
import { parseXMLBoolean, parseXMLNumber } from '../../../../utils/xmlValueConverter';
import { FlattenedXrefParameters } from './flattenXrefParameters';

export type ParsedSettingValueType = string | number | boolean | string[] | number[];

export interface ParsedInputParameterSetting {
    Name: string;

    Label: string;

    Description: string;

    ErrorCode?: string;

    Type: ViewModels.InputParameterSettingType;

    ConfigurableByCompany: boolean;

    DefaultValue?: ParsedSettingValueType;
}

export interface ParsedInputParameter {
    Name: string;

    Value: ParsedSettingValueType;

    Disposition: VFTDisposition | null;

    ValidationClass: NonNullable<ViewModels.InputParameter['ValidationClass']> | null;

    DefaultDisposition?: VFTDisposition | null;

    Enabled: boolean;
}

export interface ParsedXrefParameters {
    ruleParameters: ParsedInputParameterSetting[];

    xrefParameterValues: ParsedInputParameter[];

    parameterOverrides: {
        id: number | null;
        vendorClasses: string[];
        parameterValues: ParsedInputParameter[];
    }[];
}

type ParamTypeMap = Map<string, ViewModels.InputParameterSettingType>;

function createParamTypeMap(inputParameterSettings: ViewModels.InputParameterSetting[]) {
    return new Map(
        inputParameterSettings.map((inputParameterSetting) => [inputParameterSetting.Name, inputParameterSetting.Type])
    );
}

function parseParamValue(
    paramType: ViewModels.InputParameterSettingType,
    value: string | string[]
): ParsedSettingValueType {
    switch (paramType) {
        case 'Array<number>': {
            if (!Array.isArray(value)) {
                throw new Error('Expected array type parameter value');
            }

            return value.map(parseXMLNumber);
        }
        case 'Array<string>': {
            if (!Array.isArray(value)) {
                throw new Error('Expected array type parameter value');
            }

            return value;
        }
        case 'boolean': {
            if (Array.isArray(value)) {
                throw new Error('Expected primitive type parameter value');
            }

            return parseXMLBoolean(value);
        }
        case 'number': {
            if (Array.isArray(value)) {
                throw new Error('Expected primitive type parameter value');
            }

            return parseXMLNumber(value);
        }
        case 'string': {
            if (Array.isArray(value)) {
                throw new Error('Expected primitive type parameter value');
            }

            return value;
        }
        default:
            throw new UnexpectedCase(paramType);
    }
}

function parseInputParameterSettingDefaultValue(
    paramType: ViewModels.InputParameterSettingType,
    defaultValue: string | string[] | undefined
) {
    if (defaultValue === undefined) {
        return undefined;
    }

    return parseParamValue(paramType, defaultValue);
}

function parseRuleParameter(ruleParameter: ViewModels.InputParameterSetting): ParsedInputParameterSetting {
    return {
        ...ruleParameter,
        ConfigurableByCompany: parseXMLBoolean(ruleParameter.ConfigurableByCompany),
        DefaultValue: parseInputParameterSettingDefaultValue(ruleParameter.Type, ruleParameter.DefaultValue),
    };
}

export function parseRuleParameters(ruleParameters: ViewModels.InputParameterSetting[]): ParsedInputParameterSetting[] {
    return ruleParameters.map(parseRuleParameter);
}

function parseInputParameters(
    paramTypeMap: ParamTypeMap,
    inputParameters: ViewModels.InputParameter[]
): ParsedInputParameter[] {
    return (
        inputParameters
            // it is possible for an InputParameterSetting to be removed from a rule, in which case it won't be in the map
            // in this case we want to filter out such an InputParameter because the InputParameter no longer has any
            // meaning.
            .filter((inputParameter) => paramTypeMap.has(inputParameter.Name))
            .map((inputParameter) => {
                const disposition = Number(inputParameter.Disposition);
                return {
                    Name: inputParameter.Name,
                    // the type assertion is fine since we checked membership in the map above
                    Value: parseParamValue(paramTypeMap.get(inputParameter.Name)!, inputParameter.Value),
                    Enabled: inputParameter.Enabled === 'false' ? false : true,
                    Disposition: Number.isNaN(disposition) ? null : disposition,
                    ValidationClass: inputParameter.ValidationClass ?? null,
                };
            })
    );
}

/**
 * Parses the InputParameterSetting and InputParameter values to runtime JS values of the correct types.
 *
 * @param xref the flattened xref parameters
 * @returns the parsed xref parameters
 */
export function parseXrefParameters(xref: FlattenedXrefParameters): ParsedXrefParameters {
    const paramTypeMap = createParamTypeMap(xref.ruleParameters);

    return {
        ruleParameters: parseRuleParameters(xref.ruleParameters),
        xrefParameterValues: parseInputParameters(paramTypeMap, xref.xrefParameterValues),
        parameterOverrides: xref.parameterOverrides.map((override) => ({
            id: override.id,
            vendorClasses: override.vendorClasses,
            parameterValues: parseInputParameters(paramTypeMap, override.parameterValues),
        })),
    };
}
