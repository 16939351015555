import { backendServices, portalUserService } from '../../../../services';
import { BusinessDocType } from '../../../../types';
import { createSearchForRelatedDocumentFilter } from './searchForRelatedDocument.filter';

export function extractVendorNumber(currentDocument: backendServices.ViewModels.DocumentViewModel) {
    if (!currentDocument) {
        throw new Error('Expected current document to be defined');
    }

    switch (currentDocument.BusinessDocType) {
        case BusinessDocType.Invoice:
            return currentDocument.BusinessDocFields.Invoice.VendorNumber;
        default:
            throw new Error(
                `Unsupported business doc type for extracting vendor number: ${currentDocument.BusinessDocType}`
            );
    }
}

function getDocumentNumberFieldName(documentType: BusinessDocType) {
    switch (documentType) {
        case BusinessDocType.PurchaseOrder:
            return 'PurchaseOrderNumber';
        default:
            throw new Error(`Unsupported business doc type for getting document number field: ${documentType}`);
    }
}

export async function searchForRelatedDocument({
    relatedDocumentType,
    relatedDocumentNumber,
    isBuyer,
    vendorNumber,
}: {
    relatedDocumentType: BusinessDocType;
    relatedDocumentNumber: string;
    isBuyer: boolean;
    vendorNumber: string | null;
}) {
    const companyId = portalUserService.getCurrentCompanyId();
    const api = new backendServices.Apis.DocumentApi();

    const response = await api.getDocumentsForCompany({
        companyID: companyId,
        $count: true,
        $top: 50,
        $filter: createSearchForRelatedDocumentFilter({
            relatedDocumentType,
            documentNumberFieldName: getDocumentNumberFieldName(relatedDocumentType),
            relatedDocumentNumber,
            vendorNumber,
        }),
        isBuyer,
    });

    const item = response.data.Items[0];

    if (!item) {
        return null;
    }

    switch (relatedDocumentType) {
        case BusinessDocType.PurchaseOrder: {
            return { id: item.DocumentID, vendorNumber: item.VendorNumber };
        }
    }

    return { id: item.Id };
}
