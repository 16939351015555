import { getAppConfig } from '../../config';
import { getVersionKey } from '../versionKey';

export default function getFullConfig() {
    const config = getAppConfig().logRocket;
    const versionKey = getVersionKey();

    if (!config) {
        return undefined;
    }

    if (!config.projectId) {
        return undefined;
    }

    if (typeof versionKey !== 'string') {
        return undefined;
    }

    return { config, versionKey } as const;
}
