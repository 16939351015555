import { useCurrentUser } from './useCurrentUser';
import { portalUserService } from '../PortalUserService';

/**
 * Returns the company id that the current user is logged in under.
 *
 * Throws an error if no user is logged in or if the user is not logged in under a company.
 */
export function useCurrentCompanyId() {
    useCurrentUser('mustBeLoggedIn');

    return portalUserService.getCurrentCompanyId();
}
