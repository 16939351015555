import f, { ODataFilterBuilder } from 'odata-filter-builder';
import { isEmpty } from '../../../../utils/isEmpty';
import { orConditions } from '../../../../utils/odata';
import { escape } from '../../../../utils/escape';

export const createRulesFilter = ({ vendorClass }: { vendorClass: string | undefined }) => {
    const filterRules: ODataFilterBuilder[] = [];

    if (!isEmpty(vendorClass)) {
        filterRules.push(f().contains('VendorClasses', escape(vendorClass!)));
    }

    filterRules.push(f().eq('VendorClasses', null));

    return orConditions(filterRules).toString();
};
