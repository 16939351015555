import { useCallback, useMemo } from 'react';

import { Chip, Stack, Box, Typography, Checkbox } from '@mui/material';
import { useCompanyChildrenState, useCurrentCompanyId } from '../../services/UserService/PortalUserService/hooks';
import { MultiSortedSelect, SortedSelect } from '../../ui';
import { useCompany } from '../../hooks';

type CompanyOption = ReturnType<typeof useCompanyChildrenState>['companyChildren'][number];

const AllOption: CompanyOption = { label: 'All', value: 0 };

interface MultiCompanySelectorProps {
    value: number[];
    onChange: (values: number[]) => void;
    allowMultipleCompanies?: boolean;
}

export function MultiCompanySelector({ value, onChange, allowMultipleCompanies = true }: MultiCompanySelectorProps) {
    const companyId = useCurrentCompanyId();

    const { data: company, isLoading: isLoadingCompany } = useCompany({ companyId });

    const { companyChildren } = useCompanyChildrenState();

    const parentCompanyOption = useMemo<CompanyOption | null>(() => {
        if (isLoadingCompany || !company) {
            return null;
        }

        return { label: company.Name, value: company.ID };
    }, [company, isLoadingCompany]);

    const selectableItems = useMemo(
        () => (parentCompanyOption ? [parentCompanyOption, ...companyChildren] : []),
        [parentCompanyOption, companyChildren]
    );

    const selectedItems = selectableItems.filter((c) => value.includes(c.value));

    const hasAllOptionsSelected = selectableItems.length === selectedItems.length;

    const displayedOptions = useMemo<CompanyOption[]>(() => {
        if (!parentCompanyOption) {
            return [];
        }

        const companies = [parentCompanyOption, ...companyChildren];

        companies.sort(({ label: a }, { label: b }) => a.localeCompare(b));

        if (allowMultipleCompanies) {
            return [AllOption].concat(companies);
        }

        return companies;
    }, [companyChildren, parentCompanyOption, allowMultipleCompanies]);

    const handleChange = useCallback(
        (selectedOptions: number[]) => {
            let selectedChildrenIds: number[] | null = null;

            // user clicked the "All" option
            if (selectedOptions.includes(AllOption.value)) {
                selectedChildrenIds = hasAllOptionsSelected ? [] : selectableItems.map((c) => c.value);
            }

            selectedChildrenIds ??= selectedOptions;

            onChange(selectedChildrenIds);
        },
        [hasAllOptionsSelected, selectableItems, onChange]
    );

    if (companyChildren.length === 0) {
        return null;
    }

    const selectorIsEmpty = selectedItems.length === 0;

    return (
        <Box
            sx={{
                minWidth: 300,
                width: 'auto',
                maxWidth: '100%',
            }}
        >
            {allowMultipleCompanies ? (
                <MultiSortedSelect
                    multiple
                    disableSorting
                    options={displayedOptions}
                    value={selectedItems}
                    error={selectorIsEmpty}
                    onChange={(options) => handleChange(options.map((opt) => opt.value))}
                    renderLabel={(label) => {
                        if (label === AllOption.label) {
                            return (
                                <Stack direction="row" alignItems="center">
                                    <Checkbox
                                        checked={hasAllOptionsSelected}
                                        indeterminate={!hasAllOptionsSelected && !selectorIsEmpty}
                                    />
                                    <Typography
                                        sx={{
                                            fontWeight: (t) =>
                                                hasAllOptionsSelected
                                                    ? t.typography.fontWeightBold
                                                    : t.typography.fontWeightRegular,
                                        }}
                                    >
                                        {label}
                                    </Typography>
                                </Stack>
                            );
                        }

                        const isSelected = !!selectedItems.find((i) => i.label === label);

                        return (
                            <Stack pl={1} direction="row" alignItems="center">
                                <Checkbox checked={isSelected} />
                                {label}
                            </Stack>
                        );
                    }}
                    displayEmpty
                    renderValue={(values) => {
                        const labels = values as string[];

                        if (!labels.length) {
                            return (
                                <Typography
                                    sx={{
                                        color: (t) => t.palette.text.secondary,
                                    }}
                                >
                                    Select Company
                                </Typography>
                            );
                        }

                        return (
                            <Stack direction="row" gap={1} flexWrap="wrap" px={0.5}>
                                {labels
                                    ?.filter((v) => v !== AllOption.label)
                                    ?.map((v) => (
                                        <Chip
                                            onDelete={() => {
                                                const newSelectedOptions = selectedItems
                                                    .filter((opt) => opt.label !== v)
                                                    .map((opt) => opt.value);

                                                handleChange(newSelectedOptions);
                                            }}
                                            onMouseDown={(e) => {
                                                e.stopPropagation();
                                            }}
                                            label={v}
                                            key={v}
                                            sx={{
                                                fontSize: (theme) => theme.typography.body2,
                                            }}
                                        />
                                    ))}
                            </Stack>
                        );
                    }}
                />
            ) : (
                <SortedSelect
                    options={displayedOptions}
                    value={selectedItems[0]}
                    onChange={(c) => {
                        handleChange(c?.value ? [c.value] : []);
                    }}
                />
            )}
        </Box>
    );
}
