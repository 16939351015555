import { useEffect } from 'react';
import { SearchBox } from '../SearchBox';
import SelectVirtualized from '../SelectVirtualized';
import { IValue } from '../../types';
import { useDropdownOptionsV1, useDropdownOptionsV2 } from './useDropdownOptions';
import { DocumentEditRules } from '../../../../../../features';
import { useDropdownOptionsRepository } from '../../../../../../features/DocumentEditRules';
import { InputField } from '../../../../Molecules';
import DropdownOptionsRepository from '../../../../../../features/DocumentEditRules/PluginHost/services/dropdownOptionsRepository';
import { DocumentValidatorV2 } from '../../../../../../features/CJSRuleEngineV2ClientWrapper';

interface ICompanyCustomDatasetDropdownField {
    datasetName?: string;
    datasetPath?: string;
    smallRecordLimit?: number;
    minCharsSearch?: number;
    fieldDefinitionKey?: string;
    companyId: number;
    value: IValue | null;
    editMode: boolean;
    onChange: (value: { value: string; label?: string }) => void;
    glFieldName?: string;
    unfocused?: boolean;
    testId?: string;
    dropdownOptionsRepository: DropdownOptionsRepository;
    data: IValue[];
}

function hasMessage(error: unknown): error is { message: unknown } {
    return !!(error && typeof error === 'object' && 'message' in error);
}

const FinalComponent: React.FC<
    Omit<
        ICompanyCustomDatasetDropdownField,
        'datasetName' | 'datasetPath' | 'fieldDefinitionKey' | 'companyId' | 'glFieldName' | 'dropdownOptionsRepository'
    >
> = ({ smallRecordLimit, minCharsSearch, value, editMode, onChange, unfocused, testId, data }) => {
    const handleOnchange = (e: IValue) => {
        onChange(e);
    };

    const recordLimit = smallRecordLimit ?? 1000;
    const minChars = minCharsSearch ?? 3;

    const recordLimitReached = (data as IValue[])?.length > recordLimit;
    const placeholder = recordLimitReached ? `Type ${minChars}+ chars...` : undefined;

    const Component = recordLimitReached ? SearchBox : SelectVirtualized;

    return (
        <>
            {unfocused ? (
                <InputField
                    className="unselected-input"
                    initialValue={value?.value}
                    readOnly
                    editable
                    editMode={false}
                    id={value?.label}
                    fieldName={value?.label ?? 'unfocused field'}
                />
            ) : (
                <Component
                    testId={`CompanyCustomDatasetDropdownField-${testId}`}
                    data={data as IValue[]}
                    minimumInputSearch={minChars}
                    value={value}
                    onChange={(customData: unknown) => {
                        handleOnchange(customData as IValue);
                    }}
                    editMode={editMode}
                    isClearable={false}
                    placeholder={placeholder}
                />
            )}
        </>
    );
};

const CompanyCustomDatasetDropdownFieldJSV1: React.FC<
    Omit<ICompanyCustomDatasetDropdownField, 'glFieldName' | 'data'>
> = ({
    datasetName,
    datasetPath,
    smallRecordLimit,
    minCharsSearch,
    fieldDefinitionKey,
    companyId,
    value,
    editMode,
    onChange,
    unfocused,
    testId,
    dropdownOptionsRepository,
}) => {
    const { isLoading, isFetching, isError, error, data } = useDropdownOptionsV1(
        fieldDefinitionKey,
        datasetName,
        datasetPath,
        companyId
    );

    useEffect(() => {
        if (data && dropdownOptionsRepository) {
            dropdownOptionsRepository?.setDropdownOptions(testId as string, data);
        }
    }, [data, isFetching, testId, dropdownOptionsRepository]);

    if (isLoading) {
        return <>Loading...</>;
    }

    if (isFetching) {
        return <>Fetching...</>;
    }

    if (isError) {
        return <>{`An error has occurred:  ${hasMessage(error) && error.message ? error.message : ''}`}</>;
    }

    return (
        <FinalComponent
            data={data}
            smallRecordLimit={smallRecordLimit}
            minCharsSearch={minCharsSearch}
            value={value}
            editMode={editMode}
            onChange={onChange}
            unfocused={unfocused}
            testId={testId}
        />
    );
};

const CompanyCustomDatasetDropdownFieldJSV2: React.FC<
    Omit<ICompanyCustomDatasetDropdownField, 'datasetName' | 'datasetPath' | 'companyId' | 'glFieldName' | 'data'>
> = ({
    smallRecordLimit,
    minCharsSearch,
    fieldDefinitionKey,
    value,
    editMode,
    onChange,
    unfocused,
    testId,
    dropdownOptionsRepository,
}) => {
    const { data, isError } = useDropdownOptionsV2(fieldDefinitionKey, dropdownOptionsRepository);

    if (!data) {
        return <>Loading...</>;
    }

    if (isError) {
        return <>An error has occurred fetching the data</>;
    }

    return (
        <FinalComponent
            data={data}
            smallRecordLimit={smallRecordLimit}
            minCharsSearch={minCharsSearch}
            value={value}
            editMode={editMode}
            onChange={onChange}
            unfocused={unfocused}
            testId={testId}
        />
    );
};

export const CompanyCustomDatasetDropdownField: React.FC<
    Omit<ICompanyCustomDatasetDropdownField, 'testId' | 'dropdownOptionsRepository' | 'data'>
> = ({
    datasetName,
    datasetPath,
    smallRecordLimit,
    minCharsSearch,
    fieldDefinitionKey,
    companyId,
    value,
    editMode,
    onChange,
    glFieldName,
    unfocused = false,
}) => {
    const jsEngineVersion = DocumentValidatorV2.useCJSEngineVersion();
    const fieldName = DocumentEditRules.useContextualFieldName();
    const testId = DocumentEditRules.useFieldKey(fieldName || glFieldName || '') || glFieldName;
    const dropdownOptionsRepository = useDropdownOptionsRepository();

    if (jsEngineVersion === 2) {
        return (
            <CompanyCustomDatasetDropdownFieldJSV2
                smallRecordLimit={smallRecordLimit}
                minCharsSearch={minCharsSearch}
                fieldDefinitionKey={fieldDefinitionKey}
                value={value}
                editMode={editMode}
                onChange={onChange}
                unfocused={unfocused}
                testId={testId}
                dropdownOptionsRepository={dropdownOptionsRepository}
            />
        );
    }

    return (
        <CompanyCustomDatasetDropdownFieldJSV1
            datasetName={datasetName}
            datasetPath={datasetPath}
            smallRecordLimit={smallRecordLimit}
            minCharsSearch={minCharsSearch}
            fieldDefinitionKey={fieldDefinitionKey}
            companyId={companyId}
            value={value}
            editMode={editMode}
            onChange={onChange}
            unfocused={unfocused}
            testId={testId}
            dropdownOptionsRepository={dropdownOptionsRepository}
        />
    );
};
