import clsx from 'clsx';
import useStyles from './useStyles';

export interface SingleErrorProps {
    error: string;
    testId: string;
    color?: string;
}

export default function SingleError({ error, color, testId }: SingleErrorProps) {
    const classes = useStyles({ color });
    return (
        <div className={clsx(classes.root, classes.error)} data-testid={testId}>
            {error}
        </div>
    );
}
