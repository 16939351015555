import { IInstanceTypeConverter, CannotConvertToInstance, CannotConvertToPlainObject } from './types';

/**
 * Convert instances to plain objects and plain objects back to instances.
 *
 * Enables serializing object instances.
 */
export default class InstanceConverter {
    private converterLookup: { [key: string]: IInstanceTypeConverter<unknown> | undefined } = {};

    constructor(private converters: IInstanceTypeConverter<unknown>[]) {
        converters.forEach((c) => {
            this.converterLookup[c.typeKey] = c;
        });
    }

    /**
     * Converts an object instance to a plain object
     * @param object to convert
     * @returns the converted object, or the original object if a plain object.
     */
    convertToPlainObject(object: any) {
        // skip primitives and plain objects
        if (typeof object !== 'object') {
            return object;
        }
        if (object === null) {
            return object;
        }
        if (object.constructor === Object) {
            return object;
        }

        for (const converter of this.converters) {
            if (object instanceof converter.constructor) {
                return {
                    __instanceConverterType__: converter.typeKey,
                    value: converter.serialize(object),
                };
            }
        }

        throw new CannotConvertToPlainObject(object);
    }

    /**
     * Convert a plain object to an object instance.
     * @param object to convert
     * @returns the instance, or the original plain object if it does not represent a converted instance.
     */
    convertToInstance(object: any) {
        // the naming convention at the time of creation was purposeful. we probably will never use this though.
        // eslint-disable-next-line no-underscore-dangle
        if (typeof object === 'object' && object !== null && object.__instanceConverterType__) {
            // eslint-disable-next-line no-underscore-dangle
            const converter = this.converterLookup[object.__instanceConverterType__];
            if (!converter) {
                throw new CannotConvertToInstance(object);
            }

            return converter.parse(object.value);
        }

        return object;
    }
}
