import { IRootErrorBoundaryErrorMessage } from './types';
import { createSafeHTMLString, ISafeHTMLString } from '../SafeHTML';
import { getAppConfig } from '../../config';

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface SafeRootErrorBoundaryErrorMessage {
    dialogTitle: string;
    paragraphs: ISafeHTMLString[];
}

const defaultMessage: IRootErrorBoundaryErrorMessage = {
    dialogTitle: 'Something went wrong while displaying this page',
    paragraphs: [
        'We have encountered an error while trying to display this page. Please click the Reset button below to exit the current page.',
        'The error that caused this message to display has been reported to the Transcepta team and we are looking into it.',
        '<a href="https://help.transcepta.com/hc/en-us/articles/4406231242132" target="_blank">Click here</a> for a list of supported browsers and troubleshooting steps.',
        'If you keep encountering this issue, please <a href="https://support.transcepta.com/" target="_blank">click here to create a support case.</a>',
    ],
};

function createSafeMessage(message: IRootErrorBoundaryErrorMessage): SafeRootErrorBoundaryErrorMessage {
    // This text is either coming from the appConfig or the hardcoded defaults, so this is safe
    /* eslint-disable rulesdir/no-create-safe-html-without-reason */
    return {
        dialogTitle: message.dialogTitle,
        paragraphs: message.paragraphs.map((x) => createSafeHTMLString(x)),
    };
    /* eslint-enable */
}

export function getRootErrorBoundaryErrorMessage(): SafeRootErrorBoundaryErrorMessage {
    const message = getAppConfig().configurableMessages?.rootErrorBoundary ?? defaultMessage;
    return createSafeMessage(message);
}
