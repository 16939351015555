import * as alertActions from './alerts';

export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_PHONE = 'UPDATE_PHONE';
export const CLEAR_EMAIL_STATE = 'CLEAR_EMAIL_STATE';

export const updateContact = (value) => {
    return {
        type: UPDATE_CONTACT,
        payload: value,
    };
};

export const updateEmail = (value) => {
    return {
        type: UPDATE_EMAIL,
        payload: value,
    };
};

export const updatePhone = (value) => {
    return {
        type: UPDATE_PHONE,
        payload: value,
    };
};

export const clearState = () => {
    return {
        type: CLEAR_EMAIL_STATE,
    };
};
