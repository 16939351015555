export interface IValidationFailureTypeViewModel {
    Id?: number;
    FieldDictionaryId: number;
    ErrorCode: string;
    TitleText: string;
    DetailText: string;
    ShortText: string;
    Label: string;
    BusinessDocType: number;
    ValidationType: number;
    InternalOnly: boolean;
    LastUpdateDate?: string;
    Rowversion?: number[];
    Disposition: number;
    BuyerTitleText: string | null;
    BuyerDetailText: string | null;
    BuyerCompanyId: number | null;
    BuyerCompanyName?: string;
    BusinessDocType_US?: string;
    ValidationType_US?: string;
    Disposition_US?: string;
    ValidationFailureDetails: string[];
}

export enum VFTDisposition {
    ReturnToSupplier = 1,
    Park = 2,
}
