import { DashboardApi, SupplierTotal, InvoiceTotal, SpendTotal, POTotal, POATotal } from '../../../Api';
import { DashboardReportService } from '../../DashboardReportService';
import { createDashboardReportService } from '../../createDashboardReportService';
import { DashboardDataPayload } from '../../../Api/DashboardDataPayload';
import { amountFormatter } from '../../utils';

export class SupplierTotalReportService implements DashboardReportService<SupplierTotal, String> {
    private api = new DashboardApi();

    get storedProcedure() {
        return 'DashboardReportSupplierTotal' as const;
    }

    async fetchReport(settings: DashboardDataPayload) {
        const res = await this.api.getDashboardReportData<SupplierTotal>(settings, this.storedProcedure);
        return res.data;
    }

    get nullifyDataIfEmptyObj() {
        return {
            optIn: false,
            xAxisPropertyKey: undefined,
        };
    }

    transformVMToChartData(vm: SupplierTotal['viewModel']) {
        return vm[0]?.SupplierTotal?.toLocaleString('en-US').toString();
    }
}

export class InvoiceTotalReportService implements DashboardReportService<InvoiceTotal, String> {
    private api = new DashboardApi();

    get storedProcedure() {
        return 'DashboardReportInvoiceTotal' as const;
    }

    async fetchReport(settings: DashboardDataPayload) {
        const res = await this.api.getDashboardReportData<InvoiceTotal>(settings, this.storedProcedure);
        return res.data;
    }

    get nullifyDataIfEmptyObj() {
        return {
            optIn: false,
            xAxisPropertyKey: undefined,
        };
    }

    transformVMToChartData(vm: InvoiceTotal['viewModel']) {
        return vm[0]?.InvoiceTotal?.toLocaleString('en-US').toString();
    }
}

export class SpendTotalReportService implements DashboardReportService<SpendTotal, String> {
    private api = new DashboardApi();

    get storedProcedure() {
        return 'DashboardReportSpendTotal' as const;
    }

    async fetchReport(settings: DashboardDataPayload) {
        const res = await this.api.getDashboardReportData<SpendTotal>(settings, this.storedProcedure);
        return res.data;
    }

    get nullifyDataIfEmptyObj() {
        return {
            optIn: false,
            xAxisPropertyKey: undefined,
        };
    }

    transformVMToChartData(vm: SpendTotal['viewModel']) {
        return amountFormatter(vm[0]?.SpendTotal, true);
    }
}

export class POTotalReportService implements DashboardReportService<POTotal, String> {
    private api = new DashboardApi();

    get storedProcedure() {
        return 'DashboardReportPOTotal' as const;
    }

    async fetchReport(settings: DashboardDataPayload) {
        const res = await this.api.getDashboardReportData<POTotal>(settings, this.storedProcedure);
        return res.data;
    }

    get nullifyDataIfEmptyObj() {
        return {
            optIn: false,
            xAxisPropertyKey: undefined,
        };
    }

    transformVMToChartData(vm: POTotal['viewModel']) {
        return vm[0]?.POTotal?.toLocaleString('en-US').toString();
    }
}

export class POATotalReportService implements DashboardReportService<POATotal, String> {
    private api = new DashboardApi();

    get storedProcedure() {
        return 'DashboardReportPOATotal' as const;
    }

    async fetchReport(settings: DashboardDataPayload) {
        const res = await this.api.getDashboardReportData<POATotal>(settings, this.storedProcedure);
        return res.data;
    }

    get nullifyDataIfEmptyObj() {
        return {
            optIn: false,
            xAxisPropertyKey: undefined,
        };
    }

    transformVMToChartData(vm: POATotal['viewModel']) {
        return vm[0]?.POATotal?.toLocaleString('en-US').toString();
    }
}

export const useSupplierTotalReportService = createDashboardReportService(SupplierTotalReportService);
export const useInvoiceTotalReportService = createDashboardReportService(InvoiceTotalReportService);
export const useSpendTotalReportService = createDashboardReportService(SpendTotalReportService);
export const usePOTotalReportService = createDashboardReportService(POTotalReportService);
export const usePOATotalReportService = createDashboardReportService(POATotalReportService);
