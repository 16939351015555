import actions from '../../actions/index';
import jsonpath from 'jsonpath';

const {
    DOCUMENT_LAYOUT_FETCHING,
    DOCUMENT_LAYOUT_FETCHED,
    DOCUMENT_LAYOUT_FETCH_ERROR,
    UPDATE_LAYOUT_FIELD_VISIBLITY,
    UPDATE_LAYOUT_FIELD,
} = actions;

const initialState = {
    layout: {},
    isLoading: false,
    layoutErrors: [],
};

// eslint-disable-next-line import/prefer-default-export
export function documentLayout(state = initialState, action) {
    switch (action.type) {
        case DOCUMENT_LAYOUT_FETCHING:
            return {
                ...state,
                layout: initialState.layout,
                isLoading: true,
                layoutErrors: initialState.layoutErrors,
            };

        case DOCUMENT_LAYOUT_FETCHED:
            return { ...state, layout: action.payload.data, isLoading: false };

        case DOCUMENT_LAYOUT_FETCH_ERROR:
            return { ...state, layoutErrors: action.payload.errors, isLoading: false };

        case UPDATE_LAYOUT_FIELD_VISIBLITY: {
            let newLayout = Object.assign({}, state.layout);
            let values = jsonpath.query(newLayout, '$..*[?(@.FieldName=="' + action.payload + '")]');
            values = values.map((item) => {
                item.Visible = true;
                return item;
            });
            return {
                ...state,
                layout: newLayout,
            };
        }
        case UPDATE_LAYOUT_FIELD: {
            let updatedLayout = Object.assign({}, state.layout);
            const { payload } = action;
            const { FieldDictionaryId } = payload;
            let updatedFields = jsonpath.query(updatedLayout, `$..*[?(@.FieldDictionaryId=="${FieldDictionaryId}")]`);
            updatedFields = updatedFields.map((item) => Object.keys(item).map((s) => (item[s] = payload[s])));
            return {
                ...state,
                layout: updatedLayout,
            };
        }
        default:
            return state;
    }
}
