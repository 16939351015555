import {
    DOCUMENT_CREATION_ADD_LINE,
    DOCUMENT_CREATION_LOAD_DOCUMENT,
    DOCUMENT_CREATION_REMOVE_LINE,
    DOCUMENT_CREATION_UPDATE_FIELD_VALUE,
    DOCUMENT_CREATION_SET_DEFAULT_COMMON_FIELDS,
    FETCH_DOCUMENT_FOR_EDIT_EXECUTE,
    FETCH_DOCUMENT_FOR_EDIT_SUCCESS,
    FETCH_INVOICE_DOCUMENT_FOR_EDIT_SUCCESS,
    RESET_TO_INITIAL_STATE,
    SET_SENDER_PROFILE,
    SIM_SELECTED_DOCUMENT_CLASS,
    SIM_DOCUMENT_CREATION_SET_DEFAULT_BUS_DOC_FIELDS,
} from '../../actions/document/documentCreationActions';
import { USER_LOGIN_FAILED, USER_LOGIN_PENDING, USER_LOGIN_SUCCESS, USER_LOGOUT } from '../../actions/userlogin';

const initialState = {
    BusinessDocFields: {
        BusinessDocument: {
            Document: {
                BusinessDocument: {},
            },
        },
    },
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case DOCUMENT_CREATION_ADD_LINE:
        case DOCUMENT_CREATION_REMOVE_LINE:
        case DOCUMENT_CREATION_UPDATE_FIELD_VALUE: {
            const { timeout, ...currentState } = state;
            return timeout && timeout === true
                ? currentState
                : {
                      ...state,
                      BusinessDocFields: {
                          ...state.BusinessDocFields,
                          BusinessDocument: payload,
                      },
                  };
        }
        case DOCUMENT_CREATION_SET_DEFAULT_COMMON_FIELDS:
            return {
                ...state,
                CommonFields: {
                    ...state.CommonFields,
                    ReceiverProfileId: payload.CommonFields.ReceiverProfileId,
                    SenderProfileId: payload.CommonFields.SenderProfileId,
                    SenderProfileType: payload.CommonFields.SenderProfileType,
                    ReceiverProfileType: payload.CommonFields.ReceiverProfileType,
                    CustomerId: payload.CommonFields.CustomerId,
                    CustomerName: payload.CustomerName,
                },
            };

        case SIM_DOCUMENT_CREATION_SET_DEFAULT_BUS_DOC_FIELDS: {
            return {
                ...state,
                BusinessDocFields: {
                    ...state.BusinessDocFields,
                    BusinessDocument: {
                        ...state.BusinessDocFields.BusinessDocument,
                        Document: {
                            ...state.BusinessDocFields.BusinessDocument.Document,
                            BusinessDocument: {
                                ...state.BusinessDocFields.BusinessDocument.Document.BusinessDocument,
                                CustomerNumber: payload.CustomerNumber,
                                CustomerName: payload.CustomerName,
                                IdentifyingNumber: payload.Description,
                                DocumentDate: new Date(),
                                SIMRequestId: payload.SIMRequestId,
                                SIMDocument: {
                                    ...state.BusinessDocFields.BusinessDocument.Document.BusinessDocument.SIMDocument,
                                    Description: payload.Description,
                                    DocumentClass: payload.DocumentClass,
                                    SIMDocumentTypeId: payload.SIMDocumentTypeId,
                                    DueDate: payload.DueDate,
                                    DocumentType: payload.DocumentType,
                                },
                            },
                        },
                    },
                },
            };
        }
        case FETCH_DOCUMENT_FOR_EDIT_SUCCESS:
        case DOCUMENT_CREATION_LOAD_DOCUMENT: {
            const { timeout, ...currentState } = state;
            if (timeout && timeout === true) {
                return { ...currentState };
            }
            payload.simDocClass = state.simDocClass;
            return payload;
        }
        case SET_SENDER_PROFILE:
            return {
                ...state,
                CommonFields: {
                    ...state.CommonFields,
                    SenderProfileId: payload,
                },
            };

        case SIM_SELECTED_DOCUMENT_CLASS: {
            return {
                ...state,
                simDocClass: payload,
            };
        }
        case FETCH_DOCUMENT_FOR_EDIT_EXECUTE:
        case FETCH_INVOICE_DOCUMENT_FOR_EDIT_SUCCESS:
        case USER_LOGIN_FAILED:
        case USER_LOGIN_PENDING:
        case USER_LOGIN_SUCCESS:
        case RESET_TO_INITIAL_STATE: {
            // if it was timeout return currenState, otherwhise initialState
            const { timeout } = state;
            return timeout && timeout === true ? state : initialState;
        }
        case USER_LOGOUT: {
            const { timeout } = payload;
            return timeout && timeout == true ? { ...state, timeout } : initialState;
        }
        default:
            return state;
    }
};
