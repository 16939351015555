// DEPRECATED: Do not use
// REFACTOR: Move this inside of Molecules/DataRow as that is the only place it is used
import { useState, useRef, useEffect, FC } from 'react';
import { IconButton } from '@mui/material';
import { ChevronRight, Add, Remove } from '@mui/icons-material';
import clsx from 'clsx';
import './Card.scss';

export interface ICardProps {
    isExpanded: boolean;
    className?: string;
    classNameContent?: string;
    header: JSX.Element | JSX.Element[];
    renderBodyContent: JSX.Element | JSX.Element[];
    handleAction?: () => void;
}

export const Card: FC<ICardProps> = ({
    isExpanded = false,
    className,
    classNameContent,
    header,
    renderBodyContent,
    handleAction,
}) => {
    const [expanded, setExpanded] = useState(isExpanded);
    const [height, setHeight] = useState('0px');
    const content = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setHeight(expanded ? `${content.current && content.current.scrollHeight}px` : '0px');
    }, [expanded]);

    const LeftIcon = expanded ? Remove : Add;

    return (
        <div className={clsx('core-card', className)}>
            <div className="card-header">
                <IconButton aria-label="click to expand or collapse" onClick={() => setExpanded(!expanded)}>
                    <LeftIcon />
                </IconButton>
                <div
                    className="expandable-header"
                    onClick={() => setExpanded(!expanded)}
                    role="button"
                    tabIndex={0}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            setExpanded(!expanded);
                        }
                    }}
                >
                    {header}
                </div>
                {handleAction && (
                    <IconButton aria-label="go to document" onClick={handleAction}>
                        <ChevronRight />
                    </IconButton>
                )}
            </div>
            <div ref={content} style={{ maxHeight: `${height}` }} className={clsx('card-content', classNameContent)}>
                {renderBodyContent}
            </div>
        </div>
    );
};
