import { ViewModels } from '../../../../services/backendServices';

export interface FlattenedXrefParameters {
    ruleParameters: ViewModels.InputParameterSetting[];

    xrefParameterValues: ViewModels.InputParameter[];

    parameterOverrides: {
        id: number | null;
        vendorClasses: string[];
        parameterValues: ViewModels.InputParameter[];
    }[];
}

export function flattenInputParameterSettings(inputParameterSettings: ViewModels.InputParameterSettings | null) {
    if (!inputParameterSettings || !inputParameterSettings.Parameters.Parameter) {
        return [];
    }

    if (!Array.isArray(inputParameterSettings.Parameters.Parameter)) {
        return [inputParameterSettings.Parameters.Parameter];
    }

    return inputParameterSettings.Parameters.Parameter;
}

function flattenInputParameters(inputParameters: ViewModels.InputParameters | null) {
    if (inputParameters === null) {
        return [];
    }

    if (!inputParameters || !inputParameters.InputParameters.Parameters.Parameter) {
        return [];
    }

    if (!Array.isArray(inputParameters.InputParameters.Parameters.Parameter)) {
        return [inputParameters.InputParameters.Parameters.Parameter];
    }

    return inputParameters.InputParameters.Parameters.Parameter;
}

function flattenOverrides(overrides: ViewModels.BuyerCustomJSRuleMappingXrefInputParameterOverridesViewModel) {
    if (!overrides?.InputParameters) {
        return [];
    }

    return flattenInputParameters(overrides.InputParameters);
}

/**
 * Flattens the ugly XML structure of the InputParameterSettings and InputParameters to simple arrays.
 *
 * This step allows further processing without dealing with the messy XML structure.
 *
 * @param xref the xref to transform
 */
export function flattenXrefParameters(xref: ViewModels.BuyerCustomJSRuleMappingXrefViewModel): FlattenedXrefParameters {
    if (!xref.BuyerCustomJSRule) {
        throw new Error('Expected Custom JS Rule to be defined');
    }

    return {
        ruleParameters: flattenInputParameterSettings(xref.BuyerCustomJSRule.InputParameterSettings),
        xrefParameterValues: flattenInputParameters(xref.InputParameters),
        parameterOverrides: xref.BuyerCustomJSRuleMappingXRefInputParameterOverrides.map((override) => ({
            id: override.Id,
            vendorClasses: override.VendorClasses === null ? [] : override.VendorClasses.split(',').filter(Boolean),
            parameterValues: flattenOverrides(override),
        })),
    };
}
