/**
 * This is our application routes list *
 **
 * Import it as "R", then use in the router or for API calls
 */

import pathsConfig from './paths.config.json';
import { IRoutes } from 'common/build/legacy/transcepta-types';
const ROOT_PAGE = pathsConfig.ROOT_URL;
export const OUTER_PATH = pathsConfig.iframe.baseURL;

export const Routes: IRoutes = {
    ROOT: {
        path: `${ROOT_PAGE}/`,
        name: 'Main',
        outerPath: `${OUTER_PATH}/Home/Dashboard.aspx`,
    },

    HOME: {
        path: `${ROOT_PAGE}/home`,
        name: 'Home',
        outerPath: `${OUTER_PATH}/Home/Dashboard.aspx`,
    },

    DEV_LAYOUT_PAGE: {
        path: `${ROOT_PAGE}/devlayout`,
        name: 'Development layout',
    },

    DEV_DOCUMENT_PAGE: {
        path: `${ROOT_PAGE}/devdoc/:documentId`,
        name: 'View document',
    },

    LOGIN_PAGE: {
        path: `${ROOT_PAGE}/login`,
        name: 'Login',
    },
    INTERNAL_LOGIN_PAGE: {
        path: `${ROOT_PAGE}/internallogin`,
        name: 'Internal Login',
    },
    // we redirect user to this page on successfull login
    LOGIN_SUCCESSFUL_PAGE: {
        path: `${ROOT_PAGE}/home`,
        name: 'Login Successful',
        outerPath: `${OUTER_PATH}/Home/Dashboard.aspx`,
    },
    LOGOUT_PAGE: {
        path: `${ROOT_PAGE}/logout`,
        name: 'Signed Out',
    },

    PAGE_404: { path: `${ROOT_PAGE}/not-found`, name: '404' },

    HELP_CENTER: {
        path: `${ROOT_PAGE}/help-center`,
        name: 'Help Center',
        outerPath: `${OUTER_PATH}/HelpCenter/HelpCenter.aspx`,
    },
    HELP_CENTER_CATEGORY: {
        path: `${ROOT_PAGE}/help-center/category/:id`,
        name: 'Help Center',
        outerPath: `${OUTER_PATH}/HelpCenter/HelpCenter.aspx`,
    },
    HELP_CENTER_ARTICLE: {
        path: `${ROOT_PAGE}/help-center/resource/:id`,
        name: 'Help Center',
        outerPath: `${OUTER_PATH}/HelpCenter/HelpCenter.aspx`,
    },
    HELP_CENTER_SEARCH: {
        path: `${ROOT_PAGE}/help-center/search`,
        name: 'Help Center',
        outerPath: `${OUTER_PATH}/HelpCenter/HelpCenter.aspx`,
    },
    HELP_CENTER_EXTERNAL: {
        path: `http://help.transcepta.com`,
        name: 'Help Center',
    },
    DOCUMENTS: {
        path: `${ROOT_PAGE}/documents`,
        name: 'Documents',
        preserveRouteOnVisit: true,
    },
    DOCUMENT_SEARCH: {
        path: `${ROOT_PAGE}/documents/:businessDocType`,
    },
    DOCUMENT_DETAILS: {
        path: `${ROOT_PAGE}/documents/:businessDocType/:documentId`,
    },
    DOCUMENT_DRAFT: {
        path: `${ROOT_PAGE}/documents/:businessDocType/draft/:draftId`,
    },
    DOCUMENT_EDIT: {
        path: `${ROOT_PAGE}/documents/:businessDocType/:customerId/:customerLocationId/:documentId/edit`,
    },
    DOCUMENT_DRAFT_EDIT: {
        path: `${ROOT_PAGE}/documents/:businessDocType/draft/:customerId/:customerLocationId/:draftId/edit`,
    },
    DOCUMENT_CREATION: {
        path: `${ROOT_PAGE}/documents/:businessDocType/:customerId/:customerLocationId/create`,
    },
    DOCUMENT_CREATION_CUSTOMER_SELECTION: {
        path: `${ROOT_PAGE}/documents/:businessDocType/select-customer`,
    },
    DOCUMENT_CREATION_CUSTOMER_LOCATION_SELECTION: {
        path: `${ROOT_PAGE}/documents/:businessDocType/:customerId/select-customer-location`,
    },
    DOCUMENT_CREATION_CUSTOMER_LOCATION_CREATE: {
        path: `${ROOT_PAGE}/documents/:businessDocType/:customerId/create-customer-location`,
    },
    DOCUMENT_CREATION_SIM_DOCUMENT_REQUEST_SELECTION: {
        path: `${ROOT_PAGE}/documents/sim-documents/select-sim-request`,
    },
    INVOICES: {
        path: `${ROOT_PAGE}/documents/invoices`,
        name: 'Invoices',
        preserveRouteOnVisit: true,
    },
    INVOICE_NO_DRAFT_ID: {
        path: `${ROOT_PAGE}/documents/invoices/draft`,
        name: 'Invoice No Draft Id',
    },
    INVOICE_DETAILS: {
        path: `${ROOT_PAGE}/documents/invoices/:documentId`,
        outerPath: '',
        name: 'View Transaction Invoice',
    },
    INVOICE_DRAFT: {
        path: `${ROOT_PAGE}/documents/invoices/:draftId/draft`,
        outerPath: '',
        name: 'Invoice Draft',
    },
    PURCHASE_ORDERS: {
        path: `${ROOT_PAGE}/documents/purchase-orders`,
        name: 'Purchase Orders',
        breabcrums: 'Select Purchase Order',
        outerPath: '',
        preserveRouteOnVisit: true,
    },
    PURCHASE_ORDER_DETAILS: {
        path: `${ROOT_PAGE}/documents/purchase-orders/:documentId`,
        outerPath: '',
        name: 'View Purchase Order',
    },
    PURCHASE_ORDERS_ACKLNS: {
        path: `${ROOT_PAGE}/documents/po-acknowledgments`,
        name: 'PO Acknowledgments',
        outerPath: `${OUTER_PATH}/Documents/SelectPOAcknowledgement.aspx`,
    },
    PURCHASE_ORDER_ACKLNS_DETAILS: {
        path: `${ROOT_PAGE}/documents/po-acknowledgments/:documentId`,
        outerPath: '',
        name: 'View PO Acknowledgement',
    },
    ASNS: {
        path: `${ROOT_PAGE}/documents/asns`,
        name: 'ASNs',
        outerPath: `${OUTER_PATH}/Documents/SelectASN.aspx`,
        preserveRouteOnVisit: true,
    },
    ASN_DETAILS: {
        path: `${ROOT_PAGE}/documents/asns/:documentId`,
        name: 'View ASN',
        outerPath: '',
    },
    INVENTORY_ADVICES: {
        path: `${ROOT_PAGE}/documents/inventory-advices`,
        name: 'Inventory Advices',
        outerPath: `${OUTER_PATH}/Documents/SelectDocument.aspx?bdt=6`,
        preserveRouteOnVisit: true,
    },
    INVENTORY_ADVICE_DETAILS: {
        path: `${ROOT_PAGE}/documents/inventory-advices/:documentId`,
        name: 'View Inventory Advice',
        outerPath: '',
    },
    CONSUMPTION_ADVICES: {
        path: `${ROOT_PAGE}/documents/consumption-advices`,
        name: 'Consumption Advices',
        outerPath: `${OUTER_PATH}/Documents/SelectDocument.aspx?bdt=8`,
        preserveRouteOnVisit: true,
    },
    CONSUMPTION_ADVICE_DETAILS: {
        path: `${ROOT_PAGE}/documents/consumption-advices/:documentId`,
        name: 'View Consumption Advice',
        outerPath: '',
    },
    RECEIVING_ADVICES: {
        path: `${ROOT_PAGE}/documents/receiving-advices`,
        name: 'Receiving Advices',
        outerPath: `${OUTER_PATH}/Documents/SelectDocument.aspx?bdt=7`,
        preserveRouteOnVisit: true,
    },
    RECEIVING_ADVICE_DETAILS: {
        path: `${ROOT_PAGE}/documents/receiving-advices/:documentId`,
        name: 'View Receiving Advice',
        outerPath: '',
    },
    SIM_DOCUMENTS: {
        path: `${ROOT_PAGE}/documents/sim-documents`,
        name: 'SIM Documents',
        preserveRouteOnVisit: true,
    },
    SIM_DOCUMENT_DETAILS: {
        path: `${ROOT_PAGE}/documents/sim-documents/:documentId`,
        name: 'View SIM Document',
    },
    REPORTING: {
        path: `${ROOT_PAGE}/reporting`,
        name: 'Reporting',
        outerPath: `${OUTER_PATH}/Reporting/GenerateReport.aspx`,
    },
    SETTINGS: {
        path: `${ROOT_PAGE}/settings`,
        name: 'Settings',
    },
    SETTINGS_ADMINISTRATION: {
        path: `${ROOT_PAGE}/settings`,
        name: 'Settings',
        outerPath: `${OUTER_PATH}/Settings/Administration.aspx`,
    },
    ACCOUNT_PROFILE_SETTINGS: {
        path: `${ROOT_PAGE}/settings/account-profile`,
        outerPath: `${OUTER_PATH}/Settings/EditAccountProfile.aspx`,
        name: 'Edit Account',
    },
    CONFIGURATION_SETTINGS: {
        path: `${ROOT_PAGE}/settings/configuration`,
        outerPath: `${OUTER_PATH}/Settings/EditConfigurationSettings.aspx`,
        name: 'Edit Configuration Settings',
    },
    MANAGE_USERS: {
        path: `${ROOT_PAGE}/settings/manage-users`,
        outerPath: `${OUTER_PATH}/Settings/ManageUsers.aspx`,
        name: 'Manage Users',
    },
    CREATE_USER: {
        path: `${ROOT_PAGE}/settings/create-user`,
        name: 'Create User',
    },
    USER_DETAILS: {
        path: `${ROOT_PAGE}/settings/user-details/:userId`,
        name: 'User Details',
    },
    CHANGE_PASSWORD: {
        path: `${ROOT_PAGE}/settings/changePassword`,
        name: 'Change Password',
    },
    MANAGE_CUSTOMERS: {
        path: `${ROOT_PAGE}/settings/customers`,
        outerPath: `${ROOT_PAGE}/Settings/ManageCustomers.aspx`,
        name: 'Manage Customers',
    },
    MANAGE_CUSTOMERS_CREATE: {
        path: `${ROOT_PAGE}/settings/customers/create-location`,
        outerPath: `${ROOT_PAGE}/Settings/ManageCustomers.aspx`,
        name: 'Create Customer Location',
    },
    MANAGE_CUSTOMERS_UPDATE: {
        path: `${ROOT_PAGE}/settings/customers/update-location/:buyerCompanyId`,
        outerPath: `${ROOT_PAGE}/Settings/ManageCustomers.aspx`,
        name: 'Edit Customer Location',
    },
    CREATE_CUSTOMER: {
        path: `${ROOT_PAGE}/settings/customers/create`,
        name: 'Create Customer Location',
    },
    EDIT_CUSTOMER: {
        path: `${ROOT_PAGE}/settings/customers/edit`,
        name: 'Edit Customer Location',
    },
    MANAGE_PROFILES: {
        path: `${ROOT_PAGE}/settings/profiles/:profileType`,
        name: 'Manage Profiles',
    },
    CREATE_PROFILE: {
        path: `${ROOT_PAGE}/settings/profiles/:profileType/create`,
        name: 'Create Profile',
    },
    EDIT_PROFILE: {
        path: `${ROOT_PAGE}/settings/profiles/:profileType/:profileId/edit`,
        name: 'Edit Profile',
    },
    MANAGE_REMITTANCE_INSTRUCTIONS: {
        path: `${ROOT_PAGE}/settings/manage-remittance-instructions`,
        name: 'Manage Remittance Instructions',
    },
    CREATE_REMITTANCE_INSTRUCTONS: {
        path: `${ROOT_PAGE}/settings/manage-remittance-instructions/create`,
        name: 'Create Remittance Instructions',
    },
    EDIT_REMITTANCE_INSTRUCTIONS: {
        path: `${ROOT_PAGE}/settings/manage-remittance_instructions/:id/edit`,
        name: 'Edit Remittance Instructions',
    },
    DOWNLOAD_VIRTUAL_PRINTER: {
        path: `${ROOT_PAGE}/settings/download-virtual-printer`,
        outerPath: `${OUTER_PATH}/Settings/DownloadVirtualPrinter.aspx`,
        name: 'Download the Virtual Printer',
    },

    LOGOUT: { path: `${ROOT_PAGE}/logout`, name: 'Logout' },

    NOTICE_PAGE: {
        path: `${ROOT_PAGE}/notice/`,
        name: 'Notice page',
    },
    USER_SETTINGS_PAGE: {
        path: `${ROOT_PAGE}/settings/manage-users`,
        name: 'Manage Users',
    },
    EDIT_CREATE_USER_PAGE: {
        path: `${ROOT_PAGE}/settings/edit-create-user/`,
        name: 'Edit Create User',
    },
};

export default Routes;
