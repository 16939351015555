import { DocumentSearchUtil } from '../../legacy/transcepta-common';
import { SUFFIX_FOR_DUPES } from './getGridColumns';
import {
    includePartialMatchesDefaultFieldConfig,
    CREATED_TIME_FIELD_KEY,
    SENDER_CONFIRMATION_FIELD_KEY,
    DISPATCHED_TIME_FIELD_KEY,
} from './getSearchFormFieldColumns';

export { useSearchLayoutSize } from './useSearchLayoutSize';
export {
    getSearchFilterAndParams,
    validateFilterValues,
    removeSuffixForDupesFromSearchRequest,
    type FilterValues,
    DocumentSearchConfigurationPage,
} from './getSearchFilterAndParams';
export { getSearchFormFieldColumns } from './getSearchFormFieldColumns';
export { getGridColumns } from './getGridColumns';

export const hasContainsInFilters = DocumentSearchUtil.hasContainsInFilters;

export const constants = {
    includePartialMatchesDefaultFieldConfig,
    SUFFIX_FOR_DUPES,
    CREATED_TIME_FIELD_KEY,
    SENDER_CONFIRMATION_FIELD_KEY,
    DISPATCHED_TIME_FIELD_KEY,
};
