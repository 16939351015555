import { InternalRule } from '../useInternalRules';

export const glCodingReadOnly: InternalRule = {
    name: 'glCodingReadOnly',
    logic: (app) => {
        app.registerUIDocumentController((_, fieldController) => {
            fieldController.disableField(app.services.associatedField.glCodingSection());
        });
    },
    fieldNamesToValidateOnFocusLoss: ['glSplit'],
    eventsToValidateOn: [
        'FocusLossEvent',
        'WorkflowLoadEvent',
        'WorkflowReassignEvent',
        'WorkflowSaveEvent',
        'WorkflowSubmitEvent',
    ],
};
