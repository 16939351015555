export function logSessionInformation(
    extraData: {
        event: 'ManualSessionExtension' | 'AutomaticSessionExtension' | 'SessionWarningOpen' | 'Login' | 'UserIsActive';
    } & Record<string, unknown>
) {
    // eslint-disable-next-line
    console.log({
        timestamp: Date.now(),
        info: {
            refreshToken: localStorage.getItem('refresh_token'),
            expiration: localStorage.getItem('token_expires'),
            ...extraData,
        },
    });
}
