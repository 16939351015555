import { LocalizationProvider, DatePicker as MuiDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { BaseTextFieldProps } from '@mui/material';
import { TextField } from '..';
import { FCNC } from '../FCNC';

// TODO: replace w/ mui-x DatePicker
export interface IDatePickerProps {
    id?: string;
    value: Date | null;
    onChange: (date: Date | null, keyboardInputValue?: string | undefined) => void;
    minDate?: Date;
    maxDate?: Date;
    error?: boolean;
    disablePast?: boolean;
    sx?: BaseTextFieldProps['sx'];
    testId?: string;
    label?: string;
    required?: boolean;
}

// TODO: This is not using our MUI styles, using old styles to match placement, needs update once rework document display
export const DatePicker: FCNC<IDatePickerProps> = ({
    id,
    value,
    onChange,
    minDate,
    maxDate,
    disablePast = false,
    error,
    sx,
    testId,
    label,
    required,
}) => {
    return (
        <LocalizationProvider id={id} dateAdapter={AdapterDateFns}>
            <MuiDatePicker
                value={value}
                onChange={onChange}
                minDate={minDate}
                maxDate={maxDate}
                disablePast={disablePast}
                renderInput={(params) => (
                    // @ts-ignore
                    <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        value={value?.toString()}
                        error={error}
                        sx={{
                            '.MuiOutlinedInput-root': {
                                backgroundColor: 'background.paper',
                                borderRadius: 0,
                                fontSize: 13.3,
                            },
                            ...sx,
                        }}
                        testId={testId}
                        label={label}
                        required={required}
                    />
                )}
            />
        </LocalizationProvider>
    );
};
