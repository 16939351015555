import React, { Component, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import onClickOutside from 'react-onclickoutside';
import { DateInput as DateInputWidget, DateRangeInput } from '@blueprintjs/datetime';
import './DateInput.scss';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import { ShortcutsStateContext } from '../../../../utils';
import { sub, add, differenceInMonths, differenceInCalendarDays, isEqual, format } from 'date-fns';
import { Button } from '../../../../ui';
import { Box, Stack, useTheme, useMediaQuery } from '@mui/material';

function RenderCustomNextPreviousButtonsInPopover({ children }) {
    const [newNode, setNewNode] = useState(null);

    useEffect(() => {
        let node = null;
        let targetNode = null;

        const observer = new MutationObserver(() => {
            targetNode = document.body.querySelector('.bp3-popover-content');

            // need target node to attach to
            if (!targetNode) {
                return;
            }

            // do nothing if already attached
            if (targetNode.contains(node)) {
                return;
            }

            node = document.createElement('div');
            setNewNode(node);
            targetNode.prepend(node);
        });

        observer.observe(document.body, {
            attributes: true,
            childList: true,
            subtree: true,
        });

        return () => {
            try {
                if (targetNode && node) {
                    targetNode.removeChild(node);
                }
            } catch (e) {
                /* ignore error */
            }
        };
    }, []);

    if (!newNode) {
        return null;
    }

    return createPortal(children, newNode);
}

function ButtonStack({ children }) {
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Stack direction="row">
            {!isSmDown && <Box sx={{ width: '130px' }} />}
            <Stack sx={{ px: 2, py: 1, flex: 1 }} direction="row" justifyContent="space-between">
                {children}
            </Stack>
        </Stack>
    );
}

export function shouldShowDateRangeError(startDate, endDate, containsSearchInfo) {
    const timeDiff = differenceInMonths(endDate, startDate);
    const isDateRangeGreaterThanSemester =
        timeDiff > 6 &&
        containsSearchInfo &&
        containsSearchInfo.mode === 'Contains' &&
        containsSearchInfo.criteriaFilled;

    return isDateRangeGreaterThanSemester;
}

class DateInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: props.value ? props.value : '',
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.dateWrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handlePreviousClick = this.handlePreviousClick.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
    }

    static contextType = ShortcutsStateContext;

    static get defaultProps() {
        return {
            value: [],
        };
    }

    handleDateChange(date, after) {
        if (this.props.mode === 'range') {
            const startDate = date[0] ? new Date(date[0]) : null;
            const endDate = date[1] ? new Date(date[1]) : null;
            this.setState({ inputValue: [startDate, endDate] }, after);
        } else {
            this.setState({ inputValue: new Date(date) }, after);
        }

        if (this.dateWrapperRef.current && window.innerWidth <= 775) {
            this.dateWrapperRef.current.scrollIntoView();
        }
    }

    getCurrentInputValue() {
        const inputValue = this.state.inputValue;
        return Array.isArray(inputValue) ? [inputValue[0], inputValue[1]] : inputValue;
    }

    shouldShowDateRangeError() {
        try {
            const [startDate, endDate] = this.getCurrentInputValue();
            return shouldShowDateRangeError(startDate, endDate, this.props.containsSearchInfo);
        } catch (e) {
            return false;
        }
    }

    handleClickOutside() {
        if (!this.datesEqual(this.state.inputValue, this.props.value)) {
            this.handleSelect(this.state.inputValue);
        }
    }

    datesEqual(value1, value2) {
        if (!value1 || !value2) {
            return value1 === value2;
        }

        if (!Array.isArray(value1) || !Array.isArray(value2)) {
            return value1 === value2;
        }

        return value1.every((x, i) => isEqual(x, value2[i]));
    }

    handleSelect(date, doNotFocus) {
        this.handleDateChange(date, () => {
            this.props.onChange(this.state.inputValue, doNotFocus);
        });
    }

    isDateRangeEmpty(value) {
        if (!value) {
            return true;
        }

        if (!Array.isArray(value)) {
            return true;
        }

        if (!value[0]) {
            return true;
        }

        if (!value[1]) {
            return true;
        }

        return false;
    }

    setDefaultDateRangeIfNeeded() {
        if (
            this.isDateRangeEmpty(this.props.value) &&
            this.props.containsSearchInfo &&
            this.props.containsSearchInfo.mode === 'Contains' &&
            this.props.containsSearchInfo.criteriaFilled
        ) {
            this.handleSelect([sub(new Date(), { days: 90 }), new Date()], true);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({ inputValue: nextProps.value });
        }
    }

    componentDidMount() {
        this.setDefaultDateRangeIfNeeded();
    }

    componentDidUpdate() {
        this.setDefaultDateRangeIfNeeded();
    }

    handlePreviousClick() {
        const [startDate] = this.getCurrentInputValue();
        const currentRangeSize = this.getCurrentRangeSize();
        const newEndDate = sub(startDate, { days: 1 });

        this.handleSelect([sub(newEndDate, { [currentRangeSize.type]: currentRangeSize.value }), newEndDate]);
    }

    handleNextClick() {
        const [, endDate] = this.getCurrentInputValue();
        const currentRangeSize = this.getCurrentRangeSize();
        const newStartDate = add(endDate, { days: 1 });

        this.handleSelect([newStartDate, add(newStartDate, { [currentRangeSize.type]: currentRangeSize.value })]);
    }

    getCurrentRangeSize() {
        try {
            const [startDate, endDate] = this.getCurrentInputValue();

            const months = differenceInMonths(endDate, startDate);
            const days = differenceInCalendarDays(endDate, startDate);

            if (months) {
                return { type: 'months', pluralizedType: months === 1 ? 'month' : 'months', value: months };
            }

            if (days) {
                return { type: 'days', pluralizedType: days === 1 ? 'day' : 'days', value: days };
            }

            return null;
        } catch (e) {
            return null;
        }
    }

    render() {
        const { inputValue } = this.state;
        const { mode, testId } = this.props;
        const { shouldUseCustomShortcuts } = this.context;
        const currentRangeSize = this.getCurrentRangeSize();

        return (
            <div
                data-testid={testId}
                className={`inputField dateInput ${this.props.error ? 'hasError' : ''}`}
                ref={this.dateWrapperRef}
            >
                {mode === 'range' && (
                    <DateRangeInput
                        placeholder={'M/D/YYYY'}
                        formatDate={(date) => new Date(date).toLocaleDateString()}
                        parseDate={(str) => new Date(str)}
                        contiguousCalendarMonths={false}
                        closeOnSelection={true}
                        value={this.getCurrentInputValue()}
                        onChange={(date) => this.handleDateChange(date)}
                        allowSingleDayRange={true}
                        overlappingDatesMessage={format(new Date(), 'P')}
                        outOfRangeMessage={format(new Date(), 'P')}
                        maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 5))}
                        className="dateInput-dateRange"
                        popoverProps={{
                            placement: 'top-start',
                        }}
                        shortcuts={
                            shouldUseCustomShortcuts
                                ? [
                                      {
                                          dateRange: [new Date(), new Date()],
                                          label: 'Today',
                                      },
                                      {
                                          dateRange: [
                                              new Date(new Date().setDate(new Date().getDate() - 1)),
                                              new Date(),
                                          ],
                                          label: 'Yesterday',
                                      },
                                      {
                                          dateRange: [
                                              new Date(new Date().setDate(new Date().getDate() - 7)),
                                              new Date(),
                                          ],
                                          label: 'Past week',
                                      },
                                      {
                                          dateRange: [
                                              new Date(new Date().setMonth(new Date().getMonth() - 1)),
                                              new Date(),
                                          ],
                                          label: 'Past month',
                                      },
                                      {
                                          dateRange: [
                                              new Date(new Date().setMonth(new Date().getMonth() - 3)),
                                              new Date(),
                                          ],
                                          label: 'Past 3 months',
                                      },
                                      {
                                          dateRange: [
                                              new Date(new Date().setMonth(new Date().getMonth() - 6)),
                                              new Date(),
                                          ],
                                          label: 'Past 6 months',
                                      },
                                  ]
                                : true
                        }
                    />
                )}
                {shouldUseCustomShortcuts && currentRangeSize && (
                    <RenderCustomNextPreviousButtonsInPopover>
                        <ButtonStack>
                            <Button onClick={this.handlePreviousClick}>
                                Previous {currentRangeSize.value} {currentRangeSize.pluralizedType}
                            </Button>
                            <Button onClick={this.handleNextClick}>
                                Next {currentRangeSize.value} {currentRangeSize.pluralizedType}
                            </Button>
                        </ButtonStack>
                    </RenderCustomNextPreviousButtonsInPopover>
                )}
                {shouldUseCustomShortcuts && this.shouldShowDateRangeError() && (
                    <div className="error-msg-date-range-input">{'Searches are limited to 6 months'}</div>
                )}
                {!(mode === 'range') && (
                    <div className={`dateInput-calendarWrapper ${this.props.mode}`}>
                        <DateInputWidget
                            value={new Date(inputValue)}
                            onChange={this.handleSelect}
                            maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 5))}
                        />
                    </div>
                )}
                {this.props.error && <span className="error-msg">{this.props.error}</span>}
            </div>
        );
    }
}

export default onClickOutside(DateInput);
