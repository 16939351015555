/* eslint-disable no-nested-ternary */
export const SORT_ASCENDING = 'asc';
export const SORT_DESCENDING = 'desc';

export const applySortingHelper = (state, { column, type }) => {
    const initialDirection = state.sorting && state.sorting.column === column ? state.sorting.direction : null;

    var sorting;
    switch (type) {
        case Date.name:
            if (initialDirection === SORT_DESCENDING) sorting = { column, direction: SORT_ASCENDING, type };
            else sorting = { column, direction: SORT_DESCENDING, type };
            break;

        case Number.name:
        case String.name:
        default:
            if (initialDirection === SORT_ASCENDING) sorting = { column, direction: SORT_DESCENDING, type };
            else sorting = { column, direction: SORT_ASCENDING, type };
            break;
    }

    return { ...state, sorting };
};

export const initialState = {
    documents: [],
    filteredDocuments: [],
    searchString: '',
    searchParams: {},
    isLoading: false,
    filteredColumn: '',
    sorting: {
        column: 'CreatedTime',
        direction: SORT_DESCENDING,
        type: 'Date',
    },
    pathname: '',
    activeFilter: false,
    onlyTen: false,
    performContainsSearch: false,
};

export const documentSearchInitialSortingState = {
    sorting: {
        column: 'CreatedTime',
        direction: SORT_DESCENDING,
        type: 'Date',
    },
};
