/**
 * Returns the array of options if the condition is true, otherwise empty array.
 *
 * Useful for spreading a list of options conditionally into a larger list of options
 * @param condition the condition that must be true to return the options
 * @param options the options to return
 * @returns the options if the conditions is true, otherwise empty array
 */
export function conditionalOptions<T>(condition: boolean, options: T[]): T[] {
    if (condition) {
        return options;
    }
    return [];
}

/**
 * Returns the provided properties if the condition is true, otherwise an empty object.
 *
 * Useful for spreading a list of conditional properties into a larger object.
 *
 * @param condition the condition that must be true to return the properties
 * @param properties the properties to return
 * @returns the properties if the condition is true, otherwise empty array
 */
export function conditionalProperties<T>(condition: boolean, properties: T): T | {} {
    if (condition) {
        return properties;
    }
    return {};
}
