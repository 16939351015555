import { IInternalRule, ValidationFunctionScript } from '../../types/private';

/**
 * Thrown when an error occurs inside a validation function.
 */
export default class ValidationFunctionProgrammingError extends Error {
    constructor(public readonly baseError?: any, public readonly script?: ValidationFunctionScript | IInternalRule) {
        super('A programming error occurred in a validation script that was never handled.');
        Object.setPrototypeOf(this, ValidationFunctionProgrammingError.prototype);
    }
}
