import { useState, ChangeEvent } from 'react';
import { AxiosError } from 'axios';
import { FCNC, Form, TwoActionButtons, TextField } from '../../ui';
import { EmbedHTML } from '../../utils';
import { Box, SxProps, Theme, Typography } from '@mui/material';

import { UserService } from '../../services';
import passwordLocalizedStrings from './passwordLocalizedStrings';

import { useHistory } from 'react-router-dom';
import { createFlashMessagesHistoryState } from '../../reusableFeatures';

export function PasswordRules({ sx }: { sx?: SxProps<Theme> }) {
    return (
        <EmbedHTML
            component={Box}
            className="password-rules-text"
            safeHTMLString={{
                type: 'SafeHTMLString',
                value: passwordLocalizedStrings.textPasswordRules,
            }}
            sx={[
                {
                    fontWeight: 600,
                    fontSize: '14px',
                    '& ul, ol': {
                        my: 0,
                        counterReset: 'list',
                        '& li': {
                            listStyle: 'none',
                            position: 'relative',
                            marginLeft: '45px',
                            ':before': {
                                counterIncrement: 'list',
                                content: "counter(list, decimal) ') '",
                                position: 'absolute',
                                left: '-1.4em',
                            },
                        },
                    },
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        />
    );
}

interface IChangePasswordProps {
    testId?: string;
    redirectRoutes?: Partial<{
        onSuccess: string;
        onCancel: string;
    }>;
}

const ChangePassword: FCNC<IChangePasswordProps> = ({ testId, redirectRoutes }) => {
    const userService = new UserService();
    const history = useHistory();

    const [alerts, setAlerts] = useState({
        warning: '',
        error: '',
    });
    const [formState, setFormState] = useState({
        oldPassword: '',
        newPassword: '',
        newPasswordConfirmation: '',
    });

    const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
        const field = e.target;
        switch (field.name) {
            case 'oldPassword':
                setFormState((prev) => ({ ...prev, oldPassword: field.value }));
                break;
            case 'newPassword':
                setFormState((prev) => ({ ...prev, newPassword: field.value }));
                break;
            case 'newPasswordConfirmation':
                setFormState((prev) => ({ ...prev, newPasswordConfirmation: field.value }));
                break;
            default:
                break;
        }
    };

    const handleFocusChange = () => {
        const validationResult = userService.validatePasswordChangeRequest(formState, passwordLocalizedStrings);
        setAlerts({
            warning: validationResult.isValid ? '' : validationResult.message,
            error: '',
        });
    };

    const handleSaveError = (e: string | AxiosError) => {
        if (typeof e === 'string') {
            setAlerts({
                warning: '',
                error: e,
            });
        } else if (e instanceof Error) {
            const candidateErrorMessage = (e as AxiosError).response?.data?.errors?.[0]?.Message;
            setAlerts({
                warning: '',
                error:
                    candidateErrorMessage === 'Incorrect password.'
                        ? passwordLocalizedStrings.textBadOldPassword
                        : candidateErrorMessage ?? e.message,
            });
        }
    };

    const handleSuccess = () => {
        if (redirectRoutes?.onSuccess) {
            history.push(
                redirectRoutes.onSuccess,
                createFlashMessagesHistoryState([
                    {
                        messageKey: 'settingsPageFlashMessage',
                        text: 'Password Changed Successfully',
                        alertSeverity: 'success',
                    },
                ])
            );
        }
    };

    const handleSave = async () => {
        try {
            await userService.changePassword(formState, passwordLocalizedStrings);
            handleSuccess();
        } catch (e) {
            handleSaveError(e as Parameters<typeof handleSaveError>[0]);
        }
    };

    const handleCancel = () => {
        if (redirectRoutes?.onCancel) {
            history.push(redirectRoutes.onCancel);
        }
    };

    const formButtons = (
        <TwoActionButtons
            testId={testId}
            submitButton={{
                text: passwordLocalizedStrings.textSave,
            }}
            cancelButton={{
                text: passwordLocalizedStrings.textCancel,
                onClick: handleCancel,
            }}
        />
    );

    return (
        <Box>
            <Typography component="h2" variant="h4" sx={{ py: 2 }}>
                Change Password
            </Typography>
            <PasswordRules />
            <Form
                testId={`${testId ?? 'default'}-change-password-form`}
                formButtons={formButtons}
                onSubmit={handleSave}
                warningMessage={alerts.warning}
                errorMessage={alerts.error}
                columns={[
                    <>
                        <TextField
                            label={passwordLocalizedStrings.textOldPassword}
                            type="password"
                            testId="oldPassword"
                            id="old-password"
                            name="oldPassword"
                            value={formState.oldPassword}
                            onChange={handleFieldChange}
                            onBlur={handleFocusChange}
                            required={true}
                        />
                        <TextField
                            label={passwordLocalizedStrings.textNewPassword}
                            type="password"
                            testId="newPassword"
                            id="new-password"
                            name="newPassword"
                            value={formState.newPassword}
                            onChange={handleFieldChange}
                            onBlur={handleFocusChange}
                            required={true}
                        />
                        <TextField
                            label={passwordLocalizedStrings.textConfirmPassword}
                            type="password"
                            testId="newPasswordConfirmation"
                            id="new-password-confirmation"
                            name="newPasswordConfirmation"
                            value={formState.newPasswordConfirmation}
                            onChange={handleFieldChange}
                            onBlur={handleFocusChange}
                            required={true}
                        />
                    </>,
                ]}
            />
        </Box>
    );
};

export default ChangePassword;
