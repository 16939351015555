import { DotNotationFieldRendererViewModel, FieldRendererOnChange, renderFormColumns } from '../../FieldRenderer';
import { ICRUDFormLayout } from '../types';

/**
 * Renders a data-driven layout config
 * @param layoutConfig the layout config object
 * @param viewModel the view model to use to bind the fields to the data
 * @param onChange function to update the view model
 * @returns the rendered form field columns
 */
export function renderLayoutConfig<U extends object>(
    layoutConfig: ICRUDFormLayout<U>,
    viewModel: DotNotationFieldRendererViewModel<U>,
    onChange: FieldRendererOnChange<DotNotationFieldRendererViewModel<U>>
) {
    const columns = (() => {
        if (typeof layoutConfig.columns === 'function') {
            return layoutConfig.columns(viewModel);
        }

        return layoutConfig.columns;
    })();

    return renderFormColumns({
        columns,
        viewModel,
        onChange,
    });
}
