import { BusinessDocType } from '../../../types';
import { createPluginServices } from '../PluginHost';
import DropdownOptionsRepository from '../PluginHost/services/dropdownOptionsRepository';
import { ValidationFailureDictionary } from '../types/private';
import { createValidationFailureTypesFilter } from './fetchValidationFailureTypes.filter';

interface IAPIValidationFailureType {
    ErrorCode: string;

    TitleText: string;

    DetailText: string | null;
}

const pluginServices = createPluginServices('', new DropdownOptionsRepository());

export async function fetchValidationFailureTypes(buyerCompanyId: number, businessDocType: BusinessDocType) {
    const response = await pluginServices.api().get<IAPIValidationFailureType[]>('/ValidationFailureType', {
        params: {
            buyerCompanyId,
            $filter: createValidationFailureTypesFilter(businessDocType),
        },
    });

    const failureTypeDictionary: ValidationFailureDictionary = {};

    response.data.forEach((v) => {
        failureTypeDictionary[v.ErrorCode] = {
            errorCode: v.ErrorCode,
            titleText: v.TitleText,
            detailText: v.DetailText,
        };
    });

    return failureTypeDictionary;
}
