import { createUseCRUDService, ICRUDService } from '../../../reusableFeatures';
import { IProfile, IProfileDetailForm, portalUserService, ProfileService, ProfileType } from '../../../services';

export class ProfileCRUDService implements ICRUDService<number, IProfileDetailForm> {
    private service = new ProfileService();

    public companyId: number;

    constructor(private readonly profileType: ProfileType) {
        this.companyId = portalUserService.getCurrentCompanyId();
    }

    get defaultState(): IProfileDetailForm {
        return {
            address1: '',
            address2: '',
            address3: '',
            apeMail: '',
            city: '',
            companyId: this.companyId,
            country: '',
            companyVatRegistrationNumber: '',
            countryOfEstablishment: '',
            email: '',
            fax: '',
            invoiceConfirmationEmail: '',
            invoiceProfileType: null,
            logoPath: '',
            name: '',
            phone: '',
            postalCode: '',
            profileDeliveryMethods: [],
            profileExtendedDatas: [],
            profileType: null,
            remittanceAdviceEmail: '',
            remitToAddressBlock: '',
            ruleStatus: null,
            scanKeyProfileId: null,
            signature: '',
            signVATInvoices: false,
            state: '',
            vatComplianceRequired: false,
            vatNumber: '',
            vatRegistrationNumber: '',
        };
    }

    get key(): string {
        return 'Profile';
    }

    get invalidateKeys(): string[] {
        return ['ProfileSearch'];
    }

    async fetchEntity(id: number): Promise<IProfile> {
        return (await this.service.getProfile(id, this.profileType))!;
    }

    async createEntity(): Promise<number> {
        throw new Error('Method Not Implemented yet');
    }

    async updateEntity(): Promise<void> {
        throw new Error('Method Not Implemented yet');
    }
}

export const useProfileCRUDService = createUseCRUDService(ProfileCRUDService);
