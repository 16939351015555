import Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';
import { useRef, useEffect } from 'react';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import { HighchartsReactRefObject } from 'highcharts-react-official';

import { Box } from '@mui/material';
import { SystemCssProperties } from '@mui/system';

import './Chart.scss';

NoDataToDisplay(Highcharts);

Highcharts.setOptions({
    chart: {
        styledMode: true,
    },
    credits: {
        enabled: false,
    },
    lang: {
        noData: 'NO DATA TO DISPLAY',
    },
});

export type ChartStyleProps = {
    colors: SystemCssProperties['color'][];
    fillColors?: SystemCssProperties['color'][];
};

type ChartProps = {
    options: Highcharts.Options;
    isLoading: boolean;
} & ChartStyleProps;

function getColorStyles(colors: SystemCssProperties['color'][], fillColors?: SystemCssProperties['color'][]) {
    const styles = colors.reduce<Record<string, any>>((style, color, idx) => {
        style[`.highcharts-color-${idx}`] = {
            fill: fillColors?.[idx] || color,
            stroke: color,
        };

        return style;
    }, {});

    return styles;
}

export function Chart(props: ChartProps) {
    // * disabling dark mode until it's fixed throughout the portal
    // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const prefersDarkMode = false;

    const chartComponentRef = useRef<HighchartsReactRefObject>(null);
    useEffect(() => {
        if (props.isLoading) {
            chartComponentRef.current?.chart.showLoading();
        } else {
            chartComponentRef.current?.chart.hideLoading();
            chartComponentRef.current?.chart.redraw();
        }
    }, [props.isLoading]);

    const { colors, fillColors } = props;

    return (
        <Box
            sx={{
                width: '100%',
                '.highcharts-subtitle': { width: '100%' },
                '.highcharts-title': { width: '100%' },
                '.highcharts-loading': {
                    background: 'silver',
                },
                '.highcharts-loading-inner': {
                    color: 'black',
                },
                '.highcharts-area': {
                    'fill-opacity': '1',
                },
                '.highcharts-no-data text': {
                    'font-weight': '400',
                    'font-size': '18px',
                },
                ...getColorStyles(colors, fillColors),
            }}
        >
            <HighchartsReact
                containerProps={{
                    className: `highcharts-${prefersDarkMode ? 'dark' : 'light'}`,
                }}
                highcharts={Highcharts}
                options={props.options}
                // @ts-ignore
                ref={chartComponentRef}
            />
        </Box>
    );
}
