enum ControlType {
    SingleLine = 1,
    MultiLine = 2,
    Date = 3,
    Email = 4,
    Phone = 5,
    Money = 6,
    Quantity = 7,
    UnitPrice = 8,
    PaymentTerms = 9,
    Currency = 10,
    InvoiceProfile = 11,
    MoneyLabel = 12,
    Tax = 13,
    MiscChargesHolder = 14,
    UnitAmountAndAmount = 15,
    RadioButton = 16,
    CustomDropdown = 17,
    Percentage = 18,
    GLCodingDotNotation = 19,
    GLCodingInline = 20,
}

export default ControlType;
