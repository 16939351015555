import { ISearchConfiguration } from '../types';
import { IField, SimpleFieldRendererViewModel } from '../../FieldRenderer';
import { getSearchFormFieldColumns } from '../configUtils';

export function shouldPerformSearchForCriteria(
    searchConfiguration: ISearchConfiguration<any> | undefined,
    searchQuery: SimpleFieldRendererViewModel<any>
) {
    if (!searchConfiguration) {
        return false;
    }

    if (searchConfiguration.searchWithNoCriteria ?? true) {
        return true;
    }

    const getFieldValue = (field: IField) => {
        const value = searchQuery.getValue(field);

        // if user typed just space into a text field, we want to consider it empty
        if (field.type === 'ITextField') {
            return (value as string).trim();
        }

        return value;
    };

    const filledFields: string[] = [];
    getSearchFormFieldColumns(searchConfiguration, searchQuery)?.forEach((column) => {
        column.forEach((field) => {
            if (JSON.stringify(getFieldValue(field)) !== JSON.stringify(field.defaultValue)) {
                filledFields.push(field.dataBindingKey);
            }
        });
    });

    if (searchConfiguration.isCriteriaEmpty) {
        return !searchConfiguration.isCriteriaEmpty(searchQuery, filledFields);
    }

    return filledFields.length > 0;
}
