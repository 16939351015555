import { useQuery } from '@tanstack/react-query';
import { backendServices } from '../../services';
import * as React from 'react';
import { AuditLogAPIViewModel, AuditLogUIViewModel } from '../../services/backendServices/ViewModels';

interface IAuditMapDbFieldsToFormLabels {
    DBKey: string;
    DBKeyMapping: string;
    DBIDMappings?: {
        ID: string;
        IDMapping: string;
    }[];
    IsList?: boolean;
}

interface IUseAuditLog {
    entityType?: string;
    entityPrimaryKey: number;
    mapDbFieldsToFormLabels: IAuditMapDbFieldsToFormLabels[];
    enabled: boolean;
}

export function useAuditLog({ entityType, entityPrimaryKey, mapDbFieldsToFormLabels, enabled }: IUseAuditLog) {
    // transforms single log from API to UI format
    // returns the log if it has any of the keys in mappingsKeys and filters out the rest
    // returned log will include an array of old and new values. Non mapped keys will be removed
    // assumptions about the data agreed with the backend team:
    // 1. EntityData can either be empty or a json object with 1 and only 1 json object inside which is the EntityType
    // 2. EntityData[EntityType] if it exists can have multiple json objects inside. each object will mandatorily contain OldValue and NewValue as number | string | null | boolean
    const filterNonMappableChanges = React.useCallback(
        (log: AuditLogAPIViewModel) => {
            if (!log.EntityData) {
                return undefined;
            }

            const logDBkeys = Object.keys(log.EntityData[log.EntityType]);
            let found = false;

            const logToReturn: AuditLogUIViewModel = {
                UserName: log.UserName,
                GroupId: log.GroupId,
                CreatedTime: log.CreatedTime,
                EntityData: {},
            };

            logDBkeys.forEach((logDBkey) => {
                const logDbKeyEntity = log.EntityData[log.EntityType][logDBkey];

                const mappedDBKeyIdx = mapDbFieldsToFormLabels.findIndex((DBMapping) => DBMapping.DBKey === logDBkey);
                if (mappedDBKeyIdx !== -1) {
                    const mappedDBIDMappingOldValueIdx = mapDbFieldsToFormLabels[
                        mappedDBKeyIdx
                    ].DBIDMappings?.findIndex((DBIDMapping) => DBIDMapping.ID === String(logDbKeyEntity.OldValue));
                    const mappedDBIDMappingNewValueIdx = mapDbFieldsToFormLabels[
                        mappedDBKeyIdx
                    ].DBIDMappings?.findIndex((DBIDMapping) => DBIDMapping.ID === String(logDbKeyEntity.NewValue));
                    const isList = !!mapDbFieldsToFormLabels[mappedDBKeyIdx].IsList;

                    logToReturn.EntityData = {
                        ...logToReturn.EntityData,
                        [mapDbFieldsToFormLabels[mappedDBKeyIdx].DBKeyMapping]: {
                            OldValue:
                                mappedDBIDMappingOldValueIdx != undefined && mappedDBIDMappingOldValueIdx !== -1
                                    ? [
                                          mapDbFieldsToFormLabels[mappedDBKeyIdx].DBIDMappings![
                                              mappedDBIDMappingOldValueIdx
                                          ].IDMapping,
                                      ]
                                    : [String(logDbKeyEntity.OldValue)],
                            NewValue:
                                mappedDBIDMappingNewValueIdx !== undefined && mappedDBIDMappingNewValueIdx !== -1
                                    ? [
                                          mapDbFieldsToFormLabels[mappedDBKeyIdx].DBIDMappings![
                                              mappedDBIDMappingNewValueIdx
                                          ].IDMapping,
                                      ]
                                    : [String(logDbKeyEntity.NewValue)],
                            isList: isList,
                        },
                    };
                    found = true;
                }
            });
            return found ? logToReturn : undefined;
        },
        [mapDbFieldsToFormLabels]
    );

    const transformAPIToUIData = React.useCallback(
        (logData: AuditLogAPIViewModel[]) => {
            const auditLogGroupMap = new Map<string, AuditLogUIViewModel>();
            logData.forEach((log: AuditLogAPIViewModel) => {
                // filter out the keys that are not in mapDbFieldsToFormLabels
                const filteredLog = filterNonMappableChanges(log);

                if (filteredLog) {
                    // group by GroupId
                    const existingGroupMapEntry = auditLogGroupMap.get(filteredLog.GroupId);
                    if (!existingGroupMapEntry) {
                        auditLogGroupMap.set(filteredLog.GroupId, filteredLog);
                    } else {
                        // get all object keys and concatenate by those keys
                        const DBkeys = Object.keys(filteredLog.EntityData);
                        for (let i = 0; i < DBkeys.length; i++) {
                            const DBkey = DBkeys[i];

                            if (!existingGroupMapEntry.EntityData.hasOwnProperty(DBkey)) {
                                existingGroupMapEntry.EntityData = {
                                    ...existingGroupMapEntry.EntityData,
                                    [DBkey]: filteredLog.EntityData[DBkey],
                                };
                            } else {
                                existingGroupMapEntry.EntityData[DBkey].OldValue.push(
                                    String(filteredLog.EntityData[DBkey].OldValue)
                                );
                                existingGroupMapEntry.EntityData[DBkey].NewValue.push(
                                    String(filteredLog.EntityData[DBkey].NewValue)
                                );
                            }
                        }
                        auditLogGroupMap.set(filteredLog.GroupId, existingGroupMapEntry!);
                    }
                }
            });

            // revisit all entries and remove array duplicates and empty values. Remove entries that have the same old and new values
            auditLogGroupMap.forEach((group) => {
                const DBkeys = Object.keys(group.EntityData);
                for (let i = 0; i < DBkeys.length; i++) {
                    const DBkey = DBkeys[i];
                    const oldValuesSet = new Set(group.EntityData[DBkey].OldValue);
                    const newValuesSet = new Set(group.EntityData[DBkey].NewValue);

                    group.EntityData[DBkey].OldValue = [...oldValuesSet].filter(
                        (value) => value !== '' && value !== null && value !== 'null'
                    );
                    group.EntityData[DBkey].NewValue = [...newValuesSet].filter(
                        (value) => value !== '' && value !== null && value !== 'null'
                    );

                    // remove duplicates from both arrays
                    for (let j = 0; j < group.EntityData[DBkey].OldValue.length; j++) {
                        const valueToFind = group.EntityData[DBkey].OldValue[j];
                        if (group.EntityData[DBkey].NewValue.includes(valueToFind)) {
                            group.EntityData[DBkey].OldValue.splice(
                                group.EntityData[DBkey].OldValue.indexOf(valueToFind),
                                1
                            );
                            group.EntityData[DBkey].NewValue.splice(
                                group.EntityData[DBkey].NewValue.indexOf(valueToFind),
                                1
                            );
                            j--;
                        }
                    }

                    // remove entire entry if old and new values are the same
                    if (group.EntityData[DBkey].OldValue.toString() === group.EntityData[DBkey].NewValue.toString()) {
                        delete group.EntityData[DBkey];
                    }
                }
            });

            // final cleanup, remove entries that have no changes
            auditLogGroupMap.forEach((group) => {
                if (Object.keys(group.EntityData).length === 0) {
                    auditLogGroupMap.delete(group.GroupId);
                }
            });

            return auditLogGroupMap;
        },
        [filterNonMappableChanges]
    );

    const { data, isLoading: auditLogIsLoading } = useQuery(
        ['AuditLog', entityType, entityPrimaryKey],
        async () => {
            const auditLogApi = new backendServices.Apis.AudiLogApi();
            const res = await auditLogApi.getAuditLog({ entityType, entityPrimaryKey });
            return res.data;
        },
        { enabled: enabled }
    );

    const auditLogData = React.useMemo(() => (data ? transformAPIToUIData(data) : null), [data, transformAPIToUIData]);

    return {
        auditLogData,
        auditLogIsLoading,
    };
}
