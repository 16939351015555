import { FocusEventHandler, useMemo } from 'react';
import { FCNC } from '../FCNC';
import { BaseTextFieldProps, InputLabelProps } from '@mui/material';
import { AnyMaskedTextField } from './AnyMaskedTextField';
import { ITooltipIconProps } from '../Tooltip';
import { useIsNumericFieldRightAligned } from './NumericFieldTextRightAlignmentContext';

export interface IDisplayOptions {
    symbol?: string;
    symbolPlacement?: 'left' | 'right';
    thousandsSeparator: string;
    decimalSeparator: string;
}

function toNumber(value: string) {
    if (value.length === 0) {
        return null;
    }

    const num = Number.parseFloat(value);

    if (Number.isNaN(num)) {
        return null;
    }

    return num;
}

function createMask(localeOptions: IDisplayOptions) {
    if (localeOptions.symbol && localeOptions.symbolPlacement) {
        return localeOptions.symbolPlacement === 'left' ? `${localeOptions.symbol} num` : `num ${localeOptions.symbol}`;
    }

    return 'num';
}

export interface INumericMaskedTextFieldProps {
    id?: string;

    localeOptions: IDisplayOptions;

    value: number | null;

    scale: number;

    maxValue?: number;

    onChange: (value: number | null) => void;

    label?: string;

    required?: boolean;

    sx?: BaseTextFieldProps['sx'];

    readonly?: boolean;

    onDoubleClick?: BaseTextFieldProps['onDoubleClick'];

    padFractionalZeros?: boolean;

    testId?: string;

    tooltip?: ITooltipIconProps;

    inputLabelProps?: InputLabelProps;

    disabled?: boolean;

    signed?: boolean;

    onFocus?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;

    placeholder?: string;

    stacked?: boolean;

    textFieldLabel?: string;

    omitCommas?: boolean;
}

export const NumericMaskedTextField: FCNC<INumericMaskedTextFieldProps> = ({
    stacked,
    id,
    maxValue,
    localeOptions,
    onChange,
    value,
    required,
    sx,
    readonly,
    label,
    onDoubleClick,
    testId,
    scale,
    tooltip,
    padFractionalZeros = true,
    inputLabelProps,
    disabled,
    signed,
    onFocus,
    placeholder,
    textFieldLabel,
    omitCommas = false,
}) => {
    const maskOptions = useMemo(
        () => ({
            mask: createMask(localeOptions),
            lazy: false,
            blocks: {
                num: {
                    mask: Number,
                    thousandsSeparator: omitCommas ? '' : localeOptions.thousandsSeparator,
                    scale,
                    radix: localeOptions.decimalSeparator,
                    max: maxValue,
                    padFractionalZeros,
                    signed,
                },
            },
        }),
        [localeOptions, scale, maxValue, padFractionalZeros, signed, omitCommas]
    );

    const textAlign = useIsNumericFieldRightAligned() ? 'right' : 'left';

    return (
        <AnyMaskedTextField
            stacked={stacked}
            id={id}
            value={value === null ? '' : value.toString()}
            onChange={(x) => onChange(toNumber(x))}
            maskOptions={maskOptions}
            required={required}
            sx={{ ...sx, '.MuiOutlinedInput-input, .ReadOnly-value': { textAlign } }}
            readonly={readonly}
            label={label}
            onDoubleClick={onDoubleClick}
            testId={testId}
            tooltip={tooltip}
            inputLabelProps={inputLabelProps}
            disabled={disabled}
            onFocus={onFocus}
            placeholder={placeholder}
            textFieldLabel={textFieldLabel}
        />
    );
};
