/* eslint-disable react/no-array-index-key */
import { FCNC } from '../FCNC';
import { Form, IFormProps } from './Form';
import { TextField } from '../TextField';
import { range } from '../../utils';

export interface ILoadingFormProps {
    /**
     * Test id for the form to support automated testing tools
     */
    testId: IFormProps['testId'];

    /**
     * Form buttons to render
     */
    formButtons: IFormProps['formButtons'];

    /**
     * Loading message to display (tell the user why the form is not being
     * displayed yet).
     */
    loadingMessage: IFormProps['loadingMessage'];

    /**
     * How many [rows, columns] of fake fields to display (user cannot interact with these,
     * but they exist to show the user "progress")
     */
    fakeFieldSpec: [number, number];
}

/**
 * Renders a "placeholder loading form" when you don't yet know what fields to render
 * in a form. This should be used when the fields you render to a form are driven by data
 * loaded from a Service.
 */
export const LoadingForm: FCNC<ILoadingFormProps> = ({
    testId,
    formButtons,
    loadingMessage,
    fakeFieldSpec: [rows, columns],
}) => {
    const fakeColumns = range(rows).map(() => (
        <>
            {range(columns).map((_, n) => (
                <TextField key={n} />
            ))}
        </>
    ));

    return <Form testId={testId} columns={fakeColumns} loadingMessage={loadingMessage} formButtons={formButtons} />;
};
