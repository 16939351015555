import { useState, useEffect } from 'react';
import crashSimulator from './crashSimulator';

export interface ICrashTestFlagProps {
    /**
     * Name of the target error boundary for crashing.
     */
    targetErrorBoundary: string;
}

/**
 * Crashes the component tree it is in if requested by the user via the
 * `transceptaCrashSimulator.simulateCrash` method.
 *
 * This is useful to regression test error boundaries when
 * we don't currently have a known use-case that will trigger an error under them.
 *
 * See `CrashTestFlagProps` for further documentation.
 */
const CrashTestFlag = ({ targetErrorBoundary }: ICrashTestFlagProps) => {
    const [crash, setCrash] = useState(false);
    const [crashTime, setCrashTime] = useState(0);

    useEffect(() => {
        let id: NodeJS.Timeout | null = null;

        const cleanupSubscription = crashSimulator.onSimulateCrash(targetErrorBoundary, (timeout) => {
            if (id !== null) {
                clearTimeout(id);
            }

            id = setTimeout(() => {
                setCrashTime(timeout);
                setCrash(true);
            }, timeout * 1000);
        });

        return () => {
            cleanupSubscription();
            if (id !== null) {
                clearTimeout(id);
            }
        };
    }, [targetErrorBoundary]);

    if (crash) {
        throw new Error(`Simulated Crash after ${crashTime} seconds.`);
    }

    return null;
};

export default CrashTestFlag;
