import { useMemo } from 'react';
import { ISearchService } from '../types';

export type SearchServiceConstructor = new (...args: any[]) => ISearchService<any>;

/**
 * Creates a hook to use a search service on a SearchPage
 * @param constructor Constructor for the search service object
 * @returns the search service
 */
export function createUseSearchService<T extends SearchServiceConstructor>(constructor: T) {
    return (...args: ConstructorParameters<T>) => {
        // This is fine, we know these are the only dependencies our factory has
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return useMemo(() => new constructor(...args), args) as InstanceType<T>;
    };
}
