import { AxiosResponse } from 'axios';
import { RecursivePartial } from '../../../types';
import { api } from '../../api';
import { PageResult } from '../../backendServices/Utilities';
import { CreateUpdateDeleteViewModel } from '../../backendServices/ViewModels';
import { IGetUsersParams } from '../Services';
import { APIPermissionViewModel, IUserViewModel } from './ViewModels';

export class UserApi {
    private route = '/User';

    private routeForRoles = '/RoleType';

    async getUser(userId: number): Promise<AxiosResponse<IUserViewModel[]>> {
        return api().get(`${this.route}/${userId}`);
    }

    async getPermissionsForUser(userId: number): Promise<AxiosResponse<APIPermissionViewModel[]>> {
        return api().get(`${this.routeForRoles}/Permission`, {
            params: {
                userId,
            },
        });
    }

    public getUsers = async (params?: IGetUsersParams): Promise<AxiosResponse<PageResult<IUserViewModel>>> => {
        return api().get(`${this.route}`, { params });
    };

    public async changePassword(body: { OldPassword: string; NewPassword: string }): Promise<AxiosResponse> {
        return api().post(`${this.route}/ChangePassword`, body);
    }

    public async createUser(
        body: RecursivePartial<IUserViewModel>
    ): Promise<AxiosResponse<CreateUpdateDeleteViewModel>> {
        return api().post(`${this.route}`, body);
    }

    public async updateUser(
        id: number,
        body: RecursivePartial<IUserViewModel>
    ): Promise<AxiosResponse<CreateUpdateDeleteViewModel>> {
        return api().put(`${this.route}/${id}`, body);
    }

    public async deleteUser(id: number): Promise<AxiosResponse<CreateUpdateDeleteViewModel>> {
        return api().delete(`${this.route}/${id}`);
    }
}
