export function determineDefaultVATNumber(profileVATArchiveService, companyVATArchiveService) {
    if (
        profileVATArchiveService.VATComplianceRequired === true &&
        companyVATArchiveService.VATComplianceRequired === false
    )
        return profileVATArchiveService.VATNumber;
    else if (
        profileVATArchiveService.VATComplianceRequired === false &&
        companyVATArchiveService.VATComplianceRequired === true
    )
        return companyVATArchiveService.VATNumber;
    else if (
        profileVATArchiveService.VATComplianceRequired === true &&
        profileVATArchiveService.VATNumber &&
        profileVATArchiveService.VATNumber !== '0'
    )
        return profileVATArchiveService.VATNumber;
    else if (companyVATArchiveService.VATNumber && companyVATArchiveService.VATNumber !== '0')
        return companyVATArchiveService.VATNumber;
    else if (!profileVATArchiveService.VATNumber && !companyVATArchiveService.VATNumber) return '';
    else if (
        (!profileVATArchiveService.VATNumber || profileVATArchiveService.VATNumber === '0') &&
        (!companyVATArchiveService.VATNumber || companyVATArchiveService.VATNumber === '0')
    )
        return '0';
    else return profileVATArchiveService.VATNumber;
}
