import { AxiosInstance } from 'axios';
import { requestToKey } from './utils';
import { isDepdupOnForever, scheduleCleanup } from './dedupWhileRunning';

class DeferredResponse {
    public resolve: any;

    public reject: any;

    public readonly promise: Promise<any>;

    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }
}

type DedupCache = { [key: string]: DeferredResponse | undefined };

class DedupGetRequestSignal extends Error {
    constructor(readonly result: DeferredResponse) {
        super('skip request');

        Object.setPrototypeOf(this, DedupGetRequestSignal.prototype);
    }
}

function dedupGetRequests(instance: AxiosInstance, cache: DedupCache) {
    instance.interceptors.request.use((request) => {
        // only de-dup get requests
        if (request.method !== 'get' && request.method !== 'GET') {
            return request;
        }

        const requestKey = requestToKey(request);

        // if there is a request in flight, do not make another request
        const result = cache[requestKey];
        if (result) {
            throw new DedupGetRequestSignal(result);
        }

        // store a DeferredResponse in the cache for all duplicate requests to use
        cache[requestKey] = new DeferredResponse();
        return request;
    });

    instance.interceptors.response.use(
        (response) => {
            // handle resolving the deferred response that exists
            const requestKey = requestToKey(response.config);
            const defferredResponse = cache[requestKey];

            const cleanup = () => {
                delete cache[requestKey];
            };

            if (defferredResponse) {
                defferredResponse.resolve(response);

                // cleanup once dedup is no longer on forever
                if (!isDepdupOnForever()) {
                    cleanup();
                } else {
                    scheduleCleanup(cleanup);
                }
            }

            return response;
        },
        (error) => {
            // request was skipped due to dedup, return the promise for response
            // to original request
            if (error instanceof DedupGetRequestSignal) {
                return error.result.promise;
            }

            // handle rejecting the deferred response that exists
            if (error.isAxiosError) {
                const requestKey = requestToKey(error.config);
                const deferredResponse = cache[requestKey];
                if (deferredResponse) {
                    deferredResponse.reject(error);
                    delete cache[requestKey];
                }
            }

            return Promise.reject(error);
        }
    );
}

export class DedupGetRequests {
    private cache: DedupCache = {};

    /**
     * De-duplicates equivalent requests, so that only one instance of an equivalent request
     * can be in-flight at the same time.
     *
     * @param instance the axios instance to add de-duping to
     */
    dedupGetRequests(instance: AxiosInstance) {
        dedupGetRequests(instance, this.cache);
    }
}
