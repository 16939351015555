import { ReactElement } from 'react';
import { Tooltip } from '../Tooltip';

export interface ITaxFieldCalculatedFieldTooltipProps {
    isCalculated: boolean;
    fieldName: string;
    otherFieldName: string;
    readonly: boolean;
    children: ReactElement<any, any>;
}

function createMessage(fieldName: string, otherFieldName: string) {
    return `"${fieldName}" is currently being auto-calculated based on the value entered for "${otherFieldName}". You can choose to override the calculated "${fieldName}" by entering a different value here. You can also double click this field to auto-calculate "${otherFieldName}" based on "${fieldName}".`;
}

export const TaxFieldCalculatedFieldTooltip = ({
    fieldName,
    otherFieldName,
    isCalculated,
    children,
    readonly,
}: ITaxFieldCalculatedFieldTooltipProps) => {
    return (
        <Tooltip title={!readonly && isCalculated ? createMessage(fieldName, otherFieldName) : ''}>{children}</Tooltip>
    );
};
