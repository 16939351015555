import BigNumber from 'bignumber.js';
import jsonpath from 'jsonpath';
import { path } from 'ramda';
import { push } from 'connected-react-router';
import { addDays, format, isAfter, isBefore } from 'date-fns';
import { IsNotUndefinedNullOrEmpty, IsUndefinedNullOrEmpty } from 'common/build/legacy/transcepta-common';
import * as TranscpetaThunks from 'common/build/legacy/transcepta-thunks';
import projectConfig from '../project.config.json';
import R from '../routes';
// Actions
import { pushAlert } from '../actions/alerts';
import * as CreateInvoiceActions from '../actions/CreateInvoiceActions';
import * as CustomerActions from '../actions/CustomerActions';
import * as documentCreationActions from '../actions/document/documentCreationActions';
import * as documentActions from '../actions/document/documentActions';
import { apiErrorAction } from '../actions/error';
import { changeFixedMiscAmountCount } from '../actions/invoiceCreation/fixedMiscAmountCount';
import * as LayoutActions from '../actions/invoiceCreation/layout';
// Models
import * as InvoiceModels from '../models/InvoiceModels';
// Selectors
import miscChargesInfoSelector from '../selectors/document/miscChargesInfoSelector';
import currencyListSelector from '../selectors/CurrencyList';
import defaultVATNumberSelector from '../selectors/document/defaultVATNumberSelector';
import layoutSelector from '../selectors/document/layoutSelector';
// Utils
import { parseMessage } from '../utils';
import { CreateAddressBlock } from '../utils/address';
import { cloneObjectHack } from '../utils/dataConverter';
import { saveableDate } from '../utils/dateFormat';
import * as DocumentUtility from '../utils/document/document';
import { ParseByteFileSize } from '../utils/files';
import { getFieldLayoutRecord, getFieldLayoutRecordByFieldPurpose } from '../utils/document/layout';
import * as NumericUtility from '../utils/numeric';

// Thunks
import * as CompanyThunk from './CompanyThunk';
import * as DocumentThunk from './DocumentThunk';
import { fetchProfiles } from './ProfilesThunk';
import * as SIMDocumentTypeThunk from './SIMDocumentTypesThunk';
import {
    BusinessDocType,
    isFeatureEnabled,
    legacyErrorHandler,
    portalUserService,
    resetOriginalDocument,
} from 'common';

const performFieldCountingInvoice = () => (dispatch, getState) => {
    // console.log('executing performFieldCountingInvoice');
    let state = getState();
    let invoice = state.documentCreation.invoiceCreationFields.BusinessDocFields.Invoice;

    dispatch(
        CreateInvoiceActions.performChangeInvoiceField(
            'DiscountableAmount',
            invoice.InvoiceLineItems.reduce((subtotal, item) => {
                return subtotal + item.LineItemNetTotal;
            }, 0)
        )
    );

    state = getState();
    invoice = state.documentCreation.invoiceCreationFields.BusinessDocFields.Invoice;
    dispatch(
        calculateInvoiceSalesTaxAmount(null, Number(invoice.DiscountableAmount), Number(invoice.SalesTaxPercent), true)
    );

    state = getState();
    invoice = state.documentCreation.invoiceCreationFields.BusinessDocFields.Invoice;
    dispatch(
        CreateInvoiceActions.performChangeInvoiceField(
            'InvoiceAmount',
            Number(invoice.DiscountableAmount) - Number(invoice.ProductDiscountAmount)
        )
    );

    state = getState();
    invoice = state.documentCreation.invoiceCreationFields.BusinessDocFields.Invoice;
    dispatch(
        CreateInvoiceActions.performChangeInvoiceField(
            'AmountDue',
            Number(invoice.InvoiceAmount) - Number(invoice.AmountPaid)
        )
    );

    dispatch(calculateInvoiceTotal());
    dispatch(calculateInvoiceAmountDue());
};

const numericFields = [
    projectConfig.ControlType.Money,
    projectConfig.ControlType.Quantity,
    projectConfig.ControlType.UnitPrice,
    projectConfig.ControlType.Tax,
    projectConfig.ControlType.UnitAmountAndAmount,
];

export const performDocumentFieldValueUpdate =
    (fieldLayout, fieldName, newValue, index = null, updateAllInstances = false) =>
    (dispatch, getState) => {
        if (newValue && typeof newValue === 'string') {
            newValue = newValue.trim();
        }
        // console.log('executing performDocumentFieldValueUpdate - ' + fieldName + ' = ' + newValue + ' on ' + fieldLayout.businessDocType);
        dispatch(documentCreationActions.documentCreationUpdatingFieldsBegin());
        dispatch(updateDocumentCreationStatusAffectedByFieldValueUpdate(fieldLayout, newValue));
        const state = getState();
        const layout = layoutSelector(state, true, fieldLayout.BusinessDocType);

        let oldMiscRate = null;
        let oldLineMiscRate = null;
        let oldLineMiscUnitPrice = null;
        let oldQuantity = null;

        switch (fieldLayout.BusinessDocType) {
            case projectConfig.businessDocType.SIMDocument:
            case projectConfig.businessDocType.PurchaseOrderAcknowledgement:
            case projectConfig.businessDocType.ASNGeneric:
                const businessDocument = cloneObjectHack(
                    state.documentCreation.documentCreationFields.BusinessDocFields.BusinessDocument
                );
                const fullSkeleton = cloneObjectHack(state.documentCreation.documentSkeleton.fullSkeleton);

                let updateLineItemSkeleton = false;
                switch (fieldLayout.section) {
                    case projectConfig.fieldDictionarySection.Body:
                        let uniqueSectionNodes = jsonpath.nodes(businessDocument, `$${fieldLayout.sectionJsonPath}`)[0]
                            .value;
                        uniqueSectionNodes = !uniqueSectionNodes.length ? [uniqueSectionNodes] : uniqueSectionNodes;
                        let startingSectionIndex = 0;
                        let endingSectionIndex = 1;
                        if (index) {
                            startingSectionIndex = index;
                            endingSectionIndex = index + 1;
                        } else if (updateAllInstances === true) {
                            endingSectionIndex = uniqueSectionNodes.length;
                        }

                        for (
                            let sectionIndex = startingSectionIndex;
                            sectionIndex < endingSectionIndex;
                            sectionIndex++
                        ) {
                            const sectionNode = uniqueSectionNodes[sectionIndex];

                            let uniqueCellNodes = null;
                            if (fieldLayout.cellJsonPath) {
                                uniqueCellNodes = jsonpath.nodes(sectionNode, `$..${fieldLayout.cellJsonPath}`)[0]
                                    .value;
                            } else {
                                uniqueCellNodes = sectionNode;
                            }

                            const cellNode =
                                uniqueCellNodes && uniqueCellNodes.length > 0 ? uniqueCellNodes[0] : uniqueCellNodes;

                            if (
                                fieldLayout.EntityName === projectConfig.EntityName.LineItemMiscAmount &&
                                fieldName === 'Rate'
                            ) {
                                oldLineMiscRate = jsonpath.value(
                                    cellNode,
                                    `$..${fieldLayout.JSONPath.replace(')].Amount', ')].Rate')}`
                                );
                            } else if (
                                fieldLayout.EntityName === projectConfig.EntityName.LineItemMiscAmount &&
                                fieldName === 'UnitPrice'
                            ) {
                                oldLineMiscUnitPrice = jsonpath.value(
                                    cellNode,
                                    `$..${fieldLayout.JSONPath.replace(')].Amount', ')].UnitPrice')}`
                                );
                            } else if (
                                fieldLayout.EntityName === projectConfig.EntityName.LineItem &&
                                fieldLayout.FieldName === 'Quantity'
                            ) {
                                oldQuantity = jsonpath.value(cellNode, `$..${fieldLayout.JSONPath}`);
                            }

                            if (
                                fieldLayout.EntityName === projectConfig.EntityName.LineItemMiscAmount &&
                                (fieldName === 'Rate' || fieldName === 'UnitPrice')
                            ) {
                                jsonpath.value(
                                    cellNode,
                                    `$..${fieldLayout.JSONPath.replace(')].Amount', `)].${fieldName}`)}`,
                                    newValue
                                );
                            } else {
                                jsonpath.value(cellNode, `$..${fieldLayout.JSONPath}`, newValue);
                            }

                            if (numericFields.includes(fieldLayout.ControlType)) {
                                switch (fieldLayout.EntityName) {
                                    case projectConfig.EntityName.LineItem:
                                    case projectConfig.EntityName.LineItemMiscAmount:
                                        if (
                                            fieldLayout.EntityName === projectConfig.EntityName.LineItem &&
                                            (fieldLayout.ControlType === projectConfig.ControlType.Quantity ||
                                                fieldLayout.ControlType === projectConfig.ControlType.UnitPrice)
                                        ) {
                                            dispatch(
                                                calculateGenericLineItemTotal(
                                                    cellNode,
                                                    businessDocument,
                                                    layout,
                                                    oldQuantity
                                                )
                                            );
                                        } else {
                                            switch (fieldLayout.AmountIndicator) {
                                                case projectConfig.AmountIndicator.TotalAllowance:
                                                case projectConfig.AmountIndicator.TotalCharge:
                                                case null:
                                                    if (fieldName === 'Rate' || fieldName === 'UnitPrice') {
                                                        dispatch(
                                                            recalculateGenericLineItemMiscAmount(
                                                                cellNode,
                                                                businessDocument,
                                                                layout,
                                                                fieldLayout,
                                                                fieldName,
                                                                oldLineMiscRate,
                                                                oldLineMiscUnitPrice
                                                            )
                                                        );
                                                    } else {
                                                        dispatch(
                                                            calculateGenericLineItemNetTotal(
                                                                cellNode,
                                                                businessDocument,
                                                                layout
                                                            )
                                                        );
                                                    }
                                                    break;

                                                case projectConfig.AmountIndicator.NoAllowanceOrCharge:
                                                case projectConfig.AmountIndicator.AmountDueAllowance:
                                                case projectConfig.AmountIndicator.AmountDueCharge:
                                                default:
                                                    break;
                                            }
                                        }
                                        break;

                                    default:
                                        break;
                                }
                            }
                        }
                        break;

                    case projectConfig.fieldDictionarySection.Footer:
                    case projectConfig.fieldDictionarySection.Header:
                        const bodyJsonPath = state.documentLayout.layout.Large.Body
                            ? state.documentLayout.layout.Large.Body[0].SectionJSONPath
                            : null;
                        let applyToAllSections = false;
                        let applyToAllCells = false;
                        if (applyToAllSections === false && fieldLayout.sectionJsonPath.includes('[0]')) {
                            applyToAllSections =
                                fieldLayout.sectionJsonPath.substring(0, bodyJsonPath.length) === bodyJsonPath;
                        }
                        if (applyToAllSections === false && fieldLayout.cellJsonPath.includes('[0]')) {
                            applyToAllCells =
                                `${fieldLayout.sectionJsonPath}.${fieldLayout.cellJsonPath}`.substring(
                                    0,
                                    bodyJsonPath.length
                                ) === bodyJsonPath;
                        }
                        updateLineItemSkeleton = applyToAllSections || applyToAllCells;

                        const sectionJsonPath =
                            applyToAllSections === true
                                ? `$${fieldLayout.sectionJsonPath.replace('[0]', '')}`
                                : `$${fieldLayout.sectionJsonPath}`;

                        uniqueSectionNodes = jsonpath.nodes(businessDocument, sectionJsonPath)[0].value;

                        let sectionNodes = null;
                        if (applyToAllSections === false && uniqueSectionNodes && uniqueSectionNodes.length > 0) {
                            sectionNodes = [uniqueSectionNodes[0]];
                        } else if (!uniqueSectionNodes.length) {
                            sectionNodes = [uniqueSectionNodes];
                        } else {
                            sectionNodes = uniqueSectionNodes;
                        }

                        sectionNodes.forEach((sectionNode) => {
                            let uniqueCellNodes = null;
                            if (fieldLayout.cellJsonPath) {
                                const cellJsonPath =
                                    applyToAllCells === true
                                        ? fieldLayout.cellJsonPath.replace('[0]', '') ===
                                          fieldLayout.cellJsonPath.substring(0, fieldLayout.cellJsonPath.length - 3)
                                            ? `$..${fieldLayout.cellJsonPath.replace('[0]', '')}`
                                            : `$..${fieldLayout.cellJsonPath.replace('[0]', '.')}`
                                        : `$..${fieldLayout.cellJsonPath}`;
                                const uniqueCellNodesTemp = jsonpath.nodes(sectionNode, cellJsonPath);

                                uniqueCellNodes =
                                    uniqueCellNodesTemp.length && uniqueCellNodesTemp.length === 1
                                        ? uniqueCellNodesTemp[0].value
                                        : uniqueCellNodesTemp.length
                                        ? uniqueCellNodesTemp.map((uniqueCellNode) => uniqueCellNode.value)
                                        : uniqueCellNodesTemp.value;
                            } else {
                                uniqueCellNodes = sectionNode;
                            }

                            let cellNodes = null;
                            if (applyToAllCells === false && uniqueCellNodes && uniqueCellNodes.length > 0) {
                                cellNodes = [uniqueCellNodes[0]];
                            } else if (uniqueCellNodes && !uniqueCellNodes.length) {
                                cellNodes = [uniqueCellNodes];
                            } else {
                                cellNodes = uniqueCellNodes;
                            }
                            cellNodes &&
                                cellNodes.forEach((cellNode) => {
                                    oldMiscRate = null;
                                    if (
                                        fieldLayout.EntityName === projectConfig.EntityName.MiscAmount &&
                                        fieldName === 'Rate'
                                    ) {
                                        oldMiscRate = jsonpath.value(
                                            cellNode,
                                            `$..${fieldLayout.JSONPath.replace(')].Amount', ')].Rate')}`
                                        );
                                    }

                                    if (
                                        fieldLayout.EntityName === projectConfig.EntityName.MiscAmount &&
                                        fieldName === 'Rate'
                                    ) {
                                        jsonpath.value(
                                            cellNode,
                                            `$..${fieldLayout.JSONPath.replace(')].Amount', `)].${fieldName}`)}`,
                                            newValue
                                        );
                                        dispatch(
                                            recalculateGenericMiscAmount(
                                                cellNode,
                                                layout,
                                                fieldLayout,
                                                businessDocument,
                                                oldMiscRate
                                            )
                                        );
                                    } else {
                                        jsonpath.value(cellNode, `$..${fieldLayout.JSONPath}`, newValue);
                                    }
                                });

                            if (numericFields.includes(fieldLayout.ControlType)) {
                                switch (fieldLayout.EntityName) {
                                    case projectConfig.EntityName.Main:
                                    case projectConfig.EntityName.MiscAmount:
                                        switch (fieldLayout.AmountIndicator) {
                                            case projectConfig.AmountIndicator.TotalAllowance:
                                            case projectConfig.AmountIndicator.TotalCharge:
                                            case null:
                                                dispatch(calculateGenericTotal(layout, businessDocument));
                                                break;

                                            case projectConfig.AmountIndicator.AmountDueAllowance:
                                            case projectConfig.AmountIndicator.AmountDueCharge:
                                            case projectConfig.AmountIndicator.NoAllowanceOrCharge:
                                            default:
                                                break;
                                        }
                                        break;

                                    default:
                                        break;
                                }
                            }
                        });

                        if (updateLineItemSkeleton) {
                            uniqueSectionNodes = jsonpath.nodes(fullSkeleton, sectionJsonPath)[0].value;

                            let sectionNodes = null;
                            if (applyToAllSections === false && uniqueSectionNodes && uniqueSectionNodes.length > 0) {
                                sectionNodes = [uniqueSectionNodes[0]];
                            } else if (!uniqueSectionNodes.length) {
                                sectionNodes = [uniqueSectionNodes];
                            } else {
                                sectionNodes = uniqueSectionNodes;
                            }

                            sectionNodes.forEach((sectionNode) => {
                                let uniqueCellNodes = null;
                                if (fieldLayout.cellJsonPath) {
                                    const cellJsonPath =
                                        applyToAllCells === true
                                            ? fieldLayout.cellJsonPath.replace('[0]', '') ===
                                              fieldLayout.cellJsonPath.substring(0, fieldLayout.cellJsonPath.length - 3)
                                                ? `$..${fieldLayout.cellJsonPath.replace('[0]', '')}`
                                                : `$..${fieldLayout.cellJsonPath.replace('[0]', '.')}`
                                            : `$..${fieldLayout.cellJsonPath}`;
                                    const uniqueCellNodesTemp = jsonpath.nodes(sectionNode, cellJsonPath);
                                    uniqueCellNodes =
                                        uniqueCellNodesTemp.length && uniqueCellNodesTemp.length === 1
                                            ? uniqueCellNodesTemp[0].value
                                            : uniqueCellNodesTemp.length
                                            ? uniqueCellNodesTemp.map((uniqueCellNode) => uniqueCellNode.value)
                                            : uniqueCellNodesTemp.value;
                                } else {
                                    uniqueCellNodes = sectionNode;
                                }

                                let cellNodes = null;
                                if (applyToAllCells === false && uniqueCellNodes && uniqueCellNodes.length > 0) {
                                    cellNodes = [uniqueCellNodes[0]];
                                } else if (!uniqueCellNodes.length) {
                                    cellNodes = [uniqueCellNodes];
                                } else {
                                    cellNodes = uniqueCellNodes;
                                }

                                cellNodes.forEach((cellNode) => {
                                    jsonpath.value(cellNode, `$..${fieldLayout.JSONPath}`, newValue);
                                });
                            });
                        }
                        break;

                    default:
                        return;
                }

                dispatch(documentCreationActions.performDocumentFieldValueUpdate(businessDocument));
                if (updateLineItemSkeleton === true) {
                    const lineItemSkeleton = DocumentUtility.determineLineItemSkeleton(
                        state.documentLayout.layout,
                        fullSkeleton
                    );
                    dispatch(documentCreationActions.performDocumentLineItemSkeletonFieldValueUpdate(lineItemSkeleton));
                }
                break;
            case projectConfig.businessDocType.Invoice:
                const invoice = state.documentCreation.invoiceCreationFields.BusinessDocFields.Invoice;
                let oldSalesTaxPercent = null;
                let oldInvoiceProfileId = null;
                if (fieldLayout.EntityName === projectConfig.EntityName.Main && fieldName === 'SalesTaxPercent') {
                    oldSalesTaxPercent = invoice.SalesTaxPercent;
                } else if (
                    fieldLayout.EntityName === projectConfig.EntityName.MiscAmount &&
                    fieldName === 'Rate' &&
                    index !== null
                ) {
                    oldMiscRate = invoice.InvoiceMiscAmounts[index].Rate;
                } else if (
                    fieldLayout.EntityName === projectConfig.EntityName.LineItemMiscAmount &&
                    fieldName === 'Rate'
                ) {
                    const lineItemMiscAmounts = invoice.InvoiceLineItems[index].InvoiceLineItemMiscAmounts;
                    for (
                        let miscAmountIndex = 0;
                        miscAmountIndex < lineItemMiscAmounts.length && oldLineMiscRate === null;
                        miscAmountIndex++
                    ) {
                        const invoiceLineItemMiscAmount = lineItemMiscAmounts[miscAmountIndex];
                        if (
                            invoiceLineItemMiscAmount.AdjustmentReasonId.toString() ===
                            fieldLayout.AdjustmentReasonId.toString()
                        ) {
                            oldLineMiscRate = invoiceLineItemMiscAmount.Rate;
                        }
                    }
                } else if (
                    fieldLayout.EntityName === projectConfig.EntityName.LineItemMiscAmount &&
                    fieldName === 'UnitPrice'
                ) {
                    const lineItemMiscAmounts = invoice.InvoiceLineItems[index].InvoiceLineItemMiscAmounts;
                    for (
                        let miscAmountIndex = 0;
                        miscAmountIndex < lineItemMiscAmounts.length && oldLineMiscUnitPrice === null;
                        miscAmountIndex++
                    ) {
                        const invoiceLineItemMiscAmount = lineItemMiscAmounts[miscAmountIndex];
                        if (
                            invoiceLineItemMiscAmount.AdjustmentReasonId.toString() ===
                            fieldLayout.AdjustmentReasonId.toString()
                        ) {
                            oldLineMiscUnitPrice = invoiceLineItemMiscAmount.UnitPrice;
                        }
                    }
                } else if (
                    fieldLayout.EntityName === projectConfig.EntityName.LineItem &&
                    fieldLayout.FieldName === 'Quantity'
                ) {
                    oldQuantity = invoice.InvoiceLineItems[index].Quantity;
                } else if (fieldLayout.ControlType === projectConfig.ControlType.InvoiceProfile) {
                    oldInvoiceProfileId = invoice.InvoiceProfileID;
                }
                dispatch(
                    CreateInvoiceActions.performInvoiceFieldValueUpdate(
                        fieldLayout,
                        fieldName,
                        newValue,
                        index,
                        updateAllInstances
                    )
                );

                if (
                    fieldLayout.EntityName === projectConfig.EntityName.Main &&
                    fieldLayout.FieldName === 'InvoiceDate' &&
                    IsNotUndefinedNullOrEmpty(invoice.PaymentTerms)
                ) {
                    dispatch(calculateDueDateFromPaymentTerms(invoice.PaymentTerms));
                }

                if (numericFields.includes(fieldLayout.ControlType)) {
                    switch (fieldLayout.EntityName) {
                        case projectConfig.EntityName.Main:
                        case projectConfig.EntityName.MiscAmount:
                            if (fieldName === 'SalesTaxPercent') {
                                dispatch(
                                    calculateInvoiceSalesTaxAmount(
                                        layout,
                                        invoice.DiscountableAmount,
                                        oldSalesTaxPercent
                                    )
                                );
                            } else if (
                                fieldLayout.EntityName === projectConfig.EntityName.MiscAmount &&
                                fieldName === 'Rate'
                            ) {
                                dispatch(recalculateInvoiceMiscAmount(layout, index, oldMiscRate));
                            }

                            switch (fieldLayout.AmountIndicator) {
                                case projectConfig.AmountIndicator.TotalAllowance:
                                case projectConfig.AmountIndicator.TotalCharge:
                                case null:
                                    dispatch(calculateInvoiceTotal());
                                    break;

                                case projectConfig.AmountIndicator.AmountDueAllowance:
                                case projectConfig.AmountIndicator.AmountDueCharge:
                                    dispatch(calculateInvoiceAmountDue());
                                    break;

                                case projectConfig.AmountIndicator.NoAllowanceOrCharge:
                                default:
                                    break;
                            }

                            if (
                                fieldLayout.EntityName === projectConfig.EntityName.MiscAmount &&
                                fieldLayout.FieldName.toLowerCase() === 'vat'
                            ) {
                                dispatch(calculateInvoiceTotalVAT());
                            }
                            break;

                        case projectConfig.EntityName.LineItem:
                        case projectConfig.EntityName.LineItemMiscAmount:
                            if (
                                fieldLayout.EntityName === projectConfig.EntityName.LineItem &&
                                (fieldLayout.ControlType === projectConfig.ControlType.Quantity ||
                                    fieldLayout.ControlType === projectConfig.ControlType.UnitPrice)
                            ) {
                                dispatch(calculateInvoiceLineItemTotal(index, layout, oldQuantity, updateAllInstances));
                            } else {
                                switch (fieldLayout.AmountIndicator) {
                                    case projectConfig.AmountIndicator.TotalAllowance:
                                    case projectConfig.AmountIndicator.TotalCharge:
                                    case null:
                                        if (fieldName === 'Rate' || fieldName === 'UnitPrice') {
                                            dispatch(
                                                recalculateInvoiceLineItemMiscAmount(
                                                    index,
                                                    layout,
                                                    fieldLayout,
                                                    fieldName,
                                                    oldLineMiscRate,
                                                    oldLineMiscUnitPrice
                                                )
                                            );
                                        } else {
                                            dispatch(
                                                calculateInvoiceLineItemNetTotal(index, layout, updateAllInstances)
                                            );
                                        }
                                        break;

                                    case projectConfig.AmountIndicator.NoAllowanceOrCharge:
                                    case projectConfig.AmountIndicator.AmountDueAllowance:
                                    case projectConfig.AmountIndicator.AmountDueCharge:
                                    default:
                                        break;
                                }

                                if (
                                    fieldLayout.EntityName === projectConfig.EntityName.LineItemMiscAmount &&
                                    fieldLayout.FieldName.toLowerCase() === 'vat'
                                ) {
                                    dispatch(calculateInvoiceTotalVAT());
                                }
                            }
                            break;

                        default:
                            break;
                    }
                } else if (fieldLayout.ControlType === projectConfig.ControlType.Currency) {
                    dispatch(updateSelectedCurrency(newValue));
                } else if (fieldLayout.ControlType === projectConfig.ControlType.PaymentTerms) {
                    dispatch(calculateDueDateFromPaymentTerms(newValue));
                } else if (fieldLayout.ControlType === projectConfig.ControlType.InvoiceProfile) {
                    dispatch(updateSelectedInvoiceProfile(newValue, oldInvoiceProfileId));
                }
                break;

            default:
                break;
        }

        dispatch(documentCreationActions.documentCreationUpdatingFieldsComplete());
    };
const updateDocumentCreationStatusAffectedByFieldValueUpdate = (fieldLayout, newValue) => (dispatch, getState) => {
    if (fieldLayout.DisplayHints === 'BusinessDocNumber' || fieldLayout.DisplayHints === 'IdentifyingNumber') {
        const originalIdentifyingNumber = getState().documentCreation.originalStateInfo.identifyingNumber;
        const edited = originalIdentifyingNumber && newValue !== originalIdentifyingNumber;
        dispatch(documentCreationActions.documentCreationIdentifyingNumberEdited(edited));
    }
};

const recalculateGenericFields = (layout) => (dispatch, getState) => {
    // console.log('executing recalculateGenericFields');

    const state = getState();
    if (
        state.documentCreation.documentCreationFields.BusinessDocFields &&
        state.documentCreation.documentCreationFields.BusinessDocFields.BusinessDocument
    ) {
        const businessDocument = cloneObjectHack(
            state.documentCreation.documentCreationFields.BusinessDocFields.BusinessDocument
        );
        const runningInitialCalculations = true;

        let uniqueSectionNodes = jsonpath.nodes(businessDocument, '$..LineItems.LineItem')[0].value;
        uniqueSectionNodes = !uniqueSectionNodes.length ? [uniqueSectionNodes] : uniqueSectionNodes;

        for (let sectionIndex = 0; sectionIndex < uniqueSectionNodes.length; sectionIndex++) {
            const sectionNode = uniqueSectionNodes[sectionIndex];
            const uniqueCellNodes = sectionNode;
            const cellNode = uniqueCellNodes && uniqueCellNodes.length > 0 ? uniqueCellNodes[0] : uniqueCellNodes;

            dispatch(calculateGenericLineItemTotal(cellNode, businessDocument, layout, 0, runningInitialCalculations));
        }

        dispatch(calculateGenericSubtotal(layout, businessDocument.Document, runningInitialCalculations));

        dispatch(documentCreationActions.performDocumentFieldValueUpdate(businessDocument));
    }
};

// TODO: Update this to be more generic. Right now it is assuming that all the necessary line fields
//       for this calculation will all be available in the same node. This is true for Invoices, POs,
//       and POAs which are the only doc types that currently have calculated fields, but it may not
//       be for other doc types in the future.
const calculateGenericLineItemTotal =
    (cellNode, businessDocument, layout, oldLineQuantity, runningInitialCalculations = false) =>
    (dispatch, getState) => {
        // console.log('executing calculateGenericLineItemTotal');

        // Get the old line total
        const lineItemTotalFieldLayout = getFieldLayoutRecordByFieldPurpose(layout.body, 'LineItemTotal');

        // if line total field layout doesn't exist... skip remaining calculation
        if (!lineItemTotalFieldLayout) {
            return;
        }

        const lineItemTotalFieldJSONPath = lineItemTotalFieldLayout
            ? `$..${lineItemTotalFieldLayout.JSONPath}`
            : '$..LineItemTotal';
        const lineItemTotalNodes = jsonpath.nodes(cellNode, lineItemTotalFieldJSONPath);
        const lineTotalIsUnset = !(lineItemTotalNodes && lineItemTotalNodes.length > 0 && lineItemTotalNodes[0].value);
        const oldLineTotal = lineTotalIsUnset ? new BigNumber(0) : new BigNumber(lineItemTotalNodes[0].value);

        // Get the line quantity
        const lineQuantityFieldLayout = getFieldLayoutRecordByFieldPurpose(layout.body, 'Quantity');
        const lineQuantityFieldJSONPath = lineQuantityFieldLayout
            ? `$..${lineQuantityFieldLayout.JSONPath}`
            : '$..Quantity';
        const lineQuantityNodes = jsonpath.nodes(cellNode, lineQuantityFieldJSONPath);
        const lineQuantity =
            lineQuantityNodes && lineQuantityNodes.length > 0 && lineQuantityNodes[0].value
                ? new BigNumber(lineQuantityNodes[0].value)
                : new BigNumber(0);

        // Get the line unit price
        const lineUnitPriceFieldLayout = getFieldLayoutRecordByFieldPurpose(layout.body, 'UnitPrice');
        const lineUnitPriceFieldJSONPath = lineUnitPriceFieldLayout
            ? `$..${lineUnitPriceFieldLayout.JSONPath}`
            : '$..UnitPrice';
        const lineUnitPriceNodes = jsonpath.nodes(cellNode, lineUnitPriceFieldJSONPath);
        const lineUnitPrice =
            lineUnitPriceNodes && lineUnitPriceNodes.length > 0 && lineUnitPriceNodes[0].value
                ? new BigNumber(lineUnitPriceNodes[0].value)
                : new BigNumber(0);

        // Get the line pricining unit divisor. If the value is null or 0, it should default to 1.
        const linePricingUnitDivisorFieldLayout = getFieldLayoutRecordByFieldPurpose(layout.body, 'PricingUnitDivisor');
        const linePricingUnitDivisorFieldJSONPath = linePricingUnitDivisorFieldLayout
            ? `$..${linePricingUnitDivisorFieldLayout.JSONPath}`
            : '$..PricingUnitDivisor';
        const linePricingUnitDivisorNodes = jsonpath.nodes(cellNode, linePricingUnitDivisorFieldJSONPath);
        const linePricingUnitDivisor =
            linePricingUnitDivisorNodes &&
            linePricingUnitDivisorNodes.length > 0 &&
            linePricingUnitDivisorNodes[0].value &&
            linePricingUnitDivisorNodes[0].value !== 0
                ? new BigNumber(linePricingUnitDivisorNodes[0].value)
                : new BigNumber(1);

        // Calculate the new line total. (total = qty * (unit price / pricing unit divisor))
        const lineTotal = new BigNumber(lineQuantity.multipliedBy(lineUnitPrice.dividedBy(linePricingUnitDivisor)));
        const lineTotalMaxDecimalPlaces = NumericUtility.DetermineMaxDecimalPlaces(
            lineItemTotalFieldLayout.ControlType,
            lineItemTotalFieldLayout.ControlFormat,
            ''
        );
        const roundedLineTotal = new BigNumber(lineTotal.toFixed(lineTotalMaxDecimalPlaces));

        // Determine if the line total has changed
        let lineTotalUpdated = false;
        if (
            (lineTotalIsUnset || !oldLineTotal.isEqualTo(roundedLineTotal) || runningInitialCalculations === true) &&
            lineItemTotalFieldJSONPath
        ) {
            lineTotalUpdated = true;

            // If the value has changed, update the value in the business document node and trigger recalculation of misc amounts for this line.
            jsonpath.value(cellNode, lineItemTotalFieldJSONPath, Number(roundedLineTotal));
            dispatch(
                recalculateGenericLineItemMiscAmounts(
                    cellNode,
                    layout,
                    oldLineTotal,
                    roundedLineTotal,
                    oldLineQuantity,
                    oldLineQuantity ? lineQuantity : null
                )
            );
        }

        // Only perform further updates if the line total value has changed
        if (lineTotalUpdated) {
            dispatch(calculateGenericLineItemNetTotal(cellNode, businessDocument, layout, runningInitialCalculations));
        }
    };
// TODO: Update this to be more generic. Right now it is assuming that all the necessary line fields
//       for this calculation will all be available in the same node. This is true for Invoices, POs,
//       and POAs which are the only doc types that currently have calculated fields, but it may not
//       be for other doc types in the future.
const recalculateGenericLineItemMiscAmount =
    (cellNode, businessDocument, layout, field, updatedFieldName, previousRate, previousUnitPrice) =>
    (dispatch, getState) => {
        // console.log('executing recalculateGenericLineItemMiscAmount');
        const lineItemMiscAmounts = jsonpath.nodes(cellNode, field.JSONPath.replace(')].Amount', ')]'));
        const miscAmount =
            lineItemMiscAmounts && lineItemMiscAmounts.length > 0 && lineItemMiscAmounts[0].value
                ? lineItemMiscAmounts[0].value
                : null;

        if (miscAmount) {
            const amountMaxDecimalPlaces = NumericUtility.DetermineMaxDecimalPlaces(
                field.ControlType,
                field.ControlFormat,
                ''
            );
            const flipSign =
                Number(miscAmount.AmountIndicator) === projectConfig.AmountIndicator.TotalAllowance ||
                Number(miscAmount.AmountIndicator) === projectConfig.AmountIndicator.AmountDueAllowance;
            const currentAmount = miscAmount.Amount ? new BigNumber(miscAmount.Amount) : new BigNumber(0);
            let roundedNewAmount = null;
            let shouldUpdate = false;

            if (updatedFieldName === 'Rate') {
                // Get the line total
                const lineItemTotalFieldLayout = getFieldLayoutRecordByFieldPurpose(layout.body, 'LineItemTotal');
                const lineItemTotalFieldJSONPath = lineItemTotalFieldLayout
                    ? `$..${lineItemTotalFieldLayout.JSONPath}`
                    : '$..LineItemTotal';
                const lineItemTotalNodes = jsonpath.nodes(cellNode, lineItemTotalFieldJSONPath);
                const lineTotal =
                    lineItemTotalNodes && lineItemTotalNodes.length > 0 && lineItemTotalNodes[0].value
                        ? new BigNumber(lineItemTotalNodes[0].value)
                        : new BigNumber(0);

                // Get the old and new rates
                const oldRate = previousRate ? new BigNumber(previousRate) : new BigNumber(0);
                const rate = miscAmount.Rate ? new BigNumber(miscAmount.Rate) : null;

                // We only calculate the new amount if the previous amount was not directly input by the user. (It was calculated from the previous total and rate.)
                if (
                    (!flipSign && lineTotal.times(oldRate).dividedBy(new BigNumber(100)).isEqualTo(currentAmount)) ||
                    (flipSign &&
                        lineTotal
                            .times(oldRate)
                            .times(new BigNumber(-1))
                            .dividedBy(new BigNumber(100))
                            .isEqualTo(currentAmount))
                ) {
                    // If the new rate is null, the new amount should be as well. Otherwise, we will calculate it.
                    if (rate !== null) {
                        let newAmount = lineTotal.times(rate).dividedBy(new BigNumber(100));
                        if (flipSign) {
                            newAmount = newAmount.times(new BigNumber(-1));
                        }
                        roundedNewAmount = new BigNumber(newAmount.toFixed(amountMaxDecimalPlaces));

                        // We should only trigger further update if the amount has changed.
                        shouldUpdate = !currentAmount.isEqualTo(roundedNewAmount);
                    } else {
                        // We should only trigger further update if the amount has changed.
                        shouldUpdate = miscAmount.Amount !== null;
                    }
                }
            } else if (updatedFieldName === 'UnitPrice') {
                // Get the line quantity
                const lineQuantityFieldLayout = getFieldLayoutRecordByFieldPurpose(layout.body, 'Quantity');
                const lineQuantityFieldJSONPath = lineQuantityFieldLayout
                    ? `$..${lineQuantityFieldLayout.JSONPath}`
                    : '$..Quantity';
                const lineQuantityNodes = jsonpath.nodes(cellNode, lineQuantityFieldJSONPath);
                const lineQuantity =
                    lineQuantityNodes && lineQuantityNodes.length > 0 && lineQuantityNodes[0].value
                        ? new BigNumber(lineQuantityNodes[0].value)
                        : new BigNumber(0);

                // Get the old and new unit prices
                const oldUnitPrice = previousUnitPrice ? new BigNumber(previousUnitPrice) : new BigNumber(0);
                const unitPrice = miscAmount.UnitPrice ? new BigNumber(miscAmount.UnitPrice) : null;

                // We only calculate the new amount if the previous amount was not directly input by the user. (It was calculated from the previous quantity and unit price.)
                if (
                    (!flipSign && lineQuantity.times(oldUnitPrice).isEqualTo(currentAmount)) ||
                    (flipSign && lineQuantity.times(oldUnitPrice).times(new BigNumber(-1)).isEqualTo(currentAmount))
                ) {
                    // If the new unit price is null, the new amount should be as well. Otherwise, we will calculate it.
                    if (unitPrice !== null) {
                        let newAmount = lineQuantity.times(unitPrice);
                        if (flipSign) {
                            newAmount = newAmount.times(new BigNumber(-1));
                        }
                        roundedNewAmount = new BigNumber(newAmount.toFixed(amountMaxDecimalPlaces));

                        // We should only trigger further update if the amount has changed.
                        shouldUpdate = !currentAmount.isEqualTo(roundedNewAmount);
                    } else {
                        // We should only trigger further update if the amount has changed.
                        shouldUpdate = miscAmount.Amount !== null;
                    }
                }
            }

            if (shouldUpdate) {
                jsonpath.value(cellNode, field.JSONPath, Number(roundedNewAmount));
                dispatch(calculateGenericLineItemNetTotal(cellNode, businessDocument, layout));
            }
        }
    };
// TODO: Update this to be more generic. Right now it is assuming that all the necessary line fields
//       for this calculation will all be available in the same node. This is true for Invoices, POs,
//       and POAs which are the only doc types that currently have calculated fields, but it may not
//       be for other doc types in the future.
const recalculateGenericLineItemMiscAmounts =
    (cellNode, layout, previousLineTotal, newLineTotal, previousLineQuantity, newLineQuantity) =>
    (dispatch, getState) => {
        // console.log('executing recalculateGenericLineItemMiscAmounts');

        const lineTotal = newLineTotal ? new BigNumber(newLineTotal) : new BigNumber(0);
        const lineQuantity = newLineQuantity ? new BigNumber(newLineQuantity) : new BigNumber(0);
        const oldLineTotal = previousLineTotal ? new BigNumber(previousLineTotal) : new BigNumber(0);
        const oldLineQuantity = previousLineQuantity ? new BigNumber(previousLineQuantity) : new BigNumber(0);

        let lineItemMiscAmounts = jsonpath.nodes(cellNode, '$..MiscAmounts.MiscAmount');
        lineItemMiscAmounts =
            lineItemMiscAmounts &&
            lineItemMiscAmounts.length > 0 &&
            lineItemMiscAmounts[0].value &&
            lineItemMiscAmounts[0].value.length > 0
                ? lineItemMiscAmounts[0].value
                : [];
        for (
            let miscAmountIndex = 0;
            lineItemMiscAmounts && miscAmountIndex <= lineItemMiscAmounts.length;
            miscAmountIndex++
        ) {
            const miscAmount = lineItemMiscAmounts[miscAmountIndex];
            if (miscAmount && miscAmount !== null) {
                const miscAmountFieldLayout = layout.body.fields.find((fieldLayout) => {
                    return (
                        fieldLayout.EntityName === projectConfig.EntityName.LineItemMiscAmount &&
                        fieldLayout.AdjustmentReasonId.toString() === miscAmount.AdjustmentReasonId.toString()
                    );
                });
                const amountMaxDecimalPlaces = NumericUtility.DetermineMaxDecimalPlaces(
                    miscAmountFieldLayout && miscAmountFieldLayout.ControlType,
                    miscAmountFieldLayout && miscAmountFieldLayout.ControlFormat,
                    ''
                );

                let roundedNewAmount = null;
                const flipSign =
                    Number(miscAmount.AmountIndicator) === projectConfig.AmountIndicator.TotalAllowance ||
                    Number(miscAmount.AmountIndicator) === projectConfig.AmountIndicator.AmountDueAllowance;
                const currentAmount =
                    miscAmount.Amount && miscAmount.Amount !== null
                        ? new BigNumber(miscAmount.Amount)
                        : new BigNumber(0);

                let rate = miscAmount.Rate;
                const unitPrice = miscAmount.UnitPrice;
                if (rate && !lineTotal.isEqualTo(oldLineTotal)) {
                    rate = new BigNumber(rate);
                    if (
                        (!flipSign &&
                            oldLineTotal.times(rate).dividedBy(new BigNumber(100)).isEqualTo(currentAmount)) ||
                        (flipSign &&
                            oldLineTotal
                                .times(rate)
                                .times(new BigNumber(-1))
                                .dividedBy(new BigNumber(100))
                                .isEqualTo(currentAmount))
                    ) {
                        let newAmount = lineTotal.times(rate).dividedBy(new BigNumber(100));
                        if (flipSign) {
                            newAmount = newAmount.times(new BigNumber(-1));
                        }
                        roundedNewAmount = new BigNumber(newAmount.toFixed(amountMaxDecimalPlaces));
                    }
                } else if (unitPrice && !lineQuantity.isEqualTo(oldLineQuantity)) {
                    if (
                        (!flipSign && oldLineQuantity.times(unitPrice).isEqualTo(currentAmount)) ||
                        (flipSign && oldLineQuantity.times(unitPrice).times(new BigNumber(-1)).isEqualTo(currentAmount))
                    ) {
                        let newAmount = lineQuantity.times(unitPrice);
                        if (flipSign) {
                            newAmount = newAmount.times(new BigNumber(-1));
                        }
                        roundedNewAmount = new BigNumber(newAmount.toFixed(amountMaxDecimalPlaces));
                    }
                }

                if (roundedNewAmount !== null && !currentAmount.isEqualTo(roundedNewAmount)) {
                    jsonpath.value(cellNode, miscAmountFieldLayout.JSONPath, Number(roundedNewAmount));
                }
            }
        }
    };
// TODO: Update this to be more generic. Right now it is assuming that all the necessary line fields
//       for this calculation will all be available in the same node. This is true for Invoices, POs,
//       and POAs which are the only doc types that currently have calculated fields, but it may not
//       be for other doc types in the future.
const calculateGenericLineItemNetTotal =
    (cellNode, businessDocument, layout, runningInitialCalculations = false) =>
    (dispatch, getState) => {
        // console.log('executing calculateGenericLineItemNetTotal');
        let lineNetTotalHasChanged = false;

        // Get the old line net total
        const lineItemNetTotalFieldLayout = getFieldLayoutRecordByFieldPurpose(layout.body, 'LineItemNetTotal');
        const lineItemNetTotalFieldJSONPath = lineItemNetTotalFieldLayout
            ? `$..${lineItemNetTotalFieldLayout.JSONPath}`
            : '$..LineItemNetTotal';
        const lineItemNetTotalNodes = jsonpath.nodes(cellNode, lineItemNetTotalFieldJSONPath);
        const lineNetTotalIsUnset = !(
            lineItemNetTotalNodes &&
            lineItemNetTotalNodes.length > 0 &&
            lineItemNetTotalNodes[0].value
        );
        const oldLineNetTotal = lineNetTotalIsUnset ? new BigNumber(0) : new BigNumber(lineItemNetTotalNodes[0].value);

        // Initialized the new net total with the current line total
        const lineItemTotalFieldLayout = getFieldLayoutRecordByFieldPurpose(layout.body, 'LineItemTotal');
        const lineItemTotalFieldJSONPath = lineItemTotalFieldLayout
            ? `$..${lineItemTotalFieldLayout.JSONPath}`
            : '$..LineItemTotal';
        const lineItemTotalNodes = jsonpath.nodes(cellNode, lineItemTotalFieldJSONPath);
        let lineNetTotal =
            lineItemTotalNodes && lineItemTotalNodes.length > 0 && lineItemTotalNodes[0].value
                ? new BigNumber(lineItemTotalNodes[0].value)
                : new BigNumber(0);

        layout.body.fields
            .filter((fieldLayout) => {
                return (
                    fieldLayout.ControlType === projectConfig.ControlType.Money &&
                    fieldLayout.EntityName === projectConfig.EntityName.LineItem
                );
            })
            .forEach((fieldLayout) => {
                const fieldValueNodes = jsonpath.nodes(cellNode, `$..${fieldLayout.JSONPath}`);
                if (
                    fieldValueNodes &&
                    fieldValueNodes.length > 0 &&
                    fieldValueNodes[0].value &&
                    fieldValueNodes[0].value !== 0
                ) {
                    const fieldValue = new BigNumber(fieldValueNodes[0].value);

                    switch (fieldLayout.AmountIndicator) {
                        case projectConfig.AmountIndicator.TotalAllowance:
                            lineNetTotal = new BigNumber(lineNetTotal.minus(fieldValue));
                            break;

                        case projectConfig.AmountIndicator.TotalCharge:
                        case null:
                            lineNetTotal = new BigNumber(lineNetTotal.plus(fieldValue));
                            break;

                        case projectConfig.AmountIndicator.NoAllowanceOrCharge:
                        case projectConfig.AmountIndicator.AmountDueAllowance:
                        case projectConfig.AmountIndicator.AmountDueCharge:
                        default:
                            break;
                    }
                }
            });

        let lineItemMiscAmounts = jsonpath.nodes(cellNode, '$..MiscAmounts.MiscAmount');
        lineItemMiscAmounts =
            lineItemMiscAmounts &&
            lineItemMiscAmounts.length > 0 &&
            lineItemMiscAmounts[0].value &&
            lineItemMiscAmounts[0].value.length > 0
                ? lineItemMiscAmounts[0].value
                : [];
        lineItemMiscAmounts.forEach((miscAmount) => {
            if (miscAmount && miscAmount !== null && miscAmount.Amount && miscAmount.Amount !== 0) {
                const fieldValue = new BigNumber(miscAmount.Amount);

                switch (Number(miscAmount.AmountIndicator)) {
                    case projectConfig.AmountIndicator.TotalAllowance:
                    case projectConfig.AmountIndicator.TotalCharge:
                    case null:
                        lineNetTotal = new BigNumber(lineNetTotal.plus(fieldValue));
                        break;

                    case projectConfig.AmountIndicator.NoAllowanceOrCharge:
                    case projectConfig.AmountIndicator.AmountDueAllowance:
                    case projectConfig.AmountIndicator.AmountDueCharge:
                    default:
                        break;
                }
            }
        });

        const lineNetTotalMaxDecimalPlaces = NumericUtility.DetermineMaxDecimalPlaces(
            lineItemNetTotalFieldLayout.ControlType,
            lineItemNetTotalFieldLayout.ControlFormat,
            ''
        );
        const roundedLineNetTotal = new BigNumber(lineNetTotal.toFixed(lineNetTotalMaxDecimalPlaces));

        if (
            (lineNetTotalIsUnset ||
                !oldLineNetTotal.isEqualTo(roundedLineNetTotal) ||
                runningInitialCalculations === true) &&
            lineItemNetTotalFieldJSONPath
        ) {
            lineNetTotalHasChanged = true;
            jsonpath.value(cellNode, lineItemNetTotalFieldJSONPath, Number(roundedLineNetTotal));
        }

        // Only perform further updates if a line net total value has changed and we are not running the initial calculations for the document.
        // When running the initial calculations we may be calculating several lines at one time, and we do not want each line calculation to re-trigger
        // the totals calculations. The function calling the initial calculations on the lines will manually call the calculations for the totals.
        if (lineNetTotalHasChanged === true && runningInitialCalculations !== true) {
            dispatch(calculateGenericSubtotal(layout, businessDocument, runningInitialCalculations));
        }
    };
// TODO: Update this to be more generic. Right now it is relying upon knowledge of the POA structure.
//       It should obtain all of its information about the document from the field dictionary. We should
//       generically determine what fields are available that need to be calculated as well as the path
//       to the fields that are calculated and the fields used in calculations.
const calculateGenericSubtotal =
    (layout, businessDocument, runningInitialCalculations = false) =>
    (dispatch, getState) => {
        // console.log('executing calculateGenericSubtotal');

        // Get the old subtotal
        const subtotalFieldLayout = getFieldLayoutRecordByFieldPurpose(layout.footer, 'DiscountableAmount');
        const subtotalFieldJSONPath = subtotalFieldLayout
            ? `$${subtotalFieldLayout.sectionJsonPath}${
                  subtotalFieldLayout.cellJsonPath ? `.${subtotalFieldLayout.cellJsonPath}` : ''
              }.${subtotalFieldLayout.JSONPath}`
            : '$..DiscountableAmount';
        const subtotalNodes = jsonpath.nodes(businessDocument, subtotalFieldJSONPath);
        const subtotalIsUnset = !(subtotalNodes && subtotalNodes.length > 0 && subtotalNodes[0].value);
        const oldSubtotal = subtotalIsUnset ? new BigNumber(0) : new BigNumber(subtotalNodes[0].value);

        const lineItemNetTotalFieldLayout = getFieldLayoutRecordByFieldPurpose(layout.body, 'LineItemNetTotal');
        const lineItemNetTotalFieldJSONPath = lineItemNetTotalFieldLayout
            ? `$..${lineItemNetTotalFieldLayout.JSONPath}`
            : '$..LineItemNetTotal';

        // Add up all the line net totals
        let subtotal = new BigNumber(0);
        let lineItems = jsonpath.nodes(businessDocument, '$..LineItems.LineItem');
        lineItems =
            lineItems && lineItems[0] && lineItems[0].value
                ? lineItems[0].value.length > 0
                    ? lineItems[0].value
                    : [lineItems[0].value]
                : [];
        if (lineItems && lineItems.length > 0) {
            lineItems.forEach((lineItem) => {
                // Get the old line net total
                const lineItemNetTotalNodes = jsonpath.nodes(lineItem, lineItemNetTotalFieldJSONPath);
                if (lineItemNetTotalNodes && lineItemNetTotalNodes.length > 0 && lineItemNetTotalNodes[0].value) {
                    const lineNetTotal = new BigNumber(lineItemNetTotalNodes[0].value);
                    subtotal = subtotal.plus(lineNetTotal);
                }
            });
        }

        const subtotalMaxDecimalPlaces = subtotalFieldLayout
            ? NumericUtility.DetermineMaxDecimalPlaces(
                  subtotalFieldLayout.ControlType,
                  subtotalFieldLayout.ControlFormat,
                  ''
              )
            : null;
        const roundedSubtotal = new BigNumber(subtotal.toFixed(subtotalMaxDecimalPlaces));

        // Only trigger further updates if specified or the subtotal has changed
        if (
            (subtotalIsUnset || !oldSubtotal.isEqualTo(roundedSubtotal) || runningInitialCalculations === true) &&
            subtotalFieldLayout &&
            subtotalFieldLayout.JSONPath
        ) {
            const subtotalFullJSONPath = `$${subtotalFieldLayout.sectionJsonPath}${
                subtotalFieldLayout.cellJsonPath ? `.${subtotalFieldLayout.cellJsonPath}` : ''
            }.${subtotalFieldLayout.JSONPath}`;
            jsonpath.value(businessDocument, subtotalFullJSONPath, Number(roundedSubtotal));

            dispatch(recalculateGenericMiscAmounts(layout, businessDocument, oldSubtotal, roundedSubtotal));
            dispatch(calculateGenericTotal(layout, businessDocument));
        }
    };
// TODO: Update this to be more generic. Right now it is relying upon knowledge of the POA structure.
//       It should obtain all of its information about the document from the field dictionary. We should
//       generically determin what fields are available that need to be calculated as well as the path
//       to the fields that are calculated and the fields used in calculations.
const recalculateGenericMiscAmount =
    (cellNode, layout, field, businessDocument, previousRate) => (dispatch, getState) => {
        // console.log('executing recalculateGenericMiscAmount');

        const oldRate = previousRate ? new BigNumber(previousRate) : new BigNumber(0);

        // Get the subtotal
        const subtotalFieldLayout = getFieldLayoutRecordByFieldPurpose(layout.footer, 'DiscountableAmount');
        const subtotalFieldJSONPath = subtotalFieldLayout
            ? `$..${subtotalFieldLayout.JSONPath}`
            : '$..DiscountableAmount';
        const subtotalNodes = jsonpath.nodes(businessDocument, subtotalFieldJSONPath);
        const subtotal =
            subtotalNodes && subtotalNodes.length > 0 && subtotalNodes[0].value
                ? new BigNumber(subtotalNodes[0].value)
                : new BigNumber(0);

        const miscAmounts = jsonpath.nodes(cellNode, field.JSONPath.replace(')].Amount', ')]'));
        const miscAmount = miscAmounts && miscAmounts.length > 0 && miscAmounts[0].value ? miscAmounts[0].value : null;
        if (miscAmount && miscAmount !== null) {
            const amountMaxDecimalPlaces = NumericUtility.DetermineMaxDecimalPlaces(
                field.ControlType,
                field.ControlFormat,
                ''
            );

            let roundedNewAmount = null;
            const flipSign =
                Number(miscAmount.AmountIndicator) === projectConfig.AmountIndicator.TotalAllowance ||
                Number(miscAmount.AmountIndicator) === projectConfig.AmountIndicator.AmountDueAllowance;
            const oldAmount =
                miscAmount.Amount && miscAmount.Amount !== null ? new BigNumber(miscAmount.Amount) : new BigNumber(0);
            const rate = miscAmount.Rate ? new BigNumber(miscAmount.Rate) : new BigNumber(0);
            if (
                (!flipSign && subtotal.times(oldRate).dividedBy(new BigNumber(100)).isEqualTo(oldAmount)) ||
                (flipSign &&
                    subtotal.times(oldRate).times(new BigNumber(-1)).dividedBy(new BigNumber(100)).isEqualTo(oldAmount))
            ) {
                let newAmount = subtotal.times(rate).dividedBy(new BigNumber(100));
                if (flipSign) {
                    newAmount = newAmount.times(new BigNumber(-1));
                }
                roundedNewAmount = new BigNumber(newAmount.toFixed(amountMaxDecimalPlaces));
            }

            // Update only if the value changed
            if (roundedNewAmount !== null && !oldAmount.isEqualTo(roundedNewAmount)) {
                jsonpath.value(cellNode, field.JSONPath, Number(roundedNewAmount));
            }
        }
    };
// TODO: Update this to be more generic. Right now it is relying upon knowledge of the POA structure.
//       It should obtain all of its information about the document from the field dictionary. We should
//       generically determin what fields are available that need to be calculated as well as the path
//       to the fields that are calculated and the fields used in calculations.
const recalculateGenericMiscAmounts =
    (layout, businessDocument, previousSubtotal, newSubtotal) => (dispatch, getState) => {
        // console.log('executing recalculateGenericMiscAmounts');

        const subtotal = newSubtotal ? new BigNumber(newSubtotal) : new BigNumber(0);
        const oldSubtotal = previousSubtotal ? new BigNumber(previousSubtotal) : new BigNumber(0);

        const firstMiscAmountFieldLayout = layout.footer.fields.find(
            (fieldLayout) => fieldLayout.EntityName === projectConfig.EntityName.MiscAmount
        );
        if (firstMiscAmountFieldLayout) {
            const headerPath = `$${firstMiscAmountFieldLayout.sectionJsonPath}${
                firstMiscAmountFieldLayout.cellJsonPath ? `.${firstMiscAmountFieldLayout.cellJsonPath}` : ''
            }`;
            const headerNodes = jsonpath.nodes(businessDocument, headerPath);
            const header = headerNodes[0].value;
            let miscAmounts = jsonpath.nodes(header, '$.MiscAmounts.MiscAmount');
            miscAmounts =
                miscAmounts && miscAmounts.length > 0 && miscAmounts[0].value && miscAmounts[0].value.length > 0
                    ? miscAmounts[0].value
                    : [];
            if (miscAmounts && miscAmounts.length > 0) {
                for (let miscAmountIndex = 0; miscAmountIndex <= miscAmounts.length; miscAmountIndex++) {
                    const miscAmount = miscAmounts[miscAmountIndex];
                    if (miscAmount) {
                        const miscAmountFieldLayout = cloneObjectHack(
                            layout.footer.fields.find((fieldLayout) => {
                                return (
                                    fieldLayout.EntityName === projectConfig.EntityName.MiscAmount &&
                                    fieldLayout.AdjustmentReasonId.toString() ===
                                        miscAmount.AdjustmentReasonId.toString()
                                );
                            })
                        );
                        const amountMaxDecimalPlaces = NumericUtility.DetermineMaxDecimalPlaces(
                            miscAmountFieldLayout.ControlType,
                            miscAmountFieldLayout.ControlFormat,
                            ''
                        );

                        let roundedNewAmount = null;

                        const flipSign =
                            Number(miscAmount.AmountIndicator) === projectConfig.AmountIndicator.TotalAllowance ||
                            Number(miscAmount.AmountIndicator) === projectConfig.AmountIndicator.AmountDueAllowance;
                        const currentAmount =
                            miscAmount.Amount && miscAmount.Amount !== null
                                ? new BigNumber(miscAmount.Amount)
                                : new BigNumber(0);

                        let rate = miscAmount.Rate;
                        if (rate && !subtotal.isEqualTo(oldSubtotal)) {
                            rate = new BigNumber(rate);
                            if (
                                (!flipSign &&
                                    oldSubtotal.times(rate).dividedBy(new BigNumber(100)).isEqualTo(currentAmount)) ||
                                (flipSign &&
                                    oldSubtotal
                                        .times(rate)
                                        .times(new BigNumber(-1))
                                        .dividedBy(new BigNumber(100))
                                        .isEqualTo(currentAmount))
                            ) {
                                let newAmount = subtotal.times(rate).dividedBy(new BigNumber(100));
                                if (flipSign) {
                                    newAmount = newAmount.times(new BigNumber(-1));
                                }
                                roundedNewAmount = new BigNumber(newAmount.toFixed(amountMaxDecimalPlaces));
                            }
                        }

                        if (roundedNewAmount !== null) {
                            jsonpath.value(
                                businessDocument,
                                `${headerPath}.${miscAmountFieldLayout.JSONPath}`,
                                Number(roundedNewAmount)
                            );
                        }
                    }
                }
            }
        }
    };
// TODO: Update this to be more generic. Right now it is relying upon knowledge of the POA structure.
//       It should obtain all of its information about the document from the field dictionary. We should
//       generically determin what fields are available that need to be calculated as well as the path
//       to the fields that are calculated and the fields used in calculations.
const calculateGenericTotal = (layout, businessDocument) => (dispatch, getState) => {
    // console.log('executing calculateGenericTotal');
    const state = getState();

    // Get the old total
    let totalFieldLayout = getFieldLayoutRecordByFieldPurpose(layout.footer, 'DocumentAmount');
    if (!totalFieldLayout) {
        totalFieldLayout = getFieldLayoutRecordByFieldPurpose(layout.footer, 'Total');
    }

    // There are no calculations that rely upon this result. Therefore, we only need to calculate it if it exists in the document structure.
    if (totalFieldLayout) {
        const totalFieldJSONPath = `$${totalFieldLayout.sectionJsonPath}${
            totalFieldLayout.cellJsonPath ? `.${totalFieldLayout.cellJsonPath}` : ''
        }.${totalFieldLayout.JSONPath}`;
        const totalNodes = jsonpath.nodes(businessDocument, totalFieldJSONPath);
        const totalIsUnset = !(totalNodes && totalNodes.length > 0 && totalNodes[0].value);
        const oldTotal = totalIsUnset ? new BigNumber(0) : new BigNumber(totalNodes[0].value);

        // Initialize the new total with the current subtotal
        const subtotalFieldLayout = getFieldLayoutRecordByFieldPurpose(layout.footer, 'DiscountableAmount');
        const lineItemNetTotalFieldJSONPath = subtotalFieldLayout
            ? `$..${subtotalFieldLayout.JSONPath}`
            : '$..DiscountableAmount';
        const subtotalNodes = jsonpath.nodes(businessDocument, lineItemNetTotalFieldJSONPath);
        let total =
            subtotalNodes && subtotalNodes.length > 0 && subtotalNodes[0].value
                ? new BigNumber(subtotalNodes[0].value)
                : new BigNumber(0);

        const footerLayout =
            state.documentCreation.editLayout &&
            state.documentCreation.editLayout.Large &&
            state.documentCreation.editLayout.Large.Footer &&
            state.documentCreation.editLayout.Large.Footer !== undefined
                ? cloneObjectHack(state.documentCreation.editLayout.Large.Footer[0].Fields)
                : [];
        footerLayout
            .filter((fieldLayout) => {
                return (
                    [projectConfig.ControlType.Money, projectConfig.ControlType.Tax].includes(
                        fieldLayout.ControlType
                    ) &&
                    fieldLayout.EntityName === projectConfig.EntityName.Main &&
                    fieldLayout.FieldName !== 'AmountPaid'
                );
            })
            .forEach((fieldLayout) => {
                const fieldValueNodes = jsonpath.nodes(businessDocument, `$..${fieldLayout.JSONPath}`);
                if (
                    fieldValueNodes &&
                    fieldValueNodes.length > 0 &&
                    fieldValueNodes[0].value &&
                    fieldValueNodes[0].value !== 0
                ) {
                    const fieldValue = new BigNumber(fieldValueNodes[0].value);

                    switch (fieldLayout.AmountIndicator) {
                        case projectConfig.AmountIndicator.TotalAllowance:
                            total = new BigNumber(total.minus(fieldValue));
                            break;

                        case projectConfig.AmountIndicator.TotalCharge:
                        case null:
                            total = new BigNumber(total.plus(fieldValue));
                            break;

                        case projectConfig.AmountIndicator.NoAllowanceOrCharge:
                        case projectConfig.AmountIndicator.AmountDueAllowance:
                        case projectConfig.AmountIndicator.AmountDueCharge:
                        default:
                            break;
                    }
                }
            });

        const firstMiscAmountFieldLayout = layout.footer.fields.find((fieldLayout) => {
            return fieldLayout.EntityName === projectConfig.EntityName.MiscAmount;
        });
        if (firstMiscAmountFieldLayout) {
            const headerPath = `$${firstMiscAmountFieldLayout.sectionJsonPath}${
                firstMiscAmountFieldLayout.cellJsonPath ? `.${firstMiscAmountFieldLayout.cellJsonPath}` : ''
            }`;
            const headerNodes = jsonpath.nodes(businessDocument, headerPath);
            const header = headerNodes[0].value;
            let miscAmounts = jsonpath.nodes(header, '$.MiscAmounts.MiscAmount');
            miscAmounts =
                miscAmounts && miscAmounts.length > 0 && miscAmounts[0].value && miscAmounts[0].value.length > 0
                    ? miscAmounts[0].value
                    : [];
            if (miscAmounts && miscAmounts.length > 0) {
                miscAmounts.forEach((miscAmount) => {
                    if (miscAmount && miscAmount !== null && miscAmount.Amount && miscAmount.Amount !== 0) {
                        const fieldValue = new BigNumber(miscAmount.Amount);

                        switch (Number(miscAmount.AmountIndicator)) {
                            case projectConfig.AmountIndicator.TotalAllowance:
                            case projectConfig.AmountIndicator.TotalCharge:
                            case null:
                                total = new BigNumber(total.plus(fieldValue));
                                break;

                            case projectConfig.AmountIndicator.NoAllowanceOrCharge:
                            case projectConfig.AmountIndicator.AmountDueAllowance:
                            case projectConfig.AmountIndicator.AmountDueCharge:
                            default:
                                break;
                        }
                    }
                });
            }
        }

        const totalMaxDecimalPlaces = NumericUtility.DetermineMaxDecimalPlaces(
            totalFieldLayout.ControlType,
            totalFieldLayout.ControlFormat,
            ''
        );
        const roundedTotal = new BigNumber(total.toFixed(totalMaxDecimalPlaces));

        // Only update the value if it has changed
        if ((totalIsUnset || !oldTotal.isEqualTo(roundedTotal)) && totalFieldJSONPath) {
            jsonpath.value(businessDocument, totalFieldJSONPath, Number(roundedTotal));
        }
    }
};

const calculateDueDateFromPaymentTerms = (paymentTermsValue) => (dispatch, getState) => {
    const dueDateMatches = paymentTermsValue.match(/\d+/g);
    const dueDate = dueDateMatches && dueDateMatches.map(Number);
    const state = getState();
    const invoice = state.documentCreation.invoiceCreationFields.BusinessDocFields.Invoice;
    const invoiceDate = invoice.InvoiceDate ? new Date(invoice.InvoiceDate) : new Date();

    let invoiceDueDate = '';
    if (dueDate && dueDate.length > 0) {
        invoiceDueDate = format(addDays(new Date(invoiceDate), dueDate[dueDate.length - 1]), 'P');
    } else {
        invoiceDueDate = saveableDate(new Date());
    }
    if (
        isAfter(new Date('01/01/1900'), new Date(invoiceDueDate)) ||
        isBefore(new Date('06/07/2079'), new Date(invoiceDueDate))
    ) {
        invoiceDueDate = format(new Date(), 'P');
    }

    dispatch(performChangeInvoiceField('DueDate', invoiceDueDate));
};
const calculateInvoiceLineItemTotal =
    (lineIndex, layout, oldLineQuantity, recalculateForAllLines) => (dispatch, getState) => {
        // console.log('executing calculateInvoiceLineItemTotal');
        const state = getState();
        const invoice = cloneObjectHack(state.documentCreation.invoiceCreationFields.BusinessDocFields.Invoice);

        let startingLineIndex = 0;
        let endingLineIndex = 1;
        if (lineIndex) {
            startingLineIndex = lineIndex;
            endingLineIndex = lineIndex + 1;
        } else if (recalculateForAllLines === true) {
            endingLineIndex = invoice.InvoiceLineItems.length;
        }

        const lineItemTotalFieldLayout = cloneObjectHack(
            layout.body.fields.find((fieldLayout) => {
                return fieldLayout.FieldName === 'LineItemTotal';
            })
        );
        const lineItemTotalMaxDecimalPlaces = NumericUtility.DetermineMaxDecimalPlaces(
            lineItemTotalFieldLayout.ControlType,
            lineItemTotalFieldLayout.ControlFormat,
            ''
        );

        let lineTotalUpdated = false;
        for (let index = startingLineIndex; index < endingLineIndex; index++) {
            const oldLineTotal = invoice.InvoiceLineItems[index].LineItemTotal;

            const lineQuantity = new BigNumber(invoice.InvoiceLineItems[index].Quantity);
            const lineUnitPrice = new BigNumber(invoice.InvoiceLineItems[index].UnitPrice);
            let linePricingUnitDivisor = new BigNumber(1);
            if (invoice.InvoiceLineItems[index].PricingUnitDivisor) {
                linePricingUnitDivisor = new BigNumber(invoice.InvoiceLineItems[index].PricingUnitDivisor);
            }

            const lineTotal = new BigNumber(lineQuantity.multipliedBy(lineUnitPrice.dividedBy(linePricingUnitDivisor)));
            const roundedLineTotal = new BigNumber(lineTotal.toFixed(lineItemTotalMaxDecimalPlaces));

            if (!new BigNumber(oldLineTotal).isEqualTo(roundedLineTotal)) {
                lineTotalUpdated = true;

                dispatch(
                    CreateInvoiceActions.performInvoiceFieldValueUpdate(
                        lineItemTotalFieldLayout,
                        'LineItemTotal',
                        Number(roundedLineTotal),
                        lineIndex
                    )
                );
                dispatch(
                    recalculateInvoiceLineItemMiscAmounts(
                        lineIndex,
                        layout,
                        oldLineTotal,
                        Number(roundedLineTotal),
                        oldLineQuantity,
                        oldLineQuantity ? lineQuantity : null
                    )
                );
            }
        }

        // Only perform further updates if the line total value has changed
        if (lineTotalUpdated) {
            dispatch(calculateInvoiceLineItemNetTotal(lineIndex, layout, recalculateForAllLines));
        }
    };
const recalculateInvoiceLineItemMiscAmount =
    (lineIndex, layout, field, updatedFieldName, previousRate, previousUnitPrice) => (dispatch, getState) => {
        // console.log('executing recalculateInvoiceLineItemMiscAmount');
        const state = getState();
        const invoice = cloneObjectHack(state.documentCreation.invoiceCreationFields.BusinessDocFields.Invoice);
        const invoiceLineItemMiscAmounts = invoice.InvoiceLineItems[lineIndex].InvoiceLineItemMiscAmounts;

        const amountMaxDecimalPlaces = NumericUtility.DetermineMaxDecimalPlaces(
            field.ControlType,
            field.ControlFormat,
            ''
        );

        let miscAmount = null;
        for (
            let miscAmountIndex = 0;
            miscAmountIndex < invoiceLineItemMiscAmounts.length && miscAmount === null;
            miscAmountIndex++
        ) {
            if (
                invoiceLineItemMiscAmounts[miscAmountIndex].AdjustmentReasonId.toString() ===
                field.AdjustmentReasonId.toString()
            ) {
                miscAmount = invoiceLineItemMiscAmounts[miscAmountIndex];
            }
        }

        if (miscAmount) {
            const flipSign =
                Number(miscAmount.AmountIndicator) === projectConfig.AmountIndicator.TotalAllowance ||
                Number(miscAmount.AmountIndicator) === projectConfig.AmountIndicator.AmountDueAllowance;
            const currentAmount = miscAmount.Amount ? new BigNumber(miscAmount.Amount) : new BigNumber(0);
            let roundedNewAmount = null;
            let shouldUpdate = false;

            if (updatedFieldName === 'Rate') {
                const lineTotal = invoice.InvoiceLineItems[lineIndex].LineItemTotal
                    ? new BigNumber(invoice.InvoiceLineItems[lineIndex].LineItemTotal)
                    : new BigNumber(0);
                const oldRate = previousRate ? new BigNumber(previousRate) : new BigNumber(0);
                const rate = miscAmount.Rate ? new BigNumber(miscAmount.Rate) : null;

                // We only calculate the new amount if the previous amount was not directly input by the user. (It was calculated from the previous total and rate.)
                if (
                    (!flipSign && lineTotal.times(oldRate).dividedBy(new BigNumber(100)).isEqualTo(currentAmount)) ||
                    (flipSign &&
                        lineTotal
                            .times(oldRate)
                            .times(new BigNumber(-1))
                            .dividedBy(new BigNumber(100))
                            .isEqualTo(currentAmount))
                ) {
                    // If the new rate is null, the new amount should be as well. Otherwise, we will calculate it.
                    if (rate !== null) {
                        let newAmount = lineTotal.times(rate).dividedBy(new BigNumber(100));
                        if (flipSign) {
                            newAmount = newAmount.times(new BigNumber(-1));
                        }
                        roundedNewAmount = new BigNumber(newAmount.toFixed(amountMaxDecimalPlaces));

                        // We should only trigger further update if the amount has changed.
                        shouldUpdate = !currentAmount.isEqualTo(roundedNewAmount);
                    } else {
                        // We should only trigger further update if the amount has changed.
                        shouldUpdate = miscAmount.Amount !== null;
                    }
                }
            } else if (updatedFieldName === 'UnitPrice') {
                const lineQuantity = invoice.InvoiceLineItems[lineIndex].Quantity
                    ? new BigNumber(invoice.InvoiceLineItems[lineIndex].Quantity)
                    : new BigNumber(0);
                const oldUnitPrice = previousUnitPrice ? new BigNumber(previousUnitPrice) : new BigNumber(0);
                const unitPrice = miscAmount.UnitPrice ? new BigNumber(miscAmount.UnitPrice) : null;

                // We only calculate the new amount if the previous amount was not directly input by the user. (It was calculated from the previous quantity and unit price.)
                if (
                    (!flipSign && lineQuantity.times(oldUnitPrice).isEqualTo(currentAmount)) ||
                    (flipSign && lineQuantity.times(oldUnitPrice).times(new BigNumber(-1)).isEqualTo(currentAmount))
                ) {
                    // If the new unit price is null, the new amount should be as well. Otherwise, we will calculate it.
                    if (unitPrice !== null) {
                        let newAmount = lineQuantity.times(unitPrice);
                        if (flipSign) {
                            newAmount = newAmount.times(new BigNumber(-1));
                        }
                        roundedNewAmount = new BigNumber(newAmount.toFixed(amountMaxDecimalPlaces));

                        // We should only trigger further update if the amount has changed.
                        shouldUpdate = !currentAmount.isEqualTo(roundedNewAmount);
                    } else {
                        // We should only trigger further update if the amount has changed.
                        shouldUpdate = miscAmount.Amount !== null;
                    }
                }
            }

            if (shouldUpdate) {
                dispatch(
                    CreateInvoiceActions.performInvoiceFieldValueUpdate(
                        field,
                        'Amount',
                        Number(roundedNewAmount),
                        lineIndex
                    )
                );
                dispatch(calculateInvoiceLineItemNetTotal(lineIndex, layout));
            }
        }
    };
const recalculateInvoiceLineItemMiscAmounts =
    (lineIndex, layout, previousLineTotal, newLineTotal, previousLineQuantity, newLineQuantity) =>
    (dispatch, getState) => {
        // console.log('executing recalculateInvoiceLineItemMiscAmounts');
        const state = getState();
        const invoice = state.documentCreation.invoiceCreationFields.BusinessDocFields.Invoice;

        const lineTotal = newLineTotal ? new BigNumber(newLineTotal) : new BigNumber(0);
        const lineQuantity = newLineQuantity ? new BigNumber(newLineQuantity) : new BigNumber(0);
        const oldLineTotal = previousLineTotal ? new BigNumber(previousLineTotal) : new BigNumber(0);
        const oldLineQuantity = previousLineQuantity ? new BigNumber(previousLineQuantity) : new BigNumber(0);

        for (
            let miscAmountIndex = 0;
            miscAmountIndex <= invoice.InvoiceLineItems[lineIndex].InvoiceLineItemMiscAmounts.length;
            miscAmountIndex++
        ) {
            const miscAmount = invoice.InvoiceLineItems[lineIndex].InvoiceLineItemMiscAmounts[miscAmountIndex];
            if (miscAmount && miscAmount !== null) {
                const miscAmountFieldLayout = layout.body.fields.find((fieldLayout) => {
                    return (
                        fieldLayout.EntityName === projectConfig.EntityName.LineItemMiscAmount &&
                        fieldLayout.AdjustmentReasonId.toString() === miscAmount.AdjustmentReasonId.toString()
                    );
                });
                const amountMaxDecimalPlaces = NumericUtility.DetermineMaxDecimalPlaces(
                    miscAmountFieldLayout.ControlType,
                    miscAmountFieldLayout.ControlFormat,
                    ''
                );

                let roundedNewAmount = null;
                const flipSign =
                    Number(miscAmount.AmountIndicator) === projectConfig.AmountIndicator.TotalAllowance ||
                    Number(miscAmount.AmountIndicator) === projectConfig.AmountIndicator.AmountDueAllowance;
                const currentAmount =
                    miscAmount.Amount && miscAmount.Amount !== null
                        ? new BigNumber(miscAmount.Amount)
                        : new BigNumber(0);

                let rate = miscAmount.Rate;
                const unitPrice = miscAmount.UnitPrice;
                if (rate && !lineTotal.isEqualTo(oldLineTotal)) {
                    rate = new BigNumber(rate);
                    if (
                        (!flipSign &&
                            oldLineTotal.times(rate).dividedBy(new BigNumber(100)).isEqualTo(currentAmount)) ||
                        (flipSign &&
                            oldLineTotal
                                .times(rate)
                                .times(new BigNumber(-1))
                                .dividedBy(new BigNumber(100))
                                .isEqualTo(currentAmount))
                    ) {
                        let newAmount = lineTotal.times(rate).dividedBy(new BigNumber(100));
                        if (flipSign) {
                            newAmount = roundedNewAmount.times(new BigNumber(-1));
                        }
                        roundedNewAmount = new BigNumber(newAmount.toFixed(amountMaxDecimalPlaces));
                    }
                } else if (unitPrice && !lineQuantity.isEqualTo(oldLineQuantity)) {
                    if (
                        (!flipSign && oldLineQuantity.times(unitPrice).isEqualTo(currentAmount)) ||
                        (flipSign && oldLineQuantity.times(unitPrice).times(new BigNumber(-1)).isEqualTo(currentAmount))
                    ) {
                        let newAmount = lineQuantity.times(unitPrice);
                        if (flipSign) {
                            newAmount = roundedNewAmount.times(new BigNumber(-1));
                        }
                        roundedNewAmount = new BigNumber(newAmount.toFixed(amountMaxDecimalPlaces));
                    }
                }

                if (roundedNewAmount !== null && !currentAmount.isEqualTo(roundedNewAmount)) {
                    dispatch(
                        CreateInvoiceActions.performInvoiceFieldValueUpdate(
                            miscAmountFieldLayout,
                            'Amount',
                            Number(roundedNewAmount),
                            lineIndex
                        )
                    );
                }
            }
        }
    };
const calculateInvoiceLineItemNetTotal = (lineIndex, layout, recalculateForAllLines) => (dispatch, getState) => {
    // console.log('executing calculateInvoiceLineItemNetTotal');
    const state = getState();
    const invoice = cloneObjectHack(state.documentCreation.invoiceCreationFields.BusinessDocFields.Invoice);

    let lineNetTotalHasChanged = false;

    let startingLineIndex = 0;
    let endingLineIndex = 1;
    if (lineIndex) {
        startingLineIndex = lineIndex;
        endingLineIndex = lineIndex + 1;
    } else if (recalculateForAllLines === true) {
        endingLineIndex = invoice.InvoiceLineItems.length;
    }

    for (let index = startingLineIndex; index < endingLineIndex; index++) {
        const oldLineNetTotal = invoice.InvoiceLineItems[index].LineItemNetTotal
            ? new BigNumber(invoice.InvoiceLineItems[index].LineItemNetTotal)
            : new BigNumber(0);

        let lineNetTotal = new BigNumber(invoice.InvoiceLineItems[index].LineItemTotal);

        layout.body.fields
            .filter((fieldLayout) => {
                return (
                    fieldLayout.ControlType === projectConfig.ControlType.Money &&
                    fieldLayout.EntityName === projectConfig.EntityName.LineItem
                );
            })
            .forEach((fieldLayout) => {
                let fieldValue = new BigNumber(0);
                fieldValue =
                    invoice.InvoiceLineItems[index][fieldLayout.FieldName] &&
                    invoice.InvoiceLineItems[index][fieldLayout.FieldName] !== null
                        ? new BigNumber(invoice.InvoiceLineItems[index][fieldLayout.FieldName])
                        : new BigNumber(0);

                switch (fieldLayout.AmountIndicator) {
                    case projectConfig.AmountIndicator.TotalAllowance:
                        lineNetTotal = new BigNumber(lineNetTotal.minus(fieldValue));
                        break;

                    case projectConfig.AmountIndicator.TotalCharge:
                    case null:
                        lineNetTotal = new BigNumber(lineNetTotal.plus(fieldValue));
                        break;

                    case projectConfig.AmountIndicator.NoAllowanceOrCharge:
                    case projectConfig.AmountIndicator.AmountDueAllowance:
                    case projectConfig.AmountIndicator.AmountDueCharge:
                    default:
                        break;
                }
            });

        invoice.InvoiceLineItems[index].InvoiceLineItemMiscAmounts.forEach((miscAmount) => {
            if (miscAmount && miscAmount !== null) {
                const fieldValue =
                    miscAmount && miscAmount !== null && miscAmount.Amount && miscAmount.Amount !== null
                        ? new BigNumber(miscAmount.Amount)
                        : new BigNumber(0);

                switch (Number(miscAmount.AmountIndicator)) {
                    case projectConfig.AmountIndicator.TotalAllowance:
                    case projectConfig.AmountIndicator.TotalCharge:
                    case null:
                        lineNetTotal = new BigNumber(lineNetTotal.plus(fieldValue));
                        break;

                    case projectConfig.AmountIndicator.NoAllowanceOrCharge:
                    case projectConfig.AmountIndicator.AmountDueAllowance:
                    case projectConfig.AmountIndicator.AmountDueCharge:
                    default:
                        break;
                }
            }
        });

        const lineItemNetTotalFieldLayout = cloneObjectHack(
            layout.body.fields.find((fieldLayout) => {
                return fieldLayout.FieldName === 'LineItemNetTotal';
            })
        );
        const lineItemNetTotalMaxDecimalPlaces = NumericUtility.DetermineMaxDecimalPlaces(
            lineItemNetTotalFieldLayout.ControlType,
            lineItemNetTotalFieldLayout.ControlFormat,
            ''
        );
        const roundedLineNetTotal = new BigNumber(lineNetTotal.toFixed(lineItemNetTotalMaxDecimalPlaces));

        if (!oldLineNetTotal.isEqualTo(roundedLineNetTotal)) {
            lineNetTotalHasChanged = true;

            dispatch(
                CreateInvoiceActions.performInvoiceFieldValueUpdate(
                    lineItemNetTotalFieldLayout,
                    'LineItemNetTotal',
                    Number(roundedLineNetTotal),
                    index
                )
            );
        }
    }

    // Only perform further updates if a line net total value has changed
    if (lineNetTotalHasChanged === true) {
        dispatch(calculateInvoiceSubtotal(layout, false));
    }
};
const calculateInvoiceSubtotal = (layout, triggerFurtherUpdates) => (dispatch, getState) => {
    // console.log('executing calculateInvoiceSubtotal');
    const state = getState();
    const invoice = cloneObjectHack(state.documentCreation.invoiceCreationFields.BusinessDocFields.Invoice);

    const oldSubtotal = invoice.DiscountableAmount;

    let subtotal = new BigNumber(0);
    invoice.InvoiceLineItems.forEach((invoiceLineItem) => {
        const lineNetTotal = new BigNumber(invoiceLineItem.LineItemNetTotal);
        subtotal = new BigNumber(subtotal.plus(lineNetTotal));
    });

    const subtotalFieldLayout = cloneObjectHack(
        state.documentCreation.editLayout.Large.Footer[0].Fields.find((fieldLayout) => {
            return fieldLayout.FieldName === 'DiscountableAmount';
        })
    );
    const subtotalMaxDecimalPlaces = NumericUtility.DetermineMaxDecimalPlaces(
        subtotalFieldLayout.ControlType,
        subtotalFieldLayout.ControlFormat,
        ''
    );
    const roundedSubtotal = new BigNumber(subtotal.toFixed(subtotalMaxDecimalPlaces));

    // Only trigger further updates if specified or the subtotal has changed
    if (triggerFurtherUpdates === true || !new BigNumber(oldSubtotal).isEqualTo(roundedSubtotal)) {
        dispatch(
            CreateInvoiceActions.performInvoiceFieldValueUpdate(
                subtotalFieldLayout,
                'DiscountableAmount',
                Number(roundedSubtotal),
                null
            )
        );
        dispatch(calculateInvoiceSalesTaxAmount(layout, oldSubtotal, invoice.SalesTaxPercent));
        dispatch(recalculateInvoiceMiscAmounts(layout, oldSubtotal, Number(roundedSubtotal)));
        dispatch(calculateInvoiceTotal());
    }
};
const calculateInvoiceSalesTaxAmount =
    (layout, previousSubtotal, previousSalesTaxPercent, forceUpdate) => (dispatch, getState) => {
        // console.log('executing calculateInvoiceSalesTaxAmount');
        const state = getState();
        const invoice = cloneObjectHack(state.documentCreation.invoiceCreationFields.BusinessDocFields.Invoice);

        const oldSubtotal = previousSubtotal ? new BigNumber(previousSubtotal) : new BigNumber(0);
        const oldSalesTaxPercent = previousSalesTaxPercent ? new BigNumber(previousSalesTaxPercent) : new BigNumber(0);
        const oldSalesTaxAmount = invoice.SalesTaxAmount ? new BigNumber(invoice.SalesTaxAmount) : new BigNumber(0);

        // Only recalculate the sales tax amount if the current amount was calculated from the previous subtotal and sales tax rate
        if (
            oldSubtotal.times(oldSalesTaxPercent.dividedBy(new BigNumber(100))).isEqualTo(oldSalesTaxAmount) ||
            forceUpdate
        ) {
            const subtotal = invoice.DiscountableAmount ? new BigNumber(invoice.DiscountableAmount) : new BigNumber(0);
            const salesTaxPercent = invoice.SalesTaxPercent ? new BigNumber(invoice.SalesTaxPercent) : new BigNumber(0);
            const salesTaxAmount = subtotal.times(salesTaxPercent.dividedBy(new BigNumber(100)));

            const salesTaxAmountFieldLayout = cloneObjectHack(
                state.documentCreation.editLayout.Large.Footer[0].Fields.find((fieldLayout) => {
                    return fieldLayout.FieldName === 'SalesTaxAmount';
                })
            );
            const salesTaxAmountMaxDecimalPlaces = NumericUtility.DetermineMaxDecimalPlaces(
                salesTaxAmountFieldLayout.ControlType,
                salesTaxAmountFieldLayout.ControlFormat,
                ''
            );
            const roundedSalesTaxAmount = new BigNumber(salesTaxAmount.toFixed(salesTaxAmountMaxDecimalPlaces));

            // Only update the store if the sales tax amount value has changed
            if (!oldSalesTaxAmount.isEqualTo(roundedSalesTaxAmount)) {
                dispatch(
                    CreateInvoiceActions.performInvoiceFieldValueUpdate(
                        salesTaxAmountFieldLayout,
                        'SalesTaxAmount',
                        Number(roundedSalesTaxAmount),
                        null
                    )
                );
            }
        }
    };
const recalculateInvoiceMiscAmount = (layout, miscAmountIndex, previousRate) => (dispatch, getState) => {
    // console.log('executing recalculateInvoiceMiscAmount');
    const state = getState();
    const invoice = cloneObjectHack(state.documentCreation.invoiceCreationFields.BusinessDocFields.Invoice);

    const oldRate = previousRate ? new BigNumber(previousRate) : new BigNumber(0);
    const subtotal = invoice.DiscountableAmount ? new BigNumber(invoice.DiscountableAmount) : new BigNumber(0);

    const miscAmount = invoice.InvoiceMiscAmounts[miscAmountIndex];
    if (miscAmount && miscAmount !== null) {
        const miscAmountFieldLayout = cloneObjectHack(
            layout.footer.fields.find((fieldLayout) => {
                return (
                    fieldLayout.EntityName === projectConfig.EntityName.MiscAmount &&
                    fieldLayout.AdjustmentReasonId.toString() === miscAmount.AdjustmentReasonId.toString()
                );
            })
        );
        const amountMaxDecimalPlaces = NumericUtility.DetermineMaxDecimalPlaces(
            miscAmountFieldLayout.ControlType,
            miscAmountFieldLayout.ControlFormat,
            ''
        );

        let roundedNewAmount = null;
        const flipSign =
            Number(miscAmount.AmountIndicator) === projectConfig.AmountIndicator.TotalAllowance ||
            Number(miscAmount.AmountIndicator) === projectConfig.AmountIndicator.AmountDueAllowance;
        const oldAmount =
            miscAmount.Amount && miscAmount.Amount !== null ? new BigNumber(miscAmount.Amount) : new BigNumber(0);
        const rate = miscAmount.Rate ? new BigNumber(miscAmount.Rate) : new BigNumber(0);
        if (
            (!flipSign && subtotal.times(oldRate).dividedBy(new BigNumber(100)).isEqualTo(oldAmount)) ||
            (flipSign &&
                subtotal.times(oldRate).times(new BigNumber(-1)).dividedBy(new BigNumber(100)).isEqualTo(oldAmount))
        ) {
            let newAmount = subtotal.times(rate).dividedBy(new BigNumber(100));
            if (flipSign) {
                newAmount = newAmount.times(new BigNumber(-1));
            }
            roundedNewAmount = new BigNumber(newAmount.toFixed(amountMaxDecimalPlaces));
        }

        // Update only if the value changed
        if (roundedNewAmount !== null && !oldAmount.isEqualTo(roundedNewAmount)) {
            dispatch(
                CreateInvoiceActions.performInvoiceFieldValueUpdate(
                    miscAmountFieldLayout,
                    'Amount',
                    Number(roundedNewAmount),
                    miscAmountIndex
                )
            );
        }
    }
};
const recalculateInvoiceMiscAmounts = (layout, previousSubtotal, newSubtotal) => (dispatch, getState) => {
    // console.log('executing recalculateInvoiceMiscAmounts');
    const state = getState();
    const invoice = cloneObjectHack(state.documentCreation.invoiceCreationFields.BusinessDocFields.Invoice);

    const subtotal = newSubtotal ? new BigNumber(newSubtotal) : new BigNumber(0);
    const oldSubtotal = previousSubtotal ? new BigNumber(previousSubtotal) : new BigNumber(0);

    if (invoice.InvoiceMiscAmounts) {
        for (let miscAmountIndex = 0; miscAmountIndex <= invoice.InvoiceMiscAmounts.length; miscAmountIndex++) {
            const miscAmount = invoice.InvoiceMiscAmounts[miscAmountIndex];
            if (miscAmount && miscAmount !== null && layout && layout.footer && layout.footer.fields) {
                const miscAmountFieldLayout = cloneObjectHack(
                    layout.footer.fields.find((fieldLayout) => {
                        return (
                            fieldLayout.EntityName === projectConfig.EntityName.MiscAmount &&
                            fieldLayout.AdjustmentReasonId.toString() === miscAmount.AdjustmentReasonId.toString()
                        );
                    })
                );
                const amountMaxDecimalPlaces = NumericUtility.DetermineMaxDecimalPlaces(
                    miscAmountFieldLayout && miscAmountFieldLayout.ControlType,
                    miscAmountFieldLayout && miscAmountFieldLayout.ControlFormat,
                    ''
                );

                let roundedNewAmount = null;
                const flipSign =
                    Number(miscAmount.AmountIndicator) === projectConfig.AmountIndicator.TotalAllowance ||
                    Number(miscAmount.AmountIndicator) === projectConfig.AmountIndicator.AmountDueAllowance;
                const currentAmount =
                    miscAmount.Amount && miscAmount.Amount !== null
                        ? new BigNumber(miscAmount.Amount)
                        : new BigNumber(0);

                let rate = miscAmount.Rate;
                if (rate && !subtotal.isEqualTo(oldSubtotal)) {
                    rate = new BigNumber(rate);
                    if (
                        (!flipSign && oldSubtotal.times(rate).dividedBy(new BigNumber(100)).isEqualTo(currentAmount)) ||
                        (flipSign &&
                            oldSubtotal
                                .times(rate)
                                .times(new BigNumber(-1))
                                .dividedBy(new BigNumber(100))
                                .isEqualTo(currentAmount))
                    ) {
                        let newAmount = subtotal.times(rate).dividedBy(new BigNumber(100));
                        if (flipSign) {
                            newAmount = newAmount.times(new BigNumber(-1));
                        }

                        roundedNewAmount = new BigNumber(newAmount.toFixed(amountMaxDecimalPlaces));
                    }
                }

                if (roundedNewAmount !== null) {
                    dispatch(
                        CreateInvoiceActions.performInvoiceFieldValueUpdate(
                            miscAmountFieldLayout,
                            'Amount',
                            Number(roundedNewAmount),
                            miscAmountIndex
                        )
                    );
                }
            }
        }
    }
};
const calculateInvoiceTotal = () => (dispatch, getState) => {
    // console.log('executing calculateInvoiceTotal');
    const state = getState();
    const invoice = cloneObjectHack(state.documentCreation.invoiceCreationFields.BusinessDocFields.Invoice);
    const oldTotal = invoice.InvoiceAmount ? new BigNumber(invoice.InvoiceAmount) : new BigNumber(0);

    const footerLayout =
        state.documentCreation.editLayout &&
        state.documentCreation.editLayout.Large &&
        state.documentCreation.editLayout.Large.Footer &&
        state.documentCreation.editLayout.Large.Footer !== undefined
            ? cloneObjectHack(state.documentCreation.editLayout.Large.Footer[0].Fields)
            : [];

    let total = new BigNumber(invoice.DiscountableAmount);
    footerLayout
        .filter((fieldLayout) => {
            return (
                [projectConfig.ControlType.Money, projectConfig.ControlType.Tax].includes(fieldLayout.ControlType) &&
                fieldLayout.EntityName === projectConfig.EntityName.Main
            );
        })
        .forEach((fieldLayout) => {
            let fieldValue = new BigNumber(0);
            if (fieldLayout.FieldName !== 'AmountPaid') {
                fieldValue =
                    invoice[fieldLayout.FieldName] && invoice[fieldLayout.FieldName] !== null
                        ? new BigNumber(invoice[fieldLayout.FieldName])
                        : new BigNumber(0);
            }

            switch (fieldLayout.AmountIndicator) {
                case projectConfig.AmountIndicator.TotalAllowance:
                    total = new BigNumber(total.minus(fieldValue));
                    break;

                case projectConfig.AmountIndicator.TotalCharge:
                case null:
                    total = new BigNumber(total.plus(fieldValue));
                    break;

                case projectConfig.AmountIndicator.NoAllowanceOrCharge:
                case projectConfig.AmountIndicator.AmountDueAllowance:
                case projectConfig.AmountIndicator.AmountDueCharge:
                default:
                    break;
            }
        });

    if (invoice.InvoiceMiscAmounts) {
        invoice.InvoiceMiscAmounts.forEach((miscAmount) => {
            if (miscAmount && miscAmount !== null) {
                const fieldValue =
                    miscAmount && miscAmount !== null && miscAmount.Amount && miscAmount.Amount !== null
                        ? new BigNumber(miscAmount.Amount)
                        : new BigNumber(0);

                switch (Number(miscAmount.AmountIndicator)) {
                    case projectConfig.AmountIndicator.TotalAllowance:
                    case projectConfig.AmountIndicator.TotalCharge:
                    case null:
                        total = new BigNumber(total.plus(fieldValue));
                        break;

                    case projectConfig.AmountIndicator.NoAllowanceOrCharge:
                    case projectConfig.AmountIndicator.AmountDueAllowance:
                    case projectConfig.AmountIndicator.AmountDueCharge:
                    default:
                        break;
                }
            }
        });
    }

    const totalFieldLayout = footerLayout.find((fieldLayout) => {
        return fieldLayout.FieldName === 'InvoiceAmount';
    });
    const totalMaxDecimalPlaces = NumericUtility.DetermineMaxDecimalPlaces(
        totalFieldLayout.ControlType,
        totalFieldLayout.ControlFormat,
        ''
    );
    const roundedTotal = new BigNumber(total.toFixed(totalMaxDecimalPlaces));

    // Only update the value if it has changed
    if (!oldTotal.isEqualTo(roundedTotal)) {
        dispatch(
            CreateInvoiceActions.performInvoiceFieldValueUpdate(
                totalFieldLayout,
                'InvoiceAmount',
                Number(roundedTotal),
                null
            )
        );
    }

    // We still call the calculation of amount due even if the total has not changed in case a misc amount exists that is only for calculating amount due.
    dispatch(calculateInvoiceAmountDue());
};
const calculateInvoiceAmountDue = () => (dispatch, getState) => {
    // console.log('executing calculateInvoiceAmountDue');
    const state = getState();
    const invoice = cloneObjectHack(state.documentCreation.invoiceCreationFields.BusinessDocFields.Invoice);
    const footerLayout = cloneObjectHack(state.documentCreation.editLayout.Large.Footer[0].Fields);

    const oldAmountDue = invoice.AmountDue ? new BigNumber(invoice.AmountDue) : new BigNumber(0);

    let amountDue = invoice.InvoiceAmount ? new BigNumber(invoice.InvoiceAmount) : new BigNumber(0);
    const amountPaid = invoice.AmountPaid ? new BigNumber(invoice.AmountPaid) : new BigNumber(0);

    amountDue = new BigNumber(amountDue.minus(amountPaid));

    footerLayout
        .filter((fieldLayout) => {
            return (
                [projectConfig.ControlType.Money, projectConfig.ControlType.Tax].includes(fieldLayout.ControlType) &&
                fieldLayout.EntityName === projectConfig.EntityName.Main
            );
        })
        .forEach((fieldLayout) => {
            let fieldValue = new BigNumber(0);
            fieldValue =
                invoice[fieldLayout.FieldName] && invoice[fieldLayout.FieldName] !== null
                    ? new BigNumber(invoice[fieldLayout.FieldName])
                    : new BigNumber(0);

            switch (fieldLayout.AmountIndicator) {
                case projectConfig.AmountIndicator.AmountDueAllowance:
                    amountDue = new BigNumber(amountDue.minus(fieldValue));
                    break;

                case projectConfig.AmountIndicator.AmountDueCharge:
                    amountDue = new BigNumber(amountDue.plus(fieldValue));
                    break;

                case projectConfig.AmountIndicator.TotalAllowance:
                case projectConfig.AmountIndicator.TotalCharge:
                case projectConfig.AmountIndicator.NoAllowanceOrCharge:
                case null:
                default:
                    break;
            }
        });

    if (invoice.InvoiceMiscAmounts) {
        invoice.InvoiceMiscAmounts.forEach((miscAmount) => {
            if (miscAmount && miscAmount !== null) {
                const fieldValue =
                    miscAmount && miscAmount !== null && miscAmount.Amount && miscAmount.Amount !== null
                        ? new BigNumber(miscAmount.Amount)
                        : new BigNumber(0);

                switch (Number(miscAmount.AmountIndicator)) {
                    case projectConfig.AmountIndicator.AmountDueAllowance:
                    case projectConfig.AmountIndicator.AmountDueCharge:
                        amountDue = new BigNumber(amountDue.plus(fieldValue));
                        break;

                    case projectConfig.AmountIndicator.TotalAllowance:
                    case projectConfig.AmountIndicator.TotalCharge:
                    case projectConfig.AmountIndicator.NoAllowanceOrCharge:
                    case null:
                    default:
                        break;
                }
            }
        });
    }

    const amountDueFieldLayout = footerLayout.find((fieldLayout) => {
        return fieldLayout.FieldName === 'AmountDue';
    });
    const amountDueMaxDecimalPlaces = NumericUtility.DetermineMaxDecimalPlaces(
        amountDueFieldLayout.ControlType,
        amountDueFieldLayout.ControlFormat,
        ''
    );
    const roundedAmountDue = new BigNumber(amountDue.toFixed(amountDueMaxDecimalPlaces));

    // Only update amount due if its value has changed
    if (!oldAmountDue.isEqualTo(roundedAmountDue)) {
        dispatch(
            CreateInvoiceActions.performInvoiceFieldValueUpdate(
                amountDueFieldLayout,
                'AmountDue',
                Number(roundedAmountDue),
                null
            )
        );
    }
};
const calculateInvoiceTotalVAT = () => (dispatch, getState) => {
    // console.log('executing calculateInvoiceTotalVAT');
    const state = getState();
    const invoice = state.documentCreation.invoiceCreationFields.BusinessDocFields.Invoice;
    let bodyLayout = state.documentCreation.editLayout.Large.Body[0].Fields;
    for (let index = 1; index < state.documentCreation.editLayout.Large.Body.length; index++) {
        bodyLayout = bodyLayout.concat(state.documentCreation.editLayout.Large.Body[index].Fields);
    }
    const footerLayout = state.documentCreation.editLayout.Large.Footer[0].Fields;
    const totalVATFieldLayout = footerLayout.find((fieldLayout) => {
        return fieldLayout.FieldName === 'TotalVAT';
    });

    const oldTotalVAT = invoice.totalVAT ? new BigNumber(invoice.TotalVAT) : new BigNumber(0);
    let totalVAT = new BigNumber(0);

    const bodyVATMiscAmountLayouts = bodyLayout.filter((fieldLayout) => {
        return (
            fieldLayout.EntityName === projectConfig.EntityName.LineItemMiscAmount &&
            fieldLayout.FieldName.toLowerCase() === 'vat'
        );
    });
    invoice.InvoiceLineItems.forEach((lineItem) => {
        if (lineItem.InvoiceLineItemMiscAmounts !== undefined) {
            lineItem.InvoiceLineItemMiscAmounts.filter((miscAmount) => {
                return bodyVATMiscAmountLayouts
                    ? Array.isArray(bodyVATMiscAmountLayouts)
                        ? bodyVATMiscAmountLayouts.find((fieldLayout) => {
                              return (
                                  fieldLayout.AdjustmentReasonId.toString() ===
                                      miscAmount.AdjustmentReasonId.toString() &&
                                  fieldLayout.AdjustmentMethodId.toString() ===
                                      miscAmount.AdjustmentMethodId.toString() &&
                                  fieldLayout.AmountIndicator.toString() === miscAmount.AmountIndicator.toString()
                              );
                          })
                        : bodyVATMiscAmountLayouts.AdjustmentReasonId.toString() ===
                              miscAmount.AdjustmentReasonId.toString() &&
                          bodyVATMiscAmountLayouts.AdjustmentMethodId.toString() ===
                              miscAmount.AdjustmentMethodId.toString() &&
                          bodyVATMiscAmountLayouts.AmountIndicator.toString() === miscAmount.AmountIndicator.toString()
                    : false;
            }).forEach((miscAmount) => {
                const fieldValue =
                    miscAmount && miscAmount !== null && miscAmount.Amount && miscAmount.Amount !== null
                        ? new BigNumber(miscAmount.Amount)
                        : new BigNumber(0);

                totalVAT = new BigNumber(totalVAT.plus(fieldValue));
            });
        }
    });

    const footerVATMiscAmountLayouts = footerLayout.find((fieldLayout) => {
        return (
            fieldLayout.EntityName === projectConfig.EntityName.MiscAmount &&
            fieldLayout.FieldName.toLowerCase() === 'vat'
        );
    });
    invoice.InvoiceMiscAmounts.filter((miscAmount) => {
        return footerVATMiscAmountLayouts
            ? Array.isArray(footerVATMiscAmountLayouts)
                ? footerVATMiscAmountLayouts.find((fieldLayout) => {
                      return (
                          fieldLayout.AdjustmentReasonId.toString() === miscAmount.AdjustmentReasonId.toString() &&
                          fieldLayout.AdjustmentMethodId.toString() === miscAmount.AdjustmentMethodId.toString() &&
                          fieldLayout.AmountIndicator.toString() === miscAmount.AmountIndicator.toString()
                      );
                  })
                : footerVATMiscAmountLayouts.AdjustmentReasonId.toString() ===
                      miscAmount.AdjustmentReasonId.toString() &&
                  footerVATMiscAmountLayouts.AdjustmentMethodId.toString() ===
                      miscAmount.AdjustmentMethodId.toString() &&
                  footerVATMiscAmountLayouts.AmountIndicator.toString() === miscAmount.AmountIndicator.toString()
            : false;
    }).forEach((miscAmount) => {
        const fieldValue =
            miscAmount && miscAmount !== null && miscAmount.Amount && miscAmount.Amount !== null
                ? new BigNumber(miscAmount.Amount)
                : new BigNumber(0);

        totalVAT = new BigNumber(totalVAT.plus(fieldValue));
    });

    const totalVATMaxDecimalPlaces = NumericUtility.DetermineMaxDecimalPlaces(
        totalVATFieldLayout.ControlType,
        totalVATFieldLayout.ControlFormat,
        ''
    );
    const roundedTotalVAT = new BigNumber(totalVAT.toFixed(totalVATMaxDecimalPlaces));

    if (oldTotalVAT === null || !roundedTotalVAT.isEqualTo(oldTotalVAT)) {
        dispatch(
            CreateInvoiceActions.performInvoiceFieldValueUpdate(
                totalVATFieldLayout,
                'TotalVAT',
                Number(roundedTotalVAT),
                null
            )
        );
    }
};
const updateSelectedCurrency = (currencyType) => (dispatch, getState) => {
    // console.log('executing updateSelectedCurrency');
    const state = getState();
    const currencyList = state.userlogin.currencyList;

    let matchingCurrency = null;
    for (let c = 0; matchingCurrency === null && c < currencyList.length; c++) {
        if (currencyList[c].CurrencyType.toString() === currencyType.toString()) {
            matchingCurrency = currencyList[c];
        }
    }

    let selectedCurrency;
    if (matchingCurrency === null) {
        selectedCurrency = InvoiceModels.defaultSelectedCurrency;
    } else {
        selectedCurrency = {
            Code: matchingCurrency.CurrencyType_US,
            Symbol: matchingCurrency.Symbol,
        };
    }

    dispatch(CreateInvoiceActions.performSelectedCurrencyUpdate(selectedCurrency));
};

const updateSelectedInvoiceProfile = (InvoiceProfileID, oldInvoiceProfileId) => (dispatch, getState) => {
    // console.log('executing updateSelectedInvoiceProfile');
    if (oldInvoiceProfileId !== InvoiceProfileID) {
        const profiles = getState().profiles.data;
        const matchingProfile = profiles.filter((profile) => profile.Id === InvoiceProfileID)[0];
        const selectedProfile = {
            value: matchingProfile.Id,
            label: matchingProfile.Name,
        };
        dispatch(CreateInvoiceActions.performSelectedInvoiceProfileUpdate(selectedProfile));

        const oldMatchingProfile = oldInvoiceProfileId
            ? profiles.filter((profile) => profile.Id === oldInvoiceProfileId)[0]
            : null;
        dispatch(updateFieldsAffectedByInvoiceProfile(matchingProfile, oldMatchingProfile));
    }
};

const updateFieldsAffectedByInvoiceProfile = (invoiceProfile, oldInvoiceProfile) => (dispatch, getState) => {
    const state = getState();
    const invoice = cloneObjectHack(state.documentCreation.invoiceCreationFields.BusinessDocFields.Invoice);
    const layout = cloneObjectHack(state.documentLayout.layout);

    const currencyFieldLayout = getFieldLayoutRecord(layout, projectConfig.EntityName.Main, 'Currency');
    if (currencyFieldLayout) {
        const oldCurrencyList = currencyListSelector(
            state,
            projectConfig.businessDocType.Invoice,
            oldInvoiceProfile.Id
        );
        const oldCurrencyCode = state.documentCreation.invoiceCreationFields.SelectedCurrency.Code;

        // If the currency dropdown currently only contains one entry, and the new invoice profile doesn't have a default currency,
        // update the selected currency to be the default currency from the selected customer record if one exists.
        // Otherwise, update it back to USD by default.
        if (
            (!invoiceProfile.Currency ||
                IsUndefinedNullOrEmpty(invoiceProfile.Currency_US) ||
                invoiceProfile.Currency_US.toLowerCase() === 'multiple') &&
            oldCurrencyList.length === 1
        ) {
            const customerId = Number(invoice.CustomerID);
            let customer = state.documentCreation.customerLocation.Items.filter(
                (customer) => customer.ID === customerId
            );
            customer = customer.length > 0 ? customer[0] : null;

            const customerDefaultCurrency = customer ? customer.DefaultCurrency : 1; // USD

            dispatch(
                CreateInvoiceActions.performInvoiceFieldValueUpdate(
                    currencyFieldLayout,
                    'Currency',
                    customerDefaultCurrency,
                    null
                )
            );
            dispatch(updateSelectedCurrency(customerDefaultCurrency));
        }
        // If the currency dropdown currently contains all the currency options, but the new invoice profile is set to bill with only one currency,
        // update the selected currency to be the default currency on the newly selected invoice profile.
        else if (
            invoiceProfile.Currency &&
            IsNotUndefinedNullOrEmpty(invoiceProfile.Currency_US) &&
            invoiceProfile.Currency_US.toLowerCase() !== 'multiple' &&
            (oldCurrencyList.length > 1 || invoiceProfile.Currency_US.toLowerCase() !== oldCurrencyCode.toLowerCase())
        ) {
            dispatch(
                CreateInvoiceActions.performInvoiceFieldValueUpdate(
                    currencyFieldLayout,
                    'Currency',
                    invoiceProfile.Currency,
                    null
                )
            );
            dispatch(updateSelectedCurrency(invoiceProfile.Currency));
        }
    }

    const supplierVATNumberFieldLayout = getFieldLayoutRecord(
        layout,
        projectConfig.EntityName.Main,
        'SupplierVATNumber'
    );
    if (supplierVATNumberFieldLayout) {
        // If the supplier VAT number contains the default value from the previously selected invoice profile,
        // update the value with the default for the newly selected invoice profile.
        const buyerDestinationProfileId = state.selectCustomer.currentCustomerLocation.BuyerDestinationProfileId;
        let defaultVATNumbers = defaultVATNumberSelector(state, oldInvoiceProfile.Id, buyerDestinationProfileId);
        const oldDefaultSupplierVATNumber = defaultVATNumbers.supplierVATNumber;
        if (
            IsUndefinedNullOrEmpty(invoice.SupplierVATNumber) ||
            invoice.SupplierVATNumber === oldDefaultSupplierVATNumber
        ) {
            defaultVATNumbers = defaultVATNumberSelector(state, invoiceProfile.Id, buyerDestinationProfileId);
            const defaultSupplierVATNumber = defaultVATNumbers.supplierVATNumber;

            dispatch(
                CreateInvoiceActions.performInvoiceFieldValueUpdate(
                    supplierVATNumberFieldLayout,
                    'SupplierVATNumber',
                    defaultSupplierVATNumber,
                    null
                )
            );
        }
    }

    invoice.InvoiceContacts.forEach((contact) => {
        switch (contact.ContactType) {
            case projectConfig.ContactType.RemitTo:
                const remitToAddressBlockFieldLayout = getFieldLayoutRecord(
                    layout,
                    projectConfig.EntityName.Contact,
                    'AddressBlock',
                    contact.ContactType
                );
                if (remitToAddressBlockFieldLayout) {
                    // If the address field for the contact contains the default value from the previously selected Invoice Profile,
                    // update the value with the remit to address block from the newly selected Invoice Profile if it exists.
                    // Otherwise, update the value with the address from the newly selected Invoice Profile.
                    const oldDefaultRemitToAddressBlock =
                        oldInvoiceProfile.RemitToAddressBlock && oldInvoiceProfile.RemitToAddressBlock !== ''
                            ? oldInvoiceProfile.RemitToAddressBlock
                            : CreateAddressBlock({
                                  line1: oldInvoiceProfile.Address1,
                                  line2: oldInvoiceProfile.Address2,
                                  line3: oldInvoiceProfile.Address3,
                                  line4: oldInvoiceProfile.Address4,
                                  city: oldInvoiceProfile.City,
                                  state: oldInvoiceProfile.State,
                                  zip: oldInvoiceProfile.PostalCode,
                                  country: oldInvoiceProfile.Country,
                              });
                    if (
                        IsUndefinedNullOrEmpty(contact.AddressBlock) ||
                        contact.AddressBlock === oldDefaultRemitToAddressBlock
                    ) {
                        const newDefaultRemitToAddressBlock =
                            invoiceProfile.RemitToAddressBlock && invoiceProfile.RemitToAddressBlock !== ''
                                ? invoiceProfile.RemitToAddressBlock
                                : CreateAddressBlock({
                                      line1: invoiceProfile.Address1,
                                      line2: invoiceProfile.Address2,
                                      line3: invoiceProfile.Address3,
                                      line4: invoiceProfile.Address4,
                                      city: invoiceProfile.City,
                                      state: invoiceProfile.State,
                                      zip: invoiceProfile.PostalCode,
                                      country: invoiceProfile.Country,
                                  });
                        dispatch(
                            CreateInvoiceActions.performInvoiceFieldValueUpdate(
                                remitToAddressBlockFieldLayout,
                                'AddressBlock',
                                newDefaultRemitToAddressBlock,
                                null
                            )
                        );
                    }
                }

                const remitToNameFieldLayout = getFieldLayoutRecord(
                    layout,
                    projectConfig.EntityName.Contact,
                    'Name',
                    contact.ContactType
                );
                if (remitToNameFieldLayout) {
                    // If the name field for the contact contains the default value from the previously selected Invoice Profile,
                    // update the value with the name from the newly selected Invoice Profile.
                    if (contact.Name === oldInvoiceProfile.Name) {
                        dispatch(
                            CreateInvoiceActions.performInvoiceFieldValueUpdate(
                                remitToNameFieldLayout,
                                'Name',
                                invoiceProfile.Name,
                                null
                            )
                        );
                    }
                }
                break;
            case projectConfig.ContactType.Vendor:
                const vendorAddressBlockFieldLayout = getFieldLayoutRecord(
                    layout,
                    projectConfig.EntityName.Contact,
                    'AddressBlock',
                    contact.ContactType
                );
                if (vendorAddressBlockFieldLayout) {
                    // If the address field for the contact contains the default value from the previously selected Invoice Profile,
                    // update the value with the address from the newly selected Invoice Profile.
                    const oldDefaultVendorAddressBlock = CreateAddressBlock({
                        line1: oldInvoiceProfile.Address1,
                        line2: oldInvoiceProfile.Address2,
                        line3: oldInvoiceProfile.Address3,
                        line4: oldInvoiceProfile.Address4,
                        city: oldInvoiceProfile.City,
                        state: oldInvoiceProfile.State,
                        zip: oldInvoiceProfile.PostalCode,
                        country: oldInvoiceProfile.Country,
                    });
                    if (
                        IsUndefinedNullOrEmpty(contact.AddressBlock) ||
                        contact.AddressBlock === oldDefaultVendorAddressBlock
                    ) {
                        const newDefaultVendorAddressBlock = CreateAddressBlock({
                            line1: invoiceProfile.Address1,
                            line2: invoiceProfile.Address2,
                            line3: invoiceProfile.Address3,
                            line4: invoiceProfile.Address4,
                            city: invoiceProfile.City,
                            state: invoiceProfile.State,
                            zip: invoiceProfile.PostalCode,
                            country: invoiceProfile.Country,
                        });
                        dispatch(
                            CreateInvoiceActions.performInvoiceFieldValueUpdate(
                                vendorAddressBlockFieldLayout,
                                'AddressBlock',
                                newDefaultVendorAddressBlock,
                                null
                            )
                        );
                    }
                }

                const vendorNameFieldLayout = getFieldLayoutRecord(
                    layout,
                    projectConfig.EntityName.Contact,
                    'Name',
                    contact.ContactType
                );
                if (vendorNameFieldLayout) {
                    // If the name field for the contact contains the default value from the previously selected Invoice Profile,
                    // update the value with the name from the newly selected Invoice Profile.
                    if (contact.Name === oldInvoiceProfile.Name) {
                        dispatch(
                            CreateInvoiceActions.performInvoiceFieldValueUpdate(
                                vendorNameFieldLayout,
                                'Name',
                                invoiceProfile.Name,
                                null
                            )
                        );
                    }
                }
                break;
            default:
                break;
        }
    });
};

export const performChangeInvoiceField = (fieldName, value, isNewLine) => (dispatch) => {
    // console.log('executing performChangeInvoiceField');
    dispatch(CreateInvoiceActions.performChangeInvoiceField(fieldName, value));

    if (!isNewLine) {
        dispatch(performFieldCountingInvoice());
    }
};

export const performChangeInvoice = (fieldName, value) => (dispatch, getState) => {
    // console.log('executing performChangeInvoice');
    dispatch(CreateInvoiceActions.performChangeInvoice(fieldName, value));
};

export const performChangeDocument = (fieldName, value) => (dispatch, getState) => {
    // console.log('executing performChangeDocument');
    dispatch(documentCreationActions.performDocumentLoad(value));
};

export function addMiscAmount(miscAmount, businessDocType) {
    // console.log('executing addMiscAmount');

    switch (businessDocType) {
        case projectConfig.businessDocType.Invoice:
            return (dispatch) => {
                dispatch(documentCreationActions.documentCreationUpdatingFieldsBegin());
                dispatch(CreateInvoiceActions.performAddInvoiceMiscAmount(miscAmount));
                dispatch(calculateInvoiceTotal());
                dispatch(calculateInvoiceTotalVAT());
                dispatch(documentCreationActions.documentCreationUpdatingFieldsComplete());
            };

        case projectConfig.businessDocType.PurchaseOrderAcknowledgement:
            return (dispatch, getState) => {
                const state = getState();
                const layout = layoutSelector(state, true, businessDocType);
                if (
                    state.documentCreation.documentCreationFields.BusinessDocFields &&
                    state.documentCreation.documentCreationFields.BusinessDocFields.BusinessDocument
                ) {
                    const businessDocument = cloneObjectHack(
                        state.documentCreation.documentCreationFields.BusinessDocFields.BusinessDocument
                    );
                    const firstMiscAmountFieldLayout = layout.footer.fields.find(
                        (fieldLayout) => fieldLayout.EntityName === projectConfig.EntityName.MiscAmount
                    );

                    if (firstMiscAmountFieldLayout) {
                        dispatch(documentCreationActions.documentCreationUpdatingFieldsBegin());

                        const headerPath = `$${firstMiscAmountFieldLayout.sectionJsonPath}${
                            firstMiscAmountFieldLayout.cellJsonPath ? `.${firstMiscAmountFieldLayout.cellJsonPath}` : ''
                        }`;
                        const headerNodes = jsonpath.nodes(businessDocument, headerPath);
                        const header = headerNodes[0].value;
                        let miscAmounts = jsonpath.nodes(header, '$.MiscAmounts.MiscAmount');
                        miscAmounts =
                            miscAmounts &&
                            miscAmounts.length > 0 &&
                            miscAmounts[0].value &&
                            miscAmounts[0].value.length > 0
                                ? cloneObjectHack(miscAmounts[0].value)
                                : [];

                        miscAmounts.push(miscAmount);
                        jsonpath.value(header, '$.MiscAmounts.MiscAmount', miscAmounts);

                        dispatch(calculateGenericTotal(layout, businessDocument));
                        dispatch(documentCreationActions.performDocumentFieldValueUpdate(businessDocument));
                        dispatch(documentCreationActions.documentCreationUpdatingFieldsComplete());
                    }
                }
            };

        default:
            return null;
    }
}

function clearMiscAmounts(businessDocType) {
    // console.log(executing clearMiscAmounts);
    switch (businessDocType) {
        case projectConfig.businessDocType.PurchaseOrderAcknowledgement:
            return (dispatch, getState) => {
                const state = getState();
                const layout = layoutSelector(state, true, businessDocType);
                if (
                    state.documentCreation.documentCreationFields.BusinessDocFields &&
                    state.documentCreation.documentCreationFields.BusinessDocFields.BusinessDocument
                ) {
                    const businessDocument = cloneObjectHack(
                        state.documentCreation.documentCreationFields.BusinessDocFields.BusinessDocument
                    );
                    const firstMiscAmountFieldLayout = layout.footer.fields.find(
                        (fieldLayout) => fieldLayout.EntityName === projectConfig.EntityName.MiscAmount
                    );

                    if (firstMiscAmountFieldLayout) {
                        dispatch(documentCreationActions.documentCreationUpdatingFieldsBegin());

                        const headerPath = `$${firstMiscAmountFieldLayout.sectionJsonPath}${
                            firstMiscAmountFieldLayout.cellJsonPath ? `.${firstMiscAmountFieldLayout.cellJsonPath}` : ''
                        }`;
                        const headerNodes = jsonpath.nodes(businessDocument, headerPath);
                        if (headerNodes && headerNodes.length > 0) {
                            const header = headerNodes[0].value;
                            jsonpath.value(header, '$.MiscAmounts.MiscAmount', []);
                        }

                        dispatch(calculateGenericTotal(layout, businessDocument.Document));
                        dispatch(documentCreationActions.performDocumentFieldValueUpdate(businessDocument));
                        dispatch(documentCreationActions.documentCreationUpdatingFieldsComplete());
                    }
                }
            };

        default:
            return null;
    }
}

export function updateMiscAmount(miscAmount, index, businessDocType) {
    // console.log('executing updateMiscAmount');
    switch (businessDocType) {
        case projectConfig.businessDocType.Invoice:
            return (dispatch) => {
                dispatch(documentCreationActions.documentCreationUpdatingFieldsBegin());
                dispatch(CreateInvoiceActions.performUpdateInvoiceMiscAmount(miscAmount, index));
                dispatch(calculateInvoiceTotal());
                dispatch(calculateInvoiceTotalVAT());
                dispatch(documentCreationActions.documentCreationUpdatingFieldsComplete());
            };

        case projectConfig.businessDocType.PurchaseOrderAcknowledgement:
            return (dispatch, getState) => {
                const state = getState();
                const layout = layoutSelector(state, true, businessDocType);
                if (
                    state.documentCreation.documentCreationFields.BusinessDocFields &&
                    state.documentCreation.documentCreationFields.BusinessDocFields.BusinessDocument
                ) {
                    const businessDocument = cloneObjectHack(
                        state.documentCreation.documentCreationFields.BusinessDocFields.BusinessDocument
                    );
                    const firstMiscAmountFieldLayout = layout.footer.fields.find(
                        (fieldLayout) => fieldLayout.EntityName === projectConfig.EntityName.MiscAmount
                    );

                    if (firstMiscAmountFieldLayout) {
                        dispatch(documentCreationActions.documentCreationUpdatingFieldsBegin());

                        const headerPath = `$${firstMiscAmountFieldLayout.sectionJsonPath}${
                            firstMiscAmountFieldLayout.cellJsonPath ? `.${firstMiscAmountFieldLayout.cellJsonPath}` : ''
                        }`;
                        const headerNodes = jsonpath.nodes(businessDocument, headerPath);
                        const header = headerNodes[0].value;
                        let miscAmounts = jsonpath.nodes(header, '$.MiscAmounts.MiscAmount');
                        miscAmounts =
                            miscAmounts &&
                            miscAmounts.length > 0 &&
                            miscAmounts[0].value &&
                            miscAmounts[0].value.length > 0
                                ? cloneObjectHack(miscAmounts[0].value)
                                : [];

                        miscAmounts[index] = miscAmount;
                        jsonpath.value(header, '$.MiscAmounts.MiscAmount', miscAmounts);

                        dispatch(calculateGenericTotal(layout, businessDocument));
                        dispatch(documentCreationActions.performDocumentFieldValueUpdate(businessDocument));
                        dispatch(documentCreationActions.documentCreationUpdatingFieldsComplete());
                    }
                }
            };

        default:
            return null;
    }
}

export function deleteMiscAmount(index, businessDocType) {
    // console.log('executing deleteMiscAmount');
    switch (businessDocType) {
        case projectConfig.businessDocType.Invoice:
            return (dispatch) => {
                dispatch(documentCreationActions.documentCreationUpdatingFieldsBegin());
                dispatch(CreateInvoiceActions.performDeleteInvoiceMiscAmount(index));
                dispatch(calculateInvoiceTotal());
                dispatch(calculateInvoiceTotalVAT());
                dispatch(documentCreationActions.documentCreationUpdatingFieldsComplete());
            };

        case projectConfig.businessDocType.PurchaseOrderAcknowledgement:
            return (dispatch, getState) => {
                const state = getState();
                const layout = layoutSelector(state, true, businessDocType);
                if (
                    state.documentCreation.documentCreationFields.BusinessDocFields &&
                    state.documentCreation.documentCreationFields.BusinessDocFields.BusinessDocument
                ) {
                    const businessDocument = cloneObjectHack(
                        state.documentCreation.documentCreationFields.BusinessDocFields.BusinessDocument
                    );
                    const firstMiscAmountFieldLayout = layout.footer.fields.find(
                        (fieldLayout) => fieldLayout.EntityName === projectConfig.EntityName.MiscAmount
                    );

                    if (firstMiscAmountFieldLayout) {
                        dispatch(documentCreationActions.documentCreationUpdatingFieldsBegin());

                        const headerPath = `$${firstMiscAmountFieldLayout.sectionJsonPath}${
                            firstMiscAmountFieldLayout.cellJsonPath ? `.${firstMiscAmountFieldLayout.cellJsonPath}` : ''
                        }`;
                        const headerNodes = jsonpath.nodes(businessDocument, headerPath);
                        const header = headerNodes[0].value;
                        let miscAmounts = jsonpath.nodes(header, '$.MiscAmounts.MiscAmount');
                        miscAmounts =
                            miscAmounts &&
                            miscAmounts.length > 0 &&
                            miscAmounts[0].value &&
                            miscAmounts[0].value.length > 0
                                ? cloneObjectHack(miscAmounts[0].value)
                                : [];

                        miscAmounts.splice(index, 1);
                        jsonpath.value(header, '$.MiscAmounts.MiscAmount', miscAmounts);

                        dispatch(calculateGenericTotal(layout, businessDocument));
                        dispatch(documentCreationActions.performDocumentFieldValueUpdate(businessDocument));
                        dispatch(documentCreationActions.documentCreationUpdatingFieldsComplete());
                    }
                }
            };

        default:
            return null;
    }
}

function removeEmptyMiscAmounts(miscAmounts) {
    return miscAmounts.filter((x) => x.Amount !== '0' && x.Amount !== 0 && x.Amount != null);
}

function removePOAEmptyMiscAmounts(doc) {
    try {
        doc.MiscAmounts.MiscAmount = removeEmptyMiscAmounts(doc.MiscAmounts.MiscAmount);
    } catch (e) {
        // ignore the error - we expect some documents to not have misc amounts
    }

    doc.LineItems.LineItem.forEach((lineItem) => {
        try {
            lineItem.MiscAmounts.MiscAmount = removeEmptyMiscAmounts(lineItem.MiscAmounts.MiscAmount);
        } catch (e) {
            // ignore the error - we expect some document lines to not have misc amounts
        }
    });
}
function removeInvoiceEmptyMiscAmounts(doc) {
    try {
        doc.InvoiceMiscAmounts = removeEmptyMiscAmounts(doc.InvoiceMiscAmounts);
    } catch (e) {
        // ignore the error - we expect some documents to not have misc amounts
    }

    doc.InvoiceLineItems.forEach((lineItem) => {
        try {
            lineItem.InvoiceMiscAmounts = removeEmptyMiscAmounts(lineItem.InvoiceMiscAmounts);
        } catch (e) {
            // ignore the error - we expect some document lines to not have misc amounts
        }
    });
}

function removeUnusedDocumentFields(data) {
    // remove unused fields for POA

    if (data.BusinessDocType === BusinessDocType.PurchaseOrderAcknowledgement) {
        // POA contains a bunch of "default misc amounts". We never display these, but due to performance issues in our rendering
        // engine, these slow down rendering of the document. The simplest solution is to remove this unused data before the application
        // can use it.
        removePOAEmptyMiscAmounts(
            data.BusinessDocFields.BusinessDocument.Document.BusinessDocument.PurchaseOrderAcknowledgement
        );
    }
    if (data.BusinessDocType === BusinessDocType.Invoice) {
        removeInvoiceEmptyMiscAmounts(data.BusinessDocFields.Invoice);
    }
}

/*
 * Thunk dispatched by "Document Edit Container" screen
 */
export const performFlipToInvoice = (customerId) => async (dispatch, getState) => {
    const state = getState();

    const lineItems = state.documentCreation.flip.lineItems;
    const sourceId = state.documentCreation.flip.sourceId;
    const lineItemIds = lineItems ? lineItems.map((line) => line.Id).join(',') : null;

    const flippedInvoice = await dispatch(
        getInvoiceFlipData(sourceId, projectConfig.businessDocType.Invoice, lineItemIds, customerId)
    );

    dispatch(documentCreationActions.fetchInvoiceDocumentForEditSuccess(flippedInvoice));

    return flippedInvoice;
};

export const getFlippedDocumentFields =
    (sourceId, docType, lineItems, customerLocationId) => async (dispatch, getState) => {
        const documentThunk = new TranscpetaThunks.DocumentThunk();

        const params = {
            sourceDocumentId: sourceId,
            destinationBusinessDocType: docType,
            sourceDocumentLineIds: lineItems.length > 0 ? lineItems.map((x) => x).join(',') : '',
            customerId: customerLocationId,
            // We need the layout that is pulled during this request to return as many fields as possible to get the most accurate generic document xml strcuture.
            // CSP is highly likely to contain the superset of all fields that would be in either the buyer or supplier portal for any given doc type which is why we pass it here.
            client: 2, // CSP
        };
        const response = await documentThunk.flipDocument(params);
        if (response.type && response.type === 'error') {
            dispatch(apiErrorAction(response.text, null, params));
        } else {
            removeUnusedDocumentFields(response.data);
            dispatch(documentCreationActions.performDocumentLoad(response.data));
        }
    };

const cleanupFlipResponse = (document) => ({
    ...document,
    BusinessDocFields: {
        ...document.BusinessDocFields,
        Invoice: {
            ...document.BusinessDocFields.Invoice,
            // InvoiceDate needs to always get defaulted by the UI client
            InvoiceDate: null,
            // InvoiceLineItems come back with 0 for the ids, we need to make them unique, otherwise
            // only the last one will render
            InvoiceLineItems: document.BusinessDocFields.Invoice.InvoiceLineItems.map((lineItem, i) => ({
                ...lineItem,
                Id: i,
            })),
        },
    },
});

export const getInvoiceFlipData =
    (sourceId, docType, sourceDocumentLineIds, customerLocationId) => async (dispatch) => {
        const documentThunk = new TranscpetaThunks.DocumentThunk();

        const params = {
            sourceDocumentId: sourceId,
            destinationBusinessDocType: docType,
            ...(sourceDocumentLineIds && { sourceDocumentLineIds }),
            customerId: customerLocationId, // This is customerLocationId, API param name is confusing
            client: 2, // CSP
        };
        const response = await documentThunk.flipDocument(params);

        if (response.type && response.type === 'error') {
            dispatch(apiErrorAction(response.text, null, params));
        }

        return cleanupFlipResponse(response.data);
    };

export const saveFlipLineItems = (lineItems) => (dispatch) => {
    dispatch(documentCreationActions.saveFlipLineItems(lineItems));
};

export const saveFlipSource = (documentId, businessDocType) => (dispatch) => {
    dispatch(documentCreationActions.saveFlipSource(documentId, businessDocType));
};

export const setFlip = (flip) => (dispatch) => {
    dispatch(documentCreationActions.setFlip(flip));
};

/**
 * Recursively trims all string valued properties on an object.
 *
 * Note that the object is mutated.
 */
function recursivelyTrimStringProperties(obj) {
    // need to iterate over object properties and make recursive call on their values, then
    // update the property value to the result of the recursive call
    if (typeof obj === 'object' && obj) {
        Object.keys(obj).forEach((key) => {
            obj[key] = recursivelyTrimStringProperties(obj[key]);
        });

        // need to return the object even though we are mutating it because we might
        // be in a recursive call and the caller is going to update their property value to
        // the returned value
        return obj;
    }

    // if we encounter a string in a recursive call, trim it
    if (typeof obj === 'string') {
        return obj.trim();
    }

    // return any other value untouched
    return obj;
}

export const initializeDocumentCreation =
    (
        buyerId, // selected customer id (buyer company entity)
        customerId, // selected customer location id (customer entity)
        draftId,
        documentId,
        businessDocType
    ) =>
    async (dispatch, getState) => {
        if (isFeatureEnabled('DocumentTotalsCalculationsV2')) {
            resetOriginalDocument();
        }

        dispatch(documentCreationActions.documentCreationInitializationBegin());
        const supplierCompanyId = portalUserService.getCurrentCompanyId();
        const edit = document.URL.indexOf('edit') > -1;
        const processingMode =
            getState().selectCustomer && getState().selectCustomer.currentCustomer
                ? getState().selectCustomer.currentCustomer.ProcessingMode
                : null;

        // if (!customerId && (draftId || documentId)) {
        if (edit) {
            let document =
                businessDocType === projectConfig.businessDocType.Invoice
                    ? getState().documentCreation.invoiceCreationFields
                    : getState().documentCreation.documentCreationFields;
            if (
                businessDocType !== projectConfig.businessDocType.Invoice &&
                businessDocType !== projectConfig.businessDocType.SIMDocument
            ) {
                await dispatch(fetchDocumentSkeleton(businessDocType, buyerId, customerId));
                dispatch(updateLineItemSkeletonWithHeaderFieldValues());
            }
            const documentForEdit = await dispatch(fetchDocumentForEdit(draftId, documentId, businessDocType, false));

            if (!documentForEdit) {
                dispatch(documentActions.shouldShow404Error(true));
                return;
            }

            document =
                businessDocType === projectConfig.businessDocType.Invoice
                    ? getState().documentCreation.invoiceCreationFields
                    : getState().documentCreation.documentCreationFields;

            const currencyType = DocumentUtility.getDocumentCurrencyType(document);

            dispatch(updateSelectedCurrency(currencyType));
            // If we are not creating a brand new business document, we should set the original state info to be able to track if certain field's values have been updated.
            dispatch(setDocumentCreationOriginalStateInfo(document, businessDocType));

            let buyerCompanyId = null;
            if (draftId) {
                // Drafts do not have a related document with sender and receiver company ids. Therefore, custom lookup for the related buyer company id is needed here.
                if (businessDocType === projectConfig.businessDocType.Invoice) {
                    // Invoice drafts contain a customer id which can be used to find the buyer company id.
                    // To obtain the buyer information via the customer an API call is needed, but we need to wait for it to resolve its promise before moving on.
                    // Otherwise, we would end up attempting to call the get layout API with a promise as a parameter, and the call would fail.
                    const params = {
                        companyId: supplierCompanyId,
                        $filter: `ID eq ${document.BusinessDocFields.Invoice.CustomerID}`,
                    };
                    const companyThunk = new TranscpetaThunks.CompanyThunk();
                    const customerLocationResponse = await companyThunk.fetchCustomers(params);
                    const customerLocationData = await customerLocationResponse.data;
                    if (!customerLocationResponse.type || customerLocationResponse.type !== 'error') {
                        buyerCompanyId = customerLocationData.Items[0].BuyerDestinationProfile.CompanyID;
                        dispatch(CustomerActions.selectCustomerLocationAction(customerLocationData.Items[0]));
                    }
                }
            } else if (document.CommonFields !== null) {
                buyerCompanyId =
                    supplierCompanyId === document.CommonFields.SenderCompanyId
                        ? document.CommonFields.ReceiverCompanyId
                        : document.CommonFields.SenderCompanyId;
            }
            await dispatch(
                DocumentThunk.fetchDocumentLayout(
                    document,
                    !!draftId,
                    businessDocType,
                    buyerCompanyId,
                    supplierCompanyId
                )
            );

            if (
                businessDocType === projectConfig.businessDocType.Invoice ||
                businessDocType === projectConfig.businessDocType.PurchaseOrderAcknowledgement
            ) {
                // Since the edit layout is being retrieved here we need to update the business doc model with any misc amounts (non-line item misc amounts) that are visible by default.
                await dispatch(createDefaultFixedMiscAmounts(businessDocType));
            }

            if (businessDocType === projectConfig.businessDocType.PurchaseOrderAcknowledgement) {
                dispatch(recalculateGenericFields(layoutSelector(getState(), true, businessDocType)));
            }
            if (businessDocType === projectConfig.businessDocType.SIMDocument) {
                await dispatch(
                    SIMDocumentTypeThunk.fetchSupplierSIMRequest(
                        document.BusinessDocFields.BusinessDocument.Document.BusinessDocument.SIMRequestId
                    )
                );
                dispatch(
                    documentActions.setAttachmentsAllowed(
                        getState().simDocumentTypes.SIMRequests.items[0].SIMDocumentType.AttachmentOption !==
                            projectConfig.AttachmentOption.NoAttachments
                    )
                );
            }
        } else if (customerId) {
            const state = getState();
            const isCopy = state.document.isCopy || state.documentCreation.invoiceCreationFields.isCopy;
            const buyerCompanyId =
                buyerId ||
                (state.selectCustomer.currentCustomer ? state.selectCustomer.currentCustomer.BuyerCompanyId : null);

            let supplierProfileType = null;

            switch (businessDocType) {
                case projectConfig.businessDocType.ASNGeneric:
                    supplierProfileType = projectConfig.profileType.SupplierWarehouse;
                    break;
                case projectConfig.businessDocType.Invoice:
                    supplierProfileType = projectConfig.profileType.Invoice;
                    break;
                case projectConfig.businessDocType.PurchaseOrderAcknowledgement:
                case projectConfig.businessDocType.SIMDocument:
                    supplierProfileType = projectConfig.profileType.Sales;
                    break;
                default:
                    break;
            }

            await dispatch(documentActions.clearDocumentHistoryDelivery());

            await dispatch(
                DocumentThunk.fetchDocumentLayout(null, true, businessDocType, buyerCompanyId, supplierCompanyId)
            );

            const fullSkeleton = await (async () => {
                // to remove feature flag check here, simply await the call to dispatch(fetchDocumentSkeleton(...))
                if (
                    isFeatureEnabled('APIBasedInvoiceFlips') ||
                    businessDocType !== projectConfig.businessDocType.Invoice
                ) {
                    const flip = state.documentCreation.flip;

                    if (
                        businessDocType !== BusinessDocType.Invoice &&
                        businessDocType !== BusinessDocType.SIMDocument &&
                        !documentId &&
                        flip.isFlip
                    ) {
                        const lineItems = Array.isArray(flip.lineItems)
                            ? flip.lineItems.map((lineItem) => lineItem.Id)
                            : [];
                        const sourceId = flip.sourceId;
                        await dispatch(getFlippedDocumentFields(sourceId, businessDocType, lineItems, customerId));
                    }

                    const skeleton = await dispatch(fetchDocumentSkeleton(businessDocType, buyerCompanyId, customerId));
                    if (flip.isFlip && businessDocType === BusinessDocType.Invoice) {
                        return dispatch(performFlipToInvoice(customerId));
                    }

                    return skeleton;
                }

                return {};
            })();

            let profiles = {};
            await dispatch(fetchProfiles(supplierProfileType));
            profiles = cloneObjectHack(getState().profiles.data);
            const customerLocation = getState().selectCustomer.currentCustomerLocation;

            const today = saveableDate(Date.now());
            let defaultFields = {};

            const skeletonContacts = fullSkeleton?.BusinessDocFields?.Invoice?.InvoiceContacts || [];
            const defaultContacts =
                skeletonContacts.length === 4
                    ? skeletonContacts
                    : [
                          // We might want this to be dynamic
                          {
                              ...InvoiceModels.defaultInvoiceContactsState.state[0],
                              ContactType: projectConfig.ContactType.Buyer,
                          },
                          {
                              ...InvoiceModels.defaultInvoiceContactsState.state[1],
                              ContactType: projectConfig.ContactType.ShipTo,
                          },
                          {
                              ...InvoiceModels.defaultInvoiceContactsState.state[2],
                              ContactType: projectConfig.ContactType.RemitTo,
                          },
                          {
                              ...InvoiceModels.defaultInvoiceContactsState.state[3],
                              ContactType: projectConfig.ContactType.Vendor,
                          },
                      ];
            let fieldDefaults = {};

            if (businessDocType === projectConfig.businessDocType.Invoice) {
                let invoice = cloneObjectHack(
                    getState().documentCreation.invoiceCreationFields.BusinessDocFields.Invoice
                );

                // TODO: Remove this when we remove the APIBasedInvoiceFlips feature flag
                const debugInvoice = cloneObjectHack(invoice);
                console.log({ debugInvoice });

                await dispatch(CompanyThunk.fetchVatArchiveServiceRecords(buyerCompanyId));
                await dispatch(CompanyThunk.fetchVatArchiveServiceRecords(supplierCompanyId));
                const invoiceProfileId = profiles.length ? profiles[0].Id : null;
                const buyerDestinationProfileId =
                    state.selectCustomer.currentCustomerLocation &&
                    state.selectCustomer.currentCustomerLocation.BuyerDestinationProfileId;
                const defaultVATNumbers = defaultVATNumberSelector(
                    getState(),
                    invoiceProfileId,
                    buyerDestinationProfileId
                );

                const selectedCurrency = profiles.length && profiles[0].Currency >= 1 ? profiles[0].Currency : 1;

                if (isCopy) {
                    const document =
                        businessDocType === projectConfig.businessDocType.Invoice
                            ? getState().documentCreation.invoiceCreationFields
                            : getState().documentCreation.documentCreationFields;
                    const currencyType = DocumentUtility.getDocumentCurrencyType(document);

                    dispatch(updateSelectedCurrency(currencyType));
                } else {
                    dispatch(updateSelectedCurrency(selectedCurrency));
                }

                defaultFields = {
                    isCopy,
                    CommonFields: {
                        ...fullSkeleton?.CommonFields,
                        ReceiverProfileId: customerLocation ? customerLocation.BuyerDestinationProfileId : null,
                        SenderProfileId: invoiceProfileId,
                        SenderProfileType: projectConfig.profileType.Invoice,
                        CustomerId: customerId,
                    },
                    BusinessDocFields: {
                        Invoice: {
                            ...fullSkeleton?.BusinessDocFields?.Invoice,
                            Currency: selectedCurrency,
                            InvoiceProfileID: invoiceProfileId,
                            CustomerID: customerId,
                            InvoiceContacts: defaultContacts,
                        },
                    },
                };

                // VendorNumber default value should come from the vendor number on the selected customer location (Customer record) if it has one.
                // Else, it should come from the default vendor number on the selected customer (SupplierCompanyTradingPartner record).
                let vendorNumberFieldDefault = null;
                if (customerLocation && customerLocation.VendorNumber) {
                    vendorNumberFieldDefault = customerLocation.VendorNumber;
                } else if (
                    state.selectCustomer.currentCustomer &&
                    state.selectCustomer.currentCustomer.DefaultVendorNumber
                ) {
                    vendorNumberFieldDefault = state.selectCustomer.currentCustomer.DefaultVendorNumber;
                }

                // We need to pre-populate this with a few values that will be used as defaults upon save if the user has not provided them.
                fieldDefaults = {
                    CustomerAddressBlock: customerLocation ? customerLocation.AddressBlock : null,
                    CustomerID: customerId,
                    CustomerNumber: customerLocation ? customerLocation.CustomerNumber : null,
                    CustomerName: customerLocation ? customerLocation.Name : null,
                    CustomerShipToAddressBlock: customerLocation ? customerLocation.ShipToAddressBlock : null,
                    VendorNumber: vendorNumberFieldDefault,
                };

                // Loop through the entire field dictionary (sections, cells, and fields) to potentially set the field default for each field.
                // NOTE: We only want to populate the invoice with the default if the field is already visible.
                //       Populating it otherwise forces it to be visible when it is not set to be so.
                const layout = cloneObjectHack(getState().documentLayout.layout);
                const largeLayout = layout.Large;
                const sectionNames = ['Header', 'Body', 'Footer'];
                sectionNames.forEach((sectionName) => {
                    if (largeLayout && largeLayout[sectionName]) {
                        let sectionLayout = largeLayout[sectionName];
                        if (!Array.isArray(sectionLayout)) {
                            sectionLayout = [sectionLayout];
                        }

                        sectionLayout.forEach((cellLayout) => {
                            cellLayout.Fields.forEach((fieldLayout) => {
                                if (fieldLayout.StaticDefaultValue) {
                                    fieldDefaults[`${fieldLayout.FieldDictionaryId}`] = fieldLayout.StaticDefaultValue;
                                }

                                if (fieldLayout.EntityName === projectConfig.EntityName.Main) {
                                    switch (fieldLayout.FieldName) {
                                        case 'BillToEmail':
                                            const billToEmail = customerLocation ? customerLocation.Email : null;
                                            if (
                                                (fieldLayout.Visible || fieldLayout.Required) &&
                                                IsUndefinedNullOrEmpty(invoice[fieldLayout.FieldName])
                                            ) {
                                                defaultFields.BusinessDocFields.Invoice[fieldLayout.FieldName] =
                                                    billToEmail;
                                            }
                                            fieldDefaults[`${fieldLayout.FieldDictionaryId}`] = billToEmail;
                                            break;
                                        case 'BillToFax':
                                            const billToFax = customerLocation ? customerLocation.Fax : null;
                                            if (
                                                (fieldLayout.Visible || fieldLayout.Required) &&
                                                IsUndefinedNullOrEmpty(invoice[fieldLayout.FieldName])
                                            ) {
                                                defaultFields.BusinessDocFields.Invoice[fieldLayout.FieldName] =
                                                    billToFax;
                                            }
                                            fieldDefaults[`${fieldLayout.FieldDictionaryId}`] = billToFax;
                                            break;
                                        case 'BuyerVATNumber':
                                            const buyerVATNumber = defaultVATNumbers.buyerVATNumber;
                                            if (
                                                (fieldLayout.Visible || fieldLayout.Required) &&
                                                IsUndefinedNullOrEmpty(invoice[fieldLayout.FieldName])
                                            ) {
                                                defaultFields.BusinessDocFields.Invoice[fieldLayout.FieldName] =
                                                    buyerVATNumber;
                                            }
                                            fieldDefaults[`${fieldLayout.FieldDictionaryId}`] = buyerVATNumber;
                                            break;
                                        case 'CustomerNumber':
                                            if (
                                                (fieldLayout.Visible || fieldLayout.Required) &&
                                                IsUndefinedNullOrEmpty(invoice[fieldLayout.FieldName])
                                            ) {
                                                defaultFields.BusinessDocFields.Invoice[fieldLayout.FieldName] =
                                                    fieldDefaults.CustomerNumber;
                                            }
                                            fieldDefaults[`${fieldLayout.FieldDictionaryId}`] =
                                                fieldDefaults.CustomerNumber;
                                            break;
                                        case 'DueDate':
                                        case 'InvoiceDate':
                                            if (
                                                (fieldLayout.Visible || fieldLayout.Required) &&
                                                IsUndefinedNullOrEmpty(invoice[fieldLayout.FieldName])
                                            ) {
                                                defaultFields.BusinessDocFields.Invoice[fieldLayout.FieldName] = today;
                                            }
                                            fieldDefaults[`${fieldLayout.FieldDictionaryId}`] = today;
                                            break;
                                        case 'Name':
                                            if (
                                                (fieldLayout.Visible || fieldLayout.Required) &&
                                                IsUndefinedNullOrEmpty(invoice[fieldLayout.FieldName])
                                            ) {
                                                defaultFields.BusinessDocFields.Invoice[fieldLayout.FieldName] =
                                                    fieldDefaults.CustomerName;
                                            }
                                            fieldDefaults[`${fieldLayout.FieldDictionaryId}`] =
                                                fieldDefaults.CustomerName;
                                            break;
                                        case 'SupplierVATNumber':
                                            const supplierVATNumber = defaultVATNumbers.supplierVATNumber;
                                            if (
                                                (fieldLayout.Visible || fieldLayout.Required) &&
                                                IsUndefinedNullOrEmpty(invoice[fieldLayout.FieldName])
                                            ) {
                                                defaultFields.BusinessDocFields.Invoice[fieldLayout.FieldName] =
                                                    supplierVATNumber;
                                            }
                                            fieldDefaults[`${fieldLayout.FieldDictionaryId}`] = supplierVATNumber;
                                            break;

                                        case 'VendorSiteID':
                                        case 'VendorNumber': {
                                            const allowedProcessingMode =
                                                processingMode === projectConfig.ProcessingMode.BuyerEntry ||
                                                processingMode === projectConfig.ProcessingMode.BuyerWebEntryTest;
                                            const isVisibleOrRequiredAndHasNoValue =
                                                (fieldLayout.Visible || fieldLayout.Required) &&
                                                IsUndefinedNullOrEmpty(invoice[fieldLayout.FieldName]);

                                            if (isVisibleOrRequiredAndHasNoValue || allowedProcessingMode) {
                                                // set Required to true, adds label_bold on ControlFormat
                                                const updatedFieldLayout = {
                                                    ...fieldLayout,
                                                    Required: true,
                                                    ControlFormat: { ...fieldLayout.ControlFormat, label_bold: 'true' },
                                                };
                                                dispatch(
                                                    documentCreationActions.updateLayoutFieldRequiredAndControlFormat(
                                                        updatedFieldLayout
                                                    )
                                                );
                                            }

                                            if (IsUndefinedNullOrEmpty(invoice[fieldLayout.FieldName])) {
                                                defaultFields.BusinessDocFields.Invoice[fieldLayout.FieldName] =
                                                    fieldDefaults[fieldLayout.FieldName];
                                            }

                                            fieldDefaults[`${fieldLayout.FieldDictionaryId}`] =
                                                fieldDefaults[fieldLayout.FieldName];
                                            break;
                                        }
                                        default:
                                            break;
                                    }
                                } else if (fieldLayout.EntityName === projectConfig.EntityName.Contact) {
                                    switch (fieldLayout.ContactType) {
                                        case projectConfig.ContactType.Buyer:
                                            const buyerContact = invoice.InvoiceContacts.filter(
                                                (contact) => contact.ContactType === projectConfig.ContactType.Buyer
                                            );
                                            switch (fieldLayout.FieldName) {
                                                case 'AddressBlock':
                                                    if (
                                                        fieldLayout.Visible ||
                                                        fieldLayout.Required ||
                                                        (buyerContact &&
                                                            buyerContact.length > 0 &&
                                                            IsNotUndefinedNullOrEmpty(
                                                                buyerContact[0][fieldLayout.FieldName]
                                                            ))
                                                    ) {
                                                        defaultContacts[0][fieldLayout.FieldName] =
                                                            buyerContact &&
                                                            buyerContact.length > 0 &&
                                                            IsNotUndefinedNullOrEmpty(
                                                                buyerContact[0][fieldLayout.FieldName]
                                                            )
                                                                ? buyerContact[0][fieldLayout.FieldName]
                                                                : fieldDefaults.CustomerAddressBlock;
                                                    }
                                                    fieldDefaults[`${fieldLayout.FieldDictionaryId}`] =
                                                        fieldDefaults.CustomerAddressBlock;
                                                    break;
                                                case 'Email':
                                                    const email = customerLocation ? customerLocation.Email : null;
                                                    if (
                                                        fieldLayout.Visible ||
                                                        fieldLayout.Required ||
                                                        (buyerContact &&
                                                            buyerContact.length > 0 &&
                                                            IsNotUndefinedNullOrEmpty(
                                                                buyerContact[0][fieldLayout.FieldName]
                                                            ))
                                                    ) {
                                                        defaultContacts[0][fieldLayout.FieldName] =
                                                            buyerContact &&
                                                            buyerContact.length > 0 &&
                                                            IsNotUndefinedNullOrEmpty(
                                                                buyerContact[0][fieldLayout.FieldName]
                                                            )
                                                                ? buyerContact[0][fieldLayout.FieldName]
                                                                : email;
                                                    }
                                                    fieldDefaults[`${fieldLayout.FieldDictionaryId}`] = email;
                                                    break;
                                                case 'FaxNumber':
                                                    const fax = customerLocation ? customerLocation.Fax : null;
                                                    if (
                                                        fieldLayout.Visible ||
                                                        fieldLayout.Required ||
                                                        (buyerContact &&
                                                            buyerContact.length > 0 &&
                                                            IsNotUndefinedNullOrEmpty(
                                                                buyerContact[0][fieldLayout.FieldName]
                                                            ))
                                                    ) {
                                                        defaultContacts[0][fieldLayout.FieldName] =
                                                            buyerContact &&
                                                            buyerContact.length > 0 &&
                                                            IsNotUndefinedNullOrEmpty(
                                                                buyerContact[0][fieldLayout.FieldName]
                                                            )
                                                                ? buyerContact[0][fieldLayout.FieldName]
                                                                : fax;
                                                    }
                                                    fieldDefaults[`${fieldLayout.FieldDictionaryId}`] = fax;
                                                    break;
                                                case 'Name':
                                                    if (
                                                        fieldLayout.Visible ||
                                                        fieldLayout.Required ||
                                                        (buyerContact &&
                                                            buyerContact.length > 0 &&
                                                            IsNotUndefinedNullOrEmpty(
                                                                buyerContact[0][fieldLayout.FieldName]
                                                            ))
                                                    ) {
                                                        defaultContacts[0][fieldLayout.FieldName] =
                                                            buyerContact &&
                                                            buyerContact.length > 0 &&
                                                            IsNotUndefinedNullOrEmpty(
                                                                buyerContact[0][fieldLayout.FieldName]
                                                            )
                                                                ? buyerContact[0][fieldLayout.FieldName]
                                                                : fieldDefaults.CustomerName;
                                                    }
                                                    fieldDefaults[`${fieldLayout.FieldDictionaryId}`] =
                                                        fieldDefaults.CustomerName;
                                                    break;
                                                case 'PhoneNumber':
                                                    const phone = customerLocation ? customerLocation.Phone : null;
                                                    if (
                                                        fieldLayout.Visible ||
                                                        fieldLayout.Required ||
                                                        (buyerContact &&
                                                            buyerContact.length > 0 &&
                                                            IsNotUndefinedNullOrEmpty(
                                                                buyerContact[0][fieldLayout.FieldName]
                                                            ))
                                                    ) {
                                                        defaultContacts[0][fieldLayout.FieldName] =
                                                            buyerContact &&
                                                            buyerContact.length > 0 &&
                                                            IsNotUndefinedNullOrEmpty(
                                                                buyerContact[0][fieldLayout.FieldName]
                                                            )
                                                                ? buyerContact[0][fieldLayout.FieldName]
                                                                : phone;
                                                    }
                                                    fieldDefaults[`${fieldLayout.FieldDictionaryId}`] = phone;
                                                    break;
                                                default:
                                                    // For any contact fields that do not have default logic we need to copy any values that are already populated into the default contacts so the values are not lost.
                                                    if (
                                                        buyerContact &&
                                                        buyerContact.length > 0 &&
                                                        IsNotUndefinedNullOrEmpty(
                                                            buyerContact[0][fieldLayout.FieldName]
                                                        )
                                                    ) {
                                                        defaultContacts[0][fieldLayout.FieldName] =
                                                            buyerContact[0][fieldLayout.FieldName];
                                                    }
                                                    break;
                                            }
                                            break;
                                        case projectConfig.ContactType.ShipTo:
                                            const shipToContact = invoice.InvoiceContacts.filter(
                                                (contact) => contact.ContactType === projectConfig.ContactType.ShipTo
                                            );
                                            switch (fieldLayout.FieldName) {
                                                case 'AddressBlock':
                                                    const addressBlock = customerLocation
                                                        ? customerLocation.ShipToAddressBlock &&
                                                          customerLocation.ShipToAddressBlock !== ''
                                                            ? customerLocation.ShipToAddressBlock
                                                            : customerLocation.AddressBlock
                                                        : null;
                                                    if (
                                                        fieldLayout.Visible ||
                                                        fieldLayout.Required ||
                                                        (shipToContact &&
                                                            shipToContact.length > 0 &&
                                                            IsNotUndefinedNullOrEmpty(
                                                                shipToContact[0][fieldLayout.FieldName]
                                                            ))
                                                    ) {
                                                        defaultContacts[1][fieldLayout.FieldName] =
                                                            shipToContact &&
                                                            shipToContact.length > 0 &&
                                                            IsNotUndefinedNullOrEmpty(
                                                                shipToContact[0][fieldLayout.FieldName]
                                                            )
                                                                ? shipToContact[0][fieldLayout.FieldName]
                                                                : addressBlock;
                                                    }
                                                    fieldDefaults[`${fieldLayout.FieldDictionaryId}`] = addressBlock;
                                                    break;
                                                case 'Name':
                                                    if (
                                                        fieldLayout.Visible ||
                                                        fieldLayout.Required ||
                                                        (shipToContact &&
                                                            shipToContact.length > 0 &&
                                                            IsNotUndefinedNullOrEmpty(
                                                                shipToContact[0][fieldLayout.FieldName]
                                                            ))
                                                    ) {
                                                        defaultContacts[1][fieldLayout.FieldName] =
                                                            shipToContact &&
                                                            shipToContact.length > 0 &&
                                                            IsNotUndefinedNullOrEmpty(
                                                                shipToContact[0][fieldLayout.FieldName]
                                                            )
                                                                ? shipToContact[0][fieldLayout.FieldName]
                                                                : fieldDefaults.CustomerName;
                                                    }
                                                    fieldDefaults[`${fieldLayout.FieldDictionaryId}`] =
                                                        fieldDefaults.CustomerName;
                                                    break;
                                                default:
                                                    // For any contact fields that do not have default logic we need to copy any values that are already populated into the default contacts so the values are not lost.
                                                    if (
                                                        shipToContact &&
                                                        shipToContact.length > 0 &&
                                                        IsNotUndefinedNullOrEmpty(
                                                            shipToContact[0][fieldLayout.FieldName]
                                                        )
                                                    ) {
                                                        defaultContacts[1][fieldLayout.FieldName] =
                                                            shipToContact[0][fieldLayout.FieldName];
                                                    }
                                                    break;
                                            }
                                            break;
                                        case projectConfig.ContactType.RemitTo:
                                            const remitToContact = invoice.InvoiceContacts.filter(
                                                (contact) => contact.ContactType === projectConfig.ContactType.RemitTo
                                            );
                                            switch (fieldLayout.FieldName) {
                                                case 'AddressBlock':
                                                    const addressBlock = profiles.length
                                                        ? profiles[0].RemitToAddressBlock &&
                                                          profiles[0].RemitToAddressBlock !== ''
                                                            ? profiles[0].RemitToAddressBlock
                                                            : CreateAddressBlock({
                                                                  line1: profiles[0].Address1,
                                                                  line2: profiles[0].Address2,
                                                                  line3: profiles[0].Address3,
                                                                  line4: profiles[0].Address4,
                                                                  city: profiles[0].City,
                                                                  state: profiles[0].State,
                                                                  zip: profiles[0].PostalCode,
                                                                  country: profiles[0].Country,
                                                              })
                                                        : null;
                                                    if (
                                                        fieldLayout.Visible ||
                                                        fieldLayout.Required ||
                                                        (remitToContact &&
                                                            remitToContact.length > 0 &&
                                                            IsNotUndefinedNullOrEmpty(
                                                                remitToContact[0][fieldLayout.FieldName]
                                                            ))
                                                    ) {
                                                        defaultContacts[2][fieldLayout.FieldName] =
                                                            remitToContact &&
                                                            remitToContact.length > 0 &&
                                                            IsNotUndefinedNullOrEmpty(
                                                                remitToContact[0][fieldLayout.FieldName]
                                                            )
                                                                ? remitToContact[0][fieldLayout.FieldName]
                                                                : addressBlock;
                                                    }
                                                    fieldDefaults[`${fieldLayout.FieldDictionaryId}`] = addressBlock;
                                                    break;
                                                case 'Name':
                                                    const name = profiles.length ? profiles[0].Name : null;
                                                    if (
                                                        fieldLayout.Visible ||
                                                        fieldLayout.Required ||
                                                        (remitToContact &&
                                                            remitToContact.length > 0 &&
                                                            IsNotUndefinedNullOrEmpty(
                                                                remitToContact[0][fieldLayout.FieldName]
                                                            ))
                                                    ) {
                                                        defaultContacts[2][fieldLayout.FieldName] =
                                                            remitToContact &&
                                                            remitToContact.length > 0 &&
                                                            IsNotUndefinedNullOrEmpty(
                                                                remitToContact[0][fieldLayout.FieldName]
                                                            )
                                                                ? remitToContact[0][fieldLayout.FieldName]
                                                                : name;
                                                    }
                                                    fieldDefaults[`${fieldLayout.FieldDictionaryId}`] = name;
                                                    break;
                                                default:
                                                    // For any contact fields that do not have default logic we need to copy any values that are already populated into the default contacts so the values are not lost.
                                                    if (
                                                        remitToContact &&
                                                        remitToContact.length > 0 &&
                                                        IsNotUndefinedNullOrEmpty(
                                                            remitToContact[0][fieldLayout.FieldName]
                                                        )
                                                    ) {
                                                        defaultContacts[2][fieldLayout.FieldName] =
                                                            remitToContact[0][fieldLayout.FieldName];
                                                    }
                                                    break;
                                            }
                                            break;
                                        case projectConfig.ContactType.Vendor:
                                            const vendorContact = invoice.InvoiceContacts.filter(
                                                (contact) => contact.ContactType === projectConfig.ContactType.Vendor
                                            );
                                            switch (fieldLayout.FieldName) {
                                                case 'AddressBlock':
                                                    const addressBlock = profiles.length
                                                        ? CreateAddressBlock({
                                                              line1: profiles[0].Address1,
                                                              line2: profiles[0].Address2,
                                                              line3: profiles[0].Address3,
                                                              line4: profiles[0].Address4,
                                                              city: profiles[0].City,
                                                              state: profiles[0].State,
                                                              zip: profiles[0].PostalCode,
                                                              country: profiles[0].Country,
                                                          })
                                                        : null;
                                                    if (
                                                        fieldLayout.Visible ||
                                                        fieldLayout.Required ||
                                                        (vendorContact &&
                                                            vendorContact.length > 0 &&
                                                            IsNotUndefinedNullOrEmpty(
                                                                vendorContact[0][fieldLayout.FieldName]
                                                            ))
                                                    ) {
                                                        defaultContacts[3][fieldLayout.FieldName] =
                                                            vendorContact &&
                                                            vendorContact.length > 0 &&
                                                            IsNotUndefinedNullOrEmpty(
                                                                vendorContact[0][fieldLayout.FieldName]
                                                            )
                                                                ? vendorContact[0][fieldLayout.FieldName]
                                                                : addressBlock;
                                                    }
                                                    fieldDefaults[`${fieldLayout.FieldDictionaryId}`] = addressBlock;
                                                    break;
                                                case 'Name':
                                                    const name = profiles.length ? profiles[0].Name : null;
                                                    if (
                                                        fieldLayout.Visible ||
                                                        fieldLayout.Required ||
                                                        (vendorContact &&
                                                            vendorContact.length > 0 &&
                                                            IsNotUndefinedNullOrEmpty(
                                                                vendorContact[0][fieldLayout.FieldName]
                                                            ))
                                                    ) {
                                                        defaultContacts[3][fieldLayout.FieldName] =
                                                            vendorContact &&
                                                            vendorContact.length > 0 &&
                                                            IsNotUndefinedNullOrEmpty(
                                                                vendorContact[0][fieldLayout.FieldName]
                                                            )
                                                                ? vendorContact[0][fieldLayout.FieldName]
                                                                : name;
                                                    }
                                                    fieldDefaults[`${fieldLayout.FieldDictionaryId}`] = name;
                                                    break;
                                                default:
                                                    // For any contact fields that do not have default logic we need to copy any values that are already populated into the default contacts so the values are not lost.
                                                    if (
                                                        vendorContact &&
                                                        vendorContact.length > 0 &&
                                                        IsNotUndefinedNullOrEmpty(
                                                            vendorContact[0][fieldLayout.FieldName]
                                                        )
                                                    ) {
                                                        defaultContacts[3][fieldLayout.FieldName] =
                                                            vendorContact[0][fieldLayout.FieldName];
                                                    }
                                                    break;
                                            }
                                            break;
                                        default:
                                            // Determine if the field is already populated. If it is then we need to move the value into the default contacts so it isn't lost.
                                            const contact = invoice.InvoiceContacts.filter(
                                                (contact) => contact.ContactType === fieldLayout.ContactType
                                            );
                                            if (
                                                contact &&
                                                contact.length > 0 &&
                                                IsNotUndefinedNullOrEmpty(contact[0][fieldLayout.FieldName])
                                            ) {
                                                let defaultContact = defaultContacts.filter(
                                                    (contact) => contact.ContactType === fieldLayout.ContactType
                                                );
                                                if (defaultContact && defaultContact.length > 0) {
                                                    defaultContact = defaultContact[0];
                                                } else {
                                                    // If the contact type is not yet in the default contacts, push a new contact to default contacts and use it as the new default contact
                                                    defaultContacts.push({
                                                        ...InvoiceModels.defaultInvoiceContactsState.state[0],
                                                        ContactType: fieldLayout.ContactType,
                                                    });
                                                    defaultContact = defaultContacts[defaultContacts.length - 1];
                                                }
                                                defaultContact[fieldLayout.FieldName] =
                                                    contact[0][fieldLayout.FieldName];
                                            }
                                            break;
                                    }
                                }
                            });
                        });
                    }
                });

                // we trim user input when the user modifies any field; this duplicates that logic for the defaulting,
                // which prevents downstream errors when a field has spaces that it should not contain.
                recursivelyTrimStringProperties(fieldDefaults);
                recursivelyTrimStringProperties(defaultFields);

                // set the field defaults for use when a currently not visible field is added by the user
                dispatch(documentCreationActions.setFieldDefaults(fieldDefaults));

                // Set the default values for the invoice
                dispatch(CreateInvoiceActions.performSetDefaultDataInvoiceField(defaultFields));

                invoice = cloneObjectHack(getState().documentCreation.invoiceCreationFields.BusinessDocFields.Invoice);
                if (IsNotUndefinedNullOrEmpty(invoice.PaymentTerms)) {
                    dispatch(calculateDueDateFromPaymentTerms(invoice.PaymentTerms));
                }

                // Since the edit layout is being retrieved here we need to update the invoice model with any misc amounts (non-line item misc amounts) that are visible by default.
                await dispatch(createDefaultFixedMiscAmounts(businessDocType));

                // Calculate the footer values in case they we flipped from PO and they are not populated
                dispatch(calculateInvoiceSubtotal(layoutSelector(state, true, businessDocType), true));
            } else if (businessDocType === projectConfig.businessDocType.ASNGeneric) {
                defaultFields = {
                    CommonFields: {
                        ReceiverProfileId: customerLocation ? customerLocation.BuyerWarehouseProfileId : null,
                        ReceiverProfileType: projectConfig.profileType.BuyerWarehouse,
                        SenderProfileId: profiles.length ? profiles[0].Id : null,
                        SenderProfileType: projectConfig.profileType.SupplierWarehouse,
                        CustomerId: Number(customerId),
                    },
                };

                const state = getState();
                const { reducedHeader } = layoutSelector(state, true, businessDocType);
                const businessDocument = cloneObjectHack(
                    state.documentCreation.documentCreationFields.BusinessDocFields.BusinessDocument
                );
                const bodyJsonPath = state.documentLayout.layout.Large.Header[0].SectionJSONPath;
                const document = jsonpath.nodes(businessDocument, `$${bodyJsonPath}`)[0].value;
                const contacts = reducedHeader.fields.filter(
                    (s) => s.EntityName == projectConfig.EntityName.Contact && s.Visible == true
                );
                const {
                    selectCustomer: { currentCustomerLocation },
                } = state;

                defaultContactValues(contacts, document, businessDocType, profiles[0], currentCustomerLocation);

                dispatch(documentCreationActions.performDocumentSetDefaultCommonFields(defaultFields));
                dispatch(documentCreationActions.performDocumentFieldValueUpdate(businessDocument));
                dispatch(updateLineItemSkeletonWithHeaderFieldValues());
                dispatch(
                    generateGenericDocumentLineItemIds(
                        businessDocument,
                        state.documentLayout.layout.Large.Body[0].SectionJSONPath
                    )
                );
            } else if (businessDocType === projectConfig.businessDocType.PurchaseOrderAcknowledgement) {
                defaultFields = {
                    CommonFields: {
                        ReceiverProfileId: customerLocation ? customerLocation.ProcurementProfileId : null,
                        ReceiverProfileType: projectConfig.profileType.Procurement,
                        SenderProfileId: profiles.length ? profiles[0].Id : null,
                        SenderProfileType: projectConfig.profileType.Sales,
                        CustomerId: Number(customerId),
                    },
                };

                const state = getState();
                const { reducedHeader } = layoutSelector(state, true, businessDocType);
                const businessDocument = cloneObjectHack(
                    state.documentCreation.documentCreationFields.BusinessDocFields.BusinessDocument
                );

                const bodyJsonPath = state.documentLayout.layout.Large.Header[0].SectionJSONPath;
                const document = jsonpath.nodes(businessDocument, `$${bodyJsonPath}`)[0].value;
                const contacts = reducedHeader.fields.filter(
                    (s) => s.EntityName == projectConfig.EntityName.Contact && s.Visible == true
                );
                const {
                    selectCustomer: { currentCustomerLocation },
                } = state;

                defaultContactValues(contacts, document, businessDocType, profiles[0], currentCustomerLocation);

                dispatch(documentCreationActions.performDocumentSetDefaultCommonFields(defaultFields));
                dispatch(documentCreationActions.performDocumentFieldValueUpdate(businessDocument));
                dispatch(updateLineItemSkeletonWithHeaderFieldValues());
                dispatch(
                    generateGenericDocumentLineItemIds(
                        businessDocument,
                        state.documentLayout.layout.Large.Body[0].SectionJSONPath
                    )
                );
                if (document) {
                    await dispatch(
                        DocumentThunk.fetchDocumentLayout(
                            document,
                            !!draftId,
                            businessDocType,
                            buyerCompanyId,
                            supplierCompanyId,
                            getState().documentCreation.documentCreationFields.simDocClass
                        )
                    );

                    // Since the edit layout is being retrieved here we need to update the invoice model with any misc amounts (non-line item misc amounts) that are visible by default.
                    await dispatch(createDefaultFixedMiscAmounts(businessDocType));
                    dispatch(recalculateGenericFields(layoutSelector(getState(), true, businessDocType)));
                }
            } else if (businessDocType === projectConfig.businessDocType.SIMDocument) {
                const simRequestInfo = getState().documentCreation.simRequestInfo;
                const customerNumber = getState().selectCustomer.currentCustomerLocation.CustomerNumber;
                const defaultFields = {
                    CommonFields: {
                        CustomerId: simRequestInfo.CustomerId,
                        ReceiverProfileId: simRequestInfo.ProcurementId,
                        ReceiverProfileType: projectConfig.profileType.Procurement,
                        SenderProfileId: profiles.length ? profiles[0].Id : null,
                        SenderProfileType: projectConfig.profileType.Sales,
                    },
                };
                dispatch(documentCreationActions.performDocumentSetDefaultCommonFields(defaultFields));
                const payload = {
                    CustomerNumber: customerNumber,
                    CustomerName: simRequestInfo.CustomerName,
                    Description: simRequestInfo.Description,
                    DocumentClass: simRequestInfo.DocumentClass,
                    SIMDocumentTypeId: simRequestInfo.SIMDocumentTypeId,
                    DueDate: simRequestInfo.DueDate,
                    DocumentType: simRequestInfo.DocumentType,
                    SIMRequestId: simRequestInfo.SIMRequestId,
                };
                dispatch(documentCreationActions.performDocumentSetDefaultBusinessDocFields(payload));
                if (simRequestInfo.DataEntrySchedule === 4) {
                    dispatch(documentCreationActions.updateLayoutVisibility('ExpirationDate'));
                }
                await dispatch(SIMDocumentTypeThunk.fetchSupplierSIMRequest(simRequestInfo.SIMRequestId));
                dispatch(
                    documentActions.setAttachmentsAllowed(
                        getState().simDocumentTypes.SIMRequests.items[0].SIMDocumentType.AttachmentOption !==
                            projectConfig.AttachmentOption.NoAttachments
                    )
                );
            }
        }

        dispatch(documentCreationActions.documentCreationInitializationComplete());
    };

const setDocumentCreationOriginalStateInfo = (document, businessDocType) => (dispatch, getState) => {
    const identifyingNumber = DocumentUtility.getDocumentIdentifyingNumber(document, businessDocType);
    dispatch(documentCreationActions.performDocumentSetOriginalStateInfo(identifyingNumber));
};

const defaultContactValues = (contacts, document, businessDocType, profile, currentCustomerLocation) => {
    const mappedProfile = mapProfileToContact(profile);

    const vendor = contacts.filter((s) => s.ContactType == projectConfig.ContactType.Vendor);
    const shipTo = contacts.filter((s) => s.ContactType == projectConfig.ContactType.ShipTo);
    const billTo = contacts.filter((s) => s.ContactType == projectConfig.ContactType.Buyer);

    switch (businessDocType) {
        case projectConfig.businessDocType.PurchaseOrderAcknowledgement:
            defaultContactTo(vendor, document, mappedProfile);
            defaultContactTo(shipTo, document, currentCustomerLocation);
            defaultContactTo(billTo, document, currentCustomerLocation);
            break;
        case projectConfig.businessDocType.ASNGeneric:
            defaultContactTo(vendor, document, mappedProfile);
            defaultContactTo(shipTo, document, currentCustomerLocation);
            break;
    }
};

const defaultContactTo = (layout, businessDocument, contactValues) => {
    const cellJsonPath = layout[0].cellJsonPath;
    const uniqueNodes = jsonpath.nodes(businessDocument, cellJsonPath);

    uniqueNodes.forEach((sectionNode) => {
        const node = sectionNode.value;
        let useDefault = true;

        for (const key in node) {
            if (['Name', 'AddressBlock', 'Fax', 'Email', 'Phone'].includes(key) && node[key]) {
                useDefault = false;
                break;
            }
        }

        if (useDefault) {
            node.Name = contactValues.Name;
            node.AddressBlock = contactValues.AddressBlock;
            node.Fax = contactValues.Fax;
            node.Email = contactValues.Email;
            node.Phone = contactValues.Phone;
        }
    });
};

const updateLineItemSkeletonWithHeaderFieldValues = () => async (dispatch, getState) => {
    const state = getState();
    const layout = state.documentLayout.layout.Large;
    const bodyJsonPath = layout.Body[0].SectionJSONPath;
    const doc = cloneObjectHack(state.documentCreation.documentCreationFields);
    const fullSkeleton = cloneObjectHack(state.documentCreation.documentSkeleton.fullSkeleton);

    let updateLineItemSkeleton = false;
    layout.Header.forEach((headerCell) => {
        let sectionJsonPath = headerCell.SectionJSONPath;
        let cellJsonPath = headerCell.CellJSONPath;

        let applyToAllSections = false;
        let applyToAllCells = false;
        if (applyToAllSections === false && sectionJsonPath.includes('[0]')) {
            applyToAllSections = sectionJsonPath.substring(0, bodyJsonPath.length) === bodyJsonPath;
        }
        if (applyToAllSections === false && cellJsonPath.includes('[0]')) {
            applyToAllCells = `${sectionJsonPath}.${cellJsonPath}`.substring(0, bodyJsonPath.length) === bodyJsonPath;
        }
        updateLineItemSkeleton = updateLineItemSkeleton || applyToAllSections || applyToAllCells;

        if (applyToAllSections || applyToAllCells) {
            sectionJsonPath =
                applyToAllSections === true
                    ? sectionJsonPath.replace('[0]', '') === sectionJsonPath.substring(0, sectionJsonPath.length - 3)
                        ? `$${sectionJsonPath.replace('[0]', '')}`
                        : `$${sectionJsonPath.replace('[0]', '.')}`
                    : `$${sectionJsonPath}`;

            const uniqueSectionNodes = jsonpath.nodes(fullSkeleton, sectionJsonPath)[0].value;

            let sectionNodes = null;
            if (applyToAllSections === false && uniqueSectionNodes && uniqueSectionNodes.length > 0) {
                sectionNodes = [uniqueSectionNodes[0]];
            } else if (!uniqueSectionNodes.length) {
                sectionNodes = [uniqueSectionNodes];
            } else {
                sectionNodes = uniqueSectionNodes;
            }

            cellJsonPath =
                applyToAllCells === true
                    ? cellJsonPath.replace('[0]', '') === cellJsonPath.substring(0, cellJsonPath.length - 3)
                        ? `$..${cellJsonPath.replace('[0]', '')}`
                        : `$..${cellJsonPath.replace('[0]', '.')}`
                    : `$..${cellJsonPath}`;

            headerCell.Fields.forEach((headerField) => {
                const headerFieldValue = DocumentUtility.determineFieldValue(
                    headerField,
                    doc,
                    headerCell.SectionJSONPath,
                    headerCell.CellJSONPath,
                    null
                );

                sectionNodes.forEach((sectionNode) => {
                    let uniqueCellNodes = null;
                    if (cellJsonPath) {
                        const uniqueCellNodesTemp = jsonpath.nodes(sectionNode, cellJsonPath);
                        uniqueCellNodes =
                            uniqueCellNodesTemp.length && uniqueCellNodesTemp.length === 1
                                ? uniqueCellNodesTemp[0].value
                                : uniqueCellNodesTemp.length
                                ? uniqueCellNodesTemp.map((uniqueCellNode) => uniqueCellNode.value)
                                : uniqueCellNodesTemp.value;
                    } else {
                        uniqueCellNodes = sectionNode;
                    }

                    let cellNodes = null;
                    if (applyToAllCells === false && uniqueCellNodes && uniqueCellNodes.length > 0) {
                        cellNodes = [uniqueCellNodes[0]];
                    } else if (!uniqueCellNodes.length) {
                        cellNodes = [uniqueCellNodes];
                    } else {
                        cellNodes = uniqueCellNodes;
                    }

                    cellNodes.forEach((cellNode) => {
                        jsonpath.value(cellNode, `$..${headerField.JSONPath}`, headerFieldValue);
                    });
                });
            });
        }
    });

    if (updateLineItemSkeleton === true) {
        const lineItemSkeleton = DocumentUtility.determineLineItemSkeleton(state.documentLayout.layout, fullSkeleton);
        await dispatch(
            documentCreationActions.initializeDocumentLineItemSkeletonWithHeaderFieldValues(lineItemSkeleton)
        );
    }
};

const generateGenericDocumentLineItemIds = (businessDocument, bodyJsonPath) => (getState) => {
    const uniqueSectionNodes = jsonpath.nodes(businessDocument, `$${bodyJsonPath}`);
    uniqueSectionNodes[0].value.map((lineItem, index) => (!lineItem.Id ? (lineItem.Id = index + 1) : lineItem.Id));
};

export const performUnloadInvoiceCreation = () => (dispatch, getState) => {
    const {
        userlogin: { timeout },
    } = getState();
    if (timeout) {
        return;
    }
    dispatch(CreateInvoiceActions.unloadInvoiceCreation());
    dispatch(CreateInvoiceActions.performClearInvoiceCreationFields());
    dispatch(LayoutActions.clearLayoutToggleInfo());
};

const createDefaultFixedMiscAmounts = (businessDocType) => async (dispatch, getState) => {
    // console.log('executing createDefaultFixedMiscAmounts');
    const state = getState();
    const layout = layoutSelector(state, true, businessDocType);
    const fixedInvoiceMiscAmountLayout =
        layout && layout.footer && layout.footer.fields
            ? layout.footer.fields.filter((fieldLayout) => {
                  return (
                      fieldLayout.EntityName === projectConfig.EntityName.MiscAmount &&
                      (fieldLayout.Visible === true || fieldLayout.Required === true)
                  );
              })
            : [];

    // Grab any existing populated misc amounts so we don't add them a second time when adding the fields that are visible or required
    let { miscCharges: existingMiscAmounts } = miscChargesInfoSelector(state, businessDocType, true);
    existingMiscAmounts = existingMiscAmounts.filter((miscAmount) => miscAmount.Amount && miscAmount.Amount !== 0);

    // The API currently is returning all misc amounts in the xml regardless of whether or not they are populated, visible, or required.
    // We therefore need to clear out the misc amounts and re-add any that are populated for generic doc types that use misc amounts.
    if (
        businessDocType !== projectConfig.businessDocType.Invoice &&
        businessDocType !== projectConfig.businessDocType.PurchaseOrder
    ) {
        await dispatch(clearMiscAmounts(businessDocType));
        for (let i = 0; i < existingMiscAmounts.length; i++) {
            const existingMiscAmount = existingMiscAmounts[i];
            await dispatch(addMiscAmount(existingMiscAmount, businessDocType));
        }
    }

    for (let i = 0; i < fixedInvoiceMiscAmountLayout.length; i++) {
        const fieldLayout = fixedInvoiceMiscAmountLayout[i];

        let miscAmount = existingMiscAmounts.filter(
            (existingMiscAmount) =>
                existingMiscAmount.AdjustmentMethodId.toString() === fieldLayout.AdjustmentMethodId.toString() &&
                existingMiscAmount.AdjustmentReasonId.toString() === fieldLayout.AdjustmentReasonId.toString() &&
                existingMiscAmount.AmountIndicator.toString() === fieldLayout.AmountIndicator.toString()
        );

        if (miscAmount.length === 0) {
            miscAmount = InvoiceModels.defaultInvoiceMiscAmountsState.state[0];

            miscAmount.AdjustmentMethodId = fieldLayout.AdjustmentMethodId;
            miscAmount.AdjustmentReasonId = fieldLayout.AdjustmentReasonId;
            miscAmount.AmountIndicator = fieldLayout.AmountIndicator;
            miscAmount.Description = fieldLayout.Label;

            await dispatch(addMiscAmount(miscAmount, businessDocType));
        }
    }

    // Update the count of misc amounts that are visible, required, or populate upon first load
    const { miscCharges: newMiscAmountList } = miscChargesInfoSelector(getState(), businessDocType, true);
    dispatch(changeFixedMiscAmountCount(newMiscAmountList.length));
};

const removeUnchangedCommonFields = (document) => {
    // no need to change these for invoice
    if (document.BusinessDocType === BusinessDocType.Invoice) {
        return document;
    }

    return {
        ...document,
        CommonFields: {
            ReceiverProfileId: document.CommonFields.ReceiverProfileId,
            SenderProfileId: document.CommonFields.SenderProfileId,
            SenderProfileType: document.CommonFields.SenderProfileType,
            ReceiverProfileType: document.CommonFields.ReceiverProfileType,
            CustomerId: document.CommonFields.CustomerId,
        },
    };
};

export const saveDraft =
    (
        saveAndPreview,
        businessDocTypePathName,
        documentId,
        businessDocId,
        draft,
        saveRejectedEditsAsDraft,
        customerId,
        customerLocationId
    ) =>
    async (dispatch, getState) => {
        dispatch(documentCreationActions.documentCreationSavingBegin());

        const state = getState();
        const businessDocType = DocumentUtility.getBusinessDocTypeFromPathName(businessDocTypePathName);
        const isInvoice = businessDocType === projectConfig.businessDocType.Invoice;
        const document = isInvoice
            ? cloneObjectHack(state.documentCreation.invoiceCreationFields)
            : cloneObjectHack(state.documentCreation.documentCreationFields);
        delete document.simDocClass;
        businessDocType !== projectConfig.businessDocType.SIMDocument &&
            DocumentUtility.removeBlankDocumentLines(
                state.documentLayout.layout,
                document.BusinessDocFields,
                isInvoice
            );
        if (isInvoice) {
            let profiles = cloneObjectHack(state.profiles.data);
            profiles = profiles.filter((profile) => profile.Id === document.BusinessDocFields.Invoice.InvoiceProfileID);
            const selectedProfile = profiles.length > 0 ? profiles[0] : null;

            DocumentUtility.updateMiscAmountsForSave(projectConfig.businessDocType.Invoice, document);
            DocumentUtility.populateRequiredDefaults(
                projectConfig.businessDocType.Invoice,
                document,
                state.documentCreation.editLayout.fieldDefaults,
                selectedProfile
            );
        } else {
            DocumentUtility.populateCommonBusinessDocumentFields(state.documentLayout.layout, document);
        }
        if (documentId) {
            document.Id = documentId;
            if (isInvoice) {
                document.BusinessDocFields.Invoice.ID = businessDocId;
            } else {
                document.BusinessDocFields.BusinessDocument.Id = businessDocId;
            }
        }
        switch (document.BusinessDocType) {
            case projectConfig.businessDocType.PurchaseOrderAcknowledgement:
                document.BusinessDocFields.BusinessDocument.Document.BusinessDocument.PurchaseOrderAcknowledgement.MiscAmounts.MiscAmount =
                    DocumentUtility.cleanMiscAmounts(
                        document.BusinessDocFields.BusinessDocument.Document.BusinessDocument
                            .PurchaseOrderAcknowledgement.MiscAmounts.MiscAmount
                    );
                break;
            default:
                break;
        }

        const attachments = getState().documentCreation.attachments.files;

        const rejectedFiles = cloneObjectHack(attachments.rejected);
        const savedFiles = cloneObjectHack(attachments.saved);
        const acceptedFiles = cloneObjectHack(attachments.accepted);
        const deletedFiles = cloneObjectHack(attachments.deleted);

        if (deletedFiles && deletedFiles.length > 0) {
            for (const deletedFile of deletedFiles) {
                const documentThunk = new TranscpetaThunks.DocumentThunk();
                const response = await documentThunk.deleteAttachment({ id: deletedFile.Id });
                let errors = false;
                if (response.type && response.type === 'error') {
                    errors = true;
                    dispatch(apiErrorAction(response.text, null, { id: deletedFile.Id }));
                }

                if (!errors) {
                    dispatch(
                        documentActions.documentAttachmentRemoved(
                            acceptedFiles,
                            rejectedFiles,
                            savedFiles,
                            deletedFiles
                        )
                    );
                }
            }
        }

        // Create the parameters for the API call
        const params = {
            documentId: documentId ? documentId : null,
            document: removeUnchangedCommonFields(document),
        };
        dispatch(documentCreationActions.saveDraftExecute(params, businessDocType));

        const documentThunk = new TranscpetaThunks.DocumentThunk();

        const response = await (() => {
            // we have special cases of what we are saving to for invoices that do not
            // apply to other documents
            if (isInvoice) {
                // this case is for drafts. it will update an existing draft if our document object has
                // a draft id already set. it will overwrite an existing draft if one exists with another draft id.
                // otherwise, it creates a new draft.
                if (!documentId) {
                    return documentThunk.saveInvoiceDraft(params);
                }

                // called on parked document - update the existing parked document, return existing parked document id
                // called on rejected document - update status to fixed and creates new draft, returns new draft id
                return documentThunk.updateDocument(params);
            }

            if (!documentId) {
                return documentThunk.createDocumentDraft(params);
            }

            return documentThunk.updateDocument(params);
        })();
        const data = response.data;
        if (response.type && response.type === 'error') {
            dispatch(documentCreationActions.saveDraftFailure(response.text));
            dispatch(apiErrorAction(response.text, documentCreationActions.SAVE_DOCUMENT_DRAFT_FAILURE, params));
        } else {
            const appliedFieldEdits = cloneObjectHack(state.documentCreation.editLayout.appliedFieldEdits);
            dispatch(documentCreationActions.saveDraftSuccess(response));

            if (!isInvoice) {
                await dispatch(saveLayoutOverrides(appliedFieldEdits, null, null, data.ID));
                await dispatch(DocumentThunk.saveDocumentAttachments(null, data.ID, businessDocType, true, true));
            } else if (draft || saveRejectedEditsAsDraft) {
                await dispatch(saveLayoutOverrides(appliedFieldEdits, null, data.ID, null));
                await dispatch(DocumentThunk.saveDocumentAttachments(data.ID, null, businessDocType, true, true));
            } else {
                await dispatch(saveLayoutOverrides(appliedFieldEdits, null, null, data.ID));
                await dispatch(DocumentThunk.saveDocumentAttachments(null, data.ID, businessDocType, true, false));
            }

            dispatch(documentCreationActions.clearFlipCache());
            if (saveAndPreview) {
                if (isInvoice && (!documentId || saveRejectedEditsAsDraft)) {
                    dispatch(
                        push(
                            R.DOCUMENT_DRAFT.path
                                .replace(':businessDocType', businessDocTypePathName)
                                .replace(':draftId', data.ID)
                        )
                    );
                } else {
                    dispatch(
                        push(
                            R.DOCUMENT_DETAILS.path
                                .replace(':businessDocType', businessDocTypePathName)
                                .replace(':documentId', data.ID)
                        )
                    );
                }
            } else if (isInvoice) {
                if (!documentId || saveRejectedEditsAsDraft) {
                    // Upon saving document attachments the cache is cleared. Since the user is still editing, they now need to be pulled from the DB.
                    dispatch(getDocumentAttachments(data.ID, null, businessDocType));
                    dispatch(
                        push(
                            R.DOCUMENT_DRAFT_EDIT.path
                                .replace(':businessDocType', businessDocTypePathName)
                                .replace(':customerId', customerId)
                                .replace(':customerLocationId', customerLocationId)
                                .replace(':draftId', data.ID)
                        )
                    );
                } else {
                    // Upon saving document attachments the cache is cleared. Since the user is still editing, they now need to be pulled from the DB.
                    dispatch(getDocumentAttachments(documentId, null, businessDocType));
                }
            } else if (!documentId || saveRejectedEditsAsDraft) {
                // Upon saving document attachments the cache is cleared. Since the user is still editing, they now need to be pulled from the DB.
                dispatch(getDocumentAttachments(null, data.ID, businessDocType));
                dispatch(
                    push(
                        R.DOCUMENT_EDIT.path
                            .replace(':businessDocType', businessDocTypePathName)
                            .replace(':customerId', customerId)
                            .replace(':customerLocationId', customerLocationId)
                            .replace(':documentId', data.ID)
                    )
                );
            } else {
                // Upon saving document attachments the cache is cleared. Since the user is still editing, they now need to be pulled from the DB.
                dispatch(getDocumentAttachments(null, documentId, businessDocType));
            }
        }

        dispatch(documentCreationActions.documentCreationSavingComplete());
    };

export const saveLayoutOverrides =
    (appliedFieldEdits, supplierCompanyId, draftId, documentId) => async (dispatch, getState) => {
        // Create the basic structure of the parameter object for creating/updating field overrides
        const params = {
            layoutViewModel: {
                SupplierCompanyId: null,
                BusinessDocId: null,
                DraftBusinessDoc: null,
                Layouts: [
                    {
                        Cells: [
                            {
                                Fields: [],
                            },
                        ],
                    },
                ],
            },
        };

        // Set the information needed to create/update the supplier, document, or document draft level overrides
        if (supplierCompanyId) {
            params.layoutViewModel.SupplierCompanyId = supplierCompanyId;
        } else if (documentId) {
            params.layoutViewModel.BusinessDocId = documentId;
            params.layoutViewModel.DraftBusinessDoc = false;
        } else if (draftId) {
            params.layoutViewModel.BusinessDocId = draftId;
            params.layoutViewModel.DraftBusinessDoc = true;
        }

        // Only continue if we are able to determine which level of override we are creating/updating
        if (
            params.layoutViewModel.SupplierCompanyId !== null ||
            (params.layoutViewModel.BusinessDocId !== null && params.layoutViewModel.DraftBusinessDoc !== null)
        ) {
            const state = getState();
            const layout = cloneObjectHack(state.documentLayout.layout);

            // Populate the parameter object with the field overrides to be created/updated
            Object.keys(appliedFieldEdits).forEach((appliedFieldEditKey) => {
                const fieldDictionaryId = appliedFieldEditKey.split(';')[0];
                const layoutRecord = DocumentUtility.findFieldLayoutRecord(layout, Number(fieldDictionaryId));

                layoutRecord.Visible = appliedFieldEdits[appliedFieldEditKey];

                params.layoutViewModel.Layouts[0].Cells[0].Fields.push(layoutRecord);
            });

            // We only need to make the call if there were fields that have been overridden
            if (params.layoutViewModel.Layouts[0].Cells[0].Fields.length > 0) {
                dispatch(documentCreationActions.saveLayoutOverridesExecute(params));

                const documentThunk = new TranscpetaThunks.DocumentThunk();
                await documentThunk.saveLayoutOverrides(params);
            }
        }
    };

export const submitDocument = (businessDocTypePathName, documentId) => async (dispatch, getState) => {
    const businessDocType = DocumentUtility.getBusinessDocTypeFromPathName(businessDocTypePathName);
    const isInvoice = businessDocType === projectConfig.businessDocType.Invoice;
    const document = cloneObjectHack(getState().document.document);
    switch (document.BusinessDocType) {
        case projectConfig.businessDocType.ASN:
        case projectConfig.businessDocType.ASNGeneric:
            document.BusinessDocFields.BusinessDocument.Document.BusinessDocument.ASN.map((ASN) => {
                ASN.Contacts.Contact = DocumentUtility.cleanContacts(ASN.Contacts.Contact);
                ASN.Contacts.ContactPerson = DocumentUtility.cleanContacts(ASN.Contacts.ContactPerson);
                return ASN.Contacts.Contact;
            });
            break;
        case projectConfig.businessDocType.PurchaseOrderAcknowledgement:
            document.BusinessDocFields.BusinessDocument.Document.BusinessDocument.PurchaseOrderAcknowledgement.Contacts.Contact =
                DocumentUtility.cleanContacts(
                    document.BusinessDocFields.BusinessDocument.Document.BusinessDocument.PurchaseOrderAcknowledgement
                        .Contacts.Contact
                );
            document.BusinessDocFields.BusinessDocument.Document.BusinessDocument.PurchaseOrderAcknowledgement.LineItems.LineItem.map(
                (lineItem) => {
                    if (lineItem.MiscAmounts && lineItem.MiscAmounts.MiscAmount) {
                        lineItem.MiscAmounts.MiscAmount = DocumentUtility.cleanMiscAmounts(
                            lineItem.MiscAmounts.MiscAmount
                        );
                    }
                    return lineItem;
                }
            );
            break;
        default:
            break;
    }
    const params = {
        documentId: isInvoice ? null : documentId || 0,
        document: isInvoice
            ? documentId
                ? // When resubmitting a rejected or parked invoice after editing it, there are certain fields that need to be set before calling the API.
                  {
                      ...document,
                      Id: null,
                      ExistingDocumentId: document.Id,
                      CommonFields: {
                          Status: projectConfig.documentStatus.Inserted,
                          ExternalStatus: document.CommonFields.ExternalStatus,
                      },
                  }
                : document
            : // Lloyd and Chris say that the UI must handle the business logic of updating certain fields upon document
              // submission because the API is shared between edit save and draft submit. The update of these fields then
              // triggers other updates that need to happen upon document submisstion.
              //
              // NOTE: This only needs to be done for doc types other than invoice since invoice when submitted has this
              //       handled in the API.
              {
                  ...document,
                  CommonFields: {
                      ...document.CommonFields,
                      Status: projectConfig.documentStatus.Inserted,
                      ExternalStatus: projectConfig.externalStatus.Processing,
                  },
              },
    };
    dispatch(documentCreationActions.submitDocumentExecute(params));

    const documentThunk = new TranscpetaThunks.DocumentThunk();
    const response = isInvoice ? await documentThunk.submitInvoice(params) : await documentThunk.updateDocument(params);
    const data = await response.data;
    if (response.type && response.type === 'error') {
        dispatch(documentCreationActions.submitDocumentFailure(response.text));
        dispatch(apiErrorAction(response.text, null, params));
    } else {
        dispatch(documentCreationActions.submitDocumentSuccess(response));
        dispatch(DocumentThunk.fetchDocument(data.ID, false));
        if (isInvoice) {
            dispatch(
                push(
                    R.DOCUMENT_DETAILS.path
                        .replace(':businessDocType', businessDocTypePathName)
                        .replace(':documentId', data.ID)
                )
            );
        }
    }
};

export const cancelDocumentEditNavigation = (businessDocType, documentId, draftId) => (dispatch, getState) => {
    if (draftId) {
        dispatch(push(R.DOCUMENT_DRAFT.path.replace(':businessDocType', businessDocType).replace(':draftId', draftId)));
    } else if (documentId) {
        dispatch(
            push(
                R.DOCUMENT_DETAILS.path.replace(':businessDocType', businessDocType).replace(':documentId', documentId)
            )
        );
    } else {
        const flip = getState().documentCreation.flip;
        if (flip.isFlip && flip.sourceBusinessDocType && flip.sourceId) {
            const sourceBusinessDocTypePathName = DocumentUtility.getBusinessDocTypePathNameFromType(
                flip.sourceBusinessDocType
            );
            dispatch(
                push(
                    R.DOCUMENT_DETAILS.path
                        .replace(':businessDocType', sourceBusinessDocTypePathName)
                        .replace(':documentId', flip.sourceId)
                )
            );
            dispatch(documentCreationActions.clearFlipCache());
        } else {
            dispatch(push(R.DOCUMENT_SEARCH.path.replace(':businessDocType', businessDocType)));
        }
    }
};

export const getDocumentAttachments = (draftId, documentId, businessDocType) => async (dispatch, getState) => {
    const params = draftId
        ? {
              businessDocumentDraftId: draftId,
              businessDocType,
          }
        : {
              documentId,
          };
    dispatch(documentCreationActions.fetchSupportingDocumentsForEditExecute(params));

    const documentThunk = new TranscpetaThunks.DocumentThunk();
    const response = draftId
        ? await documentThunk.fetchDocumentDraftAttachments(params)
        : await documentThunk.fetchDocumentAttachments(params);
    const data = await response.data;
    if (response.type && response.type === 'error') {
        dispatch(documentCreationActions.fetchSupportingDocumentsForEditFailure(response.text));
        dispatch(apiErrorAction(response.text, null, params));
    } else {
        data.forEach((file) => {
            file.name = file.OriginalFileName;
            file.size = ParseByteFileSize(file.FileSize);
        });
        dispatch(documentCreationActions.fetchSupportingDocumentsForEditSuccess(data));
    }
};

export const clearDocumentCreationCache = () => (dispatch, getState) => {
    dispatch(documentCreationActions.clearDocumentCreationCache());
};

export const fetchDocumentForEdit =
    (draftId, documentId, businessDocType, skipUpdatingStore) => async (dispatch, getState) => {
        const params = draftId ? { businessDocDraftId: draftId, businessdoctype: businessDocType } : { documentId };

        if (skipUpdatingStore === false) {
            dispatch(documentCreationActions.fetchDocumentForEditExecute(documentId, draftId, params));
        }

        const documentThunk = new TranscpetaThunks.DocumentThunk();
        const response = draftId
            ? await documentThunk.fetchDocumentDraft(params)
            : await documentThunk.fetchDocument(params);
        const data = await response.data;
        removeUnusedDocumentFields(data);
        if (response.type && response.type === 'error') {
            dispatch(documentCreationActions.fetchDocumentForEditFailure(response.text));
            dispatch(apiErrorAction(response.text, null, params));
            console.error('failed to load document for edit');
            legacyErrorHandler.showError('DocumentDisplay.DependencyFailedToLoad');
        } else if (skipUpdatingStore === false) {
            if (!data || !data.BusinessDocType) {
                dispatch(documentCreationActions.fetchDocumentForEditNotFound(documentId));
            } else if (businessDocType === projectConfig.businessDocType.Invoice) {
                dispatch(documentCreationActions.fetchInvoiceDocumentForEditSuccess(data));
            } else {
                dispatch(documentCreationActions.fetchDocumentForEditSuccess(data));
            }
        }

        if (skipUpdatingStore === false) {
            dispatch(getDocumentAttachments(draftId, documentId, businessDocType));
        }

        return data;
    };

export const addDocumentLineItem = () => async (dispatch, getState) => {
    // console.log('executing addDocumentLineItem');
    const state = getState();

    // We need to clone the business document so we don't mutate the redux state when we add the new line.
    // NOTE: lodash cloneDeep for some reason is not preventing mutation when used here so we are using a less elegant clone solution JSON.parse(JSON.stringify(object))
    const businessDocument = cloneObjectHack(
        state.documentCreation.documentCreationFields.BusinessDocFields.BusinessDocument
    );
    await dispatch(updateLineItemSkeletonWithHeaderFieldValues());
    // The line item skeleton will be used as the structure template for the new line being added.
    const lineItemSkeleton = cloneObjectHack(getState().documentCreation.documentSkeleton.lineItemSkeleton);
    // Find all the line nodes
    const bodyJsonPath = `$${state.documentLayout.layout.Large.Body[0].SectionJSONPath}`;
    const uniqueSectionNodes = jsonpath.nodes(businessDocument, bodyJsonPath);

    if (Array.isArray(uniqueSectionNodes[0].value)) {
        // Push the new line node
        lineItemSkeleton.Id = uniqueSectionNodes[0].value.length + 1;
        uniqueSectionNodes[0].value.push(lineItemSkeleton);
    } else {
        // Convert the single line object into a line array with the new line node added as the second object.
        lineItemSkeleton.Id = 1;
        jsonpath.value(businessDocument, bodyJsonPath, [uniqueSectionNodes[0].value, lineItemSkeleton]);
    }

    // Call the action to update the redux state
    dispatch(documentCreationActions.addDocumentLine(businessDocument));
};

export const removeDocumentLineItem = (index, businessDocType) => (dispatch, getState) => {
    // console.log('executing removeDocumentLineItem');
    const state = getState();
    const layout = layoutSelector(state, true, businessDocType);

    // We need to clone the business document so we don't mutate the redux state when we remove the line.
    // NOTE: lodash cloneDeep for some reason is not preventing mutation when used here so we are using a less elegant clone solution JSON.parse(JSON.stringify(object))
    const businessDocument = cloneObjectHack(
        state.documentCreation.documentCreationFields.BusinessDocFields.BusinessDocument
    );

    // Find all the line nodes
    const bodyJsonPath = `$${state.documentLayout.layout.Large.Body[0].SectionJSONPath}`;
    const uniqueSectionNodes = jsonpath.nodes(businessDocument, bodyJsonPath);

    // Remove the line
    if (uniqueSectionNodes[0].value.length > 1) {
        uniqueSectionNodes[0].value.splice(index, 1);
    }
    // If there is only one line, clear any values it may contain
    else {
        const lineItemSkeleton =
            state.documentCreation.documentSkeleton.lineItemSkeleton.length > 0
                ? [cloneObjectHack(state.documentCreation.documentSkeleton.lineItemSkeleton[0])]
                : [cloneObjectHack(state.documentCreation.documentSkeleton.lineItemSkeleton)];
        dispatch(calculateGenericLineItemNetTotal(lineItemSkeleton, businessDocument, layout, true));
        jsonpath.value(businessDocument, bodyJsonPath, lineItemSkeleton);
    }

    dispatch(calculateGenericSubtotal(layout, businessDocument, true));

    // Call the action to update the redux state
    dispatch(documentCreationActions.removeDocumentLine(businessDocument));
};

export const removeLineItem = (event, lineIndex, lineItems, businessDocTypePathName) => (dispatch, getState) => {
    event.preventDefault();
    const businessDocType = DocumentUtility.getBusinessDocTypeFromPathName(businessDocTypePathName);
    if (businessDocType === projectConfig.businessDocType.Invoice) {
        let newInvoiceLineItems = lineItems.filter((item, index) => index !== lineIndex);
        if (newInvoiceLineItems.length < 1) {
            newInvoiceLineItems.push(InvoiceModels.initialState.state.BusinessDocFields.Invoice.InvoiceLineItems[0]);
        }
        newInvoiceLineItems = newInvoiceLineItems.map((line, index) => {
            line.LineNum = index + 1;
            return line;
        });
        // Delete Invoice Item in state
        dispatch(performChangeInvoiceField('InvoiceLineItems', newInvoiceLineItems));
    } else {
        dispatch(removeDocumentLineItem(lineIndex));
    }
};

export const fetchDocumentSkeleton = (businessDocType, buyerCompanyId, customerId) => async (dispatch, getState) => {
    const params = {
        businessDoctype: businessDocType,
        buyerCompanyId,
        customerId,
        // We need the layout that is pulled during this request to return as many fields as possible to get the most accurate generic document xml strcuture.
        // CSP is highly likely to contain the superset of all fields that would be in either the buyer or supplier portal for any given doc type which is why we pass it here.
        client: 2, // CSP
        // Passing a supplier company id currently will only slow down this request. It is only being used to update the visible flag when looking up the layout which is not a
        // value that is needed for returning the skeleton. We should not pass it.
        supplierCompanyId: null,
        documentClass: getState().documentCreation.documentCreationFields.simDocClass,
    };
    const state = getState();
    dispatch(documentCreationActions.fetchDocumentSkeletonExecute(params));

    const documentThunk = new TranscpetaThunks.DocumentThunk();
    const response = await documentThunk.fetchDocumentSkeleton(params);
    const data = await response.data;
    removeUnusedDocumentFields(data);
    if (response.type && response.type === 'error') {
        dispatch(documentCreationActions.fetchDocumentSkeletonFailure(response.text));
        dispatch(
            pushAlert({
                type: 'error',
                text: parseMessage(path(['response', 'data', 'errors'], response.text)),
            })
        );
    } else {
        const layout = getState().documentLayout.layout;

        if (Object.keys(layout).length === 0) {
            dispatch(documentActions.shouldShow404Error(true));
            return;
        }

        if (!state.documentCreation.flip.isFlip) {
            dispatch(documentCreationActions.performDocumentLoad(data));
            if (businessDocType === 3) {
                const document = data;
                document.BusinessDocFields.BusinessDocument.Document.BusinessDocument.PurchaseOrderAcknowledgement.Currency = 1;
                dispatch(documentActions.documentFetched(document));
            } else {
                dispatch(documentActions.documentFetched(data));
            }
        }

        let fullSkeleton;
        if (businessDocType === 1) {
            fullSkeleton = data;
        } else {
            fullSkeleton = data.BusinessDocFields.BusinessDocument.Document;
        }
        const lineItemSkeleton =
            businessDocType !== projectConfig.businessDocType.SIMDocument
                ? DocumentUtility.determineLineItemSkeleton(layout, fullSkeleton)
                : null;

        dispatch(
            documentCreationActions.fetchDocumentSkeletonSuccess({
                fullSkeleton,
                lineItemSkeleton,
            })
        );

        return cloneObjectHack(fullSkeleton);
    }
};

export const toggleFieldVisibility = (fieldToggleKey, newVisibility) => (dispatch, getState) => {
    dispatch(LayoutActions.popupFieldChange(fieldToggleKey, newVisibility));
};

export const toggleOverrideType = (supplierOverride) => (dispatch, getState) => {
    dispatch(LayoutActions.overrideTypeChange(supplierOverride));
};

export const toggleOverridePopup = () => (dispatch, getState) => {
    dispatch(LayoutActions.toggleConfigPopup());
};

export const applyOverrides = () => (dispatch, getState) => {
    const state = getState();
    const supplierOverride = state.documentCreation.editLayout.supplierOverride;
    const editedFields = cloneObjectHack(state.documentCreation.editLayout.editedFields);
    const previousAppliedFieldEdits = cloneObjectHack(state.documentCreation.editLayout.appliedFieldEdits);

    dispatch(LayoutActions.applyFieldsConfig());

    if (supplierOverride) {
        const supplierCompanyId = portalUserService.getCurrentCompanyId();
        dispatch(saveLayoutOverrides(editedFields, supplierCompanyId, null, null));
    }

    // Apply field defaulting logic for the fields that had their visibility changed
    const layout = cloneObjectHack(state.documentLayout.layout);
    const fieldDefaults = cloneObjectHack(state.documentCreation.editLayout.fieldDefaults);
    Object.keys(editedFields).forEach((editedFieldKey) => {
        const fieldId = editedFieldKey.split(';')[0];
        const field = DocumentUtility.findFieldLayoutRecord(layout, Number(fieldId));

        if (field !== null) {
            const newVisibility = editedFields[editedFieldKey];
            const oldVisibility = previousAppliedFieldEdits[editedFieldKey]
                ? previousAppliedFieldEdits[editedFieldKey]
                : field.Visible || field.Required;

            if (newVisibility !== oldVisibility) {
                let newValue = null;
                if (newVisibility) {
                    if (fieldDefaults[`${field.FieldDictionaryId}`]) {
                        newValue = fieldDefaults[`${field.FieldDictionaryId}`];
                    }
                }

                if (field.EntityName === projectConfig.EntityName.LineItemMiscAmount) {
                    if (newVisibility) {
                        // The field name on misc amount layout records will not match the name of the field that needs updated by the default logic.
                        // The field that should be updated will always be "Amount".
                        dispatch(performDocumentFieldValueUpdate(field, 'Amount', newValue, null, true));
                    } else {
                        // The field is being removed. Since each misc amount field is an entire child record of the document line item, the entire misc amount record needs to be
                        // removed instead of just updating the amount to be null. Setting the field name as null and setting the update all instances flag will trigger them to be
                        // removed instead of updated.
                        dispatch(performDocumentFieldValueUpdate(field, null, null, null, true));
                    }
                } else if (field.EntityName === projectConfig.EntityName.MiscAmount) {
                    // The field name on misc amount layout records will not match the name of the field that needs updated by the default logic.
                    // The field that should be updated will always be "Amount".
                    dispatch(performDocumentFieldValueUpdate(field, 'Amount', newValue, null, true));
                } else {
                    dispatch(performDocumentFieldValueUpdate(field, field.FieldName, newValue, null, true));
                }
            }
        }
    });
};

export const updateDocumentProfile = (profileId, businessDocType) => async (dispatch, getState) => {
    const state = getState();
    const documentCreationFields = state.documentCreation.documentCreationFields;
    const oldProfileId = documentCreationFields.CommonFields.SenderProfileId;

    if (oldProfileId !== profileId) {
        await dispatch(documentCreationActions.setSenderProfile(profileId));
        const { reducedHeader } = layoutSelector(state, true, businessDocType);
        const businessDocument = cloneObjectHack(documentCreationFields.BusinessDocFields.BusinessDocument);
        const bodyJsonPath = state.documentLayout.layout.Large.Header[0].SectionJSONPath;
        const document = jsonpath.nodes(businessDocument, `$${bodyJsonPath}`)[0].value;
        const contacts = reducedHeader.fields.filter(
            (s) => s.EntityName == projectConfig.EntityName.Contact && (s.Visible === true || s.Required === true)
        );
        const {
            profiles: { data },
        } = state;

        const oldProfile = findProfile(data, oldProfileId);
        const contactLayout = contacts.filter((s) => s.ContactType == projectConfig.ContactType.Vendor);
        const newProfile = findProfile(data, profileId);

        if (contactLayout && newProfile) {
            updateAffectedFields(oldProfile, newProfile, document, contactLayout);
            await dispatch(documentCreationActions.performDocumentFieldValueUpdate(businessDocument));
        }
    }
};

const findProfile = (profiles, profileId) => profiles.filter((s) => s.Id === profileId)[0];

const mapProfileToContact = (profile) => ({
    ...profile,
    AddressBlock: buildProfiledAddressBlock(profile),
});

const updateAffectedFields = (oldProfile, newProfile, document, layout) => {
    const cellJsonPath = layout[0].cellJsonPath;
    const uniqueNodes = jsonpath.nodes(document, cellJsonPath);

    const oldAddressBlock = buildProfiledAddressBlock(oldProfile);
    const newAddressBlock = buildProfiledAddressBlock(newProfile);

    uniqueNodes.forEach((sectionNode) => {
        const contact = sectionNode.value;
        if (oldAddressBlock == contact.AddressBlock) {
            contact.AddressBlock = newAddressBlock;
        }
        if (oldProfile.Name == contact.Name) {
            contact.Name = newProfile.Name;
        }
        if (oldProfile.Fax == contact.Fax) {
            contact.Fax = newProfile.Fax;
        }
        if (oldProfile.Email == contact.Email) {
            contact.Email = newProfile.Email;
        }
        if (oldProfile.Phone == contact.Phone) {
            contact.Phone = newProfile.Phone;
        }
    });
};

const buildProfiledAddressBlock = (profile) =>
    CreateAddressBlock({
        line1: profile.Address1,
        line2: profile.Address2,
        line3: profile.Address3,
        line4: profile.Address4,
        city: profile.City,
        state: profile.State,
        zip: profile.PostalCode,
        country: profile.Country,
    });
