import { CompanyGLAccountStructureApi } from '../Api/CompanyGLAccountStructure';
import {
    ICompanyGLAccountStructure,
    ICompanyGLAccountStructureData,
    ICompanyGLAccountStructureGrid,
    ICompanyGLAccountStructureSearchItems,
} from './types';
import { ICompanyGLAccountStructureViewModel } from '../Api/ViewModels';

import { createCompanyGLAccountStructureGridFilter } from './CompanyGLAccountStructure.filter';
import { SegmentDataType } from '../../../types';
import { validateData } from '../../../ui';

export class CompanyGLAccountStructureService {
    api = new CompanyGLAccountStructureApi();

    private transformFromViewModel(model: ICompanyGLAccountStructureViewModel): ICompanyGLAccountStructure {
        return {
            id: model.Id,
            name: model.Name,
            companyId: model.CompanyId,
            active: model.Active,
            codingValidationJavaScript: model.CodingValidationJavaScript,
            codingValidationJavaScriptPath: model.CodingValidationJavaScriptPath,
            glStructure: {
                glSegments: {
                    '@id': model.GLStructure.GLSegments['@id'],
                    glSegment: model.GLStructure.GLSegments.GLSegment,
                },
            },
        };
    }

    private transformToViewModel(
        data: ICompanyGLAccountStructureData
    ): Omit<ICompanyGLAccountStructureViewModel, 'Id'> {
        return {
            Name: data.name,
            CompanyId: data.companyId!,
            Active: data.active,
            CodingValidationJavaScript: data.codingValidationJavaScript || null,
            CodingValidationJavaScriptPath: data.codingValidationJavaScriptPath || null,
            GLStructure: {
                GLSegments: {
                    '@id': data.glStructure.glSegments['@id'],
                    GLSegment: data.glStructure.glSegments.glSegment,
                },
            },
        };
    }

    private cleanEnumTypeSegments(state: ICompanyGLAccountStructureData): void {
        state.glStructure.glSegments.glSegment?.forEach((segment) => {
            segment['@id'] = undefined;
            if (segment.DataType === SegmentDataType.Enum && segment.Enums) {
                segment.Enums.Value = segment.Enums.Value.filter((val) => val.length);
            }
        });
    }

    public async getCompanyGLAccountStructure(id: number): Promise<ICompanyGLAccountStructure> {
        const viewModel = await this.api.getCompanyGLAccountStructure(id);

        return this.transformFromViewModel(viewModel.data[0]);
    }

    public async getCompanyGLAccountStructureGrid(
        searchInfo: ICompanyGLAccountStructureSearchItems,
        orderBy?: string
    ): Promise<ICompanyGLAccountStructureGrid[]> {
        const filter = createCompanyGLAccountStructureGridFilter(searchInfo);

        const viewModel = await this.getCompanyGLAccountStructureByCompanyId(undefined, filter, orderBy);

        const newGridData: ICompanyGLAccountStructureGrid[] = viewModel.map(({ id, name, active, companyId }) => ({
            id,
            name,
            active,
            companyId,
        }));

        return newGridData;
    }

    public async getCompanyGLAccountStructureByCompanyId(
        companyId?: number,
        filter?: string,
        orderBy?: string
    ): Promise<ICompanyGLAccountStructure[]> {
        const viewModel = await this.api.getCompanyGLAccountStructureByCompanyId(companyId, filter, orderBy);

        return viewModel.data?.map(this.transformFromViewModel);
    }

    private validateGLAccountStructure(glAccStructure: ICompanyGLAccountStructureData) {
        validateData((errors) => {
            if (!glAccStructure.companyId) {
                errors.push('Company is required');
            }

            if (!glAccStructure.name) {
                errors.push('Name is required');
            }

            glAccStructure.glStructure.glSegments.glSegment?.forEach((segment) => {
                if (!segment.Name) {
                    errors.push(`Name ${segment.Order} is required`);
                }

                if (!segment.Label) {
                    errors.push(`Label ${segment.Order} is required`);
                }

                if (segment.DataType === SegmentDataType.Enum && (!segment.Enums || !segment.Enums.Value.length)) {
                    errors.push(`Enum Values ${segment.Order} are required`);
                }

                if (segment.DataType === SegmentDataType.CompanyGLAccount) {
                    if (!segment.CompanyGLAccountSegmentName) {
                        errors.push(`GL Account Segment Name ${segment.Order} is required`);
                    }

                    if (!segment.CompanyGLAccountDepth) {
                        errors.push(`Depth Label ${segment.Order} is required`);
                    }

                    if (!segment.CompanyGLAccountMinCharsSearch) {
                        errors.push(`Min Chars for Search ${segment.Order} is required`);
                    }

                    if (!segment.CompanyGLAccountSmallRecordLimit) {
                        errors.push(`Small List Record Limit ${segment.Order} is required`);
                    }
                }

                if (segment.DataType === SegmentDataType.CompanyCustomData) {
                    if (!segment.CompanyCustomDatasetName) {
                        errors.push(`Dataset Name ${segment.Order} is required`);
                    }

                    if (!segment.CompanyCustomDatasetKey) {
                        errors.push(`Key Field ${segment.Order} is required`);
                    }

                    if (!segment.CompanyCustomDatasetPath) {
                        errors.push(`XML Path ${segment.Order} is required`);
                    }

                    if (!segment.CompanyCustomDatasetMinCharsSearch) {
                        errors.push(`Min Chars for Search ${segment.Order} is required`);
                    }

                    if (!segment.CompanyCustomDatasetSmallRecordLimit) {
                        errors.push(`Small List Record Limit ${segment.Order} is required`);
                    }
                }
            });
        });
    }

    public async createCompanyGLAccountStructure(data: Omit<ICompanyGLAccountStructure, 'id'>): Promise<number> {
        this.cleanEnumTypeSegments(data);
        this.validateGLAccountStructure(data);
        const model = this.transformToViewModel(data);

        const res = await this.api.createCompanyGLAccountStructure(model);

        return res.data.IDs[0];
    }

    public async updateCompanyGLAccountStructure(
        id: number,
        data: Omit<ICompanyGLAccountStructure, 'id'>
    ): Promise<number> {
        this.cleanEnumTypeSegments(data);
        this.validateGLAccountStructure(data);
        const model = this.transformToViewModel(data);

        const res = await this.api.updateCompanyGLAccountStructure(id, model);

        return res.data.ID;
    }

    public async deleteCompanyGLAccountStructure(id: number): Promise<number> {
        const res = await this.api.deleteCompanyGLAccountStructure(id);
        return res.data.ID;
    }
}
