import { IField, IFieldRendererViewModel } from '../types';
import { getValue, setValue, quote } from './dotNotation';
import { jsXMLObject } from '../../../utils';

/**
 * Implements the IFieldRendererViewModel for a nested object, using DotNotation for dataBindingKeys
 */
export class DotNotationFieldRendererViewModel<T> implements IFieldRendererViewModel {
    constructor(
        public readonly state: T,
        public readonly environment: Record<string, unknown>,
        private keyPrefix?: string,
        private computeFields?: (state: T) => T
    ) {}

    /**
     * When storing custom fields in an "XML field", there must be a single "root object".
     *
     * Pass the current value of such an "XML field" to discover this root object automatically
     * or "null" if the "XML field" is currently empty.
     *
     * The resulting view model's state will have a single root object.
     *
     * @param data the XML field data (as a JS object)
     * @param environment the environment containing variables for DotNotation keys to use
     * @returns a view model that reads / writes under the single root object.
     */
    static fromXMLBasedObject(data: object | null, environment: Record<string, unknown>) {
        const xmlObj = data ?? { root: {} };
        const keyPrefix = quote(jsXMLObject.findRootElementName(xmlObj));
        return new DotNotationFieldRendererViewModel(xmlObj, environment, keyPrefix);
    }

    private getDataBindingKey(dataBindingKey: string) {
        if (this.keyPrefix) {
            return `${this.keyPrefix}.${dataBindingKey}`;
        }

        return dataBindingKey;
    }

    setEnvironment(environment: Record<string, unknown>): this {
        return new DotNotationFieldRendererViewModel<T>(
            this.state,
            environment,
            this.keyPrefix,
            this.computeFields
        ) as this;
    }

    getValue<Field extends IField>(field: Field): Field['defaultValue'] {
        return this.getRegularValue(field) ?? this.getComputedValue(field) ?? field.defaultValue;
    }

    isUsingComputedValue<Field extends IField>(field: Field) {
        return this.getRegularValue(field) === undefined && this.getComputedValue(field) !== undefined;
    }

    isOverridingComputedValue<Field extends IField>(field: Field) {
        return this.getRegularValue(field) !== undefined && this.getComputedValue(field) !== undefined;
    }

    private getRegularValue<Field extends IField>(field: Field) {
        return this.getValueWithKey<Field['defaultValue']>(field.dataBindingKey);
    }

    private getComputedValue<Field extends IField>(field: Field) {
        if (field.computedDataBindingKey) {
            return this.getValueWithKey<Field['defaultValue']>(field.computedDataBindingKey);
        }

        return undefined;
    }

    getValueWithKey<X>(dataBindingKey: string): X | undefined {
        return getValue(this.getDataBindingKey(dataBindingKey), this.state, this.environment);
    }

    setValue<Field extends IField>(field: Field, value: Field['defaultValue'] | undefined) {
        return this.setValueWithKey(field.dataBindingKey, value);
    }

    setValueWithKey<X>(dataBindingKey: string, value: X): this {
        let newValue = setValue(this.getDataBindingKey(dataBindingKey), this.state, this.environment, value);
        if (this.computeFields) {
            newValue = this.computeFields(newValue);
        }

        return new DotNotationFieldRendererViewModel<T>(
            newValue,
            this.environment,
            this.keyPrefix,
            this.computeFields
        ) as this;
    }

    setComputeFields(computeFields: (state: T) => T) {
        return new DotNotationFieldRendererViewModel<T>(
            this.state,
            this.environment,
            this.keyPrefix,
            computeFields
        ) as this;
    }
}
