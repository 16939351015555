import { useResettableState } from './useResettableState';

/**
 * Returns a stable reference to a value that is updated
 * whenever the JSON representation of the value changes.
 *
 * @param inputValue the input value to make stable
 * @returns the stable reference
 */
export function useStableReference<T>(inputValue: T) {
    return useResettableState(inputValue)[0];
}
