import { ValidationEvent } from '../../CustomJSRuleEngineV2/ExecutionService/DPSValidations';
import { DocumentValidationConfiguration } from './DocumentValidationConfiguration';

interface IdleState {
    type: 'IdleState';
}

function createIdleState(): IdleState {
    return {
        type: 'IdleState',
    };
}

interface ValidatingState {
    type: 'ValidatingState';
}

function createValidatingState(): ValidatingState {
    return {
        type: 'ValidatingState',
    };
}

interface ValidationSucceededState {
    type: 'ValidationSucceededState';
}

interface ValidationSucceededState {
    type: 'ValidationSucceededState';
}

export function createValidationSucceededState(): ValidationSucceededState {
    return {
        type: 'ValidationSucceededState',
    };
}

export interface ValidationFailedState {
    type: 'ValidationFailedState';
}

export function createValidationFailedState(): ValidationFailedState {
    return {
        type: 'ValidationFailedState',
    };
}

export type ValidatorResultState = IdleState | ValidatingState | ValidationSucceededState | ValidationFailedState;

export class ClientValidationController {
    private validatorResultState: ValidatorResultState = createIdleState();

    constructor(private readonly validationConfiguration: Omit<DocumentValidationConfiguration, 'inputParameters'>) {}

    public shouldCaptureDocumentState() {
        return (
            this.validatorResultState.type === 'ValidationSucceededState' ||
            this.validatorResultState.type === 'ValidationFailedState'
        );
    }

    public canRunValidations() {
        return (
            this.validatorResultState.type === 'IdleState' ||
            this.validatorResultState.type === 'ValidationSucceededState' ||
            this.validatorResultState.type === 'ValidationFailedState'
        );
    }

    public isValidating() {
        return this.validatorResultState.type === 'ValidatingState';
    }

    public isValidationNeeded(event: ValidationEvent) {
        if (event.type === 'SupportingDocumentChangeEvent') {
            return true;
        }

        const { eventsToValidateOn, fieldNamesToValidateOnFocusLoss } = this.validationConfiguration;

        if (!eventsToValidateOn.includes(event.type)) {
            return false;
        }

        if (event.type === 'FocusLossEvent' && !fieldNamesToValidateOnFocusLoss.includes(event.fieldName)) {
            return false;
        }

        return true;
    }

    public shouldDisableActionButton(eventType: ValidationEvent['type']) {
        const invalidValidatorState = this.validatorResultState.type === 'ValidatingState';

        if (invalidValidatorState) {
            return true;
        }

        if (
            eventType === 'FocusLossEvent' ||
            eventType === 'SupportingDocumentChangeEvent' ||
            eventType === 'WorkflowLoadEvent'
        ) {
            throw new Error('Unexpected Event Type');
        }

        if (!this.isValidationNeeded({ type: eventType })) {
            return false;
        }

        if (eventType === 'WorkflowSubmitEvent' && this.workflowSubmitEventIsAllowedInValidationFailedState) {
            return false;
        }

        return this.validatorResultState.type === 'ValidationFailedState';
    }

    get workflowSubmitEventIsAllowedInValidationFailedState() {
        // check the documentation for DisableSubmitWhenFocusLossJShasValidationErrors
        return this.validationConfiguration.DisableSubmitWhenFocusLossJShasValidationErrors !== true;
    }

    get hasRanValidations() {
        return this.validatorResultState.type !== 'IdleState';
    }

    public setValidatingState() {
        this.validatorResultState = createValidatingState();
    }

    public setValidationSucceededState() {
        this.validatorResultState = createValidationSucceededState();
    }

    public setValidationFailedState() {
        this.validatorResultState = createValidationFailedState();
    }
}
