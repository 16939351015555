import { IsNotUndefinedNullOrEmpty, AddNewLine, IsUndefinedNullOrEmpty } from 'common/build/legacy/transcepta-common';

export function CreateAddressBlock(address) {
    const countryIsUSA = IsUndefinedNullOrEmpty(address.country) || address.country.toUpperCase() === 'USA';

    let addressBlock = '';

    if (IsNotUndefinedNullOrEmpty(address.line1)) {
        addressBlock = AddNewLine(addressBlock, address.line1);
    }

    if (IsNotUndefinedNullOrEmpty(address.line2)) {
        addressBlock = AddNewLine(addressBlock, address.line2);
    }

    if (IsNotUndefinedNullOrEmpty(address.line3)) {
        addressBlock = AddNewLine(addressBlock, address.line3);
    }

    if (IsNotUndefinedNullOrEmpty(address.line4)) {
        addressBlock = AddNewLine(addressBlock, address.line4);
    }

    if (
        IsNotUndefinedNullOrEmpty(address.city) ||
        IsNotUndefinedNullOrEmpty(address.state) ||
        IsNotUndefinedNullOrEmpty(address.zip)
    ) {
        let line = '';
        if (IsNotUndefinedNullOrEmpty(address.city)) {
            line = address.city;
        }
        if (IsNotUndefinedNullOrEmpty(address.state)) {
            line = line + (line.length > 0 ? (countryIsUSA ? ', ' : ' ') : '') + address.state;
        }
        if (IsNotUndefinedNullOrEmpty(address.zip)) {
            line = line + (line.length > 0 ? ' ' : '') + address.zip;
        }

        addressBlock = AddNewLine(addressBlock, line);
    }

    if (IsNotUndefinedNullOrEmpty(address.country) && !countryIsUSA) {
        addressBlock = AddNewLine(addressBlock, address.country);
    }

    return addressBlock;
}
