import {
    CLEAR_DOCUMENT_CREATION_CACHE,
    CLEAR_SUPPORTING_DOCUMENT_CACHE,
    FETCH_SUPPORTING_DOCUMENTS_FOR_EDIT_EXECUTE,
    FETCH_SUPPORTING_DOCUMENTS_FOR_EDIT_FAILURE,
    FETCH_SUPPORTING_DOCUMENTS_FOR_EDIT_SUCCESS,
    SUPPORTING_DOCUMENT_REMOVED,
    SUPPORTING_DOCUMENTS_ADDED,
} from '../../actions/document/documentCreationActions';
import { USER_LOGIN_FAILED, USER_LOGIN_PENDING, USER_LOGIN_SUCCESS, USER_LOGOUT } from '../../actions/userlogin';

const initialState = {
    files: {
        accepted: [],
        deleted: [],
        rejected: [],
        saved: [],
    },
    isLoading: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_DOCUMENT_CREATION_CACHE:
        case CLEAR_SUPPORTING_DOCUMENT_CACHE:
        case USER_LOGIN_FAILED:
        case USER_LOGIN_PENDING: {
            // if it was timeout return currenState, otherwhise initialState
            const { timeout } = state;
            return timeout && timeout === true ? state : initialState;
        }
        case USER_LOGIN_SUCCESS: {
            // if it was timeout return currenState, otherwhise initialState
            // remove timeout flag
            const { timeout, ...currentState } = state;
            return timeout && timeout === true ? currentState : initialState;
        }

        case USER_LOGOUT: {
            // if timeout, add the flag and return currentState
            const { timeout } = action.payload;
            return timeout && timeout == true ? { ...state, timeout } : initialState;
        }

        case FETCH_SUPPORTING_DOCUMENTS_FOR_EDIT_EXECUTE:
            return {
                ...state,
                isLoading: true,
                files: {
                    ...state.files,
                    deleted: initialState.files.deleted,
                    saved: initialState.files.saved,
                },
            };

        case FETCH_SUPPORTING_DOCUMENTS_FOR_EDIT_FAILURE:
            return {
                ...state,
                isLoading: false,
                files: {
                    ...state.files,
                    deleted: initialState.files.deleted,
                    saved: initialState.files.saved,
                },
            };

        case FETCH_SUPPORTING_DOCUMENTS_FOR_EDIT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                files: {
                    ...state.files,
                    deleted: initialState.files.deleted,
                    saved: action.payload.files,
                },
            };

        case SUPPORTING_DOCUMENT_REMOVED:
            return {
                ...state,
                files: {
                    ...state.files,
                    accepted: action.payload.acceptedFiles,
                    deleted: action.payload.deletedFiles,
                    rejected: action.payload.rejectedFiles,
                    saved: action.payload.savedFiles,
                },
            };

        case SUPPORTING_DOCUMENTS_ADDED:
            return {
                ...state,
                files: {
                    ...state.files,
                    accepted: action.payload ? state.files.accepted.concat(action.payload) : state.files.accepted,
                },
            };

        default:
            return state;
    }
};
