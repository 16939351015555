import { FC } from 'react';
import { LoginPage } from 'common/build/legacy/transcepta-ui-core';
import { ScopedCssBaseline, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { isIE } from 'common/build/legacy/transcepta-common';
import {
    authUserByLoginPassword,
    autoLoginAction,
    resetPassword,
    resetPasswordRecoverStatus,
} from '../../../actions/userlogin';
import LoginSupplierArticle from './LoginSupplierArticle';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        height: '95vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const Login: FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const selector = useSelector((state) => state as any);

    const autoLogin = async () => {
        await dispatch(autoLoginAction());
    };

    const signIn = async (email: string, password: string, companyId: string) => {
        await dispatch(authUserByLoginPassword(email, password, companyId));
    };

    const reset = async (email: string) => {
        await dispatch(resetPassword(email));
    };

    const resetRecoverStatus = () => {
        dispatch(resetPasswordRecoverStatus());
    };

    const root = (
        <div className={classes.root}>
            <LoginPage
                defaultFormVariant="internal"
                article={<LoginSupplierArticle />}
                companySignIn={signIn}
                reset={reset}
                resetRecoverStatus={resetRecoverStatus}
                userLogin={selector.userlogin}
                autoLogin={autoLogin}
            />
        </div>
    );

    if (isIE()) {
        return root;
    }

    return <ScopedCssBaseline>{root}</ScopedCssBaseline>;
};

export default Login;
