import { RoleType, IUserRoleViewModel } from '../Api';

function isRoleType(n: string | RoleType): n is RoleType {
    return !Number.isNaN(Number(n));
}

export function getAllUserRoles(userId: number): IUserRoleViewModel[] {
    const roleNumbers = Object.values(RoleType)
        .filter(isRoleType)
        .filter((n) => n !== RoleType.ParkingLotOnly); // we need to filter this out because it will restrict the user (yes the fact that this role exists is an insult to all well designed access control systems)

    return roleNumbers.map((n) => ({
        UserID: userId,
        RoleID: n,
        Rowversion: 'AAAAAAWx1j8=' as any,
        LastUpdateDate: '"2022-04-22T13:34:41.157' as any,
    }));
}
