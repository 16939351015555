// TODO: Update all code in our application to stop using this legacy utility and use our new date utility instead

/**
 * @deprecated do not use in new code
 */
export const defaultMinDate = '1970-01-01T00:00:00Z';
/**
 * @deprecated do not use in new code
 */
export const defaultMaxDate = '2038-01-01T00:00:00Z';

/**
 * @deprecated Do not use in new code
 */
export const getUtcDate = (startDate: Date, endDate: Date) => {
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    const utcStartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString();
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString();
    return { utcStartDate, utcEndDate };
};

/**
 * @deprecated Do not use in new code
 */
export const getUtcDateByHoursDifference = (hours: number) => {
    return new Date(Date.now() - new Date().getTimezoneOffset() * 60000 - hours * 3600000).toISOString();
};

/**
 * @deprecated Do not use in new code
 */
export const convertStringToDate = (dates: string[]) => {
    if (dates.length < 1) {
        return new Date(defaultMinDate);
    } else if (dates.length === 1) {
        return new Date(dates[0]);
    } else {
        const [start, end] = dates;
        const newStart = new Date(start);
        const newEnd = new Date(end);

        return [newStart, newEnd];
    }
};

/**
 * @deprecated Use `date-fns` for localized formatting instead
 */
export const smallDateFormat = (date: Date) => {
    return `${date.getMonth() + 1}/${date.getDay()}/${date.getFullYear()}`;
};
