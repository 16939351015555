/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */
import { DotNotationExpr, INumberExpr, IVariableExpr } from './DotNotation';
import { showExpr } from './showExpr';

/**
 * Resolves a dynamic property expression in an object.
 * @param expr the expression to determine the property
 * @param currentObject always the root object
 * @param env the environment containing variable values
 * @returns the actual property to access
 */
export function resolveDynamicProperty(
    expr: DotNotationExpr | INumberExpr | IVariableExpr,
    currentObject: any,
    env: any
): any {
    const property = (() => {
        if (expr.type === 'INumberExpr') {
            return expr.value;
        }

        if (expr.type === 'IVariableExpr') {
            return env[expr.name];
        }

        return getValue(expr, currentObject, env);
    })();

    if (typeof property !== 'string' && typeof property !== 'number') {
        throw new Error(`${showExpr(expr)} - resolved to an invalid property value: ${property}}`);
    }

    return property;
}

/**
 * Gets the current value of a nested property in an object.
 * @param expr the expression to access the nested property.
 * @param currentObject the object to access the nested property in.
 * @param env the environment containing variable values
 * @returns the value of the property.
 */
export function getValue(expr: DotNotationExpr, currentObject: any, env: any): any {
    switch (expr.type) {
        case 'IDynamicPropertyAccessExpr': {
            const property = resolveDynamicProperty(expr.property, currentObject, env);
            const object = getValue(expr.object, currentObject, env);
            return object?.[property];
        }
        case 'IPropertyAccessExpr': {
            const object = getValue(expr.object, currentObject, env);
            return object?.[expr.property];
        }
        case 'IPropertyNameExpr':
            return currentObject?.[expr.property];
    }
}
