import { IField, SimpleFieldRendererViewModel } from '../FieldRenderer';
import { ISearchConfiguration } from './types';

export function getSearchFormFieldColumns<T extends object>(
    searchConfiguration: ISearchConfiguration<T>,
    searchQuery: SimpleFieldRendererViewModel<T>
): IField[][] | undefined {
    if (typeof searchConfiguration?.searchFormFieldColumns === 'function') {
        return searchConfiguration.searchFormFieldColumns(searchQuery);
    }

    return searchConfiguration?.searchFormFieldColumns;
}

export function getGridColumns<T extends object>(
    searchConfiguration: ISearchConfiguration<T>,
    searchQuery: SimpleFieldRendererViewModel<T>
) {
    if (typeof searchConfiguration.gridColumns === 'function') {
        return searchConfiguration.gridColumns(searchQuery);
    }

    return searchConfiguration.gridColumns;
}

export const getTablePageSizeKey = (key: string) => {
    return `table_size_${key}`;
};
