import { clearAlerts } from '../actions/alerts';
import * as simDocumentTypesActions from '../actions/simDocumentTypesActions';
import projectConfig from '../project.config.json';
import { apiErrorAction } from '../actions/error';
import { DocumentTypeThunk, CompanyThunk } from 'common/build/legacy/transcepta-thunks';
import { push } from 'connected-react-router';
import R from '../routes';
import { portalUserService } from 'common';

export const fetchSIMDocumentTypeList = (skip, sort) => async (dispatch, getState) => {
    dispatch(clearAlerts());
    dispatch(simDocumentTypesActions.fetchingSIMDocumentTypes());

    const { simDocumentTypes } = getState();
    const companyID = portalUserService.getCurrentCompanyId();
    const { all } = simDocumentTypes;
    sort = sort ? sort : `${all.sorting.column} ${all.sorting.direction}`;

    const params = {
        companyId: companyID,
        $skip: skip ? skip : null,
        $orderby: sort,
    };
    const documentTypeThunk = new DocumentTypeThunk();
    const response = await documentTypeThunk.fetchSIMDocumentTypeList(params);

    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, simDocumentTypesActions.FETCHING_SIM_DOCUMENT_TYPES, params));
        return;
    }

    const { data } = response;
    dispatch(simDocumentTypesActions.fetchedSIMDocumentTypes(data));
};

export const fetchSuppliers = (searchVal, skip) => async (dispatch, getState) => {
    dispatch(clearAlerts());
    const companyId = portalUserService.getCurrentCompanyId();
    const params = {
        companyId,
        isBuyer: true,
        $count: !skip || skip === 0 ? true : null,
        $top: projectConfig.docPageSize.toString(),
        $skip: skip,
        includeAll: true,
        $select: `Id, SupplierCompanyId, VendorClass, DefaultVendorNumber,CustomerVendorNumber`,
        $expand: `SupplierCompany($select=ID, Name, Address1, Address2, Address3, City, State, PostalCode, State, Country, Phone, Fax, Email)`,
    };

    dispatch(simDocumentTypesActions.fetchingSuppliers());
    params.search = searchVal;

    const companyThunk = new CompanyThunk();
    const response = await companyThunk.fetchTradingPartners(params);

    if (response.type && response.type === 'error') {
        dispatch(
            apiErrorAction(response.text, simDocumentTypesActions.SIM_DOCUMENT_TYPE_SUPPLIER_SEARCH_FETCHING, params)
        );
        return;
    }

    let data = await response.data;
    dispatch(simDocumentTypesActions.fetchedSuppliers(data.Items));
};

export const fetchVendorClasses = (skip) => async (dispatch, getState) => {
    dispatch(clearAlerts());

    const params = {
        companyId: portalUserService.getCurrentCompanyId(),
        isBuyer: true,
        $count: !skip || skip === 0 ? true : null,
        $top: projectConfig.docPageSize.toString(),
        $skip: skip,
        includeAll: true,
    };

    dispatch(simDocumentTypesActions.fetchingVendorClasses());

    const companyThunk = new CompanyThunk();
    const response = await companyThunk.fetchVendorClasses(params);

    if (response.type && response.type === 'error') {
        dispatch(
            apiErrorAction(response.text, simDocumentTypesActions.SIM_DOCUMENT_TYPE_VENDOR_CLASSES_FETCHING, params)
        );
        return;
    }

    dispatch(simDocumentTypesActions.fetchedVendorClasses(response.data));
};

export const fetchSIMDocumentType = (id) => async (dispatch, getState) => {
    dispatch(clearAlerts());

    const params = {
        companyId: portalUserService.getCurrentCompanyId(),
        id,
    };

    dispatch(simDocumentTypesActions.fetchingSIMDocumentType());
    const documentTypeThunk = new DocumentTypeThunk();
    const response = await documentTypeThunk.fetchSIMDocumentType(params);

    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, simDocumentTypesActions.SIM_DOCUMENT_TYPE_FETCHING, params));
        return;
    }

    dispatch(simDocumentTypesActions.simDocumentTypeFetched(response.data[0]));
};

export const saveSIMDocumentType = (id) => async (dispatch, getState) => {
    dispatch(clearAlerts());

    const {
        simDocumentTypes: { simDocumentType },
    } = getState();

    const companyID = portalUserService.getCurrentCompanyId();

    const documentTypeThunk = new DocumentTypeThunk();
    const documentType = { ...simDocumentType, BuyerCompanyId: companyID };
    const response = id
        ? await documentTypeThunk.updateSIMDocumentType(documentType)
        : await documentTypeThunk.addSIMDocumentType(documentType);

    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, simDocumentTypesActions.SIM_DOCUMENT_TYPE_SAVING, {}));
        return;
    }

    dispatch(push(R.SIM_DOCUMENT_SETTINGS.path));
};

export const fetchSupplierSIMRequests = (skip) => async (dispatch, getState) => {
    dispatch(clearAlerts());
    dispatch(simDocumentTypesActions.fetchingSIMRequest());
    const companyID = portalUserService.getCurrentCompanyId();

    const params = {
        id: companyID,
        isBuyer: false,
        $filter: 'DateUploaded eq null',
    };

    const documentTypeThunk = new DocumentTypeThunk();
    const response = await documentTypeThunk.fetchSupplierSIMRequests(params);

    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, simDocumentTypesActions.SIM_REQUEST_FETCHING, params));
        return;
    }

    let simRequests = [];
    const portalTextMap = new Map();

    async function getPortalText(SIMDocumentTypeId, LastReminderDays) {
        const DaysInAdvance = LastReminderDays || 0;

        const key = [SIMDocumentTypeId, DaysInAdvance].join(',');
        const portalText = portalTextMap.get(key);

        if (portalText?.fetched) {
            return portalText.data;
        }

        const { data } = await documentTypeThunk.fetchSIMDocumentType({
            id: SIMDocumentTypeId,
            isBuyer: true,
            $select: 'Id, SIMDocumentTypeReminders',
            $expand: `SIMDocumentTypeReminders($filter=DaysInAdvance ge ${DaysInAdvance};$top=1;$orderBy=Id desc; $select=PortalText)`,
        });

        if (!data) {
            return null;
        }

        const { SIMDocumentTypeReminders } = data[0];

        if (SIMDocumentTypeReminders.length <= 0) {
            return null;
        }

        const result = SIMDocumentTypeReminders[0].PortalText;

        portalTextMap.set(key, { fetched: true, data: result });

        return result;
    }

    for (const SIMRequest of response.data) {
        const { SIMDocumentTypeId, LastReminderDays } = SIMRequest;

        const portalText = await getPortalText(SIMDocumentTypeId, LastReminderDays);

        if (portalText !== null) {
            simRequests = [...simRequests, { ...SIMRequest, PortalText: portalText }];
        }
    }
    dispatch(simDocumentTypesActions.SIMRequestFetched(simRequests));
};

export const fetchSIMDocumentRequests = (id) => async (dispatch, getState) => {
    dispatch(clearAlerts());
    const documentTypeThunk = new DocumentTypeThunk();
    const params = {
        id: id,
        isBuyer: false,
        $filter: 'SIMDocumentType/DocumentClass ne 3 and SIMDocumentType/DocumentClass ne 4',
    };
    dispatch(simDocumentTypesActions.fetchingSIMRequest);
    const response = await documentTypeThunk.fetchSupplierSIMRequests(params);
    if (response.type === 'error') {
        dispatch(simDocumentTypesActions.fetchingSIMRequestFailed);
        apiErrorAction(dispatch, response, {}, simDocumentTypesActions.SIM_DOCUMENT_TYPE_REQUESTS_FETCHING_FAILED);
        return;
    }

    dispatch(simDocumentTypesActions.SIMRequestFetched(response.data));
};

export const fetchSupplierSIMRequest = (id) => async (dispatch, getState) => {
    dispatch(clearAlerts());
    dispatch(simDocumentTypesActions.fetchingSIMRequest());

    const params = {
        id: id,
    };

    const documentTypeThunk = new DocumentTypeThunk();
    const response = await documentTypeThunk.fetchSupplierSIMRequest(params);
    if (response.type === 'error') {
        dispatch(simDocumentTypesActions.fetchingSIMRequestFailed);
        apiErrorAction(dispatch, response, {}, simDocumentTypesActions.SIM_DOCUMENT_TYPE_REQUESTS_FETCHING_FAILED);
        return;
    }

    dispatch(simDocumentTypesActions.SIMRequestFetched(response.data));
};
