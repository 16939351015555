import { FC, useEffect } from 'react';
import { useHistory, matchPath } from 'react-router-dom';
import { setPreservedRoute } from './preservedRoute';
import { IRoutes } from '../../types';

interface IProps {
    routes: IRoutes;
}

const checkIfPathnamePreservedRoute = (routes: IRoutes, pathname: string) => {
    let preserve = false;

    Object.keys(routes).forEach((key) => {
        // matchPath is the match function from react-router that determines if a route
        // is rendered within the switch statement, using it here will guarantee if a route
        // is a match even if it has a param in the route
        if (
            matchPath(pathname, {
                path: routes[key].path,
                exact: true,
                strict: false,
            })?.isExact &&
            routes[key].preserveRouteOnVisit
        ) {
            preserve = true;
        }
    });

    return preserve;
};

export const SubRoutePreservationProvider: FC<IProps> = ({ routes, children }) => {
    const history = useHistory();

    useEffect(() => {
        history.listen(() => {
            if (checkIfPathnamePreservedRoute(routes, window.location.pathname)) {
                setPreservedRoute(window.location.pathname);
            }
        });
    }, [history, routes]);

    return <>{children}</>;
};
