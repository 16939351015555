import { IFormat } from '../../transcepta-types';

export const formatAmount = (value: string, format: IFormat, delimiter: string = ',') => {
    const regex = new RegExp(`(\\d)(?=(\\d{${format.Split}})+(?!\\d))`, 'g');
    return !value
        ? '0.00'
        : `${String(value).split('.')[0].replace(regex, `$1${delimiter}`)}.${
              (parseFloat(value) || 0).toFixed(format.Round).split('.')[1]
          }`;
};
