import {
    ISearchConfiguration,
    ISearchRequest,
    ISearchResponse,
    ISearchService,
    createUseSearchService,
    createTextField,
} from '../../../reusableFeatures';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import { IUserGroupGrid, IUserGroupSearchItems, orderBy, portalUserService, UserGroupService } from '../../../services';
import { TOOLTIP_CELL_MODE } from '../../../ui';
import { paginate } from '../../../utils';
import { DeleteUserGroupDialog } from './DeleteUserGroupDialog';

export class UserGroupSearchService implements ISearchService<IUserGroupSearchItems> {
    private userGroupService = new UserGroupService();

    async fetchSearchConfiguration(): Promise<ISearchConfiguration<IUserGroupSearchItems>> {
        const gridColumns: GridColumns = [
            { field: 'name', headerName: 'Name', width: 150, sortable: true },
            { field: 'description', headerName: 'Description', width: 200, sortable: true },
            { field: 'parentUserGroupName', headerName: 'Parent User Group', width: 200, sortable: false },
            {
                field: 'actions',
                headerName: 'Actions',
                flex: 1,
                renderCell: (params: GridRenderCellParams) =>
                    params.cellMode === TOOLTIP_CELL_MODE ? null : <DeleteUserGroupDialog id={+params.id} />,
                sortable: false,
            },
        ];

        const searchFormFieldColumns = [
            [
                createTextField({
                    id: 'name',
                    label: 'User Group Name',
                    defaultValue: '',
                    dataBindingKey: 'name',
                }),
                createTextField({
                    label: 'Description',
                    defaultValue: '',
                    dataBindingKey: 'description',
                }),
                createTextField({
                    label: 'Parent User Group',
                    defaultValue: '',
                    dataBindingKey: 'parentUserGroupName',
                }),
            ],
        ];

        return {
            gridColumns,
            enabledExportingMethods: [],
            searchFormFieldColumns,
            pageSizeOptions: [10, 25, 50],
            defaultPageSize: 10,
            searchWithNoCriteria: true,
        };
    }

    private filterUserGroupGrid = (
        grid: IUserGroupGrid[],
        name: string | null,
        description: string | null,
        parentUserGroupName: string | null
    ) => {
        let filteredGrid = [...grid];

        if (name) {
            filteredGrid = filteredGrid.filter((item) => item.name.toLowerCase().includes(name.toLowerCase()));
        }

        if (description) {
            filteredGrid = filteredGrid.filter((item) =>
                item.description.toLowerCase().includes(description.toLowerCase())
            );
        }

        if (parentUserGroupName) {
            filteredGrid = filteredGrid.filter((item) =>
                item.parentUserGroupName?.toLowerCase().includes(parentUserGroupName.toLowerCase())
            );
        }

        return filteredGrid;
    };

    async fetchResults(
        _searchConfiguration: ISearchConfiguration<IUserGroupSearchItems>,
        searchRequest: ISearchRequest<IUserGroupSearchItems>
    ): Promise<ISearchResponse> {
        const companyID = portalUserService.getCurrentCompanyId();
        const response = await this.userGroupService.getUserGroupsGrid(companyID, orderBy(searchRequest.sort));
        if (!response) {
            return {
                pageResults: [],
                totalResultCount: 0,
            };
        }

        const filtered = this.filterUserGroupGrid(
            response,
            searchRequest.searchQuery.state.name,
            searchRequest.searchQuery.state.description,
            searchRequest.searchQuery.state.parentUserGroupName
        );

        return {
            pageResults: paginate(filtered, searchRequest.pageSize, searchRequest.pageNumber),
            totalResultCount: filtered.length,
        };
    }

    exportData(): Promise<void> {
        throw new Error('Exporting not implemented for user group search');
    }

    get key(): string {
        return 'UserGroupSearch';
    }
}

export const useUserGroupSearchService = createUseSearchService(UserGroupSearchService);
