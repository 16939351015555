import { RoleType } from '../../../UserService/Api';
import {
    LogicType,
    WorkflowActivityTimeoutUnit,
    WorkflowActivityType,
    WorkflowReassignmentRule,
    WorkflowResult,
    WorkflowApprovalType,
} from '../../Api/ViewModels';
import { IWorkflowActivityExitFlow } from './WorkflowActivityExitFlow';

export interface IWorkflowActivity {
    id?: number;
    workflowId?: number;
    name: string;
    step: string;
    activityType: WorkflowActivityType | null;
    activityType_US?: string;
    service: string;
    setupLogicType: LogicType | null; // Supported values: Store Procedure and Store Procedure Instance Params
    setupLogicNewFile?: File | null;
    setupLogicJavaScriptFilePath: string | null;
    setupLogicType_US?: string;
    setupLogic: string;
    activityLogicType: LogicType | null; // Supported values: Store Procedure and Store Procedure Instance Params
    activityLogicNewFile?: File | null;
    activityLogicJavaScriptFilePath: string | null;
    activityLogicType_US?: string;
    activityLogic: string;
    endEventResult: WorkflowResult | null;
    endEventResult_US?: string;
    reassignmentRule: WorkflowReassignmentRule | null;
    reassignmentRule_US?: string;
    timeoutCount: string;
    timeoutUnits: WorkflowActivityTimeoutUnit | null;
    timeoutUnits_US?: string;
    roleTypeId: RoleType | null;
    workflowFieldDictionaryTemplateId: string;
    reprocess: IWorkflowActivityReprocess | null; // parsed from XML Data Structure
    customData: IWorkflowActivityCustomData | null; // parsed from XML Data Structure - WorkflowActivityConfiguration
    glCodingEditability: boolean;
    documentEditActive: boolean;
    documentLineAddDeleteActive: boolean;
    approvalType: WorkflowApprovalType | null;
    approvalType_US?: string;
    workflowAdminUserGroupName?: string;
    defaultApproverUserEmail?: string;
    defaultApproverUserId?: number;
    defaultApproverUserGroupId?: number;
    workflowActivityExitFlows: IWorkflowActivityExitFlow[];
}

export interface IWorkflowActivityReprocess {
    reprocessType: WorkflowActivityReprocess | null;
    taskResult: string | null;
    taskCustomExplanation: string | null;
}

export enum WorkflowActivityReprocess {
    Restart = 1,
    Complete = 2,
}

export interface IWorkflowActivityCustomData {
    glCoding: IWorkflowActivityCustomDataGLCoding | null;
    documentEditability: IWorkflowActivityCustomDataDocumentEditability | null;
    buttons: IWorkflowActivityCustomDataButtons | null;
    enableVBValidations?: boolean;
}

export interface IWorkflowActivityCustomDataGLCoding {
    glCodingMode?: WorkflowActivityGLCodingMode | null;
    addsUp?: boolean;
    oneSegmentFilled?: boolean;
}

export interface IWorkflowActivityCustomDataDocumentEditability {
    documentEditabilityEnabled?: boolean;
    lineAddDeleteEnabled?: boolean; // available if documentEditability is enabled
}

export interface IWorkflowActivityCustomDataButtons {
    save: {
        enabled: boolean;
        label: string;
    };
    approve: {
        enabled: boolean;
        label: string;
    };
    reject: {
        enabled: boolean;
        label: string;
    };
}

export enum WorkflowActivityGLCodingMode {
    AlwaysVisible = 0,
    NeverVisible = 1,
    ReadOnly = 2,
    PODependent = 3,
    CustomLogic = 4,
}
