import { FC, useMemo } from 'react';
import _ from 'lodash';
import Select, { Async, Creatable } from 'react-select-virtualized';
import classNames from 'classnames';
import { IAccountValue, IValue } from '../../types';
import './SelectVirtualized.scss';

export interface IInputOption {
    value: string;
    label: string;
    isNewOption?: boolean;
}

export interface IProps {
    type?: 'basic' | 'async' | 'creatable';
    value: IValue | IAccountValue | null;
    editMode?: boolean;
    className?: string;
    minimumInputSearch?: number;
    isClearable?: boolean;
    data?: any[];
    cacheOptions?: boolean;
    placeholder?: string;
    onChange: (key: string, value: string) => void;
    testId?: string;
    onCreateOption?: (inputValue: IInputOption) => void;
}

const SelectVirtualized: FC<IProps> = ({
    type = 'basic',
    value,
    className,
    minimumInputSearch = 3,
    cacheOptions = true,
    isClearable = false,
    data: incomingData,
    placeholder,
    onChange,
    testId,
    editMode,
    onCreateOption,
}) => {
    // add selected value to the list of options to ensure that it is displayed, even if the options get
    // updated to a list that doesn't include the value
    // Use case: Update GL# to a GL item which has depth 2 elements, select a depth 2 element (Sub dropdown), select a different GL item:
    // The depth 2 element is persisted because of this caching mechanism (useMemo), however the user needs a way to clear it.
    // The best way to support this is by adding an empty field in the data that the user can always select
    const data = useMemo(() => {
        if (value && value.value && incomingData?.findIndex((x) => x.value === value.value) === -1) {
            return [
                { value: '', label: '', depth: incomingData[0]?.depth },
                { value: value.value, label: value.label || value.value, depth: incomingData[0]?.depth },
                ...incomingData,
            ];
        }
        if (incomingData) {
            return [{ value: '', label: '', depth: incomingData[0]?.depth }, ...incomingData];
        }
        return incomingData;
    }, [incomingData, value]);

    const debouncedLoadOptions = useMemo(() => {
        const loadOptions = (input: string, callback: any) => {
            callback(data?.filter(({ label }) => label.toLowerCase().includes(input.toLowerCase())));
        };
        return _.debounce(loadOptions, 200);
    }, [data]);

    const determineValue = (newValue: string) => {
        return data?.filter((d: IValue) => d.value === newValue)[0] || null;
    };

    const coreClasses = classNames('core-select', className, testId);

    if (type === 'creatable') {
        return (
            <Creatable
                options={data || []}
                className={coreClasses}
                classNamePrefix="Select"
                onChange={onChange}
                value={value?.value ? determineValue(value.value) : null}
                isClearable={isClearable}
                optionHeight={50}
                placeholder={placeholder}
                isDisabled={!editMode}
                onCreateOption={onCreateOption}
            />
        );
    }

    if (type === 'async') {
        return (
            <Async
                cacheOptions={cacheOptions}
                options={data}
                loadOptions={debouncedLoadOptions}
                minimumInputSearch={minimumInputSearch}
                value={value?.value ? determineValue(value.value) : null}
                className={coreClasses}
                classNamePrefix="Select"
                isClearable={isClearable}
                onChange={onChange}
                optionHeight={50}
                placeholder={placeholder}
                isDisabled={!editMode}
            />
        );
    }

    return (
        <Select
            options={data || []}
            className={coreClasses}
            classNamePrefix="Select"
            onChange={onChange}
            value={value?.value ? determineValue(value.value) : null}
            isClearable={isClearable}
            optionHeight={50}
            placeholder={placeholder}
            isDisabled={!editMode}
        />
    );
};
export default SelectVirtualized;
