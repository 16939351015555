// we must import directly from 'common/build/config' to ensure that we set the portal default config
// before anything else tries to read the config.
import { setPortalDefaultConfig } from 'common/build/config';

setPortalDefaultConfig({
    apiCacheEndpointConfig: {
        endpoints: [
            [
                '/Document/SearchConfiguration',
                {
                    optIn: true,
                },
            ],
            [
                '/Document/Layout',
                {
                    optIn: true,
                    invalidationKey: '/Document/Layout',
                    invalidationConditions: [
                        (pathname, request) =>
                            pathname === '/Document/Layout' && request.method?.toLowerCase() === 'post',
                    ],
                },
            ],
            [
                '/common/currency',
                {
                    optIn: true,
                },
            ],
            [
                '/Company/TradingPartners',
                {
                    optIn: true,
                },
            ],
        ],
    },
    featureFlags: {},
});
