import { IDocumentViewModel } from '../../services/Document/Api';
import { resetDocFieldsHaveChangedAtLeastOnce } from './hasAnyFieldInvolvedInCalculationChanged';

let originalDocument: IDocumentViewModel | null = null;

export function resetOriginalDocument() {
    originalDocument = null;
    resetDocFieldsHaveChangedAtLeastOnce();
}

export function getOriginalDocument() {
    if (!originalDocument) {
        throw new Error('Call to getOriginalDocument not allowed before call to setOriginalDocumentIfNeeded');
    }

    return originalDocument;
}

export function setOriginalDocumentIfNeeded(newDocument: IDocumentViewModel) {
    if (originalDocument === null || originalDocument.Id !== newDocument.Id) {
        originalDocument = newDocument;
    }
}
