/**
 * Get the root element name of a JS object that represents an XML document.
 * @param data the JS object containing a single root element.
 * @returns the root element.
 */
export function findRootElementName(data: object) {
    return Object.keys(data)[0];
}

/**
 * Get the root element of a JS object that represents an XML document.
 * @param data the JS object containing a single root element
 * @returns the root element
 */
export function getRootElementOf(data: object) {
    const rootKey = findRootElementName(data);

    return (data as any)[rootKey];
}
