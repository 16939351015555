const MULTI_COMPANY_SELECT_FIELD_KEY = 'MultiCompanySelect';

export const multiCompanySelectDefaultFieldConfig = {
    id: MULTI_COMPANY_SELECT_FIELD_KEY,
    label: 'Select Company',
    multiple: true,
    dataBindingKey: MULTI_COMPANY_SELECT_FIELD_KEY,
    defaultValue: [],
    options: new Array<{ label: string; value: string }>(),
    description: 'Select',
    visibility: 'AlwaysInvisible',
} as const;
