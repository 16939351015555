import f, { ODataFilterBuilder } from 'odata-filter-builder';

import { ODataUtils, escape } from '../../../../utils';
import { BusinessDocType } from '../../../../types';

export function createSearchForRelatedDocumentFilter({
    relatedDocumentNumber,
    relatedDocumentType,
    documentNumberFieldName,
    vendorNumber,
}: {
    relatedDocumentType: BusinessDocType;
    relatedDocumentNumber: string;
    documentNumberFieldName: string;
    vendorNumber: string | null;
}) {
    const filterRules: ODataFilterBuilder[] = [];

    filterRules.push(f().eq('BusinessDocType', relatedDocumentType));
    filterRules.push(f().eq(documentNumberFieldName, escape(relatedDocumentNumber)));

    if (vendorNumber !== null) {
        filterRules.push(f().eq('VendorNumber', escape(vendorNumber)));
    }

    return filterRules.length ? ODataUtils.andConditions(filterRules).toString() : undefined;
}
