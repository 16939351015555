import { Check, Close } from '@mui/icons-material';
import { GridColumns } from '@mui/x-data-grid';
import { ReactNode } from 'react';
import { LocalizedStrings } from 'react-localization';
import {
    createTextField,
    createUseSearchService,
    ISearchConfiguration,
    ISearchRequest,
    ISearchResponse,
    ISearchService,
} from '../../../reusableFeatures';
import { IUserGridItem, IUserSearchItems, orderBy, portalUserService, RoleType, UserService } from '../../../services';
import { TOOLTIP_CELL_MODE } from '../../../ui';

export type UsersSearchServiceLocalization = LocalizedStrings<{
    textCompanyAdministrator: string;
    textSupplierAdministration: string;
    textInvoiceEdit: string;
    textSupplierLogin: string;
    textBuyerLoginRights: string;
    textPaymentCreation: string;
    textPaymentApproval: string;
    textEditOnly: string;
    textAdministration: string;
    textViewOnly: string;
    textParkingLotAdministration: string;
    textParkingLotOnly: string;
    textSuperUser: string;
}>;

const roleText = (id: RoleType, localization: UsersSearchServiceLocalization): ReactNode => {
    switch (id) {
        case RoleType.CompanyAdminRights:
            return <Check color="success" />;
        case RoleType.BuyerAdminRights:
            return localization.textCompanyAdministrator;
        case RoleType.SupplierAdminRights:
            return localization.textSupplierAdministration;
        case RoleType.InvoiceEditRights:
            return localization.textInvoiceEdit;
        case RoleType.SupplierLoginRights:
            return localization.textSupplierLogin;
        case RoleType.BuyerLoginRights:
            return localization.textBuyerLoginRights;
        case RoleType.PaymentCreationRights:
            return localization.textPaymentCreation;
        case RoleType.PaymentApprovalRights:
            return localization.textPaymentApproval;
        case RoleType.DynamicDiscountingEditRights:
            return localization.textEditOnly;
        case RoleType.DynamicDiscountingAdminRights:
            return localization.textAdministration;
        case RoleType.DynamicDiscountingViewRights:
            return localization.textViewOnly;
        case RoleType.ParkingLotAdminRights:
            return localization.textParkingLotAdministration;
        case RoleType.ParkingLotOnly:
            return localization.textParkingLotOnly;
        case RoleType.SuperUserRights:
            return localization.textSuperUser;
        default:
            return null;
    }
};

const renderDynamicDiscountingDRole = (
    userRoles: IUserGridItem['roles'],
    localization: UsersSearchServiceLocalization
) => {
    if (userRoles.some((role) => role.id === RoleType.DynamicDiscountingAdminRights)) {
        return roleText(RoleType.DynamicDiscountingAdminRights, localization);
    }
    if (userRoles.some((role) => role.id === RoleType.DynamicDiscountingEditRights)) {
        return roleText(RoleType.DynamicDiscountingEditRights, localization);
    }
    if (userRoles.some((role) => role.id === RoleType.DynamicDiscountingViewRights)) {
        return roleText(RoleType.DynamicDiscountingViewRights, localization);
    }
    return null;
};

const renderCheck = (roles: IUserGridItem['roles'], roleType: RoleType) => {
    const value = roles?.some((role) => role.id === roleType);
    return typeof value === 'boolean' ? value ? <Check color="success" /> : <Close /> : null;
};

export class UsersSearchService implements ISearchService<IUserSearchItems> {
    private service = new UserService();

    private portal: 'buyer' | 'supplier';

    private localization: UsersSearchServiceLocalization;

    constructor(portal: 'buyer' | 'supplier', localization: UsersSearchServiceLocalization) {
        this.portal = portal;
        this.localization = localization;
    }

    async fetchSearchConfiguration(): Promise<ISearchConfiguration<IUserSearchItems>> {
        const buyerGridColumns: GridColumns<IUserGridItem> = [
            {
                field: 'signIn',
                headerName: 'Sign In',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({ row, cellMode }) =>
                    cellMode === TOOLTIP_CELL_MODE ? null : renderCheck(row.roles, RoleType.BuyerLoginRights),
            },
            {
                field: 'dynamicDiscounting',
                headerName: 'Dynamic Discounting',
                sortable: false,
                renderCell: ({ row }) => <div>{renderDynamicDiscountingDRole(row.roles || [], this.localization)}</div>,
            },
            {
                field: 'lastSignIn',
                headerName: 'Last Sign-in',
                sortable: false,
                width: 150,
            },
            {
                field: 'substituteUserName',
                headerName: 'Substitute User',
                sortable: false,
                width: 200,
            },
        ];
        const supplierGridColumns: GridColumns<IUserGridItem> = [
            {
                field: 'signIn',
                headerName: 'Sign In',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({ row, cellMode }) =>
                    cellMode === TOOLTIP_CELL_MODE ? null : renderCheck(row.roles, RoleType.SupplierLoginRights),
            },
            {
                field: 'edit',
                headerName: 'Edit',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({ row, cellMode }) =>
                    cellMode === TOOLTIP_CELL_MODE ? null : renderCheck(row.roles, RoleType.InvoiceEditRights),
            },
            {
                field: 'profileAdministration',
                headerName: 'Profile Admin',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({ row, cellMode }) =>
                    cellMode === TOOLTIP_CELL_MODE ? null : renderCheck(row.roles, RoleType.SupplierAdminRights),
            },
            {
                field: 'lastSignIn',
                headerName: 'Last Sign-in',
                sortable: false,
                width: 150,
            },
        ];
        const gridColumns: GridColumns<IUserGridItem> = [
            {
                field: 'name',
                headerName: 'Name',
                sortable: true,
                width: 200,
            },
            {
                field: 'email',
                headerName: 'Email Address',
                sortable: true,
                valueGetter: (props: any) => props.row?.emailAddress,
                width: 200,
            },
            {
                field: 'adminAccount',
                headerName: 'Account Admin',
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({ value, cellMode }) => {
                    if (cellMode === TOOLTIP_CELL_MODE) {
                        return null;
                    }
                    if (typeof value === 'boolean') {
                        return value ? <Check color="success" /> : <Close />;
                    }
                    return null;
                },
            },
        ];
        if (this.portal === 'buyer') {
            gridColumns.push(...buyerGridColumns);
        }
        if (this.portal === 'supplier') {
            gridColumns.push(...supplierGridColumns);
        }

        const searchFormFieldColumns = [
            [
                createTextField({
                    id: 'name',
                    label: 'Name, Email',
                    defaultValue: '',
                    dataBindingKey: 'name',
                }),
            ],
        ];

        return {
            gridColumns,
            enabledExportingMethods: [],
            searchFormFieldColumns,
            pageSizeOptions: [10, 25, 50],
            defaultPageSize: 10,
            searchWithNoCriteria: true,
        };
    }

    async fetchResults(
        _searchConfiguration: ISearchConfiguration<IUserSearchItems>,
        searchRequest: ISearchRequest<IUserSearchItems>
    ): Promise<ISearchResponse> {
        const companyID = portalUserService.getCurrentCompanyId();
        const { pageNumber, pageSize, sort, searchQuery } = searchRequest;
        const response = await this.service.getUsersSearchGrid(companyID, true, pageSize, pageNumber, orderBy(sort), {
            name: searchQuery.state.name,
            email: searchQuery.state.name,
        });
        return {
            pageResults: response.items,
            totalResultCount: response.count,
        };
    }

    exportData(): Promise<void> {
        throw new Error('Exporting not implemented for user group search');
    }

    get key(): string {
        return 'UserSearch';
    }
}

export const useUsersSearchService = createUseSearchService(UsersSearchService);
