// DEPRECATED: Do not use
// REFACTOR: Move under Molecules/InputField since that is the only place this is used
import { useState, useEffect, useMemo } from 'react';
import { NumericUtil } from '../../../transcepta-common';

export const useKeyboard = ({
    fieldName,
    stringValue,
    controlType,
    currencyCode,
    currencySymbol,
    onChange,
    onKeyDown,
    onKeyPress,
    onEnterPress,
    onKeyUp,
    onFocus,
    onBlur,
}) => {
    const [value, setValue] = useState(stringValue);
    const [isFocused, setIsFocus] = useState(false);

    const maxDigits = useMemo(() => {
        if (NumericUtil.determineNeedsFormatting(controlType)) {
            return NumericUtil.DetermineMaxDigits(controlType);
        }
        return undefined;
    }, [controlType]);

    const maxDecimalPlaces = useMemo(() => {
        if (NumericUtil.determineNeedsFormatting(controlType)) {
            return NumericUtil.DetermineMaxDecimalPlaces(controlType, null, currencyCode);
        }
        return undefined;
    }, [controlType, currencyCode]);

    const minDecimalPlaces = useMemo(() => {
        if (NumericUtil.determineNeedsFormatting(controlType)) {
            return NumericUtil.DetermineMinDecimalPalces(controlType, null, maxDecimalPlaces, currencyCode);
        }
        return undefined;
    }, [controlType, maxDecimalPlaces, currencyCode]);

    useEffect(() => {
        const cleanValue = NumericUtil.determineNeedsFormatting(controlType)
            ? NumericUtil.CleanNumericValue(stringValue, maxDigits ?? 0, maxDecimalPlaces, minDecimalPlaces)
            : stringValue;
        const displayValue = NumericUtil.determineNeedsFormatting(controlType)
            ? NumericUtil.GenerateDisplayValue(
                  cleanValue,
                  maxDigits ?? 0,
                  maxDecimalPlaces,
                  minDecimalPlaces,
                  controlType,
                  currencySymbol
              )
            : cleanValue;

        setValue(displayValue);
        // This is working fine despite not using exhaustive deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stringValue]);

    const keyBoardBindings = {
        value,
        onChange: (e) => {
            e.persist();
            const { value: newValue } = e.target;
            setValue(newValue);
            onChange(e, { value: newValue });
        },
        onKeyDown: (e) => onKeyDown(e, { value }),
        onKeyUp: (e) => onKeyUp(e, { value }),
        onKeyPress: (e) => {
            const keyCode = e.keyCode || e.which;
            if (keyCode === 13 && onKeyPress) {
                onEnterPress(e, { value });
            } else {
                onKeyPress(e, { value });
            }
        },
        onFocus: (e) => {
            e.persist();
            setIsFocus(true);
            const { value: newValue } = e.target;

            setValue(newValue);

            onFocus(e, { value: newValue });
        },
        onBlur: (e) => {
            e.persist();
            setIsFocus(false);
            const { value: newValue } = e.target;

            const cleanValue = NumericUtil.determineNeedsFormatting(controlType)
                ? NumericUtil.CleanNumericValue(newValue, maxDigits ?? 0, maxDecimalPlaces, minDecimalPlaces)
                : newValue;
            const displayValue = NumericUtil.determineNeedsFormatting(controlType)
                ? NumericUtil.GenerateDisplayValue(
                      cleanValue,
                      maxDigits ?? 0,
                      maxDecimalPlaces,
                      minDecimalPlaces,
                      controlType,
                      currencySymbol
                  )
                : cleanValue;

            setValue(displayValue);

            onBlur(fieldName, cleanValue);
        },
    };

    return [isFocused, keyBoardBindings] as const;
};

export default useKeyboard;
