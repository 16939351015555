import { push } from 'connected-react-router';
import R from '../routes';

export const REPORTS_DEFINITIONS_FETCHING = 'REPORTS_DEFINITIONS_FETCHING';
export const REPORTS_DEFINITIONS_FETCHED = 'REPORTS_DEFINITIONS_FETCHED';
export const REPORTS_CONFIRMATION_FETCHING = 'REPORTS_CONFIRMATION_FETCHING';
export const REPORTS_CONFIRMATION_FETCHED = 'REPORTS_CONFIRMATION_FETCHED';
export const REPORTS_CONFIRMATION_UPDATING = 'REPORTS_CONFIRMATION_UPDATING';

export const reportDefinitionsFetching = () => {
    return (dispatch) => {
        dispatch({
            type: REPORTS_DEFINITIONS_FETCHING,
        });
    };
};

export const reportDefinitionsFetched = (payload) => {
    return (dispatch) => {
        dispatch({
            type: REPORTS_DEFINITIONS_FETCHED,
            payload,
        });
    };
};

export const reportConfirmationFetching = () => {
    return (dispatch) => {
        dispatch({
            type: REPORTS_CONFIRMATION_FETCHING,
        });
    };
};

export const reportConfirmationFetched = (payload) => {
    return (dispatch) => {
        dispatch({
            type: REPORTS_CONFIRMATION_FETCHED,
            payload,
        });
    };
};

export const redirectToSettings = () => {
    return (dispatch) => {
        dispatch(push(R.SETTINGS_ADMINISTRATION.path));
    };
};
