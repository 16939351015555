import { IUserGroupMembership, IUserRole } from '../../transcepta-types';
import WorkflowGroupUser from '../../transcepta-types/types/WorkflowGroupUser';

export const hasRole = (userRoles: IUserRole[], roleId: number) => {
    return userRoles && userRoles.some((role) => role.RoleID === roleId);
};

export const hasMembership = (userGroupMemberships: IUserGroupMembership[], userGroupId: number) => {
    return userGroupMemberships && userGroupMemberships.some((user) => user.UserGroupId === userGroupId);
};

export const hasWorkflowGroupUser = (workflowGroupUsers: WorkflowGroupUser[], workflowId: number) => {
    return workflowGroupUsers && workflowGroupUsers.some((group) => group.WorkflowId === workflowId);
};
