import { FCNC } from '../../../ui';
import { ControlFormatFields } from '../parseControlFormatFields';
import { useRelatedDocument } from './useRelatedDocument';
import { RelatedDocumentLinkRenderer } from '../RelatedDocumentLinkRenderer';
import { useDocumentRevisions } from '../ShowRevisionsButton/DocumentRevisionsPopup/useDocumentRevisions';
import { Typography } from '@mui/material';
import { getDocumentTypeName } from '../utils';

export interface StaticRelatedDocumentLinkProps {
    formatting: ControlFormatFields;

    value: string;

    isInEditMode: boolean;
}

export const StaticRelatedDocumentLink: FCNC<StaticRelatedDocumentLinkProps> = ({
    formatting,
    value,
    isInEditMode,
}) => {
    const relatedDocument = useRelatedDocument(formatting.relatedDocumentType);

    const { error } = useDocumentRevisions(relatedDocument?.relatedDocumentId ?? null);

    if (relatedDocument && !error) {
        return (
            <RelatedDocumentLinkRenderer
                relatedDocumentId={relatedDocument.relatedDocumentId}
                relatedDocumentNumber={value}
                relatedDocumentType={formatting.relatedDocumentType}
                currentDocumentType={relatedDocument.currentDocumentType}
                linkType={formatting.relatedDocumentLinkType}
            />
        );
    }

    if (value == null || !value.toString().trim().length) {
        return null;
    }

    if (isInEditMode) {
        return (
            <Typography sx={{ color: 'orange' }}>
                The {getDocumentTypeName(formatting.relatedDocumentType)} was not found in the Transcepta system.
            </Typography>
        );
    }

    return <>{value}</>;
};
