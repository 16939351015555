import { AxiosResponse } from 'axios';
import { api } from '../../../api';
import { CreateUpdateDeleteViewModel } from '../../ViewModels';
import { RecursivePartial } from '../../../../types';
import { HelpContentViewModel } from './HelpContentViewModel';

export class HelpApi {
    private route = '/HelpContent';

    public getHelpContent = (id: number): Promise<AxiosResponse<HelpContentViewModel>> => {
        return api().get(`${this.route}/${id}`);
    };

    public getAllHelpContent = (): Promise<AxiosResponse<HelpContentViewModel[]>> => {
        return api().get(`${this.route}`);
    };

    public postHelpContent = (
        helpContent: RecursivePartial<HelpContentViewModel>
    ): Promise<AxiosResponse<CreateUpdateDeleteViewModel>> => {
        return api().post(this.route, helpContent);
    };

    public putHelpContent = (
        id: number,
        helpContent: RecursivePartial<HelpContentViewModel>
    ): Promise<AxiosResponse<CreateUpdateDeleteViewModel>> => {
        return api().put(`${this.route}/${id}`, helpContent);
    };

    public deleteHelpContent = (id: number): Promise<AxiosResponse<CreateUpdateDeleteViewModel>> => {
        return api().delete(`${this.route}/${id}`);
    };
}
