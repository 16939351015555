import { relatedDocumentLinkTypes, RelatedDocumentLinkType } from './types';
import { BusinessDocType } from '../../../types';

export function parseFilterByVendorNumber(filterByVendorNumber: unknown) {
    // default to on
    if (filterByVendorNumber === undefined) {
        return true;
    }

    // require explicit set value to be a boolean
    if (['true', 'false'].includes(filterByVendorNumber as any)) {
        return filterByVendorNumber === 'true';
    }

    throw new Error(`Invalid "ControlFormat.filterByVendorNumber" value: ${filterByVendorNumber} - Expected boolean`);
}

export function parseIsRelatedDocumentField(isRelatedDocumentField: unknown) {
    // default to off
    if (isRelatedDocumentField === undefined) {
        return false;
    }

    // require explicit set value to be a boolean
    if (['true', 'false'].includes(isRelatedDocumentField as any)) {
        return isRelatedDocumentField === 'true';
    }
    throw new Error(
        `Invalid "ControlFormat.isRelatedDocumentField" value: ${isRelatedDocumentField} - Expected boolean`
    );
}

export function parseRelatedDocumentLinkType(relatedDocumentLinkType: unknown) {
    // default value
    if (relatedDocumentLinkType === undefined) {
        return 'GoToActive';
    }

    // require explicit value to belong to the union of string types we have pre-defined
    if (relatedDocumentLinkTypes.includes(relatedDocumentLinkType as any)) {
        return relatedDocumentLinkType as RelatedDocumentLinkType;
    }

    throw new Error(
        `Invalid "ControlFormat.relatedDocumentLinkType" value: ${relatedDocumentLinkType} - Expected one of ${relatedDocumentLinkTypes.join(
            ', '
        )}`
    );
}

const businessDocTypeNums = Object.values(BusinessDocType)
    .map((x) => parseInt(x as any, 10))
    .filter((x) => !Number.isNaN(x))
    .join(', ');

export function parseRelatedDocumentType(relatedDocumentType: unknown) {
    // default value
    if (relatedDocumentType === undefined) {
        return BusinessDocType.PurchaseOrder;
    }

    // require explicit set value to be a BusinessDocType
    const value = parseInt(relatedDocumentType as string, 10);
    if (value in BusinessDocType) {
        return value as BusinessDocType;
    }
    throw new Error(
        `Invalid "ControlFormat.relatedDocumentType" value: ${relatedDocumentType} - expected one of ${businessDocTypeNums} (BusinessDocType)`
    );
}
