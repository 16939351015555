export enum FieldDictionaryScreenSize {
    Large = 1,
    Medium = 2,
    Small = 3,
}

export interface DocumentSearchConfigurationViewModel {
    ScreenSize: FieldDictionaryScreenSize;
    ScreenSize_US: string;
    SearchConfiguration: SearchConfiguration;
}

export interface SearchConfiguration {
    SearchCriteria: SearchCriteria[];
    SearchResult: SearchResult;
    SearchFilter: SearchFilter;
}

type BaseSearchCriteria = {
    Row: number;
    Column: number;
    Label: string;
    FieldName: string;
};

type Input = BaseSearchCriteria & {
    Type: 'Input';
};

type NumericRange = BaseSearchCriteria & {
    Type: 'NumericRange';
    PlaceHolder1: string;
    PlaceHolder2: string;
};

type DateRange = BaseSearchCriteria & {
    Type: 'DateRange';
    PlaceHolder1: string;
    PlaceHolder2: string;
};

type Select = BaseSearchCriteria & {
    Type: 'Select';
    Multi: boolean;
    Options: {
        Value: number | string;
        Label: string;
    }[];
};

type Checkbox = BaseSearchCriteria & {
    Type: 'Checkbox';
};

export type SearchCriteria = Input | NumericRange | DateRange | Select | Checkbox;

export type SearchCriteriaType = SearchCriteria['Type'];

export type SearchFilter = {
    $filter: Filter;
    /**
     * ${fieldKey} are properties meant to be used as URL params
     *
     * Note:
     * {fieldKey} will never hold a {Filter} object,
     * this is a workaround to prevent ts from complaining
     */
    [fieldKey: string]: Filter | string;
};

export interface Filter {
    Prefix: string;
    ConcatenationString: string;
    Default: string;
    /**
     * This holds filter strings that may include placeholders for string interpolation
     */
    Fields: {
        Status: Record<number, string>;
        /**
         * All keys will have a value of {string}, except for Status.
         * A union is required for the same reason as {SearchFilter}
         */
        [key: string]: Record<number, string> | string;
    };
}

export interface SearchResult {
    /**
     * might include a placeholder for total count of entries
     */
    Label: string;
    Fields: SearchResultField[];
    Text?: string;
}

type BaseField = {
    Label: string;
    FieldName: string;
};

type Array = BaseField & {
    Type: 'Array';
    Format?: {
        LineCntForHover: string;
    };
    Sort: boolean;
};

type DateTime = BaseField & {
    Type: 'DateTime';
};

type ValidationFailureMessages = BaseField & {
    Type: 'ValidationFailureMessages';
};

type String = BaseField & {
    Type: 'String';
    // It looks like these two keys are only used for Status
    Css?: Record<number, 'rejected' | 'parked'>;
    Display?: Record<number, string>;
    Sort?: boolean;
};

type Currency = BaseField & {
    Type: 'Currency';
    Format: {
        Round: number;
        Split: number;
    };
};

type DateTimeFromNow = BaseField & {
    Type: 'DateTimeFromNow';
    Format: {
        Function: 'MomentFromNow';
    };
};

export type SearchResultField = Array | String | DateTime | DateTimeFromNow | Currency | ValidationFailureMessages;

export type SearchResultFieldType = SearchResultField['Type'];
