import { api } from './api';
import apiPaths from './paths';

class UserThunk {
    constructor() {}

    fetchAllUsers = (params) => {
        return api()
            .get(apiPaths.user, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchAllRoles = (params) => {
        return api()
            .get(apiPaths.getAllRoles, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    saveNewUser = (params) => {
        return api()
            .post(apiPaths.user, {
                ID: params.ID,
                CompanyID: params.CompanyID,
                Name: params.Name,
                Email: params.Email,
                Phone: params.Phone,
                UserName: params.UserName,
                EmployeeNumber: params.EmployeeNumber,
                Title: params.Title,
                Department: params.Department,
                JobClassification: params.JobClassification,
                ManagerUserId: params.ManagerUserId,
                UserRoles: params.UserRoles,
                WorkflowGroupUsers: params.WorkflowGroupUsers,
                ShowInvoiceSubmittedPopupInSupplierPortal: params.ShowInvoiceSubmittedPopupInSupplierPortal,
                SubstituteUserActive: params.SubstituteUserActive,
                SubstituteUserId: params.SubstituteUserId,
                UserGroupMemberships: params.UserGroupMemberships,
                Region: params.Region,
            })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    saveUser = (params) => {
        return api()
            .put(`${apiPaths.user}/${params.ID}`, {
                ID: params.ID,
                CompanyID: params.CompanyID,
                Name: params.Name,
                Email: params.Email,
                Phone: params.Phone,
                UserName: params.UserName,
                EmployeeNumber: params.EmployeeNumber,
                Title: params.Title,
                Department: params.Department,
                JobClassification: params.JobClassification,
                ManagersUserId: params.ManagersUserId,
                UserRoles: params.UserRoles,
                WorkflowGroupUsers: params.WorkflowGroupUsers,
                ShowInvoiceSubmittedPopupInSupplierPortal: params.ShowInvoiceSubmittedPopupInSupplierPortal,
                SubstituteUserActive: params.SubstituteUserActive,
                SubstituteUserId: params.SubstituteUserId,
                AutogenUnconfirmed: params.AutogenUnconfirmed,
                UserGroupMemberships: params.UserGroupMemberships,
                Region: params.Region,
            })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    deleteUser = (params) => {
        return api()
            .delete(apiPaths.user + params.Id)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    changePassword = (params) => {
        return api()
            .post(apiPaths.changePassword, {
                OldPassword: params.OldPassword,
                NewPassword: params.NewPassword,
            })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    logout = () => {
        return api()
            .get(apiPaths.logout)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    refreshLoginToken = () => {
        const refreshToken = window.localStorage.refresh_token;
        window.localStorage.refresh_token = '';
        return api()
            .post(apiPaths.login, `grant_type=refresh_token&refresh_token=${refreshToken}`)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    getUserByToken = () => {
        return api()
            .get(apiPaths.getUserByToken)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    resetPassword = (email) => {
        return api()
            .post(apiPaths.passwordRecover, { Email: email })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };
}

export default UserThunk;
