import { IFieldType, createFieldTypeFactory } from '../infrastructure';

/**
 * A radio button group field.
 */
export interface IRadioButtonGroup extends IFieldType<unknown | null> {
    type: 'IRadioButtonGroup';

    /**
     * The options the user can select from.
     */
    options: {
        /**
         * Label for the option.
         */
        label: string;

        /**
         * Value for the option. This is the value that the
         * field will have after selecting the option.
         */
        value: unknown;
    }[];
}

/**
 * Creates a radio button group field object.
 */
export const createRadioButtonGroup = createFieldTypeFactory<IRadioButtonGroup>('IRadioButtonGroup', null);
