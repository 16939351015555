import type { ITransceptaLogRocketUserTraits } from './identify';
import getFullConfig from './getFullConfig';

function checkIfUserRecordingIsEnabledByAppConfig(traits: ITransceptaLogRocketUserTraits) {
    const config = getFullConfig();

    if (!config) {
        return true;
    }

    const { ignoredCompanyIds, ignoredUserEmails } = config.config;

    return !ignoredUserEmails.includes(traits.email) && !ignoredCompanyIds.includes(traits.companyID);
}

/**
 * Stopgap solution to disable recording sessions by user until backend conditional recording
 * functionality is properly implemented.
 *
 * @param traits user traits
 * @returns modified user traits.
 */
export function conditionalRecordingStopgap(traits: ITransceptaLogRocketUserTraits): ITransceptaLogRocketUserTraits {
    return {
        ...traits,
        isRecordingEnabled: traits.isRecordingEnabled && checkIfUserRecordingIsEnabledByAppConfig(traits),
    };
}
