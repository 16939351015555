import { useContext } from 'react';
import { GridRowCheckboxContext } from '../../Organisms/Grid/GridRowCheckboxContext';
import { Checkbox } from '../../../../ui';

export const CheckboxCell = ({ record }) => {
    const contextState = useContext(GridRowCheckboxContext);
    if (!contextState.areCheckboxesEnabled) {
        return null;
    }
    function updateCheckBoxes(id: number): void {
        const hasId = contextState.checkedBoxes.includes(id);
        const withoutId = contextState.checkedBoxes.filter((x) => x !== id);
        if (hasId) {
            contextState.updateCheckedBoxes(withoutId);
        } else {
            contextState.updateCheckedBoxes([...withoutId, id]);
        }
    }

    return (
        <div className="data-column">
            <div className="core-grid-cell">
                <Checkbox
                    checked={contextState.checkedBoxes.includes(record.Id)}
                    onChange={() => updateCheckBoxes(record.Id)}
                    onClick={(e) => e.stopPropagation()}
                />
            </div>
        </div>
    );
};
