import { MENU_BAGES_FETCHED, MENU_BAGES_FETCHING } from '../actions/menuBageActions';

// eslint-disable-next-line import/prefer-default-export
export function menuBages(state = { total: 0, isLoading: false }, action) {
    switch (action.type) {
        case MENU_BAGES_FETCHING:
            return { ...state, isLoading: true };
        case MENU_BAGES_FETCHED:
            return { ...state, documentErrors: [...state.document.errors, ...action.payload.errors], isLoading: false };
        default:
            return state;
    }
}
