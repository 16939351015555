import { createSelector } from 'reselect';

import { IsNotUndefinedNullOrEmpty } from 'common/build/legacy/transcepta-common';
import projectConfig from '../project.config.json';

const currencyList = (state, businessDocType, profileId) => (state.userlogin ? state.userlogin.currencyList : []);
const invoiceProfiles = (state, businessDocType, profileId) => state.profiles.data;

const selectedInvoiceProfileId = (state, businessDocType, profileId) =>
    businessDocType === projectConfig.businessDocType.Invoice
        ? profileId || state.documentCreation.invoiceCreationFields.BusinessDocFields.Invoice.InvoiceProfileID
        : null;

export default createSelector(
    currencyList,
    invoiceProfiles,
    selectedInvoiceProfileId,
    (currencyList, invoiceProfiles, selectedInvoiceProfileId) => {
        let returnCurrencyList = currencyList || [];

        if (invoiceProfiles.length > 0 && selectedInvoiceProfileId) {
            let selectedInvoiceProfile = invoiceProfiles.filter((profile) => profile.Id === selectedInvoiceProfileId);
            if (selectedInvoiceProfile.length > 0) {
                selectedInvoiceProfile = selectedInvoiceProfile[0];
                if (
                    selectedInvoiceProfile.Currency &&
                    IsNotUndefinedNullOrEmpty(selectedInvoiceProfile.Currency_US) &&
                    selectedInvoiceProfile.Currency_US.toLowerCase() !== 'multiple'
                ) {
                    returnCurrencyList = returnCurrencyList.filter(
                        (currency) => currency.CurrencyType === selectedInvoiceProfile.Currency
                    );
                }
            }
        }

        return returnCurrencyList.map((currency) => {
            return {
                label: `${currency.CurrencyType_US} - ${currency.DisplayName}`,
                value: `${currency.CurrencyType}`,
                symbol: `${currency.Symbol}`,
                type_US: `${currency.CurrencyType_US}`,
            };
        });
    }
);
