export { SegmentDataType as SegmentDataTypes } from '../../../../types';

export interface ISegment {
    CompanyCustomDatasetKey?: string;
    CompanyCustomDatasetName?: string;
    CompanyCustomDatasetPath?: string;
    CompanyCustomDatasetFieldDefinitionKey?: string;
    CompanyCustomDatasetMinCharsSearch?: number;
    CompanyCustomDatasetSmallRecordLimit?: number;
    CompanyGLAccountDepth?: string;
    CompanyGLAccountSegmentName?: string;
    CompanyGLAccountMinCharsSearch?: number;
    CompanyGLAccountSmallRecordLimit?: number;
    DataType: string;
    Enums?: [];
    Label: string;
    Name: string;
    Order: string;
    key: string;
    value: string;
}

export interface IFormConfig {
    segments: ISegment[];
    amount: string;
    percent: string;
}

export interface IAccount {
    loading: boolean;
    error: string;
    accountList: { [key: string]: [] };
}

export enum FormType {
    inline = 'inline',
    dotNotation = 'dot-notation',
}

export interface IValue {
    value: string;
    label?: string;
}

export interface IAccountValue extends IValue {
    id: number;
    parentId?: number;
    depth?: number;
    name?: string;
    children: IAccountValue[];
}
