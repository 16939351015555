import React from 'react';
import { strings } from './localization/LocalizationStrings';
import projectConfig from '../project.config.json';

export const tableConfig = (profileType) => {
    let localizedProfileNameHeaderEntity = null;
    switch (profileType) {
        case projectConfig.profileType.BuyerDestination:
            localizedProfileNameHeaderEntity = strings.profileTypeBuyerDestination;
            break;
        case projectConfig.profileType.BuyerWarehouse:
            localizedProfileNameHeaderEntity = strings.profileTypeBuyerWarehouse;
            break;
        case projectConfig.profileType.Invoice:
            localizedProfileNameHeaderEntity = strings.profileTypeInvoice;
            break;
        case projectConfig.profileType.Procurment:
            localizedProfileNameHeaderEntity = strings.profileTypeProcurement;
            break;
        case projectConfig.profileType.Sales:
            localizedProfileNameHeaderEntity = strings.textPOPOA;
            break;
        case projectConfig.profileType.SupplierWarehouse:
            localizedProfileNameHeaderEntity = strings.documentNameSingleASN;
            break;
        default:
            console.error('"' + profileType + '" profile type is not yet handled by profile config');
            break;
    }

    let config = [];
    config.push({
        align: 'left',
        name: strings.textProfileName.replace(':profileType', localizedProfileNameHeaderEntity),
        key: 'Name',
        type: String,
        desktopRenderer: (row) => <div className="td">{row.Name}</div>,
    });
    config.push({
        align: 'left',
        name: strings.textRuleStatus,
        key: 'RuleStatus_US',
        type: String,
        desktopRenderer: (row) => <div className="td">{getLocalizedRuleStatus(row.RuleStatus)}</div>,
    });

    return config;
};

const getLocalizedRuleStatus = (ruleStatus) => {
    switch (ruleStatus) {
        case projectConfig.ruleStatus.Active:
            return strings.ruleStatusActive;
        case projectConfig.ruleStatus.Closed:
            return strings.ruleStatusClosed;
        case projectConfig.ruleStatus.IndirectWillNotDevelop:
            return strings.ruleStatusIndirectWillNotDevelop;
        case projectConfig.ruleStatus.Sample:
        case projectConfig.ruleStatus.Undefined:
            return strings.ruleStatusSample;
        case projectConfig.ruleStatus.WaitingForIndirect:
            return strings.ruleStatusWaitingForIndirect;
        default:
            console.error('"' + ruleStatus + '" rule status is not yet handled by profile config');
            return '';
    }
};

export function newProfileEntity(company, profileType, businessDocType) {
    return {
        Id: 0,
        CompanyID: company.ID,
        ProfileType: profileType,
        Name: profileType === projectConfig.profileType.Invoice ? company.Name : '',
        Address1: profileType === projectConfig.profileType.Invoice ? company.Address1 : '',
        Address2: profileType === projectConfig.profileType.Invoice ? company.Address2 : '',
        Address3: profileType === projectConfig.profileType.Invoice ? company.Address3 : '',
        City: profileType === projectConfig.profileType.Invoice ? company.City : '',
        State: profileType === projectConfig.profileType.Invoice ? company.State : '',
        PostalCode: profileType === projectConfig.profileType.Invoice ? company.PostalCode : '',
        Country: profileType === projectConfig.profileType.Invoice ? company.Country : '',
        Phone: company.Phone,
        Fax: profileType === projectConfig.profileType.Invoice ? company.Fax : '',
        Email: profileType === projectConfig.profileType.Invoice ? company.Email : '',
        EmailRepeat: profileType === projectConfig.profileType.Invoice ? company.Email : '',
        InvoiceConfirmationEmail: '',
        InvoiceConfirmationEmailRepeat: '',
        RemittanceAdviceEmail: '',
        RemitToAddressBlock: '',
        Signature: profileType === projectConfig.profileType.Invoice ? company.Name : null, //default the signature as the company's name for invoice profiles
        Currency_US: 'USD',
        Currency: 1,
        APEmail: '',
        APEmailRepeat: '',
        RuleStatus: 1,
        RuleStatus_US: 'Sample',
        LogoPath: '',
        ProfileDeliveryMethods:
            profileType === projectConfig.profileType.Sales ? [newProfileDeliveryMethod(businessDocType)] : [],
    };
}

export function newProfileDeliveryMethod(businessDocType) {
    return {
        ID: 0,
        ProfileId: 0,
        DeliveryMethod: null,
        IsEnabled: true,
        TestDelivery: false,
        ProcessingMode: 1,
        ProcessingMode_US: 'Production',
        EmailAddress: '',
        FaxNumber: '',
        PostRecipient: null,
        AddressLine1: null,
        AddressLine2: null,
        AddressLine3: null,
        City: null,
        PostalCode: null,
        Country: null,
        State: null,
        Uri: null,
        WhenUsed: 1,
        WhenUsed_US: 'Modes Match',
        Name: '',
        Settings: null,
        GroupBy: 1,
        GroupBy_US: 'Document (No Grouping)',
        GroupReleaseSchedule: 1, //next release time
        NoVATDelivery: false,
        ZipFileDelivery: false,
        CombinedCustomZipFileNamePattern: null,
        SeparateCustomZipFileNamePattern: null,
        ZipFileCounter: 0,
        ZipFileMaxDigits: 0,
        BusinessDocType: businessDocType,
        ProfileDeliveryFileTypes: [newProfileDeliveryFileType()],
    };
}

export function newProfileDeliveryFileType() {
    return {
        ID: 0,
        DeliveryMethodId: 0,
        FileType: 1,
        GroupingMethod: 1,
        GroupingMethod_US: 'Separate',
        Counter: 0,
        MaxDigits: 0,
        CombinedCustomZipFileNamePattern: null,
        SeparateCustomZipFileNamePattern: null,
        IsEnabled: true,
        Xslt: null,
        Settings: null,
        FileSubType: null,
    };
}

export function newProfileVatArchiveServiceEntity(company, invoiceProfileId) {
    return {
        ID: 0,
        CompanyId: company.ID,
        RegistrationStatus: 1,
        RegistrationStatus_US: 'Not Required',
        ActivationStatus: 1,
        ActivationStatus_US: 'Not Required',
        StoragePath: null,
        AdminUserName: null,
        AdminPassword: null,
        BuyerDestinationProfileId: null,
        InvoiceProfileId: invoiceProfileId,
        VATComplianceRequired: false,
        CountryOfEstablishment: null,
        ValidateVATInvoices: false,
        ArchiveVATInvoies: false,
        SignVATInvoices: 0,
        AgreementIdentifier: '',
        VATNumber: '',
        TrustWeaverSectionName: '',
        Active: false,
        SignatureInputType: 2,
        SignatureInputType_US: 'PDF',
        SignatureJobType: 1,
        SignatureJobType_US: 'CADESA',
        SignatureOutputType: 1,
        SignatureOutputType_US: 'PDF',
        TrustWeaverBranchStatus: 0,
        TrustWeaverBranchStatus_US: 'Not Required',
    };
}

export function validateProfile(profileType, profile, profileVatArchiveService, existingProfiles) {
    const enabledProfileDeliveryMethod =
        profileType === projectConfig.profileType.Sales && profile.DeliveryMethodIsEditable
            ? profile.ProfileDeliveryMethods.filter((deliveryMethod) => deliveryMethod.IsEnabled)[0]
            : null;
    const enabledProfileDeliveryFileType = enabledProfileDeliveryMethod
        ? enabledProfileDeliveryMethod.ProfileDeliveryFileTypes.filter((fileType) => fileType.IsEnabled)[0]
        : null;

    const creatingDuplicateProfile =
        profile.Id === 0 && existingProfiles.some((existingProfile) => existingProfile.Name === profile.Name);

    const requiredFieldMissing =
        !profile.Name ||
        !profile.Address1 ||
        !profile.City ||
        !profile.PostalCode ||
        !profile.Country ||
        (profileType === projectConfig.profileType.Invoice &&
            (!profile.Phone ||
                profile.Currency === undefined ||
                profile.Currency === null ||
                !profile.Email ||
                !profile.EmailRepeat)) ||
        !profile.InvoiceConfirmationEmail ||
        !profile.InvoiceConfirmationEmailRepeat ||
        (profileVatArchiveService &&
            profileVatArchiveService.VATComplianceRequired &&
            !profileVatArchiveService.VATNumber) ||
        (enabledProfileDeliveryMethod &&
            (!enabledProfileDeliveryMethod.DeliveryMethod ||
                (enabledProfileDeliveryMethod.DeliveryMethod === projectConfig.profileDeliveryMethod.Email &&
                    !enabledProfileDeliveryMethod.EmailAddress))) ||
        (enabledProfileDeliveryFileType && !enabledProfileDeliveryFileType.FileType);

    const emailNotConfirmed =
        profileType === projectConfig.profileType.Invoice && profile.Email !== profile.EmailRepeat;
    const invoiceConfirmationEmailNotConfirmed =
        profile.InvoiceConfirmationEmail !== profile.InvoiceConfirmationEmailRepeat;

    // Generate the error message based upon the checks made above
    const errorMessages = [];

    // Output message telling that the user that the name they are using for the new profile has already been used
    if (creatingDuplicateProfile) {
        const localizedProfileType = getLocalizedProfileType(profileType);
        errorMessages.push(
            strings.textValidationMessageCreatingDuplicate
                .replace(/:profileType/g, localizedProfileType)
                .replace(':profileName', profile.Name)
        );
    }

    // Output message telling user that a required field is missing
    if (requiredFieldMissing) {
        errorMessages.push(strings.textValidationMessageProfileRequiredFieldMissing);
    }

    // Output message listing all the emails that were not properly confirmed
    if (emailNotConfirmed || invoiceConfirmationEmailNotConfirmed) {
        errorMessages.push(strings.textValidationMessageProfileInvalidEmailRepeat);
    }

    return errorMessages;
}

export function getStandardStyleSheetXsltName(purchaseOrderFileFormat, standardStyleSheets) {
    const standardStyleSheetType = convertPurchaseOrderFileFormatToStandardStyleSheetType(purchaseOrderFileFormat);
    const currentStandardStyleSheets = standardStyleSheets.filter(
        (standardStyleSheet) =>
            standardStyleSheet.IsCurrentVersion && standardStyleSheet.StandardType === standardStyleSheetType
    );

    return currentStandardStyleSheets && currentStandardStyleSheets.length > 0
        ? currentStandardStyleSheets[0].Name
        : null;
}

export function convertPurchaseOrderFileFormatToProfileDeliveryFileType(purchaseOrderFileFormat) {
    switch (purchaseOrderFileFormat) {
        case projectConfig.purchaseOrderFileFormat.CSV:
        case projectConfig.purchaseOrderFileFormat.EDI:
        case projectConfig.purchaseOrderFileFormat.OAGXML:
            return projectConfig.profileDeliveryFileType.Xml;

        case projectConfig.purchaseOrderFileFormat.PDF:
            return projectConfig.profileDeliveryFileType.PDF;

        default:
            return null;
    }
}

export function convertProfileDeliveryFileTypeToPurchaseOrderFileFormat(
    profileDeliveryFileType,
    xsltName,
    standardStyleSheets
) {
    switch (profileDeliveryFileType) {
        case projectConfig.profileDeliveryFileType.PDF:
            return projectConfig.purchaseOrderFileFormat.PDF;

        case projectConfig.profileDeliveryFileType.Xml:
            const currentStandardStyleSheets = standardStyleSheets.filter(
                (standardStyleSheet) => standardStyleSheet.IsCurrentVersion && standardStyleSheet.Name === xsltName
            );
            return currentStandardStyleSheets && currentStandardStyleSheets.length > 0
                ? convertStandardStyleSheetTypeToPurchaseOrderFileFormat(currentStandardStyleSheets[0].StandardType)
                : null;

        default:
            return null;
    }
}

function convertPurchaseOrderFileFormatToStandardStyleSheetType(purchaseOrderFileFormat) {
    switch (purchaseOrderFileFormat) {
        case projectConfig.purchaseOrderFileFormat.CSV:
            return projectConfig.standardStyleSheetType.CSV;

        case projectConfig.purchaseOrderFileFormat.EDI:
            return projectConfig.standardStyleSheetType.EDI;

        case projectConfig.purchaseOrderFileFormat.OAGXML:
            return projectConfig.standardStyleSheetType.OAG;

        default:
            return null;
    }
}

function convertStandardStyleSheetTypeToPurchaseOrderFileFormat(standardStyleSheetType) {
    switch (standardStyleSheetType) {
        case projectConfig.standardStyleSheetType.CSV:
            return projectConfig.purchaseOrderFileFormat.CSV;

        case projectConfig.standardStyleSheetType.EDI:
            return projectConfig.purchaseOrderFileFormat.EDI;

        case projectConfig.standardStyleSheetType.OAG:
            return projectConfig.purchaseOrderFileFormat.OAGXML;

        default:
            return null;
    }
}

export function getLocalizedProfileType(profileType) {
    switch (profileType) {
        case projectConfig.profileType.Invoice:
            return strings.profileTypeInvoice;
        default:
            return getLocalizedProfileTypeOverride(profileType);
    }
}

export function getLocalizedProfileTypeOverride(profileType) {
    // Only return a non-null value if we want to specifically override the page title.
    switch (profileType) {
        case projectConfig.profileType.Sales:
            return strings.textPOPOA;
        case projectConfig.profileType.SupplierWarehouse:
            return strings.documentNameSingleASN;
        default:
            return null;
    }
}

export function getProfileTypeForBusinessDocType(businessDocType) {
    switch (businessDocType) {
        case projectConfig.businessDocType.ASN:
        case projectConfig.businessDocType.ASNGeneric:
        case projectConfig.businessDocType.ReceivingAdvice:
            return projectConfig.profileType.SupplierWarehouse;

        case projectConfig.businessDocType.ConsumptionAdvice:
        case projectConfig.businessDocType.InventoryAdvice:
        case projectConfig.businessDocType.PurchaseOrder:
        case projectConfig.businessDocType.SIMDocument:
        case projectConfig.businessDocType.PurchaseOrderAcknowledgement:
            return projectConfig.profileType.Sales;

        case projectConfig.businessDocType.Invoice:
            return projectConfig.profileType.Invoice;

        default:
            return null;
    }
}

export const validateAccountProfile = (profile) => {
    const requiredFieldMissing = validateAccountProfileRequiredFields(profile);
    const errorMessages = [];
    if (requiredFieldMissing) {
        errorMessages.push(strings.textValidationMessageProfileRequiredFieldMissing);
    }
    let mismatchEmails = false;
    if (profile.Contacts) {
        profile.Contacts.map((c) => {
            if ((c.Email && c.Email.trim() === '') || (c.ConfirmEmail && c.ConfirmEmail.trim() === '')) {
                errorMessages.push(strings.textValidationMessageProfileRequiredFieldMissing);
            }
            if (c.Email !== c.ConfirmEmail) {
                mismatchEmails = true;
            }
        });
    }
    if (mismatchEmails) {
        errorMessages.push(strings.textValidationMessageProfileInvalidEmailRepeat);
    }
    return errorMessages;
};

const validateAccountProfileRequiredFields = (profile) => {
    const requiredFieldMissing =
        !profile.Name ||
        !profile.Address1 ||
        !profile.City ||
        !profile.PostalCode ||
        !profile.Country ||
        !profile.Phone ||
        !profile.Address1;
    return requiredFieldMissing;
};
