import Ajv, { DefinedError, JSONSchemaType } from 'ajv';
import { ReactNode } from 'react';
import { ServiceError } from '../../ui/ErrorToErrorMessage';

function extractDefaultErrorMessage(e: DefinedError) {
    // for enums, we want to add the list of allowed values to the error message
    if (e.keyword === 'enum') {
        return `${e.message}: ${e.params.allowedValues.map((x) => `"${x}"`).join(', ')}`;
    }

    return e.message;
}

/**
 * Creates a parser that parses an arbitrary "object" to a typed object by using
 * an Ajv schema definition.
 *
 * @param schema the Ajv schema definition for the object type
 * @returns a parser function
 */
export function createObjectParser<T>(
    schema: JSONSchemaType<T>,
    transformErrorMessage?: (error: DefinedError) => string | undefined
) {
    const ajv = new Ajv({
        discriminator: true,
        allowUnionTypes: true,
        allErrors: true,
        // Begin fix for IE (can remove in future once support for IE ends): https://github.com/ajv-validator/ajv/issues/1585
        unicodeRegExp: false,
        code: { es5: true },
        // End fix for IE
    });

    const validate = ajv.compile(schema);

    const stringifyError = (rootName: string, e: DefinedError, message?: string) => {
        return `"${rootName}${e.instancePath}": ${message ?? extractDefaultErrorMessage(e)}`;
    };

    return (data: unknown, rootName: string) => {
        if (validate(data)) {
            return data; // has correct type now
        } else {
            const errors = (validate.errors as DefinedError[]).map((e) => {
                if (!transformErrorMessage) {
                    return stringifyError(rootName, e);
                }

                return stringifyError(rootName, e, transformErrorMessage(e));
            });
            throw new ServiceError(errors);
        }
    };
}

/**
 * Creates a parser that parses an arbitrary string to a typed object by using
 * an Ajv schema definition.
 * @param schema the Ajv schema definition for the object type
 * @returns a parser function
 */
export function createStringParser<T>(
    schema: JSONSchemaType<T>,
    transformErrorMessage?: (error: DefinedError) => string | undefined
) {
    const parseJSON = (data: string, rootName: string) => {
        try {
            return JSON.parse(data) as unknown;
        } catch (e: any) {
            throw new ServiceError([`"${rootName}": ${e.message}`]);
        }
    };

    const parseObject = createObjectParser(schema, transformErrorMessage);

    return (data: string, rootName: string) => {
        return parseObject(parseJSON(data, rootName), rootName);
    };
}

/**
 * Creates a function based on the parserFunction
 * @param parserFunction createStringParser
 * @returns a function with the errors when exists
 */
export function createValidatorFromParser<T>(parserFunction: (data: T, rootName: string) => unknown) {
    return (data: T, rootName: string, errors: ReactNode[]) => {
        try {
            parserFunction(data, rootName);
        } catch (e: any) {
            e.validationErrors.forEach((error: any) => {
                errors.push(error);
            });
        }
    };
}
