import { api } from '../../../api';
import { RecursivePartial } from '../../../../types';
import { AxiosResponse } from 'axios';
import { BuyerCustomJSRuleMappingViewModel, CreateUpdateDeleteViewModel, ExecutionContext } from '../../ViewModels';
import {
    GetBuyerCustomJSRuleMappingParams,
    GetBuyerCustomJSRulesMappingByCompanyParams,
    GetBuyerCustomJSRulesMappingByExecutionContextParams,
    GetBuyerCustomJSRuleMappingsByDocTypeWebEntryParams,
} from './Params';

export class BuyerCustomJSRuleMappingApi {
    private route = '/BuyerCustomJSRuleMapping';

    public getBuyerCustomJSRuleMapping = (
        id: number,
        params?: GetBuyerCustomJSRuleMappingParams
    ): Promise<AxiosResponse<BuyerCustomJSRuleMappingViewModel[]>> => {
        return api().get(`${this.route}/${id}`, { params });
    };

    public getBuyerCustomJSRulesMappingByCompany = (
        params: GetBuyerCustomJSRulesMappingByCompanyParams
    ): Promise<AxiosResponse<BuyerCustomJSRuleMappingViewModel[]>> => {
        return api().get(`${this.route}`, { params });
    };

    public getBuyerCustomJSRulesMappingByExecutionContext = async (
        params: GetBuyerCustomJSRulesMappingByExecutionContextParams
    ): Promise<AxiosResponse<BuyerCustomJSRuleMappingViewModel[]>> => {
        const ctxEnumValues =
            !Array.isArray(params.executionContext) && params.executionContext !== undefined
                ? [params.executionContext]
                : params.executionContext;

        const executionContext = ctxEnumValues?.map((ctx) => {
            switch (ctx) {
                case ExecutionContext.WebEntry: {
                    return 'WebEntry';
                }
                case ExecutionContext.DPSRule: {
                    return 'DPSRule';
                }
                case ExecutionContext.UserWorkflowOrWorkflowActivity: {
                    return 'InWorkflowOrWorkflowActivity';
                }
                default: {
                    throw new Error('Unexpected context type.');
                }
            }
        });

        const response = await api().get<
            BuyerCustomJSRuleMappingViewModel,
            AxiosResponse<BuyerCustomJSRuleMappingViewModel[]>
        >(`${this.route}`, {
            params: {
                ...params,
                executionContext: executionContext?.join(','),
            },
        });

        return response;
    };

    public getBuyerCustomJSRuleMappingsByDocTypeWebEntry = (
        params: GetBuyerCustomJSRuleMappingsByDocTypeWebEntryParams
    ): Promise<AxiosResponse<BuyerCustomJSRuleMappingViewModel[]>> => {
        return api().get(this.route, { params });
    };

    public postBuyerCustomJSRuleMapping = (
        buyerCustomJSRuleView: RecursivePartial<BuyerCustomJSRuleMappingViewModel>
    ): Promise<AxiosResponse<CreateUpdateDeleteViewModel>> => {
        return api().post(this.route, buyerCustomJSRuleView);
    };

    public putBuyerCustomJSRuleMapping = (
        id: number,
        buyerCustomJSRuleMappingView: RecursivePartial<BuyerCustomJSRuleMappingViewModel>
    ): Promise<CreateUpdateDeleteViewModel> => {
        return api().put(`${this.route}/${id}`, buyerCustomJSRuleMappingView);
    };

    public deleteBuyerCustomJSRuleMapping = (id: number): Promise<AxiosResponse<CreateUpdateDeleteViewModel>> => {
        return api().delete(`${this.route}/${id}`);
    };
}
