import { IBreadCrumb } from './IBreadCrumb';

/**
 * Creates a breadcrumb object
 * @param label the label of the breadcrumb
 * @param path path to the page breadcrumb represents
 * @returns the breadcrumb object
 */
export function createBreadCrumb(label: string, path: string | null): IBreadCrumb {
    return {
        Label: label,
        Path: path,
    };
}
