import { EventEmitter, useSubscription } from '../../../utils';
import { ILoginResponseViewModel } from '../../../services/UserService/Api';

export class AuthTokenStore {
    private emitter = new EventEmitter();

    setAuthToken(authToken: ILoginResponseViewModel) {
        localStorage.setItem('newAuthToken', JSON.stringify(authToken));
        localStorage.setItem('token_expires', authToken['.expires']);
        localStorage.setItem('access_token', authToken.access_token);
        localStorage.setItem('refresh_token', authToken.refresh_token);
        this.emitter.notify();
    }

    clearAuthToken() {
        localStorage.removeItem('newAuthToken');
        localStorage.removeItem('token_expires');
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        this.emitter.notify();
    }

    getAuthToken() {
        const x = localStorage.getItem('newAuthToken');
        if (x) {
            return JSON.parse(x) as ILoginResponseViewModel;
        }

        return null;
    }

    onIsLoggedInChange(cb: () => void) {
        return this.emitter.subscribe(cb);
    }
}

export const authTokenStore = new AuthTokenStore();

export function useAuthToken() {
    useSubscription(authTokenStore.onIsLoggedInChange.bind(authTokenStore));

    return authTokenStore.getAuthToken();
}
