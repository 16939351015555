import associatedFieldService from '../../PluginHost/services/associatedFieldService';
import {
    IAssociatedFieldService,
    IDocumentFieldController,
    IDropdownOptionsRepository,
} from '../../proservContractTypes';
import { UserWorkflowActivityState } from '../../types/DocumentEditValidationState';
import { InternalRuleConfiguration } from './useInternalRulesConfiguration';

export interface IRValidationError {
    associatedField?: string;

    message: string;
}

export interface IRWarningResult {
    type: 'WarningResult';

    error: IRValidationError;
}

export interface IRErrorResult {
    type: 'ErrorResult';

    error: IRValidationError;
}

type IRValidationResult = IRWarningResult | IRErrorResult;

export type IRValidationResults = IRValidationResult[];

export class ValidationFunctionResultsCollector {
    validationResults: IRValidationResults = [];

    constructor(private internalRuleConfiguration: InternalRuleConfiguration) {}

    addError(error: IRValidationError): void {
        this.validateError(error);
        this.validationResults.push({
            type: 'ErrorResult',
            error,
        });
    }

    addWarning(error: IRValidationError): void {
        this.validateError(error);
        this.validationResults.push({
            type: 'WarningResult',
            error,
        });
    }

    private validateError(error: any) {
        this.validateErrorSemantics(this.validateErrorFormat(error));
    }

    private validateErrorFormat(error: any) {
        if (typeof error !== 'object') {
            throw new Error(`value is not a ValidationError (not an object).`);
        }

        if (error === null) {
            throw new Error(`value is not a ValidationError (is null).`);
        }

        if (typeof error.associatedField !== 'undefined' && typeof error.associatedField !== 'string') {
            throw new Error(
                `value is not a ValidationError (its 'associatedField' property must either be undefined or a string)`
            );
        }

        if (typeof error.message !== 'string') {
            throw new Error(`value is not a IRValidationError (its 'message' property must be a string)`);
        }

        return error as IRValidationError;
    }

    private validateErrorSemantics(error: IRValidationError) {
        const { fieldNamesToValidateOnFocusLoss } = this.internalRuleConfiguration;

        if (error.associatedField) {
            const fieldName = associatedFieldService.getFieldName(error.associatedField);
            if (!fieldNamesToValidateOnFocusLoss.includes(fieldName)) {
                throw new Error(
                    `The field "${fieldName}" is not validated on focus loss, so errors cannot be associated with it`
                );
            }
        }
    }
}

export type ValidationFunction = (
    documentState: UserWorkflowActivityState,
    results: ValidationFunctionResultsCollector
) => Promise<void> | void;

export type DocumentLayoutController = (
    documentState: UserWorkflowActivityState,
    controller: IDocumentFieldController
) => Promise<void> | void;

export interface InternalRuleEnvironment {
    registerValidationFunction(validationFunction: ValidationFunction): void;

    registerUIDocumentController(uiDocumentController: DocumentLayoutController): void;

    services: {
        associatedField: IAssociatedFieldService;
        dropdownOptionsRepository: IDropdownOptionsRepository;
    };
}
