import { BaseTextFieldProps, Box, BoxProps, InputLabelProps, Stack } from '@mui/material';
import { FocusEventHandler } from 'react';
import { FCNC } from '../FCNC';
import { useFieldsReadonly } from '../Form';
import { ReadOnly } from '../ReadOnly';
import { ITooltipIconProps, renderTooltipLabel } from '../Tooltip';
import { getLocalePercentPlacements } from './LocaleOptions';
import { NumericMaskedTextField } from './NumericMaskedTextField';

export type RangeNumericTextValue = [number | null, number | null];

export interface IRangeNumericTextFieldProps {
    id?: string;
    locale: string;
    value: RangeNumericTextValue;
    onChange: (value: RangeNumericTextValue) => void;
    maxValue?: number;
    label?: string;
    required?: boolean;
    sx?: BaseTextFieldProps['sx'];
    readonly?: boolean;
    onDoubleClick?: BaseTextFieldProps['onDoubleClick'];
    scale?: number;
    padFractionalZeros?: boolean;
    testId?: string;
    tooltip?: ITooltipIconProps;
    inputLabelProps?: InputLabelProps;
    disabled?: boolean;
    signed?: boolean;
    onFocus?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
    placeholder?: string;
    stacked?: boolean;
    labelBoxSx?: BoxProps['sx'];
}

export const RangeNumericTextField: FCNC<IRangeNumericTextFieldProps> = ({
    locale,
    value,
    onChange,
    maxValue,
    required,
    sx,
    readonly,
    label,
    id,
    onDoubleClick,
    testId,
    tooltip,
    scale = 2,
    padFractionalZeros = false,
    inputLabelProps,
    disabled,
    signed,
    onFocus,
    placeholder,
    stacked,
    labelBoxSx,
}) => {
    const isReadonly = useFieldsReadonly(readonly);

    if (isReadonly) {
        return (
            <ReadOnly
                testId={testId}
                label={label}
                value={`${typeof value[0] === 'number' ? value[0] : ''} - ${
                    typeof value[1] === 'number' ? value[1] : ''
                }`}
            />
        );
    }

    if (required && label) {
        label = `${label} *`;
    }

    const localeOptions = { ...getLocalePercentPlacements(locale) };

    return (
        <Box
            sx={
                stacked
                    ? { display: 'flex', flexDirection: 'column', width: '100%' }
                    : { display: 'flex', alignItems: 'center', width: '100%' }
            }
        >
            {label ? (
                <Box component="label" htmlFor={id}>
                    <Box sx={{ width: 160, ...labelBoxSx }}>{renderTooltipLabel({ tooltip, label })}</Box>
                </Box>
            ) : null}
            <Stack
                direction="row"
                columnGap={2}
                sx={{
                    width: '100%',
                }}
            >
                <NumericMaskedTextField
                    textFieldLabel="Min"
                    stacked={stacked}
                    id={id}
                    value={value[0] ?? null}
                    onChange={(v) => onChange([v, value[1] ?? null])}
                    localeOptions={localeOptions}
                    maxValue={maxValue}
                    required={required}
                    sx={sx}
                    readonly={readonly}
                    onDoubleClick={onDoubleClick}
                    scale={scale}
                    padFractionalZeros={padFractionalZeros}
                    testId={testId}
                    tooltip={tooltip}
                    inputLabelProps={inputLabelProps}
                    disabled={disabled}
                    signed={signed}
                    onFocus={onFocus}
                    placeholder={placeholder}
                />
                <NumericMaskedTextField
                    textFieldLabel="Max"
                    stacked={stacked}
                    id={id}
                    value={value[1] ?? null}
                    onChange={(v) => onChange([value[0] ?? null, v])}
                    localeOptions={localeOptions}
                    maxValue={maxValue}
                    required={required}
                    sx={sx}
                    readonly={readonly}
                    onDoubleClick={onDoubleClick}
                    scale={scale}
                    padFractionalZeros={padFractionalZeros}
                    testId={testId}
                    tooltip={tooltip}
                    inputLabelProps={inputLabelProps}
                    disabled={disabled}
                    signed={signed}
                    onFocus={onFocus}
                    placeholder={placeholder}
                />
            </Stack>
        </Box>
    );
};
