import { ProfileType } from '../../../../services/Profile';

export function getBuyerAndSupplierCompanyIdsByProfileType({
    senderProfileType,
    receiverProfileType,
    senderCompanyId,
    receiverCompanyId,
}: {
    senderProfileType: ProfileType;
    receiverProfileType: ProfileType;
    senderCompanyId: number;
    receiverCompanyId: number;
}) {
    if (
        senderProfileType === ProfileType.BuyerDestination ||
        senderProfileType === ProfileType.Procurement ||
        senderProfileType === ProfileType.BuyerWarehouse
    ) {
        return { buyerCompanyId: senderCompanyId, supplierCompanyId: receiverCompanyId };
    }
    if (
        receiverProfileType === ProfileType.BuyerDestination ||
        receiverProfileType === ProfileType.Procurement ||
        receiverProfileType === ProfileType.BuyerWarehouse
    ) {
        return { buyerCompanyId: receiverCompanyId, supplierCompanyId: senderCompanyId };
    }

    throw new Error('Unexpected profile type configuration.');
}
