import { api } from '../../../services/api';
import f, { ODataFilterBuilder } from 'odata-filter-builder';
import { ODataUtils } from '../../../utils';
import { AxiosResponse } from 'axios';
import {
    IBusinessDocTypeSearchViewModel,
    IBusinessDocTypeSearchViewModelData,
} from './ViewModels/BusinessDocTypeSearchViewModel';
import ICreatedUpdateDeleteViewModel from '../../Common/Api/ViewModels/CreatedUpdateDeleteViewModel';

interface IODataParams {
    id?: number;
    companyId?: number;
    businessDocTypeId?: number;
    workflowTemplateUI?: string;
    page?: number;
    screenSize?: number;
    senderProfileType?: number;
    receiveProfileType?: number;
    orderBy?: string;
}

export class BusinessDocTypeSearchApi {
    route = '/BusinessDocType/Search';

    private getFilterString(
        id?: number,
        companyId?: number,
        businessDocTypeId?: number,
        workflowTemplateUI?: string,
        page?: number,
        screenSize?: number
    ) {
        const filterRules: ODataFilterBuilder[] = [];

        if (typeof id === 'number') {
            filterRules.push(f().eq('Id', id));
        }

        if (typeof companyId === 'number') {
            filterRules.push(f().eq('CompanyId', companyId));
        }

        if (typeof businessDocTypeId === 'number') {
            filterRules.push(f().eq('BusinessDocTypeId', businessDocTypeId));
        }

        if (typeof workflowTemplateUI === 'string') {
            filterRules.push(f().eq('WorkflowTemplateUI', workflowTemplateUI));
        }

        if (typeof page === 'number') {
            filterRules.push(f().eq('Page', page));
        }

        if (typeof screenSize === 'number') {
            filterRules.push(f().eq('ScreenSize', screenSize));
        }

        return filterRules.length > 0 ? ODataUtils.andConditions(filterRules).toString() : undefined;
    }

    public getBusinessDocTypeSearch = async ({
        id,
        companyId,
        businessDocTypeId,
        workflowTemplateUI,
        page,
        screenSize,
        orderBy,
    }: IODataParams): Promise<AxiosResponse<IBusinessDocTypeSearchViewModel[]>> => {
        const filters = this.getFilterString(id, companyId, businessDocTypeId, workflowTemplateUI, page, screenSize);

        const params = {
            $filter: filters || undefined,
            $orderBy: orderBy,
        };

        return api().get(`${this.route}`, { params });
    };

    public updateBusinessDocumentTypeSearch = async (
        id: number,
        businessDocTypeSearch: IBusinessDocTypeSearchViewModelData
    ): Promise<void> => api().put(`${this.route}?businessDocTypeSearchId=${id}`, businessDocTypeSearch);

    public insertBusinessDocumentTypeSearch = async (
        businessDocTypeSearch: IBusinessDocTypeSearchViewModelData
    ): Promise<AxiosResponse<ICreatedUpdateDeleteViewModel>> => {
        return api().post(this.route, businessDocTypeSearch);
    };

    public deleteBusinessDocumentTypeSearch = async (id: number): Promise<void> => {
        return api().delete(`${this.route}?businessDocTypeSearchId=${id}`);
    };
}
