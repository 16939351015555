import { ChangeEvent, FC, FormEvent } from 'react';
import { Button, TextField } from '../../../../ui';
import { Stack } from '@mui/material';

interface IProps {
    email: string;
    emailError: boolean;
    handleEmailChange: (event: ChangeEvent) => void;
    password: string;
    passwordError: boolean;
    handlePasswordChange: (event: ChangeEvent) => void;
    companyId: string;
    companyIdError: boolean;
    handleCompanyIdChange: (event: ChangeEvent) => void;
    handleSignIn: (event: FormEvent) => void;
    handleForgotPassword: () => void;
}

const LoginForm: FC<IProps> = ({
    email,
    emailError,
    handleEmailChange,
    password,
    passwordError,
    handlePasswordChange,
    companyId,
    companyIdError,
    handleCompanyIdChange,
    handleSignIn,
    handleForgotPassword,
}) => {
    return (
        <form onSubmit={handleSignIn}>
            <TextField
                stacked
                id="transcepta-login-email"
                label="Email"
                type="email"
                color="secondary"
                value={email}
                error={emailError}
                onChange={handleEmailChange}
                sx={{
                    pb: 1,
                    '.MuiInputLabel-shrink': {
                        top: -8,
                    },
                }}
            />
            <TextField
                stacked
                id="transcepta-login-password"
                label="Password"
                type="password"
                color="secondary"
                value={password}
                error={passwordError}
                onChange={handlePasswordChange}
                sx={{
                    pb: 1,
                    '.MuiInputLabel-shrink': {
                        top: -8,
                    },
                }}
            />
            <TextField
                stacked
                id="transcepta-login-company-id"
                label="Company Id"
                type="company"
                color="secondary"
                value={companyId}
                error={companyIdError}
                onChange={handleCompanyIdChange}
                sx={{
                    pb: 1,
                    '.MuiInputLabel-shrink': {
                        top: -8,
                    },
                }}
            />
            <Stack direction="row" justifyContent="space-between">
                <Button type="submit">Sign In</Button>
                <Button variant="text" onClick={handleForgotPassword}>
                    I forgot my password
                </Button>
            </Stack>
        </form>
    );
};

export default LoginForm;
