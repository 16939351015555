import { IToken, lexDotNotation } from './lexDotNotation';
import {
    DotNotationExpr,
    INumberExpr,
    IVariableExpr,
    createDynamicPropertyAccessExpr,
    createPropertyNameExpr,
    createPropertyAccessExpr,
    createNumberExpr,
    createVariableExpr,
} from './DotNotation';

type EndType = IToken['type'] | 'EOF';

class Parser {
    private tokens: IToken[];

    private pos = 0;

    constructor(private value: string) {
        this.tokens = lexDotNotation(value);
    }

    parseDotNotationExpr(endTypes: EndType[] = ['EOF']): DotNotationExpr {
        if (this.current().type === 'NAME') {
            return this.parseNameExpr(endTypes);
        }

        throw this.error('Expected property name');
    }

    private parseNameExpr(endTypes: EndType[]): DotNotationExpr {
        let left: DotNotationExpr = createPropertyNameExpr({
            property: this.next().value,
        });

        while (!endTypes.includes(this.current().type)) {
            if (this.current().type === 'DOT') {
                left = this.parsePropertyAccessExpr(left);
            } else if (this.current().type === 'OPEN_SQUARE') {
                left = this.parseDynamicPropertyAccessExpr(left);
            } else {
                throw this.error('Expected end of input, ".", or "[" after property name');
            }
        }

        return left;
    }

    private parsePropertyAccessExpr(leftExpr: DotNotationExpr): DotNotationExpr {
        this.next(); // consume DOT

        if (this.current().type !== 'NAME') {
            throw this.error('Expected property name after "."');
        }

        return createPropertyAccessExpr({
            object: leftExpr,
            property: this.next().value,
        });
    }

    private parseDynamicPropertyAccessExpr(leftExpr: DotNotationExpr): DotNotationExpr {
        this.next(); // consume OPEN_SQUARE
        const property = this.parseDynamicPropertyExpr();
        if (this.current().type !== 'CLOSE_SQUARE') {
            throw this.error('Expected "]" after dynamic property');
        }
        this.next(); // consume CLOSE_SQUARE
        return createDynamicPropertyAccessExpr({
            object: leftExpr,
            property,
        });
    }

    private parseDynamicPropertyExpr(): DotNotationExpr | INumberExpr | IVariableExpr {
        if (this.current().type === 'VARIABLE') {
            return createVariableExpr({
                name: this.next().value,
            });
        }

        if (this.current().type === 'NUMBER') {
            return createNumberExpr({
                value: Number(this.next().value),
            });
        }

        if (this.current().type !== 'NAME') {
            throw this.error('Expected nested property, variable, or number after "["');
        }

        return this.parseDotNotationExpr(['EOF', 'CLOSE_SQUARE']);
    }

    private error(message: string) {
        const token = this.current();
        const inputPosition = token.inputPosition ?? this.value.length;
        const context = `${this.value}<EOF>\n${' '.repeat(inputPosition)}^`;
        const errorMessage = `${message}\n${context}`;

        return new Error(errorMessage);
    }

    private next() {
        const value = this.current();
        this.pos += 1;
        return value;
    }

    private current() {
        if (!this.tokens[this.pos]) {
            return { type: 'EOF', value: '' as string, inputPosition: null } as const;
        }
        return this.tokens[this.pos];
    }
}

/**
 * Parses a DotNotation string to a DotNotationExpr
 * @param value the string DotNotation expression to parse
 * @returns the parsed expression
 */
export function parseDotNotation(value: string) {
    return new Parser(value).parseDotNotationExpr();
}
