// DEPRECATED: Do not use
// REFACTOR: Build a new version of this component using material-ui. Use in the new SearchPageTemplate
import { FC, useRef } from 'react';
import { Button } from '../../Atoms';
import { Search, CloseSharp } from '@mui/icons-material';
import { TextField } from '../../../../ui';
import { Stack, useMediaQuery, useTheme, InputAdornment, IconButton } from '@mui/material';

export interface ISimpleSearch {
    placeholder: string;
    handleSearchClick: Function;
    onInputChange: Function;
    id: string;
    value?: string | undefined;
    size: string;
    clearSearch?: () => void;
}

/**
 * @deprecated
 */
export const SimpleSearch: FC<ISimpleSearch> = ({
    placeholder,
    handleSearchClick,
    onInputChange,
    id,
    value,
    clearSearch,
}) => {
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <>
            <Stack direction="row">
                <TextField
                    value={value}
                    placeholder={placeholder || 'Search...'}
                    id={`input-search-${id}`}
                    key={1}
                    sx={{ width: isMdDown ? '18rem' : '40rem' }}
                    inputRef={inputRef}
                    onChange={(event) => onInputChange(event.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                    onClick={() => {
                                        if (inputRef.current) {
                                            inputRef.current.value = '';
                                        }
                                        onInputChange('');
                                        clearSearch?.();
                                    }}
                                    onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                                        event.preventDefault();
                                    }}
                                >
                                    <CloseSharp />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button
                    data-testid={`input-button-${id}`}
                    id={`input-button-${id}`}
                    onClick={() => handleSearchClick()}
                    sx={{ height: '34px' }}
                >
                    <Search />
                </Button>
            </Stack>
        </>
    );
};
