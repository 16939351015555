import {
    CLEAR_FLIP_CACHE,
    SET_FLIP,
    SAVE_FLIP_SOURCE,
    SAVE_DOCUMENT_FLIP_LINE_ITEMS,
} from '../../actions/document/documentCreationActions';
import { USER_LOGIN_FAILED, USER_LOGIN_PENDING, USER_LOGIN_SUCCESS, USER_LOGOUT } from '../../actions/userlogin';

const initialState = {
    sourceId: null,
    sourceBusinessDocTypePathName: null,
    lineItems: {},
    isFlip: false,
};
export default (state = initialState, { type, payload }) => {
    switch (type) {
        case USER_LOGIN_FAILED:
        case USER_LOGIN_PENDING: {
            // if it was timeout retrun currenState, otherwhise initialState
            const { timeout } = state;
            return timeout && timeout === true ? state : initialState;
        }

        case CLEAR_FLIP_CACHE:
        case USER_LOGIN_SUCCESS: {
            // if it was timeout retrun currenState, otherwhise initialState
            const { timeout, ...currentState } = state;
            return timeout && timeout === true ? currentState : initialState;
        }

        case USER_LOGOUT: {
            const { timeout } = payload;
            return timeout && timeout == true ? state : initialState;
            // return {
            //     ...initialState
            // };
        }
        case SAVE_DOCUMENT_FLIP_LINE_ITEMS:
            return {
                ...state,
                lineItems: payload,
            };

        case SAVE_FLIP_SOURCE:
            return {
                ...state,
                sourceId: payload.documentId,
                sourceBusinessDocType: payload.businessDocType,
            };

        case SET_FLIP:
            return {
                ...state,
                isFlip: payload,
            };
        default:
            return state;
    }
};
