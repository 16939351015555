import { useFeatureAppState } from './FeatureAppState';
import FeatureList from './FeatureList';
import Feature from './Feature';

export default function FeatureManager() {
    const { featureStates } = useFeatureAppState();

    return (
        <>
            <p>
                Enabling / Disabling features below will only affect the application on your browser. Set a feature's
                "Your Setting" to "Enabled" or "Disabled" to override the release settings for that feature.
            </p>
            <p>
                <i>
                    Important: Features may have dependencies on other features. All of a feature's dependencies must be
                    enabled in order for it to be enabled. Hover over a "Feature Key" to see its dependencies.
                </i>
            </p>
            <FeatureList>
                {featureStates.map((feature) => (
                    <Feature key={feature.featureKey} feature={feature} />
                ))}
            </FeatureList>
        </>
    );
}
