import { lazy, ComponentType } from 'react';

export type ExportNamesMatching<T, V> = { [K in keyof T]: T[K] extends V ? K : never }[keyof T];
export type NamedComponentType<U, K extends keyof U> = U[K] extends ComponentType<any> ? U[K] : never;

/**
 * A wrapper around `React.lazy` that works for named exports since our codebase uses named exports.
 *
 * @param factory a function that asynchronously loads a module with named exports
 * @param componentExportName an export name from the module loaded by `factory` whose value is a React Component
 * @returns a component that lazy loads the referenced component when rendered.
 */
export function lazyNamed<U, K extends ExportNamesMatching<U, ComponentType<any>>>(
    factory: () => Promise<U>,
    componentExportName: K
) {
    return lazy(() =>
        factory().then((x) => ({
            default: x[componentExportName] as any as NamedComponentType<U, K>,
        }))
    );
}
