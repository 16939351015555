import { QueryClient } from '@tanstack/react-query';

export default function createQueryClient() {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    });

    return queryClient;
}
