import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { isExternal } from '../../../../utils';

interface IProps {
    to: string;
}

const DashboardIcon: FC<IProps> = ({ to, children }) => {
    const [state, setState] = useState<string>('');

    const onMouseOver = () => {
        setState('over');
    };

    const onMouseOut = () => {
        setState('out');
    };

    const className = `btn-animation ${state}`;

    return (
        <Link
            to={to}
            className={className}
            onMouseEnter={onMouseOver}
            onFocus={onMouseOver}
            onMouseLeave={onMouseOut}
            onBlur={onMouseOut}
            // conditionally adds target _blank property
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(isExternal(to)
                ? {
                      target: '_blank',
                  }
                : {})}
        >
            {children}
        </Link>
    );
};

export default DashboardIcon;
