import { ReactNode } from 'react';
import {
    SearchPageTemplate,
    SearchPageTemplateOnNewClick,
    SearchPageTemplateOnRowClick,
} from '../../../reusableFeatures';
import { IUserGridItem } from '../../../services';
import { FCNC } from '../../../ui';
import { UsersSearchServiceLocalization, useUsersSearchService } from './UsersSearchService';

export type IUsersListSearchPageProps = {
    portal: 'buyer' | 'supplier';
    onRowClick: SearchPageTemplateOnRowClick<IUserGridItem>;
    onNewClick: SearchPageTemplateOnNewClick;
    instructions: ReactNode;
    localization: UsersSearchServiceLocalization;
};

export const UsersSearchPage: FCNC<IUsersListSearchPageProps> = (props) => {
    const { onRowClick, onNewClick, instructions, portal, localization } = props;

    const usersSearchService = useUsersSearchService(portal, localization);

    return (
        <SearchPageTemplate
            testId="user-datagrid"
            entityType="User"
            searchService={usersSearchService}
            onRowClick={onRowClick}
            onNewClick={onNewClick}
            instructions={instructions}
            createPermission="ManageUserSettings.CreateUser"
        />
    );
};
