import { IInputFieldDescriptor } from '../types';
import { IFieldProps } from './IFieldProps';
import FieldComponents from './FieldComponents';

/**
 * Renders the correct type of input field
 */
export function RenderField<RawType>({ field, ...props }: IFieldProps<RawType, IInputFieldDescriptor<RawType>>) {
    const Component = FieldComponents[field.type];
    return <Component {...props} field={field} />;
}

export default RenderField;
