import { push } from 'connected-react-router';
import { ProfileThunk, CompanyThunk } from 'common/build/legacy/transcepta-thunks';

import * as profilesActions from '../actions/profile/ProfilesActions';
import * as contactActions from '../actions/contact';
import { apiErrorAction } from '../actions/error';
import projectConfig from '../project.config.json';
import R from '../routes';
import stateListSelector from '../selectors/common/stateListSelector';
import { fetchVatArchiveServiceRecords, fetchCompany } from '../thunk/CompanyThunk';
import { cloneObjectHack } from '../utils/dataConverter';
import { determineContentType } from '../utils/files';
import {
    newProfileEntity,
    newProfileVatArchiveServiceEntity,
    validateProfile,
    newProfileDeliveryMethod,
    newProfileDeliveryFileType,
    getStandardStyleSheetXsltName,
    convertPurchaseOrderFileFormatToProfileDeliveryFileType,
    validateAccountProfile,
} from '../utils/profile';
import { pushAlert, clearAlerts } from '../actions/alerts';
import { portalUserService } from 'common';

export const fetchProfiles = (profileType) => async (dispatch, getState) => {
    const params = {
        companyId: portalUserService.getCurrentCompanyId(),
        profileType: profileType,
    };
    dispatch(profilesActions.searchProfiles(params));

    let profileThunk = new ProfileThunk();
    let response = await profileThunk.fetchProfiles(params);
    let data = await response.data;
    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, profilesActions.SEARCH_PROFILES_FAILED, params, false));
    } else {
        try {
            if (Array.isArray(data) && data.length > 0) {
                dispatch(profilesActions.searchProfilesSuccessAction(data));
            } else {
                throw new SyntaxError('Dates is not correct');
            }
        } catch (error) {
            console.warn(error);
        }
    }
};

export const readProfile = (profileId, profileType) => async (dispatch, getState) => {
    const params = {
        id: profileId,
        profileType: profileType,
    };

    dispatch(profilesActions.searchProfiles(params));

    let profileThunk = new ProfileThunk();
    let response = await profileThunk.fetchProfile(params);
    let data = await response.data;
    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, profilesActions.SEARCH_PROFILES_FAILED, params, false));
    } else {
        try {
            if (Array.isArray(data) && data.length > 0) {
                dispatch(profilesActions.searchProfilesSuccessAction(data));
            } else {
                throw new SyntaxError('Dates is not correct');
            }
        } catch (error) {
            console.warn(error);
        }
    }
};

export const fetchCompanyProfiles = (profileType) => async (dispatch, getState) => {
    const params = {
        companyId: portalUserService.getCurrentCompanyId(),
        profileType: profileType,
    };
    dispatch(profilesActions.fetchCompanyProfilesExecute(params));

    let profileThunk = new ProfileThunk();
    let response = await profileThunk.fetchProfiles(params);
    let data = await response.data;
    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, profilesActions.FETCH_COMPANY_PROFILES_FAILURE, params));
    } else {
        dispatch(profilesActions.fetchCompanyProfilesSuccess(data, profileType));
    }
};

export const updateCompanyProfilesPage = (profileType, recordsToSkip) => (dispatch, getState) => {
    const page = recordsToSkip / projectConfig.docPageSize;
    dispatch(profilesActions.updateCompanyProfilesPage(profileType, page));
};

export const sortCompanyProfiles = (profileType, sortKey) => (dispatch, getState) => {
    const state = getState();
    const currentSortKey = state.company.supplierCompany.profiles[profileType].sortKey;
    const currentSortDirection = state.company.supplierCompany.profiles[profileType].sortDirection;

    let sortDirection = 'ASC';

    if (currentSortKey && currentSortKey === sortKey) {
        if (currentSortDirection.toUpperCase() === 'ASC') {
            sortDirection = 'DESC';
        }
    }

    dispatch(profilesActions.updateCompanyProfilesSort(profileType, sortKey, sortDirection));
};

export const initializeSelectedProfile = (profileType, profileId) => async (dispatch, getState) => {
    let state = getState();

    const standardStyleSheetList = state.userlogin.standardStyleSheetList;
    const businessDocType =
        profileType === projectConfig.profileType.Sales ? projectConfig.businessDocType.PurchaseOrder : null;

    let selectedProfile;
    let selectedProfileVatArchiveServiceRecord = null;
    const isInvoice = profileType === projectConfig.profileType.Invoice;

    if (isInvoice) {
        if (
            !state.company.supplierCompany.vatArchiveServiceRecords ||
            state.company.supplierCompany.vatArchiveServiceRecords.length === 0
        ) {
            await dispatch(fetchVatArchiveServiceRecords(portalUserService.getCurrentCompanyId()));
            state = getState();
        }
    }

    if (profileId) {
        if (isInvoice) {
            selectedProfileVatArchiveServiceRecord = cloneObjectHack(
                state.company.supplierCompany.vatArchiveServiceRecords.find(
                    (record) => record.InvoiceProfileId === profileId
                )
            );
        }
        if (
            !state.company.supplierCompany.profiles[profileType].records ||
            state.company.supplierCompany.profiles[profileType].records.length === 0
        ) {
            await dispatch(fetchCompanyProfiles(profileType));
            state = getState();
        }
        selectedProfile = cloneObjectHack(
            state.company.supplierCompany.profiles[profileType].records.find((profile) => profile.Id === profileId)
        );
        selectedProfile.EmailRepeat = selectedProfile.Email;
        selectedProfile.InvoiceConfirmationEmailRepeat = selectedProfile.InvoiceConfirmationEmail;
        selectedProfile.Rowversion = null;
    } else {
        const businessDocType =
            profileType === projectConfig.profileType.Sales ? projectConfig.businessDocType.PurchaseOrder : null;
        const companyId = portalUserService.getCurrentCompanyId();
        if (!state.company.supplierCompany.accountInfo) {
            await dispatch(fetchCompany(companyId));
            state = getState();
        }
        const company = state.company.supplierCompany.accountInfo
            ? state.company.supplierCompany.accountInfo
            : { ID: companyId };

        selectedProfile = newProfileEntity(company, profileType, businessDocType);
        selectedProfileVatArchiveServiceRecord = newProfileVatArchiveServiceEntity(company);
    }

    // Determine if the delivery method will be editable in the UI. We need to calculate this here in the initialization.
    // Otherwise, values that the user might choose could make it uneditable.
    selectedProfile.DeliveryMethodIsEditable =
        state.userlogin.menuSettings.EnablePODelivery && profileType === projectConfig.profileType.Sales;

    dispatch(profilesActions.selectedProfileSet(selectedProfile, selectedProfileVatArchiveServiceRecord));
};

export const clearSelectedProfile = () => (dispatch, getState) => {
    dispatch(profilesActions.selectedProfileClear());
};

export const saveSelectedProfile =
    (profileType, navigate = true) =>
    async (dispatch, getState) => {
        let state = getState();
        const selectedProfile = cloneObjectHack(state.company.supplierCompany.profiles.selectedProfile);
        const selectedProfileVatArchiveService = cloneObjectHack(
            state.company.supplierCompany.profiles.selectedProfileVatArchiveService
        );

        if (
            !state.company.supplierCompany.profiles[profileType].records ||
            state.company.supplierCompany.profiles[profileType].records.length === 0
        ) {
            await dispatch(fetchCompanyProfiles(profileType));
            state = getState();
        }
        const existingProfiles = state.company.supplierCompany.profiles[profileType].records;

        const validationErrors = validateProfile(
            profileType,
            selectedProfile,
            selectedProfileVatArchiveService,
            existingProfiles
        );
        if (validationErrors.length > 0) {
            dispatch(profilesActions.setSelectedProfileValidationErrors(validationErrors));
        } else {
            const selectedProfileLogo = state.company.supplierCompany.profiles.selectedProfileLogo.file;
            const updating = selectedProfile.Id > 0;

            let formData = new FormData();
            if (selectedProfileLogo !== null) {
                const contentType = determineContentType(selectedProfileLogo.name);

                formData.append('Data', JSON.stringify(selectedProfile));
                formData.append(
                    'File',
                    new Blob([selectedProfileLogo], { type: contentType }),
                    selectedProfileLogo.name
                );
            } else {
                formData = selectedProfile;
            }
            if (!getState().userlogin.menuSettings.EnablePODelivery) {
                delete formData.ProfileDeliveryMethods;
            }
            let params = updating
                ? {
                      profileFormData: formData,
                      profileId: selectedProfile.Id,
                  }
                : {
                      profileFormData: formData,
                  };
            dispatch(profilesActions.saveSelectedProfileExecute(params));

            const profileThunk = new ProfileThunk();
            const response = updating
                ? await profileThunk.updateProfile(params)
                : await profileThunk.createProfile(params);
            const data = await response.data;
            if (response.type && response.type === 'error') {
                dispatch(apiErrorAction(response.text, profilesActions.SAVE_SELECTED_PROFILE_FAILURE, params));
            } else {
                dispatch(contactActions.clearState());
                dispatch(profilesActions.saveSelectedProfileSuccess(data));
                dispatch(fetchCompanyProfiles(profileType));
                if (profileType === projectConfig.profileType.Invoice) {
                    dispatch(saveSelectedProfileVatArchiveService(profileType, data.ID));
                } else {
                    if (navigate) {
                        dispatch(navigateToManageProfiles(profileType));
                    }
                }
            }
        }
    };

const saveSelectedProfileVatArchiveService = (profileType, invoiceProfileId) => async (dispatch, getState) => {
    const state = getState();

    if (state.company.supplierCompany.profiles.selectedProfileVatArchiveService) {
        const selectedProfileVatArchiveService = cloneObjectHack(
            state.company.supplierCompany.profiles.selectedProfileVatArchiveService
        );
        const updating = selectedProfileVatArchiveService.ID > 0;

        const params = updating
            ? {
                  vatArchiveService: selectedProfileVatArchiveService,
                  vatArchiveServiceId: selectedProfileVatArchiveService.ID,
              }
            : {
                  vatArchiveService: selectedProfileVatArchiveService,
              };

        dispatch(profilesActions.saveSelectedProfileVatArchiveServiceExecute(params));

        const companyThunk = new CompanyThunk();
        const response = updating
            ? await companyThunk.updateVATArchiveService(params)
            : await companyThunk.createVATArchiveService(params);
        const data = await response.data;
        if (response.type && response.type === 'error') {
            dispatch(
                apiErrorAction(response.text, profilesActions.SAVE_SELECTED_PROFILE_VAT_ARCHIVE_SERVICE_FAILURE, params)
            );
        } else {
            dispatch(profilesActions.saveSelectedProfileVatArchiveServiceSuccess(data));
            dispatch(fetchVatArchiveServiceRecords(portalUserService.getCurrentCompanyId()));
            dispatch(navigateToManageProfiles(profileType));
        }
    } else {
        dispatch(navigateToManageProfiles(profileType));
    }
};

export const updateSelectedProfile = (fieldKey, value, profileType) => async (dispatch, getState) => {
    let state = getState();
    const selectedProfile = cloneObjectHack(state.company.supplierCompany.profiles.selectedProfile);
    let selectedProfileVatArchiveService = cloneObjectHack(
        state.company.supplierCompany.profiles.selectedProfileVatArchiveService
    );

    if (fieldKey === 'VatRegistrationNumber') {
        if (!selectedProfileVatArchiveService) {
            const companyId = portalUserService.getCurrentCompanyId();
            if (!state.company.supplierCompany.accountInfo) {
                await dispatch(fetchCompany(companyId));
                state = getState();
            }
            const company = state.company.supplierCompany.accountInfo
                ? state.company.supplierCompany.accountInfo
                : { ID: companyId };

            selectedProfileVatArchiveService = newProfileVatArchiveServiceEntity(company, profileType);
        }

        selectedProfileVatArchiveService.VATNumber = value;
    } else if (
        fieldKey === 'DeliveryMethod' ||
        fieldKey === 'DeliveryEmail' ||
        fieldKey === 'FileType' ||
        fieldKey === 'DeliveryName' ||
        fieldKey === 'DeliveryPhone'
    ) {
        const businessDocType =
            profileType === projectConfig.profileType.Sales ? projectConfig.businessDocType.PurchaseOrder : null;

        if (!selectedProfile.ProfileDeliveryMethods || selectedProfile.ProfileDeliveryMethods.length === 0) {
            selectedProfile.ProfileDeliveryMethods = [newProfileDeliveryMethod(businessDocType)];
        }

        const enabledDeliveryMethod = selectedProfile.ProfileDeliveryMethods.filter(
            (deliveryMethod) => deliveryMethod.IsEnabled && deliveryMethod.BusinessDocType === businessDocType
        )[0];

        if (fieldKey === 'DeliveryMethod') {
            if (enabledDeliveryMethod[fieldKey] !== value) {
                enabledDeliveryMethod.EmailAddress = '';
            }

            enabledDeliveryMethod[fieldKey] = value;
        } else if (fieldKey === 'DeliveryEmail') {
            enabledDeliveryMethod.EmailAddress = value;
        } else if (fieldKey === 'FileType') {
            if (
                !enabledDeliveryMethod.ProfileDeliveryFileTypes ||
                enabledDeliveryMethod.ProfileDeliveryFileTypes.length === 0
            ) {
                enabledDeliveryMethod.ProfileDeliveryFileTypes = [newProfileDeliveryFileType()];
            }

            const enabledDeliveryFileType = enabledDeliveryMethod.ProfileDeliveryFileTypes.filter(
                (fileType) => fileType.IsEnabled
            )[0];

            enabledDeliveryFileType.Xslt = getStandardStyleSheetXsltName(value, state.userlogin.standardStyleSheetList);
            enabledDeliveryFileType[fieldKey] = convertPurchaseOrderFileFormatToProfileDeliveryFileType(value);
        } else if (fieldKey === 'DeliveryName') {
            enabledDeliveryMethod.Name = value;
        } else if (fieldKey === 'DeliveryPhone') {
            enabledDeliveryMethod.Phone = value;
        } else {
            enabledDeliveryMethod[fieldKey] = value;
        }
    } else {
        if (fieldKey === 'Country') {
            const stateList = stateListSelector(state);
            const oldStateList = stateList.filter((state) => state.countryCode === selectedProfile[fieldKey]);
            const newStateList = stateList.filter((state) => state.countryCode === value);

            if (
                (oldStateList.length > 0 || newStateList.length > 0) &&
                newStateList.filter((state) => state.value === selectedProfile.State).count === 0
            ) {
                selectedProfile.State = null;
            }
        } else if (fieldKey === 'Currency') {
            value = Number(value);

            if (value === 0) {
                selectedProfile['Currency_US'] = 'Multiple';
            } else {
                const selectedCurrency = state.userlogin.currencyList
                    ? state.userlogin.currencyList.find((currency) => currency.CurrencyType === value)
                    : null;
                const currencyTypeName = selectedCurrency ? selectedCurrency.CurrencyType_US : '';

                selectedProfile['Currency_US'] = currencyTypeName;
            }
        } else if (fieldKey === 'ContactName') {
            dispatch(contactActions.updateContact(value));
        } else if (fieldKey === 'ContactPhone') {
            dispatch(contactActions.updatePhone(value));
        } else if (fieldKey === 'ContactEmail') {
            dispatch(contactActions.updateEmail(value));
        } else if (fieldKey === 'PODeliveryMethodStatus') {
            let status_us = Object.keys(projectConfig.poDeliveryMethodStatus).find(
                (key) => projectConfig.poDeliveryMethodStatus[key] === value
            );
            selectedProfile['PODeliveryMethodStatus'] = value;
            selectedProfile['PODeliveryMethodStatus_US'] = status_us;
        }

        selectedProfile[fieldKey] = value;
    }

    dispatch(profilesActions.selectedProfileSet(selectedProfile, selectedProfileVatArchiveService));
};

export const updateSelectedProfileLogo = (file) => (dispatch, getState) => {
    // FileReader support
    let url = null;
    if (FileReader) {
        const fileReader = new FileReader();
        fileReader.onload = () => {
            url = fileReader.result;
        };
        fileReader.readAsDataURL(file);
    }

    dispatch(profilesActions.selectedProfileLogoSet(file, url));
};

export const navigateToManageProfiles = (profileType) => (dispatch, getState) => {
    dispatch(push(R.MANAGE_PROFILES.path.replace(':profileType', profileType)));
};

export const updateAccountInfoProfile = (fieldKey, value) => async (dispatch, getState) => {
    debugger;
    dispatch(clearAlerts());
    const state = getState();
    const accountInfo = cloneObjectHack(state.company.supplierCompany.accountInfo);
    const field = fieldKey.split('-');
    const prefix = field[0];
    const key = prefix === 'Main' ? field[1] : field[2];
    const isContact = prefix === 'Support';
    const index = prefix === 'Support' ? field[1] : null;
    switch (key) {
        case 'Name':
        case 'Address1':
        case 'Address2':
        case 'Address3':
        case 'City':
        case 'State':
        case 'PostalCode':
        case 'Phone':
        case 'Fax':
        case 'State':
            if (!isContact) {
                accountInfo[key] = value;
            } else {
                accountInfo.Contacts[index][key] = value;
            }
            break;
        case 'Country':
            const stateList = stateListSelector(state);
            const countryCode = !isContact ? accountInfo[key] : accountInfo.Contacts[index][key];
            const oldStateList = stateList.filter((state) => state.countryCode === countryCode);
            const newStateList = stateList.filter((state) => state.countryCode === value);
            if (oldStateList.length > 0 || newStateList.length > 0) {
                if (!isContact) {
                    accountInfo[key] = value;
                    accountInfo.State = null;
                } else {
                    accountInfo.Contacts[index][key] = value;
                    accountInfo.Contacts[index].State = null;
                }
            }
            break;
        case 'TIN':
        case 'DUNSNumber':
            accountInfo[key] = value;
            break;
        case 'Email':
        case 'ConfirmEmail':
            accountInfo.Contacts[index][key] = value;
            break;
    }
    dispatch(profilesActions.accountProfileSet(accountInfo));
};

export const saveAccountInfoProfile = () => async (dispatch, getState) => {
    const state = getState();
    const { company } = state;
    const accountInfo = company.supplierCompany.accountInfo;

    const validationErrors = validateAccountProfile(accountInfo);
    if (validationErrors.length > 0) {
        dispatch(profilesActions.accountProfileValidationErrors(validationErrors));
        return;
    }

    const {
        ID,
        Name,
        Address1,
        Address2,
        Address3,
        City,
        State,
        Country,
        Phone,
        Fax,
        TIN,
        DUNSNumber,
        PostalCode,
        Contacts,
    } = accountInfo;
    const contacts = Contacts.map((c) => {
        const { ID, Name, Address1, Address2, Address3, City, State, Country, Phone, Fax, PostalCode, Email } = c;
        return { ID, Name, Address1, Address2, Address3, City, State, Country, Phone, Fax, PostalCode, Email };
    });

    const params = {
        companyId: accountInfo.ID,
        ID,
        Name,
        Address1,
        Address2,
        Address3,
        TIN,
        DUNSNumber,
        City,
        State,
        Country,
        Phone,
        Fax,
        PostalCode,
        Contacts: contacts,
    };

    const companyThunk = new CompanyThunk();
    const response = await companyThunk.updateCompany(params);

    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, profilesActions.ACCOUNT_PROFILE_FAILED, params));
        return;
    }

    dispatch(navigateToSettings());
};

export const initializeAccountProfile = () => async (dispatch, getState) => {
    const state = getState();
    const {
        company: { supplierCompany },
    } = state;
    if (supplierCompany.accountInfo) {
        dispatch(profilesActions.accountProfileSet(addConfirmEmailFieldToContacts(supplierCompany.accountInfo)));
    }
};

export const navigateToSettings = () => (dispatch) => {
    dispatch(push(R.SETTINGS_ADMINISTRATION.path));
};

const addConfirmEmailFieldToContacts = (profile) => {
    const { Contacts, ...rest } = profile;
    return { ...rest, Contacts: [...Contacts.map((c) => ({ ...c, ConfirmEmail: c.Email }))] };
};
