import { FC, useState, ReactNode, FormEvent } from 'react';
import { Stack } from '@mui/material';
import { Button, TextField, LoadingMessage } from '../../../ui';
import { useHistory } from 'react-router-dom';
import { FormTemplate } from './FormTemplate';
import { useAuthService } from '../Auth';
import { useMutation } from '@tanstack/react-query';

export interface IForgotPasswordPageProps {
    loginRoute: string;
    marketingContent?: ReactNode;
}

export const ForgotPasswordPage: FC<IForgotPasswordPageProps> = ({ loginRoute, marketingContent }) => {
    const [email, setEmail] = useState('');
    const authService = useAuthService();
    const resetPasswordMutation = useMutation(authService.resetPassword);

    const history = useHistory();

    const handleReset = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        resetPasswordMutation.mutate(email);
    };

    return (
        <FormTemplate
            mainContent={
                resetPasswordMutation.isLoading ? (
                    <LoadingMessage>Reseting Password</LoadingMessage>
                ) : (
                    <form onSubmit={(e) => handleReset(e)}>
                        <Stack>
                            <TextField
                                stacked
                                required
                                id="transcepta-email"
                                data-testId="transcepta-email"
                                label="Email"
                                type="email"
                                autoComplete="email"
                                value={email}
                                onChange={(event) => {
                                    const element = event.target as HTMLInputElement;
                                    const newEmail = element.value as string;

                                    setEmail(newEmail);
                                }}
                                sx={{ m: 1, width: 'auto' }}
                            />
                            <Button variant="default" type="submit" sx={{ m: 1 }}>
                                Reset
                            </Button>
                            <Button
                                variant="secondary"
                                sx={{ m: 1 }}
                                onClick={() => {
                                    history.push(loginRoute);
                                }}
                            >
                                Back
                            </Button>
                        </Stack>
                    </form>
                )
            }
            marketingContent={marketingContent}
        />
    );
};
