import { SxProps, Theme } from '@mui/material';

export const hideTooltipIconWhenLabelNotFloating: SxProps<Theme> = {
    '.MuiInputLabel-root .TooltipLabel-icon': {
        display: 'none',
    },
    '.MuiInputLabel-shrink .TooltipLabel-icon': {
        display: 'inline',
    },
};
