import { pushAlert, clearAlerts } from './alerts';
import * as UserThunk from '../thunk/UserThunk';
import { strings } from '../utils/localization/LocalizationStrings';

export const FETCH_ALL_USERS = 'FETCH_ALL_USERS';
export const FETCH_ALL_USERS_ERROR = 'FETCH_ALL_USERS_ERROR';
export const FETCH_ALL_USERS_EXECUTE = 'FETCH_ALL_USERS_EXECUTE';
export const FETCH_ROLE_LIST = 'FETCH_ROLE_LIST';
export const FETCH_ROLE_LIST_ERROR = 'FETCH_ROLE_LIST_ERROR';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const UPDATE_USER = 'UPDATE_USER_ROLES';
export const SAVE_USER_FAILED = 'SAVE_USER_FAILED';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';
export const CHANGEPASSWORD_CANCEL = 'CHANGEPASSWORD_CANCEL';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';
export const USERS_SORT = 'USERS_SORT';
export const UPDATE_USER_SKIP = 'UPDATE_USER_SKIP';
export const USER_CONFIRM = 'USER_CONFIRM';
export const TOGGLE_PASSWORD_CHANGED = 'TOGGLE_PASSWORD_CHANGED';
export const FETCH_USERS_APPLY_SEARCH = 'FETCH_USERS_APPLY_SEARCH';

export const fetchAllUsers = (data) => ({
    type: FETCH_ALL_USERS,
    payload: data,
});

export const fetchAllUsersFailed = (err) => ({
    type: FETCH_ALL_USERS_ERROR,
    payload: err.response.data,
});

export const fetchAllUsersExecute = (params) => ({
    type: FETCH_ALL_USERS_EXECUTE,
    payload: {
        params: params,
    },
});

export const saveUserFailed = (err) => ({
    type: SAVE_USER_FAILED,
    payload: err.response.data,
});

export const fetchRoleList = (payload) => ({
    type: FETCH_ROLE_LIST,
    payload,
});

export const fetchRoleListError = (err) => ({
    type: FETCH_ROLE_LIST_ERROR,
    payload: err,
});

export const fetchUser = (payload) => ({
    type: FETCH_USER,
    payload,
});

export const updateUser = (payload) => ({
    type: UPDATE_USER,
    payload,
});

export const DeleteUserFailed = (err) => ({
    type: DELETE_USER_FAILED,
    payload: err.response.data,
});

export function changePasswordCancel() {
    return {
        type: CHANGEPASSWORD_CANCEL,
    };
}

export const upateUserSkip = (payload) => ({
    type: UPDATE_USER_SKIP,
    payload,
});

export function applySorting(column, config, SORTING_ACT) {
    return (dispatch) => {
        dispatch({
            type: SORTING_ACT,
            payload: {
                column,
                type: config.find((col) => col.key === column).type.name,
            },
        });
    };
}

export const validatePassword =
    (oldPassword, newPassword, newPasswordConfirmation, alertType) => (dispatch, getState) => {
        let state = getState();
        dispatch(clearAlerts());
        let catCounterCheck = 0;
        let errorCount = 0;
        let errorText = [];
        //contains lowercase
        if (/[a-z]/.test(newPassword)) {
            catCounterCheck++;
        }
        //contains uppercase
        if (/[A-Z]/.test(newPassword)) {
            catCounterCheck++;
        }
        //contains a numbers
        if (/[0-9]/.test(newPassword)) {
            catCounterCheck++;
        }
        //match a special character
        if (/[\!\@\#\$\%\^\&\*\(\)\_\+\|\-\=\{\}\[\]\:\"\;\'\<\>\/]/.test(newPassword)) {
            catCounterCheck++;
        }

        if (catCounterCheck < 3) {
            errorText.push(strings.textPasswordCategoryError);
            errorCount++;
        }
        if (!oldPassword) {
            errorText.push(strings.textMissingOldPassword);
            errorCount++;
        }
        if (newPassword !== newPasswordConfirmation) {
            errorText.push(strings.textBadPasswordConfirm);
            errorCount++;
        }
        if (oldPassword === newPassword) {
            errorText.push(strings.textOldPasswordMatches);
            errorCount++;
        }
        if (!newPassword) {
            errorText.push(strings.textMissingNewPassword);
            errorCount++;
        }
        if (newPassword.length < 7) {
            errorText.push(strings.textBadPasswordLength);
            errorCount++;
        }
        let message = errorText.join('\n');
        if (errorCount > 0) {
            dispatch(pushAlert({ type: alertType, text: message }));
        }
        return errorCount === 0;
    };

export const handlePasswordChange = (oldPassword, newPassword, newPasswordConfirmation) => (dispatch) => {
    let noErrors = dispatch(validatePassword(oldPassword, newPassword, newPasswordConfirmation, 'error'));
    if (noErrors) {
        dispatch(UserThunk.changePassword(oldPassword, newPassword, newPasswordConfirmation));
    }
};

export const confirmUser = (payload) => ({
    type: USER_CONFIRM,
    payload,
});

export const passwordChangedAlert = () => (dispatch) => {
    dispatch(pushAlert({ type: 'accepted', text: strings.textPasswordChanged }));
    dispatch(togglePasswordChanged());
};

export const togglePasswordChanged = () => (dispatch) => {
    dispatch({
        type: TOGGLE_PASSWORD_CHANGED,
    });
};

export const fetchUserApplySearch = (searchString) => ({
    type: FETCH_USERS_APPLY_SEARCH,
    payload: searchString,
});
