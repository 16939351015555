import { AxiosInstance } from 'axios';
import { WorkflowValidationData } from './WorkflowValidationData';
import { WorkflowValidationResultCollector } from './WorkflowValidationResultCollector';

import f, { ODataFilterBuilder } from 'odata-filter-builder';
import { getWorkflowValidationUtils } from './getWorkflowValidationUtils';

export class WorkflowValidationEnvironment {
    results: WorkflowValidationResultCollector;

    filterBuilder(...args: Parameters<typeof f>): ODataFilterBuilder {
        return f(...args);
    }

    utils = getWorkflowValidationUtils();

    constructor(public api: () => AxiosInstance, public readonly workflowData: WorkflowValidationData) {
        this.results = new WorkflowValidationResultCollector();
    }
}
