import { JSONSchemaType } from 'ajv';
import { ISearchFilter } from '../types';
import { createStringParser, createValidatorFromParser } from '../../../utils/dataParser';

/*
We cannot write a schema directly for ISearchFilter type because the ajv JSONSchemaType helper
cannot statically check that we have provided the correct types for "Status" and "not Status"
and "$filter" and "not $filter" in ISearchFilter. Because of this, we create an intermediate type
here that uses a union. The schema still checks that the respective properties have the correct
type, and we then assert the type of our result after parsing with the schema.

If you make modifications to the structure, you need to be careful to ensure that the schema still
checks the correct structure, otherwise the type assertion will be invalid and other code may
crash because it incorrectly then assumes an invalid type for parsed data!
*/
interface ISearchODataFilterFieldsStatusAJV {
    [status: string]: string;
}

interface ISearchODataFilterFieldsAJV {
    [key: string]: ISearchODataFilterFieldsStatusAJV | string;
}

interface ISearchODataFilterAJV {
    Prefix: string;
    ConcatenationString: 'and' | 'or';
    Default: string;
    Fields: ISearchODataFilterFieldsAJV;
}

interface ISearchFilterAJV {
    [key: string]: ISearchODataFilterAJV | string;
}

const searchODataFilterFieldsStatusAJVSchema: JSONSchemaType<ISearchODataFilterFieldsStatusAJV> = {
    type: 'object',
    patternProperties: {
        '.*': { type: 'string' },
    },
    required: [],
};

const searchODataFilterFieldsAJVSchema: JSONSchemaType<ISearchODataFilterFieldsAJV> = {
    type: 'object',
    patternProperties: {
        '^Status$': searchODataFilterFieldsStatusAJVSchema,
        '^(?!Status).*$': { type: 'string' },
    },
    required: [],
};

const searchODataFilterAJVSchema: JSONSchemaType<ISearchODataFilterAJV> = {
    type: 'object',
    properties: {
        Prefix: { type: 'string' },
        ConcatenationString: { type: 'string', enum: ['and', 'or'] },
        Default: { type: 'string' },
        Fields: searchODataFilterFieldsAJVSchema,
    },
    required: ['Prefix', 'ConcatenationString', 'Default', 'Fields'],
};

const searchFilterAJVSchema: JSONSchemaType<ISearchFilterAJV> = {
    type: 'object',
    patternProperties: {
        '^\\$filter$': searchODataFilterAJVSchema,
        '^[^$].*$': { type: 'string' },
    },
    required: ['$filter'],
};

// below is the parser that checks if data belongs to the intermediate type
const parseSearchFilterBase = createStringParser(searchFilterAJVSchema);

function parseSearchFilter(data: string, rootName: string) {
    return parseSearchFilterBase(data, rootName) as ISearchFilter;
}

export const validateSearchFilter = createValidatorFromParser(parseSearchFilter);
