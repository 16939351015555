import { BusinessDocType } from '../../../types';
import { ValidationResults } from '../proservContractTypes';
import { fetchValidationFailureTypes } from './fetchValidationFailureTypes';
import { createPluginServices } from '../PluginHost';
import { IErrorRenderer, ValidationFailureDictionary } from '../types/private';
import { StaticErrorRenderer } from './StaticErrorRenderer';
import DropdownOptionsRepository from '../PluginHost/services/dropdownOptionsRepository';

const pluginServices = createPluginServices('', new DropdownOptionsRepository());

export class DynamicErrorRenderer implements IErrorRenderer {
    constructor(
        private buyerCompanyId: number,
        private businessDocType: BusinessDocType,
        private baseValidationFailureDictionary: ValidationFailureDictionary = {}
    ) {}

    prefetchValidationFailureTypes() {
        // pre-fetch validation failure types before they are needed to speed up first document
        // validation
        this.fetchValidationFailureTypes();
    }

    async renderErrors(validationResponse: ValidationResults) {
        const fetchedFailureDictionary = await this.fetchValidationFailureTypes();

        const validationFailureDictionary = { ...this.baseValidationFailureDictionary, ...fetchedFailureDictionary };

        const staticErrorRenderer = new StaticErrorRenderer(validationFailureDictionary);

        return staticErrorRenderer.renderErrors(validationResponse);
    }

    private async fetchValidationFailureTypes() {
        return pluginServices.caching.fetch(
            ['ValidationFailureType', this.buyerCompanyId, this.businessDocType],
            () => fetchValidationFailureTypes(this.buyerCompanyId, this.businessDocType),
            {
                cacheTTl: 8 * 60 * 60 * 1000, // return cached entry for up to 8 hours
                staleTTL: 60 * 1000, // revalidate cached entry if it is older than 1 minute
                revalidate: true,
            }
        );
    }
}
