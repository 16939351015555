import axios from 'axios';
import { ReactNode } from 'react';
import { Box } from '@mui/system';
import { FCNC } from '../FCNC';
import { ServiceError } from './ServiceError';
import { ScrollToHere } from '../ScrollToHere';

export interface IErrorToErrorMessageProps {
    error: unknown;
    prompt?: string;
}

function extractAPIErrors(error: any): string[] {
    try {
        return error.response.data.errors.map((e: any) => e.Message);
    } catch (e) {
        return [];
    }
}

interface IErrorListProps {
    errors: ReactNode[];
    prompt: string;
}

const ErrorList: FCNC<IErrorListProps> = ({ prompt, errors }) => {
    return (
        <>
            <p>{prompt}:</p>
            <Box component="ul">
                {errors.map((e, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Box component="li" sx={{ listStyle: 'inside' }} key={i}>
                        {e}
                    </Box>
                ))}
            </Box>
        </>
    );
};

/**
 * Renders an error to an error message
 */
export const ErrorToErrorMessage: FCNC<IErrorToErrorMessageProps> = ({ error, prompt }) => {
    const defaultListPrompt = 'Please correct the errors below';
    const defaultSinglePrompt = 'An error ocurred';

    // we encountered an API error
    if (error instanceof Error && axios.isAxiosError(error) && extractAPIErrors(error).length > 0) {
        return (
            <ScrollToHere>
                <ErrorList prompt={prompt ?? defaultListPrompt} errors={extractAPIErrors(error)} />
            </ScrollToHere>
        );
    }

    if (error instanceof ServiceError) {
        return (
            <ScrollToHere>
                <ErrorList prompt={prompt ?? defaultListPrompt} errors={error.validationErrors} />
            </ScrollToHere>
        );
    }

    // other exception, return error message
    if (error instanceof Error) {
        return (
            <ScrollToHere>
                {prompt ?? defaultSinglePrompt}: {error.message}
            </ScrollToHere>
        );
    }

    // don't know how to handle anything else
    return null;
};

/**
 * Uses `ErrorToErrorMessage` to render the error object if it is truthy, otherwise returns null.
 *
 * Can be used in conjunction with our Form component to set its error message from a nullable error object variable.
 *
 * @param error the nullable error object
 * @returns the rendered error message or null
 */
export function renderErrorToErrorMessage(error: unknown): ReactNode | null {
    if (!error) {
        return null;
    }

    return <ErrorToErrorMessage error={error} />;
}
