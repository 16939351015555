import { AxiosInstance, AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';

class NormalizedApiError extends Error implements AxiosError {
    public isAxiosError = true;

    constructor(readonly config: AxiosRequestConfig, readonly response: AxiosResponse) {
        super('NormalizedApiError');

        Object.setPrototypeOf(this, NormalizedApiError.prototype);
    }

    // eslint-disable-next-line class-methods-use-this
    toJSON() {
        return {};
    }
}

const handlers = [
    {
        match: (response: AxiosResponse) =>
            Array.isArray(response.data.Exceptions) && response.data.Exceptions.length > 0,
        transform: (response: AxiosResponse) => ({
            ...response,
            status: 400,
            data: {
                errors: response.data.Exceptions,
            },
        }),
    },
];

export function apiErrorNormalizer(instance: AxiosInstance) {
    instance.interceptors.response.use((response) => {
        // find responses that API said were successful, but match an "error pattern". transform these
        // to the standard error response.
        if (response.status >= 200 && response.status < 300) {
            let transformedErrorResponse: AxiosResponse | null = null;
            for (const handler of handlers) {
                try {
                    if (handler.match(response)) {
                        transformedErrorResponse = handler.transform(response);
                        break;
                    }
                } catch (e) {}
            }

            if (transformedErrorResponse) {
                throw new NormalizedApiError(transformedErrorResponse.config, transformedErrorResponse);
            }
        }

        return response;
    });
}
