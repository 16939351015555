import { isEqual, isPlainObject } from 'lodash';
import { isPlainObjectArrayOrNestedArray } from './array';
import { RecursivePartial } from '../types';

function isObject(o: any) {
    return o != null && typeof o === 'object';
}

function isEmptyObject(o: any) {
    return isObject(o) && Object.keys(o).length === 0;
}

function isEmptyDiff(difference: any, initial: any, updated: any) {
    return (
        isPlainObject(difference) && isEmptyObject(difference) && (isEmptyObject(initial) || !isEmptyObject(updated))
    );
}

function deepDiffObjectsImpl(initial: any, updated: any) {
    // if objects are referentially equal, there is no diff
    if (initial === updated) {
        return {};
    }

    // if both are arrays of same length, check recursively if they are equal, in which case diff is empty
    if (
        Array.isArray(initial) &&
        Array.isArray(updated) &&
        initial.length === updated.length &&
        isEqual(initial, updated)
    ) {
        return {};
    }

    // if either is not a plain object or an array composed of plain objects or arrays, diff is updated value
    if (
        (!isPlainObject(initial) || !isPlainObject(updated)) &&
        (!isPlainObjectArrayOrNestedArray(initial) || !isPlainObjectArrayOrNestedArray(updated))
    ) {
        return updated;
    }

    const diff: any = {};
    const allKeys = new Set([...Object.keys(initial), ...Object.keys(updated)]);
    allKeys.forEach((key) => {
        // handle deleted keys in new object
        if (key in initial && !(key in updated)) {
            diff[key] = undefined;
            return;
        }

        // otherwise deep diff the property values
        const difference = deepDiffObjectsImpl(initial[key], updated[key]);

        // if there were no differences found, do not store the diff for this property
        if (isEmptyDiff(difference, initial[key], updated[key])) {
            return;
        }

        diff[key] = difference;
    });

    return diff;
}

export function deepDiffObjects<T>(initial: T, updated: T): RecursivePartial<T> {
    return deepDiffObjectsImpl(initial, updated);
}
