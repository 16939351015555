import { api } from './api';
import apiPaths from './paths';

class HelpResourceThunk {
    constructor() {}

    fetchCategories = (params) => {
        return api()
            .get(apiPaths.fetchCategories, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchHelpResources = (params) => {
        return api()
            .get(apiPaths.fetchHelpResources, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchHelpResourceSearch = (params) => {
        return api()
            .get(apiPaths.fetchHelpResourcesSearch, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };
}

export default HelpResourceThunk;
