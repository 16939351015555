import JSONParserFacade from './JSONParserFacade';
import { createConverter } from './types';

/*
This module exports the default JSON parser used in our application. Add
converters for any data type that needs to be serialized below to enable
serialization of the data type throughout the application.
*/

/**
 * Our default parser that can serialize the complex
 * data types we use.
 */
const defaultParser = new JSONParserFacade([
    createConverter<Date>({
        typeKey: 'ES.Date',
        constructor: Date,
        serialize(value: Date) {
            return value.getTime();
        },
        parse(value: any) {
            return new Date(value);
        },
    }),
    createConverter<Map<any, any>>({
        typeKey: 'ES.Map',
        constructor: Map,
        serialize(value: Map<any, any>) {
            return Array.from(value);
        },
        parse(value: any) {
            return new Map(value);
        },
    }),
]);

export default defaultParser;
