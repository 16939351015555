import {
    ISearchConfiguration,
    ISearchRequest,
    ISearchResponse,
    ISearchService,
    createUseSearchService,
    createAutocompleteField,
    createDropdownField,
    createTextField,
} from '../../../reusableFeatures';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid';
import { ICompanyGLAccountStructureSearchItems } from './types';
import { CompanyGLAccountStructureService } from './index';
import { paginate } from '../../../utils';
import { orderBy } from '../../utils';

import { CompanyService } from '../../Company';
import { Typography } from '@mui/material';

export class CompanyGLAccountStructureSearchService implements ISearchService<ICompanyGLAccountStructureSearchItems> {
    private glAccountStructureService = new CompanyGLAccountStructureService();

    async fetchSearchConfiguration(): Promise<ISearchConfiguration<ICompanyGLAccountStructureSearchItems>> {
        const companyService = new CompanyService();
        const allCompanyOptions = await companyService.getAllBuyerCompanyOptions();

        const gridColumns: GridColumns = [
            { field: 'name', headerName: 'Structure Name', flex: 1, sortable: true },
            {
                field: 'companyId',
                headerName: 'Company',
                minWidth: 200,
                flex: 1,
                renderCell: (params: GridRenderCellParams<number>) => {
                    const comp = allCompanyOptions.find(({ value }) => value === params.value);
                    const companyName = comp?.label ? comp.label : `[${params.value}]`;

                    return params.value ? <Typography>{companyName}</Typography> : null;
                },
                sortable: false,
            },
            {
                field: 'active',
                headerName: 'Active',
                flex: 1,
                renderCell: (params: GridRenderCellParams<boolean>) => (
                    <>{params.value ? <Typography>Yes</Typography> : <Typography>No</Typography>}</>
                ),
                sortable: false,
            },
        ];

        const searchFormFieldColumns = [
            [
                createTextField({
                    id: 'companyGLAccountStructure-name',
                    label: 'Structure Name',
                    defaultValue: '',
                    dataBindingKey: 'name',
                }),
                createAutocompleteField({
                    id: 'companyGLAccountStructure-companyId',
                    label: 'Company',
                    defaultValue: null,
                    dataBindingKey: 'companyId',
                    options: allCompanyOptions,
                }),
                createDropdownField({
                    id: 'companyGLAccountStructure-active',
                    label: 'Active',
                    defaultValue: null,
                    dataBindingKey: 'active',
                    options: [
                        { label: 'Yes', value: true },
                        { label: 'No', value: false },
                    ],
                }),
            ],
        ];

        return {
            gridColumns,
            enabledExportingMethods: [],
            searchFormFieldColumns,
            pageSizeOptions: [10, 25, 50],
            defaultPageSize: 10,
            searchWithNoCriteria: true,
        };
    }

    async fetchResults(
        _searchConfiguration: ISearchConfiguration<ICompanyGLAccountStructureSearchItems>,
        searchRequest: ISearchRequest<ICompanyGLAccountStructureSearchItems>
    ): Promise<ISearchResponse> {
        const { searchQuery, sort } = searchRequest;

        const response = await this.glAccountStructureService.getCompanyGLAccountStructureGrid(
            {
                name: searchQuery.state.name,
                companyId: searchQuery.state.companyId,
                active: searchQuery.state.active,
            },
            orderBy(sort)
        );

        if (!response) {
            return {
                pageResults: [],
                totalResultCount: 0,
            };
        }

        const filtered = [...response];

        return {
            pageResults: paginate(filtered, searchRequest.pageSize, searchRequest.pageNumber),
            totalResultCount: filtered.length,
        };
    }

    exportData(): Promise<void> {
        throw new Error('Exporting not implemented for company gl account structure search');
    }

    get key(): string {
        return 'CompanyGLAccountStructureSearch';
    }
}

export const useCompanyGLAccountStructureSearchService = createUseSearchService(CompanyGLAccountStructureSearchService);
