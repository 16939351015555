import { useCallback, useEffect, useState, useRef } from 'react';
import { ValidationFunctionProgrammingError } from './errors';
import { DocumentValidationEvent } from '../proservContractTypes';
import {
    createIdleState,
    createUninitializedState,
    createValidatingState,
    createValidationFailedState,
    createValidationFunctionInvalidResponseState,
    createValidationImplementationErrorState,
    createValidationSucceededState,
    defaultFieldControlState,
    DocumentEditValidationState,
    DocumentEditValidator,
    IFieldControlStates,
    ValidatorResultState,
    createExecutingDocumentControllersState,
    IInternalRule,
    SetDocumentState,
} from '../types/private';
import { useValidationService } from '../ValidationService';
import { createMessageClasses } from './createMessageClasses';
import { useDropdownOptionsRepository } from '../DropdownOptionsRepositoryContext';

function nextEventLoop() {
    return new Promise((r) => setTimeout(r));
}

/**
 * High level hook for implementing the current UI requirements for document edit validations.
 *
 * Suitable for use in components.
 *
 * Returns an object that allows running validations and retrieving the current state. See
 * `DocumentValidatorWithState` for more information on the returned object.
 *
 * All arguments to this hook must be stable references to ensure optimal performance.
 *
 * @param getDocumentState gets the state of the document that is being validated
 * @returns an object to run validations.
 */
export default function useDocumentEditValidatorState(
    getDocumentState: () => DocumentEditValidationState | undefined,
    setDocumentState: SetDocumentState,
    getInternalRules: () => IInternalRule[] | undefined
): DocumentEditValidator {
    const documentState = getDocumentState();

    const dropdownOptionsRepository = useDropdownOptionsRepository();

    const { validatorState, validationService, configuration } = useValidationService(
        documentState,
        getInternalRules,
        dropdownOptionsRepository
    );
    const { errorRenderer } = configuration;
    const [validatorResultState, setValidatorResultState] = useState<ValidatorResultState>(createUninitializedState());
    const [fieldControlStates, setFieldControlStates] = useState<IFieldControlStates>({});
    const deferredEvent = useRef<DocumentValidationEvent | null>(null);
    const [executionCompleted, setExecutionCompleted] = useState(false);

    const validateEvent = useCallback(
        async (event: DocumentValidationEvent) => {
            if (validatorState.type !== 'ReadyState') {
                return false;
            }
            if (validatorResultState.type === 'ValidatingState') {
                return false;
            }
            if (!validationService.isValidationNeeded(event)) {
                return true;
            }

            setValidatorResultState(createValidatingState());

            // Note: This is very important for performance. This allows us to ensure control is yielded back
            // to the browser layout engine for a repaint after the React render triggered above. This will ensure
            // visual updates are displayed to the user before we continue and run the validations.
            await nextEventLoop();

            try {
                const results = await validationService.validate({
                    ...getDocumentState()!,
                    event: event as any,
                });

                const renderedResults = await errorRenderer.renderErrors(results);

                const messageClasses = createMessageClasses(renderedResults);

                if (results.filter((x) => x.type === 'ErrorResult').length > 0) {
                    setValidatorResultState(createValidationFailedState(messageClasses));
                    return false;
                }

                setValidatorResultState(createValidationSucceededState(messageClasses));
                return true;
            } catch (e) {
                if (e instanceof ValidationFunctionProgrammingError) {
                    setValidatorResultState(createValidationFunctionInvalidResponseState());
                    return false;
                }

                console.error(
                    'An unhandled error occurred in the document validator implementation. This should be reported to customer support.',
                    e
                );
                setValidatorResultState(createValidationImplementationErrorState());
                return false;
            }
        },
        [
            errorRenderer,
            validationService,
            getDocumentState,
            validatorState,
            validatorResultState,
            setValidatorResultState,
        ]
    );

    const executeDocumentControllers = useCallback(async () => {
        const context = getDocumentState();

        // not expected, but TS doesn't know
        if (!context) {
            return false;
        }

        // can't run controllers if the config isn't done loading
        if (validatorState.type !== 'ReadyState') {
            return false;
        }

        // can't run controllers if we are validating or already running controllers
        if (
            validatorResultState.type === 'ValidatingState' ||
            validatorResultState.type === 'ExecutingDocumentControllersState'
        ) {
            return false;
        }

        // no need to run controllers if there are none
        if (!validationService.hasUIDocumentControllers) {
            return true;
        }

        setValidatorResultState(createExecutingDocumentControllersState());

        await nextEventLoop();

        try {
            await validationService.executeDocumentControllers(context, setFieldControlStates, setDocumentState);
        } catch (e) {
            setValidatorResultState(createValidationFunctionInvalidResponseState());
            return false;
        }

        setValidatorResultState(createIdleState());

        return true;
    }, [getDocumentState, setDocumentState, validatorResultState, validatorState, validationService]);

    const controlDocumentAndValidateEvent = useCallback(
        async (event: DocumentValidationEvent) => {
            if (
                validatorResultState.type === 'ValidatingState' ||
                validatorResultState.type === 'ExecutingDocumentControllersState'
            ) {
                deferredEvent.current = event;
            }

            setExecutionCompleted(false);

            const controllersSucceeded = await executeDocumentControllers();

            if (!controllersSucceeded) {
                return false;
            }

            const result = await validateEvent(event);

            setExecutionCompleted(true);

            return result;
        },
        [executeDocumentControllers, validateEvent, validatorResultState]
    );

    useEffect(() => {
        return validationService.onMutationFailed(() => {
            setValidatorResultState(createValidationFunctionInvalidResponseState());
        });
    }, [validationService]);

    useEffect(() => {
        return validationService.onImplementationErrorOccurred(() => {
            setValidatorResultState(createValidationImplementationErrorState());
        });
    }, [validationService]);

    useEffect(() => {
        if (validatorState.type === 'LoadingState') {
            setValidatorResultState(createUninitializedState());
            return;
        }

        if (validatorState.type === 'ReadyState' && validationService.hasValidations) {
            errorRenderer.prefetchValidationFailureTypes();
        }

        const initEvent = {
            type: 'WorkflowLoadEvent',
        } as const;
        if (validatorState.type === 'ReadyState' && validationService.isValidationNeeded(initEvent)) {
            controlDocumentAndValidateEvent(initEvent);
        } else if (validatorState.type === 'ReadyState') {
            setValidatorResultState(createIdleState());
            controlDocumentAndValidateEvent(initEvent);
        } else {
            setValidatorResultState(createIdleState());
        }

        // We only want to run this when validatorState changes. We will be referencing the correct
        // versions of our other dependencies that we use (i.e., the version of those dependencies
        // when the state changed), so there is no bug.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validatorState.type]);

    useEffect(() => {
        const event = deferredEvent.current;
        if (executionCompleted && event) {
            deferredEvent.current = null;
            controlDocumentAndValidateEvent(event);
        }
    }, [executionCompleted, controlDocumentAndValidateEvent]);

    const shouldDisableSpecificActionButton = useCallback<DocumentEditValidator['shouldDisableSpecificActionButton']>(
        (eventType) => {
            // cannot click any action button in states where doing so may lead to skipping
            // validations (such as when we are still loading validation scripts or configuration).
            const invalidValidatorState =
                validatorState.type !== 'ReadyState' ||
                validatorResultState.type === 'ValidatingState' ||
                validatorResultState.type === 'ExecutingDocumentControllersState' ||
                validatorResultState.type === 'UninitializedState';
            if (invalidValidatorState) {
                return true;
            }

            // if we are in a valid state to know which buttons to validate on and we know this
            // button doesn't trigger validations, then we know it should not be disabled by this
            // feature
            if (!validationService.isValidationNeeded({ type: eventType })) {
                return false;
            }

            // cannot click action button if there are inline errors that still need to be corrected.
            const hasInlineErrors =
                validatorResultState.type === 'ValidationFailedState' &&
                Object.keys(validatorResultState.messageClasses.errorMessages.fieldMessages).length > 0;

            return hasInlineErrors;
        },
        [validatorState, validatorResultState, validationService]
    );

    const getFieldControlState = useCallback(
        (fieldKey: string) => fieldControlStates[fieldKey] ?? defaultFieldControlState,
        [fieldControlStates]
    );

    return {
        validatorState,
        validatorResultState,
        validateEvent: controlDocumentAndValidateEvent,
        shouldDisableSpecificActionButton,
        getDropdownOptionsFetcher: validationService.getDropdownOptionsFetcher.bind(validationService),
        context: documentState,
        getFieldControlState,
        dropdownOptionsRepository: validationService.getDropdownOptionsRepository(),
    };
}
