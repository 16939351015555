import { FieldErrorMessages, MessageClasses, MessageClass, IRenderedResult } from '../types/private';

function selectUnassociatedErrors(type: IRenderedResult['type'], renderedErrors: IRenderedResult[]) {
    return renderedErrors
        .filter((x) => x.type === type && x.error.associatedField === undefined)
        .map((x) => x.error.error);
}

function selectAssociatedErrors(type: IRenderedResult['type'], renderedErrors: IRenderedResult[]) {
    const associatedErrors: FieldErrorMessages = {};

    const getErrors = (fieldName: string) => {
        if (!associatedErrors[fieldName]) {
            associatedErrors[fieldName] = [];
        }

        return associatedErrors[fieldName]!;
    };

    renderedErrors.forEach((error) => {
        if (error.type !== type) {
            return;
        }
        if (!error.error.associatedField) {
            return;
        }

        if (
            error.error.associatedField.startsWith('glField-') &&
            !error.error.associatedField.endsWith('glSplit') &&
            !error.error.associatedField.endsWith('GLCodingDotNotationInput')
        ) {
            const key = error.error.associatedField
                .split('-')
                .slice(0, -1)
                .concat('GLCodingDotNotationInput')
                .join('-');
            getErrors(key).push(error.error.error);
        }

        getErrors(error.error.associatedField).push(error.error.error);
    });

    return associatedErrors;
}

function createMessageClass(type: IRenderedResult['type'], renderedErrors: IRenderedResult[]): MessageClass {
    return {
        messages: selectUnassociatedErrors(type, renderedErrors),
        fieldMessages: selectAssociatedErrors(type, renderedErrors),
    };
}

export function createMessageClasses(renderedResults: IRenderedResult[]): MessageClasses {
    return {
        warningMessages: createMessageClass('WarningResult', renderedResults),
        errorMessages: createMessageClass('ErrorResult', renderedResults),
    };
}
