import { useSubscription } from '../../../../utils';
import { portalUserService } from '../PortalUserService';

export function useCompanyChildrenState() {
    useSubscription((cb) => portalUserService.subscribeToCompanyChildrenEvent(cb), [portalUserService]);

    return {
        companyChildren: portalUserService.getCompanyChildren(),
        ...portalUserService.getCompanyChildrenSelection(),
    };
}
