import f, { ODataFilterBuilder } from 'odata-filter-builder';

/**
 * Reduce an array of conditions using the `f.or()` odata filter builder.
 * @param conditions
 * @returns The {@link ODataFilterBuilder} instance
 */
export function orConditions(conditions: ODataFilterBuilder[]) {
    return conditions.reduce((filter, condition) => filter.or(condition), f.or());
}

/**
 * Reduce an array of conditions using the `f.and()` odata filter builder.
 * @param conditions
 * @returns The {@link ODataFilterBuilder} instance
 */
export function andConditions(conditions: ODataFilterBuilder[]) {
    return conditions.reduce((filter, condition) => filter.and(condition), f.and());
}
