/**
 * Traverse an object, converting objects using a converter function.
 *
 * Any non-array object or primitive value encountered while traversing the
 * object will be passed to `convert`. This includes `null` values. `convert`
 * should return the object unchanged to indicate that it has done nothing and
 * that traversal should continue with that object's properties.
 *
 * @param object object to traverse
 * @param convert function to convert an object to a different object
 * @returns the resulting object after traversing and converting
 */
export default function traverseObject(object: any, convert: (value: any) => any): any {
    if (Array.isArray(object)) {
        return object.map((x) => traverseObject(x, convert));
    }

    if (typeof object === 'object') {
        const x = convert(object);
        if (x !== object) {
            return x;
        }

        if (object === null) {
            return object;
        }

        const newObject: any = {};
        Object.keys(object).forEach((k) => {
            newObject[k] = traverseObject(object[k], convert);
        });
        return newObject;
    }

    return convert(object);
}
