import { BaseTextFieldProps, Grid } from '@mui/material';
import { TaxFieldCalculatedFieldTooltip } from './TaxFieldCalculatedFieldTooltips';
import { FCNC } from '../FCNC';
import { useFieldsReadonly } from '../Form/FormControlContext';
import { PercentField } from './PercentField';
import { CurrencyField } from './CurrencyField';
import { BigNumber } from 'bignumber.js';
import { ITooltipIconProps } from '../Tooltip';

export type ITaxFieldValue = { amount: number; type: 'amount' } | { percentage: number; type: 'percentage' } | null;

export interface ITaxFieldProps {
    id?: string;

    value: ITaxFieldValue;

    taxableAmount: number;

    onChange: (newValue: ITaxFieldValue) => void;

    maxPercentageValue: number;

    maxAmountValue: number;

    locale: string;

    currencyCode: string;

    textFieldSX?: BaseTextFieldProps['sx'];

    readonly?: boolean;

    required?: boolean;

    label?: string;

    testId?: string;

    tooltip?: ITooltipIconProps;
}

function toNumber(num: BigNumber) {
    const v = num.toNumber();
    if (Number.isNaN(v) || !Number.isFinite(v)) {
        return 0;
    }

    return v;
}

const getValue = (value: ITaxFieldValue, taxableAmount: number) => {
    if (!value) {
        return { amount: null, percent: null };
    }

    if (value.type === 'amount') {
        const percent = new BigNumber(value.amount).dividedBy(taxableAmount).decimalPlaces(4);

        return { percent: toNumber(percent), amount: value.amount };
    }

    const amount = new BigNumber(value.percentage).multipliedBy(taxableAmount).decimalPlaces(2);

    return { amount: toNumber(amount), percent: value.percentage };
};

const addBoldToSX = (sx: BaseTextFieldProps['sx'], isBold: boolean) => {
    const boldStyle = { '.MuiInputBase-input': { fontWeight: 'bold' } };
    if (isBold) {
        return { ...sx, ...boldStyle } as any;
    }
    return { ...sx } as any;
};

export const TaxField: FCNC<ITaxFieldProps> = ({
    id,
    value,
    taxableAmount,
    onChange,
    maxPercentageValue,
    maxAmountValue,
    locale,
    currencyCode,
    textFieldSX,
    readonly,
    required,
    label,
    testId,
    tooltip,
}) => {
    const { amount, percent } = getValue(value, taxableAmount);

    const isReadonly = useFieldsReadonly(readonly);

    return (
        <Grid container>
            <Grid item>
                <TaxFieldCalculatedFieldTooltip
                    readonly={isReadonly}
                    fieldName="Percentage"
                    otherFieldName="Amount"
                    isCalculated={value?.type === 'amount'}
                >
                    <div>
                        <PercentField
                            id={`${id}-percent`}
                            testId={`${testId}-percent`}
                            label={`${label} (Percentage)`}
                            value={percent}
                            locale={locale}
                            maxValue={maxPercentageValue}
                            onChange={(newValue) => {
                                if (newValue === null) {
                                    onChange(null);
                                    return;
                                }
                                onChange({ type: 'percentage', percentage: newValue });
                            }}
                            sx={addBoldToSX(textFieldSX, value?.type === 'amount')}
                            readonly={isReadonly}
                            required={required}
                            onDoubleClick={() => {
                                if (!percent) {
                                    return;
                                }

                                onChange({ type: 'percentage', percentage: percent });
                            }}
                            tooltip={tooltip}
                        />
                    </div>
                </TaxFieldCalculatedFieldTooltip>
            </Grid>
            <Grid item>
                <TaxFieldCalculatedFieldTooltip
                    readonly={isReadonly}
                    fieldName="Amount"
                    otherFieldName="Percentage"
                    isCalculated={value?.type === 'percentage'}
                >
                    <div>
                        <CurrencyField
                            id={`${id}-amount`}
                            testId={`${testId}-amount`}
                            label={`${label} (Amount)`}
                            value={amount}
                            locale={locale}
                            maxValue={maxAmountValue}
                            onChange={(newValue) => {
                                if (newValue === null) {
                                    onChange(null);
                                    return;
                                }
                                onChange({ type: 'amount', amount: newValue });
                            }}
                            currencyCode={currencyCode}
                            sx={addBoldToSX(textFieldSX, value?.type === 'percentage')}
                            readonly={isReadonly}
                            required={required}
                            onDoubleClick={() => {
                                if (!amount) {
                                    return;
                                }

                                onChange({ type: 'amount', amount });
                            }}
                            tooltip={tooltip}
                        />
                    </div>
                </TaxFieldCalculatedFieldTooltip>
            </Grid>
        </Grid>
    );
};
