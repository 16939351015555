import { useQuery } from '@tanstack/react-query';
import { ICRUDService } from '../types';

export function validEntityId(entityId: any) {
    if (entityId === 0) {
        return true;
    }
    if (entityId === '0') {
        return true;
    }
    if (entityId == null) {
        return false;
    }

    return !!entityId;
}

/**
 * Uses react-query to fetch an entity from an ICRUDService
 * @param service the service to use for fetching
 * @param entityId the unique identifier for the entity
 * @param isEditable whether the entity is editable
 * @returns the entity data and fetching status
 */
export function useEntity<ID extends any, U extends object, T extends ICRUDService<ID, U>>(
    service: T,
    entityId: ID | null,
    isEditable: boolean,
    sessionEntityState: U | null
) {
    const { data, isFetching, error } = useQuery(
        [service.key, entityId],
        async () => {
            if (!validEntityId(entityId) && service.fetchDefaultState) {
                return service.fetchDefaultState();
            }

            return service.fetchEntity(entityId!);
        },
        {
            enabled: (validEntityId(entityId) || !!service.fetchDefaultState) && sessionEntityState === null,
            staleTime: isEditable ? Infinity : 0,
            cacheTime: 0,
            retry: false,
        }
    );

    return {
        data: data ?? null,
        isLoading: isFetching,
        error,
    };
}
