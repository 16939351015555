import * as DocumentActions from '../../actions/document/documentActions';
import * as DocumentCreationActions from '../../actions/document/documentCreationActions';
import { DocumentActionStatusEnum } from '../../utils/documentActionStatus.utils';

import { handleActions } from 'redux-actions';

const initialState = {
    document: {},
    attachments: {
        accepted: [],
        rejected: [],
        saved: [],
        enabled: true,
    },
    documentActionStatus: DocumentActionStatusEnum.Validating,
    validation: [],
    fieldValidations: {
        valid: true,
        messages: [],
    },
    documentHistory: [],
    dynamicDiscounting: [],
    deliveryDetails: [],
    isLoading: {
        document: false,
        attachments: false,
        validation: false,
        documentHistory: false,
        dynamicDiscounting: false,
        deliveryDetails: false,
        workflow: false,
        duplicateCheck: false,
    },
    linesToHighlight: null,
    taskId: null,
    uiActions: {},
    activeActionPopup: '',
    documentErrors: [],
    senderCompanyId: null,
    receiverCompanyId: null,
    searchModel: {},
    isCopy: false,
    isDuplicate: false,
    documentBuyerMessages: null,
    documentBuyerMessageIsLoading: false,
    shouldShow404Error: false,
};

const documentReducer = handleActions(
    {
        [DocumentActions.DOCUMENT_FETCHING]: (state) => {
            return {
                ...state,
                document: initialState.document,
                documentErrors: initialState.documentErrors,
                isLoading: {
                    ...state.isLoading,
                    document: true,
                },
                senderCompanyId: initialState.senderCompanyId,
                receiverCompanyId: initialState.receiverCompanyId,
                isDuplicate: initialState.isDuplicate,
            };
        },
        [DocumentActions.DOCUMENT_FETCHED]: (state, action) => {
            return {
                ...state,
                document: action.payload,
                isLoading: {
                    ...state.isLoading,
                    document: false,
                },
                senderCompanyId: action.payload.CommonFields ? action.payload.CommonFields.SenderCompanyId : null,
                receiverCompanyId: action.payload.CommonFields ? action.payload.CommonFields.ReceiverCompanyId : null,
            };
        },
        [DocumentActions.DOCUMENT_FETCH_ERROR]: (state, action) => {
            return {
                ...state,
                documentErrors: state.document.documentErrors
                    ? [...state.document.documentErrors, action.payload.errors]
                    : action.payload.errors,
                isLoading: {
                    ...state.isLoading,
                    document: false,
                },
            };
        },
        [DocumentActions.DOCUMENT_NOT_FOUND]: (state) => {
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    document: false,
                },
            };
        },
        [DocumentActions.DOCUMENT_SHOULD_SHOW_404_ERROR]: (state, action) => {
            return {
                ...state,
                shouldShow404Error: action.payload,
            };
        },

        [DocumentActions.DOCUMENT_ATTACHMENTS_EXECUTE]: (state) => {
            return {
                ...state,
                attachments: {
                    ...initialState.attachments,
                    enabled: state.attachments.enabled,
                },
                isLoading: {
                    ...state.isLoading,
                    attachments: true,
                },
            };
        },
        [DocumentActions.DOCUMENT_ATTACHMENTS_FETCHED]: (state, action) => {
            return {
                ...state,
                attachments: {
                    ...initialState.attachments,
                    saved: action.payload,
                },
                isLoading: {
                    ...state.isLoading,
                    attachments: false,
                },
            };
        },
        [DocumentActions.DOCUMENT_ATTACHMENTS_FETCH_ERROR]: (state, action) => {
            return {
                ...state,
                documentErrors: state.document.documentErrors
                    ? [...state.document.documentErrors, action.payload.errors]
                    : action.payload.errors,
                attachments: initialState.attachments,
                isLoading: {
                    ...state.isLoading,
                    attachments: false,
                },
            };
        },
        [DocumentActions.DOCUMENT_ATTACHMENT_REMOVED]: (state, action) => {
            return {
                ...state,
                attachments: {
                    ...state.attachments,
                    accepted: action.payload.acceptedFiles,
                    rejected: action.payload.rejectedFiles,
                    saved: action.payload.savedFiles,
                },
            };
        },
        [DocumentActions.DOCUMENT_ATTACHMENTS_ADDED]: (state, action) => {
            return {
                ...state,
                attachments: {
                    ...state.attachments,
                    accepted: action.payload
                        ? state.attachments.accepted.concat(action.payload)
                        : state.attachments.accepted,
                },
            };
        },
        [DocumentActions.SAVE_DOCUMENT_ATTACHMENTS_EXECUTE]: (state, action) => {
            return {
                ...state,
                documentActionStatus: DocumentActionStatusEnum.Saving,
            };
        },
        [DocumentActions.SAVE_DOCUMENT_ATTACHMENTS_FAILURE]: (state, action) => {
            return {
                ...state,
                documentActionStatus: initialState.documentActionStatus,
            };
        },
        [DocumentActions.SAVE_DOCUMENT_ATTACHMENTS_SUCCESS]: (state, action) => {
            return {
                ...state,
                documentActionStatus: initialState.documentActionStatus,
            };
        },
        [DocumentActions.DOCUMENT_VALIDATION_EXECUTE]: (state) => {
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    validation: true,
                },
                documentActionStatus: initialState.documentActionStatus,
                validation: initialState.validation,
            };
        },
        [DocumentActions.DOCUMENT_VALIDATION_FETCHED]: (state, action) => {
            return {
                ...state,
                documentActionStatus: DocumentActionStatusEnum.ValidationFinished,
                validation: action.payload,
                isLoading: {
                    ...state.isLoading,
                    validation: false,
                },
            };
        },
        [DocumentActions.DOCUMENT_VALIDATION_FETCH_ERROR]: (state, action) => {
            return {
                ...state,
                documentActionStatus: DocumentActionStatusEnum.ValidationFinished,
                documentErrors: state.document.documentErrors
                    ? [...state.document.documentErrors, action.payload.errors]
                    : action.payload.errors,
                isLoading: {
                    ...state.isLoading,
                    validation: false,
                },
            };
        },
        [DocumentActions.DOCUMENT_SET_ATTACHMENTS_ALLOWED]: (state, action) => {
            return {
                ...state,
                attachments: {
                    ...state.attachments,
                    enabled: action.payload,
                },
            };
        },
        [DocumentActions.DOCUMENT_FIELD_VALIDATION]: (state, action) => {
            return {
                ...state,
                fieldValidations: action.payload,
            };
        },
        [DocumentActions.DOCUMENT_CLEAR_VALIDATION]: (state, action) => {
            return {
                ...state,
                fieldValidations: initialState.fieldValidations,
                validation: initialState.validation,
                documentActionStatus: initialState.documentActionStatus,
            };
        },
        [DocumentActions.DOCUMENT_HISTORY_EXECUTE]: (state) => {
            return {
                ...state,
                documentHistory: initialState.documentHistory,
                isLoading: {
                    ...state.isLoading,
                    documentHistory: true,
                },
            };
        },
        [DocumentActions.DOCUMENT_HISTORY_FETCHED]: (state, action) => {
            return {
                ...state,
                documentHistory: action.payload,
                isLoading: {
                    ...state.isLoading,
                    documentHistory: false,
                },
            };
        },
        [DocumentActions.DOCUMENT_HISTORY_FETCH_ERROR]: (state, action) => {
            return {
                ...state,
                documentErrors: state.document.documentErrors
                    ? [...state.document.documentErrors, action.payload.errors]
                    : action.payload.errors,
                isLoading: {
                    ...state.isLoading,
                    documentHistory: false,
                },
            };
        },

        [DocumentActions.DOCUMENT_DYNAMIC_DISCOUNTING_EXECUTE]: (state) => {
            return {
                ...state,
                dynamicDiscounting: initialState.dynamicDiscounting,
                isLoading: {
                    ...state.isLoading,
                    dynamicDiscounting: true,
                },
            };
        },
        [DocumentActions.DOCUMENT_DYNAMIC_DISCOUNTING_FETCHED]: (state, action) => {
            return {
                ...state,
                dynamicDiscounting: action.payload,
                isLoading: {
                    ...state.isLoading,
                    dynamicDiscounting: false,
                },
            };
        },
        [DocumentActions.DOCUMENT_DYNAMIC_DISCOUNTING_ERROR]: (state, action) => {
            return {
                ...state,
                documentErrors: state.document.documentErrors
                    ? [...state.document.documentErrors, action.payload.errors]
                    : action.payload.errors,
                isLoading: {
                    ...state.isLoading,
                    dynamicDiscounting: false,
                },
            };
        },

        [DocumentActions.DOCUMENT_DELIVERY_EXECUTE]: (state) => {
            return {
                ...state,
                deliveryDetails: initialState.deliveryDetails,
                isLoading: {
                    ...state.isLoading,
                    deliveryDetails: true,
                },
            };
        },
        [DocumentActions.DOCUMENT_DELIVERY_FETCHED]: (state, action) => {
            return {
                ...state,
                deliveryDetails: action.payload,
                isLoading: {
                    ...state.isLoading,
                    deliveryDetails: false,
                },
            };
        },
        [DocumentActions.DOCUMENT_DELIVERY_ERROR]: (state, action) => {
            return {
                ...state,
                documentErrors: state.document.documentErrors
                    ? [...state.document.documentErrors, action.payload.errors]
                    : action.payload.errors,
                isLoading: {
                    ...state.isLoading,
                    deliveryDetails: false,
                },
            };
        },

        [DocumentActions.DOCUMENT_WORKFLOW_EXECUTE]: (state) => {
            return {
                ...state,
                isLoading: {
                    ...state.isLoading,
                    workflow: true,
                },
            };
        },
        [DocumentActions.DOCUMENT_WORKFLOW_FETCHED]: (state, action) => {
            return {
                ...state,
                ...action.payload,
                isLoading: {
                    ...state.isLoading,
                    workflow: false,
                },
            };
        },
        [DocumentActions.DOCUMENT_WORKFLOW_ERROR]: (state, action) => {
            return {
                ...state,
                documentErrors: state.document.documentErrors
                    ? [...state.document.documentErrors, action.payload.errors]
                    : action.payload.errors,
                isLoading: {
                    ...state.isLoading,
                    workflow: false,
                },
            };
        },

        [DocumentActions.DOCUMENT_TOGGLE_ACTION_POPUP]: (state, action) => {
            return { ...state, activeActionPopup: action.payload };
        },
        [DocumentActions.DOCUMENT_SENDERCOMPANYID_FETCHED]: (state, action) => {
            return { ...state, senderCompanyId: action.payload };
        },
        [DocumentActions.DOCUMENT_RECEIVERCOMPANYID_FETCHED]: (state, action) => {
            return { ...state, receiverCompanyId: action.payload };
        },
        [DocumentActions.DOCUMENT_SET_SEARCH_MODEL]: (state, action) => {
            return {
                ...state,
                searchModel: action.payload.searchModel,
            };
        },
        [DocumentActions.DOCUMENT_IS_A_COPY]: (state, action) => {
            return {
                ...state,
                isCopy: action.payload,
            };
        },

        [DocumentActions.DOCUMENT_DUPLICATE_CHECK_EXECUTE]: (state, action) => {
            return {
                ...state,
                isDuplicate: false,
                isLoading: {
                    ...state.isLoading,
                    duplicateCheck: true,
                },
            };
        },
        [DocumentActions.DOCUMENT_DUPLICATE_CHECK_FAILURE]: (state, action) => {
            return {
                ...state,
                isDuplicate: false,
                isLoading: {
                    ...state.isLoading,
                    duplicateCheck: false,
                },
            };
        },
        [DocumentActions.DOCUMENT_DUPLICATE_CHECK_SUCCESS]: (state, action) => {
            return {
                ...state,
                isDuplicate: action.payload.Items.length > 0,
                isLoading: {
                    ...state.isLoading,
                    duplicateCheck: false,
                },
            };
        },
        [DocumentActions.DOCUMENT_CLEAR_HISTORY_DELIVERY]: (state) => {
            return {
                ...state,
                documentHistory: initialState.documentHistory,
                deliveryDetails: initialState.deliveryDetails,
            };
        },
        [DocumentActions.CANCEL_DOCUMENT_EXECUTE]: (state, action) => {
            return {
                ...state,
                documentActionStatus: DocumentActionStatusEnum.Canceling,
            };
        },
        [DocumentActions.CANCEL_DOCUMENT_SUCCESS]: (state, action) => {
            return {
                ...state,
                documentActionStatus: initialState.documentActionStatus,
            };
        },
        [DocumentActions.CANCEL_DOCUMENT_FAILURE]: (state, action) => {
            return {
                ...state,
                documentActionStatus: initialState.documentActionStatus,
            };
        },
        [DocumentCreationActions.SAVE_DOCUMENT_DRAFT_ATTACHMENTS_EXECUTE]: (state, action) => {
            return {
                ...state,
                documentActionStatus: DocumentActionStatusEnum.Saving,
            };
        },
        [DocumentCreationActions.SAVE_DOCUMENT_DRAFT_ATTACHMENTS_FAILURE]: (state, action) => {
            return {
                ...state,
                documentActionStatus: initialState.documentActionStatus,
            };
        },
        [DocumentCreationActions.SAVE_DOCUMENT_DRAFT_ATTACHMENTS_SUCCESS]: (state, action) => {
            return {
                ...state,
                documentActionStatus: initialState.documentActionStatus,
            };
        },
        [DocumentCreationActions.SUBMIT_DOCUMENT_EXECUTE]: (state, action) => {
            return {
                ...state,
                documentActionStatus: DocumentActionStatusEnum.Submitting,
            };
        },
        [DocumentCreationActions.SUBMIT_DOCUMENT_SUCCESS]: (state, action) => {
            return {
                ...state,
                documentActionStatus: initialState.documentActionStatus,
            };
        },
        [DocumentCreationActions.SUBMIT_DOCUMENT_FAILURE]: (state, action) => {
            return {
                ...state,
                documentActionStatus: initialState.documentActionStatus,
            };
        },
        [DocumentActions.RELEASE_DOCUMENT_EXECUTE]: (state, action) => {
            return {
                ...state,
                documentActionStatus: DocumentActionStatusEnum.Releasing,
            };
        },
        [DocumentActions.RELEASE_DOCUMENT_SUCCESS]: (state, action) => {
            return {
                ...state,
                documentActionStatus: initialState.documentActionStatus,
            };
        },
        [DocumentActions.RELEASE_DOCUMENT_FAILURE]: (state, action) => {
            return {
                ...state,
                documentActionStatus: initialState.documentActionStatus,
            };
        },
        [DocumentActions.DOCUMENT_GET_BUYER_MESSAGE_START]: (state) => {
            return {
                ...state,
                documentBuyerMessageIsLoading: true,
            };
        },
        [DocumentActions.DOCUMENT_GET_BUYER_MESSAGE_END]: (state) => {
            return {
                ...state,
                documentBuyerMessageIsLoading: false,
            };
        },
        [DocumentActions.DOCUMENT_GET_BUYER_MESSAGE_SUCCESS]: (state, action) => {
            return {
                ...state,
                documentBuyerMessages: action.payload.messages,
            };
        },
        [DocumentActions.DOCUMENT_GET_BUYER_MESSAGE_FAILURE]: (state, action) => {
            return {
                ...state,
                documentBuyerMessages: action.payload.error,
            };
        },
        [DocumentActions.DOCUMENT_CLEAR_MESSAGES]: (state, action) => {
            return {
                ...state,
                documentBuyerMessages: null,
            };
        },
    },
    initialState
);

export default documentReducer;
