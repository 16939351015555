export { CJSRuleEngineV2ClientWrapper } from './CJSRuleEngineV2ClientWrapper';
import {
    DocumentValidatorProvider,
    useDocumentValidatorContext,
    withDocumentValidatorV2,
} from './DocumentValidationContext';
import { useValidationFailureDictionary } from './useValidationFailureDictionary';
import { CJSEngineVersionProvider, useCJSEngineVersion } from './EngineVersionContext';

export const DocumentValidatorV2 = {
    DocumentValidatorProvider,
    withDocumentValidatorV2,
    useDocumentValidatorContext,
    useValidationFailureDictionary,
    CJSEngineVersionProvider,
    useCJSEngineVersion,
};
