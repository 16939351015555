import { ExecutionService } from '../CustomJSRuleEngineV2';
import { MessageClass, MessageClasses } from '../DocumentEditRules';

type ValidationResult = {
    errors: ExecutionService.DPSValidations.DPSValidationResult[];
    warnings: ExecutionService.DPSValidations.DPSValidationResult[];
};

const formatResult = ({ defaultVFT }: ExecutionService.DPSValidations.DPSValidationResult) => {
    if (!defaultVFT?.titleText) {
        return null;
    }

    const message = defaultVFT.detailText ? `${defaultVFT.titleText}: ${defaultVFT.detailText}` : defaultVFT.titleText;

    return message;
};

export function formatValidationResultAndTransformToMessageClasses(results: ValidationResult): MessageClasses {
    const reduceResultToMsgClass = (
        msgClass: MessageClass,
        result: ExecutionService.DPSValidations.DPSValidationResult
    ) => {
        const formattedMessage = formatResult(result);

        if (formattedMessage === null) {
            return msgClass;
        }

        if (typeof result.associatedUIField === 'string') {
            msgClass.fieldMessages[result.associatedUIField] ??= [];
            msgClass.fieldMessages[result.associatedUIField]?.push(formattedMessage);
        } else {
            msgClass.messages.push(formattedMessage);
        }

        return msgClass;
    };

    const errorMessages = results.errors.reduce<MessageClass>(reduceResultToMsgClass, {
        messages: [],
        fieldMessages: {} as Record<string, string[]>,
    });

    const warningMessages = results.warnings.reduce<MessageClass>(reduceResultToMsgClass, {
        messages: [],
        fieldMessages: {} as Record<string, string[]>,
    });

    return {
        errorMessages,
        warningMessages,
    };
}
