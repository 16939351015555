import { ViewModels } from '../../../../services/backendServices';
import { flattenXrefParameters, flattenInputParameterSettings } from './flattenXrefParameters';
import { parseXrefParameters, parseRuleParameters } from './parseXrefParameters';

/**
 * Processes the xref InputParameterSettings and InputParameters to useful values.
 * @param xref the xref
 * @returns the useful values
 */
export function processXrefParameters(xref: ViewModels.BuyerCustomJSRuleMappingXrefViewModel) {
    return parseXrefParameters(flattenXrefParameters(xref));
}

/**
 * Processes the InputParameterSettings on a rule to useful values.
 * @param inputParameterSettings the InputParameterSettings
 * @returns the useful values
 */
export function processInputParameterSettings(inputParameterSettings: ViewModels.InputParameterSettings | null) {
    return parseRuleParameters(flattenInputParameterSettings(inputParameterSettings));
}
