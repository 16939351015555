import { FC, useEffect } from 'react';
import { handleLogout } from '../../../../utils';
import { Button } from '../../../../ui';

interface IProps {
    handleLogInAgain: () => void;
}

const LogoutForm: FC<IProps> = ({ handleLogInAgain }) => {
    useEffect(() => {
        handleLogout();
    }, []);

    return (
        <>
            <Button color="primary" onClick={handleLogInAgain} testid="sign-in-again-button">
                Sign In Again
            </Button>
        </>
    );
};

export default LogoutForm;
