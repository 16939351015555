import { HotTable, HotTableClass } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.min.css';
import 'pikaday/css/pikaday.css';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { ColumnSettings } from 'handsontable/settings';
import { CellValue } from 'handsontable/common';
import { checkForEmptyCell } from './HotTableComponent.utils';
import _ from 'lodash';
import { Box } from '@mui/material';

registerAllModules();

export type IErrorMapKey = {
    row: number;
    col: number;
};

export interface HotTableComponentProps {
    columns: ColumnSettings[];
    colHeaders: string[];
    colWidths?: number[];
    setDataValidity: (valid: boolean) => void;
    setHotChanges?: (tableChange: HotTableChangeInterface) => void;
    setData?: (data: CellValue[] | undefined) => void;
    setErrors: (errors: Map<string, string>) => void; // Key is JSON.stringify({ row, col })
}

export interface HotTableChangeInterface {
    changes: CellValue[] | null;
    source: string;
}

/**
 * HotTableComponent is a wrapper around the Handsontable component.
 * It provides a controlled interface to the Handsontable instance.
 * It also provides a callback to notify the parent component of the data validity.
 * @param props.columns - column settings for the Handsontable instance
 * @param props.colHeaders - column headers for the Handsontable instance
 * @param props.colWidths - column widths for the Handsontable instance
 * @param props.setDataValidity - callback to notify the parent component of the data validity
 * @param props.setHotChanges - callback to notify the parent component of the data changes
 * @param props.setData - callback to notify the parent component of the data
 * @param props.setErrors - callback to notify the parent component of the errors
 * @param forwardedRef - ref to the HotTableClass instance
 */
export const HotTableComponent = forwardRef<HotTableClass, HotTableComponentProps>(function HotTableComponent(
    props,
    forwardedRef
) {
    const hotRef = useRef<HotTableClass | null>(null);
    useImperativeHandle(forwardedRef, () => hotRef.current as HotTableClass);
    const VALIDATION_DEBOUNCE_TIME = 100;
    const errorsMap: Map<string, string> = new Map<string, string>();

    const debouncedValidate = _.debounce((isDataEmpty: boolean = false) => {
        props.setErrors(errorsMap);
        if (errorsMap.size || isDataEmpty) {
            props.setDataValidity(false);
        } else {
            props.setDataValidity(true);
        }
    }, VALIDATION_DEBOUNCE_TIME);

    const validate = () => {
        const instance = hotRef.current?.hotInstance;
        if (!instance) {
            return;
        }

        let foundOneNonEmptyRow = false;
        for (let row = 0; row < instance.countRows(); row++) {
            if (!instance.isEmptyRow(row)) {
                foundOneNonEmptyRow = true;
                for (let col = 0; col < instance.countCols(); col++) {
                    const cellMeta = instance.getCellMeta(row, col);
                    const cellValue = instance.getDataAtCell(row, col);
                    instance.validateCell(
                        cellValue,
                        cellMeta,
                        (cellValid) => {
                            if (cellValid === false) {
                                errorsMap.set(JSON.stringify({ row: row, col: col }), props.columns[col].errorHint);
                            } else {
                                // check if cell is empty when it's required
                                if (
                                    props.columns[col].required &&
                                    checkForEmptyCell(instance.getDataAtCell(row, col))
                                ) {
                                    errorsMap.set(
                                        JSON.stringify({ row: row, col: col }),
                                        'Required field cannot be empty.'
                                    );
                                } else {
                                    errorsMap.delete(JSON.stringify({ row: row, col: col }));
                                }
                            }
                            debouncedValidate();
                        },
                        'manual'
                    );
                }
            }
        }
        // if no non-empty rows are found, clear the errors. Use case is when the user deletes all rows that have errors.
        if (!foundOneNonEmptyRow) {
            errorsMap.clear();
            debouncedValidate(true); // isDataEmpty = true so that the data validity is set to false
        }
    };

    return (
        <Box
            sx={{
                '.handsontable th': { whiteSpace: 'normal' },
            }}
        >
            <HotTable
                ref={hotRef}
                colWidths={props.colWidths}
                colHeaders={props.colHeaders}
                hiddenColumns={{
                    indicators: true,
                }}
                contextMenu={true}
                multiColumnSorting={true}
                filters={true}
                rowHeaders={true}
                autoWrapCol={true}
                autoWrapRow={true}
                manualRowMove={true}
                licenseKey="9c1b0-254d0-8b653-4443b-8c82c"
                columns={props.columns}
                afterChange={(changes, source) => {
                    props.setHotChanges?.({ changes, source });
                    props.setData?.(hotRef.current?.hotInstance?.getData());
                    validate();
                }}
                afterRemoveRow={() => {
                    props.setData?.(hotRef.current?.hotInstance?.getData());
                    validate();
                }}
                minSpareRows={1}
                minRows={10}
                manualColumnResize={true}
            />
        </Box>
    );
});
