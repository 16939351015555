import {
    IAutocompleteField,
    ICheckboxField,
    ICurrencyField,
    IDateRangePickerField,
    IDropdownField,
    IDropzoneField,
    IField,
    IFieldType,
    INumericTextField,
    IRadioButtonGroup,
    IRangeNumericTextField,
    ITaxField,
    ITextField,
} from './types';
import { UnexpectedCase } from '../../utils';

function parseBaseField<DataType, FieldType extends string>(
    data: any,
    dataType: string
): IFieldType<DataType> & { type: FieldType } {
    // shallow copy data
    data = { ...data };

    if (typeof data.id !== 'string') {
        throw new Error('Expected field to have string "id" property.');
    }

    if (typeof data.dataBindingKey !== 'string') {
        throw new Error('Expected field to have string "dataBindingKey" property.');
    }

    if (typeof data.label !== 'string') {
        throw new Error('Expected field to have string "label" property.');
    }

    // temporary solution because API loses type information of non-string fields
    if (typeof data.readOnly === 'string') {
        data.readOnly = data.readOnly === 'true';
    }
    // end temporary solution
    if (typeof data.readOnly !== 'boolean') {
        throw new Error('Expected field to have boolean "readOnly" property.');
    }

    const visibilityValues: IField['visibility'][] = ['AlwaysInvisible', 'AlwaysVisible', 'VisibleIfHasValue'];
    if (!visibilityValues.includes(data.visibility)) {
        throw new Error(`Expected field to have "visibility" property equal to one of: ${visibilityValues.join(', ')}`);
    }

    if (dataType !== 'unknown') {
        // temporary solution because API converts empty strings to null
        if (data.defaultValue === null && dataType === 'string') {
            data.defaultValue = '';
        }
        if (data.emptyValue === null && dataType === 'string') {
            data.emptyValue = '';
        }
        // end temporary solution
        if (typeof data.defaultValue !== dataType) {
            throw new Error(`Expected field to have ${dataType} "defaultValue" property.`);
        }

        if (typeof data.emptyValue !== dataType) {
            throw new Error(`Expected field to have ${dataType} "defaultValue" property.`);
        }
    }

    return data;
}

function parseITextField(data: any): ITextField {
    return parseBaseField<string, 'ITextField'>(data, 'string');
}

function validateOption(option: any) {
    if (typeof option.label !== 'string') {
        throw new Error('Expected option to have string "label" property.');
    }

    if (typeof option.value === 'undefined') {
        throw new Error('Expected option to have defined "value" property.');
    }
}

function parseIRadioButtonGroup(data: any): IRadioButtonGroup {
    data = parseBaseField<string, 'IRadioButtonGroup'>(data, 'string');

    if (!Array.isArray(data.options)) {
        throw new Error('Expected field to have array of "options" property.');
    }

    data.options.forEach(validateOption);

    return data as IRadioButtonGroup;
}

function parseIDropdownField<T extends IDropdownField | IAutocompleteField>(data: any): T {
    data = parseBaseField<string, 'IDropdownField'>(data, 'string');

    if (!['string', 'undefined'].includes(typeof data.prompt)) {
        throw new Error('Expected field to have string "prompt" property, if defined');
    }

    if (!['string', 'undefined'].includes(typeof data.nullOptionLabel)) {
        throw new Error('Expected field to have string "nullOptionLabel" property, if defined');
    }

    if (!Array.isArray(data.options)) {
        throw new Error('Expected field to have array of "options" property.');
    }

    data.options.forEach(validateOption);

    return data as T;
}

function parseICheckboxField(data: any): ICheckboxField {
    return parseBaseField<boolean, 'ICheckboxField'>(data, 'boolean');
}

function parseICurrencyField(data: any): ICurrencyField {
    data = parseBaseField<unknown, 'ICurrencyField'>(data, 'unknown');

    // TODO: Finish implementing this parser

    return data as ICurrencyField;
}

function parseITaxField(data: any): ITaxField {
    data = parseBaseField<unknown, 'ITaxField'>(data, 'unknown');

    // TODO: Finish implementing this parser

    return data as ITaxField;
}

function parseINumericTextField(data: any): INumericTextField {
    data = parseBaseField<unknown, 'INumericTextField'>(data, 'unknown');

    // TODO: Finish implementing this parser

    return data as INumericTextField;
}

function parseIDropzoneField(data: any): IDropzoneField {
    data = parseBaseField<unknown, 'IDropzoneField'>(data, 'unknown');

    // TODO: Finish implementing this parser

    return data as IDropzoneField;
}

function parseIDateRangePickerField(data: any): IDateRangePickerField {
    data = parseBaseField<unknown, 'IDateRangePickerField'>(data, 'unknown');

    // TODO: Finish implementing this parser

    return data as IDateRangePickerField;
}

function parseIRangeNumericTextField(data: any): IRangeNumericTextField {
    data = parseBaseField<unknown, 'IRangeNumericTextField'>(data, 'unknown');

    // TODO: Finish implementing this parser

    return data as IRangeNumericTextField;
}

/**
 * Validates that an object of unknown type is an IField.
 *
 * Returns an IField typed object if the validation succeeds, otherwise null.
 *
 * Logs an error when validation fails.
 */
export function parseField(data: any): IField | null {
    try {
        const type = data.type as IField['type'];
        switch (type) {
            case 'ITextField':
                return parseITextField(data);
            case 'IDropdownField':
                return parseIDropdownField(data);
            case 'IEnumDropdownField':
                throw new Error('IEnumDropdownField cannot be serialized/parsed and is only for static usage');
            case 'ICheckboxField':
                return parseICheckboxField(data);
            case 'IAutocompleteField':
                return parseIDropdownField(data);
            case 'IRenderPropField':
                throw new Error('IRenderPropField cannot be serialized/parsed and is only for static usage');
            case 'IRadioButtonGroup':
                return parseIRadioButtonGroup(data);
            case 'ICurrencyField':
                return parseICurrencyField(data);
            case 'ITaxField':
                return parseITaxField(data);
            case 'INumericTextField':
                return parseINumericTextField(data);
            case 'IDropzoneField':
                return parseIDropzoneField(data);
            case 'IDateRangePickerField':
                return parseIDateRangePickerField(data);
            case 'IRangeNumericTextField':
                return parseIRangeNumericTextField(data);
            default:
                throw new UnexpectedCase(type);
        }
    } catch (e) {
        console.error('Error parsing field, returning null', e);
        return null;
    }
}
