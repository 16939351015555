// NON-LEGACY: This module is non-legacy and should be immediately moved to the new ui core library after #14226
import useOperationTookTooLong from './useOperationTookTooLong';
import { ResetApplicationStateDialog } from '../../../../ui/ResetApplicationStateDialog';

export interface IOperationTookTooLongGuardProps {
    isLoading: boolean;

    tooLongTimeInSeconds: number;

    /**
     * No longer used
     * @deprecated
     */
    showFatalErrorOnUnhandledRejection?: boolean;
}

/**
 * Shows an error dialog if the operation took too long to complete. In development mode,
 * or if `showFatalErrorOnUnhandledRejection` is `true`, shows a dialog if an unhandled
 * promise rejection occurs.
 */
export function OperationTookTooLongGuard({ isLoading, tooLongTimeInSeconds }: IOperationTookTooLongGuardProps) {
    const tookTooLong = useOperationTookTooLong(tooLongTimeInSeconds, isLoading);

    return (
        <>
            <ResetApplicationStateDialog
                open={tookTooLong}
                title="Operation has stalled"
                reason={
                    <>
                        <p>
                            The operation you were performing is unlikely to finish. Please click the Reset button below
                            and try again.
                        </p>
                        <p>
                            If you keep encountering this issue, please reach out to{' '}
                            <a href="mailto:support@transcepta.com">Transcepta Support</a>
                        </p>
                    </>
                }
                redirectLocation={window.location.href}
            />
        </>
    );
}
