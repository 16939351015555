import { Box, Typography, Stack } from '@mui/material';
import { useState, useRef, useMemo } from 'react';
import { LocalizedStrings } from 'react-localization';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CRUDTemplate, useCRUDFormObserver } from '../../../reusableFeatures';
import {
    IUserFormData,
    IUserGroup,
    IWorkflowGroupUserViewModel,
    portalUserService,
    useRoles,
    useUsers,
} from '../../../services';
import { RoleType } from '../../../services/UserService/Api';
import { Button, Checkbox, FCNC, RadioButton, RadioButtonGroup, SortedSelect, TextField, Dialog } from '../../../ui';
import { EmbedHTML } from '../../../utils';
import { UserInfoModal } from '../UserInfoModal';
import { useUserCRUDService } from './UserCRUDService';
import { AuditLog } from '../../AuditLog/AuditLog';
import HistoryIcon from '@mui/icons-material/History';
import { useAuditLog } from '../../AuditLog';

interface IUserCRUDProps {
    hideWorkflowGroupUsers?: boolean;
    userId: number | null;
    searchPagePathName: string;
    createEditPathName: (id: number) => string;
    projectConfig: any;
    resetPassword: (email: string) => void;
    workflowUserGroups: IWorkflowGroupUserViewModel[] | null;
    userGroups: IUserGroup[];
    loadingMessage?: string;
    localization: LocalizedStrings<{
        textAccountAdministration: string;
        textAccountAdminExplanation: string;
        textSignIn: string;
        textSignInExplanation: string;
        textSupplierCreationRightsExplanation: string;
        textDDEdit: string;
        textDDEditRights: string;
        textDDAdministration: string;
        textDDAdministrationExplanation: string;
        textInvoiceEditRightsExplanation?: string;
        textDDViewOnly: string;
        textDDViewOnlyExplanation: string;
        textSupplierAdminExplanation?: string;
        textResetPassword: string;
        textResetUserPasswordInfo: string;
        textConfirmUserMessage: string;
        textUserPrivileges: string;
        textUserPrivilegeExplanation: string;
        textUserGroups: string;
        textDynamicDiscountingPrivileges: string;
        textSubstituteUser: string;
        textWorkflowGroupUser: string;
        textSuperUserRights: string;
        textCompanyAdminRights: string;
        textSupplierAdminRights: string;
        textInvoiceEditRights: string;
        textSupplierLoginRights: string;
        textBuyerAdminRights: string;
        textPaymentCreationRights: string;
        textPaymentApprovalRights: string;
        textBuyerLoginRights: string;
        textDynamicDiscountingEditRights: string;
        textDynamicDiscountingAdminRights: string;
        textDynamicDiscountingViewRights: string;
        textParkingLotAdminRights: string;
        textParkingLotOnly: string;
        textSupplierCreationRights: string;
        textSuperUserApprover: string;
        textUserName: string;
        textEmailAddress: string;
        textConfirmEmail: string;
        textPhoneNumber: string;
        textEmployeeNumber: string;
        textTitle: string;
        textDepartment: string;
        textRegion: string;
        textJobCode: string;
        textManager: string;
    }>;
}

const hasRole = (userRoles: IUserFormData['userRoles'], roleId: number) => {
    return !!userRoles?.some((role) => role.RoleID === roleId);
};

const hasGroupMembership = (userGroupMemberships: IUserFormData['userGroupMemberships'], userGroupId: number) => {
    return !!userGroupMemberships?.find((userGroupMembership) => userGroupMembership.UserGroupId === userGroupId)
        ?.checked;
};

const hasWorkflowGroupUser = (workflowGroupUsers: IUserFormData['workflowGroupUsers'], workflowId: number) => {
    return !!workflowGroupUsers?.find((group) => group.WorkflowId === workflowId)?.checked;
};

const dynamicDiscountingPrivilegesIds = [
    RoleType.DynamicDiscountingEditRights,
    RoleType.DynamicDiscountingAdminRights,
    RoleType.DynamicDiscountingViewRights,
];

type ViewPopup = {
    viewAccountExplanationPopup?: boolean;
    viewSignInExplanationPopup?: boolean;
    viewSupplierCreationPopup?: boolean;
    viewDDEditRightsPopup?: boolean;
    viewDDAdministrationPopup?: boolean;
    viewDDViewOnlyPopup?: boolean;
    viewResetUserPopup?: boolean;
    viewInvoiceEditRightsPopup?: boolean;
    viewSupplierAdminRightsPopup?: boolean;
};

export const UserCRUD: FCNC<IUserCRUDProps> = ({
    hideWorkflowGroupUsers = false,
    userId,
    searchPagePathName,
    createEditPathName,
    resetPassword,
    projectConfig,
    userGroups,
    workflowUserGroups,
    loadingMessage: parentLoadingMessage,
    localization,
}) => {
    const service = useUserCRUDService();
    const history = useHistory();
    const { isFetching: isFetchingUsers, users } = useUsers();
    const { isFetching: isFetchingRoles, roles } = useRoles();
    const loadingMessage = (() => {
        if (isFetchingUsers) {
            return 'Loading Users';
        }

        if (isFetchingRoles) {
            return 'Loading Roles';
        }

        return parentLoadingMessage || null;
    })();
    const currentUser = portalUserService.getCurrentUser('mustBeLoggedIn');
    const enableVendorEdits = useSelector((state: any) => state.userlogin.menuSettings.EnableVendorEdits);
    const enableDynamicDiscounting = useSelector((state: any) => state.userlogin.menuSettings.EnableDynamicDiscounting);
    const { onFormStateChange, formState } = useCRUDFormObserver(service);
    const [popup, setPopup] = useState<ViewPopup>({});
    const [isDynamicDiscountingChecked, setIsDynamicDiscountingChecked] = useState(false);
    const showDynamicDiscounting =
        isDynamicDiscountingChecked ||
        formState.userRoles?.some((userRole) => dynamicDiscountingPrivilegesIds.includes(userRole.RoleID));

    const options = users.map((subUser) => ({ value: subUser.id, label: subUser.name }));

    const toggleUserPrivilegePopups = (roleId: RoleType) => {
        switch (roleId) {
            case RoleType.CompanyAdminRights:
                setPopup((prevState) => ({ ...prevState, viewAccountExplanationPopup: true }));
                break;
            case RoleType.SupplierAdminRights:
                setPopup((prevState) => ({ ...prevState, viewSupplierAdminRightsPopup: true }));
                break;
            case RoleType.InvoiceEditRights:
                setPopup((prevState) => ({ ...prevState, viewInvoiceEditRightsPopup: true }));
                break;
            case RoleType.SupplierLoginRights:
            case RoleType.BuyerLoginRights:
                setPopup((prevState) => ({ ...prevState, viewSignInExplanationPopup: true }));
                break;
            default:
                break;
        }
    };
    const [showHistory, setShowHistory] = useState(false);
    const hideHistory = () => setShowHistory(false);

    // this is used for the Show History button
    // Add to this list whatever field changes we need to show in the History page
    const mapDbFieldsToFormLabels = useMemo(
        () => [
            {
                DBKey: 'Name',
                DBKeyMapping: localization.textUserName,
            },
            {
                DBKey: 'Email',
                DBKeyMapping: localization.textEmailAddress,
            },
            {
                DBKey: 'Phone',
                DBKeyMapping: localization.textPhoneNumber,
            },
            {
                DBKey: 'EmployeeNumber',
                DBKeyMapping: localization.textEmployeeNumber,
            },
            {
                DBKey: 'Title',
                DBKeyMapping: localization.textTitle,
            },
            {
                DBKey: 'Department',
                DBKeyMapping: localization.textDepartment,
            },
            {
                DBKey: 'Region',
                DBKeyMapping: localization.textRegion,
            },
            {
                DBKey: 'JobClassification',
                DBKeyMapping: localization.textJobCode,
            },
            {
                DBKey: 'ManagersUserId',
                DBKeyMapping: localization.textManager,
                DBIDMappings: users.map((user) => ({ ID: String(user.id), IDMapping: user.name })),
            },
            {
                DBKey: 'SubstituteUserId',
                DBKeyMapping: localization.textSubstituteUser,
                DBIDMappings: users.map((user) => ({ ID: String(user.id), IDMapping: user.name })),
            },
            {
                DBKey: 'UserGroupId',
                DBKeyMapping: localization.textUserGroups,
                DBIDMappings: userGroups.map((userGroup) => ({ ID: String(userGroup.id), IDMapping: userGroup.name })),
                IsList: true,
            },
            {
                DBKey: 'RoleID',
                DBKeyMapping: localization.textUserPrivileges,
                DBIDMappings: roles.map((role) => ({
                    ID: String(role.id),
                    IDMapping:
                        //@ts-ignore
                        localization[`text${role.name}`],
                })),
                IsList: true,
            },
            {
                DBKey: 'WorkflowId',
                DBKeyMapping: localization.textWorkflowGroupUser,
                DBIDMappings: workflowUserGroups?.map((workflowUserGroup) => ({
                    ID: String(workflowUserGroup.Id),
                    IDMapping: workflowUserGroup.ShortName,
                })),
                IsList: true,
            },
            {
                DBKey: 'AutogenUnconfirmed',
                DBKeyMapping: 'Unconfirmed User',
            },
        ],
        [userGroups, workflowUserGroups, roles, users, localization]
    );

    // userId is null when creating a new user. Do not show the history button in that case
    const { auditLogData, auditLogIsLoading } = useAuditLog({
        entityType: 'User',
        entityPrimaryKey: userId ?? 0,
        mapDbFieldsToFormLabels: mapDbFieldsToFormLabels,
        enabled: userId !== null,
    });

    const hidePopups = () => {
        setPopup({
            viewAccountExplanationPopup: false,
            viewSignInExplanationPopup: false,
            viewSupplierCreationPopup: false,
            viewDDEditRightsPopup: false,
            viewDDAdministrationPopup: false,
            viewDDViewOnlyPopup: false,
            viewResetUserPopup: false,
            viewSupplierAdminRightsPopup: false,
            viewInvoiceEditRightsPopup: false,
        });
    };

    const toggleDynamicDiscountingPopups = (roleId: RoleType) => {
        switch (roleId) {
            case RoleType.DynamicDiscountingEditRights:
                setPopup({ viewDDEditRightsPopup: true });
                break;
            case RoleType.DynamicDiscountingAdminRights:
                setPopup({ viewDDAdministrationPopup: true });
                break;
            case RoleType.DynamicDiscountingViewRights:
                setPopup({ viewDDViewOnlyPopup: true });
                break;
            default:
                break;
        }
    };

    const handleResetPassword = () => {
        resetPassword(formState.email);
        hidePopups();
    };

    const confirmUserCallback = useRef<(() => void) | null>(null);

    return (
        <>
            <UserInfoModal
                visible={!!popup.viewAccountExplanationPopup}
                hidePopup={hidePopups}
                title={localization.textAccountAdministration}
                innerText={localization.textAccountAdminExplanation}
            />
            <UserInfoModal
                visible={!!popup.viewSignInExplanationPopup}
                hidePopup={hidePopups}
                title={localization.textSignIn}
                innerText={localization.textSignInExplanation}
            />
            <UserInfoModal
                visible={!!popup.viewSupplierCreationPopup}
                hidePopup={hidePopups}
                title={localization.textSupplierCreationRights}
                innerText={localization.textSupplierCreationRightsExplanation}
            />
            {localization.textInvoiceEditRightsExplanation && (
                <UserInfoModal
                    visible={!!popup.viewInvoiceEditRightsPopup}
                    hidePopup={hidePopups}
                    title={localization.textInvoiceEditRights}
                    innerText={localization.textInvoiceEditRightsExplanation}
                />
            )}
            {localization.textSupplierAdminExplanation && (
                <UserInfoModal
                    visible={!!popup.viewSupplierAdminRightsPopup}
                    hidePopup={hidePopups}
                    title={localization.textSupplierAdminRights}
                    innerText={localization.textSupplierAdminExplanation}
                />
            )}
            <UserInfoModal
                visible={!!popup.viewDDEditRightsPopup}
                hidePopup={hidePopups}
                title={localization.textDDEdit}
                innerText={localization.textDDEditRights}
            />
            <UserInfoModal
                visible={!!popup.viewDDAdministrationPopup}
                hidePopup={hidePopups}
                title={localization.textDDAdministration}
                innerText={localization.textDDAdministrationExplanation}
            />
            <UserInfoModal
                visible={!!popup.viewDDViewOnlyPopup}
                hidePopup={hidePopups}
                title={localization.textDDViewOnly}
                innerText={localization.textDDViewOnlyExplanation}
            />
            {auditLogData && !auditLogIsLoading && userId !== null && (
                <Dialog
                    title={'User History'}
                    open={showHistory}
                    handleOnClose={hideHistory}
                    content={<AuditLog logData={auditLogData} />}
                    actions={<Button onClick={hideHistory}>Close</Button>}
                    staticWidth={true}
                />
            )}
            <Dialog
                title={localization.textResetPassword}
                open={!!popup.viewResetUserPopup}
                handleOnClose={hidePopups}
                content={<Stack spacing={2}>{localization.textResetUserPasswordInfo}</Stack>}
                actions={
                    <Stack direction="row" justifyContent="flex-end" gap={1} sx={{ width: 1 }}>
                        <Button variant="secondary" onClick={hidePopups}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                hidePopups();
                                handleResetPassword();
                            }}
                        >
                            Reset
                        </Button>
                    </Stack>
                }
            />
            <CRUDTemplate
                headerContent={
                    <Box sx={{ pr: '32px', display: 'flex', gap: 1 }}>
                        {auditLogData && !auditLogIsLoading && (
                            <Button
                                testid="user-crud-history-btn"
                                variant="secondary"
                                onClick={() => setShowHistory(true)}
                            >
                                <HistoryIcon fontSize="large" />
                            </Button>
                        )}
                        {formState.id &&
                            formState.type !== projectConfig.UserType.SSO &&
                            !formState.autogenUnconfirmed && (
                                <Button color="success" onClick={() => setPopup({ viewResetUserPopup: true })}>
                                    Reset Password
                                </Button>
                            )}
                        {formState.id &&
                            formState.type !== projectConfig.UserType.SSO &&
                            formState.autogenUnconfirmed && (
                                <Button color="success" onClick={() => confirmUserCallback.current?.()}>
                                    Confirm User
                                </Button>
                            )}
                    </Box>
                }
                confirmDeletion
                testId="UserForm"
                searchPagePathName={searchPagePathName}
                createPermission="ManageUserSettings.CreateUser"
                editPermission="ManageUserSettings.EditUser"
                deletePermission="ManageUserSettings.DeleteUser"
                readPermission="ManageUserSettings.ListUsers"
                entityType="User"
                onFormStateChange={onFormStateChange}
                service={service}
                entityId={userId}
                onCreateSuccess={(newId) => {
                    history.replace(createEditPathName(newId));
                }}
                onDeleteSuccess={() => {
                    history.replace(searchPagePathName);
                }}
                onCancel={() => {
                    history.goBack();
                }}
                loadingMessage={loadingMessage}
                hideDeleteButton={!!workflowUserGroups || formState?.id === currentUser.id}
                renderFormLayout={(userFields, handleFieldChange) => {
                    const manager = options.find((u) => u.value === userFields.managersUserId) || null;
                    const substituteUser = options.find((u) => u.value === userFields.substituteUserId) || null;

                    confirmUserCallback.current = () => {
                        handleFieldChange('autogenUnconfirmed', false);
                    };

                    const toggleDynamicDiscounting = (checked: boolean) => {
                        setIsDynamicDiscountingChecked(checked);
                        const userRoles = (userFields.userRoles || []).filter(
                            (r) => !dynamicDiscountingPrivilegesIds.includes(r.RoleID)
                        );
                        handleFieldChange('userRoles', userRoles);
                    };

                    return [
                        <>
                            {userFields.type !== projectConfig.UserType.SSO && (
                                <>
                                    <TextField
                                        id="user-name"
                                        label={localization.textUserName}
                                        value={userFields.name}
                                        onChange={(ev) => handleFieldChange('name', ev.target.value)}
                                        required
                                        testId="user-crud-name"
                                    />
                                    <TextField
                                        id="user-email"
                                        label={localization.textEmailAddress}
                                        value={userFields.email}
                                        onChange={(ev) => handleFieldChange('email', ev.target.value)}
                                        required
                                        testId="user-crud-email"
                                    />
                                    <TextField
                                        id="user-remail"
                                        label={localization.textConfirmEmail}
                                        value={userFields.rEmail}
                                        onChange={(ev) => handleFieldChange('rEmail', ev.target.value)}
                                        required
                                        testId="user-crud-email"
                                    />
                                    <TextField
                                        id="user-phone"
                                        label={localization.textPhoneNumber}
                                        value={userFields.phone}
                                        onChange={(ev) => handleFieldChange('phone', ev.target.value)}
                                        testId="user-crud-phone"
                                    />
                                </>
                            )}
                            {userFields.type === projectConfig.UserType.SSO && (
                                <>
                                    <TextField
                                        id="user-name"
                                        label={localization.textUserName}
                                        value={userFields.name}
                                        required
                                        testId="user-crud-name"
                                        disabled
                                    />
                                    <TextField
                                        id="user-email"
                                        label={localization.textEmailAddress}
                                        value={userFields.email}
                                        required
                                        testId="user-crud-email"
                                        disabled
                                    />
                                </>
                            )}
                            {!!workflowUserGroups?.length && (
                                <>
                                    <TextField
                                        id="user-employee-number"
                                        label={localization.textEmployeeNumber}
                                        value={userFields.employeeNumber}
                                        onChange={(ev) => handleFieldChange('employeeNumber', ev.target.value)}
                                        testId="user-crud-employee-number"
                                    />
                                    <TextField
                                        id="user-title"
                                        label={localization.textTitle}
                                        value={userFields.title}
                                        onChange={(ev) => handleFieldChange('title', ev.target.value)}
                                        testId="user-crud-title"
                                    />
                                    <TextField
                                        id="user-department"
                                        label={localization.textDepartment}
                                        value={userFields.department}
                                        onChange={(ev) => handleFieldChange('department', ev.target.value)}
                                        testId="user-crud-department"
                                    />
                                    <TextField
                                        id="user-region"
                                        label={localization.textRegion}
                                        value={userFields.region}
                                        onChange={(ev) => handleFieldChange('region', ev.target.value)}
                                        testId="user-crud-region"
                                    />
                                    <TextField
                                        id="user-job-code"
                                        label={localization.textJobCode}
                                        value={userFields.jobClassification}
                                        onChange={(ev) => handleFieldChange('jobClassification', ev.target.value)}
                                        testId="user-crud-job-code"
                                    />
                                </>
                            )}
                            {!!options.length && (
                                <SortedSelect
                                    id="user-manager"
                                    label={localization.textManager}
                                    labelId="userManager"
                                    name="userManager"
                                    value={manager}
                                    options={options}
                                    nullOptionLabel="none"
                                    onChange={(v) => {
                                        handleFieldChange('managersUserId', v?.value ?? null);
                                    }}
                                    testId="user-crud-manager"
                                />
                            )}
                            {userFields.autogenUnconfirmed ? (
                                <div>
                                    <br />
                                    <EmbedHTML
                                        component={Typography}
                                        safeHTMLString={{
                                            type: 'SafeHTMLString',
                                            value: localization.textConfirmUserMessage,
                                        }}
                                    />
                                    <br />
                                </div>
                            ) : (
                                <>
                                    <h3>{localization.textUserPrivileges}</h3>
                                    <p>{localization.textUserPrivilegeExplanation}</p>
                                    <ul>
                                        {roles
                                            ?.filter((role) => {
                                                if (projectConfig.userPrivileges.includes(role.name)) {
                                                    return (
                                                        role.id !== RoleType.SupplierCreationRights || enableVendorEdits
                                                    );
                                                }
                                                return false;
                                            })
                                            .map((role) => (
                                                <li key={role.name}>
                                                    <Checkbox
                                                        name={role.name}
                                                        id={`role-checkbox${role.id}`}
                                                        checked={hasRole(userFields.userRoles, role.id)}
                                                        onChange={({ target: { checked } }) => {
                                                            const userRoles = [...userFields.userRoles].filter(
                                                                (r) => r.RoleID !== role.id
                                                            );
                                                            if (checked) {
                                                                userRoles.push({ RoleID: role.id });
                                                            }
                                                            handleFieldChange('userRoles', userRoles);
                                                        }}
                                                        labelAsNode={
                                                            toggleUserPrivilegePopups && (
                                                                <Button
                                                                    variant="text"
                                                                    id={`anchor-${role.name}`}
                                                                    onClick={() => toggleUserPrivilegePopups(role.id)}
                                                                    sx={{ textAlign: 'left' }}
                                                                >
                                                                    {/*@ts-ignore*/}
                                                                    {localization[`text${role.name}`]}
                                                                </Button>
                                                            )
                                                        }
                                                    />
                                                </li>
                                            ))}
                                    </ul>
                                    <h3>{localization.textUserGroups}</h3>
                                    <ul>
                                        {userGroups
                                            ?.sort((a, b) => {
                                                if (a.name < b.name) {
                                                    return -1;
                                                }
                                                if (b.name < a.name) {
                                                    return 1;
                                                }
                                                return 0;
                                            })
                                            .map((userGroup) => (
                                                <li key={userGroup.id}>
                                                    <Checkbox
                                                        name={userGroup.name}
                                                        id={`userGroup-checkbox-${userGroup.id}`}
                                                        checked={hasGroupMembership(
                                                            userFields.userGroupMemberships,
                                                            userGroup.id ?? 0
                                                        )}
                                                        onChange={({ target: { checked } }) => {
                                                            const userGroupMemberships = [
                                                                ...(userFields.userGroupMemberships || []),
                                                            ];
                                                            const userGroupMembershipInUserFieldsIndex =
                                                                userGroupMemberships.findIndex(
                                                                    (userGroupMembership) =>
                                                                        userGroupMembership.UserGroupId === userGroup.id
                                                                );

                                                            if (userGroupMembershipInUserFieldsIndex === -1) {
                                                                userGroupMemberships.push({
                                                                    Id: null,
                                                                    UserGroupId: userGroup.id ?? 0,
                                                                    checked,
                                                                });
                                                            } else {
                                                                userGroupMemberships[
                                                                    userGroupMembershipInUserFieldsIndex
                                                                ] = {
                                                                    ...userGroupMemberships[
                                                                        userGroupMembershipInUserFieldsIndex
                                                                    ],
                                                                    checked,
                                                                };
                                                            }
                                                            handleFieldChange(
                                                                'userGroupMemberships',
                                                                userGroupMemberships
                                                            );
                                                        }}
                                                        checkboxSx={{
                                                            '&.MuiCheckbox-root': {
                                                                paddingTop: 0,
                                                            },
                                                        }}
                                                        formControlLabelSx={{
                                                            alignItems: 'flex-start',
                                                        }}
                                                        fullWidth
                                                        labelAsNode={
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        marginBottom: 0,
                                                                        marginRight: '20px',
                                                                        lineHeight: 1,
                                                                    }}
                                                                >
                                                                    {userGroup.name}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        margin: 0,
                                                                        wordBreak: 'break-word',
                                                                        lineHeight: 1,
                                                                    }}
                                                                >
                                                                    {userGroup.description}
                                                                </Typography>
                                                            </Box>
                                                        }
                                                    />
                                                </li>
                                            ))}
                                    </ul>
                                </>
                            )}
                            {enableDynamicDiscounting && (
                                <>
                                    <h4>
                                        <Checkbox
                                            checked={showDynamicDiscounting}
                                            onChange={({ target: { checked } }) => toggleDynamicDiscounting(checked)}
                                            id="dynamic-disc-toggler"
                                            label={localization.textDynamicDiscountingPrivileges}
                                        />
                                    </h4>
                                    <div style={{ display: showDynamicDiscounting ? 'block' : 'none' }}>
                                        <p>{localization.textUserPrivilegeExplanation}</p>
                                        <div className="inputBlock" style={{ marginBottom: '2rem' }}>
                                            <RadioButtonGroup
                                                value={String(
                                                    (userFields.userRoles || []).find((userRole) =>
                                                        dynamicDiscountingPrivilegesIds.includes(userRole.RoleID)
                                                    )?.RoleID
                                                )}
                                                onChange={({ target: { value } }) => {
                                                    const roleId = parseInt(value);
                                                    const userRoles = (userFields.userRoles || []).filter(
                                                        (userRole) =>
                                                            !dynamicDiscountingPrivilegesIds.includes(userRole.RoleID)
                                                    );
                                                    userRoles.push({ RoleID: roleId });
                                                    handleFieldChange('userRoles', userRoles);
                                                }}
                                            >
                                                {roles
                                                    ?.filter((role) =>
                                                        dynamicDiscountingPrivilegesIds.includes(role.id)
                                                    )
                                                    .map((role) => (
                                                        <RadioButton
                                                            value={String(role.id)}
                                                            labelAsNode={
                                                                toggleDynamicDiscountingPopups && (
                                                                    <Button
                                                                        variant="text"
                                                                        id={`anchor-${role.name}`}
                                                                        onClick={() =>
                                                                            toggleDynamicDiscountingPopups(role.id)
                                                                        }
                                                                    >
                                                                        {/*@ts-ignore*/}
                                                                        {localization[`text${role.name}`]}
                                                                    </Button>
                                                                )
                                                            }
                                                        />
                                                    ))}
                                            </RadioButtonGroup>
                                        </div>
                                    </div>
                                </>
                            )}
                            {!!workflowUserGroups?.length && !!users && (
                                <div>
                                    <h4>
                                        <Checkbox
                                            checked={userFields.substituteUserActive}
                                            onChange={({ target: { checked } }) => {
                                                handleFieldChange('substituteUserActive', checked);
                                                handleFieldChange('substituteUserId', null);
                                            }}
                                            label={localization.textSubstituteUser}
                                        />
                                    </h4>
                                    {userFields.substituteUserActive && (
                                        <SortedSelect
                                            value={substituteUser}
                                            onChange={(v) => {
                                                handleFieldChange('substituteUserId', v?.value ?? null);
                                            }}
                                            options={options}
                                        />
                                    )}
                                </div>
                            )}
                            {!hideWorkflowGroupUsers && !!workflowUserGroups?.length && !!userFields.id && (
                                <div>
                                    <h4>{localization.textWorkflowGroupUser}</h4>
                                    <ul>
                                        {workflowUserGroups.map((wf) => (
                                            <li key={wf.Name}>
                                                <Checkbox
                                                    value=""
                                                    name={wf.ShortName}
                                                    checked={hasWorkflowGroupUser(userFields.workflowGroupUsers, wf.Id)}
                                                    onChange={({ target: { checked } }) => {
                                                        const workflowGroupUsers = [
                                                            ...(userFields.workflowGroupUsers || []),
                                                        ];
                                                        const workflowGroupUserIndex = workflowGroupUsers.findIndex(
                                                            (r) => r.WorkflowId === wf.Id
                                                        );
                                                        if (workflowGroupUserIndex === -1) {
                                                            workflowGroupUsers.push({
                                                                Id: null,
                                                                WorkflowId: wf.Id,
                                                                checked,
                                                            });
                                                        } else {
                                                            workflowGroupUsers[workflowGroupUserIndex] = {
                                                                ...workflowGroupUsers[workflowGroupUserIndex],
                                                                checked,
                                                            };
                                                        }
                                                        handleFieldChange('workflowGroupUsers', workflowGroupUsers);
                                                    }}
                                                    label={wf.ShortName}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                    <br />
                                </div>
                            )}
                        </>,
                    ];
                }}
            />
        </>
    );
};
