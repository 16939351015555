import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { CurrencyService } from '../CurrencyService';
import { Currency } from '../types';

export function useCurrency() {
    const { isLoading, error, data, isFetching } = useQuery(
        ['Currency'],
        async () => {
            const currencyService = new CurrencyService();
            return currencyService.getCurrencies();
        },
        {
            staleTime: Infinity,
            cacheTime: Infinity,
        }
    );

    return {
        isLoading: isLoading || isFetching,
        error,
        data,
    };
}

export function useCurrencyOptions(currencyList: Currency[]) {
    const currencyOptions = useMemo(() => {
        return currencyList.reduce<Record<string, any>>((record, c) => {
            const label = `${c.displayName} (${c.currencyType_US})`;

            return { ...record, [label]: c.currencyType };
        }, {});
    }, [currencyList]);

    return currencyOptions;
}
