import { useCallback, useContext } from 'react';
import { FileStoreContext } from '../contexts';

export function useFileStoreState() {
    const { filesRef, setFiles } = useContext(FileStoreContext);

    const getFiles = useCallback((sessionID: string) => filesRef.current[sessionID] || {}, [filesRef]);

    const addFile = useCallback(
        (sessionID: string, path: string, file: File) =>
            setFiles((prevState) => ({ ...prevState, [sessionID]: { ...prevState[sessionID], [path]: file } })),
        [setFiles]
    );

    const clearFiles = useCallback(
        (sessionID: string) =>
            setFiles((prevState) => {
                const { [sessionID]: discardedSession, ...otherSessions } = prevState;

                return otherSessions;
            }),
        [setFiles]
    );

    return {
        getFiles,
        addFile,
        clearFiles,
    };
}
