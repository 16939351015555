import { FC } from 'react';
import { Stack } from '@mui/material';
import { BusinessDocType } from '../../../../types';
import { TooltipIcon } from '../../../../ui';
import { getDocumentTypeName } from '../../utils';

export interface ProcessedAgainstRevisionBlurbProps {
    relatedDocumentType: BusinessDocType;

    relatedDocumentNumber: string;

    currentDocumentType: BusinessDocType;
}

export const ProcessedAgainstRevisionBlurb: FC<ProcessedAgainstRevisionBlurbProps> = ({
    relatedDocumentType,
    relatedDocumentNumber,
    currentDocumentType,
}) => {
    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            {getDocumentTypeName(currentDocumentType)} processed revision
            <TooltipIcon
                message={`${getDocumentTypeName(
                    currentDocumentType
                )} was processed against this revision of ${getDocumentTypeName(
                    relatedDocumentType
                )} #${relatedDocumentNumber}. Viewing this revision may help understand the reason for validation failures that occurred, if any, when processing the ${getDocumentTypeName(
                    currentDocumentType
                )}.`}
                icon="Help"
            />
        </Stack>
    );
};
