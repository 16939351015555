import { FCNC } from '../FCNC';

export const TransceptaLogoDarkBlue: FCNC = () => {
    return (
        <svg width="199" height="44" viewBox="0 0 199 44" fill="none" xmlns="http://www.w3.org/2000/svg" role="img">
            <title>Transcepta</title>
            <path
                d="M10.4851 12.2032L14.0088 8.89438V30.9816C14.0088 32.0559 14.1377 32.7435 14.3525 33.1302C14.5674 33.5169 14.9541 33.6888 15.5557 33.6888C16.1573 33.6888 16.8019 33.5599 17.4465 33.2591C18.091 32.9583 18.7356 32.5286 19.4231 31.927V35.4506C18.6067 35.8804 17.8762 36.2241 17.1886 36.439C16.5441 36.6538 15.8995 36.7398 15.2979 36.7398C13.622 36.7398 12.4188 36.2671 11.6453 35.3217C10.8718 34.3764 10.4851 32.8724 10.4851 30.8527V12.2032Z"
                fill="#212845"
            />
            <path
                d="M25.4822 36.1816V16.028L29.0058 14.6959V21.9151H29.5215C30.6387 19.9814 31.756 18.3914 32.9592 17.1453C34.1624 15.9421 35.3656 15.0826 36.5688 14.6529V20.6689C36.3109 20.6259 36.0531 20.583 35.8383 20.583C35.6234 20.583 35.4515 20.54 35.2796 20.54C34.2483 20.54 33.26 20.8408 32.2716 21.3994C31.2833 22.001 30.209 22.9034 29.0488 24.2355V36.0956H25.4822V36.1816Z"
                fill="#212845"
            />
            <path
                d="M52.6831 33.346H52.2534C51.0502 34.6351 49.9759 35.4946 49.1165 35.9672C48.257 36.4399 47.2687 36.6548 46.1944 36.6548C44.5615 36.6548 43.2294 36.1391 42.2411 35.1078C41.2527 34.0765 40.7371 32.7014 40.7371 31.0255C40.7371 28.6621 41.8113 26.8144 43.9599 25.3963C46.1085 23.9782 48.9876 23.2477 52.6831 23.2048V21.0992C52.6831 19.9819 52.4253 19.2084 51.9096 18.6498C51.3939 18.1341 50.5775 17.8763 49.5032 17.8763C48.5578 17.8763 47.5695 18.0482 46.5812 18.392C45.5928 18.7357 44.5615 19.2514 43.5302 19.896L41.8973 17.1888C43.3153 16.3294 44.6474 15.7278 45.9366 15.298C47.2257 14.9113 48.5149 14.6964 49.804 14.6964C51.9955 14.6964 53.6285 15.1262 54.6598 16.0286C55.6911 16.888 56.2067 18.2631 56.2067 20.1538V31.9709C56.2067 32.5725 56.3356 33.0022 56.5935 33.303C56.8513 33.6038 57.238 33.7327 57.7537 33.7327C57.7967 33.7327 57.8396 33.7327 57.9686 33.7327C58.0545 33.7327 58.1404 33.7327 58.1834 33.7327V36.5259H57.5818C56.2497 36.5259 55.1754 36.268 54.4019 35.7524C53.5855 35.2367 53.0269 34.4203 52.6831 33.346ZM52.6831 30.9396V25.3533C50.0189 25.4393 47.9562 25.9549 46.5382 26.8573C45.0772 27.7597 44.3896 28.9629 44.3896 30.5099C44.3896 31.5842 44.6904 32.4436 45.292 33.0882C45.8936 33.7327 46.7101 34.0335 47.7414 34.0335C48.4289 34.0335 49.2024 33.7757 50.0189 33.26C50.7923 32.7444 51.6947 31.9709 52.6831 30.9396Z"
                fill="#212845"
            />
            <path
                d="M64.5427 36.1816V16.028L68.0664 14.6959V19.079H68.4101C69.6563 17.6179 70.8165 16.5007 71.9338 15.7702C73.051 15.0397 74.0394 14.6529 75.0277 14.6529C76.2739 14.6529 77.2622 15.1256 77.9927 16.1139C78.7233 17.1023 79.11 18.4344 79.11 20.0673V36.0956H75.5864V21.0986C75.5864 19.8525 75.4145 18.9501 75.1137 18.3914C74.8129 17.8328 74.2972 17.532 73.5667 17.532C73.3089 17.532 73.051 17.575 72.7932 17.6609C72.5354 17.7469 72.2346 17.8758 71.9338 18.0477C71.633 18.2625 71.3322 18.4774 71.0314 18.7352C70.7306 18.993 70.4298 19.2938 70.086 19.6376L70.0431 19.6806C68.7539 21.0557 68.1093 21.8721 68.1093 22.1299V36.2245H64.5427V36.1816Z"
                fill="#212845"
            />
            <path
                d="M85.0403 33.7316L86.974 31.4111C87.8764 32.2705 88.7358 32.9151 89.5523 33.3448C90.3687 33.7316 91.1422 33.9464 91.9157 33.9464C93.0329 33.9464 93.8924 33.6886 94.5369 33.13C95.1815 32.5713 95.4823 31.8408 95.4823 30.8955C95.4823 30.079 95.3104 29.4344 94.9237 28.9618C94.5799 28.4891 93.8064 27.9304 92.6462 27.3718L89.896 26.0397C88.6069 25.3951 87.6615 24.6646 86.974 23.7193C86.3294 22.8169 85.9857 21.7855 85.9857 20.6253C85.9857 18.9065 86.5873 17.4884 87.8334 16.4141C89.0796 15.3398 90.6695 14.7812 92.6462 14.7812C93.5486 14.7812 94.451 14.9101 95.3104 15.2109C96.1699 15.5117 97.0723 15.9414 97.9747 16.5001L96.9433 19.2932C96.2128 18.6916 95.5253 18.2619 94.7948 18.0041C94.0643 17.7462 93.2908 17.6173 92.4743 17.6173C91.443 17.6173 90.6266 17.8322 90.0679 18.2619C89.5093 18.6916 89.2085 19.2932 89.2085 20.0667C89.2085 20.7113 89.3804 21.2269 89.7242 21.6566C90.0679 22.0863 90.6266 22.4731 91.4 22.8598L93.7635 24.0201C95.6112 24.9224 96.8574 25.8248 97.5879 26.7702C98.3184 27.7156 98.6622 28.9618 98.6622 30.4228C98.6622 32.2276 98.0176 33.6886 96.7285 34.9348C95.4393 36.138 93.8494 36.7396 91.9157 36.7396C90.7984 36.7396 89.6812 36.4817 88.521 36.009C87.3607 35.4074 86.2005 34.6769 85.0403 33.7316Z"
                fill="#212845"
            />
            <path
                d="M115.163 20.7984C114.905 19.81 114.476 19.0365 113.96 18.5639C113.401 18.0912 112.671 17.8333 111.769 17.8333C110.35 17.8333 109.276 18.4779 108.546 19.81C107.815 21.1421 107.428 23.0758 107.428 25.6112C107.428 28.2324 107.815 30.2091 108.546 31.4982C109.276 32.7874 110.436 33.4319 111.94 33.4319C113.058 33.4319 113.874 33.1311 114.476 32.4866C115.077 31.842 115.421 30.8537 115.55 29.5645L118.816 30.9826C118.472 32.8303 117.699 34.2054 116.495 35.1938C115.292 36.1391 113.702 36.6548 111.769 36.6548C109.233 36.6548 107.257 35.7094 105.881 33.7757C104.463 31.842 103.776 29.1348 103.776 25.6541C103.776 22.1735 104.463 19.4663 105.881 17.5755C107.257 15.6848 109.233 14.6964 111.769 14.6964C113.316 14.6964 114.691 15.0832 115.765 15.8567C116.882 16.6302 117.742 17.7904 118.386 19.3373L115.163 20.7984Z"
                fill="#212845"
            />
            <path
                d="M138.024 33.9906C137.121 34.893 136.176 35.5805 135.145 36.0102C134.113 36.4399 132.91 36.6548 131.621 36.6548C128.957 36.6548 126.894 35.7524 125.433 33.9476C123.972 32.1428 123.241 29.5645 123.241 26.2128C123.241 22.7321 124.015 19.9389 125.519 17.8333C127.023 15.7278 129 14.6964 131.492 14.6964C133.769 14.6964 135.66 15.7278 137.078 17.7904C138.496 19.853 139.27 22.6032 139.27 26.0409H126.894V26.3846C126.894 28.834 127.281 30.5958 128.054 31.7561C128.828 32.9163 130.074 33.4749 131.75 33.4749C132.394 33.4749 132.996 33.346 133.641 33.0452C134.285 32.7444 134.93 32.3577 135.617 31.799L138.024 33.9906ZM126.98 23.5915H135.746C135.531 21.7437 135.016 20.3257 134.285 19.3373C133.555 18.392 132.566 17.8763 131.363 17.8763C130.16 17.8763 129.171 18.349 128.441 19.2944C127.71 20.2827 127.195 21.7008 126.98 23.5915Z"
                fill="#212845"
            />
            <path
                d="M145.2 43.3583V16.0286L148.766 14.6964V18.8217H149.153C150.184 17.3607 151.173 16.2864 152.075 15.6418C152.978 14.9972 153.923 14.6964 154.954 14.6964C156.974 14.6964 158.607 15.6848 159.81 17.6615C161.013 19.6381 161.658 22.3024 161.658 25.6541C161.658 29.0059 161.056 31.7131 159.81 33.6898C158.607 35.6664 156.974 36.6548 154.954 36.6548C153.923 36.6548 152.978 36.311 152.075 35.6664C151.173 35.0219 150.184 33.9476 149.196 32.4436H148.809V43.3583H145.2ZM148.766 25.6541C148.766 28.0175 149.239 29.9513 150.227 31.4553C151.216 32.9592 152.419 33.6898 153.88 33.6898C155.212 33.6898 156.243 33.0022 156.931 31.6701C157.618 30.338 157.962 28.3183 157.962 25.6541C157.962 22.9899 157.618 21.0132 156.974 19.6811C156.286 18.349 155.298 17.7044 153.923 17.7044C152.419 17.7044 151.216 18.4349 150.227 19.9389C149.282 21.4 148.766 23.2907 148.766 25.6541Z"
                fill="#212845"
            />
            <path
                d="M167.459 12.2032L170.983 8.89438V30.9816C170.983 32.0559 171.112 32.7435 171.327 33.1302C171.542 33.5169 171.928 33.6888 172.53 33.6888C173.131 33.6888 173.776 33.5599 174.421 33.2591C175.065 32.9583 175.71 32.5286 176.397 31.927V35.4506C175.581 35.8804 174.85 36.2241 174.163 36.439C173.518 36.6538 172.874 36.7398 172.272 36.7398C170.596 36.7398 169.393 36.2671 168.619 35.3217C167.846 34.3764 167.459 32.8724 167.459 30.8527V12.2032Z"
                fill="#212845"
            />
            <path
                d="M193.5 33.346H193.07C191.867 34.6351 190.793 35.4946 189.933 35.9672C189.074 36.4399 188.085 36.6548 187.011 36.6548C185.378 36.6548 184.046 36.1391 183.058 35.1078C182.069 34.0765 181.554 32.7014 181.554 31.0255C181.554 28.6621 182.628 26.8144 184.777 25.3963C186.925 23.9782 189.804 23.2477 193.5 23.2048V21.0992C193.5 19.9819 193.242 19.2084 192.726 18.6498C192.211 18.1341 191.394 17.8763 190.32 17.8763C189.374 17.8763 188.386 18.0482 187.398 18.392C186.409 18.7357 185.378 19.2514 184.347 19.896L182.714 17.1888C184.132 16.3294 185.464 15.7278 186.753 15.298C188.042 14.9113 189.332 14.6964 190.621 14.6964C192.812 14.6964 194.445 15.1262 195.476 16.0286C196.508 16.888 197.023 18.2631 197.023 20.1538V31.9709C197.023 32.5725 197.152 33.0022 197.41 33.303C197.668 33.6038 198.055 33.7327 198.57 33.7327C198.613 33.7327 198.656 33.7327 198.785 33.7327C198.871 33.7327 198.957 33.7327 199 33.7327V36.5259H198.398C197.066 36.5259 195.992 36.268 195.219 35.7524C194.359 35.2367 193.801 34.4203 193.5 33.346ZM193.5 30.9396V25.3533C190.836 25.4393 188.773 25.9549 187.355 26.8573C185.894 27.7597 185.206 28.9629 185.206 30.5099C185.206 31.5842 185.507 32.4436 186.109 33.0882C186.71 33.7327 187.527 34.0335 188.558 34.0335C189.246 34.0335 190.019 33.7757 190.836 33.26C191.609 32.7444 192.511 31.9709 193.5 30.9396Z"
                fill="#212845"
            />
            <path
                opacity="0.5"
                d="M68.7541 3.69553C42.4986 3.69553 17.0166 13.3641 0 28.1032C14.6962 11.1296 38.932 0 66.8634 0C87.3607 0 103.561 5.28547 115.378 14.0516C103.217 7.51998 87.7045 3.69553 68.7541 3.69553Z"
                fill="#212845"
            />
            <path
                opacity="0.3"
                d="M116.151 3.69553C142.407 3.69553 167.889 13.3641 184.906 28.1032C170.252 11.1296 145.974 0 118.085 0C97.5449 0 81.3877 5.28547 69.5706 14.0516C81.6885 7.51998 97.2011 3.69553 116.151 3.69553Z"
                fill="#212845"
            />
            <path
                d="M173.855 7.46484H172.941V10H172.568V7.46484H171.656V7.15625H173.855V7.46484ZM174.762 7.15625L175.691 9.47656L176.621 7.15625H177.107V10H176.732V8.89258L176.768 7.69727L175.834 10H175.547L174.615 7.70312L174.652 8.89258V10H174.277V7.15625H174.762Z"
                fill="#212845"
            />
        </svg>
    );
};
