import { useQuery } from '@tanstack/react-query';
import { useCurrentCompanyId } from '../../PortalUserService';
import { UserService } from '../UserService';

export const useUsers = () => {
    const companyId = useCurrentCompanyId();
    const { data, isFetching, error } = useQuery(['Users', companyId], async () => {
        const userService = new UserService();
        return userService.getUsers(companyId);
    });

    return {
        users: data ?? [],
        isFetching,
        error,
    };
};
