import { NumericMaskedTextField } from './NumericMaskedTextField';
import { FCNC } from '../FCNC';
import { getLocalePlacements } from './LocaleOptions';
import { BaseTextFieldProps } from '@mui/material';
import { ITooltipIconProps } from '../Tooltip';

const currencySymbols = new Map([
    ['USD', '$'],
    ['GBP', '£'],
    ['CAD', 'CA$'],
    ['EUR', '€'],
]);

export interface ICurrencyFieldProps {
    id?: string;

    locale: string;

    currencyCode: string;

    value: number | null;

    maxValue?: number;

    onChange: (value: number | null) => void;

    label?: string;

    required?: boolean;

    sx?: BaseTextFieldProps['sx'];

    readonly?: boolean;

    onDoubleClick?: BaseTextFieldProps['onDoubleClick'];

    testId?: string;

    tooltip?: ITooltipIconProps;
}

export const CurrencyField: FCNC<ICurrencyFieldProps> = ({
    id,
    value,
    onChange,
    locale,
    currencyCode,
    maxValue,
    required,
    sx,
    readonly,
    label,
    onDoubleClick,
    testId,
    tooltip,
}) => {
    const symbol = currencySymbols.get(currencyCode);

    if (!symbol) {
        throw new Error(`Unknown Currency Code: ${currencyCode}`);
    }

    const localeOptions = { symbol, ...getLocalePlacements(locale) };

    return (
        <NumericMaskedTextField
            id={id}
            value={value}
            onChange={onChange}
            localeOptions={localeOptions}
            maxValue={maxValue}
            required={required}
            sx={sx}
            readonly={readonly}
            label={label}
            onDoubleClick={onDoubleClick}
            scale={2}
            testId={testId}
            tooltip={tooltip}
        />
    );
};
