import { useQuery } from '@tanstack/react-query';
import { useCurrentCompanyId } from '../../../UserService';
import { DocumentTypeService } from '../DocumentTypeService';

export function useTradingPartnersThatReceiveRemittanceInstructions() {
    const supplierCompanyId = useCurrentCompanyId();
    const { data: tradingParterNames, isLoading } = useQuery(
        ['TradingPartnersThatReceiveRemittanceInstructions', supplierCompanyId],
        () => {
            const service = new DocumentTypeService();
            return service.getTradingPartnersThatReceiveRemittanceInstructions(supplierCompanyId);
        }
    );

    return {
        tradingParterNames,
        isLoading,
    };
}
