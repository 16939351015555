/**
 * Throw this error with the value you are performing case-analysis on in a switch statement
 * to ensure a case is never missed. If a case is somehow missed at runtime, an exception will
 * be thrown. TypeScript should give an error message about the missing case if a code modification elsewhere
 * leads to a missing case.
 *
 * @example
 * switch (value.type) {
 *     case 'Foo':
 *         // handle Foo
 *     case 'Bar':
 *         // handle Bar
 *     default:
 *         // You will get a type error on following line if a case is missing
 *         throw new UnreachableCase(value);
 *  }
 *
 */
export class UnexpectedCase extends Error {
    /**
     * Construct an UnexpectedCase error object
     *
     * @example
     * switch (value.type) {
     *     case 'Foo':
     *         // handle Foo
     *     case 'Bar':
     *         // handle Bar
     *     default:
     *         // You will get a type error on following line if a case is missing
     *         throw new UnreachableCase(value);
     *  }
     *
     */
    constructor(value: never) {
        super(`Did not expect to reach case: ${JSON.stringify(value)}`);
        Object.setPrototypeOf(this, UnexpectedCase.prototype);
    }
}
