import { ReactNode } from 'react';
import { EventEmitter } from '../../utils';
import { IAlertProps } from '../Alert';

export interface IToast {
    id: number;

    message: ReactNode;

    severity: IAlertProps['severity'];

    autoHideDuration?: number;
}

export class Toaster {
    private emitter = new EventEmitter();

    private toasts: IToast[] = [];

    private idCounter = 0;

    constructor() {
        this.subscribe = this.subscribe.bind(this);
    }

    subscribe(cb: () => void) {
        return this.emitter.subscribe(cb);
    }

    makeToast(toastData: Omit<IToast, 'id'>) {
        this.toasts = [...this.toasts, { id: this.generateId(), ...toastData }];
        this.emitter.notify();
    }

    throwAwayToast(id: number) {
        this.toasts = this.toasts.filter((x) => x.id !== id);
        this.emitter.notify();
    }

    getToasts() {
        return this.toasts;
    }

    private generateId() {
        this.idCounter += 1;
        return this.idCounter;
    }
}

export const toaster = new Toaster();
