import { createTheme, Theme } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import type {} from '@mui/x-data-grid/themeAugmentation';

// allow use styles to have access to other colors
// @ts-ignore
export interface ICustomTheme extends Theme {
    palette: {
        // @ts-ignore
        mode: string;
        [key: string]: {
            [key: string]: string;
        };
    };
}

const typography: TypographyOptions = {
    fontFamily: 'Open Sans',
    h1: {
        fontSize: 28,
        lineHeight: '36px',
    },
    h2: {
        fontSize: 24,
        lineHeight: '32px',
    },
    h3: {
        fontSize: 22,
        lineHeight: '28px',
        fontWeight: 700,
    },
    h4: {
        fontSize: 22,
        lineHeight: '28px',
        fontWeight: 700,
    },
    h5: {
        fontSize: 22,
        lineHeight: '28px',
        fontWeight: 700,
    },
    h6: {
        fontSize: 22,
        lineHeight: '28px',
        fontWeight: 700,
    },
    subtitle1: {
        fontSize: 16,
        lineHeight: '24px',
        letterSpacing: '0.15px',
        fontWeight: 700,
    },
    subtitle2: {
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '0.1px',
        fontWeight: 600,
    },
    body1: {
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '0.25px',
    },
    body2: {
        fontSize: 12,
        lineHeight: '16px',
        letterSpacing: '0.4px',
    },
    caption: {
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '0.1px',
        fontWeight: 700,
    },
    button: {
        fontSize: 14,
        lineHeight: '20px',
        letterSpacing: '0.1px',
        fontWeight: 600,
        textTransform: 'none',
    },
};

const breakpoints = {
    values: {
        xs: 0,
        sm: 768,
        md: 1024,
        lg: 1200,
        xl: 1536,
    },
};

export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            '900': '#031828',
            '800': '#06314F',
            '700': '#0A4977',
            '600': '#0D629E',
            '500': '#0E6EB2',
            '400': '#107AC6',
            '300': '#4095D1',
            '200': '#70AFDD',
            '100': '#9FCAE8',
            '50': '#CFE4F4',
            main: '#0E6EB2',
            dark: '#06314F',
            light: '#70AFDD',
        },
        secondary: {
            '900': '#031828',
            '800': '#06314F',
            '700': '#0A4977',
            '600': '#0D629E',
            '500': '#0E6EB2',
            '400': '#107AC6',
            '300': '#4095D1',
            '200': '#70AFDD',
            '100': '#9FCAE8',
            '50': '#CFE4F4',
            main: '#0E6EB2',
            dark: '#06314F',
            light: '#70AFDD',
        },
        error: {
            main: '#B63425',
            '900': '#49150F',
            '800': '#6D1F16',
            '700': '#922A1E',
            '600': '#A42F21',
            '500': '#B63425',
            '400': '#C55D51',
            '300': '#D3857C',
            '200': '#E2AEA8',
            '100': '#F0D6D3',
            '50': '#F8EBE9',
        },
        info: {
            '900': '#031828',
            '800': '#06314F',
            '700': '#0A4977',
            '600': '#0D629E',
            '500': '#0E6EB2',
            '400': '#107AC6',
            '300': '#4095D1',
            '200': '#70AFDD',
            '100': '#9FCAE8',
            '50': '#CFE4F4',
            main: '#0E6EB2',
            dark: '#06314F',
            light: '#70AFDD',
        },
        success: {
            main: '#52BD64',
            '900': '#214C28',
            '800': '#31713C',
            '700': '#429750',
            '600': '#4AAA5A',
            '500': '#52BD64',
            '400': '#75CA83',
            '300': '#97D7A2',
            '200': '#BAE5C1',
            '100': '#DCF2E0',
            '50': '#EEF8EF',
        },
        warning: {
            main: '#FFCC48',
            '900': '#66521D',
            '800': '#997A2B',
            '700': '#CCA33A',
            '600': '#CCA33A',
            '500': '#FFCC48',
            '400': '#FFD66D',
            '300': '#FFE091',
            '200': '#FFEBB6',
            '100': '#FFF5DA',
            '50': '#FFFAED',
        },
        background: {
            paper: '#F8F8F9',
            default: '#FBFCFC',
        },
        text: {
            primary: '#25292F',
        },
        grey: {
            '900': '#41444C',
            '800': '#626671',
            '700': '#828897',
            '600': '#9399AA',
            '500': '#A3AABD',
            '400': '#B5BBCA',
            '300': '#C8CCD7',
            '200': '#DADDE5',
            '100': '#EDEEF2',
            '50': '#F6F6F8',
        },
    },
    typography,
    breakpoints,
});

lightTheme.components = {
    MuiButtonBase: {
        defaultProps: {
            disableRipple: true,
        },
    },
    MuiBreadcrumbs: {
        styleOverrides: {
            root: lightTheme.palette.text.primary,
            li: {
                a: {
                    textDecoration: 'none',
                    color: lightTheme.palette.text.primary,
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                },
                p: {
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                },
            },
        },
    },
    MuiBackdrop: {
        styleOverrides: {
            root: {
                backgroundColor: 'rgba(13, 98, 158, 0.50)',
            },
        },
    },
    MuiDialog: {
        styleOverrides: {
            paper: {
                maxWidth: 1200,
            },
        },
    },
    MuiTab: {
        styleOverrides: {
            root: {
                '&.MuiTabs-scroller': {
                    '&.MuiTabs-indicator': {
                        height: 6,
                        bgcolor: 'primary.400',
                    },
                },
            },
        },
    },
    MuiAccordionSummary: {
        styleOverrides: {
            root: {
                '& .MuiAccordionSummary-content': {
                    justifyContent: 'space-between',
                    mt: '10px',
                    mb: '10px',
                },
            },
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            input: {
                padding: '6.5px 12px !important',
            },
        },
    },
    MuiAutocomplete: {
        styleOverrides: {
            input: {
                padding: '0px 4px !important',
                height: '1.125em !important',
                width: '100%',
            },
        },
    },
    MuiRadio: {
        styleOverrides: {
            root: {
                '&:hover': {
                    backgroundColor: 'rgba(16, 122, 198, 0.12)',
                },
                '&:active': {
                    backgroundColor: 'rgba(16, 122, 198, 0.24)',
                },
                '&:focus': {
                    border: '1px solid',
                    borderColor: '#031828',
                },
            },
        },
    },
};

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            '900': '#031828',
            '800': '#06314F',
            '700': '#0A4977',
            '600': '#0D629E',
            '500': '#0E6EB2',
            '400': '#107AC6',
            '300': '#4095D1',
            '200': '#70AFDD',
            '100': '#9FCAE8',
            '50': '#CFE4F4',
            main: '#0E6EB2',
            dark: '#06314F',
            light: '#70AFDD',
        },
        secondary: {
            '900': '#031828',
            '800': '#06314F',
            '700': '#0A4977',
            '600': '#0D629E',
            '500': '#0E6EB2',
            '400': '#107AC6',
            '300': '#4095D1',
            '200': '#70AFDD',
            '100': '#9FCAE8',
            '50': '#CFE4F4',
            main: '#0E6EB2',
            dark: '#06314F',
            light: '#70AFDD',
        },
        error: {
            main: '#B63425',
            '900': '#49150F',
            '800': '#6D1F16',
            '700': '#922A1E',
            '600': '#A42F21',
            '500': '#B63425',
            '400': '#C55D51',
            '300': '#D3857C',
            '200': '#E2AEA8',
            '100': '#F0D6D3',
            '50': '#F8EBE9',
        },
        info: {
            '900': '#031828',
            '800': '#06314F',
            '700': '#0A4977',
            '600': '#0D629E',
            '500': '#0E6EB2',
            '400': '#107AC6',
            '300': '#4095D1',
            '200': '#70AFDD',
            '100': '#9FCAE8',
            '50': '#CFE4F4',
            main: '#0E6EB2',
            dark: '#06314F',
            light: '#70AFDD',
        },
        success: {
            main: '#52BD64',
            '900': '#214C28',
            '800': '#31713C',
            '700': '#429750',
            '600': '#4AAA5A',
            '500': '#52BD64',
            '400': '#75CA83',
            '300': '#97D7A2',
            '200': '#BAE5C1',
            '100': '#DCF2E0',
            '50': '#EEF8EF',
        },
        warning: {
            main: '#52BD64',
            '900': '#66521D',
            '800': '#997A2B',
            '700': '#CCA33A',
            '600': '#CCA33A',
            '500': '#FFCC48',
            '400': '#FFD66D',
            '300': '#FFE091',
            '200': '#FFEBB6',
            '100': '#FFF5DA',
            '50': '#FFFAED',
        },
        background: {
            paper: '#1f1f1f',
            default: '#2a2c34',
        },
        grey: {
            '900': '#2e3036',
            '800': '#4a4d56',
            '700': '#666a76',
            '600': '#787b8a',
            '500': '#8a8d9e',
            '400': '#9ba0b1',
            '300': '#acb1c2',
            '200': '#bdc2d3',
            '100': '#cfd3e4',
            '50': '#d8d9e0',
        },
        text: {
            primary: '#FCFEFF',
        },
    },
    typography,
    breakpoints,
});

darkTheme.components = {
    MuiButtonBase: {
        defaultProps: {
            disableRipple: true,
        },
    },
    MuiDialog: {
        styleOverrides: {
            paper: {
                maxWidth: 1200,
                backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08))',
            },
        },
    },
    MuiAccordionSummary: {
        styleOverrides: {
            root: {
                '& .MuiAccordionSummary-content': {
                    justifyContent: 'space-between',
                },
            },
        },
    },
    MuiBackdrop: {
        styleOverrides: {
            root: {
                backgroundColor: 'rgba(1, 1, 20, 0.50)',
            },
        },
    },
    MuiBreadcrumbs: {
        styleOverrides: {
            root: darkTheme.palette.text.primary,
            li: {
                a: {
                    textDecoration: 'none',
                    color: darkTheme.palette.text.primary,
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                },
                p: {
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                },
            },
        },
    },
    MuiInputLabel: {
        styleOverrides: {
            root: {
                top: -8,
            },
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            input: {
                padding: '6.5px 12px !important',
            },
        },
    },
    MuiAutocomplete: {
        styleOverrides: {
            input: {
                padding: '0px 4px !important',
                height: '1.125em !important',
            },
        },
    },
    MuiRadio: {
        styleOverrides: {
            root: {
                '&:hover': {
                    backgroundColor: 'rgba(16, 122, 198, 0.12)',
                },
                '&:active': {
                    backgroundColor: 'rgba(16, 122, 198, 0.24)',
                },
                '&:focus': {
                    border: '1px solid',
                    borderColor: '#031828',
                },
            },
        },
    },
};
