import { Link as MuiLink, LinkProps } from '@mui/material';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface ILinkProps extends LinkProps {
    to: string;
    replace?: boolean;
}

export const Link: FC<ILinkProps> = (props) => {
    /* eslint-disable react/jsx-props-no-spreading */
    return (
        <MuiLink component={RouterLink} {...props}>
            {props.children}
        </MuiLink>
    );
};
