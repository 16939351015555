import LogRocket from 'logrocket';
import getFullConfig from './getFullConfig';
import { handlePageChange } from './sessionURL';
import { isRecordingEnabledOnDevice } from './conditionalRecording';

let isInitialized = false;

/**
 * Initializes LogRocket using the appConfig and current version key.
 */
export function init() {
    const fullConfig = getFullConfig();

    if (!fullConfig) {
        return;
    }
    if (!isRecordingEnabledOnDevice()) {
        return;
    }
    if (isInitialized) {
        return;
    }

    const { config, versionKey } = fullConfig;

    LogRocket.init(config.projectId, {
        ...config.initOptions,
        release: versionKey,
    });

    isInitialized = true;

    handlePageChange();
}

/**
 * Run the callback if LogRocket integration is enabled.
 * @param cb that does something with LogRocket
 */
export function runIfEnabled(cb: (lr: typeof LogRocket) => void) {
    if (!isInitialized) {
        return;
    }

    try {
        cb(LogRocket);
    } catch (e) {
        console.error('Error occurred in LogRocket integration code', e);
    }
}

/**
 * Restarts the session if the configuration allows doing so.
 */
export function handleLogout() {
    const config = getFullConfig();

    if (!config) {
        return;
    }

    if (!config.config.resetSessionOnLogOut) {
        return;
    }

    LogRocket.startNewSession();
}
