import { GridSortModel } from '@mui/x-data-grid';
import { SimpleFieldRendererViewModel } from '../../FieldRenderer';

export interface ISearchFormState {
    enteredCriteria: SimpleFieldRendererViewModel<any>;

    appliedCriteria: SimpleFieldRendererViewModel<any>;

    sortModel: GridSortModel;

    pageSize: number | undefined;

    page: number;
}

export const initialSearchFormState: ISearchFormState = {
    enteredCriteria: new SimpleFieldRendererViewModel({}),
    appliedCriteria: new SimpleFieldRendererViewModel({}),
    sortModel: [],
    pageSize: undefined,
    page: 0,
};
