/* eslint-disable class-methods-use-this */
class ThreadingService {
    execute<A extends any[], T>(fn: (...args: A) => T, ...args: A): Promise<T> {
        if (!this.environmentSupportsWebWorkers()) {
            return this.executeSynchronously(fn, ...args);
        }

        return this.executeInWorker(fn, ...args);
    }

    executeSynchronously<A extends any[], T>(fn: (...args: A) => T, ...args: A): Promise<T> {
        try {
            return Promise.resolve(fn(...args));
        } catch (e) {
            return Promise.reject(e);
        }
    }

    private environmentSupportsWebWorkers() {
        return !!window.Worker;
    }

    private executeInWorker<A extends any[], T>(fn: (...args: A) => T, ...args: A): Promise<T> {
        return new Promise((res, rej) => {
            const worker = this.createWorker(fn);
            worker.onmessage = (e) => {
                const result = JSON.parse(e.data);

                if (result.type === 'error') {
                    rej(result.reason);
                } else {
                    res(result.value);
                }
            };

            worker.postMessage(JSON.stringify(args));
        });
    }

    private createWorker(fn: any) {
        const workerURL = this.functionToStringURL(fn);
        const worker = new Worker(workerURL);
        URL.revokeObjectURL(workerURL);
        return worker;
    }

    private functionToStringURL(fn: any) {
        return URL.createObjectURL(new Blob([this.wrapFunction(fn)], { type: 'application/javascript' }));
    }

    private wrapFunction(fn: any) {
        // Below the function is wrapped in a WebWorker shell that handles all the communication
        // back to the parent thread. The wrapping is done carefully to ensure that the function
        // does not capture any variables we are creating in our wrapper, which could potentially allow
        // it to become dependent on the wrapping implementation.
        return `
            var getWrappedFunction = (function () {
                return (${fn.toString()});
            });

            (function() {
                function sendFailure(reason) {
                    console.error('Error in worker thread', reason);

                    self.postMessage(JSON.stringify({
                        type: 'error',
                        reason: reason.toString(),
                    }));
                }
    
                function sendSuccess(value) {
                    self.postMessage(JSON.stringify({
                        type: 'success',
                        value: value,
                    }));
                }
    
                self.onmessage = function (e) {
                    var args = JSON.parse(e.data);

                    try {
                        var result = getWrappedFunction().apply(null, args);
                        if (typeof result === 'object' && 'then' in result) {
                            result.then(sendSuccess, sendFailure);
                        } else {
                            sendSuccess(result);
                        }
                    } catch (e) {
                        sendFailure(e);
                    }
                };
            })();
        `;
    }
}

export default new ThreadingService();
