import f, { ODataFilterBuilder } from 'odata-filter-builder';
import { isEmpty, ODataUtils } from '../../utils';

export function createDocumentBuyerMessageFilter(docState: number, vendorClass?: number, businessDocType?: number) {
    const filterRules: ODataFilterBuilder[] = [];

    filterRules.push(f().eq('Enabled', true));

    if (!isEmpty(docState)) {
        filterRules.push(f().in('DocState', [docState, 3]));
    }

    filterRules.push(f().eq('VendorClass', isEmpty(vendorClass) ? null : vendorClass));

    if (!isEmpty(businessDocType)) {
        filterRules.push(f().eq('BusinessDocType', businessDocType));
    }

    return filterRules.length > 0 ? ODataUtils.andConditions(filterRules).toString() : undefined;
}
