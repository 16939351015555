import { IWorkflowActivityViewModel } from './WorkflowActivityViewModel';
import { IWorkflowGroupUserViewModel } from './WorkflowGroupUserViewModel';

import { WorkflowTemplateUIType } from '../../../BusinessDocTypeSearch';
import { BusinessDocType } from '../../../../types';
import { ProfileType } from '../../../Profile';

export interface IWorkflowViewModel {
    Id: number;
    Name: string;
    RuleName: string;
    ShortName: string;
    WorkflowTemplateUI: WorkflowTemplateUIType;
    Version: number;
    BusinessDocType: BusinessDocType; // Supported value: Invoice
    BusinessDocType_US?: string;
    Reprocess: string | null;
    ProfileType: ProfileType; // Supported value: BuyerDestination
    ProfileType_US?: string;
    ProfileId: number;
    RuleOrdinal: number | null;
    EntryConditionLogicType: EntryConditionLogicType; // Supported value: Stored Procedure Name
    EntryConditionLogicType_US?: string;
    EntryConditionLogic: string;
    JavaScriptFilePath: string | null;
    Active: boolean;
    CustomData: string | null; // XML Data Structure
    ApprovalType: WorkflowApprovalType | null;
    ApprovalType_US?: string;
    WorkflowActivities: IWorkflowActivityViewModel[];
    WorkflowGroupUsers: IWorkflowGroupUserViewModel[];
    LastUpdateDate?: string;
    Rowversion?: string;
}

export enum WorkflowApprovalType {
    CustomApproval = 1,
    UserManagerHierarchy = 2,
    UserGroupHierarchy = 3,
    UserGroupWithUserManagerFallback = 4,
    UserManagerWithUserGroupFallback = 5,
}

export enum EntryConditionLogicType {
    Undefined,
    SQLScript,
    SQLStatement,
    'Stored Procedure Name',
    VBScrip4,
    JavascriptCode,
}
