import { createUseCRUDService, ICRUDService } from '../../../reusableFeatures';
import { IUserGroup, IUserGroupData, UserGroupService } from '../../../services';

export class UserGroupCRUDService implements ICRUDService<number, IUserGroup> {
    private service = new UserGroupService();

    get defaultState(): IUserGroup {
        return {
            id: null,
            name: '',
            description: '',
            parentUserGroupId: null,
            controlUS: '',
            usageUS: '',
            control: null,
            usage: null,
            workflowUserGroups: [],
            userGroupMemberships: [],
        };
    }

    get key(): string {
        return 'UserGroup';
    }

    get invalidateKeys(): string[] {
        return ['UserGroupSearch'];
    }

    fetchEntity(id: number): Promise<IUserGroup> {
        return this.service.getUserGroup(id);
    }

    createEntity(state: IUserGroupData): Promise<number> {
        return this.service.createUserGroup(state);
    }

    async updateEntity(id: number, state: IUserGroupData): Promise<void> {
        await this.service.updateUserGroup(id, state);
    }

    async deleteEntity(id: number): Promise<void> {
        await this.service.deleteUserGroup(id);
    }
}

export const useUserGroupCRUDService = createUseCRUDService(UserGroupCRUDService);
