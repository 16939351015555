import R from '../routes';
import * as customerActions from '../actions/CustomerActions';
import { strings } from './localization/LocalizationStrings';
import projectConfig from '../project.config.json';

export function CustomersSearchConfig() {
    this.createSearchConfig = (customers) => {
        let searchModel = new Customer();
        searchModel.tableConfig = tableConfig();
        searchModel.advancedSearchConfig = advancedSearchConfig(customers);
        return searchModel;
    };
}

function Customer() {
    this.toggleSearch = null;
    this.noDefaultFetching = null;
    this.noDefaultFetchingSimple = null;
    this.onlyAdvancedSearch = null;
    this.pageSize = projectConfig.pageSize;
    this.rowDataPath = `${R.MANAGE_CUSTOMERS.path}`;
    this.resultText = strings.textSearchResultText;
    this.itemFoundText = strings.textCustomerLocationsFound;
    this.itemsFoundText = strings.textCustomerLocationsFound;
    this.advancedSearchText = null;
    this.advancedSearchTitle = strings.textManageCustomersPlaceholder;
    this.searchBoxWide = true;
    this.stateName = 'selectCustomer';
    this.fetching = customerActions.CUSTOMERS_FETCHING;
    this.fetched = customerActions.CUSTOMERS_FETCHING;
    this.search = customerActions.CUSTOMERS_SEARCH_ADVANCED;
    this.sorting = customerActions.CUSTOMERS_APPLY_SORTING;
    this.clearSearch = customerActions.CUSTOMERS_CLEAR_SEARCH;
    this.defaultSortColumn = 'Name';
}

function advancedSearchConfig(customers) {
    return [
        {
            name: strings.textCustomer,
            key: 'BuyerDestinationProfile/CompanyID ',
            type: 'Select',
            options: customers || [],
        },
        { name: strings.textCustomerNumber, key: 'CustomerNumber', type: 'text' },
        { name: strings.textCustomerName, key: 'Name', type: 'text' },
        { name: strings.textLocationName, key: 'AddressBlock', type: 'TextArea' },
    ];
}

const tableConfig = () => {
    return [
        {
            name: strings.textCustomerNumber2,
            key: 'CustomerNumber',
            type: String,
        },
        {
            name: strings.textCustomerName,
            key: 'Name',
            type: String,
        },
        {
            name: strings.textLocationName,
            key: 'AddressBlock',
            type: String,
        },
    ];
};
