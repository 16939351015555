import {
    CLEAR_DOCUMENT_CREATION_CACHE,
    DOCUMENT_CREATION_IDENTIFYING_NUMBER_EDITED,
    DOCUMENT_CREATION_INITIALIZATION_BEGIN,
    DOCUMENT_CREATION_INITIALIZATION_COMPLETE,
    DOCUMENT_CREATION_SAVING_BEGIN,
    DOCUMENT_CREATION_SAVING_COMPLETE,
    DOCUMENT_CREATION_SET_ORIGINAL_STATE_INFO,
    DOCUMENT_CREATION_UPDATING_FIELDS_BEGIN,
    DOCUMENT_CREATION_UPDATING_FIELDS_COMPLETE,
} from '../../actions/document/documentCreationActions';
import { USER_LOGIN_FAILED, USER_LOGIN_PENDING, USER_LOGIN_SUCCESS, USER_LOGOUT } from '../../actions/userlogin';

const initialState = {
    identifyingNumberEdited: false,
    initializing: false,
    saving: false,
    unsavedEdits: false,
    updatingFields: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case CLEAR_DOCUMENT_CREATION_CACHE:
        case USER_LOGIN_FAILED:
        case USER_LOGIN_PENDING: {
            // if it was timeout return currenState, otherwhise initialState
            const { timeout } = state;
            return timeout && timeout === true ? state : initialState;
        }
        case USER_LOGIN_SUCCESS: {
            // if it was timeout return currenState, otherwhise initialState
            // remove timeout flag
            const { timeout, ...currentState } = state;
            return timeout && timeout === true ? currentState : initialState;
        }
        case USER_LOGOUT: {
            const { timeout } = payload;
            return timeout && timeout === true ? { ...state, timeout } : initialState;
        }
        case DOCUMENT_CREATION_IDENTIFYING_NUMBER_EDITED:
            return {
                ...state,
                identifyingNumberEdited: payload,
            };

        case DOCUMENT_CREATION_INITIALIZATION_BEGIN:
            return {
                ...state,
                initializing: true,
            };

        case DOCUMENT_CREATION_INITIALIZATION_COMPLETE:
            return {
                ...state,
                unsavedEdits: false,
                initializing: false,
            };

        case DOCUMENT_CREATION_SAVING_BEGIN:
            return {
                ...state,
                saving: true,
            };

        case DOCUMENT_CREATION_SAVING_COMPLETE:
            return {
                ...state,
                unsavedEdits: false,
                saving: false,
            };

        case DOCUMENT_CREATION_SET_ORIGINAL_STATE_INFO:
            return {
                ...state,
                identifyingNumberEdited: initialState.identifyingNumberEdited,
            };

        case DOCUMENT_CREATION_UPDATING_FIELDS_BEGIN:
            return {
                ...state,
                updatingFields: true,
            };

        case DOCUMENT_CREATION_UPDATING_FIELDS_COMPLETE:
            return {
                ...state,
                unsavedEdits: true,
                updatingFields: false,
            };

        default:
            return state;
    }
};
