export enum LoginType {
    'internal' = '1',
    'normal' = '0',
}

export const isInternalLogin = (): boolean => {
    return localStorage.getItem('isInternalLogin') === LoginType.internal;
};

export const setLoginType = (loginType: LoginType): void => {
    localStorage.setItem('isInternalLogin', loginType);
};
