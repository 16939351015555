// DEPRECATED: Do not use
// REFACTOR: Build replacement UI controls for specific types of input fields (numeric, money, percent, etc). This component is impossible to reuse because it does way too many things.
import { FC } from 'react';
import { useKeyboard } from '../../CommonResources/hooks/useKeyboard';
import { ControlTypes } from './types';
import './styles.scss';

export interface IInputProps {
    controlType?: ControlTypes;
    currencyCode?: string | null;
    currencySymbol?: string | null;
    id: any;
    key?: any;
    initialValue?: string | number;
    value?: string;
    indexField?: string | number;
    editMode?: 'label' | 'edit' | 'view' | boolean;
    fieldLayout?: any;
    placeholder?: string;
    required?: boolean;
    rootURL?: string;
    editable?: boolean;
    readOnly?: boolean;
    testid?: string;

    // Events
    onChange?: (e) => boolean | void;
    onFocus?: (e) => boolean | void;
    onBlur?: (key, value) => boolean | void;
    onClick?: (e) => boolean | void;
    onKeyDown?: (e) => boolean | void;
    onKeyPress?: (e) => boolean | void;
    onKeyUp?: (e) => boolean | void;
    onMouseOut?: (e) => boolean | void;
    onMouseOver?: (e) => boolean | void;
    onEnterPress?: (e) => boolean | void;

    strings?: any;
    fieldName: string;
    className?: string;
    formatting?: any;
}

const getCurrentPlaceholder = (placeholder, controlType, currencySymbol) => {
    if (placeholder) {
        return placeholder;
    }

    // Use default placeholder of '%' for percentages and currency symbol for money and tax controls
    switch (controlType) {
        case ControlTypes.Money:
        case ControlTypes.MoneyLabel:
        case ControlTypes.Tax:
            return currencySymbol;

        case ControlTypes.Percentage:
            return '%';
        default:
            return '';
    }
};

/**
 * @deprecated
 */
export const Input: FC<IInputProps> = ({
    controlType = ControlTypes.SingleLine,
    currencyCode = null,
    currencySymbol = null,
    id,
    className = null,
    formatting,
    initialValue = '',
    value,
    editable,
    fieldName,
    placeholder,
    required = false,
    readOnly = false,
    testid = '',

    onChange = () => false,
    onFocus = () => false,
    onBlur = () => false,
    onKeyDown = () => false,
    onKeyPress = () => false,
    onKeyUp = () => false,
    onEnterPress = () => false,
}) => {
    const stringValue = initialValue ?? '';

    const [, keyBoardBindings] = useKeyboard({
        fieldName,
        stringValue,
        controlType,
        currencyCode,
        currencySymbol,
        onChange,
        onKeyDown,
        onKeyPress,
        onEnterPress,
        onKeyUp,
        onFocus,
        onBlur,
    });

    const currentPlaceholder = getCurrentPlaceholder(placeholder, controlType, currencySymbol);

    return (
        <input
            id={id}
            required={required}
            className={`core-input ${currentPlaceholder ? 'has-placeholder' : ''} ${
                readOnly ? 'read-only' : ''
            } ${className}`}
            placeholder={currentPlaceholder}
            maxLength={formatting?.maxChar ?? null}
            type={'text'}
            disabled={!editable}
            readOnly={readOnly}
            data-testid={testid}
            // Ignore prop spreading here. Component is already deprecated.
            // eslint-disable-next-line
            {...keyBoardBindings}
            value={value ?? keyBoardBindings.value}
        />
    );
};
