import { useState, useContext, ReactNode, createContext } from 'react';
import * as registry from '../registry';
import * as localStorageSource from '../localStorageSource';
import { isFeatureEnabledComputation as isFeatureEnabled } from '../isFeatureEnabled';
import { IFeatureFlagStates } from '../types';

export interface IFeatureState {
    userState: IFeatureFlagStates;
    isEnabled: boolean;
    featureKey: string;
}

export interface IFeatureAppState {
    featureStates: IFeatureState[];

    setFeatureState(featureKey: string, featureState: IFeatureFlagStates): void;
}

const FeatureAppStateContext = createContext<IFeatureAppState>({
    featureStates: [],
    setFeatureState: () => {},
});

function useTriggerUpdate() {
    const [, setState] = useState(0);

    function triggerUpdate() {
        setState((s) => s + 1);
    }

    return triggerUpdate;
}

export function FeatureAppStateProvider({ children }: { children: ReactNode }) {
    const triggerUpdate = useTriggerUpdate();

    const features = registry.getAllFeatures();

    function setFeatureState(featureKey: string, featureState: IFeatureFlagStates) {
        localStorageSource.setFeatureState(featureKey, featureState);
        triggerUpdate();
    }

    const featureStates = features.map((featureKey) => ({
        featureKey,
        userState: localStorageSource.getFeatureState(featureKey),
        isEnabled: isFeatureEnabled(featureKey),
    }));

    const value = {
        featureStates,
        setFeatureState,
    };

    return <FeatureAppStateContext.Provider value={value}>{children}</FeatureAppStateContext.Provider>;
}

export function useFeatureAppState() {
    return useContext(FeatureAppStateContext);
}
