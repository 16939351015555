export enum EntityType {
    Unknown,
    Header,
    LineItem,
    Contact,
    LineItemMiscAmount,
    MiscAmount,
    LineItemArea,
    ShippingDetail,
    LineItemShippingDetail,
}
