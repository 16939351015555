import { Help, Info } from '@mui/icons-material';
import { Tooltip } from './Tooltip';
import { FCNC } from '../FCNC';
import { ReactNode } from 'react';
import { EmbedHTML, ISafeHTMLString, isSafeHTMLString } from '../../utils';

const iconMap = { Help, Info };

export type ITooltipIconType = keyof typeof iconMap;

export interface ITooltipIconProps {
    message: NonNullable<ReactNode> | ISafeHTMLString;
    icon: ITooltipIconType;
}

export const TooltipIcon: FCNC<ITooltipIconProps> = ({ message, icon }) => {
    const Icon = iconMap[icon];

    const title: NonNullable<ReactNode> = (() => {
        if (isSafeHTMLString(message)) {
            return <EmbedHTML safeHTMLString={message} component="div" />;
        }

        return message;
    })();

    return (
        <Tooltip title={title}>
            <Icon />
        </Tooltip>
    );
};
