export const FETCH_BUYER_COMPANY_EXECUTE = 'FETCH_BUYER_COMPANY_EXECUTE';
export const FETCH_BUYER_COMPANY_FAILURE = 'FETCH_BUYER_COMPANY_FAILURE';
export const FETCH_BUYER_COMPANY_SUCCESS = 'FETCH_BUYER_COMPANY_SUCCESS';
export const FETCH_BUYER_VAT_ARCHIVE_SERVICE_EXECUTE = 'FETCH_BUYER_VAT_ARCHIVE_SERVICE_EXECUTE';
export const FETCH_BUYER_VAT_ARCHIVE_SERVICE_FAILURE = 'FETCH_BUYER_VAT_ARCHIVE_SERVICE_FAILURE';
export const FETCH_BUYER_VAT_ARCHIVE_SERVICE_SUCCESS = 'FETCH_BUYER_VAT_ARCHIVE_SERVICE_SUCCESS';
export const FETCH_SUPPLIER_COMPANY_EXECUTE = 'FETCH_SUPPLIER_COMPANY_EXECUTE';
export const FETCH_SUPPLIER_COMPANY_FAILURE = 'FETCH_SUPPLIER_COMPANY_FAILURE';
export const FETCH_SUPPLIER_COMPANY_SUCCESS = 'FETCH_SUPPLIER_COMPANY_SUCCESS';
export const FETCH_SUPPLIER_VAT_ARCHIVE_SERVICE_EXECUTE = 'FETCH_SUPPLIER_VAT_ARCHIVE_SERVICE_EXECUTE';
export const FETCH_SUPPLIER_VAT_ARCHIVE_SERVICE_FAILURE = 'FETCH_SUPPLIER_VAT_ARCHIVE_SERVICE_FAILURE';
export const FETCH_SUPPLIER_VAT_ARCHIVE_SERVICE_SUCCESS = 'FETCH_SUPPLIER_VAT_ARCHIVE_SERVICE_SUCCESS';
export const SET_SELECTED_CONTACT = 'SET_SELECTED_CONTACT';
export const CLEAR_SELECTED_CONTACT = 'CLEAR_SELECTED_CONTACT';
export const SAVE_SELECTED_CONTACT_FAILED = 'SAVE_SELECTED_CONTACT_FAILED';
export const SAVE_SELECTED_CONTACT_SUCCESS = 'SAVE_SELECTED_CONTACT_SUCCESS';
/* -------------------------------------------------------------------------- */
export const fetchBuyerVATArchiveServiceExecute = (params) => ({
    type: FETCH_BUYER_VAT_ARCHIVE_SERVICE_EXECUTE,
    payload: {
        params: params,
    },
});

export const fetchBuyerVATArchiveServiceFailure = (err) => ({
    type: FETCH_BUYER_VAT_ARCHIVE_SERVICE_FAILURE,
    payload: err.response.data,
});

export const fetchBuyerVATArchiveServiceSuccess = (data) => ({
    type: FETCH_BUYER_VAT_ARCHIVE_SERVICE_SUCCESS,
    payload: data,
});

/* -------------------------------------------------------------------------- */
export const fetchSupplierVATArchiveServiceExecute = (params) => ({
    type: FETCH_SUPPLIER_VAT_ARCHIVE_SERVICE_EXECUTE,
    payload: {
        params: params,
    },
});

export const fetchSupplierVATArchiveServiceFailure = (err) => ({
    type: FETCH_SUPPLIER_VAT_ARCHIVE_SERVICE_FAILURE,
    payload: err.response.data,
});

export const fetchSupplierVATArchiveServiceSuccess = (data) => ({
    type: FETCH_SUPPLIER_VAT_ARCHIVE_SERVICE_SUCCESS,
    payload: data,
});

/* -------------------------------------------------------------------------- */
export const fetchBuyerCompanyExecute = (params) => ({
    type: FETCH_BUYER_COMPANY_EXECUTE,
    payload: {
        params: params,
    },
});

export const fetchBuyerCompanyFailure = (err) => ({
    type: FETCH_BUYER_COMPANY_FAILURE,
    payload: err.response.data,
});

export const fetchBuyerCompanySuccess = (data) => ({
    type: FETCH_BUYER_COMPANY_SUCCESS,
    payload: data,
});

/* -------------------------------------------------------------------------- */
export const fetchSupplierCompanyExecute = (params) => ({
    type: FETCH_SUPPLIER_COMPANY_EXECUTE,
    payload: {
        params: params,
    },
});

export const fetchSupplierCompanyFailure = (err) => ({
    type: FETCH_SUPPLIER_COMPANY_FAILURE,
    payload: err.response.data,
});

export const fetchSupplierCompanySuccess = (data) => ({
    type: FETCH_SUPPLIER_COMPANY_SUCCESS,
    payload: data,
});

export const setSelectedContact = (data) => ({
    type: SET_SELECTED_CONTACT,
    payload: data,
});

export const clearSelectedContact = () => ({
    type: CLEAR_SELECTED_CONTACT,
});

export const saveSelectedContactSuccess = (data) => ({
    type: SAVE_SELECTED_CONTACT_SUCCESS,
    payload: data,
});
