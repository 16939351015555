import { AxiosInstance } from 'axios';
import { cacheGetRequests, DedupGetRequests, invalidateCacheResponsesWithKey } from './axiosOptimizer';
import { Cache, LocalStoragePersistor, persistCacheInBackground } from '../../utils/cache';
import { getVersionKey } from '../../utils';
import { AxiosMocker, installAxiosMocker } from './axiosMocker';
import { getAppConfig } from '../../config';
import { apiErrorNormalizer } from './axiosOptimizer/apiErrorNormalizer';
import { installCypressAxiosInterceptor, CypressRequestCollector, CypressAxiosInterceptor } from '../../infrastructure';
import { cypressRequestCollector } from './cypressRequestCollector';

declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Window {
        transceptaAPIErrorTester: AxiosMocker;
        transceptaAxiosMocker: AxiosMocker;
        transceptaInvalidateApiCacheResponses: (invalidationKey: string) => void;
        transceptaCypressRequestCollector: CypressRequestCollector;
    }
}

// stores API responses
let responseCache: Cache | null = null;
function getResponseCache() {
    if (!responseCache) {
        responseCache = new Cache(
            50,
            getAppConfig().apiCacheEndpointConfig?.defaultCacheTTL ?? 5 * 60 * 1000,
            getAppConfig().apiCacheEndpointConfig?.defaultStaleTTL ?? 5 * 60 * 1000
        );

        // only enable persistence in production builds or when developer turns it on via debug flag
        const versionNumber = getVersionKey();
        if (versionNumber) {
            const persistor = new LocalStoragePersistor('api-call-cache', versionNumber);

            persistCacheInBackground(responseCache, persistor, 5000);
        }
    }

    return responseCache;
}

// de-dups equivalent requests made at the same time
const deduper = new DedupGetRequests();

export const axiosMocker = new AxiosMocker();
window.transceptaAxiosMocker = window.transceptaAPIErrorTester = axiosMocker;

export type APIInterceptor = (instance: AxiosInstance) => void;

const otherInterceptors: APIInterceptor[] = [];

export function registerAPIInterceptor(interceptor: APIInterceptor) {
    otherInterceptors.push(interceptor);
}

export function invalidateApiCacheResponses(invalidationKey: string) {
    invalidateCacheResponsesWithKey(getResponseCache(), invalidationKey);
}
window.transceptaInvalidateApiCacheResponses = invalidateApiCacheResponses;

const cypressAxiosInterceptor = new CypressAxiosInterceptor(cypressRequestCollector, '/v1.0/api');
cypressAxiosInterceptor.ignoreHeader('server');
cypressAxiosInterceptor.ignoreHeader('x-powered-by');
cypressAxiosInterceptor.ignoreHeader('x-aspnet-version');
cypressAxiosInterceptor.ignoreHeader('authorization');

export function optimizeApi(instance: AxiosInstance) {
    installCypressAxiosInterceptor(instance, cypressAxiosInterceptor);
    deduper.dedupGetRequests(instance);
    cacheGetRequests(instance, getResponseCache(), getAppConfig().apiCacheEndpointConfig);
    installAxiosMocker(instance, axiosMocker);
    otherInterceptors.forEach((interceptor) => interceptor(instance));
    apiErrorNormalizer(instance);
    return instance;
}
