import { useState } from 'react';
import { useUserGroupCRUDService } from '../CRUDPage';
import { ConfirmationDialog, FCNC, Button } from '../../../ui';
import { Typography } from '@mui/material';
import { useDeleteEntity } from '../../../reusableFeatures';

export interface IDeleteUserGroupDialogProps {
    id: number;
}

export const DeleteUserGroupDialog: FCNC<IDeleteUserGroupDialogProps> = ({ id }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const crudService = useUserGroupCRUDService();
    const deleteMutation = useDeleteEntity(crudService, undefined);
    return (
        <>
            <ConfirmationDialog
                title={'Delete User Group'}
                prompt={'Are you sure you want to delete this user group? This operation cannot be undone.'}
                confirm={{
                    onClick: () => {
                        setIsDialogOpen((previous) => !previous);
                        deleteMutation.mutate({ id });
                    },
                    buttonTitle: 'Yes',
                    autoFocus: true,
                }}
                reject={{
                    onClick: () => {
                        setIsDialogOpen((previous) => !previous);
                    },
                    buttonTitle: 'No',
                }}
                open={isDialogOpen}
            />
            <Button
                testid="delete-usergroup-button"
                onClick={(e) => {
                    e.stopPropagation();
                    setIsDialogOpen((previous) => !previous);
                }}
            >
                <Typography>Delete</Typography>
            </Button>
        </>
    );
};
