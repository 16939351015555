import IPersistor, { ICacheEntry } from './IPersistor';
import LocalStoragePersistor from './LocalStoragePersistor';
import { setSessionCookie, getCookie } from '../cookie';

/**
 * Persists data to localStorage, but busts the persisted cache
 * image on new browser sessions by keeping track of when the session
 * changes using a session cookie.
 */
export default class SessionPersistor implements IPersistor {
    private basePersistor: IPersistor;

    /**
     * Constructs a SessionPersistor
     * @param name unique name for this persistor to prevent conflicts with other persistors of this type
     * @param versionKey uniquely identify the version of the cache image. this should be updated with each build.
     */
    constructor(private name: string, private versionKey: string | number) {
        const baseVersionKey = this.getBustKey();
        this.basePersistor = new LocalStoragePersistor(name, baseVersionKey);
    }

    async persist(entries: ICacheEntry[]) {
        return this.basePersistor.persist(entries);
    }

    async hydrate(): Promise<ICacheEntry[]> {
        return this.basePersistor.hydrate();
    }

    private getBustKey() {
        // bust the base storage bust key when our version key changes (this handles busting
        // if version key changes during a browser session, which could happen if a new build
        // was deployed)
        if (getCookie(`${this.name}VersionKey`) !== this.versionKey) {
            setSessionCookie(`${this.name}VersionKey`, this.versionKey.toString());
            setSessionCookie(`${this.name}BustKey`, Date.now().toString());
        }

        // the bust key is used to bust the base storage. it will always reset in a new
        // browser session or when our version key changes.
        return getCookie(`${this.name}BustKey`)!;
    }
}
