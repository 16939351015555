import { ReactNode } from 'react';
import {
    parseDotNotation,
    getValue as baseGetValue,
    setValue as baseSetValue,
    updateIn as baseUpdateIn,
} from './language';
import { DotNotationError } from './DotNotationError';

/**
 * Validates a DotNotation expression. Adds errors to the error list if any are found.
 *
 * Suitable to call while saving fields using DotNotation data binding keys to report errors
 * to user.
 *
 * @param validationContext will be prepended to any error message
 * @param value the value in the field
 * @param errors the error list to add parsing error to
 */
export function validateDotNotation(validationContext: string, value: string, errors: ReactNode[]) {
    try {
        parseDotNotation(value);
    } catch (e: any) {
        errors.push(<DotNotationError error={e} validationContext={validationContext} />);
    }
}

/**
 * Get value in object using DotNotation expression.
 * @param expr the DotNotation expression.
 * @param obj the object to get value in.
 * @param env the environment containing variable values.
 * @returns the value
 */
export function getValue(expr: string, obj: any, env: any) {
    return baseGetValue(parseDotNotation(expr), obj, env);
}

/**
 * Set (immutably) value in object using DotNotation expression.
 * @param expr the DotNotation expression.
 * @param obj the object to set value in.
 * @param env the environment containing variable values.
 * @param value the new value
 * @returns the updated object
 */
export function setValue(expr: string, obj: any, env: any, value: any) {
    return baseSetValue(parseDotNotation(expr), obj, env, value);
}

/**
 * Update (immutably) value in object using DotNotation expression.
 * @param expr the DotNotation expression.
 * @param obj the object to set value in.
 * @param env the environment containing variable values.
 * @param updater function to update the value
 * @returns the updated object
 */
export function updateIn(expr: string, obj: any, env: any, updater: (value: any) => any) {
    return baseUpdateIn(parseDotNotation(expr), obj, env, updater);
}

export { quote } from './language';
