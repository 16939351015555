import { IFeatureFlagConfigurationSource } from './types';
import { getAppConfig } from '../../appConfig';

/**
 * Checks if a feature flag is enabled in a global property that is defined by a JS file
 * deployed during the release.
 */
const globalPropertySource: IFeatureFlagConfigurationSource = {
    getFeatureFlagState(featureKey: string) {
        const configuredState = getAppConfig().featureFlags[featureKey];
        if (['enabled', 'disabled', 'unset'].indexOf(configuredState) > -1) {
            return configuredState;
        }
        return 'unset';
    },
};

export default globalPropertySource;
