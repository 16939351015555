import { useMemo } from 'react';
import { DashboardReportService } from './DashboardReportService';

type DashboardReportServiceConstructor = new (...args: any[]) => DashboardReportService<any, any>;

/**
 * Creates a hook to use a DashboardReportService for a Dashboard report component.
 * @param constructor Constructor for the dashboard service object
 * @returns a hook to construct an instance of the DashboardReportService
 */
export function createDashboardReportService<T extends DashboardReportServiceConstructor>(constructor: T) {
    return (...args: ConstructorParameters<T>) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return useMemo(() => new constructor(...args), args) as InstanceType<T>;
    };
}
