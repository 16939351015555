import { FC } from 'react';
import { Box, Stack, Typography, Link, useMediaQuery, useTheme } from '@mui/material';

interface IFooterProps {
    logout: Function;
    termsOfServiceURL: string;
}

export const Footer: FC<IFooterProps> = ({ logout, termsOfServiceURL }) => {
    const theme = useTheme();
    const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box sx={{ bgcolor: 'primary.700', color: 'white', width: '100%', p: 2 }}>
            <Stack direction={isMdDown ? 'column' : 'row'} justifyContent="space-between">
                <Typography sx={isMdDown ? { mb: 4 } : undefined}>
                    &copy; 2006-{new Date().getFullYear()} Transcepta LLC. If you are a supplier or vendor, Transcepta’s
                    service is governed by Transcepta’s{' '}
                    <Link style={{ color: 'white' }} href={termsOfServiceURL} target="_blank">
                        Terms of Service.
                    </Link>
                </Typography>
                <Stack
                    direction={isMdDown ? 'column' : 'row'}
                    justifyContent="space-evenly"
                    alignItems={isMdDown ? 'flex-start' : 'center'}
                    gap={2}
                >
                    <Link
                        style={{ color: 'white' }}
                        noWrap
                        href="mailto:support@transcepta.com?subject=Transcepta Manager Feedback"
                        target="_blank"
                    >
                        Send Feedback
                    </Link>
                    <Link style={{ color: 'white' }} noWrap href="mailto:support@transcepta.com" target="_blank">
                        Support
                    </Link>
                    <Link
                        style={{ color: 'white' }}
                        noWrap
                        href="https://archive.transcepta.com/resources/Transcepta Privacy Policy.pdf"
                        target="_blank"
                    >
                        Privacy Policy
                    </Link>
                    <Link
                        style={{ color: 'white' }}
                        noWrap
                        href="https://archive.transcepta.com/resources/Transcepta%20Instructions%20and%20Restrictions.pdf"
                        target="_blank"
                    >
                        Restrictions
                    </Link>
                    <Link style={{ color: 'white' }} noWrap href="#" onClick={() => logout()}>
                        Logout
                    </Link>
                </Stack>
            </Stack>
        </Box>
    );
};
