import React, { FC, useRef } from 'react';
import { Box, ButtonBase, ClickAwayListener, MenuItem, MenuList, Paper, Popper, Typography } from '@mui/material';
import { AccountCircle, KeyboardArrowDown } from '@mui/icons-material';
import { IAccountMenu } from './types';
import { useHistory } from 'react-router';

interface IAccountDropdown {
    accountMenu: IAccountMenu;
}

export const AccountDropdown: FC<IAccountDropdown> = ({ accountMenu }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLDivElement>(null);
    const accountRef = useRef<HTMLDivElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const history = useHistory();

    const handleProfileMenuOpen = () => {
        setAnchorEl(accountRef.current);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Box sx={{ bgcolor: '#06314F', height: '100%', p: 2.5 }} ref={accountRef} data-testid="menu-account-icon">
                <ButtonBase
                    aria-label="account of current user"
                    aria-controls={isMenuOpen ? 'user-settings-menu' : undefined}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                    sx={{ color: 'white' }}
                >
                    <AccountCircle sx={{ fontSize: 32, mr: 2 }} />
                    <Typography variant="inherit" noWrap>
                        {accountMenu.username}
                    </Typography>
                    <KeyboardArrowDown sx={{ ml: 2 }} />
                </ButtonBase>
            </Box>
            <Popper open={isMenuOpen} anchorEl={accountRef.current} disablePortal sx={{ zIndex: 9999 }}>
                <Paper>
                    <ClickAwayListener onClickAway={handleMenuClose}>
                        <MenuList id="user-settings-menu">
                            {accountMenu.items.map((accountItem, index) => (
                                <MenuItem
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    onClick={() => {
                                        if (accountItem.action) {
                                            accountItem.action();
                                        } else {
                                            history.push(accountItem.path);
                                        }
                                        handleMenuClose();
                                    }}
                                    data-testid={`menu-account-option-${accountItem.testId ?? accountItem.name}`}
                                >
                                    <Typography variant="inherit" noWrap>
                                        {accountItem.name}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </>
    );
};
