import { EventEmitter } from '../../utils';

/**
 * A function that can set up subscriptions to application events, in order to
 * forward them to an analytics SDK.
 */
export type IAnalyticsBootstrapFn = (hooks: AnalyticsHooks) => void;

declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Window {
        transceptaAnalytics: IAnalyticsBootstrapFn[] | undefined;
    }
}

/**
 * Provides methods for analytics scripts to hook into the application's events.
 */
export class AnalyticsHooks {
    private pageViewEmitter = new EventEmitter();

    /**
     * Subscribe to the "pageview" event
     */
    public onPageView(cb: () => void) {
        return this.pageViewEmitter.subscribe(cb);
    }

    /**
     * Trigger the "pageview" event.
     */
    public triggerPageView() {
        this.pageViewEmitter.notify();
    }

    /**
     * Perform initial set up to allow analytics scripts to hook into the application.
     */
    public bootstrap() {
        this.connectGlobal();
    }

    private connectGlobal() {
        // default the global to an empty array if not already defined
        window.transceptaAnalytics = window.transceptaAnalytics || [];
        // call each bootstrap function with this object
        window.transceptaAnalytics.forEach((fn) => fn(this));
        // set up the "push" so that any new functions pushed will immediately bootstrap
        window.transceptaAnalytics.push = (fn) => {
            fn(this);

            return 0;
        };
    }
}

export const analyticsHooks = new AnalyticsHooks();
