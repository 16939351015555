import { api } from './api';
import apiPaths from './paths';

class CommonThunk {
    constructor() {}

    fetchCountries = (params) => {
        return api()
            .get(apiPaths.country, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchStandardStyleSheets = () => {
        return api()
            .get(apiPaths.standardStyleSheet)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchStates = (params) => {
        return api()
            .get(apiPaths.state, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    postEmail = (params) => {
        return api()
            .post(apiPaths.contact, {
                Type: params.type,
                Email: params.email,
                Name: params.name,
                Phone: params.phone,
                CompanyId: params.companyId,
            })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchCurrencies = (params) => {
        return api()
            .get(apiPaths.currencyList, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };
}

export default CommonThunk;
