/**
 * Return a page of results from a "table"
 * @param arr the "table"
 * @param pageSize the page size
 * @param page the page number
 * @returns the page of results
 */
export function paginate<T>(arr: T[], pageSize: number, page: number) {
    const start = pageSize * page;
    const end = pageSize * (page + 1);

    return arr.slice(start, end);
}

/**
 * Calculate the offset in a table from the page size / page number
 * @param pageSize the size of a page
 * @param page the page number
 * @returns the offset in a table
 */
export function calculatePageOffset(pageSize: number, page: number) {
    const skip = pageSize * page;

    return skip > 0 ? skip : undefined;
}
