// DEPRECATED: Do not use
// REFACTOR: Find all uses (Organisms/Grid and Molecules/Alert - also deprecated). Replace with usage of useMediaQuery from material-ui
import { useState, useEffect } from 'react';

/**
 * @deprecated
 */
export const useIsDesktop = () => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return isDesktop;
};
