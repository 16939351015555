import { DocumentBatchApi, ISearchDocumentBatchSamplesParams } from '../Api/DocumentBatchApi';
import { IReportSamplesForm, ValidateSamplesGrid } from './types';
import { IDocumentBatchViewModel, DocumentBatchStatus } from '../Api/ViewModels';
import { validateData } from '../../../ui';
import { EditorState } from 'draft-js';
import { downloadFromFileViewModel } from '../../../utils';

export class DocumentBatchService {
    api = new DocumentBatchApi();

    public getValidateSamplesGrid = async (
        params: ISearchDocumentBatchSamplesParams = {}
    ): Promise<ValidateSamplesGrid> => {
        const samples = await this.api.searchDocumentBatchSamples({
            status: DocumentBatchStatus.SampleReceived.toString(),
            ...params,
        });
        return {
            count: samples.data.Count || 0,
            items: (samples.data.Items || []).map((item) => ({
                id: item.DocumentBatchId,
                documentBatchId: item.DocumentBatchId,
                createdDate: item.CreatedDate.replace(/T.*/, ''),
                supplierProfileId: item.SupplierProfileId,
                tradingPartners: item.TradingPartners,
                ruleConfigurationName: item.RuleConfigurationName,
                status: item.Status,
            })),
        };
    };

    public validateSelectedSamples = (ids: string[]) => {
        const actions = ids.map((id) => {
            return this.api.putDocumentBatch(
                Number(id),
                { Id: Number(id), Status: DocumentBatchStatus.SampleVerified },
                { historyDescription: 'Sample verified and sent to RuleDev' }
            );
        });

        return Promise.all(actions);
    };

    public sendToSupport = (ids: string[]) => {
        const actions = ids.map((id) => {
            return this.api.putDocumentBatch(
                Number(id),
                { Id: Number(id), Status: DocumentBatchStatus.SampleRejected },
                { historyDescription: 'Sample rejected back to support' }
            );
        });

        return Promise.all(actions);
    };

    public downloadDocumentBatch = async (id: number) => {
        const response = await this.api.getDocumentBatchFile(id);
        downloadFromFileViewModel(response.data);
    };

    private validateReportSamplesForm(reportSamplesFields: IReportSamplesForm) {
        validateData((errors) => {
            if (!reportSamplesFields.tradingPartner) {
                errors.push('Buyer is required');
            }

            if (!reportSamplesFields.from) {
                errors.push('Email "from" is required');
            }

            if (!reportSamplesFields.from.includes('@transcepta.com')) {
                errors.push('Email "from" must be a Transcepta email');
            }

            if (!reportSamplesFields.to) {
                errors.push('Email "To" is required');
            }

            if (!reportSamplesFields.subject) {
                errors.push('Email Subject is required');
            }

            if (!reportSamplesFields.body) {
                errors.push('Email body content is required');
            }
        });
    }

    public sendEmailsSamples = async (reportSamplesFields: IReportSamplesForm) => {
        this.validateReportSamplesForm(reportSamplesFields);

        reportSamplesFields.ids.forEach((id) => {
            const documentBatchView: Partial<IDocumentBatchViewModel> = {
                Id: Number(id),
                EmailContent: {
                    To: reportSamplesFields.to,
                    CC: reportSamplesFields.cc ?? '',
                    Subject: reportSamplesFields.subject,
                    Body: (reportSamplesFields.body as EditorState)?.getCurrentContent().getPlainText() || '',
                },
            };
            this.api.putDocumentBatch(Number(id), documentBatchView, {});
        });
    };

    static defaultReportSamplesCRUDFields: IReportSamplesForm = {
        ids: [],
        tradingPartner: null,
        from: '',
        to: '',
        cc: '',
        emailTemplate: '',
        subject: '',
        body: null,
        hasAttachments: false,
    };
}
