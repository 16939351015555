import { AxisType, DashboardExportType, DashboardMenu, GraphType } from '../../../types';
import { z } from 'zod';

export interface AxisDefinition {
    name: string;
    type: AxisType;
}

export interface GraphConfigurationDetailViewModel {
    graphType: GraphType;
    horizontalAxisName?: string; // not used for pie/donut
    valueAxisDefinition: AxisDefinition[]; // bar, line(s), and pie/donut segments
    valueAxisMaxValue?: number; // not used for pie/donut
    allowZeroData: boolean;
    fillLineArea?: boolean; // only used for line graphs
}

export interface GraphConfigurationViewModel {
    root: GraphConfigurationDetailViewModel;
}

export interface DashboardReportMetadataViewModel {
    Id?: number;
    Name: string;
    Description: string;
    Menu: DashboardMenu;
    Menu_US?: string;
    SubMenu: string;
    Tooltip?: string;
    GraphSP: string;
    ExportSP: string;
    ExportBusinessDocTypeSearchId?: number;
    ExportType: DashboardExportType;
    ExportType_US?: string;
    Order: number;
    GraphType: GraphType;
    GraphType_US?: string;
    GraphConfiguration: GraphConfigurationViewModel;
    MaxExportDays: number;
    BundleName?: string;
    CompanyId?: number;
}

const AxisDefinitionSchema = z.object({
    name: z.string(),
    type: z.nativeEnum(AxisType),
});

export const GraphConfigurationDetailSchema = z
    .object({
        graphType: z.nativeEnum(GraphType),
        horizontalAxisName: z.string().optional(),
        valueAxisDefinition: z.array(AxisDefinitionSchema).min(1, 'At least one value definition is required'),
        valueAxisMaxValue: z.number().optional(),
        allowZeroData: z.boolean(),
        fillLineArea: z.boolean().optional(),
    })
    .superRefine((data, ctx) => {
        if (data.graphType === GraphType.Bar || data.graphType === GraphType.Line) {
            if (!data.horizontalAxisName) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Horizontal axis name is required for bar and line graphs',
                });
            }
        }
    });

export const GraphConfigurationSchema = z.object({
    root: GraphConfigurationDetailSchema,
});

export const DashboardReportMetadataSchema = z
    .object({
        Id: z.number().optional(),
        Name: z.string(),
        Description: z.string(),
        Menu: z.nativeEnum(DashboardMenu),
        SubMenu: z.string(),
        Tooltip: z.string().optional(),
        GraphSP: z.string(),
        ExportSP: z.string(), // TODO: confirm if this will ever be optional
        ExportBusinessDocTypeSearchId: z.number().optional(),
        ExportType: z.nativeEnum(DashboardExportType),
        Order: z.number(),
        GraphType: z.nativeEnum(GraphType),
        GraphConfiguration: GraphConfigurationSchema,
        MaxExportDays: z.number(),
        BundleName: z.string().optional(),
        CompanyId: z.number().optional(),
    })
    .superRefine((data, ctx) => {
        if (
            (data.ExportType === DashboardExportType.TransactionsPage ||
                data.ExportType === DashboardExportType.WorkflowsPage) &&
            !data.ExportBusinessDocTypeSearchId
        ) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Export business doc type search ID is required for transactions and workflows page exports',
            });
        }
    });

export type GraphConfigurationDetailForm = z.infer<typeof GraphConfigurationDetailSchema>;
export type GraphConfigurationForm = z.infer<typeof GraphConfigurationSchema>;
export type DashboardReportMetadataForm = z.infer<typeof DashboardReportMetadataSchema>;
