import { FC } from 'react';
import { Typography, CircularProgress } from '@mui/material';
import { BusinessDocType } from '../../../../types';
import { useDocumentRevisions } from './useDocumentRevisions';
import { Button, Dialog } from '../../../../ui';
import { getDocumentTypeName } from '../../utils';
import { DocumentRevisionsList } from './DocumentRevisionsList';

interface DocumentRevisionsPopupProps {
    isPopupOpen: boolean;
    handleOnClosePopup: () => void;
    relatedDocumentId: number;
    relatedDocumentNumber: string;
    relatedDocumentType: BusinessDocType;
    currentDocumentType: BusinessDocType;
}

export const DocumentRevisionsPopup: FC<DocumentRevisionsPopupProps> = ({
    isPopupOpen,
    handleOnClosePopup,
    relatedDocumentId,
    relatedDocumentType,
    relatedDocumentNumber,
    currentDocumentType,
}) => {
    const { data: documentRevisions } = useDocumentRevisions(relatedDocumentId);

    return (
        <Dialog
            open={isPopupOpen}
            title={`${getDocumentTypeName(relatedDocumentType)} #${relatedDocumentNumber} revisions`}
            handleOnClose={handleOnClosePopup}
            actions={
                <Button onClick={handleOnClosePopup} id={'close'}>
                    Close
                </Button>
            }
            content={
                <>
                    {!documentRevisions && (
                        <Typography sx={{ textAlign: 'center' }}>
                            <CircularProgress size="14px" /> Loading revisions
                        </Typography>
                    )}
                    {documentRevisions && (
                        <DocumentRevisionsList
                            documentRevisions={documentRevisions}
                            relatedDocumentNumber={relatedDocumentNumber}
                            relatedDocumentType={relatedDocumentType}
                            currentDocumentType={currentDocumentType}
                            processedAgainstRevisionDocumentId={relatedDocumentId}
                        />
                    )}
                </>
            }
        ></Dialog>
    );
};
