import { registerFeatureFlag, isFeatureEnabled as baseIsFeatureEnabled } from './infrastructure';
import { featureFlags } from './featureFlags';
import { FeatureFlag } from './types';

featureFlags.forEach((flag) => {
    registerFeatureFlag(flag);
});

export function isFeatureEnabled(featureFlag: FeatureFlag) {
    return baseIsFeatureEnabled(featureFlag);
}
