import { format } from 'date-fns';

/**
 * Generate query string from searchObject
 * @param {object} searchParams
 * @return {string}
 */
export function generateSearchString(searchParams) {
    let filter = Object.keys(searchParams).reduce((acc, key) => {
        if (
            searchParams[key].value &&
            typeof searchParams[key].value === 'string' &&
            searchParams[key].value.trim().length === 0
        ) {
            searchParams[key].value = '';
        }
        if (
            searchParams[key] &&
            searchParams[key].value &&
            key !== 'IdentifyingNumber' &&
            key !== 'ReferenceDocumentIdentifyingNumber' &&
            key !== 'VendorNumber' &&
            key !== 'VendorName' &&
            key !== 'CustomerName' &&
            key !== 'CustomerNumber' &&
            key !== 'DocumentAmount' &&
            key !== 'DocumentTypeId'
        ) {
            if (acc) acc += ' and ';

            let valArr = Array.isArray(searchParams[key].value) ? searchParams[key].value : [searchParams[key].value];

            if (searchParams[key].type === 'orBlock' && searchParams[key].value) {
                let orBlockVals = valArr.reduce((prev, current) => {
                    return prev ? (prev += ` or ${key} eq ${current}`) : `${key} eq ${current}`;
                }, '');
                acc += `(${orBlockVals})`;
            } else {
                if (key === 'AcknowledgementStatus') {
                    valArr = valArr.sort(function (a, b) {
                        return compareHelper.Number(a.value, b.value);
                    });
                }

                acc += valArr.reduce((valArrAcc, v, valArrInd) => {
                    if (valArrInd && v) {
                        if (!isNaN(parseFloat(valArr[0])) || searchParams[key].type.includes('Date')) {
                            valArrAcc += ` and ${key} le `;
                        } else if (key !== 'ExternalStatus' && key !== 'PurchaseOrderStatus') {
                            valArrAcc += `${key} le `;
                        }
                    } else if (valArr.length > 1 && valArrInd === 0) {
                        if (!isNaN(parseFloat(v)) || searchParams[key].type.includes('Date')) {
                            valArrAcc += `${key} ge `;
                        }
                    } else if (key === 'DaysParked') {
                        valArrAcc += `CreatedTime ge ${new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000 - v * 3600000
                        ).toISOString()} and CreatedTime le ${new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                        ).toISOString()}`;
                    } else if (searchParams[key].type.includes('text') && key !== 'AcknowledgementDocumentNumber') {
                        valArrAcc += `startswith(${key},`;
                    } else if (
                        v &&
                        key !== 'ExternalStatus' &&
                        key !== 'PurchaseOrderStatus' &&
                        key !== 'AcknowledgementDocumentNumber'
                    ) {
                        valArrAcc += `${key} eq `;
                    }

                    if (searchParams[key].type.includes('Date')) {
                        let date = new Date(Date.parse(v));
                        //set date start to start of the day 00:00:00, and date end to the end of the date 23:59:59
                        valArrInd === 0 ? date.setHours(0, 0, 0, 0) : date.setHours(23, 59, 59, 999);
                        let utc = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
                        valArrAcc += `${utc}`;
                    } else if (searchParams[key].type.includes('NumberRange')) {
                        if (!isNaN(parseFloat(v))) valArrAcc += parseFloat(v) + 'd';
                    } else if (
                        searchParams[key].type.includes('Number') &&
                        !searchParams[key].type.includes('NumberRange')
                    ) {
                        valArrAcc += isNaN(parseFloat(v)) ? 0 : parseFloat(v);
                    } else if (searchParams[key].type.includes('Long')) {
                        valArrAcc += isNaN(parseFloat(v)) ? 0 : parseFloat(v) + 'L';
                    } else if (searchParams[key].type.includes('Integer')) {
                        valArrAcc += parseInt(v, 10) || 0;
                    } else if (
                        searchParams[key].type.includes('text') &&
                        key !== 'DaysParked' &&
                        key !== 'AcknowledgementDocumentNumber'
                    ) {
                        if (v) {
                            valArrAcc += `'${v.trim()}')`;
                        }
                    } else if ((key === 'ExternalStatus' || key === 'PurchaseOrderStatus') && v) {
                        if (valArrInd === 0) {
                            valArrAcc += '(';
                        }
                        valArrAcc +=
                            valArrInd < valArr.length - 1 ? `${key} eq ${v.value} or ` : `${key} eq ${v.value}`;
                        if (valArrInd === valArr.length - 1) {
                            valArrAcc += ')';
                        }
                    } else if (key === 'SenderProfileId' || key === 'ReceiverProfileId') {
                        valArrAcc += `${v.value}`;
                    } else if (v && key !== 'DaysParked' && key !== 'AcknowledgementDocumentNumber') {
                        if (searchParams[key].type.includes('Select') && !key.includes('_US')) {
                            valArrAcc += `${v.value}`;
                        } else {
                            valArrAcc += `'${v}' `;
                        }
                    }

                    return valArrAcc;
                }, '');

                //add filter by ExternalStatus based on the businessDocType
                if (key == 'BusinessDocType') {
                    if (Array.isArray(searchParams[key].value)) {
                        for (let i = 0; i < searchParams[key].value.length; ++i) {
                            if (searchParams[key].value[i] == 2) {
                                acc += ' and ExternalStatus ne 3 and ExternalStatus ne 14 and ExternalStatus ne 29';
                            } else if (searchParams[key].value[i] == 1) {
                                acc += ' and ExternalStatus ne 29';
                            }
                        }
                    } else if (searchParams[key].value == 2) {
                        acc += ' and ExternalStatus ne 3 and ExternalStatus ne 14 and ExternalStatus ne 29';
                    } else if (searchParams[key].value == 1) {
                        acc += ' and ExternalStatus ne 29';
                    }
                }
            }
        }
        return acc;
    }, '');

    return filter;
}

/**
 * Generate simple search
 * @param {array} arr - array of fields to match
 * @param {string} str - search string
 * @param {number} docTypeId - Business Document Type
 * @returns {string}
 */
export const generateSimpleSearchString = (arr, str, docTypeId) => {
    const initialAcc = `BusinessDocType eq ${docTypeId}`;

    if (str === '') return initialAcc;

    let conditions = arr.reduce((conditions, field) => {
        if (!field) return conditions;
        const type = field.type || 'text';
        const fieldKey = typeof field === 'string' ? field : field.key;
        if (!fieldKey) return conditions;
        let condition = [];

        if (type.includes('Number')) {
            condition = !isNaN(str) && str !== '' ? `${fieldKey} eq ${Number(str)}d` : [];
        } else if (type.includes('Integer') || type.includes('Number')) {
            condition = !isNaN(str) && str !== '' ? `${fieldKey} eq ${Number(str)}` : [];
        } else if (type.includes('Long')) {
            condition = !isNaN(str) && str !== '' ? `${fieldKey} eq ${Number(str)}L` : [];
        } else if (type.includes('Date')) {
            const mDate = format(new Date(str), 'P');
            condition = mDate.isValid()
                ? `(${fieldKey} ge ${mDate.toISOString()} and ${fieldKey} le ${mDate.add(1, 'd').toISOString()})`
                : [];
        } else {
            condition = `startswith(${fieldKey},'${str}')`;
        }
        return conditions.concat(condition);
    }, []);

    return `${initialAcc} and (${conditions.join(' or ')})`;
};

/**
 * Simple string comparator function
 * @param sourceA
 * @param sourceB
 * @returns {number}
 */
const compareString = (sourceA, sourceB) => {
    const a = sourceA && sourceA.toString().toLowerCase();
    const b = sourceB && sourceB.toString().toLowerCase();
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
};

const compareDates = (dateA, dateB) => Date.parse(dateA) - Date.parse(dateB);

const compareNumbers = (a, b) => a - b;

export const compareHelper = {
    String: compareString,
    Number: compareNumbers,
    Date: compareDates,
};
