import { createContext, useContext, FC, useState, Dispatch, SetStateAction, useMemo } from 'react';

interface ITargetRegionContextState {
    outletNodes: Map<string, HTMLDivElement>;
}

export interface ITargetRegionContextValue {
    state: ITargetRegionContextState;
    setState: Dispatch<SetStateAction<ITargetRegionContextState>>;
}

export const TargetRegionContext = createContext<ITargetRegionContextValue | null>(null);

/**
 * Provides a unique context for all regions in the component tree. Render once near the
 * top of the Component Tree.
 */
export const Provider: FC<{}> = ({ children }) => {
    const [state, setState] = useState<ITargetRegionContextState>({
        outletNodes: new Map(),
    });

    const value = useMemo(() => ({ state, setState }), [state, setState]);

    return <TargetRegionContext.Provider value={value}>{children}</TargetRegionContext.Provider>;
};

export function useTargetRegionState(componentName: string) {
    const value = useContext(TargetRegionContext);

    if (!value) {
        throw new Error(
            `You tried to use "TargetRegion.${componentName}" from outside of a "TargetRegion.Provider". Please render a "TargetRegion.Provider" near the top of your component tree, in some parent of this component, to use this component.`
        );
    }

    return value;
}
