import actions from '../actions';
import {
    FETCH_COUNTRIES_EXECUTE,
    FETCH_COUNTRIES_FAILURE,
    FETCH_COUNTRIES_SUCCESS,
    FETCH_STANDARD_STYLE_SHEETS_EXECUTE,
    FETCH_STANDARD_STYLE_SHEETS_FAILURE,
    FETCH_STANDARD_STYLE_SHEETS_SUCCESS,
    FETCH_STATES_EXECUTE,
    FETCH_STATES_FAILURE,
    FETCH_STATES_SUCCESS,
} from '../actions/CommonActions';

const {
    USER_LOGIN_SUCCESS,
    USER_LOGOUT,
    USER_LOGIN_PENDING,
    USER_LOGIN_FAILED,
    USER_PROFILE_UPDATED,
    USER_PASSWORD_RECOVER_FAILED,
    USER_PASSWORD_RECOVER_SUCCESS,
    USER_PASSWORD_RECOVER_PENDING,
    USER_PASSWORD_RECOVER_RESET,
    USER_CURRENCY_FETCHED,
    USER_MENUSETTINGS_FETCHED,
    USER_LOGIN_TIMEOUT,
} = actions;

function convertUnicode(input) {
    return input.replace(/\\u(\w\w\w\w)/g, function (a, b) {
        var charcode = parseInt(b, 16);
        return String.fromCharCode(charcode);
    });
}

/*
        User auth action flow:
                    USER_LOGIN_PENDING
                    (request user auth)
                |                       |
                v                       v
        USER_LOGIN_SUCCESS      USER_LOGIN_FAILED
                |
      (request user profile)
   (request company information)
                |
                v
        USER_PROFILE_UPDATED
    (COMPANY_PROFILE_UPDATED)

    USER_LOGIN_PENDING - Login request is send, waiting for responce, login button must be blocked
    USER_LOGIN_SUCCESS - Login successfull. If we have, we use previous user profile from localstorage,
                         Simultaneously we call action requestUserProfile() and request latest profile.
    USER_PROFILE_UPDATED - Request from the requestUserProfile() action was received, process it throught storage.
*/
const invoicePaymentTerms = [
    '',
    'Net 10 days',
    'Net 14 days',
    'Net 15 days',
    'Net 30 days',
    'Net 35 days',
    'Net 45 days',
    'Net 60 days',
    'Due upon receipt',
    'Paid when paid',
    'Custom',
];

const initialState = {
    /** UI state fields */
    isLoggedIn: false,
    isPending: false,
    lastLoggedInError: null,

    passwordRecoverPending: false,
    passwordRecoverError: null,
    passwordRecoverSuccess: false,

    /** Auth information */
    access_token: '',
    expires_in: 0,

    /** User profile fields */
    ID: 0,
    CompanyID: 0,
    Name: '',
    UserName: '',
    Email: '',
    countryList: [],
    currencyList: [],
    standardStyleSheetList: [],
    stateList: [],
    menuSettings: {},
    invoicePaymentTerms,
    timeout: false,
    pathname: null,
};

// eslint-disable-next-line import/prefer-default-export
export function userlogin(state = initialState, action) {
    switch (action.type) {
        case USER_LOGIN_SUCCESS: {
            const { timeout, pathname } = state;
            return {
                ...action.payload,
                timeout,
                pathname,
                isLoggedIn: true,
                isPending: false,
                lastLoggedInError: null,
                invoicePaymentTerms,
            };
        }
        case USER_LOGIN_PENDING: {
            const { timeout, pathname } = state;
            return {
                isPending: true,
                isLoggedIn: false,
                lastLoggedInError: null,
                access_token: '',
                timeout,
                pathname,
            };
        }
        case USER_LOGOUT:
            const { timeout, pathname } = state;
            return {
                timeout,
                pathname,
                isLoggedIn: false,
                isPending: false,
                lastLoggedInError: action && action.payload && action.payload.error ? action.payload.error : null,
                access_token: '',
                expires_in: 0,
                invoicePaymentTerms: [],
            };

        case USER_LOGIN_FAILED:
            return {
                ...state,
                isLoggedIn: false,
                isPending: false,
                lastLoggedInError: action.payload.error,
            };

        case USER_PROFILE_UPDATED:
            return {
                ...state,
                ...action.payload,
            };

        case USER_PASSWORD_RECOVER_PENDING:
            return {
                ...state,
                passwordRecoverPending: true,
                passwordRecoverError: null,
                lastLoggedInError: null,
                isPending: false,
            };

        case USER_PASSWORD_RECOVER_FAILED:
            return {
                ...state,
                passwordRecoverError: action.payload,
                passwordRecoverPending: false,
                lastLoggedInError: null,
                isPending: false,
            };

        case USER_PASSWORD_RECOVER_SUCCESS:
            return {
                ...state,
                passwordRecoverError: null,
                passwordRecoverPending: false,
                passwordRecoverSuccess: true,
            };
        case USER_PASSWORD_RECOVER_RESET:
            return {
                ...state,
                passwordRecoverError: null,
                passwordRecoverPending: false,
                passwordRecoverSuccess: false,
            };
        case USER_CURRENCY_FETCHED:
            return {
                ...state,
                currencyList: action.payload.map((curStr) => {
                    return {
                        ...curStr,
                        Symbol: convertUnicode(curStr['Symbol']),
                    };
                }),
            };

        case USER_MENUSETTINGS_FETCHED:
            return {
                ...state,
                menuSettings: action.payload,
            };

        case FETCH_COUNTRIES_EXECUTE:
        case FETCH_COUNTRIES_FAILURE:
            return {
                ...state,
                countryList: initialState.countryList,
            };

        case FETCH_COUNTRIES_SUCCESS:
            return {
                ...state,
                countryList: action.payload,
            };

        case FETCH_STATES_EXECUTE:
        case FETCH_STATES_FAILURE:
            return {
                ...state,
                stateList: initialState.stateList,
            };

        case FETCH_STATES_SUCCESS:
            return {
                ...state,
                stateList: action.payload,
            };

        case FETCH_STANDARD_STYLE_SHEETS_EXECUTE:
        case FETCH_STANDARD_STYLE_SHEETS_FAILURE:
            return {
                ...state,
                standardStyleSheetList: initialState.standardStyleSheetList,
            };

        case FETCH_STANDARD_STYLE_SHEETS_SUCCESS:
            return {
                ...state,
                standardStyleSheetList: action.payload,
            };

        case USER_LOGIN_TIMEOUT: {
            const { timeout, pathname } = action.payload;
            return {
                ...state,
                timeout,
                pathname,
            };
        }

        // force to unlock Login and password recover buttons, when location changed
        case '@@router/LOCATION_CHANGE': {
            return {
                ...state,
                isPending: false,
                lastLoggedInError: null,
                passwordRecoverPending: false,
                passwordRecoverError: null,
                passwordRecoverSuccess: false,
            };
        }
        default:
            return state;
    }
}
