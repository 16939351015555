import { applySortingHelper, initialState, documentSearchInitialSortingState } from './helpers';
import { DOCUMENT_SEARCH_CSV_DATA_FETCHED } from '../actions/document/documentActions';

const documentListReducerCreator = (listName = '') =>
    function documentList(state = initialState, action) {
        switch (action.type) {
            case `${listName}_FETCHING`:
                return {
                    ...state,
                    isLoading: true,
                    searchParams: action.payload && action.payload.searchParams ? action.payload.searchParams : null,
                    skip: action.payload && action.payload.newSkip ? action.payload.newSkip : null,
                };
            case `${listName}_FETCHED`:
                return {
                    ...state,
                    isLoading: false,
                    filteredDocuments: action.payload.filteredDocuments,
                    totalEntries: action.payload.totalEntries,
                    allCount: action.allCount,
                    statusFilter: action.statusFilter,
                    skip: action.payload.skip,
                    ...(!action.payload.orderBy && documentSearchInitialSortingState),
                };
            case `${listName}_FETCH_FAILED`:
                return {
                    ...state,
                    isLoading: false,
                    error: action.error,
                };
            case `${listName}_REJECTED_COUNT_FETCHED`:
                return {
                    ...state,
                    rejectedCount: action.rejectedCount,
                };
            case `${listName}_SEARCH_EXECUTE`:
                return { ...state, isLoading: true };
            case `${listName}_SEARCH_SUCCESS`:
                return {
                    ...state,
                    filteredDocuments: action.payload.filteredDocuments,
                    searchParams: action.payload.searchParams,
                    totalEntries: action.payload.totalEntries,
                    allCount: action.payload.allCount,
                    statusFilter: action.statusFilter,
                    nothingFound: action.payload.nothingFound,
                    searchString: action.payload.searchString,
                    isLoading: false,
                };
            case `${listName}_APPLY_SORTING`:
                return applySortingHelper(state, action.payload);

            case `${listName}_APPLY_FILTERING`:
                return {
                    ...state,
                    filtering: action.payload,
                };
            case `${listName}_SHOW_ALL`:
                return {
                    ...state,
                    onlyTen: false,
                };
            case 'USER_LOGOUT':
                const { timeout } = action.payload;
                return timeout && timeout == true ? state : initialState;
            //return initialState;
            case `${listName}_CLEAR_SEARCH`: {
                return {
                    ...state,
                    searchParams: [],
                    filteredDocuments: [],
                    totalEntries: 0,
                    isLoading: false,
                    skip: null,
                };
            }
            case '@@router/LOCATION_CHANGE':
                return state.pathname !== action.payload.pathname
                    ? { ...state, pathname: action.payload.pathname }
                    : state;
            case `${listName}_SET_PERFORM_CONTAINS_SEARCH`:
                return {
                    ...state,
                    performContainsSearch: action.payload,
                };
            case 'DOCUMENT_SEARCH_CSV_DATA_FETCHED':
                return {
                    ...state,
                    isLoading: false,
                };
            default:
                return state;
        }
    };

export default documentListReducerCreator;
