import { AxiosResponse } from 'axios';
import { api } from '../../../services/api';
import { IVendorViewModel } from './types';

export class VendorApi {
    route = '/Company/Vendor';

    public getVendor = (id: number): Promise<AxiosResponse<IVendorViewModel | null>> => {
        return api().get(`${this.route}/${id}`);
    };

    public getVendors = (
        params: Record<string, string | number | boolean | null | undefined>
    ): Promise<AxiosResponse<{ Count: null; Items: IVendorViewModel[] }>> => {
        return api().get(`${this.route}/`, { params });
    };

    public updateVendor = (id: number, data: Partial<IVendorViewModel>): Promise<AxiosResponse<IVendorViewModel>> => {
        return api().put(`${this.route}/${id}`, data);
    };

    public createVendor = (data: Partial<IVendorViewModel>): Promise<AxiosResponse<{ IDs: number[] }>> => {
        return api().post(this.route, [data]);
    };
}
