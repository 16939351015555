//

import { handleActions } from 'redux-actions';

import * as ApplicationActions from '../actions/ApplicationActions';

const initialState = {
    mobileMenuVisible: false,
};

const AppearanceReducer = handleActions(
    {
        [ApplicationActions.SHOW_MOBILE_MENU]: (state) => {
            return {
                ...state,
                mobileMenuVisible: true,
            };
        },

        [ApplicationActions.HIDE_MOBILE_MENU]: (state) => {
            return {
                ...state,
                mobileMenuVisible: false,
            };
        },
    },
    initialState
);

export default AppearanceReducer;
