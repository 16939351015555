import { createContext, useContext } from 'react';
import { RelatedDocumentsState } from './types';

const RelatedDocumentsContext = createContext<RelatedDocumentsState>({
    documentId: 0,
    relatedDocuments: undefined,
    documentRouteTemplates: undefined,
    document: undefined,
    isBuyer: false,
});

export const RelatedDocumentsProvider = RelatedDocumentsContext.Provider;

export function useRelatedDocumentsState(): RelatedDocumentsState {
    return useContext(RelatedDocumentsContext);
}
