import { Divider, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';
import { TransceptaLogoDarkBlue } from 'common/build/legacy/transcepta-ui-core';

const useStyles = makeStyles(() => ({
    logo: {
        height: 48,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 16,
    },
}));

const LoginSupplierLogo: FC = () => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <section className={classes.logo}>
            <TransceptaLogoDarkBlue />
            <Divider
                orientation="vertical"
                style={{ marginRight: 16, marginLeft: 16, backgroundColor: theme.palette.text.primary }}
            />
            <Typography component="span" variant="h4">
                Supplier Portal
            </Typography>
        </section>
    );
};

export default LoginSupplierLogo;
