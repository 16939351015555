import { api } from '../../utils/index';
import apiPaths from '../../api/paths';

export const MANAGEUSERS_FETCHING = 'MANAGEUSERS_FETCHING';
export const MANAGEUSERS_FETCHED = 'MANAGEUSERS_FETCHED';
export const MANAGEUSERS_LIST_LOADED = 'MANAGEUSERS_LIST_LOADED';
export const MANAGEUSERS_ROLES_LOADED = 'MANAGEUSERS_ROLES_LOADED';
export const MANAGEUSERS_SAVE_USER = 'MANAGEUSERS_SAVE_USER';

function manageUsersFetching() {
    return {
        type: MANAGEUSERS_FETCHING,
    };
}

function manageUsersFetched() {
    return {
        type: MANAGEUSERS_FETCHED,
    };
}

function manageUsersListLoaded(response) {
    return {
        type: MANAGEUSERS_LIST_LOADED,
        payload: response.data.Items,
    };
}

function manageUsersRolesListLoaded(response) {
    return {
        type: MANAGEUSERS_ROLES_LOADED,
        payload: response.data,
    };
}

export function fetchUsersList() {
    return (dispatch) => {
        dispatch(manageUsersFetching());
        api()
            .get(apiPaths.readUser)
            .then(
                (response) => {
                    dispatch(manageUsersFetched());
                    dispatch(manageUsersListLoaded(response));
                },
                () => {
                    // parseMessage(err);
                    // TODO: Add error handling
                    dispatch(manageUsersFetched());
                }
            );
    };
}

export function fetchRolesList() {
    return (dispatch) => {
        dispatch(manageUsersFetching());
        api()
            .get(apiPaths.getAllRoles)
            .then(
                (response) => {
                    dispatch(manageUsersFetched());
                    dispatch(manageUsersRolesListLoaded(response));
                },
                () => {
                    // TODO: Add error handling
                    // parseMessage(err);
                    dispatch(manageUsersFetched());
                }
            );
    };
}

function userSaveSuccess(user) {
    return {
        type: MANAGEUSERS_SAVE_USER,
        payload: { ...user },
    };
}

export function saveUser(user) {
    console.log(user);
    return (dispatch) => {
        dispatch(manageUsersFetching());
        api()
            .post(apiPaths.readUser, { ...user })
            .then(
                () => {
                    dispatch(manageUsersFetched());
                    dispatch(userSaveSuccess(user));
                },
                () => {
                    // TODO: Add error handling
                    // parseMessage(err);
                    dispatch(manageUsersFetched());
                }
            );
    };
}
