import axios from 'axios';
import React from 'react';

import pathsConfig from '../paths.config.json';
import projectConfig from '../project.config.json';
import { optimizeApi } from 'common';

export function createApi() {
    return axios.create({
        ...pathsConfig.API,
        headers: {
            Authorization: `Bearer ${window.localStorage.access_token ? window.localStorage.access_token : ''}`,
        },
    });
}

export function api() {
    return optimizeApi(createApi());
}

const { baseURL, timeout } = pathsConfig.iframe;

export const outerAPI = () =>
    axios.create({
        baseURL,
        timeout,
    });

export const parseMessage = (errArr) =>
    errArr &&
    errArr.map((e) => [
        <span key="1">
            {projectConfig.errorMessages[e.Code]
                ? `${projectConfig.errorMessages[e.Code]} (${e.Code})`
                : `${e.Message} (${e.Code})`}
        </span>,
    ]);

export default api;
