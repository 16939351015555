import { api } from './api';
import apiPaths from './paths';
import { createDocumentBuyerMessageFilter } from './DocumentThunk.filter';

class DocumentThunk {
    fetchDocumentStatus = (params) => {
        return api()
            .get(apiPaths.validateDocument, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchDraftDocumentStatus = (params) => {
        return api()
            .get(apiPaths.validateDraftDocument, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchDocumentList = (params, headers = {}) => {
        return api()
            .get(`${apiPaths.document}`, { headers, params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchUserGroups = (params, headers = {}) => {
        return api()
            .get(`${apiPaths.userGroup}`, { headers, params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchVendor = (params) => {
        return api()
            .get(`${apiPaths.readCompany}Vendor/`, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchVendorList = ({ companyId, ...params }) =>
        api()
            .get(`${apiPaths.listVendors.replace(':id', companyId)}`, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );

    fetchDocumentLayout = (params) => {
        return api()
            .get(apiPaths.documentLayout, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    saveLayoutOverrides = (params) => {
        return api()
            .post(apiPaths.documentLayout, params.layoutViewModel)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchDocumentHistory = (params) => {
        return api()
            .get(apiPaths.documentHistory, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchDocument = (params) => {
        return api()
            .get(apiPaths.readDocument.replace(':id', params.documentId))
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchDocumentDraft = (params) => {
        return api()
            .get(apiPaths.documentDraft, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    deleteDocumentDraft = (params) => {
        return api()
            .delete(apiPaths.documentDraft, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchDocumentAttachments = (params) => {
        return api()
            .get(apiPaths.documentAttachments, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    saveDocumentAttachments = (params) => {
        return api()
            .post(apiPaths.saveDocumentAttachments.replace(':documentId', params.documentId), params.fileFormData)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchDocumentDraftAttachments = (params) => {
        return api()
            .get(apiPaths.documentDraftAttachments, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    // Document with actual Dynamic Discounting: 24345
    fetchDynamicDiscounting = (params) => {
        return api()
            .get(apiPaths.dynamicDiscounting, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    // Document Delivery Details
    fetchDeliveryDetails = (params) => {
        return api()
            .get(apiPaths.documentDelivery, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchPDF = (params) => {
        return api()
            .get(apiPaths.fetchPDF, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    generatePDF = (params) => {
        return api()
            .get(apiPaths.generatePDF, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchAttachment = (params) => {
        return api()
            .get(apiPaths.downloadDocumentAttachment, {
                params,
            })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchDeliveredDocument = ({ deliveryGroupId, fileName, ...params }) => {
        return api()
            .get(
                apiPaths.downloadDeliveredDocument
                    .replace(':deliveryGroupId', deliveryGroupId)
                    .replace(':fileName', fileName),
                { params }
            )
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    flipDocument = (params) => {
        return api()
            .get(apiPaths.flipDocument, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    saveInvoiceDraft = (params) => {
        return api()
            .post(apiPaths.documentDraft, params.document)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    saveDocumentDraft = (params) => {
        return api()
            .put(apiPaths.documentDraftSave.replace(':documentId', params.documentId), params.document)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    createDocumentDraft = (params) => {
        return api()
            .post(apiPaths.documentDraft, params.document)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    submitInvoice = (params) => {
        return api()
            .post(apiPaths.document, params.document)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    submitDocument = (params) => {
        return api()
            .put(apiPaths.documentDraftSave.replace(':documentId', params.documentId), params.document)
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    /**
     * Creates a new document (for buyer invoice creation).
     *
     * @param {Object} options - The options for creating the document.
     * @param {string} options.workflowId - The ID of the workflow.
     * @param {Object} options.document - The document data to be sent.
     * @param {string} [options.childCompanyId] - The ID of the child company (optional).
     * @returns {Promise<Object>} A promise that resolves to the response object or an error object.
     */
    createNewDocument = ({ workflowId, document, childCompanyId }) => {
        const path = apiPaths.document;

        const params = {
            workflowId,
            ...(childCompanyId && { childCompanyId }), // Conditionally add childCompanyId if it is provided
        };

        return api()
            .post(path, document, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    /**
     * Creates a new document with the provided file (for buyer invoice creation).
     *
     * @param {Object} options - The options for creating the document.
     * @param {string} options.workflowId - The ID of the workflow.
     * @param {Object} options.document - The document data to be sent.
     * @param {string} options.guidFileName - The GUID file name.
     * @param {string} [options.childCompanyId] - The ID of the child company (optional).
     * @returns {Promise<Object>} A promise that resolves to the response object or an error object.
     */
    createNewDocumentWithFile = ({ workflowId, document, guidFileName, childCompanyId }) => {
        const path = apiPaths.document;

        const params = {
            workflowId,
            guidFileName,
            ...(childCompanyId && { childCompanyId }), // Conditionally add childCompanyId if it is provided
        };

        return api()
            .post(path, document, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    updateDocument = ({ documentId, document, deleteLine, saveValidationFailureDetails } = {}) => {
        const path = apiPaths.readDocument.replace(':id', documentId);

        return api()
            .put(path, document, { params: { deleteLine, saveValidationFailureDetails, enableAuditLog: true } })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    saveDocumentDraftAttachments = (params) => {
        return api()
            .post(
                apiPaths.saveDocumentDraftAttachments
                    .replace(':draftId', params.draftId)
                    .replace(':businessDocType', params.businessDocType)
                    .replace(':documentId', params.documentId),
                params.fileFormData
            )
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    /**
     * Fetches the document skeleton based on the provided parameters.
     *
     * @param {Object} params - The parameters for fetching the document skeleton.
     * @param {string} params.businessDoctype - The type of the business document.
     * @param {string} params.client - Enum for where the Skeleton Document is used (e.g 'BuyerPortal', 'SupplierPortal', 'DynamicDiscounting', 'CSP', 'PDF', 'Nothing')
     * @param {number} params.buyerCompanyId - The ID of the buyer company (optional).
     * @param {number} params.customerId - The ID of the customer (optional).
     * @param {number} params.supplierCompanyId - The ID of the supplier company (optional).
     * @param {number} params.documentclass - The ID of the vendor class (optional).
     * @param {number} [params.childCompanyId] - The ID of the child company (optional).
     * @returns {Promise<Object>} A promise that resolves to the API response or an error object.
     */
    fetchDocumentSkeleton = (params) => {
        return api()
            .get(apiPaths.documentSkeleton, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    resendDocument = (params) => {
        return api()
            .post(apiPaths.resendDocument.replace(':documentId', params.documentId))
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchSearchConfig = (params) => {
        return api()
            .get(apiPaths.documentSearchConfiguration, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    deleteAttachment = (params) => {
        return api()
            .delete(apiPaths.deleteDocumentAttachment.replace(':id', params.id))
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };

    fetchDocumentBuyerMessage = (params) => {
        const {
            companyId,
            docState,
            vendorClass, // number | null
            businessDocType,
        } = params;

        const filters = createDocumentBuyerMessageFilter(docState, vendorClass, businessDocType);

        const query = [`companyId=${companyId}`, `$filter=${filters}`, `$orderBy desc`].join('&');

        return api()
            .get(`${apiPaths.documentBuyerMessage}?${query}`)
            .then((messages) => messages.data)
            .catch((error) => {
                console.warn('Error while retrieving document messages', error);
            });
    };

    /**
     * Fetches the count of active workflow documents.
     *
     * The precedence for fetching the count is based on the following order:
     * 1. companyId
     * 2. userId
     * 3. childCompanyId
     *
     * @param {Object} params - The parameters for fetching the document count.
     * @param {number} [params.companyId] - The ID of the company (optional).
     * @param {number} [params.userId] - The ID of the user (optional).
     * @param {string} [params.childCompanyId] - The ID(s) of the child company, can be comma-delimited (optional).
     * @returns {Promise<number>} The count of active workflow documents.
     */
    fetchDocumentActiveWorkflowDocumentCount = (params) => {
        return api()
            .get(apiPaths.documentActiveWorkflowDocumentCount, { params })
            .then(
                (response) => {
                    return response;
                },
                (err) => {
                    return {
                        type: 'error',
                        text: err,
                    };
                }
            );
    };
}

export default DocumentThunk;
