/**
 * Interface for an object that parses / serializes complex
 * object instances.
 */
export interface IInstanceTypeConverter<T> {
    /**
     * Uniquely identifies the instance type (constructor).
     *
     * We do not use the constructor name as it may not be unique.
     */
    readonly typeKey: string;

    /**
     * Constructor to check if this converter matches an object instance.
     */
    constructor: new (...args: any[]) => T;

    /**
     * Serialize an instance to a plain object
     * @param value the instance
     * @returns the plain object
     */
    serialize(value: T): any;

    /**
     * Parse a plain object to an instance
     * @param value the plain object
     * @returns the instance
     */
    parse(value: any): T;
}

/**
 * Allows defining a type converter in a contextual position where the
 * wrong type will be inferred by the compiler.
 * @param converter the type converter definition
 * @returns the definition value unchanged, but with the correct type
 */
export function createConverter<T>(converter: IInstanceTypeConverter<T>) {
    return converter;
}

/**
 * Indicates that a value cannot be serialized because we are missing a converter
 * for an instance.
 */
export class CannotConvertToPlainObject extends Error {
    constructor(public readonly object: any) {
        super(`Encountered an object that could not be converted to a plain object for serialization: ${object}`);

        Object.setPrototypeOf(this, CannotConvertToPlainObject.prototype);
    }
}

/**
 * Indicates that a value cannot be parsed because we are missing a converter
 * for an instance.
 */
export class CannotConvertToInstance extends Error {
    constructor(public readonly object: any) {
        super(`Encountered an object that could not be converted to an instance: ${object}`);

        Object.setPrototypeOf(this, CannotConvertToInstance.prototype);
    }
}
