import {
    FETCH_ALL_USERS,
    FETCH_ALL_USERS_EXECUTE,
    FETCH_ALL_USERS_ERROR,
    FETCH_ROLE_LIST,
    FETCH_ROLE_LIST_ERROR,
    FETCH_USER,
    FETCH_USER_FAILURE,
    UPDATE_USER,
    SAVE_USER_FAILED,
    DELETE_USER_FAILED,
    USER_CONFIRM,
    USERS_SORT,
    UPDATE_USER_SKIP,
    TOGGLE_PASSWORD_CHANGED,
    FETCH_USERS_APPLY_SEARCH,
} from '../actions/user';
import { applySortingHelper } from './helpers';

import { USER_LOGOUT } from '../actions/userlogin';
export const SORT_ASCENDING = 'asc';
export const SORT_DESCENDING = 'desc';

const initialState = {
    allUsers: [],
    count: 0,
    roles: [],
    selectedUser: {},
    sorting: {
        column: 'Name',
        direction: SORT_ASCENDING,
        type: 'String',
    },
    skip: 0,
    passwordChanged: false,
    isLoading: false,
};

export function user(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_USERS_EXECUTE:
            return {
                ...state,
                isLoading: true,
            };

        case FETCH_ALL_USERS:
            return {
                ...state,
                allUsers: action.payload.Items,
                count: action.payload.Count,
                isLoading: false,
            };
        case SAVE_USER_FAILED:
        case DELETE_USER_FAILED:
        case FETCH_ALL_USERS_ERROR:
            return state;
        case USER_LOGOUT:
            // clear the cache
            const { timeout } = action.payload;
            return timeout && timeout == true ? state : initialState;
        case FETCH_ROLE_LIST: {
            return {
                ...state,
                roles: action.payload,
            };
        }
        case FETCH_USER: {
            return {
                ...state,
                selectedUser: action.payload,
            };
        }
        case UPDATE_USER_SKIP: {
            return {
                ...state,
                skip: action.payload,
            };
        }
        case USERS_SORT: {
            return applySortingHelper(state, action.payload);
        }
        case UPDATE_USER: {
            if (!action.payload.Phone) {
                action.payload.Phone = '';
            }
            return {
                ...state,
                selectedUser: [action.payload],
            };
        }
        case USER_CONFIRM: {
            return {
                ...state,
                selectedUser: [
                    ...state.selectedUser.map((s) =>
                        s.ID === action.payload ? { ...s, AutogenUnconfirmed: false } : s
                    ),
                ],
            };
        }
        case TOGGLE_PASSWORD_CHANGED: {
            return {
                ...state,
                passwordChanged: !state.passwordChanged,
            };
        }
        case FETCH_USERS_APPLY_SEARCH: {
            return {
                ...state,
                searchString: action.payload,
            };
        }
        default:
            return state;
    }
}
