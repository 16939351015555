import { Breadcrumbs as MuiBreadcrumbs, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { Link, FCNC } from '../../ui';
import { IBreadCrumb } from './IBreadCrumb';
import { createBreadCrumbHistoryState } from './createBreadCrumbHistoryState';

export interface IBreadcrumbsProps {
    breadcrumbs: IBreadCrumb[];
}

export const Breadcrumbs: FCNC<IBreadcrumbsProps> = ({ breadcrumbs }) => {
    const history = useHistory();
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <MuiBreadcrumbs
            sx={
                isSmDown
                    ? {
                          paddingX: 2,
                      }
                    : {}
            }
            separator={<ArrowForward fontSize="small" />}
        >
            {breadcrumbs.map((crumb, i) =>
                i !== breadcrumbs.length - 1 && crumb.Path ? (
                    <Link
                        key={crumb.Label}
                        to={crumb.Path}
                        onClick={(ev) => {
                            ev.preventDefault();
                            const parentCrumbs = breadcrumbs.slice(0, breadcrumbs.indexOf(crumb));
                            history.push(crumb.Path!, createBreadCrumbHistoryState(parentCrumbs));
                        }}
                    >
                        <Typography key={crumb.Label} variant="body2">
                            {crumb.Label}
                        </Typography>
                    </Link>
                ) : (
                    <Typography key={crumb.Label} variant="body2">
                        {crumb.Label}
                    </Typography>
                )
            )}
        </MuiBreadcrumbs>
    );
};
