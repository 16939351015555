//
import R from '../routes';
import { push } from 'connected-react-router';
import { Action } from '../models/Actions';
import { api } from '../utils';
import apiPaths from '../api/paths';
import * as InvoiceModels from '../models/InvoiceModels';
import * as DocumentThunk from '../thunk/DocumentThunk';

import { DocumentThunk as CommonDocumentThunk } from 'common/build/legacy/transcepta-thunks';

export const PERFORM_CLEAR_INVOICE_CREATION_FIELDS = 'PERFORM_CLEAR_INVOICE_CREATION';

export const CREATE_INVOICE_PENDING = 'CREATE_INVOICE_PENDING';
export const CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS';
export const CREATE_INVOICE_FAILED = 'CREATE_INVOICE_FAILED';

export const SUBMIT_INVOICE_PENDING = 'SUBMIT_INVOICE_PENDING';
export const SUBMIT_INVOICE_SUCCESS = 'SUBMIT_INVOICE_SUCCESS';
export const SUBMIT_INVOICE_FAILED = 'SUBMIT_INVOICE_FAILED';

export const PERFORM_INVOICE_FIELD_VALUE_UPDATE = 'PERFORM_INVOICE_FIELD_VALUE_UPDATE';
export const PERFORM_SET_DEFAULT_DATA_INVOICE_FIELD = 'PERFORM_SET_DEFAULT_DATA_INVOICE_FIELD';
export const PERFORM_CHANGE_INVOICE_FIELD = 'PERFORM_CHANGE_INVOICE_FIELD';
export const PERFORM_CHANGE_INVOICE_MISC_AMOUNTS_FIELD = 'PERFORM_CHANGE_INVOICE_MISC_AMOUNTS_FIELD';
export const PERFORM_CHANGE_INVOICE_LINE_ITEMS_FIELD = 'PERFORM_CHANGE_INVOICE_LINE_ITEMS_FIELD';
export const PERFORM_CHANGE_CONTACT_INVOICE_FIELD = 'PERFORM_CHANGE_CONTACT_INVOICE_FIELD';
export const PERFORM_CHANGE_INVOICE = 'PERFORM_CHANGE_INVOICE';

export const PERFORM_ADD_INVOICE_MISC_AMOUNT = 'PERFORM_ADD_INVOICE_MISC_AMOUNT';
export const PERFORM_UPDATE_INVOICE_MISC_AMOUNT = 'PERFORM_UPDATE_INVOICE_MISC_AMOUNT';
export const PERFORM_DELETE_INVOICE_MISC_AMOUNT = 'PERFORM_DELETE_INVOICE_MISC_AMOUNT';
export const PERFORM_ADD_INVOICE_CONTACT = 'PERFORM_ADD_INVOICE_CONTACT';

export const UPDATE_INVOICE_CREATION_SELECTED_CURRENCY = 'UPDATE_INVOICE_CREATION_SELECTED_CURRENCY';

export const SUBMIT_INVOICE_FILES_PENDING = 'SUBMIT_INVOICE_FILES_PENDING';
export const SUBMIT_INVOICE_FILES_SUCCESS = 'SUBMIT_INVOICE_FILES_SUCCESS';
export const SUBMIT_INVOICE_FILES_FAILED = 'SUBMIT_INVOICE_FILES_FAILED';

export const UNLOADING_INVOICE_CREATION = 'UNLOADING_INVOICE_CREATION';

export const DOCUMENT_NAVIGATE_TO_INVOICE = 'DOCUMENT_NAVIGATE_TO_INVOICE';

export const UPDATE_INVOICE_CREATION_SELECTED_PROFILE = 'UPDATE_INVOICE_CREATION_SELECTED_PROFILE';

export const PERFORM_APPLY_DOCUMENT_STATE_DIFF = 'PERFORM_APPLY_DOCUMENT_STATE_DIFF';

export function performClearInvoiceCreationFields() {
    return {
        type: PERFORM_CLEAR_INVOICE_CREATION_FIELDS,
    };
}

export function performInvoiceFieldValueUpdate(
    fieldLayout,
    fieldName,
    newValue,
    index = null,
    updateAllInstances = false
) {
    return {
        type: PERFORM_INVOICE_FIELD_VALUE_UPDATE,
        payload: {
            fieldLayout: fieldLayout,
            fieldName: fieldName,
            newValue: newValue,
            index: index,
            updateAllInstances: updateAllInstances,
        },
    };
}

export const performApplyDocStateDiff = (stateDiff) => ({
    type: PERFORM_APPLY_DOCUMENT_STATE_DIFF,
    payload: stateDiff,
});

export const performChangeInvoiceField = (fieldName, value) => ({
    type: PERFORM_CHANGE_INVOICE_FIELD,
    payload: {
        fieldName,
        value,
    },
});

export const performChangeInvoice = (fieldName, value) => ({
    type: PERFORM_CHANGE_INVOICE,
    payload: {
        fieldName,
        value,
    },
});

export const performChangeContactInvoiceField = (fieldName, value, arrayIndeхMap, objectFieldNameMap) => ({
    type: PERFORM_CHANGE_CONTACT_INVOICE_FIELD,
    payload: {
        fieldName,
        value,
        arrayIndeхMap,
        objectFieldNameMap,
    },
});

export const performСhangeInvoiceLineItemsField = (fieldName, value, arrayIndeхMap) => ({
    type: PERFORM_CHANGE_INVOICE_LINE_ITEMS_FIELD,
    payload: {
        fieldName,
        value,
        arrayIndeхMap,
    },
});

export function performAddInvoiceMiscAmount(miscAmount) {
    return {
        type: PERFORM_ADD_INVOICE_MISC_AMOUNT,
        payload: {
            miscAmount: miscAmount,
        },
    };
}

export function performAddInvoiceContact(contact) {
    return {
        type: PERFORM_ADD_INVOICE_CONTACT,
        payload: {
            contact: contact,
        },
    };
}

export function performUpdateInvoiceMiscAmount(miscAmount, index) {
    return {
        type: PERFORM_UPDATE_INVOICE_MISC_AMOUNT,
        payload: {
            miscAmount: miscAmount,
            index: index,
        },
    };
}

export function performDeleteInvoiceMiscAmount(index) {
    return {
        type: PERFORM_DELETE_INVOICE_MISC_AMOUNT,
        payload: {
            index: index,
        },
    };
}

export const performСhangeInvoiceMiscAmountsField = (fieldName, value, arrayIndeхMap) => ({
    type: PERFORM_CHANGE_INVOICE_MISC_AMOUNTS_FIELD,
    payload: {
        fieldName,
        value,
        arrayIndeхMap,
    },
});

export const performSetDefaultDataInvoiceField = (defaultFields) => ({
    type: PERFORM_SET_DEFAULT_DATA_INVOICE_FIELD,
    payload: { defaultFields },
});

export function submitInvoice() {
    return (dispatch, getState) => {
        const invoiceFields = getState().document.document;
        dispatch({ type: SUBMIT_INVOICE_PENDING });

        const documentThunk = new CommonDocumentThunk();

        return documentThunk
            .submitInvoice({
                document: invoiceFields,
            })
            .then((response) => {
                if (response.type && response.type === 'error') {
                    dispatch({ type: SUBMIT_INVOICE_FAILED });
                    return;
                }

                if (response.data.ID && response.data.ID !== null) {
                    dispatch({ type: SUBMIT_INVOICE_SUCCESS });
                    dispatch(navigateToInvoice(response.data.ID));
                    dispatch(DocumentThunk.fetchDocument(response.data.ID, false));
                }
            });
    };
}

export const unloadInvoiceCreation = () => ({
    type: UNLOADING_INVOICE_CREATION,
});

export function performSelectedCurrencyUpdate(selectedCurrency) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_INVOICE_CREATION_SELECTED_CURRENCY,
            payload: selectedCurrency,
        });
    };
}

export function performSelectedInvoiceProfileUpdate(selectedProfile) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_INVOICE_CREATION_SELECTED_PROFILE,
            payload: selectedProfile,
        });
    };
}

export function navigateToInvoice(documentId) {
    return (dispatch) => {
        dispatch({ type: DOCUMENT_NAVIGATE_TO_INVOICE });
        dispatch(push(R.INVOICE_DETAILS.path.replace(':documentId', documentId)));
    };
}
