import { useQuery } from '@tanstack/react-query';
import { ICRUDService } from '../types';

/**
 * Fetches the form layout config using the ICRUDService, if such a method is defined on the service.
 * @param service the service to use to fetch the form layout config
 * @returns the layout config data and fetching status.
 */
export function useFormLayoutConfig<ID extends any, U extends object, T extends ICRUDService<ID, U>>(service: T) {
    const { data, isFetching, error } = useQuery(
        [`${service.key}LayoutConfig`],
        async () => {
            return service.fetchFormLayout!();
        },
        {
            enabled: !!service.fetchFormLayout,
            staleTime: 0,
            cacheTime: 0,
            retry: false,
        }
    );

    return {
        data: data ?? null,
        isLoading: isFetching,
        error,
    };
}
