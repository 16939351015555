import { DocumentState } from './document';
import { DocumentEditValidationState } from '../types/DocumentEditValidationState';

export const entityTypes = ['Document'] as const;

export type EntityType = typeof entityTypes[number];

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface Entities {
    Document: DocumentState;
}

export type EntityMutationFunction<K extends EntityType> = (
    entity: Entities[K],
    context: DocumentEditValidationState
) => Promise<void> | void;
