import { IFormValidationSchema, IOnSubmit, IErrors, IHandleChange } from './types';
import useResettableState from '../useResettableState';
import useStableReference from '../useStableReference';
import useValidationErrors from './useValidationErrors';
import useSubmit from './useSubmit';

/**
 * Parameters for `useForm`
 */
export interface IUseFormProps<T> {
    /**
     * The initial values for the form.
     *
     * *Note: Despite the name, changes to the `initialValues` are observed and
     * will cause the returned values to be overwritten. The name of this parameter
     * has been kept for historical reasons and may be updated in the future to better
     * reflect its behavior. A change to a single value will cause the entire internal
     * state to be replaced.*
     */
    initialValues: T;

    /**
     * A validation schema for the form fields. This schema can be partial,
     * meaning it does not need to include validators for every field in the
     * form. Only included fields will be validated.
     */
    validationSchema: Partial<IFormValidationSchema<T>>;

    /**
     * Function to call when the form is submitted. This is called regardless
     * of whether the form has errors.
     *
     * In most cases you will simply want to check if there are any errors. The
     * `hasErrors` parameter should be used to do this. If you need to check the
     * exact errors, use the `errors` parameter. The arguments to these 2 parameters
     * will always be consistent (eg, you will not get errors in `errors` and get
     * `hasErrors = false`)
     */
    onSubmit: IOnSubmit<T>;
}

/**
 * Object containing the current form state and methods to manipulate the
 * form state.
 */
export interface IUseFormController<T> {
    /**
     * The current values of the form.
     */
    values: T;

    /**
     * The current errors of the form fields.
     */
    errors: IErrors<T>;

    /**
     * Indicates whether the previous submission had errors.
     */
    hadErrorsOnSubmit: boolean;

    /**
     * Function to call to submit the form.
     */
    handleSubmit: () => void;

    /**
     * Function to call to change a form field.
     */
    handleChange: IHandleChange<T>;

    /**
     * Function to call to reset all fields to new values.
     */
    handleSet: (newValues: T) => void;
}

/**
 * Manages the state of a form.
 * @param props See `UseFormProps`
 * @returns See `UseFormController`
 */
export default function useForm<T>({
    initialValues,
    validationSchema: inputValidationSchema,
    onSubmit,
}: IUseFormProps<T>): IUseFormController<T> {
    const [values, setValues] = useResettableState(initialValues);
    const validationSchema = useStableReference(inputValidationSchema);
    const errors = useValidationErrors(values, validationSchema);

    const { handleSubmit, hadErrorsOnSubmit } = useSubmit(values, errors, onSubmit);

    function handleSet(newValues: T) {
        setValues(newValues);
    }

    const handleChange: IHandleChange<T> = (fieldKey, newValue) => {
        setValues((prev) => ({
            ...prev,
            [fieldKey]: newValue,
        }));
    };

    return {
        values,
        errors,
        handleChange,
        handleSet,
        handleSubmit,
        hadErrorsOnSubmit,
    };
}
