const emailRegex: RegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
const integerRegex: RegExp = /^[+-]?\d+$/;
const floatRegex: RegExp = /^[+-]?\d+(\.\d+)?$/;

export const checkForEmptyCell = (value: any) => {
    return value === null || value === undefined || (typeof value === 'string' && value.trim() === '');
};

export const htEmailValidator = (value: any, callback: any) => {
    // check for null or empty string
    if (checkForEmptyCell(value)) {
        callback(true);
        return;
    }

    // check if value is an email
    if (typeof value === 'string' && emailRegex.test(value.trim())) {
        callback(true);
    } else {
        callback(false);
    }
};

export const htIntegerValidator = (value: any, callback: any) => {
    // check for null or empty string
    if (checkForEmptyCell(value)) {
        callback(true);
        return;
    }

    // check if value is an integer
    if (
        (typeof value === 'string' && integerRegex.test(value.trim())) ||
        (typeof value === 'number' && integerRegex.test(String(value)))
    ) {
        callback(true);
    } else {
        callback(false);
    }
};

export const htFloatValidator = (value: any, callback: any) => {
    // check for null or empty string
    if (checkForEmptyCell(value)) {
        callback(true);
        return;
    }

    // check if value is a float
    if (
        (typeof value === 'string' && floatRegex.test(value.trim())) ||
        (typeof value === 'number' && floatRegex.test(String(value)))
    ) {
        callback(true);
    } else {
        callback(false);
    }
};

export const htBooleanValidator = (value: any, callback: any) => {
    // check for null or empty string
    if (checkForEmptyCell(value)) {
        callback(true);
        return;
    }

    // check if value is 'yes', 'y', 'no', or 'n'
    if (
        value.trim().toLowerCase() === 'yes' ||
        value.trim().toLowerCase() === 'y' ||
        value.trim().toLowerCase() === 'no' ||
        value.trim().toLowerCase() === 'n'
    ) {
        callback(true);
    } else {
        callback(false);
    }
};

export const htMultiEnumValidator = (enumList: string[], value: any, callback: any) => {
    // check for null or empty string
    if (checkForEmptyCell(value)) {
        callback(true);
        return;
    }

    // check if value is in the enum list
    const parsedMultiValues = value.split(',').map((x: string) => x.trim());
    for (const val of parsedMultiValues) {
        if (!enumList.includes(val)) {
            callback(false);
            return;
        }
    }
    callback(true);
};

export const errorMapSortAlgorithm = (a: [string, string], b: [string, string]) => {
    try {
        const rowA = JSON.parse(a[0]).row;
        const colA = JSON.parse(a[0]).col;
        const rowB = JSON.parse(b[0]).row;
        const colB = JSON.parse(b[0]).col;
        if (rowA > rowB) {
            return 1;
        } else if (rowA === rowB) {
            return colA > colB ? 1 : -1;
        } else if (rowA < rowB) {
            return -1;
        }
    } catch (e) {
        console.error('Error parsing errorMapSortAlgorithm');
    }
    return 0;
};
