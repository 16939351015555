//

import { handleActions } from 'redux-actions';

import * as CustomerLocationActions from '../../actions/invoiceCreation/customerLocation';
import * as CustomerModels from '../../models/CustomerModels';
import { Action } from '../../models/Actions';
import * as UserLoginActions from '../../actions/userlogin';

const initialState = {
    Count: null,
    Items: [],
    currentCustomer: null,
    extPageLink: null,
    isLoading: false,
    currentPage: 0,
};

const customerLocationReducer = handleActions(
    {
        [UserLoginActions.USER_LOGOUT]: (state, action) => {
            const { timeout } = action.payload;
            return timeout && timeout == true ? { ...state, timeout } : initialState;

            //return { ...initialState };
        },

        [CustomerLocationActions.GET_CUSTOMER_LOCATION_EXECUTE]: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },

        [CustomerLocationActions.GET_CUSTOMER_LOCATION_SUCCESS]: (state, action) => {
            return {
                ...state,
                ...action.payload,
                isLoading: false,
            };
        },

        [CustomerLocationActions.GET_CUSTOMER_LOCATION_FAILED]: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },

        [CustomerLocationActions.CREATE_CUSTOMER_LOCATION_EXECUTE]: (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },

        [CustomerLocationActions.CREATE_CUSTOMER_LOCATION_SUCCESS]: (state, action) => {
            return {
                ...state,
                isLoading: false,
                ...action.payload,
            };
        },

        [CustomerLocationActions.CREATE_CUSTOMER_LOCATION_FAILED]: (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
    },
    initialState
);

export default customerLocationReducer;
