import { FC } from 'react';
import { List, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BusinessDocType } from '../../../../types';
import { DocumentRevisionListItem } from './DocumentRevisionListItem';
import { DocumentRevision } from './useDocumentRevisions';

const useStyles = makeStyles((theme: Theme) => ({
    list: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
}));

export interface DocumentRevisionsListProps {
    documentRevisions: DocumentRevision[];
    relatedDocumentType: BusinessDocType;
    relatedDocumentNumber: string;
    currentDocumentType: BusinessDocType;
    processedAgainstRevisionDocumentId: number;
}

export const DocumentRevisionsList: FC<DocumentRevisionsListProps> = ({
    documentRevisions,
    relatedDocumentType,
    relatedDocumentNumber,
    currentDocumentType,
    processedAgainstRevisionDocumentId,
}) => {
    const classes = useStyles();

    return (
        <List className={classes.list}>
            {documentRevisions
                .sort((a, b) => {
                    return b.revisionNumber - a.revisionNumber;
                })
                .map((document) => (
                    <DocumentRevisionListItem
                        key={document.id}
                        document={document}
                        relatedDocumentType={relatedDocumentType}
                        relatedDocumentNumber={relatedDocumentNumber}
                        currentDocumentType={currentDocumentType}
                        processedAgainstRevisionDocumentId={processedAgainstRevisionDocumentId}
                    />
                ))}
        </List>
    );
};
