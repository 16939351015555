import { IFieldType, createFieldTypeFactory } from '../infrastructure';

/**
 * A dropdown field object.
 */
export interface IDropdownField extends IFieldType<unknown | null> {
    type: 'IDropdownField';

    /**
     * Prompt the user to select a value. Optional, no prompt
     * is displayed if not defined.
     */
    prompt?: string;

    /**
     * Label that is displayed if the current value is "null". Nothing
     * is displayed if not defined.
     */
    nullOptionLabel?: string;

    /**
     * The options the user can select from.
     */
    options: {
        /**
         * Label for the option.
         */
        label: string;

        /**
         * Value for the option. This is the value that the
         * field will have after selecting the option.
         */
        value: unknown;
    }[];

    /**
     * The Select component can handle multiple selections if multiple prop is true
     */
    multiple?: boolean;
}

export function createSimpleDropdownOptions(options: string[]) {
    return options.map((x) => ({ label: x, value: x }));
}

/**
 * Creates a dropdown field object.
 */
export const createDropdownField = createFieldTypeFactory<IDropdownField>('IDropdownField', null);
