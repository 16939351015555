import { IFieldType, createFieldTypeFactory } from '../infrastructure';
import { ITaxFieldValue } from '../../../../ui';

/**
 * A ITaxField field object.
 */
export interface ITaxField extends IFieldType<ITaxFieldValue> {
    type: 'ITaxField';

    locale: string;

    currencyCode: string;

    maxPercentageValue: number;

    maxAmountValue: number;

    taxableAmountDataBindingKeys: string[];
}

/**
 * Creates a ITaxField field object.
 */
export const createTaxField = createFieldTypeFactory<ITaxField>('ITaxField', null);
