export const loadConstantDropdown = (enums) => {
    return (
        enums &&
        enums.Value.map((value) => {
            return {
                value,
                label: value,
            };
        })
    );
};
