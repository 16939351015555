import {
    IValidationResultsCollector,
    ValidationClassResult,
    ValidationResult,
} from '../../ScriptingEngineEnvironment/Touchpoint/DocumentValidation';

export class ValidationResultsCollector implements IValidationResultsCollector {
    validationResults: ValidationClassResult[] = [];

    addError(result: ValidationResult): void {
        this.validationResults.push({
            class: 'Error',
            result,
        });
    }

    addWarning(result: ValidationResult): void {
        this.validationResults.push({
            class: 'Warning',
            result,
        });
    }

    public getResults(): ValidationClassResult[] {
        return this.validationResults;
    }
}
