import { createContext } from 'react';

interface IGridRowCheckboxContextState {
    areCheckboxesEnabled: boolean;
    checkedBoxes: number[];
    updateCheckedBoxes: (checkedBoxes: number[]) => void;
}

export const GridRowCheckboxContext = createContext<IGridRowCheckboxContextState>({
    areCheckboxesEnabled: false,
    checkedBoxes: [] as number[],
    updateCheckedBoxes: () => {},
});

// import { useState, useMemo } from 'react';
// const Example = () => {
//     const [checkedBoxes, setCheckedBoxes] = useState<number[]>([]);
//     const value = useMemo(
//         () => ({ areCheckboxesEnabled: true, checkedBoxes: checkedBoxes, updateCheckedBoxes: setCheckedBoxes }),
//         [checkedBoxes]
//     );

//     return <GridRowCheckboxContext.Provider value={value}><Foo/></GridRowCheckboxContext.Provider>;
// };
