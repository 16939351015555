import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import * as queryString from 'querystring';
import { getAccount, login } from '../../../../services';
import { IUserLogin } from '../../../transcepta-types';
import LoginContainer from './LoginContainer';
import { useAutoLogin } from './useAutoLogin';

interface ISSOLoginPageProps {
    article: ReactNode;
    userLogin: IUserLogin;
    ssoSignIn: () => void;
    getSSOSettings: (key: string) => void;
    backToLogin: () => void;
    autoLogin?: () => Promise<void>;
}

/**
 * Gets the origin for SSO redirect
 */
export function getSSOOrigin() {
    // special case for dev server -> redirect to the local IIS which hosts the SSO service
    if (window.location.host === 'localhost:3000') {
        return 'https://localhost';
    }

    // default case -> IIS is hosting both the portal and SSO service, so use same origin
    return window.location.origin;
}

/**
 * Gets the key for SSO settings lookup
 */
export function getSSOKey() {
    // special case for dev server -> use the `host` as the key
    if (window.location.host === 'localhost:3000') {
        return window.location.host;
    }

    // default case -> use the first part of the domain name (eg `buyer` in `buyer1.transcepta.com`) as the key
    const { host } = window.location;
    return host.slice(0, host.indexOf('.'));
}

/**
 * Initiate the SAML-based SSO login process using the SSO service.
 *
 * The user will be redirected to their IDP. After the IDP authenticates the user, they
 * will eventually get redirected back to the application with their API access token.
 *
 * @param client
 * @param encrypted
 */
export function initiateSAMLSSOLogin(client: string, encrypted: boolean) {
    window.location.href = `${getSSOOrigin()}/sso/sp/post?client=${client}&encrypted=${encrypted}`;
}

/**
 * Initiate the SAML-based SSO logout process using the SSO service.
 *
 * The user will be redirected to their IDP. After the IDP ends the user's session,
 * they will eventually get redirected back to the application, signed out.
 *
 * @param client
 * @param encrypted
 * @param nameId
 */
export function initiateSAMLSSOLogout(client: string, encrypted: boolean, nameId: string) {
    window.location.href = `${getSSOOrigin()}/sso/sp/single_logout/redirect?client=${client}&encrypted=${encrypted}&nameId=${nameId}`;
}

export const SSOLoginPage: FC<ISSOLoginPageProps> = ({
    article,
    userLogin,
    ssoSignIn,
    getSSOSettings,
    backToLogin,
    autoLogin,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>('');
    const [receivedToken, setReceivedToken] = useState<boolean>(false);
    const [receivedSSOSettings, setReceivedSSOSettings] = useState<boolean>(false);

    // this is needed to prevent a race on isLoading between this and the fetchToken below
    const [isAutoLoggingIn, setIsAutoLoggingIn] = useState(false);
    useAutoLogin(autoLogin, setIsAutoLoggingIn);

    const fetchSSOSettings = useCallback(async () => {
        await getSSOSettings(getSSOKey());
    }, [getSSOSettings]);

    useEffect(() => {
        if (!receivedSSOSettings) {
            fetchSSOSettings();
            setReceivedSSOSettings(true);
        }
    }, [fetchSSOSettings, receivedSSOSettings]);

    const fetchToken = useCallback(async () => {
        setIsLoading(true);
        let token: string | string[] | undefined;

        const params = queryString.parse(window.location.search);

        if (params) {
            if (params['?access_token']) {
                window.localStorage.access_token = params['?access_token'];
                window.localStorage.refresh_token = params.refresh_token;
                token = params['?access_token'];
                window.localStorage.token_expires = params.expires_in;
            }
        }

        if (userLogin.accountProfile?.SSOAttributes?.root?.type !== 'saml') {
            token = await getAccount(userLogin.accountProfile?.SSOAttributes);
        }

        if (token) {
            await ssoSignIn();
        } else {
            setIsLoading(false);
        }
    }, [ssoSignIn, userLogin.accountProfile.SSOAttributes]);

    useEffect(() => {
        if (!receivedToken) {
            fetchToken();
            setReceivedToken(true);
        }
    }, [fetchToken, receivedToken]);

    useEffect(() => {
        if (userLogin.lastLoggedInError !== null) {
            setIsLoading(false);
            setErrorMessage(userLogin.lastLoggedInError);
        } else {
            setErrorMessage(undefined);
        }
    }, [userLogin]);

    const handleSSOSignIn = async () => {
        setIsLoading(true);
        if (userLogin?.accountProfile?.SSOAttributes?.root) {
            const ssoAttributes = userLogin.accountProfile.SSOAttributes;
            if (ssoAttributes?.root?.type !== 'saml') {
                await login(ssoAttributes);
            } else {
                const client = ssoAttributes.root.client;
                const encrypted = ssoAttributes.root.encrypted;
                initiateSAMLSSOLogin(client, encrypted);
            }
        }
        await ssoSignIn();
    };

    return (
        <LoginContainer
            article={article}
            formVariant="sso"
            userLogin={userLogin}
            isLoading={isLoading || isAutoLoggingIn}
            email=""
            emailError={false}
            password=""
            passwordError={false}
            companyId={''}
            companyIdError={false}
            errorMessage={errorMessage}
            successMessage=""
            handleEmailChange={() => {}}
            handlePasswordChange={() => {}}
            handleCompanyIdChange={() => {}}
            handleSignIn={() => {}}
            handleForgotPassword={() => {}}
            handleReset={() => {}}
            handleBackToSignIn={() => {}}
            handleSSOSignIn={handleSSOSignIn}
            handleLogInAgain={backToLogin}
        />
    );
};
