import { FCNC } from '../../../../../../ui';

import { Input } from '../../../../Molecules/InputField';
import { ControlTypes } from '../../../../Molecules/InputField/types';

import calculateRemainderAndRunningTotals from '../calculateRemainderAndRunningTotals';

import './SplitAmountAndPercent.scss';

type SplitAmountAndPercentProps = {
    splitIndex: number;
    splits: any[];
    total: number;
    currencySymbol: string;
    remainingAmountMode: 'lastSplitOnly' | 'allSplits';

    currencyCode: string;
    onSegmentChange: (key: string, value: string) => void;
    config: {
        percentIsEditable: boolean;
        amountIsEditable: boolean;
    };
    initialValues: {
        percent: string;
        amount: string;
    };
    lineNumber: string;
};

const SplitAmountAndPercent: FCNC<SplitAmountAndPercentProps> = ({
    splitIndex,
    splits,
    total,
    currencySymbol,
    currencyCode,
    onSegmentChange,
    remainingAmountMode,
    config,
    initialValues,
    lineNumber,
}) => {
    const {
        isLastLine,
        formattedPercentRemaining: percentPlaceholder,
        formattedAmountRemaining: amountPlaceholder,
        isValidAllocation,
    } = calculateRemainderAndRunningTotals(splitIndex, splits, total, currencySymbol);

    const showPlaceholders = remainingAmountMode === 'allSplits' || isLastLine;

    return (
        <>
            {remainingAmountMode === 'allSplits' && <label htmlFor={'percent'}>Split (% and Amount)</label>}
            <div className={`form-fields${isValidAllocation ? ' valid-allocation' : ''}`}>
                <div className="percent-container">
                    <Input
                        id="percent"
                        className="percent-input"
                        fieldName="percent"
                        controlType={ControlTypes.Percentage}
                        onBlur={onSegmentChange}
                        initialValue={
                            initialValues.amount !== 'NaN' && (Number(initialValues.percent) || !showPlaceholders)
                                ? initialValues.percent || '0'
                                : undefined
                        }
                        editable={config.percentIsEditable}
                        editMode
                        placeholder={showPlaceholders ? percentPlaceholder : undefined}
                        testid={`percent-${lineNumber}-${splitIndex}`}
                    />
                </div>
                <div className="amount-container">
                    <Input
                        id="Amount"
                        className="amount-input"
                        fieldName="amount"
                        controlType={ControlTypes.Money}
                        currencyCode={currencyCode}
                        currencySymbol={currencySymbol}
                        onBlur={onSegmentChange}
                        initialValue={
                            Number(initialValues.amount) || !showPlaceholders || Number(initialValues.percent)
                                ? initialValues.amount || '0'
                                : undefined
                        }
                        editable={config.amountIsEditable}
                        editMode
                        placeholder={showPlaceholders ? amountPlaceholder : undefined}
                        testid={`amount-${lineNumber}-${splitIndex}`}
                    />
                </div>
            </div>
        </>
    );
};

export default SplitAmountAndPercent;
