export interface IWorkflowActivityExitFlowViewModel {
    Id: number;
    Result: string;
    FlowInfo: string;
    WorkflowActivityId: number;
    FlowType: FlowType;
    FlowType_US?: string;
    LastUpdateDate?: string;
    Rowversion?: string;
}

export enum FlowType {
    Undefined,
    'Sequence Flow',
    'Message Flow',
    Association,
}
