import { IFeatureFlagStates } from './types';
import { getDependencies, assertIsRegistered } from './registry';
import sources from './sources';

const cachedResult: { [key: string]: boolean | undefined } = {};

function isFeatureEnabledInConfiguration(featureKey: string) {
    return (
        sources.reduce((state: IFeatureFlagStates, source) => {
            const sourceState = source.getFeatureFlagState(featureKey);
            if (sourceState === 'enabled') {
                return 'enabled';
            }
            if (sourceState === 'disabled') {
                return 'disabled';
            }
            return state;
        }, 'unset') === 'enabled'
    );
}

function areDependenciesEnabled(featureKey: string, recur: typeof isFeatureEnabledComputation): boolean {
    const dependencies = getDependencies(featureKey);
    const enabledStates = dependencies.map((requiredKey) => {
        const result = recur(requiredKey);
        if (!result) {
            console.warn(
                `Feature "${featureKey}" will remain disabled because its dependency "${requiredKey}" is disabled.`
            );
        }
        return result;
    });
    return enabledStates.every(Boolean);
}

export function isFeatureEnabledComputation(featureKey: string, recur = isFeatureEnabledComputation) {
    assertIsRegistered(featureKey);
    return isFeatureEnabledInConfiguration(featureKey) && areDependenciesEnabled(featureKey, recur);
}

/**
 * Returns whether the feature is enabled.
 *
 * Disabled dependencies will be logged as a warning and cause the feature
 * to be reported as disabled, even if it was enabled in one of the feature
 * flag configuration sources.
 *
 * @param featureKey the feature to check
 * @returns whether the feature is enabled
 */
export default function isFeatureEnabled(featureKey: string) {
    if (cachedResult[featureKey] === undefined) {
        cachedResult[featureKey] = isFeatureEnabledComputation(featureKey, isFeatureEnabled);
    }

    return cachedResult[featureKey]!;
}
