export const PUSH_ALERT = 'PUSH_MESSAGE';
export const CLEAR_ALERTS = 'CLEAR_MESSAGES';
export function pushAlert(alert) {
    return {
        type: PUSH_ALERT,
        payload: alert,
    };
}

export function clearAlerts() {
    return {
        type: CLEAR_ALERTS,
    };
}
