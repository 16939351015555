import { useState } from 'react';
import { ConfirmationDialog, Dialog, Button } from '../../ui';
import debounce from 'lodash/debounce';

import { useIdleTimer } from 'react-idle-timer';
import { logSessionInformation } from './sessionLogger';
import { getAppConfig } from '../../config';

function useIsUserActive() {
    const idleTimeoutMinutes = getAppConfig().idleTimeout;
    const idleTimeoutDefault = 10;

    const { isIdle } = useIdleTimer({
        events: ['mousemove', 'keydown'],
        timeout: 60_000 * (idleTimeoutMinutes ?? idleTimeoutDefault),
        onActive(event) {
            logSessionInformation({ event: 'UserIsActive', reason: event?.type });
        },
    });

    return () => !isIdle();
}

const WARNING_TIME_MINUTES = 5;

type TimerAction = 'RESTART' | 'EARLY_LOGOUT' | 'LOGOUT';

export function SessionTimer({
    expiresInMilliseconds,
    refreshUserLogin,
    retrieveTokenExpiration,
    logout,
}: {
    expiresInMilliseconds: number;
    refreshUserLogin(): Promise<void>;
    retrieveTokenExpiration(): void;
    logout(): void;
}) {
    const [showTimeoutWarning, setShowTimeoutWarning] = useState(false);
    const [showTimeout, setShowTimeout] = useState(false);

    const isUserActive = useIsUserActive();

    const promptBeforeIdleMilliseconds = WARNING_TIME_MINUTES * 60_000;

    const { message } = useIdleTimer({
        events: [],
        promptBeforeIdle:
            promptBeforeIdleMilliseconds < expiresInMilliseconds
                ? promptBeforeIdleMilliseconds
                : expiresInMilliseconds - 1000,
        onPrompt: () => {
            if (isUserActive()) {
                logSessionInformation({ event: 'AutomaticSessionExtension' });
                refreshUserLogin().then(() => {
                    message('RESTART', true);
                });
                return;
            }
            logSessionInformation({ event: 'SessionWarningOpen' });

            setShowTimeoutWarning(true);
        },
        timeout: expiresInMilliseconds,
        onIdle: () => {
            setShowTimeoutWarning(false);
            setShowTimeout(true);
        },
        onMessage: (data: TimerAction, idleTimer) => {
            switch (data) {
                case 'RESTART': {
                    retrieveTokenExpiration();
                    setShowTimeoutWarning(false);

                    idleTimer?.start();
                    break;
                }
                case 'EARLY_LOGOUT': {
                    idleTimer?.reset();
                    logout();
                    break;
                }
                case 'LOGOUT': {
                    logout();
                    break;
                }
                default: {
                    throw new Error('Unexpected Action');
                }
            }
        },
        syncTimers: 200,
        crossTab: true,
    });

    const broadcast = (action: TimerAction, emitOnSelf?: boolean) => message(action, emitOnSelf);

    const handleSessionExtension = debounce(async () => {
        logSessionInformation({ event: 'ManualSessionExtension' });
        setShowTimeoutWarning(false);
        await refreshUserLogin();
        broadcast('RESTART', true);
    }, 500);

    const handleEarlyLogout = () => broadcast('EARLY_LOGOUT', true);
    const handleLogout = () => broadcast('LOGOUT', true);

    return (
        <>
            <ConfirmationDialog
                open={showTimeoutWarning}
                disableClickOutside
                title="Session Timeout Warning"
                prompt={`Your user session will expire in ${WARNING_TIME_MINUTES} minute(s). Would you like to continue your user session or logout?`}
                confirm={{
                    buttonTitle: 'Continue',
                    onClick: handleSessionExtension,
                }}
                reject={{
                    buttonTitle: 'Logout',
                    onClick: handleEarlyLogout,
                }}
                color="warn"
            />
            <Dialog
                open={showTimeout}
                title="Session Timeout"
                content="Your session has expired."
                handleOnClose={handleLogout}
                actions={<Button onClick={handleLogout}>Ok</Button>}
            />
        </>
    );
}
