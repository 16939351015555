import { DateRange, DateRangePicker as MuiDateRangePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, TextField as MuiTextField, useMediaQuery, useTheme } from '@mui/material';
import { FCNC } from '../FCNC';
import { TextField } from '../TextField';

export interface IDateRangePickerProps {
    id?: string;
    value: DateRange<Date>;
    onChange: (date: DateRange<Date>, keyboardInputValue?: string | undefined) => void;
    maxDate?: Date;
    error?: boolean;
    testId?: string;
    row?: boolean;
}

// TODO: This is not using our MUI styles, using old styles to match placement, needs update once rework document display
export const DateRangePicker: FCNC<IDateRangePickerProps> = ({ id, value, onChange, maxDate, error, testId, row }) => {
    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <LocalizationProvider id={id} dateAdapter={AdapterDateFns}>
            <MuiDateRangePicker
                data-testid={testId}
                startText="Start Date"
                endText="End Date"
                value={value}
                onChange={onChange}
                maxDate={maxDate}
                renderInput={(startProps, endProps) =>
                    row ? (
                        <Box component="div" sx={{ display: 'flex', gap: 2 }}>
                            <TextField
                                disabled={startProps.disabled}
                                error={startProps.error}
                                label={startProps.label as string}
                                inputProps={startProps.inputProps}
                                testId={`${testId}-start`}
                            />
                            <TextField
                                disabled={endProps.disabled}
                                error={endProps.error}
                                label={endProps.label as string}
                                inputProps={endProps.inputProps}
                                labelBoxSx={{ width: 80 }}
                                testId={`${testId}-end`}
                            />
                        </Box>
                    ) : (
                        <>
                            <MuiTextField
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...startProps}
                                error={error}
                                sx={{
                                    '.MuiOutlinedInput-root': {
                                        backgroundColor: 'background.paper',
                                        borderRadius: 0,
                                        fontSize: isSmDown ? 12 : 13.3,
                                    },
                                    '.MuiInputBase-input': {
                                        padding: isSmDown ? '6.5px 6px !important' : '',
                                    },
                                    '>.MuiFormLabel-root:not(.MuiFormLabel-filled):not(.Mui-focused)': { top: -9 },
                                }}
                                data-testid={`${testId}-start`}
                            />
                            <MuiTextField
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...endProps}
                                error={error}
                                sx={{
                                    '.MuiOutlinedInput-root': {
                                        backgroundColor: 'background.paper',
                                        borderRadius: 0,
                                        fontSize: isSmDown ? 12 : 13.3,
                                    },
                                    '.MuiInputBase-input': {
                                        padding: isSmDown ? '6.5px 6px !important' : '',
                                    },
                                    '>.MuiFormLabel-root:not(.MuiFormLabel-filled):not(.Mui-focused)': { top: -9 },
                                }}
                                data-testid={`${testId}-end`}
                            />
                        </>
                    )
                }
            />
        </LocalizationProvider>
    );
};
