/**
 * The field control state for a specific field.
 */
export interface IFieldControlState {
    /**
     * Overrides the default visibility of the field if it defaults to visible.
     */
    visible?: boolean;

    /**
     * Overrides the default editability of the field if it defaults to editable.
     */
    editable?: boolean;
}

/**
 * The field control state for all fields.
 */
export interface IFieldControlStates {
    [fieldKey: string]: IFieldControlState | undefined;
}

/**
 * The default field control state for a field when it has not been set by
 * any UIDocumentController.
 */
export const defaultFieldControlState: IFieldControlState = {};
