import { useMemo } from 'react';
import { ICRUDService } from '../types';

export type CRUDServiceConstructor = new (...args: any[]) => ICRUDService<any, any>;

/**
 * Creates a hook to use a ICRUDService for a CRUD page.
 * @param constructor Constructor for the crud service object
 * @returns a hook to construct an instance of the ICRUDService
 */
export function createUseCRUDService<T extends CRUDServiceConstructor>(constructor: T) {
    return (...args: ConstructorParameters<T>) => {
        // This is fine, we know these are the only dependencies our factory has
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return useMemo(() => new constructor(...args), args) as InstanceType<T>;
    };
}
