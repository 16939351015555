import { backendServices } from '../..';
import { BusinessDocType } from '../../../types';
import { escape } from '../../../utils';
import { portalUserService } from '../../UserService';
import {
    DocumentApi,
    ExternalStatus,
    IDocumentViewModel,
    IDocumentValidationFailureViewModel,
    IInvoiceMiscAmount,
} from '../Api';
import { IDocumentSkeleton, IMiscAmount } from './types';

export default class DocumentService {
    api = new DocumentApi();

    /**
     * TODO: get rid of this service class altogether
     */
    newDocumentApi = new backendServices.Apis.DocumentApi();

    private mapToMiscAmount = (miscAmount: IInvoiceMiscAmount): IMiscAmount => {
        return {
            adjustmentMethodId: miscAmount.AdjustmentMethodId,
            adjustmentReasonId: miscAmount.AdjustmentReasonId,
            amountIndicator: miscAmount.AmountIndicator,
            id: miscAmount.Id,
            invoiceId: miscAmount.InvoiceId,
            rate: miscAmount.Rate ?? 0,
            description: miscAmount.Description,
        };
    };

    private parseDocumentSkeleton = (skeleton?: IDocumentViewModel): IDocumentSkeleton | undefined => {
        switch (skeleton?.BusinessDocType) {
            case 1:
                const { Invoice } = skeleton.BusinessDocFields;
                const headerItems = Object.entries(Invoice)
                    .sort((a, b) => a[0].localeCompare(b[0]))
                    .filter((field: any) => typeof field[1] !== 'object' || field[1] === null);

                const lineItems = Object.entries(Invoice.InvoiceLineItems[0])
                    .sort((a, b) => a[0].localeCompare(b[0]))
                    .filter((field: any) => typeof field[1] !== 'object' || field[1] === null);

                return {
                    headerItems: Object.fromEntries(headerItems),
                    lineItems: Object.fromEntries(lineItems),
                    miscItems: Invoice.InvoiceMiscAmounts.map((item) => this.mapToMiscAmount(item)),
                };
            default:
                return undefined;
        }
    };

    public getDocumentSkeleton = async (): Promise<IDocumentSkeleton | undefined> => {
        const response = await this.api.getDocumentSkeleton();
        return this.parseDocumentSkeleton(response?.data);
    };

    public getDraftDocuments = async (businessDocType: number, idNumber: string, documentId: number) => {
        const companyID = portalUserService.getCurrentCompanyId();
        if (idNumber) {
            idNumber = idNumber.trim();
        }
        let filter =
            businessDocType === BusinessDocType.Invoice
                ? 'Draft eq 1 and BusinessDocType eq ' +
                  businessDocType +
                  " and InvoiceNumber eq '" +
                  escape(idNumber) +
                  "'"
                : 'ExternalStatus eq ' + ExternalStatus.Draft + ' and BusinessDocType eq ' + businessDocType;
        if (documentId && businessDocType !== BusinessDocType.Invoice) {
            filter += ' and DocumentID ne ' + documentId + 'L';
        }

        const response = await this.newDocumentApi.getDocuments(
            {
                companyID,
                $count: true,
                $filter: filter,
                $skip: 0,
                useTransaction: true,
                identifyingNumber:
                    businessDocType !== BusinessDocType.Invoice ? (idNumber == null ? 'null' : idNumber.trim()) : null,
            },
            { $top: null } // ! Not sure this makes sense, putting it here for now. We may be able to remove it.
        );

        if (response.data.Count) {
            return response.data;
        }
        return null;
    };

    public getDocumentSkeletonViewModel = async (): Promise<IDocumentViewModel | undefined> => {
        const response = await this.api.getDocumentSkeleton();
        return response?.data;
    };

    public getDocumentSkeletonViewModelByType = async (
        businessDocType: number
    ): Promise<IDocumentViewModel | undefined> => {
        const response = await this.api.getDocumentSkeletonByType(businessDocType);
        return response?.data;
    };

    public postIDEPortalValidations = async (
        documentViewModel: IDocumentViewModel | undefined,
        buyerCompanyId: number
    ): Promise<IDocumentValidationFailureViewModel[] | undefined> => {
        const response = await this.api.postIDEPortalValidations(documentViewModel, buyerCompanyId);
        return response?.data;
    };
}
