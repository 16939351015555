/**
 * Ensures there is only a single instance of the feature flag registry.
 *
 * This is needed because currently our builds are set up such that there isn't only
 * one instance of this package at runtime. Using a module scoped registry would lead
 * to different instances seeing different flags as available.
 *
 * @returns the global flag registry
 */
function getOrCreateGlobalFlagRegistry() {
    (window as any).transceptaFeatureFlagRegistry = (window as any).transceptaFeatureFlagRegistry || {};
    return (window as any).transceptaFeatureFlagRegistry as { [key: string]: string[] | undefined };
}

const flags = getOrCreateGlobalFlagRegistry();

/**
 * Register a feature flag and declare any dependencies it has.
 * @param featureKey feature to enable
 * @param dependencies that must be enabled for the feature to be enabled
 */
export function registerFeatureFlag(featureKey: string, dependencies: string[] = []) {
    flags[featureKey] = dependencies;
}

/**
 * Checks if a feature is registered.
 * @param featureKey the feature to check
 * @returns whether the feature is registered
 */
export function isFeatureRegistered(featureKey: string) {
    return flags[featureKey] !== undefined;
}

/**
 * Throws an error if the feature is not registered
 * @param featureKey feature to check
 */
export function assertIsRegistered(featureKey: string) {
    if (!isFeatureRegistered(featureKey)) {
        const message = `The application attempted to check an unregistered feature key (${featureKey}). Feature keys must be registered so they can be listed.`;
        // explicitly log in case the client code catches and ignores the error
        console.error(message);
        throw new Error(message);
    }
}

/**
 * Retrieve the registered dependencies for a feature
 * @param featureKey the feature to retrieve dependencies for
 * @returns the feature's dependencies
 */
export function getDependencies(featureKey: string) {
    return flags[featureKey] || [];
}

/**
 * Get all registered features
 * @returns all registered featuers
 */
export function getAllFeatures() {
    return Object.keys(flags);
}
