import { useEffect, useMemo } from 'react';

export class DependenciesWatcher {
    private resolver: (...args: unknown[]) => void = () => {};

    private promise = new Promise(() => {});

    private dependenciesState: 'Loading' | 'Ready' = 'Loading';

    constructor() {
        this.promise = new Promise((res) => {
            this.resolver = res;
        });
    }

    get state() {
        return this.dependenciesState;
    }

    public setReadyState() {
        this.resolver();
        this.dependenciesState = 'Ready';
    }

    public async waitForDependencies() {
        return this.promise;
    }
}

export function useDependencyWatcher({ dependenciesLoaded }: { dependenciesLoaded: boolean }) {
    const dependenciesWatcher = useMemo(() => {
        return new DependenciesWatcher();
    }, []);

    useEffect(() => {
        if (dependenciesLoaded) {
            dependenciesWatcher.setReadyState();
        }
    }, [dependenciesLoaded, dependenciesWatcher]);

    return dependenciesWatcher;
}
