import f, { ODataFilterBuilder } from 'odata-filter-builder';
import { escape, isEmpty, ODataUtils } from '../../../utils';

export const createBusinessDocFlipGridFilter = (
    id: string | null,
    companyId: number | null,
    name: string | null,
    sourceDocType: number | null,
    destinationDocType: number | null
) => {
    const filterRules: ODataFilterBuilder[] = [];

    if (!isEmpty(id)) {
        filterRules.push(f().eq('Id', escape(id!)));
    }

    if (companyId) {
        filterRules.push(f().eq('CompanyId', companyId));
    }

    if (!isEmpty(name)) {
        filterRules.push(f().contains('name', escape(name!)));
    }

    if (sourceDocType) {
        filterRules.push(f().eq('SourceBusinessDocTypeId', sourceDocType));
    }

    if (destinationDocType) {
        filterRules.push(f().eq('DestinationBusinessDocTypeId', destinationDocType));
    }

    return filterRules.length > 0 ? ODataUtils.andConditions(filterRules).toString() : undefined;
};
