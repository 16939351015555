import { RangeNumericTextValue } from '../../../../ui';
import { createFieldTypeFactory, IFieldType } from '../infrastructure';

/**
 * A range numeric field object.
 */
export interface IRangeNumericTextField extends IFieldType<RangeNumericTextValue> {
    type: 'IRangeNumericTextField';

    locale: string;

    scale?: number;

    padFractionalZeros?: boolean;

    maxValue?: number;
}

/**
 * Creates a INumericTextField field object.
 */
export const createRangeNumericTextField = createFieldTypeFactory<IRangeNumericTextField>('IRangeNumericTextField', [
    null,
    null,
]);
