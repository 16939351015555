import LocalizedStrings from 'react-localization';

const strings = new LocalizedStrings({
    'en-US': {
        textPasswordCategoryError: 'Password must contain characters from three of the four categories.',
        textMissingOldPassword: 'Please type old password.',
        textBadPasswordConfirm: 'Wrong password confirmation.',
        textOldPasswordMatches: 'Old Password and New Password must be different.',
        textMissingNewPassword: 'New password is blank.',
        textBadPasswordLength: 'Password does not meet the length requirement.',
        textPasswordRules:
            'Your password must be at least 7 characters long and must contain characters from at least 3 of following categories:<br><br/><ul><li>lower case characters a-z</li><li>upper case characters A-Z</li><li>numeric digits 0-9</li><li>special characters @#$%^&*()_+|-={}[]:";\'&lt; &gt;/</li></ul><br/><br/>',
        textOldPassword: 'Old Password',
        textNewPassword: 'New Password',
        textConfirmPassword: 'Re-enter new Password',
        textSave: 'Save',
        textCancel: 'Cancel',
        textBadOldPassword: 'Old Password is invalid',
    },

    'es-US': {
        textPasswordCategoryError: 'La contraseña debe contener caracteres de tres de las cuatro categorías.',
        textMissingOldPassword: 'Por favor escriba la contraseña anterior.',
        textBadPasswordConfirm: 'Confirmación de contraseña incorrecta.',
        textOldPasswordMatches: 'La contraseña antigua y la nueva contraseña deben ser diferentes.',
        textMissingNewPassword: 'La nueva contraseña está en blanco.',
        textBadPasswordLength: 'La contraseña no cumple con el requisito de longitud.',
        textPasswordRules:
            'Su contraseña debe tener al menos 7 caracteres y debe contener caracteres de al menos 3 de las siguientes categorías:<br><br/><ul><li>caracteres en minúscula a-z</li><li>caracteres en mayúscula A-Z</li><li>digits numéricos 0-9</li><li>caracteres especiales @#$%^&*()_+|-={}[]:";\'&lt; &gt;/</li></ul><br/><br/>',
        textOldPassword: 'Contraseña Anterior',
        textNewPassword: 'Nueva Contraseña',
        textConfirmPassword: 'Re-ingrese Nueva Contraseña',
        textSave: 'Salvar',
        textCancel: 'Cancelar',
        textBadOldPassword: 'La contraseña anterior no es válida',
    },
});

export default strings;
