import { useQuery } from '@tanstack/react-query';

import { BusinessDocType } from '../types';
import { backendServices } from '../services';

export function useDocumentByBusinessDocId({ docId, docType }: { docId?: string; docType?: BusinessDocType }) {
    const enabled = docId != null && docType != null;

    const query = useQuery(
        ['Document', docId, docType],
        async () => {
            const api = new backendServices.Apis.DocumentApi();

            const res = await api.getDocumentByBusinessDocId({
                businessDocId: docId!,
                businessDocType: docType!,
            });

            return res.data;
        },
        {
            enabled,
        }
    );

    return query;
}

