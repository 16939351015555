import { FormEvent, ReactNode } from 'react';
import { Alert } from '../Alert';
import { Box, Typography } from '@mui/material';
import { FormItems } from './FormItems';
import { FCNC } from '../FCNC';
import { FormControlProvider } from './FormControlContext';
import { ScrollToHere, IScrollToHereProps } from '../ScrollToHere';

export interface IFormProps {
    testId: string;
    loadingMessage?: ReactNode;
    savingMessage?: ReactNode;
    errorMessage?: ReactNode;
    successMessage?: ReactNode;
    infoMessage?: ReactNode;
    warningMessage?: ReactNode;
    columns: ReactNode[];
    onSubmit?: () => void;
    requiredMessage?: boolean;
    customFooterMessage?: string;
    formButtons?: ReactNode;
    readonly?: boolean;
    scrollToAlert?: IScrollToHereProps;
}

interface IFormAlertProps {
    testId: string;
    loadingMessage: ReactNode;
    savingMessage: ReactNode;
    errorMessage: ReactNode;
    successMessage: ReactNode;
    infoMessage: ReactNode;
    warningMessage: ReactNode;
}

export const FormAlert: FCNC<IFormAlertProps> = ({
    testId,
    savingMessage,
    errorMessage,
    successMessage,
    loadingMessage,
    infoMessage,
    warningMessage,
}) => {
    // priority is loading, saving, error, success, info
    const loadingOrSavingMessage: ReactNode = loadingMessage || savingMessage;
    if (loadingOrSavingMessage) {
        return (
            <Alert testId={testId} severity="info" loading>
                {loadingOrSavingMessage}
            </Alert>
        );
    }

    if (errorMessage) {
        return (
            <Alert testId={testId} severity="error">
                {errorMessage}
            </Alert>
        );
    }

    if (successMessage) {
        return (
            <Alert testId={testId} severity="success">
                {successMessage}
            </Alert>
        );
    }

    if (warningMessage) {
        return (
            <Alert testId={testId} severity="warning">
                {warningMessage}
            </Alert>
        );
    }

    if (infoMessage) {
        return (
            <Alert testId={testId} severity="info">
                {infoMessage}
            </Alert>
        );
    }

    return null;
};

export const Form: FCNC<IFormProps> = ({
    columns,
    onSubmit,
    savingMessage,
    errorMessage,
    successMessage,
    loadingMessage,
    infoMessage,
    warningMessage,
    testId,
    requiredMessage = true,
    customFooterMessage,
    formButtons,
    readonly = false,
    scrollToAlert,
}) => {
    const loadingOrSavingMessage: ReactNode = loadingMessage || savingMessage;

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (onSubmit) {
            onSubmit();
        }
    };

    return (
        <Box sx={{ my: 2 }} data-testid={testId}>
            <ScrollToHere enabled={!!scrollToAlert?.enabled} marginTop={scrollToAlert?.marginTop}>
                <FormAlert
                    testId={`${testId}-message`}
                    warningMessage={warningMessage}
                    infoMessage={infoMessage}
                    errorMessage={errorMessage}
                    successMessage={successMessage}
                    savingMessage={savingMessage}
                    loadingMessage={loadingMessage}
                />
            </ScrollToHere>
            <FormControlProvider value={{ readonly }}>
                <form onSubmit={handleSubmit} noValidate data-testid={`${testId}-form`}>
                    <FormItems columns={columns} showBackdrop={!!loadingOrSavingMessage} />
                    <Box
                        sx={{
                            px: 3,
                            py: 1,
                            '& > *:not(:first-child)': {
                                pt: 0.5,
                            },
                        }}
                    >
                        {requiredMessage && (
                            <Box>
                                <Typography component="span" variant="caption">
                                    * indicates a required field
                                </Typography>
                            </Box>
                        )}
                        {customFooterMessage && (
                            <Box>
                                <Typography component="span" variant="caption">
                                    {customFooterMessage}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    {formButtons && (
                        <Box
                            sx={{
                                pointerEvents: !!loadingOrSavingMessage ? 'none' : 'auto',
                            }}
                        >
                            {formButtons}
                        </Box>
                    )}
                </form>
            </FormControlProvider>
        </Box>
    );
};
