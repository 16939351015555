// DEPRECATED: Do not use
// REFACTOR: Move under Organisms/Grid as that is the only thing using it
import { FC, useState } from 'react';
import clsx from 'clsx';
import Select from 'react-select-legacy';
import './GridHeader.scss';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { HeaderCheckboxCell } from './HeaderCheckboxCell';

export interface IGridHeaderProps {
    columns: Array<{
        name: string;
        key: string;
        noSort: boolean;
    }>;
    sortColumn?: string;
    sortDirection?: string;
    handleSort?: (key: string) => void;
    isDesktop: boolean;
    records?: any;
}

/**
 * @deprecated
 */
export const GridHeader: FC<IGridHeaderProps> = ({
    columns,
    sortColumn = '',
    sortDirection = 'asc',
    handleSort,
    isDesktop,
    records,
}) => {
    const [isMobileSortEnabled, setIsMobileSortEnabled] = useState(false);
    const headerCell = (name, key, noSort, newKey) => {
        const isSortActive = !noSort && sortColumn === key;
        const onHeaderCellClickHandler = () => {
            if (!noSort && handleSort) {
                handleSort(key);
            }
        };
        return (
            <span
                className={`${key} ${noSort ? 'not-sortable' : 'sortable'}`}
                onClick={onHeaderCellClickHandler}
                role="button"
                key={newKey}
                tabIndex={0}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        onHeaderCellClickHandler();
                    }
                }}
            >
                <span>{name} </span>
                {isSortActive ? (
                    <>
                        <span className="sort-current-icon">
                            {sortDirection === 'desc' ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                        </span>
                        <span className="sort-hover-icon">
                            {sortDirection === 'desc' ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </span>
                    </>
                ) : (
                    <span className="sort-hover-icon">
                        <KeyboardArrowDown />
                    </span>
                )}
            </span>
        );
    };

    return (
        <section className={clsx('core-grid-header', { 'is-mobile': !isDesktop })}>
            <HeaderCheckboxCell records={records} />
            {isDesktop && columns ? (
                columns.map((column, index) => headerCell(column.name, column.key, column.noSort, index.toString()))
            ) : (
                <div className="mobile-header">
                    <span
                        className="sort-icon"
                        role="button"
                        onClick={() => setIsMobileSortEnabled(!isMobileSortEnabled)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                setIsMobileSortEnabled(!isMobileSortEnabled);
                            }
                        }}
                        tabIndex={0}
                    >
                        {sortDirection === 'desc' ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                    </span>
                    {isMobileSortEnabled && (
                        <div className="sort-dropdown">
                            <Select
                                options={columns.map((c) => ({
                                    value: c.key,
                                    label: c.name,
                                }))}
                                placeholder="Sort by..."
                                onChange={(selectedOption) =>
                                    // @ts-ignore
                                    handleSort && handleSort(selectedOption.value)
                                }
                            />
                        </div>
                    )}
                </div>
            )}
        </section>
    );
};
