import { ChangeEvent, FC, FormEvent, ReactNode, useEffect } from 'react';
import { CircularProgress, Divider, Paper, Theme, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IUserLogin } from '../../../transcepta-types';
import { getAppConfig } from '../../../../config';
import clsx from 'clsx';
import LoginForm from './LoginForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import SSOForm from './SSOForm';
import LogoutForm from './LogoutForm';
import { OperationTookTooLongGuard } from '../../Molecules';
import { FormVariant } from './types';
import InternalLoginForm from './InternalLoginForm';
import { turnDedupForeverOff, turnDedupForeverOn } from '../../../../services/utils/axiosOptimizer';
import { Alert } from '../../../../ui';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: 700,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    sso: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 16,
    },
    ssoText: {
        textAlign: 'center',
    },
    logo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 16,
        '& img': {
            maxWidth: '400px',
            maxHeight: '200px',
            width: 'auto',
            height: '100%',
        },
    },
    error: {
        display: 'flex',
        flexDirection: 'column',
        // @ts-ignore
        backgroundColor: theme.palette.error['100'],
        borderLeft: `16px solid ${theme.palette.error.main}`,
        marginTop: 16,
        marginBottom: 16,
        padding: 12,
    },
    success: {
        display: 'flex',
        flexDirection: 'column',
        // @ts-ignore
        backgroundColor: theme.palette.success['100'],
        borderLeft: `16px solid ${theme.palette.success.main}`,
        marginTop: 16,
        marginBottom: 16,
        padding: 12,
    },
    message: {
        marginBottom: 16,
    },
    card: {
        display: 'flex',
        gap: 8,
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        padding: 24,
        '& > form > *': {
            marginBottom: 8,
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: 200,
        },
    },
    accent: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText + ' !important',
        '& > *': {
            textDecorationColor: `${theme.palette.primary.contrastText} !important`,
            color: theme.palette.primary.contrastText + ' !important',
        },
    },
    loading: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    copyright: {
        marginTop: `${theme.spacing(1)} !important`,
        textAlign: 'right',
    },
}));

interface IPageContentProps {
    formVariant: FormVariant;
    userLogin: IUserLogin;
    isLoading: boolean;
    email: string;
    password: string;
    companyId: string;
    emailError: boolean;
    passwordError: boolean;
    companyIdError: boolean;
    handleEmailChange: (event: ChangeEvent) => void;
    handlePasswordChange: (event: ChangeEvent) => void;
    handleCompanyIdChange: (event: ChangeEvent) => void;
    handleSignIn: (event: FormEvent) => void;
    handleForgotPassword: () => void;
    handleReset: (event: FormEvent) => void;
    handleBackToSignIn: () => void;
    handleSSOSignIn: () => void;
    handleLogInAgain: () => void;
    isBuyer?: boolean;
}

interface IProps extends IPageContentProps {
    article: ReactNode;
    logo?: ReactNode;
    errorMessage?: string;
    successMessage?: string;
    isBuyer?: boolean;
}

const PageContent: FC<IPageContentProps> = ({
    formVariant,
    userLogin,
    isLoading,
    email,
    password,
    companyId,
    emailError,
    passwordError,
    companyIdError,
    handleEmailChange,
    handlePasswordChange,
    handleCompanyIdChange,
    handleSignIn,
    handleForgotPassword,
    handleReset,
    handleBackToSignIn,
    handleSSOSignIn,
    handleLogInAgain,
    isBuyer,
}) => {
    const classes = useStyles();

    const getDataSelector: Record<string, string> = {
        forgotPassword: 'Resetting Password',
        loggingOut: 'Signing Out',
        default: 'Fetching User Profile',
    };

    if (isLoading) {
        return (
            <div className={classes.loading}>
                <CircularProgress color="secondary" />
                <Typography component="span" variant="caption">
                    {getDataSelector[formVariant] ?? getDataSelector.default}
                </Typography>
            </div>
        );
    }

    // REFACTOR: this should be "slot prop" passed in from the component rendering LoginContainer, which
    // will allow us to not drill most of these props down to here.
    switch (formVariant) {
        case 'login':
            return (
                <LoginForm
                    isBuyer={isBuyer}
                    email={email}
                    emailError={emailError}
                    handleEmailChange={handleEmailChange}
                    password={password}
                    passwordError={passwordError}
                    handlePasswordChange={handlePasswordChange}
                    handleSignIn={handleSignIn}
                    handleForgotPassword={handleForgotPassword}
                />
            );
        case 'forgotPassword':
            return (
                <ForgotPasswordForm
                    email={email}
                    emailError={emailError}
                    handleEmailChange={handleEmailChange}
                    handleReset={handleReset}
                    handleBackToSignIn={handleBackToSignIn}
                />
            );
        case 'sso':
            return (
                <SSOForm handleSSOSignIn={handleSSOSignIn} handleLogInAgain={handleLogInAgain} userLogin={userLogin} />
            );
        case 'internal':
            return (
                <InternalLoginForm
                    email={email}
                    emailError={emailError}
                    handleEmailChange={handleEmailChange}
                    password={password}
                    passwordError={passwordError}
                    handlePasswordChange={handlePasswordChange}
                    companyId={companyId}
                    companyIdError={companyIdError}
                    handleCompanyIdChange={handleCompanyIdChange}
                    handleSignIn={handleSignIn}
                    handleForgotPassword={handleForgotPassword}
                />
            );
        case 'logout':
            return <LogoutForm handleLogInAgain={handleLogInAgain} />;
        default:
            return null;
    }
};

const LoginContainer: FC<IProps> = ({
    article,
    logo,
    formVariant,
    userLogin,
    isLoading,
    email,
    password,
    companyId,
    emailError,
    passwordError,
    companyIdError,
    errorMessage,
    successMessage,
    handleEmailChange,
    handlePasswordChange,
    handleCompanyIdChange,
    handleSignIn,
    handleForgotPassword,
    handleReset,
    handleBackToSignIn,
    handleSSOSignIn,
    handleLogInAgain,
    isBuyer = false,
}) => {
    const classes = useStyles();

    useEffect(() => {
        if (isLoading) {
            turnDedupForeverOn();
        } else {
            turnDedupForeverOff();
        }

        return () => {
            turnDedupForeverOff();
        };
    }, [isLoading]);

    return (
        <div className={classes.root}>
            <OperationTookTooLongGuard
                isLoading={isLoading}
                tooLongTimeInSeconds={getAppConfig().logInStallTimeout ?? 30}
            />
            {formVariant === 'sso' ? (
                <section className={classes.sso}>
                    <div className={classes.logo}>
                        <img
                            alt={userLogin.accountProfile?.SSOAttributes?.root?.customerLogo?.alternateText}
                            src={userLogin.accountProfile?.SSOAttributes?.root?.customerLogo?.url}
                        />
                    </div>
                    {userLogin.accountProfile?.SSOAttributes?.root?.customWelcomeText ? (
                        <span className={classes.ssoText}>
                            {userLogin.accountProfile?.SSOAttributes?.root?.customWelcomeText}
                        </span>
                    ) : (
                        userLogin.accountProfile?.SSOAttributes?.root?.customText && (
                            <div
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: userLogin.accountProfile.SSOAttributes.root.customText,
                                }}
                            />
                        )
                    )}
                </section>
            ) : null}
            {logo && logo}
            {errorMessage && (
                <div>
                    {errorMessage
                        .split('\n')
                        .filter((item) => item.length > 5)
                        .map((value, index) => (
                            <Alert
                                severity="error"
                                testId={`login-error-${index}`}
                                // eslint-disable-next-line react/no-array-index-key
                                key={`login-error-${index}`}
                                sx={{ my: 1 }}
                            >
                                <Typography component="span" variant="caption">
                                    {value}
                                </Typography>
                            </Alert>
                        ))}
                </div>
            )}
            {successMessage && (
                <div>
                    {successMessage
                        .split('\n')
                        .filter((item) => item.length > 5)
                        .map((value, index) => (
                            <Alert
                                severity="success"
                                testId={`login-success-${index}`}
                                // eslint-disable-next-line react/no-array-index-key
                                key={`login-success-${index}`}
                                sx={{ my: 1 }}
                            >
                                <Typography component="span" variant="caption">
                                    {value}
                                </Typography>
                            </Alert>
                        ))}
                </div>
            )}
            <Paper className={classes.card} elevation={6}>
                <Box className={clsx(classes.column, classes.accent)}>{article}</Box>
                <Divider orientation="vertical" />
                <div className={clsx(classes.column)}>
                    <PageContent
                        isBuyer={isBuyer}
                        formVariant={formVariant}
                        userLogin={userLogin}
                        isLoading={isLoading}
                        email={email}
                        password={password}
                        companyId={companyId}
                        emailError={emailError}
                        passwordError={passwordError}
                        companyIdError={companyIdError}
                        handleEmailChange={handleEmailChange}
                        handlePasswordChange={handlePasswordChange}
                        handleCompanyIdChange={handleCompanyIdChange}
                        handleSignIn={handleSignIn}
                        handleForgotPassword={handleForgotPassword}
                        handleReset={handleReset}
                        handleBackToSignIn={handleBackToSignIn}
                        handleSSOSignIn={handleSSOSignIn}
                        handleLogInAgain={handleLogInAgain}
                    />
                </div>
            </Paper>
            <Typography component="span" variant="caption" className={classes.copyright}>
                &copy; 2006-{new Date().getFullYear()} Transcepta, LLC.
            </Typography>
        </div>
    );
};

export default LoginContainer;
