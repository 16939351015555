import { Component } from 'react';
import { Typography } from '@mui/material';
import { createExternalErrorInfo, IExternalErrorInfo } from '../../reusableFeatures';
import { EmbedHTML, formatSafeHTMLString, getRootErrorBoundaryErrorMessage, runIfEnabled } from '../../utils';
import resetState from '../../utils/resetState';
import { ResetApplicationStateDialog } from '../ResetApplicationStateDialog';

interface IProps {
    route: string;
}

interface IState {
    hasError: boolean;
    logRocketDelayDone: boolean;
    resetClicked: boolean;
    errorInfo?: IExternalErrorInfo | undefined;
}

function getErrorType(error: any) {
    try {
        return error.constructor.name;
    } catch (e) {
        return 'Cannot Be Determined.';
    }
}
export class ErrorBoundary extends Component<IProps, IState> {
    private errorReported = false;

    constructor(props: IProps) {
        super(props);
        this.state = { hasError: false, logRocketDelayDone: false, resetClicked: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        console.error('App Crashed', error, errorInfo);

        if (this.errorReported) {
            console.warn(
                'Error for crash was already reported, but application tree has not yet unmounted and thrown another error. That error is logged above for debugging, but will not be reported on the error channel as doing so will annoy operations.'
            );
            return;
        }
        this.errorReported = true;

        let logRocketIsEnabled = false;
        // mark the session as having an app crash in LogRocket
        runIfEnabled((logRocket) => {
            logRocketIsEnabled = true;

            logRocket.track('AppCrash', {
                timestamp: Math.round(Date.now() / 1000),
                url: window.location.href,
                pathname: window.location.pathname,
                errorType: getErrorType(error),
            });

            setTimeout(() => {
                this.setState({ logRocketDelayDone: true });
                if (this.state.resetClicked) {
                    this.handleOnClose();
                }
            }, 5000);
        });

        if (!logRocketIsEnabled) {
            this.setState({ logRocketDelayDone: true });
        }

        this.setState({ errorInfo: createExternalErrorInfo('ReactAppCrash') });
    }

    handleOnClose = () => {
        if (!this.state.logRocketDelayDone) {
            this.setState({ resetClicked: true });
            return;
        }
        resetState(`${this.props.route}/`);
    };

    render() {
        if (this.state.hasError) {
            const { errorInfo } = this.state;
            return (
                <ResetApplicationStateDialog
                    open={this.state.hasError}
                    redirectLocation={this.props.route}
                    title={getRootErrorBoundaryErrorMessage().dialogTitle}
                    reason={
                        errorInfo &&
                        getRootErrorBoundaryErrorMessage().paragraphs.map((x) => (
                            <EmbedHTML
                                component={Typography}
                                variant="body2"
                                gutterBottom
                                safeHTMLString={formatSafeHTMLString(x, {
                                    errorCode: errorInfo.errorCode,
                                })}
                            />
                        ))
                    }
                />
            );
        }

        return this.props.children;
    }
}
