import { path } from 'ramda';
import { push } from 'connected-react-router';
import * as TranscpetaThunks from 'common/build/legacy/transcepta-thunks';
import projectConfig from '../project.config.json';
import * as documentActions from '../actions/document/documentActions';
import * as documentCreationActions from '../actions/document/documentCreationActions';
import * as DocumentCreationThunk from './DocumentCreationThunk';
import * as DocumentSearchThunk from './DocumentSearchThunk';
import * as CustomerThunk from './CustomerThunk';
import * as ProfilesThunk from './ProfilesThunk';
import * as SIMDocumentTypeThunk from './SIMDocumentTypesThunk';
import R from '../routes';
import * as customerActions from '../actions/CustomerActions';
import { base64ToBlob, cloneObjectHack } from '../utils/dataConverter';
import { CompanyThunk, DocumentThunk } from 'common/build/legacy/transcepta-thunks';
import { apiErrorAction } from '../actions/error';
import { getBusinessDocTypePathNameFromType, validateLayout } from '../utils/document/document';
import { strings } from '../utils/localization/LocalizationStrings';
import { convertDocumentLayouts as convertLayouts } from 'common/build/legacy/transcepta-common';
import * as AlertActions from '../actions/alerts';
import { formatDate } from 'common/build/utils/date/formatDate';
import { isFeatureEnabled, legacyErrorHandler, portalUserService } from 'common';

export const fetchDocumentLayout =
    (
        documentData,
        draft,
        businessDocType = null,
        buyerCompanyId = null,
        supplierCompanyId = null,
        simDocClass = null
    ) =>
    async (dispatch, getState) => {
        let businessDocId = null;
        if (documentData) {
            businessDocId = draft ? documentData.RelatedDocumentId : documentData.Id;
        }

        if (businessDocType === null) {
            businessDocType = documentData.BusinessDocType;
        }

        if (supplierCompanyId === null) {
            supplierCompanyId = portalUserService.getCurrentCompanyId();
        }

        if (buyerCompanyId === null) {
            if (draft) {
                // Drafts do not have a related document with sender and receiver company ids. Therefore, custom lookup for the related buyer company id is needed here.
                if (businessDocType === projectConfig.businessDocType.Invoice) {
                    // Invoice drafts contain a customer id which can be used to find the buyer company id.
                    // To obtain the buyer information via the customer an API call is needed, but we need to wait for it to resolve its promise before moving on.
                    // Otherwise, we would end up attempting to call the get layout API with a promise as a parameter, and the call would fail.
                    const params = {
                        companyId: supplierCompanyId,
                        $filter: `ID eq ${documentData.BusinessDocFields.Invoice.CustomerID}`,
                    };
                    const companyThunk = new CompanyThunk();
                    const customerLocationResponse = await companyThunk.fetchCustomers(params);
                    const customerLocationData = await customerLocationResponse.data;
                    if (!customerLocationResponse.type || customerLocationResponse.type !== 'error') {
                        buyerCompanyId = customerLocationData.Items[0].BuyerDestinationProfile.CompanyID;
                    }
                }
            } else if (documentData.CommonFields) {
                buyerCompanyId =
                    supplierCompanyId === documentData.CommonFields.SenderCompanyId
                        ? documentData.CommonFields.ReceiverCompanyId
                        : documentData.CommonFields.SenderCompanyId;
            }
        }
        // Sometimes the customer record will not yet be linked to a buyer. When this is the case, there is no way to tell what the buyer company id should be.
        // The layout API call will fail if a buyer company id is not passed. So we set it to 0 here if one could not be determined above.
        if (buyerCompanyId === null) {
            buyerCompanyId = 0;
        }
        if (businessDocType === projectConfig.businessDocType.SIMDocument) {
            if (!simDocClass && getState().documentCreation.documentCreationFields.simDocClass) {
                simDocClass = getState().documentCreation.documentCreationFields.simDocClass;
            } else if (
                !simDocClass &&
                documentData &&
                documentData.BusinessDocFields &&
                documentData.BusinessDocFields.BusinessDocument &&
                documentData.BusinessDocFields.BusinessDocument.Document &&
                documentData.BusinessDocFields.BusinessDocument.Document.BusinessDocument &&
                documentData.BusinessDocFields.BusinessDocument.Document.BusinessDocument.SIMDocument
            ) {
                simDocClass = parseInt(
                    documentData.BusinessDocFields.BusinessDocument.Document.BusinessDocument.SIMDocument.DocumentClass,
                    10
                );
            }
        }
        const params = {
            businessDocType,
            client: 4, // supplier portal
            culture: 'en-US', // This is hard-coded for now. We will update it later to use other localization cultures.
            buyerCompanyId: buyerCompanyId,
            supplierCompanyId: supplierCompanyId,
            businessDocId: businessDocId,
            draftBusinessDoc: draft ? draft : false,
            documentClass: simDocClass,
            businessDocSubTypeId: documentData?.BusinessDocSubType ?? null,
        };

        dispatch(documentActions.documentLayoutFetching(params));

        const documentThunk = new DocumentThunk();
        const response = await documentThunk.fetchDocumentLayout(params);
        const data = await response.data;
        if (response.type && response.type === 'error') {
            dispatch(apiErrorAction(response.text, documentActions.DOCUMENT_LAYOUT_FETCH_ERROR, params));
            console.error('failed to load document layout');
            legacyErrorHandler.showError('DocumentDisplay.DependencyFailedToLoad');
        } else {
            dispatch(documentActions.documentLayoutFetched(params, convertLayouts(data.Layouts)));
            dispatch(documentActions.editLayoutFetched(convertLayouts(data.Layouts))); //TODO: Edit and view should be using same portion of store.
            if (documentData) {
                dispatch(documentActions.documentFetched(documentData));
            }
        }

        dispatch(documentActions.fetchDocumentSenderCompanyId(supplierCompanyId));
        dispatch(documentActions.fetchDocumentReceiverCompanyId(buyerCompanyId));
    };

export const fetchDocument =
    (documentId, draft = false) =>
    async (dispatch, getState) => {
        let businessDocType = projectConfig.docTypes.Invoice; //TODO: When starting web entry for other doc types this will need to be made into a parameter instead of being hard-coded
        const attachmentDocumentId = documentId;
        const params = draft
            ? { businessDocDraftId: documentId, businessdoctype: businessDocType }
            : { documentId: documentId };

        dispatch(documentActions.fetchDocumentExecute(documentId, draft, params));

        const documentThunk = new DocumentThunk();
        const response = draft
            ? await documentThunk.fetchDocumentDraft(params)
            : await documentThunk.fetchDocument(params);
        const data = await response.data;
        let validFields = null;
        if (response.type && response.type === 'error') {
            dispatch(apiErrorAction(response.text, documentActions.DOCUMENT_FETCH_ERROR, params));
            console.error('failed to load document or draft');
            if (response.text.response.data.errors[0].Code) {
                dispatch(documentActions.shouldShow404Error(true));
                return;
            }
            legacyErrorHandler.showError('DocumentDisplay.DependencyFailedToLoad');
        } else {
            // request document layout
            if (!data || !data.BusinessDocType) {
                dispatch(documentActions.fetchDocumentNotFound(documentId));
                dispatch(documentActions.shouldShow404Error(true));
                return;
            }
            const documentData = data;
            businessDocType = documentData.BusinessDocType || projectConfig.docTypes.Invoice;
            const simDocClass =
                businessDocType === projectConfig.businessDocType.SIMDocument
                    ? documentData.BusinessDocFields.BusinessDocument.Document.BusinessDocument.SIMDocument
                          .DocumentClass
                    : null;
            await dispatch(fetchDocumentLayout(documentData, draft, null, null, null, simDocClass));

            if (businessDocType !== projectConfig.businessDocType.Invoice) {
                draft = projectConfig.externalStatus.Draft === path(['CommonFields', 'ExternalStatus'], documentData);
                documentId = draft ? documentData.RelatedDocumentId : documentData.Id;
            }
            if (businessDocType !== projectConfig.businessDocType.PurchaseOrder) {
                let businessDocFields = {};
                if (businessDocType !== projectConfig.businessDocType.Invoice) {
                    businessDocFields = documentData.BusinessDocFields.BusinessDocument.Document;
                } else {
                    businessDocFields = documentData.BusinessDocFields;
                }
                const validationErrors = validateLayout(getState().documentLayout.layout, businessDocFields);
                if (validationErrors.length > 0) {
                    validFields = false;
                    dispatch(documentActions.fetchDocumentStatusSuccess(validationErrors));
                } else {
                    validFields = true;
                }
            }

            const receiverProfileId = path(['CommonFields', 'ReceiverProfileId'], documentData);
            const externalStatus = path(['CommonFields', 'ExternalStatus'], documentData);
            if (
                businessDocType === projectConfig.businessDocType.Invoice &&
                receiverProfileId &&
                externalStatus === projectConfig.externalStatus.Rejected
            ) {
                dispatch(ProfilesThunk.readProfile(receiverProfileId, projectConfig.profileType.BuyerDestination));
            }

            if (
                draft ||
                (documentData.CommonFields &&
                    documentData.CommonFields.ExternalStatus === projectConfig.externalStatus.Draft)
            ) {
                let state = getState();
                const supplierCompanyId = portalUserService.getCurrentCompanyId();

                let businessDocStatus = null;
                let identifyingNumber = null;
                let senderProfileId = null;
                let testBusinessDoc = null;
                let documentId = 0;
                switch (businessDocType) {
                    case projectConfig.businessDocType.Invoice:
                        let processingMode = projectConfig.ProcessingMode.Production;

                        const customerId = path(['BusinessDocFields', 'Invoice', 'CustomerID'], documentData);
                        await dispatch(CustomerThunk.fetchCustomerLocation(customerId));
                        state = getState();
                        const customer = state.selectCustomer.currentCustomerLocation;
                        if (
                            customer &&
                            (customer.BuyerDestinationProfile ||
                                customer.ProcurementProfile ||
                                customer.BuyerWarehouseProfile)
                        ) {
                            let buyerCompanyId = null;
                            if (customer.BuyerDestinationProfile) {
                                buyerCompanyId = customer.BuyerDestinationProfile.CompanyID;
                            }
                            if (!buyerCompanyId && customer.ProcurementProfile) {
                                buyerCompanyId = customer.ProcurementProfile.CompanyID;
                            }
                            if (!buyerCompanyId && customer.BuyerWarehouseProfile) {
                                buyerCompanyId = customer.BuyerWarehouseProfile.companyID;
                            }

                            if (buyerCompanyId) {
                                if (
                                    !state.selectCustomer.currentCustomer ||
                                    state.selectCustomer.currentCustomer.BuyerCompanyId !== buyerCompanyId
                                ) {
                                    await dispatch(CustomerThunk.fetchCustomer(buyerCompanyId));
                                    state = getState();
                                }

                                const tradingPartner = state.selectCustomer.currentCustomer;
                                if (tradingPartner) {
                                    processingMode = tradingPartner.ProcessingMode;
                                }
                            }
                        }

                        businessDocStatus = projectConfig.invoiceStatus.Open;
                        identifyingNumber = path(['BusinessDocFields', 'Invoice', 'InvoiceNumber'], documentData);
                        senderProfileId = path(['BusinessDocFields', 'Invoice', 'InvoiceProfileID'], documentData);
                        testBusinessDoc =
                            processingMode === projectConfig.ProcessingMode.Test ||
                            processingMode === projectConfig.ProcessingMode.BuyerWebEntryTest;
                        dispatch(
                            DocumentSearchThunk.documentExists(
                                supplierCompanyId,
                                businessDocType,
                                0,
                                senderProfileId,
                                identifyingNumber,
                                testBusinessDoc,
                                businessDocStatus,
                                documentId
                            )
                        );
                        break;
                    default:
                        identifyingNumber = path(
                            [
                                'BusinessDocFields',
                                'BusinessDocument',
                                'Document',
                                'BusinessDocument',
                                'IdentifyingNumber',
                            ],
                            documentData
                        );
                        senderProfileId = path(['CommonFields', 'SenderProfileId'], documentData);
                        documentId = documentData.Id;
                        break;
                }
            }
        }
        if (!draft || (draft && validFields)) {
            dispatch(fetchDocumentStatus(documentId, businessDocType, draft));
        }
        if (businessDocType === projectConfig.businessDocType.SIMDocument) {
            await dispatch(
                SIMDocumentTypeThunk.fetchSupplierSIMRequest(
                    getState().document.document.BusinessDocFields.BusinessDocument.Document.BusinessDocument
                        .SIMRequestId
                )
            );
            const attachmentsAllowed =
                getState().simDocumentTypes.SIMRequests.items[0].SIMDocumentType.AttachmentOption !==
                projectConfig.AttachmentOption.NoAttachments;
            dispatch(documentActions.setAttachmentsAllowed(attachmentsAllowed));
            if (attachmentsAllowed) {
                dispatch(fetchDocumentFiles(attachmentDocumentId, businessDocType, draft));
            }
        } else {
            dispatch(fetchDocumentFiles(attachmentDocumentId, businessDocType, draft));
        }
        if (!draft) {
            dispatch(fetchDocumentHistory(documentId));
            dispatch(fetchDynamicDiscounting(documentId));
            dispatch(fetchDeliveryDetails(documentId));
        }
    };

export const fetchDocumentStatus = (documentId, businessDocType, draft) => async (dispatch, getState) => {
    const params = draft
        ? { businessDocumentDraftId: documentId, businessDocType: businessDocType }
        : {
              documentId: documentId,
              businessDocType: businessDocType,
              isBuyer: false,
          };

    dispatch(documentActions.fetchDocumentStatusExecute(params));
    const documentThunk = new DocumentThunk();
    const response = draft
        ? await documentThunk.fetchDraftDocumentStatus(params)
        : await documentThunk.fetchDocumentStatus(params);
    const data = await response.data;
    if (response.type && response.type === 'error') {
        dispatch(
            apiErrorAction(
                strings.textAPIVaidationFailureMessage,
                documentActions.DOCUMENT_VALIDATION_FETCH_ERROR,
                params
            ),
            false
        );
        dispatch(documentActions.fetchDocumentStatusSuccess([]));
    } else {
        dispatch(documentActions.fetchDocumentStatusSuccess(data));
    }
};

export const fetchDocumentHistory = (documentId) => async (dispatch, getState) => {
    const params = {
        documentId: documentId,
    };

    dispatch(documentActions.fetchDocumentHistoryExecute(params));

    const documentThunk = new DocumentThunk();
    const response = await documentThunk.fetchDocumentHistory(params);
    const data = await response.data;
    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, documentActions.DOCUMENT_HISTORY_FETCH_ERROR, params));
    } else {
        // history received
        dispatch(documentActions.fetchDocumentHistorySuccess(data));
    }
};

export const fetchDocumentFiles = (documentId, businessDocType, draft) => async (dispatch, getState) => {
    const params =
        draft && businessDocType === projectConfig.businessDocType.Invoice
            ? {
                  businessDocumentDraftId: documentId,
                  businessDocType: businessDocType,
              }
            : {
                  documentId: documentId,
              };
    dispatch(documentActions.fetchDocumentFilesExecute(params));

    const documentThunk = new DocumentThunk();
    const response =
        draft && businessDocType === projectConfig.businessDocType.Invoice
            ? await documentThunk.fetchDocumentDraftAttachments(params)
            : await documentThunk.fetchDocumentAttachments(params);
    const data = await response.data;
    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, documentActions.DOCUMENT_ATTACHMENTS_FETCH_ERROR, params));
    } else {
        // status received
        dispatch(documentActions.fetchDocumentFilesSuccess(data));
    }
};

// Document with actual Dynamic Discounting: 24345
export const fetchDynamicDiscounting = (documentId) => async (dispatch, getState) => {
    const params = {
        documentId: documentId,
    };

    dispatch(documentActions.fetchDynamicDiscountingExecute(params));

    const documentThunk = new DocumentThunk();
    const response = await documentThunk.fetchDynamicDiscounting(params);
    const data = await response.data;
    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, documentActions.DOCUMENT_DYNAMIC_DISCOUNTING_ERROR, params));
    } else {
        // Dynamic discounting received
        dispatch(documentActions.fetchDynamicDiscountingSuccess(data));
    }
};

// Document Delivery Details
export const fetchDeliveryDetails = (documentId) => async (dispatch, getState) => {
    const params = {
        documentId: documentId,
    };

    dispatch(documentActions.fetchDeliveryDetailsExecute(params));

    const documentThunk = new DocumentThunk();
    const response = await documentThunk.fetchDeliveryDetails(params);
    const data = await response.data;
    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, documentActions.DOCUMENT_DELIVERY_ERROR, params));
    } else {
        dispatch(documentActions.fetchDeliveryDetailsSuccess(data));
    }
};

// Other
export const showActionPopup = (status) => (dispatch, getState) => {
    dispatch(documentActions.showActionPopup(status ? status : ['']));
};

export const resendDocument = (documentId, businessDocTypePathName) => async (dispatch, getState) => {
    const params = { documentId: documentId };
    const documentThunk = new TranscpetaThunks.DocumentThunk();
    const response = await documentThunk.resendDocument(params);
    const data = await response.data;
    if (response.type && response.type === 'error') {
        dispatch(documentCreationActions.submitDocumentFailure(response.text));
        dispatch(apiErrorAction(response.text, null, params));
    } else {
        dispatch(documentCreationActions.submitDocumentSuccess(response));
        dispatch(fetchDocument(data.ID, false));
        dispatch(
            push(
                R.DOCUMENT_DETAILS.path
                    .replace(':businessDocType', businessDocTypePathName)
                    .replace(':documentId', data.ID)
            )
        );
    }
};
export const generatePDF = (documentId, businessDocType, isDraft) => async (dispatch, getState) => {
    const params = isDraft
        ? { businessDocumentDraftId: documentId, businessDocType: businessDocType }
        : { documentId: documentId };

    const executeAction = isDraft
        ? documentActions.generateDocumentPdfExecute(params)
        : documentActions.fetchDocumentPdfExecute(params);
    dispatch(executeAction);

    const documentThunk = new DocumentThunk();
    const response = isDraft ? await documentThunk.generatePDF(params) : await documentThunk.fetchPDF(params);
    const data = await response.data;
    if (response.type && response.type === 'error') {
        const failedAction = isDraft
            ? documentActions.generateDocumentPdfFailed(response.text)
            : documentActions.fetchDocumentPdfFailed(response.text);
        dispatch(apiErrorAction(response.text, failedAction, params));
    } else {
        const successAction = isDraft
            ? documentActions.generateDocumentPdfSuccess(data)
            : documentActions.fetchDocumentPdfSuccess(data);
        dispatch(successAction);

        const { FileData, FileName, FileContentType } = data;
        const dataBlob = base64ToBlob(FileData, FileContentType);

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(dataBlob, FileName);
            return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const dataUrl = window.URL.createObjectURL(dataBlob);
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = FileName;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        setTimeout(
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(dataUrl),
            100
        );
        document.body.removeChild(link);
    }
};
export const downloadDocumentAttachment = (documentAttachmentId) => async (dispatch, getState) => {
    // Mark this as billable since this is coming from the supplier portal and we want to record this download in the invoice history so it can be billed.
    const params = {
        documentAttachmentId: documentAttachmentId,
        isBillable: true,
    };
    dispatch(documentActions.downloadDocumentAttachmentExecute(params));

    const documentThunk = new DocumentThunk();
    const response = await documentThunk.fetchAttachment(params);
    const data = await response.data;
    if (response.type && response.type === 'error') {
        dispatch(documentActions.downloadDocumentAttachmentFailure(response.text));
        dispatch(apiErrorAction(response.text, documentActions.DOCUMENT_ATTACHMENT_DOWNLOAD_FAILURE, params));
    } else {
        dispatch(documentActions.downloadDocumentAttachmentSuccess(data));

        const { FileData, FileName, FileContentType } = data;
        const dataBlob = base64ToBlob(FileData, FileContentType);

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(dataBlob, FileName);
            return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const dataUrl = window.URL.createObjectURL(dataBlob);
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = FileName;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        setTimeout(
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(dataUrl),
            100
        );
        document.body.removeChild(link);
    }
};

/*
 * Thunk dispatched by "Purchase Order" screen
 */
export const editInvoice = (businessDocTypePathName, documentId, draftId) => async (dispatch, getState) => {
    const state = getState();

    const document = state.document.document;
    await dispatch(CustomerThunk.fetchCustomerList());
    await dispatch(DocumentCreationThunk.performChangeInvoice('Document', document));
    const customer = state.selectCustomer.customers.find((customer) => {
        return customer.BuyerCompanyId === state.document.receiverCompanyId;
    });
    const isInvoice = businessDocTypePathName === 'invoices';
    const customerId = isInvoice
        ? state.selectCustomer.currentCustomer
            ? state.selectCustomer.currentCustomer.Id
            : state.document.document.CommonFields.ReceiverCompanyId
        : state.document.document.CommonFields.ReceiverCompanyId;
    const customerLocationId = isInvoice
        ? state.document.document.BusinessDocFields.Invoice.CustomerID
        : state.document.document.CommonFields.CustomerId;

    if (customer) {
        await dispatch(customerActions.selectCustomerAction(customer));
    }

    if (draftId) {
        dispatch(
            push(
                R.DOCUMENT_DRAFT_EDIT.path
                    .replace(':businessDocType', businessDocTypePathName)
                    .replace(':customerId', customerId)
                    .replace(':customerLocationId', customerLocationId)
                    .replace(':draftId', draftId)
            )
        );
    } else if (documentId) {
        dispatch(
            push(
                R.DOCUMENT_EDIT.path
                    .replace(':businessDocType', businessDocTypePathName)
                    .replace(':customerId', customerId)
                    .replace(':customerLocationId', customerLocationId)
                    .replace(':documentId', documentId)
            )
        );
    } else {
        dispatch(
            push(
                R.DOCUMENT_CREATION.path
                    .replace(':businessDocType', businessDocTypePathName)
                    .replace(':customerId', state.selectCustomer.currentCustomer.Id)
                    .replace(':customerLocationId', state.document.document.BusinessDocFields.Invoice.CustomerID)
            )
        );
    }
};

/*
 * Thunk dispatched by "Purchase Order" screen
 */
export const navigateToCustomerLocationSelectPage =
    (dataItems, businessDocTypePathName) => async (dispatch, getState) => {
        const document = cloneObjectHack(getState().document.document);
        const purchaseOrder = document.BusinessDocFields.PurchaseOrder;
        let lineItems = purchaseOrder.PurchaseOrderLineItems.filter((item, i) => dataItems.includes(i));
        // fix some items
        lineItems = lineItems.map((item, i) => ({ PurchaseOrderLineNum: item.LineNum, ...item }));

        dispatch(DocumentCreationThunk.setFlip(true));
        dispatch(DocumentCreationThunk.saveFlipSource(document.Id, projectConfig.businessDocType.PurchaseOrder));
        dispatch(DocumentCreationThunk.saveFlipLineItems(lineItems)); //saveFlipLineItems
        dispatch(
            CustomerThunk.navigateToCustomerLocationSelectPage(
                businessDocTypePathName,
                document.CommonFields.SenderCompanyId
            )
        );
    };

/*
 * Thunk dispatched by "Purchase Order" screen
 */
export const createInvoiceFromPurchaseOrder = (dataItems, businessDocTypePathName) => async (dispatch, getState) => {
    const state = getState();
    const document = cloneObjectHack(state.document.document);
    const purchaseOrder = document.BusinessDocFields.PurchaseOrder;
    let lineItems = purchaseOrder.PurchaseOrderLineItems.filter((item, i) => dataItems.includes(i));
    // fix some items
    lineItems = lineItems.map((item, i) => ({ PurchaseOrderLineNum: item.LineNum, ...item }));
    purchaseOrder.PurchaseOrderLineItems = lineItems;
    dispatch(DocumentCreationThunk.setFlip(true));
    dispatch(DocumentCreationThunk.saveFlipSource(document.Id, projectConfig.businessDocType.PurchaseOrder));
    dispatch(DocumentCreationThunk.performChangeInvoice('Document', document));
    dispatch(
        CustomerThunk.navigateToCustomerLocationSelectPage(
            businessDocTypePathName,
            document.CommonFields.SenderCompanyId
        )
    );
};

export const createDocumentFromPurchaseOrder = (dataItems, businessDocTypePathName) => async (dispatch, getState) => {
    const state = getState();
    const document = state.document.document;
    const purchaseOrder = document.BusinessDocFields.PurchaseOrder;
    let lineItems = purchaseOrder.PurchaseOrderLineItems.filter((item, i) => dataItems.includes(i));
    // fix some items
    lineItems = lineItems.map((item, i) => ({ PurchaseOrderLineNum: item.LineNum, ...item }));
    await dispatch(CustomerThunk.fetchCustomerList());
    //purchaseOrder.PurchaseOrderLineItems = lineItems;
    dispatch(DocumentCreationThunk.setFlip(true));
    dispatch(DocumentCreationThunk.saveFlipLineItems(lineItems));
    dispatch(DocumentCreationThunk.saveFlipSource(document.Id, projectConfig.businessDocType.PurchaseOrder));
    const customer = getState().selectCustomer.customers.find((customer) => {
        return customer.BuyerCompanyId === document.CommonFields.SenderCompanyId;
    });
    if (customer) {
        dispatch(customerActions.selectCustomerAction(customer));
    }
    dispatch(DocumentCreationThunk.performChangeDocument('Document', state.document.document));
    dispatch(CustomerThunk.navigateToCustomerLocationSelectPage(businessDocTypePathName));
};

export const createDraftFromExistingDocument = (documentId, businessDocType) => async (dispatch, getState) => {
    const apiBasedInvoiceFlipsEnabled = isFeatureEnabled('APIBasedInvoiceFlips');

    const state = getState();
    let document = JSON.parse(JSON.stringify(state.document.document));
    await dispatch(CustomerThunk.fetchCustomerList());
    const customer = getState().selectCustomer.customers.find((customer) => {
        return customer.BuyerCompanyId === document.CommonFields.ReceiverCompanyId;
    });

    let businessDoc;
    let customerLocation;
    switch (businessDocType) {
        case projectConfig.businessDocType.Invoice:
            businessDoc = document.BusinessDocFields.Invoice;
            if (businessDoc.CustomerID !== undefined) {
                customerLocation = businessDoc.CustomerID;
            }
            if (apiBasedInvoiceFlipsEnabled) {
                const lineItems = businessDoc.InvoiceLineItems.map((item) => ({ ...item }));
                dispatch(DocumentCreationThunk.setFlip(true));
                dispatch(DocumentCreationThunk.saveFlipSource(document.Id, projectConfig.businessDocType.Invoice));
                dispatch(DocumentCreationThunk.saveFlipLineItems(lineItems));
            }
            document = {
                ...document,
                RelatedDocumentId: null,
                Id: null,
                BusinessDocFields: {
                    ...document.BusinessDocFields,
                    Invoice: {
                        ...businessDoc,
                        InvoiceNumber: '',
                        ID: null,
                        DueDate: null,
                        InvoiceDate: new Date(),
                        FlippedDocumentId: null,
                    },
                },
            };
            dispatch(documentActions.markAsCopy(true));
            break;
        case projectConfig.businessDocType.PurchaseOrder:
            return;
        default:
            businessDoc = document.BusinessDocFields.BusinessDocument;
            if (
                businessDoc.Document !== undefined &&
                businessDoc.Document.BusinessDocument !== undefined &&
                businessDoc.Document.BusinessDocument.CustomerNumber !== undefined
            ) {
                await dispatch(CustomerThunk.getCustomerLocationList());
                customerLocation = getState().documentCreation.customerLocation.Items.find((location) => {
                    return location.CustomerNumber === businessDoc.Document.BusinessDocument.CustomerNumber;
                });
            }
            document = {
                ...document,
                BusinessDocFields: {
                    ...document.BusinessDocFields,
                    BusinessDocument: {
                        ...businessDoc,
                        FlippedDocumentId: null,
                    },
                },
            };
            break;
    }

    if (customerLocation !== null) {
        const businessDocTypePathName = getBusinessDocTypePathNameFromType(businessDocType);

        if (customer) {
            dispatch(customerActions.selectCustomerAction(customer));
        }

        if (businessDocType !== projectConfig.businessDocType.Invoice || !apiBasedInvoiceFlipsEnabled) {
            await dispatch(DocumentCreationThunk.performChangeInvoice('Document', document));
        }
        dispatch(navigateToCreateDocumentDraft(businessDocTypePathName, customer.BuyerCompanyId, customerLocation));
    }
};

export const deleteDraftDocument = (draftDocumentId, businessDocType, isDraft) => async (dispatch, getState) => {
    if (isDraft === true) {
        const params = {
            businessDocumentDraftId: draftDocumentId,
            businessDocType: businessDocType,
        };

        dispatch(documentActions.deleteDraftDocumentExecute(params));

        const documentThunk = new DocumentThunk();
        const response = await documentThunk.deleteDocumentDraft(params);
        if (response.type && response.type === 'error') {
            dispatch(apiErrorAction(response.text, null, params));
        } else {
            dispatch(documentActions.deleteDraftDocumentSuccess());
            dispatch(navigateToBusinessDocList(businessDocType));
        }
    }
};

export const navigateToBusinessDocList = (businessDocType) => (dispatch, getState) => {
    switch (businessDocType) {
        case projectConfig.businessDocType.ASN:
        case projectConfig.businessDocType.ASNGeneric:
            dispatch(navigateToASNs());
            break;
        case projectConfig.businessDocType.ConsumptionAdvice:
            dispatch(navigateToConsumptionAdvices());
            break;
        case projectConfig.businessDocType.InventoryAdvice:
            dispatch(navigateToInventoryAdvices());
            break;
        case projectConfig.businessDocType.PurchaseOrder:
            dispatch(navigateToPurchaseOrders());
            break;
        case projectConfig.businessDocType.PurchaseOrderAcknowledgement:
            dispatch(navigateToPurchaseOrderAcknowledgements());
            break;
        case projectConfig.businessDocType.ReceivingAdvice:
            dispatch(navigateToReceivingAdvices());
            break;
        case projectConfig.businessDocType.SIMDocument:
            dispatch(navigateToSIMDocuments());
            break;
        case projectConfig.businessDocType.Invoice:
        default:
            dispatch(navigateToInvoices());
            break;
    }
};

export const navigateToASNs = () => (dispatch, getState) => {
    dispatch(documentActions.navigateToASNs());
    dispatch(
        push(R.DOCUMENT_SEARCH.path.replace(':businessDocType', projectConfig.businessDocTypePathName.ASNGeneric))
    );
};

export const navigateToConsumptionAdvices = () => (dispatch, getState) => {
    dispatch(documentActions.navigateToConsumptionAdvices());
    dispatch(
        push(
            R.DOCUMENT_SEARCH.path.replace(':businessDocType', projectConfig.businessDocTypePathName.ConsumptionAdvice)
        )
    );
};

export const navigateToInventoryAdvices = () => (dispatch, getState) => {
    dispatch(documentActions.navigateToInventoryAdvices());
    dispatch(
        push(R.DOCUMENT_SEARCH.path.replace(':businessDocType', projectConfig.businessDocTypePathName.InventoryAdvice))
    );
};

export const navigateToInvoices = () => (dispatch, getState) => {
    dispatch(documentActions.navigateToInvoices());
    dispatch(push(R.DOCUMENT_SEARCH.path.replace(':businessDocType', projectConfig.businessDocTypePathName.Invoice)));
};

export const navigateToPurchaseOrderAcknowledgements = () => (dispatch, getState) => {
    dispatch(documentActions.navigateToPurchaseOrderAcknowledgements());
    dispatch(push(R.PURCHASE_ORDERS_ACKLNS.path));
};

export const navigateToPurchaseOrders = () => (dispatch, getState) => {
    dispatch(documentActions.navigateToPurchaseOrders());
    dispatch(
        push(R.DOCUMENT_SEARCH.path.replace(':businessDocType', projectConfig.businessDocTypePathName.PurchaseOrder))
    );
};

export const navigateToReceivingAdvices = () => (dispatch, getState) => {
    dispatch(documentActions.navigateToReceivingAdvices());
    dispatch(
        push(R.DOCUMENT_SEARCH.path.replace(':businessDocType', projectConfig.businessDocTypePathName.ReceivingAdvice))
    );
};

export const navigateToSIMDocuments = () => (dispatch, getState) => {
    dispatch(
        push(R.DOCUMENT_SEARCH.path.replace(':businessDocType', projectConfig.businessDocTypePathName.SIMDocument))
    );
};

export const navigateToCreateDocumentDraft =
    (businessDocTypePathName, buyerCompanyId, customerId) => (dispatch, getState) => {
        dispatch(documentActions.setAttachmentsAllowed(true));
        dispatch(documentActions.navigateToCreateDocumentDraft());
        dispatch(
            push(
                R.DOCUMENT_CREATION.path
                    .replace(':businessDocType', businessDocTypePathName)
                    .replace(':customerId', buyerCompanyId)
                    .replace(':customerLocationId', customerId)
            )
        );
    };

export const navigateToCreateSIMDocumentDraft = (businessDocTypePathName, data, customerId) => (dispatch, getState) => {
    const payload = {
        Description: data.Description,
        BuyerCompanyId: data.BuyerCompanyId,
        CustomerId: customerId,
        CustomerName: data.BuyerCompanyName,
        DocumentClass: data.SIMDocumentType.DocumentClass,
        SIMDocumentTypeId: data.SIMDocumentTypeId,
        ProcurementProfileId: data.SIMDocumentType.ProcurementProfileId,
        DueDate: data.DueDate,
        DocumentType: data.SIMDocumentType.Description,
        DataEntrySchedule: data.SIMDocumentType.DataEntrySchedule,
        SIMRequestId: data.Id,
    };
    dispatch(documentCreationActions.updateSIMDocumentClass(data.SIMDocumentType.DocumentClass));
    dispatch(documentCreationActions.updateSIMDocumentCreationFields(payload));
    dispatch(documentActions.setAttachmentsAllowed(false));
    dispatch(documentActions.navigateToCreateDocumentDraft());
    dispatch(
        push(
            R.DOCUMENT_CREATION.path
                .replace(':businessDocType', businessDocTypePathName)
                .replace(':customerId', data.BuyerCompanyId)
                .replace(':customerLocationId', customerId)
        )
    );
};

export const cancelDocument = (documentId) => async (dispatch, getState) => {
    const document = getState().document.document;
    const params = {
        documentId: documentId,
        document: {
            ...document,
            CommonFields: {
                ...document.CommonFields,
                Status: projectConfig.documentStatus.Canceled,
                NeedsReprocessing: false,
                CancellationReasonCode: projectConfig.cancellationReason.SupplierCanceled,
            },
        },
    };
    dispatch(documentActions.cancelDocumentExecute(params));
    dispatch(AlertActions.clearAlerts());

    const documentThunk = new TranscpetaThunks.DocumentThunk();
    const response = await documentThunk.updateDocument(params);
    const data = await response.data;
    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, documentActions.CANCEL_DOCUMENT_FAILURE, params));
        dispatch(fetchDocument(documentId, false));
    } else {
        dispatch(documentActions.cancelDocumentSuccess(response));
        dispatch(fetchDocument(data.ID, false));
    }
};

export const releaseDocumentHold = (documentId) => async (dispatch, getState) => {
    const document = getState().document.document;

    const attachmentSaveSuccessful = await dispatch(saveDocumentAttachments(null, documentId, null, false, false));
    if (attachmentSaveSuccessful) {
        if (
            document.CommonFields.Status !== projectConfig.documentStatus.HoldingForScheduledRelease ||
            document.CommonFields.ProcessingState !== projectConfig.processingState.Hold
        ) {
            console.warn('Unable to release document from hold. Document is not in the correct state.');
        } else {
            const params = {
                documentId: documentId,
                document: {
                    ...document,
                    CommonFields: {
                        ...document.CommonFields,
                        Status: projectConfig.documentStatus.Released,
                        ExternalStatus: projectConfig.externalStatus.Processing,
                        ProcessingState: projectConfig.processingState.Ready,
                        ReleaseTime: formatDate(new Date(), 'America/Los_Angeles', 'yyyy-MM-dd HH:mm:ss'),
                        HoldExpirationTime: null,
                    },
                },
            };
            dispatch(documentActions.releaseDocumentExecute(params));

            const documentThunk = new TranscpetaThunks.DocumentThunk();
            const response = await documentThunk.updateDocument(params);
            const data = await response.data;
            if (response.type && response.type === 'error') {
                dispatch(documentActions.releaseDocumentFailure(response.text));
                dispatch(apiErrorAction(response.text, null, params));
            } else {
                dispatch(documentActions.releaseDocumentSuccess(response));
                dispatch(fetchDocument(data.ID, false));
            }
        }
    }
};

export const saveDocumentAttachments =
    (draftId, documentId, businessDocType, forDocumentEdit, forDocumentCreation) => async (dispatch, getState) => {
        const attachments = forDocumentEdit
            ? getState().documentCreation.attachments.files
            : getState().document.attachments;

        if (attachments.accepted.length > 0) {
            const formData = new FormData();
            attachments.accepted.forEach((file, index) => {
                formData.append('value', file);
            });

            if (forDocumentCreation) {
                const params = {
                    businessDocType: businessDocType,
                    draftId: draftId,
                    documentId: documentId,
                    fileFormData: formData,
                };
                dispatch(documentCreationActions.saveDraftAttachmentsExecute(params));

                const documentThunk = new TranscpetaThunks.DocumentThunk();
                const response = await documentThunk.saveDocumentDraftAttachments(params);
                if (response.type && response.type === 'error') {
                    dispatch(documentCreationActions.saveDraftAttachmentsFailure(response.text));
                    dispatch(apiErrorAction(response.text, null, params));
                    return false;
                } else {
                    dispatch(documentCreationActions.saveDraftAttachmentsSuccess(response));
                    dispatch(documentCreationActions.clearSupportingDocumentCache());
                    return true;
                }
            } else {
                const params = {
                    documentId: documentId,
                    fileFormData: formData,
                };
                dispatch(documentActions.saveDocumentAttachmentsExecute(params));

                const documentThunk = new TranscpetaThunks.DocumentThunk();
                const response = await documentThunk.saveDocumentAttachments(params);
                if (response.type && response.type === 'error') {
                    dispatch(documentActions.saveDocumentAttachmentsFailure(response.text));
                    dispatch(apiErrorAction(response.text, null, params));
                    return false;
                } else {
                    dispatch(documentActions.saveDocumentAttachmentsSuccess(response));
                    return true;
                }
            }
        }

        return true;
    };

export const addDocumentAttachments = (acceptedFiles, forDocumentCreation) => (dispatch, getState) => {
    if (forDocumentCreation) {
        dispatch(documentCreationActions.supportingDocumentsAdded(acceptedFiles));
    } else {
        dispatch(documentActions.documentAttachmentsAdded(acceptedFiles));
    }
};

export const removeDocumentAttachment = (id, type, files, forDocumentCreation) => async (dispatch, getState) => {
    const attachments = forDocumentCreation
        ? getState().documentCreation.attachments.files
        : getState().document.attachments;
    let acceptedFiles = cloneObjectHack(attachments.accepted);
    const rejectedFiles = cloneObjectHack(attachments.rejected);
    let savedFiles = cloneObjectHack(attachments.saved);
    let deletedFiles = cloneObjectHack(attachments.deleted);
    const errors = false;

    acceptedFiles = attachments.accepted.filter((value) => value.Id !== id);
    savedFiles = attachments.saved.filter((value) => value.Id !== id);
    deletedFiles = attachments.saved.filter((value) => value.Id === id);

    if (forDocumentCreation && !errors) {
        dispatch(
            documentCreationActions.supportingDocumentRemoved(acceptedFiles, rejectedFiles, savedFiles, [
                ...attachments.deleted,
                ...deletedFiles,
            ])
        );
    } else if (!errors) {
        dispatch(
            documentActions.documentAttachmentRemoved(acceptedFiles, rejectedFiles, savedFiles, [
                ...attachments.deleted,
                ...deletedFiles,
            ])
        );
    }
};

export const documentGetBuyerMessage = (params) => async (dispatch) => {
    const { supplierCompanyId, buyerCompanyId, businessDocType, docState } = params;

    dispatch(documentActions.documentGetBuyerMessageStart());
    try {
        const companyThunk = new CompanyThunk();
        const documentThunk = new DocumentThunk();

        const { data } = await companyThunk.fetchTradingPartners({
            companyId: params.supplierCompanyId,
            $count: true,
            $filter: `BuyerCompanyId eq ${buyerCompanyId}`,
        });

        let vendorClass = null;
        if (data && data.Items && data.Items.length > 0) {
            vendorClass = data.Items[0].VendorClass;
        }

        const vendorClassID = await companyThunk
            .fetchVendorClasses({
                companyId: buyerCompanyId,
            })
            .then((r) => r.data.find((vv) => vv.VendorClassName === vendorClass));

        const buyerMessageParams = {
            vendorClass: vendorClass ? vendorClassID.Id : null,
            companyId: buyerCompanyId,
            docState,
            businessDocType,
        };

        const messages = await documentThunk.fetchDocumentBuyerMessage(buyerMessageParams);
        const justTheMessages = messages.map((documentMessages) => documentMessages.MessageText);

        dispatch(documentActions.documentGetBuyerMessageSuccess(justTheMessages));
    } catch (e) {
        documentActions.documentGetBuyerMessageFailure(e);
    } finally {
        dispatch(documentActions.documentGetBuyerMessageEnd());
    }
};
