import { getExternalErrorKey } from './IExternalErrorInfo';
import errorTypes from './errorTypes';

declare global {
    interface Window {
        copyTransceptaErrorCodes: () => void;
    }
}

function copyTransceptaErrorCodes() {
    const errorDescriptions = Object.keys(errorTypes).map((key) => {
        const errorType = errorTypes[key as keyof typeof errorTypes];
        const externalKey = getExternalErrorKey(errorType);

        return [
            externalKey.value,
            `External Error Code: ${externalKey.value}
Internal Error Key: ${key}
Department to raise to: ${errorType.department}
Error Severity level: ${errorType.severity}
Error Description: ${errorType.description}`,
        ] as const;
    });

    errorDescriptions.sort((a, b) => {
        if (a[0] > b[0]) {
            return 1;
        }
        if (a[0] < b[0]) {
            return -1;
        }
        return 0;
    });

    const descriptions = errorDescriptions.map((x) => x[1]).join('\n\n');

    // @ts-ignore
    (copy as any)(descriptions);
}

window.copyTransceptaErrorCodes = copyTransceptaErrorCodes;
