/* eslint-disable consistent-return */
import { DotNotationExpr, INumberExpr, IVariableExpr } from './DotNotation';

function showName(value: string) {
    if (value.startsWith('$')) {
        return `\\${value}`;
    }

    return value;
}

/**
 * Converts an expression back to concrete DotNotation syntax.
 * @param expr the abstract expression
 * @returns the concrete expression
 */
export function showExpr(expr: DotNotationExpr | INumberExpr | IVariableExpr): string {
    switch (expr.type) {
        case 'IDynamicPropertyAccessExpr':
            return `${showExpr(expr.object)}[${showExpr(expr.property)}]`;
        case 'IPropertyAccessExpr':
            return `${showExpr(expr.object)}.${showName(expr.property)}`;
        case 'IPropertyNameExpr':
            return showName(expr.property);
        case 'IVariableExpr':
            return `$${expr.name}`;
        case 'INumberExpr':
            return expr.value.toString();
    }
}
