import { useEffect } from 'react';
import { useFlashMessage } from '../reusableFeatures';
import { Alert, FCNC } from '../ui';

export const SettingsPageAlerts: FCNC = () => {
    const [alertMessage, clearAlert, alertSeverity] = useFlashMessage('settingsPageFlashMessage');

    useEffect(() => {
        return clearAlert;
    }, [clearAlert]);

    const severity = alertSeverity ?? 'info';

    return !alertMessage ? null : (
        <Alert
            testId="settings-page-alerts"
            severity={severity}
            sx={{
                marginTop: '2rem',
                border: (theme) => `2px solid ${theme.palette[severity].main}`,
            }}
        >
            {alertMessage}
        </Alert>
    );
};
