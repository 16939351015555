import { AxiosResponse } from 'axios';
import { api } from '../../../services/api';
import ICreatedUpdateDeleteViewModel from '../../Common/Api/ViewModels/CreatedUpdateDeleteViewModel';
import { IWorkflowActivityExitFlowViewModel, IWorkflowActivityViewModel, IWorkflowViewModel } from './ViewModels';

export class WorkflowApi {
    route = '/Workflow';

    public getWorkflow = (id: number): Promise<AxiosResponse<IWorkflowViewModel[]>> => {
        return api().get(`${this.route}/${id}`);
    };

    public getWorkflowByCompanyId = async (
        companyId?: number,
        filter?: string
    ): Promise<AxiosResponse<IWorkflowViewModel[]>> => {
        const params = {
            companyId,
            $filter: filter,
        };

        return api().get(`${this.route}/FindByCompany`, { params });
    };

    public createWorkflowWithoutFiles = (
        workflowViewModel: Omit<IWorkflowViewModel, 'Id'>
    ): Promise<AxiosResponse<ICreatedUpdateDeleteViewModel>> => {
        return api().post(this.route, workflowViewModel);
    };

    public createWorkflowWithFiles = (
        workflowViewModel: Omit<IWorkflowViewModel, 'Id'>,
        files: File[]
    ): Promise<AxiosResponse<ICreatedUpdateDeleteViewModel>> => {
        const fileFormData = new FormData();
        for (const file of files) {
            fileFormData.append('file', file);
        }
        fileFormData.append('data', JSON.stringify(workflowViewModel));

        return api().post(this.route, fileFormData, {
            headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' },
        });
    };

    public updateWorkflowWithoutFiles = (
        id: number,
        workflowViewModel: Omit<IWorkflowViewModel, 'Id'>
    ): Promise<AxiosResponse<ICreatedUpdateDeleteViewModel>> => {
        return api().put(`${this.route}/${id}`, workflowViewModel);
    };

    public updateWorkflowWithFiles = (
        id: number,
        workflowViewModel: Omit<IWorkflowViewModel, 'Id'>,
        files: File[]
    ): Promise<AxiosResponse<ICreatedUpdateDeleteViewModel>> => {
        const fileFormData = new FormData();
        for (const file of files) {
            fileFormData.append('file', file);
        }
        fileFormData.append('data', JSON.stringify(workflowViewModel));

        return api().put(`${this.route}/${id}`, fileFormData, {
            headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' },
        });
    };

    public deleteWorkflow = (id: number): Promise<AxiosResponse<ICreatedUpdateDeleteViewModel>> => {
        return api().delete(`${this.route}/${id}`);
    };

    public getWorkflowActivity = async (
        workflowId: number,
        filter?: string
    ): Promise<AxiosResponse<IWorkflowActivityViewModel[]>> => {
        const params = {
            workflowId,
            $filter: filter,
        };

        return api().get(`${this.route}/WorkflowActivity`, { params });
    };

    public getWorkflowActivityById = async (id: number): Promise<AxiosResponse<IWorkflowActivityViewModel[]>> => {
        return api().get(`${this.route}/WorkflowActivity/${id}`);
    };

    public getWorkflowActivityExitFlow = async (
        id: number
    ): Promise<AxiosResponse<IWorkflowActivityExitFlowViewModel[]>> => {
        return api().get(`${this.route}/WorkflowActivity/WorkflowActivityExitFlow/${id}`);
    };
}
