import { useQueryClient, useMutation } from '@tanstack/react-query';
import { ICRUDService, ICRUDSession } from '../types';
import { stagedCRUDRepository } from '../utils';

export interface IDeleteEntityOptions<ID extends any, U extends object> {
    id: ID;

    fields?: U;
}

/**
 * Returns a react-query mutation to delete an entity
 * @param service the ICRUDService to use for deleting
 * @returns the mutation
 */
export function useDeleteEntity<ID extends any, U extends object, T extends ICRUDService<ID, U>>(
    service: T,
    session: ICRUDSession<ID, U> | undefined,
    onSuccess?: () => void
) {
    const queryClient = useQueryClient();
    const mutation = useMutation(
        async ({ id, fields }: IDeleteEntityOptions<ID, U>) => {
            if (service.deleteEntity) {
                if (session && session.mode === 'stage' && session.handleDelete) {
                    stagedCRUDRepository.setEntity(session.sessionID, session.handleDelete(fields!));
                    return;
                }

                await service.deleteEntity(id);
            }
        },
        {
            onSuccess: () => {
                /**
                 * After a deletion, it's common to unmount the component that was displaying the entity
                 * through the `onSuccess` function, calling it here makes sure that queryKey is invalidated
                 * after the unmounting process, this prevents attempts to refetch an entity that no longer exists.
                 */
                onSuccess?.();
                if ((session?.mode ?? 'commit') === 'commit') {
                    queryClient.invalidateQueries([service.key]);
                    service.invalidateKeys.forEach((key) => {
                        queryClient.invalidateQueries([key]);
                    });

                    if (session) {
                        stagedCRUDRepository.deleteSession(session.sessionID);
                    }
                }
            },
        }
    );

    return mutation;
}
