import { BigNumber } from 'bignumber.js';
import isEmpty from 'lodash/isEmpty';
import {
    IInvoice,
    IInvoiceCompanyGLAccount,
    IInvoiceLineItemCompanyGLAccount,
} from '../../../../services/Document/Api';

function checkGLCodingDetail(options: {
    companyGLAccounts: (IInvoiceLineItemCompanyGLAccount | IInvoiceCompanyGLAccount)[];
    netTotal: number;
    onInvalidAllocation: () => void;
}): void {
    const splitAmounts = options.companyGLAccounts.map((split) => split.PriceApplied);

    const hasAnEmptySplit = splitAmounts.some((amount) => amount == null || amount === '');
    // The user is forced to set a value on the amount value, including "0"
    if (hasAnEmptySplit) {
        options.onInvalidAllocation();
        return;
    }

    const splitAmountTotal = splitAmounts
        .map((amount) => new BigNumber(amount))
        .reduce((acc, amount) => acc.plus(amount), new BigNumber(0));

    if (!splitAmountTotal.isEqualTo(new BigNumber(options.netTotal))) {
        options.onInvalidAllocation();
    }
}

export function addsUpValidation(options: {
    glMode: 'line' | 'header';
    invoice: IInvoice;
    onValidationFailure: {
        line: (lineNumber: number) => void;
        header: () => void;
    };
}) {
    if (options.glMode === 'line') {
        options.invoice.InvoiceLineItems.forEach((lineItem, lineIndex) =>
            checkGLCodingDetail({
                companyGLAccounts: lineItem.InvoiceLineItemCompanyGLAccounts,
                netTotal: lineItem.LineItemNetTotal,
                onInvalidAllocation: () => options.onValidationFailure.line(lineIndex + 1),
            })
        );
    } else if (options.glMode === 'header') {
        checkGLCodingDetail({
            companyGLAccounts: options.invoice.InvoiceCompanyGLAccounts,
            netTotal: options.invoice.InvoiceAmount,
            onInvalidAllocation: options.onValidationFailure.header,
        });
    }
}

function checkGLCodingSplitSegments(options: {
    companyGLAccounts: (IInvoiceLineItemCompanyGLAccount | IInvoiceCompanyGLAccount)[];
    onEmptySplit: (splitNumber: number) => void;
}): void {
    options.companyGLAccounts.forEach((split, splitIndex) => {
        const isOneSegmentFilled = Object.values(split.Coding.Coding).some((field: any) => !!field?.value);

        if (!isOneSegmentFilled) {
            options.onEmptySplit(splitIndex + 1);
        }
    });
}

export function oneSegmentFilledValidation(options: {
    glMode: 'line' | 'header';
    invoice: IInvoice;
    onValidationFailure: {
        line: (splitInfo: { lineNumber: number; splitNumber: number }) => void;
        header: (splitNumber: number) => void;
    };
}) {
    if (options.glMode === 'line') {
        options.invoice.InvoiceLineItems.forEach((lineItem, lineIndex) =>
            checkGLCodingSplitSegments({
                companyGLAccounts: lineItem.InvoiceLineItemCompanyGLAccounts,
                onEmptySplit: (splitNumber) =>
                    options.onValidationFailure.line({ lineNumber: lineIndex + 1, splitNumber }),
            })
        );
    } else if (options.glMode === 'header') {
        checkGLCodingSplitSegments({
            companyGLAccounts: options.invoice.InvoiceCompanyGLAccounts,
            onEmptySplit: options.onValidationFailure.header,
        });
    }
}

export function checkPONumberExists(invoice: IInvoice): boolean {
    return (
        !isEmpty(invoice.PurchaseOrder) ||
        invoice.InvoiceLineItems.some((lineItem) => !isEmpty(lineItem.PurchaseOrderNum))
    );
}
