import { FC, useMemo } from 'react';
import { components } from 'react-select';
import { Search } from '@mui/icons-material';
import { Async } from 'react-select-virtualized';
import classNames from 'classnames';
import _ from 'lodash';
import { IAccountValue, IValue } from '../../types';
import './SearchBox.scss';

export interface ISearchBoxProps {
    value: IValue | IAccountValue | null;
    editMode?: boolean;
    className?: string;
    minimumInputSearch?: number;
    isClearable?: boolean;
    data?: any[];
    cacheOptions?: boolean;
    placeholder?: string;
    onChange: (key: string, value: string) => void;
    testId?: string;
}

const styles = {
    control: (css) => ({ ...css, paddingLeft: '1rem' }),
};

const Control = ({ children, ...props }) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <components.Control {...props}>
            <Search />
            {children}
        </components.Control>
    );
};

export const SearchBox: FC<ISearchBoxProps> = ({
    value,
    className,
    minimumInputSearch = 3,
    cacheOptions = true,
    isClearable = false,
    data: incomingData,
    placeholder,
    onChange,
    testId,
    editMode,
}) => {
    // add selected value to the list of options to ensure that it is displayed, even if the options get
    // updated to a list that doesn't include the value
    const data = useMemo(() => {
        if (value && value.value && incomingData?.findIndex((x) => x.value === value.value) === -1) {
            return [{ value: value.value, label: value.label || value.value }, ...incomingData];
        }
        return incomingData;
    }, [incomingData, value]);

    const debouncedLoadOptions = useMemo(() => {
        const loadOptions = (input: string, callback: any) => {
            callback(data?.filter(({ label }) => label.toLowerCase().includes(input.toLowerCase())));
        };
        return _.debounce(loadOptions, 200);
    }, [data]);

    const determineValue = (newValue: string) => {
        return data?.filter((d: IValue) => d.value === newValue)[0] || null;
    };
    const coreClasses = classNames('core-select', className, testId);

    return (
        <Async
            isDisabled={!editMode}
            cacheOptions={cacheOptions}
            options={data}
            loadOptions={debouncedLoadOptions}
            minimumInputSearch={minimumInputSearch}
            value={value?.value ? determineValue(value.value) : null}
            className={coreClasses}
            classNamePrefix="SearchBox"
            isClearable={isClearable}
            onChange={onChange}
            optionHeight={50}
            placeholder={placeholder}
            components={{ Control }}
            styles={styles}
        />
    );
};
