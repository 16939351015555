import {
    createNumericTextField,
    createTextField,
    createUseSearchService,
    ISearchConfiguration,
    ISearchRequest,
    ISearchResponse,
    ISearchService,
} from '../../../reusableFeatures';
import { GridColumns, GridRenderCellParams, GridRowId } from '@mui/x-data-grid';
import { calculatePageOffset } from '../../../utils';
import { IValidateSamplesSearchItem } from './types';
import { DocumentBatchService } from './DocumentBatchService';
import { Button, ErrorToErrorMessage, toaster, TOOLTIP_CELL_MODE } from '../../../ui';
import { orderBy } from '../../utils';

export class ValidateSamplesSearchService implements ISearchService<IValidateSamplesSearchItem> {
    private documentBatchService = new DocumentBatchService();

    async download(id: GridRowId) {
        try {
            await this.documentBatchService.downloadDocumentBatch(Number(id));
        } catch (e) {
            toaster.makeToast({
                severity: 'error',
                message: <ErrorToErrorMessage error={e} prompt="Error(s) occurred" />,
                autoHideDuration: 5000,
            });
        }
    }

    async fetchSearchConfiguration(): Promise<ISearchConfiguration<IValidateSamplesSearchItem>> {
        const gridColumns: GridColumns = [
            { field: 'supplierProfileId', headerName: 'Sender ProfileID', flex: 1 },
            { field: 'createdDate', headerName: 'Created Date', flex: 1 },
            { field: 'documentBatchId', headerName: 'Document Batch Id', flex: 1 },
            {
                field: 'download',
                headerName: 'Download Batch',
                renderCell: (params: GridRenderCellParams) =>
                    params.cellMode === TOOLTIP_CELL_MODE ? null : (
                        <Button onClick={() => this.download(params.id)}>Download</Button>
                    ),
                flex: 1,
                sortable: false,
            },
            {
                field: 'ruleConfigurationName',
                headerName: 'Rule Configuration Name',
                flex: 2,
                sortable: false,
            },
            {
                field: 'tradingPartners',
                headerName: 'Trading Partners',
                flex: 2,
                sortable: false,
            },
        ];

        const searchFormFieldColumns = [
            [
                createTextField({
                    label: 'Rule Configuration Name',
                    defaultValue: '',
                    dataBindingKey: 'ruleConfigurationName',
                    id: 'ValidateSamplesSearch-ruleConfigurationName',
                }),
                createNumericTextField({
                    label: 'Sender ProfileID',
                    defaultValue: null,
                    dataBindingKey: 'senderProfileId',
                    id: 'ValidateSamplesSearch-supplierProfileId',
                    locale: 'en_US',
                    scale: 0,
                    omitCommas: true,
                }),
                createTextField({
                    label: 'Trading Partner Name',
                    defaultValue: '',
                    dataBindingKey: 'tradingPartnerName',
                    id: 'ValidateSamplesSearch-tradingPartnerName',
                }),
            ],
        ];

        return {
            gridColumns,
            enabledExportingMethods: [],
            searchFormFieldColumns,
            pageSizeOptions: [10, 25, 50],
            defaultPageSize: 10,
            searchWithNoCriteria: true,
        };
    }

    async fetchResults(
        _searchConfiguration: ISearchConfiguration<IValidateSamplesSearchItem>,
        searchRequest: ISearchRequest<IValidateSamplesSearchItem>
    ): Promise<ISearchResponse> {
        const response = await this.documentBatchService.getValidateSamplesGrid({
            $top: searchRequest.pageSize,
            $skip: calculatePageOffset(searchRequest.pageSize, searchRequest.pageNumber),
            $count: true,
            $orderby: orderBy(searchRequest.sort),
            ruleConfigurationName: searchRequest.searchQuery.state.ruleConfigurationName ?? undefined,
            senderProfileId: searchRequest.searchQuery.state.senderProfileId ?? undefined,
            buyerCompanyName: searchRequest.searchQuery.state.tradingPartnerName ?? undefined,
        });

        return {
            pageResults: response.items,
            totalResultCount: response.count,
        };
    }

    exportData(): Promise<void> {
        throw new Error('Exporting not implemented for validate samples search');
    }

    get key(): string {
        return 'ValidateSamplesSearch';
    }
}

export const useValidateSamplesSearchService = createUseSearchService(ValidateSamplesSearchService);
