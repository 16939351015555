export type { FetchOptions, CachingService } from '../../../DocumentEditRules/proservContractTypes/CachingService';
import type { CachingService } from '../../../DocumentEditRules/proservContractTypes/CachingService';

export function createFakeCachingService(): CachingService {
    return {
        fetch<T>(_key: any, compute: () => T | Promise<T>) {
            return Promise.resolve(compute());
        },
    };
}
