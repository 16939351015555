enum ProcessingMode {
    Production = 1,
    Test = 2,
    Provisional = 3,
    BuyerWebEntryTest = 4,
    Disabled = 5,
    BuyerEntry = 6,
}

export default ProcessingMode;
