import { createContext, useContext, FC } from 'react';
import { MessageClass, MessageClasses } from './types/ValidatorStates';
import { defaultMessageClassesState } from './internalRules/useInternalRules/useInternalRules';
import { useInternalRulesValidatorContext } from './internalRules';
import { useDocumentEditValidator } from './DocumentEditValidator';
import { DocumentValidatorV2 } from '../CJSRuleEngineV2ClientWrapper';

const MessageClassesContext = createContext<MessageClasses>(defaultMessageClassesState);

function mergeMessageClasses(msgClasses: MessageClasses[]): MessageClasses {
    const reduceMessageClass = (result: MessageClass, msgClass: MessageClass) => {
        result.messages.push(...msgClass.messages);

        Object.entries(msgClass.fieldMessages).forEach(([key, errors]) => {
            if (errors == null) {
                return;
            }

            if (result.fieldMessages[key] == null) {
                result.fieldMessages[key] = [];
            }

            result.fieldMessages[key]?.push(...errors);
        });

        return result;
    };

    const errorMessages = msgClasses
        .map((c) => c.errorMessages)
        .reduce<MessageClass>(reduceMessageClass, {
            messages: [],
            fieldMessages: {} as Record<string, string[]>,
        });

    const warningMessages = msgClasses
        .map((c) => c.warningMessages)
        .reduce<MessageClass>(reduceMessageClass, {
            messages: [],
            fieldMessages: {} as Record<string, string[]>,
        });

    return {
        errorMessages,
        warningMessages,
    };
}

export const MessageClassesProvider: FC = ({ children }) => {
    const { messageClasses } = useInternalRulesValidatorContext();

    const { messageClasses: messageClassesFromV2 } = DocumentValidatorV2.useDocumentValidatorContext();

    const { validatorResultState } = useDocumentEditValidator();

    const classes = [messageClasses, messageClassesFromV2];

    if (
        validatorResultState.type === 'ValidationFailedState' ||
        validatorResultState.type === 'ValidationSucceededState'
    ) {
        classes.push(validatorResultState.messageClasses);
    }

    const mergedMessageClasses = mergeMessageClasses(classes);

    return <MessageClassesContext.Provider value={mergedMessageClasses}>{children}</MessageClassesContext.Provider>;
};

export function useMessageClassesContext() {
    const state = useContext(MessageClassesContext);

    return state;
}
