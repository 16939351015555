import { api } from '../../utils';
import apiPaths from '../../api/paths';
import { serviceAgreementPopupShow } from '../../actions/serviceAgreement/serviceAgreementPopup';
import { fetchMenuSettings } from '../userlogin';
import { getServiceAgreements } from './serviceAgreement';
import { portalUserService } from 'common';

export const CREATE_SERVICE_AGREEMENT_SIGNATURE_SUCCESS = 'CREATE_SERVICE_AGREEMENT_SIGNATURE_SUCCESS';
export const CREATE_SERVICE_AGREEMENT_SIGNATURE_PENDING = 'CREATE_SERVICE_AGREEMENT_SIGNATURE_PENDING';
export const CREATE_SERVICE_AGREEMENT_SIGNATURE_FAILED = 'CREATE_SERVICE_AGREEMENT_SIGNATURE_FAILED';

/**
 * Service agreement success action
 * @param {*} response - response to api
 *
 * @return { Function }
 */
export function createServiceAgreementSignatureSuccessAction(response) {
    return (dispatch) => {
        dispatch({
            type: CREATE_SERVICE_AGREEMENT_SIGNATURE_SUCCESS,
            payload: {},
        });
        dispatch(serviceAgreementPopupShow(false));
        dispatch(fetchMenuSettings());
        dispatch(getServiceAgreements());
    };
}

/**
 * Service agreement error action
 * @param {*} error
 *
 * @return { Function }
 */
export function createServiceAgreementSignatureErrorAction(error) {
    return (dispatch) => {
        dispatch({
            type: CREATE_SERVICE_AGREEMENT_SIGNATURE_FAILED,
            payload: error,
        });
    };
}

/**
 * Create service agreement signature
 * @param {number|number[]} ServiceAgreementContentIds
 * @return { Function }
 */
export function createServiceAgreementSignature(ServiceAgreementContentIds = []) {
    ServiceAgreementContentIds = [].concat(ServiceAgreementContentIds);
    return (dispatch, getState) => {
        const CompanyId = parseInt(portalUserService.getCurrentCompanyId());
        const serviceAgreementSignature = (ServiceAgreementContentId) => ({
            CompanyId,
            ServiceAgreementContentId,
            Title: 'string',
        });
        dispatch({ type: CREATE_SERVICE_AGREEMENT_SIGNATURE_PENDING });
        Promise.all(
            ServiceAgreementContentIds.map((ServiceAgreementContentId) =>
                api({ 'Content-Type': 'application/json' })
                    .post(
                        apiPaths.createServiceAgreementSignature,
                        JSON.stringify(serviceAgreementSignature(ServiceAgreementContentId))
                    )
                    .then(
                        (response) => response,
                        (error) => dispatch(createServiceAgreementSignatureErrorAction(error))
                    )
            )
        ).then(() => dispatch(createServiceAgreementSignatureSuccessAction()));
    };
}
