import { FC, useRef, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTargetRegionState } from './Context';

export interface IContentsProps {
    region: string;
}

/**
 * Returns the same (initially) unmounted HTML DIV element on each render
 */
function useNewElement() {
    const elRef = useRef<HTMLElement>();
    if (!elRef.current) {
        elRef.current = document.createElement('div');
    }
    const el = elRef.current!;
    return el;
}

/**
 * Gets the default content element from an `<Outlet />`
 */
function getDefaultsEl(outletEl: HTMLDivElement) {
    const el = outletEl.querySelector('[data-defaults]');
    if (!el) {
        return null;
    }

    return el as HTMLDivElement;
}

/**
 * Renders its children into the `<Outlet />` with the same region name.
 *
 * If the `<Outlet />` has any default contents, they will be hidden as long
 * as this component is mounted.
 */
export const Contents: FC<IContentsProps> = ({ region, children }) => {
    const el = useNewElement();
    const { state } = useTargetRegionState('Contents');

    // hooks up the contents to the outlet
    useLayoutEffect(() => {
        // try to find the target outlet element in the context's state.
        // if it doesn't exist, we will just end up not rendering anything to screen
        const outletEl = state.outletNodes.get(region);
        if (!outletEl) {
            return undefined;
        }

        // hide default contents in outlet and append our node to the outlet element
        const defaultsEl = getDefaultsEl(outletEl);
        if (defaultsEl) {
            defaultsEl.style.display = 'none';
        }
        outletEl.appendChild(el);

        return () => {
            // clean up by removing our node from the outlet and showing its defaults
            outletEl.removeChild(el);
            if (defaultsEl) {
                defaultsEl.style.display = 'block';
            }
        };
    }, [el, region, state]);

    // render the children into our new element, which we hook up to the outlet in the effect above
    return createPortal(children, el);
};
