import {
    Box,
    IconButton,
    List,
    ListItem,
    ListItemText,
    tooltipClasses,
    TooltipProps,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {
    useSupplierTotalReportService,
    useInvoiceTotalReportService,
    useSpendTotalReportService,
    usePOTotalReportService,
    usePOATotalReportService,
} from './SpendTotalsReportService';
import { SmartCard } from './SmartCard';

import { Tooltip } from '../../../../../ui';
import HelpIcon from '@mui/icons-material/Help';
import { styled } from '@mui/material/styles';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 600,
    },
});

interface SpendTotalsReportProps {
    isPOUser: boolean;
    isPOAUser: boolean;
}

// The PO and POA cards will only show if buyer is configured with PO and POA
export function SpendTotalsReport({ isPOUser, isPOAUser }: SpendTotalsReportProps) {
    const supplierTotalReportService = useSupplierTotalReportService();
    const invoiceTotalReportService = useInvoiceTotalReportService();
    const spendTotalReportService = useSpendTotalReportService();
    const poTotalReportService = usePOTotalReportService();
    const poaTotalReportService = usePOATotalReportService();

    const theme = useTheme();
    const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));

    const titleSupplierTotal = 'Suppliers';
    const titleInvoiceTotal = 'Invoices';
    const titleSpendTotal = 'Spend';
    const titlePOTotal = 'POs';
    const titlePOATotal = 'POAs';

    return (
        <>
            <Typography
                sx={{
                    fontFamily: 'Open Sans',
                    fontSize: '28px',
                    fontWeight: 400,
                    lineHeight: '36px',
                    letterSpacing: '0em',
                    textAlign: 'center',
                    padding: '20px',
                }}
            >
                Totals (All Time)
                <Box component="span" ml={1}>
                    <CustomWidthTooltip
                        title={
                            <List>
                                <ListItem disablePadding>
                                    <ListItemText primary="Suppliers: The total count of your suppliers that have been added to Transcepta." />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemText primary="Invoices: The total count of all invoices you've received through Transcepta." />
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemText primary="Spend: The total monetary amount represented in the invoices you have received through Transcepta." />
                                </ListItem>
                                {isPOUser && (
                                    <ListItem disablePadding>
                                        <ListItemText primary="POs: The total count of all POs you have shared through Transcepta." />
                                    </ListItem>
                                )}
                                {isPOAUser && (
                                    <ListItem disablePadding>
                                        <ListItemText primary="POAs: The total count of all Purchase Order Acknowledgements you have received through Transcepta." />
                                    </ListItem>
                                )}
                            </List>
                        }
                        placement="top-start"
                    >
                        <IconButton>
                            <HelpIcon />
                        </IconButton>
                    </CustomWidthTooltip>
                </Box>
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isLgDown ? 'column' : 'row',
                    justifyContent: 'center',
                    margin: '10px',
                }}
            >
                <SmartCard cardTitle={titleSupplierTotal} service={supplierTotalReportService} />
                <SmartCard cardTitle={titleInvoiceTotal} service={invoiceTotalReportService} />
                <SmartCard cardTitle={titleSpendTotal} service={spendTotalReportService} />
                {isPOUser && <SmartCard cardTitle={titlePOTotal} service={poTotalReportService} />}
                {isPOAUser && <SmartCard cardTitle={titlePOATotal} service={poaTotalReportService} />}
            </Box>
        </>
    );
}
