import { ValidationFunctionProgrammingError } from '../DocumentEditValidator/errors';
import { ValidationError, ValidationResults } from '../proservContractTypes';
import { IErrorRenderer, IRenderedError, ValidationFailureDictionary, ValidationFailureType } from '../types/private';

function cSharpFormat(formatString: string, replacements: any[]) {
    return formatString.replace(/{(\d+).*?}/g, (_, index) => {
        const value = replacements[index];

        if (value === undefined) {
            throw new Error(
                `Invalid replacements for format string. Format string required at least ${
                    parseInt(index, 10) + 1
                } replacements, but there are only ${replacements.length} replacements.`
            );
        }

        return value;
    });
}

export class StaticErrorRenderer implements IErrorRenderer {
    constructor(private failureDictionary: ValidationFailureDictionary) {}

    prefetchValidationFailureTypes() {}

    async renderErrors(validationResponse: ValidationResults) {
        return validationResponse.map((e) => ({
            type: e.type,
            error: this.formatError(e.error),
        }));
    }

    // eslint-disable-next-line class-methods-use-this
    private formatError(e: ValidationError) {
        const failureType = this.failureDictionary[e.errorCode];
        if (!failureType) {
            console.error(
                'ErrorRenderer - Programming error occurred. An unknown ValidationError.errorCode was provided',
                e
            );
            throw new ValidationFunctionProgrammingError();
        }

        try {
            const renderedError: IRenderedError = {
                associatedField: e.associatedField,
                error: this.formatErrorText(e, failureType),
            };
            return renderedError;
        } catch (error) {
            console.error(
                'ErrorRenderer - Programming error occurred trying to format error message:',
                e,
                'failure type:',
                failureType,
                'error was:',
                error
            );
            throw new ValidationFunctionProgrammingError();
        }
    }

    // eslint-disable-next-line class-methods-use-this
    private formatErrorText(e: ValidationError, failureType: ValidationFailureType) {
        const titleText = cSharpFormat(failureType.titleText, e.titleArguments);

        if (!failureType.detailText) {
            return titleText;
        }

        const detailText = cSharpFormat(failureType.detailText, e.detailArguments);

        return `${titleText}: ${detailText}`;
    }
}
