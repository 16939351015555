import { CompiledScript, CompiledWorkflowScript } from './CompiledScript';

/**
 * Compiles script source so it is executable in the `ScriptingEngineEnvironment`.
 *
 * @param scriptSrc the source of the script
 * @returns the compiled script
 */
export function compileScript(scriptSrc: string) {
    try {
        const wrapped = `(function (app) { ${scriptSrc} })`;
        return eval(wrapped) as CompiledScript;
    } catch (e) {
        throw new Error(`Error Compiling Script: ${e}`);
    }
}

/**
 * Compiles script source so it is executable in the `WorkflowValidationEnvironment`.
 *
 * @param scriptSrc the source of the script
 * @returns the compiled script
 */
export function compileWorkflowScript(scriptSrc: string) {
    try {
        const wrapped = `(async function (app) { ${scriptSrc} })`;
        return eval(wrapped) as CompiledWorkflowScript;
    } catch (e) {
        throw new Error(`Error Compiling Workflow Script: ${e}`);
    }
}
