import * as registry from '../registry';
import { IFeatureState, useFeatureAppState } from './FeatureAppState';

export interface IFeatureProps {
    feature: IFeatureState;
}

const tdStyle = {
    padding: '10px 0',
} as const;

export default function Feature({ feature }: IFeatureProps) {
    const { setFeatureState } = useFeatureAppState();

    const dependencies = registry.getDependencies(feature.featureKey);
    const helpText =
        dependencies.length > 0 ? `Dependencies (must also be enabled): ${dependencies.join(', ')}` : 'No dependencies';

    return (
        <tr>
            <td title={helpText} style={tdStyle}>
                {feature.featureKey}
            </td>
            <td style={tdStyle}>
                <select
                    value={feature.userState}
                    onChange={(e) => setFeatureState(feature.featureKey, e.target.value as any)}
                >
                    <option value="unset">Not Set</option>
                    <option value="enabled">Enabled</option>
                    <option value="disabled">Disabled</option>
                </select>
            </td>
            <td style={tdStyle}>{feature.isEnabled ? 'Enabled' : 'Disabled'}</td>
        </tr>
    );
}
