import { SeriesLineOptions } from 'highcharts';
import { DashboardApi, InvoicesRightFirstTimeDashboardsReportLine } from '../../../Api';
import { DashboardDataPayload } from '../../../Api/DashboardDataPayload';
import { createDashboardReportService } from '../../createDashboardReportService';
import { DashboardReportService } from '../../DashboardReportService';
import { dashboardFormatDate } from '../../../Charts/Chart/Chart.utils';

class InvoicesFirstTimeRightReportService
    implements DashboardReportService<InvoicesRightFirstTimeDashboardsReportLine, SeriesLineOptions['data']>
{
    private api = new DashboardApi();

    get storedProcedure() {
        return 'DashboardReportInvoicesRightFirstTimeLine' as const;
    }

    async fetchReport(settings: DashboardDataPayload) {
        const res = await this.api.getDashboardReportData<InvoicesRightFirstTimeDashboardsReportLine>(
            settings,
            this.storedProcedure
        );
        return res.data;
    }

    get nullifyDataIfEmptyObj() {
        return {
            optIn: true,
            xAxisPropertyKey: 'Date' as const,
        };
    }

    transformVMToChartData(vm: InvoicesRightFirstTimeDashboardsReportLine['viewModel']) {
        return vm.map((p) => ({
            y: p.PercentRightFirstTime,
            name: dashboardFormatDate(p.Date),
            label: dashboardFormatDate(p.Date),
        }));
    }
}

export const useInvoicesFirstTimeRightReportService = createDashboardReportService(InvoicesFirstTimeRightReportService);
