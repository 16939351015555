import { useEffect, useCallback, useState } from 'react';
import { Snackbar, Stack, Fade } from '@mui/material';
import { Alert } from '../Alert';
import { FCNC } from '../FCNC';
import { useToaster } from './useToaster';
import { IToast } from './Toaster';

export interface IToastProps {
    testId: string;
    toast: IToast;
}

const Toast: FCNC<IToastProps> = ({ testId, toast }) => {
    const [open, setOpen] = useState(true);

    const toaster = useToaster();

    const handleClose = useCallback((_: any, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    }, []);

    useEffect(() => {
        if (!toast.autoHideDuration) {
            return undefined;
        }

        const timeout = setTimeout(() => {
            handleClose(null);
        }, toast.autoHideDuration);

        return () => {
            clearTimeout(timeout);
        };
    }, [toast, handleClose]);

    return (
        <Fade
            in={open}
            timeout={400}
            onExited={() => {
                toaster.throwAwayToast(toast.id);
            }}
        >
            <div>
                <Alert testId={testId} onClose={handleClose} severity={toast.severity} sx={{ width: '100%' }}>
                    {toast.message}
                </Alert>
            </div>
        </Fade>
    );
};

export interface IToastShowerProps {
    testId: string;
}

export const ToastShower: FCNC<IToastShowerProps> = ({ testId }) => {
    const toaster = useToaster();
    const toasts = toaster.getToasts();

    return (
        <Snackbar data-testid={testId} open={toasts.length > 0}>
            <div>
                <Stack spacing={1}>
                    {toasts
                        .slice(0, 3)
                        .reverse()
                        .map((toast) => (
                            <div key={toast.id}>
                                <Toast testId={`${testId}-toast`} key={toast.id} toast={toast} />
                            </div>
                        ))}
                </Stack>
            </div>
        </Snackbar>
    );
};
