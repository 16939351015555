interface EntityDataObjectAPI {
    OldValue: string | number | boolean | null;
    NewValue: string | number | boolean | null;
}

export interface EntityDataObjectUI {
    OldValue: Array<string | number | boolean | null>;
    NewValue: Array<string | number | boolean | null>;
    isList: boolean;
}

enum ChangeTypeEnum {
    CREATE = 1,
    UPDATE = 2,
    DELETE = 3,
}
export interface AuditLogAPIViewModel {
    Id: number;
    UserName: string;
    GroupId: string;
    ChangeType: ChangeTypeEnum;
    ChangeType_US: string;
    CreatedTime: string;
    EntityData: Record<string, Record<string, EntityDataObjectAPI>>;
    EntityType: string;
    EntityPrimaryKey: number;
}

export type AuditLogUIViewModel = Pick<AuditLogAPIViewModel, 'UserName' | 'GroupId' | 'CreatedTime'> & {
    EntityData: Record<string, EntityDataObjectUI>;
};
