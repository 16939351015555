export type EnumKeys<K extends string, T> = {
    [key in K]: T;
};

export function isAllowedEnumKey<K extends string, T>(theEnum: EnumKeys<K, T>, value: string): value is K {
    return Object.keys(theEnum).some((x) => x === value);
}

export type EnumValues<T> = {
    [key: string]: T;
};

export function isAllowedEnumValue<T>(theEnum: EnumValues<T>, value: unknown): value is T {
    return Object.keys(theEnum).some((x) => theEnum[x] === value);
}
