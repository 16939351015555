import { IDocumentFieldController } from '../proservContractTypes';
import { IFieldControlState, IFieldControlStates, defaultFieldControlState } from '../types/IFieldControlStates';

export class DocumentFieldController implements IDocumentFieldController {
    private fieldControlStates: IFieldControlStates = {};

    disableField(fieldKey: string): void {
        this.updateFieldControlState(fieldKey, (x) => ({
            ...x,
            editable: false,
        }));
    }

    enableField(fieldKey: string): void {
        this.updateFieldControlState(fieldKey, (x) => ({
            ...x,
            editable: true,
        }));
    }

    hideField(fieldKey: string): void {
        if (fieldKey.startsWith('bodyField-')) {
            throw new Error(
                `Attempted to hide a body line-level field ("${fieldKey}") for a specific line. This is not allowed as it leads to display issues. Please disable the field instead.`
            );
        }

        this.updateFieldControlState(fieldKey, (x) => ({
            ...x,
            visible: false,
        }));
    }

    showField(fieldKey: string): void {
        if (fieldKey.startsWith('bodyField-')) {
            throw new Error(
                `Attempted to show a body line-level field ("${fieldKey}") for a specific line. This is not allowed as it leads to display issues. Please enable the field instead.`
            );
        }

        this.updateFieldControlState(fieldKey, (x) => ({
            ...x,
            visible: true,
        }));
    }

    getFieldControlStates() {
        return this.fieldControlStates;
    }

    private getFieldControlState(fieldKey: string) {
        return this.fieldControlStates[fieldKey] ?? defaultFieldControlState;
    }

    private updateFieldControlState(
        fieldKey: string,
        updater: (currentState: IFieldControlState) => IFieldControlState
    ) {
        this.fieldControlStates[fieldKey] = updater(this.getFieldControlState(fieldKey));
    }
}
