import { push } from 'connected-react-router';

import * as TransceptaThunks from 'common/build/legacy/transcepta-thunks';

import { pushAlert, clearAlerts } from '../actions/alerts';
import * as customerActions from '../actions/CustomerActions';
import * as customerLocationActions from '../actions/invoiceCreation/customerLocation';
import projectConfig from '../project.config.json';
import R from '../routes';
import * as documentCreationActions from '../actions/document/documentCreationActions';
import { path } from 'ramda';
import { parseMessage } from '../utils/index';
import { CustomersSearchConfig } from '../utils/customerSearchConfig';
import { apiErrorAction } from '../actions/error';
import { strings } from '../utils/localization/LocalizationStrings';
import { portalUserService } from 'common';

/*
 * Thunk dispatched by "Create Customer Location" screen
 */
export const createCustomerLocationRequest = (formData, businessDocTypePathName) => async (dispatch, getState) => {
    let state = getState();
    dispatch(clearAlerts());
    if (!isValid(formData)) {
        dispatch(
            pushAlert({
                type: 'error',
                text: strings.textRequiredFieldMissing,
            })
        );
        return;
    }
    let CustomerDeliveryMethods = [];
    let formDataPrepared = preparedFormData(formData, CustomerDeliveryMethods);
    formDataPrepared['CustomerDeliveryMethods'] = CustomerDeliveryMethods;
    const CompanyID = parseFloat(portalUserService.getCurrentCompanyId(), 10);
    const params = {
        ...formDataPrepared,
        CompanyID,
        PaymentType: 0,
        AllowMarketingMaterial: true,
    };

    dispatch(customerLocationActions.createCustomerLocationRequest(params));
    let companyThunk = new TransceptaThunks.CompanyThunk();
    let response = await companyThunk.addCustomer(params);
    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, customerLocationActions.CREATE_CUSTOMER_LOCATION_FAILED, params));
        return;
    }

    dispatch(customerLocationActions.createCustomerLocationRequestSuccess(response));
    dispatch(navigateToCustomerLocationSelectPage(businessDocTypePathName));
};

/*
 * Thunk dispatched by "Select Customer Location" screen
 */
export const navigateToCreateCustomerLocationPage = (businessDocTypePathName) => (dispatch, getState) => {
    const state = getState();
    dispatch(
        push({
            pathname: R.DOCUMENT_CREATION_CUSTOMER_LOCATION_CREATE.path
                .replace(':businessDocType', businessDocTypePathName)
                .replace(
                    ':customerId',
                    state.selectCustomer.currentCustomer ? state.selectCustomer.currentCustomer.Id : ''
                ),
            state: {
                path: R.DOCUMENT_CREATION.path,
            },
        })
    );
};

/*
 * Thunk dispatched by "Create Select Customer Location" screen
 */
export const navigateToCustomerLocationSelectPage =
    (businessDocTypePathName, buyerCompanyId) => (dispatch, getState) => {
        let state = getState();

        const customerLocationPageSize = projectConfig.AppSettings.CustomerLocationSearchRowsPerPage;

        dispatch(
            push(
                R.DOCUMENT_CREATION_CUSTOMER_LOCATION_SELECTION.path
                    .replace(':businessDocType', businessDocTypePathName)
                    .replace(
                        ':customerId',
                        state.selectCustomer.currentCustomer
                            ? state.selectCustomer.currentCustomer.BuyerCompanyId
                            : buyerCompanyId
                    )
            )
        );
    };

/*
 * Thunk dispatched by "Create Select Customer Location" screen
 */
export const navigateToSelectCustomerPage = (businessDocTypePathName) => async (dispatch, getState) => {
    let state = getState();
    dispatch(documentCreationActions.clearFlipCache());
    if (!state.selectCustomer.customers || state.selectCustomer.customers.length === 0) {
        let data = await dispatch(fetchCustomerList());

        if (data) {
            dispatch(customerActions.navigateToSelectCustomerPage());
            dispatch(
                push(R.DOCUMENT_CREATION_CUSTOMER_SELECTION.path.replace(':businessDocType', businessDocTypePathName))
            );
        }
    } else {
        dispatch(customerActions.navigateToSelectCustomerPage());
        dispatch(
            push(R.DOCUMENT_CREATION_CUSTOMER_SELECTION.path.replace(':businessDocType', businessDocTypePathName))
        );
    }
};

/*
 * Thunk dispatched by PO Flip screen
 */
export const getCustomerSettings = (companyId) => async (dispatch) => {
    let companyThunk = new TransceptaThunks.CompanyThunk();
    const params = {
        companyID: companyId,
    };
    let response = await companyThunk.fetchBuyerPortalSettings(params);
    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, null, params));
    } else {
        let settings = await response.data;
        dispatch(customerActions.setCustomerSettings(settings));
    }
};

export const fetchCustomerLocation = (id) => async (dispatch, getState) => {
    const params = {
        companyId: portalUserService.getCurrentCompanyId(),
        $count: true,
        $top: null,
        $filter: 'ID eq ' + id,
        $skip: null,
    };

    dispatch(customerLocationActions.fetchCustomerLocationExecute(params));

    const companyThunk = new TransceptaThunks.CompanyThunk();
    const response = await companyThunk.fetchCustomers(params);
    if (response.type && response.type === 'error') {
        dispatch(customerLocationActions.fetchCustomerLocationFailure(response.text));
        dispatch(apiErrorAction(response.text));
    } else {
        const data = await response.data;
        dispatch(customerLocationActions.fetchCustomerLocationSuccess(data));
    }
};

/*
 * Thunk dispatched by "Create Select Customer Location" screen
 */
export const getCustomerLocationList =
    (pageSize = null, page = 0, sortColumnKey = 'CustomerNumber', sortDirection = 'asc', buyerCompanyId = null) =>
    async (dispatch, getState) => {
        const state = getState();
        const buyerId = state.selectCustomer.currentCustomer
            ? state.selectCustomer.currentCustomer.BuyerCompanyId
            : buyerCompanyId;
        const params = {
            companyId: portalUserService.getCurrentCompanyId(),
            $count: true,
            $top: pageSize !== null ? `${pageSize}` : null,
            $filter: `BuyerDestinationProfile/CompanyID eq ${buyerId}`,
            $skip: page > 0 ? `${pageSize * page}` : null,
            $orderby: `${sortColumnKey} ${sortDirection}`,
        };
        dispatch(customerLocationActions.getCustomerLocation(params));

        let companyThunk = new TransceptaThunks.CompanyThunk();
        let response = await companyThunk.fetchCustomers(params);
        if (response.type && response.type === 'error') {
            dispatch(apiErrorAction(response.text, customerLocationActions.GET_CUSTOMER_LOCATION_FAILED, params));
        } else {
            dispatch(
                customerLocationActions.getCustomerLocationSuccessAction(response, page, sortColumnKey, sortDirection)
            );
        }
    };

/*
 * Thunk dispatched by "Create Select Customer Location" screen
 */
export const fetchCustomerList = (buyerCompanyId, businessDocType) => async (dispatch, getState) => {
    const companyId = portalUserService.getCurrentCompanyId();

    const params = {
        companyId: companyId,
        businessDocType: businessDocType,
        $count: true,
        $filter: 'ProcessingMode ne ' + projectConfig.ProcessingMode.Disabled,
    };
    dispatch(customerActions.fetchCustomerList(params));

    let companyThunk = new TransceptaThunks.CompanyThunk();
    let response = await companyThunk.fetchTradingPartners(params);
    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, customerActions.SELECT_CUSTOMER_LIST_FETCH_FAILED, params));
    } else {
        await dispatch(customerActions.fetchCustomerListSuccess(response.data.Items));

        if (buyerCompanyId) {
            const customer = getState().selectCustomer.customers.find((customer) => {
                return customer.BuyerCompanyId === parseInt(buyerCompanyId, 10);
            });
            if (customer) dispatch(customerActions.selectCustomerAction(customer));
        }
    }

    return response;
};

/*
 * Thunk dispatched by "Select Customer" screen
 */
export const searchBuyerProfiles = (buyerCompanyId) => async (dispatch, getState) => {
    const params = {
        companyId: buyerCompanyId,
        profileType: projectConfig.profileType.BuyerDestination,
    };
    dispatch(customerActions.searchBuyerProfiles(params));

    let profileThunk = new TransceptaThunks.ProfileThunk();
    let response = await profileThunk.fetchProfiles(params);
    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, customerActions.SEARCH_BUYER_PROFILES_FAILED, params), false);
    } else {
        dispatch(customerActions.searchBuyerProfilesSuccess(response));
    }
};

/*
 * Thunk dispatched by "Select Customer" screen
 */
export const selectCustomer = (id, businessDocTypePathName) => (dispatch, getState) => {
    const state = getState();
    const customer = state.selectCustomer.customers.find((customer) => {
        return customer.Id === id;
    });
    if (customer) {
        dispatch(customerActions.selectCustomerAction(customer));
        dispatch(searchBuyerProfiles(customer.BuyerCompanyId));
        dispatch(navigateToCustomerLocationSelectPage(businessDocTypePathName));
    }
};

export const selectCustomerLocation = (selectedCustomer, businessDocTypePathName) => (dispatch, getState) => {
    const state = getState();
    const customer = state.selectCustomer.search.filteredCustomers.find((customer) => {
        return customer.ID === selectedCustomer.ID;
    });
    if (customer) {
        dispatch(customerActions.selectCustomerLocationAction(customer));
    }
};

/*
 * Thunk dispatched by "Select Customer Location" screen
 */
export const applyCustomerLocationSorting = (pageSize, columnKey) => (dispatch, getState) => {
    const state = getState();
    const currentType =
        state.documentCreation.customerLocation.sorting &&
        state.documentCreation.customerLocation.sorting.column === columnKey
            ? state.documentCreation.customerLocation.sorting.type
            : null;

    var dir;
    switch (columnKey) {
        case 'AddressBlock':
        case 'CustomerNumber':
        case 'ID':
        case 'Name':
        default:
            if (currentType === 'asc') dir = 'desc';
            else dir = 'asc';
            break;
    }

    dispatch(getCustomerLocationList(pageSize, 0, columnKey, dir));
};

export const fetchCustomersForSearchFilter = () => async (dispatch, getState) => {
    dispatch(customerActions.customersForAdvanceSearchFiltersFetching());
    dispatch(customerActions.clearCurrentCustomer());

    const companyID = portalUserService.getCurrentCompanyId();

    const params = {
        $count: true,
        companyId: companyID,
        isBuyer: false,
        $select: 'BuyerCompanyId, BuyerCompanyName',
    };

    const companyThunk = new TransceptaThunks.CompanyThunk();
    const response = await companyThunk.fetchTradingPartners(params);
    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, customerActions.CUSTOMERS_FOR_ADVANCE_SEARCH_FILTER_FAILED, params));
        return;
    }
    const { data } = response;
    const filterCustomers = data.Items.map((d) => ({ value: d.BuyerCompanyId, label: d.BuyerCompanyName }));
    dispatch(customerActions.customersForAdvanceSearchFiltersFetched(filterCustomers));
};

export const fetchTradingPartners = () => async (dispatch, getState) => {
    const companyID = portalUserService.getCurrentCompanyId();

    const params = {
        companyId: companyID,
        isBuyer: false,
    };
    const companyThunk = new TransceptaThunks.CompanyThunk();
    const response = await companyThunk.fetchTradingPartners(params);
    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, customerActions.SELECT_CUSTOMER_LIST_FETCH_FAILED, params));
    } else {
        await dispatch(customerActions.fetchCustomerListSuccess(response.data.Items));
    }
};

export const fetchSearchCustomers =
    (
        searchParams,
        skip,
        sort,
        fetchAll = false,
        top = projectConfig.docPageSize.toString(),
        buyerCompanyId,
        businessDocTypePathName = null
    ) =>
    async (dispatch, getState) => {
        dispatch(clearAlerts());
        const { selectCustomer, userlogin } = getState();
        const { search } = selectCustomer;
        const searchConfig = getCustomersSearchConfig();
        let newSearchParams = {};
        let newSkip = skip ? skip : 0;
        let params = {
            companyId: portalUserService.getCurrentCompanyId(),
            isBuyer: false,
            $skip: newSkip,
            $count: true,
            $top: top,
            $orderby: sort ? sort : `${search.sorting.column} ${search.sorting.direction}`,
        };

        let destinationProfileType = 'BuyerDestinationProfile';
        switch (businessDocTypePathName) {
            case projectConfig.businessDocTypePathName.InventoryAdvice:
                destinationProfileType = projectConfig.receiverProfiles[projectConfig.businessDocType.InventoryAdvice];
                break;
            case projectConfig.businessDocTypePathName.PurchaseOrderAcknowledgement:
                destinationProfileType =
                    projectConfig.receiverProfiles[projectConfig.businessDocType.PurchaseOrderAcknowledgement];
                break;
            case projectConfig.businessDocTypePathName.ASNGeneric:
                destinationProfileType = projectConfig.receiverProfiles[projectConfig.businessDocType.ASNGeneric];
                break;
            case projectConfig.businessDocTypePathName.Invoice:
                destinationProfileType = projectConfig.receiverProfiles[projectConfig.businessDocType.Invoice];
                break;
            case projectConfig.SIMDocument:
                destinationProfileType = projectConfig.receiverProfiles[projectConfig.businessDocType.SIMDocument];
                break;
            default:
                break;
        }

        if (fetchAll || !searchParams) {
            params.$filter = `${destinationProfileType}Id ne null`;
        } else {
            if (search.advancedSearch) {
                const noSearchParams = hasSearchParams(searchParams, searchConfig.advancedSearchConfig);
                if (noSearchParams) {
                    dispatch(
                        pushAlert({
                            type: 'error',
                            text: strings.textPleaseProvideSearchCriteria,
                        })
                    );
                    return;
                }
                const searchFilter = `${buildAdvancedSearchString({ ...searchParams })}`;
                params.$filter = searchFilter;
                newSearchParams = { searchParams, searchString: '' };
            } else {
                if (searchParams && !searchParams.trim()) {
                    dispatch(customerActions.customerClearSearch());
                    dispatch(
                        pushAlert({
                            type: 'error',
                            text: strings.textPleaseProvideSearchCriteria,
                        })
                    );
                    return;
                }

                const searchFilter = `${buildCustomerSingleSearchString(searchParams)}`;
                params.$filter = searchFilter;
                newSearchParams = { searchString: searchParams, searchParams: {} };
            }
        }

        params.$filter = buyerCompanyId
            ? `(${params.$filter}) and ${destinationProfileType}/CompanyID eq ${buyerCompanyId}`
            : params.$filter;
        dispatch(customerActions.customersFetching({ ...newSearchParams, newSkip, fetchAll }));

        const companyThunk = new TransceptaThunks.CompanyThunk();
        const response = await companyThunk.fetchCustomers(params);
        if (response.type && response.type === 'error') {
            dispatch(apiErrorAction(response.text, customerActions.CUSTOMERS_FETCHING, params));
            return;
        }

        const { data } = response;

        const result = {
            filteredCustomers: data.Items || [],
            totalEntries: data.Count,
            ...newSearchParams,
            skip: newSkip,
            sort,
            fetchAll,
        };
        dispatch(customerActions.customersFetched(result));
    };

export const sortCustomersSearch =
    (column, top = null, buyerProfileId = null, destinationProfile = null) =>
    async (dispatch, getState) => {
        const { selectCustomer } = getState();
        const { search } = selectCustomer;
        const { searchParams, skip, advancedSearch, searchString, fetchAll } = search;
        const searchConfig = getCustomersSearchConfig();
        const params = advancedSearch ? searchParams : searchString;
        const sortingColumn = {
            column,
            type: searchConfig.tableConfig.find((col) => col.key === column).type.name,
        };
        await dispatch(customerActions.customerSortSearch(sortingColumn));
        const direction = getState().selectCustomer.search.sorting.direction;
        const sort = `${column} ${direction}`;
        dispatch(fetchSearchCustomers(params, skip, sort, fetchAll, top, buyerProfileId, destinationProfile));
    };

export const customersSearchGoToPage =
    (skip, top = null, buyerProfileId = null, destinationProfile = null) =>
    (dispatch, getState) => {
        const { selectCustomer } = getState();
        const { search } = selectCustomer;
        const { sorting, searchParams, advancedSearch, searchString, fetchAll } = search;
        const params = advancedSearch ? searchParams : searchString;
        const { column, direction } = sorting;
        const sort = `${column} ${direction}`;
        dispatch(fetchSearchCustomers(params, skip, sort, fetchAll, top, buyerProfileId, destinationProfile));
    };

export const createCustomerLocation = (formData, customerLocationID) => async (dispatch, getState) => {
    let state = getState();
    dispatch(clearAlerts());
    if (!isValid(formData)) {
        dispatch(
            pushAlert({
                type: 'error',
                text: strings.textRequiredFieldMissing,
            })
        );
        return;
    }
    let CustomerDeliveryMethods = [];
    let formDataPrepared = preparedFormData(formData, CustomerDeliveryMethods);
    formDataPrepared['CustomerDeliveryMethods'] = CustomerDeliveryMethods;
    const CompanyID = parseFloat(portalUserService.getCurrentCompanyId(), 10);
    const { FaxNumber, ...rest } = formDataPrepared;

    const params = {
        Fax: FaxNumber,
        ...rest,
        CompanyID,
        PaymentType: 0,
        AllowMarketingMaterial: true,
    };

    dispatch(customerLocationActions.createCustomerLocationRequest(params));
    let companyThunk = new TransceptaThunks.CompanyThunk();
    let response;
    if (!customerLocationID) {
        response = await companyThunk.addCustomer(params);
    } else {
        const currentProfile = state.selectCustomer.currentProfile;
        const buyerProfile = state.selectCustomer.buyerProfiles.filter(
            (s) => s.Id === params.BuyerDestinationProfileId
        )[0];
        let buyerwarehouseProfileTypeId = null;
        let procurementProfileTypeId = null;
        if (currentProfile.buyerWarehouseProfile) {
            buyerwarehouseProfileTypeId = await getProfileById(
                dispatch,
                buyerProfile.CompanyID,
                projectConfig.profileType.BuyerWarehouse
            );
        }
        if (currentProfile.procurementProfile) {
            procurementProfileTypeId = await getProfileById(
                dispatch,
                buyerProfile.CompanyID,
                projectConfig.profileType.Procurement
            );
        }
        params.ID = customerLocationID;
        // params.BuyerDestinationProfile = {}.
        // params.BuyerDestinationProfile.Address1 = "101 Enterprise_";
        params.BuyerWarehouseProfileId = buyerwarehouseProfileTypeId;
        params.ProcurementProfileId = procurementProfileTypeId;
        response = await companyThunk.updateCustomerLocation(params);
    }

    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, customerLocationActions.CREATE_CUSTOMER_LOCATION_FAILED, params));
    } else {
        const {
            selectCustomer: { search },
        } = state;
        const searchParams = search.advancedSearch ? search.searchParams : search.searchString;
        // updating search grid results to reflect change
        await dispatch(fetchSearchCustomers(searchParams, search.skip, search.sort, search.fetchAll));
        dispatch(customerLocationActions.createCustomerLocationRequestSuccess(response));
        dispatch(customerActions.clearCurrentCustomer());
        dispatch(customerActions.navigateToBackManageSelectCustomer());
    }
};

export const fetchManagerCustomerLocation = (buyerCompanyId) => async (dispatch, getState) => {
    const params = {
        companyId: portalUserService.getCurrentCompanyId(),
        count: true,
        $top: null,
        $filter: 'ID eq ' + buyerCompanyId,
        $skip: null,
    };

    dispatch(customerActions.fetchingCustomerProfile());

    const companyThunk = new TransceptaThunks.CompanyThunk();
    const response = await companyThunk.fetchCustomers(params);
    if (response.type && response.type === 'error') {
        dispatch(customerLocationActions.fetchCustomerLocationFailure(response.text));
        dispatch(apiErrorAction(response.text));
        return;
    }

    const data = await response.data;
    const currentLocation = data.Items[0];
    dispatch(customerActions.selectCustomerLocationAction(currentLocation));

    const { Email, Fax, ShipToAddressBlock, AddressBlock, DefaultCurrency } = currentLocation;
    const { CompanyID } = currentLocation.BuyerDestinationProfile;

    await dispatch(fetchCustomer(CompanyID));
    dispatch(
        customerActions.fetchedCustomerProfile({
            ...currentLocation.BuyerDestinationProfile,
            CustomerNumber: currentLocation.CustomerNumber,
            Name: currentLocation.Name,
            Email,
            Fax,
            ShipToAddressBlock,
            AddressBlock,
            DefaultCurrency,
            buyerWarehouseProfile: currentLocation.BuyerWarehouseProfile,
            procurementProfile: currentLocation.ProcurementProfile,
        })
    );
    dispatch(
        push({
            pathname: R.MANAGE_CUSTOMERS_UPDATE.path.replace(':buyerCompanyId', buyerCompanyId),
            state: {
                path: R.MANAGE_CUSTOMERS.path,
            },
        })
    );
};

export const fetchCustomer = (buyerCompanyId) => async (dispatch, getState) => {
    const companyId = portalUserService.getCurrentCompanyId();

    const params = {
        companyId: companyId,
        $count: true,
        $filter: `BuyerCompanyId eq ${buyerCompanyId}`,
    };

    dispatch(customerActions.selectCustomerExecute(params));

    let companyThunk = new TransceptaThunks.CompanyThunk();
    let response = await companyThunk.fetchTradingPartners(params);
    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, customerActions.SELECT_CUSTOMER, params));
        return;
    }
    const {
        data: { Items },
    } = response;
    if (Items.length > 0) {
        dispatch(customerActions.selectCustomerAction({ ...Items[0] }));
    }
};

export const fetchBuyerProfilesByCompanyIds = (buyerCompanyIds) => async (dispatch) => {
    const params = {
        companyId: buyerCompanyIds.join(', '),
        profileType: projectConfig.profileType.BuyerDestination,
    };
    dispatch(customerActions.searchBuyerProfiles(params));
    let profileThunk = new TransceptaThunks.ProfileThunk();
    let response = await profileThunk.fetchProfiles(params);
    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, customerActions.SEARCH_BUYER_PROFILES_FAILED, params), false);
        return;
    }
    dispatch(customerActions.searchBuyerProfilesSuccess(response));
};

const getProfileById = async (dispatch, companyID, profileType) => {
    const params = {
        companyId: companyID,
        profileType: profileType,
    };

    let profileThunk = new TransceptaThunks.ProfileThunk();
    let response = await profileThunk.fetchProfiles(params);
    if (response.type && response.type === 'error') {
        dispatch(apiErrorAction(response.text, customerActions.SEARCH_BUYER_PROFILES_FAILED, params), false);
        return null;
    }
    return response.data;
};
const buildAdvancedSearchString = (searchParams) => {
    return Object.keys(searchParams).reduce((acc, key) => {
        if (searchParams[key].value) {
            if (acc) {
                acc += ' and ';
            }
            const valArr = Array.isArray(searchParams[key].value) ? searchParams[key].value : [searchParams[key].value];
            acc += valArr.reduce((valArrAcc, v) => {
                if (searchParams[key].type.includes('text') || searchParams[key].type.includes('TextArea')) {
                    valArrAcc += `contains(tolower(${key}), tolower('${v}'))`;
                } else if (searchParams[key].type.includes('Select')) {
                    valArrAcc += `BuyerDestinationProfile/CompanyID eq ${v.value}`;
                }
                return valArrAcc;
            }, '');
        }

        return acc;
    }, '');
};

const buildCustomerSingleSearchString = (searchString) => {
    const config = getCustomersSearchConfig();
    const searchParams = config.advancedSearchConfig;
    return searchParams.reduce((acc, item) => {
        if (acc) {
            acc += ' or ';
        }

        if (item.type.includes('text') || item.type.includes('TextArea')) {
            acc += `contains(tolower(${item.key}), tolower('${searchString}'))`;
        }

        return acc;
    }, '');
};

const getCustomersSearchConfig = () => {
    let customerrSearchConfig = new CustomersSearchConfig();
    return customerrSearchConfig.createSearchConfig();
};

const hasSearchParams = (searchParams, advancedSearchConfig) => {
    let noSearchParams = true;

    advancedSearchConfig.map((field) => {
        if (searchParams[field.key] && searchParams[field.key].value && searchParams[field.key].value != '') {
            noSearchParams = false;
        }
    });

    return noSearchParams;
};

const isValid = (formData) => {
    let valid = true;
    let field;
    for (let i = 0; i < formData.length; i++) {
        field = formData[i];
        if (field.required && field.required === true && typeof field.value !== undefined && field.value === '') {
            valid = false;
            break;
        }
    }
    return valid;
};

const preparedFormData = (formData, CustomerDeliveryMethods) => {
    let formDataPrepared = {};
    let field, currentKey;
    for (let i = 0; i < formData.length; i++) {
        field = formData[i];
        currentKey = formData[i].key;
        switch (currentKey) {
            case 'DoNotDeliver':
            case 'UseDeliverySettings':
                //do not include this fields
                break;
            case 'Email':
                if (field.value === '') {
                    formDataPrepared[currentKey] = null;
                } else {
                    formDataPrepared[currentKey] = formData[i].value;
                }
                break;
            case 'Status':
                formDataPrepared[currentKey] = projectConfig.TradingPartnerStatus[field.value]
                    ? projectConfig.TradingPartnerStatus[field.value]
                    : projectConfig.TradingPartnerStatus.Active;
                break;
            case 'DefaultCurrency':
                formDataPrepared[currentKey] = parseFloat(formData[i].value, 10);
                break;
            case 'SendVia':
                if (field.value === true) {
                    formDataPrepared['DeliverySetting'] = 3;
                }
                break;
            case 'SendViaElectronic':
                if (field.value === true && formDataPrepared['DeliverySetting'] === 2) {
                    CustomerDeliveryMethods.push({ InvoiceDeliveryMethod: 6 });
                }
                break;
            case 'SendViaEmail':
                if (field.value === true && formDataPrepared['DeliverySetting'] === 2) {
                    CustomerDeliveryMethods.push({ InvoiceDeliveryMethod: 2 });
                }
                break;
            case 'SendViaFax':
                if (field.value === true && formDataPrepared['DeliverySetting'] === 2) {
                    CustomerDeliveryMethods.push({ InvoiceDeliveryMethod: 3 });
                }
                break;
            case 'SendViaPost':
                if (field.value === true && formDataPrepared['DeliverySetting'] === 2) {
                    CustomerDeliveryMethods.push({ InvoiceDeliveryMethod: 4 });
                }
                break;
            case 'DeliverySetting':
                formDataPrepared[currentKey] =
                    field.value === true
                        ? projectConfig.deliverySetting.DoNotSend
                        : projectConfig.deliverySetting.SendToBuyer;
                break;

            default:
                formDataPrepared[currentKey] = formData[i].value;
        }
    }

    return formDataPrepared;
};
