import { useState, useCallback, useEffect } from 'react';
import { IErrors, IFormValidationSchema } from './types';

/**
 * Validates the form values against the schema whenever they update and returns
 * the current form errors.
 *
 * @param values current form values
 * @param validationSchema schema to validate values against
 * @returns the current form errors
 */
export default function useValidationErrors<T>(values: T, validationSchema: Partial<IFormValidationSchema<T>>) {
    const [errors, setErrors] = useState<IErrors<T>>({} as any);

    const validateField = useCallback(
        <K extends keyof T>(fieldKey: K, value: T[K]) => {
            const error = '';

            const fieldSchema = validationSchema[fieldKey];
            if (fieldSchema) {
                for (const rule of fieldSchema.rules) {
                    if (rule.isRequired && !value) {
                        return rule.message;
                    } else if (typeof rule.test === 'function' && rule.test(value)) {
                        const reason = typeof rule.test(value) === 'string' ? rule.test(value) : '';
                        return `${rule.message} ${reason}`;
                    }
                }
            }

            return error;
        },
        [validationSchema]
    );

    useEffect(() => {
        const newErrors: IErrors<T> = {};

        Object.keys(validationSchema).forEach((key) => {
            const error = validateField(key as keyof T, values[key as keyof T]);
            if (error) {
                newErrors[key as keyof T] = error;
            }
        });

        setErrors(newErrors);
    }, [values, validationSchema, validateField]);

    return errors;
}
